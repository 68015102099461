import React, { Fragment } from "react";

export const ReleasePreviewForm = props => {
  const { values } = props;
  return (
    <Fragment>
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <span className="card-title mb-0">
                {/* <Link
                    to={`/asset/leases`}
                    className="badge bg-white custom-btn-sm"
                  >
                    <i className="fas fa-arrow-left" />
                  </Link> */}
                &nbsp;Asset Release Form
              </span>
            </div>
            <div className="card-body">
              <div className="row mt-3 mb-3">
                <div className="col-md-12 text-center">
                  <h5>
                    <strong>Asset Lease Confirmation</strong>
                  </h5>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card-deck">
                    <div className="card">
                      <div className="card-header">
                        <strong>Asset Information</strong>
                      </div>
                      <div className="card-body">
                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Asset Id</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.asset && values.asset.assetId) || ""}
                          </label>
                        </div>

                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong> Name</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.asset && values.asset.assetName) || ""}
                          </label>
                        </div>
                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong> Type</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.asset && values.asset.assetType) || ""}
                          </label>
                        </div>
                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong> Category</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.asset && values.asset.assetCategory) || ""}
                          </label>
                        </div>

                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong> Owner</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.asset && values.asset.ownerName) || ""}
                          </label>
                        </div>

                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Rental Type</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.asset && values.asset.rentalType) || ""}
                          </label>
                        </div>

                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Status</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.asset && values.asset.status) || ""}
                          </label>
                        </div>
                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Rental Status</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.asset && values.asset.rentalStatus) || ""}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <strong>Lease Information</strong>
                      </div>
                      <div className="card-body">
                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Leasor Name</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.assetLease && values.assetLease.name) ||
                              ""}
                          </label>
                        </div>

                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong> Requestor Type</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.assetLease &&
                              values.assetLease.requestorType) ||
                              ""}
                          </label>
                        </div>

                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Start Date</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.assetLease &&
                              values.assetLease.startDate) ||
                              ""}
                          </label>
                        </div>

                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong> End Date</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {(values.assetLease && values.assetLease.endDate) ||
                              ""}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <strong>Release Information</strong>
                      </div>
                      <div className="card-body">
                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Released At</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {values.createdAt}
                          </label>
                        </div>

                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Released By</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {values.releasedBy || ""}
                          </label>
                        </div>
                        <div className="form-group row mb-0">
                          <label className="col-form-label col-6">
                            <strong>Notes</strong>
                          </label>
                          <label className="col-form-label col-6 pl-lg-0 pl-xl-0">
                            {values.notes || ""}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5 mb-5">
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-sm btn-info"
                    onClick={props.handlePrev}
                  >
                    Back
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={props.handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
