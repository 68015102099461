import React, { Component } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/lib/Creatable";
import {
  RELATIONSHIP_MANAGERS_API,
  SUPPLEMENTARY_INCUBATORS_API
} from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import {
  GRADUATION_STATUS,
  RESIDENTIAL_STATUS,
  INCUBATION_SECTORS,
  SUB_SECTORS,
  INCUBATEE_STAGES,
  INCUBATION_CATEGORY_TYPES
} from "../common/IncubateeProfileConstants";
import Select from "react-select";
const renderInputData = data => {
  return data ? data : "";
};

export class IncubateeProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incubateeProfile: {
        businessPlan: null,
        businessModel: null,
        incubateeStage: null,
        grandsOrFunding: null,
        incubationSector: null,
        subTechSector: null,
        targetSector: null,
        currentEmployeeCount: null,
        femaleEmployeeCount: null,
        graduationStatus: null,
        residentialStatus: null,
        incubationCategory: null,
        relationshipManager: null,
        anchorIncubator: null,
        supplIncubators: [],
        about: null
      },

      incubators: [],
      supplIncubators: [],
      anchorIncubators: [],
      relationshipManagers: []
    };
  }

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value;
    if (e.target.value === "") {
      targetValue = null;
    }
    const incubateeProfile = { ...this.state.incubateeProfile };
    if (targetName === "incubationCategory") {
      if (!targetValue) {
        incubateeProfile.anchorIncubator = null;
        incubateeProfile.supplIncubators = [];
      }
    }
    incubateeProfile[targetName] = targetValue;
    this.setStateIncProfile(incubateeProfile);
  };

  setStateIncProfile = incubateeProfile => {
    this.setState({ incubateeProfile });
  };

  handleRelationshipManager = selectedManager => {
    const incubateeProfile = this.state.incubateeProfile;
    if (selectedManager) {
      incubateeProfile.relationshipManager = selectedManager;
    } else {
      delete incubateeProfile.relationshipManager;
    }
    this.setStateIncProfile(incubateeProfile);
  };

  getAllIncubators = () => {
    const url = SUPPLEMENTARY_INCUBATORS_API;

    HttpUtil.get(
      url,
      {},
      data => {
        let incubators = [];
        if (data.length > 0) {
          incubators = data.map(incubator => {
            let inbtr = {};
            inbtr.label = incubator.name;
            inbtr.value = incubator._id;
            return inbtr;
          });
        }
        this.setState({
          incubators: incubators,
          supplIncubators: incubators,
          anchorIncubators: incubators
        });
      },
      (data, status) => {
        this.props.handleApiFailed(data.message);
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  getRelationshipManager = () => {
    const url = RELATIONSHIP_MANAGERS_API;

    HttpUtil.get(
      url,
      {},
      data => {
        let relationshipManagers = [];
        if (data.length > 0) {
          relationshipManagers = data.map(relationshipmanager => {
            let relationmanager = {};
            relationmanager.label = relationshipmanager.name;
            relationmanager.value = relationshipmanager._id;
            return relationmanager;
          });
        }
        this.setState({
          relationshipManagers: relationshipManagers
        });
      },
      (data, status) => {
        this.props.handleApiFailed(
          data.message ||
            "An error occurred while getting data for relationship managers. Please try again or contact support if the issue persists."
        );
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  createAnchorIncubator = newIncubatorName => {
    this.setState({
      ancLoding: true
    });

    const url = SUPPLEMENTARY_INCUBATORS_API;
    const headers = {
      "Content-Type": "application/json"
    };

    const incubator = { name: newIncubatorName };
    HttpUtil.post(
      url,
      headers,
      incubator,
      data => {
        let incubators = [];
        let newIncubator = {};
        if (data.length > 0) {
          incubators = data.map(incubator => {
            let inbtr = {};
            inbtr.label = incubator.name;
            inbtr.value = incubator._id;

            if (incubator.name === newIncubatorName) {
              newIncubator = inbtr;
            }
            return inbtr;
          });
        }

        const subppleInbtr = this.removeIncubator(
          newIncubator.value,
          incubators
        );

        const incubateeProfile = { ...this.state.incubateeProfile };
        incubateeProfile.anchorIncubator = newIncubator;

        this.setState({
          ancLoding: false,
          incubators: incubators,
          supplIncubators: subppleInbtr,
          anchorIncubators: incubators,
          incubateeProfile
        });
      },
      (data, status) => {
        this.props.handleApiFailed(data.message);
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  createSupplementoryIncubator = newIncubatorName => {
    this.setState({
      supLoding: true
    });

    const url = SUPPLEMENTARY_INCUBATORS_API;
    const headers = {
      "Content-Type": "application/json"
    };

    const incubator = { name: newIncubatorName };
    HttpUtil.post(
      url,
      headers,
      incubator,
      data => {
        let incubators = [];
        let newIncubator = {};
        if (data.length > 0) {
          incubators = data.map(incubator => {
            let inbtr = {};
            inbtr.label = incubator.name;
            inbtr.value = incubator._id;

            if (incubator.name === newIncubatorName) {
              newIncubator = inbtr;
            }
            return inbtr;
          });
        }
        const ancInbtr = this.removeIncubator(newIncubator.value, incubators);

        const incubateeProfile = { ...this.state.incubateeProfile };
        incubateeProfile.supplIncubators.push(newIncubator);

        this.setState({
          supLoding: false,
          incubators: incubators,
          supplIncubators: incubators,
          anchorIncubators: ancInbtr,
          incubateeProfile
        });
      },
      (data, status) => {
        this.props.handleApiFailed(data.message);
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  removeIncubator = (removeId, incubators) => {
    if (incubators.length === 0) {
      return;
    }
    return incubators.filter(ibtr => ibtr.value !== removeId);
  };

  selectAnchorIncubator = (incubator, actionMeta) => {
    let incubateeProfile = { ...this.state.incubateeProfile };

    if (incubator === null) {
      incubateeProfile.anchorIncubator = null;
      this.setState({ incubateeProfile });
      return;
    }
    const acnhorIncubatorId = incubator.value;
    const incubators = this.state.incubators;
    const supleInbtr = this.removeIncubator(acnhorIncubatorId, incubators);
    incubateeProfile.anchorIncubator = incubator;
    this.setState({
      supplIncubators: supleInbtr,
      incubateeProfile
    });
  };

  selectSupplementaryIncubator = (incubator, actionMeta) => {
    let incubateeProfile = { ...this.state.incubateeProfile };
    if (incubator === null) {
      incubateeProfile.supplIncubators = null;
      this.setState({ incubateeProfile });
      return;
    }

    const selectedInbtrs = incubator;
    const incubators = this.state.incubators;
    let ancInbtr = [];
    if (selectedInbtrs.length > 0) {
      const exstingSuppleInbtrs = new Set();
      selectedInbtrs.forEach(si => exstingSuppleInbtrs.add(si.value));
      ancInbtr = incubators.filter(
        inctr => !exstingSuppleInbtrs.has(inctr.value)
      );
    }

    incubateeProfile.supplIncubators = incubator;
    this.setState({
      anchorIncubators: ancInbtr,
      incubateeProfile
    });
  };

  validate = () => {
    const profile = { ...this.state.incubateeProfile };
    const anchor = profile.anchorIncubator;
    profile.anchorIncubator = anchor ? anchor.value : null;
    if (profile.supplIncubators) {
      profile.supplIncubators = profile.supplIncubators.map(s => s.value);
    }
    this.props.onValidate(null, "incubateeProfile", profile);
  };

  loadStateFromProps = () => {
    const { incubateeProfile } = this.props;
    if (incubateeProfile !== null) {
      const anchorIncubator = incubateeProfile.anchorIncubator;
      let ancInbtr = anchorIncubator
        ? {
            label: anchorIncubator.name,
            value: anchorIncubator._id
          }
        : null;

      const newIncubateProfile = { ...incubateeProfile };
      newIncubateProfile.anchorIncubator = ancInbtr;

      const supplIncubators = incubateeProfile.supplIncubators;
      let subppleInbtrs = supplIncubators
        ? supplIncubators.map(sptrs => {
            const supleInbtr = {
              label: sptrs.name,
              value: sptrs._id
            };
            return supleInbtr;
          })
        : [];

      newIncubateProfile.supplIncubators = subppleInbtrs;

      this.setState({
        incubateeProfile: { ...newIncubateProfile }
      });
    }
  };

  componentDidMount() {
    this.getAllIncubators();
    this.loadStateFromProps();
    this.getRelationshipManager();
  }

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    if (this.props.basicInfo !== prevState.basicInfo) {
      this.loadStateFromProps();
    }
  };

  render() {
    const {
      businessPlan,
      businessModel,
      incubateeStage,

      incubationSector,
      subTechSector,
      targetSector,
      graduationStatus,
      residentialStatus,
      incubationCategory,
      about,
      relationshipManager
    } = this.state.incubateeProfile;

    const {
      anchorIncubators,
      supplIncubators,
      ancLoding,
      supLoding
    } = this.state;

    const selectedAnchorInbtr = this.state.incubateeProfile.anchorIncubator;
    const selectedSuppleInbtr = this.state.incubateeProfile.supplIncubators;

    return (
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">
                <Link to={this.props.previousLocation} className="">
                  <i className="fas fa-arrow-left mr-2" />
                </Link>
                Incubatee Profile
              </h5>
            </div>
            <div className="card-body">
              <div className="form-group row mb-0 ">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Business Plan
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Business Plan"
                    name="businessPlan"
                    value={renderInputData(businessPlan)}
                    onChange={this.handleInputChange}
                  />
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Business Model
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Business Model"
                    name="businessModel"
                    value={renderInputData(businessModel)}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Incubatee Stage
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    className="custom-select custom-select-sm"
                    name="incubateeStage"
                    value={renderInputData(incubateeStage)}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select...</option>
                    {INCUBATEE_STAGES.map((opt, i) => (
                      <option key={`${i}`} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </select>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Target Sector
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Target selector"
                    name="targetSector"
                    value={renderInputData(targetSector)}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Incubation Sector
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    name="incubationSector"
                    value={renderInputData(incubationSector)}
                    onChange={this.handleInputChange}
                    className="custom-select custom-select-sm"
                  >
                    <option value="">Select...</option>
                    {INCUBATION_SECTORS.map((opt, i) => (
                      <option key={`${i}`} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </select>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Sub Tech Sector
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    name="subTechSector"
                    value={renderInputData(subTechSector)}
                    onChange={this.handleInputChange}
                    className="custom-select custom-select-sm"
                  >
                    <option value="">Select...</option>
                    {SUB_SECTORS.map((opt, i) => (
                      <option key={`${i}`} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <hr />
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Graduation Status
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    name="graduationStatus"
                    value={renderInputData(graduationStatus)}
                    onChange={this.handleInputChange}
                    className="custom-select custom-select-sm"
                    disabled
                  >
                    <option value="">Select...</option>
                    {GRADUATION_STATUS.map((opt, i) => (
                      <option key={`${i}`} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </select>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Residential Status
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    name="residentialStatus"
                    value={renderInputData(residentialStatus)}
                    onChange={this.handleInputChange}
                    className="custom-select custom-select-sm"
                  >
                    <option value="">Select...</option>
                    {RESIDENTIAL_STATUS.map((opt, i) => (
                      <option key={`${i}`} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Incubation Category
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    name="incubationCategory"
                    onChange={this.handleInputChange}
                    value={incubationCategory || ""}
                    className="custom-select custom-select-sm"
                  >
                    <option value="">Select...</option>
                    {INCUBATION_CATEGORY_TYPES.map((opt, i) => (
                      <option key={`${i}`} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </select>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Relationship Manager
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Select
                    isClearable
                    options={this.state.relationshipManagers}
                    name="relationshipManager"
                    onChange={this.handleRelationshipManager}
                    value={relationshipManager}
                  />
                </div>
              </div>

              {incubationCategory === "COINCUBATION" ? (
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Anchor Incubator
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <CreatableSelect
                      isClearable
                      isDisabled={ancLoding}
                      isLoading={ancLoding}
                      onChange={this.selectAnchorIncubator}
                      onCreateOption={this.createAnchorIncubator}
                      options={anchorIncubators}
                      value={selectedAnchorInbtr}
                    />
                  </div>
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Supplementary Incubator
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <CreatableSelect
                      isMulti
                      isDisabled={supLoding}
                      isLoading={supLoding}
                      onChange={this.selectSupplementaryIncubator}
                      onCreateOption={this.createSupplementoryIncubator}
                      options={supplIncubators}
                      value={selectedSuppleInbtr}
                    />
                  </div>
                </div>
              ) : null}

              <hr />

              <div className="form-group row ">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  About
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <textarea
                    type="text"
                    className="form-control form-control-sm"
                    name="about"
                    rows="3"
                    placeholder="Write something about your Business"
                    value={renderInputData(about)}
                    onChange={this.handleInputChange}
                  />
                  <small>Write brief about the incubatee</small>
                  <div className="invalid-feedback">
                    Please enter business background and summary *.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
