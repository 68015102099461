import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableList from "../../common/TableList";
import AlertComponent from "../../common/AlertComponent";
import { GRANT_AWARDS_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { AWARD_STATUS_OBJECT } from "../../common/LookupConstants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";

function getName(cell, record) {
  let name;
  if (record.granteeType === "Individual") {
    const names = [];
    if (record.grantee.firstName) {
      names.push(record.grantee.firstName);
    }
    if (record.grantee.lastName) {
      names.push(record.grantee.lastName);
    }
    name = names.join(" ").trim();
  } else {
    name = record.grantee.name;
  }
  return name;
}

function linkFormater(cell, record) {
  return getName(cell, record);
}

function dateFormatter(cell) {
  let displayDate = getDisplayDate(convertNumberToDate(cell));
  return displayDate;
}

function actionsFormater(cell, row) {
  return (
    <Fragment>
      <Link
        to={`/funding/awards/progress/${row._id}`}
        role="button"
        datatoggle="tooltip"
        title="Edit Review"
      >
        <i className="fas fa-edit fa-lg action-icon" />
      </Link>
    </Fragment>
  );
}

const columnsAwards = [
  {
    dataField: "grantee",
    text: "Grantee",
    sort: true,
    formatter: linkFormater,
    csvFormatter: getName
  },
  {
    dataField: "grantRef.grantProgram.name",
    text: "Grant",
    sort: true
  },
  {
    dataField: "grantRef.grantId",
    text: "Grant Funding Cycle ID",
    sort: true
  },
  {
    dataField: "startDate",
    text: "Start Date",
    sort: true,
    formatter: dateFormatter,
    csvFormatter: dateFormatter
  },
  {
    dataField: "endDate",
    text: "End Date",
    sort: true,
    formatter: dateFormatter,
    csvFormatter: dateFormatter
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: status => AWARD_STATUS_OBJECT[status],
    csvFormatter: status => AWARD_STATUS_OBJECT[status]
  },
  {
    dataField: "actions",
    text: "Actions",
    isDummyField: true,
    headerAlign: "center",
    align: "center",
    csvExport: false,
    formatter: actionsFormater,
    headerStyle: { width: "16.66%" }
  }
];

class GrantAwardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awardList: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    HttpUtil.get(
      GRANT_AWARDS_API,
      {},
      data => {
        this.setState({ awardList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb border-bottom-0">
                <li className="breadcrumb-item">
                  <Link to="/funding/agencies">Funding Agencies</Link>
                </li>
                <li className="breadcrumb-item active">Awards</li>
              </ol>
            </nav>
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsAwards}
          data={this.state.awardList}
          noDataIndication="No Grant Funding Awards are available."
          sizePerPage="10"
          headerName="Grant Funding Awards"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default GrantAwardList;

const RenderCustomButton = props => {
  return (
    <Link
      to="/funding/awards/new"
      role="button"
      className="btn btn-sm text-white bg-pink"
    >
      New
    </Link>
  );
};
