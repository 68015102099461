// For Header.js
export const headerLinks = [
  {
    title: "Home",
    link: "/home"
  },
  {
    title: "Change Password",
    link: "/change-password"
  },
  // {
  //   title: "Services",
  //   link: "/services"
  // },
  // {
  //   title: "Profile",
  //   link: "/profile"
  // },
  {
    title: "Logout",
    link: "/logout",
    clickHandlerName: "handleLogout"
  }
];
