import React from "react";
import { Field, ErrorMessage } from "formik";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

const Contact = props => {
  const { values, errors, touched, setFieldValue, downloadDocument } = props;
  const contactTouched = touched.contact || {};
  const contactErrors = errors.contact || {};
  const addressErrors = contactErrors.address || {};
  const addressTouched = contactTouched.address || {};
  const contact = values.contact || {};
  const address = contact.address || {};

  const identificationDocs = values.identificationDocs || {};
  const identificationDocsErrors = errors.identificationDocs || {};
  const identificationDocsTouched = touched.identificationDocs || {};
  return (
    <div>
      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Contact Information</span>
        </div>
      </div>

      <div className="card-body">
        <div className="row mb-2">
          {/* Phone */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Phone
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(contactTouched, contactErrors, "phone")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="contact.phone"
                  values={contact.phone || ""}
                />
                <small className="text-muted">
                  Please enter primary mobile number
                </small>
                <ErrorMessage
                  component="div"
                  name="contact.phone"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Email */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Email
              </label>
              <div className="col-lg-6">
                <Field
                  type="email"
                  className={`form-control form-control-sm ${
                    getError(contactTouched, contactErrors, "email")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="contact.email"
                  values={contact.email || ""}
                />
                <small className="text-muted">Please enter primary email</small>
                <ErrorMessage
                  component="div"
                  name="contact.email"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Address Information</span>
        </div>
      </div>

      <div className="card-body">
        <div className="row mb-2">
          {/* Street Line 1 */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Street Line 1
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(addressTouched, addressErrors, "streetLine1")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="contact.address.streetLine1"
                  values={address.streetLine1 || ""}
                />
                <ErrorMessage
                  component="div"
                  name="contact.address.streetLine1"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Street Line 2 */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Street Line 2
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(addressTouched, addressErrors, "streetLine2")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="contact.address.streetLine2"
                  values={address.streetLine2 || ""}
                />
                <ErrorMessage
                  component="div"
                  name="contact.address.streetLine2"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* City */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                City
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(addressTouched, addressErrors, "city")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="contact.address.city"
                  values={address.city || ""}
                />
                <ErrorMessage
                  component="div"
                  name="contact.address.city"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Landmark */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Landmark
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(addressTouched, addressErrors, "landmark")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="contact.address.landmark"
                  values={address.landmark || ""}
                />
                <ErrorMessage
                  component="div"
                  name="contact.address.landmark"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* State */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                State
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`form-control form-control-sm ${
                    getError(addressTouched, addressErrors, "state")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="contact.address.state"
                  values={address.state || ""}
                >
                  <option value="">Select...</option>
                  {props.states.map((state, i) => (
                    <option key={i} value={state.stateCode}>
                      {state.stateName}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="contact.address.state"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Country */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Country
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`form-control form-control-sm ${
                    getError(addressTouched, addressErrors, "country")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="contact.address.country"
                  values={address.country || ""}
                >
                  <option value="">Select...</option>
                  {props.countries.map((country, i) => (
                    <option key={i} value={country.countryCode}>
                      {country.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="contact.address.country"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Postal Code */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Postal Code
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(addressTouched, addressErrors, "postalCode")
                      ? "is-invalid"
                      : ""
                  }`}
                  inputMode="numeric"
                  name="contact.address.postalCode"
                  values={address.postalCode || ""}
                />
                <ErrorMessage
                  component="div"
                  name="contact.address.postalCode"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Address Proof */}
        <div className="row mb-2">
          <div className="col-lg-12 mb-0">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label">
                Address Proof (Driving License, Aadhaar)
              </label>
              <div className="col-lg-6">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="contact.address.uploadAddressProof"
                      name="contact.address.uploadAddressProof"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue(
                            "contact.address.uploadAddressProof",
                            file
                          );
                        }
                        e.target.value = "";
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="contact.address.uploadAddressProof"
                    >
                      {address.uploadAddressProof
                        ? address.uploadAddressProof.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        setFieldValue("contact.address.uploadAddressProof", "")
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                {address.addressProof ? (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-1"
                    onClick={downloadDocument.bind(this, address.addressProof)}
                  >
                    <i className="fas fa-download mr-1" /> Address Proof
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Identification Documents</span>
        </div>
      </div>

      <div className="card-body">
        {/* Pan */}
        <div className="row mb-2">
          <div className="col-lg-4">
            <div className="form-group row mb-0">
              <label className="col-form-label col-lg-4 col-xl-4 edit-page-label">
                Pan
              </label>
              <div className="col-lg-8 col-xl-8">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(
                      identificationDocsTouched,
                      identificationDocsErrors,
                      "panNumber"
                    )
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Pan Number"
                  name="identificationDocs.panNumber"
                  value={identificationDocs.panNumber}
                />
                <ErrorMessage
                  component="div"
                  name="identificationDocs.panNumber"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group row mb-0">
              <label className="col-form-label col-lg-4 edit-page-label">
                Pan Scanned Copy
              </label>
              <div className="col-lg-7">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="identificationDocs.uploadPan"
                      name="identificationDocs.uploadPan"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue("identificationDocs.uploadPan", file);
                        }
                        e.target.value = "";
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="identificationDocs.uploadPan"
                    >
                      {identificationDocs.uploadPan
                        ? identificationDocs.uploadPan.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        setFieldValue("identificationDocs.uploadPan", "")
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="row mb-0">
              <div className="col-12">
                {identificationDocs.pan ? (
                  <button
                    className="btn btn-sm btn-info"
                    onClick={downloadDocument.bind(
                      this,
                      identificationDocs.pan
                    )}
                  >
                    <i className="fas fa-download mr-1" /> Pan copy
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* GSTN */}
        {values.incubateeType === "COMPANY" ? (
          <div className="row mb-2">
            <div className="col-lg-4">
              <div className="form-group row mb-0">
                <label className="col-form-label col-lg-4 col-xl-4 edit-page-label">
                  GSTN
                </label>
                <div className="col-lg-8 col-xl-8">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${
                      getError(
                        identificationDocsTouched,
                        identificationDocsErrors,
                        "gstnNumber"
                      )
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="GSTN Number"
                    name="identificationDocs.gstnNumber"
                    value={identificationDocs.gstnNumber}
                  />
                  <ErrorMessage
                    component="div"
                    name="identificationDocs.gstnNumber"
                    className="invalid-feedback mb-2"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row mb-0">
                <label className="col-form-label col-lg-4 edit-page-label">
                  GSTN Scanned Copy
                </label>
                <div className="col-lg-7">
                  <div
                    className={`input-group ${
                      getError(
                        identificationDocsTouched,
                        identificationDocsErrors,
                        "uploadGstn"
                      )
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <div className="custom-file">
                      <input
                        type="file"
                        className={`custom-file-input ${
                          getError(
                            identificationDocsTouched,
                            identificationDocsErrors,
                            "uploadGstn"
                          )
                            ? "is-invalid"
                            : ""
                        }`}
                        id="identificationDocs.uploadGstn"
                        aria-describedby="identificationDocs.uploadGstn"
                        name="identificationDocs.uploadGstn"
                        onChange={e => {
                          const file = e.target.files[0];
                          if (file) {
                            setFieldValue(
                              "identificationDocs.uploadGstn",
                              file
                            );
                          }
                          e.target.value = "";
                        }}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="identificationDocs.uploadGstn"
                      >
                        {identificationDocs.uploadGstn
                          ? identificationDocs.uploadGstn.name
                          : "Choose file"}
                      </label>
                    </div>
                    <div className="input-group-append">
                      <button
                        className="btn btn-sm btn-outline-info"
                        type="button"
                        onClick={e =>
                          setFieldValue("identificationDocs.uploadGstn", "")
                        }
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <ErrorMessage
                    component="div"
                    name="identificationDocs.uploadGstn"
                    className="invalid-feedback d-block mb-2"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="row mb-0">
                <div className="col-12">
                  {identificationDocs.gstn ? (
                    <button
                      className="btn btn-sm btn-info"
                      onClick={downloadDocument.bind(
                        this,
                        identificationDocs.gstn
                      )}
                    >
                      <i className="fas fa-download mr-1" /> GSTN copy
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* Aadhaar */}
        {values.incubateeType === "INDIVIDUAL" ? (
          <div className="row mb-2">
            <div className="col-lg-4">
              <div className="form-group row mb-0">
                <label className="col-form-label col-lg-4 col-xl-4 edit-page-label required">
                  Aadhaar
                </label>
                <div className="col-lg-8 col-xl-8">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${
                      getError(
                        identificationDocsTouched,
                        identificationDocsErrors,
                        "aadhaarNumber"
                      )
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Aadhaar Number"
                    name="identificationDocs.aadhaarNumber"
                    value={identificationDocs.aadhaarNumber}
                  />
                  <ErrorMessage
                    component="div"
                    name="identificationDocs.aadhaarNumber"
                    className="invalid-feedback mb-2"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row mb-0">
                <label className="col-form-label col-lg-4 edit-page-label required">
                  Aadhaar Scanned Copy
                </label>
                <div className="col-lg-7">
                  <div
                    className={`input-group ${
                      getError(
                        identificationDocsTouched,
                        identificationDocsErrors,
                        "uploadAadhaar"
                      )
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <div className="custom-file">
                      <input
                        type="file"
                        className={`custom-file-input ${
                          getError(
                            identificationDocsTouched,
                            identificationDocsErrors,
                            "uploadAadhaar"
                          )
                            ? "is-invalid"
                            : ""
                        }`}
                        id="identificationDocs.uploadAadhaar"
                        aria-describedby="identificationDocs.uploadAadhaar"
                        name="identificationDocs.uploadAadhaar"
                        onChange={e => {
                          const file = e.target.files[0];
                          if (file) {
                            setFieldValue(
                              "identificationDocs.uploadAadhaar",
                              file
                            );
                          }
                          e.target.value = "";
                        }}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="identificationDocs.uploadAadhaar"
                      >
                        {identificationDocs.uploadAadhaar
                          ? identificationDocs.uploadAadhaar.name
                          : "Choose file"}
                      </label>
                    </div>
                    <div className="input-group-append">
                      <button
                        className="btn btn-sm btn-outline-info"
                        type="button"
                        onClick={e =>
                          setFieldValue("identificationDocs.uploadAadhaar", "")
                        }
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <ErrorMessage
                    component="div"
                    name="identificationDocs.uploadAadhaar"
                    className="invalid-feedback d-block mb-2"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="row mb-0">
                <div className="col-12">
                  {identificationDocs.aadhaar ? (
                    <button
                      className="btn btn-sm btn-info"
                      onClick={downloadDocument.bind(
                        this,
                        identificationDocs.aadhaar
                      )}
                    >
                      <i className="fas fa-download mr-1" /> Aadhaar copy
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Contact;
