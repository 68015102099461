import React, { Fragment } from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import { EDU_QUALIFICATION_TYPES } from "../../../common/LookupConstants";
import ShowHideComponent from "../../../widgets/ShowHideComponent";
import { getYears } from "../../../lease/DateUtil";

const YEARS = getYears();

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

const GENDERS = [
  {
    label: "Male",
    value: "M"
  },
  {
    label: "Female",
    value: "F"
  },
  {
    label: "Transgender",
    value: "T"
  }
];

const Education = props => {
  const {
    pmtrIndex,
    indvError,
    indvTouched,
    individual,
    setFieldValue
  } = props;

  const education = individual.education || [];

  return (
    <FieldArray
      name={`organization.promoters[${pmtrIndex}].individual.education`}
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {education.map((edu, eduIndex) => {
              const educationError =
                (indvError.education && indvError.education[eduIndex]) || {};
              const educationTouched =
                (indvTouched.education && indvTouched.education[eduIndex]) ||
                {};

              return (
                <div className="card-body" key={eduIndex}>
                  <div className="row">
                    <div className="col-12 mb-3 edit-page-subsection-header-text">
                      Education
                    </div>
                  </div>
                  <div className="row mb-2">
                    {/* Edu Qualification */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Edu Qualification
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            // className="form-control form-control-sm"
                            className={`custom-select custom-select-sm ${
                              getError(
                                educationTouched,
                                educationError,
                                "qualification"
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].qualification`}
                            values={edu.qualification || ""}
                          >
                            <option value="">Select...</option>
                            {EDU_QUALIFICATION_TYPES.map((opt, i) => (
                              <option key={i} value={opt.key}>
                                {opt.value}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].qualification`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Degree */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Degree
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(
                                educationTouched,
                                educationError,
                                "degree"
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].degree`}
                            values={edu.degree || ""}
                          />
                          <ErrorMessage
                            component="div"
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].degree`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Institution */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          Institution
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(
                                educationTouched,
                                educationError,
                                "institution"
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].institution`}
                            values={edu.institution || ""}
                          />
                          <ErrorMessage
                            component="div"
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].institution`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* PSG Alumni */}
                  <div className="row mb-2">
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          PSG Alumni
                        </label>
                        <div className="col-lg-6">
                          <div className="custom-control custom-checkbox">
                            <Field
                              type="checkbox"
                              className="custom-control custom-control-input"
                              id={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].alumni`}
                              checked={edu.alumni || false}
                              onChange={e => {
                                setFieldValue(
                                  `organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].alumni`,
                                  e.target.checked
                                );
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].alumni`}
                            />
                            Yes
                          </div>
                          <ErrorMessage
                            component="div"
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].alumni`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {/* Year of Passing */}
                    <div className="col-lg-6">
                      <div className="form-group row mb-0">
                        <label
                          className={`col-lg-4 col-form-label col-form-label-sm edit-page-label ${
                            edu.alumni ? "required" : ""
                          }`}
                        >
                          Year of Passing
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            className={`custom-select custom-select-sm ${
                              getError(educationTouched, educationError, "year")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].year`}
                            values={edu.year || ""}
                          >
                            <option value="">Select...</option>
                            {YEARS.map(yyyy => (
                              <option key={yyyy} value={yyyy}>
                                {yyyy}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].year`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Branch */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label
                          className={`col-lg-4 col-form-label col-form-label-sm edit-page-label ${
                            edu.alumni ? "required" : ""
                          }`}
                        >
                          Branch
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(
                                educationTouched,
                                educationError,
                                "branch"
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].branch`}
                            values={edu.branch || ""}
                          />
                          <ErrorMessage
                            component="div"
                            name={`organization.promoters[${pmtrIndex}].individual.education[${eduIndex}].branch`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const Phones = props => {
  const { pmtrIndex, indvError, indvTouched, individual } = props;

  const phones = individual.phones || [];
  return (
    <FieldArray
      name={`organization.promoters[${pmtrIndex}].individual.phones`}
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {phones.map((ph, phIndex) => {
              const phoneError =
                (indvError.phones && indvError.phones[phIndex]) || {};
              const phoneTouched =
                (indvTouched.phones && indvTouched.phones[phIndex]) || {};

              return (
                <Fragment key={phIndex}>
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                      Mobile
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(phoneTouched, phoneError, "phone")
                            ? "is-invalid"
                            : ""
                        }`}
                        name={`organization.promoters[${pmtrIndex}].individual.phones[${phIndex}].phone`}
                        values={individual.phone || ""}
                      />
                      <small>Please enter primary mobile number</small>
                      <ErrorMessage
                        component="div"
                        name={`organization.promoters[${pmtrIndex}].individual.phones[${phIndex}].phone`}
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const Emails = props => {
  const { pmtrIndex, indvError, indvTouched, individual } = props;

  const emails = individual.emails || [];
  return (
    <FieldArray
      name={`organization.promoters[${pmtrIndex}].individual.emails`}
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {emails.map((eml, emlIndex) => {
              const emailError =
                (indvError.emails && indvError.emails[emlIndex]) || {};
              const emailTouched =
                (indvTouched.emails && indvTouched.emails[emlIndex]) || {};

              return (
                <Fragment key={emlIndex}>
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                      Email
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="email"
                        className={`form-control form-control-sm ${
                          getError(emailTouched, emailError, "email")
                            ? "is-invalid"
                            : ""
                        }`}
                        name={`organization.promoters[${pmtrIndex}].individual.emails[${emlIndex}].email`}
                        values={eml.email || ""}
                      />
                      <small>Please enter primary email</small>
                      <ErrorMessage
                        component="div"
                        name={`organization.promoters[${pmtrIndex}].individual.emails[${emlIndex}].email`}
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const Addresses = props => {
  const {
    pmtrIndex,
    indvError,
    indvTouched,
    individual,
    setFieldValue,
    downloadDocument
  } = props;

  const addresses = individual.addresses || [];
  return (
    <FieldArray
      name={`organization.promoters[${pmtrIndex}].individual.addresses`}
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {addresses.map((address, addrIndex) => {
              const addrTouched =
                (indvTouched.addresses && indvTouched.addresses[addrIndex]) ||
                {};
              const addrError =
                (indvError.addresses && indvError.addresses[addrIndex]) || {};
              return (
                <Fragment key={addrIndex}>
                  <div className="row no-gutters">
                    <div className="col-12 mb-3 edit-page-subsection-header-text">
                      <span className="ml-3"> Address Information</span>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row mb-2">
                      {/* Street Line 1 */}
                      <div className="col-lg-6 mb-2">
                        <div className="form-group row mb-0">
                          <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                            Street Line 1
                          </label>
                          <div className="col-lg-6">
                            <Field
                              type="text"
                              className={`form-control form-control-sm ${
                                getError(addrTouched, addrError, "streetLine1")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].streetLine1`}
                              values={address.streetLine1 || ""}
                            />
                            <ErrorMessage
                              component="div"
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].streetLine1`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>

                      {/* Street Line 2 */}
                      <div className="col-lg-6 mb-2">
                        <div className="form-group row mb-0">
                          <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                            Street Line 2
                          </label>
                          <div className="col-lg-6">
                            <Field
                              type="text"
                              className={`form-control form-control-sm ${
                                getError(addrTouched, addrError, "streetLine2")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].streetLine2`}
                              values={address.streetLine2 || ""}
                            />
                            <ErrorMessage
                              component="div"
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].streetLine2`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>

                      {/* City */}
                      <div className="col-lg-6 mb-2">
                        <div className="form-group row mb-0">
                          <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                            City
                          </label>
                          <div className="col-lg-6">
                            <Field
                              type="text"
                              className={`form-control form-control-sm ${
                                getError(addrTouched, addrError, "city")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].city`}
                              values={address.city || ""}
                            />
                            <ErrorMessage
                              component="div"
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].city`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>

                      {/* Landmark */}
                      <div className="col-lg-6 mb-2">
                        <div className="form-group row mb-0">
                          <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                            Landmark
                          </label>
                          <div className="col-lg-6">
                            <Field
                              type="text"
                              className={`form-control form-control-sm ${
                                getError(addrTouched, addrError, "landmark")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].landmark`}
                              values={address.landmark || ""}
                            />
                            <ErrorMessage
                              component="div"
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].landmark`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>

                      {/* State */}
                      <div className="col-lg-6 mb-2">
                        <div className="form-group row mb-0">
                          <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                            State
                          </label>
                          <div className="col-lg-6">
                            <Field
                              component="select"
                              className={`form-control form-control-sm ${
                                getError(addrTouched, addrError, "state")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].state`}
                              values={address.state || ""}
                            >
                              <option value="">Select...</option>
                              {props.states.map((state, i) => (
                                <option key={i} value={state.stateCode}>
                                  {state.stateName}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              component="div"
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].state`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>

                      {/* Country */}
                      <div className="col-lg-6 mb-2">
                        <div className="form-group row mb-0">
                          <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                            Country
                          </label>
                          <div className="col-lg-6">
                            <Field
                              component="select"
                              className={`form-control form-control-sm ${
                                getError(addrTouched, addrError, "country")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].country`}
                              values={address.country || ""}
                            >
                              <option value="">Select...</option>
                              {props.countries.map((country, i) => (
                                <option key={i} value={country.countryCode}>
                                  {country.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              component="div"
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].country`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>

                      {/* Postal Code */}
                      <div className="col-lg-6 mb-2">
                        <div className="form-group row mb-0">
                          <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                            Postal Code
                          </label>
                          <div className="col-lg-6">
                            <Field
                              type="text"
                              className={`form-control form-control-sm ${
                                getError(addrTouched, addrError, "postalCode")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              inputMode="numeric"
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].postalCode`}
                              values={address.postalCode || ""}
                            />
                            <ErrorMessage
                              component="div"
                              name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].postalCode`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Address Proof */}
                    <div className="row mb-2">
                      <div className="col-lg-12 mb-0">
                        <div className="form-group row mb-0">
                          <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label">
                            Address Proof (Driving License, Aadhaar)
                          </label>
                          <div className="col-lg-6">
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].uploadAddressProof`}
                                  name={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].uploadAddressProof`}
                                  onChange={e => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      setFieldValue(
                                        `organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].uploadAddressProof`,
                                        file
                                      );
                                    }
                                    e.target.value = "";
                                  }}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor={`organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].uploadAddressProof`}
                                >
                                  {address.uploadAddressProof
                                    ? address.uploadAddressProof.name
                                    : "Choose file"}
                                </label>
                              </div>
                              <div className="input-group-append">
                                <button
                                  className="btn btn-sm btn-outline-info"
                                  type="button"
                                  onClick={e =>
                                    setFieldValue(
                                      `organization.promoters[${pmtrIndex}].individual.addresses[${addrIndex}].uploadAddressProof`,
                                      ""
                                    )
                                  }
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            {address.addressProof ? (
                              <button
                                type="button"
                                className="btn btn-info btn-sm mt-1"
                                onClick={downloadDocument.bind(
                                  this,
                                  address.addressProof
                                )}
                              >
                                <i className="fas fa-download mr-1" /> Address
                                Proof
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const IdentificationDocs = props => {
  const {
    pmtrIndex,
    indvError,
    indvTouched,
    individual,
    setFieldValue,
    downloadDocument
  } = props;

  const idDocs = individual.identificationDocs || [];

  return (
    <Fragment>
      {/* Contact Info */}
      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-subsection-header-text">
          <span className="ml-3"> Identification Documents </span>
        </div>
      </div>
      <div className="card-body mb-4">
        {idDocs.map((idDoc, idDocIndex) => {
          const idDocError =
            (indvError.identificationDocs &&
              indvError.identificationDocs[idDocIndex]) ||
            {};
          const idDocTouched =
            (indvTouched.identificationDocs &&
              indvTouched.identificationDocs[idDocIndex]) ||
            {};
          return (
            <Fragment key={idDocIndex}>
              {/* Aadhaar */}
              {idDoc.type === "AADHAAR" ? (
                <div className="row mb-2">
                  <div className="col-lg-4">
                    <div className="form-group row mb-0">
                      <label className="col-form-label col-lg-4 col-xl-4 edit-page-label required">
                        Aadhaar
                      </label>
                      <div className="col-lg-8 col-xl-8">
                        <Field
                          type="text"
                          className={`form-control form-control-sm ${
                            getError(idDocTouched, idDocError, "number")
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="Aadhaar Number"
                          name={`organization.promoters[${pmtrIndex}].individual.identificationDocs[${idDocIndex}].number`}
                          value={idDoc.number}
                        />
                        <ErrorMessage
                          component="div"
                          name={`organization.promoters[${pmtrIndex}].individual.identificationDocs[${idDocIndex}].number`}
                          className="invalid-feedback mb-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group row mb-0">
                      <label className="col-form-label col-lg-4 edit-page-label required">
                        Aadhaar Scanned Copy
                      </label>
                      <div className="col-lg-7">
                        <div
                          className={`input-group ${
                            getError(idDocTouched, idDocError, "uploadAadhaar")
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <div className="custom-file">
                            <input
                              type="file"
                              className={`custom-file-input ${
                                getError(
                                  idDocTouched,
                                  idDocError,
                                  "uploadAadhaar"
                                )
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id={`organization.promoters[${pmtrIndex}].individual.identificationDocs[${idDocIndex}].uploadAadhaar`}
                              onChange={e => {
                                const file = e.target.files[0];
                                if (file) {
                                  setFieldValue(
                                    `organization.promoters[${pmtrIndex}].individual.identificationDocs[${idDocIndex}].uploadAadhaar`,
                                    file
                                  );
                                }
                                e.target.value = "";
                              }}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor={`organization.promoters[${pmtrIndex}].individual.identificationDocs[${idDocIndex}].uploadAadhaar`}
                            >
                              {idDoc.uploadAadhaar
                                ? idDoc.uploadAadhaar.name
                                : "Choose file"}
                            </label>
                          </div>
                          <div className="input-group-append">
                            <button
                              className="btn btn-sm btn-outline-info"
                              type="button"
                              onClick={e =>
                                setFieldValue(
                                  `organization.promoters[${pmtrIndex}].individual.identificationDocs[${idDocIndex}].uploadAadhaar`,
                                  ""
                                )
                              }
                            >
                              Reset
                            </button>
                          </div>
                          <ErrorMessage
                            component="div"
                            name={`organization.promoters[${pmtrIndex}].individual.identificationDocs[${idDocIndex}].uploadAadhaar`}
                            className="invalid-feedback d-block mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="row mb-0">
                      <div className="col-12">
                        {idDoc.document ? (
                          <button
                            className="btn btn-sm btn-info"
                            onClick={downloadDocument.bind(
                              this,
                              idDoc.document
                            )}
                          >
                            <i className="fas fa-download mr-1" /> Aadhaar copy
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

const Promoters = props => {
  const { values, touched, errors, setFieldValue, downloadDocument } = props;
  const orgErrors = errors.organization || {};
  const orgTouched = touched.organization || {};

  const organization = values.organization || {};
  const promoters = organization.promoters || [];

  return (
    <div className="row p-0 m-0 edit-page-subsection mb-4">
      <div className="col-md-12">
        <FieldArray
          name="organization.promoters"
          render={({ insert, remove, push }) => {
            return (
              <Fragment>
                {promoters.map((promoter, pmtrIndex) => {
                  const promoterError =
                    (orgErrors.promoters && orgErrors.promoters[pmtrIndex]) ||
                    {};
                  const promoterTouched =
                    (orgTouched.promoters && orgTouched.promoters[pmtrIndex]) ||
                    {};

                  const individual = promoter.individual || {};
                  const indvError = promoterError.individual || {};
                  const indvTouched = promoterTouched.individual || {};

                  const showAddButton =
                    values.organization.promoters.length - 1 === pmtrIndex;

                  const showRemoveButton =
                    values.organization.promoters.length > 1;

                  return (
                    <Fragment key={pmtrIndex}>
                      <div className="card ml-4 mr-4 mt-4 mb-4">
                        <div className="card-header edit-page-subsection-title border-0">
                          <div className="card-header edit-page-subsection-title border-0">
                            {showRemoveButton ? (
                              <button
                                type="button"
                                className="close pull-right mt-1"
                                aria-label="Close"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={`Remove Promoter ${pmtrIndex + 1}`}
                                onClick={() => remove(pmtrIndex)}
                                style={{ color: "#ff0000" }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            ) : null}
                            <h6 className="card-title  mb-0">
                              Promoter {pmtrIndex + 1}
                            </h6>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 mb-3 edit-page-subsection-header-text">
                              Basic Info
                            </div>
                          </div>
                          <div className="row mb-2">
                            {/* Name */}
                            <div className="col-lg-6 mb-2">
                              <div className="form-group row mb-0">
                                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                                  Name
                                </label>
                                <div className="col-lg-6">
                                  <Field
                                    type="text"
                                    // className="form-control form-control-sm"
                                    className={`form-control form-control-sm ${
                                      getError(
                                        indvTouched,
                                        indvError,
                                        "firstName"
                                      )
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`organization.promoters[${pmtrIndex}].individual.firstName`}
                                    values={individual.firstName || ""}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`organization.promoters[${pmtrIndex}].individual.firstName`}
                                    className="invalid-feedback mb-2"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Gender */}
                            <div className="col-lg-6 mb-2">
                              <div className="form-group row mb-0">
                                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                                  Gender
                                </label>
                                <div className="col-lg-6">
                                  <Field
                                    component="select"
                                    // className="custom-select custom-select-sm"
                                    className={`custom-select custom-select-sm ${
                                      getError(indvTouched, indvError, "gender")
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`organization.promoters[${pmtrIndex}].individual.gender`}
                                    values={individual.gender || ""}
                                  >
                                    <option value="">Select...</option>
                                    {GENDERS.map((g, i) => (
                                      <option key={i} value={g.value}>
                                        {g.label}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    component="div"
                                    name={`organization.promoters[${pmtrIndex}].individual.gender`}
                                    className="invalid-feedback mb-2"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Experience */}
                            <div className="col-lg-6 mb-2">
                              <div className="form-group row mb-0">
                                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                                  Experience
                                </label>
                                <div className="col-lg-6">
                                  <Field
                                    type="text"
                                    // className="form-control form-control-sm"
                                    className={`form-control form-control-sm ${
                                      getError(
                                        indvTouched,
                                        indvError,
                                        "experience"
                                      )
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`organization.promoters[${pmtrIndex}].individual.experience`}
                                    values={individual.experience || ""}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`organization.promoters[${pmtrIndex}].individual.experience`}
                                    className="invalid-feedback mb-2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Photo */}
                          <div className="row">
                            <div className="col-lg-12 mb-2">
                              <div className="form-group row mb-0">
                                <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label required mt-1">
                                  Photo
                                </label>
                                <div className="col-lg-6">
                                  <div
                                    className={`input-group ${
                                      getError(
                                        indvTouched,
                                        indvError,
                                        "uploadPhoto"
                                      )
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                  >
                                    <div className="custom-file">
                                      <input
                                        type="file"
                                        className={`custom-file-input ${
                                          getError(
                                            indvTouched,
                                            indvError,
                                            "uploadPhoto"
                                          )
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        id={`organization.promoters[${pmtrIndex}].individual.uploadPhoto`}
                                        name={`organization.promoters[${pmtrIndex}].individual.uploadPhoto`}
                                        onChange={e => {
                                          const file = e.target.files[0];
                                          if (file) {
                                            setFieldValue(
                                              `organization.promoters[${pmtrIndex}].individual.uploadPhoto`,
                                              file
                                            );
                                          }
                                          e.target.value = "";
                                        }}
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor={`organization.promoters[${pmtrIndex}].individual.uploadPhoto`}
                                      >
                                        {individual.uploadPhoto
                                          ? individual.uploadPhoto.name
                                          : "Choose file"}
                                      </label>
                                    </div>
                                    <div className="input-group-append">
                                      <button
                                        className="btn btn-sm btn-outline-info"
                                        type="button"
                                        onClick={e =>
                                          setFieldValue(
                                            `organization.promoters[${pmtrIndex}].individual.uploadPhoto`,
                                            ""
                                          )
                                        }
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    component="div"
                                    name={`organization.promoters[${pmtrIndex}].individual.uploadPhoto`}
                                    className="invalid-feedback d-block mb-2"
                                  />
                                </div>
                                <div className="col-lg-2">
                                  {individual.photo ? (
                                    <button
                                      type="button"
                                      className="btn btn-info btn-sm mt-1"
                                      onClick={downloadDocument.bind(
                                        this,
                                        individual.photo
                                      )}
                                    >
                                      <i className="fas fa-download mr-1" />
                                      Photo
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Education */}
                        <Education
                          pmtrIndex={pmtrIndex}
                          indvError={indvError}
                          indvTouched={indvTouched}
                          individual={individual}
                          setFieldValue={setFieldValue}
                        />
                        {/* Shareholding Info */}
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12 mb-3 edit-page-subsection-header-text">
                              Shareholding Info
                            </div>
                          </div>
                          <div className="row mb-2">
                            {/* Founder */}
                            <div className="col-lg-6 mb-2">
                              <div className="form-group row mb-0">
                                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                                  Founder
                                </label>
                                <div className="col-lg-6">
                                  <div className="custom-control custom-checkbox">
                                    <Field
                                      type="checkbox"
                                      className="custom-control custom-control-input"
                                      id={`organization.promoters[${pmtrIndex}].isFounder`}
                                      name="subsidiary"
                                      checked={promoter.isFounder || false}
                                      onChange={e => {
                                        setFieldValue(
                                          `organization.promoters[${pmtrIndex}].isFounder`,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`organization.promoters[${pmtrIndex}].isFounder`}
                                    />
                                    Yes
                                  </div>
                                  <ErrorMessage
                                    component="div"
                                    name={`organization.promoters[${pmtrIndex}].isFounder`}
                                    className="invalid-feedback mb-2"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Sharehold Percentage  */}
                            <div className="col-lg-6 mb-2">
                              <div className="form-group row mb-0">
                                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                                  Sharehold Percentage
                                </label>
                                <div className="col-lg-6">
                                  <Field
                                    type="text"
                                    // className="form-control form-control-sm"
                                    className={`form-control form-control-sm ${
                                      getError(
                                        promoterTouched,
                                        promoterError,
                                        "shareholding"
                                      )
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`organization.promoters[${pmtrIndex}].shareholding`}
                                    values={promoter.shareholding || ""}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`organization.promoters[${pmtrIndex}].shareholding`}
                                    className="invalid-feedback mb-2"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Designation  */}
                            <div className="col-lg-6 mb-2">
                              <div className="form-group row mb-0">
                                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                                  Designation
                                </label>
                                <div className="col-lg-6">
                                  <Field
                                    type="text"
                                    // className="form-control form-control-sm"
                                    className={`form-control form-control-sm ${
                                      getError(
                                        promoterTouched,
                                        promoterError,
                                        "designation"
                                      )
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`organization.promoters[${pmtrIndex}].designation`}
                                    values={promoter.designation || ""}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`organization.promoters[${pmtrIndex}].designation`}
                                    className="invalid-feedback mb-2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Contact Info */}
                        <div className="row no-gutters">
                          <div className="col-12 mb-3 edit-page-subsection-header-text">
                            <span className="ml-3"> Contact Information</span>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row mb-2">
                            {/* Phones */}
                            <div className="col-lg-6 mb-2">
                              <Phones
                                pmtrIndex={pmtrIndex}
                                indvError={indvError}
                                indvTouched={indvTouched}
                                individual={individual}
                              />
                            </div>

                            {/* Emails */}
                            <div className="col-lg-6 mb-2">
                              <Emails
                                pmtrIndex={pmtrIndex}
                                indvError={indvError}
                                indvTouched={indvTouched}
                                individual={individual}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Address Info */}
                        <Addresses
                          pmtrIndex={pmtrIndex}
                          indvError={indvError}
                          indvTouched={indvTouched}
                          individual={individual}
                          setFieldValue={setFieldValue}
                          states={props.states}
                          countries={props.countries}
                          downloadDocument={downloadDocument}
                        />
                        {/* IdentificationDocs Info */}
                        <IdentificationDocs
                          pmtrIndex={pmtrIndex}
                          indvError={indvError}
                          indvTouched={indvTouched}
                          individual={individual}
                          setFieldValue={setFieldValue}
                          downloadDocument={downloadDocument}
                        />
                      </div>
                      <div className="form-group row mt-3 mb-0 text-center">
                        <div className="col-12">
                          <ShowHideComponent show={showAddButton}>
                            <button
                              type="button"
                              className="btn btn-sm btn-outline-primary mb-3"
                              onClick={() =>
                                push({
                                  individual: {
                                    firstName: "",
                                    gender: "",
                                    experience: "",
                                    education: [
                                      {
                                        qualification: "",
                                        degree: "",
                                        institution: "",
                                        alumni: false,
                                        branch: "",
                                        year: ""
                                      }
                                    ],
                                    references: "",
                                    indvPhoto: "",
                                    uploadPhoto: "",
                                    addresses: [
                                      {
                                        uploadAddressProof: "",
                                        // addressProof: "",
                                        streetLine1: "",
                                        streetLine2: "",
                                        landmark: "",
                                        city: "",
                                        state: "",
                                        postalCode: "",
                                        country: "",
                                        status: "",
                                        type: ""
                                      }
                                    ],
                                    identificationDocs: [
                                      {
                                        status: "ACTIVE",
                                        type: "AADHAAR",
                                        number: "",
                                        uploadAadhaar: ""
                                      },
                                      {
                                        status: "ACTIVE",
                                        type: "PAN",
                                        number: "",
                                        uploadPan: ""
                                      }
                                    ],
                                    emails: [{ email: "" }],
                                    phones: [{ phone: "" }]
                                  },
                                  designation: "",
                                  shareholding: "",
                                  isFounder: false
                                })
                              }
                            >
                              Add More
                            </button>
                          </ShowHideComponent>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Promoters;
