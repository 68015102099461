import React, { Component, Fragment } from "react";
import HttpUtil from "../../common/HttpUtil";
import DatePicker from "react-datepicker";
import { Formik, Form, Field } from "formik";
import { GRANT_AWARDS_API } from "../../common/Constants";
import {
  convertNumberToDate,
  getDisplayDate,
  convertDateToNumber
} from "../../lease/DateUtil";
import AlertComponent from "../../common/AlertComponent";
import { GRANT_AWARD_CLOSURE_STATUS_REASONS } from "../../common/LookupConstants";
import { ErrorComponent, getErrorCssClassName } from "../../common/FormikUtil";
import ShowHideComponent from "../../widgets/ShowHideComponent";
import { DocumentTemplate } from "../award/DocumentTemplate";
import { ChecklistTemplate } from "../award/ChecklistTemplate";
import Patents from "../award/Patents";
import { validate } from "../award/GrantAwardReviewForm";
import { GrantOverview } from "./grant-widgets/GrantOverview";
import Breadcrumb from "../../common/Breadcrumb";
import Link from "react-router-dom/Link";
import { Redirect } from "react-router";

function getFieldName(stringValue) {
  if (!stringValue) {
    return null;
  }
  let text = stringValue.toLowerCase();
  text = text.split(" ");
  let name = "";
  for (let i = 1; i < text.length; i++) {
    let txt = text[i];
    txt = txt.charAt(0).toUpperCase() + txt.substring(1);
    text[i] = txt;
  }
  name = text.join("");
  return name;
}

const AddtionalInformation = props => {
  const { values, touched, errors, setFieldValue } = props;
  return (
    <Fragment>
      <div className="form-group row mb-1">
        <label className="col-sm-5 col-form-label col-form-label-sm edit-page-label">
          Trademark Registered ?
        </label>
        <div className="col-sm-7">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
              name="trademarkRegistered"
              checked={values.trademarkRegistered || ""}
            />
            <label
              className="custom-control-label edit-page-label"
              htmlFor="customCheck1"
            >
              Yes
            </label>
          </div>
        </div>
      </div>

      <ShowHideComponent show={values.trademarkRegistered}>
        <div className="form-group row">
          <label className="col-sm-5 col-form-label col-form-label-sm edit-page-label-required">
            Trademark Name
          </label>
          <div className="col-sm-7">
            <Field
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touched,
                errors,
                "trademarkName"
              )}`}
              name="trademarkName"
              value={values.trademarkName || ""}
            />

            <ErrorComponent
              fieldName="trademarkName"
              touched={touched}
              error={errors}
            />
          </div>
        </div>
      </ShowHideComponent>

      <ShowHideComponent show={values.granteeType !== "Organization"}>
        <div className="form-group row mb-1">
          <label className="col-sm-5 col-form-label col-form-label-sm edit-page-label">
            Company is Formed ?
          </label>
          <div className="col-sm-7">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="customCheck2"
                name="companyFormed"
                checked={values.companyFormed || ""}
              />
              <label
                className="custom-control-label edit-page-label"
                htmlFor="customCheck2"
              >
                Yes
              </label>
            </div>
            <ErrorComponent
              fieldName="companyFormed"
              touched={touched}
              error={errors}
            />
          </div>
        </div>

        <ShowHideComponent show={values.companyFormed}>
          <div className="form-group row mb-1">
            <label className="col-sm-5 col-form-label col-form-label-sm edit-page-label">
              After Grant Award ?
            </label>
            <div className="col-sm-7">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck3"
                  name="afterGrantAward"
                  checked={values.afterGrantAward || ""}
                />
                <label
                  className="custom-control-label edit-page-label"
                  htmlFor="customCheck3"
                >
                  Yes
                </label>
              </div>
              <ErrorComponent
                fieldName="afterGrantAward"
                touched={touched}
                error={errors}
              />
            </div>
          </div>
          <div className="form-group row mb-1">
            <label className="col-sm-5 col-form-label col-form-label-sm edit-page-label">
              Name of Company
            </label>
            <div className="col-sm-7">
              <Field
                type="text"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "companyName"
                )}`}
                name="companyName"
                value={values.companyName || ""}
              />

              <ErrorComponent
                fieldName="companyName"
                touched={touched}
                error={errors}
              />
            </div>
          </div>
          <div className="form-group row mb-1">
            <label className="col-sm-5 col-form-label col-form-label-sm edit-page-label">
              Date of Incorporation
            </label>
            <div className="col-sm-7">
              <DatePicker
                value={
                  values.dateOfIncorporation !== null
                    ? getDisplayDate(values.dateOfIncorporation)
                    : ""
                }
                selected={values.dateOfIncorporation || null}
                onChange={e => {
                  setFieldValue(`dateOfIncorporation`, e);
                }}
                utcOffset={0}
                placeholderText="dd-mm-yyyy"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "dateOfIncorporation"
                )}`}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />

              <ErrorComponent
                fieldName="dateOfIncorporation"
                touched={touched}
                error={errors}
                datePicker={true}
              />
            </div>
          </div>
        </ShowHideComponent>
      </ShowHideComponent>
    </Fragment>
  );
};

const FieldInformations = props => {
  const { values, template, touched, errors } = props;

  const fieldTemplates = template.fieldTemplates || [];
  const closureAddlInfo = values.closureAddlInfo || {};

  const touchClosureAddlInfo = touched.closureAddlInfo || {};
  const errorClosureAddlInfo = errors.closureAddlInfo || {};
  return (
    <Fragment>
      {fieldTemplates.map((fldTemplate, index) => {
        const name = getFieldName(fldTemplate.name);
        closureAddlInfo[name] = closureAddlInfo[name] || "";
        return (
          <div key={index} className="form-group row mb-1">
            <label className="col-sm-4 col-form-label col-form-label-sm edit-page-label">
              {fldTemplate.name}
            </label>
            <div className="col-sm-8">
              <Field
                type={fldTemplate.type}
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touchClosureAddlInfo,
                  errorClosureAddlInfo,
                  `${name}`
                )}`}
                name={`closureAddlInfo.${name}`}
                value={closureAddlInfo[name] || ""}
              />
              <ErrorComponent
                fieldName={`${name}`}
                touched={touchClosureAddlInfo}
                error={errorClosureAddlInfo}
              />
            </div>
          </div>
        );
      })}

      {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
        <AddtionalInformation {...props} />
      </div> */}

      {/* <label className="col-sm-2 col-form-label col-form-label-sm edit-page-label mt-2">
          About History 1
        </label>
        <div className="col-sm-4 mt-2">
          <Field
            type="text"
            className="form-control form-control-sm"
            name="about"
          />
          <ErrorComponent
            fieldName="completed"
            touched={touched}
            error={errors}
          />
        </div>
        <label className="col-sm-2 col-form-label col-form-label-sm edit-page-label mt-2">
          About History 2
        </label>
        <div className="col-sm-4 mt-2">
          <Field
            type="text"
            className="form-control form-control-sm"
            name="about"
          />
        </div>
        <label className="col-sm-2 col-form-label col-form-label-sm edit-page-label mt-2">
          About History 3
        </label>
        <div className="col-sm-4 mt-2">
          <Field
            type="text"
            className="form-control form-control-sm"
            name="about"
          />
        </div> */}
    </Fragment>
  );
};

const ClosureInformation = props => {
  const { values, setFieldValue, touched, errors } = props;
  return (
    <div className="pl-3 pr-3">
      <strong className="edit-page-section-header-text d-block">
        Closure Information
      </strong>

      <div className="form-group row mb-2 mt-2">
        <label className="col-form-label edit-page-label-required col-form-label-sm col-12 col-lg-4 col-xl-4 pr-0">
          Status
        </label>
        <div className="col-12 col-lg-8 col-xl-8 edit-page-label">Closed</div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
          Completed Successfully
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              name="completed"
              id="completed"
              className="custom-control-input"
              onChange={e => {
                setFieldValue("completed", e.target.checked);
              }}
              value={values.completed}
              checked={values.completed}
            />

            <label
              className="custom-control-label edit-page-label edit-page-label"
              htmlFor="completed"
            >
              Yes
            </label>
          </div>
          <ErrorComponent
            fieldName="completed"
            touched={touched}
            error={errors}
          />
        </div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
          Status Reason
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <Field
            component="select"
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "statusReason"
            )}`}
            name="statusReason"
            value={values.statusReason || ""}
          >
            <option value="">Select...</option>
            {GRANT_AWARD_CLOSURE_STATUS_REASONS.map((clsr, i) => (
              <option key={i} value={clsr.key}>
                {clsr.value}
              </option>
            ))}
          </Field>

          <ErrorComponent
            fieldName="statusReason"
            touched={touched}
            error={errors}
          />
        </div>
      </div>
      <ShowHideComponent show={values.statusReason === "OTHER"}>
        <div className="form-group row mb-2">
          <label className="col-form-label edit-page-label-required col-form-label-sm col-12 col-lg-4 col-xl-4">
            Other Reason
          </label>
          <div className="col-12 col-lg-8 col-xl-8">
            <Field
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touched,
                errors,
                "otherStatusReason"
              )}`}
              name="otherStatusReason"
              value={values.otherStatusReason || ""}
            />

            <ErrorComponent
              fieldName="otherStatusReason"
              touched={touched}
              error={errors}
            />
          </div>
        </div>
      </ShowHideComponent>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
          Closure Date
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <DatePicker
            value={
              values.grantClosureDate !== null
                ? getDisplayDate(values.grantClosureDate)
                : ""
            }
            selected={values.grantClosureDate}
            onChange={e => {
              setFieldValue(`grantClosureDate`, e);
            }}
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "grantClosureDate"
            )}`}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />

          <ErrorComponent
            fieldName="grantClosureDate"
            touched={touched}
            error={errors}
            datePicker={true}
          />
        </div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
          Notes
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <Field
            component="textarea"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "notes"
            )}`}
            name="notes"
            rows="6"
          />

          <ErrorComponent fieldName="notes" touched={touched} error={errors} />
        </div>
      </div>
    </div>
  );
};

function getDocumentTypes(template) {
  const documents = (template && template.documents) || [];
  const docTypes = documents.map(d => d.documentType);
  return docTypes;
}

function getCheckListLabel(template) {
  return (template && template.checklist) || [];
}

const CustomForm = props => {
  const { values, errors, touched, setFieldValue } = props;
  const docTypes = getDocumentTypes(props.template);
  return (
    <Form>
      <div className="card-body pt-0">
        <div className="row mt-5 mb-3">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
            <ClosureInformation {...props} />
          </div>

          {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <AddtionalInformation {...props} />
          </div> */}
        </div>

        {/* <div className="row mt-5 mb-3">
          <FieldInformations {...props} />
        </div> */}

        <div className="row mt-5 mb-3">
          <div className="col-12 pl-3 pr-3">
            <div className="edit-page-section-header-text d-block mb-3">
              <div className="">Addtional Information</div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <div className="pl-3 pr-3">
              <AddtionalInformation {...props} />
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <div className="pl-3 pr-3">
              <FieldInformations {...props} />
            </div>
          </div>
        </div>

        <div className="row mt-5 mb-3">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <div className="pl-3 pr-3">
              <ChecklistTemplate
                values={values}
                errors={errors}
                uniqueKey={values._id || "New"}
              />
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <div className="pl-3 pr-3">
              <DocumentTemplate
                values={values}
                errors={errors}
                documentTypes={docTypes}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
        </div>

        <div className="row mt-5 mb-3">
          <div className="col-12  p-0">
            <div className="pl-3 pr-3">
              <Patents
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card-footer text-center pb-3">
        <Link
          to={`/funding/awards/progress/${values._id}`}
          className="btn btn-sm btn-outline-secondary mr-3"
        >
          Cancel
        </Link>
        <button className="btn btn-sm btn-primary" type="submit">
          Close Grant
        </button>
      </div>
    </Form>
  );
};

const INITIAL_VALUES = {
  statusReason: "",
  completed: false,
  otherStatusReason: "",
  grantClosureDate: null,
  notes: "",
  documents: [],
  availableDocuments: [],
  documentName: "",
  documentType: "",
  checklist: [],
  closureAddlInfo: "",
  trademarkRegistered: false,
  trademarkName: "",

  companyFormed: false,
  afterGrantAward: false,
  companyName: "",
  dateOfIncorporation: "",

  patents: [
    {
      country: "",
      applicationNumber: "",
      applicationFilingDate: "",
      fieldOfInvention: "",
      classificationCode: "",
      patentStatus: ""
    }
  ]
};

function validateAddlInfo(errors, values, template) {
  const fieldTemplates = template.fieldTemplates || [];
  const fieldTmpltsIds = {};
  const NAME_MAPPER = {};
  fieldTemplates.forEach(itms => {
    const name = getFieldName(itms.name);
    fieldTmpltsIds[name] = itms.required;
    NAME_MAPPER[name] = itms.name;
  });

  const closureAddlInfoErrors = {};
  const closureAddlInfo = values.closureAddlInfo || {};
  for (let key in closureAddlInfo) {
    if (fieldTmpltsIds[key] && !closureAddlInfo[key]) {
      closureAddlInfoErrors[key] = `${NAME_MAPPER[key]} is required field.`;
    }
  }

  const hasError = Object.values(closureAddlInfoErrors).some(itm => itm);
  if (hasError) {
    errors.closureAddlInfo = closureAddlInfoErrors;
  }
}

function validateFn(values, template) {
  const errors = validate(values, template);
  validateAddlInfo(errors, values, template);

  if (!values.statusReason) {
    errors.statusReason = "Reason is required field";
  }

  if (!values.grantClosureDate) {
    errors.grantClosureDate = "Closure Date is required field";
  }

  if (values.statusReason === "OTHER") {
    errors.otherStatusReason = "Status Reason is required field";
  }

  if (!values.notes) {
    errors.notes = "Notes is required field";
  }

  if (values.trademarkRegistered) {
    if (!values.trademarkName) {
      errors.trademarkName = "Trademark Name is required field";
    }
  }

  return errors;
}

class GrantClosure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      award: {
        ...INITIAL_VALUES
      },
      template: "",
      breadCrumbList: [],
      previousLocation: ""
    };
  }

  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAward = id => {
    const url = `${GRANT_AWARDS_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        const award = { ...this.state.award };

        const breadCrumbList = [];

        const awardList = {
          displayName: "Awards",
          linkTo: `/funding/awards`
        };
        breadCrumbList.push(awardList);

        const grantee = data.grantee;
        const names = [];

        if (grantee.firstName) {
          names.push(grantee.firstName);
        }
        if (grantee.lastName) {
          names.push(grantee.lastName);
        }
        if (grantee.name) {
          names.push(grantee.name);
        }
        const project = {
          displayName: `${names.join(" ")} - ${data.projectTitle}`,
          linkTo: `/funding/awards/progress/${data._id}`
        };
        breadCrumbList.push(project);
        const previousLocation = `/funding/awards/progress/${data._id}`;

        const closureList = {
          displayName: "Closure",
          linkTo: null
        };
        breadCrumbList.push(closureList);

        award._id = data._id || "";
        award.applicationFormId = data.applicationFormId || "";
        award.incubatee = data.incubatee || "";
        award.projectTitle = data.projectTitle || "";
        award.grantRef = data.grantRef || "";
        award.granteeType = data.granteeType || "";
        award.grantee = data.grantee || "";
        award.vertical = data.vertical || "";
        award.sanctionedAmount = data.sanctionedAmount || "";
        award.disbursedAmounts = data.disbursedAmounts || [];
        award.amountDisburesd = award.disbursedAmounts.reduce(
          (agg, value) => agg + value.amount,
          0
        );
        award.startDate = convertNumberToDate(data.startDate) || null;
        award.endDate = convertNumberToDate(data.endDate) || null;
        award.revisedEndDate = convertNumberToDate(data.revisedEndDate) || null;
        award.status = data.status || "";
        award.closureAddlInfo = data.closureAddlInfo || "";
        // Used as input field. So no need to load data.
        // Documents from the existing record is available in availableDocuments
        award.availableDocuments = data.closureDocuments || [];
        award.documents = [];
        // award.notes = data.notes || []; // wip#

        if (data.patents && data.patents.length > 0) {
          award.patents = data.patents.map(pt => {
            const patent = {};
            patent.country = pt.country;
            patent.applicationNumber = pt.applicationNumber;
            patent.applicationFilingDate = convertNumberToDate(
              pt.applicationFilingDate
            );
            patent.fieldOfInvention = pt.fieldOfInvention;
            patent.classificationCode = pt.classificationCode;
            patent.patentStatus = pt.patentStatus;
            return patent;
          });
        }

        award.reviews = data.reviews || [];

        award.grantRef = data.grantRef || "";

        let closureTemplate = {};
        let checklist = [];
        if (data.grantRef.grantProgressTemplate) {
          const grantProgressTemplate = data.grantRef.grantProgressTemplate;
          closureTemplate = grantProgressTemplate.closureTemplate;
        }

        if (data.closureChecklist && data.closureChecklist.length > 0) {
          award.checklist = data.closureChecklist;
        } else {
          checklist = getCheckListLabel(closureTemplate);
          award.checklist = checklist;
        }

        award.trademarkRegistered = data.trademarkRegistered || false;
        award.trademarkName = data.trademarkName || "";
        award.companyFormed = data.companyFormed || false;
        award.afterGrantAward = data.afterGrantAward || false;
        award.companyName = data.companyName || "";
        if (data.dateOfIncorporation) {
          award.dateOfIncorporation = convertNumberToDate(
            data.dateOfIncorporation
          );
        }

        if (data.grantClosureDate) {
          award.grantClosureDate = convertNumberToDate(data.grantClosureDate);
        }

        award.completed = data.completed || false;
        award.statusReason = data.statusReason || "";

        this.setState({
          award,
          template: closureTemplate,
          breadCrumbList,
          previousLocation
        });
      },
      data => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getPatents = patents => {
    let allPatents = patents || [];
    let newPatents = [];
    for (let i = 0; i < allPatents.length; i++) {
      let pt = allPatents[i];
      let hasData = Object.values(pt).some(itm => itm);

      let patent = {};
      patent.country = pt.country;
      patent.applicationNumber = pt.applicationNumber;
      let date = new Date(pt.applicationFilingDate);
      date = convertDateToNumber(date);
      patent.applicationFilingDate = date;
      patent.fieldOfInvention = pt.fieldOfInvention;
      patent.classificationCode = pt.classificationCode;
      patent.patentStatus = pt.patentStatus;

      if (hasData) {
        newPatents.push(patent);
      }
    }
    return newPatents;
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  onClosure = (values, { setSubmitting }) => {
    setSubmitting(true);

    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}/close`;

    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "closureDocuments", docs[i].document);
    }

    const patents = this.getPatents(values.patents);

    const closureRequest = {
      completed: values.completed,
      grantClosureDate: convertDateToNumber(values.grantClosureDate),
      statusReason: values.statusReason,
      otherStatusReason: values.otherStatusReason || undefined,
      notes: values.notes,
      closureChecklist: values.checklist,
      closureDocuments: docs.map(d => {
        return { name: d.name, type: d.type };
      }),

      trademarkRegistered: values.trademarkRegistered,
      trademarkName: values.trademarkName,

      companyFormed: values.companyFormed,
      afterGrantAward: values.afterGrantAward,
      companyName: values.companyName,
      dateOfIncorporation: convertDateToNumber(values.dateOfIncorporation),
      patents,
      closureAddlInfo: values.closureAddlInfo
    };

    formData.append("message", JSON.stringify(closureRequest));

    HttpUtil.post(
      url,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.getAward(id);
        this.setState({
          closure: false,
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `"${
            this.state.award.projectTitle
          }" has been closed successfully.`,
          redirect: true
        });
        window.scrollTo(0, 0);
      },

      data => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      let awardId = this.props.match.params.id;
      this.getAward(awardId);
    }
    window.scrollTo(0, 0);
  };

  render() {
    const { award, previousLocation } = this.state;

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      alertWithTitle: this.state.alertWithTitle,
      confirm:
        this.state.alertType === "Confirmation"
          ? this.state.confirm
          : undefined,
      close: this.closeDefaultAlert
    };

    const props = {
      initialValues: this.state.award,
      template: this.state.template,
      onClosure: this.onClosure
    };

    if (this.state.redirect) {
      const url = `/funding/awards/progress/${this.props.match.params.id}`;
      return <Redirect to={url} />;
    }

    return (
      <Fragment>
        <Breadcrumb breadCrumbList={this.state.breadCrumbList} />

        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="card edit-page-container mb-5">
          <div className="card-header border-0 text-center">
            <Link
              to={previousLocation}
              className="badge bg-white custom-btn-sm float-left"
            >
              <i className="fas fa-arrow-left mr-3" />
            </Link>
            <span className="card-title ">
              <span
                className="edit-page-title"
                style={{ color: "rgb(100, 122, 203)" }}
              >
                {`Grant Closure -${
                  award.projectTitle ? award.projectTitle : "Loading ..."
                }` || "--"}
                {/* <small> {`(${award.applicationFormId})`}</small> */}
              </span>
            </span>
          </div>

          <div className="card-body pt-0 pb-0">
            <GrantOverview award={this.state.award} />
          </div>

          <Formik
            enableReinitialize
            initialValues={props.initialValues}
            validate={values => validateFn(values, props.template)}
            onSubmit={(values, { setSubmitting }) => {
              this.setState({
                alertType: "Confirmation",
                showAlert: true,
                alertColor: "danger",
                alertMessage: `Grant Award once closed can't be reopened again. Do you want to continue ?`,
                alertWithTitle: "Grant Award Closure",
                confirm: () => props.onClosure(values, { setSubmitting })
              });
              // props.onClosure(values, { setSubmitting });
            }}
            render={renderProps => <CustomForm {...props} {...renderProps} />}
          />
        </div>
      </Fragment>
    );
  }
}

export default GrantClosure;
