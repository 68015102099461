import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
  render() {
    const { breadCrumbList } = this.props;
    let bctms = [];
    if (breadCrumbList.length > 0) {
      bctms = breadCrumbList.map((bct, index) => {
        const activeClass =
          breadCrumbList.length - 1 !== index
            ? "breadcrumb-item font-weight-bold"
            : "breadcrumb-item font-weight-bold active";
        return (
          <Fragment key={index}>
            <li className={activeClass}>
              {bct.linkTo !== null ? (
                <Link to={bct.linkTo}>{bct.displayName}</Link>
              ) : (
                bct.displayName
              )}
            </li>
          </Fragment>
        );
      });
    }

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb border-bottom-0 pl-0">{bctms}</ol>
      </nav>
    );
  }
}

export default Breadcrumb;
