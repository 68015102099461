import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Download from "downloadjs";
import AlertComponent from "../common/AlertComponent";
import {
  getDisplayDate,
  getYears,
  convertDateToNumber,
  convertNumberToDate
} from "../lease/DateUtil";
import { getDownloadFileName } from "../common/Util";
import { INDIVIDUALS_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import { Emails } from "../widgets/Emails";
import { Addresses } from "../widgets/Addresses";
import { IdentificationDocuments } from "../widgets/IdentificationDocuments";
import { Phones } from "../widgets/Phones";
import { EDU_QUALIFICATION_TYPES } from "../common/LookupConstants";
import { titleCaseForOneCaseString } from "../common/Util";

const ORG_ID_TYPES = [
  {
    code: "PAN",
    name: "PAN",
    required: true
  },
  {
    code: "AADHAAR",
    name: "Aadhaar",
    required: false
  }
];

const YEARS = getYears();

const checkEmptyValue = (inputError, fieldName, value) => {
  if (value === undefined || value === "" || value === null) {
    inputError[fieldName] = true;
    return true;
  }
  return false;
};

const renderInputData = data => {
  return data ? data : "";
};

class EditIndividualForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // For input validations
      validate: false,
      toBeValidated: [],
      hasError: false,
      update: false,
      updatedIndividual: {},

      // For All Component
      _id: null,
      basicInfo: null,
      phones: [],
      emails: [],
      addresses: [],
      idDocs: [],
      newRecord: null,
      previousLocation: "",
      incProfUrl: ""
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  // if the set state is called directly,
  // then toBeValidated Array data gets overwritten
  // Passing function queues up the set state
  // and they are called in the same order they are called.
  // https://medium.freecodecamp.org/functional-setstate-is-the-future-of-react-374f30401b6b

  setStateValidateResult = (childName, childInvalid, data) => {
    this.setState(state => {
      const toBeValidated = state.toBeValidated.filter(
        item => item !== childName
      );
      const validationComplete = toBeValidated.length === 0;

      const hasError = state.hasError || childInvalid;
      const update = !hasError && validationComplete;
      const updatedIndividual = state.updatedIndividual;
      updatedIndividual[childName] = data;

      let errorState = {};
      if (validationComplete && hasError) {
        errorState = {
          alertType: "Default",
          showAlert: true,
          alertColor: "danger",
          alertMessage: "Please correct the errors and submit again"
        };
        window.scrollTo(0, 0);
      }
      return {
        validate: !validationComplete,
        hasError,
        toBeValidated,
        update,
        updatedIndividual,
        ...errorState
      };
    });
  };

  onValidate = (error, childName, data) => {
    if (error === null) {
      this.setStateValidateResult(childName, false, data);
    } else {
      this.setStateValidateResult(childName, true, null);
    }
  };

  addDocs = (formData, name, file) => {
    if (file && typeof file !== "string") {
      formData.append(name, file);
    }
  };

  getFormData = () => {
    let formData = new FormData();
    const updatedIndividual = this.state.updatedIndividual;
    const basicInfo = updatedIndividual.basicInfo;

    const identificationDocs = updatedIndividual.idDocs.map(id => {
      this.addDocs(formData, `id_doc_${id.type}`, id.scannedId);
      return {
        type: id.type,
        number: id.number,
        status: id.status
      };
    });

    const addresses = updatedIndividual.addresses.map(ad => {
      this.addDocs(formData, `address_doc_${ad.type}`, ad.addressProofUpload);
      return {
        _id: ad._id,
        streetLine1: ad.streetLine1,
        streetLine2: ad.streetLine2,
        landmark: ad.landmark,
        city: ad.city,
        state: ad.state,
        postalCode: ad.postalCode,
        country: ad.country,
        status: ad.status,
        type: ad.type
      };
    });

    this.addDocs(formData, "photo", basicInfo.photoUpload);
    const individualForm = {
      firstName: basicInfo.firstName,
      lastName: basicInfo.lastName,
      gender: basicInfo.gender,
      dob: basicInfo.dob ? basicInfo.dob : "",
      experience: basicInfo.experience,
      references: basicInfo.references,
      education: basicInfo.education,
      phones: updatedIndividual.phones,
      emails: updatedIndividual.emails,
      addresses,
      identificationDocs
    };

    formData.append("message", JSON.stringify(individualForm));

    return formData;
  };

  setDataFromServer = data => {
    const basicInfo = {
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      dob: data.dob,
      photo: data.photo,
      experience: data.experience,
      references: data.references,
      education: data.education
    };

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const from = params.get("from");
    const incProfUrl = params.get("incProfUrl");

    this.setState({
      _id: data._id,
      basicInfo,
      phones: data.phones ? data.phones : [],
      emails: data.emails ? data.emails : [],
      addresses: data.addresses ? data.addresses : [],
      idDocs: data.identificationDocs ? data.identificationDocs : [],
      newRecord: false,
      previousLocation: from,
      incProfUrl: incProfUrl
    });
  };

  callUpdateApi = () => {
    // console.log("Calling Update API");

    const updateRequest = this.getFormData();

    const url = `${INDIVIDUALS_API}/${this.state._id}`;

    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        this.setDataFromServer(data);
        this.setState({
          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: ` ${
            data.firstName
          } profile has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );

    this.setState({ update: false, updatedIndividual: {} });
  };

  handleUpdate = () => {
    this.setState({
      validate: true,
      hasError: false,
      toBeValidated: ["basicInfo", "phones", "emails", "addresses", "idDocs"],
      update: false,
      updatedIndividual: {}
    });
  };

  getIndividualById = id => {
    const url = INDIVIDUALS_API + "/" + id;

    HttpUtil.get(
      url,
      {},
      data => this.setDataFromServer(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  downloadDocument = filePartUrl => {
    const url = `${INDIVIDUALS_API}/${this.state._id}/${filePartUrl}`;

    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return Promise.all([
            response.blob(),
            getDownloadFileName(response, "photo")
          ]);
        } else {
          throw Error(response.statusText);
        }
      })
      .then(([blob, filename]) => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString());
      });
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getIndividualById(id);
    } else {
      this.setState({ newRecord: true });
    }
  }

  componentDidUpdate = prevState => {
    if (this.state.update !== prevState.update && this.state.update) {
      this.callUpdateApi();
    }
  };

  render() {
    const {
      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      newRecord
    } = this.state;

    if (newRecord === null) {
      return null;
    }

    const props = {
      onValidate: this.onValidate,
      validate: this.state.validate,

      // state object
      newRecord: newRecord,
      _id: this.state._id,
      basicInfo: this.state.basicInfo,
      phones: this.state.phones,
      emails: this.state.emails,
      addresses: this.state.addresses,
      idDocs: this.state.idDocs,
      previousLocation: this.state.previousLocation,
      incProfUrl: this.state.incProfUrl,

      // download document
      downloadDocument: this.downloadDocument
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        {/* All Components */}
        <BasicInfo {...props} />
        <ContactInformation {...props} />
        <Addresses {...props} />
        <IdentificationDocuments idTypes={ORG_ID_TYPES} {...props} />
        <div className="row mt-5 mb-5">
          <div className="col-12">
            <button
              className="btn btn-sm btn-primary"
              onClick={this.handleUpdate}
            >
              Update Changes
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditIndividualForm;

/*++++++++++++++++++++ BasicInfo Start +++++++++++++++++++++++++++ */
export class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInfo: {
        firstName: null,
        lastName: null,
        gender: "",
        dob: null,
        photo: null,
        photoUpload: null,
        experience: null,
        references: null,
        education: []
      },
      inputError: {},
      validateChildren: false,
      childrenToValidate: [],
      hasError: false
    };
  }

  loadStateFromProps = () => {
    const { newRecord, basicInfo } = this.props;
    if (!newRecord && basicInfo !== null) {
      const newBasicInfo = { ...basicInfo };
      newBasicInfo.dob = convertNumberToDate(newBasicInfo.dob);
      this.setState({
        basicInfo: newBasicInfo
      });
    }
  };

  componentDidMount = () => {
    this.loadStateFromProps();
  };

  handleDateChange = (targetName, date) => {
    let basicInfo = { ...this.state.basicInfo };
    basicInfo[targetName] = date;
    this.setState({ basicInfo });
  };

  handleNameInputchange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    const basicInfo = { ...this.state.basicInfo };

    const prevValueFieldName = `${targetName}PrevValue`;
    const prevValue = this.state[prevValueFieldName];

    if (prevValue === targetValue) {
      basicInfo[targetName] = targetValue;
    } else {
      basicInfo[targetName] = titleCaseForOneCaseString(targetValue);
    }

    this.setState({ basicInfo, [prevValueFieldName]: targetValue });
  };

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (e.target.type === "file") {
      targetValue = e.target.files[0];
    }
    const basicInfo = { ...this.state.basicInfo };
    basicInfo[targetName] = targetValue;
    this.setState({ basicInfo });
  };

  validate = () => {
    let inputError = {};
    const basicInfo = this.state.basicInfo;
    checkEmptyValue(inputError, "firstName", basicInfo.firstName);
    checkEmptyValue(inputError, "experience", basicInfo.experience);

    const hasError = Object.keys(inputError).length > 0;

    // Fields in basic info main component has been validated.
    // Now trigger validation for child components.
    this.setState({
      inputError,
      hasError,
      validateChildren: true,
      childrenToValidate: ["education"]
    });
  };

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    if (this.props.basicInfo !== prevState.basicInfo) {
      this.loadStateFromProps();
    }
  };

  onChildValidate = (error, childName, data) => {
    let hasError = this.state.hasError || error !== null;
    const childrenToValidate = this.state.childrenToValidate.filter(
      item => item !== childName
    );
    const validationComplete = childrenToValidate.length === 0;

    const newState = {
      validateChildren: !validationComplete,
      hasError,
      childrenToValidate
    };

    const { basicInfo, inputError } = this.state;

    if (hasError) {
      inputError[childName] = error;
    } else {
      // Set data in the new state
      basicInfo[childName] = data;
      newState.basicInfo = basicInfo;
    }

    if (validationComplete) {
      const newBasicInfo = { ...basicInfo };
      newBasicInfo.dob = convertDateToNumber(newBasicInfo.dob);

      this.props.onValidate(
        hasError ? inputError : null,
        "basicInfo",
        newBasicInfo
      );
    }
    this.setState(newState);
  };

  downloadPhoto = () => {
    this.props.downloadDocument(`photo/${this.state.basicInfo.photo._id}`);
  };

  render() {
    const {
      firstName,
      lastName,
      gender,
      dob,
      experience,
      references,
      photo,
      photoUpload
    } = this.state.basicInfo;
    const { inputError } = this.state;
    const childProps = {
      basicInfo: this.props.basicInfo,
      validate: this.state.validateChildren,
      onValidate: this.onChildValidate
    };

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <h5 className="h6 card-title mb-0">
                <Link
                  to={`${
                    this.props.previousLocation
                  }?from=/admin/individuals/edit/${this.props._id}&incProfUrl=${
                    this.props.incProfUrl
                  }`}
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
                &nbsp;Basic Information
              </h5>
            </div>

            <div className="card-body">
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 required">
                  First Name
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className={
                      inputError["firstName"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="First Name"
                    name="firstName"
                    value={renderInputData(firstName)}
                    onChange={this.handleNameInputchange}
                  />
                  <div className="invalid-feedback">
                    Please enter first name *
                  </div>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Last Name
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Last Name"
                    name="lastName"
                    value={renderInputData(lastName)}
                    onChange={this.handleNameInputchange}
                  />
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Gender
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    name="gender"
                    className={
                      inputError["gender"]
                        ? "custom-select custom-select-sm is-invalid"
                        : "custom-select custom-select-sm"
                    }
                    value={renderInputData(gender)}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select...</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="T">Others</option>
                  </select>
                  <div className="invalid-feedback">Please select gender *</div>
                </div>

                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Date of Birth
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <DatePicker
                    value={
                      dob && typeof dob === "object" ? getDisplayDate(dob) : ""
                    }
                    selected={dob && typeof dob === "object" ? dob : null}
                    onChange={this.handleDateChange.bind(this, "dob")}
                    utcOffset={0}
                    maxDate={new Date()}
                    placeholderText="dd-mm-yyyy"
                    className={
                      inputError["dob"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />

                  {inputError["dob"] ? (
                    <div className="auto-lookup-empty">
                      Please select dob *.
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 required">
                  Experience
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className={
                      inputError["experience"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Any Experience"
                    name="experience"
                    value={renderInputData(experience)}
                    onChange={this.handleInputChange}
                  />
                  <div className="invalid-feedback">
                    Please enter experience *
                  </div>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  References
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="References"
                    name="references"
                    value={renderInputData(references)}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              {/* <div className="form-group mt-xl-2 mb-0 row">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Photo
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="file"
                    name="photoUpload"
                    className="form-control-file"
                    onChange={this.handleInputChange}
                  />
                </div>
                {photo && photo._id ? (
                  <div className="col-12 col-lg-2 col-xl-2 mt-2 mt-lg-0 mt-xl-0">
                    <button
                      className="btn btn-sm btn-info"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Download Photo"
                      onClick={this.downloadPhoto}
                    >
                      <i className="fas fa-file-download" />
                      &nbsp; Download
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-lg-2 col-xl-2 mt-2 mt-lg-0 mt-xl-0">
                    <button
                      className="btn btn-sm btn-info"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Download disabled"
                      disabled
                    >
                      <i className="fas fa-file-download" />
                      &nbsp; Download
                    </button>
                  </div>
                )}
              </div> */}

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Photo
                </label>
                <label className="col-form-label col-12 col-lg-4 col-xl-3">
                  {photo ? photo.filename : "Not Available"}
                  &nbsp;&nbsp;
                  <span className="text-info">
                    {photoUpload ? `New File : "${photoUpload.name}"` : null}
                  </span>
                </label>

                <div className="col-12 col-lg-4 col-xl-3">
                  <div className="upload-btn-wrapper mt-2">
                    <button className="btn btn-sm btn-outline-info custom-btn">
                      <i className="fas fa-file-upload" />
                      &nbsp;Upload
                      <input
                        type="file"
                        name="photoUpload"
                        onChange={this.handleInputChange}
                      />
                    </button>
                  </div>
                  &nbsp;
                  {photo && photo._id ? (
                    <div className="download-btn-wrapper mt-2">
                      <button
                        className="btn btn-sm btn-outline-info custom-btn"
                        data-toggle="tooltip"
                        title="Download Photo"
                        onClick={this.downloadPhoto}
                      >
                        <i className="fas fa-file-download" />
                        &nbsp; Download
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <hr />
              <Education {...childProps} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*++++++++++++++++++++ BasicInfo End +++++++++++++++++++++++++++++++ */

/*++++++++++++++++++++ Education Start ++++++++++++++++++++++++ */
export class Education extends Component {
  constructor(props) {
    super(props);
    this.state = {
      education: [],
      inputError: []
    };
  }

  addMoreEducation = () => {
    const education = [...this.state.education];
    education.push({
      qualification: null,
      degree: null,
      year: null,
      institution: null,
      alumni: false
    });
    this.setState({ education });
  };

  setStateEducation = education => {
    this.setState({ education });
  };

  removeEducation = index => {
    let education = [...this.state.education];
    if (education.length === 0) return;
    education = education.filter((edu, idx) => idx !== index);
    this.setStateEducation(education);
  };

  handleInputChange = (index, e) => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
    }
    let education = [...this.state.education];
    let edu = education[index];
    edu[targetName] = targetValue;
    education[index] = edu;
    this.setStateEducation(education);
  };

  validate = () => {
    let inputError = [];
    const education = this.state.education;
    let hasError = false;

    education.forEach((edu, index) => {
      inputError[index] = {};
      checkEmptyValue(inputError[index], "degree", edu.degree);
      checkEmptyValue(inputError[index], "qualification", edu.qualification);
      // checkEmptyValue(inputError[index], "year", edu.year);
      // checkEmptyValue(inputError[index], "institution", edu["institution"]);
      hasError = hasError || Object.keys(inputError[index]).length > 0;
    });

    if (hasError) {
      this.props.onValidate(inputError, "education", null);
    } else {
      inputError = [];

      this.props.onValidate(null, "education", education);
    }

    this.setState({ inputError });
  };

  componentDidMount = () => {
    this.loadStateFromProps();
  };

  loadStateFromProps = () => {
    const newRecord = this.props.newRecord;
    const basicInfo = this.props.basicInfo;
    let education = basicInfo !== null ? basicInfo.education : [];
    if (!newRecord && education.length > 0) {
      this.setState({ education });
    }

    // props 'education' is null or is empty then create new 'education' array in local state.
    if (education && education.length === 0) {
      if (this.state.education.length === 0) {
        this.addMoreEducation();
      }
    }
  };

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }
    if (this.props.basicInfo !== prevState.basicInfo) {
      this.loadStateFromProps();
    }
  };

  render() {
    const { education, inputError } = this.state;

    const educationList = education.map((edu, index) => {
      const hideRemoveBtn = education.length === 1;
      const hideAddBtn = education.length - 1 !== index;
      return (
        <Fragment key={index}>
          <div key={index} className="mt-3">
            <strong>Education {index + 1}</strong>
            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-2 col-xl-2 pr-0 required">
                Edu Qualification
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <select
                  className={
                    inputError[index] && inputError[index]["qualification"]
                      ? "custom-select custom-select-sm is-invalid"
                      : "custom-select custom-select-sm"
                  }
                  name="qualification"
                  value={renderInputData(edu.qualification)}
                  onChange={this.handleInputChange.bind(this, index)}
                >
                  <option value="">Select...</option>
                  {EDU_QUALIFICATION_TYPES.map((opt, i) => (
                    <option key={i} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  Please select Education qualification *.
                </div>
              </div>
              <label className="col-form-label col-12 col-lg-2 col-xl-2 required">
                Degree
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className={
                    inputError[index] && inputError[index]["degree"]
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                  placeholder="Degree"
                  name="degree"
                  value={renderInputData(edu.degree)}
                  onChange={this.handleInputChange.bind(this, index)}
                />
                <div className="invalid-feedback">Please enter degree *</div>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-2 col-xl-2">
                Institution
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className={
                    inputError[index] && inputError[index]["institution"]
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                  placeholder="Institution"
                  name="institution"
                  value={renderInputData(edu.institution)}
                  onChange={this.handleInputChange.bind(this, index)}
                />
                <div className="invalid-feedback">
                  Please enter institution *
                </div>
              </div>
              <label className="col-form-label col-12 col-lg-2 col-xl-2">
                Year of Passing
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <select
                  className={
                    inputError[index] && inputError[index]["year"]
                      ? "custom-select custom-select-sm is-invalid"
                      : "custom-select custom-select-sm"
                  }
                  name="year"
                  value={renderInputData(edu.year)}
                  onChange={this.handleInputChange.bind(this, index)}
                >
                  <option value="">Select...</option>
                  {YEARS.map(yyyy => (
                    <option key={yyyy} value={yyyy}>
                      {yyyy}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                  Please select year of passing *.
                </div>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-2 col-xl-2">
                Alumni
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`#${index}alumni`}
                    name="alumni"
                    checked={edu.alumni}
                    onChange={this.handleInputChange.bind(this, index)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`#${index}alumni`}
                  />
                  Yes
                </div>
              </div>
            </div>
            <div className="form-group row mt-2 mb-0">
              <div className="col-12">
                <button
                  className={
                    hideAddBtn
                      ? "btn btn-info btn-sm d-none"
                      : "btn btn-info btn-sm"
                  }
                  onClick={this.addMoreEducation}
                >
                  Add More
                </button>
                &nbsp;
                <button
                  className={
                    hideRemoveBtn
                      ? "btn btn-outline-danger btn-sm d-none"
                      : "btn btn-outline-danger btn-sm"
                  }
                  onClick={this.removeEducation.bind(this, index)}
                >
                  Remove Education {index + 1}
                </button>
              </div>
            </div>
          </div>
          {education.length - 1 !== index ? <hr /> : null}
        </Fragment>
      );
    });
    return <Fragment>{educationList}</Fragment>;
  }
}
/*++++++++++++++++++++ Education End +++++++++++++++++++++++*/

/*++++++++++++++++++++ ContactInformation Start +++++++++++++++++++++++*/
export class ContactInformation extends Component {
  render() {
    return (
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <h5 className="h6 card-title mb-0">
                Primary Contact Information
              </h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-6">
                  <Phones {...this.props} />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong>Email</strong>
                  <Emails {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*++++++++++++++++++++ ContactInformation End +++++++++++++++++++++++*/
