import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

function renderAssetInfo(result) {
  return <Fragment />;
}
function renderAssetInfo2(result) {
  return (
    <Fragment>
      <td>{result.assetId}</td>
      <td>{result.assetType}</td>
      <td>{result.asset}</td>
      <td>
        <a>
          <Link
            to={`/service-mgmt/asset-reservation-requests/edit/${
              result.reservation_id
            }?from=/service-mgmt/asset-reservations`}
          >
            {result.requestor}
          </Link>
        </a>
      </td>
    </Fragment>
  );
}

function renderResultRow(result) {
  const reservedAssets = result.reservedAssets || [];
  let defaultReservedAsset = {};
  if (reservedAssets.length > 0) {
    const reservedAsset = reservedAssets[0] || {};
    defaultReservedAsset = {
      requestor: reservedAsset.requestor || "--",
      reservedBy: reservedAsset.reservedBy || "--",
      reservationId: reservedAsset.reservationId || "--",
      reservedHours: reservedAsset.reservedHours || "--",
      reservationStatus: reservedAsset.reservationStatus || "--"
    };
  }
  const subReservedAssets = [];
  for (let i = 1; i < reservedAssets.length; i++) {
    subReservedAssets.push(reservedAssets[i]);
  }

  const rSpan = reservedAssets.length;

  const isComplete = defaultReservedAsset.reservationStatus === "COMPLETE";
  const color = isComplete ? "success" : "warning";

  return (
    <Fragment key={`${result.assetId}`}>
      <tr>
        <td className="pt-2" rowspan={`${rSpan}`}>
          {result.assetId}
        </td>
        <td className="pt-2 text-uppercase" rowspan={`${rSpan}`}>
          {result.assetType}
        </td>
        <td className="pt-2" rowspan={`${rSpan}`}>
          {result.asset}
        </td>
        <td>
          <div className="my-1">{`${defaultReservedAsset.reservedBy ||
            "--"}`}</div>
        </td>
        <td>
          <div className="my-1">
            <h6 className="mb-1 d-inline-block">
              <Link
                to={`/service-mgmt/asset-reservation-requests/edit/${
                  defaultReservedAsset.reservationId
                }?from=/service-mgmt/asset-reservation-daily-summary`}
                style={{ fontWeight: "400" }}
              >
                {defaultReservedAsset.requestor}
              </Link>
            </h6>
            <div className="d-flex justify-content-start d-inline-block">
              <div className="pr-2">
                <span
                  className={`badge badge-${color} badge-pill text-uppercase px-2 py-1`}
                >
                  {isComplete ? "RESERVED" : "INPROGRESS"}
                </span>
              </div>
              <span className="badge badge-primary badge-pill px-2 py-1">
                {defaultReservedAsset.reservedHours
                  ? `${defaultReservedAsset.reservedHours} Hours`
                  : null}
              </span>
            </div>
          </div>
        </td>
      </tr>

      {subReservedAssets.map((reservation, index) => {
        const isComplete = reservation.reservationStatus === "COMPLETE";
        const color = isComplete ? "success" : "warning";
        return (
          <tr key={index}>
            <td>
              <div className="my-1">{`${reservation.reservedBy || "--"}`}</div>
            </td>
            <td>
              <div className="my-1">
                <h6 className="mb-1 d-inline-block">
                  <Link
                    to={`/service-mgmt/asset-reservation-requests/edit/${
                      reservation.reservationId
                    }?from=/service-mgmt/asset-reservation-daily-summary`}
                    style={{ fontWeight: "400" }}
                  >
                    {reservation.requestor}
                  </Link>
                </h6>
                <div className="d-flex justify-content-start d-inline-block">
                  <div className="pr-2">
                    <span
                      className={`badge badge-${color} badge-pill text-uppercase px-2 py-1`}
                    >
                      {isComplete ? "RESERVED" : "INPROGRESS"}
                    </span>
                  </div>
                  <span className="badge badge-primary badge-pill px-2 py-1">
                    {reservation.reservedHours
                      ? `${reservation.reservedHours} Hours`
                      : null}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
}

class SearchResults extends Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="row">
          <div className="col-10 offset-1">
            <div className="progress-bar-loading-text">Loading...</div>
            <div className="progress-bar">
              <div className="indeterminate" />
            </div>
          </div>
        </div>
      );
    }

    const results = this.props.results || [];

    return (
      <div>
        <table className="table table-sm border-bottom">
          <thead>
            <tr>
              <th
                style={{ width: "10%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
              >
                Asset Id
              </th>
              <th
                style={{ width: "10%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
              >
                Asset Type
              </th>
              <th
                style={{ width: "35%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
              >
                Asset Name
              </th>
              <th
                style={{ width: "15%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
              >
                Reserved By
              </th>
              <th
                style={{ width: "30%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
              >
                Reserved For / Hours Reserved
              </th>
            </tr>
          </thead>
          <tbody>
            {results.length === 0 ? (
              <tr>
                <td colSpan="4" className="text-center">
                  No reservation found for this day.
                </td>
              </tr>
            ) : (
              results.map(result => renderResultRow(result))
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SearchResults;
