import React, { Component, Fragment } from "react";
import AlertComponent from "../common/AlertComponent";
import TableList from "../common/TableList";
import { getDisplayDate } from "../lease/DateUtil";
import HttpUtil from "../common/HttpUtil";
import { GRANT_APPLICATION_API } from "../common/Constants";
import { AppFormStatus } from "../common/LookupConstants";
import Link from "react-router-dom/Link";

function getProgrammeName(cell, row) {
  // console.log("cell:", cell, "row:", row);
  const PROGRAMMES = {
    IncubateeAppForm: "PSG Step Incubatee",
    NidhiEirAppForm: "NIDHI EIR",
    NidhiPrayasAppForm: "NIDHI PRAYAS"
  };
  return PROGRAMMES[cell];
}

class ShortList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortList: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getShortListData = () => {
    const url = `${GRANT_APPLICATION_API}?closed=1`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ shortList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getShortListData();
  }

  actionsFormater(cell, row) {
    //console.log("cell:", cell, "row:", row);
    if (!row) return null;
    let onboardButton = null;
    onboardButton = (
      <Fragment key={row._id}>
        <Link to={`/admin/view/incubator/form/${row._id}`}>
          <i className="fas fa-eye fa-lg action-icon" />
        </Link>
        {row.grantAward ? (
          <Link
            to={`/funding/awards/progress/${row.grantAward}`}
            className="ml-2"
          >
            <i className="fas fa-comments fa-lg action-icon" />
          </Link>
        ) : null}
      </Fragment>
    );

    return onboardButton;
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const columnsShortList = [
      {
        dataField: "name",
        text: "Applicant Name",
        sort: true
        // style: { textDecoration: "underline", cursor: "pointer" },
        // events: {
        //   onClick: this.handleClick.bind(this)
        // }
      },
      {
        dataField: "kind",
        text: "Programme",
        sort: true,
        formatter: getProgrammeName
      },

      {
        dataField: "createdAt",
        text: "Approve Date",
        formatter: getDisplayDate
      },
      {
        dataField: "status",
        text: "Status",
        formatter: AppFormStatus
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        csvExport: false,
        formatter: this.actionsFormater
      }
    ];

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsShortList}
          data={this.state.shortList}
          noDataIndication="No Incubator Application found."
          sizePerPage="10"
          headerName="Grant Awards"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default ShortList;
