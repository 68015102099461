import React, { Component, Fragment } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import {
  getDisplayDate,
  convertNumberToDate,
  convertDateToNumber
} from "../../lease/DateUtil";
import AlertComponent from "../../common/AlertComponent";
import { GRANT_AWARDS_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { INCUBATION_SECTORS_OBJECT } from "../../common/IncubateeProfileConstants";
import {
  AWARD_STATUS_OBJECT,
  AWARD_STATUS
} from "../../common/LookupConstants";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  return getError(touch, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touch, error, datePicker } = props;
  let renderComponent = "";

  if (datePicker) {
    renderComponent = (
      <div className="auto-lookup-empty">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  } else {
    renderComponent = (
      <div className="invalid-feedback">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  }
  return renderComponent;
};

export const GrantProgressForm = props => {
  const { values, setFieldValue, touched, errors } = props;

  return (
    <Fragment>
      <div className="row mb-3">
        <div className="col-md-12 text-center">
          <h6 className="mb-0 d-inline-block">
            <strong>Grant Progress</strong>
          </h6>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="shadow card flex-fill box-border box-light-blue">
            <div className="card-body">
              <GrantOverview {...props} />

              <div className="row mt-5 mb-3">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
                  <div className="card border-0 h-100">
                    <div className="card-header border-0 pb-0">
                      <strong className="border-bottom pb-1 d-block">
                        Grant Information
                      </strong>
                    </div>
                    <div className="card-body pt-2">
                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 pr-0">
                          Revised End Date
                        </label>
                        <div className="col-12 col-lg-8 col-xl-8">
                          <DatePicker
                            value={
                              values.revisedEndDate
                                ? getDisplayDate(values.revisedEndDate)
                                : ""
                            }
                            selected={values.revisedEndDate}
                            onChange={e => {
                              setFieldValue(`revisedEndDate`, e);
                            }}
                            utcOffset={0}
                            placeholderText="dd-mm-yyyy"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "revisedEndDate"
                            )}`}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4">
                          Notes
                        </label>
                        <div className="col-12 col-lg-8 col-xl-8">
                          <Field
                            component="textarea"
                            className="form-control form-control-sm"
                            name="notes"
                            rows="6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
                  <div className="card border-0 h-100">
                    <div className="card-header border-0 pb-0">
                      <strong className="border-bottom pb-1 d-block">
                        Documents
                      </strong>
                    </div>
                    <div className="card-body pt-2">
                      <ul
                        className="list-group list-group-flush mb-3"
                        style={{ minHeight: "128px" }}
                      >
                        <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                          <span>
                            <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                            Grant Aggrement.pdf
                          </span>
                          <div>
                            <i
                              className="fas fa-download fa-md mr-3"
                              style={{ cursor: "pointer" }}
                            />
                            <i
                              className="far fa-trash-alt fa-md"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                          <span>
                            <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                            Grant Progress Report.pdf
                          </span>
                          <div>
                            <i
                              className="fas fa-download fa-md mr-3"
                              style={{ cursor: "pointer" }}
                            />
                            <i
                              className="far fa-trash-alt fa-md"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </li>
                        <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                          <span>
                            <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                            Product Review.pdf
                          </span>
                          <div>
                            <i
                              className="fas fa-download fa-md mr-3"
                              style={{ cursor: "pointer" }}
                            />
                            <i
                              className="far fa-trash-alt fa-md"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </li>
                      </ul>
                      <div className="text-center">
                        <button
                          className="btn btn-sm btn-outline-info"
                          onClick={() => {}}
                        >
                          <i className="fas fa-upload mr-2" />
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer text-center pb-3">
              <button className="btn btn-sm btn-outline-danger ml-3">
                Terminate
              </button>
              <button className="btn btn-sm btn-outline-info ml-3">
                Closure
              </button>
              <button className="btn btn-sm btn-primary ml-3">Save</button>

              <button
                className="btn btn-sm btn-outline-primary ml-3"
                type="button"
                onClick={() => {
                  values.addedNewReview = true;
                  setFieldValue(`values.addedNewReview`, values);
                }}
              >
                Add Review
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const GrantOverview = props => {
  const { values } = props;

  let incubateeName = "--";
  if (values.incubatee && values.incubatee.incubateeType === "COMPANY") {
    incubateeName = values.incubatee.organization.name;
  }

  if (values.incubatee && values.incubatee.incubateeType === "INDIVIDUAL") {
    let fName = values.incubatee.individual.firstName || "";
    let lName = values.incubatee.individual.lastName || "";
    incubateeName = `${fName} ${lName}`;
  }

  let granteeName = "--";
  if (values.grantee) {
    granteeName = `${values.grantee.name || ""} ${values.grantee.firstName ||
      ""} ${values.grantee.lastName || ""}`;
  }

  return (
    <Fragment>
      <div className="row mb-3">
        <div className="col-12">
          <strong>
            {values.projectTitle || "--"}
            <span className="badge badge-soft-info badge-pill ml-2">
              {AWARD_STATUS_OBJECT[values.status] || "--"}
            </span>
          </strong>
          <small className="float-right">
            <strong> {values.applicationId || "--"} </strong>
          </small>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-award fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Grantee Type</small>
            <div className="mb-0">{values.granteeType}</div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-trophy fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Grantee Name</small>
            <div className="mb-0">{granteeName || "--"}</div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-rocket fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Incubatee Name</small>
            <div className="mb-0">{incubateeName}</div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-rupee-sign fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Amount Sanction</small>
            <div className="mb-0">{values.sanctionedAmount || "--"}</div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-calendar-alt fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Fund Start Date</small>
            <div className="mb-0">
              {getDisplayDate(values.startDate) || "--"}
            </div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-calendar-alt fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Fund End Date</small>
            <div className="mb-0">{getDisplayDate(values.endDate) || "--"}</div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-ruler-vertical fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Vertical</small>
            <div className="mb-0">
              {INCUBATION_SECTORS_OBJECT[values.vertical] || "--"}
            </div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-rupee-sign fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Disbursed Amount</small>
            <div className="mb-0">{values.amountDisburesd || "--"}</div>
          </span>
        </div>
      </div>
    </Fragment>
  );
};

const PreviewForm = props => {
  const { values, setFieldValue } = props;

  const reviews = values.reviews || [];

  return (
    <Fragment>
      {reviews.map((review, index) => {
        if (review.editReview) {
          return (
            <EditReviewForm
              key={`editForm#${index}`}
              index={index}
              reviews={values.reviews}
              setFieldValue={props.setFieldValue}
              touched={props.touched}
              errors={props.errors}
              awardBackup={props.awardBackup}
            />
          );
        } else {
          const reviewers = review.reviewers || [];
          return (
            <Fragment key={`${index}`}>
              <div className="row mb-5">
                <div className="col-md-12">
                  <div className="shadow card flex-fill box-border box-light-blue">
                    <div className="card-header border-0 text-center">
                      <h6 className="mb-0 d-inline-block">
                        <strong>
                          {review.reviewTitle}
                          <span className="badge badge-soft-secondary badge-pill ml-2">
                            {review.status}
                          </span>
                        </strong>
                      </h6>
                      <button
                        className="btn btn-info badge custom-btn-sm text-white float-right"
                        type="button"
                        key={`${index}`}
                        onClick={() => {
                          const field = `reviews[${index}].editReview`;
                          setFieldValue(field, true);
                        }}
                      >
                        <i className="far fa-edit text-white" /> Edit
                      </button>
                    </div>
                    <div className="card-body pt-0">
                      <div className="row mt-2 mb-3">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-8">
                          <span className="d-block border-bottom pb-2 mb-2">
                            <strong>Review Date</strong>
                          </span>
                          <p className="mt-3">
                            {getDisplayDate(review.reviewDate)}
                          </p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <span className="d-block border-bottom pb-2 mb-2">
                            <strong>Disbursed Amount</strong>
                          </span>
                          <p className="mt-3">
                            <i className="fas fa-rupee-sign mr-2" />
                            {review.amountDisburesd}
                          </p>
                        </div>
                      </div>

                      <div className="row mt-2 mb-3">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                          <strong className="d-block border-bottom pb-2 mb-2">
                            <strong>Reviewers Notes</strong>
                          </strong>
                          <p className="mt-1">{review.reviewersComments}</p>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                          <strong className="d-block border-bottom pb-2">
                            <strong>Reviewers</strong>
                          </strong>
                          <ul className="list-group">
                            {reviewers.map((reviewer, rIdx) => {
                              return (
                                <li
                                  key={`${rIdx}`}
                                  className="list-group-item d-flex align-items-center border-0 pl-0 pb-0"
                                >
                                  <i className="far fa-user mr-2" />
                                  {`${reviewer.name} (${reviewer.organization})`}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>

                      <div className="row mt-2 mb-3">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                          <strong className="d-block border-bottom pb-2 mb-2">
                            <strong>Incubators Notes</strong>
                          </strong>
                          <p className="mt-1">{review.incubatorsComments}</p>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                          <strong className="d-block border-bottom pb-2">
                            <strong>Documents</strong>
                          </strong>
                          <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 pl-0 pb-0">
                              <span>
                                <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                Grant Aggrement.pdf
                              </span>
                              <div>
                                <i
                                  className="fas fa-download fa-md mr-3"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 pl-0 pb-0">
                              <span>
                                <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                Product Review.pdf
                              </span>
                              <div>
                                <i
                                  className="fas fa-download fa-md mr-3"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 pl-0 pb-0">
                              <span>
                                <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                Report.xlsx
                              </span>
                              <div>
                                <i
                                  className="fas fa-download fa-md mr-3"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }
      })}
    </Fragment>
  );
};

const EditReviewForm = props => {
  const { index, reviews, setFieldValue, touched, errors, awardBackup } = props;

  return (
    <Fragment>
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="shadow card flex-fill box-border box-light-blue">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h6 className="d-inline-block">
                    <strong>{reviews[index].reviewTitle}</strong>
                  </h6>
                </div>
              </div>

              <div className="row mb-3 mt-2">
                <div className="col-md-12">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Basic Information</strong>
                  </strong>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-md-12">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Review Title
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "reviewTitle"
                        )}`}
                        name={`reviews[${index}].reviewTitle`}
                      />
                      <ErrorComponent
                        fieldName={`reviews[${index}].reviewTitle`}
                        touch={touched}
                        error={errors}
                      />
                    </div>

                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Review Date
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <DatePicker
                        value={
                          reviews[index] && reviews[index].reviewDate
                            ? getDisplayDate(reviews[index].reviewDate)
                            : ""
                        }
                        selected={reviews[index] && reviews[index].reviewDate}
                        onChange={e => {
                          setFieldValue(`reviews[${index}].reviewDate`, e);
                        }}
                        utcOffset={0}
                        placeholderText="dd-mm-yyyy"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "reviewDate"
                        )}`}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <ErrorComponent
                        fieldName={`reviews[${index}].reviewDate`}
                        touch={touched}
                        error={errors}
                        datePicker={true}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Disbursed Amt
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "amountDisburesd"
                        )}`}
                        name={`reviews[${index}].amountDisburesd`}
                      />
                      <ErrorComponent
                        fieldName={`reviews[${index}].amountDisburesd`}
                        touch={touched}
                        error={errors}
                      />
                    </div>
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Status
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        component="select"
                        type="text"
                        className={`custom-select custom-select-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "status"
                        )}`}
                        name={`reviews[${index}].status`}
                      >
                        <option value="">Select...</option>
                        {AWARD_STATUS.map((opt, i) => (
                          <option key={i} value={opt.key}>
                            {opt.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorComponent
                        fieldName={`reviews[${index}].status`}
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Reviewers</strong>
                  </strong>

                  <div className="card border-0">
                    <FieldArray
                      name={`reviews[${index}].reviewers`}
                      render={({ remove, push }) => {
                        const reviewers = reviews[index].reviewers || [];
                        return (
                          <Fragment>
                            <ul
                              className="list-group mb-3"
                              style={{ minHeight: "100px" }}
                            >
                              {reviewers.length === 0 ? (
                                <li
                                  key={`000`}
                                  className="list-group-item border-0 d-flex justify-content-between align-items-center pb-1 pt-1 mb-1 mt-1"
                                >
                                  <span className="text-muted">
                                    <strong> No record is available.</strong>
                                  </span>
                                </li>
                              ) : null}
                              {reviewers.map((reviewer, reviewerIdx) => {
                                return (
                                  <li
                                    key={`${reviewerIdx}`}
                                    className="list-group-item border-0 d-flex justify-content-between align-items-center pb-1 pt-1 mb-1 mt-1"
                                  >
                                    <span>
                                      {`${reviewer.name} (${reviewer.organization})`}
                                    </span>
                                    <div>
                                      <i
                                        className="far fa-trash-alt "
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={() => remove(reviewerIdx)}
                                      />
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                            <div className="mt-auto">
                              <div className="input-group w-100">
                                <Field
                                  type="text"
                                  className="form-control form-control-sm w-25"
                                  name={`reviews[${index}].reviewerName`}
                                  placeholder="Name"
                                />
                                &nbsp;
                                <Field
                                  type="text"
                                  className="form-control form-control-sm w-25"
                                  name={`reviews[${index}].reviewerOrganization`}
                                  placeholder="Organization"
                                />
                                &nbsp;
                                <button
                                  className="btn btn-outline-info btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Add"
                                  type="button"
                                  onClick={() => {
                                    if (
                                      reviews[index].reviewerName &&
                                      reviews[index].reviewerOrganization
                                    ) {
                                      push({
                                        name: reviews[index].reviewerName,
                                        organization:
                                          reviews[index].reviewerOrganization
                                      });

                                      setFieldValue(
                                        `reviews[${index}].reviewerName`,
                                        ""
                                      );
                                      setFieldValue(
                                        `reviews[${index}].reviewerOrganization`,
                                        ""
                                      );
                                    }
                                  }}
                                >
                                  <i className="fas fa-plus " />
                                </button>
                                &nbsp;
                              </div>
                            </div>
                          </Fragment>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Documents</strong>
                  </strong>
                  <FieldArray
                    name="reviewers"
                    render={({ remove, push }) => {
                      return (
                        <Fragment>
                          <div className="card border-0">
                            <ul
                              className="list-group list-group-flush mb-3"
                              style={{ minHeight: "100px" }}
                            >
                              <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                                <span>
                                  <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                  Grant Aggrement.pdf
                                </span>
                                <div>
                                  <i
                                    className="fas fa-download fa-md mr-3"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <i
                                    className="far fa-trash-alt fa-md"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </li>
                              <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                                <span>
                                  <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                  Grant Progress Report.pdf
                                </span>
                                <div>
                                  <i
                                    className="fas fa-download fa-md mr-3"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <i
                                    className="far fa-trash-alt fa-md"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </li>
                              <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                                <span>
                                  <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                  Product Review.pdf
                                </span>
                                <div>
                                  <i
                                    className="fas fa-download fa-md mr-3"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <i
                                    className="far fa-trash-alt fa-md"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </li>
                            </ul>
                            <div className="text-center">
                              <button
                                className="btn btn-sm btn-outline-info"
                                type="button"
                                onClick={() => {}}
                              >
                                <i className="fas fa-upload mr-2" />
                                Upload
                              </button>
                            </div>
                          </div>
                        </Fragment>
                      );
                    }}
                  />
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Reviewers Comments</strong>
                  </strong>

                  <Field
                    component="textarea"
                    className="form-control form-control-sm mt-3"
                    name={`reviews[${index}].reviewersComments`}
                    rows="6"
                    value={reviews[index].reviewersComments || ""}
                  />
                </div>

                <div className="col-12 col-lg-6 col-xl-6">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Incubators Comments</strong>
                  </strong>
                  <Field
                    component="textarea"
                    className="form-control form-control-sm mt-3"
                    name={`reviews[${index}].incubatorsComments`}
                    rows="6"
                    value={reviews[index].incubatorsComments || ""}
                  />
                </div>
              </div>

              <div className="row mt-3 mb-5">
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-sm btn-outline-secondary mr-2"
                    type="button"
                    onClick={() => {
                      const bkupReview = awardBackup.reviews[index];
                      bkupReview.editReview = false;
                      setFieldValue(`reviews[${index}]`, bkupReview);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-sm btn-primary" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const NewReviewForm = props => {
  const { values, touched, errors, setFieldValue } = props;
  if (!values.addedNewReview) {
    return null;
  }

  const error = errors.newReview || {};
  const touch = touched.newReview || {};

  return (
    <Fragment>
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="shadow card flex-fill box-border box-light-blue">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h6 className="d-inline-block">
                    <strong>Add New Review</strong>
                  </h6>
                </div>
              </div>

              <div className="row mb-3 mt-2">
                <div className="col-md-12">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Basic Information</strong>
                  </strong>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-md-12">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Review Title
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touch,
                          error,
                          "reviewTitle"
                        )}`}
                        name={`newReview.reviewTitle`}
                      />

                      <ErrorComponent
                        fieldName={`reviewTitle`}
                        touch={touch}
                        error={error}
                      />
                    </div>

                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Review Date
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <DatePicker
                        value={
                          values.newReview.reviewDate
                            ? getDisplayDate(values.newReview.reviewDate)
                            : ""
                        }
                        selected={values.newReview.reviewDate}
                        onChange={e => {
                          setFieldValue(`newReview.reviewDate`, e);
                        }}
                        utcOffset={0}
                        placeholderText="dd-mm-yyyy"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touch,
                          error,
                          "reviewDate"
                        )}`}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <ErrorComponent
                        fieldName={`reviewDate`}
                        touch={touch}
                        error={error}
                        datePicker={true}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Disbursed Amt
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touch,
                          error,
                          "amountDisburesd"
                        )}`}
                        name={`newReview.amountDisburesd`}
                      />
                      <ErrorComponent
                        fieldName={`amountDisburesd`}
                        touch={touch}
                        error={error}
                      />
                    </div>
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Status
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        component="select"
                        type="text"
                        className={`custom-select custom-select-sm ${getErrorCssClassName(
                          touch,
                          error,
                          "status"
                        )}`}
                        name={`newReview.status`}
                      >
                        <option value="">Select...</option>
                        {AWARD_STATUS.map((opt, i) => (
                          <option key={i} value={opt.key}>
                            {opt.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorComponent
                        fieldName={`status`}
                        touch={touch}
                        error={error}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Reviewers</strong>
                  </strong>

                  <div className="card border-0">
                    <FieldArray
                      name={`newReview.reviewers`}
                      render={({ remove, push }) => {
                        const reviewers = values.newReview.reviewers || [];
                        return (
                          <Fragment>
                            <ul
                              className="list-group mb-3"
                              style={{ minHeight: "100px" }}
                            >
                              {reviewers.length === 0 ? (
                                <li
                                  key={`000`}
                                  className="list-group-item border-0 d-flex justify-content-between align-items-center pb-1 pt-1 mb-1 mt-1"
                                >
                                  <span className="text-muted">
                                    <strong> No record is available.</strong>
                                  </span>
                                </li>
                              ) : null}
                              {reviewers.map((reviewer, reviewerIdx) => {
                                return (
                                  <li
                                    key={`${reviewerIdx}`}
                                    className="list-group-item border-0 d-flex justify-content-between align-items-center pb-1 pt-1 mb-1 mt-1"
                                  >
                                    <span>
                                      {`${reviewer.name} (${reviewer.organization})`}
                                    </span>
                                    <div>
                                      <i
                                        className="far fa-trash-alt "
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={() => remove(reviewerIdx)}
                                      />
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                            <div className="mt-auto">
                              <div className="input-group w-100">
                                <Field
                                  type="text"
                                  className="form-control form-control-sm w-25"
                                  name={`newReview.reviewerName`}
                                  placeholder="Name"
                                />
                                &nbsp;
                                <Field
                                  type="text"
                                  className="form-control form-control-sm w-25"
                                  name={`newReview.reviewerOrganization`}
                                  placeholder="Organization"
                                />
                                &nbsp;
                                <button
                                  className="btn btn-outline-info btn-sm"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Add"
                                  type="button"
                                  onClick={() => {
                                    if (
                                      values.newReview.reviewerName &&
                                      values.newReview.reviewerOrganization
                                    ) {
                                      push({
                                        name: values.newReview.reviewerName,
                                        organization:
                                          values.newReview.reviewerOrganization
                                      });

                                      setFieldValue(
                                        `newReview.reviewerName`,
                                        ""
                                      );
                                      setFieldValue(
                                        `newReview.reviewerOrganization`,
                                        ""
                                      );
                                    }
                                  }}
                                >
                                  <i className="fas fa-plus " />
                                </button>
                                &nbsp;
                              </div>
                            </div>
                          </Fragment>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Documents</strong>
                  </strong>
                  <FieldArray
                    name="reviewers"
                    render={({ remove, push }) => {
                      return (
                        <Fragment>
                          <div className="card border-0">
                            <ul
                              className="list-group list-group-flush mb-3"
                              style={{ minHeight: "100px" }}
                            >
                              <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                                <span>
                                  <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                  Grant Aggrement.pdf
                                </span>
                                <div>
                                  <i
                                    className="fas fa-download fa-md mr-3"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <i
                                    className="far fa-trash-alt fa-md"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </li>
                              <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                                <span>
                                  <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                  Grant Progress Report.pdf
                                </span>
                                <div>
                                  <i
                                    className="fas fa-download fa-md mr-3"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <i
                                    className="far fa-trash-alt fa-md"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </li>
                              <li className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                                <span>
                                  <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                  Product Review.pdf
                                </span>
                                <div>
                                  <i
                                    className="fas fa-download fa-md mr-3"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <i
                                    className="far fa-trash-alt fa-md"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </li>
                            </ul>
                            <div className="text-center">
                              <button
                                className="btn btn-sm btn-outline-info"
                                type="button"
                                onClick={() => {}}
                              >
                                <i className="fas fa-upload mr-2" />
                                Upload
                              </button>
                            </div>
                          </div>
                        </Fragment>
                      );
                    }}
                  />
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Reviewers Comments</strong>
                  </strong>

                  <Field
                    component="textarea"
                    className={`form-control form-control-sm mt-3 ${getErrorCssClassName(
                      touch,
                      error,
                      "reviewersComments"
                    )}`}
                    name={`newReview.reviewersComments`}
                    rows="6"
                    value={values.newReview.reviewersComments || ""}
                  />
                  <ErrorComponent
                    fieldName={`reviewersComments`}
                    touch={touch}
                    error={error}
                  />
                </div>

                <div className="col-12 col-lg-6 col-xl-6">
                  <strong className="d-block border-bottom pb-2">
                    <strong>Incubators Comments</strong>
                  </strong>
                  <Field
                    component="textarea"
                    className={`form-control form-control-sm mt-3 ${getErrorCssClassName(
                      touch,
                      error,
                      "reviewTitle"
                    )}`}
                    name={`newReview.incubatorsComments`}
                    rows="6"
                    value={values.newReview.incubatorsComments || ""}
                  />
                  <ErrorComponent
                    fieldName={`incubatorsComments`}
                    touch={touch}
                    error={error}
                  />
                </div>
              </div>

              <div className="row mt-3 mb-5">
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-sm btn-outline-secondary mr-2"
                    type="button"
                    onClick={() => {
                      values.addedNewReview = false;
                      values.newReview = {
                        reviewTitle: "",
                        reviewDate: null,
                        amountDisburesd: "",
                        status: "",
                        reviewersComments: "",
                        incubatorsComments: "",
                        reviewerName: "",
                        reviewerOrganization: "",
                        reviewers: [],
                        documents: []
                      };

                      setFieldValue(`values.addedNewReview`, values);
                    }}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-sm btn-primary" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const GRANT_PROGRESS_SCHEMA = Yup.object().shape({
  newReview: Yup.object().when("addedNewReview", {
    is: true,
    then: Yup.object().shape({
      reviewTitle: Yup.string()
        .label("Review Title")
        .required(),
      reviewDate: Yup.date()
        .label("Review Date")
        .required(),
      amountDisburesd: Yup.string()
        .label("Amount Disbursed")
        .required(),
      status: Yup.string()
        .label("Status")
        .required(),
      reviewersComments: Yup.string()
        .label("Reviewer Comments")
        .required(),
      incubatorsComments: Yup.string()
        .label("IncubatorsComment")
        .required()
    })
  })
});

class GrantProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      award: {
        _id: "",
        incubatee: "",
        applicationId: "",
        projectTitle: "",
        grantName: "",
        granteeType: "",
        grantee: "",
        vertical: "",
        sanctionedAmount: "",
        amountDisburesd: "",
        startDate: null,
        endDate: null,
        revisedEndDate: null,
        status: "",
        notes: [],
        documents: [],
        reviews: [],

        addedNewReview: false,
        newReview: {
          reviewTitle: "",
          reviewDate: null,
          amountDisburesd: "",
          status: "",
          reviewersComments: "",
          incubatorsComments: "",
          reviewerName: "",
          reviewerOrganization: "",
          reviewers: [],
          documents: []
        }
      },

      awardBackup: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAward = id => {
    if (this.props.location.state && this.props.location.state.created) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: `Award is created successfully.`
      });
      window.scrollTo(0, 0);
    }

    const url = `${GRANT_AWARDS_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        const award = { ...this.state.award };

        award._id = data._id || "";
        award.applicationId = data.applicationId || "";
        award.incubatee = data.incubatee || "";
        award.projectTitle = data.projectTitle || "";
        award.grantName = data.grantName || "";
        award.granteeType = data.granteeType || "";
        award.grantee = data.grantee || "";
        award.vertical = data.vertical || "";
        award.sanctionedAmount = data.sanctionedAmount || "";
        award.amountDisburesd = data.amountDisburesd || "";
        award.startDate = convertNumberToDate(data.startDate) || null;
        award.endDate = convertNumberToDate(data.endDate) || null;
        award.revisedEndDate = convertNumberToDate(data.revisedEndDate) || null;
        award.status = data.status || "";
        // award.notes = data.notes || []; // wip#

        const reviews = data.reviews.map(review => {
          const newReview = { ...review };
          newReview.reviewDate = convertNumberToDate(newReview.reviewDate);
          newReview.editReview = false;
          return newReview;
        });

        award.reviews = reviews;
        award.addedNewReview = false;
        award.newReview = {
          reviewTitle: "",
          reviewDate: null,
          amountDisburesd: "",
          status: "",
          reviewersComments: "",
          incubatorsComments: "",
          reviewerName: "",
          reviewerOrganization: "",
          reviewers: [],
          documents: []
        };

        let awardBackup = { ...award };

        this.setState({ award, awardBackup });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getRequest = values => {
    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "documents", docs[i]);
    }

    let startDate = new Date(values.startDate);
    startDate = convertDateToNumber(startDate);

    let endDate = new Date(values.endDate);
    endDate = convertDateToNumber(endDate);

    const reviews = values.reviews.map(review => {
      delete review.reviewerName;
      delete review.reviewerOrganization;

      const newReview = { ...review };
      let reviewDate = new Date(newReview.reviewDate);
      newReview.reviewDate = convertDateToNumber(reviewDate);
      return newReview;
    });

    if (values.addedNewReview) {
      let newReview = { ...values.newReview };
      newReview.reviewDate = convertDateToNumber(newReview.reviewDate);
      reviews.push(newReview);
    }

    const request = {
      incubatee: values.incubatee._id,
      applicationId: values.applicationId,
      projectTitle: values.projectTitle,
      grantName: values.grantName._id,
      granteeType: values.granteeType,
      grantee: values.grantee._id,
      vertical: values.vertical,
      sanctionedAmount: values.sanctionedAmount,
      amountDisburesd: values.amountDisburesd,
      startDate: startDate,
      endDate: endDate,
      revisedEndDate: endDate,
      status: values.status,
      notes: [{ remark: values.notes }],
      reviews: reviews
    };

    formData.append("message", JSON.stringify(request));
    return formData;
  };

  updateAward = (values, setSubmitting) => {
    setSubmitting(true);

    const updateRequest = this.getRequest(values);

    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}`;

    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        setSubmitting(false);
        this.getAward(data._id);
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Fund Award Reviews "${data.applicationId}" is updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSubmit = (values, { setSubmitting }) => {
    const award = this.state.award;
    if (award._id) {
      // console.log("submit : values:", JSON.stringify(values, null, 2));
      this.updateAward(values, setSubmitting);
    }
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getAward(id);
    } else {
      const award = { ...this.state.award };
      award.formTitle = "New Award Form";
      this.setState({ award });
    }
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const props = {
      initialValues: this.state.award,
      validationSchema: GRANT_PROGRESS_SCHEMA,
      handleSubmit: this.handleSubmit,
      awardBackup: this.state.awardBackup
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={props.initialValues}
          validationSchema={props.validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            props.handleSubmit(values, { setSubmitting })
          }
          render={({
            values,
            onSubmit,
            isSubmitting,
            errors,
            touched,
            setFieldValue
          }) => {
            const formProps = {
              values,
              onSubmit,
              isSubmitting,
              errors,
              touched,
              setFieldValue,
              awardBackup: props.awardBackup
            };
            return (
              <Form>
                <GrantProgressForm {...formProps} />
                <NewReviewForm {...formProps} />
                <PreviewForm {...formProps} />
              </Form>
            );
          }}
        />
      </Fragment>
    );
  }
}

export default GrantProgress;
