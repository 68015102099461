import { TOGGLE_NAVBAR } from "./ToggleNavbarAction";

const initialState = {
  navBarShow: false
};

const ToggleNavbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NAVBAR:
      return {
        ...state,
        navBarShow: !action.navBarShow
      };

    default:
      return state;
  }
};

export default ToggleNavbarReducer;
