import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../common/HttpUtil";
import {
  INCUBATEES_ONGOING_COUNT,
  PHYSICAL_INCUBATEES_ONGOING_COUNT,
  ANNUAL_TURNOVER_STATS_API,
  JOBS_GENERATED_STATS_API,
  PARTITION_SPACE_USAGE_API
} from "../common/Constants";
import LoadingComponent from "../common/spinnerloader/LoadingComponent";
import { numberFormat, roundOf } from "../common/LookupConstants";

let pow = Math.pow,
  floor = Math.floor,
  abs = Math.abs,
  log = Math.log;
let abbrev = "KMB"; // could be an array of strings: [' m', ' Mo', ' Md']

function round(n, precision) {
  let prec = Math.pow(10, precision);
  return Math.round(n * prec) / prec;
}

function shorterRupeesFormat(n) {
  // Ref
  // https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k
  let base = floor(log(abs(n)) / log(1000));
  let suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? [round(n / pow(1000, base), 2), suffix] : "" + n;
}

class CoreStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incubatees: null,
      physicalIncubatees: null,
      annualTurnover: null,
      jobsGenerated: null,
      partitionSpace: null
    };
  }

  getIncubateeCountByOngoingStats = () => {
    const url = INCUBATEES_ONGOING_COUNT;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          incubatees: {
            errorMessage: null,
            count: data.count
          }
        });
      },
      (data, status) => {
        this.setState({
          incubatees: {
            errorMessage: data.message,
            count: null
          }
        });
      },
      error => {
        this.setState({
          incubatees: {
            errorMessage: "Unable to Fetch data",
            count: null
          }
        });
      }
    );
  };

  getOngoingPhysicalIncubateeCount = () => {
    HttpUtil.get(
      PHYSICAL_INCUBATEES_ONGOING_COUNT,
      {},
      data => {
        this.setState({
          physicalIncubatees: {
            errorMessage: null,
            count: data.count
          }
        });
      },
      (data, status) => {
        this.setState({
          physicalIncubatees: {
            errorMessage: data.message,
            count: null
          }
        });
      },
      error => {
        this.setState({
          physicalIncubatees: {
            errorMessage: "Unable to Fetch data",
            count: null
          }
        });
      }
    );
  };

  handleChangeFy = e => {
    let targetValue = e.target.value;
    const annualTurnover = { ...this.state.annualTurnover };
    annualTurnover.fy = annualTurnover.data[targetValue];
    annualTurnover.index = targetValue;
    this.setState({ annualTurnover });
  };

  getAnnualTurnoverStats = () => {
    const url = ANNUAL_TURNOVER_STATS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        if (data.length >= 0) {
          this.setState({
            annualTurnover: {
              errorMessage: null,
              fy: data[0],
              index: 0,
              data
            }
          });
        } else {
          this.setState({
            annualTurnover: {
              errorMessage: "No data",
              data: []
            }
          });
        }
      },
      (data, status) => {
        this.setState({
          annualTurnover: {
            errorMessage: data.message,
            data: []
          }
        });
      },
      error => {
        this.setState({
          annualTurnover: {
            errorMessage: "Unable to Fetch data",
            data: []
          }
        });
      }
    );
  };

  getJobsGeneratedStats = () => {
    const url = JOBS_GENERATED_STATS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        if (data && data.total >= 0) {
          this.setState({
            jobsGenerated: {
              errorMessage: null,
              total: data.total || 0,
              incubatees: data.incubatees || 0
            }
          });
        } else {
          this.setState({
            jobsGenerated: {
              errorMessage: "No data",
              total: 0,
              incubatees: 0
            }
          });
        }
      },
      (data, status) => {
        this.setState({
          jobsGenerated: {
            errorMessage: data.message,
            total: null,
            incubatees: null
          }
        });
      },
      error => {
        this.setState({
          jobsGenerated: {
            errorMessage: "Unable to Fetch data",
            total: null,
            incubatees: null
          }
        });
      }
    );
  };

  getPartitionSpaceStats = () => {
    const url = PARTITION_SPACE_USAGE_API;
    HttpUtil.get(
      url,
      {},
      data => {
        if (data) {
          let allocatedSpace =
            data.partitionUsedSpace + data.partitionProvisionedSpace;

          let provisionedSpace =
            (data.partitionUsedSpace / allocatedSpace) * 100;

          this.setState({
            partitionSpace: {
              errorMessage: null,
              totalArea: data.totalArea || 0,
              usableArea: data.usableArea || 0,
              partitionUsedSpace: data.partitionUsedSpace,
              allocatedSpace: allocatedSpace,
              partitionProvisionedSpace: roundOf(provisionedSpace)
            }
          });
        } else {
          this.setState({
            partitionSpace: {
              errorMessage: "No data",
              totalArea: 0,
              usableArea: 0,
              partitionUsedSpace: 0,
              partitionProvisionedSpace: 0
            }
          });
        }
      },
      (data, status) => {
        this.setState({
          partitionSpace: {
            errorMessage: data.message,
            totalArea: null,
            usableArea: null,
            partitionUsedSpace: null,
            partitionProvisionedSpace: null
          }
        });
      },
      error => {
        this.setState({
          partitionSpace: {
            errorMessage: "Unable to Fetch data",
            totalArea: null,
            usableArea: null,
            partitionUsedSpace: null,
            partitionProvisionedSpace: null
          }
        });
      }
    );
  };

  componentDidMount() {
    this.getIncubateeCountByOngoingStats();
    this.getOngoingPhysicalIncubateeCount();
    this.getAnnualTurnoverStats();
    this.getJobsGeneratedStats();
    this.getPartitionSpaceStats();
  }

  render() {
    const {
      incubatees,
      physicalIncubatees,
      annualTurnover,
      jobsGenerated,
      partitionSpace
    } = this.state;

    let turnover = null;
    if (
      annualTurnover &&
      annualTurnover.fy &&
      annualTurnover.errorMessage === null
    ) {
      let [amount, suffix] = shorterRupeesFormat(
        annualTurnover.fy.turnover || 0
      );

      turnover = (
        <Fragment>
          {amount}
          {suffix !== "0" ? <small>{suffix}</small> : null}
        </Fragment>
      );
    }

    return (
      <div>
        <div
          className="card elevate mb-3"
          // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
        >
          <div className="card-body">
            <div className="row b-1 ml-3 mr-3">
              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-3 pl-0 pr-0">
                <div className="media" style={{ position: "relative" }}>
                  <div className="db-data-icon-wrapper rounded-circle mt-3">
                    <div
                      className="db-data-icon-wrapper-bg opacity-10"
                      style={{ backgroundColor: "#FFE483" }}
                    />
                    <i className="fas fa-rocket fa-4x text-dark" />
                  </div>
                  <div className="media-body ml-2">
                    <div className="db-data-result-heading">
                      Startups Supported
                    </div>
                    {incubatees === null ? (
                      <div className="float-left mt-1 text-primary">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <Fragment>
                        {incubatees.errorMessage !== null ? (
                          <div className="text-danger font-weight-normal mt-2">
                            {incubatees.errorMessage}
                          </div>
                        ) : (
                          <Fragment>
                            <div className="db-data-result-number">
                              <Link
                                to="/reports/incubatee/info"
                                style={{ color: "inherit" }}
                              >
                                {incubatees.count}
                              </Link>
                            </div>
                            <div
                              className="db-data-result-description text-focus opacity-8"
                              style={{ position: "absolute", top: 70 }}
                            >
                              <div className="d-inline text-danger pr-1">
                                <span className="pl-1 pr-1">Active</span>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-3 pl-0 pr-0">
                <div className="media" style={{ position: "relative" }}>
                  <div className="db-data-icon-wrapper rounded-circle mt-3">
                    <div
                      className="db-data-icon-wrapper-bg opacity-10"
                      style={{ backgroundColor: "#6FC4FF" }}
                    />
                    <i className="fas fa-briefcase fa-4x text-dark" />
                  </div>
                  <div className="media-body ml-2">
                    <div className="db-data-result-heading">
                      Physical Incubatees
                    </div>

                    {physicalIncubatees === null ? (
                      <div className="float-left mt-1 text-primary">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <Fragment>
                        {physicalIncubatees.errorMessage !== null ? (
                          <div className="text-danger font-weight-normal mt-2">
                            {physicalIncubatees.errorMessage}
                          </div>
                        ) : (
                          <Fragment>
                            <div className="db-data-result-number">
                              <Link
                                to="/reports/incubatee/info?group=residential-status"
                                style={{ color: "inherit" }}
                              >
                                {physicalIncubatees.count}
                              </Link>
                            </div>
                            <div
                              className="db-data-result-description text-focus opacity-8"
                              style={{ position: "absolute", top: 70 }}
                            >
                              <div className="d-inline text-danger pr-1">
                                <span className="pl-1 pr-1">Active</span>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-3 pl-0 pr-0">
                <div className="media" style={{ position: "relative" }}>
                  <div className="db-data-icon-wrapper rounded-circle mt-3">
                    <div
                      className="db-data-icon-wrapper-bg opacity-10"
                      style={{ backgroundColor: "#FFAECF" }}
                    />
                    <i className="fas fa-users fa-4x text-dark" />
                  </div>
                  <div className="media-body ml-2">
                    <div className="db-data-result-heading">Jobs Generated</div>
                    {jobsGenerated === null ? (
                      <div className="float-left mt-1 text-primary">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <Fragment>
                        {jobsGenerated.errorMessage !== null ? (
                          <div className="text-danger font-weight-normal mt-2">
                            {jobsGenerated.errorMessage}
                          </div>
                        ) : (
                          <Fragment>
                            <div className="db-data-result-number">
                              <Link
                                to="/reports/incubatee/jobs-generated-info"
                                style={{ color: "inherit" }}
                              >
                                {jobsGenerated.total}
                              </Link>
                            </div>
                            <div
                              className="db-data-result-description text-focus opacity-8"
                              style={{ position: "absolute", top: 70 }}
                            >
                              <div className="d-inline text-danger pr-1">
                                <span className="pl-1">
                                  {jobsGenerated.incubatees}
                                </span>
                              </div>
                              Incubatees
                            </div>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-3 pl-0 pr-0">
                <div className="media" style={{ position: "relative" }}>
                  <div className="db-data-icon-wrapper rounded-circle mt-3">
                    <div
                      className="db-data-icon-wrapper-bg opacity-10"
                      style={{ backgroundColor: "#86F8A8" }}
                    />
                    <i className="fas fa-rupee-sign fa-4x text-dark" />
                  </div>
                  <div className="media-body ml-2">
                    <div className="db-data-result-heading">
                      Collective Turnover
                    </div>

                    {annualTurnover === null ? (
                      <div className="float-left mt-1 text-primary">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <Fragment>
                        {annualTurnover.errorMessage ? (
                          <div className="text-danger font-weight-normal mt-2">
                            {annualTurnover.errorMessage}
                          </div>
                        ) : (
                          <Fragment>
                            <div className="db-data-result-number">
                              {/* {turnover || "--"} */}
                              <Link
                                to="/reports/incubatee/annual-turnover-info"
                                style={{ color: "inherit" }}
                              >
                                {turnover}
                              </Link>
                            </div>
                            <div style={{ position: "absolute", top: 70 }}>
                              <div className="d-inline-block text-danger">
                                {`${(annualTurnover.fy &&
                                  annualTurnover.fy.incubateesCount) ||
                                  "--"} `}
                              </div>
                              &nbsp;Orgs
                              <div className="d-inline-block text-danger">
                                <select
                                  name="fy"
                                  value={annualTurnover.index}
                                  onChange={this.handleChangeFy}
                                  className="select-transparent text-danger pl-1"
                                >
                                  {annualTurnover.data.map((annual, i) => {
                                    return (
                                      <option key={i} value={i}>
                                        {annual._id}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              [FY]
                            </div>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="elevate mt-3 space-info">
          <div className="pt-3 pb-0 pr-3 pl-3 clearfix">
            <span className="text-white font-weight-bold">Facility Usage</span>

            <Link
              className="btn btn-sm space-white-btn shadow float-right"
              to="/reports/facility/consolidated-facility-usage"
            >
              View Details
            </Link>
          </div>
          <div className="space-info-container">
            <div className="space-info-content">
              <div className="mb-lg-2 space-info-title">TOTAL SPACE </div>
              <div>
                {partitionSpace === null ? (
                  <div className="float-left mt-1 text-white">
                    <LoadingComponent />
                  </div>
                ) : (
                  <Fragment>
                    {partitionSpace.errorMessage !== null ? (
                      <div className="text-danger font-weight-normal mt-2">
                        {partitionSpace.errorMessage}
                      </div>
                    ) : (
                      <h3 className="mb-0 text-white d-flex align-items-center">
                        {numberFormat(partitionSpace.totalArea)}
                        <small className="ml-1 mt-1">sqft</small>
                      </h3>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="space-info-content">
              <div className="mb-lg-2 space-info-title">USABLE SPACE</div>
              <div>
                {partitionSpace === null ? (
                  <div className="float-left mt-1 text-white">
                    <LoadingComponent />
                  </div>
                ) : (
                  <Fragment>
                    {partitionSpace.errorMessage !== null ? (
                      <div className="text-danger font-weight-normal mt-2">
                        {partitionSpace.errorMessage}
                      </div>
                    ) : (
                      <h3 className="mb-0 text-white d-flex align-items-center">
                        {numberFormat(partitionSpace.usableArea)}
                        <small className="ml-1 mt-1">sqft</small>
                      </h3>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="space-info-content">
              <div className="mb-lg-2 space-info-title">ALLOCATED SPACE</div>
              <div>
                {partitionSpace === null ? (
                  <div className="float-left mt-1 text-white">
                    <LoadingComponent />
                  </div>
                ) : (
                  <Fragment>
                    {partitionSpace.errorMessage !== null ? (
                      <div className="text-danger font-weight-normal mt-2">
                        {partitionSpace.errorMessage}
                      </div>
                    ) : (
                      <h3 className="mb-0 text-white d-flex align-items-center">
                        {numberFormat(partitionSpace.allocatedSpace)}
                        <small className="ml-1 mt-1">sqft</small>
                      </h3>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="space-info-content">
              <div className="mb-lg-2 space-info-title">USED SPACE</div>
              <div>
                {partitionSpace === null ? (
                  <div className="float-left mt-1 text-white">
                    <LoadingComponent />
                  </div>
                ) : (
                  <Fragment>
                    {partitionSpace.errorMessage !== null ? (
                      <div className="text-danger font-weight-normal mt-2">
                        {partitionSpace.errorMessage}
                      </div>
                    ) : (
                      <h3 className="mb-0 text-white d-flex align-items-center">
                        {numberFormat(partitionSpace.partitionUsedSpace)}
                        <small className="ml-1 mt-1">sqft</small>
                      </h3>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="space-info-content">
              <div className="mb-lg-2 space-info-title">
                ALLOCATED SPACE USAGE
              </div>
              <div>
                {partitionSpace === null ? (
                  <div className="float-left mt-1 text-white">
                    <LoadingComponent />
                  </div>
                ) : (
                  <Fragment>
                    {partitionSpace.errorMessage !== null ? (
                      <div className="text-danger font-weight-normal mt-2">
                        {partitionSpace.errorMessage}
                      </div>
                    ) : (
                      <h3 className="mb-0 text-white">
                        {numberFormat(partitionSpace.partitionProvisionedSpace)}
                        <small className="ml-1 mt-1">%</small>
                      </h3>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="elevate mt-3 space-info-container">
          <div className="space-info-content">
            <div className="mb-lg-2 space-info-title"></div>
            
          </div>

          <div className="space-info-content">
            <div className="mb-lg-2 space-info-title"></div>
            
          </div>

          <div className="space-info-content">
            <div className="mb-lg-2 space-info-title"></div>
            
          </div>

          <div className="space-info-content">
            <div className="mb-lg-2 space-info-title"></div>
            
          </div>

          <div className="space-info-content">
            <div className="mb-lg-2 space-info-title">
              
            </div>
            
          </div>
        </div> */}
      </div>
    );
  }
}

export default CoreStats;
