import React, { Component, Fragment } from "react";

class NidhiEir extends Component {
  getData = data => {
    return data ? data : "--";
  };
  render() {
    const { form } = this.props;
    const GENDER = {
      M: "Male",
      F: "Female",
      O: "Others",
      T: "Transgender"
    };

    return (
      <Fragment>
        {/* Application Information */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Application Information</h6>
              </div>
              <div className="row pl-1">
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>{`${form.source} ID`}</th>
                        <td>{form.sourceId}</td>
                      </tr>
                      <tr>
                        <th>{`${form.source} Reference`}</th>
                        <td>{form.sourceReference}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>{`${form.source} Status`}</th>
                        <td>{form.sourceStatus}</td>
                      </tr>
                      <tr>
                        <th>Application Status</th>
                        <td>{form.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Basic Information */}
        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Basic Information</h6>
              </div>
              <div className="row pl-2">
                <div className="col-6">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name </strong>
                        </td>
                        <td>{this.getData(form.name)}</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Email</strong>
                        </td>
                        <td>{this.getData(form.email)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Mobile</strong>
                        </td>
                        <td>{this.getData(form.mobile)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Gender</strong>
                        </td>
                        <td>{this.getData(GENDER[form.gender])}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Age</strong>
                        </td>
                        <td>{this.getData(form.age)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Aadhaar</strong>
                        </td>
                        <td>{this.getData(form.aadhaar)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Postal Address</strong>
                        </td>
                        <td>{this.getData(form.postalAddress)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Highest Education Qualification</strong>
                        </td>
                        <td>{this.getData(form.education)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Any experiences relating to entrepreneurship,
                            leadership,fund raising, organizing activities /
                            events etc
                          </strong>
                        </td>
                        <td>{this.getData(form.experience)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* The Application's Technology / Business Idea */}
        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">
                  The Application's Technology / Business Idea
                </h6>
              </div>
              <div className="row pl-2">
                <div className="col-12">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>
                          <strong> Title of the Project</strong>
                        </td>
                        <td>{form.projectTitle}</td>
                      </tr>

                      <tr>
                        <td>
                          <strong>
                            Please describe the technology for which you are
                            seeking market opportunity or market for which you
                            are seeking technology opportunities
                          </strong>
                        </td>
                        <td>{form.technology}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Technology Business Ideas with larger technology
                            uncertainties and / or long gestation periods
                          </strong>
                        </td>
                        <td>
                          {form.riskyTechLongGestation ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Technology Business Ideas leveraging technology or
                            IP from publicly funded research or academic
                            organizations
                          </strong>
                        </td>
                        <td>
                          {form.publicAcademicTechIp ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Technology Business Ideas with considerable social
                            impact
                          </strong>
                        </td>
                        <td>
                          {form.bigSocialImpact ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">
                  Checklist for Essential Criteria
                </h6>
              </div>
              <div className="row ">
                <div className="col-12">
                  <table className="table table-sm table-striped mb-0">
                    <tbody>
                      <tr>
                        <td style={{ width: "90%" }}>
                          Applicant confirms that he/ she has not been the
                          recipient of the NIDHI-EIR previously. NIDHI-EIR can
                          be used only once.
                        </td>
                        <td>
                          {form.noPrevNidhiEir ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Applicant is planning to pursue NIDHI-EIR full time
                          with no other concurrent committments
                        </td>
                        <td>
                          {form.fullTimeNidhiEir ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Applicant confirms that he/she will not be in receipt
                          of any other remuneration or fellowship during the
                          duration of NIDHI-EIR
                        </td>
                        <td>
                          {form.noOtherRenumeration ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Applicant confirms that he/she is fully committed to
                          exploring a business idea. NIDHI-EIR support recipient
                          should not treat this support as a stop gap
                          arrangement to support them in their academic pursuits
                          or transition between jobs
                        </td>
                        <td>
                          {form.exploreBusinessIdea ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Applicant confirms that he/she is not the promoter or
                          significant (>10%) shareholder / beneficiary of
                          another company at the time of applying for and
                          receiving the NIDHI-EIR support
                        </td>
                        <td>
                          {form.nonPromoter ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          The NIDHI-EIR has or is planning to register for the
                          pre-incubation or incubation program at PEPI for the
                          entire duration of NIDHI-EIR support
                        </td>
                        <td>
                          {form.regIncubationSupport ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Declaration Information */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Declaration Information</h6>
              </div>
              <div className="row pl-1">
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>Declaration Date</th>
                        <td>{this.getData(form.declarationDate)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>Declaration Place</th>
                        <td>{this.getData(form.declarationPlace)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default NidhiEir;
