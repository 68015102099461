const IdDocSchema = {
  title: "Identification Documents",
  type: "array",
  minItems: 1,
  items: {
    title: "ID Document",
    type: "object",
    required: ["type", "number"],
    properties: {
      type: {
        type: "string",
        title: "Type",
        anyOf: [
          {
            type: "string",
            title: "PAN",
            enum: ["PAN"]
          },
          {
            type: "string",
            title: "Aadhaar",
            enum: ["AADHAAR"]
          },
          {
            type: "string",
            title: "GSTN",
            enum: ["GSTN"]
          }
        ]
      },
      number: {
        type: "string",
        title: "Number"
      },
      document: {
        title: "ID Proof Document",
        type: "string",
        format: "data-url"
      }
    }
  }
};

const IdDocUiSchema = {};

export { IdDocSchema, IdDocUiSchema };
