import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import LeaseForm from "./LeaseForm";
import HttpUtil from "../common/HttpUtil";
import {
  LEASE_API,
  LEASE_ACTIONS_API,
  LEASE_TERMINATIONS_API
} from "../common/Constants";
import {
  getMonths,
  convertDateToNumber,
  convertNumberToDate,
  addMonths
} from "./DateUtil";
import AlertComponent from "../common/AlertComponent";
import Download from "downloadjs";
import { checkEmptyValue } from "../common/LookupConstants";
import ErrorPage from "../common/error.page";

const LEASE_UPDATE = 1;
const LEASE_RENEWAL = 2;
const LEASE_TERMINATION = 3;

class Lease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Agreement
      _id: "",
      incubatee: "",
      agreementDate: "",
      leaseStartDate: "",
      leaseEndDate: "",
      leaseDuration: "",
      leaseType: "REGULAR",

      //Facility Information
      // auto lookup
      campus: "",
      building: "",
      floors: "",
      partition: "",

      //Partition Information
      seatCount: 0,
      leasePartitions: [],
      estimatedRent: 0,
      actualRent: 0,
      duplicatePartitions: false,

      // Lease History
      leaseHistory: [],

      //Payment Information
      deposit: 0,
      nonRefundableFee: 0,
      monthlyRent: 0,
      additionalMonthlyFee: 0,
      discountAmount: 0,
      discounts: [],
      removeDiscount: false,
      confirmAddAdvance: true, // default set true,
      advanceBalance: 0,

      //Additional Information
      leaseApprovedBy: "",
      scannedCopy: [],
      notes: [],
      remarks: "",
      leaseTerminationDate: "",
      scannedCopyList: [],
      scannedCopyLimit: 5,

      actionStatus: "",
      actionStage: "",
      action: "",
      actions: [],
      disabledField: {},

      //redirect to edit partition with id
      editLease: false,
      editLeaseId: "",

      //for input error alert
      inputError: {},

      //Default alert
      alertType: "",
      showAlert: "",
      alertColor: "",
      alertMessage: "",
      confirm: false,
      alertWithTitle: "",
      permanentFailure: false,

      newLease: null,
      previousLease: null,

      renewalMetrics: {
        totalEmployee: null,
        femaleEmployee: null,
        annualTurnover: null,
        annualTurnoverFy: null
      },

      empInfo: "",
      finInfo: "",

      leaseTermination: null,
      previousLocation: null,

      // redirect to
      renewalRequestId: null,
      renewalMsg: "",

      actionBtnShow: false
    };
  }

  loadUrlLocationState = () => {
    const location = this.props.location;

    if (!location) {
      return;
    }

    if (location.state !== undefined && location.state.renewed) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: location.state.message
      });
      window.scrollTo(0, 0);
    }
  };

  componentDidMount = () => {
    this.loadUrlLocationState();
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getLeaseById(id);
    } else {
      this.setState({ newLease: true });
      this.getLeaseActions();
    }
  };

  getLeaseActions = () => {
    let url = LEASE_ACTIONS_API;
    const authToken = this.props.authToken;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken
    };

    return HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({
          actions: data
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleLeaseDuration = e => {
    const leaseDuration = Number(e.target.value);

    const inputError = {};
    if (leaseDuration < 0 || 24 < leaseDuration) {
      inputError["leaseDuration"] = true;
    }
    const invalid = Object.values(inputError).some(item => item);

    if (invalid) {
      this.setState({ inputError, leaseDuration });
      return;
    }

    const { leaseStartDate, leaseEndDate } = this.state;
    let endDate = leaseEndDate;
    if (leaseStartDate && leaseDuration > 0) {
      const offset = leaseDuration;
      endDate = addMonths(leaseStartDate, offset);
    }

    this.setState({ leaseDuration, inputError, leaseEndDate: endDate });
  };

  // input onchange event
  onChange = e => {
    if (e.target.type === "number" && isNaN(e.target.value)) {
      return;
    }

    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else if (e.target.type === "file") {
      const { scannedCopyList, scannedCopyLimit } = this.state;

      let listCount = scannedCopyList.length;
      let filesCount = e.target.files.length;
      let max = listCount + filesCount;

      let inputError = {};

      if (scannedCopyLimit < max) {
        inputError[e.target.name] = true;
        return this.setState({
          inputError: inputError
        });
      }
      inputError[e.target.name] = false;
      this.setState({
        [e.target.name]: e.target.files,
        inputError: inputError
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

  handleDeposit = e => {
    const targetName = e.target.name;
    const targetValue = Number(e.target.value);
    const advanceBalance = this.state.advanceBalance || 0;
    console.log(`name ${targetName} value ${targetValue}`);
    console.log(`advanceBalance ${advanceBalance} `);
    let confirmAddAdvance = true;
    if (targetValue > 0 && advanceBalance > 0) {
      confirmAddAdvance = false;
    }
    this.setState({ [targetName]: targetValue, confirmAddAdvance });
  };

  handleDateChange = (targetName, date) => {
    const { leaseStartDate, agreementDate } = this.state;
    const inputError = {};
    const state = {};

    if (targetName === "leaseStartDate") {
      inputError["agreementDate"] =
        inputError["agreementDate"] || agreementDate === "";

      state.leaseStartDate = date;
      const leaseDuration = this.state.leaseDuration;
      if (leaseDuration) {
        const offset = leaseDuration;
        state.leaseEndDate = addMonths(date, offset);
      }
    } else if (targetName === "leaseEndDate") {
      inputError["agreementDate"] =
        inputError["agreementDate"] || agreementDate === "";

      if (!leaseStartDate) {
        inputError["leaseStartDate"] = true;
      } else if (leaseStartDate > date) {
        inputError["leaseStartDate"] = true;
        inputError["leaseEndDate"] = true;
      }
      state.leaseEndDate = date;
    } else {
      state.agreementDate = date;
    }

    state.inputError = inputError;

    return this.setState(state);
  };

  getCampus = campus => {
    if (campus !== null) {
      return this.setState({
        campus: campus.lookup,
        building: "",
        floors: ""
      });
    } else {
      this.setState({
        campus: null,
        building: "",
        floors: ""
      });
    }
  };

  getBuilding = building => {
    if (building !== null) {
      return this.setState({
        building: building.lookup,
        floors: ""
      });
    } else {
      this.setState({
        building: null,
        floors: ""
      });
    }
  };

  getFloor = floor => {
    if (floor !== null) {
      return this.setState({ floors: floor.lookup, partition: "" });
    } else {
      this.setState({ floors: null, partition: "" });
    }
  };

  getPartition = partition => {
    if (partition !== null) {
      return this.setState({ partition: partition.lookup });
    } else {
      this.setState({ partition: null });
    }
  };

  getIncubatee = incubatee => {
    if (incubatee !== null) {
      return this.setState({ incubatee: incubatee.lookup });
    } else {
      this.setState({ incubatee: null });
    }
  };

  //Partition Information
  addLeasePartitions = e => {
    e.preventDefault();
    const { partition } = this.state;
    if (partition === "") {
      return;
    }
    let leasePartitions = [...this.state.leasePartitions];

    let duplicatePartitions = leasePartitions.filter(
      (part, idx) => part.partition === partition._id
    );
    if (duplicatePartitions.length > 0) {
      this.setState({ duplicatePartitions: true });
      return;
    }

    let price = "",
      actualRent = "";
    if (partition.partitionType === "SEATER") {
      price = partition.seaterPrice;
      actualRent = Number(price) * Number(partition.seater);
    }

    if (partition.partitionType === "RENTAL") {
      price = partition.areaPrice;
      actualRent = Number(price) * Number(partition.area);
    }
    //let actualRent =price *
    let inputError = { ...this.state.inputError };
    inputError["campus"] = false;
    inputError["building"] = false;
    inputError["floors"] = false;
    inputError["partition"] = false;
    let newLeasePartition = {
      partition: partition._id,
      seatCount: partition.seater,
      estimatedRent: price,
      actualRent: actualRent,
      partitionType: partition.partitionType,
      displayName: partition.displayName,
      areaUnit: partition.areaUnit,
      area: partition.area
    };
    leasePartitions.push(newLeasePartition);

    let monthlyRent = this.getMonthlyRent(leasePartitions);

    return this.setState({
      leasePartitions,
      monthlyRent,
      seatCount: "",
      duplicatePartitions: false,
      inputError: inputError
    });
  };
  //Partition Information
  handleEdit = index => e => {
    e.preventDefault();
    let leasePartitions = [...this.state.leasePartitions];

    let partition = leasePartitions[index];
    //let price = ''
    if (e.target.name === "seatCount" && partition.partitionType === "SEATER") {
      let price = partition.unitPrice;
      let seatCount = e.target.value;

      let actualRent = Number(seatCount) * Number(price);
      partition.estimatedRent = actualRent;
      partition.actualRent = actualRent;
    }

    partition[e.target.name] = Number(e.target.value);
    leasePartitions[index] = partition;
    let monthlyRent = this.getMonthlyRent(leasePartitions);

    this.setState({ leasePartitions, monthlyRent });
  };

  //Partition Information
  removeLeasePartition = index => {
    let leasePartitions = [...this.state.leasePartitions];
    if (leasePartitions.length === 0) {
      return;
    }
    leasePartitions = leasePartitions.filter((leasePart, idx) => idx !== index);
    let monthlyRent = this.getMonthlyRent(leasePartitions);
    this.setState({ leasePartitions, monthlyRent });
  };

  getSeletedPartitions = (partitions, searchedSeatCount) => {
    if (partitions.length === 0) {
      return;
    }

    const seletedPartitions = [];
    partitions.forEach(partition => {
      let rent = "";
      let unitPrice = 0;
      let partitionSeatCount =
        Number(searchedSeatCount) > 0 ? Number(searchedSeatCount) : 1;

      if (partition.availability.length > 1) {
        partitionSeatCount = 0;
      }

      if (partition.partitionType === "SEATER") {
        unitPrice = Number(partition.seaterPrice);
        rent = Number(partitionSeatCount) * Number(partition.seaterPrice);
      }

      if (partition.partitionType === "RENTAL") {
        unitPrice = Number(partition.areaPrice);
        rent = Number(partition.areaPrice) * Number(partition.area);
      }

      let leasePartition = {
        partition: partition.partitionId,
        seatCount: partitionSeatCount ? partitionSeatCount : 0,
        // If the lease is complementary, then actual rent is zero.
        // For other lease types, estimated rent & actual rent is same initially.
        // User can adjust the actual rent later.
        estimatedRent: rent,
        actualRent: this.state.leaseType === "COMPLEMENTARY" ? 0 : rent,
        partitionType: partition.partitionType,
        displayName: partition.displayName,
        areaUnit: partition.areaUnit,
        area: partition.area ? partition.area : 0,
        unitPrice: unitPrice,
        floorName: partition.floorName,
        buildingName: partition.buildingName,
        campusName: partition.campusName
      };

      seletedPartitions.push(leasePartition);
    });

    let leasePartitions = [...this.state.leasePartitions, ...seletedPartitions];
    let monthlyRent = this.getMonthlyRent(leasePartitions);
    return this.setState({
      leasePartitions,
      monthlyRent
    });
  };

  removePartition = (index, e) => {
    let leasePartitions = this.state.leasePartitions;
    if (leasePartitions.length === 0) {
      return;
    }
    leasePartitions = leasePartitions.filter((part, idx) => idx !== index);

    let monthlyRent = this.getMonthlyRent(leasePartitions);
    this.setState({ leasePartitions, monthlyRent });
  };

  //Payment Information
  addDiscounts = e => {
    e.preventDefault();
    const { discountAmount, leaseStartDate } = this.state;

    let inputError = {};
    inputError["leaseStartDate"] = false;
    if (leaseStartDate === "") {
      inputError["leaseStartDate"] = true;
      return this.setState({
        inputError: { ...inputError }
      });
    }

    let discounts = [...this.state.discounts];
    let monthIndex = discounts.length === 0 ? 0 : discounts.length;

    let newDiscount = {
      discountAmt: Number(discountAmount),
      monthId: monthIndex
    };
    discounts.push(newDiscount);

    return this.setState({
      discountAmount: 0,
      discounts,
      inputError: { ...inputError }
    });
  };

  handleEditDiscount = index => e => {
    e.preventDefault();
    let discounts = [...this.state.discounts];
    let discount = discounts[index];
    discount["discountAmt"] = Number(e.target.value);
    discounts[index] = discount;
    this.setState({ discounts });
  };

  //Payment Information
  removeDiscounts = index => {
    let discounts = [...this.state.discounts];
    if (discounts.length === 0) {
      return;
    }
    discounts = discounts.filter((dis, idx) => idx !== index);
    return this.setState({ discounts });
  };

  getMonthlyRent = leasePartitions => {
    let monthly = 0;
    leasePartitions.forEach(partition => {
      monthly = monthly + Number(partition.actualRent);
    });
    return monthly;
  };

  handleApiSuccess = data => {
    let leasePartitions =
      data !== null || data !== undefined ? data.leasePartitions : [];
    leasePartitions = leasePartitions.map(lp => {
      const unitPrice =
        lp.partition.partitionType === "RENTAL"
          ? Number(lp.partition.areaPrice)
          : Number(lp.partition.seaterPrice);
      let leasePartition = {
        partition: lp.partition._id,
        seatCount: lp.seatCount ? lp.seatCount : 0,
        estimatedRent: lp.estimatedRent,
        actualRent: lp.actualRent,
        partitionType: lp.partition.partitionType,
        displayName: lp.partition.displayName,
        areaUnit: lp.partition.areaUnit,
        area: lp.partition.area ? lp.partition.area : 0,
        unitPrice: unitPrice,
        floorName: lp.floorName,
        buildingName: lp.buildingName,
        campusName: lp.campusName
      };

      return leasePartition;
    });

    let monthlyRent = this.getMonthlyRent(leasePartitions);

    // convertNumberToDate
    let agreeDate = data.agreementDate;
    if (typeof agreementDate !== "number") {
      agreeDate = convertNumberToDate(data.agreementDate);
    }

    let leaseStDate = data.leaseStartDate;
    if (typeof leaseStartDate !== "number") {
      leaseStDate = convertNumberToDate(data.leaseStartDate);
    }

    let leaseEdDate = data.leaseEndDate;
    if (typeof leaseEndDate !== "number") {
      leaseEdDate = convertNumberToDate(data.leaseEndDate);
    }

    const mtrs = data.renewalMetrics || {};
    const empInfo = mtrs.employeeIndicator || false;
    const finInfo = mtrs.turnoverIndicator || false;

    const renewalMetrics = {};
    renewalMetrics.totalEmployee = mtrs.totalEmployee || null;
    renewalMetrics.femaleEmployee = mtrs.femaleEmployee || null;
    renewalMetrics.annualTurnover = mtrs.annualTurnover || null;
    renewalMetrics.annualTurnoverFy = mtrs.annualTurnoverFy || null;

    let leaseTermination = null;
    const leaseStatus = data.status;

    if (leaseStatus === "TERMINATED") {
      const actualTerminationDate = convertNumberToDate(data.terminationDate);
      const leaseTerminationRequest = data.leaseTerminationRequest;
      const terminationReason = leaseTerminationRequest.terminationReason;
      const supportingDocuments = leaseTerminationRequest.supportingDocuments
        ? leaseTerminationRequest.supportingDocuments
        : [];
      leaseTermination = {
        _id: leaseTerminationRequest._id,
        actualTerminationDate: actualTerminationDate,
        terminationReason: terminationReason,
        notes: leaseTerminationRequest.notes.reverse(),
        supportingDocuments: supportingDocuments
      };
    }

    let disabledField = {};

    if (!this.state.newLease) {
      disabledField =
        data.actions.length === 0
          ? { readOnly: "readOnly", disabled: "disabled" }
          : {};
    }

    this.setState({
      _id: data._id,
      previousLease: data.previousLease ? data.previousLease : null,
      incubatee: data.incubatee,
      leasePartitions: leasePartitions,
      leaseHistory: data.leaseHistory || [],
      advanceBalance: data.advanceBalance || 0,
      discounts: data.discounts ? data.discounts : [],
      agreementDate: agreeDate,
      leaseStartDate: leaseStDate,
      leaseEndDate: leaseEdDate,
      leaseDuration: data.leaseDuration || null,
      deposit: data.deposit,
      nonRefundableFee: data.nonRefundableFee,
      notes: data.notes.length > 0 ? data.notes.reverse() : [],
      remarks: "",
      monthlyRent: monthlyRent,
      additionalMonthlyFees: data.additionalMonthlyFees,
      actions: data.actions ? data.actions : [],
      actionStatus: data.status,
      actionStage: data.stage,
      scannedCopyList: data.documents ? data.documents : [],
      scannedCopy: [],
      renewalMetrics: renewalMetrics,
      empInfo,
      finInfo,
      leaseTermination: leaseTermination,
      leaseType: data.leaseType || "",
      disabledField
    });
  };

  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      alertFor: "",
      alertWithTitle: "",
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  getLeaseById = id => {
    let url = LEASE_API + "/" + id;
    const authToken = this.props.authToken;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken
    };

    return HttpUtil.get(
      url,
      headers,
      data => {
        this.handleApiSuccess(data);

        let previousLocation = null;
        if (this.props.location.search !== "") {
          const search = this.props.location.search;
          const params = new URLSearchParams(search);
          previousLocation = params.get("from");
        }

        this.setState({
          inputError: false,
          newLease: false,
          previousLocation
        });
        window.scrollTo(0, 0);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getLeaseForm = () => {
    const {
      // Agreement
      incubatee,
      agreementDate,
      leaseStartDate,
      leaseEndDate,
      leaseDuration,
      leaseType,

      //Facility Information
      // auto lookup
      campus,

      //Partition Information
      leasePartitions,

      // Lease History
      leaseHistory,

      //Payment Information
      deposit,
      nonRefundableFee,
      monthlyRent,
      additionalMonthlyFee,
      discountAmount,
      discounts,

      //Additional Information
      leaseApprovedBy,
      scannedCopy,
      remarks,

      leaseTerminationDate
    } = this.state;

    const leaseForm = {
      // Agreement
      incubatee,
      agreementDate,
      leaseStartDate,
      leaseEndDate,
      leaseDuration,
      leaseType,

      //Facility Information
      // auto lookup
      campus: campus._id,
      //Partition Information
      leasePartitions,
      // Lease History
      leaseHistory,
      //Payment Information
      deposit,
      nonRefundableFee,
      monthlyRent,
      additionalMonthlyFee,
      discountAmount,
      discounts,

      //Additional Information
      leaseApprovedBy,
      scannedCopy,
      notes: [...this.state.notes, remarks],
      leaseTerminationDate
    };

    return leaseForm;
  };

  validation = (leaseForm, action) => {
    let inputError = {};

    if (leaseForm.incubatee === "") {
      inputError["incubatee"] = true;
    }

    if (leaseForm.leasePartitions.length === 0) {
      inputError["campus"] = true;
      inputError["building"] = true;
      inputError["floors"] = true;
      inputError["partition"] = true;
    }

    if (leaseForm.leaseStartDate !== "" && leaseForm.leaseEndDate !== "") {
      if (leaseForm.leaseStartDate > leaseForm.leaseEndDate) {
        inputError["leaseStartDate"] = true;
        inputError["leaseEndDate"] = true;
      }
    }

    checkEmptyValue(inputError, "leaseType", leaseForm.leaseType);

    if (leaseForm.agreementDate === "") {
      inputError["agreementDate"] = true;
    }

    if (leaseForm.leaseStartDate === "") {
      inputError["leaseStartDate"] = true;
    }

    if (leaseForm.leaseEndDate === "") {
      inputError["leaseEndDate"] = true;
    }

    if (leaseForm.leaseType === "REGULAR") {
      if (leaseForm.monthlyRent === 0) {
        inputError["monthlyRent"] = true;
      }
      inputError["nonRefundableFee"] = false;
    } else {
      if (Number(leaseForm.nonRefundableFee) === 0) {
        inputError["nonRefundableFee"] = true;
      }
      inputError["monthlyRent"] = false;
    }

    const complAction = action === "SUBMIT" || action === "APPROVE";
    if (complAction && leaseForm.leaseType === "COMPLEMENTARY") {
      inputError["nonRefundableFee"] = false;
      inputError["remarks"] = this.state.remarks === "";
    }

    if (action === "REJECT" || action === "REWORK" || action === "APPROVE") {
      inputError["remarks"] = this.state.remarks === "";
    }

    const subAction = action === "SUBMIT" || action === "APPROVE";
    if (subAction && this.state.remarks === "") {
      const leasePartitions = this.state.leasePartitions;

      const estAndActualRentChanged = [];
      leasePartitions.forEach(lp => {
        if (lp.estimatedRent !== lp.actualRent) {
          estAndActualRentChanged.push(lp);
        }
      });

      inputError["estAndActualRentNotEqual"] =
        estAndActualRentChanged.length > 0;

      inputError["remarks"] = this.state.remarks === "";
    }
    /**
      need to validate below ==> working in-progress
      1) seater count ==> when Rental type is seat
      2) upload file  ==> 
      3) notes        ==> status rejected or terminated or rework
      4) lease termination ==> 
     */

    const previousLease = this.state.previousLease;
    const renewalMetrics = this.state.renewalMetrics;
    const empInfo = this.state.empInfo;
    const finInfo = this.state.finInfo;

    if (previousLease) {
      // check empInfo is undefined
      if (this.state.empInfo !== true && this.state.empInfo !== false) {
        inputError.empInfo = true;
      } else {
        delete inputError.empInfo;
      }

      if (this.state.finInfo !== true && this.state.finInfo !== false) {
        inputError.finInfo = true;
      } else {
        delete inputError.finInfo;
      }

      if (empInfo) {
        checkEmptyValue(
          inputError,
          "totalEmployee",
          renewalMetrics.totalEmployee
        );
        const { totalEmployee, femaleEmployee } = renewalMetrics;
        if (Number(totalEmployee) < Number(femaleEmployee)) {
          inputError.femaleEmployeeCountMsg =
            "Female employee count shouldn't more than total employee count.";
        }
      } else {
        renewalMetrics.totalEmployee = null;
        renewalMetrics.femaleEmployee = null;
      }

      const incubateeType = this.state.incubatee.incubateeType;
      if (finInfo && incubateeType === "COMPANY") {
        checkEmptyValue(
          inputError,
          "annualTurnover",
          renewalMetrics.annualTurnover
        );
        checkEmptyValue(
          inputError,
          "annualTurnoverFy",
          renewalMetrics.annualTurnoverFy
        );
      }
    }

    const invalid = Object.values(inputError).some(item => item);

    if (invalid) {
      this.setState({
        inputError,
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: "Please correct the errors and submit again"
      });
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      alertFor: ""
    });
  };

  handleSubmit = (action, e) => {
    let leaseForm = this.getLeaseForm();
    if (!this.validation(leaseForm, action)) {
      return;
    }

    let formData = this.getFormData(action);

    const { leaseStartDate, discounts, leaseEndDate } = this.state;

    // Alert Top of monthly discount table header with message.
    let months = getMonths(new Date(leaseStartDate), new Date(leaseEndDate));

    let removeDiscount = months < discounts.length;

    if (discounts.length > 0 && removeDiscount) {
      return this.setState({ removeDiscount: true });
    }

    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = LEASE_API;
    const headers = {
      Authorization: authToken
    };

    // Fire request
    HttpUtil.post(
      url,
      headers,
      formData,
      data => {
        this.handleApiSuccess(data);
        let id = data._id;
        this.setState({
          inputError: false,
          editLease: true,
          editLeaseId: id,
          newLease: false
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getFormData = action => {
    const { scannedCopy } = this.state;
    //const scannedCopyList = [...this.state.scannedCopyList];

    let formData = new FormData();

    if (scannedCopy.length > 0) {
      Array.from(scannedCopy).forEach(file => {
        formData.append("documents", file);
      });
    }

    let leasePartitions = [...this.state.leasePartitions];
    leasePartitions = leasePartitions.map(partition => {
      let leasePartition = {};
      leasePartition.partition = partition.partition;
      leasePartition.seatCount = partition.seatCount;
      leasePartition.estimatedRent = partition.estimatedRent;
      leasePartition.actualRent = partition.actualRent;
      return leasePartition;
    });

    const newRemarks = []; // [...this.state.notes];
    if (this.state.remarks !== "") {
      newRemarks.push({
        remark: this.state.remarks
      });
    }

    const lease = {
      incubatee: this.state.incubatee._id,
      leasePartitions: leasePartitions,
      agreementDate: convertDateToNumber(this.state.agreementDate),
      leaseStartDate: convertDateToNumber(this.state.leaseStartDate),
      leaseEndDate: convertDateToNumber(this.state.leaseEndDate),
      leaseDuration: this.state.leaseDuration,
      leaseType: this.state.leaseType,

      deposit: this.state.deposit,
      nonRefundableFee: this.state.nonRefundableFee,
      discounts: this.state.discounts,
      notes: newRemarks,
      monthlyRent: this.state.monthlyRent,
      additionalMonthlyFees: this.state.additionalMonthlyFee,
      action: action,
      status: this.state.actionStatus,
      stage: this.state.actionStage
    };

    const renwlMtrs = this.state.renewalMetrics;

    const renewalMetrics = {};
    if (this.state.empInfo) {
      renewalMetrics.employeeIndicator = this.state.empInfo;
      renewalMetrics.totalEmployee = renwlMtrs.totalEmployee;
      renewalMetrics.femaleEmployee = renwlMtrs.femaleEmployee;
    } else {
      renewalMetrics.totalEmployee = null;
      renewalMetrics.femaleEmployee = null;
    }

    if (this.state.finInfo) {
      renewalMetrics.turnoverIndicator = this.state.finInfo;
      renewalMetrics.annualTurnover = renwlMtrs.annualTurnover;
      renewalMetrics.annualTurnoverFy = renwlMtrs.annualTurnoverFy;
    } else {
      renewalMetrics.annualTurnover = null;
      renewalMetrics.annualTurnoverFy = null;
    }

    const previousLease = this.state.previousLease;
    if (previousLease) {
      lease.renewalMetrics = renewalMetrics;
      lease.previousLease = previousLease;
    }

    formData.append("message", JSON.stringify(lease));

    return formData;
  };

  fileExist = () => {
    const { scannedCopyList, scannedCopy } = this.state;
    let duplicates = [];
    if (scannedCopyList.length > 0) {
      const existingDocumentNames = new Set();
      scannedCopyList.forEach(scpl => existingDocumentNames.add(scpl.filename));
      duplicates = [...scannedCopy].filter(document =>
        existingDocumentNames.has(document.name)
      );
    }
    return duplicates;
  };

  getUpdatedMessage = data => {
    const stage = data.stage;
    const status = data.status;

    const TYPE_STAGE_STATUS_DESC = {
      "INIT|CREATED": "Lease Agreement has been created",
      "APPR1|PENDING":
        "Lease has been updated and Request is sent for Manager Approval",
      "APPR2|PENDING": "Lease updated and Request is sent for ED Approval",
      "COMPLETE|REJECTED": "Lease Request has been Rejected",
      "INIT|REWORK": "Lease Request has been sent for Rework",
      "COMPLETE|ACTIVE": "Lease has been completed.",
      "COMPLETE|TERMINATED": "Lease has been terminated",
      "COMPLETE|CLOSED": "Lease has been renewed",
      "COMPLETE|EXPIRED": "Lease has been expired",
      LEASE: "Lease has been updated"
    };
    const key = `${stage}|${status}`;
    return TYPE_STAGE_STATUS_DESC[key] || "Lease has been updated";
  };

  handleUpdate = (action, e) => {
    // Action comes fine when button is clicked.
    // If the user is asked for confirmation, then it gets lost.
    // So take it from state

    const confirmAction = action || this.state.action;
    let leaseForm = this.getLeaseForm();
    if (!this.validation(leaseForm, confirmAction)) {
      return;
    }

    const advanceBalance = this.state.advanceBalance || 0;
    const currentDeposit = this.state.deposit || 0;
    const confirmAddAdvance = this.state.confirmAddAdvance;

    if (!confirmAddAdvance && currentDeposit > 0 && advanceBalance > 0) {
      const message = `Advance deposit amount ₹${advanceBalance} has been paid for this partition in the previous lease agreements. Do you want to collect ₹${currentDeposit} as additional deposit in this renewal lease ?`;
      return this.setState({
        inputError: false,
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "primary",
        alertMessage: message,

        alertFor: LEASE_UPDATE,
        alertWithTitle: "Advance amount",
        confirmAddAdvance: true,
        action: confirmAction
      });
    }

    let formData = this.getFormData(confirmAction);
    // const { leaseStartDate, discounts, leaseEndDate } = this.state;
    // // Alert Top of monthly discount table header with message.
    // let months = getMonths(new Date(leaseStartDate), new Date(leaseEndDate));
    // let removeDiscount = months < discounts.length;

    // if (discounts.length > 0 && removeDiscount) {
    //   return this.setState({ removeDiscount: true });
    // }

    const { scannedCopyList, scannedCopyLimit, scannedCopy } = this.state;

    let listCount = scannedCopyList.length;
    let filesCount = scannedCopy.length;
    let max = listCount + filesCount;

    let inputError = {};

    if (scannedCopyLimit < max) {
      inputError["scannedCopy"] = true;
      this.setState({
        inputError: inputError
      });
      return;
    }

    if (!this.state.alertFor) {
      let duplicates = this.fileExist();

      if (duplicates.length > 0) {
        let fileNames = [...duplicates].map(({ name }) => name);
        fileNames = fileNames.join(",");
        let message = `The file "${fileNames}" that is being uploaded is already exists under this lease. Do you want to overwrite the existing file with a new copy ?`;
        return this.setState({
          inputError: false,
          alertType: "Confirmation",
          showAlert: true,
          alertColor: "primary",
          alertMessage: message,

          alertFor: LEASE_UPDATE,
          alertWithTitle: "Duplicate Files Exists",
          removeDiscount: false,
          action: confirmAction
        });
      }
    }

    let leaseId = this.state._id;
    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = LEASE_API + "/" + leaseId;
    const headers = {
      Authorization: authToken
    };

    // Fire request
    HttpUtil.put(
      url,
      headers,
      formData,
      data => {
        this.handleApiSuccess(data);
        const alertMessage = this.getUpdatedMessage(data);
        this.setState({
          inputError: false,
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: alertMessage,
          removeDiscount: false,
          alertFor: "",
          alertWithTitle: ""
        });
        window.scrollTo(0, 0);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleAlertFor = () => {
    const { alertFor } = this.state;

    if (alertFor === LEASE_UPDATE) {
      this.handleUpdate();
    } else if (alertFor === LEASE_RENEWAL) {
      this.handleLeaseRenew();
    } else if (alertFor === LEASE_TERMINATION) {
      this.handleTerminateLease();
    }
  };

  getDocument = (file, e) => {
    e.preventDefault();
    let leaseId = "";
    let fileId = file._id;
    let filename = file.filename;
    if (this.props.match !== undefined) {
      leaseId = this.props.match.params.id;
    }
    if (leaseId === "") {
      return;
    }

    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = LEASE_API + "/" + leaseId + "/documents/" + fileId;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: authToken
      }
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(blob => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString(), true);
      });
  };

  removeScannedCopy = (idx, e) => {
    e.preventDefault();

    let scannedCopy = [...this.state.scannedCopy];
    if (scannedCopy.length === 0) {
      return;
    }

    scannedCopy = [...scannedCopy].filter((scopy, index) => idx !== index);

    return this.setState({
      scannedCopy: scannedCopy.length > 0 ? scannedCopy : []
    });
  };

  handleTerminateLease = e => {
    if (e) {
      e.preventDefault();
    }

    if (!this.state.alertFor) {
      return this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "primary",
        alertMessage: `Do you want to start the Lease Termination process ?`,
        alertFor: LEASE_TERMINATION,
        alertWithTitle: "Lease Termination Request"
      });
    }

    const leaseId = this.state._id;
    const headers = { "Content-Type": "application/json" };
    const url = `${LEASE_API}/${leaseId}/terminate`;

    HttpUtil.post(
      url,
      headers,
      {},
      data => {
        this.setState({
          viewTerminateLease: true,
          viewTerminateLeaseId: data._id,
          inputError: false,
          newLease: false,
          alertType: "",
          showAlert: false,
          alertColor: "",
          alertMessage: "",
          alertFor: "",
          alertWithTitle: ""
        });
      },
      (data, status) => {
        if (data && data.id) {
          return this.setState({
            terminationRequestExist: true,
            terminationId: data.id,
            alertType: "",
            showAlert: false,
            alertColor: "",
            alertMessage: "",
            alertFor: "",
            alertWithTitle: ""
          });
        }
        this.handleApiFailed(data.message, status === 403 || status === 404);
      },
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  closeLeaseTerminationAlert = () => {
    this.setState({
      terminationRequestExist: false,
      terminationId: null,
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      alertFor: "",
      alertWithTitle: ""
    });
  };

  handleLeaseRenew = e => {
    if (e) {
      e.preventDefault();
    }

    if (!this.state.alertFor) {
      return this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "primary",
        alertMessage: `Do you want to start the Lease Renewal process ?`,
        alertFor: LEASE_RENEWAL,
        alertWithTitle: "Lease Renewal Request"
      });
    }

    const url = `${LEASE_API}/renew`;
    const headers = { "Content-Type": "application/json" };

    const leaseId = this.state._id;
    const renewRequest = {
      previousLease: leaseId,
      renewalMetrics: {
        employeeIndicator: false,
        totalEmployee: null,
        femaleEmployee: null,
        turnoverIndicator: false,
        annualTurnover: null,
        annualTurnoverFy: null
      }
    };

    HttpUtil.post(
      url,
      headers,
      renewRequest,
      data => {
        this.setState({
          renewalRequestId: data._id,
          renewalMsg: "Lease Renewal Request has been created successfully."
        });
      },
      (data, status) => {
        if (data && data.id) {
          this.setState({
            renewalRequestId: data.id,
            renewalMsg: "Lease Renewal Request already exists for this lease."
          });
        } else {
          this.handleApiFailed(data.message, status === 403 || status === 404);
        }
      },
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleRenewalInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    const renewalMetrics = this.state.renewalMetrics;
    renewalMetrics[targetName] = targetValue;
    this.setState({ renewalMetrics });
  };

  handleRenewalRadioBtn = (value, e) => {
    const inputError = this.state.inputError || {};
    if (value) {
      inputError[e.target.name] = false;
    }
    this.setState({ [e.target.name]: value, inputError });
  };

  handleRenewalCountChange = e => {
    const targetName = e.target.name;
    let targetValue =
      e.target.value !== "" ? e.target.value.replace(/[^\d].+/, "") : null;
    if (Number(targetValue) < 0) {
      return;
    }
    const renewalMetrics = this.state.renewalMetrics;
    renewalMetrics[targetName] = targetValue;
    this.setState({ renewalMetrics });
  };

  downloadDocument = (file, e) => {
    e.preventDefault();
    let leaseId = "";
    let fileId = file._id;
    let filename = file.filename;
    if (this.props.match !== undefined) {
      leaseId = this.props.match.params.id;
    }
    if (leaseId === "") {
      return;
    }

    // Extract request method & header & params
    const id = this.state.leaseTermination._id;
    const url = `${LEASE_TERMINATIONS_API}/${id}/documents/${fileId}`;
    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(blob => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString(), true);
      });
  };

  toggleActionBtn = () => {
    this.setState({ actionBtnShow: !this.state.actionBtnShow });
  };

  render() {
    const {
      // Agreement
      incubatee,
      agreementDate,
      leaseStartDate,
      leaseEndDate,

      //Facility Information
      // auto lookup
      campus,
      building,
      floors,
      partition,

      //Partition Information
      seatCount,
      leasePartitions,
      estimatedRent,
      actualRent,
      duplicatePartitions,

      // LeaseHistory
      leaseHistory,

      //Payment Information
      deposit,
      nonRefundableFee,
      monthlyRent,
      additionalMonthlyFee,
      discountAmount,
      discounts,
      removeDiscount,

      //Additional Information
      leaseApprovedBy,
      scannedCopy,
      scannedCopyList,
      scannedCopyLimit,
      remarks,
      notes,
      // leaseTerminationDate,

      editLease,
      editLeaseId,

      viewTerminateLease,
      viewTerminateLeaseId,

      terminationRequestExist,
      terminationId,

      //for input error alert
      inputError,

      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      permanentFailure,

      newLease,

      // lease actions
      actions,
      actionStage,
      actionStatus
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (newLease === null) {
      return null;
    }

    if (viewTerminateLease) {
      const url = `/admin/lease/termination/edit/${viewTerminateLeaseId}`;
      return <Redirect to={url} />;
    }

    if (editLease) {
      let url = "/admin/lease/edit/" + editLeaseId;
      return <Redirect to={url} />;
    }

    if (this.state.renewalRequestId) {
      const locationState = {
        pathname: `/admin/lease/edit/${this.state.renewalRequestId}`,
        state: {
          renewed: true,
          message: this.state.renewalMsg
        }
      };
      return <Redirect to={locationState} />;
    }

    return (
      <Fragment>
        <div className="row custom-input-lease">
          <div className="col-12 col-xl-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.handleAlertFor}
              alertWithTitle={this.state.alertWithTitle}
            />

            <LeaseForm
              titleTest={[
                { name: "Thiru", id: "abdc2" },
                { name: "Mani", id: "asdf22" }
              ]}
              _id={this.state._id}
              previousLease={this.state.previousLease}
              terminationRequestExist={terminationRequestExist}
              terminationId={terminationId}
              leaseTermination={this.state.leaseTermination}
              previousLocation={this.state.previousLocation}
              //Agreement
              incubatee={incubatee}
              agreementDate={agreementDate}
              leaseStartDate={leaseStartDate}
              leaseEndDate={leaseEndDate}
              leaseDuration={this.state.leaseDuration}
              leaseType={this.state.leaseType}
              // auto-lookup
              authToken={this.props.authToken}
              campus={campus}
              building={building}
              floors={floors}
              partition={partition}
              getCampus={this.getCampus}
              getBuilding={this.getBuilding}
              getFloor={this.getFloor}
              getPartition={this.getPartition}
              getIncubatee={this.getIncubatee}
              //Partition Information
              seatCount={seatCount}
              leasePartitions={leasePartitions}
              estimatedRent={estimatedRent}
              actualRent={actualRent}
              addLeasePartitions={this.addLeasePartitions}
              handleEdit={this.handleEdit}
              removeLeasePartition={this.removeLeasePartition}
              duplicatePartitions={duplicatePartitions}
              getSeletedPartitions={this.getSeletedPartitions}
              removePartition={this.removePartition}
              // leaseHistory
              leaseHistory={leaseHistory}
              //Payment Information
              deposit={deposit}
              handleDeposit={this.handleDeposit}
              nonRefundableFee={nonRefundableFee}
              monthlyRent={monthlyRent}
              discountAmount={discountAmount}
              additionalMonthlyFee={additionalMonthlyFee}
              addDiscounts={this.addDiscounts}
              discounts={discounts}
              removeDiscounts={this.removeDiscounts}
              removeDiscount={removeDiscount}
              // Additional Information
              leaseApprovedBy={leaseApprovedBy}
              scannedCopy={scannedCopy}
              scannedCopyList={scannedCopyList}
              scannedCopyLimit={scannedCopyLimit}
              notes={notes}
              remarks={remarks}
              getDocument={this.getDocument}
              removeScannedCopy={this.removeScannedCopy}
              // Renewal Information
              renewalMetrics={this.state.renewalMetrics}
              empInfo={this.state.empInfo}
              finInfo={this.state.finInfo}
              handleRenewalInputChange={this.handleRenewalInputChange}
              handleRenewalRadioBtn={this.handleRenewalRadioBtn}
              handleRenewalCountChange={this.handleRenewalCountChange}
              //events
              newLease={newLease}
              onChange={this.onChange}
              handleLeaseDuration={this.handleLeaseDuration}
              submit={newLease ? this.handleSubmit : this.handleUpdate}
              inputError={inputError}
              handleDateChange={this.handleDateChange}
              handleEditDiscount={this.handleEditDiscount}
              actions={actions}
              disabledField={this.state.disabledField}
              actionStage={actionStage}
              actionStatus={actionStatus}
              handleTerminateLease={this.handleTerminateLease}
              closeLeaseTerminationAlert={this.closeLeaseTerminationAlert}
              handleLeaseRenew={this.handleLeaseRenew}
              downloadDocument={this.downloadDocument}
              toggleActionBtn={this.toggleActionBtn}
              actionBtnShow={this.state.actionBtnShow}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Lease;
