import * as Yup from "yup";

const USER_INFO_SCHEMA = Yup.object().shape({
  requestorType: Yup.string()
    .label("Requester Type")
    .required(),

  userType: Yup.string().when("requestorType", {
    is: "INCUBATEE",
    then: Yup.string().notRequired(),
    otherwise: Yup.string()
      .label("User Type")
      .required()
  }),

  requestorCategory: Yup.string().when("requestorType", {
    is: "INCUBATEE",
    then: Yup.string().notRequired(),
    otherwise: Yup.string()
      .label("Requester Category")
      .required()
  }),

  firstName: Yup.string().when("requestorType", {
    is: "INDIVIDUAL",
    then: Yup.string()
      .label("First Name")
      .required()
  }),

  organizationName: Yup.string().when("requestorType", {
    is: "ORGANIZATION",
    then: Yup.string()
      .label("Organization Name")
      .required()
  }),

  designation: Yup.string()
    .label("Designation")
    .optional(),

  contactNameOrDepartment: Yup.string()
    .label("Contact Name / Department")
    .optional(),
  reference: Yup.string()
    .label("Reference")
    .optional()
});

const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; // for Mobile Numbers

const POSTAL_CODE_REG_EXP = /^[1-9][0-9]{5}$/;

const CONTACT_INFO_SCHEMA = Yup.object().shape({
  mobile: Yup.string()
    .label("Mobile")
    .test("Mobile", "Please enter valid mobile number", value => {
      if (value) {
        const schema = Yup.string().matches(
          PHONE_REG_EXP,
          "Please enter valid mobile number"
        );
        return schema.isValidSync(value);
      }
      return true;
    })
    .required(),
  email: Yup.string()
    .label("Email")
    .required(),
  streetLine1: Yup.string()
    .label("Street Line 1")
    .required(),
  streetLine2: Yup.string()
    .label("Street Line 2")
    .optional(),
  city: Yup.string()
    .label("City")
    .required(),
  state: Yup.string()
    .label("State")
    .required(),
  country: Yup.string()
    .label("Country")
    .required(),
  postalCode: Yup.string()
    .label("Postal Code")
    .test("Postal Code", "Please enter valid Postal Code", value => {
      if (value) {
        const schema = Yup.string().matches(
          POSTAL_CODE_REG_EXP,
          "Please enter valid Postal Code"
        );
        return schema.isValidSync(value);
      }
      return true;
    })
    .optional()
    .nullable()
});

const ADDITIONAL_INFO_SCHEMA = Yup.object().shape({
  usageType: Yup.string()
    .label("Usage Type")
    .required(),

  waiver: Yup.boolean()
    .label("Waiver")
    .required(),

  chargesWaiverReason: Yup.string().when("waiver", {
    is: true,
    then: Yup.string()
      .label("Charges Waiver Reason")
      .required()
  }),

  internalApprovalRequired: Yup.boolean()
    .label("Internal Approval Required")
    .required(),

  internalApprovalProof: Yup.mixed()
    .optional()
    .nullable(),

  pendingInternalApprovalProof: Yup.mixed()
    .when("internalApprovalProof", {
      is: value => {
        return !value;
      },
      then: Yup.mixed()
        .when("internalApprovalRequired", {
          is: true,
          then: Yup.mixed()
            .label("Internal Approval Proof")
            .required()
        })
        .nullable()
    })
    .nullable(),

  comments: Yup.string()
    .label("Remarks")
    .optional()
});

const ASSET_INOF_SCHEMA = Yup.object().shape({
  asset: Yup.object()
    .label("Asset")
    .required(),
  from: Yup.mixed()
    .label("From Date")
    .required(),
  to: Yup.mixed()
    .label("To Date")
    .required(),
  unitType: Yup.string().when("requireUnitType", {
    is: true,
    then: Yup.string()
      .label("Usage Unit Type")
      .required(),
    otherwise: Yup.string().notRequired()
  }),
  usageUnit: Yup.string()
    .label("Usage Unit")
    .when("requireUnitType", {
      is: true,
      then: Yup.string()
        .label("Usage Unit")
        .test("Usage Unit", "Please enter valid usage unit", value => {
          if (Number(value) && Number(value) > 0) {
            return true;
          }
          return false;
        })
        .required(),
      otherwise: Yup.string()
        .label("Usage Unit")
        .test("Usage Unit", "Please enter valid usage unit", value => {
          if (!value) {
            return true;
          }
          if (isNaN(Number(value))) {
            return false;
          }
          return true;
        })
    }),
  usageHours: Yup.string()
    .label("Requested Usage Hours")
    .test(
      "Requested Usage Hours",
      "Please enter valid requested usage hours",
      value => {
        if (Number(value) && Number(value) > 0) {
          return true;
        }
        return false;
      }
    )
    .required(),
  charge: Yup.string()
    .label("Charge")
    .test("Charge", "Please enter valid charge", value => {
      if (Number(value) && Number(value) > 0) {
        return true;
      }
      return false;
    })
    .required()
});

const getValidationSchema = requestorType => {
  let ASSET_RESERVATION_REQUEST_SCHEMA = "";
  if (requestorType === "INCUBATEE") {
    ASSET_RESERVATION_REQUEST_SCHEMA = Yup.object().shape({
      additionalInformation: ADDITIONAL_INFO_SCHEMA,
      advance: Yup.number().min(0),
      assetRequested: Yup.array()
        .of(ASSET_INOF_SCHEMA)
        .label("Assets")
        .min(1)
    });
  } else {
    ASSET_RESERVATION_REQUEST_SCHEMA = Yup.object().shape({
      userInformation: USER_INFO_SCHEMA,
      contactInformation: CONTACT_INFO_SCHEMA,
      advance: Yup.number().min(0),
      additionalInformation: ADDITIONAL_INFO_SCHEMA,
      assetRequested: Yup.array()
        .of(ASSET_INOF_SCHEMA)
        .label("Requested Assets")
        .min(1)
    });
  }
  return ASSET_RESERVATION_REQUEST_SCHEMA;
};

export default getValidationSchema;
