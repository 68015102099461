import { EducationSchema } from "./EducationSchema";

const IndividualSchema = {
  title: "Individual",
  type: "object",
  required: ["firstName", "experience", "gender", "dob", "photo"],
  properties: {
    firstName: {
      type: "string",
      title: "First Name"
    },
    lastName: {
      type: "string",
      title: "Last Name"
    },
    gender: {
      type: "string",
      title: "Gender",
      enum: ["M", "F", "T"],
      enumNames: ["Male", "Female", "Transgender"]
    },
    dob: {
      type: "string",
      title: "Date of Birth",
      format: "date"
    },

    experience: {
      type: "string",
      title: "Experience"
    },

    references: {
      type: "string",
      title: "References"
    },
    photo: {
      type: "string",
      format: "data-url",
      title: "Photo"
    },
    education: EducationSchema
  }
};

const IndividualUiSchema = {
  experience: {
    "ui:widget": "textarea"
  },
  references: {
    "ui:widget": "textarea"
  }
};

export { IndividualSchema, IndividualUiSchema };
