import {
  generateEnumValues,
  LEGAL_STATUS_TYPES
} from "../../../common/LookupConstants";

function generateYears(howFar) {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let index = 0; index < howFar; index++) {
    years.push(currentYear - index);
  }
  return years;
}

const BasicInfoSchema = {
  title: "Basic Info",
  description: "Basic Information about the compnay",
  type: "object",
  required: ["name", "domainOperation", "legalStatus"],
  dependencies: {
    subisdary: ["parentCompany"]
  },
  properties: {
    name: {
      type: "string",
      title: "Name"
    },
    website: {
      type: "string",
      title: "Website"
    },
    domainOperation: {
      type: "string",
      title: "Area/Domain"
    },
    estYear: {
      type: "number",
      title: "Year of Establishment",
      enum: generateYears(30)
    },
    legalStatus: {
      type: "string",
      title: "Legal Status",
      anyOf: generateEnumValues(LEGAL_STATUS_TYPES)
    },
    reference: {
      type: "string",
      title: "References"
    },
    subsidiary: {
      type: "boolean",
      title: "Subsidary"
    },
    parentCompany: {
      type: "string",
      title: "Parent Company"
    },
    companyProfile: {
      type: "string",
      format: "data-url",
      title: "Company Profile"
    },
    regCertificate: {
      type: "string",
      format: "data-url",
      title: "Registration Certificate"
    },
    articlesOfAssociation: {
      type: "string",
      format: "data-url",
      title: "Articles of Association"
    }
  }
};

const BasicInfoUiSchema = {
  legalStatus: {
    "ui:widget": "radio"
  },
  reference: {
    "ui:widget": "textarea"
  }
};

export { BasicInfoSchema, BasicInfoUiSchema };
