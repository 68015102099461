import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { RENTAL_REQUEST_API } from "../../common/Constants";
import Can from "../../../auth/can";
import { INCUBATEE_TYPES_OBJECT } from "../../common/LookupConstants";

const { SearchBar } = Search;

function nameFormater(cell, row) {
  let name = "";
  if (row.incubateeIndicator && row.incubatee) {
    name = row.incubatee.name;
  } else {
    name = row.name;
  }
  return name;
}

function actionsFormater(cell, row) {
  //console.log("cell:", cell, "row:", row);
  let onboardButton = null;

  onboardButton = (
    <Fragment key={row._id}>
      <Link
        to={`/check/asset/availablity/${row._id}`}
        role="button"
        className="list-action-btn-info"
      >
        Check Availablity
      </Link>
      &nbsp;&nbsp;
      <Link
        to={`/asset/enquiry-form/${row._id}`}
        role="button"
        className="list-action-btn-info"
      >
        View
      </Link>
      &nbsp;&nbsp;
      {row.status === "PENDING" ? (
        <Link
          to={`/asset/duplicate-check/${row._id}`}
          role="button"
          className="list-action-btn-info"
        >
          Onboard
        </Link>
      ) : null}
    </Fragment>
  );

  return onboardButton;
}

function requestorFormater(cell, row) {
  let rqType = "";
  if (row.incubateeIndicator && row.incubatee) {
    rqType = INCUBATEE_TYPES_OBJECT[row.incubatee.incubateeType];
  } else {
    rqType = INCUBATEE_TYPES_OBJECT[row.requestorType];
  }
  return rqType;
}

const ASSET_LIST_COLUMNS = [
  {
    dataField: "name",
    text: "Client Name",
    sort: true,
    formatter: nameFormater
  },
  {
    dataField: "requestorType",
    text: "Requestor Type",
    sort: true,
    formatter: requestorFormater
  },
  {
    dataField: "incubateeIndicator",
    text: "Is Incubatee",
    sort: true,
    formatter: cell => (cell ? "Yes" : "No")
  },
  {
    dataField: "status",
    text: "Status",
    sort: true
  },
  {
    dataField: "actions",
    text: "Actions",
    isDummyField: true,
    formatter: actionsFormater
  }
];

class AssetEnquiryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiryList: []
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getEquipmentRentalRequests = () => {
    let url = RENTAL_REQUEST_API;

    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ enquiryList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getEquipmentRentalRequests();
  }

  render() {
    const { enquiryList } = this.state;

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white">
                <span className="card-title mb-0">
                  <i
                    className="fas fa-align-justify"
                    style={{ fontSize: "1.1em", cursor: "pointer" }}
                  />
                  &nbsp;&nbsp; Assets &nbsp;&nbsp;
                </span>

                <Can do="CREATE" on="Equipment">
                  <Link
                    to="/equipment/new"
                    role="button"
                    className="badge bg-white custom-btn-sm"
                  >
                    New
                  </Link>
                </Can>
              </div>

              <div className="card-body">
                <div className="table-responsive-sm table-responsive-md table-responsive-lg">
                  <ToolkitProvider
                    keyField="_id"
                    data={enquiryList}
                    columns={ASSET_LIST_COLUMNS}
                    bootstrap4={true}
                    search
                  >
                    {props => (
                      <Fragment>
                        <div className="mb-2">
                          <SearchBar {...props.searchProps} />
                        </div>
                        <BootstrapTable
                          bootstrap4
                          keyField={"_id"}
                          data={enquiryList}
                          columns={ASSET_LIST_COLUMNS}
                          condensed
                          striped
                          headerClasses="bg-card-header text-white"
                          // for pagination
                          pagination={paginationFactory({
                            page: 1,
                            sizePerPage: 8,
                            hideSizePerPage: true,
                            hidePageListOnlyOnePage: true
                          })}
                          //for search
                          {...props.baseProps}
                          //for custom css
                          className="react-bootstrap-table table .react-bootstrap-table td > .selection-input-4, .react-bootstrap-table th > .selection-input-4"
                          noDataIndication="No Data found"
                        />
                      </Fragment>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AssetEnquiryList;
