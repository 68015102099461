import Download from "downloadjs";

const TYPE_STAGE_STATUS_DESC = {
  "LEASE|INIT|CREATED": "Lease Agreement in progress",
  "LEASE|APPR1|PENDING": "Pending Manager Approval",
  "LEASE|APPR2|PENDING": "Pending ED Approval",
  "LEASE|COMPLETE|REJECTED": "Lease Request - Rejected",
  "LEASE|INIT|REWORK": "Lease Request - Rework",
  "LEASE|COMPLETE|ACTIVE": "Lease Active",
  "LEASE|COMPLETE|TERMINATED": "Lease Terminated",
  "LEASE|COMPLETE|CLOSED": "Lease Renewed",
  "LEASE|COMPLETE|EXPIRED": "Lease Expired",
  LEASE: "Lease Request",
  "IncubateeOnboarding|INIT|CREATED": "Onboarding in Progress",
  "IncubateeOnboarding|APPR1|PENDING": "Pending Manager Approval",
  "IncubateeOnboarding|APPR2|PENDING": "Pending ED Approval",
  "IncubateeOnboarding|COMPLETE|REJECTED": "Onboarding Request - Rejected",
  "IncubateeOnboarding|INIT|REWORK": "Onboarding Request - Rework",
  "IncubateeOnboarding|COMPLETE|ACTIVE": "Onboarding Complete",
  IncubateeOnboarding: "Onboarding Request Created",
  "LeaseTermination|INIT|CREATED": "Lease Termination in progress",
  "LeaseTermination|APPR1|PENDING": "Pending Manager Approval",
  "LeaseTermination|APPR2|PENDING": "Pending ED Approval",
  "LeaseTermination|COMPLETE|REJECTED": "Termination Request - Rejected",
  "LeaseTermination|INIT|REWORK": "Termination Request - Rework",
  "LeaseTermination|COMPLETE|APPROVED": "Lease Termination Complete",
  "LeaseTermination|COMPLETE|TERMINATED": "Lease Terminated",
  LeaseTermination: "Termination in Progress",
  "RequestorOnboard|INIT|CREATED": "Requestor Onboard in progress",
  "RequestorOnboard|APPR1|PENDING": "Pending Manager Approval",
  "RequestorOnboard|COMPLETE|REJECTED": "Requestor Onboard Request - Rejected",
  "RequestorOnboard|INIT|REWORK": "Requestor Onboard Request - Rework",
  "RequestorOnboard|COMPLETE|APPROVED": "Requestor Onboard Complete",
  "RequestorOnboard|COMPLETE|TERMINATED": "Requestor Onboard Terminated",
  RequestorOnboard: "Requestor Onboard in progress",
  "AssetLease|INIT|CREATED": "Asset Lease Request in progress",
  "AssetLease|APPR1|PENDING": "Pending Manager Approval",
  "AssetLease|COMPLETE|REJECTED": "Asset Lease Request - Rejected",
  "AssetLease|INIT|REWORK": "Asset Lease Request - Rework",
  "AssetLease|COMPLETE|APPROVED": "Asset Lease Request Completed",
  "AssetLease|COMPLETE|TERMINATED": "Asset Lease Request Terminated",
  AssetLease: "Asset Lease Request in progress",
  "IncubateeGraduation|INIT|CREATED": "Graduation Request in progress",
  "IncubateeGraduation|APPR1|PENDING": "Pending Manager Approval",
  "IncubateeGraduation|APPR2|PENDING": "Pending ED Approval",
  "IncubateeGraduation|COMPLETE|REJECTED": "Graduation Request - Rejected",
  "IncubateeGraduation|INIT|REWORK": "Graduation Request - Rework",
  "IncubateeGraduation|COMPLETE|APPROVED": "Graduation Request Completed",
  IncubateeGraduation: "Graduation Request Created",
  "AccountClosure|INIT|CREATED": "Account Closure Request in progress",
  "AccountClosure|APPR1|PENDING": "Pending Manager Approval",
  "AccountClosure|APPR2|PENDING": "Pending ED Approval",
  "AccountClosure|COMPLETE|REJECTED": "Account Closure Request - Rejected",
  "AccountClosure|COMPLETE|REWORK": "Account Closure Request - Re-work",
  "AccountClosure|INIT|REWORK": "Account Closure Request - Rework",
  "AccountClosure|COMPLETE|ACTIVE": "Account Closure Completed",
  "AccountClosure|PAYMENT|PENDING": "Account Closure Request - Approved",
  "AccountClosure|ACCTREFUND|PENDING": "Pending Refund",
  "AccountClosure|COMPLETE|CLOSED": "Account Closure Request - Completed",
  AccountClosure: "Account Closure Request Created",
  "IncubatorEnquiry|APPR1|PENDING": "New Enquiry",
  "IncubatorEnquiry|HOLD|PENDING": "Enquiry Request - Hold",
  "IncubatorEnquiry|COMPLETE|REJECTED": "Enquiry Request - Rejected",
  "IncubatorEnquiry|COMPLETE|APPROVED": "Pending Asset Reservation",
  "IncubatorEnquiry|RESERVE|PENDING": "Pending Asset Reservation",
  "IncubatorEnquiry|COMPLETE|CLOSED": "Enquiry Request - Closed",
  IncubatorEnquiry: "Enquiry Request - Incomplete",
  "AssetReservationRequest|APPR1|PENDING": "Pending Manager Approval",
  "AssetReservationRequest|RESERVEPAY|PENDING": "Pending Payments",
  "AssetReservationRequest|COMPLETE|REJECTED": "Asset Reservation - Rejected",
  "AssetReservationRequest|COMPLETE|CLOSED": "Asset Reservation - Closed",
  "AssetReservationRequest|RESERVEAPPR|APPROVED":
    "Asset Reservation - Completed",
  "AssetReservationRequest|RESERVEREFUND|PENDING": "Pending Refund",
  AssetReservationRequest: "Asset Reservation - Incomplete"
};

const getDescription = function(subject, stage, status) {
  const key = `${subject}|${stage}|${status}`;
  let desc = TYPE_STAGE_STATUS_DESC[key];
  if (desc) {
    return desc;
  }
  desc = TYPE_STAGE_STATUS_DESC[subject];

  return desc ? desc : "";
};

function getDownloadFileName(response, defaultFileName) {
  var filename = defaultFileName ? defaultFileName : "";
  var disposition = response.headers.get("content-disposition");
  if (disposition && disposition.indexOf("attachment") !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }
  return filename;
}

function downloadDocument(url, failureHandler, defaultFileName) {
  fetch(url, {
    method: "GET",
    headers: {}
  })
    .then(response => {
      if (response.status === 200) {
        return Promise.all([
          response.blob(),
          getDownloadFileName(response, defaultFileName)
        ]);
      } else {
        throw Error(response.statusText);
      }
    })
    .then(([blob, filename]) => {
      Download(blob, filename);
    })
    .catch(error => {
      failureHandler(error.toString());
    });
}

function titleCase(str) {
  if (!str) {
    return str;
  }
  const words = str.toLowerCase().split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
}

function titleCaseForOneCaseString(str) {
  if (!str) {
    return str;
  }

  const words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const lowerCaseString = word.toLowerCase();
    const upperCaseString = word.toUpperCase();

    if (lowerCaseString === word || upperCaseString === word) {
      words[i] =
        lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
    }
  }
  return words.join(" ");
}

function getActionCss(action) {
  const ACTIONS = {
    REFUND: "btn btn-sm btn-warning",
    REJECT: "btn btn-sm btn-outline-danger",
    REWORK: "btn btn-sm btn-outline-info",
    SAVE: "btn btn-sm btn-outline-info",
    SUBMIT: "btn btn-sm btn-primary",
    CANCEL: "btn btn-sm btn-outline-danger",
    APPROVE: "btn btn-sm btn-primary",
    CLOSE: "btn btn-sm btn-secondary"
  };

  return `${ACTIONS[action]}` || "btn btn-sm btn-primary";
}

function getConcatenatedName(firstName, lastName) {
  let names = [];

  if (firstName) {
    names.push(firstName);
  }

  if (lastName) {
    names.push(lastName);
  }
  return `${names.join(" ")}` || "--";
}

function getLabelFromArray(array, value) {
  let label = "";
  for (const obj of array) {
    if (obj.key === value) {
      label = obj.value;
      break;
    }
  }
  return label;
}

const CLOSURE_SUCCESS_MESSAGES = {
  SAVE: "Account Closure Request Saved",
  SUBMIT: "Account Closure Request Submitted",
  APPROVE: "Account Closure Request Approved",
  REWORK: "Account Closure Request Send for Rework",
  REJECT: "Account Closure Request Rejected",
  REFUND: "Account Closure Request Created"
};

function getName({ firstName, lastName }) {
  const names = [];
  if (firstName) {
    names.push(firstName);
  }
  if (lastName) {
    names.push(lastName);
  }
  return names.join(" ");
}

export {
  getDescription,
  getDownloadFileName,
  downloadDocument,
  titleCase,
  titleCaseForOneCaseString,
  getActionCss,
  getConcatenatedName,
  getLabelFromArray,
  CLOSURE_SUCCESS_MESSAGES,
  getName
};
