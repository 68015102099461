import React, { Fragment } from "react";
import {
  changeToINR,
  REFUND_STATUS_LIST_OBJECT,
  REFUND_PAYMENT_MODES_OBJECT
} from "../../common/LookupConstants";
import CompactTable from "../../widgets/CompactTable";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";

function entityNameFormater(cell, row) {
  let names = [];
  let entity = row.entity;
  if (entity.firstName) {
    names.push(entity.firstName);
  }

  if (entity.lastName) {
    names.push(entity.lastName);
  }

  if (entity.name) {
    names.push(entity.name);
  }

  const name = names.join(" ");
  return name;
}

function dateFormater(yyyymmdd) {
  return getDisplayDate(convertNumberToDate(yyyymmdd));
}

const columnsRefunds = [
  {
    dataField: "entity",
    text: "Customer Name",
    sort: true,
    formatter: entityNameFormater,
    filterValue: entityNameFormater,
    headerStyle: {
      width: "30%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "entityType",
    text: "Customer Type",
    sort: true,
    headerStyle: {
      width: "15%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "refundReason",
    text: "Reason",
    sort: true,
    classes: "text-center",
    // formatter: cell => REFUND_PAYMENT_MODES_OBJECT[cell],
    headerStyle: {
      width: "30%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "refundDate",
    text: "Refund Date",
    formatter: dateFormater,
    csvFormatter: dateFormater,
    sort: true,
    headerStyle: {
      width: "15%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: changeToINR,
    classes: "text-right",
    sort: true,
    headerStyle: {
      width: "10%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "text-center",
    formatter: cell => REFUND_STATUS_LIST_OBJECT[cell],
    headerStyle: {
      width: "10%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  }
];

const RefundSearchResult = props => {
  let refunds = [];
  if (props.searchResults && props.searchResults.refunds) {
    refunds = props.searchResults.refunds;
  }

  return (
    <Fragment>
      <h6 className="card-title mb-2 edit-page-section-header-text">Refunds</h6>
      <CompactTable
        data={refunds}
        columns={columnsRefunds}
        noDataIndication={"No Refund Available"}
      />
    </Fragment>
  );
};

export default RefundSearchResult;
