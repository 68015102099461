import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";
import GraduationForm from "./GraduationForm";
import { INCUBATEE_GRADUATION_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { convertNumberToDate, convertDateToNumber } from "../../lease/DateUtil";
import * as Yup from "yup";
import ErrorPage from "../../common/error.page";

const GRADUATION_SCHEMA = Yup.object().shape({
  reason: Yup.string()
    .label("Reason")
    .required(),
  graduationDate: Yup.date()
    .label("Graduation Date")
    .required(),
  notes: Yup.string()
    .label("Notes")
    .required()
});

class Graduation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: null,
      initialValues: {
        // Graduation Information
        reason: "",
        graduationDate: "",
        notes: "",
        status: "",
        stage: "",
        // Contact Information
        emails: [{ type: "WORK", email: "", status: "ACTIVE" }],
        phones: [{ type: "WORK", phone: "", status: "ACTIVE" }],
        addresses: [
          {
            streetLine1: "",
            streetLine2: "",
            landmark: "",
            city: "",
            state: "",
            postalCode: "",
            country: "IN",
            status: "ACTIVE",
            type: ""
          }
        ],
        actions: [],
        // Document Information
        documents: []
      },
      availableDocuments: [],
      promoters: [],
      incubatee: {},

      editPage: false,
      editPageReqId: null,
      permanentFailure: false
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      confirm: false
    });
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getEmails = emails => {
    const arr = [];
    for (let i = 0; i < emails.length; i++) {
      let em = emails[i] || null;
      if (em && em.email) {
        arr.push(em);
      }
    }
    return arr;
  };

  getPhones = phones => {
    const arr = [];
    for (let i = 0; i < phones.length; i++) {
      let ph = phones[i] || null;
      if (ph && ph.phone) {
        arr.push(ph);
      }
    }
    return arr;
  };

  getAddressess = addresses => {
    const arr = [];
    for (let i = 0; i < addresses.length; i++) {
      let addr = addresses[i] || null;
      delete addr.country; // has default values so ignore to validation
      delete addr.status; // has default values so ignore to validation
      const hasData = Object.values(addr).some(value => value);
      if (hasData) {
        arr.push(addresses[i]);
      }
    }
    return arr;
  };

  getGraduationFormData = values => {
    const formData = new FormData();
    const documents = values.documents || [];
    for (let index = 0; index < documents.length; index++) {
      this.addDocs(formData, "documents", documents[index]);
    }

    let date = new Date(values.graduationDate);
    date = convertDateToNumber(date);
    const request = {
      incubatee: values.incubatee,
      reason: values.reason,
      graduationDate: date,
      notes: values.notes,
      status: values.status,
      addresses: this.getAddressess(values.addresses),
      emails: this.getEmails(values.emails),
      phones: this.getPhones(values.phones),
      action: values.action
    };

    formData.append("message", JSON.stringify(request));
    return formData;
  };

  setDataFromServer = data => {
    let promoters = [];
    let incubatee = data.incubatee || {};
    let incubateeType = incubatee.incubateeType || null;
    if (incubateeType && incubateeType === "COMPANY") {
      let organization = incubatee.organization;
      promoters = organization.promoters;
    }

    const initialValues = { ...this.state.initialValues };
    initialValues.incubatee = incubatee._id;
    initialValues.reason = data.reason;
    initialValues.notes = data.notes;
    initialValues.status = data.status;
    initialValues.stage = data.stage;
    initialValues.actions = data.actions || [];

    let graduationDate = convertNumberToDate(data.graduationDate);
    initialValues.graduationDate = graduationDate;

    if (data.emails.length > 0) {
      initialValues.emails = data.emails;
    }

    if (data.phones.length > 0) {
      initialValues.phones = data.phones;
    }

    if (data.addresses.length > 0) {
      initialValues.addresses = data.addresses;
    }

    initialValues.documents = [];
    const availableDocuments = data.documents || [];

    this.setState({
      _id: data._id,
      initialValues,
      promoters,
      incubatee,
      availableDocuments
    });
  };

  handleSubmit = (values, { setSubmitting }) => {
    //console.log("submit: values:", JSON.stringify(values, null, 2));
    const formData = this.getGraduationFormData(values);
    const url = `${INCUBATEE_GRADUATION_API}/${this.state._id}`;
    HttpUtil.put(
      url,
      {},
      formData,
      data => {
        this.setDataFromServer(data);
        this.setState({
          editPage: true,
          editPageReqId: data._id,
          confirm: false
        });
        window.scrollTo(0, 0);
      },
      (data, status) => {
        this.handleApiFailed(data.message, status === 403 || status === 404);
        this.setState({ confirm: false });
      },
      error => {
        this.handleApiFailed(error.toString(), true);
        this.setState({ confirm: false });
      }
    );
  };

  getGraduationRequest = id => {
    const url = `${INCUBATEE_GRADUATION_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      this.setDataFromServer,
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  loadUrlLocationState = () => {
    const location = this.props.location;
    if (location.state !== undefined && location.state.requestSubmitted) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: location.state.message
      });
    }

    if (location.state !== undefined && location.state.requestUpdated) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: location.state.message
      });
    }
  };

  componentDidMount() {
    this.loadUrlLocationState();

    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getGraduationRequest(id);
    }
  }

  render() {
    if (this.state.editPage) {
      const graduationRequestId = this.state.editPageReqId;
      const incubatee = this.state.incubatee;
      const stateObj = {
        pathname: `/incubatee/graduation/form/${graduationRequestId}`,
        state: {
          requestUpdated: true,
          message: `Graduation Request for ${incubatee.name} has been updated sucessfully.`
        }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const graduationFormProps = {
      initialValues: this.state.initialValues,
      readOnly: this.state.initialValues.actions.length === 0,
      promoters: this.state.promoters,
      incubatee: this.state.incubatee,
      validationSchema: GRADUATION_SCHEMA,
      handleSubmit: this.handleSubmit,
      availableDocuments: this.state.availableDocuments
    };

    if (this.state.permanentFailure) {
      return <ErrorPage message={this.state.alertMessage} />;
    }

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">
            <GraduationForm {...graduationFormProps} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Graduation;
