import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
// import Select from "react-select";
import Creatable from "react-select/lib/Creatable";
import Can from "../../../auth/can";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  ASSET_CATEGORIES,
  RENTAL_TYPES,
  STATUS_LIST
} from "../../common/LookupConstants";
import { downloadDocument } from "../../common/Util";
import ImageViewer from "../../common/ImageViewer";

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export const TextInput = props => {
  const {
    label,
    required,
    fieldName,
    touch,
    error,
    helperText,
    disabled
  } = props;
  const labelCssClass = `col-form-label col-12 col-lg-2 col-xl-2 ${
    required ? "edit-page-label-required" : "edit-page-label"
  }`;

  return (
    <Fragment>
      <label className={labelCssClass}>{label}</label>
      <div className="col-12 col-lg-4 col-xl-4">
        <Field
          type="text"
          className={`form-control form-control-sm ${
            touch && error ? "is-invalid" : ""
          }`}
          name={fieldName}
          disabled={disabled}
        />
        <small>{helperText || null}</small>
        <div className="invalid-feedback">{error}</div>
      </div>
    </Fragment>
  );
};

export const TextAreaInput = props => {
  const { fieldName, touch, error, helperText } = props;

  return (
    <Fragment>
      {/* <label className={labelCssClass}>{label}</label> */}
      <div className="col-12">
        <Field
          component="textarea"
          className={`form-control form-control-sm ${
            touch && error ? "is-invalid" : ""
          }`}
          rows="5"
          name={fieldName}
        />
        <small>{helperText || null}</small>
        <div className="invalid-feedback">{error}</div>
      </div>
    </Fragment>
  );
};

export const SelectInput = props => {
  const { label, required, fieldName, error, touch, optionsData } = props;
  const labelCssClass = `col-form-label col-12 col-lg-2 col-xl-2 pr-0 ${
    required ? "edit-page-label-required" : "edit-page-label"
  }`;

  return (
    <Fragment>
      <label className={labelCssClass}>{label}</label>
      <div className="col-12 col-lg-4 col-xl-4">
        <Field
          component="select"
          className={`custom-select custom-select-sm ${
            touch && error ? "is-invalid" : ""
          }`}
          name={fieldName}
        >
          <option value="">Select...</option>
          {optionsData.map((op, idx) => (
            <option key={idx} value={op.key}>
              {op.value}
            </option>
          ))}
        </Field>
        <div className="invalid-feedback">{error}</div>
      </div>
    </Fragment>
  );
};

/********************** ProductImages Start ****************************************/
class ImagePreviewContainer extends React.Component {
  constructor(args) {
    super(args);
    this.state = {
      imageUrl: null,
      imageName: null
    };
  }

  handlePreviewImage = (imageUrl, imageName) => {
    this.setState({ imageUrl, imageName });
  };

  handlePreviewImageClose = () => {
    this.setState({ imageUrl: null, imageName: null });
  };

  render() {
    const { completedUploads, pendingUploads, handleRemoveImage } = this.props;

    const hasImages =
      (completedUploads && completedUploads.length > 0) ||
      (pendingUploads && pendingUploads.length > 0);
    const previewUploadedFiles = hasImages ? (
      <div className="row p-2 justify-content-center">
        {completedUploads.map((fileInfo, idx) => {
          return (
            <Fragment key={idx}>
              <div className="col-12 col-md-4 mt-3">
                <div className="card">
                  <div className="w-100">
                    <img
                      src={fileInfo.url}
                      className="card-img-top img-fluid"
                      alt={fileInfo.name}
                      style={{
                        maxHeight: 200,
                        minHeight: 200,
                        objectFit: "cover",
                        cursor: "pointer"
                      }}
                      onClick={this.handlePreviewImage.bind(
                        this,
                        fileInfo.url,
                        fileInfo.name
                      )}
                    />
                  </div>

                  <div className="card-body p-2">
                    <span className="float-right">
                      <span
                        className="mr-2"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          downloadDocument(
                            fileInfo.url,
                            msg => window.alert(msg),
                            fileInfo.name
                          )
                        }
                      >
                        <i className="fas fa-download" />
                      </span>
                      <span
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={handleRemoveImage.bind(this, fileInfo._id)}
                      >
                        <i className="far fa-trash-alt" />
                      </span>
                    </span>

                    <small>
                      <h6 className="card-title mb-0">{fileInfo.name}</h6>
                    </small>
                    <small className="mb-0">
                      <i className="far fa-hdd mr-1" /> {fileInfo.size}
                    </small>
                    <small className="mb-0 ml-4">
                      <i className="far fa-clock mr-1" />
                      {fileInfo.updatedAt}
                    </small>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
        {pendingUploads.map((file, idx) => {
          return (
            <Fragment key={idx}>
              <div className="col-12 col-md-4 mt-3">
                <div className="card">
                  <div className="w-100">
                    <img
                      src={URL.createObjectURL(file)}
                      className="card-img-top img-fluid"
                      alt={file.name || "image"}
                      style={{
                        maxHeight: 200,
                        minHeight: 200,
                        objectFit: "cover"
                      }}
                      onClick={this.handlePreviewImage.bind(
                        this,
                        file.url,
                        file.name
                      )}
                    />
                  </div>

                  <div className="card-body p-2">
                    <span className="float-right">
                      <span className="mr-2 text-success">
                        <i className="fas fa-upload" />
                      </span>
                      <span
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let uploadedImages = this.props.pendingUploads.filter(
                            (file, index) => {
                              return idx !== index;
                            }
                          );

                          this.props.setFieldValue(
                            "uploadedImages",
                            uploadedImages
                          );
                        }}
                      >
                        <i className="far fa-trash-alt" />
                      </span>
                    </span>

                    <small>
                      <h6 className="card-title mb-0">
                        {file.name}
                        <span className="badge badge-success ml-1"> New</span>
                      </h6>
                    </small>

                    <small className="mb-0">
                      <i className="far fa-hdd" /> {bytesToSize(file.size)}
                    </small>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    ) : null;

    const imageViewerProps = {
      imageUrl: this.state.imageUrl,
      handlePreviewImageClose: this.handlePreviewImageClose,
      imageName: this.state.imageName
    };

    return (
      <Fragment>
        {previewUploadedFiles}
        <ImageViewer {...imageViewerProps} />
      </Fragment>
    );
  }
}

const ProductImages = props => {
  const {
    values,
    uploadedProductImagesInfo,
    handleRemoveImage,
    setFieldValue
  } = props;
  return (
    <Fragment>
      <div className="row mt-4 mb-3 no-gutters">
        <div className="col-12 edit-page-section-header-text">
          <strong className="ml-2">Product Images </strong>
        </div>
      </div>

      <ImagePreviewContainer
        completedUploads={uploadedProductImagesInfo}
        handleRemoveImage={handleRemoveImage}
        pendingUploads={values.uploadedImages || []}
        setFieldValue={setFieldValue}
      />

      <div className="row mt-5 mb-5">
        <div className="col-md-3" />
        <div className="col-md-6 text-center">
          <div className="d-flex justify-content-center mt-2">
            <div className="input-group mb-0">
              <div className="custom-file">
                <input
                  id="uploadedImages"
                  name="uploadedImages"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={event => {
                    const files = event.currentTarget.files || [];
                    const imageFiles = values.uploadedImages || [];

                    // Find out duplicate file
                    let existingFiles = {};
                    for (let i = 0; i < imageFiles.length; i++) {
                      let file = imageFiles[i];

                      if (!existingFiles[file.name]) {
                        existingFiles[file.name] = true;
                      }
                    }

                    for (let i = 0; i < files.length; i++) {
                      let file = files[i];
                      const fileType = file.type;

                      // verify duplicate file
                      let isDuplicateFile = existingFiles[file.name] || false;
                      if (fileType.includes("image") && !isDuplicateFile) {
                        imageFiles.push(file);
                      }
                    }

                    setFieldValue("uploadedImages", imageFiles);
                    // clear the input after uploading the file. Otherwise selecting the same file again would not trigger the input change as nothing has changed.
                    event.target.value = null;
                  }}
                />
                <label className="custom-file-label" htmlFor="uploadedImages" />
              </div>
            </div>
          </div>
          <small>
            For best results, use an image at least 150px by 130px in .jpg or
            .png format, {"<"} 1 MB
          </small>
        </div>
        <div className="col-md-3" />
      </div>
    </Fragment>
  );
};
/********************** ProductImages End *******************************************/

/********************** ProductDetails Start ****************************************/
const ProductDetails = props => {
  const { values, setFieldValue } = props;
  const uploadedOtherDocuments = values.uploadedOtherDocuments || {};
  return (
    <Fragment>
      <div className="row mt-4 mb-3 no-gutters">
        <div className="col-12 edit-page-section-header-text">
          <strong className="ml-2">Product Details </strong>
        </div>
      </div>

      <div className="row mt-1 mb-3 pl-2 pr-2">
        <label className="col-form-label col-12 col-lg-2 edit-page-label required">
          Specification
        </label>
        <div className="col-12 col-lg-4">
          <div
            className={`input-group ${
              props.touched["specification"] && props.errors["specification"]
                ? "is-invalid"
                : ""
            }`}
          >
            <div className="custom-file">
              <input
                type="file"
                // className="custom-file-input"
                className={`custom-file-input ${
                  props.touched["specification"] &&
                  props.errors["specification"]
                    ? "is-invalid"
                    : ""
                }`}
                id={`specification`}
                name={`specification`}
                onChange={e => {
                  const file = e.target.files[0];
                  if (file) {
                    setFieldValue(`specification`, file);
                  }
                }}
              />
              <label className="custom-file-label" htmlFor={`specification`}>
                {values.specification
                  ? values.specification.name
                  : "Choose file"}
              </label>
            </div>
            <div className="input-group-append">
              <button
                className="btn btn-sm btn-outline-info"
                type="button"
                onClick={e => setFieldValue(`specification`, "")}
              >
                Reset
              </button>
            </div>
          </div>

          <ErrorMessage
            component="div"
            name="specification"
            className="invalid-feedback d-block mb-2"
          />
        </div>

        <div className="col-lg-2">
          {uploadedOtherDocuments.specification &&
          uploadedOtherDocuments.specification.link ? (
            <button
              type="button"
              className="btn btn-info btn-sm mt-1"
              onClick={() =>
                downloadDocument(
                  uploadedOtherDocuments.specification.link,
                  msg => window.alert(msg),
                  uploadedOtherDocuments.specification.filename
                )
              }
            >
              <i className="fas fa-download mr-1" /> Specification
            </button>
          ) : null}
        </div>
      </div>

      <div className="row mt-1 pl-2 pr-2">
        <label className="col-form-label col-12 col-lg-2 edit-page-label required">
          Operation Manual
        </label>
        <div className="col-12 col-lg-4">
          <div
            className={`input-group ${
              props.touched["operationManual"] &&
              props.errors["operationManual"]
                ? "is-invalid"
                : ""
            }`}
          >
            <div className="custom-file">
              <input
                type="file"
                // className="custom-file-input"
                className={`custom-file-input ${
                  props.touched["operationManual"] &&
                  props.errors["operationManual"]
                    ? "is-invalid"
                    : ""
                }`}
                id={`operationManual`}
                name={`operationManual`}
                onChange={e => {
                  const file = e.target.files[0];
                  if (file) {
                    setFieldValue(`operationManual`, file);
                  }
                }}
              />
              <label className="custom-file-label" htmlFor={`operationManual`}>
                {values.operationManual
                  ? values.operationManual.name
                  : "Choose file"}
              </label>
            </div>
            <div className="input-group-append">
              <button
                className="btn btn-sm btn-outline-info"
                type="button"
                onClick={e => setFieldValue(`operationManual`, "")}
              >
                Reset
              </button>
            </div>
          </div>

          <ErrorMessage
            component="div"
            name="operationManual"
            className="invalid-feedback d-block mb-2"
          />
        </div>

        <div className="col-lg-2">
          {uploadedOtherDocuments.operationManual &&
          uploadedOtherDocuments.operationManual.link ? (
            <button
              type="button"
              className="btn btn-info btn-sm mt-1"
              onClick={() =>
                downloadDocument(
                  uploadedOtherDocuments.operationManual.link,
                  msg => window.alert(msg),
                  uploadedOtherDocuments.operationManual.filename
                )
              }
            >
              <i className="fas fa-download mr-1" /> Operation Manual
            </button>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};
/********************** Product Details End *******************************************/

/********************** RentalPrice Start *********************************************/
// holding rendal price
// const RentalPrice = props => {
//   const { values, touched, errors, setFieldValue, createUnitType } = props;

//   const styles = {
//     control: base => ({
//       ...base,
//       height: "calc(1.8125rem + 2px)",
//       minHeight: "calc(1.8125rem + 2px)"
//     }),
//     indicatorsContainer: provided => ({
//       ...provided,
//       height: "30px"
//     })
//   };

//   const RATE_CARD_TYPES = props.rentalRateCardTypes || [];
//   const RATE_UNIT_TYPES = props.rentalRateUnitTypes || [];

//   return (
//     <Fragment>
//       <div className="row mt-4 mb-3 no-gutters">
//         <div className="col-12 edit-page-section-header-text">
//           <strong className="ml-2">Asset Usage Charges </strong>
//         </div>
//       </div>
//       <FieldArray
//         name="rates"
//         render={({ insert, remove, push }) => {
//           const ratesErrors = errors.rates || [];
//           const ratesTouched = touched.rates || [];

//           return (
//             <div className="card-body pt-0">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th
//                       style={{ width: "25%" }}
//                       scope="col"
//                       className="border-bottom-0 display-table-header text-center"
//                     >
//                       User Type
//                     </th>
//                     <th
//                       style={{ width: "25%" }}
//                       scope="col"
//                       className="text-right border-bottom-0 display-table-header text-center"
//                     >
//                       Rate
//                     </th>
//                     <th
//                       style={{ width: "25%" }}
//                       scope="col"
//                       className="text-center border-bottom-0 display-table-header text-center"
//                     >
//                       Unit Type
//                     </th>
//                     <th
//                       style={{ width: "25%" }}
//                       scope="col"
//                       className="text-center border-bottom-0 display-table-header text-center"
//                     >
//                       Actions
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {values.rates.map((rtlRate, index) => {
//                     const error = ratesErrors[index] || {};
//                     const touch = ratesTouched[index] || {};
//                     const hideRemoveButton = values.rates.length === 1;
//                     const showAddButton = values.rates.length - 1 !== index;

//                     const rateCard = rtlRate.rateCard.label;
//                     const unitType = rtlRate.unitType.label;

//                     return (
//                       <tr key={`row-${index}`}>
//                         <td>{rateCard}</td>
//                         <td className="text-right">{rtlRate.rate}</td>

//                         <td className="text-center">{unitType}</td>
//                         <td className="text-center">
//                           <button
//                             className="btn btn-sm btn-outline-danger"
//                             type="button"
//                             onClick={() => remove(index)}
//                           >
//                             <i className="far fa-trash-alt" />
//                           </button>
//                         </td>
//                       </tr>
//                     );
//                   })}

//                   <tr>
//                     <th scope="row">
//                       <div className="">
//                         <Select
//                           isClearable
//                           onChange={selectedRentalFor => {
//                             setFieldValue("rateCard", selectedRentalFor);
//                           }}
//                           value={values.rateCard || ""}
//                           options={RATE_CARD_TYPES}
//                           styles={styles}
//                         />
//                       </div>
//                     </th>
//                     <td className="text-right">
//                       <Field
//                         type="number"
//                         className="form-control form-control-sm"
//                         placeholder=""
//                         name="rate"
//                         value={values.rate || ""}
//                       />
//                     </td>

//                     <td>
//                       <div className="">
//                         <Creatable
//                           isClearable
//                           onChange={selectedUnitType => {
//                             if (selectedUnitType.__isNew__) {
//                               createUnitType(selectedUnitType);
//                             } else {
//                               setFieldValue("unitType", selectedUnitType);
//                             }
//                           }}
//                           value={values.unitType || ""}
//                           options={RATE_UNIT_TYPES}
//                           styles={styles}
//                         />
//                       </div>
//                     </td>
//                     <td className="text-center">
//                       <button
//                         className="btn btn-sm btn-outline-info"
//                         type="button"
//                         id="button-addon2"
//                         onClick={() => {
//                           let rateCard = values.rateCard;
//                           let rate = values.rate;
//                           let unitType = values.unitType;

//                           if (rateCard && rate && unitType) {
//                             push({
//                               rateCard,
//                               rate,

//                               unitType
//                             });
//                             setFieldValue("rateCard", "");
//                             setFieldValue("rate", "");
//                             setFieldValue("unitType", "");
//                           }
//                         }}
//                       >
//                         <i className="fas fa-plus mr-2" />
//                         Add
//                       </button>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           );
//         }}
//       />
//     </Fragment>
//   );
// };
/********************** RentalPrice End ****************************************/

/********************** Manufacture Start ****************************************/
const Manufacture = props => {
  const { values } = props;
  const errrorsManufacture = props.errors.manufacture || "";
  const touchedManufacture = props.touched.manufacture || "";

  const uploadedOtherDocuments = values.uploadedOtherDocuments || {};
  return (
    <Fragment>
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="row mt-4 mb-3 no-gutters">
            <div className="col-12 edit-page-section-header-text">
              <strong className="ml-2">Manufacturer Information</strong>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="form-group row mb-2">
              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Manufacturer Name
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["manufacturerName"] &&
                    errrorsManufacture["manufacturerName"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.manufacturerName"
                />
                <div className="invalid-feedback">
                  {errrorsManufacture["manufacturerName"]}
                </div>
              </div>

              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Manufactured Year
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["manufacturedYear"] &&
                    errrorsManufacture["manufacturedYear"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.manufacturedYear"
                />
                <div className="invalid-feedback">
                  {errrorsManufacture["manufacturedYear"]}
                </div>
              </div>
            </div>
            <div className="form-group row mb-2">
              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Model Number
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["modelNumber"] &&
                    errrorsManufacture["modelNumber"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.modelNumber"
                />
                <div className="invalid-feedback">
                  {errrorsManufacture["modelNumber"]}
                </div>
              </div>
            </div>
            <div className="form-group row mb-2">
              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Vendor Name
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["vendorName"] &&
                    errrorsManufacture["vendorName"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.vendorName"
                />
                <ErrorMessage
                  component="div"
                  name="manufacture.vendorName"
                  className="invalid-feedback"
                />
              </div>
              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Vendor Email
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["vendorEmail"] &&
                    errrorsManufacture["vendorEmail"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.vendorEmail"
                />
                <ErrorMessage
                  component="div"
                  name="manufacture.vendorEmail"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-group row mb-2">
              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Vendor Phone
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["vendorPhone"] &&
                    errrorsManufacture["vendorPhone"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.vendorPhone"
                />
                <ErrorMessage
                  component="div"
                  name="manufacture.vendorPhone"
                  className="invalid-feedback"
                />
              </div>
              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Asset Price
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <Field
                  type="number"
                  className={`form-control form-control-sm ${
                    touchedManufacture["vendorPrice"] &&
                    errrorsManufacture["vendorPrice"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.vendorPrice"
                  value={values.manufacture.vendorPrice || ""}
                />
                <ErrorMessage
                  component="div"
                  name="manufacture.vendorPrice"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="form-group row mb-2">
              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Asset Invoice
              </label>
              <div className="col-12 col-lg-4">
                <div
                  className={`input-group ${
                    touchedManufacture["assetInvoice"] &&
                    errrorsManufacture["assetInvoice"]
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <div className="custom-file">
                    <input
                      type="file"
                      className={`custom-file-input ${
                        touchedManufacture["assetInvoice"] &&
                        errrorsManufacture["assetInvoice"]
                          ? "is-invalid"
                          : ""
                      }`}
                      id={`manufacture.assetInvoice`}
                      name={`manufacture.assetInvoice`}
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          props.setFieldValue(`manufacture.assetInvoice`, file);
                        }
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor={`manufacture.assetInvoice`}
                    >
                      {props.values.manufacture.assetInvoice
                        ? props.values.manufacture.assetInvoice.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        props.setFieldValue(`manufacture.assetInvoice`, "")
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>

                <ErrorMessage
                  component="div"
                  name="manufacture.assetInvoice"
                  className="invalid-feedback d-block"
                />
              </div>

              <div className="col-lg-2">
                {uploadedOtherDocuments.assetInvoice &&
                uploadedOtherDocuments.assetInvoice.link ? (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-1"
                    onClick={() =>
                      downloadDocument(
                        uploadedOtherDocuments.assetInvoice.link,
                        msg => window.alert(msg),
                        uploadedOtherDocuments.assetInvoice.filename
                      )
                    }
                  >
                    <i className="fas fa-download mr-1" /> Asset Invoice
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="mt-4 mb-3 no-gutters">
            <div className="col-12 edit-page-section-header-text">
              <strong className="ml-2">Notes</strong>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                Notes
              </label>
              <div className="col-12 col-lg-10 col-xl-10">
                <TextAreaInput
                  label="Notes"
                  fieldName="notes"
                  {...props}
                  error={props.errors["notes"] || ""}
                  touch={props.touched["notes"] || ""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
/********************** Manufacture End ****************************************/

/********************** Location Start ****************************************/
const Location = props => {
  const errorsLocation = props.errors.location || "";
  const touchedLocation = props.touched.location || "";
  return (
    <Fragment>
      <div className="edit-page-section-header-text mt-4">
        <strong>Location Information (as applicable)</strong>
      </div>
      <div className="card-body">
        <div className="form-group row mb-3">
          <TextInput
            label="Institution Name"
            fieldName="location.campus"
            {...props}
            error={errorsLocation["campus"] || ""}
            touch={touchedLocation["campus"] || ""}
            helperText={`Eg: PSG Tech, PSG iTech, PSG IAS, etc.`}
            required
          />
          <TextInput
            label="Building Name"
            fieldName="location.building"
            {...props}
            error={errorsLocation["building"] || ""}
            touch={touchedLocation["building"] || ""}
            helperText={`Eg: K Block, G Block, etc.`}
          />
        </div>

        <div className="form-group row mb-3">
          <TextInput
            label="Department Name"
            fieldName="location.department"
            {...props}
            error={errorsLocation["department"] || ""}
            touch={touchedLocation["department"] || ""}
            helperText={`Enter College Department.`}
            required
          />
          <TextInput
            label="Floor number"
            fieldName="location.floor"
            {...props}
            error={errorsLocation["floor"] || ""}
            touch={touchedLocation["floor"] || ""}
            helperText={`Eg:Ground, 1st, 2nd, 3rd, etc.`}
          />
        </div>

        <div className="form-group row mb-3">
          <TextInput
            label="Lab/Center Name"
            fieldName="location.facility"
            {...props}
            error={errorsLocation["facility"] || ""}
            touch={touchedLocation["facility"] || ""}
            helperText={`Eg: PRAYAS Lab, Bionest, Nano Lab, etc.`}
          />
          <TextInput
            label="Room number"
            fieldName="location.room"
            {...props}
            error={errorsLocation["room"] || ""}
            touch={touchedLocation["room"] || ""}
            helperText={`Enter Room No or Name.`}
          />
        </div>
      </div>
    </Fragment>
  );
};
/********************** Location End ****************************************/

/********************** AssetOwnerContact Start ****************************************/
const AssetOwnerContact = props => {
  const errorsContact = props.errors.assetOwnerContact || "";
  const touchedContact = props.touched.assetOwnerContact || "";
  return (
    <Fragment>
      <div className="edit-page-section-header-text mt-4">
        <strong>Asset Owner Contact Information</strong>
      </div>
      <div className="card-body">
        <div className="form-group row mb-2">
          <TextInput
            label="Name"
            fieldName="assetOwnerContact.name"
            {...props}
            error={errorsContact["name"] || ""}
            touch={touchedContact["name"] || ""}
            required
          />

          <label className="col-form-label col-12 col-lg-2 col-xl-2">
            Phone
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <Field
              type="text"
              inputMode="numeric"
              className={`form-control form-control-sm ${
                touchedContact["phone"] && errorsContact["phone"]
                  ? "is-invalid"
                  : ""
              }`}
              name="assetOwnerContact.phone"
            />

            <ErrorMessage
              component="div"
              name="assetOwnerContact.phone"
              className="invalid-feedback"
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <TextInput
            label="Email"
            fieldName="assetOwnerContact.email"
            {...props}
            error={errorsContact["email"] || ""}
            touch={touchedContact["email"] || ""}
          />
        </div>
      </div>
    </Fragment>
  );
};
/********************** AssetOwnerContact End ****************************************/

/********************** IncubatorContact Start ****************************************/
const IncubatorContact = props => {
  const errorsContact = props.errors.incubatorContact || "";
  const touchedContact = props.touched.incubatorContact || "";
  return (
    <Fragment>
      <div className="edit-page-section-header-text mt-4">
        <strong>Incubator Contact Information</strong>
      </div>
      <div className="card-body">
        <div className="form-group row mb-2">
          <TextInput
            label="Name"
            fieldName="incubatorContact.name"
            {...props}
            error={errorsContact["name"] || ""}
            touch={touchedContact["name"] || ""}
            required
          />

          <label className="col-form-label col-12 col-lg-2 col-xl-2">
            Phone
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <Field
              type="text"
              inputMode="numeric"
              className={`form-control form-control-sm ${
                touchedContact["phone"] && errorsContact["phone"]
                  ? "is-invalid"
                  : ""
              }`}
              name="incubatorContact.phone"
            />

            <ErrorMessage
              component="div"
              name="incubatorContact.phone"
              className="invalid-feedback"
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <TextInput
            label="Email"
            fieldName="incubatorContact.email"
            {...props}
            error={errorsContact["email"] || ""}
            touch={touchedContact["email"] || ""}
          />
        </div>
      </div>
    </Fragment>
  );
};
/********************** IncubatorContact End ****************************************/

/********************** BasicInfo Start ****************************************/
const BasicInfo = props => {
  const { values, initialValues } = props;
  const ASSET_TYPES = props.assetTypes || [];
  const RATE_UNIT_TYPES = props.rentalRateUnitTypes || [];

  let assetTypeError = "";
  if (props.errors.assetType) {
    assetTypeError = props.errors.assetType.value;
  }

  const uploadedOtherDocuments = values.uploadedOtherDocuments || {};

  const assetId = initialValues.assetId;
  const assetName = initialValues.assetName;

  return (
    <Fragment>
      <div className="edit-page-section-header-text">
        <strong>Basic Information</strong>
      </div>
      <div className="card-body">
        <div className="form-group row mb-2">
          <TextInput
            label="Asset Id"
            fieldName="assetId"
            {...props}
            error={props.errors["assetId"] || ""}
            touch={props.touched["assetId"] || ""}
            disabled={!props.newAsset && assetId}
            required
          />

          <TextInput
            label="Asset Name"
            fieldName="assetName"
            {...props}
            error={props.errors["assetName"] || ""}
            disabled={!props.newAsset && assetName}
            touch={props.touched["assetName"] || ""}
            required
          />
        </div>

        <div className="form-group row mb-2">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Asset Type
          </label>

          <div className="col-12 col-lg-4 col-xl-4">
            <Creatable
              isClearable
              onChange={selectedAssetType => {
                if (selectedAssetType && selectedAssetType.__isNew__) {
                  props.createAssetType(selectedAssetType, props.values);
                } else {
                  props.setFieldValue("assetType", selectedAssetType || "");
                }
              }}
              value={props.values.assetType || ""}
              options={ASSET_TYPES}
            />

            {assetTypeError ? (
              <div className="text-danger" style={{ fontSize: "0.875em" }}>
                {assetTypeError}
              </div>
            ) : null}
          </div>
          <SelectInput
            label="Asset Category"
            fieldName="assetCategory"
            {...props}
            error={props.errors["assetCategory"]}
            touch={props.touched["assetCategory"]}
            optionsData={ASSET_CATEGORIES}
            required
          />
        </div>

        <div className="form-group row mb-2">
          <TextInput
            label="Asset Owner"
            fieldName="ownerName"
            {...props}
            error={props.errors["ownerName"] || ""}
            touch={props.touched["ownerName"] || ""}
            required
          />
          <SelectInput
            label="Asset Usage Type"
            fieldName="assetUsage"
            {...props}
            error={props.errors["assetUsage"] || ""}
            touch={props.touched["assetUsage"] || ""}
            optionsData={RENTAL_TYPES}
            required
          />
        </div>
        <div className="form-group row mb-2">
          <TextInput
            label="Serial Number"
            fieldName="serialNumber"
            {...props}
            error={props.errors["serialNumber"] || ""}
            touch={props.touched["serialNumber"] || ""}
            disabled={values.serialNumberDisable}
            required
          />
          <SelectInput
            label="Status"
            fieldName="status"
            {...props}
            error={props.errors["status"] || ""}
            touch={props.touched["status"] || ""}
            optionsData={STATUS_LIST}
            required
          />
        </div>
        <div className="form-group row mb-2">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
            Require Unit Type for Reservation
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="custom-control custom-checkbox  mt-3">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="requireUnitType"
                name="requireUnitType"
                checked={props.values.requireUnitType === true}
              />
              <label className="custom-control-label" htmlFor="requireUnitType">
                Yes
              </label>
            </div>
          </div>

          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
            Daily Availability(in Hours)
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <Field
              type="number"
              min={0}
              max={24}
              className={`form-control form-control-sm ${
                props.errors["availableHours"] && props.errors["availableHours"]
                  ? "is-invalid"
                  : ""
              }`}
              name="availableHours"
            />
            <div className="invalid-feedback">
              {props.errors["availableHours"]}
            </div>
          </div>
        </div>

        <div className="form-group row mb-2">
          <label
            className={`col-form-label col-12 col-lg-2 col-xl-2 edit-page-label${
              props.values.requireUnitType ? "-required" : ""
            }`}
          >
            Charge unit Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <Creatable
              isMulti
              onChange={selectedUnitType => {
                let newUnitType = selectedUnitType.filter(
                  utype => utype.__isNew__
                );
                if (newUnitType && newUnitType.length > 0) {
                  props.createUnitType(newUnitType[0], props.values);
                } else {
                  props.setFieldValue("unitTypes", selectedUnitType);
                }
              }}
              value={props.values.unitTypes || ""}
              options={RATE_UNIT_TYPES}
              className={props.errors.unitTypes ? "is-invalid" : ""}
            />
            <div className="text-danger">{props.errors.unitTypes}</div>
          </div>
          {/* <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
            Rate Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <Select
              isMulti
              onChange={rtypes => {
                props.setFieldValue("rateTypes", rtypes);
              }}
              value={props.values.rateTypes || ""}
              options={ASSET_RATE_TYPES}
            />
          </div> */}
        </div>
        <div className="form-group row mb-2">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
            Asset Detail
          </label>
          <div className="col-12 col-lg-10 col-xl-10">
            <Field
              component="textarea"
              rows="5"
              className={`form-control form-control-sm ${
                props.touched["description"] && props.errors["description"]
                  ? "is-invalid"
                  : ""
              }`}
              name="description"
            />
            <ErrorMessage
              component="div"
              name="description"
              className="invalid-feedback"
            />
          </div>
        </div>

        <div className="form-group row mb-2">
          <label className="col-form-label col-12 col-lg-2 edit-page-label">
            Upload Asset Detail
          </label>
          <div className="col-12 col-lg-4">
            <div
              className={`input-group ${
                props.touched["assetDetailUpload"] &&
                props.errors["assetDetailUpload"]
                  ? "is-invalid"
                  : ""
              }`}
            >
              <div className="custom-file">
                <input
                  type="file"
                  // className="custom-file-input"
                  className={`custom-file-input ${
                    props.touched["assetDetailUpload"] &&
                    props.errors["assetDetailUpload"]
                      ? "is-invalid"
                      : ""
                  }`}
                  id={`assetDetailUpload`}
                  name={`assetDetailUpload`}
                  onChange={e => {
                    const file = e.target.files[0];
                    if (file) {
                      props.setFieldValue(`assetDetailUpload`, file);
                    }
                  }}
                />
                <label
                  className="custom-file-label"
                  htmlFor={`assetDetailUpload`}
                >
                  {props.values.assetDetailUpload
                    ? props.values.assetDetailUpload.name
                    : "Choose file"}
                </label>
              </div>
              <div className="input-group-append">
                <button
                  className="btn btn-sm btn-outline-info"
                  type="button"
                  onClick={e => props.setFieldValue(`assetDetailUpload`, "")}
                >
                  Reset
                </button>
              </div>
            </div>

            <ErrorMessage
              component="div"
              name="assetDetailUpload"
              className="invalid-feedback d-block"
            />
          </div>

          <div className="col-lg-2">
            {uploadedOtherDocuments.assetDetailUpload &&
            uploadedOtherDocuments.assetDetailUpload.link ? (
              <button
                type="button"
                className="btn btn-info btn-sm mt-1"
                onClick={() =>
                  downloadDocument(
                    uploadedOtherDocuments.assetDetailUpload.link,
                    msg => window.alert(msg),
                    uploadedOtherDocuments.assetDetailUpload.filename
                  )
                }
              >
                <i className="fas fa-download mr-1" /> Asset Detail
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
/********************** BasicInfo End ****************************************/

/*********************** CustomForm Start *******************************/
const CustomForm = props => {
  const { isSubmitting, newAsset } = props;

  const permisson = newAsset ? "CREATE" : "UPDATE";
  const submitLabel = newAsset ? "Save" : "Update";

  return (
    <Form>
      <div className="pl-1 pr-1">
        <BasicInfo {...props} />
        <AssetOwnerContact {...props} />
        <IncubatorContact {...props} />
        <Location {...props} />
        <Manufacture {...props} />
        <ProductImages {...props} />
        <ProductDetails {...props} />

        {/* Temporarily Hided */}
        {/* <RentalPrice {...props} /> */}

        <div className="row mb-5 mt-5">
          <div className="col-md-12 text-center">
            <button
              className="btn btn-sm btn-primary mr-2"
              type="button"
              onClick={() => {
                // onSubmit
                // console.log("save as draft:prps:", values);
                // props.setFieldValue("saveAsDraft", true);
                // onSubmit((values, { setSubmitting }))
                props.handleSaveAsDraft(props.values);
              }}
              disabled={isSubmitting}
            >
              Save as Draft
            </button>

            <Can do={permisson} on="Asset">
              <button
                className="btn btn-sm btn-primary"
                type="submit"
                // onClick={onSubmit}
                disabled={isSubmitting}
              >
                {submitLabel}
              </button>
            </Can>
          </div>
        </div>
      </div>
    </Form>
  );
};
/*********************** CustomForm End *******************************/

/*********************** AssetForm Start *******************************/
class AssetForm extends Component {
  render() {
    const { internalOrgs, newAsset } = this.props;
    return (
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3 mb-3">
              <div className="text-center">
                <Link
                  to="/service-mgmt/assets"
                  className="badge bg-white custom-btn-sm float-left"
                >
                  <i className="fas fa-arrow-left mr-2" />
                </Link>
                <span className="edit-page-title">
                  {newAsset ? (
                    <span className="float-left">Add Asset</span>
                  ) : (
                    <span>{this.props.initialValues.assetName}</span>
                  )}
                </span>
              </div>
            </div>

            <Formik
              // enableReinitialize
              initialValues={this.props.initialValues}
              validationSchema={this.props.validationSchema}
              validateOnChange={false}
              onSubmit={(values, { setSubmitting }) => {
                // console.log("onSubmit:", values);
                this.props.handleSubmit(values, { setSubmitting });
              }}
              render={({
                values,
                initialValues,
                // onSubmit,
                isSubmitting,
                errors,
                touched,
                setFieldValue
              }) => {
                const formProps = {
                  values,
                  initialValues,
                  uploadedProductImagesInfo: this.props
                    .uploadedProductImagesInfo,
                  rentalRateUnitTypes: this.props.rentalRateUnitTypes,
                  rentalRateCardTypes: this.props.rentalRateCardTypes,
                  assetTypes: this.props.assetTypes,
                  createUnitType: this.props.createUnitType,
                  createAssetType: this.props.createAssetType,
                  handleRemoveImage: this.props.handleRemoveImage,
                  newAsset: this.props.newAsset,
                  // onSubmit,
                  isSubmitting,
                  internalOrgs,
                  errors,
                  touched,
                  handleSubmit: this.props.handleSubmit,
                  handleSaveAsDraft: this.props.handleSaveAsDraft,
                  setFieldValue
                };
                return <CustomForm {...formProps} />;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AssetForm;
/*********************** AssetForm End *******************************/
