import Logger from "../../Logger";

export const TOGGLE_NAVBAR = "TOGGLE_NAVBAR";

const ToggleNavbarAction = () => (dispatch, getState) => {
  const navBarShow = getState().ToggleNavbarReducer.navBarShow;
  Logger.info("ToggleNavbarAction.js==>navBarShow:", navBarShow);
  const toggle = {
    type: TOGGLE_NAVBAR,
    navBarShow
  };

  return dispatch(toggle);
};

export default ToggleNavbarAction;
