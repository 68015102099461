import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginUser } from "../../actions/login/LoginAction";
import fetchUserPermissions from "../../auth/fetchUserPermissions";

//import Logger from "../../Logger";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // username: "admin", //==> need to remove in future.
      // password: "12345678" //==> need to remove in future.
      username: "",
      password: ""
    };
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = () => {};

  handleLogin = () => {
    const { username, password } = this.state;

    const loginRequest = {
      username: username,
      password: password
    };

    this.props.loginUser(loginRequest);
  };

  redirectLoggedInUser() {
    fetchUserPermissions();
    return <Redirect to="/dashboard" />;
  }

  render() {
    const { username, password } = this.state;
    //Logger.info("Login.js:this.props:", this.props);

    const { userLoggedIn, errorType, errorMessage } = this.props;

    if (userLoggedIn) {
      return this.redirectLoggedInUser();
    }
    //Logger.info("Login.js:username:", username, "password:", password);
    return (
      <div className="main h-100 w-100">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  <p className="lead">Sign in to your account to continue</p>
                </div>
                {errorType !== null && !userLoggedIn ? (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                ) : null}

                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <form>
                        <div className="form-group">
                          <label>Username</label>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            name="username"
                            placeholder="Enter your username"
                            value={username}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            className="form-control form-control-lg"
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={this.onChange}
                          />
                          <small>
                            <a href="pages-reset-password.html">
                              Forgot password?
                            </a>
                          </small>
                        </div>

                        <div className="text-center mt-3">
                          {username !== "" && password !== "" ? (
                            <button
                              name="signinButton"
                              type="button"
                              className="btn btn-md btn-primary"
                              onClick={this.handleLogin}
                            >
                              Sign in
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-md btn-primary"
                              disabled
                            >
                              Sign in
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//Redux
const mapDispatchToProps = dispatch =>
  bindActionCreators({ loginUser }, dispatch);

const mapStateToProps = state => ({
  userLoggedIn: state.LoginReducer.userLoggedIn,
  errorType: state.LoginReducer.errorType,
  errorMessage: state.LoginReducer.errorMessage
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
