import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import { RENTAL_REQUEST_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { INCUBATEE_TYPES_OBJECT } from "../../common/LookupConstants";

const addAddress = (array, value) => {
  if (value) {
    array.push(value);
  }
};

class DuplicateCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalRequest: { _id: null },
      duplicates: [],

      selectedId: null,
      updateRequest: {
        mappedType: null,
        mappedIndividual: null,
        mappedOrganization: null
      },

      userConfirmation: false,

      inputError: {},
      redirect: false,

      redirectToOnboardForm: false,
      redirectToOnboardFormId: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getOrganization = data => {
    const duplicates = data.organizations.map(organization => {
      const email = organization.emails[0]
        ? organization.emails[0].email
        : "--";
      const phone = organization.phones[0]
        ? organization.phones[0].phone
        : "--";
      const address = organization.addresses[0]
        ? organization.addresses[0]
        : "";
      const pan = organization.identificationDocs[0]
        ? organization.identificationDocs[0].number
        : "--";

      let addrs = [];
      addAddress(addrs, address.streetLine1);
      addAddress(addrs, address.streetLine2);
      addAddress(addrs, address.landmark);
      addAddress(addrs, address.city);
      addAddress(addrs, address.state);
      addAddress(addrs, address.postalCode);
      addAddress(addrs, address.country);
      let addressString = addrs.join(",");

      let duplicate = {
        _id: organization._id,
        name: organization.name,
        type: "COMPANY",
        pan: pan,
        phone: phone,
        email: email,
        address: addressString,
        phoneMatch: organization.phoneMatch,
        emailMatch: organization.emailMatch,
        panMatch: organization.panMatch,
        incubatee: organization.incubatee.length > 0
      };

      return duplicate;
    });

    return duplicates;
  };

  getIndividual = data => {
    const duplicates = data.individuals.map(individual => {
      const email = individual.emails[0] ? individual.emails[0].email : "--";
      const phone = individual.phones[0] ? individual.phones[0].phone : "--";
      const address = individual.addresses[0] ? individual.addresses[0] : "";
      const pan = individual.identificationDocs[0]
        ? individual.identificationDocs[0].number
        : "--";

      let fname = individual.firstName || "";
      let lname = individual.lastName || "";
      individual.firstName = `${fname} ${lname}`;

      let addrs = [];
      addAddress(addrs, address.streetLine1);
      addAddress(addrs, address.streetLine2);
      addAddress(addrs, address.landmark);
      addAddress(addrs, address.city);
      addAddress(addrs, address.state);
      addAddress(addrs, address.postalCode);
      addAddress(addrs, address.country);
      let addressString = addrs.join(",");

      let duplicate = {
        _id: individual._id,
        name: `${fname} ${lname}`,
        type: "INDIVIDUAL",
        pan: pan,
        phone: phone,
        email: email,
        address: addressString,
        phoneMatch: individual.phoneMatch,
        emailMatch: individual.emailMatch,
        panMatch: individual.panMatch,
        incubatee: individual.incubatee.length > 0
      };

      return duplicate;
    });

    return duplicates;
  };

  setDataFromServer = data => {
    let duplicates = [
      ...this.getOrganization(data),
      ...this.getIndividual(data)
    ];

    let selectedId = null;

    let updateRequest = {};

    if (data.mappedType === "COMPANY") {
      selectedId = data.mappedOrganization._id;
      updateRequest = {
        mappedType: data.mappedType,
        mappedIndividual: null,
        mappedOrganization: selectedId
      };
    } else if (data.mappedType === "INDIVIDUAL") {
      selectedId = data.mappedIndividual._id;
      updateRequest = {
        mappedType: data.mappedType,
        mappedIndividual: selectedId,
        mappedOrganization: null
      };
    }

    this.setState({
      duplicates,
      rentalRequest: data,
      selectedId,
      updateRequest
    });
  };

  getRentalRequests = id => {
    let url = `${RENTAL_REQUEST_API}/${id}/lookup`;

    HttpUtil.get(
      url,
      {},
      data => {
        this.setDataFromServer(data);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSelect = (id, incubateeType) => {
    let updateRequest = {};
    if (incubateeType === "COMPANY") {
      updateRequest = {
        mappedType: incubateeType,
        mappedIndividual: null,
        mappedOrganization: id
      };
    }

    if (incubateeType === "INDIVIDUAL") {
      updateRequest = {
        mappedType: incubateeType,
        mappedIndividual: id,
        mappedOrganization: null
      };
    }
    this.setState({ updateRequest, selectedId: id });
  };

  okConfirmUpdate = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      userConfirmation: true
    });
  };

  getRequest = () => {
    const updateRequest = this.state.updateRequest;
    let hasError = false;
    if (!updateRequest.mappedType) {
      hasError = true;
    }

    let selectedId = this.state.selectedId;
    if (hasError || !selectedId) {
      hasError = hasError || true;
    }

    if (hasError) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: "Please select a record from the matched records."
      });
      window.scrollTo(0, 0);
      return false;
    }

    return this.state.updateRequest;
  };

  handleCreate = () => {
    const rentalRequest = this.state.rentalRequest;
    const url = `${RENTAL_REQUEST_API}/${rentalRequest._id}/onboard`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      {},
      data => {
        this.setState({
          redirectToOnboardForm: true,
          redirectToOnboardFormId: data._id,
          userConfirmation: false
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleUpdate = () => {
    const updateRequest = this.getRequest();
    if (!updateRequest) {
      return;
    }

    const userConfirmation = this.state.userConfirmation;
    if (!userConfirmation) {
      this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "info",
        alertMessage: "Do you want to Update ?"
      });
      return;
    }

    const rentalRequest = this.state.rentalRequest;
    const url = `${RENTAL_REQUEST_API}/${rentalRequest._id}/update-mapped`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.put(
      url,
      headers,
      updateRequest,
      data => {
        this.setState({ redirect: true, userConfirmation: false });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.userConfirmation) {
      this.handleUpdate();
    }
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getRentalRequests(id);
    }
  }

  render() {
    const {
      duplicates,
      selectedId,
      rentalRequest,
      redirect,
      redirectToOnboardForm,
      redirectToOnboardFormId
    } = this.state;

    if (rentalRequest._id === null) {
      return null;
    }

    if (redirectToOnboardForm) {
      const stateObj = {
        pathname: `/asset/requestor-onboard-form/${redirectToOnboardFormId}`,
        state: { onboardCreated: true }
      };
      return <Redirect to={stateObj} />;
    }

    if (redirect) {
      const url = "/asset/enquiries";
      return <Redirect to={url} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const renderList = duplicates.map((dup, index) => {
      const cssClassPhoneMatch = dup.phoneMatch
        ? "badge badge-pill badge-success"
        : "";
      const cssClassEmailMatch = dup.emailMatch
        ? "badge badge-pill badge-success"
        : "";
      const cssClassPanMatch = dup.panMatch
        ? "badge badge-pill badge-success"
        : "";

      return (
        <Fragment key={index}>
          <div className="card-body p-0">
            <div className="form-row mb-3 mt-3 pl-3">
              <div className="my-auto text-center">
                <div
                  className="custom-control custom-radio custom-control-inline mr-0"
                  datatoggle="tooltip"
                  title="Use This"
                >
                  <input
                    type="radio"
                    id={`selected${index}`}
                    name="selected"
                    value={dup._id}
                    checked={selectedId === dup._id}
                    className="custom-control-input"
                    onChange={this.handleSelect.bind(this, dup._id, dup.type)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`selected${index}`}
                  />
                </div>
              </div>
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-row">
                      <div className="form-group mb-0 col-xs-12 col-sm-12 col-md-4 ">
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                          <strong>Name</strong>
                        </label>
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                          <span className="">{dup.name}</span>
                        </label>
                      </div>

                      <div className="form-group mb-0 col-xs-12 col-sm-12 col-md-4 ">
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                          <strong>Incubatee</strong>
                        </label>
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                          {dup.incubatee ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                          <span className="">{dup.incubatee}</span>
                        </label>
                      </div>

                      <div className="form-group mb-0 col-xs-12 col-sm-12 col-md-4 ">
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                          <strong>Entity Type</strong>
                        </label>
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                          <span className="">
                            {INCUBATEE_TYPES_OBJECT[dup.type]}
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="form-row mt-xl-3">
                      <div className="form-group mb-0 col-xs-12 col-sm-12 col-md-4 ">
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                          <strong>Phone</strong>
                        </label>
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                          <span className={cssClassPhoneMatch}>
                            {dup.phone}
                          </span>
                        </label>
                      </div>
                      <div className="form-group mb-0 col-xs-12 col-sm-12 col-md-4 ">
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                          <strong>Email</strong>
                        </label>
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                          <span className={cssClassEmailMatch}>
                            {dup.email}
                          </span>
                        </label>
                      </div>

                      <div className="form-group mb-0 col-xs-12 col-sm-12 col-md-4 ">
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                          <strong>PAN</strong>
                        </label>
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                          <span className={cssClassPanMatch}>{dup.pan}</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-row">
                      <div className="form-group mb-0 col-12">
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                          <strong>Address</strong>
                        </label>
                        <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                          <span className="">{dup.address}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="p-0" />
        </Fragment>
      );
    });

    const props = {
      rentalRequest: this.state.rentalRequest
    };

    return (
      <div>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <RequestorInformation {...props} />

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-card-header text-white text-center">
                <span className="card-title mb-0">
                  {/* <Link
                    to="/asset/enquiries"
                    className="badge bg-white custom-btn-sm"
                  >
                    <i className="fas fa-arrow-left" />
                  </Link>
                  &nbsp;*/}
                  Duplicate Check - Matched Records
                </span>
              </div>

              <div className="card-body h-100 p-0">
                {renderList.length > 0 ? (
                  renderList
                ) : (
                  <p className="text-center mt-4 text-danger">
                    No matching records found.
                  </p>
                )}
              </div>

              <div className="row mb-5">
                <div className="col-md-12 text-center">
                  {renderList.length > 0 ? (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.handleUpdate}
                    >
                      Use Existing Record
                    </button>
                  ) : null}
                  &nbsp;
                  <button
                    className={`btn btn-sm btn-${
                      renderList.length === 0 ? "primary" : "warning text-white"
                    }`}
                    onClick={this.handleCreate}
                  >
                    Create New Record
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DuplicateCheck;

export const RequestorInformation = props => {
  const { rentalRequest } = props;
  const email = rentalRequest.emails[0] ? rentalRequest.emails[0].email : "";
  const phone = rentalRequest.phones[0] ? rentalRequest.phones[0].phone : "";
  const address = rentalRequest.addresses[0] ? rentalRequest.addresses[0] : "";
  const pan = rentalRequest.identificationDocs[0]
    ? rentalRequest.identificationDocs[0].number
    : "";

  let addrs = [];
  addAddress(addrs, address.streetLine1);
  addAddress(addrs, address.streetLine2);
  addAddress(addrs, address.landmark);
  addAddress(addrs, address.city);
  addAddress(addrs, address.state);
  addAddress(addrs, address.postalCode);
  addAddress(addrs, address.country);
  let addressString = addrs.join(",");

  return (
    <Fragment>
      <h4 className="text-center">Asset Enquiry - Duplicate Check</h4>
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <span className="card-title mb-0">
                <Link
                  to="/asset/enquiries"
                  className="badge bg-white custom-btn-sm float-left"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
                <div className="text-center">Requestor Information</div>
              </span>
            </div>
            <div className="card-body h-100">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                      Name
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <input
                        type="text"
                        className="form-control form-control-sm "
                        value={rentalRequest.name}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                      Requestor Type
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <input
                        type="text"
                        className="form-control form-control-sm "
                        value={
                          INCUBATEE_TYPES_OBJECT[rentalRequest.requestorType]
                        }
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                      Address
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <textarea
                        className="form-control form-control-sm "
                        value={addressString}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                      Phone
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <input
                        type="text"
                        className="form-control form-control-sm "
                        value={phone}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                      Email
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <input
                        type="text"
                        className="form-control form-control-sm "
                        value={email}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                      PAN
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <input
                        type="text"
                        className="form-control form-control-sm "
                        value={pan}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
