import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import Download from "downloadjs";
import {
  INCUBATEES_API,
  INDIVIDUALS_API,
  ORGANIZATIONS_API,
  MILESTONE_API,
  INCUBATEE_GRADUATION_API,
  GRANT_AWARDS_API
} from "../common/Constants";
import {
  INCUBATEE_STAGES_OBJECT,
  INCUBATION_SECTORS_OBJECT,
  SUB_SECTORS_OBJECT,
  RESIDENTIAL_STATUS_OBJECT,
  GRADUATION_STATUS_OBJECT,
  INCUBATION_CATEGORY_TYPES_OBJECT
} from "../common/IncubateeProfileConstants";
import HttpUtil from "../common/HttpUtil";
import {
  getDisplayDate,
  convertNumberToDate,
  convertDateToNumber
} from "../lease/DateUtil";
import AlertComponent from "../common/AlertComponent";
import ShowHideComponent from "../widgets/ShowHideComponent";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import {
  sizePerPageOptionRenderer,
  paginationTotalRenderer
} from "../common/TableList";
import {
  MILESTONE_CATEGORY_TYPES_OBJECT,
  LEASE_TYPES_OBJECT,
  PHONE_TYPES_LIST_OBJECT,
  EMAIL_TYPES_LIST_OBJECT,
  ADDRESS_TYPES_LIST_OBJECT,
  GRADUATION_REASONS_OBJECT
} from "../common/LookupConstants";
import {
  downloadDocument,
  getDescription,
  getConcatenatedName
} from "../common/Util";
import Can from "../../auth/can";
import ProductServices from "./ProductServices";
import FinancialYearInfo from "./FinancialYearInfo";
import EducationList from "./EducationList";
import { formatNumericDate } from "../lease/DateUtil";

class IncubateeStartupProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incubatee: null,
      actionBtnShow: false
    };
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const incubateeId = this.props.match.params.id;
      this.getIncubatee(incubateeId);
    }

    if (
      this.props.location.state &&
      this.props.location.state.milestoneCreated
    ) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: "New Milestone has been created successfully."
      });
    }
  }

  handleEdit = () => {
    this.setState({ editIncubateeProfile: true });
  };

  getIncubatee = incubateeId => {
    const url = INCUBATEES_API + "/" + incubateeId;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ incubatee: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  convertIncubateeResidentialStatus = (resStatus, e) => {
    e.preventDefault();
    const incubatee = this.state.incubatee;
    const url = `${INCUBATEES_API}/${incubatee._id}/residential-status`;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      { residentialStatus: resStatus },
      data => {
        this.setState({
          incubatee: data,
          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Residential status for "${
            data.name
          }" has been changed to "${
            RESIDENTIAL_STATUS_OBJECT[data.residentialStatus]
          }".`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      confirm: false
    });
  };

  okConfirmUpdate = () => {
    // do stuff
    this.setState(
      {
        alertType: "",
        showAlert: false,
        alertColor: "",
        alertMessage: "",
        confirm: true
      },
      this.graduationRequest()
    );
  };

  renderEditButton = () => {
    let editButton;

    const incubateeType = this.state.incubatee.incubateeType;
    const incubateeId = this.state.incubatee._id;
    const graduationStatus = this.state.incubatee.graduationStatus;

    const graduated = graduationStatus === "GRADUATED";

    if (incubateeType === "COMPANY") {
      const id = this.state.incubatee.organization._id;
      const url = `/organizations/edit/${id}?from=/admin/incubatee/profile/${incubateeId}&incProfUrl=/admin/incubatee/profile/${incubateeId}&incubatee=${incubateeId}&graduationStatus=${graduationStatus}`;
      editButton = (
        <Can do="VIEW" on="Organization">
          <Link
            to={url}
            className="list-group-item list-group-item-action d-flex align-items-center"
          >
            {graduated ? (
              <i className="fas fa-eye fa-sm pr-3" />
            ) : (
              <i className="fas fa-pencil-alt fa-sm pr-3" />
            )}
            <span className="fa-sm">
              {graduated ? "View Organization" : "Edit Organization"}
            </span>
          </Link>
        </Can>
      );
    }

    if (incubateeType === "INDIVIDUAL") {
      const id = this.state.incubatee.individual._id;
      const url = `/individuals/edit/${id}?from=/admin/incubatee/profile/${incubateeId}&incProfUrl=/admin/incubatee/profile/${incubateeId}&incubatee=${incubateeId}&graduationStatus=${graduationStatus}`;

      editButton = (
        <Can do="VIEW" on="Individual">
          <Link
            to={url}
            className="list-group-item list-group-item-action d-flex align-items-center"
          >
            {graduated ? (
              <i className="fas fa-eye fa-sm pr-3" />
            ) : (
              <i className="fas fa-pencil-alt fa-sm pr-3" />
            )}
            <span className="fa-sm">
              {graduated ? "View Individual" : "Edit Individual"}
            </span>
          </Link>
        </Can>
      );
    }

    return editButton;
  };

  renderIncubateeConvertBtn = () => {
    let convertBtn;
    const incubateeType = this.state.incubatee.incubateeType;
    const incubateeId = this.state.incubatee._id;
    const graduationStatus = this.state.incubatee.graduationStatus;

    const graduated = graduationStatus === "GRADUATED";

    if (graduated) {
      return null;
    }

    if (incubateeType === "INDIVIDUAL") {
      const id = this.state.incubatee.individual._id;
      const url = `/admin/incubatee/convert/${id}?from=/admin/incubatee/profile/&incubatee=${incubateeId}&incProfUrl=/admin/incubatee/profile/${incubateeId}`;

      convertBtn = (
        <Can do="CREATE" on="Organization">
          <Link
            to={url}
            className="list-group-item list-group-item-action d-flex align-items-center"
            data-toggle="tooltip"
            title="Convert Individual to Organization"
          >
            <i className="fas fa-sitemap fa-sm pr-3" />

            <span className="fa-sm"> Convert to Organization</span>
          </Link>
        </Can>
      );
    }
    return convertBtn;
  };

  toggleActionBtn = () => {
    this.setState({ actionBtnShow: !this.state.actionBtnShow });
  };

  renderGraduationStatusChange = () => {
    const { incubatee } = this.state;
    if (incubatee.graduationStatus === "GRADUATED") {
      return null;
    }

    let graduationRequesBtn = null;
    let graduationRequest = incubatee.graduationRequest || null;

    if (!graduationRequest || graduationRequest.status === "REJECTED") {
      graduationRequesBtn = (
        <Can do="CREATE" on="GraduationRequest">
          <Fragment>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href="#"
                role="button"
                className="list-group-item list-group-item-action d-flex align-items-center"
                onClick={this.graduationRequest.bind(this)}
              >
                <i
                  className="fas fa-graduation-cap fa-sm pr-3"
                  aria-hidden="true"
                />
                <span className="fa-sm">Graduate</span>
              </a>
            }
          </Fragment>
        </Can>
      );
    } else {
      graduationRequesBtn = (
        <Can do="VIEW" on="GraduationRequest">
          <Link
            to={`/incubatee/graduation/form/${
              incubatee.graduationRequest._id
            }?from=/admin/incubatee/profile/${incubatee._id}`}
            className="list-group-item list-group-item-action d-flex align-items-center"
            data-toggle="tooltip"
            title="View Graduation Request"
          >
            <i className="fas fa-eye fa-sm pr-3" />
            <span className="fa-sm"> Graduation Request</span>
          </Link>
        </Can>
      );
    }

    return graduationRequesBtn;
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getGraduationFormData = () => {
    const formData = new FormData();
    const incubateeId = this.state.incubatee._id;
    let date = new Date();
    date = convertDateToNumber(date);
    const graduationRequest = {
      incubatee: incubateeId,
      reason: "",
      graduationDate: date,
      notes: "",
      status: "",
      action: "SAVE",
      addresses: [],
      emails: [],
      phones: [],
      documents: []
    };

    formData.append("message", JSON.stringify(graduationRequest));
    return formData;
  };

  graduationRequest = e => {
    if (e) {
      e.preventDefault();
    }

    if (!this.state.confirm) {
      return this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "info",
        alertMessage: "Do you want to start the Graduation process ?",
        confirm: true
      });
    }

    const formData = this.getGraduationFormData();

    const url = INCUBATEE_GRADUATION_API;
    HttpUtil.post(
      url,
      {},
      formData,
      data => {
        this.setState({
          confirm: false,
          redirectToGraduationForm: true,
          redirectToGraduationFormId: data._id
        });
      },
      (data, status) => {
        this.handleApiFailed(data.message);
        this.setState({ confirm: false });
      },
      error => {
        this.handleApiFailed(error.toString());
        this.setState({ confirm: false });
      }
    );
  };

  render() {
    const {
      incubatee,

      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      actionBtnShow
    } = this.state;

    if (incubatee === null) {
      return null;
    }

    if (this.state.redirectToGraduationForm) {
      const graduationRequestId = this.state.redirectToGraduationFormId;
      const stateObj = {
        pathname: `/incubatee/graduation/form/${graduationRequestId}`,
        state: {
          requestSubmitted: true,
          message: `Graduation Request for ${
            incubatee.name
          } has been created sucessfully.`
        }
      };
      return <Redirect to={stateObj} />;
    }

    const incubateeType = incubatee.incubateeType;

    const props = {
      incubatee: incubatee,
      handleApiFailed: this.handleApiFailed
    };

    const milestoneProps = {
      incubateeId: incubatee._id,
      handleApiFailed: this.handleApiFailed
    };

    const currentLocationUrl = this.props.location.pathname;

    const show = actionBtnShow ? "show" : "";

    const graduated = incubatee.graduationStatus === "GRADUATED";

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-3">
            <div className="d-inline-flex ">
              <h5 className="mb-0 ml-1">Incubatee Profile</h5>
            </div>
          </div>
          <div className="col-9 text-right">
            <div className={`btn-group ${show}`}>
              <button
                type="button"
                className="btn btn-sm bg-n-primary dropdown-toggle text-white"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={actionBtnShow}
                onClick={this.toggleActionBtn}
              >
                Action
              </button>
              <div
                className={`dropdown-menu ${show} m-0 p-0`}
                x-placement="bottom-start"
                style={{
                  border: "none",
                  position: "absolute",
                  willChange: "transform",
                  top: 0,
                  left: "-160px",
                  transform: "translate3d(0px, 31px, 0px)",
                  width: 230
                }}
              >
                <div className="list-group dropdown-item-icon-text">
                  {this.renderEditButton()}
                  <Can do="UPDATE" on="Incubatee">
                    <Link
                      to={`/incubatee/edit/${
                        incubatee._id
                      }?from=admin/incubatee/profile/${
                        incubatee._id
                      }&graduationStatus=${incubatee.graduationStatus}`}
                      className="list-group-item list-group-item-action d-flex align-items-center"
                    >
                      {graduated ? (
                        <i className="fas fa-eye fa-sm pr-3" />
                      ) : (
                        <i className="fas fa-pencil-alt fa-sm pr-3" />
                      )}
                      <span className="fa-sm">
                        {graduated
                          ? "View Incubatee Profile"
                          : "Edit Incubatee Profile"}
                      </span>
                    </Link>
                  </Can>

                  {this.renderIncubateeConvertBtn()}

                  {graduated ? null : (
                    <Can do="CREATE" on="Milestone">
                      <Link
                        to={`/add-milestone/${
                          incubatee._id
                        }?from=/admin/incubatee/profile/${incubatee._id}`}
                        className="list-group-item list-group-item-action d-flex align-items-center"
                      >
                        <i className="fas fa-plus fa-sm pr-3" />
                        <span className="fa-sm"> Add Milestone</span>
                      </Link>
                    </Can>
                  )}

                  {this.renderGraduationStatusChange()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="elevate"
          style={{ padding: "1em", backgroundColor: "#fff" }}
        >
          <BasicProfile incubatee={incubatee} />
          <div className="card-deck mt-5">
            <About incubatee={incubatee} />
            <IncubateeProfileAndStatus incubatee={incubatee} />
          </div>

          {incubateeType === "COMPANY" ? (
            <PromotersProfile incubatee={incubatee} />
          ) : null}

          {incubateeType === "INDIVIDUAL" ? (
            <EducationList incubatee={incubatee} />
          ) : null}

          <ProductServices incubatee={incubatee} />

          {incubateeType === "COMPANY" ? (
            <FinancialYearInfo incubatee={incubatee} />
          ) : null}

          {incubatee.graduationStatus === "GRADUATED" ? (
            <GraduatationDetails incubatee={incubatee} />
          ) : null}

          {/* Milestone */}
          <Can do="VIEW" on="Milestone">
            <Milestones {...milestoneProps} />
          </Can>
          <Leases
            incubatee={incubatee}
            currentLocationUrl={currentLocationUrl}
          />
          <GrantsProgrammeProfile incubatee={incubatee} />
          <Documents {...props} />

          <PatentsProfile incubatee={incubatee} />
        </div>
      </Fragment>
    );
  }
}

export default IncubateeStartupProfile;

function replaceAll(txt, find, replace) {
  return txt.split(find).join(replace);
}

function checkEmptyAndAdd(list, data) {
  if (data) {
    let newData = replaceAll(data, "\r", "");
    newData = replaceAll(newData, "\n", "");
    list.push(newData);
  }
}

const GraduatationDetails = props => {
  const incubatee = props.incubatee;
  const graduationRequest = incubatee.graduationRequest || {};

  return (
    <div>
      <div className="row mt-3 mb-5">
        <div className="col-md-12">
          <div
            className="card flex-fill"
            style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
          >
            <div className="card-header border-0">
              <h6 className="card-title mb-0 text-center">
                Graduation Details
              </h6>
            </div>
            <div className="card-body pt-0">
              <div className="row mt-2 mb-3">
                <div className="col-md-4">
                  <div className="row mt-2 mb-3">
                    <div className="col-12">
                      <span className="d-block border-bottom pb-0 mb-1">
                        Graduation Date
                      </span>
                      <p className="mt-1">
                        {getDisplayDate(
                          convertNumberToDate(graduationRequest.graduationDate)
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 mb-3">
                    <div className="col-12">
                      <span className="d-block border-bottom pb-0 mb-1">
                        Notes
                      </span>
                      <p className="mt-1">{graduationRequest.notes}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="row mt-2 mb-3">
                    <div className="col-12">
                      <span className="d-block border-bottom pb-0 mb-1">
                        Graduation Reason
                      </span>
                      <p className="mt-1">
                        {GRADUATION_REASONS_OBJECT[graduationRequest.reason]}
                      </p>
                    </div>
                  </div>

                  <div className="row mt-2 mb-3">
                    <div className="col-12">
                      {graduationRequest.documents &&
                      graduationRequest.documents.length > 0 ? (
                        <Fragment>
                          <span className="d-block border-bottom pb-0 mb-1">
                            Documents
                          </span>
                          <ul className="list-group">
                            {graduationRequest.documents.map((doc, idx) => {
                              return (
                                <li
                                  key={idx}
                                  className="list-group-item d-flex justify-content-between align-items-center border-0 pl-0 pb-0 pt-1"
                                >
                                  <span>
                                    <i className="far fa-file-pdf fa-lg text-danger mr-2" />
                                    {doc.filename}
                                  </span>
                                  <div>
                                    <i
                                      className="fas fa-download fa-md mr-3"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        downloadDocument(
                                          doc.link,
                                          msg => window.alert(msg),
                                          doc.filename
                                        )
                                      }
                                    />
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </Fragment>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <GraduationContactInformation {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function splitIndianMobileFormate(mobileNumber) {
  let numberToString = mobileNumber.toString();
  if (numberToString.charAt(0) === "0") {
    return mobileNumber;
  }

  if (numberToString.length !== 10) {
    return mobileNumber;
  }

  let output = [];
  for (let i = 0; i < numberToString.length; i++) {
    if (i === 5) {
      output.push(" ");
    }
    output.push(numberToString.charAt(i));
  }
  return output.join("");
}

function addressBuilder(address) {
  const addressList = [];
  const addrLine123 = [];
  checkEmptyAndAdd(addrLine123, address.streetLine1);
  checkEmptyAndAdd(addrLine123, address.streetLine2);
  checkEmptyAndAdd(addrLine123, address.landmark);
  checkEmptyAndAdd(addressList, addrLine123.join(", "));

  const cityStatePostalCode = [];
  checkEmptyAndAdd(cityStatePostalCode, address.city);
  checkEmptyAndAdd(cityStatePostalCode, address.state);
  checkEmptyAndAdd(cityStatePostalCode, String(address.postalCode));

  checkEmptyAndAdd(addressList, cityStatePostalCode.join(", "));
  return addressList;
}

const GraduationContactInformation = props => {
  const incubatee = props.incubatee || {};

  const graduationRequest = incubatee.graduationRequest || {};
  let phones = graduationRequest.phones || [];
  let emails = graduationRequest.emails || [];
  let addresses = graduationRequest.addresses || [];
  // phones = [];
  // emails = [];
  // addresses = [];
  if (phones.length === 0 && emails.length === 0 && addresses.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <div className="row mb-3">
        <div className="col-12 mt-2">
          <span className="d-block border-bottom pb-0 mb-1">
            Contact Information
          </span>
          <div className="card border-bottom-0 mt-2">
            {phones.length > 0 ? (
              <Fragment>
                {phones.map((ph, index) => {
                  return (
                    <div key={index} className="row no-gutters border-bottom">
                      <div className="col-2 text-center align-self-center">
                        <i className="fas fa-phone fa-flip-horizontal text-secondary fa-lg" />
                      </div>
                      <div className="col-10 d-flex justify-content-between align-items-center border-left p-3">
                        <div className="text-wrap w-75">
                          {splitIndianMobileFormate(ph.phone)}
                        </div>
                        <span className="badge badge-primary badge-pill">
                          {`${PHONE_TYPES_LIST_OBJECT[ph.type] ||
                            "Unspecified"}`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : null}

            {emails.length > 0 ? (
              <Fragment>
                {emails.map((em, index) => {
                  return (
                    <div key={index} className="row no-gutters border-bottom">
                      <div className="col-2 text-center align-self-center">
                        <i className="fas fa-envelope text-secondary fa-lg" />
                      </div>
                      <div className="col-10 d-flex justify-content-between align-items-center border-left p-3">
                        <div className="text-wrap w-75">{em.email}</div>
                        <span className="badge badge-primary badge-pill">
                          {`${EMAIL_TYPES_LIST_OBJECT[em.type] ||
                            "Unspecified"}`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : null}

            {addresses.length > 0 ? (
              <Fragment>
                {addresses.map((addr, index) => {
                  const addressList = addressBuilder(addr);
                  return (
                    <div key={index} className="row no-gutters border-bottom">
                      <div className="col-2 text-center align-self-center">
                        <i className="fas fa-map-marker-alt text-secondary fa-lg" />
                      </div>
                      <div className="col-10 border-left p-3">
                        <span className="text-muted">
                          {`${ADDRESS_TYPES_LIST_OBJECT[addr.type] ||
                            "Unspecified"}`}
                        </span>
                        <br />
                        <div className="text-wrap w-100">
                          {addressList.map((addr, idx) => (
                            <div className="row pb-1" key={idx}>
                              <div className="col-12">{addr}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

/** +++++++++++++++++++++ Basic Profile Start +++++++++++++++++++++ */
class BasicProfile extends Component {
  getAddress(coreInfo) {
    const addressList = [];

    const address = coreInfo.addresses[0];
    const addrLine123 = [];
    checkEmptyAndAdd(addrLine123, address.streetLine1);
    checkEmptyAndAdd(addrLine123, address.streetLine2);
    checkEmptyAndAdd(addrLine123, address.landmark);
    checkEmptyAndAdd(addressList, addrLine123.join(", "));

    const cityStatePostalCode = [];

    checkEmptyAndAdd(cityStatePostalCode, address.city);
    checkEmptyAndAdd(cityStatePostalCode, address.state);
    checkEmptyAndAdd(cityStatePostalCode, String(address.postalCode));

    checkEmptyAndAdd(addressList, cityStatePostalCode.join(", "));

    return (
      <Fragment>
        {addressList.map((addr, index) => (
          <div className="row pb-1" key={index}>
            <div className="col-12 text-center">{addr}</div>
          </div>
        ))}
      </Fragment>
    );
  }

  getEmail(emails) {
    for (let index = 0; index < emails.length; index++) {
      const email = emails[index];
      if (email.status === "ACTIVE") {
        return (
          <Fragment>
            <i className="fas fa-envelope pr-2" />
            {email.email}
          </Fragment>
        );
      }
    }
    return null;
  }

  getPhone(phones) {
    for (let index = 0; index < phones.length; index++) {
      const phone = phones[index];
      if (phone.status === "ACTIVE") {
        return (
          <Fragment>
            <i className="fas fa-phone fa-flip-horizontal pr-2" />
            {phone.phone}
          </Fragment>
        );
      }
    }
    return null;
  }

  getWebsite(coreInfo) {
    if (coreInfo.website) {
      return (
        <Fragment>
          <i className="fas fa-globe pr-2" />
          {coreInfo.website}
        </Fragment>
      );
    }
    return null;
  }

  render() {
    const { incubatee } = this.props;
    let coreInfo;
    let name;
    let nameIcon;
    let logoOrPhoto;
    if (incubatee.incubateeType === "COMPANY") {
      coreInfo = incubatee.organization;
      name = coreInfo.name;
      nameIcon = "fas fa-industry";
      const logo = incubatee.organization.logo || null;
      const primaryUrl = `${ORGANIZATIONS_API}/${incubatee.organization._id}`;
      if (logo) {
        logoOrPhoto = `${primaryUrl}/logo/${logo._id}`;
      }
    } else {
      coreInfo = incubatee.individual;
      name = [coreInfo.firstName, coreInfo.lastName]
        .filter(n => (n ? true : false))
        .join(" ");
      nameIcon = "fas fa-user-tie";
      const photo = incubatee.individual.photo || null;
      const primaryUrl = `${INDIVIDUALS_API}/${incubatee.individual._id}`;

      if (photo) {
        logoOrPhoto = `${primaryUrl}/photo/${photo._id}`;
      }
    }
    const contacts = [
      this.getWebsite(coreInfo),
      this.getEmail(coreInfo.emails),
      this.getPhone(coreInfo.phones)
    ].filter(contact => (contact ? true : false));

    let graduationStatus = null;
    if (incubatee.graduationStatus === "GRADUATED") {
      graduationStatus = GRADUATION_STATUS_OBJECT[incubatee.graduationStatus];
    }
    return (
      <div className="card border-0">
        <div
          className="card-body text-center"
          style={{ backgroundColor: "inherit" }}
        >
          <div className="d-flex justify-content-center align-items-center mb-3">
            {logoOrPhoto ? (
              <div className="mr-2 d-flex" style={{ width: 50, height: 50 }}>
                <img
                  className="rounded-circle w-100 align-self-center"
                  src={logoOrPhoto}
                  alt="User"
                />
              </div>
            ) : null}
            <h1 className="mb-0" style={{ color: "#647acb" }}>
              {logoOrPhoto ? null : <i className={`${nameIcon} pr-2`} />}
              {name}
              <span
                className="badge badge-soft-success badge-pill ml-2"
                style={{ fontSize: "50%" }}
              >
                {graduationStatus}
              </span>
            </h1>
          </div>

          {this.getAddress(coreInfo)}
          <ShowHideComponent show={contacts.length > 0}>
            <ul className="contact-list">
              {contacts.map((contact, index) => (
                <li key={index}>{contact}</li>
              ))}
            </ul>
          </ShowHideComponent>
        </div>
      </div>
    );
  }
}

/** +++++++++++++++++++++ Basic Profile End +++++++++++++++++++++ */

/** +++++++++++++++++++++ About Start +++++++++++++++++++++ */
export class About extends Component {
  render() {
    const hasData = this.props.incubatee.about ? true : false;

    return (
      <ShowHideComponent show={hasData}>
        <div
          className="card"
          style={{
            borderTop: "2px solid #647acb"
          }}
        >
          <div className="card-header border-0">
            <h6 className="card-title mb-0 text-center">About</h6>
          </div>
          <div
            className="card-body pt-0 pb-0"
            style={{ maxHeight: 300, overflowX: "auto" }}
          >
            <p className="text-justify pb-0">
              &nbsp;&nbsp;&nbsp;
              {this.props.incubatee.about
                ? this.props.incubatee.about
                : "No information available"}
            </p>
          </div>
        </div>
      </ShowHideComponent>
    );
  }
}
/** +++++++++++++++++++++ About End +++++++++++++++++++++ */

/** +++++++++++++++++++++ Promoters Start +++++++++++++++++++++ */
export class PromotersProfile extends Component {
  getEmail = promoter => {
    const individual = promoter.individual;
    const email = {};
    individual.emails.forEach(em => {
      // temp hided
      // if (em.type === "PERSONAL") {
      // }
      if (em.email) {
        email._id = em._id;
        email.email = em.email;
        email.type = em.type;
        email.status = em.status;
      }
    });
    return email;
  };
  getPhoneOrMobileNumber = promoter => {
    const individual = promoter.individual;
    const phone = {};
    individual.phones.forEach(ph => {
      // temp hided
      // if (ph.type === "PERSONAL") {
      // }

      if (ph.phone) {
        phone._id = ph._id;
        phone.phone = ph.phone;
        phone.type = ph.type;
        phone.status = ph.status;
      }
    });
    return phone;
  };

  getName = promoter => {
    const individual = promoter.individual;
    return `${individual.firstName} ${
      individual.lastName ? individual.lastName : ""
    }`;
  };
  render() {
    const promoters = this.props.incubatee.organization.promoters;

    const promterList = promoters.map((prmtr, index) => {
      const email = this.getEmail(prmtr);
      const phone = this.getPhoneOrMobileNumber(prmtr);

      const promoterName = this.getName(prmtr);

      const individual = prmtr.individual || {};

      const photo = individual.photo || null;
      const primaryUrl = `${INDIVIDUALS_API}/${individual._id}`;
      let link = "";
      if (photo) {
        link = `${primaryUrl}/photo/${photo}`;
      }

      return (
        <tr key={index}>
          <td className="text-center">
            {link ? (
              <div className="ml-2" style={{ width: 50, height: 50 }}>
                <img className="rounded-circle w-100" src={link} alt="User" />
              </div>
            ) : (
              <div className="ml-2" style={{ width: 50, height: 50 }}>
                <img
                  className="rounded-circle w-100"
                  src="images/grey-person-image-icon.png"
                  alt="User"
                />
              </div>
            )}
          </td>
          <td>
            <div>
              {promoterName || "--"}
              <br />
              <small>{prmtr.designation || "--"}</small>
            </div>
          </td>
          <td>
            <div>
              {email.email || "--"}
              <br />
              {/* <small> (Office)</small> */}
            </div>
          </td>
          <td>
            <div>
              {phone.phone}
              <br />
              {/* <small> (Office)</small> */}
            </div>
          </td>
          <td>
            <div>
              {`${prmtr.shareholding ? `${prmtr.shareholding} %` : "--"}`}{" "}
              <br />
              {/* <small> 2018-2019</small> */}
            </div>
          </td>
        </tr>
      );
    });

    let renderPromoters = null;
    if (promterList.length > 0) {
      renderPromoters = (
        <div
          className="card mt-4"
          style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
        >
          {/* <div className="card-header border-0">
            <h6 className="card-title mb-0 text-center">Promoters</h6>
          </div> */}
          <div className="card-body p-0">
            <table className="table mb-1">
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "10%" }}
                  />
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "22.5%" }}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "22.5%" }}
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "22.5%" }}
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "22.5%" }}
                  >
                    Share holding
                  </th>
                </tr>
              </thead>
              <tbody>{promterList}</tbody>
            </table>
          </div>
        </div>
      );
    }
    return <Fragment>{renderPromoters}</Fragment>;
  }
}
/** +++++++++++++++++++++ Promoters End +++++++++++++++++++++ */

/** +++++++++++++++++++++ IncubateeProfileAndStatus Start +++++++++++++++++++++ */
export class IncubateeProfileAndStatus extends Component {
  render() {
    const incubatee = this.props.incubatee;
    const anchorIncubator = incubatee.anchorIncubator
      ? incubatee.anchorIncubator
      : null;
    const supplIncubators = incubatee.supplIncubators
      ? incubatee.supplIncubators
      : [];
    const stage = INCUBATEE_STAGES_OBJECT[incubatee.incubateeStage];
    const incubationDate = formatNumericDate(incubatee.incubationDate);
    const sector = INCUBATION_SECTORS_OBJECT[incubatee.incubationSector];
    const subSector = SUB_SECTORS_OBJECT[incubatee.subTechSector];
    const resStatus = RESIDENTIAL_STATUS_OBJECT[incubatee.residentialStatus];
    const gradStatus = GRADUATION_STATUS_OBJECT[incubatee.graduationStatus];
    const incubationCategory =
      INCUBATION_CATEGORY_TYPES_OBJECT[incubatee.incubationCategory];

    const organization = incubatee.organization || {};

    return (
      <Fragment>
        <div
          className="card"
          style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
        >
          <div className="card-header border-0">
            <h6 className="card-title mb-0 text-center">Incubatee Profile</h6>
          </div>
          <div className="row pl-1">
            <div className="col-12">
              <table className="table table-sm table-borderless ">
                <tbody>
                  {/* <tr>
                      <th>Business Plan</th>
                      <td>
                        {incubatee.businessPlan ? incubatee.businessPlan : "--"}
                      </td>
                    </tr>
                    <tr>
                      <th>Business Model</th>
                      <td>
                        {incubatee.businessModel
                          ? incubatee.businessModel
                          : "--"}
                      </td>
                    </tr> */}
                  <tr>
                    <th>Incubatee Stage</th>
                    <td>{stage || "--"}</td>
                  </tr>
                  <tr>
                    <th>Incubation Date</th>
                    <td>{incubationDate || "--"}</td>
                  </tr>

                  <tr>
                    <th>Area / Domain</th>
                    <td>{organization.domainOperation || "--"}</td>
                  </tr>
                  <tr>
                    <th>Incubation Sector</th>
                    <td>{sector || "--"}</td>
                  </tr>
                  <tr>
                    <th>Sub Sector</th>
                    <td>{subSector || "--"}</td>
                  </tr>
                  <tr>
                    <th>Target Sector</th>
                    <td>{incubatee.targetSector}</td>
                  </tr>
                  <tr>
                    <th>Relationship Manager </th>
                    <td>
                      {incubatee.relationshipManager
                        ? getConcatenatedName(
                            incubatee.relationshipManager.firstName,
                            incubatee.relationshipManager.lastName
                          )
                        : "--"}
                    </td>
                  </tr>
                  <tr>
                    <th>&nbsp;</th>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          className="card"
          style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
        >
          <div className="card-header border-0">
            <h6 className="card-title mb-0 text-center">Incubatee Status</h6>
          </div>
          <div className="row pl-1">
            <div className="col-12 ">
              <table className="table table-sm table-borderless ">
                <tbody>
                  <tr>
                    <th>Graduation Status</th>
                    <td>{gradStatus || "--"}</td>
                  </tr>
                  <tr>
                    <th>Residential Status</th>
                    <td>{resStatus || "--"}</td>
                  </tr>
                  <tr>
                    <th>Incubation Category</th>
                    <td>{incubationCategory || "--"}</td>
                  </tr>

                  <tr>
                    <th>Anchor Incubator</th>
                    <td>{anchorIncubator ? anchorIncubator.name : null}</td>
                  </tr>
                  <tr>
                    <th>Supplementary Incubator</th>
                    <td>
                      {supplIncubators.map((sup, idx) => {
                        return (
                          <Fragment key={idx}>
                            {sup.name}
                            {idx !== supplIncubators.length - 1 ? ", " : null}
                          </Fragment>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
/** +++++++++++++++++++++ IncubateeProfileAndStatus End +++++++++++++++++++++ */

/** +++++++++++++++++++++ Leases Start +++++++++++++++++++++ */
const Leases = props => {
  const { incubatee } = props;
  const leaseList = incubatee.leases || [];
  if (leaseList.length === 0) {
    return null;
  }

  const currentLocationUrl = props.currentLocationUrl;

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-12">
          <div
            className="card flex-fill"
            style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
          >
            <div className="card-header border-0">
              <h6 className="card-titile mb-0 text-center">Leases</h6>
            </div>

            <div className="card-body pt-0">
              <div className="responsive">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th className="display-table-header" scope="col">
                        Lease Type
                      </th>
                      <th className="display-table-header" scope="col">
                        Partition
                      </th>
                      <th className="display-table-header" scope="col">
                        Agreement Date
                      </th>
                      <th className="display-table-header" scope="col">
                        Lease Start Date
                      </th>
                      <th className="display-table-header" scope="col">
                        Lease End Date
                      </th>
                      <th className="display-table-header" scope="col">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaseList.map((lease, index) => {
                      let partitionNames = [];
                      lease.leasePartitions.forEach((ptn, idx) => {
                        partitionNames.push(ptn.partition.displayName);
                      });

                      const names = partitionNames.join(", ");

                      const leaseUrl = `/admin/lease/edit/${
                        lease._id
                      }?from=${currentLocationUrl}`;

                      let leaseEndDate = "";
                      if (
                        lease.stage === "COMPLETE" &&
                        lease.status === "TERMINATED"
                      ) {
                        leaseEndDate = getDisplayDate(
                          convertNumberToDate(lease.terminationDate)
                        );
                      } else {
                        leaseEndDate = getDisplayDate(
                          convertNumberToDate(lease.leaseEndDate)
                        );
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <Link to={leaseUrl}>
                              {LEASE_TYPES_OBJECT[lease.leaseType]}
                            </Link>
                          </td>
                          <td>{names}</td>
                          <td>
                            {getDisplayDate(
                              convertNumberToDate(lease.agreementDate)
                            )}
                          </td>
                          <td>
                            {getDisplayDate(
                              convertNumberToDate(lease.leaseStartDate)
                            )}
                          </td>
                          <td>{leaseEndDate}</td>
                          <td>
                            {getDescription("LEASE", lease.stage, lease.status)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
/** +++++++++++++++++++++ Leases End +++++++++++++++++++++ */

/** +++++++++++++++++++++ GrantsProgrammeProfile Start +++++++++++++++++++++ */
export class GrantsProgrammeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grantAwards: []
    };
  }

  getAwardsByIncubatee = url => {
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ grantAwards: data });
      },
      (data, status) => {},
      error => {}
    );
  };

  componentDidMount = () => {
    const incubatee = this.props.incubatee;
    if (incubatee) {
      const MAPPER = {
        INDIVIDUAL: "Individual",
        COMPANY: "Organization"
      };

      const granteeType = MAPPER[incubatee.incubateeType];

      let granteeId = "";
      if (granteeType === "Individual") {
        granteeId = incubatee.individual._id;
      } else if (granteeType === "Organization") {
        granteeId = incubatee.organization._id;
      }

      const url = `${GRANT_AWARDS_API}/${granteeType}/${granteeId}`;

      this.getAwardsByIncubatee(url);
    }
  };

  render() {
    const { grantAwards } = this.state;

    const awardList = grantAwards.map((award, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{award.agencyName}</td>
          <td>{award.grantName}</td>
          <td>{award.projectTitle}</td>
          <td>{getDisplayDate(convertNumberToDate(award.startDate))}</td>
          <td>{getDisplayDate(convertNumberToDate(award.endDate))}</td>
          <td>{award.status}</td>
        </tr>
      );
    });

    let renderComponent = null;
    if (awardList.length > 0) {
      renderComponent = (
        <div className="row mt-4">
          <div className="col-12">
            <div
              className="card flex-fill"
              style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
            >
              <div className="card-header border-0">
                <h6 className="card-title mb-0 text-center">Grants</h6>
              </div>

              <div className="card-body pt-0">
                <div className="responsive">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th className="display-table-header" scope="col">
                          #
                        </th>

                        <th className="display-table-header" scope="col">
                          Agency Name
                        </th>
                        <th className="display-table-header" scope="col">
                          Grant Name
                        </th>
                        <th className="display-table-header" scope="col">
                          Project Title
                        </th>
                        <th className="display-table-header" scope="col">
                          Start Date
                        </th>
                        <th className="display-table-header" scope="col">
                          End Date
                        </th>

                        <th className="display-table-header" scope="col">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>{awardList}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return renderComponent;
  }
}
/** +++++++++++++++++++++ GrantsProgrammeProfile End +++++++++++++++++++++ */

/** +++++++++++++++++++++ Documents Start +++++++++++++++++++++ */
export class Documents extends Component {
  downloadDocument = (doc, e) => {
    e.preventDefault();

    if (doc === "") {
      return;
    }

    let filename = doc.name;

    fetch(doc.downloadUrl, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(blob => {
        Download(blob, filename);
      })
      .catch(error => {
        this.props.handleApiFailed(error.toString());
      });
  };

  getDocuments = () => {
    const documents = [];

    const incubatee = this.props.incubatee;
    let idDocs;

    let primaryUrl = "";

    if (incubatee.incubateeType === "COMPANY") {
      idDocs = incubatee.organization.identificationDocs;
      primaryUrl = `${ORGANIZATIONS_API}/${incubatee.organization._id}`;

      const organization = incubatee.organization;

      const articlesOfAssociation =
        organization.articlesOfAssociation &&
        organization.articlesOfAssociation._id
          ? organization.articlesOfAssociation
          : null;

      if (articlesOfAssociation !== null) {
        documents.push({
          type: "Articles of Association",
          name: articlesOfAssociation.filename,
          uploadDate: articlesOfAssociation.uploadDate,
          downloadUrl: `${primaryUrl}/aoa/${articlesOfAssociation._id}`
        });
      }

      const companyProfile =
        organization.companyProfile && organization.companyProfile._id
          ? organization.companyProfile
          : null;
      if (companyProfile !== null) {
        documents.push({
          type: "Company Profile",
          name: companyProfile.filename,
          uploadDate: companyProfile.uploadDate,
          downloadUrl: `${primaryUrl}/profile/${companyProfile._id}`
        });
      }

      const regCertificate =
        organization.regCertificate && organization.regCertificate._id
          ? organization.regCertificate
          : null;
      if (regCertificate !== null) {
        documents.push({
          type: "Company Registration Certificate",
          name: regCertificate.filename,
          uploadDate: regCertificate.uploadDate,
          downloadUrl: `${primaryUrl}/reg-cert/${regCertificate._id}`
        });
      }
    } else {
      idDocs = incubatee.individual.identificationDocs;
      primaryUrl = `${INDIVIDUALS_API}/${incubatee.individual._id}`;
    }

    idDocs.forEach(idDoc => {
      if (idDoc.document) {
        documents.push({
          type: idDoc.type,
          name: idDoc.document.filename,
          uploadDate: idDoc.document.uploadDate,
          downloadUrl: `${primaryUrl}/id-doc/${idDoc.type}/${
            idDoc.document._id
          }`
        });
      }
    });
    return documents;
  };

  render() {
    const idDocs = this.getDocuments().map((doc, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{doc.type}</td>
          <td>
            <i className="far fa-file-image text-info" />
            &nbsp;&nbsp;
            {doc.name}
          </td>
          <td>{getDisplayDate(doc.uploadDate)}</td>

          <td className="text-center">
            <i
              className="fas fa-download"
              data-toggle="tooltip"
              data-placement="top"
              title="Download"
              onClick={this.downloadDocument.bind(this, doc)}
              style={{ cursor: "pointer" }}
            />
          </td>
        </tr>
      );
    });

    let renderDocuments = null;
    if (idDocs.length > 0) {
      renderDocuments = (
        <div className="row mt-4">
          <div className="col-12">
            <div
              className="card flex-fill"
              style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
            >
              <div className="card-header border-0">
                <h6 className="card-title mb-0 text-center">Documents</h6>
              </div>
              <div className="card-body pt-0">
                <div className="responsive">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th className="display-table-header" scope="col">
                          #
                        </th>
                        <th className="display-table-header" scope="col">
                          Type
                        </th>
                        <th className="display-table-header" scope="col">
                          File Name
                        </th>
                        <th className="display-table-header" scope="col">
                          Upload At
                        </th>
                        <th
                          className="display-table-header text-center"
                          scope="col"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>{idDocs}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return renderDocuments;
  }
}
/** +++++++++++++++++++++ Documents End +++++++++++++++++++++ */

/** +++++++++++++++++++++ PatentsProfile Start +++++++++++++++++++++ */
export class PatentsProfile extends Component {
  render() {
    const patents = this.props.incubatee.patents;
    const list = patents.map((pt, index) => {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{pt.applicationNumber}</td>
          <td>
            {getDisplayDate(convertNumberToDate(pt.applicationFilingDate))}
          </td>
          <td>{pt.country}</td>
          <td>{pt.fieldOfInvention}</td>
          <td>{pt.classificationCode}</td>
          <td>{pt.patentStatus}</td>
        </tr>
      );
    });

    let renderComponent = null;
    if (list.length > 0) {
      renderComponent = (
        <div className="row mt-4">
          <div className="col-12">
            <div
              className="card flex-fill"
              style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
            >
              <div className="card-header border-0">
                <h6 className="card-title mb-0 text-center">Patents</h6>
              </div>

              <div className="card-body pt-0">
                <div className="table-resonsive-sm table-resonsive-md">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th className="display-table-header" scope="col">
                          #
                        </th>
                        <th className="display-table-header" scope="col">
                          Application Number
                        </th>
                        <th className="display-table-header" scope="col">
                          Fill Date
                        </th>
                        <th className="display-table-header" scope="col">
                          Country
                        </th>
                        <th className="display-table-header" scope="col">
                          Field Of Invention
                        </th>
                        <th className="display-table-header" scope="col">
                          IPC
                        </th>
                        <th className="display-table-header" scope="col">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>{list}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return renderComponent;
  }
}
/** +++++++++++++++++++++ PatentsProfile End +++++++++++++++++++++ */

/** +++++++++++++++++++++ Milestones Start +++++++++++++++++++++ */
class Milestones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      milestones: null
    };
  }

  getMilestones = incubateeId => {
    const url = `${MILESTONE_API}/incubatee/${incubateeId}`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ milestones: data });
        // this.setState({ milestones: [] });
      },
      (data, status) => this.props.handleApiFailed(data.message),
      error => this.props.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    const incubateeId = this.props.incubateeId;
    if (incubateeId) {
      this.getMilestones(incubateeId);
    }
  }

  categoryFormater = (cell, row) => {
    let category = MILESTONE_CATEGORY_TYPES_OBJECT[cell] || "Unspecified";

    if (cell === "OTHER") {
      category = row.otherCategory;
    }

    return category;
  };

  linkFormater = (cell, row) => {
    let link = "--";
    if (row.link) {
      link = (
        <span
          className="d-inline-block text-truncate"
          data-toggle="tooltip"
          title={row.link}
          style={{ width: "200px" }}
        >
          <a href={row.link} rel="noopener noreferrer" target="_blank">
            {row.link}
          </a>
        </span>
      );
    }
    return link;
  };
  render() {
    const { milestones } = this.state;
    if (milestones === null) {
      return null;
    }

    const options = {
      custom: true,
      alwaysShowAllBtns: true,
      sizePerPageOptionRenderer,
      paginationTotalRenderer,
      sizePerPage: 10,
      totalSize: milestones ? milestones.length : 0
    };

    const columnsMilestone = [
      {
        dataField: "title",
        text: "Title",
        sort: true,
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          borderBottom: "none",
          fontWeight: "500",
          width: "20%"
        }
      },
      {
        dataField: "date",
        text: "Milestone Date",
        formatter: cell => getDisplayDate(convertNumberToDate(cell)),
        sort: true,
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          borderBottom: "none",
          fontWeight: "500",
          width: "20%"
        }
      },

      {
        dataField: "category",
        text: "Category",
        formatter: this.categoryFormater,
        sort: true,
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          borderBottom: "none",
          fontWeight: "500",
          width: "20%"
        }
      },
      {
        dataField: "link",
        text: "Link",
        sort: true,
        formatter: this.linkFormater,
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          borderBottom: "none",
          fontWeight: "500",
          width: "20%"
        }
      },
      {
        dataField: "description",
        text: "Description",
        sort: true,
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)",
          borderBottom: "none",
          fontWeight: "500",
          width: "20%"
        }
      }
    ];
    return (
      <div className="row mb-4 mt-4">
        <div className="col-lg-12 col-xs-12 col-md-12">
          <div
            className="card flex-fill"
            style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
          >
            <div className="card-header border-0">
              <h6 className="card-title mb-0 text-center">Milestones </h6>
            </div>

            <div className="card-body pt-0">
              <div className="table-responsive-sm table-responsive-md table-responsive-lg">
                <PaginationProvider pagination={paginationFactory(options)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <Fragment>
                      <BootstrapTable
                        bootstrap4
                        keyField={"_id"}
                        data={milestones}
                        columns={columnsMilestone}
                        condensed
                        bordered={false}
                        noDataIndication="No Milestones available"
                        {...paginationTableProps}
                      />
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="table-list-total">
                            <PaginationTotalStandalone {...paginationProps} />
                          </div>
                        </div>

                        <div className="col-lg-8 col-md-8 col-sm-12">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </div>
                    </Fragment>
                  )}
                </PaginationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/** +++++++++++++++++++++ Milestones End +++++++++++++++++++++ */
