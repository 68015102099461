import React, { Component, Fragment } from "react";
import { getDisplayDate } from "../lease/DateUtil";
import { Link } from "react-router-dom";
import TableList from "../common/TableList";
import { EVENTS_API } from "../common/Constants";
import AlertComponent from "../common/AlertComponent";
import HttpUtil from "../common/HttpUtil";

function getEventName(cell, row) {
  if (!row) return null;
  const event = row;
  const url = `/events/${row._id}?from=/events/recent`;
  return <Link to={url}>{event.name}</Link>;
}

function getEventNameFilter(cell, row) {
  if (!row) return null;
  return row.name || "Unspecified";
}

function dateFormatter(cell, row) {
  return row && cell ? getDisplayDate(cell) : "--";
}

function organiser(cell, event) {
  if (!event) return null;
  let organizer = event.organizer || {};
  return organizer.name || "--";
}

function location(cell, event) {
  if (!event) return null;
  let location = event.location || {};
  return location.name || "--";
}
const EVENTS_LIST_COLUMNS = [
  {
    dataField: "name",
    text: "Event Name",
    formatter: getEventName,
    filterValue: getEventNameFilter,
    csvFormatter: getEventNameFilter
  },
  {
    dataField: "startDate",
    text: "Start date",
    csvFormatter: dateFormatter,
    formatter: dateFormatter,
    filterValue: dateFormatter
  },

  {
    dataField: "endDate",
    text: "End date",
    formatter: dateFormatter,
    csvFormatter: dateFormatter,
    filterValue: dateFormatter
  },

  {
    dataField: "location",
    text: "Location",
    formatter: location,
    csvFormatter: location,
    filterValue: location
  },

  {
    dataField: "organizer",
    text: "Organizer",
    formatter: organiser,
    csvFormatter: organiser,
    filterValue: organiser
  }
];

class RecentEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getEvents = () => {
    HttpUtil.get(
      `${EVENTS_API}/recent`,
      {},
      data => this.setState({ events: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getEvents();
  }

  render() {
    const { events } = this.state;
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={EVENTS_LIST_COLUMNS}
          data={events}
          noDataIndication="No Event found."
          sizePerPage="25"
          headerName="Recent Events"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default RecentEvent;
