import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import IncubateeProfile from "./onboarding-ui-form/IncubateeProfile";

import Contact from "./onboarding-ui-form/Contact";
import Promoter from "./onboarding-ui-form/Promoter";
import Patent from "./onboarding-ui-form/Patent";

import { getYears } from "../../lease/DateUtil";

const YEARS = getYears();

const getFinancialYears = () => {
  let year = new Date().getFullYear();
  const fy = [];
  for (let indx = 0; indx < 5; indx++) {
    fy.push(
      `${year - indx - 1}-${((year - indx) % 100).toString().padStart(2, "0")}`
    );
  }
  return fy;
};

class IncubateeType extends Component {
  render() {
    const {
      name,
      legalStatus,
      estYear,
      website,
      subsidiary,
      parentCompany,
      reference,
      bizSummary,
      services,
      domainOperation,
      authorizedCapital,
      annualTurnover,
      annualTurnoverFY,
      organization,

      // events
      handleNameInputchange,
      handleInputChange,
      downloadDocument,
      inputError,
      tabsError
    } = this.props;

    let articlesOfAssociation = "";
    let logo = "";
    let companyProfile = "";
    let regCertificate = "";

    if (organization) {
      articlesOfAssociation = organization.articlesOfAssociation;
      logo = organization.logo;
      companyProfile = organization.companyProfile;
      regCertificate = organization.regCertificate;
    }

    const fYearMapper = getFinancialYears();
    return (
      <div className="row">
        <div className="col-md-12">
          <Tabs>
            <TabList>
              <Tab>
                {tabsError && tabsError.organization ? (
                  <i className="far fa-times-circle align-middle react-tabs-error" />
                ) : null}
                &nbsp;Org Info
              </Tab>
              <Tab>
                {tabsError && tabsError.contact ? (
                  <i className="far fa-times-circle align-middle react-tabs-error" />
                ) : null}
                &nbsp;Contact
              </Tab>
              <Tab>
                {tabsError && tabsError.promoter ? (
                  <i className="far fa-times-circle align-middle react-tabs-error" />
                ) : null}
                &nbsp;Promoter
              </Tab>
              <Tab>
                {tabsError && tabsError.incubateeProfile ? (
                  <i className="far fa-times-circle align-middle react-tabs-error" />
                ) : null}
                &nbsp;Incubatee Profile
              </Tab>
              <Tab>Patents</Tab>
            </TabList>
            <TabPanel>
              <div className="row">
                <div className="col-md-12">
                  <div className="card-body pt-1 pb-0">
                    <div className="row">
                      <div className="col-12 mb-3 edit-page-section-header-text">
                        Basic Info
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                            Name
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="text"
                              className={
                                inputError["name"]
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder="Incubatee name"
                              name="name"
                              value={name}
                              onChange={handleNameInputchange}
                            />
                            <div className="invalid-feedback">
                              Please enter Organization name *.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Website
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="text"
                              className={
                                inputError["website"]
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder="www.example.com"
                              name="website"
                              value={website}
                              onChange={handleInputChange}
                            />
                            <div className="invalid-feedback">
                              Please enter website name *.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                            Area / Domain
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="text"
                              className={
                                inputError["domainOperation"]
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder="Area / Domain"
                              name="domainOperation"
                              value={domainOperation}
                              onChange={handleInputChange}
                            />
                            <div className="invalid-feedback">
                              Please enter area domain/operation *.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                            Legal Status
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <select
                              className={
                                inputError["legalStatus"]
                                  ? "custom-select custom-select-sm is-invalid"
                                  : "custom-select custom-select-sm"
                              }
                              name="legalStatus"
                              value={legalStatus}
                              onChange={handleInputChange}
                            >
                              <option value="">Select...</option>
                              <option value="PROPRIETARY">
                                Proprietorship
                              </option>
                              <option value="PARTNERSHIP">Partnership</option>
                              <option value="PVTLTD">Private Limited</option>
                              <option value="LLP">LLP</option>
                              <option value="OPC">OPC</option>
                            </select>
                            <div className="invalid-feedback">
                              Please select legal status *.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                            Year of Establishment
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <select
                              className={
                                inputError["estYear"]
                                  ? "custom-select custom-select-sm is-invalid"
                                  : "custom-select custom-select-sm"
                              }
                              name="estYear"
                              value={estYear !== null ? estYear : ""}
                              onChange={handleInputChange}
                            >
                              <option value="">Select...</option>
                              {YEARS.map(yyyy => (
                                <option key={yyyy} value={yyyy}>
                                  {yyyy}
                                </option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              Please enter year of Establishment *.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            References
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="text"
                              className={
                                inputError["orgReference"]
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder="Reference"
                              name="reference"
                              value={reference ? reference : ""}
                              onChange={handleInputChange}
                            />
                            <div className="invalid-feedback">
                              Please enter reference *.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Subsidiary
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control custom-control-input"
                                id="defaultChecked01233"
                                name="subsidiary"
                                checked={subsidiary ? subsidiary : ""}
                                onChange={handleInputChange}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="defaultChecked01233"
                              />
                              Yes
                            </div>
                          </div>
                        </div>
                      </div>
                      {subsidiary ? (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="form-group row">
                            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                              Parent Company
                            </label>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                              <textarea
                                type="text"
                                className={
                                  inputError["parentCompany"]
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                placeholder=""
                                name="parentCompany"
                                value={parentCompany}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card-body pt-0 pb-0">
                    <div className="row">
                      <div className="col-12 mb-3 edit-page-section-header-text">
                        Financial Info
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Authorized Capital
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="number"
                              className={
                                inputError["authorizedCapital"]
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder="0"
                              name="authorizedCapital"
                              value={authorizedCapital}
                              onChange={handleInputChange}
                            />
                            <div className="invalid-feedback">
                              Please enter authorized capital *.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Annual Turnover
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="number"
                              className={
                                inputError["annualTurnover"]
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder="0"
                              name="annualTurnover"
                              value={annualTurnover}
                              onChange={handleInputChange}
                            />
                            <div className="invalid-feedback">
                              Please enter annual turnover name *.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Annual Turnover FY
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <select
                              className={
                                inputError["annualTurnoverFY"]
                                  ? "custom-select custom-select-sm is-invalid"
                                  : "custom-select custom-select-sm "
                              }
                              name="annualTurnoverFY"
                              value={annualTurnoverFY}
                              onChange={handleInputChange}
                            >
                              <option value="">Select...</option>
                              {fYearMapper.map(yyyy => (
                                <option key={yyyy} value={yyyy}>
                                  {yyyy}
                                </option>
                              ))}
                            </select>
                            <div className="invalid-feedback">
                              Please select financial year *.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-12 mb-3 edit-page-section-header-text">
                        Business/Services Info
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-12">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-2  text-sm-left edit-page-label">
                            Business/Summary
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-9 ml-xl-5">
                            <textarea
                              type="text"
                              className={
                                inputError["bizSummary"]
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder=""
                              name="bizSummary"
                              value={bizSummary}
                              onChange={handleInputChange}
                            />
                            <div className="invalid-feedback">
                              Please enter business background and summary *.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-12">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-2 text-sm-left edit-page-label">
                            Products/Service
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-9 ml-xl-5">
                            <textarea
                              type="text"
                              className={
                                inputError["services"]
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder=""
                              name="services"
                              value={services}
                              onChange={handleInputChange}
                            />
                            <div className="invalid-feedback">
                              Please enter products or services offered*.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card-body pb-0">
                    <div className="row">
                      <div className="col-12 mb-3 edit-page-section-header-text">
                        Documents
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Company Logo
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="file"
                              name="logo"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      {logo ? (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="form-group row">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={downloadDocument.bind(this, logo)}
                            >
                              Download Profile
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Incubatee Profile
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="file"
                              name="companyProfile"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      {companyProfile ? (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="form-group row">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={downloadDocument.bind(
                                this,
                                companyProfile
                              )}
                            >
                              Download Profile
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Registration Certificate
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="file"
                              name="regCertificate"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      {regCertificate ? (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="form-group row">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={downloadDocument.bind(
                                this,
                                regCertificate
                              )}
                            >
                              Download Certificate
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                            Articles Of Association
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <input
                              type="file"
                              name="articlesOfAssociation"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>

                      {articlesOfAssociation ? (
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="form-group row">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={downloadDocument.bind(
                                this,
                                articlesOfAssociation
                              )}
                            >
                              Download Articles
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <Contact {...this.props} />
            </TabPanel>

            <TabPanel>
              <Promoter {...this.props} />
            </TabPanel>

            <TabPanel>
              <IncubateeProfile {...this.props} />
            </TabPanel>

            <TabPanel>
              <Patent {...this.props} />
            </TabPanel>
            {/* <TabPanel>
              <OnboardFunding {...this.props} />
            </TabPanel> */}
          </Tabs>
        </div>

        {/* <div className="row">
          <Contact {...this.props} />
        </div>

        <div className="row">
          <Promoter {...this.props} />
        </div>

        <div className="row">
          <IncubateeProfile {...this.props} />
        </div>

        <div className="row">
          <Patent {...this.props} />
        </div>

        <div className="row">
          <OnboardFunding {...this.props} />
        </div> */}
      </div>
    );
  }
}

export default IncubateeType;
