import { convertNumberToDate, convertDateToNumber } from "../lease/DateUtil";

export const transferServerToUi = data => {
  const individual = {};
  individual._id = data._id;
  individual.firstName = data.firstName || "";
  individual.lastName = data.lastName || "";
  individual.gender = data.gender || "";
  individual.dob = data.dob ? convertNumberToDate(data.dob) : "";
  individual.photo = data.photo || "";
  individual.experience = data.experience || "";
  individual.references = data.references || "";

  const education = data.education || [];
  individual.education = education.map(edu => {
    return {
      _id: edu._id,
      qualification: edu.qualification || "",
      degree: edu.degree || "",
      year: edu.year || "",
      institution: edu.institution || "",
      alumni: edu.alumni || false,
      branch: edu.branch || ""
    };
  });

  const addresses = data.addresses || [];
  individual.addresses = addresses.map(ad => {
    return {
      _id: ad._id,
      streetLine1: ad.streetLine1 || "",
      streetLine2: ad.streetLine2 || "",
      landmark: ad.landmark || "",
      city: ad.city || "",
      state: ad.state || "",
      postalCode: ad.postalCode || "",
      country: ad.country || "",
      status: ad.status || "",
      type: ad.type || "COMMUNICATION",
      addressProof: ad.addressProof || ""
    };
  });

  const emails = data.emails || [];
  individual.emails = emails.map(em => {
    return {
      _id: em._id,
      email: em.email || "",
      type: em.type || "WORK"
    };
  });

  const phones = data.phones || [];
  individual.phones = phones.map(ph => {
    return {
      _id: ph._id,
      phone: ph.phone || "",
      type: ph.type || "WORK"
    };
  });

  // Identification documents
  let panNumber, aadhaarNumber;
  let pan, aadhaar;

  data.identificationDocs.forEach(idDoc => {
    if (idDoc.type === "PAN") {
      panNumber = idDoc.number || panNumber;
      pan = idDoc.document || "";
    }

    if (idDoc.type === "AADHAAR") {
      aadhaarNumber = idDoc.number || aadhaarNumber;
      aadhaar = idDoc.document || "";
    }
  });

  const identificationDocs = {};
  identificationDocs.panNumber = panNumber || "";
  identificationDocs.aadhaarNumber = aadhaarNumber || "";
  identificationDocs.pan = pan || "";
  identificationDocs.aadhaar = aadhaar || "";
  individual.identificationDocs = identificationDocs;

  return individual;
};

function checkAndAddIdDocs(idDocs, docType, reference) {
  if (reference) {
    idDocs.push({ type: docType, number: reference });
  }
}

function addDocs(formData, name, file) {
  if (file) {
    formData.append(name, file);
  }
}

export const transferUiToApi = values => {
  let formData = new FormData();

  const idDocs = [];
  const idDoc = values.identificationDocs;

  checkAndAddIdDocs(idDocs, "PAN", idDoc.panNumber);
  checkAndAddIdDocs(idDocs, "AADHAAR", idDoc.aadhaarNumber);

  addDocs(formData, `id_doc_PAN`, idDoc.uploadPan);
  addDocs(formData, `id_doc_AADHAAR`, idDoc.uploadAadhaar);

  const addresses = values.addresses.map(ad => {
    addDocs(formData, `address_doc_${ad.type}`, ad.uploadAddressProof);
    return {
      _id: ad._id,
      streetLine1: ad.streetLine1,
      streetLine2: ad.streetLine2,
      landmark: ad.landmark,
      city: ad.city,
      state: ad.state,
      postalCode: ad.postalCode,
      country: ad.country,
      status: ad.status,
      type: ad.type
    };
  });

  addDocs(formData, "photo", values.uploadIndvPhoto);

  const individualForm = {
    firstName: values.firstName,
    lastName: values.lastName,
    gender: values.gender || null,
    dob: values.dob ? convertDateToNumber(values.dob) : "",
    experience: values.experience,
    references: values.references,
    education: values.education,
    phones: values.phones,
    emails: values.emails,
    addresses,
    identificationDocs: idDocs
  };

  formData.append("message", JSON.stringify(individualForm));

  return formData;
};
