import React from "react";
import { Switch, Route } from "react-router-dom";
import FundingAgencyList from "./setup/FundingAgencyList";
import FundingAgencyDetail from "./setup/FundingAgencyDetail";

import GrantList from "./grants/GrantList";
import GrantProgramDetail from "./grants/GrantProgramDetail";

import GrantFundingCycleList from "./grantFundingCycle/GrantFundingCycleList";
import GrantFundingCycleDetail from "./grantFundingCycle/GrantFundingCycleDetail";

import GrantAwardList from "./award/GrantAwardList";
import GrantAwardDetail from "./award/GrantAwardDetail";
import GrantProgress from "./award/GrantProgress";
import GrantAwardProgress from "./award/GrantAwardProgress";
import GrantProgressTemplateList from "./grant-progress-template/GrantProgressTemplateList";
import GrantProgressTemplate from "./grant-progress-template/GrantProgressTemplate";
import GrantClosure from "./grant-progress/GrantClosure";
import GrantTermination from "./grant-progress/GrantTermination";
import AgencyList from "./agencies/AgencyList";
import AgencyInformation from "./agencies/AgencyInformation";
import GrantInformation from "./agencies/GrantInformation";
import ShortList from "./ShortList";
import CompletedApplications from "./CompletedApplications";
import GrantOnboardForm from "./GrantOnboardForm";

const ROUTES = [
  {
    path: "/funding/grant-progress-template",
    component: GrantProgressTemplateList
  },
  {
    path: "/funding/grant-progress-template/new",
    component: GrantProgressTemplate
  },
  {
    path: "/funding/grant-progress-template/edit/:id",
    component: GrantProgressTemplate,
    passProps: true
  },
  {
    path: "/funding/agencies",
    component: FundingAgencyList
  },
  {
    path: "/funding/agencies/new",
    component: FundingAgencyDetail
  },
  {
    path: "/funding/agencies/edit/:id",
    component: FundingAgencyDetail,
    passProps: true
  },
  {
    path: "/funding/shortList",
    component: ShortList
  },
  {
    path: "/funding/closed-apps",
    component: CompletedApplications
  },
  {
    path: "/funding/grant-onboard/:id",
    component: GrantOnboardForm,
    passProps: true
  },
  {
    path: "/funding/setup/agencies",
    component: AgencyList
  },
  {
    path: "/funding/setup/agencies/info/:id",
    component: AgencyInformation,
    passProps: true
  },
  {
    path: "/funding/setup/agencies/info/:id/grant/:id",
    component: GrantInformation,
    passProps: true
  },
  {
    path: "/funding/grants",
    component: GrantList
  },
  {
    path: "/funding/grants/new",
    component: GrantProgramDetail
  },
  {
    path: "/funding/grants/edit/:id",
    component: GrantProgramDetail,
    passProps: true
  },
  {
    path: "/funding/funding-cycles",
    component: GrantFundingCycleList
  },
  {
    path: "/funding/funding-cycles/new",
    component: GrantFundingCycleDetail
  },
  {
    path: "/funding/funding-cycles/edit/:id",
    component: GrantFundingCycleDetail,
    passProps: true
  },
  {
    path: "/funding/awards",
    component: GrantAwardList
  },
  {
    path: "/funding/awards/new",
    component: GrantAwardDetail
  },
  {
    path: "/funding/awards/edit/:id",
    component: GrantAwardDetail,
    passProps: true
  },
  {
    path: "/funding/awards/progress/:id",
    component: GrantAwardProgress,
    passProps: true
  },
  {
    path: "/funding/awards/grant-closure/:id",
    component: GrantClosure,
    passProps: true
  },
  {
    path: "/funding/awards/grant-termination/:id",
    component: GrantTermination,
    passProps: true
  },
  {
    path: "/funding/awards/progress-old/:id",
    component: GrantProgress,
    passProps: true
  }
];

const FundingRoutes = () => {
  return (
    <Switch>
      {ROUTES.map((route, index) => {
        const { path, passProps } = route;
        const key = `funding-${index}`;
        const Comp = route.component;
        const routeComponent = passProps ? props => <Comp {...props} /> : Comp;
        return <Route exact key={key} path={path} component={routeComponent} />;
      })}
    </Switch>
  );
};

export default FundingRoutes;
