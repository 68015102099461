import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";
import { EQUIPMENTS_API } from "../common/Constants";
import {
  RENTAL_TYPES_OBJECT,
  STATUS_LIST_OBJECT
} from "../common/LookupConstants";
import Can from "../../auth/can";
import TableList from "../common/TableList";

function linkFormater(assetId, row) {
  const url = `/assets/edit/${row._id}`;
  return <Link to={url}>{assetId}</Link>;
}

function getModel(cell, row) {
  const manufacture = row.manufacture || {};
  return manufacture.modelNumber || "--";
}

function getProductImage(cell, row) {
  const productImages = row.productImages || [];
  const image =
    productImages[0] && productImages[0]._id ? productImages[0] : null;
  let img = "";

  if (image) {
    let equipmentId = row._id;
    let previewUrl = `${EQUIPMENTS_API}/${equipmentId}/product-images/${image._id}`;
    let filename = image.filename;
    img = (
      <img
        src={previewUrl}
        className="img-fluid"
        style={{ width: 30, height: 30 }}
        alt={filename}
        datatoggle="tooltip"
        title={filename}
      />
    );
  }

  return img;
}

const ASSET_LIST_COLUMNS = [
  {
    dataField: "productImages",
    text: "Product",
    formatter: getProductImage,
    align: "center",
    headerStyle: () => {
      return { width: "5%", textAlign: "center" };
    }
  },
  {
    dataField: "assetId",
    text: "Asset ID",
    sort: true,
    formatter: linkFormater,
    filterValue: cell => cell
  },

  {
    dataField: "assetType",
    text: "Asset Type",
    sort: true
  },

  {
    dataField: "modelNumber",
    text: "Model Number",
    sort: true,
    formatter: getModel,
    filterValue: getModel
  },

  {
    dataField: "rentalType",
    text: "Rental Type",
    sort: true,
    formatter: cell => RENTAL_TYPES_OBJECT[cell],
    filterValue: cell => RENTAL_TYPES_OBJECT[cell]
  },
  // {
  //   dataField: "location",
  //   text: "Location"
  // },
  {
    dataField: "status",
    text: "Status",
    formatter: cell => STATUS_LIST_OBJECT[cell]
  }
];

class EquipmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipments: []
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getEquipments = () => {
    let url = EQUIPMENTS_API;

    HttpUtil.get(
      url,
      {},
      data => this.setState({ equipments: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getEquipments();
  }

  render() {
    const { equipments } = this.state;

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={ASSET_LIST_COLUMNS}
          data={equipments}
          noDataIndication="No Asset found."
          sizePerPage="5"
          headerName="Assets"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default EquipmentList;

const RenderCustomButton = props => {
  return (
    <Can do="CREATE" on="Equipment">
      <Link
        to="/assets/new"
        role="button"
        className="btn btn-sm text-white bg-pink"
      >
        New
      </Link>
    </Can>
  );
};
