import * as Yup from "yup";

const POSTAL_CODE_REG_EXP = /^[1-9][0-9]{5}$/;

const ADDRESS_SCHEMA = Yup.object().shape({
  streetLine1: Yup.string()
    .label("Street Line 1")
    .required(),
  streetLine2: Yup.string()
    .label("Street Line 2")
    .optional(),
  landmark: Yup.string()
    .label("Landmark")
    .optional(),
  city: Yup.string()
    .label("City")
    .required(),
  state: Yup.string()
    .label("State")
    .required(),

  postalCode: Yup.string()
    .label("Postal Code")
    .test("Postal Code", "Please enter valid Postal Code", value => {
      if (value) {
        const schema = Yup.string().matches(
          POSTAL_CODE_REG_EXP,
          "Please enter valid Postal Code"
        );
        return schema.isValidSync(value);
      }
      return true;
    })
    .required()
});

const PROMOTER_SCHEMA = Yup.object().shape({
  designation: Yup.string()
    .label("Designation")
    .optional(),
  shareholding: Yup.string()
    .label("Shareholding")
    .optional(),
  isFounder: Yup.boolean()
    .label("Founder")
    .optional(),
  individual: Yup.object().shape({
    firstName: Yup.string()
      .label("First Name")
      .required(),
    gender: Yup.string()
      .label("Gender")
      .optional(),

    photo: Yup.mixed()
      .label("Photo")
      .optional(),

    uploadPhoto: Yup.mixed()
      .when("photo", {
        is: value => {
          return !value;
        },
        then: Yup.mixed()
          .label("photo")
          .test("photo", "Photo is required", value => {
            return value && typeof value.arrayBuffer === "function";
          })
          .required()
      })
      .nullable()
      .optional(),

    experience: Yup.string()
      .label("Experience")
      .optional(),

    education: Yup.array()
      .of(
        Yup.object().shape({
          alumni: Yup.boolean()
            .label("Alumni")
            .optional(),
          qualification: Yup.string()
            .label("Qualification")
            .required(),
          degree: Yup.string()
            .label("Degree")
            .required(),
          institution: Yup.string()
            .label("Institution")
            .optional(),
          year: Yup.string()
            .when("alumni", {
              is: true,
              then: Yup.string()
                .label("Year")
                .required(),
              otherwise: Yup.string()
                .label("Year")
                .optional()
            })
            .label("Year")
            .optional(),

          branch: Yup.string()
            .when("alumni", {
              is: true,
              then: Yup.string()
                .label("Branch")
                .required(),
              otherwise: Yup.string()
                .label("Branch")
                .optional()
            })
            .label("Branch")
            .optional()
        })
      )
      .min(1)
      .label("Education")
      .required(),

    phones: Yup.array()
      .of(
        Yup.object().shape({
          phone: Yup.string()
            .label("Phone")
            .required()
        })
      )
      .min(1)
      .label("Phones")
      .required(),

    emails: Yup.array()
      .of(
        Yup.object().shape({
          email: Yup.string()
            .email("Invalid email")
            .label("Email")
            .required()
        })
      )
      .min(1)
      .label("Emails")
      .required(),

    identificationDocs: Yup.array().of(
      Yup.object().shape({
        type: Yup.string()
          .label("Type")
          .optional(),

        number: Yup.string().when("type", {
          is: "AADHAAR",
          then: Yup.string()
            .label("Aadhaar Number")
            .required(),
          otherwise: Yup.string().optional()
        }),

        document: Yup.mixed()
          .label("Document")
          .optional(),

        uploadAadhaar: Yup.mixed().when("type", {
          is: "AADHAAR",
          then: Yup.mixed().when("number", {
            is: value => value || true,
            then: Yup.mixed().when("document", {
              is: value => {
                return !value;
              },
              then: Yup.mixed()
                .label("Aadhaar")
                .test(
                  "fileFormat",
                  "Aadhaar Scanned Copy is required",
                  value => {
                    return value && typeof value.arrayBuffer === "function";
                  }
                )
                .required()
            })
          })
        })
      })
    ),

    addresses: Yup.array()
      .of(ADDRESS_SCHEMA)
      .min(1)
      .label("Addresses")
      .required()
  })
});

export const ONBOARD_FORM_SCHEMA = Yup.object().shape({
  incubateeType: Yup.string()
    .label("Incubatee Type")
    .required(),

  organization: Yup.object().when("incubateeType", {
    is: "COMPANY",
    then: Yup.object().shape({
      name: Yup.string()
        .label("Name")
        .required(),
      website: Yup.string()
        .label("Website")
        .optional(),
      domainOperation: Yup.string()
        .label("Area / Domain")
        .required(),
      legalStatus: Yup.string()
        .label("Legal Status")
        .required(),
      estYear: Yup.string()
        .label("Year of Establishment")
        .required(),
      reference: Yup.string()
        .label("Reference")
        .optional(),
      subsidiary: Yup.boolean()
        .label("Subsidiary")
        .optional(),
      parentCompany: Yup.string()
        .label("Parent Company")
        .optional(),

      products: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .label("Product Name")
            .required(),
          description: Yup.string()
            .label("Description")
            .required()
        })
      ),

      authorizedCapital: Yup.number()
        .min(0, "Min value is 0.")
        .label("Authorized Capital")
        .optional()
        .typeError("Authorized Capital required")
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() === "" ? null : value
        ),

      currentEmployeeCount: Yup.number()
        .positive()
        .integer()
        .label("Employee Count")
        .typeError("Employee Count is required")
        .nullable()
        .optional(),
      femaleEmployeeCount: Yup.number()
        .positive()
        .integer()
        .lessThan(
          Yup.ref("currentEmployeeCount"),
          "Female Employee count must be lessthan Total Employee Count"
        )
        .label("Female Employee Count")
        .typeError("Female Employee count is required")
        .nullable()
        .optional(),

      annualTurnover: Yup.number()
        .min(0, "Min value is 0.")
        .label("Annual Turnover")
        .optional()
        .typeError("Annual Turnover required")
        .nullable()
        .transform((value, originalValue) =>
          originalValue.trim() === "" ? null : value
        ),

      annualTurnoverFY: Yup.string()
        .label("Annual Turnover FY")
        .optional(),

      promoters: Yup.array()
        .of(PROMOTER_SCHEMA)
        .label("Promoters")
        .min(1)
        .required()
    })
  }),

  individual: Yup.object().when("incubateeType", {
    is: "INDIVIDUAL",
    then: Yup.object().shape({
      firstName: Yup.string()
        .label("First Name")
        .required(),
      lastName: Yup.string()
        .label("Last Name")
        .optional(),
      gender: Yup.string()
        .label("Gender")
        .optional(),
      // dob: Yup.date()
      //   .label("Date of Birth")
      //   .typeError("Date of Birth required")
      //   .optional(),
      experience: Yup.string()
        .label("Experience")
        .required(),
      references: Yup.string()
        .label("References")
        .optional(),

      qualification: Yup.string()
        .label("Edu Qualification")
        .required(),
      degree: Yup.string()
        .label("Degree")
        .required(),
      institution: Yup.string()
        .label("Institution")
        .optional(),
      alumni: Yup.boolean()
        .label("Alumni")
        .optional(),
      // year: Yup.string()
      //   .label("Year of Passing")
      //   .optional(),
      year: Yup.string().when("alumni", {
        is: true,
        then: Yup.string()
          .label("Year")
          .required(),
        otherwise: Yup.string()
          .label("Year")
          .optional()
      }),

      branch: Yup.string().when("alumni", {
        is: true,
        then: Yup.string()
          .label("Branch")
          .required(),
        otherwise: Yup.string()
          .label("Branch")
          .optional()
      }),

      indvPhoto: Yup.mixed()
        .label("Photo")
        .optional(),

      uploadIndvPhoto: Yup.mixed()
        .when("indvPhoto", {
          is: value => {
            return !value;
          },
          then: Yup.mixed()
            .label("photo")
            .test("indvPhoto", "Photo is required", value => {
              return value && typeof value.arrayBuffer === "function";
            })
            .required()
        })
        .nullable()
        .optional()
    })
  }),

  contact: Yup.object().shape({
    phone: Yup.string()
      .label("Phone")
      .required(),

    email: Yup.string()
      .email("Invalid email")
      .label("Email")
      .required(),

    address: Yup.object().shape({
      streetLine1: Yup.string()
        .label("Street Line 1")
        .required(),
      streetLine2: Yup.string()
        .label("Street Line 2")
        .optional(),
      landmark: Yup.string()
        .label("Landmark")
        .optional(),
      city: Yup.string()
        .label("City")
        .required(),
      state: Yup.string()
        .label("State")
        .required(),

      postalCode: Yup.string()
        .label("Postal Code")
        .test("Postal Code", "Please enter valid Postal Code", value => {
          if (value) {
            const schema = Yup.string().matches(
              POSTAL_CODE_REG_EXP,
              "Please enter valid Postal Code"
            );
            return schema.isValidSync(value);
          }
          return true;
        })
        .required()
    })
  }),

  identificationDocs: Yup.object()
    .shape({
      incubateeType: Yup.string()
        .label("Incubatee Type")
        .optional(), // For local validation

      panNumber: Yup.string()
        .label("PAN Number")
        .optional(),

      gstnNumber: Yup.string().when("incubateeType", {
        is: value => {
          return value === "COMPANY";
        },
        then: Yup.string()
          .label("GSTN Number")
          .optional(),
        otherwise: Yup.string()
          .label("GSTN Number")
          .optional()
      }),

      gstn: Yup.mixed()
        .label("GSTN")
        .optional(),

      // uploadGstn: Yup.mixed().when("incubateeType", {
      //   is: value => {
      //     return value === "COMPANY";
      //   },
      //   then: Yup.mixed().when("gstn", {
      //     is: value => {
      //       return !value;
      //     },
      //     then: Yup.mixed()
      //       .label("GSTN")
      //       .test("aadhaar", "GSTN scanned copy is required", value => {
      //         return value && typeof value.arrayBuffer === "function";
      //       })
      //       .required(),
      //     otherwise: Yup.mixed()
      //       .nullable()
      //       .optional()
      //   })
      // }),

      aadhaar: Yup.mixed()
        .label("Aadhaar")
        .optional(),
      aadhaarNumber: Yup.string()
        .when("incubateeType", {
          is: value => {
            return value === "INDIVIDUAL";
          },
          then: Yup.string()
            .label("Aadhaar Number")
            .required(),
          otherwise: Yup.string().optional()
        })
        .optional(),

      uploadAadhaar: Yup.mixed().when("incubateeType", {
        is: value => {
          return value === "INDIVIDUAL";
        },
        then: Yup.mixed().when("aadhaar", {
          is: value => {
            return !value;
          },
          then: Yup.mixed()
            .label("Aadhaar")
            .test("aadhaar", "Aadhaar scanned copy is required", value => {
              return value && typeof value.arrayBuffer === "function";
            })
            .required(),
          otherwise: Yup.mixed()
            .nullable()
            .optional()
        })
      })
    })
    .optional(),

  incubateeProfile: Yup.object().shape({
    graduationStatus: Yup.string()
      .label("Graduation Status")
      .required(),
    residentialStatus: Yup.string()
      .label("Residential Status")
      .optional(),
    incubationSector: Yup.string()
      .label("Incubation Sector")
      .optional(),
    subTechSector: Yup.string()
      .label("Sub-tech Sector")
      .optional(),
    otherSubSector: Yup.string()
      .label("Other Sub Sector")
      .optional(),
    incubateeStage: Yup.string()
      .label("Incubatee Stage")
      .optional(),
    targetSector: Yup.string()
      .label("Target Sector")
      .optional(),
    incubationCategory: Yup.string()
      .label("Incubation Category")
      .optional(),
    relationshipManager: Yup.string()
      .label("Relationship Manager")
      .optional(),
    about: Yup.string()
      .label("About")
      .optional(),
    businessPlan: Yup.string()
      .label("Business Plan")
      .optional()
  }),

  patents: Yup.array()
    .of(
      Yup.object().shape({
        titleOfInnovation: Yup.string()
          .label("Title of the innovation")
          .optional(),
        country: Yup.string()
          .label("Country")
          .optional(),
        applicationNumber: Yup.string()
          .label("Application Number")
          .optional(),
        applicationFilingDate: Yup.date()
          .label("Application Filing Date")
          .typeError("Application Filing Date")
          .nullable()
          .optional(),
        fieldOfInvention: Yup.string()
          .label("Field Of Invention")
          .optional(),
        classificationCode: Yup.string()
          .label("Classification Code")
          .optional(),
        patentStatus: Yup.string()
          .label("Patent Status")
          .optional()
      })
    )
    .label("Patents")
    .min(1)
    .optional()
});
