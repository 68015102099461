import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { REQUESTOR_CATEGORY_TYPES } from "../../common/LookupConstants";
import { getDisplayDate } from "../../lease/DateUtil";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const ErrorComponent = props => {
  const { fieldName, touch, error, datePicker } = props;
  let renderComponent = "";

  if (datePicker) {
    renderComponent = (
      <div className="auto-lookup-empty">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  } else {
    renderComponent = (
      <div className="invalid-feedback">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  }
  return renderComponent;
};

const CustomForm = props => {
  const {
    values,
    setFieldValue,
    assetList,
    incubatees,
    errors,
    touched
  } = props;

  const incubator = values.incubator || {};

  let styles = "";

  if (errors.nameOfIncubatee) {
    styles = {
      control: base => ({
        ...base,
        borderColor: "#f44455",
        height: "calc(1.8125rem + 2px)",
        minHeight: "calc(1.8125rem + 2px)"
      })
    };
  }

  let equipmentsErrorStyle = "";
  if (errors.equipments) {
    equipmentsErrorStyle = {
      control: base => ({
        ...base,
        borderColor: "#f44455",
        height: "calc(1.8125rem + 2px)",
        minHeight: "calc(1.8125rem + 2px)"
      })
    };
  }
  return (
    <Form>
      <div className="card border-0 pl-1 pr-1">
        <div className="d-flex justify-content-center align-items-center mt-4">
          {incubator.logoPath ? (
            <div
              className="mr-2 d-flex align-self-center"
              style={{ width: 50, height: 50 }}
            >
              <img className="w-100" src={incubator.logoPath} alt="User" />
            </div>
          ) : (
            <i
              className="fas fa-industry pr-2 fa-3x"
              style={{ color: "#647acb" }}
            />
          )}

          <h1 className="mb-0" style={{ color: "#647acb" }}>
            {incubator.name}
          </h1>
        </div>
        <div className="edit-page-section-header-text mt-2 text-center">
          <strong>Enquiry Form</strong>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
              Enquiry Regarding
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <Field
                component="select"
                name="enquiryType"
                className={getErrorCssClassName(touched, errors, "enquiryType")}
                value={values.enquiryType || ""}
              >
                <option value="">Select...</option>
                {[{ key: "EQUIPMENT_RENTAL", value: "Equipment Usage" }].map(
                  (opt, i) => (
                    <option key={i} value={opt.key}>
                      {opt.value}
                    </option>
                  )
                )}
              </Field>
              <ErrorComponent
                fieldName="enquiryType"
                touch={touched}
                error={errors}
              />
            </div>
          </div>

          {values.enquiryType === "EQUIPMENT_RENTAL" ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
                Name of Equipment
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Select
                  key="001"
                  isMulti
                  name="equipments"
                  options={assetList}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isClearable
                  styles={equipmentsErrorStyle}
                  value={values.equipments || ""}
                  onChange={data => {
                    const newValue = data || "";
                    setFieldValue("equipments", newValue);
                  }}
                />
                <div className="invalid-feedback">
                  {/* {getError(touch, error, "type")} */}
                </div>

                {errors.equipments ? (
                  <div className="text-danger">{errors.equipments}</div>
                ) : null}
              </div>
            </div>
          ) : null}

          {values.enquiryType === "LAB_USAGE" ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                Lab Test {/*labTest*/}
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Field
                  type="text"
                  className={getErrorCssClassName(touched, errors, "labTest")}
                  name="labTest"
                  value={values.labTest || ""}
                />
                <ErrorComponent
                  fieldName="labTest"
                  touch={touched}
                  error={errors}
                />
              </div>
            </div>
          ) : null}

          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
              Priority
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInlineLOW"
                  name="priority"
                  className="custom-control-input"
                  checked={values.priority === "LOW" ? true : false}
                  onChange={() => {
                    setFieldValue("priority", "LOW");
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInlineLOW"
                >
                  Low
                </label>
              </div>
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInlineMEDIUM"
                  name="priority"
                  className="custom-control-input"
                  checked={values.priority === "MEDIUM" ? true : false}
                  onChange={() => {
                    setFieldValue("priority", "MEDIUM");
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInlineMEDIUM"
                >
                  Medium
                </label>
              </div>
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInlineHIGH"
                  name="priority"
                  className="custom-control-input"
                  checked={values.priority === "HIGH" ? true : false}
                  onChange={() => {
                    setFieldValue("priority", "HIGH");
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInlineHIGH"
                >
                  High
                </label>
              </div>
            </div>
          </div>

          <div className="form-group row mb-4">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
              Date
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <label className="mb-1">From</label>
                  <DatePicker
                    value={getDisplayDate(values.from)}
                    selected={values.from}
                    onChange={date => setFieldValue("from", date)}
                    minDate={new Date()}
                    utcOffset={0}
                    placeholderText="dd-mm-yyyy"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />

                  {errors.from ? (
                    <div
                      className="text-danger m-1"
                      style={{ fontSize: "0.850em" }}
                    >
                      From required field
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-lg-6">
                  <label className="mb-1">To</label>
                  <DatePicker
                    value={getDisplayDate(values.to)}
                    selected={values.to}
                    onChange={date => setFieldValue("to", date)}
                    utcOffset={0}
                    minDate={values.from}
                    placeholderText="dd-mm-yyyy"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {errors.to ? (
                    <div
                      className="text-danger m-1"
                      style={{ fontSize: "0.850em" }}
                    >
                      To required field
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
              Are you currently or previously incubated at PSG-STEP?
              {/* incubatee */}
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInline1"
                  name="incubatee"
                  className="custom-control-input"
                  checked={values.incubatee ? true : false}
                  onChange={() => {
                    setFieldValue("incubatee", true);
                    setFieldValue("incubateeType", "");
                    setFieldValue("otherIncubator", false);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline1"
                >
                  Yes
                </label>
              </div>
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInline2"
                  name="incubatee"
                  className="custom-control-input"
                  checked={!values.incubatee ? true : false}
                  onChange={() => {
                    setFieldValue("incubatee", false);
                    setFieldValue("nameOfIncubatee", "");
                    if (values.incubateeType) {
                      setFieldValue("incubateeType", values.incubateeType);
                    } else {
                      setFieldValue("incubateeType", "INDIVIDUAL");
                    }
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline2"
                >
                  No
                </label>
              </div>
            </div>
          </div>

          {values.incubatee ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                Name of Incubatee
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Select
                  key="002"
                  name="nameOfIncubatee"
                  options={incubatees}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={values.nameOfIncubatee || null}
                  isClearable
                  styles={styles}
                  onChange={data => {
                    const newValue = data || "";
                    setFieldValue("nameOfIncubatee", newValue);
                  }}
                />
                {errors.nameOfIncubatee ? (
                  <div className="text-danger">
                    {errors.nameOfIncubatee.value}
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Are you already associated with any other incubator or
                  accelerator?
                  {/* otherIncubator */}
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInline3"
                      name="otherIncubator"
                      className="custom-control-input"
                      checked={values.otherIncubator ? true : false}
                      onChange={() => {
                        setFieldValue("otherIncubator", true);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline3"
                    >
                      Yes
                    </label>
                  </div>
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInline4"
                      name="otherIncubator"
                      className="custom-control-input"
                      checked={!values.otherIncubator ? true : false}
                      onChange={() => {
                        setFieldValue("otherIncubator", false);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline4"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              {values.otherIncubator ? (
                <Fragment>
                  <div className="form-group row">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                      Incubator Name
                    </label>
                    <div className="col-12 col-lg-6 col-xl-6">
                      <Field
                        type="text"
                        className={getErrorCssClassName(
                          touched,
                          errors,
                          "incubatorName"
                        )}
                        name="incubatorName"
                        value={values.incubatorName || ""}
                      />
                      <ErrorComponent
                        fieldName="incubatorName"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                      Incubator Location
                    </label>
                    <div className="col-12 col-lg-6 col-xl-6">
                      <Field
                        type="text"
                        className={getErrorCssClassName(
                          touched,
                          errors,
                          "incubatorLocation"
                        )}
                        name="incubatorLocation"
                        value={values.incubatorLocation || ""}
                      />
                      <ErrorComponent
                        fieldName="incubatorLocation"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : null}

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  What best describes you? {/* requestorCategory  */}
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    component="select"
                    name="requestorCategory"
                    className={getErrorCssClassName(
                      touched,
                      errors,
                      "requestorCategory"
                    )}
                    value={values.requestorCategory || ""}
                    onChange={event => {
                      if (
                        event.target.value === "STARTUP" ||
                        event.target.value === "INDUSTRY"
                      ) {
                        setFieldValue("incubateeType", "COMPANY");
                      }

                      if (event.target.value === "ACADEMIC") {
                        setFieldValue("incubateeType", "INDIVIDUAL");
                      } else {
                        setFieldValue("department", "");
                        setFieldValue("contactPersonDesignation", "");
                      }
                      setFieldValue("requestorCategory", event.target.value);
                    }}
                  >
                    <option value="">Select...</option>
                    {REQUESTOR_CATEGORY_TYPES.map((opt, i) => (
                      <option key={i} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </Field>
                  <ErrorComponent
                    fieldName="requestorCategory"
                    touch={touched}
                    error={errors}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Requestor Type
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInline5"
                      name="incubateeType"
                      className="custom-control-input"
                      checked={
                        values.incubateeType === "INDIVIDUAL" ? true : false
                      }
                      onChange={() => {
                        setFieldValue("incubateeType", "INDIVIDUAL");
                        setFieldValue("organizationName", "");
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline5"
                    >
                      Individual
                    </label>
                  </div>
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInline6"
                      name="incubateeType"
                      className="custom-control-input"
                      checked={
                        values.incubateeType === "COMPANY" ? true : false
                      }
                      onChange={() => {
                        setFieldValue("incubateeType", "COMPANY");
                        setFieldValue("name", "");
                      }}
                      disabled={
                        values.requestorCategory === "ACADEMIC" ? true : false
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline6"
                    >
                      Organization
                    </label>
                  </div>
                </div>
              </div>

              {values.incubateeType === "INDIVIDUAL" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(touched, errors, "name")}
                      name="name"
                      value={values.name || ""}
                    />
                    <ErrorComponent
                      fieldName="name"
                      touch={touched}
                      error={errors}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Organization Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "organizationName"
                      )}
                      name="organizationName"
                      value={values.organizationName || ""}
                    />
                    <ErrorComponent
                      fieldName="organizationName"
                      touch={touched}
                      error={errors}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Contact Person Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "contactPersonName"
                      )}
                      name="contactPersonName"
                      value={values.contactPersonName || ""}
                    />
                    <ErrorComponent
                      fieldName="contactPersonName"
                      touch={touched}
                      error={errors}
                    />
                  </div>
                </div>
              ) : null}

              {values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Department
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "department"
                      )}
                      name="department"
                      value={values.department || ""}
                    />
                    <ErrorComponent
                      fieldName="department"
                      touch={touched}
                      error={errors}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ||
              values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Designation
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "contactPersonDesignation"
                      )}
                      name="contactPersonDesignation"
                      value={values.contactPersonDesignation || ""}
                    />
                    <ErrorComponent
                      fieldName="contactPersonDesignation"
                      touch={touched}
                      error={errors}
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Email
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="email"
                    className={getErrorCssClassName(touched, errors, "email")}
                    name="email"
                    value={values.email || ""}
                  />
                  <ErrorComponent
                    fieldName="email"
                    touch={touched}
                    error={errors}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Mobile
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="text"
                    inputMode="numeric"
                    className={getErrorCssClassName(touched, errors, "mobile")}
                    name="mobile"
                    value={values.mobile || ""}
                  />
                  <ErrorComponent
                    fieldName="mobile"
                    touch={touched}
                    error={errors}
                  />
                </div>
              </div>

              {values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Institution Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "institutionName"
                      )}
                      name="institutionName"
                      value={values.institutionName || ""}
                    />
                    <ErrorComponent
                      fieldName="institutionName"
                      touch={touched}
                      error={errors}
                    />
                  </div>
                </div>
              ) : null}
            </Fragment>
          )}

          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
              Requestor Notes {/*userNotes*/}
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <Field
                component="textarea"
                className={getErrorCssClassName(touched, errors, "userNotes")}
                name="userNotes"
                rows="5"
                value={values.userNotes || ""}
              />
              <ErrorComponent
                fieldName="userNotes"
                touch={touched}
                error={errors}
              />
            </div>
          </div>

          <div className="row m-5">
            <div className="col-12 text-center">
              <button className="btn btn-sm btn-primary" type="submit">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

class IncubatorEnquiryForm extends Component {
  render() {
    return (
      <div className="card edit-page-container">
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          validationSchema={this.props.validationSchema}
          // validate={values => validateFn(values)}
          // validate={values => validateFn(values, props.template)}
          onSubmit={(values, { setSubmitting }) =>
            this.props.handleSubmit(values, { setSubmitting })
          }
          render={renderProps => (
            <CustomForm {...renderProps} {...this.props} />
          )}
        />
      </div>
    );
  }
}

export default IncubatorEnquiryForm;
