import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import { changeToINR } from "../../common/LookupConstants";

class ManualChargePreviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInfo: [],
      paymentInfo: [],
      additionalInfo: []
    };
  }
  componentDidMount() {
    const data = this.props.data;
    this.renderData(data);
  }

  addData = (payment, labelName, value) => {
    if (value) {
      payment.push({
        label: labelName,
        value
      });
    }
  };
  getCheckIcon = data => {
    let unitPriced;
    if (data) {
      unitPriced = (
        <Fragment>
          <strong>
            <i
              className="far fa-check-square text-success font-weight-bold"
              style={{ fontSize: "m-large" }}
            />
          </strong>
          &nbsp; Yes
        </Fragment>
      );
    } else {
      unitPriced = (
        <Fragment>
          <strong>
            <i
              className="far fa-window-close text-danger font-weight-bold"
              style={{ fontSize: "m-large" }}
            />
          </strong>
          &nbsp; No
        </Fragment>
      );
    }

    return unitPriced;
  };
  getName = customer => {
    let name = "";
    const names = [];
    if (customer.firstName) {
      names.push(customer.firstName);
    }
    if (customer.lastName) {
      names.push(customer.lastName);
    }
    name = names.join(" ");

    name = names.length > 0 ? names.join(" ") : customer.name;
    return name;
  };
  getBasicInfo = data => {
    let basicInfo = [];
    this.addData(basicInfo, "Customer", this.getName(data.customer));
    this.addData(basicInfo, "Customer Type", data.customerType);
    if (basicInfo.length === 0) {
      basicInfo.push({
        label: null,
        value: "No basic Information available."
      });
    }
    return basicInfo;
  };

  getPaymentInfo = data => {
    let paymentInfo = [];
    this.addData(
      paymentInfo,
      "Unit Priced",
      this.getCheckIcon(data.unitPriced)
    );
    this.addData(paymentInfo, "Price", data.price);
    this.addData(paymentInfo, "Quantity", data.quantity);
    this.addData(paymentInfo, "Amount", changeToINR(data.amount));
    this.addData(
      paymentInfo,
      "Charge Date",
      getDisplayDate(convertNumberToDate(data.chargeDate))
    );
    this.addData(
      paymentInfo,
      "Due Date",
      getDisplayDate(convertNumberToDate(data.dueDate))
    );
    if (paymentInfo.length === 0) {
      paymentInfo.push({
        label: null,
        value: "No payment Information available."
      });
    }
    return paymentInfo;
  };
  getAdditionalInfo = data => {
    let additionalInfo = [];
    this.addData(additionalInfo, "Charge Description", data.description);
    if (additionalInfo.length === 0) {
      additionalInfo.push({
        label: null,
        value: "No additional Information available."
      });
    }
    return additionalInfo;
  };
  renderData = data => {
    const basicInfo = this.getBasicInfo(data);
    const paymentInfo = this.getPaymentInfo(data);
    const additionalInfo = this.getAdditionalInfo(data);

    this.setState({
      basicInfo,
      paymentInfo,
      additionalInfo
    });
  };
  render() {
    const { basicInfo, paymentInfo, additionalInfo } = this.state;
    return (
      <div className="row mb-2">
        <div className="col-md-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title mb-0">
                <Link
                  to={"/admin/finance/manual-charges"}
                  className="badge bg-white custom-btn-sm"
                  data-toggle="tooltip"
                  title="Back to Manual Charges List Page"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
              </span>
              <span style={{ color: "#647acb" }}>
                <strong>{this.props.title}</strong>
              </span>
            </div>
            <div className="card-body">
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Basic Information</strong>
                </div>
              </div>
              {basicInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-4 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}

              <hr />
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Charge Information</strong>
                </div>
              </div>
              {paymentInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-4 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}

              <hr />
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Additional Information</strong>
                </div>
              </div>
              {additionalInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-4 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManualChargePreviewForm;
