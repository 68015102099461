import React, { Component } from "react";
import { Redirect } from "react-router";
import AlertComponent from "../../common/AlertComponent";
import IncubatorEnquiryForm from "./IncubatorEnquiryForm";
import { PUBLIC_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import * as Yup from "yup";
import { convertDateToNumber } from "../../lease/DateUtil";

const ENQUIRY_SCHEMA = Yup.object().shape({
  enquiryType: Yup.string()
    .label("Enquiry Regarding")
    .required(),

  labTest: Yup.string().when("enquiryType", {
    is: "LAB_USAGE",
    then: Yup.string()
      .label("Lab Test")
      .required()
  }),

  equipments: Yup.array().when("enquiryType", {
    is: "EQUIPMENT_RENTAL",
    then: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string()
            .label("Equipments")
            .required(),
          label: Yup.string().optional()
        })
      )
      .label("Equipments")
  }),
  incubatee: Yup.boolean()
    .label("Incubated at PSG-STEP")
    .required(),

  nameOfIncubatee: Yup.object().when("incubatee", {
    is: true,
    then: Yup.object()
      .shape({
        value: Yup.string()
          .label("Incubatee")
          .required(),
        label: Yup.string().optional()
      })
      .required(),
    otherwise: Yup.object()
      .optional()
      .nullable()
  }),

  otherIncubator: Yup.boolean()
    .label("Other Incubator")
    .required(),

  incubatorName: Yup.string().when("otherIncubator", {
    is: true,
    then: Yup.string()
      .label("Other Incubator Name")
      .required()
  }),

  incubatorLocation: Yup.string().when("otherIncubator", {
    is: true,
    then: Yup.string()
      .label("Other Incubator Location")
      .required()
  }),

  requestorCategory: Yup.string().when("incubatee", {
    is: false,
    then: Yup.string()
      .label("Describes you")
      .required()
  }),

  name: Yup.string().when("incubateeType", {
    is: "INDIVIDUAL",
    then: Yup.string()
      .label("Name")
      .required()
  }),

  department: Yup.string().when("incubatee", {
    is: false,
    then: Yup.string().when("requestorCategory", {
      is: "ACADEMIC",
      then: Yup.string()
        .label("Department")
        .required(),
      otherwise: Yup.string().optional()
    })
  }),

  organizationName: Yup.string().when("incubateeType", {
    is: "COMPANY",
    then: Yup.string()
      .label("Company Name")
      .required()
  }),

  contactPersonName: Yup.string().when("incubateeType", {
    is: "COMPANY",
    then: Yup.string()
      .label("Contact Person Name")
      .required()
  }),

  contactPersonDesignation: Yup.string()
    .when("incubateeType", {
      is: "COMPANY",
      then: Yup.string()
        .label("Designation")
        .required(),
      otherwise: Yup.string().optional()
    })
    .when("requestorCategory", {
      is: "ACADEMIC",
      then: Yup.string()
        .label("Designation")
        .required(),
      otherwise: Yup.string().optional()
    }),
  email: Yup.string().when("incubatee", {
    is: false,
    then: Yup.string()
      .label("Email")
      .required()
  }),

  mobile: Yup.string().when("incubatee", {
    is: false,
    then: Yup.string()
      .label("Mobile")
      .required()
  }),

  institutionName: Yup.string().when("incubatee", {
    is: false,
    then: Yup.string().when("requestorCategory", {
      is: "ACADEMIC",
      then: Yup.string()
        .label("Institution Name")
        .required(),
      otherwise: Yup.string().optional()
    })
  }),

  userNotes: Yup.string().when("equipments.length", {
    is: 0,
    then: Yup.string()
      .label("Requestor notes")
      .required(),
    otherwise: Yup.string().optional()
  })
});

class IncubatorEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiry: {
        incubator: null,
        enquiryType: "",
        incubatee: false,
        priority: "MEDIUM",
        from: "",
        to: "",
        nameOfIncubatee: "",
        otherIncubator: false,
        incubatorName: "",
        incubatorLocation: "",
        requestorCategory: "",
        department: "",
        equipments: [],
        labTest: "",
        userNotes: "",
        incubateeType: "INDIVIDUAL",
        name: "",
        organizationName: "",
        contactPersonName: "",
        contactPersonDesignation: "",
        email: "",
        mobile: "",
        stage: "",
        status: "",
        institutionName: ""
      },
      assetList: [],
      incubatees: [],

      requestSubmittedMsg: null,
      enquiryId: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getIncubatorById = id => {
    const url = `${PUBLIC_API}/${id}/incubator-profile`;
    HttpUtil.get(
      url,
      {},
      data => {
        const enquiry = { ...this.state.enquiry };
        enquiry.incubator = { ...data };
        this.setState({ enquiry });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllAssets = id => {
    const url = `${PUBLIC_API}/${id}/asset-list`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ assetList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllIncubatees = id => {
    const url = `${PUBLIC_API}/${id}/incubatees`;
    HttpUtil.get(
      url,
      {},
      data => {
        const list = data.map(d => {
          return {
            value: d._id,
            label: d.name,
            incubateeType: d.incubateeType
          };
        });
        this.setState({ incubatees: list });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getRequest = values => {
    let equipments = values.equipments || [];
    equipments = equipments.map(data => data.value);

    const from = new Date(values.from);
    const to = new Date(values.to);

    const request = {
      incubator: values.incubator._id,
      enquiryType: values.enquiryType,
      incubatee: values.incubatee,
      priority: values.priority,
      from: convertDateToNumber(from),
      to: convertDateToNumber(to),
      nameOfIncubatee: values.nameOfIncubatee.value,
      otherIncubator: values.otherIncubator,
      incubatorName: values.incubatorName,
      incubatorLocation: values.incubatorLocation,
      requestorCategory: values.requestorCategory,
      equipments,
      labTest: values.labTest,
      userNotes: values.userNotes,
      incubateeType: values.incubateeType,
      name: values.name,
      department: values.department,
      organizationName: values.organizationName,
      contactPersonName: values.contactPersonName,
      contactPersonDesignation: values.contactPersonDesignation,
      email: values.email,
      mobile: values.mobile,
      institutionName: values.institutionName
    };
    return request;
  };

  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const request = this.getRequest(values);
    const incubatorId = values.incubator._id;
    const url = `${PUBLIC_API}/${incubatorId}/enquiry-form`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      request,
      data => {
        this.setState({
          requestSubmittedMsg:
            "Enquiry form request has been submitted successfully.",
          enquiryId: data._id
        });
        setSubmitting(false);
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getIncubatorById(id);
      this.getAllAssets(id);
      this.getAllIncubatees(id);
    }
  };

  render() {
    const { requestSubmittedMsg, enquiryId } = this.state;

    if (enquiryId) {
      const stateObj = {
        pathname: `/public/enquiry/${enquiryId}`,
        state: { message: requestSubmittedMsg }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const formikProps = {
      initialValues: this.state.enquiry,
      validationSchema: ENQUIRY_SCHEMA,
      assetList: this.state.assetList,
      incubatees: this.state.incubatees,

      // events
      handleApiFailed: this.handleApiFailed,
      handleSubmit: this.handleSubmit
    };
    return (
      <div
        className="wrapper"
        style={{ backgroundColor: "#dadada", minHeight: "100vh" }}
      >
        <div className="main w-100">
          <div className="content">
            <div className="container-fluid-lg container-fluid-xl">
              <div className="row mb-3">
                <div className="col-md-2" />
                <div className="col-md-8">
                  <AlertComponent {...alertProps} />
                </div>
                <div className="col-md-2" />
              </div>

              <div className="row">
                <div className="col-md-2" />
                <div className="col-md-8">
                  <IncubatorEnquiryForm {...formikProps} />
                </div>
                <div className="col-md-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IncubatorEnquiry;
