import React, { Fragment } from "react";
import { Field, ErrorMessage, FieldArray } from "formik";
import { LEGAL_STATUS_TYPES } from "../../../common/LookupConstants";
import { getYears } from "../../../lease/DateUtil";

const YEARS = getYears();

const getFinancialYears = () => {
  let year = new Date().getFullYear();
  const fy = [];
  for (let indx = 0; indx < 5; indx++) {
    fy.push(
      `${year - indx - 1}-${((year - indx) % 100).toString().padStart(2, "0")}`
    );
  }
  return fy;
};

const fYearMapper = getFinancialYears();

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

const Organization = props => {
  const { values, errors, touched, setFieldValue, downloadDocument } = props;

  const orgErrors = errors.organization || {};
  const orgTouched = touched.organization || {};
  const organization = values.organization || {};

  const products = organization.products || [];

  const productErrors = orgErrors.products || [];
  const productTouched = orgTouched.products || [];
  return (
    <div>
      {/* <div className="row no-gutters">
        <div className="col-12 mb-3 card-header border-bottom-0 pt-3">
          <span className="edit-page-title ml-4">Organization</span>
        </div>
      </div> */}
      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Basic Information</span>
        </div>
      </div>
      <div className="card-body">
        <div className="row mb-2">
          {/* Name */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Name
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "name") ? "is-invalid" : ""
                  }`}
                  name="organization.name"
                  values={organization.name || ""}
                />
                <ErrorMessage
                  component="div"
                  name="organization.name"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Website */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Website
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "website")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.website"
                  values={organization.website || ""}
                />
                <ErrorMessage
                  component="div"
                  name="organization.website"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Area / Domain */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Area / Domain
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "domainOperation")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.domainOperation"
                  values={organization.domainOperation || ""}
                />
                <ErrorMessage
                  component="div"
                  name="organization.domainOperation"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Legal Status */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Legal Status
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(orgTouched, orgErrors, "legalStatus")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.legalStatus"
                  values={organization.legalStatus || ""}
                >
                  <option value="">Select...</option>
                  {LEGAL_STATUS_TYPES.map((data, idx) => (
                    <option key={idx} value={data.key}>
                      {data.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="organization.legalStatus"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Year of Establishment */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Year of Establishment
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(orgTouched, orgErrors, "estYear")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.estYear"
                  values={organization.estYear || ""}
                >
                  <option value="">Select...</option>
                  {YEARS.map(yyyy => (
                    <option key={yyyy} value={yyyy}>
                      {yyyy}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="organization.estYear"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* References */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                References
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "reference")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.reference"
                  values={organization.reference || ""}
                />
                <ErrorMessage
                  component="div"
                  name="organization.reference"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Current Employee Count */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Current Employee Count
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "currentEmployeeCount")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.currentEmployeeCount"
                  values={organization.currentEmployeeCount || ""}
                  onChange={e => {
                    const targetValue = e.target.value || null;
                    setFieldValue(
                      "organization.currentEmployeeCount",
                      targetValue
                    );
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="organization.currentEmployeeCount"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Female Employee Count */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Female Employee Count
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "femaleEmployeeCount")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.femaleEmployeeCount"
                  values={organization.femaleEmployeeCount || ""}
                  onChange={e => {
                    const targetValue = e.target.value || null;
                    setFieldValue(
                      "organization.femaleEmployeeCount",
                      targetValue
                    );
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="organization.femaleEmployeeCount"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Subsidiary */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Subsidiary
              </label>
              <div className="col-lg-6">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control custom-control-input"
                    id="subsidiary"
                    name="subsidiary"
                    checked={organization.subsidiary || false}
                    onChange={e => {
                      if (!e.target.checked) {
                        setFieldValue("organization.parentCompany", "");
                      }
                      setFieldValue(
                        "organization.subsidiary",
                        e.target.checked
                      );
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="subsidiary"
                  />
                  Yes
                </div>
                <ErrorMessage
                  component="div"
                  name="organization.subsidiary"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Parent Company */}
          {organization.subsidiary ? (
            <div className="col-lg-6 mb-2">
              <div className="form-group row mb-0">
                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                  Parent Company
                </label>
                <div className="col-lg-6">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${
                      getError(orgTouched, orgErrors, "parentCompany")
                        ? "is-invalid"
                        : ""
                    }`}
                    name="organization.parentCompany"
                    values={organization.parentCompany || ""}
                  />
                  <ErrorMessage
                    component="div"
                    name="organization.parentCompany"
                    className="invalid-feedback mb-2"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Financial Information</span>
        </div>
      </div>
      <div className="card-body">
        <div className="row mb-2">
          {/* Authorized Capital */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Authorized Capital
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  inputMode="numeric"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "authorizedCapital")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.authorizedCapital"
                  values={organization.authorizedCapital || ""}
                />
                <ErrorMessage
                  component="div"
                  name="organization.authorizedCapital"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Annual Turnover */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Annual Turnover
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  inputMode="numeric"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "annualTurnover")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.annualTurnover"
                  values={organization.annualTurnover || ""}
                />
                <ErrorMessage
                  component="div"
                  name="organization.annualTurnover"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Annual Turnover FY */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Annual Turnover FY
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(orgTouched, orgErrors, "annualTurnoverFY")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.annualTurnoverFY"
                  values={organization.annualTurnoverFY || ""}
                >
                  <option value="">Select...</option>
                  {fYearMapper.map(yyyy => (
                    <option key={yyyy} value={yyyy}>
                      {yyyy}
                    </option>
                  ))}
                </Field>

                <ErrorMessage
                  component="div"
                  name="organization.annualTurnoverFY"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Product and service</span>
        </div>
      </div>

      <div className="card-body">
        <FieldArray
          name="organization.products"
          render={({ insert, remove, push }) => (
            <Fragment>
              {products.map((product, index) => {
                const prdError = productErrors[index] || {};
                const prdTouch = productTouched[index] || {};
                return (
                  <Fragment key={index}>
                    {/*Product Name */}
                    <div className={`row mb-2 ${index !== 0 ? "mt-5" : ""}`}>
                      <div className="col-lg-8">
                        <div className="form-group row mb-0">
                          <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                            Product Name
                          </label>
                          <div className="col-lg-9">
                            <Field
                              type="text"
                              className={`form-control form-control-sm ${
                                getError(prdTouch, prdError, "name")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`organization.products[${index}].name`}
                              values={product.name || ""}
                            />
                            <ErrorMessage
                              component="div"
                              name={`organization.products[${index}].name`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {products.length > 1 ? (
                          <button
                            className="btn btn-sm btn-outline-danger"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </button>
                        ) : null}
                      </div>
                    </div>

                    {/*Product Description */}
                    <div className="row mb-2">
                      <div className="col-lg-8">
                        <div className="form-group row mb-0">
                          <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                            Product Description
                          </label>
                          <div className="col-lg-9">
                            <Field
                              component="textarea"
                              className={`form-control form-control-sm ${
                                getError(prdTouch, prdError, "description")
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name={`organization.products[${index}].description`}
                              values={product.description || ""}
                              rows="10"
                              style={{ resize: "none" }}
                            />
                            <ErrorMessage
                              component="div"
                              name={`organization.products[${index}].description`}
                              className="invalid-feedback mb-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}

              <div className="row mb-2">
                <div className="col-lg-2 " />
                <div className="col-lg-6">
                  <button
                    className="btn btn-sm btn-outline-info"
                    type="button"
                    onClick={() =>
                      push({
                        name: "",
                        description: ""
                      })
                    }
                  >
                    Add More
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        />

        {/*         
        <div className="row mb-2">
          <div className="col-lg-8">
            <div className="form-group row mb-0">
              <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                Product Name
              </label>
              <div className="col-lg-9">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "productName")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.productName"
                  values={organization.productName || ""}
                />
                <ErrorMessage
                  component="div"
                  name="organization.productName"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <button className="btn btn-sm btn-outline-danger">Remove</button>
          </div>
        </div>

        
        <div className="row mb-2">
          <div className="col-lg-8">
            <div className="form-group row mb-0">
              <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                Product Description
              </label>
              <div className="col-lg-9">
                <Field
                  component="textarea"
                  className={`form-control form-control-sm ${
                    getError(orgTouched, orgErrors, "productDescription")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="organization.productDescription"
                  values={organization.productDescription || ""}
                  rows="10"
                  style={{ resize: "none" }}
                />
                <ErrorMessage
                  component="div"
                  name="organization.productName"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>

         */}
      </div>
      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Document Information</span>
        </div>
      </div>

      <div className="card-body">
        {/* Company Logo */}
        <div className="row mb-2">
          <div className="col-lg-12 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mt-1">
                Company Logo
              </label>
              <div className="col-lg-6">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="organization.uploadLogo"
                      aria-describedby="organization.uploadLogo"
                      name="organization.uploadLogo"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue("organization.uploadLogo", file);
                        }
                        e.target.value = "";
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="organization.uploadLogo"
                    >
                      {organization.uploadLogo
                        ? organization.uploadLogo.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        setFieldValue("organization.uploadLogo", "")
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                {organization.logo ? (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-1"
                    onClick={downloadDocument.bind(this, organization.logo)}
                  >
                    <i className="fas fa-download mr-1" /> Logo
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Company Profile */}
        <div className="row mb-2">
          <div className="col-lg-12 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mt-1">
                Company Profile
              </label>
              <div className="col-lg-6">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="organization.uploadCompanyProfile"
                      aria-describedby="organization.uploadCompanyProfile"
                      name="organization.uploadCompanyProfile"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue(
                            "organization.uploadCompanyProfile",
                            file
                          );
                        }
                        e.target.value = "";
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="organization.uploadCompanyProfile"
                    >
                      {organization.uploadCompanyProfile
                        ? organization.uploadCompanyProfile.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        setFieldValue("organization.uploadCompanyProfile", "")
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <small>Only one Page to be uploaded</small>
              </div>
              <div className="col-lg-2">
                {organization.companyProfile ? (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-1"
                    onClick={downloadDocument.bind(
                      this,
                      organization.companyProfile
                    )}
                  >
                    <i className="fas fa-download mr-1" /> Profile
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Company Registration Certificate */}
        <div className="row mb-2">
          <div className="col-lg-12 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mt-1">
                Company Registration Certificate
              </label>
              <div className="col-lg-6">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="organization.uploadRegCertificate"
                      aria-describedby="organization.uploadRegCertificate"
                      name="organization.uploadRegCertificate"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue(
                            "organization.uploadRegCertificate",
                            file
                          );
                        }
                        e.target.value = "";
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="organization.uploadRegCertificate"
                    >
                      {organization.uploadRegCertificate
                        ? organization.uploadRegCertificate.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        setFieldValue("organization.uploadRegCertificate", "")
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                {organization.regCertificate ? (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-1"
                    onClick={downloadDocument.bind(
                      this,
                      organization.regCertificate
                    )}
                  >
                    <i className="fas fa-download mr-1" /> Certificate
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Articles Of Association */}
        <div className="row mb-2">
          <div className="col-lg-12 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mt-1">
                Articles Of Association
              </label>
              <div className="col-lg-6">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="organization.uploadArticlesOfAssociation"
                      name="organization.uploadArticlesOfAssociation"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue(
                            "organization.uploadArticlesOfAssociation",
                            file
                          );
                        }
                        e.target.value = "";
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="organization.uploadArticlesOfAssociation"
                    >
                      {organization.uploadArticlesOfAssociation
                        ? organization.uploadArticlesOfAssociation.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        setFieldValue(
                          "organization.uploadArticlesOfAssociation",
                          ""
                        )
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                {organization.articlesOfAssociation ? (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-1"
                    onClick={downloadDocument.bind(
                      this,
                      organization.articlesOfAssociation
                    )}
                  >
                    <i className="fas fa-download mr-1" /> Articles
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organization;
