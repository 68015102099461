import React, { Component, Fragment } from "react";
import { STATES_API, COUNTRIES_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import { DEFAULT_COUNTRY, DEFAULT_STATE } from "../common/data/DefaultData";

const checkEmptyValue = (inputError, fieldName, value) => {
  if (value === undefined || value === "" || value === null) {
    inputError[fieldName] = true;
    return true;
  }
  return false;
};

const renderInputData = data => {
  return data ? data : "";
};

export class Addresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
      inputError: [],
      states: [],
      countries: []
    };
  }

  addMoreAddresses = () => {
    const addresses = [...this.state.addresses];
    addresses.push({
      streetLine1: null,
      streetLine2: null,
      landmark: null,
      city: null,
      state: DEFAULT_STATE,
      postalCode: null,
      country: DEFAULT_COUNTRY,
      addressProof: null,
      addressProofUpload: null,
      status: "ACTIVE",
      type: null
    });
    this.setState({ addresses });
  };

  setStateAddresses = addresses => {
    this.setState({ addresses });
  };

  removeAddress = (index, e) => {
    let addresses = [...this.state.addresses];
    if (addresses.length === 0) return;
    addresses = addresses.filter((addr, idx) => idx !== index);
    this.setStateAddresses(addresses);
  };

  handleInputChange = (index, e) => {
    e.preventDefault();
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (e.target.type === "file") {
      targetValue = e.target.files[0];
    }
    let addresses = [...this.state.addresses];
    let address = addresses[index];
    address[targetName] = targetValue;
    addresses[index] = address;

    this.setStateAddresses(addresses);
  };

  validate = () => {
    let inputError = [];
    let hasError = false;
    const addresses = this.state.addresses;

    addresses.forEach((addr, index) => {
      inputError[index] = {};
      checkEmptyValue(inputError[index], "streetLine1", addr.streetLine1);
      checkEmptyValue(inputError[index], "city", addr.city);
      checkEmptyValue(inputError[index], "state", addr.state);
      checkEmptyValue(inputError[index], "postalCode", addr.postalCode);
      checkEmptyValue(inputError[index], "country", addr.country);
      checkEmptyValue(inputError[index], "type", addr.type);
      hasError = hasError || Object.keys(inputError[index]).length > 0;
    });

    if (hasError) {
      this.props.onValidate(inputError, "addresses", null);
    } else {
      this.props.onValidate(null, "addresses", addresses);
      inputError = [];
    }
    this.setState({ inputError });
  };

  loadStateFromProps = () => {
    const { newRecord, addresses } = this.props;

    // props 'addresses' not null or not empty then add to local state.
    if (!newRecord && addresses && addresses.length > 0) {
      this.setState({ addresses: [...addresses] });
      return;
    }

    // props 'addresses' is null or is empty then create new 'addresses' array in local state.
    if (this.state.addresses.length === 0) {
      this.addMoreAddresses();
    }
  };

  download = index => {
    const addr = this.state.addresses[index];
    if (addr.addressProof) {
      const id = addr.addressProof._id || addr.addressProof;
      this.props.downloadDocument(`address/${addr._id}/${id}`);
    }
  };

  getStates = () => {
    const url = STATES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ states: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getCountries();
    this.getStates();
    this.loadStateFromProps();
  }

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    const addresses = this.props.addresses;
    if (addresses !== prevState.addresses) {
      this.loadStateFromProps();
    }
  };

  render() {
    const { countries, states, addresses, inputError } = this.state;

    const addressList = addresses.map((addr, index) => {
      const hideRemoveBtn = addresses.length === 1;
      const hideAddBtn = addresses.length - 1 !== index;
      // const hasAddrProof =
      //   addr.addressProof !== null && addr.addressProof !== undefined;

      const aProof =
        addr.addressProof && addr.addressProof._id ? addr.addressProof : null;

      const addressProofUpload = addr.addressProofUpload || null;

      return (
        <Fragment key={index}>
          <div className="mt-0">
            <strong>Address {index + 1}</strong>
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-6">
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 required">
                    Street Line1
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="text"
                      className={
                        inputError[index] && inputError[index]["streetLine1"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      placeholder="Street Line1"
                      name="streetLine1"
                      value={renderInputData(addr.streetLine1)}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                    <div className="invalid-feedback">
                      Please enter street line1 *
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 required">
                    City
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="text"
                      className={
                        inputError[index] && inputError[index]["city"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      placeholder="City"
                      name="city"
                      value={renderInputData(addr.city)}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                    <div className="invalid-feedback">
                      Please enter city name *
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 required">
                    State
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <select
                      className={
                        inputError[index] && inputError[index]["state"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      name="state"
                      value={renderInputData(addr.state)}
                      onChange={this.handleInputChange.bind(this, index)}
                    >
                      <option value="">Select...</option>
                      {states.map((state, i) => (
                        <option key={i} value={state.stateCode}>
                          {state.stateName}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Please select state name *.
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 required">
                    Postal Code
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="text"
                      className={
                        inputError[index] && inputError[index]["postalCode"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      placeholder="Postal Code"
                      name="postalCode"
                      value={renderInputData(addr.postalCode)}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                    <div className="invalid-feedback">
                      Please enter postalCode name *
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4">
                    Street Line 2
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Street Line2"
                      name="streetLine2"
                      value={renderInputData(addr.streetLine2)}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                    <div className="invalid-feedback">
                      Please enter streetLine2 *
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4">
                    Landmark
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Landmark"
                      name="landmark"
                      value={renderInputData(addr.landmark)}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                    <div className="invalid-feedback">
                      Please enter landmark *
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 required">
                    Country
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <select
                      className={
                        inputError[index] && inputError[index]["country"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      name="country"
                      value={renderInputData(addr.country)}
                      onChange={this.handleInputChange.bind(this, index)}
                    >
                      <option value="">Select...</option>
                      {countries.map((country, i) => (
                        <option key={i} value={country.countryCode}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Please select country name *
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 required">
                    Address Type
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <select
                      className={
                        inputError[index] && inputError[index]["type"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      name="type"
                      value={renderInputData(addr.type)}
                      onChange={this.handleInputChange.bind(this, index)}
                    >
                      <option value="">Select...</option>
                      <option value="PERMANENT">Permanent Address</option>
                      <option value="COMMUNICATION">
                        Communication Address
                      </option>
                      <option value="OFFICE">Office Address</option>
                    </select>
                    <div className="invalid-feedback">
                      Please select address type *
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="form-group mt-xl-2 mb-0 row">
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Address Proof
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <input
                type="file"
                name="addressProofUpload"
                onChange={this.handleInputChange.bind(this, index)}
              />
            </div>
            <div className="col-12 col-lg-2 col-xl-2 mt-2 mt-lg-0 mt-xl-0">
              <button
                className={`btn btn-sm btn-${
                  hasAddrProof ? "info" : "secondary"
                }`}
                data-toggle="tooltip"
                data-placement="top"
                title="Download Address Proof"
                disabled={!hasAddrProof}
                onClick={this.download.bind(this, index)}
              >
                <i className="fas fa-file-download" />
                &nbsp; Download
              </button>
            </div>
          </div> */}

          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Address Proof
            </label>
            <label className="col-form-label col-12 col-lg-4 col-xl-3">
              {aProof ? aProof.filename : "Not Available"}
              &nbsp;&nbsp;
              <span className="text-info">
                {addressProofUpload
                  ? `New File : "${addressProofUpload.name}"`
                  : null}
              </span>
            </label>

            <div className="col-12 col-lg-4 col-xl-3">
              <div className="upload-btn-wrapper mt-2">
                <button className="btn btn-sm btn-outline-info custom-btn">
                  <i className="fas fa-file-upload" />
                  &nbsp;Upload
                  <input
                    type="file"
                    name="addressProofUpload"
                    onChange={this.handleInputChange.bind(this, index)}
                  />
                </button>
              </div>
              &nbsp;
              {aProof && aProof._id ? (
                <div className="download-btn-wrapper mt-2">
                  <button
                    className="btn btn-sm btn-outline-info custom-btn"
                    data-toggle="tooltip"
                    title="Download Address Proof"
                    onClick={this.download.bind(this, index)}
                  >
                    <i className="fas fa-file-download" />
                    &nbsp; Download
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row mt-3 mb-0">
            <div className="col-12">
              <button
                className={
                  hideAddBtn
                    ? "btn btn-sm btn-info d-none"
                    : "btn btn-sm btn-info"
                }
                onClick={this.addMoreAddresses}
              >
                Add More
              </button>
              &nbsp;
              <button
                className={
                  hideRemoveBtn
                    ? "btn btn-sm btn-outline-danger d-none"
                    : "btn btn-sm btn-outline-danger"
                }
                onClick={this.removeAddress.bind(this, index)}
              >
                Remove Address {index + 1}
              </button>
            </div>
          </div>
          {addresses.length - 1 !== index ? <hr /> : null}
        </Fragment>
      );
    });
    return (
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <h5 className="h6 card-title mb-0">Addresses </h5>
            </div>
          </div>
          <div className="card-body border">{addressList}</div>
        </div>
      </div>
    );
  }
}
