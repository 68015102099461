import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { EVENT_TYPES } from "../common/LookupConstants";
import { getYearFirstDate, addDays } from "../lease/DateUtil";
import { downloadDocument } from "../common/Util";
import { Link } from "react-router-dom";

const validationSchema = Yup.object({
  type: Yup.string()
    .label("Event Type")
    .required(),
  name: Yup.string()
    .label("Name of the Event")
    .required(),
  description: Yup.string()
    .label("Event Description")
    .required(),
  startDate: Yup.date()
    .label("Start Date")
    .required(),
  endDate: Yup.date(),
  website: Yup.string(),
  // Added for validating website urls.
  websiteValidate: Yup.string()
    .url()
    .label("Website"),
  publish: Yup.boolean()
    .label("Publish Event")
    .required(),
  location: Yup.object({
    name: Yup.string()
      .label("Location")
      .optional(),
    address: Yup.string()
      .label("Address of location")
      .optional()
  }),

  organizer: Yup.object({
    name: Yup.string()
      .label("Organizer Name")
      .optional(),
    address: Yup.string()
      .label("Address of organizer")
      .optional()
  })
});

class EventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDate = date => {
    return date.length > 10 ? date.substring(0, 10) : date;
  };

  addHttpToWebsite(website) {
    if (website && website.indexOf("http") !== 0) {
      return `http://${website}`;
    }
    return website;
  }

  render() {
    const id = this.props.initialValues._id;
    const cancelUrl = id ? `/events/${id}` : `/events`;
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          validationSchema={validationSchema}
          onSubmit={this.props.onSubmit}
        >
          {({ errors, touched, setFieldValue, values }) => {
            return (
              <Form>
                <div className="card edit-page-container">
                  <div className="card-body">
                    <h5
                      className="card-title text-center"
                      style={{ color: "#1e3eb4" }}
                    >
                      {this.props.initialValues._id
                        ? this.props.initialValues.name
                        : "New Event"}
                    </h5>
                    <div className="row">
                      <div className="col-12 mb-3 edit-page-section-header-text">
                        Basic Information
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label required mb-0">
                            Event type
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              component="select"
                              className={`custom-select custom-select-sm ${
                                errors.type && touched.type ? "is-invalid" : ""
                              }`}
                              name="type"
                            >
                              <option value="">Select</option>
                              {EVENT_TYPES.map(type => (
                                <option key={type.value} value={type.value}>
                                  {type.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              component="div"
                              name="type"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label required">
                            Name of the event
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              className={`form-control form-control-sm ${
                                errors.name && touched.name ? "is-invalid" : ""
                              }`}
                              name="name"
                              type="text"
                            />
                            <ErrorMessage
                              component="div"
                              name="name"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row"> */}
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label required">
                            Start Date
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              className={`form-control form-control-sm ${
                                errors.startDate && touched.startDate
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="startDate"
                              type="date"
                              value={
                                values.startDate &&
                                this.getDate(values.startDate)
                              }
                              min={getYearFirstDate(addDays(new Date(), -30))}
                            />
                            <ErrorMessage
                              component="div"
                              name="startDate"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label">
                            End Date
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              className={`form-control form-control-sm ${
                                errors.endDate && touched.endDate
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="endDate"
                              type="date"
                              value={
                                values.endDate && this.getDate(values.endDate)
                              }
                              min={getYearFirstDate(
                                values.startDate || addDays(new Date(), -30)
                              )}
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          component="div"
                          name="endDate"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* <div className="row"> */}
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label">
                            URL of the event
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              className={`form-control form-control-sm ${
                                errors.websiteValidate &&
                                touched.websiteValidate
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="website"
                              type="text"
                              onChange={e => {
                                setFieldValue("website", e.target.value);
                                setFieldValue(
                                  "websiteValidate",
                                  this.addHttpToWebsite(e.target.value)
                                );
                              }}
                            />
                            <ErrorMessage
                              component="div"
                              name="websiteValidate"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-2">
                        <div className="form-group row mt-2">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label required">
                            Publish Event
                          </label>
                          <div className="col-lg-7">
                            <div
                              className={` ${
                                errors.publish && touched.publish
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <div className="custom-control custom-radio custom-control-inline mt-1">
                                <Field
                                  id="publihYes"
                                  type="radio"
                                  name="publish"
                                  disabled={this.props.initialValues.publish}
                                  value={true}
                                  checked={values.publish === true}
                                  onChange={() => {
                                    setFieldValue("publish", true);
                                  }}
                                  className={`custom-control-input ${
                                    errors.publish && touched.publish
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <label
                                  className="edit-page-label custom-control-label"
                                  htmlFor="publihYes"
                                >
                                  Publish
                                </label>
                              </div>
                              <div className="custom-control custom-radio custom-control-inline mt-1">
                                <Field
                                  type="radio"
                                  id="publishNo"
                                  name="publish"
                                  value={true}
                                  disabled={this.props.initialValues.publish}
                                  checked={values.publish === false}
                                  onChange={() => {
                                    setFieldValue("publish", false);
                                  }}
                                  className={`custom-control-input ${
                                    errors.publish && touched.publish
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                                <label
                                  className=" edit-page-label custom-control-label"
                                  htmlFor="publishNo"
                                >
                                  Draft
                                </label>
                              </div>
                            </div>
                            <ErrorMessage
                              component="div"
                              name="publish"
                              className="text-danger"
                              style={{ fontSize: "80%" }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row"> */}
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-12 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 edit-page-label required">
                            Description
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-12 col-xl-12">
                            <Field
                              component="textarea"
                              rows="5"
                              className={`form-control form-control-sm ${
                                errors.description && touched.description
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="description"
                            />
                            <ErrorMessage
                              component="div"
                              name="description"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mb-3 edit-page-section-header-text mt-3">
                        Venue Details
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label mb-0">
                            Location Name
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              className={`form-control form-control-sm ${
                                errors.location &&
                                errors.location.name &&
                                touched.location &&
                                touched.location.name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="location.name"
                              type="text"
                            />
                            <ErrorMessage
                              component="div"
                              name="location.name"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label">
                            Address of Location
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              className={`form-control form-control-sm ${
                                errors.location &&
                                errors.location.address &&
                                touched.location &&
                                touched.location.address
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="location.address"
                              type="text"
                            />
                            <ErrorMessage
                              component="div"
                              name="location.address"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row"> */}
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label mb-0">
                            Organiser Name
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              className={`form-control form-control-sm ${
                                errors.organizer &&
                                errors.organizer.name &&
                                touched.organizer &&
                                touched.organizer.name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="organizer.name"
                              type="text"
                            />
                            <ErrorMessage
                              component="div"
                              name="organizer.name"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mt-3">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label">
                            Address of organiser
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8">
                            <Field
                              className={`form-control form-control-sm ${
                                errors.organizer &&
                                errors.organizer.address &&
                                touched.organizer &&
                                touched.organizer.address
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="organizer.address"
                              type="text"
                            />
                            <ErrorMessage
                              component="div"
                              name="organizer.address"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6">
                        <div className="col-12 mb-3 edit-page-section-header-text">
                          Flyer
                        </div>
                        {this.props.initialValues.flyer ? (
                          <div>
                            <img
                              alt="Flyer"
                              src={`/api/v1/events/${this.props.initialValues._id}/flyer`}
                              className="img-fluid w-25"
                            />
                            <button
                              className="btn btn-link btn-sm ml-2 p-0"
                              onClick={() =>
                                downloadDocument(
                                  `/api/v1/events/${this.props.initialValues._id}/flyer`,
                                  msg => window.alert(msg),
                                  "flyer"
                                )
                              }
                            >
                              Download Flyer
                            </button>
                          </div>
                        ) : null}
                        <br />
                        <input
                          id="flyer"
                          name="flyer"
                          type="file"
                          accept="image/*"
                          onChange={event => {
                            setFieldValue(
                              "flyer",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <div className="col-12 mb-3 edit-page-section-header-text">
                          Brochure
                        </div>
                        {this.props.initialValues.brochure ? (
                          <button
                            className="btn btn-sm btn-outline-info mr-2 mb-2"
                            onClick={() =>
                              downloadDocument(
                                `/api/v1/events/${this.props.initialValues._id}/brochure`,
                                msg => window.alert(msg),
                                "brochure"
                              )
                            }
                          >
                            Download Brochure
                          </button>
                        ) : null}
                        <br />
                        <input
                          id="brochure"
                          name="brochure"
                          type="file"
                          accept="application/pdf"
                          onChange={event => {
                            setFieldValue(
                              "brochure",
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm text-center"
                        >
                          {this.props.initialValues._id ? "Update" : "Submit"}
                        </button>
                        <Link
                          to={cancelUrl}
                          role="button"
                          className="btn btn-outline-secondary btn-sm ml-3"
                        >
                          Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default EventForm;
