import React, { Component, Fragment } from "react";
import AutoLookup from "../custom/AutoLookup";
import DatePicker from "react-datepicker";
import PopUpPartition from "./PopUpPartition";
import "react-datepicker/dist/react-datepicker.css";

import {
  getDisplayDate,
  getDateAndTimeFormat,
  convertNumberToDate
} from "./DateUtil";
import { LEASE_TYPES, LEASE_TYPES_OBJECT } from "../common/LookupConstants";

import { getDescription } from "../common/Util";

export const getSize = byteValue => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let i = 0,
    n = parseInt(byteValue, 10) || 0;
  while (n >= 1024 && ++i) {
    n = n / 1024;
  }
  return n.toFixed(n >= 10 || i < 1 ? 0 : 1) + " " + units[i];
};

export const getSizeInMb = byteValue => {
  let n = parseInt(byteValue, 10) || 0;
  return n / (1024 * 1024);
};

const changeToINR = number => {
  let num = number;
  if (isNaN(num)) {
    return num;
  }
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(num);
};

export const PARTITION_TYPE_MAPPER = {
  RENTAL: "Fixed Rental",
  SEATER: "Seater area"
};

export const AREA_UNIT_MAPPER = {
  SQFT: "Sqft"
};

export const getIcon = partition => {
  let renderIcon;
  if (partition.partitionType === "SEATER") {
    renderIcon = (
      <span className="fa-stack fa-lg" data-toggle="tooltip" title="Seater">
        <i className="fas fa-circle fa-stack-2x" style={{ color: "#2457ce" }} />
        <i className="fas fa-chair fa-stack-1x text-white" />
      </span>
    );
  } else {
    renderIcon = (
      <span
        className="fa-stack fa-lg"
        data-toggle="tooltip"
        title="Fixed Rental"
      >
        <i className="fas fa-circle fa-stack-2x" style={{ color: "#2457ce" }} />
        <i className="far fa-building fa-stack-1x text-white" />
      </span>
    );
  }
  return renderIcon;
};
export class PartitionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  showPartitionPopUp = () => {
    this.setState({ show: !this.state.show });
  };

  getSeletedPartitions = (partitions, searchedSeatCount) => {
    this.setState({ show: !this.state.show });
    this.props.getSeletedPartitions(partitions, searchedSeatCount);
  };

  removePartition = (index, e) => {
    this.props.removePartition(index, e);
  };

  render() {
    const { show } = this.state;
    const {
      authToken,
      leasePartitions,
      inputError,
      handleEdit,
      actions
    } = this.props;

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 mb-3 edit-page-section-header-text">
            Partition Information
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 p-0">
            {actions.length > 0 ? (
              <Fragment>
                <div className="mb-2 mr-3 float-right">
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={this.showPartitionPopUp}
                  >
                    New
                  </button>
                </div>
                {leasePartitions.length === 0 ? (
                  <span className="ml-3">No Rental Partition is available</span>
                ) : null}
              </Fragment>
            ) : null}

            <div className="table-responsive">
              {show ? (
                <PopUpPartition
                  selectedPartitions={this.getSeletedPartitions}
                  leaseSelectedPartitions={leasePartitions}
                  show={show}
                  authToken={authToken}
                  close={this.showPartitionPopUp}
                />
              ) : null}

              {leasePartitions.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        className="border-bottom-0 display-table-header text-center"
                        scope="col"
                        style={{ width: "35%" }}
                      >
                        Partition
                      </th>
                      <th
                        className="border-bottom-0 display-table-header text-right"
                        scope="col"
                        style={{ width: "10%" }}
                      >
                        Unit Price
                      </th>
                      <th
                        className="border-bottom-0 display-table-header text-right"
                        scope="col"
                        style={{ width: "20%" }}
                      >
                        Seat Count / Area
                      </th>
                      <th
                        className="border-bottom-0 display-table-header text-right"
                        scope="col"
                        style={{ width: "10%" }}
                      >
                        Est.Rent
                      </th>
                      <th
                        className="border-bottom-0 display-table-header text-right"
                        scope="col"
                        style={{ width: "20%" }}
                      >
                        Actual Rent
                      </th>
                      {actions.length > 0 ? (
                        <th
                          className="border-bottom-0 display-table-header"
                          scope="col"
                          style={{ width: "5%" }}
                        />
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {leasePartitions.map((partition, index) => {
                      const warningStyle = {
                        backgroundColor: "#FFE19E",
                        borderColor: "#FFE19E",
                        color: "#000"
                      };

                      const cssClassName =
                        partition.estimatedRent !== partition.actualRent
                          ? warningStyle
                          : {};

                      return (
                        <tr key={index}>
                          <td className="align-middle">
                            <div className="d-flex">
                              <div className="align-self-center">
                                {getIcon(partition)}
                              </div>
                              <div className="ml-2">
                                <h5 className="mb-0">
                                  {partition.displayName}
                                </h5>
                                <small>
                                  {partition.campusName} <strong> | </strong>{" "}
                                  {partition.buildingName}
                                  <strong> | </strong> {partition.floorName}
                                </small>
                              </div>
                            </div>
                          </td>
                          <td className="text-right align-middle">
                            {changeToINR(partition.unitPrice || 0)}
                          </td>
                          <td className="text-right align-middle">
                            <div>
                              {partition.partitionType === "SEATER" ? (
                                <input
                                  type="number"
                                  className={
                                    inputError["seatCount"]
                                      ? "form-control form-control-sm text-right float-right w-75 is-invalid"
                                      : "form-control form-control-sm text-right float-right w-75"
                                  }
                                  placeholder="Enter seat count"
                                  name="seatCount"
                                  defaultValue={partition.seatCount}
                                  onChange={handleEdit(index)}
                                  {...this.props.disabledField}
                                />
                              ) : (
                                `${partition.area} ${
                                  AREA_UNIT_MAPPER[partition.areaUnit]
                                }`
                              )}
                            </div>
                          </td>

                          <td className="text-right align-middle">
                            {changeToINR(partition.estimatedRent)}
                          </td>

                          <td className="align-middle">
                            <input
                              className="form-control form-control-sm text-right float-right w-75"
                              type="number"
                              name="actualRent"
                              value={partition.actualRent}
                              placeholder="Rs.00"
                              onChange={handleEdit(index)}
                              style={cssClassName}
                              {...this.props.disabledField}
                            />
                          </td>

                          {actions.length > 0 ? (
                            <td
                              className="align-middle"
                              data-toggle="tooltip"
                              title="Remove"
                            >
                              <i
                                className="far fa-trash-alt text-danger"
                                style={{ cursor: "pointer" }}
                                onClick={this.removePartition.bind(this, index)}
                              />
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// ---------------------------- Lease History -------------------------------

export class LeaseHistory extends Component {
  render() {
    const { leaseType } = this.props;
    const leaseHistory = this.props.leaseHistory || [];

    if (leaseHistory.length < 1) {
      return null;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 mb-3 edit-page-section-header-text">
            Previous Leases
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 p-0">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      className="border-bottom-0 display-table-header ml-5"
                      scope="col"
                      style={{ width: "35%" }}
                    >
                      Partition
                    </th>
                    <th
                      className="border-bottom-0 display-table-header"
                      scope="col"
                      style={{ width: "16%" }}
                    >
                      Lease Type
                    </th>
                    <th
                      className="border-bottom-0 display-table-header"
                      scope="col"
                      style={{ width: "22%" }}
                    >
                      Lease Duration
                    </th>
                    <th
                      className="border-bottom-0 display-table-header"
                      scope="col"
                      style={{ width: "16%" }}
                    >
                      Status
                    </th>
                    <th
                      className="border-bottom-0 display-table-header text-center"
                      scope="col"
                      style={{ width: "16%" }}
                    >
                      Advance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leaseHistory.map((lease, index) => {
                    const warningStyle = {
                      backgroundColor: "#FFE19E",
                      borderColor: "#FFE19E",
                      color: "#000"
                    };

                    const leaseStartDate = convertNumberToDate(
                      lease.leaseStartDate
                    );
                    const leaseEndDate = convertNumberToDate(
                      lease.leaseEndDate
                    );
                    return (
                      <tr key={index}>
                        <td className="align-middle">
                          <div className="d-flex">
                            <div className="align-self-center">
                              {getIcon(lease)}
                            </div>
                            <div className="ml-2">
                              {lease.leasePartitions.map((partition, index) => {
                                const displayName =
                                  partition.partition.displayName;
                                const campusName =
                                  partition.partition.floor.building.campus
                                    .name;
                                const buildingName =
                                  partition.partition.floor.building
                                    .buildingName;
                                const floorName =
                                  partition.partition.floor.floorName;
                                return (
                                  <div key={index}>
                                    <h5 className="mb-0">{displayName}</h5>
                                    <small>
                                      {campusName}
                                      <strong> | </strong> {buildingName}
                                      <strong> | </strong> {floorName}
                                    </small>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </td>
                        <td className="align-middle">
                          {LEASE_TYPES_OBJECT[leaseType] || "--"}
                        </td>
                        <td className="align-middle">
                          {getDisplayDate(leaseStartDate)} <br />
                          {getDisplayDate(leaseEndDate)}
                        </td>

                        <td className="align-middle">
                          {getDescription("LEASE", lease.stage, lease.status)}
                        </td>

                        <td className="align-middle text-right">
                          <div className="w-75">
                            {changeToINR(lease.deposit)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// ---------------------------- Payment Information ------------------------------
export const PaymentInfo = props => {
  const {
    deposit,
    nonRefundableFee,
    monthlyRent,

    additionalMonthlyFee,
    inputError,
    onChange,
    handleDeposit
    // discountAmount,
    // discounts,
    // leaseStartDate,
    // leaseEndDate,
    // addDiscounts,
    // removeDiscounts, // funtion
    // removeDiscount, // boolean
    // handleEditDiscount
  } = props;

  // const monthNames = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December"
  // ];

  // let months = getMonths(new Date(leaseStartDate), new Date(leaseEndDate));
  // let hideAddDiscount = months === discounts.length;

  // let discountList = discounts.map(discount => {
  //   let d = new Date(leaseStartDate);
  //   let monthName =
  //     monthNames[d.getMonth() + discount.monthId] + ", " + d.getFullYear();
  //   let list = {
  //     discountAmt: discount.discountAmt,
  //     monthId: discount.monthId,
  //     monthName: monthName
  //   };
  //   return list;
  // });

  const leaseType = props.leaseType;

  const requiredClass =
    leaseType !== "REGULAR" && leaseType !== "COMPLEMENTARY" ? true : false;

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 mb-3 edit-page-section-header-text">
          Payment Information
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
              Advance Deposit
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <input
                type="number"
                className={
                  inputError["deposit"]
                    ? "form-control text-right is-invalid"
                    : "form-control text-right"
                }
                placeholder="0"
                name="deposit"
                value={deposit}
                onChange={handleDeposit}
                {...props.disabledField}
              />
              {inputError["deposit"] ? (
                <div className="invalid-feedback">Please enter deposit *.</div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label
              className={`col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left ${
                requiredClass ? "edit-page-label-required" : "edit-page-label"
              }`}
            >
              Non Refundable Fee
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <input
                type="number"
                className={
                  inputError["nonRefundableFee"]
                    ? "form-control text-right is-invalid"
                    : "form-control text-right"
                }
                placeholder="0"
                name="nonRefundableFee"
                value={nonRefundableFee}
                onChange={onChange}
                {...props.disabledField}
              />
              {inputError["nonRefundableFee"] ? (
                <div className="invalid-feedback">
                  Please enter non refundable fee
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label
              className={`col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left ${
                requiredClass ? "edit-page-label" : "edit-page-label-required"
              }`}
            >
              Monthly Rent
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <input
                className={
                  inputError["monthlyRent"]
                    ? "form-control text-right is-invalid"
                    : "form-control text-right"
                }
                placeholder="0"
                name="monthlyRent"
                value={changeToINR(monthlyRent)}
                readOnly
              />
              {inputError["monthlyRent"] ? (
                <div className="invalid-feedback">
                  Please enter monthly rent *
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
              Additional Monthly Fees
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <input
                type="number"
                className={
                  inputError["additionalMonthlyFee"]
                    ? "form-control text-right is-invalid"
                    : "form-control text-right"
                }
                placeholder="0"
                name="additionalMonthlyFee"
                value={additionalMonthlyFee}
                onChange={onChange}
                {...props.disabledField}
              />
              {inputError["additionalMonthlyFee"] ? (
                <div className="invalid-feedback">
                  Please additional monthly fee
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* temporary hided - Monthly Discounts
        <div className="input-group">
          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
            <div className="form-group row">
              <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                Monthly Discounts
              </label>
              <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                <input
                  type="number"
                  className={
                    inputError["discountAmount"]
                      ? "form-control text-right is-invalid"
                      : "form-control text-right"
                  }
                  placeholder="0"
                  name="discountAmount"
                  value={discountAmount}
                  onChange={onChange}
                  {...props.disabledField}
                />

                {inputError["discountAmount"] ? (
                  <div className="invalid-feedback">Please enter discount.</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-2">
            {hideAddDiscount ? (
              <span className="input-group-sm input-group-prepend">
                <button
                  className="btn btn-sm btn-secondary"
                  type="button"
                  disabled
                >
                  Add
                </button>
              </span>
            ) : (
              <span className="input-group-sm input-group-prepend">
                <button
                  className="btn btn-sm btn-secondary"
                  type="button"
                  onClick={addDiscounts}
                >
                  Add
                </button>
              </span>
            )}
          </div>
        </div> */}
      </div>
      {/* temporary hided - Monthly Discounts & discountList
       {removeDiscount ? (
        <legend className="scheduler-border" style={{ color: "#f44455" }}>
          Lease End date is changed. Please re-update or remove below discount *
        </legend>
      ) : null}
      {discountList.length > 0 ? (
        <div className="row">
          <div className="col-12">
            <table className="table table-bordered table-striped table-sm">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Month</th>
                  <th>Discount</th>
                  <th>Action </th>
                </tr>
              </thead>
              <tbody>
                {discountList.map((discountAmt, idx) => {
                  let showDelete = discountList.length - 1 === idx;
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{discountAmt.monthName} </td>
                      <td>
                        <input
                          className="form-control text-right form-control-sm"
                          type="number"
                          name="discountAmount"
                          value={discountAmt.discountAmt}
                          placeholder="0"
                          onChange={handleEditDiscount(idx)}
                        />
                      </td>
                      <td>
                        {showDelete ? (
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={removeDiscounts.bind(this, idx)}
                          >
                            {" "}
                            Delete
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null} */}
    </Fragment>
  );
};
// ---------------------------- Additional Information ------------------------------
export const AdditionalInfo = props => {
  const {
    inputError,
    // leaseApprovedBy,
    // leaseTerminationDate,
    scannedCopyList,
    scannedCopyLimit,
    scannedCopy,
    remarks,
    notes,

    actions,
    // event
    onChange,
    getDocument,
    // removeScannedCopy,
    disabledField
  } = props;

  let documentLimit = scannedCopyLimit - scannedCopyList.length;

  const hasDocuments =
    (scannedCopyList || []).length > 0 || (scannedCopy || []).length > 0;

  return (
    <Fragment>
      <div className="card-deck mb-3">
        <div className="card border-0 ml-0">
          <div className="edit-page-section-header-text mb-2">Notes </div>
          <div className="card-body p-0">
            <ul className="list-group list-group-flush ml-3 mb-3">
              {notes.map((nt, i) => {
                return (
                  <li
                    key={i}
                    className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                  >
                    <div className="d-flex w-100 justify-content-between">
                      <strong>{nt.user.firstName}</strong>
                      <small>{getDateAndTimeFormat(nt.date)}</small>
                    </div>
                    <p className="mb-1">{nt.remark}</p>
                  </li>
                );
              })}

              {notes.length === 0 && actions.length === 0 ? (
                <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                  No notes entered so far.
                </li>
              ) : null}
            </ul>

            {actions.length === 0 ? null : (
              <div className="m-3">
                <textarea
                  type="text"
                  className={
                    inputError["remarks"] ||
                    inputError["estAndActualRentNotEqual"]
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Write some notes here"
                  name="remarks"
                  rows="2"
                  value={remarks}
                  onChange={onChange}
                  {...disabledField}
                />
                {inputError["remarks"] ? (
                  <div className="invalid-feedback">Please enter notes *</div>
                ) : null}
                {inputError["estAndActualRentNotEqual"] ? (
                  <div className="invalid-feedback">
                    Difference between estimated rent and actual rent. Please
                    enter notes.
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>

        <div className="card border-0 mr-0">
          <div className="edit-page-section-header-text mb-2">Documents </div>
          <div className="card-body p-0">
            <ul
              className="list-group list-group-flush ml-3"
              style={{ minHeight: "100px" }}
            >
              {scannedCopyList.map((doc, index) => {
                return (
                  <li
                    key={index}
                    className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                  >
                    <span>
                      <i className={`far fa-file fa-lg mr-2`} />
                      {doc.filename}
                    </span>
                    <div>
                      <i
                        className="fas fa-download fa-md mr-3 text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={getDocument.bind(this, doc)}
                      />
                    </div>
                  </li>
                );
              })}
              {Array.from(scannedCopy).map((file, index) => {
                return (
                  <li
                    key={index}
                    className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                  >
                    <span>
                      <i className={`far fa-file fa-lg mr-2`} />
                      {file.name}
                    </span>
                    <div>
                      <span className="">Upload Pending</span>
                      {/* <i
                        className="far fa-trash-alt fa-md text-danger  ml-3 mr-3"
                        style={{ cursor: "pointer" }}
                        onClick={removeScannedCopy.bind(this, file)}
                      /> */}
                    </div>
                  </li>
                );
              })}
              {!hasDocuments ? (
                <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                  No documents available
                </li>
              ) : null}
            </ul>
            {actions.length === 0 ? null : (
              <div className="text-center">
                <div className="upload-btn-wrapper">
                  <button
                    className="btn btn-sm btn-outline-info"
                    type="button"
                    {...disabledField}
                  >
                    <i className="fas fa-upload mr-2" />
                    Upload
                    <input
                      type="file"
                      className={
                        inputError["scannedCopy"]
                          ? "validation-file is-invalid"
                          : "validation-file"
                      }
                      name="scannedCopy"
                      multiple
                      onChange={onChange}
                      {...disabledField}
                    />
                  </button>
                </div>
                {inputError["scannedCopy"] ? (
                  <div className="auto-lookup-empty">
                    Only {documentLimit} documents can be allowed.
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// ---------------------------- Agreement ---------------- ------------------------------
export const Agreement = props => {
  const {
    incubatee,
    agreementDate,
    leaseStartDate,
    leaseEndDate,
    leaseDuration,
    // alert error
    inputError,
    // event
    handleLeaseDuration,
    getIncubatee,
    authToken,
    newLease,
    handleDateChange
  } = props;

  const leaseableIncubateesApi = `/api/v1/incubatees/leaseable?name=`;

  let agreeDate = agreementDate ? new Date(agreementDate) : "";
  if (typeof agreementDate === "number") {
    agreeDate = convertNumberToDate(agreementDate);
  }

  let leaseStDate = leaseStartDate ? new Date(leaseStartDate) : "";
  if (typeof leaseStartDate === "number") {
    leaseStDate = convertNumberToDate(leaseStartDate);
  }

  let leaseEdDate = leaseEndDate ? new Date(leaseEndDate) : "";
  if (typeof leaseEndDate === "number") {
    leaseEdDate = convertNumberToDate(leaseEndDate);
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 mb-3 edit-page-section-header-text">
          Agreement
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
              Incubatee Name
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              {newLease ? (
                <AutoLookup
                  className={inputError["incubatee"]}
                  name="name"
                  value={incubatee}
                  authToken={authToken}
                  getObject={getIncubatee}
                  url={leaseableIncubateesApi}
                />
              ) : (
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Incubatee Name *"
                  name="incubatee"
                  defaultValue={incubatee.name}
                  {...props.disabledField}
                />
              )}

              {inputError["incubatee"] ? (
                <div className="auto-lookup-empty">
                  Please enter incubatee name *.
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
              Lease Type
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <select
                className={
                  inputError["leaseType"]
                    ? "custom-select custom-select-sm is-invalid"
                    : "custom-select custom-select-sm"
                }
                name="leaseType"
                value={props.leaseType}
                onChange={props.onChange}
                {...props.disabledField}
              >
                <option value="">Select</option>
                {LEASE_TYPES.map((type, idx) => (
                  <option key={idx} value={type.key}>
                    {type.value}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                Please select partition type *.
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
              Lease Duration (Months)
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <input
                type="number"
                className="form-control form-control-sm"
                name="leaseDuration"
                value={leaseDuration || ""}
                onChange={handleLeaseDuration}
                {...props.disabledField}
              />

              {inputError["leaseDuration"] ? (
                <div className="auto-lookup-empty">
                  Please enter lease duration 1 to 24 month limit *.
                </div>
              ) : (
                <small>1 to 24 month limit</small>
              )}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
              Agreement Date
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <DatePicker
                value={getDisplayDate(agreeDate)}
                selected={agreeDate !== "" ? new Date(agreeDate) : null}
                onChange={handleDateChange.bind(this, "agreementDate")}
                utcOffset={0}
                placeholderText="dd-mm-yyyy"
                className={
                  inputError["agreementDate"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={props.disabledField.disabled ? true : false}
              />

              {inputError["agreementDate"] ? (
                <div className="auto-lookup-empty">
                  Please select agreement date *.
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
              Lease Start Date
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <DatePicker
                value={getDisplayDate(leaseStDate)}
                selected={leaseStDate !== "" ? new Date(leaseStDate) : null}
                onChange={handleDateChange.bind(this, "leaseStartDate")}
                utcOffset={0}
                placeholderText="dd-mm-yyyy"
                className={
                  inputError["leaseStartDate"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={props.disabledField.disabled ? true : false}
              />

              {inputError["leaseStartDate"] ? (
                <div className="auto-lookup-empty">
                  Please select lease start date *.
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
          <div className="form-group row">
            <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
              Lease End Date
            </label>
            <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
              <DatePicker
                value={getDisplayDate(leaseEdDate)}
                selected={leaseEdDate !== "" ? new Date(leaseEdDate) : null}
                minDate={
                  leaseStDate !== ""
                    ? new Date(
                        new Date(leaseStDate).getTime() +
                          1 * 24 * 60 * 60 * 1000
                      )
                    : null
                }
                onChange={handleDateChange.bind(this, "leaseEndDate")}
                utcOffset={0}
                placeholderText="dd-mm-yyyy"
                className={
                  inputError["leaseEndDate"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                disabled={props.disabledField.disabled ? true : false}
              />

              {inputError["leaseEndDate"] ? (
                <div className="auto-lookup-empty">
                  Please select lease end date *.
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
