import React, { Fragment } from "react";
import { Field, FieldArray, getIn } from "formik";
import { FIELD_TEMPLATE_TYPES } from "../../common/LookupConstants";

const FieldTemplateForm = props => {
  const { errors, touched } = props;
  const fieldTemplates = props.fieldTemplates || [];

  const fieldErrors = getIn(errors, props.fieldArrayName) || [];
  const fieldTouched = getIn(touched, props.fieldArrayName) || [];

  return (
    <Fragment>
      <FieldArray
        name={`${props.fieldArrayName}`}
        render={({ remove, push }) => {
          return (
            <Fragment>
              <div className="col-12 col-md-6 mt-5">
                <strong className="border-bottom pb-1 d-block mb-2">
                  Field Template
                </strong>
                <div className="">
                  {fieldTemplates.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th
                              className="text-center display-table-header"
                              style={{ width: "33.33%" }}
                              scope="col"
                            >
                              Field Name
                            </th>
                            <th
                              className="text-center display-table-header"
                              style={{ width: "33.33%" }}
                              scope="col"
                            >
                              Field Type
                            </th>
                            <th
                              className="text-center display-table-header"
                              style={{ width: "33.33%" }}
                              scope="col"
                            >
                              Required
                            </th>
                            <th className="display-table-header" />
                          </tr>
                        </thead>
                        <tbody>
                          {fieldTemplates.map((ft, index) => {
                            const error = fieldErrors[index] || {};
                            const touch = fieldTouched[index] || {};
                            return (
                              <tr key={index}>
                                <td className="align-middle pl-0">
                                  <Field
                                    type="text"
                                    className={`form-control form-control-sm ${
                                      touch["name"] && error["name"]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    title="Enter Field Name"
                                    placeholder="Enter Field Name"
                                    name={`${props.fieldArrayName}.${index}.name`}
                                    value={ft.name}
                                  />
                                  <div className="invalid-feedback">
                                    {touch["name"] && error["name"]}
                                  </div>
                                </td>
                                <td className="align-middle">
                                  <Field
                                    component="select"
                                    type="text"
                                    className={`custom-select custom-select-sm ${
                                      touch["type"] && error["type"]
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name={`${props.fieldArrayName}.${index}.type`}
                                    value={ft.type || ""}
                                  >
                                    <option value=""></option>
                                    {FIELD_TEMPLATE_TYPES.map((flt, i) => (
                                      <option key={i} value={flt.key}>
                                        {flt.value}
                                      </option>
                                    ))}
                                  </Field>
                                  <div className="invalid-feedback">
                                    {touch["type"] && error["type"]}
                                  </div>
                                </td>
                                <td className="align-middle text-center">
                                  <div className="custom-control custom-checkbox">
                                    <Field
                                      type="checkbox"
                                      className={`custom-control-input ${
                                        touch["required"] && error["required"]
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      id={`fieldTemplates[${index}].required`}
                                      checked={ft.required}
                                      name={`${props.fieldArrayName}.${index}.required`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`fieldTemplates[${index}].required`}
                                    />
                                  </div>
                                </td>
                                <td className="align-middle text-center">
                                  <i
                                    className="far fa-trash-alt text-danger"
                                    title="Remove"
                                    onClick={() => remove(index)}
                                    style={{
                                      cursor: "pointer"
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-sm btn-outline-info custom-btn"
                        type="button"
                        onClick={() =>
                          push({
                            name: "",
                            type: "",
                            required: false
                          })
                        }
                      >
                        <i className="fas fa-plus mr-2"></i>Add More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      />
    </Fragment>
  );
};

export default FieldTemplateForm;
