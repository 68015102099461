import React from "react";
import { Switch, Route } from "react-router-dom";
import FixedRentalOccupancyReport from "./facility/FixedRentalOccupancyReport";
import SeaterOccupancyReport from "./facility/SeaterOccupancyReport";
import IncubateeInfoReport from "./incubatee/IncubateeInfoReport";
import PartitionUsageReport from "./facility/PartitionUsageReport";
import ConsolidatedFacilityUsage from "./facility/ConsolidatedFacilityUsage";
import IncubateeClusterInfoReport from "./incubatee/IncubateeClusterInfoReport";
import JobGeneratedInfo from "./incubatee/JobGeneratedInfo";
import AnnualTurnoverInfo from "./incubatee/AnnualTurnoverInfo";

const ROUTES = [
  {
    path: "/reports/facility/fixed-rental-occupancy",
    component: FixedRentalOccupancyReport,
    passProps: true
  },
  {
    path: "/reports/facility/seater-occupancy",
    component: SeaterOccupancyReport,
    passProps: true
  },
  {
    path: "/reports/facility/total-partition-usage",
    component: PartitionUsageReport,
    passProps: true
  },
  {
    path: "/reports/facility/consolidated-facility-usage",
    component: ConsolidatedFacilityUsage,
    passProps: true
  },
  {
    path: "/reports/incubatee/info",
    component: IncubateeInfoReport,
    passProps: true
  },
  {
    path: "/reports/incubatee/cluster-info",
    component: IncubateeClusterInfoReport,
    passProps: true
  },
  {
    path: "/reports/incubatee/jobs-generated-info",
    component: JobGeneratedInfo,
    passProps: true
  },
  {
    path: "/reports/incubatee/annual-turnover-info",
    component: AnnualTurnoverInfo,
    passProps: true
  }
];

const ReportRoutes = () => {
  return (
    <Switch>
      {ROUTES.map((route, index) => {
        const { path, passProps } = route;
        const key = `reports-${index}`;
        const Comp = route.component;
        const routeComponent = passProps ? props => <Comp {...props} /> : Comp;
        return <Route exact key={key} path={path} component={routeComponent} />;
      })}
    </Switch>
  );
};

export default ReportRoutes;
