import React, { Component, Fragment } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDisplayDate,
  getYears,
  convertDateToNumber,
  convertNumberToDate
} from "../../lease/DateUtil";
import { GENDERS_API } from "../../common/Constants";
import Contact from "./onboarding-ui-form/Contact";
import IncubateeProfile from "./onboarding-ui-form/IncubateeProfile";
import Patent from "./onboarding-ui-form/Patent";
import HttpUtil from "../../common/HttpUtil";
import { EDU_QUALIFICATION_TYPES } from "../../common/LookupConstants";
import { titleCaseForOneCaseString } from "../../common/Util";

const YEARS = getYears();

class IndividualType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genders: [],
      individualType: {
        firstName: "",
        lastName: "",
        gender: null,
        dob: "",
        experience: "",
        education: [
          {
            year: "",
            qualification: "",
            degree: "",
            institution: "",
            alumni: false
          }
        ],
        references: "",
        indvPhoto: null
      }
    };
  }

  handleDateChange = (targetName, date) => {
    let individualType = { ...this.state.individualType };
    individualType[targetName] = convertDateToNumber(date);
    this.setState({ individualType }, this.handleSetState(individualType));
  };

  handleNameInputchange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    const individualType = { ...this.props.individual };

    const prevValueFieldName = `${targetName}PrevValue`;
    const prevValue = this.state[prevValueFieldName];

    if (prevValue === targetValue) {
      individualType[targetName] = targetValue;
    } else {
      individualType[targetName] = titleCaseForOneCaseString(targetValue);
    }

    this.setState(
      { individualType, [prevValueFieldName]: targetValue },
      this.handleSetState(individualType)
    );
  };

  handleInputChange = e => {
    if (e.target.type === "number" && isNaN(e.target.value)) {
      return;
    }
    let individualType = { ...this.state.individualType };
    let targetName = e.target.name;
    let targetValue = e.target.value;

    if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
    }

    if (e.target.type === "file") {
      targetValue = e.target.files[0];
    } else if (targetName === "gender" && targetValue === "") {
      targetValue = null;
    } else if (
      ["year", "degree", "qualification", "institution", "alumni"].indexOf(
        targetName
      ) !== -1
    ) {
      const edu = individualType.education[0];
      edu[targetName] = targetValue;
      targetName = "education";
      targetValue = [edu];
    }
    individualType[targetName] = targetValue;
    this.setState({ individualType }, this.handleSetState(individualType));
  };

  componentDidMount = () => {
    const individualType = { ...this.state.individualType };
    const propIndividualType = this.props.individual;

    if (propIndividualType) {
      for (const key in propIndividualType) {
        individualType[key] = propIndividualType[key];
      }
    }
    let edu = individualType.education;
    if (!edu || edu.length === 0) {
      edu = [
        {
          year: "",
          qualification: "",
          degree: "",
          institution: "",
          alumni: false
        }
      ];
    }

    individualType.education = edu;

    this.setState({ individualType });
    this.getGenders();
  };

  handleSetState = individualType => {
    this.props.getIndividualType(individualType);
  };

  getGenders = () => {
    const url = GENDERS_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ genders: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertType: "Default",
      alertColor: "danger",
      alertMessage: message
    });
  };

  render() {
    const {
      firstName,
      lastName,
      gender,
      dob,
      education,
      experience,
      references
    } = this.state.individualType;

    let qualification, degree, year, institution, alumni;
    if (education && education.length > 0) {
      qualification = education[0].qualification;
      degree = education[0].degree;
      year = education[0].year;
      institution = education[0].institution;
      alumni = education[0].alumni;
    }

    const { inputError, downloadDocument, tabsError } = this.props;

    const { genders } = this.state;

    if (genders.length === 0) {
      return null;
    }

    const photoMetainfo = this.state.individualType.photo;

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <Tabs>
              <TabList>
                <Tab>
                  {tabsError && tabsError.individual ? (
                    <i className="far fa-times-circle align-middle react-tabs-error" />
                  ) : null}
                  &nbsp;Individual Info
                </Tab>
                <Tab>
                  {tabsError && tabsError.contact ? (
                    <i className="far fa-times-circle align-middle react-tabs-error" />
                  ) : null}
                  &nbsp;Contact
                </Tab>
                <Tab>
                  {tabsError && tabsError.incubateeProfile ? (
                    <i className="far fa-times-circle align-middle react-tabs-error" />
                  ) : null}
                  &nbsp;Incubatee Profile
                </Tab>
                <Tab>Patents</Tab>
              </TabList>

              <TabPanel>
                <div className="card-body mb-3">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          First Name
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["firstName"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={this.handleNameInputchange}
                          />
                          <div className="invalid-feedback">
                            Please enter first name *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Last Name
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={this.handleNameInputchange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Date of Birth
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <DatePicker
                            value={getDisplayDate(convertNumberToDate(dob))}
                            selected={dob ? convertNumberToDate(dob) : null}
                            onChange={this.handleDateChange.bind(this, "dob")}
                            utcOffset={0}
                            placeholderText="dd-mm-yyyy"
                            className={
                              inputError["dob"]
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />

                          {inputError["dob"] ? (
                            <div className="auto-lookup-empty">
                              Please select dob *.
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                          Gender
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <select
                            className={
                              inputError["gender"]
                                ? "custom-select custom-select-sm is-invalid"
                                : "custom-select custom-select-sm"
                            }
                            name="gender"
                            value={gender !== null ? gender : ""}
                            onChange={this.handleInputChange}
                          >
                            <option value="">Select...</option>
                            {genders.map((g, i) => (
                              <option key={i} value={g.value}>
                                {g.label}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            Please select gender *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Experience
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["experience"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="experience"
                            name="experience"
                            value={experience}
                            onChange={this.handleInputChange}
                          />
                          <div className="invalid-feedback">
                            Please enter experience *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                          References
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["references"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="References"
                            name="references"
                            value={references}
                            onChange={this.handleInputChange}
                          />
                          <div className="invalid-feedback">
                            Please enter references *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Edu Qualification
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <select
                            className={
                              inputError["qualification"]
                                ? "custom-select custom-select-sm is-invalid"
                                : "custom-select custom-select-sm"
                            }
                            name="qualification"
                            value={qualification !== null ? qualification : ""}
                            onChange={this.handleInputChange}
                          >
                            <option value="">Select...</option>
                            {EDU_QUALIFICATION_TYPES.map((opt, i) => (
                              <option key={i} value={opt.key}>
                                {opt.value}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            Please select Education qualification *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Degree
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["degree"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="Education"
                            name="degree"
                            value={degree}
                            onChange={this.handleInputChange}
                          />
                          <div className="invalid-feedback">
                            Please enter degree *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                          Year of Passing
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <select
                            className={
                              inputError["year"]
                                ? "custom-select custom-select-sm is-invalid"
                                : "custom-select custom-select-sm"
                            }
                            name="year"
                            value={year !== null ? year : ""}
                            onChange={this.handleInputChange}
                          >
                            <option value="">Select...</option>
                            {YEARS.map(yyyy => (
                              <option key={yyyy} value={yyyy}>
                                {yyyy}
                              </option>
                            ))}
                          </select>

                          <div className="invalid-feedback">
                            Please enter year of passing *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                          Institution
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            value={institution}
                            className={
                              inputError["institution"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder=""
                            name="institution"
                            onChange={this.handleInputChange}
                          />
                          <div className="invalid-feedback">
                            Please enter institution name *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                          PSG Alumni
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="alumni"
                              name="alumni"
                              checked={alumni}
                              onChange={this.handleInputChange}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="alumni"
                            />
                            Yes
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                          Photo
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="file"
                            name="indvPhoto"
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    {photoMetainfo ? (
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <div className=" col-12 edit-page-label">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={downloadDocument.bind(
                                this,
                                photoMetainfo
                              )}
                            >
                              Download Photo
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <Contact {...this.props} />
              </TabPanel>

              <TabPanel>
                <IncubateeProfile {...this.props} />
              </TabPanel>

              <TabPanel>
                <Patent {...this.props} />
              </TabPanel>
              {/* <TabPanel>
                <OnboardFunding {...this.props} />
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default IndividualType;
