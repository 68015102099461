import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import CreatableSelect from "react-select/lib/Creatable";
import { Formik, FieldArray, Form, Field, ErrorMessage } from "formik";
import {
  INCUBATEE_STAGES,
  INCUBATION_SECTORS,
  SUB_SECTORS,
  RESIDENTIAL_STATUS,
  INCUBATION_CATEGORY_TYPES
} from "../../common/IncubateeProfileConstants";
import { GRADUATION_STATUS } from "../../common/LookupConstants";
import Select from "react-select";
import { getDisplayDate } from "../../lease/DateUtil";
import { downloadDocument } from "../../common/Util";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

const Patents = props => {
  const { values, errors, touched, setFieldValue, initialValues } = props;
  const patentErrors = errors.patents || [];
  const patentTouched = touched.patents || [];

  const graduated = initialValues.graduationStatus === "GRADUATED";

  const disabledField = graduated
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name="patents"
      render={({ insert, remove, push }) => {
        return (
          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title   mb-0">Patents</h5>
            </div>
            <div className="card-body">
              {values.patents &&
                values.patents.map((patent, index) => {
                  const error = patentErrors[index] || {};
                  const touch = patentTouched[index] || {};

                  return (
                    <Fragment key={index}>
                      <div className="card mb-4">
                        <div className="card-header edit-page-subsection-title border-0">
                          <button
                            type="button"
                            className="close pull-right mt-1"
                            aria-label="Close"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Remove Patent ${index + 1}`}
                            onClick={() => remove(index)}
                            style={{ color: "#ff0000" }}
                            {...disabledField}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <h6 className="card-title  mb-0">
                            Patent {index + 1}
                          </h6>
                        </div>
                        <div className="card-body mb-3">
                          <div className="form-group row mb-2">
                            <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label-required">
                              Title of the innovation
                            </label>
                            <div className="col-lg-4">
                              <Field
                                type="text"
                                className={`form-control form-control-sm ${
                                  getError(touch, error, "titleOfInnovation")
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name={`patents[${index}].titleOfInnovation`}
                                value={patent.titleOfInnovation || ""}
                                {...disabledField}
                              />
                              <ErrorMessage
                                component="div"
                                name={`patents[${index}].titleOfInnovation`}
                                className="invalid-feedback mb-2"
                              />
                            </div>
                          </div>

                          <div className="form-group row mb-2">
                            <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mb-2">
                              Application Number
                            </label>
                            <div className="col-lg-4">
                              <Field
                                type="text"
                                className={`form-control form-control-sm ${
                                  getError(touch, error, "applicationNumber")
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name={`patents[${index}].applicationNumber`}
                                value={patent.applicationNumber || ""}
                                {...disabledField}
                              />
                              <ErrorMessage
                                component="div"
                                name={`patents[${index}].applicationNumber`}
                                className="invalid-feedback mb-2"
                              />
                            </div>
                            <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mb-2">
                              Application Filing Date
                            </label>
                            <div className="col-lg-4">
                              <DatePicker
                                value={getDisplayDate(
                                  patent.applicationFilingDate
                                )}
                                selected={patent.applicationFilingDate || null}
                                onChange={date =>
                                  setFieldValue(
                                    `patents[${index}].applicationFilingDate`,
                                    date
                                  )
                                }
                                utcOffset={0}
                                placeholderText="dd-mm-yyyy"
                                className={`form-control form-control-sm ${
                                  getError(
                                    touch,
                                    error,
                                    "applicationFilingDate"
                                  )
                                    ? "is-invalid"
                                    : ""
                                }`}
                                isClearable={true}
                                isDisabled={graduated}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                              <ErrorMessage
                                component="div"
                                name={`patents[${index}].applicationFilingDate`}
                                className="invalid-feedback mb-2"
                              />
                            </div>

                            <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mb-2">
                              Field Of Invention
                            </label>
                            <div className="col-lg-4">
                              <Field
                                type="text"
                                className={`form-control form-control-sm ${
                                  getError(touch, error, "fieldOfInvention")
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name={`patents[${index}].fieldOfInvention`}
                                value={patent.fieldOfInvention || ""}
                                {...disabledField}
                              />
                              <ErrorMessage
                                component="div"
                                name={`patents[${index}].fieldOfInvention`}
                                className="invalid-feedback mb-2"
                              />
                            </div>

                            <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mb-2">
                              Classification (IPC)
                            </label>
                            <div className="col-lg-4">
                              <Field
                                type="text"
                                className={`form-control form-control-sm ${
                                  getError(touch, error, "classificationCode")
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name={`patents[${index}].classificationCode`}
                                value={patent.classificationCode || ""}
                                {...disabledField}
                              />
                              <ErrorMessage
                                component="div"
                                name={`patents[${index}].classificationCode`}
                                className="invalid-feedback mb-2"
                              />
                            </div>

                            <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mb-2">
                              Country
                            </label>
                            <div className="col-lg-4">
                              <Field
                                component="select"
                                className={`form-control form-control-sm ${
                                  getError(touch, error, "country")
                                    ? "is-invalid"
                                    : ""
                                }`}
                                value={patent.country}
                                name={`patents[${index}].country`}
                                {...disabledField}
                              >
                                <option value="">Select...</option>
                                {props.countries.map((country, i) => (
                                  <option key={i} value={country.countryCode}>
                                    {country.name}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                component="div"
                                name={`patents[${index}].country`}
                                className="invalid-feedback mb-2"
                              />
                            </div>

                            <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mb-2">
                              Patent Status
                            </label>
                            <div className="col-lg-4">
                              <Field
                                type="text"
                                className={`form-control form-control-sm ${
                                  getError(touch, error, "patentStatus")
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name={`patents[${index}].patentStatus`}
                                value={patent.patentStatus || ""}
                                {...disabledField}
                              />
                              <ErrorMessage
                                component="div"
                                name={`patents[${index}].patentStatus`}
                                className="invalid-feedback mb-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}

              <div className="row">
                <div className="col-2">
                  <button
                    className="btn btn-sm btn-outline-info"
                    type="button"
                    onClick={() =>
                      push({
                        titleOfInnovation: "",
                        country: "",
                        applicationNumber: "",
                        applicationFilingDate: null,
                        fieldOfInvention: "",
                        classificationCode: "",
                        patentStatus: ""
                      })
                    }
                    {...disabledField}
                  >
                    Add More
                  </button>
                </div>
                {values.patents && values.patents.length === 0 ? (
                  <div className="col-6 flex align-self-center">
                    <span className="ml-2 font-weight-bold">No Patents !</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

const ProductsAndService = props => {
  const { values, errors, touched, setFieldValue, initialValues } = props;

  const productErrors = errors.products || [];
  const productTouched = touched.products || [];

  const products = values.products || [];

  const graduated = initialValues.graduationStatus === "GRADUATED";

  const disabledField = graduated
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name="products"
      render={({ push, remove }) => {
        return (
          <Fragment>
            {!products.length ? <div>Errored</div> : null}

            {/* Products and Services */}
            <div className="card border-0 mt-3">
              <div className="edit-page-section-header-text">
                <h5 className="h6 card-title mb-0">Products and Services </h5>
              </div>
              <div className="card-body">
                {products.map((product, index) => {
                  const productCategory = product.productCategory || "";
                  const subCategoryOptions = productCategory.children || [];

                  const disabledFieldSub =
                    productCategory === "" ? { isDisabled: "isDisabled" } : {};

                  const prdTouch = productTouched[index] || {};
                  const prdError = productErrors[index] || {};

                  return (
                    <Fragment key={index}>
                      <div
                        className={
                          products.length - 1 === index ? "row" : "row mb-5"
                        }
                      >
                        <div className="col-lg-8">
                          <div className="form-group row mb-2">
                            <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                              Product Category
                            </label>
                            <div className="col-lg-9">
                              <CreatableSelect
                                isClearable
                                isDisabled={graduated}
                                onChange={productCategory => {
                                  if (
                                    productCategory &&
                                    productCategory.__isNew__
                                  ) {
                                    const newMetaData = {
                                      type: "ProductsCategory",
                                      label: productCategory.label
                                    };
                                    const updateName = `products[${index}].productCategory`;
                                    props.createMetaDataForProducts(
                                      newMetaData,
                                      setFieldValue,
                                      updateName
                                    );
                                  } else if (productCategory) {
                                    setFieldValue(
                                      `products[${index}].productCategory`,
                                      productCategory
                                    );
                                  } else {
                                    setFieldValue(
                                      `products[${index}].productCategory`,
                                      ""
                                    );
                                  }
                                  setFieldValue(
                                    `products[${index}].productSubCategory`,
                                    ""
                                  );
                                }}
                                options={props.productMetadata}
                                value={product.productCategory || ""}
                              />
                              <small>Type to create or select </small>
                            </div>
                          </div>
                          <div className="form-group row mb-2">
                            <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                              Sub Category
                            </label>
                            <div className="col-lg-9">
                              <CreatableSelect
                                isClearable
                                {...disabledFieldSub}
                                onChange={productSubCategory => {
                                  if (
                                    productSubCategory &&
                                    productSubCategory.__isNew__
                                  ) {
                                    const newMetaData = {
                                      type: "ProductsSubCategory",
                                      label: productSubCategory.label,
                                      parentKey: productCategory._id
                                    };
                                    const updateName = `products[${index}].productSubCategory`;
                                    props.createMetaDataForProducts(
                                      newMetaData,
                                      setFieldValue,
                                      updateName
                                    );
                                  } else if (productSubCategory) {
                                    props.setFieldValue(
                                      `products[${index}].productSubCategory`,
                                      productSubCategory
                                    );
                                  } else {
                                    props.setFieldValue(
                                      `products[${index}].productSubCategory`,
                                      ""
                                    );
                                  }
                                }}
                                options={subCategoryOptions}
                                value={product.productSubCategory || ""}
                              />
                              <small>Type to create or select </small>
                            </div>
                          </div>
                          <div className="form-group row mb-2">
                            <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                              Product Name
                            </label>
                            <div className="col-lg-9">
                              <Field
                                name={`products[${index}].name`}
                                type="text"
                                className={`form-control form-control-sm ${
                                  getError(prdTouch, prdError, "name")
                                    ? "is-invalid"
                                    : ""
                                }`}
                                value={product.name}
                                {...disabledField}
                              />
                              <ErrorMessage
                                component="div"
                                name={`products[${index}].name`}
                                className="invalid-feedback mb-2"
                              />
                            </div>
                          </div>
                          <div className="form-group row mb-2">
                            <label className="col-lg-3 col-form-label col-form-label-sm edit-page-label mt-1">
                              Product Description
                            </label>
                            <div className="col-lg-9">
                              <Field
                                component="textarea"
                                className={`form-control form-control-sm ${
                                  getError(prdTouch, prdError, "description")
                                    ? "is-invalid"
                                    : ""
                                }`}
                                rows="10"
                                style={{ resize: "none" }}
                                name={`products[${index}].description`}
                                value={product.description || ""}
                                {...disabledField}
                              />
                              <ErrorMessage
                                component="div"
                                name={`products[${index}].description`}
                                className="invalid-feedback mb-2"
                              />
                            </div>
                          </div>
                        </div>
                        {index !== 0 && (
                          <div className="col-lg-4">
                            <button
                              className="btn btn-sm btn-outline-danger"
                              type="button"
                              onClick={() => remove(index)}
                              {...disabledField}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}

                <div className="row mb-2">
                  <div className="col-lg-2 " />
                  <div className="col-lg-6">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={() =>
                        push({
                          productCategory: "",
                          productSubCategory: "",
                          name: "",
                          description: ""
                        })
                      }
                      {...disabledField}
                    >
                      Add More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
};

const CustomForm = props => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    previousLocation,
    initialValues
  } = props;

  const graduated = initialValues.graduationStatus === "GRADUATED";

  const disabledField = graduated
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <Form>
      <div className="card edit-page-container mb-5">
        <div className="card-header border-bottom-0 pt-3">
          <span className="edit-page-title">
            <a href={`#/${previousLocation}`} className="mr-2">
              <i className="fas fa-arrow-left" />
            </a>
            {values.name || "Incubatee"}
          </span>
        </div>

        <div className="card-body p-0 mt-2">
          {/* Incubatee Profile */}
          <div className="card border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Incubatee Profile </h5>
            </div>
            <div className="card-body">
              <div className="row mb-2">
                {/* Business Model */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Business Model
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "businessModel")
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Business Model"
                        name="businessModel"
                        value={values.businessModel || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="businessModel"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Incubatee Stage */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Incubatee Stage
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "incubateeStage")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="incubateeStage"
                        values={values.incubateeStage || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {INCUBATEE_STAGES.map((opt, i) => (
                          <option key={`${i}`} value={opt.key}>
                            {opt.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="incubateeStage"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Incubation Sector */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Incubation Sector
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "incubationSector")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="incubationSector"
                        values={values.incubationSector || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {INCUBATION_SECTORS.map((opt, i) => (
                          <option key={`${i}`} value={opt.key}>
                            {opt.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="incubationSector"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Target Sector */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Target Sector
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "targetSector")
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Target Sector"
                        name="targetSector"
                        value={values.targetSector || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="targetSector"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Sub Tech Sector */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Sub Tech Sector
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "subTechSector")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="subTechSector"
                        values={values.subTechSector || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {SUB_SECTORS.map((opt, i) => (
                          <option key={`${i}`} value={opt.key}>
                            {opt.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="subTechSector"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>
                {/* Other Sub sector */}
                {values.subTechSector === "OTHER" ? (
                  <div className="col-lg-6 mb-2">
                    <div className="form-group row mb-0">
                      <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                        Other Sub sector
                      </label>
                      <div className="col-lg-6">
                        <Field
                          type="text"
                          className={`form-control form-control-sm ${
                            getError(touched, errors, "otherSubSector")
                              ? "is-invalid"
                              : ""
                          }`}
                          name="otherSubSector"
                          values={values.otherSubSector || ""}
                          {...disabledField}
                        />

                        <ErrorMessage
                          component="div"
                          name="otherSubSector"
                          className="invalid-feedback mb-2"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <hr />
              <div className="row mb-2">
                {/* Graduation Status */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Graduation Status
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "graduationStatus")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="graduationStatus"
                        values={values.graduationStatus || ""}
                        disabled
                      >
                        <option value="">Select...</option>
                        {GRADUATION_STATUS.map((opt, i) => (
                          <option key={`${i}`} value={opt.key}>
                            {opt.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="graduationStatus"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Residential Status */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Residential Status
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "residentialStatus")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="residentialStatus"
                        values={values.residentialStatus || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {RESIDENTIAL_STATUS.map((opt, i) => (
                          <option key={`${i}`} value={opt.key}>
                            {opt.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="residentialStatus"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Incubation Category */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Incubation Category
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "incubationCategory")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="incubationCategory"
                        values={values.incubationCategory || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {INCUBATION_CATEGORY_TYPES.map((opt, i) => (
                          <option key={`${i}`} value={opt.key}>
                            {opt.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="incubationCategory"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Relationship Manager */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Relationship Manager
                    </label>
                    <div className="col-lg-6">
                      <Select
                        isClearable
                        isDisabled={graduated}
                        options={props.relationshipManagers}
                        name="relationshipManager"
                        value={values.relationshipManager || ""}
                        onChange={selected => {
                          setFieldValue("relationshipManager", selected);
                        }}
                        {...disabledField}
                      />

                      <ErrorMessage
                        component="div"
                        name="relationshipManager"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {values.incubationCategory === "COINCUBATION" ? (
                  <Fragment>
                    {/* Anchor Incubator */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          Anchor Incubator
                        </label>
                        <div className="col-lg-6">
                          <CreatableSelect
                            isClearable
                            isDisabled={props.ancLoding}
                            isLoading={props.ancLoding}
                            onChange={incubator => {
                              props.selectAnchorIncubator(
                                incubator,
                                setFieldValue
                              );
                            }}
                            onCreateOption={newIncubatorName =>
                              props.createAnchorIncubator(
                                newIncubatorName,
                                setFieldValue
                              )
                            }
                            options={props.anchorIncubators || []}
                            value={values.anchorIncubator}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Supplementary Incubator */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          Supplementary Incubator
                        </label>
                        <div className="col-lg-6">
                          <CreatableSelect
                            isMulti
                            isClearable
                            isDisabled={props.ancLoding}
                            isLoading={props.ancLoding}
                            onChange={incubator => {
                              props.selectSupplementaryIncubator(
                                incubator,
                                setFieldValue
                              );
                            }}
                            onCreateOption={newIncubatorName =>
                              props.createSupplementoryIncubator(
                                newIncubatorName,
                                values,
                                setFieldValue
                              )
                            }
                            options={props.supplIncubators || []}
                            value={values.supplIncubators}
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : null}
              </div>

              <hr />

              <div className="row">
                <label className="col-form-label col-12 col-lg-2 col-form-label-sm edit-page-label">
                  About
                </label>
                <div className="col-12 col-lg-9">
                  <Field
                    component="textarea"
                    className={`form-control form-control-sm ${
                      getError(touched, errors, "about") ? "is-invalid" : ""
                    }`}
                    name="about"
                    rows="3"
                    placeholder="Write something about your Business"
                    values={values.about || ""}
                    style={{ resize: "none", height: 200 }}
                    {...disabledField}
                  />
                  <small>Write brief about the incubatee</small>
                </div>
                {/* </div> */}
                <ErrorMessage
                  component="div"
                  name="about"
                  className="invalid-feedback mb-2"
                />
              </div>

              {/* Business Plan */}
              <div className="row mb-2 mt-3">
                <div className="col-lg-12 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mt-1">
                      Business Plan
                    </label>
                    <div className="col-lg-6">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="uploadBusinessPlan"
                            aria-describedby="uploadBusinessPlan"
                            name="uploadBusinessPlan"
                            onChange={e => {
                              const file = e.target.files[0];
                              if (file) {
                                setFieldValue("uploadBusinessPlan", file);
                              }
                            }}
                            {...disabledField}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="uploadBusinessPlan"
                          >
                            {values.uploadBusinessPlan
                              ? values.uploadBusinessPlan.name
                              : "Choose file"}
                          </label>
                        </div>
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-outline-info"
                            type="button"
                            onClick={e =>
                              setFieldValue("uploadBusinessPlan", "")
                            }
                            {...disabledField}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      <small className="text-muted">Attachement only</small>
                    </div>
                    <div className="col-lg-2">
                      {values.businessPlan && values.businessPlan._id ? (
                        <button
                          type="button"
                          className="btn btn-info btn-sm mt-1"
                          onClick={() =>
                            downloadDocument(
                              values.businessPlan.link,
                              msg => window.alert(msg),
                              values.businessPlan.filename
                            )
                          }
                        >
                          <i className="fas fa-download mr-1" /> Business Plan
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Patents */}
          <Patents {...props} />

          {/* ProductsAndService */}
          <ProductsAndService {...props} />
        </div>
        <div className="card-footer text-muted mt-5 p-4">
          {/* Update button */}
          <div className="row no-gutters">
            <div className="col-12 text-center">
              {graduated ? null : (
                <button
                  className="btn btn-sm btn-primary ml-md-5"
                  type="submit"
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const IncubateeProfileForm = props => {
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={props.incubateeProfile}
        validationSchema={props.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.handleSubmit(values);
        }}
        render={renderProps => <CustomForm {...renderProps} {...props} />}
      />
    </div>
  );
};

export default IncubateeProfileForm;
