import React, { useEffect, Fragment } from "react";
import { Field } from "formik";
import PromoterExportSelectOptions from "./PromoterExportSelectOptions";

function ExportSelectOptions(props) {
  const {
    values,
    setFieldValue,
    individualOnly,
    organizationOnly,
    isSelected,
    errorMsg
  } = props;

  function handleBasicInfoSelectAll(e) {
    const checked = e.target.checked;
    const basicInfoFields = {
      name: checked,
      dob: checked,
      gender: checked,
      experience: checked,
      references: checked,
      website: checked,
      domainOperation: checked,
      legalStatus: checked,
      estYear: checked,
      currentEmployeeCount: checked,
      femaleEmployeeCount: checked,
      subsidiary: checked,
      parentCompany: checked
    };
    setFieldValue("selectOptions.basicInfoFields", basicInfoFields);
    setFieldValue("selectOptions.basicInfo", checked);
  }

  function handleEduSelectAll(e) {
    const checked = e.target.checked;
    const eduInfoFields = {
      qualification: checked,
      degree: checked,
      institution: checked,
      alumni: checked,
      year: checked,
      branch: checked
    };
    setFieldValue("selectOptions.educationFields", eduInfoFields);
    setFieldValue("selectOptions.education", checked);
  }

  function handleContactSelectAll(e) {
    const checked = e.target.checked;
    const contactsFields = {
      mobile: checked,
      email: checked,
      address: checked
    };
    setFieldValue("selectOptions.contactsFields", contactsFields);
    setFieldValue("selectOptions.contacts", checked);
  }

  function handleIdDocSelectAll(e) {
    const checked = e.target.checked;
    const idDocFields = {
      pan: checked,
      aadhar: checked,
      gstn: checked
    };
    setFieldValue("selectOptions.idDocsFields", idDocFields);
    setFieldValue("selectOptions.idDocs", checked);
  }

  function handleMetricsSelectAll(e) {
    const checked = e.target.checked;
    const metricsFields = {
      totalEmployeeCount: checked,
      femaleEmployeeCount: checked,
      updatedAt: checked
    };
    setFieldValue("selectOptions.metricsFields", metricsFields);
    setFieldValue("selectOptions.metrics", checked);
  }

  function handleProfileSelectAll(e) {
    const checked = e.target.checked;
    const profileFields = {
      incubateeType: checked,
      incubationDate: checked,
      graduationStatus: checked,
      graduationDate: checked,
      incubateeSector: checked,
      subTechSector: checked,
      incubateeStage: checked,
      residentialStatus: checked,
      incubationCategory: checked,
      relationshipManager: checked
    };
    setFieldValue("selectOptions.profileFields", profileFields);
    setFieldValue("selectOptions.profile", checked);
  }
  function handlePatentSelectAll(e) {
    const checked = e.target.checked;
    const patentFields = {
      titleOfInnovation: checked,
      applicationNumber: checked,
      applicationFilingDate: checked,
      patentStatus: checked,
      fieldOfInvention: checked,
      classification: checked,
      country: checked
    };
    setFieldValue("selectOptions.patentsFields", patentFields);
    setFieldValue("selectOptions.patents", checked);
  }
  function handleProductsSelectAll(e) {
    const checked = e.target.checked;
    const productsServicesFields = {
      name: checked,
      description: checked,
      category: checked,
      subCategory: checked
    };
    setFieldValue(
      "selectOptions.productsServicesFields",
      productsServicesFields
    );
    setFieldValue("selectOptions.productsServices", checked);
  }

  function handleSelectAll(e) {
    const checked = e.target.checked;
    const individualChecked = individualOnly && e.target.checked;
    const organizationChecked = organizationOnly && e.target.checked;

    const selectOptions = {
      selectIncubatee: checked,
      profile: checked,
      profileFields: {
        incubateeType: checked,
        incubationDate: checked,
        graduationStatus: checked,
        graduationDate: checked,
        incubateeSector: checked,
        subTechSector: checked,
        incubateeStage: checked,
        residentialStatus: checked,
        incubationCategory: checked,
        relationshipManager: checked
      },
      patents: checked,
      patentsFields: {
        titleOfInnovation: checked,
        applicationNumber: checked,
        applicationFilingDate: checked,
        patentStatus: checked,
        fieldOfInvention: checked,
        classification: checked,
        country: checked
      },
      productsServices: checked,
      productsServicesFields: {
        name: checked,
        description: checked,
        category: checked,
        subCategory: checked
      },
      basicInfoFields: {
        dob: individualChecked,
        gender: individualChecked,
        experience: individualChecked,
        references: checked,
        website: organizationChecked,
        domainOperation: organizationChecked,
        legalStatus: organizationChecked,
        estYear: organizationChecked,
        subsidiary: organizationChecked,
        parentCompany: organizationChecked
      },
      educationFields: {
        qualification: individualChecked,
        degree: individualChecked,
        institution: individualChecked,
        alumni: individualChecked,
        year: individualChecked,
        branch: individualChecked
      },
      financialsFields: { annualTurnover: organizationChecked },
      contactsFields: { mobile: checked, email: checked, address: checked },
      idDocsFields: { aadhar: checked, pan: checked, gstn: checked },
      metricsFields: {
        totalEmployeeCount: organizationChecked,
        femaleEmployeeCount: organizationChecked,
        updatedAt: organizationChecked
      },
      metrics: checked,
      basicInfo: checked,
      education: individualChecked,
      contacts: checked,
      financials: checked,
      idDocs: checked,
      promoters: checked,
      promotersFields: {
        idDocs: checked,
        basicInfo: checked,
        contacts: checked,
        education: checked,
        basicInfoFields: {
          founders: checked,
          designation: checked,
          shareholding: checked,
          dob: checked,
          gender: checked,
          experience: checked,
          references: checked
        },
        contactsFields: { mobile: checked, email: checked, address: checked },
        idDocsFields: { aadhar: checked, pan: checked },
        educationFields: {
          qualification: checked,
          degree: checked,
          institution: checked,
          alumni: checked,
          year: checked,
          branch: checked
        }
      }
    }; // }, //   shareholding: organizationChecked //   designation: organizationChecked, //   isFounder: organizationChecked, // promotersFields: { // promoters: checked,
    setFieldValue("selectOptions", selectOptions);
    setFieldValue("selectAll", checked);
  }
  function resetAll() {
    const selectOptions = {
      profile: false,
      profileFields: {
        incubateeType: false,
        incubationDate: false,
        graduationStatus: false,
        graduationDate: false,
        incubateeSector: false,
        subTechSector: false,
        incubateeStage: false,
        residentialStatus: false,
        incubationCategory: false,
        relationshipManager: false
      },
      patents: false,
      patentsFields: {
        titleOfInnovation: false,
        applicationNumber: false,
        applicationFilingDate: false,
        patentStatus: false,
        fieldOfInvention: false,
        classification: false,
        country: false
      },
      productsServices: false,
      productsServicesFields: {
        name: false,
        description: false,
        category: false,
        subCategory: false
      },
      basicInfoFields: {
        dob: false,
        gender: false,
        experience: false,
        references: false,
        website: false,
        domainOperation: false,
        legalStatus: false,
        estYear: false,
        subsidiary: false,
        parentCompany: false
      },
      educationFields: {
        qualification: false,
        degree: false,
        institution: false,
        alumni: false,
        year: false,
        branch: false
      },
      financialsFields: {
        // date: false, // }, //   shareholding: false //   designation: false, //   isFounder: false, // promotersFields: {
        // authorizedCapital: false,
        annualTurnover: false
      },
      contactsFields: { mobile: false, email: false, address: false },
      idDocsFields: { aadhar: false, pan: false, gstn: false },
      metricsFields: {
        totalEmployeeCount: false,
        femaleEmployeeCount: false,
        updatedAt: false
      },
      promoters: false,
      promotersFields: {
        idDocs: false,
        basicInfo: false,
        contacts: false,
        education: false,
        basicInfoFields: {
          founders: false,
          designation: false,
          shareholding: false,
          dob: false,
          gender: false,
          experience: false,
          references: false
        },
        contactsFields: { mobile: false, email: false, address: false },
        idDocsFields: { aadhar: false, pan: false },
        educationFields: {
          qualification: false,
          degree: false,
          institution: false,
          alumni: false,
          year: false,
          branch: false
        }
      },
      metrics: false,
      basicInfo: false,
      education: false,
      contacts: false,
      financials: false,
      idDocs: false
    }; // financialYear: false // promoters: false,
    setFieldValue("selectOptions", selectOptions);
    setFieldValue("selectOptions.selectAll", false);
  }

  useEffect(
    () => {
      resetAll();
    },
    [values.incubateeType]
  );

  const basicInfoFields = values.selectOptions.basicInfoFields;
  const educationFields = values.selectOptions.educationFields;
  const contactsFields = values.selectOptions.contactsFields;
  const idDocFields = values.selectOptions.idDocsFields;
  const metricsFields = values.selectOptions.metricsFields;
  const financialsFields = values.selectOptions.financialsFields;
  const profileFields = values.selectOptions.profileFields;
  const patentsFields = values.selectOptions.patentsFields;
  const productsServicesFields = values.selectOptions.productsServicesFields;
  const promotersFields = values.selectOptions.promotersFields;

  return (
    <>
      {/* <pre>{JSON.stringify(values.selectOptions, null, 2)}</pre> */}

      <div className="row">
        <div className="col-12 mb-3 edit-page-section-header-text">
          Export Field Options
        </div>
      </div>

      {!isSelected && errorMsg ? (
        <div className="alert alert-danger">
          Please select atleast one export field option
        </div>
      ) : null}

      <div className="row ml-3">
        <div className="col-md-6 ">
          <div className="form-group row ">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="selectAll"
                name="selectOptions.selectAll"
                value={values.selectOptions.selectAll}
                checked={values.selectOptions.selectAll}
                onChange={handleSelectAll}
              />
              <label className="custom-control-label" htmlFor="selectAll">
                <strong>Select All Fields</strong>
              </label>
            </div>
          </div>

          {/* Basic Info */}
          <Fragment>
            <div className="form-group row mt-3 mb-3">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="basicInfo"
                  name="selectOptions.basicInfo"
                  value={values.selectOptions.basicInfo}
                  checked={values.selectOptions.basicInfo}
                  onChange={handleBasicInfoSelectAll}
                />
                <label className="custom-control-label" htmlFor="basicInfo">
                  <strong> Basic information</strong>
                </label>
              </div>
            </div>

            {individualOnly && (
              <Fragment>
                <div className="form-group row mb-0 ml-1">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="dob"
                      name="dob"
                      value={basicInfoFields.dob}
                      checked={basicInfoFields.dob}
                      onChange={e =>
                        setFieldValue(
                          "selectOptions.basicInfoFields.dob",
                          e.target.checked
                        )
                      }
                    />
                    <label className="custom-control-label" htmlFor="dob">
                      Date Of birth (Individual)
                    </label>
                  </div>
                </div>

                <div className="form-group row mb-0 ml-1">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="gender"
                      name="gender"
                      value={basicInfoFields.gender}
                      checked={basicInfoFields.gender}
                      onChange={e =>
                        setFieldValue(
                          "selectOptions.basicInfoFields.gender",
                          e.target.checked
                        )
                      }
                    />
                    <label className="custom-control-label" htmlFor="gender">
                      Gender (Individual)
                    </label>
                  </div>
                </div>

                <div className="form-group row mb-0 ml-1">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="experience"
                      name="experience"
                      value={basicInfoFields.experience}
                      checked={basicInfoFields.experience}
                      onChange={e =>
                        setFieldValue(
                          "selectOptions.basicInfoFields.experience",
                          e.target.checked
                        )
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="experience"
                    >
                      Experience (Individual)
                    </label>
                  </div>
                </div>
              </Fragment>
            )}

            {organizationOnly && (
              <Fragment>
                <div className="form-group row mb-0 ml-1">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="website"
                      name="website"
                      value={basicInfoFields.website}
                      checked={basicInfoFields.website}
                      onChange={e =>
                        setFieldValue(
                          "selectOptions.basicInfoFields.website",
                          e.target.checked
                        )
                      }
                    />
                    <label className="custom-control-label" htmlFor="website">
                      Website (Organization)
                    </label>
                  </div>
                </div>
                <div className="form-group row mb-0 ml-1">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="domainOperation"
                      name="domainOperation"
                      value={basicInfoFields.domainOperation}
                      checked={basicInfoFields.domainOperation}
                      onChange={e =>
                        setFieldValue(
                          "selectOptions.basicInfoFields.domainOperation",
                          e.target.checked
                        )
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="domainOperation"
                    >
                      Domain (Organization)
                    </label>
                  </div>
                </div>

                <div className="form-group row mb-0 ml-1">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="estYear"
                      name="estYear"
                      value={basicInfoFields.estYear}
                      checked={basicInfoFields.estYear}
                      onChange={e =>
                        setFieldValue(
                          "selectOptions.basicInfoFields.estYear",
                          e.target.checked
                        )
                      }
                    />
                    <label className="custom-control-label" htmlFor="estYear">
                      Establishment Year (Organization)
                    </label>
                  </div>
                </div>

                <div className="form-group row mb-0 ml-1">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="subsidiary"
                      name="subsidiary"
                      value={basicInfoFields.subsidiary}
                      checked={basicInfoFields.subsidiary}
                      onChange={e =>
                        setFieldValue(
                          "selectOptions.basicInfoFields.subsidiary",
                          e.target.checked
                        )
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="subsidiary"
                    >
                      Subsidiary (Organization)
                    </label>
                  </div>
                </div>

                <div className="form-group row mb-0 ml-1">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="parentCompany"
                      name="parentCompany"
                      value={basicInfoFields.parentCompany}
                      checked={basicInfoFields.parentCompany}
                      onChange={e =>
                        setFieldValue(
                          "selectOptions.basicInfoFields.parentCompany",
                          e.target.checked
                        )
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="parentCompany"
                    >
                      Parent Company (Organization)
                    </label>
                  </div>
                </div>
              </Fragment>
            )}

            <div className="form-group row mb-0 ml-1">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="references1"
                  name="references"
                  value={basicInfoFields.references}
                  checked={basicInfoFields.references}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.basicInfoFields.references",
                      e.target.checked
                    )
                  }
                />
                <label className="custom-control-label" htmlFor="references1">
                  References
                </label>
              </div>
            </div>
            {organizationOnly && (
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="legalStatus"
                    name="legalStatus"
                    value={basicInfoFields.legalStatus}
                    checked={basicInfoFields.legalStatus}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.basicInfoFields.legalStatus",
                        e.target.checked
                      )
                    }
                  />
                  <label className="custom-control-label" htmlFor="legalStatus">
                    Legal Status (Organization)
                  </label>
                </div>
              </div>
            )}
          </Fragment>

          {/* Contact Info */}
          <Fragment>
            <div className="form-group row mt-3 mb-3">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="contacts"
                  name="selectOptions.contacts"
                  value={values.selectOptions.contacts}
                  checked={values.selectOptions.contacts}
                  onClick={handleContactSelectAll}
                />
                <label className="custom-control-label" htmlFor="contacts">
                  <strong>Contact Information</strong>
                </label>
              </div>
            </div>
            <div className="form-group row mb-0 ml-1">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="contactsFields.mobile"
                  name="contactsFields.mobile"
                  value={contactsFields.mobile}
                  checked={contactsFields.mobile}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.contactsFields.mobile",
                      e.target.checked
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="contactsFields.mobile"
                >
                  Phone
                </label>
              </div>
            </div>
            <div className="form-group row mb-0 ml-1">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="contactsFields.email"
                  name="contactsFields.email"
                  value={contactsFields.email}
                  checked={contactsFields.email}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.contactsFields.email",
                      e.target.checked
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="contactsFields.email"
                >
                  Email
                </label>
              </div>
            </div>
            <div className="form-group row mb-0 ml-1">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="contactsFields.address"
                  name="contactsFields.address"
                  value={contactsFields.address}
                  checked={contactsFields.address}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.contactsFields.address",
                      e.target.checked
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="contactsFields.address"
                >
                  Address
                </label>
              </div>
            </div>
          </Fragment>

          {/* Incubatee */}
          <Fragment>
            <div className="form-group row mb-0 mt-3">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="profile"
                  name="profile"
                  value={values.selectOptions.profile}
                  checked={values.selectOptions.profile}
                  onChange={handleProfileSelectAll}
                />
                <label className="custom-control-label" htmlFor="profile">
                  <strong>Incubatee Profile</strong>
                </label>
              </div>
            </div>
            <div className="form-group row ml-1 mb-0 mt-3">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="incubateeType"
                  name="incubateeType"
                  value={profileFields.incubateeType}
                  checked={profileFields.incubateeType}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.incubateeType",
                      e.target.checked
                    )
                  }
                />

                <label className="custom-control-label" htmlFor="incubateeType">
                  Incubatee Type
                </label>
              </div>
            </div>
            <div className="form-group row ml-1 mb-0">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="incubationDate"
                  name="incubationDate"
                  value={profileFields.incubationDate}
                  checked={profileFields.incubationDate}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.incubationDate",
                      e.target.checked
                    )
                  }
                />

                <label
                  className="custom-control-label"
                  htmlFor="incubationDate"
                >
                  Incubation Date
                </label>
              </div>
            </div>
            <div className="form-group row ml-1 mb-0">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="graduationStatus"
                  name="graduationStatus"
                  value={profileFields.graduationStatus}
                  checked={profileFields.graduationStatus}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.graduationStatus",
                      e.target.checked
                    )
                  }
                />

                <label
                  className="custom-control-label"
                  htmlFor="graduationStatus"
                >
                  Graduation Status
                </label>
              </div>
            </div>
            <div className="form-group row ml-1 mb-0">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="graduationDate"
                  name="graduationDate"
                  value={profileFields.graduationDate}
                  checked={profileFields.graduationDate}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.graduationDate",
                      e.target.checked
                    )
                  }
                />

                <label
                  className="custom-control-label"
                  htmlFor="graduationDate"
                >
                  Graduation Date
                </label>
              </div>
            </div>
            <div className="form-group row ml-1 mb-0">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="incubateeSector"
                  name="incubateeSector"
                  value={profileFields.incubateeSector}
                  checked={profileFields.incubateeSector}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.incubateeSector",
                      e.target.checked
                    )
                  }
                />

                <label
                  className="custom-control-label"
                  htmlFor="incubateeSector"
                >
                  Incubation Sector
                </label>
              </div>
            </div>
            <div className="form-group row ml-1 mb-0">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="subTechSector"
                  name="subTechSector"
                  value={profileFields.subTechSector}
                  checked={profileFields.subTechSector}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.subTechSector",
                      e.target.checked
                    )
                  }
                />

                <label className="custom-control-label" htmlFor="subTechSector">
                  Sub Tech Sector
                </label>
              </div>
            </div>
            <div className="form-group row ml-1 mb-0">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="incubateeStage"
                  name="incubateeStage"
                  value={profileFields.incubateeStage}
                  checked={profileFields.incubateeStage}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.incubateeStage",
                      e.target.checked
                    )
                  }
                />

                <label
                  className="custom-control-label"
                  htmlFor="incubateeStage"
                >
                  Incubatee Stage
                </label>
              </div>
            </div>

            <div className="form-group row ml-1 mb-0">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="residentialStatus"
                  name="residentialStatus"
                  value={profileFields.residentialStatus}
                  checked={profileFields.residentialStatus}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.residentialStatus",
                      e.target.checked
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="residentialStatus"
                >
                  Residential Status
                </label>
              </div>
            </div>

            <div className="form-group row ml-1 mb-0">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="incubationCategory"
                  name="incubationCategory"
                  value={profileFields.incubationCategory}
                  checked={profileFields.incubationCategory}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.incubationCategory",
                      e.target.checked
                    )
                  }
                />

                <label
                  className="custom-control-label"
                  htmlFor="incubationCategory"
                >
                  Incubation Category
                </label>
              </div>
            </div>

            <div className="form-group row ml-1 mb-3">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="relationshipManager"
                  name="relationshipManager"
                  value={profileFields.relationshipManager}
                  checked={profileFields.relationshipManager}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.profileFields.relationshipManager",
                      e.target.checked
                    )
                  }
                />

                <label
                  className="custom-control-label"
                  htmlFor="relationshipManager"
                >
                  Relationship Manager
                </label>
              </div>
            </div>
          </Fragment>
          {/* Education */}
          {individualOnly && (
            <Fragment>
              <div className="form-group row mt-3 mb-3">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="education"
                    name="selectOptions.education"
                    value={values.selectOptions.education}
                    checked={values.selectOptions.education}
                    onClick={handleEduSelectAll}
                  />
                  <label className="custom-control-label" htmlFor="education">
                    <strong>Education (Individual)</strong>
                  </label>
                </div>
              </div>
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="qualification"
                    name="qualification"
                    value={educationFields.qualification}
                    checked={educationFields.qualification}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.educationFields.qualification",
                        e.target.checked
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="qualification"
                  >
                    Qualification
                  </label>
                </div>
              </div>

              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="degree"
                    name="degree"
                    value={educationFields.degree}
                    checked={educationFields.degree}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.educationFields.degree",
                        e.target.checked
                      )
                    }
                  />
                  <label className="custom-control-label" htmlFor="degree">
                    Degree
                  </label>
                </div>
              </div>
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="institution"
                    name="institution"
                    value={educationFields.institution}
                    checked={educationFields.institution}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.educationFields.institution",
                        e.target.checked
                      )
                    }
                  />
                  <label className="custom-control-label" htmlFor="institution">
                    Institution
                  </label>
                </div>
              </div>

              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="alumni"
                    name="alumni"
                    value={educationFields.alumni}
                    checked={educationFields.alumni}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.educationFields.alumni",
                        e.target.checked
                      )
                    }
                  />
                  <label className="custom-control-label" htmlFor="alumni">
                    Alumni
                  </label>
                </div>
              </div>
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="year"
                    name="year"
                    value={educationFields.year}
                    checked={educationFields.year}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.educationFields.year",
                        e.target.checked
                      )
                    }
                  />
                  <label className="custom-control-label" htmlFor="year">
                    Year
                  </label>
                </div>
              </div>
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="branch"
                    name="branch"
                    value={educationFields.branch}
                    checked={educationFields.branch}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.educationFields.branch",
                        e.target.checked
                      )
                    }
                  />
                  <label className="custom-control-label" htmlFor="branch">
                    Branch
                  </label>
                </div>
              </div>
            </Fragment>
          )}

          {/* Patents */}
          <div className="form-group row mb-0 mt-3">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="patents"
                name="patents"
                value={values.selectOptions.patents}
                checked={values.selectOptions.patents}
                onChange={handlePatentSelectAll}
              />
              <label className="custom-control-label" htmlFor="patents">
                <strong>Patents</strong>
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0 mt-3">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="titleOfInnovation"
                name="titleOfInnovation"
                value={patentsFields.titleOfInnovation}
                checked={patentsFields.titleOfInnovation}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.patentsFields.titleOfInnovation",
                    e.target.checked
                  )
                }
              />

              <label
                className="custom-control-label"
                htmlFor="titleOfInnovation"
              >
                Title Of Innovation
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="applicationNumber"
                name="applicationNumber"
                value={patentsFields.applicationNumber}
                checked={patentsFields.applicationNumber}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.patentsFields.applicationNumber",
                    e.target.checked
                  )
                }
              />

              <label
                className="custom-control-label"
                htmlFor="applicationNumber"
              >
                Application Number
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="applicationFilingDate"
                name="applicationFilingDate"
                value={patentsFields.applicationFilingDate}
                checked={patentsFields.applicationFilingDate}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.patentsFields.applicationFilingDate",
                    e.target.checked
                  )
                }
              />

              <label
                className="custom-control-label"
                htmlFor="applicationFilingDate"
              >
                Application Filing Date
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="patentStatus"
                name="patentStatus"
                value={patentsFields.patentStatus}
                checked={patentsFields.patentStatus}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.patentsFields.patentStatus",
                    e.target.checked
                  )
                }
              />

              <label className="custom-control-label" htmlFor="patentStatus">
                Patent Status
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="fieldOfInvention"
                name="fieldOfInvention"
                value={patentsFields.fieldOfInvention}
                checked={patentsFields.fieldOfInvention}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.patentsFields.fieldOfInvention",
                    e.target.checked
                  )
                }
              />

              <label
                className="custom-control-label"
                htmlFor="fieldOfInvention"
              >
                Field Of Invention
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="classification"
                name="classification"
                value={patentsFields.classification}
                checked={patentsFields.classification}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.patentsFields.classification",
                    e.target.checked
                  )
                }
              />

              <label className="custom-control-label" htmlFor="classification">
                Classification
              </label>
            </div>
          </div>
          <div className="form-group row ml-1">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="country"
                name="country"
                value={patentsFields.country}
                checked={patentsFields.country}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.patentsFields.country",
                    e.target.checked
                  )
                }
              />

              <label className="custom-control-label" htmlFor="country">
                Country
              </label>
            </div>
          </div>
          <div className="form-group row mb-3">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="productsServices"
                name="productsServices"
                value={values.selectOptions.productsServices}
                checked={values.selectOptions.productsServices}
                onChange={handleProductsSelectAll}
              />
              <label
                className="custom-control-label"
                htmlFor="productsServices"
              >
                <strong>Products and Services</strong>
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="name"
                name="name"
                value={productsServicesFields.name}
                checked={productsServicesFields.name}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.productsServicesFields.name",
                    e.target.checked
                  )
                }
              />

              <label className="custom-control-label" htmlFor="name">
                Name
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="description"
                name="description"
                value={productsServicesFields.description}
                checked={productsServicesFields.description}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.productsServicesFields.description",
                    e.target.checked
                  )
                }
              />

              <label className="custom-control-label" htmlFor="description">
                Description
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="category"
                name="category"
                value={productsServicesFields.category}
                checked={productsServicesFields.category}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.productsServicesFields.category",
                    e.target.checked
                  )
                }
              />

              <label className="custom-control-label" htmlFor="category">
                Category
              </label>
            </div>
          </div>
          <div className="form-group row ml-1 mb-0">
            <div className="custom-control custom-checkbox">
              <Field
                type="checkbox"
                className="custom-control-input"
                id="subCategory"
                name="subCategory"
                value={productsServicesFields.subCategory}
                checked={productsServicesFields.subCategory}
                onChange={e =>
                  setFieldValue(
                    "selectOptions.productsServicesFields.subCategory",
                    e.target.checked
                  )
                }
              />

              <label className="custom-control-label" htmlFor="subCategory">
                Sub Category
              </label>
            </div>
          </div>

          {/* Metrics */}
          {organizationOnly && (
            <Fragment>
              <div className="form-group row mt-3 mb-3">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="metrics"
                    name="selectOptions.metrics"
                    value={values.selectOptions.metrics}
                    checked={values.selectOptions.metrics}
                    onClick={handleMetricsSelectAll}
                  />
                  <label className="custom-control-label" htmlFor="metrics">
                    <strong>Metrics (Organization)</strong>
                  </label>
                </div>
              </div>
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="metricsFields.totalEmployeeCount"
                    name="metricsFields.totalEmployeeCount"
                    value={metricsFields.totalEmployeeCount}
                    checked={metricsFields.totalEmployeeCount}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.metricsFields.totalEmployeeCount",
                        e.target.checked
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="metricsFields.totalEmployeeCount"
                  >
                    Total employee count
                  </label>
                </div>
              </div>
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="metricsFields.femaleEmployeeCount"
                    name="metricsFields.femaleEmployeeCount"
                    value={metricsFields.femaleEmployeeCount}
                    checked={metricsFields.femaleEmployeeCount}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.metricsFields.femaleEmployeeCount",
                        e.target.checked
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="metricsFields.femaleEmployeeCount"
                  >
                    Female employee count
                  </label>
                </div>
              </div>
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="metricsFields.updatedAt"
                    name="metricsFields.updatedAt"
                    value={metricsFields.updatedAt}
                    checked={metricsFields.updatedAt}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.metricsFields.updatedAt",
                        e.target.checked
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="metricsFields.updatedAt"
                  >
                    Updated At
                  </label>
                </div>
              </div>
            </Fragment>
          )}

          {/* identification Docs */}
          <Fragment>
            <div className="form-group row mt-3 mb-3">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="idDocs"
                  name="selectOptions.idDocs"
                  value={values.selectOptions.idDocs}
                  checked={values.selectOptions.idDocs}
                  onClick={handleIdDocSelectAll}
                />
                <label className="custom-control-label" htmlFor="idDocs">
                  <strong>Identification Docs</strong>
                </label>
              </div>
            </div>
            {individualOnly && (
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="idDocsFields.aadhar"
                    name="idDocsFields.aadhar"
                    value={idDocFields.aadhar}
                    checked={idDocFields.aadhar}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.idDocsFields.aadhar",
                        e.target.checked
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="idDocsFields.aadhar"
                  >
                    Aadhar (Individual)
                  </label>
                </div>
              </div>
            )}
            <div className="form-group row mb-0 ml-1">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="idDocsFields.pan"
                  name="idDocsFields.pan"
                  value={idDocFields.pan}
                  checked={idDocFields.pan}
                  onChange={e =>
                    setFieldValue(
                      "selectOptions.idDocsFields.pan",
                      e.target.checked
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="idDocsFields.pan"
                >
                  PAN
                </label>
              </div>
            </div>

            {organizationOnly && (
              <div className="form-group row mb-0 ml-1">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="idDocsFields.gstn"
                    name="idDocsFields.gstn"
                    value={idDocFields.gstn}
                    checked={idDocFields.gstn}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.idDocsFields.gstn",
                        e.target.checked
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="idDocsFields.gstn"
                  >
                    GSTN (Organization)
                  </label>
                </div>
              </div>
            )}
          </Fragment>

          {/* Financials */}
          {organizationOnly && (
            <Fragment>
              <div className="form-group row mt-3 mb-3">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control-input"
                    id="financialsFields.annualTurnover"
                    name="financialsFields.annualTurnover"
                    value={financialsFields.annualTurnover}
                    checked={financialsFields.annualTurnover}
                    onChange={e =>
                      setFieldValue(
                        "selectOptions.financialsFields.annualTurnover",
                        e.target.checked
                      )
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="financialsFields.annualTurnover"
                  >
                    <strong>Annual Turnover</strong>
                  </label>
                </div>
              </div>
            </Fragment>
          )}
          {organizationOnly ? (
            <PromoterExportSelectOptions
              values={values}
              setFieldValue={setFieldValue}
              promotersFields={promotersFields}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ExportSelectOptions;
