import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import {
  setInputData,
  changeToINR,
  TERMINATION_REASONS,
  TERMINATION_REASONS_OBJECT
} from "../../common/LookupConstants";
import { PARTITION_TYPE_MAPPER, getSize } from "../LeaseInputForm";
import {
  getDisplayDate,
  convertNumberToDate,
  getDateAndTimeFormat,
  getLastDayPreviousMonth
} from "../DateUtil";
import { getDescription } from "../../common/Util";

class LeaseTerminationForm extends Component {
  render() {
    const { actions, handleSubmit } = this.props;
    const { stage, status } = this.props.leaseTermination;
    let renderForm = null;
    if (actions.length === 0) {
      renderForm = <ReadOnlyTerminationForm {...this.props} />;
    }
    if (actions.length > 0) {
      renderForm = <TerminationForm {...this.props} />;
    }
    return (
      <div>
        <LeaseInfo {...this.props} />

        {renderForm}

        <div className="form-group row mt-3 mb-5">
          <div className="col-sm-12 text-left">
            <h5>
              <span className="sidebar-badge badge badge-md badge-light">{`Status : ${getDescription(
                "LeaseTermination",
                stage,
                status
              )}`}</span>
            </h5>
          </div>
          <div className="col-12 text-center">
            {actions.length > 0
              ? actions.map((action, idx) => {
                  return (
                    <Fragment key={idx}>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleSubmit.bind(this, action)}
                      >
                        {action}
                      </button>
                      &nbsp;&nbsp;
                    </Fragment>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default LeaseTerminationForm;

export const LeaseInfo = props => {
  const {
    agreementDate,
    leaseStartDate,
    leaseEndDate
  } = props.leaseTermination.lease;
  const { name } = props.leaseTermination.lease.incubatee;
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="edit-page-section-header-text">Lease Information</div>
          <div className="card-body">
            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-2 col-xl-2">
                Incubatee Name
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={setInputData(name)}
                  readOnly
                />
              </div>
              <label className="col-form-label col-12 col-lg-2 col-xl-2">
                Agreement Date
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={getDisplayDate(convertNumberToDate(agreementDate))}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-2 col-xl-2">
                Lease Start Date
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={getDisplayDate(convertNumberToDate(leaseStartDate))}
                  readOnly
                />
              </div>
              <label className="col-form-label col-12 col-lg-2 col-xl-2">
                Lease End Date
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  value={getDisplayDate(convertNumberToDate(leaseEndDate))}
                  readOnly
                />
              </div>
            </div>
            <hr />
            <PaymentInfo {...props} />
            <hr />
            <PartitionInfo {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const PartitionInfo = props => {
  const { leasePartitions } = props.leaseTermination.lease;
  return (
    <Fragment>
      {leasePartitions.length > 0 ? (
        <div className="row">
          <div className="col-12 table-responsive">
            <table className="table table-bordered table-striped table-sm">
              <thead>
                <tr>
                  <th style={{ width: "14.28%" }}>Partition</th>
                  <th style={{ width: "10%" }}>Type</th>
                  <th style={{ width: "10%" }}>
                    Per Unit &nbsp;
                    <i className="fas fa-rupee-sign" aria-hidden="true" />
                  </th>
                  <th style={{ width: "15.28%" }}>Seat Count / Area</th>
                  <th style={{ width: "10.28%" }}>
                    Est.Rent &nbsp;
                    <i className="fas fa-rupee-sign" aria-hidden="true" />
                  </th>
                  <th style={{ width: "14.28%" }}>
                    Actual Rent &nbsp;
                    <i className="fas fa-rupee-sign" aria-hidden="true" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {leasePartitions.map((lp, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{lp.partition.displayName}</td>
                      <td>
                        {PARTITION_TYPE_MAPPER[lp.partition.partitionType]}
                      </td>
                      <td>
                        {lp.partition.partitionType === "SEATER"
                          ? changeToINR(lp.partition.seaterPrice)
                          : null}
                        {lp.partition.partitionType === "RENTAL"
                          ? changeToINR(lp.partition.areaPrice)
                          : null}
                      </td>
                      <td>
                        {lp.partition.partitionType === "SEATER"
                          ? lp.seatCount
                          : null}
                        {lp.partition.partitionType === "RENTAL"
                          ? lp.partition.area
                          : null}
                      </td>
                      <td>{changeToINR(lp.estimatedRent)}</td>
                      <td>{changeToINR(lp.actualRent)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export const PaymentInfo = props => {
  const { deposit, monthlyRent } = props.leaseTermination.lease;

  return (
    <div className="form-group row mb-0">
      <label className="col-form-label col-12 col-lg-2 col-xl-2">
        Advance Deposit
      </label>
      <div className="col-12 col-lg-4 col-xl-4">
        <input
          type="text"
          className="form-control form-control-sm"
          defaultValue={changeToINR(deposit)}
          readOnly
        />
      </div>
      <label className="col-form-label col-12 col-lg-2 col-xl-2">
        Monthly Rent
      </label>
      <div className="col-12 col-lg-4 col-xl-4">
        <input
          type="text"
          className="form-control form-control-sm"
          defaultValue={changeToINR(monthlyRent)}
          readOnly
        />
      </div>
    </div>
  );
};

export const TerminationForm = props => {
  const {
    inputError,
    remark,
    handleDateChange,
    handleInputChange,
    handleRemark,
    handleUploadDocuments,
    documentUploadLimit,
    handleRemoveDocument,
    uploadedDocuments,
    downloadDocument
  } = props;
  const {
    requestedDate,
    actualTerminationDate,
    terminationReason,
    supportingDocuments
  } = props.leaseTermination;

  const previousMonthLastDay = getLastDayPreviousMonth();

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card">
          <div className="edit-page-section-header-text">Lease Termination</div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-7">
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4">
                    Requested date
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <DatePicker
                      value={requestedDate ? getDisplayDate(requestedDate) : ""}
                      selected={requestedDate ? requestedDate : null}
                      onChange={handleDateChange.bind(this, "requestedDate")}
                      utcOffset={0}
                      minDate={previousMonthLastDay}
                      placeholderText="dd-mm-yyyy"
                      className={
                        inputError["requestedDate"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />

                    {inputError["requestedDate"] ? (
                      <div className="auto-lookup-empty">
                        Please select requested date *.
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4">
                    Actual Termination Date
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <DatePicker
                      value={
                        actualTerminationDate
                          ? getDisplayDate(actualTerminationDate)
                          : ""
                      }
                      selected={
                        actualTerminationDate ? actualTerminationDate : null
                      }
                      onChange={handleDateChange.bind(
                        this,
                        "actualTerminationDate"
                      )}
                      utcOffset={0}
                      minDate={previousMonthLastDay}
                      placeholderText="dd-mm-yyyy"
                      className={
                        inputError["actualTerminationDate"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />

                    {inputError["actualTerminationDate"] ? (
                      <div className="auto-lookup-empty">
                        Please select termination requested date *.
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4">
                    Termination Reason
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <select
                      name="terminationReason"
                      value={setInputData(terminationReason)}
                      onChange={handleInputChange}
                      className={
                        inputError["terminationReason"]
                          ? "custom-select custom-select-sm is-invalid"
                          : "custom-select custom-select-sm"
                      }
                    >
                      <option value="">Select...</option>
                      {TERMINATION_REASONS.map((tr, i) => (
                        <option key={i} value={tr.key}>
                          {tr.value}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Please select termination reason *
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4">
                    Any Remarks
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <textarea
                      type="text"
                      name="remark"
                      className={
                        inputError["remark"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      value={setInputData(remark)}
                      onChange={handleRemark}
                    />
                    <div className="invalid-feedback">
                      Please enter remark *
                    </div>
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4">
                    Upload Documents (Limit : 5)
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <div className="custom-file">
                      <div className="custom-file">
                        <input
                          type="file"
                          className={
                            inputError["uploadedDocuments"]
                              ? "custom-file-input is-invalid"
                              : "custom-file-input"
                          }
                          id="customFile"
                          name="uploadedDocuments"
                          multiple
                          onChange={handleUploadDocuments}
                        />
                        <label
                          className="custom-file-label text-truncate"
                          htmlFor="customFile"
                        >
                          Choose file
                        </label>
                      </div>
                    </div>
                    {inputError["uploadedDocuments"] ? (
                      <div className="auto-lookup-empty">
                        Morethen {documentUploadLimit} documents can't be
                        allowed.
                      </div>
                    ) : null}
                  </div>
                </div>

                {uploadedDocuments.length > 0 ? (
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <strong>Documents to be uploded</strong>
                    </div>
                    <div className="col-12 table-responsive">
                      <table className="table table-bordered table-striped table-sm">
                        <thead>
                          <tr>
                            <th>File Name</th>
                            <th>Type</th>
                            <th>Size</th>
                            <th>Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {[...uploadedDocuments].map((file, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{file.name} </td>
                                <td>{file.type}</td>
                                <td>{getSize(file.size)}</td>
                                <td>
                                  <button
                                    className="btn btn-outline-danger btn-sm"
                                    onClick={handleRemoveDocument.bind(
                                      this,
                                      idx
                                    )}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-5">
                <RemarkList {...props} />
              </div>
            </div>
          </div>
          {supportingDocuments.length > 0 ? (
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-12">
                  <strong>Uploded Documents</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <table className="table table-bordered table-striped table-sm">
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {supportingDocuments.map((file, idx) => {
                        console.log("file", file);
                        return (
                          <tr key={idx}>
                            <td>{file.filename} </td>
                            <td>{file.mimeType}</td>
                            <td>{getSize(file.size)}</td>
                            <td>
                              <button
                                className="btn btn-outline-info btn-sm"
                                onClick={downloadDocument.bind(this, file)}
                              >
                                Download
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const RemarkList = props => {
  const { leaseTermination } = props;
  const notes = leaseTermination.notes;

  if (notes.length === 0) {
    return null;
  }

  return (
    <div className="pt-0">
      <div className="form-group row">
        <div className="col-12 edit-page-label">
          <strong>Notes</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="card border-0">
            {notes.map((nt, idx) => {
              const user = nt.user;
              const name = `${user.firstName}`;
              return (
                <Fragment key={idx}>
                  <div className="media">
                    <div className="media-body">
                      <small className="float-right text-navy">
                        {getDateAndTimeFormat(nt.date)}
                      </small>
                      <p className="mb-0">
                        <strong>{name}</strong>
                      </p>
                      <p className="mb-2">{nt.remark}</p>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ReadOnlyTerminationForm = props => {
  const {
    actualTerminationDate,
    terminationReason,
    supportingDocuments
  } = props.leaseTermination;
  const { downloadDocument } = props;
  return (
    <div className="row mt-3">
      <div className="col-12 p-0">
        <div className="card border-0">
          <div className="edit-page-section-header-text mb-2 mb-2 mb-0">
            Lease Termination
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                    Termination Date
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      defaultValue={getDisplayDate(actualTerminationDate)}
                      readOnly
                    />
                  </div>

                  <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                    Termination Reason
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      defaultValue={setInputData(
                        TERMINATION_REASONS_OBJECT[terminationReason]
                      )}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <RemarkList {...props} />
              </div>
            </div>
          </div>
          {supportingDocuments.length > 0 ? (
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-md-12">
                  <strong>Uploded Documents</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <table className="table table-bordered table-striped table-sm">
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {supportingDocuments.map((file, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{file.filename} </td>
                            <td>{file.mimeType}</td>
                            <td>{getSize(file.size)}</td>
                            <td>
                              <button
                                className="btn btn-outline-info btn-sm"
                                onClick={downloadDocument.bind(this, file)}
                              >
                                Download
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
