import React, { Component, Fragment } from "react";
import Form from "react-jsonschema-form-bs4";
import HttpUtil from "../../common/HttpUtil";
import { getIsoDataFromNumericDate } from "../../lease/DateUtil";
// import {
//   IncubateeOrgSchema,
//   IncubateeOrgUiSchema
// } from "./schemas/IncubateeOrgSchema";

// import { AddressInfoSchema } from "./schemas/AddressSchema";
// import { PhoneInfoSchema } from "./schemas/PhoneSchema";
// import { EmailInfoSchema } from "./schemas/EmailSchema";

import ArrayFieldTemplate from "./ArrayFieldTemplate";
import ErrorListTemplate from "./ErrorListTemplate";
import { ORGANIZATION_STEPS } from "./OrganizationStepsForm";

import { INDIVIDUAL_STEPS } from "./IndividualStepsForm";

const STEPS = INDIVIDUAL_STEPS;

class IncubateePublicDataCollectionForm extends Component {
  constructor(args) {
    super(args);
    this.state = {
      currentStep: 0,
      formData: {},
      loading: true
    };
  }

  handleSubmit = formInfo => {
    const { currentStep, formData } = this.state;
    if (currentStep < STEPS.length) {
      formData[STEPS[currentStep].key] = formInfo.formData;
      this.setState({ formData, currentStep: currentStep + 1 });
    }
    console.log(formInfo);
    console.log(formData);
    window.scrollTo(0, 0);
  };

  setCurrentStep = index => {
    window.scrollTo(0, 0);
    this.setState({ currentStep: index });
  };

  convertIndividualFormData = data => {
    const formData = {};
    const individual = {
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      dob: getIsoDataFromNumericDate(data.dob),
      references: data.references,
      experience: data.experience
    };

    individual.education = data.education.map(ed => {
      const year = Number.parseInt(ed.year) || undefined;
      return {
        degree: ed.degree,
        year,
        institution: ed.institution,
        alumni: ed.alumni
      };
    });
    formData.individual = individual;

    formData.contacts = {
      addresses: data.addresses,
      emails: data.emails,
      phones: data.phones,
      identificationDocs: data.identificationDocs
    };

    formData.incubateeProfile = {
      about: data.incubateeProfile.about || undefined,
      businessModel: data.incubateeProfile.businessModel || undefined,
      businessPlan: data.incubateeProfile.businessPlan || undefined,
      incubateeStage: data.incubateeProfile.incubateeStage,
      incubationSector: data.incubateeProfile.incubationSector,
      subTechSector: data.incubateeProfile.subTechSector,
      targetSector: data.incubateeProfile.targetSector || undefined,
      isCoIncubatee: data.incubateeProfile.isCoIncubatee,
      metric: data.incubateeProfile.metric
    };

    return formData;
  };

  componentDidMount = () => {
    // HttpUtil.get(
    //   "/api/v1/public/forms/5cb3ecf7fe58ab154d0dd06e/5d5f698d55a4de59fc086ce0",
    //   {},
    //   data =>
    //     this.setState({
    //       formData: this.convertIndividualFormData(data.details),
    //       loading: false
    //     }),
    //   data => window.alert(data.message),
    //   err => window.alert(err.toString())
    // );

    this.setState({
      formData: {},
      loading: false
    });
  };

  render() {
    const { currentStep, formData, loading } = this.state;
    const firstStep = currentStep === 0;
    const lastStep = currentStep === STEPS.length - 1;
    const step = currentStep < STEPS.length ? STEPS[currentStep] : null;

    return (
      <div className="container">
        <h3 className="text-center mt-3" style={{ color: "#647acb" }}>
          Incubatee Enquiry Form
        </h3>
        <div className="row">
          <div className="col-1" />
          <div className="col-10">
            <ul className="stepper">
              {STEPS.map((step, index) => {
                let clz;
                let attrs = {};
                if (index <= currentStep) {
                  clz = "active";
                  attrs = {
                    onClick: () => this.setCurrentStep(index),
                    style: { cursor: "pointer" }
                  };
                }
                return (
                  <li className={clz} key={step.key} {...attrs}>
                    {step.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-1" />
          <div className="col-10">
            <div
              className="card edit-page-container mt-3 mb-5"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="card-body">
                {!loading && step ? (
                  <Form
                    noHtml5Validate={true}
                    schema={step.schema}
                    uiSchema={step.uiSchema}
                    formData={formData[step.key]}
                    onSubmit={this.handleSubmit}
                    ArrayFieldTemplate={ArrayFieldTemplate}
                    ErrorList={ErrorListTemplate}
                  >
                    <div className="text-center">
                      {!firstStep ? (
                        <button
                          onClick={() => this.setCurrentStep(currentStep - 1)}
                          type="button"
                          className="btn btn-sm btn-outline-primary"
                        >
                          Previous
                        </button>
                      ) : null}
                      <button
                        className="btn btn-sm btn-primary ml-2"
                        type="submit"
                      >
                        {lastStep ? "Submit" : "Next"}
                      </button>
                    </div>
                  </Form>
                ) : loading ? (
                  "Loading"
                ) : (
                  <div className="alert alert-success text-center">
                    Enquiry form has been submitted successfully.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IncubateePublicDataCollectionForm;
