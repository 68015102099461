import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import { BUILDING_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import DefaultAlert from "../DefaultAlert";
import TableList from "../../common/TableList";
import { STATUS_LIST_OBJECT } from "../../common/LookupConstants";
import Can from "../../../auth/can";

class BuildingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildingList: null,

      //redirect to edit building page
      editBuilding: false,
      editBuildingId: "",

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    };
  }

  //react-bootstrap-table-next default funtion with param
  handleClick = (e, column, columnIndex, row, rowIndex) => {
    let buildingId = row._id;
    return this.setState({
      editBuilding: true,
      editBuildingId: buildingId
    });
  };

  // React life cycle
  componentDidMount = () => {
    this.getBuildingList();
  };

  // Get building list from server
  getBuildingList = () => {
    const url = BUILDING_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    // Fetch api
    HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({ buildingList: data });
      },
      (data, status) => {
        this.setState({
          showAlert: true,
          alertMode: "danger",
          alertMessage: data.message
        });
      },
      error => {
        this.setState({
          showAlert: true,
          alertMode: "danger",
          alertMessage: error.toString()
        });
      }
    );
  };

  // Close bootstrap default alert box
  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  linkFormater = (cell, row) => {
    if (!row) {
      return null;
    }
    const url = `/admin/buildings/edit/${row._id}`;
    return <Link to={url}>{row.buildingName}</Link>;
  };

  getBuildingName = (cell, row) => {
    if (!row) {
      return null;
    }
    return row.buildingName;
  };

  render() {
    const {
      buildingList,

      editBuilding,
      editBuildingId,

      //Default alert
      showAlert,
      alertMode,
      alertMessage
    } = this.state;

    if (editBuilding) {
      let url = "/admin/buildings/edit/" + editBuildingId;
      return <Redirect to={url} />;
    }

    const columnsBuilding = [
      {
        dataField: "campus.name",
        text: "Campus Name",
        sort: true
      },
      {
        dataField: "buildingName",
        text: "Building Name",
        sort: true,
        formatter: this.linkFormater,
        csvFormatter: this.getBuildingName,
        filterValue: this.getBuildingName
      },
      {
        dataField: "totalFloors",
        text: "Floors",
        sort: true
      },
      {
        dataField: "status",
        text: "Status",
        formatter: cell => STATUS_LIST_OBJECT[cell],
        csvFormatter: cell => STATUS_LIST_OBJECT[cell],
        filterValue: cell => STATUS_LIST_OBJECT[cell]
      }
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/* show alert message  */}
            <DefaultAlert
              show={showAlert}
              alertMode={alertMode}
              message={alertMessage}
              closeAlert={this.closeDefaultAlert}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsBuilding}
          data={buildingList}
          noDataIndication="No Building found."
          sizePerPage="10"
          headerName="Building"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default BuildingList;

const RenderCustomButton = props => {
  return (
    <Can I="CREATE" a="Building">
      <Link
        to="/admin/buildings/add"
        role="button"
        className="btn btn-sm text-white bg-pink"
      >
        New
      </Link>
    </Can>
  );
};
