import React, { Fragment } from "react";
import { getErrorCssClassName, ErrorComponent } from "../../common/FormikUtil";
import DatePicker from "react-datepicker";
import {
  downloadDocument,
  getActionCss,
  getConcatenatedName
} from "../../common/Util";
import { Link } from "react-router-dom";
import { Field, Form } from "formik";
import { getDescription } from "../../common/Util";
import { changeToINR } from "../../common/LookupConstants";
import {
  getDisplayDate,
  convertNumberToDate,
  getDateAndTimeFormat
} from "../../lease/DateUtil";
import PaymentDetails from "./PaymentDetails";

function CreateClosureRequest(props) {
  const {
    values,
    touched,
    errors,
    setFieldValue,
    screen,
    closureRequest,
    prevLocation
  } = props;

  const {
    actions = [],
    leaseRentalCharge = {},
    assetReservationCharge = {},
    otherCharge = {},
    chargeDetails = {}
  } = closureRequest;

  const rentalCharges = leaseRentalCharge.charges || [];
  const assetCharges = assetReservationCharge.charges || [];
  const otherCharges = otherCharge.charges || [];

  const disabledField = actions.length === 0;
  const disabledAdjustFromAdvance = disabledField || values.stage !== "INIT";
  const hasDocuments =
    (values.availableDocuments || []).length > 0 ||
    (values.documents || []).length > 0;

  const rejected =
    closureRequest.stage === "COMPLETE" && closureRequest.status === "REJECTED";

  const showPaymentDetails =
    (values.stage === "ACCTREFUND" || values.stage === "COMPLETE") &&
    values.status !== "REJECTED" &&
    chargeDetails.refund !== 0;
  function getRefundAmount() {
    let refund = 0;
    if (
      (values.adjustFromAdvance || !chargeDetails.pendingBalance) &&
      chargeDetails.advance
    ) {
      refund = chargeDetails.refund;
    } else {
      refund = chargeDetails.advance;
    }
    return changeToINR(refund);
  }
  return (
    <Form>
      <div className="card edit-page-container box-border box-light-blue">
        <div className="card-header border-bottom-0 pt-3">
          <div className="row">
            <div className="col-lg-6 col-xs-8 mt-2">
              <Link to={prevLocation} className="badge bg-white custom-btn-sm">
                <i className="fas fa-arrow-left mr-3" />
              </Link>
              <span className="edit-page-title">Account Closure Request</span>
            </div>
            <div className="col-sm mt-2 mr-2">
              <span className=" row float-right edit-page-status">
                {`Status : ${getDescription(
                  "AccountClosure",
                  closureRequest.stage,
                  closureRequest.status
                )}`}
              </span>
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row no-gutter">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Entity Type
            </label>
            <div className="col-12 col-lg-4 col-xl-4 pr-4">
              <Field
                component="select"
                name="entityType"
                className={"custom-select custom-select-sm"}
                disabled={screen === "Create"}
              >
                <option value="">{values.entityType}</option>
              </Field>
            </div>
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Entity
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                component="select"
                name="entity"
                className={"custom-select custom-select-sm"}
                disabled={screen === "Create"}
              >
                <option value="">
                  {values.entity.name || values.entity.firstName}
                </option>
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mt-3 mx-0 px-1">
              <div className="col-12 mb-3 edit-page-section-header-text">
                Closure Information
              </div>

              <div className="form-group row mb-0 mt-2 pl-2">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Closure Date
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <DatePicker
                    value={getDisplayDate(values.closureDate) || ""}
                    selected={values.closureDate || null}
                    onChange={e => {
                      setFieldValue(`closureDate`, e);
                    }}
                    utcOffset={0}
                    placeholderText="dd-mm-yyyy"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "closureDate"
                    )}`}
                    disabled={disabledField}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {errors.closureDate && touched.closureDate && (
                    <div className="invalid-feedback mb-2">
                      {errors.closureDate}
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group row mb-0 pl-2">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Reason
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <Field
                    component="textarea"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "reason"
                    )}`}
                    disabled={disabledField}
                    rows="4"
                    name={`reason`}
                    value={values.reason}
                  />

                  <ErrorComponent
                    fieldName={`reason`}
                    touched={touched}
                    error={errors}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-3 mx-0 px-1">
              <div className="edit-page-section-header-text pl-2">
                Documents <small>( Max 5 )</small>
              </div>
              <div className="row no-gutters">
                <div className="col-12 text-center">
                  <ul
                    className="list-group mt-2"
                    style={{ minHeight: "128px" }}
                  >
                    {values.availableDocuments.map((doc, index) => {
                      return (
                        <li
                          key={index}
                          className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                        >
                          <span>
                            <i className={`far fa-file fa-lg mr-2`} />
                            {doc.filename}
                          </span>
                          <div>
                            <i
                              className="fas fa-download fa-md mr-3"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                downloadDocument(
                                  doc.link,
                                  msg => window.alert(msg),
                                  doc.filename
                                )
                              }
                            />
                          </div>
                        </li>
                      );
                    })}
                    {Array.from(values.documents).map((file, index) => {
                      return (
                        <li
                          key={index}
                          className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                        >
                          <span>
                            <i className={`far fa-file fa-lg mr-2`} />
                            {file.name || file.filename}
                          </span>

                          <div>
                            <span>Uploaded</span>
                            {!file._id && (
                              <button
                                type="button"
                                onClick={() => {
                                  values.documents.splice(index, 1);
                                  setFieldValue("documents", values.documents);
                                }}
                                className="close mx-2"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            )}
                          </div>
                        </li>
                      );
                    })}
                    {!hasDocuments ? (
                      <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                        No documents available
                      </li>
                    ) : null}
                  </ul>
                  <div className="upload-btn-wrapper">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      disabled={disabledField}
                    >
                      <i className="fas fa-upload mr-2" />
                      Upload
                      <input
                        type="file"
                        name="documents"
                        multiple
                        disabled={disabledField}
                        onChange={event => {
                          const max = event.currentTarget.files.length;
                          if (max > 5 || values.documents.length > 4) {
                            alert("Only 5 documents can be allowed.");
                          } else {
                            setFieldValue("documents", [
                              ...values.documents,
                              ...event.currentTarget.files
                            ]);
                          }
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Charge details */}

          {chargeDetails.pendingBalance > 0 &&
          // Show only if the rental charges or asset charges present
          (rentalCharges.length > 0 ||
            assetCharges.length > 0 ||
            otherCharges.length > 0) &&
          !rejected ? (
            <Fragment>
              <div className="row mt-2">
                <div className="col-12 mb-3 edit-page-section-header-text">
                  Charge Details
                </div>
              </div>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th className="display-table-header" scope="col">
                      Charge Date
                    </th>
                    <th className="display-table-header" scope="col">
                      Charge Type
                    </th>

                    <th className="display-table-header" scope="col">
                      Description
                    </th>
                    <th className="display-table-header" scope="col">
                      Amount
                    </th>
                    <th className="display-table-header" scope="col">
                      Pending Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rentalCharges.map((charge, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {getDisplayDate(
                            convertNumberToDate(charge.chargeDate)
                          )}
                        </td>
                        <td>Facility</td>
                        <td>{charge.description}</td>
                        <td>{changeToINR(charge.amount)}</td>
                        <td>
                          {changeToINR(charge.amount - charge.paidAmount)}
                        </td>
                      </tr>
                    );
                  })}

                  {assetCharges.map((charge, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {getDisplayDate(
                            convertNumberToDate(charge.chargeDate)
                          )}
                        </td>
                        <td>Asset Reservation</td>
                        <td>{charge.description}</td>
                        <td>{changeToINR(charge.amount)}</td>
                        <td>
                          {changeToINR(charge.amount - charge.paidAmount)}
                        </td>
                      </tr>
                    );
                  })}

                  {otherCharges.map(charge => {
                    return (
                      <tr key={charge._id}>
                        <td>
                          {getDisplayDate(
                            convertNumberToDate(charge.chargeDate)
                          )}
                        </td>
                        <td>Other Charge</td>
                        <td>{charge.description}</td>
                        <td>{changeToINR(charge.amount)}</td>
                        <td>
                          {changeToINR(charge.amount - charge.paidAmount)}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td />
                    <td />
                    <td>Total</td>
                    <td>{changeToINR(chargeDetails.pendingBalance)}</td>
                    <td>{changeToINR(chargeDetails.pendingBalance)}</td>
                  </tr>
                </tbody>
              </table>
            </Fragment>
          ) : null}

          {/* Summary Section */}
          {!rejected && (
            <>
              <div className="row mt-3">
                <div className="col-12 mb-3 edit-page-section-header-text">
                  Summary
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-xs-12 col-md-5 edit-page-label">
                  Total Pending Amount
                </label>
                <label className="col-form-label col-xs-12 col-md-4 ">
                  {changeToINR(chargeDetails.pendingBalance)}
                </label>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-xs-12 col-md-5 edit-page-label">
                  Total Advance
                </label>
                <label className="col-form-label col-xs-12 col-md-4 ">
                  {changeToINR(closureRequest.advance)}
                </label>
              </div>

              {chargeDetails.pendingBalance > 0 && chargeDetails.advance > 0 && (
                <div className="form-group row mb-0">
                  <label className="col-form-label col-xs-12 col-md-5 edit-page-label">
                    Adjust from Advance
                  </label>
                  <div className="col-xs-12 col-md-4">
                    <div className="custom-control custom-radio custom-control-inline mt-1">
                      <Field
                        id="adjustFromAdvanceYes"
                        type="radio"
                        name="adjustFromAdvance"
                        checked={values.adjustFromAdvance === true}
                        onChange={() => {
                          setFieldValue("adjustFromAdvance", true);
                          setFieldValue(
                            "adjustedPendingAmount",
                            chargeDetails.due
                          );
                        }}
                        disabled={disabledAdjustFromAdvance}
                        className={"custom-control-input"}
                      />
                      <label
                        className="edit-page-label custom-control-label"
                        htmlFor="adjustFromAdvanceYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline mt-1">
                      <Field
                        id="adjustFromAdvanceNo"
                        type="radio"
                        name="adjustFromAdvance"
                        checked={values.adjustFromAdvance === false}
                        disabled={disabledAdjustFromAdvance}
                        onChange={() => {
                          setFieldValue("adjustFromAdvance", false);
                          setFieldValue("adjustedPendingAmount", "");
                        }}
                        className={"custom-control-input"}
                      />
                      <label
                        className="edit-page-label custom-control-label"
                        htmlFor="adjustFromAdvanceNo"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group row mb-0">
                <label className="col-form-label col-xs-12 col-md-5 edit-page-label">
                  Amount Due from Incubatee
                </label>
                <label className="col-form-label col-xs-12 col-md-4 ">
                  {values.adjustFromAdvance
                    ? changeToINR(chargeDetails.due)
                    : changeToINR(chargeDetails.pendingBalance)}
                </label>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-xs-12 col-md-5 edit-page-label">
                  Refund amount to incubatee
                </label>
                <label className="col-form-label col-xs-12 col-md-4 ">
                  {getRefundAmount()}
                </label>
              </div>
            </>
          )}
          {/* Notes Section */}

          <div className="row mt-3">
            <div className="col-12 mb-3 edit-page-section-header-text">
              Notes
            </div>
            {!disabledField && !rejected && values.stage !== "ACCTREFUND" ? (
              <div className="col-lg-6 mx-0 px-1">
                <div className="form-group row mb-0 mt-2 pl-2">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                    Notes
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <Field
                      component="textarea"
                      className={`form-control form-control-sm ${getErrorCssClassName(
                        touched,
                        errors,
                        "note"
                      )}`}
                      disabled={disabledField}
                      rows="4"
                      name={`note`}
                      value={values.note}
                    />

                    <ErrorComponent
                      fieldName={`note`}
                      touched={touched}
                      error={errors}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {values.notes.length > 0 && (
              <div className="col-6 mt-1">
                <table className="table table-sm">
                  <tbody>
                    {values.notes.map(note => (
                      <tr key={note.date}>
                        <td>
                          <div>
                            <strong>
                              {getConcatenatedName(
                                note.user.firstName,
                                note.user.lastName
                              )}
                            </strong>
                          </div>
                          <div>
                            <small>{note.remark}</small>
                          </div>
                        </td>
                        <td>{getDateAndTimeFormat(note.date)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {showPaymentDetails && (
            <PaymentDetails
              errors={errors}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              chargeDetails={chargeDetails}
              disabledField={disabledField}
            />
          )}
          <div className="col-md-12 text-center mt-3">
            {actions.map(action => (
              <button
                key={action}
                className={`${getActionCss(action)} m-2`}
                type="submit"
                disabled={
                  action === "SUBMIT" &&
                  chargeDetails.pendingBalance > 0 &&
                  !values.adjustFromAdvance
                    ? true
                    : action === "SUBMIT" &&
                      values.adjustFromAdvance &&
                      chargeDetails.due > 0
                    ? true
                    : false
                }
                onClick={() => setFieldValue("action", action)}
              >
                {action}
              </button>
            ))}
          </div>
        </div>
      </div>
    </Form>
  );
}

export default CreateClosureRequest;
