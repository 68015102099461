import React, { Fragment } from "react";
import { Field, ErrorMessage } from "formik";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const FinancialInformation = props => {
  const { values, touched, errors, readOnly } = props;

  const disabledField = readOnly
    ? { readOnly: "readOnly", disabled: "disabled" }
    : {};

  return (
    <Fragment>
      <div className="row mt-5">
        <div className="col-12 mb-3 edit-page-section-header-text">
          Financial Information
        </div>
      </div>

      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
          Advance
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            name="advance"
            value={values.advance}
            className={getErrorCssClassName(touched, errors, "advance")}
            {...disabledField}
          />
          <ErrorMessage
            component="div"
            name="advance"
            className="invalid-feedback"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FinancialInformation;
