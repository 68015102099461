function checkNaN(val) {
  return isNaN(val) ? 0 : val;
}

function formatNumber(val) {
  if (!val) {
    return 0;
  }
  return isNaN(val) ? 0 : new Intl.NumberFormat("en-IN").format(val.toFixed(0));
}

function getPercent(numerator, denominator) {
  if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
    return null;
  }
  return Math.round((numerator * 100) / denominator);
}

function getPercentString(numerator, denominator) {
  if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
    return "---";
  }
  return `${Math.round((numerator * 100) / denominator)} %`;
}

export { checkNaN, getPercent, getPercentString, formatNumber };
