import React, { Fragment } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import LoadingComponent from "../../common/spinnerloader/LoadingComponent";
import { Link } from "react-router-dom";

function getError(touched, error, fieldName) {
  return touched && touched[fieldName] && error && error[fieldName];
}

const CustomForm = props => {
  const { values, errors, touched, setFieldValue } = props;

  return (
    <Form>
      <div className="row">
        <div className="col-md-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title">
                <Link
                  to="/external-users"
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left mr-3" />
                </Link>
                {values.newUser
                  ? "Incubatee User - New"
                  : `Incubatee User - Update`}
              </span>
            </div>

            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Basic Information</h5>
            </div>

            <div className="card-body">
              <div className="form-group row mb-1">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  First Name
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${
                      getError(touched, errors, "firstName") ? "is-invalid" : ""
                    }`}
                    name="firstName"
                    value={values.firstName || ""}
                  />
                  <ErrorMessage
                    component="div"
                    className="invalid-feedback mb-3"
                    name="firstName"
                  />
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Last Name
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${
                      getError(touched, errors, "lastName") ? "is-invalid" : ""
                    }`}
                    name="lastName"
                    value={values.lastName || ""}
                  />
                  <ErrorMessage
                    component="div"
                    className="invalid-feedback mb-3"
                    name="lastName"
                  />
                </div>
              </div>

              {values.newUser ? (
                <Fragment>
                  <div className="form-group row mb-1">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                      Password
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="password"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "password")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="password"
                        value={values.password || ""}
                      />
                      <ErrorMessage
                        component="div"
                        className="invalid-feedback mb-3"
                        name="password"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-1">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                      Confirm password
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="password"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "confirmPassword")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="confirmPassword"
                        value={values.confirmPassword || ""}
                      />
                      <ErrorMessage
                        component="div"
                        className="invalid-feedback mb-3"
                        name="confirmPassword"
                      />
                    </div>
                  </div>
                </Fragment>
              ) : null}

              <div className="form-group row mb-1">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Email
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${
                      getError(touched, errors, "email") ? "is-invalid" : ""
                    }`}
                    name="email"
                    value={values.email || ""}
                  />
                  <div className="mb-2">
                    <small>Email as Username</small>
                  </div>
                  <ErrorMessage
                    component="div"
                    className="invalid-feedback mb-3"
                    name="email"
                  />
                </div>
              </div>
              <div className="form-group row mb-1">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Mobile Number
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${
                      getError(touched, errors, "mobileNumber")
                        ? "is-invalid"
                        : ""
                    }`}
                    name="mobileNumber"
                    value={values.mobileNumber || ""}
                  />
                  <ErrorMessage
                    component="div"
                    className="invalid-feedback mb-3"
                    name="mobileNumber"
                  />
                </div>
              </div>
              <div className="form-group row mb-1">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Status
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                      name="isActive"
                      checked={values.isActive}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      Active
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row mb-1">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Roles
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    component="select"
                    className={`custom-select custom-select-sm ${
                      getError(touched, errors, "role") ? "is-invalid" : ""
                    }`}
                    name="role"
                    value={values.role || ""}
                  >
                    <option value="">Select...</option>
                    {props.userRoles.map((role, i) => (
                      <option key={i} value={role._id}>
                        {role.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component="div"
                    className="invalid-feedback mb-3"
                    name="role"
                  />
                </div>
              </div>
              <div className="form-group row mb-1">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Select Entity
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <div
                    className={`${
                      getError(touched, errors, "entity") ? "is-invalid" : ""
                    }`}
                  >
                    <Select
                      value={values.entity}
                      onChange={entity => setFieldValue("entity", entity)}
                      options={props.incubatees}
                      isClearable
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="d-block invalid-feedback mb-3"
                    name="entity"
                  />
                </div>
              </div>
              <div className="row mt-4 mb-4">
                <div className="col-12">
                  <button className="btn btn-sm btn-primary" type="submit">
                    {values.newUser ? "Create User" : "Update User"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

function OtherUserForm(props) {
  const { initialValues } = props;
  const user = initialValues || {};
  return (
    <div>
      {user.newUser === null ? (
        <LoadingComponent />
      ) : (
        <Formik
          enableReinitialize
          initialValues={props.initialValues}
          validationSchema={props.validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            props.handleSubmit(values, { setSubmitting })
          }
          render={renderProps => <CustomForm {...props} {...renderProps} />}
        />
      )}
    </div>
  );
}

export default OtherUserForm;
