const RentalReauest = {
  _id: null,
  incubateeIndicator: false,
  incubatee: "",
  requestorType: "",
  individual: {},
  organization: {},
  name: "",
  identificationDocs: [
    {
      type: "",
      number: "",
      status: "",
      document: ""
    }
  ],
  emails: [
    {
      email: "",
      type: "",
      status: ""
    }
  ],
  phones: [
    {
      phone: "",
      type: "",
      status: ""
    }
  ],
  addresses: [
    {
      streetLine1: "",
      streetLine2: "",
      landmark: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      addressProof: "",
      status: "",
      type: ""
    }
  ],
  equipments: [
    {
      equipment: "",
      quantity: ""
    }
  ],
  status: ""
};

export default RentalReauest;
