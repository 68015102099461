import React, { Component, Fragment } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import DatePicker from "react-datepicker";
import { GRANT_AWARD_REVIEW_STATUS } from "../../common/LookupConstants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import { getErrorCssClassName, ErrorComponent } from "../../common/FormikUtil";
import { DocumentTemplate } from "./DocumentTemplate";
import { ChecklistTemplate } from "./ChecklistTemplate";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";

function getDocumentTypes(template) {
  const documents = (template && template.documents) || [];
  const docTypes = documents.map(d => d.documentType);
  return docTypes;
}

function getCheckListLabel(template) {
  return (template && template.checklist) || [];
}

// const GRANT_REVIEW_DEFAULT_SCHEMA = {
//   title: Yup.string()
//     .label("Review Title")
//     .required(),
//   reviewDate: Yup.date()
//     .label("Review Date")
//     .required(),
//   amountDisbursed: Yup.number().label("Disbursed Amount"),
//   status: Yup.string()
//     .label("Status")
//     .required(),
//   reviewerComments: Yup.string().when("reviewers", {
//     is: reviewers => reviewers && reviewers.length > 0,
//     then: Yup.string()
//       .required()
//       .label("Reviewer Comments"),
//     else: Yup.string().label("Reviewer Comments")
//   }),
//   incubatorComments: Yup.string()
//     .label("Incubator Comments")
//     .required()
// };

// const GRANT_REVIEW_SCHEMA = Yup.object().shape({
//   title: Yup.string()
//     .label("Review Title")
//     .required(),
//   reviewDate: Yup.date()
//     .label("Review Date")
//     .required(),
//   amountDisbursed: Yup.number().label("Disbursed Amount"),
//   status: Yup.string()
//     .label("Status")
//     .required(),
//   reviewerComments: Yup.string().when("reviewers", {
//     is: reviewers => reviewers && reviewers.length > 0,
//     then: Yup.string()
//       .required()
//       .label("Reviewer Comments"),
//     else: Yup.string().label("Reviewer Comments")
//   }),
//   incubatorComments: Yup.string()
//     .label("Incubator Comments")
//     .required()
// });

// const DOCUMENT_TEMPLATE_SCHEMA = Yup.object().shape({
//   required: Yup.boolean()
//     .label("Required")
//     .optional(),
//   documentType: Yup.string().when("required", {
//     is: true,
//     then: Yup.string()
//       .label("Document Type")
//       .required(),
//     otherwise: Yup.string()
//       .nullable()
//       .optional()
//   }),
//   allowMultiple: Yup.boolean()
//     .label("Allow Muiltiple")
//     .optional()
// });

// const CHECKLIST_TEMPLATE_SCHEMA = Yup.object().shape({
//   required: Yup.boolean()
//     .label("Required")
//     .optional(),
//   label: Yup.string().when("required", {
//     is: true,
//     then: Yup.string()
//       .label("Label")
//       .required(),
//     otherwise: Yup.string()
//       .nullable()
//       .optional()
//   })
// });

function validateChecklists(values, template) {
  const checklistIds = {};
  template.checklist.forEach(checkListItem => {
    checklistIds[checkListItem._id] = checkListItem.required;
  });
  const checkListErrors = [];
  values.checklist.forEach((checkListItem, index) => {
    if (checklistIds[checkListItem._id] && !checkListItem.selected) {
      checkListErrors[index] = {
        selected: "Checklist item should be completed."
      };
    }
  });
  return checkListErrors;
}

function sort(a, b) {
  const atype = (a.type || "").toLocaleLowerCase();
  const btype = (b.type || "").toLocaleLowerCase();
  if (atype === btype) {
    const aname = (a.name || "").toLocaleLowerCase();
    const bname = (b.name || "").toLocaleLowerCase();
    if (aname < bname) {
      return -1;
    } else if (aname === bname) {
      return 0;
    } else {
      return 1;
    }
  } else if (atype < btype) {
    return -1;
  } else {
    return 1;
  }
}

function validateDocuments(
  existingDocuments,
  documentType,
  documentName,
  allowMultiple
) {
  const documents = existingDocuments || [];

  const errors = [];

  const documentTypeNameIndex = {};
  const documentTypesIndex = {};
  documents.forEach((doc, index) => {
    documentTypeNameIndex[`${doc.type}|||${doc.name}`] = index + 1;
    const type = documentTypesIndex[doc.type] || [];
    type.push(index + 1);
    documentTypesIndex[doc.type] = type;
  });

  const docKey = `${documentType}|||${documentName}`;

  if (!allowMultiple && documentTypesIndex[documentType]) {
    const typeIndexes = documentTypesIndex[documentType];
    typeIndexes.forEach(index => {
      errors[index - 1] = {
        name: `Existing Document of type "${documentType}"`,
        type: ``
      };
    });
  } else if (documentTypeNameIndex[docKey]) {
    const index = documentTypeNameIndex[docKey] - 1;
    errors[index] = {
      name: `Existing Document "${documentName}" of type "${documentType}"`,
      type: ``
    };
  }
  return errors;
}

export const validate = (values, template) => {
  let errors = {};

  const checklist = validateChecklists(values, template);
  if (checklist.length > 0) {
    errors.checklist = checklist;
  }

  const documentName = values.documentName;
  const documentType = values.documentType;

  let allowMultiple = false;
  for (let index = 0; index < template.documents.length; index++) {
    const doc = template.documents[index];
    if (doc.documentType === documentType) {
      allowMultiple = doc.allowMultiple;
      break;
    }
  }

  let availableDocuments = values.availableDocuments
    ? [...values.availableDocuments]
    : [];
  availableDocuments.sort(sort);

  const availableDocumentsError = validateDocuments(
    availableDocuments,
    documentType,
    documentName,
    allowMultiple
  );

  if (availableDocumentsError.length > 0) {
    errors.availableDocumentsError = availableDocumentsError;
  }

  let fileExistsInServer = availableDocumentsError.length > 0;

  let documentsError = [];
  if (!fileExistsInServer) {
    documentsError = validateDocuments(
      values.documents,
      documentType,
      documentName,
      allowMultiple
    );
  }

  if (documentsError.length > 0) {
    errors.documentsError = documentsError;
  }

  return errors;
};

function validateFn(values, template) {
  const errors = validate(values, template);
  if (!values.title) {
    errors.title = "Review Title is required field";
  }

  if (!values.reviewDate) {
    errors.reviewDate = "Review Date is required field";
  }

  if (!values.status) {
    errors.status = "Review status is required field";
  }
  return errors;
}

class GrantAwardReviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      alertFor: "",
      alertWithTitle: "",
      deleteDocument: null,
      customAlertMessage: "",

      data: null,
      scrollToNewReview: false
    };
    this.refObject = React.createRef();
  }

  getReviewData(review) {
    let checklistLabels = getCheckListLabel(this.props.template);

    if (!review) {
      return {
        title: "",
        reviewDate: null,
        amountSanctioned: "",
        status: "INPROGRESS",
        reviewers: [],
        reviewerComments: "",
        incubatorComments: "",
        documents: [],
        availableDocuments: [],
        documentName: "",
        documentType: "",
        checklist: [...checklistLabels],
        grantProgressTemplate: this.props.template.grantProgressTemplate,
        reviewerName: "",
        reviewerOrganization: ""
      };
    }

    let existingChecklist = (review && review.checklist) || [];

    return {
      _id: review._id,
      title: review.title,
      reviewDate: convertNumberToDate(review.reviewDate),
      amountSanctioned: review.amountSanctioned,
      status: review.status,
      reviewers: review.reviewers,
      reviewerComments: review.reviewerComments,
      incubatorComments: review.incubatorComments,
      documents: [],
      availableDocuments: review.documents,
      documentName: "",
      documentType: "",
      checklist: existingChecklist,
      grantProgressTemplate: review.grantProgressTemplate || {},
      reviewerName: "",
      reviewerOrganization: ""
    };
  }

  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      alertFor: "",
      alertWithTitle: "",
      customAlertMessage: ""
    });
    window.scrollTo(0, 0);
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      alertFor: "",
      alertWithTitle: "",
      customAlertMessage: ""
    });
  };

  handleDelete = doc => {
    if (!this.state.alertFor) {
      return this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "primary",
        alertMessage: "Do you want to delete this file ?",
        alertFor: 101,
        alertWithTitle: "Delete File",
        deleteDocument: doc
      });
    }

    const url = doc.link;

    HttpUtil.delete(
      url,
      {},
      review => {
        if (review) {
          const data = this.getReviewData(review);

          this.setState({
            data,
            customAlertMessage: "Document removed successfully.",
            alertType: "",
            showAlert: false,
            alertColor: "",
            alertMessage: "",
            alertFor: "",
            alertWithTitle: "",
            deleteId: null
          });
        }
      },
      (data, status) => {
        this.handleApiFailed(data.message);
      },
      error => {
        this.handleApiFailed(error.toString());
      }
    );
  };

  handleAlertFor = () => {
    const { alertFor } = this.state;
    if (alertFor === 101) {
      const deleteDocument = this.state.deleteDocument;
      this.handleDelete(deleteDocument);
    }
  };

  componentDidMount = () => {
    const review = this.props.review;
    const data = this.getReviewData(review);
    this.setState({ data, scrollToNewReview: !data._id });
  };

  componentDidUpdate = () => {
    if (this.state.scrollToNewReview) {
      const reviewForm = document.getElementById("reviewForm");
      if (reviewForm) {
        reviewForm.scrollIntoView({ behavior: "smooth" });
      }
      this.setState({ scrollToNewReview: false });
    }
  };

  render() {
    const { data } = this.state;
    if (data === null) {
      return null;
    }

    const docTypes = getDocumentTypes(this.props.template);

    return (
      <div ref={this.refObject}>
        <AlertComponent
          show={this.state.showAlert}
          type={this.state.alertType}
          alertColor={this.state.alertColor}
          message={this.state.alertMessage}
          close={this.closeDefaultAlert}
          confirm={this.handleAlertFor}
          alertWithTitle={this.state.alertWithTitle}
        />

        <Formik
          enableReinitialize
          initialValues={data}
          //validationSchema={GRANT_REVIEW_SCHEMA}
          validate={values => validateFn(values, this.props.template)}
          onSubmit={(values, actions) => {
            this.props.saveReview(values, actions);
          }}
          render={({
            values,
            onSubmit,
            isSubmitting,
            errors,
            touched,
            setFieldValue
          }) => {
            return (
              <Form>
                <div
                  id="reviewForm"
                  className="shadow card flex-fill box-border box-light-blue"
                >
                  <div className="card-header border-0 text-center pb-0">
                    <h6 className="mb-0 text-light-blue">
                      <strong>
                        {values._id
                          ? `Edit Review - ${values.title}`
                          : "New Review"}
                      </strong>
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="row mb-3 mt-2">
                      <div className="col-12">
                        <strong className="edit-page-section-header-text d-block">
                          Basic Information
                        </strong>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label edit-page-label-required col-12 col-lg-2 col-xl-2">
                        Review Title
                      </label>
                      <div className="col-12 col-lg-4 col-xl-4">
                        <Field
                          type="text"
                          className={`form-control form-control-sm ${getErrorCssClassName(
                            touched,
                            errors,
                            "title"
                          )}`}
                          name={`title`}
                        />

                        <ErrorComponent
                          fieldName={`title`}
                          touched={touched}
                          error={errors}
                        />
                      </div>

                      <label className="col-form-label edit-page-label-required col-12 col-lg-2 col-xl-2">
                        Review Date
                      </label>
                      <div className="col-12 col-lg-4 col-xl-4">
                        <DatePicker
                          value={
                            values.reviewDate
                              ? getDisplayDate(values.reviewDate)
                              : ""
                          }
                          selected={values.reviewDate}
                          onChange={e => {
                            setFieldValue(`reviewDate`, e);
                          }}
                          utcOffset={0}
                          placeholderText="dd-mm-yyyy"
                          className={`form-control form-control-sm ${getErrorCssClassName(
                            touched,
                            errors,
                            "reviewDate"
                          )}`}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <ErrorComponent
                          fieldName={`reviewDate`}
                          touched={touched}
                          error={errors}
                          datePicker={true}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-form-label edit-page-label col-12 col-lg-2 col-xl-2">
                        Approved Amount
                      </label>
                      <div className="col-12 col-lg-4 col-xl-4">
                        <Field
                          type="text"
                          className={`form-control form-control-sm ${getErrorCssClassName(
                            touched,
                            errors,
                            "amountSanctioned"
                          )}`}
                          name={`amountSanctioned`}
                        />
                        <ErrorComponent
                          fieldName={`amountSanctioned`}
                          touched={touched}
                          error={errors}
                        />
                      </div>
                      <label className="col-form-label edit-page-label-required col-12 col-lg-2 col-xl-2">
                        Status
                      </label>
                      <div className="col-12 col-lg-4 col-xl-4">
                        <Field
                          component="select"
                          type="text"
                          className={`custom-select custom-select-sm ${getErrorCssClassName(
                            touched,
                            errors,
                            "status"
                          )}`}
                          name={`status`}
                        >
                          <option value="">Select...</option>
                          {GRANT_AWARD_REVIEW_STATUS.map((opt, i) => (
                            <option key={i} value={opt.key}>
                              {opt.value}
                            </option>
                          ))}
                        </Field>
                        <ErrorComponent
                          fieldName={`status`}
                          touched={touched}
                          error={errors}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <ChecklistTemplate
                          values={values}
                          errors={errors}
                          uniqueKey={data._id || "New"}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        {this.state.customAlertMessage ? (
                          <div
                            className="alert alert-success alert-dismissible fade show"
                            role="alert"
                          >
                            {this.state.customAlertMessage}
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                              onClick={this.closeDefaultAlert}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        ) : null}

                        <DocumentTemplate
                          values={values}
                          errors={errors}
                          documentTypes={docTypes}
                          handleDelete={this.handleDelete}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-12 col-lg-6 col-xl-6">
                        <strong className="edit-page-section-header-text d-block">
                          Reviewers
                        </strong>

                        <div className="card border-0">
                          <FieldArray
                            name={`reviewers`}
                            render={({ remove, push }) => {
                              const reviewers = values.reviewers || [];
                              return (
                                <Fragment>
                                  <ul
                                    className="list-group mb-3"
                                    style={{ minHeight: "100px" }}
                                  >
                                    {reviewers.length === 0 ? (
                                      <li
                                        key={`000`}
                                        className="list-group-item border-0 d-flex justify-content-between align-items-center pb-1 pt-1 mb-1 mt-1"
                                      >
                                        <span className="text-muted">
                                          No Reviewers entered
                                        </span>
                                      </li>
                                    ) : null}
                                    {reviewers.map((reviewer, reviewerIdx) => {
                                      return (
                                        <li
                                          key={`${reviewerIdx}`}
                                          className="list-group-item border-0 d-flex justify-content-between align-items-center pb-1 pt-1 mb-1 mt-1"
                                        >
                                          <span>
                                            {`${reviewer.name} (${
                                              reviewer.organization
                                            })`}
                                          </span>
                                          <div>
                                            <i
                                              className="far fa-trash-alt "
                                              style={{
                                                cursor: "pointer"
                                              }}
                                              onClick={() =>
                                                remove(reviewerIdx)
                                              }
                                            />
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  <div className="mt-auto">
                                    <div className="input-group w-100">
                                      <Field
                                        type="text"
                                        className="form-control form-control-sm w-25"
                                        name={`reviewerName`}
                                        placeholder="Name"
                                      />
                                      &nbsp;
                                      <Field
                                        type="text"
                                        className="form-control form-control-sm w-25"
                                        name={`reviewerOrganization`}
                                        placeholder="Organization"
                                      />
                                      &nbsp;
                                      <button
                                        className="btn btn-outline-info btn-sm"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Add"
                                        type="button"
                                        onClick={() => {
                                          if (
                                            values.reviewerName &&
                                            values.reviewerOrganization
                                          ) {
                                            push({
                                              name: values.reviewerName,
                                              organization:
                                                values.reviewerOrganization
                                            });

                                            setFieldValue("reviewerName", "");
                                            setFieldValue(
                                              "reviewerOrganization",
                                              ""
                                            );
                                          }
                                        }}
                                      >
                                        <i className="fas fa-plus " />
                                      </button>
                                      &nbsp;
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6 col-xl-6">
                        <strong className="edit-page-section-header-text d-block">
                          Reviewer Comments
                        </strong>

                        <Field
                          component="textarea"
                          className={`form-control form-control-sm mt-3 ${getErrorCssClassName(
                            touched,
                            errors,
                            "reviewerComments"
                          )}`}
                          name={`reviewerComments`}
                          rows="6"
                          value={values.reviewerComments || ""}
                        />
                        <ErrorComponent
                          fieldName={`reviewerComments`}
                          touched={touched}
                          error={errors}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-12">
                        <strong className="edit-page-section-header-text d-block">
                          Incubator Comments
                        </strong>
                        <Field
                          component="textarea"
                          className={`form-control form-control-sm mt-3 ${getErrorCssClassName(
                            touched,
                            errors,
                            "incubatorComments"
                          )}`}
                          name={`incubatorComments`}
                          rows="6"
                          value={values.incubatorComments || ""}
                        />
                        <ErrorComponent
                          fieldName={`incubatorComments`}
                          touched={touched}
                          error={errors}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-center">
                    <button
                      className="btn btn-sm btn-outline-secondary mr-2"
                      type="button"
                      onClick={this.props.cancelReview}
                    >
                      Cancel
                    </button>
                    <button className="btn btn-sm btn-primary" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

export default GrantAwardReviewForm;
