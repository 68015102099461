import ErrorPage from "./error.page";
import React from "react";

const DEFAULT_MESSAGE = `Either the page that you are looking for is not present or 
you don't have enough permissions to access this page.`;

function UnauthorizedPage(props) {
  return <ErrorPage message={props.message || DEFAULT_MESSAGE} />;
}

export default UnauthorizedPage;
