import React, { Component, Fragment } from "react";
import AlertComponent from "../../common/AlertComponent";
import AssetReturnForm from "./AssetReturnForm";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import HttpUtil from "../../common/HttpUtil";
import { ASSET_LEASES_API, ASSET_RETURN_API } from "../../common/Constants";
import {
  ASSET_CATEGORIES_OBJECT,
  RENTAL_TYPES_OBJECT,
  STATUS_LIST_OBJECT
} from "../../common/LookupConstants";
import * as Yup from "yup";
import { ReturnPreviewForm } from "./ReturnPreviewForm";

const ASSET_RETURN_SCHEMA = Yup.object().shape({
  receivedBy: Yup.string()
    .label("Received By")
    .required(),
  damaged: Yup.boolean()
    .label("Damaged")
    .optional(),
  damages: Yup.string().when("damaged", {
    is: true,
    then: Yup.string()
      .label("Damages")
      .required()
  }),
  notes: Yup.string()
    .label("Notes")
    .required()
});

const MAX_UPLOAD_DOCUMENT = 5;

class AssetReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: null,
      assetReturn: {},

      searchAssetId: null,
      form: {
        previewForm: false,
        editForm: true
      },

      showMessageComponent: false,
      showMessageComponentMsg: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleNext = (values, { setSubmitting }) => {
    setSubmitting(false);
    this.setState({
      assetReturn: values,
      form: {
        previewForm: true,
        editForm: false
      }
    });
  };

  handlePrev = () => {
    const assetReturn = { ...this.state.assetReturn };
    this.setState({
      assetReturn,
      form: {
        previewForm: false,
        editForm: true
      }
    });
  };

  handleNewRequest = () => {
    this.setState({
      searchResult: null,
      assetReturn: {},
      form: {
        previewForm: false,
        editForm: true
      },
      searchAssetId: null,
      showMessageComponent: false,
      showMessageComponentMsg: null
    });
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getFormData = () => {
    const assetReturn = { ...this.state.assetReturn };

    let formData = new FormData();

    const documents = assetReturn.documents || [];
    for (let i = 0; i < MAX_UPLOAD_DOCUMENT; i++) {
      this.addDocs(formData, "documents", documents[i]);
    }

    const returnRequest = {
      lease: assetReturn.lease._id,
      asset: assetReturn.asset._id,
      receivedBy: assetReturn.receivedBy,
      damaged: assetReturn.damaged,
      damages: assetReturn.damages,
      notes: assetReturn.notes
    };

    formData.append("message", JSON.stringify(returnRequest));

    return formData;
  };

  handleSubmit = () => {
    const url = ASSET_RETURN_API;

    const formData = this.getFormData();

    HttpUtil.post(
      url,
      {},
      formData,
      data => {
        this.setState({
          assetReturn: {},
          form: {
            previewForm: false,
            editForm: false
          },
          searchAssetId: null,
          showMessageComponent: true,
          showMessageComponentMsg: "Asset Return Request is Created"
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSearch = () => {
    const searchAssetId = this.state.searchAssetId;
    if (!searchAssetId) {
      return;
    }
    const url = `${ASSET_LEASES_API}/search?assetId=${searchAssetId}`;

    HttpUtil.get(
      url,
      {},
      data => {
        if (data) {
          const asset = {
            _id: data._id,
            assetId: data.assetId,
            assetName: data.assetName,
            assetType: data.assetType,
            assetCategory: ASSET_CATEGORIES_OBJECT[data.assetCategory],
            ownerName: data.ownerName,
            rentalType: RENTAL_TYPES_OBJECT[data.rentalType],
            status: STATUS_LIST_OBJECT[data.status],
            rentalStatus: data.rentalStatus
          };

          const leasedAssets = data.assetLease.leasedAssets;
          let startDate = "",
            endDate = "";
          for (let i = 0; i < leasedAssets.length; i++) {
            const leasedAsset = leasedAssets[i];
            if (leasedAsset.asset === data._id) {
              startDate = getDisplayDate(
                convertNumberToDate(leasedAsset.startDate)
              );
              endDate = getDisplayDate(
                convertNumberToDate(leasedAsset.endDate)
              );
              break;
            }
            break;
          }

          const lease = {
            _id: data._id,
            name: data.assetLease.requestor.name,
            requestorType: data.assetLease.requestorType,
            startDate,
            endDate
          };

          const assetReturn = {
            lease,
            asset,
            receivedBy: "",
            damaged: false, // false true
            damages: "",
            notes: "",
            createdAt: getDisplayDate(new Date())
          };
          this.setState({
            assetReturn,
            searchResult: 1
          });
        } else {
          this.setState({ searchResult: 0 });
        }
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };
  render() {
    const { form, showMessageComponent } = this.state;

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const props = {
      initialValues: this.state.assetReturn,
      validationSchema: ASSET_RETURN_SCHEMA,

      searchAssetId: this.state.searchAssetId,
      searchResult: this.state.searchResult,
      form: this.state.form,
      onChange: this.onChange,
      handleSearch: this.handleSearch,
      handleNext: this.handleNext
    };

    const previewFormProps = {
      values: this.state.assetReturn,
      handlePrev: this.handlePrev,
      handleSubmit: this.handleSubmit
    };

    const messageComponentProps = {
      message: this.state.showMessageComponentMsg,
      handleNewRequest: this.handleNewRequest
    };

    let renderForm = "";

    if (showMessageComponent) {
      renderForm = <MessageComponent {...messageComponentProps} />;
    }

    if (form.editForm) {
      renderForm = <AssetReturnForm {...props} />;
    }

    if (form.previewForm) {
      renderForm = <ReturnPreviewForm {...previewFormProps} />;
    }

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        {/* <AssetReturnForm {...props} /> */}
        {renderForm}
      </Fragment>
    );
  }
}

export default AssetReturn;

export const MessageComponent = props => {
  return (
    <div className="col-md-8">
      <div className="container mb-5">
        <div className="card">
          <div className="card-header bg-card-header text-white">
            <span className="card-title mb-0">Return Request Form</span>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <div className="col-md-12 text-center">
                <h5>{props.message}</h5>
              </div>
            </div>

            <div className="form-group row mt-5">
              <div className="col-md-12 text-center">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={props.handleNewRequest}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
