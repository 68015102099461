import React, { Component, Fragment } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import Link from "react-router-dom/Link";
import FieldTemplateForm from "./FieldTemplateForm";
import {
  validateClosureTemplate,
  validateTerminationTemplate,
  validateReviewTemplate
} from "../FormikCustomValidation";

function validateFn(values) {
  const errors = {};

  validateReviewTemplate(errors, values.reviewTemplate);
  validateClosureTemplate(errors, values.closureTemplate);
  validateTerminationTemplate(errors, values.terminationTemplate);

  if (!values.name) {
    errors.name = "Template Name is required field";
  }

  return errors;
}

const DocumentTemplate = props => {
  const { values, errors, touched, templateName } = props;

  const readOnly = values.status === "FINALIZE";

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name={`${templateName}.documents`}
      render={({ remove, push }) => {
        const documentsErrors =
          getIn(errors, `${templateName}.documents`) || [];

        const documentsTouched =
          getIn(touched, `${templateName}.documents`) || [];

        const documents = values[templateName].documents || [];

        return (
          <Fragment>
            <div className="col-12 col-md-6">
              <strong className="border-bottom pb-1 d-block mb-2">
                Documents
              </strong>
              <div>
                {documents.length > 0 ? (
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th
                          className="display-table-header"
                          style={{
                            width: "80%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)"
                          }}
                          scope="col"
                        >
                          Document Type
                        </th>
                        <th
                          className="text-center display-table-header"
                          style={{
                            width: "30.33%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)"
                          }}
                          scope="col"
                        >
                          Required
                        </th>
                        <th
                          className="text-center display-table-header"
                          style={{
                            width: "33.33%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)"
                          }}
                          scope="col"
                        >
                          Multiple
                        </th>
                        <th
                          className="text-center display-table-header"
                          scope="col"
                          style={{
                            backgroundColor: "rgba(0, 0, 0, 0.05)"
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.map((doc, index) => {
                        const error = documentsErrors[index] || {};
                        const touch = documentsTouched[index] || {};

                        return (
                          <tr key={index}>
                            <td className="align-middle pl-0">
                              <div>
                                <Field
                                  type="text"
                                  className={`form-control form-control-sm ${
                                    touch["documentType"] &&
                                    error["documentType"]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  title="Enter Document Type"
                                  name={`${templateName}.documents.${index}.documentType`}
                                  value={doc.documentType || ""}
                                  placeholder="Enter Document Type"
                                  {...disabledField}
                                />
                                <div className="invalid-feedback">
                                  &nbsp;{" "}
                                  {touch["documentType"] &&
                                    error["documentType"]}
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-center">
                              <div
                                className="custom-control custom-checkbox"
                                title="Required Document"
                              >
                                <Field
                                  type="checkbox"
                                  className={`custom-control-input ${
                                    touch["required"] && error["required"]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name={`${templateName}.documents.${index}.required`}
                                  checked={doc.required || ""}
                                  id={`${templateName}.documents.${index}.required`}
                                  {...disabledField}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`${templateName}.documents.${index}.required`}
                                />
                                <div className="invalid-feedback">
                                  &nbsp;{touch["required"] && error["required"]}
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-center">
                              <div
                                className="custom-control custom-checkbox"
                                title="Allow Multiple Documents"
                              >
                                <Field
                                  type="checkbox"
                                  className={`custom-control-input ${
                                    touch["allowMultiple"] &&
                                    error["allowMultiple"]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name={`${templateName}.documents.${index}.allowMultiple`}
                                  checked={doc.allowMultiple || ""}
                                  id={`${templateName}.documents.${index}.allowMultiple`}
                                  {...disabledField}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`${templateName}.documents.${index}.allowMultiple`}
                                />
                                <div className="invalid-feedback">
                                  &nbsp;{" "}
                                  {touch["allowMultiple"] &&
                                    error["allowMultiple"]}
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-center">
                              {readOnly ? null : (
                                <i
                                  className="far fa-trash-alt text-danger"
                                  title="Remove"
                                  onClick={() => remove(index)}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  "No Document template entered."
                )}

                {readOnly ? null : (
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-sm btn-outline-info custom-btn"
                        type="button"
                        onClick={() =>
                          push({
                            documentType: "",
                            allowMultiple: false,
                            required: false
                          })
                        }
                      >
                        <i className="fas fa-plus mr-2"></i>Add More
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
};

const ChecklistTemplate = props => {
  const { values, errors, touched } = props;
  const labels = props.labels || [];

  const readOnly = values.status === "FINALIZE";

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name={`${props.fieldArrayName}`}
      render={({ remove, push }) => {
        const labelErrors = getIn(errors, props.fieldArrayName) || [];
        const labelTouched = getIn(touched, props.fieldArrayName) || [];

        return (
          <Fragment>
            <div className="col-12 col-md-6">
              <strong className="border-bottom pb-1 d-block mb-2">
                {props.fieldTitle}
              </strong>
              <div>
                {labels.length > 0 ? (
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th
                          className="display-table-header"
                          style={{
                            width: "60.33%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)"
                          }}
                          scope="col"
                        >
                          Label
                        </th>
                        <th
                          className="text-center display-table-header"
                          style={{
                            width: "30.33%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)"
                          }}
                          scope="col"
                        >
                          Required
                        </th>

                        <th
                          className="text-center"
                          scope="col"
                          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {labels.map((lb, index) => {
                        const error = labelErrors[index] || {};
                        const touch = labelTouched[index] || {};
                        return (
                          <tr key={index}>
                            <td className="align-middle pl-0">
                              <div>
                                <Field
                                  type="text"
                                  className={`form-control form-control-sm ${
                                    touch["label"] && error["label"]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  title="Enter Label Name"
                                  name={`${props.fieldArrayName}.${index}.label`}
                                  value={lb.label || ""}
                                  placeholder="Enter Label Name"
                                  {...disabledField}
                                />
                                <div className="invalid-feedback">
                                  {touch["label"] && error["label"]}
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-center">
                              <div
                                className="custom-control custom-checkbox"
                                title="Required Field"
                              >
                                <Field
                                  type="checkbox"
                                  className={`custom-control-input ${
                                    touch["required"] && error["required"]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name={`${props.fieldArrayName}.${index}.required`}
                                  checked={lb.required || ""}
                                  id={`${props.fieldArrayName}.${index}.required`}
                                  {...disabledField}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`${props.fieldArrayName}.${index}.required`}
                                />
                                <div className="invalid-feedback">
                                  {touch["required"] && error["required"]}
                                </div>
                              </div>
                            </td>

                            <td className="align-middle text-center">
                              {readOnly ? null : (
                                <i
                                  className="far fa-trash-alt text-danger"
                                  title="Remove"
                                  onClick={() => remove(index)}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  "No Checklist template entered."
                )}

                {readOnly ? null : (
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-sm btn-outline-info custom-btn"
                        type="button"
                        onClick={() =>
                          push({
                            label: "",
                            required: false
                          })
                        }
                      >
                        <i className="fas fa-plus mr-2"></i>Add More
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
};

const CustomForm = props => {
  const { values, setFieldValue, touched, errors } = props;
  let title = "New Grant Progress Template";
  if (props.values.incubator) {
    title = `Grant Progress Template - ${props.values.name}`;
  }

  const readOnly = values.status === "FINALIZE";

  return (
    <Fragment>
      <Form>
        <div className="card edit-page-container">
          <div className="card-header border-bottom-0 pt-3">
            <div className="edit-page-title text-center">
              <span>
                <Link
                  to="/funding/grant-progress-template"
                  className="badge bg-white custom-btn-sm float-left"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
              </span>
              <span className="">{title}</span>
            </div>
          </div>
          <div className="card-body ">
            {readOnly ? null : (
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                      Template Name
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                      <Field
                        type="text"
                        name="name"
                        value={props.values.name || ""}
                        className={`form-control form-control-sm ${
                          touched["name"] && errors["name"] ? "is-invalid" : ""
                        }`}
                        placeholder="Untitled Template"
                      />
                      <div className="invalid-feedback">
                        {touched["name"] && errors["name"]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12 mb-3 edit-page-section-header-text">
                Review Template
              </div>
            </div>
            <div className="row">
              <DocumentTemplate templateName="reviewTemplate" {...props} />
              <ChecklistTemplate
                labels={props.values.reviewTemplate.checklist}
                fieldArrayName="reviewTemplate.checklist"
                fieldTitle="Checklist"
                {...props}
              />
            </div>
            <div className="row mt-4">
              <div className="col-12 mb-3 edit-page-section-header-text">
                Closure Template
              </div>
            </div>
            <div className="row">
              <DocumentTemplate templateName="closureTemplate" {...props} />
              <ChecklistTemplate
                labels={props.values.closureTemplate.checklist}
                fieldArrayName="closureTemplate.checklist"
                fieldTitle="Checklist"
                {...props}
              />
              <FieldTemplateForm
                fieldTemplates={props.values.closureTemplate.fieldTemplates}
                fieldArrayName="closureTemplate.fieldTemplates"
                {...props}
              />
            </div>

            <div className="row mt-4">
              <div className="col-12 mb-3 edit-page-section-header-text">
                Termination Template
              </div>
            </div>
            <div className="row">
              <DocumentTemplate templateName="terminationTemplate" {...props} />
              <ChecklistTemplate
                labels={props.values.terminationTemplate.checklist}
                fieldArrayName="terminationTemplate.checklist"
                fieldTitle="Checklist"
                {...props}
              />
            </div>
          </div>

          {readOnly ? null : (
            <div className="card-footer">
              <div className="text-center">
                <button
                  className="btn btn-sm btn-primary"
                  type="submit"
                  onClick={() => setFieldValue("action", "DRAFT")}
                >
                  Save as Draft
                </button>
                <button
                  className="btn btn-sm btn-primary ml-2"
                  type="submit"
                  onClick={() => setFieldValue("action", "FINALIZE")}
                >
                  Finalize Template
                </button>
              </div>
            </div>
          )}
        </div>
      </Form>
    </Fragment>
  );
};

class GrantProgressTemplateForm extends Component {
  render() {
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          // validationSchema={this.props.validationSchema}
          validate={values => validateFn(values)}
          // validate={values => validateFn(values, props.template)}
          onSubmit={(values, { setSubmitting }) =>
            this.props.handleSubmit(values, { setSubmitting })
          }
          render={renderProps => (
            <CustomForm {...renderProps} {...this.props} />
          )}
        />
      </div>
    );
  }
}

export default GrantProgressTemplateForm;
