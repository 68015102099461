import { Field } from "formik";
import React, { Fragment } from "react";

function PromoterExportSelectOptions({
  values,
  promotersFields,
  setFieldValue
}) {
  function handlePromotersSelectAll(e) {
    const checked = e.target.checked;
    const proFields = {
      idDocs: checked,
      basicInfo: checked,
      contacts: checked,
      education: checked,
      basicInfoFields: {
        founders: checked,
        designation: checked,
        shareholding: checked,
        dob: checked,
        gender: checked,
        experience: checked,
        references: checked
      },
      contactsFields: { mobile: checked, email: checked, address: checked },
      idDocsFields: { aadhar: checked, pan: checked },
      educationFields: {
        qualification: checked,
        degree: checked,
        institution: checked,
        alumni: checked,
        year: checked,
        branch: checked
      }
    };
    setFieldValue("selectOptions.promotersFields", proFields);
    setFieldValue("selectOptions.promoters", checked);
  }
  function handlePromoterBasicInfoSelectAll(e) {
    const checked = e.target.checked;
    const proBasicInfo = {
      founders: checked,
      designation: checked,
      shareholding: checked,
      dob: checked,
      gender: checked,
      experience: checked,
      references: checked
    };
    setFieldValue(
      "selectOptions.promotersFields.basicInfoFields",
      proBasicInfo
    );
    setFieldValue("selectOptions.promotersFields.basicInfo", checked);
  }
  function handlePromoterContactSelectAll(e) {
    const checked = e.target.checked;
    const proConntacts = { mobile: checked, email: checked, address: checked };
    setFieldValue("selectOptions.promotersFields.contactsFields", proConntacts);
    setFieldValue("selectOptions.promotersFields.contacts", checked);
  }
  function handleDocsSelectAll(e) {
    const checked = e.target.checked;
    const proDocs = { aadhar: checked, pan: checked };
    setFieldValue("selectOptions.promotersFields.idDocsFields", proDocs);
    setFieldValue("selectOptions.promotersFields.idDocs", checked);
  }
  function handleDocsEducationSelectAll(e) {
    const checked = e.target.checked;
    const proEdu = {
      qualification: checked,
      degree: checked,
      institution: checked,
      alumni: checked,
      year: checked,
      branch: checked
    };
    setFieldValue("selectOptions.promotersFields.educationFields", proEdu);
    setFieldValue("selectOptions.promotersFields.education", checked);
  }
  return (
    <>
      <div className="form-group row mt-3 mb-3">
        <div className="custom-control custom-checkbox">
          <Field
            type="checkbox"
            className="custom-control-input"
            id="promoter"
            name="selectOptions.promoters"
            value={values.selectOptions.promoters}
            checked={values.selectOptions.promoters}
            onChange={handlePromotersSelectAll}
          />
          <label className="custom-control-label" htmlFor="promoter">
            <strong>Promoters (Organization)</strong>
          </label>
        </div>
      </div>
      {/* Basic Info Fields */}
      <Fragment>
        <div className="form-group row mt-3 mb-3 ml-1">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="probasicInfo"
              name="selectOptions.promotersFields.basicInfo"
              value={values.selectOptions.promotersFields.basicInfo}
              checked={values.selectOptions.promotersFields.basicInfo}
              onChange={handlePromoterBasicInfoSelectAll}
            />
            <label className="custom-control-label" htmlFor="probasicInfo">
              <strong>Basic information</strong>
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="profounders"
              name="profounders"
              value={promotersFields.basicInfoFields.founders}
              checked={promotersFields.basicInfoFields.founders}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.basicInfoFields.founders",
                  e.target.checked
                )
              }
            />
            <label className="custom-control-label" htmlFor="profounders">
              Founders
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="prodesignation"
              name="prodesignation"
              value={promotersFields.basicInfoFields.designation}
              checked={promotersFields.basicInfoFields.designation}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.basicInfoFields.designation",
                  e.target.checked
                )
              }
            />
            <label className="custom-control-label" htmlFor="prodesignation">
              Designation
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="proshareholding"
              name="proshareholding"
              value={promotersFields.basicInfoFields.shareholding}
              checked={promotersFields.basicInfoFields.shareholding}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.basicInfoFields.shareholding",
                  e.target.checked
                )
              }
            />
            <label className="custom-control-label" htmlFor="proshareholding">
              Shareholding
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="progender"
              name="progender"
              value={promotersFields.basicInfoFields.gender}
              checked={promotersFields.basicInfoFields.gender}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.basicInfoFields.gender",
                  e.target.checked
                )
              }
            />
            <label className="custom-control-label" htmlFor="progender">
              Gender
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="prodob"
              name="prodob"
              value={promotersFields.basicInfoFields.dob}
              checked={promotersFields.basicInfoFields.dob}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.basicInfoFields.dob",
                  e.target.checked
                )
              }
            />
            <label className="custom-control-label" htmlFor="prodob">
              Date Of birth
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="proexperience"
              name="proexperience"
              value={promotersFields.basicInfoFields.experience}
              checked={promotersFields.basicInfoFields.experience}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.basicInfoFields.experience",
                  e.target.checked
                )
              }
            />
            <label className="custom-control-label" htmlFor="proexperience">
              Experience
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="proreferences"
              name="proreferences"
              value={promotersFields.basicInfoFields.references}
              checked={promotersFields.basicInfoFields.references}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.basicInfoFields.references",
                  e.target.checked
                )
              }
            />
            <label className="custom-control-label" htmlFor="proreferences">
              References
            </label>
          </div>
        </div>
      </Fragment>
      {/* Contact Info */}
      <Fragment>
        <div className="form-group row mt-3 mb-3 ml-1">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="procontacts"
              name="selectOptions.promotersFields.contacts"
              value={values.selectOptions.promotersFields.contacts}
              checked={values.selectOptions.promotersFields.contacts}
              onClick={handlePromoterContactSelectAll}
            />
            <label className="custom-control-label" htmlFor="procontacts">
              <strong>Contact Information</strong>
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.contactsFields.mobile"
              name="promotersFields.contactsFields.mobile"
              value={promotersFields.contactsFields.mobile}
              checked={promotersFields.contactsFields.mobile}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.contactsFields.mobile",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.contactsFields.mobile"
            >
              Phone
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.contactsFields.email"
              name="promotersFields.contactsFields.email"
              value={promotersFields.contactsFields.email}
              checked={promotersFields.contactsFields.email}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.contactsFields.email",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.contactsFields.email"
            >
              Email
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.contactsFields.address"
              name="promotersFields.contactsFields.address"
              value={promotersFields.contactsFields.address}
              checked={promotersFields.contactsFields.address}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.contactsFields.address",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.contactsFields.address"
            >
              Address
            </label>
          </div>
        </div>
      </Fragment>
      <Fragment>
        <div className="form-group row mt-3 mb-3 ml-1">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="proDocs"
              name="selectOptions.promotersFields.idDocs"
              value={values.selectOptions.promotersFields.idDocs}
              checked={values.selectOptions.promotersFields.idDocs}
              onClick={handleDocsSelectAll}
            />
            <label className="custom-control-label" htmlFor="proDocs">
              <strong>Identification Docs</strong>
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.idDocsFields.aadhar"
              name="promotersFields.idDocsFields.aadhar"
              value={promotersFields.idDocsFields.aadhar}
              checked={promotersFields.idDocsFields.aadhar}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.idDocsFields.aadhar",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.idDocsFields.aadhar"
            >
              Aadhar
            </label>
          </div>
        </div>

        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.idDocsFields.pan"
              name="promotersFields.idDocsFields.pan"
              value={promotersFields.idDocsFields.pan}
              checked={promotersFields.idDocsFields.pan}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.idDocsFields.pan",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.idDocsFields.pan"
            >
              PAN
            </label>
          </div>
        </div>
      </Fragment>
      <Fragment>
        <div className="form-group row mt-3 mb-3 ml-1">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="proEdu"
              name="selectOptions.promotersFields.education"
              value={values.selectOptions.promotersFields.education}
              checked={values.selectOptions.promotersFields.education}
              onClick={handleDocsEducationSelectAll}
            />
            <label className="custom-control-label" htmlFor="proEdu">
              <strong>Education</strong>
            </label>
          </div>
        </div>

        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.educationFields.qualification"
              name="promotersFields.educationFields.qualification"
              value={promotersFields.educationFields.qualification}
              checked={promotersFields.educationFields.qualification}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.educationFields.qualification",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.educationFields.qualification"
            >
              Qualification
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.educationFields.degree"
              name="promotersFields.educationFields.degree"
              value={promotersFields.educationFields.degree}
              checked={promotersFields.educationFields.degree}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.educationFields.degree",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.educationFields.degree"
            >
              Degree
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.educationFields.institution"
              name="promotersFields.educationFields.institution"
              value={promotersFields.educationFields.institution}
              checked={promotersFields.educationFields.institution}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.educationFields.institution",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.educationFields.institution"
            >
              Institution
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.educationFields.alumni"
              name="promotersFields.educationFields.alumni"
              value={promotersFields.educationFields.alumni}
              checked={promotersFields.educationFields.alumni}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.educationFields.alumni",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.educationFields.alumni"
            >
              Alumni
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.educationFields.year"
              name="promotersFields.educationFields.year"
              value={promotersFields.educationFields.year}
              checked={promotersFields.educationFields.year}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.educationFields.year",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.educationFields.year"
            >
              Year
            </label>
          </div>
        </div>
        <div className="form-group row mb-0 ml-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="promotersFields.educationFields.branch"
              name="promotersFields.educationFields.branch"
              value={promotersFields.educationFields.branch}
              checked={promotersFields.educationFields.branch}
              onChange={e =>
                setFieldValue(
                  "selectOptions.promotersFields.educationFields.branch",
                  e.target.checked
                )
              }
            />
            <label
              className="custom-control-label"
              htmlFor="promotersFields.educationFields.branch"
            >
              Branch
            </label>
          </div>
        </div>
      </Fragment>
    </>
  );
}

export default PromoterExportSelectOptions;
