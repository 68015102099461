import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";
import PartitionForm from "./PartitionForm";
import HttpUtil from "../../common/HttpUtil";
import * as Yup from "yup";
import {
  PARTITION_TYPE_LIST_API,
  STATUS_LIST_API,
  INCUBATORS_API,
  FACILITY_CLUSTERS_API,
  AREA_UNIT_LIST_API,
  PARTITION_API,
  PARTITION_CATEGORIES_API
} from "../../common/Constants";
import Breadcrumb from "../../common/Breadcrumb";
import PartitionHistory from "./PartitionHistory";
import ErrorPage from "../../common/error.page";

function greaterThanOrEqualTo(ref, refLabel, msg) {
  return this.test({
    name: "greaterThanOrEqualTo",
    exclusive: "false",
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || "${path} must be greater than or equal to ${reference}",
    params: {
      reference: refLabel || ref.path
    },
    test: function(value) {
      return value >= this.resolve(ref);
    }
  });
}

Yup.addMethod(Yup.number, "greaterThanOrEqualTo", greaterThanOrEqualTo);

const PARTITION_SCHEMA = Yup.object().shape({
  campus: Yup.object().shape({
    value: Yup.string()
      .label("Campus")
      .required()
  }),
  building: Yup.object().shape({
    value: Yup.string()
      .label("Building")
      .required()
  }),
  floor: Yup.object().shape({
    value: Yup.string()
      .label("Floor")
      .required()
  }),

  category: Yup.object().shape({
    value: Yup.string()
      .label("Partition Category")
      .required()
  }),

  rentablePartitionSelected: Yup.boolean().optional(), // for validation purpose

  partitionName: Yup.string()
    .label("Partition Name")
    .required(),

  status: Yup.string()
    .label("Status")
    .required(),
  partitionOwner: Yup.string()
    .label("Partition Owner")
    .required(),

  area: Yup.number().test(
    "area",
    "Area should be a positive number",
    value => value && Number(value) > 0
  ),
  areaUnit: Yup.string()
    .label("Area Unit")
    .required(),

  partitionType: Yup.string().when("rentablePartitionSelected", {
    is: true,
    then: Yup.string()
      .label("Partition Type")
      .required()
  }),

  rentTaxRate: Yup.number().when("rentablePartitionSelected", {
    is: true,
    then: Yup.number().when("rentTaxable", {
      is: true,
      then: Yup.number().test(
        "rentTaxRate",
        "Rent Tax Rate should be a positive number",
        value => value && Number(value) > 0
      )
    })
  }),

  areaPrice: Yup.number().when("rentablePartitionSelected", {
    is: true,
    then: Yup.number().when("partitionType", {
      is: "RENTAL",
      then: Yup.number()
        .label("Price (Rs/SQFT)")
        .test(
          "areaPrice",
          "Price should be a positive number",
          value => value && Number(value) > 0
        )
    })
  }),

  seatCount: Yup.number().when("rentablePartitionSelected", {
    is: true,
    then: Yup.number().when("partitionType", {
      is: "SEATER",
      then: Yup.number()
        .label("Seat Count")
        .test(
          "seatCount",
          "Seat Count should be a positive number",
          value => value && Number(value) > 0
        )
    })
  }),

  seaterPrice: Yup.number().when("rentablePartitionSelected", {
    is: true,
    then: Yup.number().when("partitionType", {
      is: "SEATER",
      then: Yup.number()
        .label("Seat Count")
        .test(
          "seaterPrice",
          "Seater Price should be a positive number",
          value => value && Number(value) > 0
        )
    })
  })

  // maxSeatingCapacity: Yup.number().when("partitionType", {
  //   is: "SEATER",
  //   then: Yup.number()
  //     .label("Max Seating Capacity")
  //     .greaterThanOrEqualTo(Yup.ref("seatCount"), "Seat Count")
  // })

  // maxSeatingCapacity: Yup.number().when("rentablePartitionSelected", {
  //   is: true,
  //   then: Yup.number().when("partitionType", {
  //     is: "SEATER",
  //     then: Yup.number()
  //       .label("Max Seating Capacity")
  //       .greaterThanOrEqualTo(Yup.ref("seatCount"), "Seat Count")
  //   })
  // })
});

class Partition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        _id: null,
        floor: "",
        partitionOwner: "",
        facility: null,
        rentTaxable: false,
        rentTaxRate: 0,
        category: "",
        subCategory: "",
        rentablePartitionSelected: false, // for validation purpose
        partitionName: "",
        displayName: "",
        partitionType: "",
        status: "",
        area: 0,
        areaUnit: "",
        areaPrice: 0,
        seatCount: 0,
        maxSeatingCapacity: 0,
        seaterPrice: 0,
        remarks: "",
        partitionDimensions: [],
        partitionImages: [],
        previousPartitions: "",
        amenities: [],

        campus: "",
        building: ""
      },
      permanentFailure: false,

      partitionCategories: [],
      partitionTypes: [],
      statusList: [],
      incubators: [],
      areaUnits: [],

      newPartition: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      confirm: false
    });
  };

  getPartitionRequest = values => {
    const partition = {};

    partition.floor = values.floor.value;
    partition.partitionName = values.partitionName;
    partition.displayName = values.displayName;
    partition.area = values.area;
    partition.areaUnit = values.areaUnit;
    partition.status = values.status;
    partition.partitionOwner = values.partitionOwner;
    partition.facility = (values.facility && values.facility.value) || null;
    partition.remarks = values.remarks;
    partition.amenities = values.amenities;

    partition.partitionType = "";

    if (values.subCategory.value) {
      partition.subCategory = values.subCategory.value;
    }

    if (values.rentablePartitionSelected) {
      partition.rentTaxable = values.rentTaxable;
      partition.rentTaxRate = values.rentTaxable ? values.rentTaxRate : 0;
    } else {
      partition.rentTaxable = false;
      partition.rentTaxRate = 0;
    }

    if (values.rentablePartitionSelected) {
      partition.category = values.category.value;

      partition.partitionType = values.partitionType;

      if (values.partitionType === "SEATER") {
        partition.seatCount = values.seatCount || 0;
        partition.seaterPrice = values.seaterPrice || 0;
        partition.maxSeatingCapacity = values.maxSeatingCapacity || 0;
      } else if (values.partitionType === "RENTAL") {
        partition.areaPrice = values.areaPrice || 0;
      }
    } else if (values.category.value) {
      partition.category = values.category.value;
    }
    return partition;
  };

  createPartition = (values, setSubmitting) => {
    setSubmitting(true);
    const partition = this.getPartitionRequest(values);

    const url = PARTITION_API;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      partition,
      data => {
        setSubmitting(false);
        this.setState({
          editPartition: true,
          editPartitionId: data._id
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  updatePartition = (values, setSubmitting) => {
    setSubmitting(true);
    const partition = this.getPartitionRequest(values);

    const url = PARTITION_API + "/" + this.state.initialValues._id;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.put(
      url,
      headers,
      partition,
      data => {
        setSubmitting(false);
        const initialValues = this.setDataFromServer(data);
        this.setState({
          initialValues,
          newPartition: false,
          history:
            data.history && data.history.length > 1
              ? data.history.reverse()
              : [],
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Partition "${data.displayName}" has been updated now.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  handleSubmit = (values, { setSubmitting }) => {
    if (this.state.newPartition) {
      this.createPartition(values, setSubmitting);
    } else {
      this.updatePartition(values, setSubmitting);
    }
  };

  getPartitionTypeList = () => {
    const url = PARTITION_TYPE_LIST_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ partitionTypes: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getStatusList = () => {
    const url = `${STATUS_LIST_API}PARTITION`;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ statusList: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllIncubators = () => {
    const url = `${INCUBATORS_API}/orgs`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ incubators: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getFacilityClusters = () => {
    let url = FACILITY_CLUSTERS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        const allFacilities = data.map(facility => {
          const option = {
            label: `${facility.name} (${facility.type})`,
            value: facility._id
          };
          return option;
        });
        this.setState({ allFacilities });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAreaUnitList = () => {
    const url = AREA_UNIT_LIST_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ areaUnits: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllPartitionCategories = () => {
    const url = PARTITION_CATEGORIES_API;
    HttpUtil.get(
      url,
      {},
      data => {
        const partitionCategories = data.map(category => {
          let rentable = category.rentable ? "Rentable" : null;

          let option = {};
          if (rentable) {
            option.label = `${category.categoryName} (${rentable})`;
            option.value = category._id;
          } else {
            option.label = `${category.categoryName}`;
            option.value = category._id;
          }

          // add sub-categories
          if (category.subCategories) {
            option.subCategories = category.subCategories.map(scts => {
              return {
                label: scts.name,
                value: scts._id
              };
            });
          }

          option.rentable = category.rentable;

          return option;
        });
        this.setState({ partitionCategories });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  setDataFromServer = data => {
    let facility = {};
    if (data.facility && data.facility._id) {
      facility = {
        label: `${data.facility.name} (${data.facility.type})`,
        value: data.facility._id
      };
    }

    const floor = {
      label: data.floor.floorName,
      value: data.floor._id
    };

    let campus = {};

    if (data.floor.building.campus) {
      campus = {
        label: data.floor.building.campus.name,
        value: data.floor.building.campus._id
      };
    }

    let building = {};
    if (data.floor.building) {
      building = {
        label: data.floor.building.buildingName,
        value: data.floor.building._id
      };
    }

    const category = {};
    let subCategory = "";
    let rentablePartitionSelected = false;

    if (data.category) {
      let cty = data.category;

      let rentable = cty.rentable ? "Rentable" : null;
      if (rentable) {
        rentablePartitionSelected = cty.rentable;
        category.label = `${cty.categoryName} (${rentable})`;
        category.value = cty._id;
      } else {
        category.label = `${cty.categoryName}`;
        category.value = cty._id;
      }

      // add sub-categories
      if (cty.subCategories) {
        category.subCategories = cty.subCategories.map(scts => {
          if (data.subCategory === scts._id) {
            subCategory = {
              label: scts.name,
              value: scts._id
            };
          }
          return {
            label: scts.name,
            value: scts._id
          };
        });
      }

      category.rentable = cty.rentable ? true : false;
    }

    const initialValues = {
      _id: data._id,
      campus: campus,
      building: building,
      floor: floor,
      partitionOwner:
        data.partitionOwner && data.partitionOwner._id
          ? data.partitionOwner._id
          : "",
      facility: facility,
      rentTaxable: data.rentTaxable || false,
      rentTaxRate: data.rentTaxRate || 0,
      category,
      subCategory,
      rentablePartitionSelected,
      partitionName: data.partitionName,
      displayName: data.displayName,

      status: data.status,
      area: data.area,
      areaUnit: data.areaUnit,

      remarks: data.remarks,
      amenities: data.amenities
    };

    if (data.partitionType) {
      initialValues.partitionType = data.partitionType;
    }

    if (data.partitionType && data.partitionType === "SEATER") {
      initialValues.seatCount = data.seatCount || 0;
      initialValues.seaterPrice = data.seaterPrice || 0;
      initialValues.maxSeatingCapacity = data.maxSeatingCapacity || 0;
    } else if (data.partitionType && data.partitionType === "RENTAL") {
      initialValues.areaPrice = data.areaPrice || 0;
    }

    return initialValues;
  };

  getPartitionById = id => {
    const url = `${PARTITION_API}/${id}`;

    if (this.props.location.state && this.props.location.state.created) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: "New Partition has been added successfully."
      });
    }

    HttpUtil.get(
      url,
      {},
      data => {
        const breadCrumbList = [];

        const campusName = data.floor.building.campus.name;
        const campusId = data.floor.building.campus._id;
        const bctCampus = {
          displayName: campusName,
          linkTo: `/admin/campuses/edit/${campusId}`
        };
        breadCrumbList.push(bctCampus);

        const buildingName = data.floor.building.buildingName;
        const buildingId = data.floor.building._id;
        const bctBuilding = {
          displayName: buildingName,
          linkTo: `/admin/buildings/edit/${buildingId}`
        };
        breadCrumbList.push(bctBuilding);

        const bctFloor = {
          displayName: data.floor.floorName,
          linkTo: `/admin/floors/edit/${data.floor._id}`
        };
        breadCrumbList.push(bctFloor);

        const bctPartition = {
          displayName: data.partitionName,
          linkTo: null
        };
        breadCrumbList.push(bctPartition);

        const initialValues = this.setDataFromServer(data);

        this.setState({
          initialValues,
          newPartition: false,
          breadCrumbList: breadCrumbList,
          history:
            data.history && data.history.length > 1
              ? data.history.reverse()
              : []
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getPartitionById(id);
    } else {
      this.setState({ newPartition: true });
    }
    this.getPartitionTypeList();
    this.getStatusList();
    this.getAllIncubators();
    this.getFacilityClusters();
    this.getAreaUnitList();
    this.getAllPartitionCategories();
  };

  render() {
    const { newPartition, permanentFailure } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={this.state.alertMessage} />;
    }

    if (newPartition === null) {
      return null;
    }

    // redirect to partition edit page with id
    if (this.state.editPartition) {
      const locationState = {
        pathname: `/admin/partitions/edit/${this.state.editPartitionId}`,
        state: { created: true }
      };
      return <Redirect to={locationState} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const partitionFormProps = {
      initialValues: this.state.initialValues,
      validationSchema: PARTITION_SCHEMA,
      handleSubmit: this.handleSubmit,

      // extra
      partitionCategories: this.state.partitionCategories,
      newPartition: this.state.newPartition,
      partitionTypes: this.state.partitionTypes,
      statusList: this.state.statusList,
      incubators: this.state.incubators,
      allFacilities: this.state.allFacilities,
      areaUnits: this.state.areaUnits
    };

    return (
      <Fragment>
        {newPartition ? null : (
          <Breadcrumb breadCrumbList={this.state.breadCrumbList} />
        )}
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">
            <PartitionForm {...partitionFormProps} />

            <PartitionHistory
              statusList={this.state.statusList}
              partitionTypes={this.state.partitionTypes}
              areaUnits={this.state.areaUnits}
              history={this.state.history}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Partition;
