import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import HttpUtil from "../common/HttpUtil";
import {
  INCUBATEES_ONBOARD_API,
  INCUBATEE_APP_FORMS_INPROGRESS_API
} from "../common/Constants";
import { getDisplayDate } from "../lease/DateUtil";
import TableList from "../common/TableList";
import AlertComponent from "../common/AlertComponent";
import { AppFormStatus } from "../common/LookupConstants";
import Can from "../../auth/can";

class OnboardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardList: null,
      // redirect to edit page
      editOnboardForm: false,
      editOnboardFormById: "",

      viewFormById: "",
      viewForm: false,

      incubatorForm: null
    };
  }

  handleClick = (e, column, columnIndex, row, rowIndex) => {
    let id = row._id;

    return this.setState({
      onboard: true,
      incubatorId: id
    });
  };

  handleOnboard = (row, e) => {
    let id = row._id;
    return this.setState({
      onboard: true,
      incubatorId: id
    });
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  createNewOnboard = (row, e) => {
    const incubatorAppFormId = row._id;
    let formData = new FormData();
    const onboardForm = {
      incubatorAppFormId: incubatorAppFormId,
      incubateeType: "COMPANY",
      individual: {},
      organization: {},
      emails: [],
      phones: [],
      identificationDocs: [],
      addresses: [],
      businessPlan: "",
      graduationStatus: "",
      incubateeStage: "",
      incubationSector: "",
      subTechSector: "",
      targetSector: "",
      grandsOrFunding: "",
      businessModel: "",
      currentEmployeeCount: "",
      femaleEmployeeCount: "",
      residentialStatus: "",
      incubationCategory: "",
      //anchorIncubator: "",
      supplementaryIncubators: [],
      patents: [],
      stage: "",
      status: "",
      action: "SAVE"
    };

    formData.append("message", JSON.stringify(onboardForm));

    const url = INCUBATEES_ONBOARD_API;
    const headers = {};

    HttpUtil.post(
      url,
      headers,
      formData,
      data => {
        this.setState({
          editOnboardForm: true,
          editOnboardFormById: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  actionsFormater = (cell, row) => {
    //console.log("cell:", cell, "row:", row);
    if (!row) return null;
    let onboardButton = null;
    onboardButton = (
      <Fragment key={row._id}>
        <Can do="VIEW" on="IncubatorAppForm">
          <Link
            to={`/admin/view/incubator/form/${row._id}`}
            role="button"
            datatoggle="tooltip"
            title="View"
          >
            <i className="fas fa-eye fa-lg action-icon" />
          </Link>
        </Can>
        &nbsp;&nbsp;
        {row.status === "NOT_STARTED" ? (
          <Can do="CREATE" on="IncubateeOnboardingForm">
            <span
              role="button"
              onClick={this.create.bind(this, row._id)}
              className="cursor-pointer pl-2"
              datatoggle="tooltip"
              title="Onboard"
            >
              <i className="fas fa-edit fa-lg action-icon" />
            </span>
          </Can>
        ) : null}
      </Fragment>
    );

    return onboardButton;
  };

  getProgrammeName = (cell, row) => {
    // console.log("cell:", cell, "row:", row);
    const PROGRAMMES = {
      IncubateeAppForm: "PSG Step Incubatee",
      NidhiEirAppForm: "NIDHI EIR",
      NidhiPrayasAppForm: "NIDHI PRAYAS"
    };
    return PROGRAMMES[cell];
  };

  componentDidMount = () => {
    this.getOnboardList();
  };

  getOnboardList = () => {
    let url = `${INCUBATEE_APP_FORMS_INPROGRESS_API}`;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({ onboardList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  viewFormById = row => {
    this.setState({
      viewForm: true,
      viewFormById: row._id
    });
  };

  create = formId => {
    const url = INCUBATEES_ONBOARD_API + "/appform/" + formId;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      {},
      data => {
        this.setState({
          editOnboardForm: true,
          editOnboardFormById: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  render() {
    const {
      onboardList,
      editOnboardForm,
      editOnboardFormById,
      viewForm,
      viewFormById
    } = this.state;

    if (viewForm) {
      const url = "/admin/view/incubator/form/" + viewFormById;
      return <Redirect to={url} />;
    }

    if (editOnboardForm) {
      let url = "/onboard/incubatees/edit/" + editOnboardFormById;
      return <Redirect to={url} />;
    }

    const columnsOnboard = [
      {
        dataField: "name",
        text: "Applicant Name",
        sort: true
        // style: { textDecoration: "underline", cursor: "pointer" },
        // events: {
        //   onClick: this.handleClick.bind(this)
        // }
      },
      {
        dataField: "kind",
        text: "Programme",
        sort: true,
        formatter: this.getProgrammeName,
        csvFormatter: this.getProgrammeName
      },

      {
        dataField: "createdAt",
        text: "Approve Date",
        formatter: getDisplayDate,
        csvFormatter: getDisplayDate
      },
      {
        dataField: "status",
        text: "Status",
        formatter: AppFormStatus,
        csvFormatter: AppFormStatus
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        csvExport: false,
        formatter: this.actionsFormater
      }
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsOnboard}
          data={onboardList}
          noDataIndication="No Incubator Application found."
          sizePerPage="10"
          headerName="Incubator Application"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default OnboardList;
