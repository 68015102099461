import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";
import { LEASE_API } from "../../common/Constants";
import { LEASE_REQUEST_TYPES_OBJECT } from "../../common/LookupConstants";
import HttpUtil from "../../common/HttpUtil";
import { getDescription } from "../../common/Util";
import { convertNumberToDate, getDisplayDate } from "../DateUtil";
import TableList from "../../common/TableList";
import Can from "../../../auth/can";

class CancelledLeaseRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelledRequests: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getCancelledLeaseRequests = () => {
    let url = `${LEASE_API}/cancelled`;
    HttpUtil.get(
      url,
      {},
      cancelledRequests =>
        this.setState({ cancelledRequests: cancelledRequests }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  partitionFormatter = (partitions, row) => {
    if (!row) return null;
    const partitionNames = partitions.map(lp => lp.partition.displayName);
    return partitionNames.join(",");
  };

  buildingFormatter = (cell, record) => {
    if (!record) return null;
    let buildings = {};
    record.leasePartitions.forEach(lp => {
      buildings[lp.partition.floor.building.buildingName] = true;
    });
    const entries = Object.keys(buildings);
    return entries.length === 1 ? entries[0] : "--";
  };

  statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!row) return null;
    return getDescription("LEASE", row[formatExtraData], cell);
  };

  dateFormatter = (cell, row) => {
    if (!row) return null;
    let displayDate = getDisplayDate(convertNumberToDate(cell));
    return displayDate;
  };

  linkFormater = (cell, row) => {
    if (!row) return null;
    const URL_MAPPER = {
      TERMINATE: `/admin/lease/termination/edit/${
        row._id
      }?from=/admin/cancelled/leases/requests`,
      RENEW: `/admin/lease/edit/${
        row._id
      }?from=/admin/cancelled/leases/requests`,
      NEW: `/admin/lease/edit/${row._id}?from=/admin/cancelled/leases/requests`
    };
    const url = URL_MAPPER[row.type];
    return <Link to={url}>{row.incubatee.name}</Link>;
  };

  getIncubateeName = (cell, row) => {
    if (!row) return null;
    return row.incubatee.name;
  };

  typeFormatter = (cell, row) => {
    if (!row) return null;
    return LEASE_REQUEST_TYPES_OBJECT[cell];
  };

  componentDidMount() {
    this.getCancelledLeaseRequests();
  }

  render() {
    const { cancelledRequests } = this.state;

    const columnsLease = [
      {
        dataField: "incubatee.name",
        text: "Incubatee",
        sort: true,
        formatter: this.linkFormater,
        filterValue: this.getIncubateeName
      },

      {
        dataField: "type",
        text: "Request Type",
        formatter: this.typeFormatter,
        csvFormatter: this.typeFormatter,
        filterValue: this.typeFormatter
      },
      {
        dataField: "",
        formatter: this.buildingFormatter,
        csvFormatter: this.buildingFormatter,
        text: "Building"
      },
      {
        dataField: "leasePartitions",
        formatter: this.partitionFormatter,
        csvFormatter: this.partitionFormatter,
        text: "Partition"
      },
      {
        dataField: "leaseStartDate",
        text: "Start date",
        formatter: this.dateFormatter,
        csvFormatter: this.dateFormatter,
        filterValue: this.dateFormatter
      },
      {
        dataField: "leaseEndDate",
        text: "End date",
        formatter: this.dateFormatter,
        csvFormatter: this.dateFormatter,
        filterValue: this.dateFormatter
      },

      {
        dataField: "status",
        text: "Status",
        formatter: this.statusFormatter,
        csvFormatter: this.statusFormatter,
        formatExtraData: "stage",
        filterValue: this.statusFormatter
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsLease}
          data={cancelledRequests}
          noDataIndication="No Rejected Lease Requests found."
          sizePerPage="10"
          headerName="Rejected Lease Requests"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default CancelledLeaseRequests;

const RenderCustomButton = props => {
  return (
    <Can I="CREATE" a="Lease">
      <Link
        to="/admin/lease/add"
        role="button"
        className="btn btn-sm text-white bg-pink"
      >
        New
      </Link>
    </Can>
  );
};
