import {
  LOGIN_SUCCESS,
  AUTHENTICATE_USER,
  USER_LOGOUT_SUCCESS,
  CURRENT_USER_PROFILE
} from "./LoginAction";

const initialState = {
  userLoggedIn: null,
  userLoggedOut: null,
  data: null,
  errorType: null,
  errorMessage: null
};

const LoginReducer = (state = initialState, action) => {
  let errMsg = "";
  if (action.data) {
    errMsg = action.data.message ? action.data.message : action.data;
  }

  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        userLoggedIn: false,
        userLoggedOut: true,
        data: null,
        errorType: AUTHENTICATE_USER,
        errorMessage: errMsg
      };

    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        userLoggedIn: false,
        userLoggedOut: true,
        data: null,
        errorType: null,
        errorMessage: null
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        userLoggedIn: true,
        userLoggedOut: false,
        data: action.data.data,
        errorType: null,
        errorMessage: null
      };

    case CURRENT_USER_PROFILE:
      return {
        ...state,
        userLoggedIn: true,
        userLoggedOut: false,
        data: action.data,
        errorType: null,
        errorMessage: null
      };
    default:
      return state;
  }
};

export default LoginReducer;
