import React, { Component } from "react";
import DiffViewer from "./DiffViewer";

// Example object's
import { schema1 } from "./schema";

//import DataDisplay from "./DataDisplay";
class DataCompare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: "",
      changedData: {}
    };
  }

  getFormData = formData => {
    //console.log("DataCompare.js==>formData:",formData)
  };

  dataChange = (key, value) => {
    //console.log("DataCompare.js==>value:",value,"key:",key)
    this.setState({ changedData: value });
  };

  render() {
    const data1 = {
      name: "Test Company",
      type: "Company",
      netWorth: 105.5,
      address: "cbe",
      tags: ["nano", "bio"],
      personalDetails: {
        aadhaar: "21312131213",
        phones: [
          {
            type: "Mobile",
            number: "0212123-121267"
          },
          {
            type: "Office",
            number: "121267"
          }
        ],
        email: "abcd@gmail.com"
      },
      promoters: [
        {
          active: false,
          name: "Sathish",
          designation: "MD"
        },
        {
          active: true,
          name: "Saravana",
          designation: "CTO"
        }
      ],
      schemes: [
        {
          name: "PRAYAS",
          year: 2018,
          prayasForm: {
            amount: 10000
          }
        },
        {
          name: "EIR",
          year: 2018,
          eirForm: {
            grant: 2000
          }
        }
      ]
    };
    const data2 = {
      name: "Test Corporation",
      type: "Individual",
      netWorth: 50,
      doi: "2018-02-01",
      address: "cbe",
      tags: ["lab", "nano"],
      personalDetails: {
        aadhaar: "1234 4567 9870",
        phone: "04562-2121267",
        email: "abcd@gmail.com"
      },
      communcationAddress: {
        street: "main street"
      },
      promoters: [
        {
          active: false,
          name: "Ravi",
          designation: "MD"
        },
        {
          active: true,
          name: "Ragesh",
          designation: "CTO"
        }
      ],

      schemes: [
        { name: "PRAYAS", year: 2019, prayasForm: { amount: "20000" } },
        { name: "EIR", year: 2019, eirForm: { grant: "20000" } }
      ]
    };
    return (
      <div className="card-body">
        <DiffViewer
          schema={schema1}
          data={[data1, data2]}
          key=""
          name="Incubator Form"
          level={0}
          dataChange={this.dataChange}
        />

        {/* <DataDisplay
          name=""
          schema={schema1}
          data={data1}
          formData={this.getFormData}
          dataChange={this.dataChange}
        /> */}
      </div>
    );
  }
}

export default DataCompare;
