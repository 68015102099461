import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import BootsrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import AlertComponent from "../../common/AlertComponent";
import { LEASE_TERMINATIONS_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { getDisplayDate, convertNumberToDate } from "../DateUtil";
import { getDescription } from "../../common/Util";
import { PAYEE_TYPES_OBJECT } from "../../common/LookupConstants";

const { SearchBar } = Search;

class LeaseTerminationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminations: []
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getTerminationList = () => {
    const url = LEASE_TERMINATIONS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ terminations: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getTerminationList();
  }

  incubateeTypeFormatter = (cell, row) => {
    const incubateeType = row.lease.incubatee.incubateeType;
    return PAYEE_TYPES_OBJECT[incubateeType];
  };

  dateFormater = yyyymmdd => {
    return getDisplayDate(convertNumberToDate(yyyymmdd));
  };

  statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return getDescription("LeaseTermination", row[formatExtraData], cell);
  };

  incubateeFormatter = (cell, row) => {
    const name = row.lease.incubatee.name;
    const url = `/admin/lease/termination/edit/${row._id}`;
    return <Link to={url}>{name}</Link>;
  };

  incubateeName = (cell, row) => {
    const name = row.lease.incubatee.name;
    return name;
  };

  render() {
    const {
      terminations,
      sizePerPage,
      page,
      totalSize,
      chargeDesc
    } = this.state;

    const columnsCharges = [
      {
        dataField: "lease",
        text: "Incubatee",
        sort: true,
        key: "Incubatee",
        formatter: this.incubateeFormatter,
        filterValue: this.incubateeName
      },
      {
        dataField: "lease.incubatee",
        text: "Incubatee Type",
        sort: true,
        key: "Type",
        formatter: this.incubateeTypeFormatter,
        filterValue: this.incubateeTypeFormatter
      },
      {
        dataField: "requestedDate",
        text: "Requested Date",
        sort: true,
        formatter: this.dateFormater,
        filterValue: this.dateFormater
      },
      {
        dataField: "actualTerminationDate",
        text: "Actual Termi.Date",
        sort: true,
        formatter: this.dateFormater,
        filterValue: this.dateFormater
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: this.statusFormatter,
        filterValue: this.statusFormatter,
        formatExtraData: "stage"
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      message: this.state.message,
      close: this.closeDefaultAlert
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="card-header bg-card-header text-white">
                <span className="card-title mb-0">
                  <i
                    className="fas fa-align-justify"
                    style={{ fontSize: "1.1em", cursor: "pointer" }}
                  />
                  &nbsp;&nbsp;Lease Termination List &nbsp;&nbsp;
                </span>
              </div>
              <div className="card-body">
                <ToolkitProvider
                  keyField="_id"
                  data={terminations}
                  columns={columnsCharges}
                  bootstrap4={true}
                  search
                  exportCSV={{
                    fileName: `${chargeDesc}.csv`
                  }}
                >
                  {props => (
                    <Fragment>
                      <div className="mb-2">
                        <SearchBar {...props.searchProps} />
                      </div>
                      <BootsrapTable
                        bootstrap4
                        keyField={"_id"}
                        data={terminations}
                        columns={columnsCharges}
                        condensed
                        striped
                        headerClasses="bg-card-header text-white"
                        // selectRow={selectRow}
                        // for pagination
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize
                        })}
                        //for search
                        {...props.baseProps}
                        //for custom css
                        className="react-bootstrap-table table .react-bootstrap-table td > .selection-input-4, .react-bootstrap-table th > .selection-input-4"
                        // enable remote
                        // remote
                        // below funtion work on when enable remote
                        // onTableChange={this.onTableChange}
                        noDataIndication="No Data found"
                      />
                    </Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LeaseTerminationList;
