import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Pie } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import {
  sizePerPageOptionRenderer,
  paginationTotalRenderer
} from "../common/TableList";
import HttpUtil from "../common/HttpUtil";
import { getDescription } from "../common/Util";
import { convertNumberToDate, getDisplayDate } from "../lease/DateUtil";
import {
  DASHBOARD_PENDING_ITEMS_API,
  FACILITY_COUNT_BY_CAMPUS_API,
  LEASE_STATISTICS_API,
  LEASE_SPACE_STATISTICS_API,
  LEASE_STATS_BY_YEAR,
  INCUBATEE_COUNT_BY_SECTOR_API,
  INCUBATEE_COUNT_BY_GRANTS_API
} from "../common/Constants";
import AlertComponent from "../common/AlertComponent";
import {
  DASHBOARD_PENDING_ITEM_TYPES_OBJECT,
  DASHBOARD_PENDING_ITEM_SUB_CATEGORY_MAPPER
} from "../common/LookupConstants";
import CoreStats from "./CoreStats";
import Charts from "./Charts";
import ReactApexChart from "react-apexcharts";
import ShowHideComponent from "../widgets/ShowHideComponent";
import { formatNumber } from "../reports/ReportsUtil";
import IncubateeClusterStats from "./IncubateeClusterStats";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingItems: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getPendingItems = () => {
    let url = DASHBOARD_PENDING_ITEMS_API;
    HttpUtil.get(
      url,
      {},
      pendingItems => this.setState({ pendingItems: pendingItems }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  partitionFormatter = partitions => {
    let partitiDisplayName = "";
    partitions.forEach(lp => {
      partitiDisplayName = lp.partition.displayName;
    });
    return partitiDisplayName;
  };

  statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return getDescription("LEASE", row[formatExtraData], cell);
  };

  dateFormatter = (cell, row) => {
    let displayDate = getDisplayDate(convertNumberToDate(cell));
    return displayDate;
  };

  linkFormater = (cell, row) => {
    const URL_MAPPER = {
      TERMINATE: `/admin/lease/termination/edit/${row._id}?from=/dashboard`,
      RENEW: `/admin/lease/edit/${row._id}?from=/dashboard`,
      NEW: `/admin/lease/edit/${row._id}?from=/dashboard`,
      IncubateeOnboard: `/admin/incubatoronboard/edit/${row._id}?from=/dashboard`
    };
    const url = URL_MAPPER[row.subCategory] || URL_MAPPER[row.category];
    return (
      <Link className="text-decoration-none text-primary" to={url}>
        {row.incubateeName || "--"}
      </Link>
    );
  };

  getIncubateeName = (cell, row) => {
    return row.incubatee.name;
  };

  requestTypeFormater = (cell, row) => {
    const categoryLabel = DASHBOARD_PENDING_ITEM_TYPES_OBJECT[cell];

    const subCategory =
      DASHBOARD_PENDING_ITEM_SUB_CATEGORY_MAPPER[`${cell}|${row.subCategory}`];

    let subCategoryLabel = "";
    if (subCategory) {
      subCategoryLabel = (
        <span
          className={`badge badge-pill badge-${subCategory.cssClassName} p-1`}
        >
          {subCategory.label}
        </span>
      );
    }

    return (
      <Fragment>
        {categoryLabel}&nbsp;
        {subCategoryLabel}
      </Fragment>
    );
  };

  recordAgeFormatter = age => {
    if (age === 0) {
      return "today";
    }
    return age ? `${age} day(s) ago` : "";
  };

  componentDidMount() {
    this.getPendingItems();
  }

  render() {
    const { pendingItems } = this.state;

    const columnsLease = [
      {
        dataField: "incubateeName",
        text: "Incubatee Name",
        formatter: this.linkFormater,
        filterValue: this.linkFormater,
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },
      {
        dataField: "category",
        text: "Request Type",
        sort: true,
        formatter: this.requestTypeFormater,
        filterValue: this.requestTypeFormater,
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },

      {
        dataField: "statusDesc",
        text: "Status",
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },
      {
        dataField: "age",
        text: "Last Updated",
        formatter: this.recordAgeFormatter,
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const props = {
      handleApiFailed: this.handleApiFailed
    };

    const options = {
      custom: true,
      alwaysShowAllBtns: true,
      sizePerPageOptionRenderer,
      paginationTotalRenderer,
      sizePerPage: 10,
      totalSize: pendingItems ? pendingItems.length : 0
    };

    const hasPendingItemData = pendingItems ? true : false;

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <CoreStats />

        <Charts />

        <div className="row mb-3">
          <IncubateeClusterStats />

          <FacilityStatistics {...props} />

          {/* <LeaseSpaceStatistics {...props} /> */}
        </div>
        <div className="row mb-3">
          <LeaseStatsPerYear {...props} />
        </div>

        {/* <div className="row mb-3">
          <LeaseStatistics {...props} />
        </div> */}
        {/* <div className="row mb-3">{<IncubateeCountBySector {...props} />} 
          <IncubateeCountByGrants {...props} /></div> */}

        <div className="row mb-5">
          <div className="col-lg-12 col-xs-12 col-md-12">
            <div
              className="card elevate mt-3"
              // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
            >
              <div className="card-header border-bottom-0">
                <div
                  className="card-header-title font-size-lg text-center"
                  style={{ fontWeight: "500" }}
                >
                  Pending Items
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive-sm table-responsive-md table-responsive-lg">
                  <ShowHideComponent show={hasPendingItemData}>
                    <PaginationProvider pagination={paginationFactory(options)}>
                      {({ paginationProps, paginationTableProps }) => (
                        <Fragment>
                          <BootstrapTable
                            bootstrap4
                            keyField={"_id"}
                            data={pendingItems}
                            columns={columnsLease}
                            condensed
                            bordered={false}
                            noDataIndication="No Pending Items"
                            {...paginationTableProps}
                          />
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="table-list-total">
                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </div>

                            <div className="col-lg-8 col-md-8 col-sm-12">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </PaginationProvider>
                  </ShowHideComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Dashboard;

export class FacilityStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campusStats: []
    };
  }

  componentDidMount = () => {
    this.getCountByCampus();
  };

  getCountByCampus = () => {
    HttpUtil.get(
      FACILITY_COUNT_BY_CAMPUS_API,
      {},
      data => {
        this.setState({ campusStats: data });
      },
      (data, status) => this.props.handleApiFailed(data.message),
      error => this.props.handleApiFailed(error.toString())
    );
  };
  render() {
    const { campusStats } = this.state;

    const columnsStats = [
      {
        dataField: "name",
        text: "Campus",
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },
      {
        dataField: "buildings",
        text: "Buildings",
        headerAlign: "right",
        align: "right",
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },

      {
        dataField: "floors",
        text: "Floors",
        headerAlign: "right",
        align: "right",
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },
      {
        dataField: "partitions",
        text: "Partitions",
        headerAlign: "right",
        align: "right",
        headerClasses: "display-table-header border-0",

        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },
      {
        dataField: "totalSpace",
        text: "Total Space",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => formatNumber(row.totalSpace),
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      },
      {
        dataField: "usableSpace",
        text: "Usable Space",
        headerAlign: "right",
        align: "right",
        formatter: (cell, row) => formatNumber(row.usableSpace),
        headerClasses: "display-table-header border-0",
        headerStyle: {
          backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
      }
    ];

    return (
      <div className="col-12 col-lg-6">
        <div
          className="card elevate mt-3"
          // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
        >
          <div className="card-header border-bottom-0">
            <div className="text-center">
              <span
                className="card-header-title font-size-lg"
                style={{ fontWeight: "500" }}
              >
                Available Facilities
              </span>

              <Link
                className="btn btn-sm space-info-btn shadow float-right"
                to="/reports/facility/consolidated-facility-usage"
              >
                View Details
              </Link>
            </div>
          </div>

          <div className="card-body pt-0 pb-0 mt-2" style={{ height: "240px" }}>
            <div className="table-responsive-sm table-responsive-md table-responsive-lg">
              <BootstrapTable
                bootstrap4
                keyField={"_id"}
                data={campusStats}
                columns={columnsStats}
                condensed
                bordered={false}
                noDataIndication="No Data found"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class LeaseStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaseStats: null
    };
  }

  componentDidMount = () => {
    this.getLeaseStatitics();
  };

  getLeaseStatitics = () => {
    let url = LEASE_STATISTICS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        const leaseLabels = [];
        const leaseData = [];
        if (data.length > 0) {
          data.forEach((d, index) => {
            let labelBaseText;
            if (d.type === "COMPANY") {
              labelBaseText = d.count > 1 ? "Organizations" : "Organization";
              leaseData[index] = d.count;
            } else if (d.type === "INDIVIDUAL") {
              labelBaseText = d.count > 1 ? "Individuals" : "Individual";
              leaseData[index] = d.count;
            }
            leaseLabels[index] = labelBaseText;
          });
        }

        const leaseChartData = {
          labels: leaseLabels,
          datasets: [
            {
              data: leaseData,
              backgroundColor: ["#ff9800", "#2c7be5", "#FFCE56"],
              hoverBackgroundColor: ["#ff9800", "#2c7be5", "#FFCE56"]
            }
          ]
        };
        this.setState({ leaseStats: leaseChartData });
      },
      (data, status) => this.props.handleApiFailed(data.message),
      error => this.props.handleApiFailed(error.toString())
    );
  };

  render() {
    const { leaseStats } = this.state;
    if (leaseStats === null) {
      return null;
    }

    return (
      <div className="col-md-6 d-flex mt-4 mt-xl-0">
        <div className="card flex-fill">
          <div className="card-header bg-card-header text-white">
            <h5 className="h6 card-title mb-0">
              Lease Counts by Incubatee Type
            </h5>
          </div>

          <div className="card-body">
            <div className="row">
              <Pie data={leaseStats} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function getLeaseStatsPerYearOptions(categories) {
  return {
    chart: {
      shadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1
      },
      toolbar: {
        show: false
      }
    },
    colors: ["#2c7be5", "#ff9800"],
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: "smooth"
    },
    title: {
      text: "Count",
      align: "left"
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    markers: {
      size: 6
    },
    xaxis: {
      categories,
      title: {
        text: ""
      }
    },
    yaxis: {
      title: {
        text: ""
      }
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  };
}

export class LeaseStatsPerYear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      series: null,
      error: null
    };
  }

  componentDidMount = () => {
    this.getLeaseStatsByYear();
  };

  getLeaseStatsByYear = () => {
    let url = LEASE_STATS_BY_YEAR;
    HttpUtil.get(
      url,
      {},
      this.setOptionsAndSeries,
      (data, status) => this.setState({ error: data.message }),
      error => this.setState({ error: "Error while loading data.." })
    );
  };

  getYearMonthLabel = data => {
    const ALL_MONTHS = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec"
    };
    return data.map(mn => `${ALL_MONTHS[mn.month]}-${mn.year}`);
  };

  setOptionsAndSeries = data => {
    const series = [
      {
        name: "New",
        data: data.new.map(d => d.count)
      },
      {
        name: "Terminated",
        data: data.terminated.map(d => d.count)
      }
    ];

    const options = getLeaseStatsPerYearOptions(
      this.getYearMonthLabel(data.terminated)
    );

    this.setState({ options, series });
  };

  render() {
    const { options, series, error } = this.state;
    if (series === null && error === null) {
      return null;
    }

    let chart = "";
    if (error !== null) {
      chart = <div className="text-center text-danger">{error}</div>;
    } else if (series !== null) {
      chart = (
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height="250"
        />
      );
    } else {
      chart = <div className="text-center text-primary">Loading Data....</div>;
    }

    return (
      <div className="col-12">
        <div
          className="card elevate mt-3"
          // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
        >
          <div className="card-header border-bottom-0">
            <div
              className="card-header-title font-size-lg text-center"
              style={{ fontWeight: "500" }}
            >
              Past Year Lease Trend
            </div>
          </div>
          <div className="card-body pb-0 pt-1 mt-2" style={{ height: "260px" }}>
            {chart}
          </div>
        </div>
      </div>
    );
  }
}

export class LeaseSpaceStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaseSpaceStats: null
    };
  }

  componentDidMount = () => {
    this.getLeaseSpaceStatitics();
  };

  getLeaseSpaceStatitics = () => {
    let url = LEASE_SPACE_STATISTICS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        if (data.length > 0) {
          this.setState({ leaseSpaceStats: data });
        } else {
          const emptyDataSet = [
            {
              totalArea: 0,
              partitionType: "RENTAL",
              areaUnit: "SQFT"
            },
            {
              count: 0,
              partitionType: "SEATER"
            }
          ];
          this.setState({ leaseSpaceStats: emptyDataSet });
        }
      },
      (data, status) => this.props.handleApiFailed(data.message),
      error => this.props.handleApiFailed(error.toString())
    );
  };

  render() {
    const { leaseSpaceStats } = this.state;
    if (leaseSpaceStats === null) {
      return null;
    }

    let rental = {};
    let seater = {};
    leaseSpaceStats.forEach(ls => {
      if (ls.partitionType === "RENTAL") {
        rental.totalArea = `${ls.totalArea} sqft`;
      } else if (ls.partitionType === "SEATER") {
        seater.count = `${ls.count} seats`;
      }
    });

    const fontStyle = {
      marginBottom: ".5rem",
      fontWeight: "400",
      lineHeight: "1.2",
      color: "#000"
    };

    return (
      <div className="col-md-6 d-flex mt-4 mt-xl-0">
        <div className="card flex-fill">
          <div className="card-body">
            <div className="row mt-5 ml-4">
              <div className="col-lg-6 col-xl-6">
                <div className="media">
                  <div className="media-body">
                    <h4 style={fontStyle}>{rental.totalArea}</h4>
                    <div>Fixed Rental Space Leased</div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 mt-4 mt-xl-0">
                <div className="media">
                  <div className="media-body">
                    <h4 style={fontStyle}>{seater.count}</h4>
                    <div>Seaters Leased</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5 ml-4">
              <div className="col-lg-6 col-xl-6">
                <div className="media">
                  <div className="media-body">
                    <h5 style={fontStyle}>80</h5>
                    <div>Startups</div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 mt-4 mt-xl-0">
                <div className="media">
                  <div className="media-body">
                    <h5 style={fontStyle}>20</h5>
                    <div>Startups Funded </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class IncubateeCountBySector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectors: []
    };
  }

  componentDidMount = () => {
    this.getCountBySectors();
  };

  getCountBySectors = () => {
    let url = INCUBATEE_COUNT_BY_SECTOR_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ sectors: data });
      },
      (data, status) => this.props.handleApiFailed(data.message),
      error => this.props.handleApiFailed(error.toString())
    );
  };

  getDataSet = sectors => {
    let sectorLabels = [];
    let sectorCounts = [];
    let bgColors = [
      "#8bd9e5", // cyan
      "#abf4df", // teal
      "#a5e2b3", // green
      "#efcf70", // yellow
      "#f29f59", // orange
      "#d85b67", // red
      "#e876aa", // pink
      "#bcabdb", // purple
      "#a67cea", // indigo
      "#2c7be5" // blue
    ];

    sectors.forEach((sec, index) => {
      sectorLabels[index] = sec.sector;
      sectorCounts[index] = sec.count;
      if (sec.sector === "Unspecified") {
        const gray = "#4a4a4c";
        bgColors[index] = gray;
      }
    });
    const data = {
      labels: sectorLabels,
      datasets: [
        {
          data: sectorCounts,
          backgroundColor: bgColors,
          hoverBackgroundColor: bgColors
        }
      ]
    };
    return data;
  };
  render() {
    const data = this.getDataSet(this.state.sectors);
    return (
      <div className="col-md-6 d-flex mt-4 mt-xl-0">
        <div className="card flex-fill">
          <div className="card-header bg-card-header text-white">
            <h5 className="h6 card-title mb-0">Incubatee Count By Sector</h5>
          </div>
          <div className="card-body ">
            <Pie data={data} />
          </div>
        </div>
      </div>
    );
  }
}

export class IncubateeCountByGrants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grants: []
    };
  }

  componentDidMount = () => {
    this.getCountByGrants();
  };

  getCountByGrants = () => {
    let url = INCUBATEE_COUNT_BY_GRANTS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ grants: data });
      },
      (data, status) => this.props.handleApiFailed(data.message),
      error => this.props.handleApiFailed(error.toString())
    );
  };

  getDataSet = grants => {
    let grantLabels = [];
    let grantCounts = [];
    let bgColors = [
      "#007bff", // blue
      "#a67cea", // indigo
      "#bcabdb", // purple
      "#e876aa", // pink
      "#d85b67", // red
      "#f29f59", // orange
      "#efcf70", // yellow
      "#a5e2b3", // green
      "#abf4df", // teal
      "#8bd9e5", // cyan
      "#4a4a4c" // gray
    ];
    grants.forEach((grts, index) => {
      grantLabels[index] = grts.program;
      grantCounts[index] = grts.count;
    });
    const data = {
      labels: grantLabels,
      datasets: [
        {
          data: grantCounts,
          backgroundColor: bgColors,
          hoverBackgroundColor: bgColors
        }
      ]
    };

    return data;
  };
  render() {
    const data = this.getDataSet(this.state.grants);

    return (
      <div className="col-md-6 d-flex mt-4 mt-xl-0">
        <div className="card flex-fill">
          <div className="card-header bg-card-header text-white">
            <h5 className="h6 card-title mb-0">Incubatee Count By Grants</h5>
          </div>
          <div className="card-body ">
            <Pie data={data} />
          </div>
        </div>
      </div>
    );
  }
}
