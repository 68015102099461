import React, { Component } from "react";

import { INCUBATOR_APPLICATION_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
class IncubatorApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // form field
      _id: "",
      organizationName: "",
      domainOperation: "",
      phone: "",
      email: "",
      reference: "",
      address: "",
      status: "NotStarted",

      // for form field error
      inputError: {},

      newIncubator: true,
      editIncubator: false,
      editIncubatorId: ""
    };
  }

  onChange = e => {
    let targetValue = e.target.value;
    if (e.target.type === "number" && isNaN(targetValue)) {
      return;
    }
    this.setState({ [e.target.name]: targetValue });
  };

  getMasterForm = () => {
    const incubatorApp = {
      name: this.state.organizationName,
      domainOperation: this.state.domainOperation,
      phone: this.state.phone,
      email: this.state.email,
      address: this.state.address,
      reference: this.state.reference,
      status: this.state.status
    };

    let id = this.state._id;
    if (id) {
      incubatorApp._id = id;
    }

    return incubatorApp;
  };

  validation = incubatorApp => {
    let inputError = this.state.inputError;
    let formError = {};
    let errorMessage = "";
    let valid = true;
    if (incubatorApp.organizationName === "") {
      errorMessage = "Please enter name";
      formError.organizationName = {
        error: true,
        errorMessage: errorMessage
      };
    }

    if (incubatorApp.status === "") {
      errorMessage = "Please select status";
      formError.status = {
        error: true,
        errorMessage: errorMessage
      };
    }

    if (incubatorApp.email === "") {
      errorMessage = "Please select email";
      formError.email = {
        error: true,
        errorMessage: errorMessage
      };
    }

    inputError = formError;

    if (Object.keys(inputError).length !== 0) {
      valid = false;
      this.setState({
        inputError: { ...inputError }
      });
    }
    return valid;
  };
  handleSave = () => {
    let incubatorApp = this.getMasterForm();
    if (!this.validation(incubatorApp)) {
      //return;
    }

    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = INCUBATOR_APPLICATION_API;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.post(
      url,
      headers,
      incubatorApp,
      data => {
        this.setState({
          organizationName: "",
          domainOperation: "",
          phone: "",
          email: "",
          reference: "",
          status: "",
          address: "",
          editIncubator: true,
          newIncubator: false,
          inputError: {}
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };
  render() {
    const {
      organizationName,
      domainOperation,
      phone,
      email,
      address,
      reference,
      status,
      inputError,
      newIncubator
    } = this.state;

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <span className="card-title mb-0">
                <i
                  className="fas fa-arrow-left campus-arrow-left"
                  onClick={this.backToList}
                />{" "}
                IncubatorApp <b>"New Record"</b>
              </span>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                  <fieldset className="scheduler-border">
                    <legend className="scheduler-border">
                      Organization Information
                    </legend>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                            Name
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <input
                              type="text"
                              className={
                                inputError["organizationName"] !== undefined
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder="Name of organization"
                              name="organizationName"
                              value={organizationName}
                              onChange={this.onChange}
                            />
                            {inputError["organizationName"] ? (
                              <div className="invalid-feedback">
                                {inputError.organizationName.errorMessage}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                            Status
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <select
                              className={
                                inputError["status"] !== undefined
                                  ? "custom-select custom-select-sm is-invalid"
                                  : "custom-select custom-select-sm"
                              }
                              name="status"
                              value={status}
                              onChange={this.onChange}
                            >
                              <option value="">Select</option>
                              <option value="NotStarted">NotStarted</option>
                              <option value="Rejected">Rejected</option>
                              <option value="Onboard">Onboard</option>
                            </select>
                            {inputError["status"] ? (
                              <div className="invalid-feedback">
                                {inputError.status.errorMessage}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                            Email
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <input
                              type="email"
                              className={
                                inputError["email"] !== undefined
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              placeholder="Email"
                              name="email"
                              value={email}
                              onChange={this.onChange}
                            />
                            {inputError["email"] ? (
                              <div className="invalid-feedback">
                                {inputError.email.errorMessage}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                            Area / Domain
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Area / Domain operation"
                              name="domainOperation"
                              value={domainOperation}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                            Phone
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Phone"
                              name="phone"
                              value={phone}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                            References
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="References any"
                              name="reference"
                              value={reference}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                            Address
                          </label>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <textarea
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Address any"
                              name="address"
                              row="5"
                              value={address}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <div className="col-sm-12 ">
                            {newIncubator ? (
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={this.handleSave}
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={this.handleUpdate}
                              >
                                Update
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IncubatorApp;
