import React, { Component } from "react";
import { Link } from "react-router-dom";
import BootsrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

class BuildingBasedFloorList extends Component {
  actionsFormater = (cell, row) => {
    const url = `/admin/floors/edit/${row._id}`;
    let viewButton = (
      <Link role="button" className="" to={url}>
        {row.floorName}
      </Link>
    );
    return viewButton;
  };
  render() {
    const columnsFloor = [
      {
        dataField: "floorName",
        text: "Floor",
        headerClasses: "display-table-header",
        sort: true,
        formatter: this.actionsFormater
      },
      {
        dataField: "abbreviation",
        text: "Description",
        headerClasses: "display-table-header",
        sort: true
      },
      {
        dataField: "totalArea",
        headerClasses: "display-table-header",
        text: "Total Area",
        sort: true
      },
      {
        dataField: "usableArea",
        headerClasses: "display-table-header",
        text: "Usable Area",
        sort: true
      }
    ];
    return (
      <div className="row mb-5 mt-5">
        <div className="col-12">
          <div className="card edit-page-container">
            <h4 className="card-header border-bottom-0 mt-3 mb-2 text-center edit-page-title">
              Floor Information
            </h4>
            <div className="card-body">
              <BootsrapTable
                bootstrap4
                keyField={"_id"}
                data={this.props.floors}
                columns={columnsFloor}
                condensed
                bordered={false}
                //for custom css
                noDataIndication="Floor information not available for this building."
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuildingBasedFloorList;
