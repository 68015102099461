import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { REQUESTOR_ONBOARD_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import AlertComponent from "../common/AlertComponent";
import { INCUBATEE_TYPES_OBJECT } from "../common/LookupConstants";

function actionsFormater(cell, row) {
  return (
    <Link
      to={`/asset/requestor-onboard-form/${row._id}`}
      role="button"
      className="list-action-btn-info"
    >
      View
    </Link>
  );
}

function requestorFormater(cell, row) {
  let name = "";

  if (row.requestorType === "INDIVIDUAL") {
    name = row.individual.firstName;
  }
  if (row.requestorType === "COMPANY") {
    name = row.organization.name;
  }

  return name;
}

const REQUESTOR_ONBOARD_COLUMNS = [
  {
    dataField: "parentRequest",
    text: "Requestor",
    sort: true,
    formatter: requestorFormater
  },

  {
    dataField: "requestorType",
    text: "Requestor Type",
    sort: true,
    formatter: cell => INCUBATEE_TYPES_OBJECT[cell]
  },

  {
    dataField: "parentRequestType",
    text: "Request Type",
    sort: true
  },

  {
    dataField: "status",
    text: "Status"
  },
  {
    dataField: "actions",
    text: "Actions",
    isDummyField: true,
    formatter: actionsFormater
  }
];
class RequestorOnboardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardList: []
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getOnboardList = () => {
    let url = REQUESTOR_ONBOARD_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ onboardList: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getOnboardList();
  }
  render() {
    const { onboardList } = this.state;
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white">
                <span className="card-title mb-0">
                  <i
                    className="fas fa-align-justify"
                    style={{ fontSize: "1.1em", cursor: "pointer" }}
                  />
                  &nbsp;&nbsp; Applicant Onboard &nbsp;&nbsp;
                </span>
              </div>

              <div className="card-body">
                <div className="table-responsive-sm table-responsive-md table-responsive-lg">
                  <ToolkitProvider
                    keyField="_id"
                    data={onboardList}
                    columns={REQUESTOR_ONBOARD_COLUMNS}
                    bootstrap4={true}
                    search
                  >
                    {props => (
                      <Fragment>
                        {/* <div className="mb-2">
                            <SearchBar {...props.searchProps} />
                          </div> */}
                        <BootstrapTable
                          bootstrap4
                          keyField={"_id"}
                          data={onboardList}
                          columns={REQUESTOR_ONBOARD_COLUMNS}
                          condensed
                          striped
                          headerClasses="bg-card-header text-white"
                          // for pagination
                          pagination={paginationFactory({
                            page: 1,
                            sizePerPage: 10,
                            hideSizePerPage: true,
                            hidePageListOnlyOnePage: true
                          })}
                          //for search
                          {...props.baseProps}
                          //for custom css
                          className="react-bootstrap-table table .react-bootstrap-table td > .selection-input-4, .react-bootstrap-table th > .selection-input-4"
                          noDataIndication="No Data found"
                        />
                      </Fragment>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default RequestorOnboardList;
