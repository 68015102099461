import React from "react";
import { checkNaN, formatNumber } from "./ReportsUtil";
import PivotReport from "./PivotReport";

const dimensions = [
  { value: "campus", title: "Campus" },
  { value: "building", title: "Building" },
  { value: "organization", title: "Organization" },
  { value: "cluster", title: "Cluster" }
];

function reduce(row, aggRecord) {
  aggRecord.totalAmount = (aggRecord.totalAmount || 0) + checkNaN(row.amount);
  return aggRecord;
}
const calculations = [
  {
    title: "Total Amount",
    value: "totalAmount",
    template: val => formatNumber(val),
    sortBy: row => checkNaN(row.totalAmount)
  }
];

function dataTransformer(data) {
  return data.charges;
}

function MonthlyReport(props) {
  const id = props.match.params.id;
  return (
    <PivotReport
      title="Monthly Rental Report - Grouped Information"
      url={`/api/v1/finance/reports/${id}/charges`}
      dataTransformer={dataTransformer}
      dimensions={dimensions}
      reduce={reduce}
      calculations={calculations}
    />
  );
}

export default MonthlyReport;
