import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../../common/HttpUtil";
import { FACILITY_CLUSTERS_API } from "../../common/Constants";
import AlertComponent from "../../common/AlertComponent";
import TableList from "../../common/TableList";
import Can from "../../../auth/can";

function actionsFormater(cell, row) {
  return (
    <Can do="UPDATE" on="FacilityCluster">
      <Link to={`/facility-clusters/edit/${row._id}`} role="button">
        <i
          className="fas fa-eye fa-lg action-icon"
          datatoggle="tooltip"
          title="View"
        />
      </Link>
    </Can>
  );
}

const FACILITY_CLUSTERS_COLUMNS = [
  {
    dataField: "name",
    text: "Name",
    sort: true
  },

  {
    dataField: "type",
    text: "Type",
    sort: true
  },

  {
    dataField: "actions",
    text: "Actions",
    isDummyField: true,
    formatter: actionsFormater,
    csvExport: false
  }
];

class FacilityClusters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityClusters: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getFacilityClusters = () => {
    let url = FACILITY_CLUSTERS_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ facilityClusters: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getFacilityClusters();
  }

  render() {
    const { facilityClusters } = this.state;

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={FACILITY_CLUSTERS_COLUMNS}
          data={facilityClusters}
          noDataIndication="No Facility Clusters found."
          sizePerPage="5"
          headerName="Facility Clusters"
          showSearchbar={true}
          customComponent={
            <Can do="CREATE" on="FacilityCluster">
              <Link
                to="/facility-clusters/new"
                role="button"
                className="btn btn-sm text-white bg-pink"
              >
                New
              </Link>
            </Can>
          }
        />
      </Fragment>
    );
  }
}

export default FacilityClusters;
