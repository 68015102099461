import React, { Fragment, Component } from "react";
import { Field, FieldArray, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import ShowHideComponent from "../../../widgets/ShowHideComponent";
import {
  convertDateToNumber,
  convertNumberToDate,
  getDisplayDate
} from "../../../lease/DateUtil";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  return getError(touch, error, fieldName) ? "is-invalid" : "";
}

const Patent = props => {
  const { index, touch, error, disabledField, patent, setFieldValue } = props;

  return (
    <Fragment>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label">
          Title of the innovation
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touch,
              error,
              "titleOfInnovation"
            )}`}
            value={patent.titleOfInnovation}
            name={`patents[${index}].titleOfInnovation`}
            placeholder="Title of the innovation"
            {...disabledField}
          />

          <ErrorMessage
            component="div"
            name={`patents[${index}].titleOfInnovation`}
            className="invalid-feedback"
          />
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label">
          Country
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            component="select"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touch,
              error,
              "country"
            )}`}
            value={patent.country}
            name={`patents[${index}].country`}
          >
            <option value="">Select...</option>
            {props.countries.map((country, i) => (
              <option key={i} value={country.countryCode}>
                {country.name}
              </option>
            ))}
          </Field>

          <ErrorMessage
            component="div"
            name={`patents[${index}].country`}
            className="invalid-feedback"
          />
        </div>
        <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label">
          Application Number
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touch,
              error,
              "applicationNumber"
            )}`}
            value={patent.applicationNumber}
            name={`patents[${index}].applicationNumber`}
            placeholder="0"
            {...disabledField}
          />

          <ErrorMessage
            component="div"
            name={`patents[${index}].applicationNumber`}
            className="invalid-feedback"
          />
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label">
          Application Filing Date
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <DatePicker
            value={getDisplayDate(
              convertNumberToDate(patent.applicationFilingDate)
            )}
            selected={
              patent.applicationFilingDate
                ? convertNumberToDate(patent.applicationFilingDate)
                : null
            }
            onChange={value =>
              setFieldValue(
                `patents[${index}].applicationFilingDate`,
                convertDateToNumber(value)
              )
            }
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touch,
              error,
              "applicationFilingDate"
            )}`}
            isClearable={true}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />

          <ErrorMessage
            component="div"
            name={`patents[${index}].applicationFilingDate`}
            className="invalid-feedback"
          />
        </div>
        <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label">
          Field Of Invention
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touch,
              error,
              "fieldOfInvention"
            )}`}
            value={patent.fieldOfInvention}
            name={`patents[${index}].fieldOfInvention`}
            placeholder="Field Of Invention"
            {...disabledField}
          />

          <ErrorMessage
            component="div"
            name={`patents[${index}].fieldOfInvention`}
            className="invalid-feedback"
          />
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label">
          Classification (IPC)
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touch,
              error,
              "classificationCode"
            )}`}
            value={patent.classificationCode}
            name={`patents[${index}].classificationCode`}
            placeholder="IPC"
            {...disabledField}
          />

          <ErrorMessage
            component="div"
            name={`patents[${index}].classificationCode`}
            className="invalid-feedback"
          />
        </div>
        <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label">
          Patent Status
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touch,
              error,
              "patentStatus"
            )}`}
            value={patent.patentStatus}
            name={`patents[${index}].patentStatus`}
            placeholder="Patent Status"
            {...disabledField}
          />

          <ErrorMessage
            component="div"
            name={`patents[${index}].patentStatus`}
            className="invalid-feedback"
          />
        </div>
      </div>
    </Fragment>
  );
};

class Patents extends Component {
  render() {
    const {
      values,
      touched,
      errors,
      setFieldValue,
      readOnly,
      countries
    } = this.props;
    const patentErrors = errors.patents || [];
    const patentTouched = touched.patents || [];
    return (
      <FieldArray
        name="patents"
        render={({ insert, remove, push }) => (
          <Fragment>
            {values.patents.map((patent, index) => {
              const error = patentErrors[index] || {};
              const touch = patentTouched[index] || {};

              const showAddButton = values.patents.length - 1 === index;

              const showRemoveButton = values.patents.length > 1;

              const disabledField = readOnly
                ? { readOnly: "readonly", disabled: "disabled" }
                : {};

              const props = {
                index,
                touch,
                error,
                readOnly,
                disabledField,
                patent,
                setFieldValue,
                countries
              };
              return (
                <Fragment key={index}>
                  <div className="card ml-4 mr-4 mt-4 mb-2">
                    <div className="card-header edit-page-subsection-title border-0">
                      {showRemoveButton ? (
                        <button
                          type="button"
                          className="close pull-right mt-1"
                          aria-label="Close"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={`Remove Patent ${index + 1}`}
                          onClick={() => remove(index)}
                          style={{ color: "#ff0000" }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      ) : null}
                      <h6 className="card-title  mb-0">Patent {index + 1}</h6>
                    </div>
                    <div className="card-body mb-3" key={index}>
                      <Patent {...props} />
                    </div>
                  </div>
                  <div className="form-group row mt-3 mb-0 text-center">
                    <div className="col-12">
                      <ShowHideComponent show={showAddButton}>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-primary mb-3"
                          onClick={() =>
                            push({
                              titleOfInnovation: "",
                              country: "",
                              applicationNumber: "",
                              applicationFilingDate: null,
                              fieldOfInvention: "",
                              classificationCode: "",
                              patentStatus: ""
                            })
                          }
                        >
                          Add More
                        </button>
                      </ShowHideComponent>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        )}
      />
    );
  }
}

export default Patents;
