const Address = {
  uploadAddressProof: "",
  addressProof: "",
  streetLine1: "",
  streetLine2: "",
  landmark: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
  status: "",
  type: ""
};

const Email = { email: "", type: "WORK" };
const Phone = { phone: "", type: "WORK" };

export const DEFAULT_ORG = {
  // basic information
  name: "",
  website: "",
  domainOperation: "",
  legalStatus: "",
  estYear: "",
  reference: "",
  subsidiary: false,
  parentCompany: "",

  // For local validation
  metric: {
    date: null,
    employeeCount: null,
    femaleEmployeeCount: null
  },

  metrics: [
    {
      date: null,
      employeeCount: "",
      femaleEmployeeCount: ""
    }
  ],

  // product and service
  products: [
    {
      name: "",
      description: ""
    }
  ],

  // Financial information
  financials: [
    {
      authorizedCapital: "",
      annualTurnover: "",
      financialYear: ""
    }
  ],

  // Document information
  logo: "",
  companyProfile: "",
  regCertificate: "",
  articlesOfAssociation: "",

  uploadLogo: "",
  uploadCompanyProfile: "",
  uploadRegCertificate: "",
  uploadArticlesOfAssociation: "",

  // Promoters
  promoters: [
    {
      individual: {
        firstName: "",
        gender: "",
        experience: "",
        education: [
          {
            year: "",
            qualification: "",
            degree: "",
            institution: "",
            alumni: false
          }
        ],
        references: "",
        indvPhoto: "",
        uploadPhoto: "",
        addresses: [
          {
            uploadAddressProof: "",
            // addressProof: "",
            streetLine1: "",
            streetLine2: "",
            landmark: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
            status: "",
            type: ""
          }
        ],
        identificationDocs: [
          {
            status: "ACTIVE",
            type: "AADHAAR",
            number: "",
            uploadAadhaar: ""
          },
          {
            status: "ACTIVE",
            type: "PAN",
            number: "",
            uploadPan: ""
          }
        ],
        emails: [{ email: "" }],
        phones: [{ phone: "" }]
      },
      designation: "",
      shareholding: "",
      isFounder: false
    }
  ],

  addresses: [Address],
  emails: [Email],
  phones: [Phone],

  identificationDocs: {
    panNumber: "",
    gstnNumber: "",

    // For Both
    pan: "", // file from server

    // For Individual
    gstn: "", // file from server

    uploadPan: "",
    uploadGstn: ""
  }
};

export const transferServerToUi = data => {
  const organization = {};
  organization._id = data._id;
  organization.name = data.name || "";
  organization.website = data.website || "";
  organization.domainOperation = data.domainOperation || "";
  organization.legalStatus = data.legalStatus || "";
  organization.estYear = data.estYear || "";
  organization.reference = data.reference || "";

  organization.subsidiary = data.subsidiary || false;
  organization.parentCompany = data.parentCompany || "";
  organization.bizSummary = data.bizSummary || "";
  organization.services = data.services || "";

  organization.metric = {
    date: null,
    employeeCount: null,
    femaleEmployeeCount: null
  };

  let metrics = [];
  if (data.metrics && data.metrics.length > 0) {
    for (let i = 0; i < data.metrics.length; i++) {
      const metric = data.metrics[i];

      const empCount = metric.employeeCount || "";
      const date = metric.date || "";
      const femCount = metric.femaleEmployeeCount || null;
      if (empCount && date) {
        metrics.push({
          _id: metric._id,
          date,
          employeeCount: empCount,
          femaleEmployeeCount: femCount
        });
      }
    }
  }
  organization.metrics = metrics;

  let financials = [];
  if (data.financials && data.financials.length > 0) {
    financials = data.financials.map(fin => {
      return {
        _id: fin._id,
        annualTurnover: fin.annualTurnover,
        authorizedCapital: fin.authorizedCapital,
        date: fin.date,
        financialYear: fin.financialYear
      };
    });
  } else {
    financials = [
      {
        annualTurnover: 0,
        authorizedCapital: 0,
        date: "",
        financialYear: ""
      }
    ];
  }
  organization.financials = financials;

  const addresses = data.addresses || [];
  if (addresses.length > 0) {
    organization.addresses = addresses.map(addrObj => {
      return {
        ...addrObj,
        streetLine1: addrObj.streetLine1 || "",
        streetLine2: addrObj.streetLine2 || "",
        landmark: addrObj.landmark || "",
        city: addrObj.city || "",
        state: addrObj.state || "TN",
        postalCode: addrObj.postalCode || "",
        country: addrObj.country || "IN",
        uploadAddressProof: "",
        type: addrObj.type || ""
      };
    });
  } else {
    organization.addresses = [
      {
        uploadAddressProof: "",
        // addressProof: "",
        streetLine1: "",
        streetLine2: "",
        landmark: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        status: "",
        type: ""
      }
    ];
  }

  organization.emails = data.emails;

  organization.phones = data.phones;

  const pmtr = data.promoters || [];
  const promoters = pmtr.map(data => {
    const promoter = {
      ...data,
      designation: data.designation || "",
      shareholding: data.shareholding || "",
      isFounder: data.isFounder || false
    };

    if (data._id) {
      promoter._id = data._id;
    }
    return promoter;
  });
  organization.promoters = promoters;

  // Identification documents
  let panNumber, gstnNumber;
  let pan, gstn;

  data.identificationDocs.forEach(idDoc => {
    if (idDoc.type === "PAN") {
      panNumber = idDoc.number || panNumber;
      pan = idDoc.document || "";
    }

    if (idDoc.type === "GSTN" || idDoc.type === "GSTIN") {
      gstnNumber = idDoc.number || gstnNumber;
      gstn = idDoc.document || "";
    }
  });

  const identificationDocs = {};
  identificationDocs.panNumber = panNumber || "";
  identificationDocs.gstnNumber = gstnNumber || "";
  identificationDocs.pan = pan || "";
  identificationDocs.gstn = gstn || "";
  organization.identificationDocs = identificationDocs;

  // Org documents
  organization.logo = data.logo || "";
  organization.companyProfile = data.companyProfile || "";
  organization.regCertificate = data.regCertificate || "";
  organization.articlesOfAssociation = data.articlesOfAssociation || "";

  // Producs and service
  let products = [];
  if (data.products && data.products.length > 0) {
    products = data.products.map(product => {
      return {
        _id: product._id,
        name: product.name || "",
        description: product.description || ""
      };
    });
  } else {
    products = [
      {
        name: "",
        description: ""
      }
    ];
  }

  organization.products = products;
  return organization;
};

function checkAndAddIdDocs(idDocs, docType, reference) {
  if (reference) {
    idDocs.push({ type: docType, number: reference });
  }
}

function addDocs(formData, name, file) {
  if (file) {
    formData.append(name, file);
  }
}

export const transferUiToApi = values => {
  let formData = new FormData();

  addDocs(formData, `logo`, values.uploadLogo);
  addDocs(formData, `companyProfile`, values.uploadCompanyProfile);
  addDocs(formData, `regCertificate`, values.uploadRegCertificate);
  addDocs(
    formData,
    `articlesOfAssociation`,
    values.uploadArticlesOfAssociation
  );

  const idDocs = [];
  const idDoc = values.identificationDocs;

  checkAndAddIdDocs(idDocs, "PAN", idDoc.panNumber);
  checkAndAddIdDocs(idDocs, "GSTIN", idDoc.gstnNumber);

  addDocs(formData, `id_doc_PAN`, idDoc.uploadPan);
  addDocs(formData, `id_doc_GSTIN`, idDoc.uploadGstn);

  const addresses = values.addresses.map(ad => {
    addDocs(formData, `address_doc_${ad.type}`, ad.uploadAddressProof);
    return {
      _id: ad._id,
      streetLine1: ad.streetLine1,
      streetLine2: ad.streetLine2,
      landmark: ad.landmark,
      city: ad.city,
      state: ad.state,
      postalCode: ad.postalCode,
      country: ad.country,
      status: ad.status,
      type: ad.type
    };
  });

  // Metrics
  const metrics = [];
  const metric = values.metric || {};
  const { employeeCount, femaleEmployeeCount, date } = metric;
  if (employeeCount > 0) {
    const newMetric = {
      employeeCount: employeeCount,
      femaleEmployeeCount: femaleEmployeeCount || 0,
      date: date || new Date()
    };
    metrics.push(newMetric);
  }

  // Products
  const prds = values.products || [];
  const products = [];
  prds.forEach(product => {
    const values = Object.values(product).filter(d => d);
    if (values.length > 0) {
      products.push(product);
    }
  });

  // financials
  const financials = [];
  const finals = values.financials || [];
  finals.forEach(finance => {
    const values = Object.values(finance).filter(d => d);

    if (values.length > 0) {
      const fin = {
        ...finance,
        date: finance.date || new Date()
      };
      financials.push(fin);
    }
  });

  const organizationForm = {
    name: values.name || "",
    website: values.website || "",
    domainOperation: values.domainOperation || "",
    legalStatus: values.legalStatus || "",
    estYear: values.estYear || "",
    reference: values.reference || "",

    subsidiary: values.subsidiary || false,
    parentCompany: values.parentCompany || "",
    bizSummary: values.bizSummary || "",
    services: values.services || "",

    promoters: values.promoters,

    phones: values.phones,
    emails: values.emails,
    addresses,
    identificationDocs: idDocs,
    financials,
    products,
    metrics
  };

  formData.append("message", JSON.stringify(organizationForm));
  return formData;
};
