import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import DataCompare from "./steps/DataCompare";
import DuplicateCheck from "./steps/DuplicateCheck";
import MasterForm from "./steps/MasterForm";
import OnboardIncubatee from "./incubatee/OnboardIncubatee";

class Onboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "incubateeType",
      onboardList: false,
      incubatorApplication: {},

      newOnboardForm: true,
      editOnboardForm: false,
      editOnboardFormId: "",

      previousLocation: ""
    };
  }

  handleActiveTab = (tabName, e) => {
    e.preventDefault();
    this.setState({ activeTab: tabName });
  };

  getRenderComponent = () => {
    const { activeTab } = this.state;

    if (this.props.match === undefined) {
      return null;
    }

    const onboardFormId = this.props.match.params.id;

    let renderComponent = "";

    if (activeTab === "duplicate") {
      renderComponent = <DuplicateCheck />;
    }
    if (activeTab === "dataCompare") {
      renderComponent = <DataCompare />;
    }
    if (activeTab === "masterForm") {
      renderComponent = <MasterForm />;
    }
    if (activeTab === "incubateeType") {
      renderComponent = <OnboardIncubatee onboardFormId={onboardFormId} />;
    }
    return renderComponent;
  };

  backToList = () => {
    this.setState({
      onboardList: true
    });
  };

  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  componentDidMount() {
    if (this.props.location.search !== "") {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const from = params.get("from");
      this.setState({ previousLocation: from });
    }
  }

  render() {
    const { onboardList, previousLocation } = this.state;

    if (onboardList) {
      return <Redirect to="/admin/incubatees/onboard" />;
    }

    let renderComponent = this.getRenderComponent();

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title">
                <Link
                  to={previousLocation}
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
                &nbsp;Onboard
              </span>
            </div>

            {renderComponent}
          </div>
        </div>
      </div>
    );
  }
}

export default Onboard;
