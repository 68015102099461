import React from "react";
import { changeToINR } from "../common/LookupConstants";

function FinancialYearInfo(props) {
  const incubatee = props.incubatee;
  const organization = incubatee.organization;

  const financials = organization.financials || [];

  return (
    <div>
      <div
        class="card flex-fill"
        style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
      >
        <div class="card-header border-0">
          <h6 class="card-titile mb-0 text-center">Financial Information</h6>
        </div>
        <div class="card-body pt-0">
          <div class="responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th class="display-table-header" scope="col">
                    Financial Year
                  </th>
                  <th class="display-table-header" scope="col">
                    Authorized Capital
                  </th>
                  <th class="display-table-header" scope="col">
                    Annual Turnover
                  </th>
                </tr>
              </thead>
              <tbody>
                {financials.map((finInfo, index) => {
                  return (
                    <tr key={index}>
                      <td>{finInfo.financialYear}</td>
                      <td>{changeToINR(finInfo.authorizedCapital)}</td>
                      <td>{changeToINR(finInfo.annualTurnover)}</td>
                    </tr>
                  );
                })}

                {financials.length === 0 ? (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No Financial Information
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancialYearInfo;
