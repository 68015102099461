import React, { Component, Fragment } from "react";
import AlertComponent from "../../common/AlertComponent";
import LeaseTerminationForm from "./LeaseTerminationForm";
import HttpUtil from "../../common/HttpUtil";
import { LEASE_TERMINATIONS_API } from "../../common/Constants";
import { checkEmptyValue } from "../../common/LookupConstants";
import { convertNumberToDate, convertDateToNumber } from "../DateUtil";
import Download from "downloadjs";

class LeaseTermination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaseTermination: null,
      remark: null,
      inputError: {},

      uploadedDocuments: [],
      documentUploadLimit: 5
    };
  }

  handleDateChange = (targetName, date) => {
    const leaseTermination = this.state.leaseTermination;
    leaseTermination[targetName] = date;
    this.setState({ leaseTermination });
  };

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    const leaseTermination = this.state.leaseTermination;
    leaseTermination[targetName] = targetValue;
    this.setState({ leaseTermination });
  };

  handleRemark = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    this.setState({ [targetName]: targetValue });
  };

  handleRemoveDocument = (idx, e) => {
    let uploadedDocuments = [...this.state.uploadedDocuments];
    if (uploadedDocuments.length === 0) {
      return;
    }

    uploadedDocuments = [...uploadedDocuments].filter(
      (scopy, index) => idx !== index
    );

    return this.setState({
      uploadedDocuments: uploadedDocuments.length > 0 ? uploadedDocuments : []
    });
  };

  handleUploadDocuments = e => {
    const lsetm = this.state.leaseTermination;
    const exsitingDocuments = lsetm.supportingDocuments
      ? lsetm.supportingDocuments
      : [];

    const existingDocCount = exsitingDocuments.length;
    const currentDocUploadCount = e.target.files.length;

    const totalDocCount = existingDocCount + currentDocUploadCount;
    const documentUploadLimit = this.state.documentUploadLimit;

    let inputError = {};

    if (documentUploadLimit < totalDocCount) {
      inputError[e.target.name] = true;
      this.setState({ inputError });
      return;
    } else {
      // const uploadedDocuments = [...this.state.uploadedDocuments];
      // uploadedDocuments.push(e.target.files);
      // console.log("uploadedDocuments:", uploadedDocuments);
      this.setState({
        [e.target.name]: e.target.files,
        // uploadedDocuments,
        inputError: {}
      });
    }
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getTerminationLease = id => {
    const url = `${LEASE_TERMINATIONS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        let requestedDate = "";
        if (data.requestedDate) {
          requestedDate = convertNumberToDate(data.requestedDate);
        } else {
          requestedDate = new Date(data.createdAt);
        }

        const actualTermiDate = convertNumberToDate(data.actualTerminationDate);
        this.setState({
          leaseTermination: {
            _id: data._id,
            lease: data.lease,
            requestedDate: requestedDate,
            actualTerminationDate: actualTermiDate,
            notes: data.notes.reverse(),
            terminationReason: data.terminationReason,
            stage: data.stage,
            status: data.status,
            action: data.action,
            supportingDocuments: data.supportingDocuments
          },
          actions: data.actions,
          uploadedDocuments: []
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getTerminationLease(id);
    }
  };

  validate = action => {
    const leaseTermination = this.state.leaseTermination;

    let inputError = {};
    const terminationReason = leaseTermination.terminationReason;
    const remark = this.state.remark;
    checkEmptyValue(inputError, "terminationReason", terminationReason);
    checkEmptyValue(inputError, "remark", remark);
    checkEmptyValue(
      inputError,
      "actualTerminationDate",
      leaseTermination.actualTerminationDate
    );

    const invalid = Object.keys(inputError).length > 0;
    if (invalid) {
      this.setState({
        inputError,
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: "Please correct the errors and submit again"
      });
      window.scrollTo(0, 0);
      return false;
    } else {
      const terminReq = { ...leaseTermination };

      terminReq.lease = leaseTermination.lease._id;

      const newRemark = { remark: this.state.remark };
      terminReq.notes = [newRemark];

      const requestedDate = new Date(leaseTermination.requestedDate);
      const actualTermiDate = new Date(leaseTermination.actualTerminationDate);
      terminReq.requestedDate = convertDateToNumber(requestedDate);
      terminReq.actualTerminationDate = convertDateToNumber(actualTermiDate);
      terminReq.action = action;
      terminReq.incubatee = leaseTermination.lease.incubatee._id;

      let formData = new FormData();

      const uploadedDocuments = this.state.uploadedDocuments;
      if (uploadedDocuments.length > 0) {
        Array.from(uploadedDocuments).forEach(file => {
          formData.append("supportingDocuments", file);
        });
      }

      formData.append("message", JSON.stringify(terminReq));
      this.setState({ inputError: {} });

      return formData;
    }
  };

  handleSubmit = action => {
    const formData = this.validate(action);
    if (!formData) {
      return;
    }

    const id = this.state.leaseTermination._id;
    const url = `${LEASE_TERMINATIONS_API}/${id}`;
    const headers = {};
    HttpUtil.put(
      url,
      headers,
      formData,
      data => {
        this.getTerminationLease(data._id);
        this.setState({
          _id: data._id,
          inputError: {},
          actions: data.actions,
          remark: null,

          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: "Lease Termination has been updated now."
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  downloadDocument = (file, e) => {
    e.preventDefault();
    let leaseId = "";
    let fileId = file._id;
    let filename = file.filename;
    if (this.props.match !== undefined) {
      leaseId = this.props.match.params.id;
    }
    if (leaseId === "") {
      return;
    }

    // Extract request method & header & params
    const id = this.state.leaseTermination._id;
    const url = `${LEASE_TERMINATIONS_API}/${id}/documents/${fileId}`;
    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(blob => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString());
      });
  };

  render() {
    if (this.state.leaseTermination === null) {
      return null;
    }
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      message: this.state.alertMessage,
      alertColor: this.state.alertColor,
      close: this.closeDefaultAlert
    };

    const props = {
      leaseTermination: this.state.leaseTermination,
      actions: this.state.actions,
      remark: this.state.remark,
      uploadedDocuments: this.state.uploadedDocuments,
      documentUploadLimit: this.state.documentUploadLimit,
      inputError: this.state.inputError,
      // events
      handleDateChange: this.handleDateChange,
      handleInputChange: this.handleInputChange,
      handleRemark: this.handleRemark,
      handleSubmit: this.handleSubmit,
      handleRemoveDocument: this.handleRemoveDocument,
      handleUploadDocuments: this.handleUploadDocuments,
      downloadDocument: this.downloadDocument
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12 text-center">
            <h4>{"Lease Request"}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <LeaseTerminationForm {...props} />
      </Fragment>
    );
  }
}

export default LeaseTermination;
