import React from "react";
import { GRANT_AWARD_CLOSURE_STATUS_REASONS_OBJECT } from "../../common/LookupConstants";
import { convertNumberToDate, getDisplayDate } from "../../lease/DateUtil";
import { downloadDocument } from "../../common/Util";
import { getFileIconByContentType } from "../../common/IconUtil";

function GrantAwardTerminationDetail({ award }) {
  return (
    <div className="shadow card flex-fill box-border box-light-blue mb-4">
      <div className="card-header border-0 text-center pb-0">
        <h6 className="mb-0 text-light-blue d-inline">
          <strong>Termination Information</strong>
        </h6>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-md-6 col-12">
            <table className="table table-sm table-borderless ">
              <tbody>
                <tr>
                  <th>Completed Successfully</th>
                  <td>{award.completed ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Status Reason</th>
                  <td>
                    {award.statusReason === "OTHER"
                      ? award.otherStatusReason
                      : GRANT_AWARD_CLOSURE_STATUS_REASONS_OBJECT[
                          award.statusReason
                        ] || "--"}
                  </td>
                </tr>
                <tr>
                  <th>Closure Date</th>
                  <td>
                    {getDisplayDate(
                      convertNumberToDate(award.grantClosureDate)
                    )}
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">Notes</th>
                </tr>
                <tr>
                  <td colSpan="2">
                    {award.closureNotes.map(note => (
                      <div key={note._id}>{note.remark}</div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-md-6 col-12">
            <div className="border-bottom pb-2 mb-2">
              <strong>Documents</strong>
            </div>
            <ul className="list-group list-group-flush">
              {award.closureDocuments.map((doc, index) => {
                return (
                  <li
                    key={doc._id}
                    className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                  >
                    <span>
                      <i
                        className={`${getFileIconByContentType(doc.type)} mr-2`}
                      />
                      {doc.type} - {doc.name}
                    </span>
                    <div>
                      <i
                        className="fas fa-download fa-md mr-3"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          downloadDocument(
                            doc.link,
                            msg => window.alert(msg),
                            doc.filename
                          )
                        }
                      />
                    </div>
                  </li>
                );
              })}
              {award.closureDocuments.length === 0 ? (
                <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                  No documents available
                </li>
              ) : null}
            </ul>
            {award.closureChecklist.length > 0 ? (
              <>
                <div className="border-bottom pb-2 mb-2 mt-2">
                  <strong>Checklist</strong>
                </div>

                {award.closureChecklist.map(chklst => {
                  return (
                    <div key={chklst._id} className="form-group row mb-2 mt-3">
                      <div className="col-12">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={chklst._id}
                            checked={chklst.selected}
                            readOnly
                            disabled
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={chklst._id}
                          >
                            {chklst.label}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrantAwardTerminationDetail;
