import React, { Component, Fragment } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { getActionCss } from "../../../common/Util";
import Can from "../../../../auth/can";

class CloseAssetReservation extends Component {
  render() {
    const { assetReservation, paymentPageUrl } = this.props;

    return (
      <Fragment>
        <Formik
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            return this.props.handleSubmit(values, {
              setSubmitting
            });
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <div className="row m-5">
                <div className="col-12 text-center">
                  {assetReservation.stage === "RESERVEAPPR" &&
                  assetReservation.status === "APPROVED" ? (
                    <Fragment>
                      {assetReservation.actions.length > 0 ? (
                        <Fragment>
                          {assetReservation.actions.map((action, idx) => {
                            if (action === "CANCEL") {
                              return null;
                            }
                            return (
                              <Fragment key={idx}>
                                <Can do="UPDATE" on="AssetReservationRequest">
                                  <button
                                    className={getActionCss(action)}
                                    type="submit"
                                    onClick={() => {
                                      setFieldValue("action", action);
                                    }}
                                  >
                                    {action === "CLOSE"
                                      ? "Close Reservation"
                                      : action}
                                  </button>
                                </Can>
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : null}

                  {/* If not generated "Charge" for this reservation request then hide "Pay Now" btn */}
                  {paymentPageUrl ? (
                    <Link
                      to={paymentPageUrl}
                      className="btn btn-sm btn-success ml-3"
                      role="button"
                    >
                      Pay Now
                    </Link>
                  ) : null}
                </div>
              </div>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

export default CloseAssetReservation;
