import * as Yup from "yup";

const POSTAL_CODE_REG_EXP = /^[1-9][0-9]{5}$/;

function greaterThanOrEqualTo(ref, refLabel, msg) {
  return this.test({
    name: "greaterThanOrEqualTo",
    exclusive: "false",
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || "${path} must be greater than or equal to ${reference}",
    params: {
      reference: refLabel || ref.path
    },
    test: function(value) {
      return value >= this.resolve(ref);
    }
  });
}

Yup.addMethod(Yup.number, "greaterThanOrEqualTo", greaterThanOrEqualTo);

const ADDRESS_SCHEMA = Yup.object().shape({
  streetLine1: Yup.string()
    .label("Street Line 1")
    .required(),
  streetLine2: Yup.string()
    .label("Street Line 2")
    .optional(),
  landmark: Yup.string()
    .label("Landmark")
    .optional(),
  city: Yup.string()
    .label("City")
    .required(),
  state: Yup.string()
    .label("State")
    .required(),
  country: Yup.string()
    .label("Country")
    .required(),
  postalCode: Yup.string()
    .label("Postal Code")
    .test("Postal Code", "Please enter valid Postal Code", value => {
      if (value) {
        const schema = Yup.string().matches(
          POSTAL_CODE_REG_EXP,
          "Please enter valid Postal Code"
        );
        return schema.isValidSync(value);
      }
      return true;
    })
    .required(),
  type: Yup.string()
    .label("Address Type")
    .required()
});

export const ORGANIZATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .label("Name")
    .required(),
  website: Yup.string()
    .label("Website")
    .optional(),
  domainOperation: Yup.string()
    .label("Domain Operation")
    .required(),
  legalStatus: Yup.string()
    .label("Legal Status")
    .required(),
  estYear: Yup.string()
    .label("Est Year")
    .required(),
  reference: Yup.string()
    .label("Reference")
    .optional(),
  subsidiary: Yup.boolean()
    .label("Subsidiary")
    .optional(),
  parentCompany: Yup.string().when("subsidiary", {
    is: true,
    then: Yup.string()
      .label("Parent Company")
      .required(),
    otherwise: Yup.string().optional()
  }),

  metric: Yup.object()
    .shape({
      date: Yup.date()
        .label("Date")
        .typeError("Date is required")
        .nullable()
        .optional(),
      employeeCount: Yup.number()
        .positive()
        .integer()
        .label("Employee Count")
        .typeError("Employee Count is required")
        .nullable()
        .optional(),
      femaleEmployeeCount: Yup.number()
        .positive()
        .integer()
        .lessThan(
          Yup.ref("employeeCount"),
          "Female Employee count must be lessthan Total Employee Count"
        )
        .label("Female Employee Count")
        .typeError("Female Employee count is required")
        .nullable()
        .optional()
    })
    .nullable()
    .optional(),

  products: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .label("Product Name")
          .optional(),

        description: Yup.string().when("name", {
          is: value => value,
          then: Yup.string()
            .label("Description")
            .required(),
          otherwise: Yup.string()
            .nullable()
            .optional()
        })
      })
    )
    .nullable()
    .optional(),

  addresses: Yup.array()
    .of(ADDRESS_SCHEMA)
    .min(1)
    .label("Addresses")
    .required(),

  phones: Yup.array()
    .of(
      Yup.object().shape({
        phone: Yup.string()
          .label("Phone")
          .required(),
        type: Yup.string()
          .label("Type")
          .required()
      })
    )
    .min(1)
    .label("Phones")
    .required(),

  emails: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string()
          .label("Email")
          .required(),
        type: Yup.string()
          .label("Type")
          .required()
      })
    )
    .min(1)
    .label("Emails")
    .required(),

  identificationDocs: Yup.object().shape({
    panNumber: Yup.string()
      .label("PAN Number")
      .required(),

    gstnNumber: Yup.string()
      .label("Gstn Number")
      .optional()
  }),

  promoters: Yup.array()
    .of(
      Yup.object().shape({
        designation: Yup.string()
          .label("Designation")
          .required(),
        shareholding: Yup.string()
          .label("Shareholding")
          .required()
      })
    )
    .min(1)
    .label("Promoters")
    .required(),

  financials: Yup.array()
    .of(
      Yup.object()
        .shape({
          financialYear: Yup.string()
            .label("Financial Year")
            .optional(),

          authorizedCapital: Yup.number().when("financialYear", {
            is: value => value,
            then: Yup.number()
              .moreThan(0)
              .label("Authorized Capital")
              .typeError("Authorized Capital required")
              .required(),
            otherwise: Yup.number()
              .optional()
              .typeError("Authorized Capital required")
              .nullable()
          }),
          annualTurnover: Yup.number().when("financialYear", {
            is: value => value,
            then: Yup.number()
              .moreThan(0)
              .label("Annual Turnover")
              .typeError("Annual Turnover required")
              .required(),
            otherwise: Yup.number()
              .optional()
              .typeError("Annual Turnover required")
              .nullable()
          })
        })
        .test("unique", "Year must be unique", function validateUnique(
          financial
        ) {
          const otherData = this.parent.filter(fnal => fnal !== financial);

          const isDuplicate = otherData.some(
            data => data.financialYear === financial.financialYear
          );

          return isDuplicate
            ? this.createError({ path: `${this.path}.financialYear` })
            : true;
        })
    )
    .nullable()
    .optional()
});
