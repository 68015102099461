import React, { useState, useEffect } from "react";
import AccountClosureRequestForm from "../accountClosureRequest/AccountClosureRequestForm";
import { Formik } from "formik";
import * as Yup from "yup";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { convertDateToNumber, convertNumberToDate } from "../../lease/DateUtil";
import { CLOSURE_SUCCESS_MESSAGES } from "../../common/Util";

function getInitialValues(closureRequest) {
  const {
    entityType,
    entity = {},
    closureDate,
    reason,
    documents = [],
    notes = [],
    adjustFromAdvance,
    stage,
    status,
    refund = {}
  } = closureRequest;

  return {
    entityType: entityType || "",
    entity: entity || {},
    closureDate: convertNumberToDate(closureDate) || "",
    reason: reason || "",
    documents: documents || [],
    availableDocuments: [],
    adjustFromAdvance: adjustFromAdvance || false,
    adjustedPendingAmount: "",
    notes: notes || [],
    note: "",
    stage: stage || "",
    status: status || "",
    // Payment Details
    amount: refund.amount || "",
    refundAmount: closureRequest.adjustedAmount - closureRequest.advance,
    paymentMode: refund.paymentMode || "REFUND_CHEQUE",
    paymentDate: convertNumberToDate(refund.refundDate) || "",
    collectedBy: refund.collectedBy || "",
    reference: refund.reference || "",
    bankName: refund.bankName || "",
    chequeNumber: refund.chequeNumber || "",
    chequeDate: convertNumberToDate(refund.chequeDate) || "",
    paymentTransferType: refund.paymentTransferType || "",
    cardType: refund.cardType || "",
    paymentNotes: refund.notes || "",

    cardIssuer: refund.cardIssuer || ""
  };
}
const validationSchema = Yup.object().shape({
  entityType: Yup.string()
    .label("Entity Type")
    .required(),
  entity: Yup.string()
    .label("Entity")
    .required(),
  closureDate: Yup.date().when("action", {
    is: "SUBMIT",
    then: Yup.date()
      .label("Closure Date")
      .required()
  }),
  reason: Yup.string().when("action", {
    is: "SUBMIT",
    then: Yup.string()
      .label("Reason")
      .required()
  }),
  note: Yup.string().when("action", {
    is: "SAVE",
    then: Yup.string()
      .label("Notes")
      .optional(),
    otherwise: Yup.string().when("action", {
      is: "REFUND",
      then: Yup.string()
        .label("Notes")
        .optional(),
      otherwise: Yup.string()
        .label("Notes")
        .required()
    })
  }),

  // Payment Validation
  amount: Yup.string().when("action", {
    is: "REFUND",
    then: Yup.string()
      .label("Amount")
      .optional()
  }),
  chequeNumber: Yup.string().when("action", {
    is: "REFUND",
    then: Yup.string().when("paymentMode", {
      is: "REFUND_CHEQUE",
      then: Yup.string()
        .label("Cheque Number")
        .required()
    })
  }),
  chequeDate: Yup.string().when("action", {
    is: "REFUND",
    then: Yup.string().when("paymentMode", {
      is: "REFUND_CHEQUE",
      then: Yup.string()
        .label("Cheque Date")
        .required()
    })
  }),

  bankName: Yup.string().when("action", {
    is: "REFUND",
    then: Yup.string().when("paymentMode", {
      is: "REFUND_CHEQUE",
      then: Yup.string()
        .label("Bank Name")
        .required()
    })
  }),

  cardType: Yup.string().when("action", {
    is: "REFUND",
    then: Yup.string().when("paymentMode", {
      is: "REFUND_CARD_PAYMENT",
      then: Yup.string()
        .label("Card Type")
        .required()
    })
  }),

  cardIssuer: Yup.string().when("action", {
    is: "REFUND",
    then: Yup.string().when("paymentMode", {
      is: "REFUND_CARD_PAYMENT",
      then: Yup.string()
        .label("Card Issuer")
        .required()
    })
  }),

  paymentTransferType: Yup.string().when("action", {
    is: "REFUND",
    then: Yup.string().when("paymentMode", {
      is: "REFUND_ONLINE_PAYMENT",
      then: Yup.string()
        .label("Transfer Type")
        .required()
    })
  }),

  collectedBy: Yup.string().optional(),
  paymentNotes: Yup.string().optional(),
  reference: Yup.string().optional(),
  paymentDate: Yup.string().optional()
});

function handleValidate(values, setAlert, setData) {
  const { entity, entityType } = values;

  const payLoad = {
    entity: entity.value,
    entityType: entityType === "Organization" ? "COMPANY" : "INDIVIDUAL"
  };
  const url = "/api/v1/finance/account-closures/validate";
  const headers = { "Content-Type": "application/json" };
  HttpUtil.post(
    url,
    headers,
    payLoad,
    data => setData({ ...data, status: "fetched" }),
    data => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: data.message
      });
      window.scrollTo(0, 0);
    },
    error => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: error.toString()
      });
      window.scrollTo(0, 0);
    }
  );
}

function getAccountClosureRequest(
  closureRequestId,
  setClosureRequest,
  setAlert
) {
  let url = `/api/v1/finance/account-closures/${closureRequestId}`;

  HttpUtil.get(
    url,
    {},
    data => setClosureRequest(data),
    (data, status) => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: data.message
      });
      window.scrollTo(0, 0);
    },
    error => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: error.toString()
      });
      window.scrollTo(0, 0);
    }
  );
}

function handleClosureCreate(values, setAlert, router, prevLocation) {
  const { entity, entityType } = values;

  const payLoad = {
    entity: entity.value,
    entityType: entityType === "Organization" ? "COMPANY" : "INDIVIDUAL"
  };
  const url = "/api/v1/finance/account-closures";
  const headers = { "Content-Type": "application/json" };
  HttpUtil.post(
    url,
    headers,
    payLoad,
    data =>
      router.push(
        `/finance/account/closure-requests/edit/${
          data._id
        }?from=${prevLocation}`
      ),
    data => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: data.message
      });
      window.scrollTo(0, 0);
    },
    error => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: error.toString()
      });
      window.scrollTo(0, 0);
    }
  );
}
function addDocs(formData, name, file) {
  if (file) {
    formData.append(name, file);
  }
}

function handleClosureUpdate(id, values, setAlert, setRefetch, prevLocation) {
  const url = `/api/v1/finance/account-closures/${id}`;

  const {
    documents,
    closureDate,
    amount,
    paymentMode,
    paymentDate,
    collectedBy,
    reference,
    bankName,
    chequeNumber,
    chequeDate,
    paymentTransferType,
    cardType,
    cardIssuer,
    entity,
    refundAmount,
    paymentNotes,
    ...restPayload
  } = values;

  restPayload.closureDate = convertDateToNumber(closureDate);
  restPayload.entity = entity._id;

  restPayload.refund = {
    amount: values.amount,
    paymentMode: values.paymentMode,
    paymentDate: convertDateToNumber(values.paymentDate),
    collectedBy: values.collectedBy,
    reference: values.reference,
    bankName: values.bankName,
    chequeNumber: values.chequeNumber,
    chequeDate: convertDateToNumber(values.chequeDate),
    paymentTransferType: values.paymentTransferType,
    cardType: values.cardType,
    cardIssuer: values.cardIssuer,
    paymentNotes: values.paymentNotes
  };

  const formData = new FormData();
  formData.append("message", JSON.stringify(restPayload));

  values.documents.forEach(doc => {
    addDocs(formData, "documents", doc);
  });

  HttpUtil.put(
    url,
    {},
    formData,
    data => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: CLOSURE_SUCCESS_MESSAGES[values.action]
      });
      window.scrollTo(0, 0);
      setRefetch(Math.random());
    },
    (data, status) => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: data.message
      });
      window.scrollTo(0, 0);
    },
    error => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: error.toString()
      });
      window.scrollTo(0, 0);
    }
  );
}

function AccountClosureRequest(props) {
  const [data, setData] = useState({
    leases: [],
    assetReservations: []
  });

  const [alert, setAlert] = useState({
    alertType: "",
    showAlert: false,
    alertColor: "",
    alertMessage: ""
  });
  const [reFetch, setRefetch] = useState(0);
  const closureRequestId = props.match.params.id;

  const [closureRequest, setClosureRequest] = useState({});

  const [prevLocation, setPrevLocation] = useState({});

  useEffect(
    () => {
      if (closureRequestId)
        getAccountClosureRequest(closureRequestId, setClosureRequest, setAlert);
    },
    [reFetch]
  );

  useEffect(
    () => {
      if (props.location.search !== "") {
        const search = props.location.search;
        const params = new URLSearchParams(search);

        const from = params.get("from");
        setPrevLocation(from);
      }
    },
    [prevLocation]
  );

  return (
    <>
      <AlertComponent
        show={alert.showAlert}
        type={alert.alertType}
        alertColor={alert.alertColor}
        message={alert.alertMessage}
        close={() =>
          setAlert({
            alertType: "",
            showAlert: false,
            alertColor: "",
            alertMessage: ""
          })
        }
      />
      <Formik
        enableReinitialize
        initialValues={getInitialValues(closureRequest)}
        validationSchema={validationSchema}
        onSubmit={values => {
          handleClosureUpdate(closureRequest._id, values, setAlert, setRefetch);
        }}
      >
        {formikProps => (
          <AccountClosureRequestForm
            {...formikProps}
            handleValidate={values => handleValidate(values, setAlert, setData)}
            handleClosureCreate={values =>
              handleClosureCreate(values, setAlert, props.history, prevLocation)
            }
            data={data}
            setData={setData}
            closureRequestId={closureRequestId}
            closureRequest={closureRequest}
            setRefetch={setRefetch}
            prevLocation={prevLocation}
          />
        )}
      </Formik>
    </>
  );
}

export default AccountClosureRequest;
