import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Form, Formik } from "formik";
import HttpUtil from "../common/HttpUtil";
import {
  INCUBATOR_APPLICATION_API,
  ORGANIZATIONS_API,
  INDIVIDUALS_API
} from "../common/Constants";
import AlertComponent from "../common/AlertComponent";
import {
  individualPhotoLink,
  organizationLogoLink
} from "../common/DocumentLinkUtil";
import Link from "react-router-dom/Link";
// import { TEMP_DATA } from "./tempData";

// eslint-disable-next-line no-unused-vars
const Organizations = props => {
  const { values, searchResult, setFieldValue } = props;

  let organizations = searchResult.organizations || [];

  if (organizations.length === 0) {
    return null;
  }

  const list = organizations.map((org, orgIndex) => {
    const logoUrl = organizationLogoLink(org._id, org.logo);

    const idDocs = org.identificationDocs || [];
    // const idDocs = [];

    const matchedEmailsKeys = (org.matchedEmails || []).reduce((ag, value) => {
      ag[value.email] = true;
      return ag;
    }, {});

    const matchedPhoneKeys = (org.matchedPhones || []).reduce((ag, value) => {
      ag[value.phone] = true;
      return ag;
    }, {});

    const matchedIdDocKeys = (org.matchedIdDocs || []).reduce((ag, value) => {
      ag[`${value.type}|${value.number}`] = true;
      return ag;
    }, {});

    return (
      <tr key={orgIndex}>
        <td className="text-center align-middle">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id={`org${orgIndex}`}
              name="customRadioInline1"
              value={org._id}
              checked={values.selectedIncubatee === org._id}
              className="custom-control-input"
              onChange={e => {
                setFieldValue("selectedIncubatee", e.target.value);
                setFieldValue("type", "Organization");
              }}
            />
            <label
              className="custom-control-label"
              htmlFor={`org${orgIndex}`}
            />
          </div>
        </td>
        <td className="text-center align-middle">
          {logoUrl ? (
            <div className="ml-2" style={{ width: 50, height: 50 }}>
              <img
                className="rounded-circle w-100"
                src={logoUrl}
                alt={org.name}
              />
            </div>
          ) : (
            <i className="fas fa-industry fa-lg" style={{ color: "#647acb" }} />
          )}
        </td>
        <td className="align-middle">{org.name}</td>
        <td className="align-middle">
          <ul className="list-group">
            {org.emails.map((em, emIndx) => (
              <li
                key={emIndx}
                className={`list-group-item p-0 border-0 text-center`}
              >
                <div
                  className={`${
                    matchedEmailsKeys[em.email] ? "search-highlight" : ""
                  }`}
                >
                  {em.email}
                </div>
              </li>
            ))}
          </ul>
        </td>
        <td className="align-middle">
          <ul className="list-group">
            {org.phones.map((ph, phIndx) => (
              <li
                key={phIndx}
                className="list-group-item p-0 border-0 text-center"
              >
                <div
                  className={`${
                    matchedPhoneKeys[ph.phone] ? "search-highlight" : ""
                  }`}
                >
                  {ph.phone}
                </div>
              </li>
            ))}
          </ul>
        </td>
        <td className="align-middle">
          <ul className="list-group">
            {idDocs.map((idDoc, idIndx) => {
              return (
                <li
                  key={idIndx}
                  className="list-group-item p-0 border-0 d-flex align-items-center text-center"
                >
                  <div
                    className={`${
                      matchedIdDocKeys[`${idDoc.type}|${idDoc.number}`]
                        ? "search-highlight"
                        : ""
                    }`}
                  >
                    {idDoc.number}
                    <span className="badge badge-primary badge-pill ml-2">
                      {idDoc.type}
                    </span>
                  </div>
                </li>
              );
            })}
            {idDocs.length === 0 ? "--" : null}
          </ul>
        </td>
      </tr>
    );
  });

  return (
    <div className="mt-2 p-3">
      <h6 className="card-title mb-0">Organization</h6>
      <div
        className="card mt-2"
        style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
      >
        <div className="card-body p-0">
          <table className="table mb-1">
            <thead className="">
              <tr>
                <th
                  scope="col"
                  className="border-bottom-0 display-table-header"
                  style={{ width: "10%" }}
                />
                <th
                  scope="col"
                  className="border-bottom-0 display-table-header"
                  style={{ width: "10%" }}
                />
                <th
                  scope="col"
                  className="border-bottom-0 display-table-header"
                  style={{ width: "16.66%" }}
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="border-bottom-0 display-table-header"
                  style={{ width: "16.66%" }}
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="border-bottom-0 display-table-header"
                  style={{ width: "16.66%" }}
                >
                  Phone
                </th>
                <th
                  scope="col"
                  className="border-bottom-0 display-table-header"
                  style={{ width: "16.66%" }}
                >
                  Id Documents
                </th>
              </tr>
            </thead>
            <tbody>
              {list}
              {/* <tr>
                <td className="text-center align-middle">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline1"
                    ></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="ml-2" style={{ width: 50, height: 50 }}>
                    <img
                      className="rounded-circle w-100"
                      src="https://www.designfreelogoonline.com/wp-content/uploads/2016/03/00106-3D-company-logo-design-free-logo-online-Template-04.png"
                      alt="Company"
                    />
                  </div>
                </td>
                <td>
                  <div>
                    Hurestic IoT Systems <br />
                    <small>Partnership</small>
                  </div>
                </td>
                <td>
                  <div>
                    support.m@hurestic.com
                    <br />
                  </div>
                </td>
                <td>
                  <div>
                    9686833277
                    <br />
                  </div>
                </td>
                <td>
                  <div>
                    www.hurestic.com <br />
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-center align-middle">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadioInline2"
                      name="customRadioInline2"
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline2"
                    ></label>
                  </div>
                </td>
                <td className="text-center">
                  <div className="ml-2" style={{ width: 50, height: 50 }}>
                    <img
                      className="rounded-circle w-100"
                      src="https://www.designfreelogoonline.com/wp-content/uploads/2014/12/00240-Design-Free-3D-Company-Logo-Templates-03.png"
                      alt="Company"
                    />
                  </div>
                </td>
                <td>
                  <div>
                    Nano IoT Systems <br />
                    <small>Private Limited</small>
                  </div>
                </td>
                <td>
                  <div>
                    nano.m@hurestic.com
                    <br />
                  </div>
                </td>
                <td>
                  <div>
                    9686878977
                    <br />
                  </div>
                </td>
                <td>
                  <div>
                    www.nanohurestic.com <br />
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const Individuals = props => {
  const { values, searchResult, setFieldValue } = props;
  let individuals = searchResult.individuals || [];

  if (individuals.length === 0) {
    return null;
  }

  let list = individuals.map((indv, index) => {
    let names = [];
    if (indv.firstName) {
      names.push(indv.firstName);
    }
    if (indv.lastName) {
      names.push(indv.lastName);
    }

    const idDocs = indv.identificationDocs || [];
    // const idDocs = [];
    let photoUrl = individualPhotoLink(indv._id, indv.photo);

    const matchedEmailsKeys = (indv.matchedEmails || []).reduce((ag, value) => {
      ag[value.email] = true;
      return ag;
    }, {});

    const matchedPhoneKeys = (indv.matchedPhones || []).reduce((ag, value) => {
      ag[value.phone] = true;
      return ag;
    }, {});

    const matchedIdDocKeys = (indv.matchedIdDocs || []).reduce((ag, value) => {
      ag[`${value.type}|${value.number}`] = true;
      return ag;
    }, {});

    return (
      <tr key={index}>
        <td className="text-center align-middle">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              id={`indv${index}`}
              name="customRadioInline1"
              value={indv._id}
              checked={values.selectedIncubatee === indv._id}
              className="custom-control-input"
              onChange={e => {
                setFieldValue("selectedIncubatee", e.target.value);
                setFieldValue("type", "Individual");
              }}
            />
            <label className="custom-control-label" htmlFor={`indv${index}`} />
          </div>
        </td>
        <td className="text-center align-middle">
          {photoUrl ? (
            <div className="ml-2" style={{ width: 50, height: 50 }}>
              <img
                className="rounded-circle w-100"
                src={photoUrl}
                alt={names.join(" ")}
              />
            </div>
          ) : (
            <div className="ml-2" style={{ width: 50, height: 50 }}>
              <img
                className="rounded-circle w-100"
                src="images/grey-person-image-icon.png"
                alt="User"
              />
            </div>
          )}
        </td>
        <td className="align-middle">
          <div>
            {names.join(" ")} <br />
          </div>
        </td>
        <td className="align-middle">
          <ul className="list-group">
            {indv.emails.map((em, emIndx) => (
              <li key={emIndx} className={`list-group-item p-0 border-0`}>
                <div
                  className={`${
                    matchedEmailsKeys[em.email] ? "search-highlight" : ""
                  }`}
                >
                  {em.email}
                </div>
              </li>
            ))}
          </ul>
        </td>
        <td className="align-middle">
          <ul className="list-group">
            {indv.phones.map((ph, phIndx) => (
              <li key={phIndx} className="list-group-item p-0 border-0">
                <div
                  className={`${
                    matchedPhoneKeys[ph.phone] ? "search-highlight" : ""
                  }`}
                >
                  {ph.phone}
                </div>
              </li>
            ))}
          </ul>
        </td>
        <td className="">
          <ul className="list-group">
            {idDocs.map((idDoc, idIndx) => {
              return (
                <li
                  key={idIndx}
                  className="list-group-item p-0 border-0 d-flex align-items-center"
                >
                  <div
                    className={`${
                      matchedIdDocKeys[`${idDoc.type}|${idDoc.number}`]
                        ? "search-highlight"
                        : ""
                    }`}
                  >
                    {idDoc.number}
                    <span className="badge badge-primary badge-pill ml-2">
                      {idDoc.type}
                    </span>
                  </div>
                </li>
              );
            })}
            {idDocs.length === 0 ? "--" : null}
          </ul>
        </td>
      </tr>
    );
  });
  return (
    <Fragment>
      <div className="mt-4 p-3">
        <h6 className="card-title mb-0">Individuals</h6>
        <div
          className="card mt-2"
          style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
        >
          <div className="card-body p-0">
            <table className="table mb-1">
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "10%" }}
                  />
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "10%" }}
                  />
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "16.66%" }}
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "16.66%" }}
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "16.66%" }}
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="border-bottom-0 display-table-header"
                    style={{ width: "20%" }}
                  >
                    Id Documents
                  </th>
                </tr>
              </thead>
              <tbody>
                {list}
                {/* <tr>
                  <td className="text-center align-middle">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="indvcustomRadioInline1"
                        name="customRadioInline1"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="indvcustomRadioInline1"
                      ></label>
                    </div>
                  </td>
                  <td className="text-center">
                    <div className="ml-2" style={{ width: 50, height: 50 }}>
                      <img
                        className="rounded-circle w-100"
                        src="/api/v1/organizations/5d049fa883379f20b97dd070/logo/5d2420a7730044268b4860f7"
                        alt="Individual"
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      Ram Prasad D S <br />
                      <small>CEO</small>
                    </div>
                  </td>
                  <td>
                    <div>
                      ramprasad1803@gmail.com
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      9629969190
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Pan : AJRPG5918K
                      <br />
                      Aadhaar : 954167204498
                      <br />
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const MatchedIncubatees = props => {
  const { values, lookupResults, setFieldValue } = props;

  const selectedId = values.selectedIncubatee;

  const results = lookupResults.map((result, index) => {
    let logoOrPhoto = "";
    if (result.incubateeType === "Individual") {
      logoOrPhoto = (
        <img
          src={result.logoOrPhotoUrl || "images/grey-person-image-icon.png"}
          className="rounded-circle mr-2"
          alt={result.name}
          width="46"
          height="46"
        />
      );
    } else if (result.incubateeType === "Organization") {
      if (result.logoOrPhotoUrl) {
        logoOrPhoto = (
          <img
            src={result.logoOrPhotoUrl}
            className="rounded-circle mr-2"
            alt={result.name}
            width="46"
            height="46"
          />
        );
      } else {
        logoOrPhoto = (
          <i className="fas fa-industry fa-lg" style={{ color: "#647acb" }} />
        );
      }
    }
    return (
      <div
        key={index}
        className={`card card-fluid m-2 ${
          selectedId === result._id ? "border border-success" : ""
        }`}
        onClick={() => {
          if (values.selectedIncubatee === result._id) {
            setFieldValue("selectedIncubatee", null);
            setFieldValue("type", null);
          } else {
            setFieldValue("selectedIncubatee", result._id);
            setFieldValue("type", result.incubateeType);
          }
        }}
      >
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-auto pr-0">{logoOrPhoto}</div>
            <div className="col ml-md-n2">
              {result.name}
              <small className="d-block text-muted">
                {result.incubateeType}
              </small>
            </div>
            <div className="col-auto">
              {selectedId === result._id ? (
                <i className="fas fa-check-circle fa-2x text-success" />
              ) : null}
            </div>
          </div>
        </div>

        <div className="card-body p-0">
          <div className="row align-items-center p-3">
            <div className="col-1 align-self-start">
              <i className="fas fa-mobile-alt mr-2" />
            </div>
            <div className="col pl-2">
              <h6 className="text-sm mb-0">Phone</h6>
              <div className="mt-1">
                {result.contact.phones.map((ph, phIndex) => {
                  let cssClassName = ph.matched
                    ? "text-sm text-muted matched-highlight mb-1"
                    : "text-sm text-muted mb-1";
                  return (
                    <div key={phIndex} className={cssClassName}>
                      {ph.phone}
                      {ph.matched ? (
                        <i className="fas fa-check-circle ml-1" />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="row align-items-center p-3">
            <div className="col-1 align-self-start">
              <i className="far fa-envelope" />
            </div>
            <div className="col pl-2">
              <h6 className="text-sm mb-0">Email</h6>
              <div className="mt-1">
                {result.contact.emails.map((em, emIndex) => {
                  let cssClassName = em.matched
                    ? "text-sm text-muted matched-highlight mb-1"
                    : "text-sm text-muted mb-1";
                  return (
                    <div key={emIndex} className={cssClassName}>
                      {em.email}
                      {em.matched ? (
                        <i className="fas fa-check-circle ml-1" />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="row align-items-center p-3">
            <div className="col-1 align-self-start">
              <i className="far fa-address-card" />
            </div>
            <div className="col pl-2">
              <h6 className="text-sm mb-0">Identification</h6>
              {result.contact.identificationDocs.map((idDoc, idDocIndex) => {
                let cssClassName = idDoc.matched
                  ? "text-sm text-muted matched-highlight"
                  : "text-sm text-muted";
                return (
                  <div key={idDocIndex} className="row mt-1">
                    <div className="col">
                      <span className="text-sm text-muted">{idDoc.type}</span>
                    </div>
                    <div className="col-auto">
                      <span className={cssClassName}>
                        {idDoc.number}
                        {idDoc.matched ? (
                          <i className="fas fa-check-circle ml-1" />
                        ) : null}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <Fragment>
      <div className="row mt-0 pt-0 pl-3 pr-3 pb-3 no-gutters justify-content-center">
        <div className="card-deck p-2">{results}</div>

        {/* <div className="col-lg-4">
          <div
            className={`card card-fluid m-2 ${
              selectedId === 1 ? "border border-success" : ""
            }`}
            onClick={props.handleSelectIncubatee.bind(this, 1)}
          >
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-auto pr-0">
                  <a href="#" className="avatar rounded-circle">
                    <img
                      src="/api/v1/organizations/5d049fa883379f20b97dd070/logo/5d2420a7730044268b4860f7"
                      className="rounded-circle mr-2"
                      alt="Ashley Briggs"
                      width="46"
                      height="46"
                    />
                  </a>
                </div>
                <div className="col ml-md-n2">
                  John Sullivan DEFAULT
                  <small className="d-block text-muted">Individual</small>
                </div>
                <div className="col-auto">
                  {selectedId === 1 ? (
                    <i className="fas fa-check-circle fa-2x text-success"></i>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              <div className="row align-items-center p-3">
                <div className="col-1 align-self-start">
                  <i className="fas fa-mobile-alt mr-2"></i>
                </div>
                <div className="col pl-2">
                  <h6 className="text-sm mb-0">Phone</h6>
                  <div className="mt-1">
                    <div className="text-sm text-muted matched-highlight mb-1">
                      9629969190 <i className="fas fa-check-circle ml-1"></i>
                    </div>
                    <div className="text-sm text-muted">9629969192</div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center p-3">
                <div className="col-1 align-self-start">
                  <i className="far fa-envelope"></i>
                </div>
                <div className="col pl-2">
                  <h6 className="text-sm mb-0">Email</h6>
                  <div className="mt-1">
                    <div className="text-sm text-muted matched-highlight mb-1">
                      ramprasad1803@gmail.com
                      <i className="fas fa-check-circle ml-1"></i>
                    </div>
                    <div className="text-sm text-muted">
                      ramprasad1804@gmail.com
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center p-3">
                <div className="col-1 align-self-start">
                  <i className="far fa-address-card"></i>
                </div>
                <div className="col pl-2">
                  <h6 className="text-sm mb-0">Identification</h6>
                  <div className="row mt-1">
                    <div className="col">
                      <span className="text-sm text-muted">PAN</span>
                    </div>
                    <div className="col-auto">
                      <span className="text-sm text-muted matched-highlight">
                        AGDC12345 <i className="fas fa-check-circle ml-1"></i>
                      </span>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col">
                      <span className="text-sm text-muted">Aadhaar</span>
                    </div>
                    <div className="col-auto">
                      <span className="text-sm text-muted matched-highlight">
                        1234465798798
                        <i className="fas fa-check-circle ml-1"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
 */}
      </div>
    </Fragment>
  );
};

const CustomForm = props => {
  const { values, searchResult, lookupResults } = props;

  let individualIsEmpty = searchResult.individuals.length === 0;

  let organizationIsEmpty = searchResult.organizations.length === 0;

  let lookupResultsIsEmpty = lookupResults.length === 0;

  return (
    <Form>
      <div className="container">
        <div className="elevate" style={{ backgroundColor: "#fff" }}>
          <div className="card-title text-center mb-0">
            <h6 className="mb-0 d-inline-block text-light-blue  mt-4">
              {lookupResultsIsEmpty ? (
                <strong>No Matches Found</strong>
              ) : (
                <strong>Please choose the Incubatee</strong>
              )}
            </h6>
          </div>

          {lookupResultsIsEmpty ? (
            <div className="p-5 text-center">
              <img
                src="images/no_data.svg"
                className="responsive"
                alt="No Data"
                width="200"
                height="200"
              />
            </div>
          ) : (
            <Fragment>
              <MatchedIncubatees {...props} />
              {/* <Organizations {...props} />
              <Individuals {...props} /> */}
            </Fragment>
          )}

          <div className="card-footer">
            <div className="row">
              <div className="col-12 text-center">
                {individualIsEmpty && organizationIsEmpty ? (
                  <Link to="/funding/shortList" className="fa-lg">
                    Back
                  </Link>
                ) : (
                  <Fragment>
                    {values.selectedIncubatee && values.type ? (
                      <button className="btn btn-sm btn-primary" type="submit">
                        Next
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                      >
                        Next
                      </button>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

class GrantOnboardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        selectedIncubatee: "",
        type: ""
      },
      searchResult: null,
      lookupResults: [],

      redirectToAwardPage: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  handleSubmit = (values, { setSubmitting }) => {
    const selectedIncubatee = values.selectedIncubatee;
    const type = values.type;
    const currentPathname = this.props.location.pathname;
    const appFormId = this.props.match.params.id;
    const from = `?from=${currentPathname}&incubatee=${selectedIncubatee}&type=${type}&appFormId=${appFormId}`;

    const redirectToAwardPage = `/funding/awards/new${from}`;

    this.setState({ redirectToAwardPage });
  };

  setMatchedPhones = (matchedPhones, phones) => {
    let allPhones = [];
    const mPhones = {};
    matchedPhones.forEach(ph => {
      mPhones[ph.phone] = true;
    });

    phones.forEach(ph => {
      const matched = mPhones[ph.phone] || false;
      const phone = { ...ph };
      phone.matched = matched;
      allPhones.push(phone);
    });
    return allPhones;
  };

  setMatchedEmails = (matchedEmails, emails) => {
    let allEmails = [];
    const mEmails = {};
    matchedEmails.forEach(em => {
      mEmails[em.email] = true;
    });

    emails.forEach(em => {
      const matched = mEmails[em.email] || false;
      const email = { ...em };
      email.matched = matched;
      allEmails.push(email);
    });

    return allEmails;
  };

  setMatchedIdDocs = (matchedIdDocs, idDocs) => {
    let allIdDocs = [];
    const mIdDocs = {};
    matchedIdDocs.forEach(id => {
      mIdDocs[id.number] = true;
    });

    idDocs.forEach(id => {
      const matched = mIdDocs[id.number] || false;
      const idDoc = { ...id };
      idDoc.matched = matched;
      allIdDocs.push(idDoc);
    });
    return allIdDocs;
  };

  setIndividualContacts = individual => {
    const matchedPhones = individual.matchedphones || [];
    const phones = individual.phones || [];
    let allPhones = this.setMatchedPhones(matchedPhones, phones);

    const matchedEmails = individual.matchedEmails || [];
    const emails = individual.emails || [];
    let allEmails = this.setMatchedEmails(matchedEmails, emails);

    const matchedIdDocs = individual.matchedIdDocs || [];
    const identificationDocs = individual.identificationDocs || [];
    let allIdDocs = this.setMatchedIdDocs(matchedIdDocs, identificationDocs);

    const contactInfo = {
      phones: allPhones,
      emails: allEmails,
      identificationDocs: allIdDocs
    };
    return contactInfo;
  };

  setOrganizationContacts = org => {
    const matchedPhones = org.matchedphones || [];
    const phones = org.phones || [];
    let allPhones = this.setMatchedPhones(matchedPhones, phones);

    const matchedEmails = org.matchedEmails || [];
    const emails = org.emails || [];
    let allEmails = this.setMatchedEmails(matchedEmails, emails);

    const matchedIdDocs = org.matchedIdDocs || [];
    const identificationDocs = org.identificationDocs || [];
    let allIdDocs = this.setMatchedIdDocs(matchedIdDocs, identificationDocs);

    const contactInfo = {
      phones: allPhones,
      emails: allEmails,
      identificationDocs: allIdDocs
    };
    return contactInfo;
  };

  searchLookupResults = id => {
    const url = `${INCUBATOR_APPLICATION_API}/${id}/lookup`;
    HttpUtil.get(
      url,
      {},
      data => {
        const lookupResults = [];
        if (data.individuals && data.individuals.length > 0) {
          for (let i = 0; i < data.individuals.length; i++) {
            const individual = data.individuals[i] || {};
            const contactInfo = this.setIndividualContacts(individual);

            let names = [];
            if (individual.firstName) {
              names.push(individual.firstName);
            }
            if (individual.lastName) {
              names.push(individual.lastName);
            }

            let logoApi = null;
            if (individual.photo) {
              logoApi = `${INDIVIDUALS_API}/${individual._id}/photo/${
                individual.photo
              }`;
            }

            let lookupResult = {};
            lookupResult._id = individual._id;
            lookupResult.name = names.join(" ");
            lookupResult.incubateeType = "Individual";
            lookupResult.logoOrPhotoUrl = logoApi;
            lookupResult.contact = contactInfo;
            lookupResults.push(lookupResult);
          }
        }

        if (data.organizations && data.organizations.length > 0) {
          for (let i = 0; i < data.organizations.length; i++) {
            const org = data.organizations[i] || {};
            const contactInfo = this.setOrganizationContacts(org);

            let logoApi = null;
            if (org.logo) {
              logoApi = `${ORGANIZATIONS_API}/${org._id}/logo/${org.logo}`;
            }

            let lookupResult = {};
            lookupResult._id = org._id;
            lookupResult.name = org.name;
            lookupResult.incubateeType = "Organization";
            lookupResult.logoOrPhotoUrl = logoApi;
            lookupResult.contact = contactInfo;
            lookupResults.push(lookupResult);
          }
        }

        this.setState({ searchResult: data, lookupResults });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      let id = this.props.match.params.id;
      this.searchLookupResults(id);
    }
  };

  render() {
    const { searchResult, redirectToAwardPage } = this.state;

    if (searchResult === null) {
      return null;
    }

    if (redirectToAwardPage) {
      return <Redirect to={redirectToAwardPage} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={this.state.initialValues}
          // validationSchema={props.validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            this.handleSubmit(values, { setSubmitting })
          }
          render={renderProps => (
            <CustomForm
              searchResult={this.state.searchResult}
              lookupResults={this.state.lookupResults}
              {...renderProps}
            />
          )}
        />
      </Fragment>
    );
  }
}

export default GrantOnboardForm;
