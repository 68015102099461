import React, { Component } from "react";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import IncubatorAppForm from "./IncubatorAppForm";
import NidhiEir from "./NidhiEir";
import NidhiPrayas from "./NidhiPrayas";
import { INCUBATOR_APPLICATION_API } from "../../common/Constants";
import ErrorPage from "../../common/error.page";

class ViewIncubatorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incubatorForm: null,

      // alert component
      showAlert: null,
      alertType: null,
      alertColor: null,
      alertMessage: null,
      permanentFailure: false
    };
  }

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const formId = this.props.match.params.id;
      this.getForm(formId);
    }
  };

  getForm = formId => {
    const url = INCUBATOR_APPLICATION_API + "/" + formId;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ incubatorForm: data });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      showAlert: true,
      alertType: "Default",
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getRenderComponent = incubatorForm => {
    let renderComponent = "";
    const kind = incubatorForm.kind;
    const ALL_COMPONENT = {
      IncubateeAppForm: <IncubatorAppForm form={incubatorForm} />,
      NidhiEirAppForm: <NidhiEir form={incubatorForm} />,
      NidhiPrayasAppForm: <NidhiPrayas form={incubatorForm} />
    };
    renderComponent = ALL_COMPONENT[kind];
    return renderComponent;
  };

  render() {
    const {
      showAlert,
      alertType,
      alertColor,
      alertMessage,
      incubatorForm,
      permanentFailure
    } = this.state;

    const FORMS = {
      IncubateeAppForm: "Incubatee Application Form",
      NidhiEirAppForm: "NIDHI EIR Application Form",
      NidhiPrayasAppForm: "NIDHI PRAYAS Application Form"
    };

    let formTitile = "";
    let renderComponent = "";
    if (incubatorForm) {
      const kind = incubatorForm.kind;
      formTitile = FORMS[kind];
      renderComponent = this.getRenderComponent(incubatorForm);
    }
    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    return (
      <div>
        {/* show alert message  */}
        <AlertComponent
          show={showAlert}
          type={alertType}
          alertColor={alertColor}
          message={alertMessage}
          close={this.closeDefaultAlert}
          confirm={this.okConfirmUpdate}
        />
        <div className="row">
          <div className="col-12 text-center">
            <h5>{formTitile}</h5>
          </div>
        </div>
        {renderComponent}
      </div>
    );
  }
}

export default ViewIncubatorForm;
