import React from "react";
import PivotReport from "../PivotReport";
import { checkNaN, getPercent, getPercentString } from "../ReportsUtil";

const dimensions = [
  { value: "owner", title: "Owner" },
  { value: "cluster", title: "Cluster" },
  { value: "campus", title: "Campus" },
  { value: "building", title: "Building" },
  { value: "partitionDisplayName", title: "Partition" },
  { value: "status", title: "Status" }
];

function reduce(row, aggRecord) {
  aggRecord.count = (aggRecord.count || 0) + 1;
  aggRecord.totalLeasedSeats =
    (aggRecord.totalLeasedSeats || 0) + parseFloat(row.leasedSeatCount);
  aggRecord.totalSeatCount =
    (aggRecord.totalSeatCount || 0) + parseFloat(row.seatCount);
  return aggRecord;
}

const calculations = [
  {
    title: "Partition Count",
    value: "count",
    template: val => checkNaN(val),
    sortBy: row => checkNaN(row.count)
  },
  {
    title: "Occupancy",
    value: "count",
    template: (val, row) =>
      getPercentString(row.totalLeasedSeats, row.totalSeatCount),
    sortBy: row => getPercent(row.totalLeasedSeats, row.totalSeatCount)
  },
  {
    title: "Leased Seats",
    value: "totalLeasedSeats",
    sortBy: row => checkNaN(row.totalLeasedSeats)
  },
  {
    title: "Total Seats",
    value: "totalSeatCount",
    sortBy: row => checkNaN(row.totalSeatCount)
  }
];

function SeaterOccupancyReport() {
  return (
    <PivotReport
      title="Occupancy - Seater"
      url="/api/v1/reports/facility/seater-occupancy"
      dimensions={dimensions}
      reduce={reduce}
      calculations={calculations}
    />
  );
}

export default SeaterOccupancyReport;
