import React, { Component, Fragment } from "react";
import {
  convertDateToNumber,
  convertNumberToDate,
  getDisplayDate
} from "../../lease/DateUtil";
import HttpUtil from "../../common/HttpUtil";
import { GRANT_AWARDS_API } from "../../common/Constants";
import Breadcrumb from "../../common/Breadcrumb";
import AlertComponent from "../../common/AlertComponent";
import { GrantOverview } from "./grant-widgets/GrantOverview";
import { Formik, Field, Form } from "formik";
import { validate } from "../award/GrantAwardReviewForm";

import { DocumentTemplate } from "../award/DocumentTemplate";
import { ChecklistTemplate } from "../award/ChecklistTemplate";
import Link from "react-router-dom/Link";
import DatePicker from "react-datepicker";
import { getErrorCssClassName, ErrorComponent } from "../../common/FormikUtil";
import { GRANT_AWARD_TERMINATION_STATUS_REASONS } from "../../common/LookupConstants";
import ShowHideComponent from "../../widgets/ShowHideComponent";
import { Redirect } from "react-router";

function getDocumentTypes(template) {
  const documents = (template && template.documents) || [];
  const docTypes = documents.map(d => d.documentType);
  return docTypes;
}

function getCheckListLabel(template) {
  return (template && template.checklist) || [];
}
function validateFn(values, template) {
  const errors = validate(values, template);

  if (!values.statusReason) {
    errors.statusReason = "Reason is required field";
  }

  if (!values.closureDate) {
    errors.closureDate = "Termination Date is required field";
  }

  if (values.statusReason === "OTHER" && !values.otherStatusReason) {
    errors.otherStatusReason = "Status Reason is required field";
  }

  if (!values.notes) {
    errors.notes = "Notes is required field";
  }

  if (values.trademarkRegistered) {
    if (!values.trademarkName) {
      errors.trademarkName = "Trademark Name is required field";
    }
  }

  return errors;
}

const TerminationInformation = props => {
  const { values, setFieldValue, touched, errors } = props;
  return (
    <div className="pl-3 pr-3">
      <strong className="edit-page-section-header-text d-block">
        Termination Information
      </strong>

      <div className="form-group row mb-2 mt-2">
        <label className="col-form-label edit-page-label-required col-form-label-sm col-12 col-lg-4 col-xl-4 pr-0">
          Status
        </label>
        <div className="col-12 col-lg-8 col-xl-8 edit-page-label">
          Terminated
        </div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
          Completed Successfully
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              name="completed"
              id="completed"
              className="custom-control-input"
              onChange={e => {
                setFieldValue("completed", e.target.checked);
              }}
              value={values.completed}
              checked={values.completed}
            />

            <label
              className="custom-control-label edit-page-label"
              htmlFor="completed"
            >
              Yes
            </label>
          </div>
          <ErrorComponent
            fieldName="completed"
            touched={touched}
            error={errors}
          />
        </div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
          Status Reason
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <Field
            component="select"
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "statusReason"
            )}`}
            name="statusReason"
            value={values.statusReason || ""}
          >
            <option value="">Select...</option>
            {GRANT_AWARD_TERMINATION_STATUS_REASONS.map((clsr, i) => (
              <option key={i} value={clsr.key}>
                {clsr.value}
              </option>
            ))}
          </Field>

          <ErrorComponent
            fieldName="statusReason"
            touched={touched}
            error={errors}
          />
        </div>
      </div>
      <ShowHideComponent show={values.statusReason === "OTHER"}>
        <div className="form-group row mb-2">
          <label className="col-form-label edit-page-label-required col-form-label-sm col-12 col-lg-4 col-xl-4">
            Other Reason
          </label>
          <div className="col-12 col-lg-8 col-xl-8">
            <Field
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touched,
                errors,
                "otherStatusReason"
              )}`}
              name="otherStatusReason"
              value={values.otherStatusReason || ""}
            />

            <ErrorComponent
              fieldName="otherStatusReason"
              touched={touched}
              error={errors}
            />
          </div>
        </div>
      </ShowHideComponent>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
          Termination Date
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <DatePicker
            value={
              values.closureDate !== null
                ? getDisplayDate(values.closureDate)
                : ""
            }
            selected={values.closureDate}
            onChange={e => {
              setFieldValue(`closureDate`, e);
            }}
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "closureDate"
            )}`}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />

          <ErrorComponent
            fieldName="closureDate"
            touched={touched}
            error={errors}
            datePicker={true}
          />
        </div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
          Notes
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <Field
            component="textarea"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "notes"
            )}`}
            name="notes"
            rows="6"
          />

          <ErrorComponent fieldName="notes" touched={touched} error={errors} />
        </div>
      </div>
    </div>
  );
};

const CustomForm = props => {
  const { values, errors, setFieldValue } = props;
  const docTypes = getDocumentTypes(props.template);
  return (
    <Form>
      <div className="card-body pt-0">
        <div className="row mt-5 mb-3">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
            <TerminationInformation {...props} />
          </div>
        </div>

        <div className="row mt-5 mb-3">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <div className="pl-3 pr-3">
              <ChecklistTemplate
                values={values}
                errors={errors}
                uniqueKey={values._id || "New"}
              />
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
            <div className="pl-3 pr-3">
              <DocumentTemplate
                values={values}
                errors={errors}
                documentTypes={docTypes}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card-footer text-center pb-3">
        <Link
          to={`/funding/awards/progress/${values._id}`}
          className="btn btn-sm btn-outline-secondary mr-3"
        >
          Cancel
        </Link>
        <button className="btn btn-sm btn-danger" type="submit">
          Terminate Grant
        </button>
      </div>
    </Form>
  );
};

const INITIAL_VALUES = {
  statusReason: "",
  completed: false,
  otherStatusReason: "",
  closureDate: null,
  notes: "",
  documents: [],
  availableDocuments: [],
  documentName: "",
  documentType: "",
  checklist: [],

  trademarkRegistered: false,
  trademarkName: "",

  companyFormed: false,
  afterGrantAward: false,
  companyName: "",
  dateOfIncorporation: "",
  patents: [
    {
      country: "",
      applicationNumber: "",
      applicationFilingDate: "",
      fieldOfInvention: "",
      classificationCode: "",
      patentStatus: ""
    }
  ]
};

class GrantTermination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      award: {
        ...INITIAL_VALUES
      },
      template: "",
      breadCrumbList: [],
      previousLocation: ""
    };
  }

  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAward = id => {
    const url = `${GRANT_AWARDS_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        const award = { ...this.state.award };

        const breadCrumbList = [];

        const awardList = {
          displayName: "Awards",
          linkTo: `/funding/awards`
        };
        breadCrumbList.push(awardList);

        const grantee = data.grantee;

        const names = [];

        if (grantee.firstName) {
          names.push(grantee.firstName);
        }
        if (grantee.lastName) {
          names.push(grantee.lastName);
        }
        if (grantee.name) {
          names.push(grantee.name);
        }

        const project = {
          displayName: `${names.join(" ")} - ${data.projectTitle.trim()}`,
          linkTo: `/funding/awards/progress/${data._id}`
        };
        breadCrumbList.push(project);
        const previousLocation = `/funding/awards/progress/${data._id}`;

        const closureList = {
          displayName: "Termination",
          linkTo: null
        };
        breadCrumbList.push(closureList);

        award._id = data._id || "";
        award.applicationFormId = data.applicationFormId || "";
        award.incubatee = data.incubatee || "";
        award.projectTitle = data.projectTitle || "";
        award.grantRef = data.grantRef || "";
        award.granteeType = data.granteeType || "";
        award.grantee = data.grantee || "";
        award.vertical = data.vertical || "";
        award.sanctionedAmount = data.sanctionedAmount || "";
        award.disbursedAmounts = data.disbursedAmounts || [];
        award.amountDisburesd = award.disbursedAmounts.reduce(
          (agg, value) => agg + value.amount,
          0
        );
        award.startDate = convertNumberToDate(data.startDate) || null;
        award.endDate = convertNumberToDate(data.endDate) || null;
        award.revisedEndDate = convertNumberToDate(data.revisedEndDate) || null;
        award.status = data.status || "";
        // Used as input field. So no need to load data.
        // Documents from the existing record is available in availableDocuments
        award.documents = [];
        // award.notes = data.notes || []; // wip#

        award.reviews = data.reviews || [];
        const availableDocuments = data.documents || [];

        award.grantRef = data.grantRef || "";

        let terminationTemplate = {};
        let checklist = [];
        if (data.grantRef.grantProgressTemplate) {
          const grantProgressTemplate = data.grantRef.grantProgressTemplate;
          terminationTemplate = grantProgressTemplate.terminationTemplate;
          checklist = getCheckListLabel(terminationTemplate);
          award.checklist = checklist;
        }
        this.setState({
          award,
          availableDocuments,
          template: terminationTemplate,
          breadCrumbList,
          previousLocation
        });
      },
      data => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getPatents = patents => {
    let allPatents = patents || [];
    let newPatents = [];
    for (let i = 0; i < allPatents.length; i++) {
      let pt = allPatents[i];
      let hasData = Object.values(pt).some(itm => itm);

      let patent = {};
      patent.country = pt.country;
      patent.applicationNumber = pt.applicationNumber;
      let date = new Date(pt.applicationFilingDate);
      date = convertDateToNumber(date);
      patent.applicationFilingDate = date;
      patent.fieldOfInvention = pt.fieldOfInvention;
      patent.classificationCode = pt.classificationCode;
      patent.patentStatus = pt.patentStatus;

      if (hasData) {
        newPatents.push(patent);
      }
    }
    return newPatents;
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  onTerminate = (values, { setSubmitting }) => {
    // console.log(values);
    setSubmitting(true);

    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}/terminate`;

    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "closureDocuments", docs[i].document);
    }

    const patents = this.getPatents(values.patents);

    const terminateRequest = {
      grantClosureDate: convertDateToNumber(values.closureDate),
      statusReason: values.statusReason,
      otherStatusReason: values.otherStatusReason || undefined,
      notes: values.notes,
      closureChecklist: values.checklist,
      closureDocuments: docs.map(d => {
        return { name: d.name, type: d.type };
      }),
      trademarkRegistered: values.trademarkRegistered,
      trademarkName: values.trademarkName,

      companyFormed: values.companyFormed,
      afterGrantAward: values.afterGrantAward,
      companyName: values.companyName,
      dateOfIncorporation: convertDateToNumber(values.dateOfIncorporation),
      patents
    };

    formData.append("message", JSON.stringify(terminateRequest));

    HttpUtil.post(
      url,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.getAward(id);
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `"${
            this.state.award.projectTitle
          }" has been terminated successfully.`,
          redirect: true
        });
        window.scrollTo(0, 0);
      },

      data => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(
          "Server error occurred. Please contact support if the issue persists."
        );
      }
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      let awardId = this.props.match.params.id;
      this.getAward(awardId);
    }
    window.scrollTo(0, 0);
  };

  render() {
    const { award, previousLocation } = this.state;
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      alertWithTitle: this.state.alertWithTitle,
      confirm:
        this.state.alertType === "Confirmation" ? this.state.confirm : undefined
    };

    const props = {
      initialValues: this.state.award,
      template: this.state.template,
      onTerminate: this.onTerminate
    };

    if (this.state.redirect) {
      const url = `/funding/awards/progress/${this.props.match.params.id}`;
      return <Redirect to={url} />;
    }
    return (
      <Fragment>
        <Breadcrumb breadCrumbList={this.state.breadCrumbList} />

        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="card edit-page-container mb-5">
          <div className="card-header border-0 text-center">
            <Link
              to={previousLocation}
              className="badge bg-white custom-btn-sm float-left"
            >
              <i className="fas fa-arrow-left mr-3" />
            </Link>
            <span className="card-title ">
              <span
                className="edit-page-title"
                style={{ color: "rgb(100, 122, 203)" }}
              >
                {`Grant Termination - ${
                  award.projectTitle ? award.projectTitle : "Loading ..."
                }` || "--"}
                {/* <small> {`(${award.applicationFormId})`}</small> */}
              </span>
            </span>
          </div>
          <div className="card-body pt-0 pb-0">
            <GrantOverview award={this.state.award} />
          </div>
          <Formik
            enableReinitialize
            initialValues={props.initialValues}
            validate={values => validateFn(values, props.template)}
            onSubmit={(values, { setSubmitting }) => {
              this.setState({
                alertType: "Confirmation",
                showAlert: true,
                alertColor: "danger",
                alertMessage: `Grant Award once terminated can't be reopened again. Do you want to continue ?`,
                alertWithTitle: "Grant Award Termination",
                confirm: () => props.onTerminate(values, { setSubmitting })
              });
              // props.onTerminate(values, { setSubmitting })
            }}
            render={renderProps => <CustomForm {...props} {...renderProps} />}
          />
        </div>
      </Fragment>
    );
  }
}

export default GrantTermination;
