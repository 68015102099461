import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableList from "../../common/TableList";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { FUND_AGENCY_API } from "../../common/Constants";

function linkFormater(cell, row) {
  const url = `/funding/setup/agencies/info/${row._id}`;
  return <Link to={url}>{row.name}</Link>;
}

function websiteFormater(cell, row) {
  let link = "--";
  if (row.website) {
    link = (
      <span
        className="d-inline-block text-truncate"
        data-toggle="tooltip"
        title={row.website}
        style={{ width: "200px" }}
      >
        <a href={row.website} rel="noopener noreferrer" target="_blank">
          {row.website}
        </a>
      </span>
    );
  }
  return link;
}

const columnsAgency = [
  {
    dataField: "name",
    text: "Agency Name",
    sort: true,
    headerStyle: { width: "20%" },
    formatter: linkFormater
  },

  {
    dataField: "type",
    text: "Agency Type",
    sort: true,
    headerStyle: { width: "15%" }
  },
  {
    dataField: "state",
    text: "State (for agencies)",
    sort: true,
    headerStyle: { width: "20%" }
  },
  {
    dataField: "website",
    text: "Website",
    formatter: websiteFormater,
    csvFormatter: website => website || "",
    sort: true,
    headerStyle: { width: "10%" }
  },

  {
    dataField: "fullName",
    text: "Agency Full Name",
    csvFormatter: fullName => fullName || "",
    sort: true,
    headerStyle: { width: "30%" }
  }
];

class AgencyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencyList: null,
      breadCrumbList: []
    };
  }

  getAll = () => {
    const url = FUND_AGENCY_API;
    HttpUtil.get(
      url,
      {},
      data => {
        const breadCrumbList = [
          {
            linkTo: null,
            displayName: "Funding Agencies"
          }
        ];
        this.setState({ agencyList: data, breadCrumbList });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb border-bottom-0">
                <li className="breadcrumb-item active">Funding Agencies</li>
              </ol>
            </nav>
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsAgency}
          data={this.state.agencyList}
          noDataIndication="No Agency found."
          sizePerPage="10"
          headerName="Funding Agencies"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default AgencyList;
