import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { FUND_AGENCY_API, GRANT_PROGRAM_API } from "../../common/Constants";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  return getError(touch, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touch, error, datePicker } = props;
  let renderComponent = "";

  if (datePicker) {
    renderComponent = (
      <div className="auto-lookup-empty">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  } else {
    renderComponent = (
      <div className="invalid-feedback">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  }
  return renderComponent;
};

const GrantForm = props => {
  const { values, touched, errors } = props;
  const disabledField = values.readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};
  const agencyId = values.agency._id || values.agency;
  return (
    <Form>
      <div className="shadow card flex-fill box-border box-light-blue">
        <div className="card-header border-0 text-center  pb-0">
          <span className="float-left">
            {agencyId ? (
              <Link
                to={`/funding/setup/agencies/info/${agencyId}`}
                className="text-light-blue"
              >
                <i className="fas fa-arrow-left" />
              </Link>
            ) : null}
          </span>
          <h6 className="mb-0 d-inline-block text-light-blue">
            <strong>{values.formTitle}</strong>
          </h6>
        </div>

        <div className="card-body pt-2">
          <div className="card border-0 h-100">
            <div className="card-header border-0 pb-0">
              <strong className="border-bottom pb-1 d-block text-muted">
                Grant Information
              </strong>
            </div>
            <div className="card-body pt-2">
              <div className="form-group row mb-2">
                <label className="col-form-label col-form-label-sm col-12 col-lg-3 col-xl-3">
                  Funding Agency
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    component="select"
                    type="text"
                    className={`custom-select custom-select-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "agency"
                    )}`}
                    name="agency"
                    value={values.agency || ""}
                    {...disabledField}
                  >
                    <option value="">Select...</option>
                    {values.agencyList.map((agency, i) => (
                      <option key={i} value={agency._id}>
                        {agency.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorComponent
                    fieldName="agency"
                    touch={touched}
                    error={errors}
                  />
                </div>
              </div>
              <div className="form-group row mb-2">
                <label className="col-form-label col-form-label-sm col-12 col-lg-3 col-xl-3">
                  Grant Name
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "name"
                    )}`}
                    name="name"
                    {...disabledField}
                  />
                  <ErrorComponent
                    fieldName="name"
                    touch={touched}
                    error={errors}
                  />
                </div>
              </div>

              <div className="form-group row mb-2">
                <label className="col-form-label col-form-label-sm col-12 col-lg-3 col-xl-3">
                  Grant Full Name
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "fullName"
                    )}`}
                    name="fullName"
                    {...disabledField}
                  />
                  <ErrorComponent
                    fieldName="fullName"
                    touch={touched}
                    error={errors}
                  />
                </div>
              </div>
              <div className="form-group row mb-2">
                <label className="col-form-label col-form-label-sm col-12 col-lg-3 col-xl-3">
                  Grant Description
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    component="textarea"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "description"
                    )}`}
                    name="description"
                    rows="5"
                    {...disabledField}
                  />
                  <ErrorComponent
                    fieldName="description"
                    touch={touched}
                    error={errors}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2 mt-2">
            <div className="col-md-12 text-center">
              <button className="btn btn-sm btn-primary" type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const GRANT_SCHEMA = Yup.object().shape({
  agency: Yup.string()
    .label("Funding Agency")
    .required(),
  name: Yup.string()
    .label("Grant Name")
    .required(),
  fullName: Yup.string()
    .label("Grant Full Name")
    .required(),
  description: Yup.string()
    .label("Grant Description")
    .required()
});

class GrantProgramDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grantProgram: {
        _id: "",
        agency: "",
        name: "",
        fullName: "",
        formTitle: "",
        agencyList: [],
        description: "",
        readOnly: false
      },

      editGrantProgram: false,
      editGrantProgramId: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getGrantProgram = id => {
    if (this.props.location.state && this.props.location.state.created) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: `Grant Program has been created successfully.`
      });
      window.scrollTo(0, 0);
    }

    const url = `${GRANT_PROGRAM_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        const grantProgram = { ...this.state.grantProgram };
        grantProgram._id = data._id || "";
        grantProgram.agency = data.agency._id || data.agency || "";
        grantProgram.name = data.name || "";
        grantProgram.fullName = data.fullName || "";
        grantProgram.description = data.description || "";
        grantProgram.formTitle = `Grant - ${data.name}`;
        this.setState({ grantProgram });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  createGrantProgram = (values, setSubmitting) => {
    setSubmitting(true);
    const createRequest = {
      agency: values.agency,
      name: values.name,
      fullName: values.fullName,
      description: values.description
    };

    const url = `${GRANT_PROGRAM_API}`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      createRequest,
      data => {
        setSubmitting(false);
        this.setState({
          editGrantProgram: true,
          editGrantProgramId: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  updateGrantProgram = (values, setSubmitting) => {
    setSubmitting(true);
    const updateRequest = {
      agency: values.agency,
      name: values.name,
      fullName: values.fullName,
      description: values.description
    };

    const id = this.props.match.params.id;
    const url = `${GRANT_PROGRAM_API}/${id}`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.put(
      url,
      headers,
      updateRequest,
      data => {
        setSubmitting(false);
        this.getGrantProgram(data._id);
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Grant program "${data.name}" is updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSubmit = (values, { setSubmitting }) => {
    // console.log("submit : values:", JSON.stringify(values, null, 2));
    const grantProgram = this.state.grantProgram;

    if (grantProgram._id) {
      this.updateGrantProgram(values, setSubmitting);
    } else {
      this.createGrantProgram(values, setSubmitting);
    }
  };

  getAllAgencies = () => {
    const url = FUND_AGENCY_API;
    HttpUtil.get(
      url,
      {},
      data => {
        const grantProgram = { ...this.state.grantProgram };
        grantProgram.agencyList = data;
        this.setState({ grantProgram });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    const { match } = this.props;
    if (match && match.params && match.params.id) {
      const id = match.params.id;
      this.getGrantProgram(id);
    } else {
      const grantProgram = { ...this.state.grantProgram };
      grantProgram.formTitle = "New Grant";
      this.setState({ grantProgram });
    }
    this.getAllAgencies();
  }

  render() {
    const { editGrantProgram, editGrantProgramId } = this.state;

    if (editGrantProgram) {
      const stateObj = {
        pathname: `/funding/grants/edit/${editGrantProgramId}`,
        state: { created: true }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const props = {
      initialValues: this.state.grantProgram,
      validationSchema: GRANT_SCHEMA,
      handleSubmit: this.handleSubmit
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={props.initialValues}
          validationSchema={props.validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            props.handleSubmit(values, { setSubmitting })
          }
          render={({
            values,
            isSubmitting,
            errors,
            touched,
            setFieldValue
          }) => {
            const formProps = {
              values,
              isSubmitting,
              errors,
              touched,
              setFieldValue
            };
            return <GrantForm {...formProps} />;
          }}
        />
      </Fragment>
    );
  }
}

export default GrantProgramDetail;
