import React, { Component } from "react";
import { convertNumberToDate, getDisplayDate } from "../../lease/DateUtil";
import ShowHideComponent from "../../widgets/ShowHideComponent";
import { downloadDocument } from "../../common/Util";
import { getFileIconByContentType } from "../../common/IconUtil";
import {
  changeToINR,
  GRANT_AWARD_REVIEW_STATUS_OBJECT
} from "../../common/LookupConstants";
import GrantAwardReviewForm from "./GrantAwardReviewForm";

const GrantAwardReviewView = props => {
  const { review } = props;
  const reviewers = review.reviewers || [];
  const documents = review.documents || [];
  const statusText =
    GRANT_AWARD_REVIEW_STATUS_OBJECT[review.status] || review.status;

  const checklist = review.checklist || [];
  return (
    <div className="shadow card flex-fill box-border box-light-blue mb-4">
      <div className="card-header border-0 text-center pb-0">
        <h6 className="mb-0 text-light-blue d-inline">
          <strong>
            {review.title}
            <span className="badge badge-soft-secondary badge-pill ml-2">
              {statusText}
            </span>
          </strong>
        </h6>
        <ShowHideComponent show={!props.closedAward}>
          <button
            className="btn btn-info badge custom-btn-sm text-white float-right"
            type="button"
            onClick={props.editForm}
          >
            <i className="far fa-edit text-white" /> Edit
          </button>
        </ShowHideComponent>
      </div>

      <div className="card-body">
        <div className="row mt-2 mb-3">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="border-bottom pb-2 mb-2">
              <strong>Review Date</strong>
            </div>
            <p className="mt-3">
              {getDisplayDate(convertNumberToDate(review.reviewDate))}
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6  col-lg-6 col-xl-6">
            <div className="border-bottom pb-2 mb-2">
              <strong>Approved Amount</strong>
            </div>
            <p className="mt-3">{changeToINR(review.amountSanctioned || 0)}</p>
          </div>
        </div>

        <div className="row mt-2 mb-3">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="border-bottom pb-2 mb-2">
              <strong>Reviewer Comments</strong>
            </div>
            <pre className="mt-1">{review.reviewerComments || "--"}</pre>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12  col-lg-6 col-xl-6">
            <div className="border-bottom pb-2 mb-2">
              <strong>Reviewers</strong>
            </div>
            <ul className="list-group">
              {reviewers.map((reviewer, rIndex) => {
                return (
                  <li
                    key={rIndex}
                    className="list-group-item d-flex align-items-center border-0 pl-0 pb-0"
                  >
                    <i className="far fa-user mr-2" />
                    {`${reviewer.name} (${reviewer.organization})`}
                  </li>
                );
              })}
              <ShowHideComponent show={reviewers.length === 0}>
                <li className="list-group-item d-flex align-items-center border-0 pl-0 pb-0">
                  --
                </li>
              </ShowHideComponent>
            </ul>
          </div>
        </div>

        <div className="row mt-2 mb-3">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="border-bottom pb-2 mb-2">
              <strong>Incubator Comments</strong>
            </div>
            <pre className="mt-1">{review.incubatorComments || "--"}</pre>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12  col-lg-6 col-xl-6">
            <div className="border-bottom pb-2 mb-2">
              <strong>Documents</strong>
            </div>
            <ul className="list-group list-group-flush">
              {documents.map((doc, index) => {
                return (
                  <li
                    key={index}
                    className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                  >
                    <span>
                      <i
                        className={`${getFileIconByContentType(doc.type)} mr-2`}
                      />
                      {doc.name}
                    </span>
                    <div>
                      <i
                        className="fas fa-download fa-md mr-3"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          downloadDocument(
                            doc.link,
                            msg => window.alert(msg),
                            doc.filename
                          )
                        }
                      />
                    </div>
                  </li>
                );
              })}
              {documents.length === 0 ? (
                <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                  No documents available
                </li>
              ) : null}
            </ul>
          </div>

          <ShowHideComponent show={checklist.length > 0}>
            <div className="col-xs-12 col-sm-12 col-md-12  col-lg-6 col-xl-6">
              <div className="border-bottom pb-2 mb-2">
                <strong>Checklist</strong>
              </div>
              {checklist.map((chklst, index) => {
                return (
                  <div key={index} className="form-group row mb-2 mt-3">
                    <div className="col-12">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`chklst${index}`}
                          checked={chklst.selected}
                          readOnly
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`chklst${index}`}
                        >
                          {chklst.label}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ShowHideComponent>
        </div>
      </div>
    </div>
  );
};

class GrantAwardReview extends Component {
  constructor(args) {
    super(args);
    this.state = {
      edit: false
    };
  }

  editForm = () => this.setState({ edit: true });
  cancelEdit = () => this.setState({ edit: false });

  saveReview = (values, { setSubmitting }) => {
    setSubmitting(true);
    this.props.updateReview(this.props.review._id, values, success => {
      setSubmitting(false);
      this.setState({ edit: false });
    });
  };

  render() {
    return this.state.edit ? (
      <GrantAwardReviewForm
        review={this.props.review}
        template={this.props.template}
        cancelReview={this.cancelEdit}
        saveReview={this.saveReview}
      />
    ) : (
      <GrantAwardReviewView
        closedAward={this.props.awardClosed}
        review={this.props.review}
        editForm={this.editForm}
      />
    );
  }
}

export default GrantAwardReview;
