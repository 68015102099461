import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { ASSETS_API } from "../../common/Constants";
import {
  RENTAL_TYPES_OBJECT,
  STATUS_LIST_OBJECT
} from "../../common/LookupConstants";
import TableList from "../../common/TableList";
import Can from "../../../auth/can";

function capitalize(s) {
  if (typeof s !== "string") {
    return "";
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function assetTypeFormater(cell, row) {
  if (!row) return;
  let assetType = "";
  if (row.assetType) {
    assetType = row.assetType.name;
  }
  return capitalize(assetType) || "--";
}

function linkFormater(assetId, row) {
  console.log("assetId:", assetId);
  const url = `/service-mgmt/assets/edit/${row._id}`;
  return <Link to={url}>{assetId || "Untitled"}</Link>;
}

function getModel(cell, row) {
  const manufacture = row.manufacture || {};
  return manufacture.modelNumber || "--";
}

function getProductImage(cell, row) {
  const productImages = row.productImages || [];
  const image =
    productImages[0] && productImages[0]._id ? productImages[0] : null;
  let img = "";

  if (image) {
    let assetId = row._id;
    let previewUrl = `${ASSETS_API}/${assetId}/product-images/${image._id}`;
    let filename = image.filename;
    img = (
      <img
        src={previewUrl}
        className="img-fluid"
        style={{ width: 30, height: 30 }}
        alt={filename}
        datatoggle="tooltip"
        title={filename}
      />
    );
  }

  return img;
}

const ASSET_LIST_COLUMNS = [
  {
    dataField: "assetId",
    text: "Asset ID",
    sort: true,
    formatter: linkFormater,
    filterValue: cell => cell,
    csvFormatter: cell => cell || "Untitled"
  },

  {
    dataField: "assetType",
    text: "Asset Type",
    formatter: assetTypeFormater,
    filterValue: assetTypeFormater,
    csvFormatter: assetTypeFormater,
    sort: true
  },

  {
    dataField: "assetName",
    text: "Asset Name",
    sort: true
  },

  {
    dataField: "assetUsage",
    text: "Asset Usage Type",
    sort: true,
    formatter: cell => RENTAL_TYPES_OBJECT[cell],
    filterValue: cell => RENTAL_TYPES_OBJECT[cell]
  },
  // {
  //   dataField: "location",
  //   text: "Location"
  // },
  {
    dataField: "status",
    text: "Status",
    formatter: cell => STATUS_LIST_OBJECT[cell]
  }
];

class AssetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAssets = () => {
    let url = ASSETS_API;

    HttpUtil.get(
      url,
      {},
      data => this.setState({ assets: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getAssets();
  }

  render() {
    const { assets } = this.state;

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={ASSET_LIST_COLUMNS}
          data={assets}
          noDataIndication="No Asset found."
          sizePerPage="25"
          headerName="Assets"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default AssetList;

const RenderCustomButton = props => {
  return (
    <Can do="CREATE" on="Asset">
      <Link
        to="/service-mgmt/assets/new"
        role="button"
        className="btn btn-sm text-white bg-pink"
      >
        New
      </Link>
    </Can>
  );
};
