import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDisplayDate,
  addMonths,
  convertDateToNumber
} from "../../../lease/DateUtil";
import AlertComponent from "../../../common/AlertComponent";
import { ASSET_RESERVATIONS_API } from "../../../common/Constants";
import ReservationTrackerResults from "./ReservationTrackerResults";
import HttpUtil from "../../../common/HttpUtil";

function getDateWitMonthOffset(offset, date) {
  const newDate = date || new Date();
  return addMonths(newDate, offset);
}

class ReservationTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationDate: new Date(),
      loading: false,
      results: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      loading: false,
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getReservations() {
    const reservationDate = convertDateToNumber(this.state.reservationDate);
    this.setState({ loading: true });
    HttpUtil.get(
      `${ASSET_RESERVATIONS_API}?reservationDate=${reservationDate}`,
      {},
      data => {
        this.setState({ results: data, loading: false });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  }

  componentDidMount() {
    this.getReservations();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row mt-4 mb-5">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="card-header border-bottom-0 pt-3 text-center">
                <h5 className="font-weight-bold" style={{ color: "#647acb" }}>
                  Asset Reservation Daily Summary
                </h5>
              </div>
              <div className="card-body">
                <div className="row no-gutters">
                  <div className="col-md-12">
                    <div className="form-group row d-flex justify-content-center">
                      <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label-required">
                        Check Reservations on
                      </label>
                      <div className="col-12 col-lg-3 col-xl-3">
                        <DatePicker
                          value={
                            this.state.reservationDate
                              ? getDisplayDate(this.state.reservationDate)
                              : ""
                          }
                          selected={
                            this.state.reservationDate
                              ? this.state.reservationDate
                              : null
                          }
                          onChange={date => {
                            this.setState(
                              { reservationDate: date },
                              this.getReservations
                            );
                          }}
                          utcOffset={0}
                          minDate={getDateWitMonthOffset(-12)}
                          placeholderText="dd-mm-yyyy"
                          className="form-control form-control-sm"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <ReservationTrackerResults
                  results={this.state.results}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ReservationTracker;
