import React, { Component } from "react";
import { Link } from "react-router-dom";
import BootsrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { PARTITION_STATUS_LIST_OBJECT } from "../../common/LookupConstants";

function areaSeatsFormatter(cell, row) {
  if (!row) {
    return;
  }

  const partitionType = row.partitionType;
  let availablePartition = "";

  let messurement = "";
  let value = "--";
  if (partitionType === "SEATER") {
    messurement = row.seatCount > 1 ? "seats" : "seat";
    value = row.seatCount;
  }

  availablePartition = (
    <div className="d-flex align-items-center mr-3">
      <span className="w-50 text-right">{value}</span>
      <small className="ml-2">{messurement}</small>
    </div>
  );
  return availablePartition;
}

function areaFormatter(cell, row) {
  if (!row) {
    return;
  }

  let strValue = `${row.area}`;
  strValue = strValue.split(".");
  let areaValue = strValue[0];
  let afterDotValue = strValue[1] ? `.${strValue[1]}` : "";
  let messurement = "sqft";
  return (
    <div className="d-flex align-items-center">
      <div className="w-50 text-right">
        <span className="w-50">{areaValue}</span>
        <span className="w-50">{`${afterDotValue}`}</span>
      </div>
      <small className="w-50 text-left ml-2">{messurement}</small>
    </div>
  );
}

function partitionTypeText(cell, row) {
  if (!row) {
    return;
  }

  let type = "--";
  const partitionType = row.partitionType;
  if (partitionType === "SEATER") {
    type = "Seater";
  } else if (partitionType === "RENTAL") {
    type = "Fixed Rental";
  }
  return type;
}

function partitionTypeValue(cell, row) {
  if (!row) {
    return;
  }

  const partitionType = row.partitionType;
  let availablePartition = "";

  let messurement = "";
  let value = "";
  if (partitionType === "SEATER") {
    messurement = row.seatCount > 1 ? "seats" : "seat";
    value = row.seatCount;
  } else if (partitionType === "RENTAL") {
    messurement = "sqft";
    value = row.area;
  }

  availablePartition = `${value} ${messurement}`;

  return availablePartition;
}

class FloorBasedPartiitonList extends Component {
  actionsFormater = (cell, row) => {
    const url = `/admin/partitions/edit/${row._id}`;
    let viewButton = (
      <Link role="button" className="" to={url}>
        {row.partitionName}
      </Link>
    );
    return viewButton;
  };

  getName = (cell, row) => {
    if (!row) {
      return null;
    }
    return row.partitionName;
  };

  render() {
    const columnsPartitions = [
      {
        dataField: "partitionName",
        text: "Partition",
        sort: true,
        classes: "align-middle text-left",
        formatter: this.actionsFormater,
        csvFormatter: this.getName,
        filterValue: this.getName,
        headerClasses: "display-table-header",
        headerStyle: (colum, colIndex) => {
          return { width: "16.66%", textAlign: "center" };
        }
      },
      {
        dataField: "displayName",
        text: "Display Name",
        classes: "align-middle text-left",
        sort: true,
        headerClasses: "display-table-header",
        headerStyle: (colum, colIndex) => {
          return { width: "20%" };
        }
      },
      {
        dataField: "category.categoryName",
        text: "Category",
        sort: true,
        headerClasses: "display-table-header",
        headerStyle: (colum, colIndex) => {
          return { width: "16.66%" };
        }
      },

      {
        dataField: "partitionType",
        text: "Type",
        sort: true,
        classes: "align-middle text-center",
        formatter: partitionTypeText,
        csvFormatter: partitionTypeText,
        filterValue: partitionTypeText,
        headerClasses: "display-table-header",
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "center" };
        }
      },

      {
        dataField: "area",
        text: "Area",
        classes: "align-middle text-center",
        formatter: areaFormatter,
        sort: true,
        headerClasses: "display-table-header",
        headerStyle: (colum, colIndex) => {
          return { width: "13%", textAlign: "center" };
        }
      },

      {
        dataField: "partition",
        text: "Seats",
        sort: true,
        classes: "align-middle text-center",
        formatter: areaSeatsFormatter,
        csvFormatter: partitionTypeValue,
        filterValue: partitionTypeValue,
        headerClasses: "display-table-header",
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "center" };
        }
      },

      {
        dataField: "status",
        text: "Status",
        sort: true,
        classes: "align-middle text-center",
        formatter: cell => PARTITION_STATUS_LIST_OBJECT[cell],
        csvFormatter: cell => PARTITION_STATUS_LIST_OBJECT[cell],
        filterValue: cell => PARTITION_STATUS_LIST_OBJECT[cell],
        headerClasses: "display-table-header",
        headerStyle: () => {
          return { width: "5%", textAlign: "center" };
        }
      }
    ];

    return (
      <div className="row mb-5 mt-5">
        <div className="col-12">
          <div className="card edit-page-container">
            <div className="card-header border-0 text-center">
              <span className="edit-page-title">Partition Information</span>
            </div>
            <div className="card-body">
              <BootsrapTable
                bootstrap4
                keyField={"_id"}
                data={this.props.partitions}
                columns={columnsPartitions}
                condensed
                bordered={false}
                // headerClasses="display-table-header"
                //for custom css
                noDataIndication="No Data found"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FloorBasedPartiitonList;
