import React, { Component, Fragment } from "react";
import AdjustmentPreviewForm from "./AdjustmentPreviewForm";
import { PAYEE_TYPES_OBJECT } from "../../common/LookupConstants";
import { convertDateToNumber } from "../../lease/DateUtil";

class AdjustmentConfirmForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewForm: null
    };
  }
  addData = (adjustment, labelName, value) => {
    if (value) {
      adjustment.push({
        label: labelName,
        value
      });
    }
  };

  componentDidMount() {
    const previewForm = {};
    previewForm.adjustmentTo = this.props.displayName;
    previewForm.adjustmentToType = PAYEE_TYPES_OBJECT[this.props.payeeType];
    previewForm.adjustmentAmount = this.props.adjustmentAmount;
    previewForm.date = convertDateToNumber(this.props.date);
    previewForm.adjustmentReason = this.props.adjustmentReason;
    previewForm.remarks = this.props.remarks;
    previewForm.status = this.props.status;
    this.setState({ previewForm });
  }
  render() {
    const { handleEditForm, handleSubmit } = this.props;
    const { previewForm } = this.state;
    if (previewForm === null) {
      return null;
    }

    return (
      <Fragment>
        <AdjustmentPreviewForm
          data={this.state.previewForm}
          title="Adjustment  Confirmation"
        />
        <div className="form-group row mt-3 mb-0">
          <div className="col-12 text-center">
            <button className="btn btn-primary btn-sm" onClick={handleEditForm}>
              Edit
            </button>
            &nbsp;
            <button className="btn btn-primary btn-sm" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AdjustmentConfirmForm;
