import {
  INCUBATION_SECTORS,
  SUB_SECTORS,
  INCUBATEE_STAGES
} from "../../../common/IncubateeProfileConstants";
import { generateEnumValues } from "../../../common/LookupConstants";

const CoIncubation = {
  title: "Co Incubation",
  type: "object",
  properties: {
    isCoIncubatee: {
      type: "boolean",
      title: "Is-Co Incubatee",
      default: false
    }
  },
  dependencies: {
    isCoIncubatee: {
      oneOf: [
        {
          properties: {
            isCoIncubatee: {
              enum: [false]
            }
          }
        },
        {
          properties: {
            isCoIncubatee: {
              enum: [true]
            },
            anchorIncubator: {
              type: "string",
              title: "Anchor Incubator"
            },
            supplementaryIncubators: {
              type: "array",
              minItems: 1,
              title: "Supplementary Incubators",
              items: {
                type: "string"
              }
            }
          },
          required: ["anchorIncubator", "supplementaryIncubators"]
        }
      ]
    }
  }
};

const IncubateeProfileSchema = {
  title: "Incubatee Profile",
  type: "object",
  required: [
    "about",
    "businessModel",
    "businessPlan",
    "incubationSector",
    "subTechSector",
    "incubateeStage",
    "targetSector"
  ],
  properties: {
    about: {
      type: "string",
      title: "About"
    },
    businessModel: {
      type: "string",
      title: "Business Model"
    },
    businessPlan: {
      type: "string",
      title: "Business Plan"
    },
    incubationSector: {
      type: "string",
      title: "Incubation Sector",
      anyOf: generateEnumValues(INCUBATION_SECTORS)
    },
    subTechSector: {
      type: "string",
      title: "Sub Sector",
      anyOf: generateEnumValues(SUB_SECTORS)
    },
    incubateeStage: {
      type: "string",
      title: "Incubatee Stage",
      anyOf: generateEnumValues(INCUBATEE_STAGES)
    },
    targetSector: {
      type: "string",
      title: "Target Sector"
    },
    coIncubation: CoIncubation,
    metric: {
      title: "Metrics",
      type: "object",
      properties: {
        employeeCount: {
          type: "number",
          title: "Current Employee Count"
        },
        femaleEmployeeCount: {
          type: "number",
          title: "Female Employee Count"
        }
      }
    }
  }
};

const IncubateeProfileUiSchema = {
  about: {
    "ui:widget": "textarea",
    "ui:options": {
      rows: 5
    }
  },
  businessModel: {
    "ui:widget": "textarea",
    "ui:options": {
      rows: 5
    }
  },
  businessPlan: {
    "ui:widget": "textarea",
    "ui:options": {
      rows: 5
    }
  }
};

export { IncubateeProfileSchema, IncubateeProfileUiSchema };
