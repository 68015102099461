import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import AlertComponent from "../common/AlertComponent";

class ViewIndividual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editIndividualProfile: false,
      individualId: null
    };
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.setState({
        individualId: id
      });
    }
  }

  editIndividualProfile = () => {
    this.setState({
      editIndividualProfile: true
    });
  };

  render() {
    const {
      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,

      editIndividualProfile,
      individualId
    } = this.state;

    if (!individualId) {
      return null;
    }

    if (editIndividualProfile) {
      const url = "/admin/individuals/edit/" + individualId;
      return <Redirect to={url} />;
    }
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6 ">
            <div className="d-inline-flex ">
              <h5 className="mb-0 ml-1">Individual Profile</h5>
              <button
                className="btn btn-sm btn-primary ml-3"
                onClick={this.editIndividualProfile}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="col-6  text-right mb-2" />
        </div>
        <div className="row">
          <div className="col-12 col-lg-4 col-xl-3">
            <div className="card">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Details</h6>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-2 text-center">
                    <i
                      className="fab fa-superpowers text-primary align-middle"
                      style={{ fontSize: "5em" }}
                    />
                    <h5 className="card-title mb-0">Zoho</h5>
                    <div className="text-muted mb-2">
                      Company &nbsp;
                      <small className="text-muted">(Graduated)</small>
                    </div>
                  </div>

                  <div className="col-12">
                    <ul className="list-unstyled mb-0">
                      <li className="mb-1">
                        <i className="far fa-envelope text-secondary align-middle" />
                        &nbsp;&nbsp;
                        <label className="text-primary"> zoho@gmail.com</label>
                      </li>
                      <li className="mb-1">
                        <i className="fas fa-phone fa-flip-horizontal text-secondary align-middle" />
                        &nbsp;&nbsp;
                        <label className="text-primary">
                          India +91 (44) 71817070
                        </label>
                      </li>
                      <li className="mb-1">
                        <i className="fas fa-globe text-secondary align-middle" />
                        &nbsp;&nbsp;
                        <label className="text-primary">www.website.com</label>
                      </li>
                      <li className="mb-1">
                        <div className="media">
                          <i className="fas fa-map-marker-alt mt-1 text-secondary align-middle" />
                          &nbsp;&nbsp;
                          <div className="media-body">
                            Estancia IT Park, Plot No. 140 & 151, GST Road,
                            Vallancherry Village, Chengalpattu Taluk,
                            Kanchipuram District 603 202, INDIA Phone : 044 -
                            67447070 044 - 71817070
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <table className="table table-sm my-2">
                    <tbody>
                      <tr>
                        <th>Company</th>
                        <td>PSG STEP</td>
                      </tr>
                      <tr>
                        <th>Domain</th>
                        <td>Facility Service</td>
                      </tr>

                      <tr>
                        <th>Status</th>
                        <td>
                          <span className="badge badge-success">Active</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-9">
            <div className="row">
              <div className="col-12">
                <div className="card flex-fill">
                  <div className="card-header bg-card-header text-white ">
                    <h6 className="card-titile mb-0">Documents</h6>
                  </div>

                  <div className="row pl-1">
                    <div className="col-sm-12">
                      <div className="table-responsive">
                        <table className="table table-sm table-striped">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">File Name</th>
                              <th scope="col">Last Modified At</th>
                              <th scope="col">Last Modified By</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>
                                <i className="far fa-file-image text-info" />
                                &nbsp;&nbsp;Zoho Profile.jpg
                              </td>
                              <td>20/05/2019</td>
                              <td>Admin</td>
                              <td>
                                <i
                                  className="fas fa-download"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Download"
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <i
                                  className="far fa-trash-alt text-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Remove"
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>
                                <i className="far fa-file-pdf text-danger" />
                                &nbsp;&nbsp; Registration Certificate_v1.pdf
                              </td>
                              <td>12/02/2019</td>
                              <td>Admin</td>
                              <td>
                                <i
                                  className="fas fa-download"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Download"
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <i
                                  className="far fa-trash-alt text-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Remove"
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>
                                <i className="far fa-file-pdf text-danger" />
                                &nbsp;&nbsp;Zoho Article.pdf
                              </td>
                              <td>01/01/2019</td>
                              <td>Admin</td>
                              <td>
                                <i
                                  className="fas fa-download"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Download"
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <i
                                  className="far fa-trash-alt text-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Remove"
                                />
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">4</th>
                              <td>
                                <i className="far fa-file-pdf text-danger" />
                                &nbsp;&nbsp;Business Summary.pdf
                              </td>
                              <td>04/03/2019</td>
                              <td>Admin</td>
                              <td>
                                <i
                                  className="fas fa-download"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Download"
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <i
                                  className="far fa-trash-alt text-danger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Remove"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ViewIndividual;
