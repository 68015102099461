import React, { Component, Fragment } from "react";
import { getDescription } from "../../common/Util";
import Download from "downloadjs";
import CompanyType from "./CompanyType";
import IndividualType from "./IndividualType";
import {
  LEASE_ACTIONS_API,
  INCUBATEES_ONBOARD_API,
  STATES_API,
  COUNTRIES_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { DEFAULT_COUNTRY, DEFAULT_STATE } from "../../common/data/DefaultData";
import { titleCaseForOneCaseString } from "../../common/Util";

class OnboardIncubatee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      incubateeType: "",

      reference: "",
      // for individual start
      individual: "",

      // for organization start
      organization: {},
      name: "",
      legalStatus: "",
      estYear: "",
      website: "",
      subsidiary: false,
      parentCompany: "",

      bizSummary: "",
      services: "",
      domainOperation: "",
      companyProfile: null, // file upload
      regCertificate: null, // file upload
      articlesOfAssociation: null, // file upload
      authorizedCapital: "",
      annualTurnover: "",
      annualTurnoverFY: "",

      // for common field organization and individual start
      identificationDocs: [],
      addresses: [],
      email: "",
      phone: "",
      address: {
        country: DEFAULT_COUNTRY,
        state: DEFAULT_STATE
      },
      aadhaar: "",
      pan: "",
      gstn: "",

      incubateeProfile: "",

      incubatorAppFormId: "",

      patents: [],
      grantProgrammes: [],
      actions: [],
      status: "INIT",
      stage: "",
      // for common field organization and individual end

      //Default alert
      showAlert: "",
      alertMode: "",
      alertMessage: "",

      // input field error
      inputError: {},
      promotersFieldsError: [],
      tabsError: null,

      loading: true,

      states: [],
      countries: [],

      rejectConfirmation: false,

      incubateeTypeChanged: false
    };
  }

  componentDidMount = () => {
    this.getOnboardForm(this.props.onboardFormId);
    this.getStates();
    this.getCountries();
  };

  getFirstElement = (objArr, defaultValue) => {
    return objArr && objArr.length > 0 ? objArr[0] : defaultValue;
  };

  loadAddressDefaults = addresses => {
    if (addresses) {
      addresses.forEach(address => {
        if (!address.state) {
          address.state = DEFAULT_STATE;
        }
        if (!address.country) {
          address.country = DEFAULT_COUNTRY;
        }
      });
    }
  };

  getOnboardForm = id => {
    let url = INCUBATEES_ONBOARD_API + "/" + id;
    const headers = {};

    HttpUtil.get(
      url,
      headers,
      data => {
        let emailObj;
        let phoneObj;
        let address;
        let idDocs = [];
        let addresses = [];
        let aadhaar = "",
          pan = "",
          gstn = "";

        let organization = {};
        let reference = "";
        if (data.organization) {
          organization = data.organization;
          reference = data.organization.reference;
          emailObj = this.getFirstElement(organization.emails, "");
          phoneObj = this.getFirstElement(organization.phones, "");
          addresses = organization.addresses;
          idDocs = organization.identificationDocs;

          if (organization.promoters) {
            organization.promoters.forEach(promoter => {
              if (promoter.individual) {
                const prmAddress = promoter.individual.addresses;
                if (!prmAddress || prmAddress.length < 1) {
                  promoter.individual.addresses = [
                    { state: DEFAULT_STATE, country: DEFAULT_COUNTRY }
                  ];
                }

                this.loadAddressDefaults(promoter.individual.addresses);
              }
            });
          }
        }

        let individual = null;
        const incubateeType = data.incubateeType.toUpperCase();
        if (incubateeType === "INDIVIDUAL") {
          individual = this.setIndividualType(data);
          emailObj = this.getFirstElement(data.individual.emails, "");
          phoneObj = this.getFirstElement(data.individual.phones, "");
          addresses = data.individual.addresses;
          idDocs = data.individual.identificationDocs;
        }

        this.loadAddressDefaults(addresses);

        address = this.getFirstElement(addresses, {
          country: DEFAULT_COUNTRY,
          state: DEFAULT_STATE
        });
        idDocs.forEach(idDoc => {
          pan = idDoc.type === "PAN" ? idDoc.number : pan;
          gstn = idDoc.type === "GSTN" ? idDoc.number : gstn;
          aadhaar = idDoc.type === "AADHAAR" ? idDoc.number : aadhaar;
        });

        const incubateeProfile = this.setIncubateeProfile(data);

        this.setState({
          loading: false,
          _id: data._id,
          incubateeType: data.incubateeType.toUpperCase(),
          individual: individual,

          email: emailObj.email ? emailObj.email : "",
          phone: phoneObj.phone ? phoneObj.phone : "",
          address: address ? address : {},
          pan: pan,
          gstn: gstn,
          aadhaar: aadhaar,
          reference: reference,
          organization: data.organization ? data.organization : {},
          name: organization.name ? organization.name : "",
          legalStatus: organization.legalStatus ? organization.legalStatus : "",
          estYear: organization.estYear ? organization.estYear : "",
          website: organization.website ? organization.website : "",
          subsidiary: organization.subsidiary,
          parentCompany: organization.parentCompany
            ? organization.parentCompany
            : "",

          bizSummary: organization.bizSummary ? organization.bizSummary : "",
          services: organization.services ? organization.services : "",
          domainOperation: organization.domainOperation
            ? organization.domainOperation
            : "",
          promoters: organization.promoters ? organization.promoters : [],
          patents: data.patents ? data.patents : [],

          // companyProfile: {}, // file upload
          // regCertificate: {}, // file upload
          // articlesOfAssociation: {}, // file upload
          authorizedCapital: organization.authorizedCapital
            ? organization.authorizedCapital
            : "",
          annualTurnover: organization.annualTurnover
            ? organization.annualTurnover
            : "",
          annualTurnoverFY: organization.annualTurnoverFY
            ? organization.annualTurnoverFY
            : "",

          incubatorAppFormId: data.incubatorAppFormId,
          actions: data.actions,
          status: data.status,
          stage: data.stage,

          incubateeProfile: incubateeProfile,
          identificationDocs: idDocs || [],
          addresses: addresses || [],
          promotersFieldsError: []
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleChangeIncubateeType = e => {
    this.setState({
      [e.target.name]: e.target.value,
      inputError: {}
    });
  };

  handleNameInputchange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    const prevValueFieldName = `${targetName}PrevValue`;
    const prevValue = this.state[prevValueFieldName];

    let newValue =
      prevValue === targetValue
        ? targetValue
        : titleCaseForOneCaseString(targetValue);

    this.setState({
      [targetName]: newValue,
      [prevValueFieldName]: targetValue
    });
  };

  handleInputChange = e => {
    if (e.target.type === "number" && isNaN(e.target.value)) {
      return;
    }
    const targetName = e.target.name;
    let targetValue = e.target.value;

    if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
    } else if (e.target.type === "file") {
      targetValue = e.target.files[0];
    }
    this.setState({ [targetName]: targetValue });
  };

  getRenderComponent = props => {
    const { incubateeType } = this.state;
    let authToken = this.props.authToken;
    let renderComponent = "";
    if (incubateeType === "COMPANY") {
      renderComponent = <CompanyType {...props} authToken={authToken} />;
    }
    if (incubateeType === "INDIVIDUAL") {
      renderComponent = <IndividualType {...props} authToken={authToken} />;
    }
    return renderComponent;
  };

  getOnboardActions = () => {
    let url = LEASE_ACTIONS_API; // we need to change actions api to onboard actions.
    const authToken = this.props.authToken;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken
    };

    return HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({
          actions: data
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertType: "Default",
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      //Default alert
      showAlert: false,
      alertType: "",
      alertColor: "",
      alertMessage: ""
    });
  };

  isEmpty = data => {
    return data === undefined || data === "" || data === null;
  };
  organizationTabValidation = () => {
    const tabErr =
      this.isEmpty(this.state.name) ||
      this.isEmpty(this.state.domainOperation) ||
      this.isEmpty(this.state.estYear) ||
      this.isEmpty(this.state.legalStatus);
    return tabErr;
  };

  promoterTabValidation = () => {
    let tabErr = false;
    const promoters = this.state.promoters;
    tabErr = tabErr || promoters.length === 0;
    promoters.forEach(pmtr => {
      const individual = pmtr.individual;
      tabErr = this.isEmpty(individual.firstName);
      individual.addresses.forEach(addr => {
        tabErr = tabErr || this.isEmpty(addr.streetLine1);
        tabErr = tabErr || this.isEmpty(addr.city);
        tabErr = tabErr || this.isEmpty(addr.state);
        tabErr = tabErr || this.isEmpty(addr.postalCode);
        tabErr = tabErr || this.isEmpty(addr.country);
      });
      const phones = individual.phones;
      tabErr = tabErr || phones.length === 0;

      const emails = individual.emails;
      tabErr = tabErr || emails.length === 0;
    });

    return tabErr;
  };

  individualTabValidation = () => {
    const individual = this.state.individual;
    const tabErr =
      individual === null ||
      this.isEmpty(individual.firstName) ||
      this.isEmpty(individual.gender) ||
      this.isEmpty(individual.education[0].degree) ||
      this.isEmpty(individual.education[0].qualification) ||
      this.isEmpty(individual.experience);

    return tabErr;
  };

  contactTabValidation = () => {
    let address = this.state.address;
    const tabErr =
      this.isEmpty(this.state.email) ||
      this.isEmpty(this.state.phone) ||
      this.isEmpty(address) ||
      this.isEmpty(address.streetLine1) ||
      this.isEmpty(address.city) ||
      this.isEmpty(address.state) ||
      this.isEmpty(address.postalCode);
    return tabErr;
  };

  validatePromoterAddress = (addresses, addrValidtionResult) => {
    let hasError = false;
    if (addresses.length === 0) {
      let address = {};
      address.streetLine1 = true;
      address.city = true;
      address.state = true;
      address.postalCode = true;
      address.country = true;
      addrValidtionResult.push(address);
      hasError = true;
    } else {
      addresses.forEach(addr => {
        const validateAddress = {
          streetLine1: this.isEmpty(addr.streetLine1),
          city: this.isEmpty(addr.city),
          state: this.isEmpty(addr.state),
          postalCode: this.isEmpty(addr.postalCode),
          country: this.isEmpty(addr.country)
        };

        hasError =
          hasError || Object.values(validateAddress).some(value => value);
        addrValidtionResult.push(validateAddress);
      });
    }
    return hasError;
  };

  validatePromoters = () => {
    let hasError = false;
    let promotersFieldsError = [];
    let promoters = this.state.promoters;

    if (promoters.length === 0) {
      hasError = true;

      const promoter = {
        individual: {
          firstName: true,
          addresses: [
            {
              streetLine1: true,
              landmark: true,
              city: true,
              state: true,
              postalCode: true
            }
          ],
          emails: [{ email: true }],
          phones: [{ phone: true }]
        }
      };
      promotersFieldsError = [promoter];
    } else {
      promoters.forEach((pmtr, index) => {
        const individualErrors = {};
        hasError = this.isEmpty(pmtr.individual.firstName);
        individualErrors.firstName = this.isEmpty(pmtr.individual.firstName);

        // Validate Address
        let addresses = pmtr.individual.addresses;
        const addrValidtionResult = [];
        const addrError = this.validatePromoterAddress(
          addresses,
          addrValidtionResult
        );
        hasError = hasError || addrError;
        individualErrors.addresses = addrValidtionResult;

        // Validate Phone
        if (pmtr.individual.phones.length === 0) {
          hasError = true;
          individualErrors.phones = [{ phone: true }];
        } else {
          individualErrors.phones = pmtr.individual.phones.map(ph => {
            const isPhoneEmpty = this.isEmpty(ph.phone);
            hasError = hasError || isPhoneEmpty;
            return {
              phone: isPhoneEmpty
            };
          });
        }

        // Validate Email
        if (pmtr.individual.emails.length === 0) {
          hasError = true;
          individualErrors.emails = [{ email: true }];
        } else {
          individualErrors.emails = pmtr.individual.emails.map(em => {
            const isEmailEmpty = this.isEmpty(em.email);
            hasError = hasError || isEmailEmpty;
            return {
              email: isEmailEmpty
            };
          });
        }

        // validate Education
        let education = pmtr.individual.education || [];
        individualErrors.education = education.map(edu => {
          let errorFields = {};

          if (!this.isEmpty(edu.qualification)) {
            errorFields.degree = this.isEmpty(edu.degree);
            hasError = hasError || this.isEmpty(edu.degree);
          } else if (!this.isEmpty(edu.degree)) {
            errorFields.qualification = this.isEmpty(edu.qualification);
            hasError = hasError || this.isEmpty(edu.qualification);
          }
          return errorFields;
        });

        const promoterFieldErrors = {};
        promoterFieldErrors.individual = individualErrors;

        promotersFieldsError[index] = promoterFieldErrors;
      });
    }

    if (hasError) {
      return promotersFieldsError;
    } else {
      return [];
    }
  };

  validateIncubatorProfile(inputError) {
    if (
      this.state.incubateeProfile === null ||
      this.isEmpty(this.state.incubateeProfile.residentialStatus)
    ) {
      inputError.residentialStatus = true;
      return true;
    }
    return false;
  }

  validate = () => {
    const inputError = {};
    const tabsError = {};

    const { incubateeType } = this.state;

    tabsError.incubateeProfile = this.validateIncubatorProfile(inputError);

    if (incubateeType === "COMPANY") {
      inputError.name = this.state.name === "";
      inputError.domainOperation = this.state.domainOperation === "";
      inputError.estYear = this.state.estYear === "";
      inputError.legalStatus = this.state.legalStatus === "";
      tabsError.organization = this.organizationTabValidation();
      tabsError.promoter = this.promoterTabValidation();
    }

    if (incubateeType === "INDIVIDUAL") {
      const individual = this.state.individual;

      inputError.firstName =
        individual === null || this.isEmpty(individual.firstName);

      inputError.gender =
        individual === null || this.isEmpty(individual.gender);

      inputError.degree =
        individual === null || this.isEmpty(individual.education[0].degree);

      inputError.qualification =
        individual === null ||
        this.isEmpty(individual.education[0].qualification);

      inputError.experience =
        individual === null || this.isEmpty(individual.experience);
      tabsError.individual = this.individualTabValidation();
    }

    inputError.email = this.state.email === "";
    inputError.phone = this.state.phone === "";

    let address = this.state.address;

    inputError.streetLine1 =
      address === {} ||
      address.streetLine1 === undefined ||
      address.streetLine1 === "";
    inputError.city =
      address === {} || address.city === undefined || address.city === "";
    inputError.state =
      address === {} || address.state === undefined || address.state === "";
    inputError.postalCode =
      address === {} ||
      address.postalCode === undefined ||
      address.postalCode === "";

    tabsError.contact = this.contactTabValidation();

    const invalid = Object.values(inputError).some(item => item);
    let promotersFieldsError = [];
    if (incubateeType === "COMPANY") {
      promotersFieldsError = this.validatePromoters();
      tabsError.promoter = tabsError.promoter || promotersFieldsError;
    }

    if (invalid || promotersFieldsError.length !== 0) {
      this.setState({
        inputError,
        promotersFieldsError,
        tabsError,
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: "Please complete required fields."
      });
      window.scrollTo(0, 0);
      return false;
    } else {
      this.setState({ inputError: "", tabsError: null });
      return true;
    }
  };

  getOrganizationForm = () => {
    const promoters = this.state.promoters.map(promoter => {
      const individual = promoter.individual;

      const addresses = individual.addresses.map(address => {
        const newAd = address._id ? { _id: address._id } : {};

        return {
          ...newAd,
          addressProof: address.addressProofDocument,
          streetLine1: address.streetLine1,
          streetLine2: address.streetLine2,
          landmark: address.landmark,
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          country: address.country ? address.country : "IN",
          status: address.status,
          type: address.type
        };
      });

      return {
        _id: promoter._id,
        individual: {
          emails: individual.emails,
          phones: individual.phones,
          firstName: individual.firstName,
          gender: individual.gender,
          education: individual.education,
          experience: individual.experience,
          identificationDocs: individual.identificationDocs,
          addresses: addresses
        },
        designation: promoter.designation,
        shareholding: promoter.shareholding,
        isFounder: promoter.isFounder
      };
    });
    const organization = {
      name: this.state.name,
      legalStatus: this.state.legalStatus,
      estYear: this.state.estYear,
      website: this.state.website,
      subsidiary: this.state.subsidiary,
      parentCompany: this.state.subsidiary ? this.state.parentCompany : "",
      reference: this.state.reference,
      bizSummary: this.state.bizSummary,
      services: this.state.services,
      domainOperation: this.state.domainOperation,
      //companyProfile: {}, // file upload
      //regCertificate: {}, // file upload
      //articlesOfAssociation: {}, // file upload
      authorizedCapital: this.state.authorizedCapital,
      annualTurnover: this.state.annualTurnover,
      annualTurnoverFY: this.state.annualTurnoverFY,
      promoters: promoters
    };
    return organization;
  };

  getPromoters = promoters => {
    this.setState({ promoters });
  };

  getPatents = patents => {
    this.setState({ patents });
  };

  getGrantProgrammes = grantProgrammes => {
    this.setState({ grantProgrammes });
  };

  getIndividualType = individual => {
    this.setState({ individual });
  };

  setIndividualType = data => {
    const individualType = data.individual;

    let individual = {
      firstName: individualType.firstName ? individualType.firstName : "",
      lastName: individualType.lastName ? individualType.lastName : "",
      gender: individualType.gender ? individualType.gender : "",
      dob: individualType.dob ? individualType.dob : "",
      education: individualType.education ? individualType.education : "",
      photo: individualType.photo ? individualType.photo : "",
      experience: individualType.experience ? individualType.experience : "",
      yearOfPassing: individualType.yearOfPassing
        ? individualType.yearOfPassing
        : "",
      alumniInfo: individualType.alumniInfo ? individualType.alumniInfo : "",
      references: individualType.references ? individualType.references : ""
    };
    return individual;
  };

  getIncubateeProfile = incubateeProfile => {
    this.setState({ incubateeProfile });
  };

  setIncubateeProfile = data => {
    const anchorIncubator = data.anchorIncubator;
    let anchrInbtr = null;
    if (anchorIncubator) {
      anchrInbtr = {
        label: anchorIncubator.name,
        value: anchorIncubator._id
      };
    }
    const supplementaryIncubators = data.supplementaryIncubators;

    let incubators = [];

    if (supplementaryIncubators.length > 0) {
      incubators = supplementaryIncubators.map(incubator => {
        let inbtr = {};
        inbtr.label = incubator.name;
        inbtr.value = incubator._id;
        return inbtr;
      });
    }

    const relMagr = data.relationshipManager || {};
    const names = [];
    if (relMagr.firstName) {
      names.push(relMagr.firstName);
    }
    if (relMagr.lastName) {
      names.push(relMagr.lastName);
    }

    const relationshipManager = {
      label: names.join(" "),
      value: relMagr._id
    };

    let incubateeProfile = {
      businessPlan: data.businessPlan ? data.businessPlan : "",
      graduationStatus: data.graduationStatus ? data.graduationStatus : "",
      incubateeStage: data.incubateeStage ? data.incubateeStage : "",
      incubationSector: data.incubationSector ? data.incubationSector : "",
      subTechSector: data.subTechSector ? data.subTechSector : "",
      targetSector: data.targetSector ? data.targetSector : "",
      grandsOrFunding: data.grandsOrFunding ? data.grandsOrFunding : "",
      businessModel: data.businessModel ? data.businessModel : "",
      currentEmployeeCount: data.currentEmployeeCount
        ? data.currentEmployeeCount
        : "",
      femaleEmployeeCount: data.femaleEmployeeCount
        ? data.femaleEmployeeCount
        : "",
      about: data.about ? data.about : "",
      residentialStatus: data.residentialStatus ? data.residentialStatus : "",
      incubationCategory: data.incubationCategory
        ? data.incubationCategory
        : "",
      anchorIncubator: data.anchorIncubator ? anchrInbtr : null,
      supplementaryIncubators: data.supplementaryIncubators ? incubators : [],
      relationshipManager
    };
    return incubateeProfile;
  };

  checkAndAddIdDocs = (idDocs, docType, reference) => {
    if (reference) {
      idDocs.push({ type: docType, number: reference });
    }
  };

  addDocs = (formData, name, file) => {
    if (file) {
      // let filename = file.name;
      // const dotIndex = filename.lastIndexOf(".");
      // if (dotIndex !== -1) {
      //   filename = `${name}${filename.substr(dotIndex)}`;
      // }
      formData.append(name, file);
    }
  };

  getFormData = action => {
    const incubatorAppFormId = this.state.incubatorAppFormId;

    let formData = new FormData();

    const incubateeType = this.state.incubateeType;
    let individualForm = {},
      organizationForm = {};
    const promoterUploadInfo = {
      promoterPhoto: [],
      promoterAddrProof: [],
      promoterAadhaar: []
    };

    const emails = [
      { email: this.state.email, type: "WORK", status: "ACTIVE" }
    ];

    const phones = [
      { phone: this.state.phone, type: "WORK", status: "ACTIVE" }
    ];

    const idDocs = [];
    this.checkAndAddIdDocs(idDocs, "PAN", this.state.pan);
    this.addDocs(formData, "scannedPan", this.state.scannedPan);
    if (incubateeType === "COMPANY") {
      organizationForm = this.getOrganizationForm();
      organizationForm.emails = emails;
      organizationForm.phones = phones;
      organizationForm.addresses = [this.state.address];
      this.checkAndAddIdDocs(idDocs, "GSTN", this.state.gstn);
      this.addDocs(formData, "scannedGstn", this.state.scannedGstn);
      this.addDocs(formData, "logo", this.state.logo);
      this.addDocs(formData, "companyProfile", this.state.companyProfile);
      this.addDocs(formData, "regCertificate", this.state.regCertificate);
      this.addDocs(
        formData,
        "articlesOfAssociation",
        this.state.articlesOfAssociation
      );

      const promoters = this.state.promoters || [];

      promoters.forEach((promoter, index) => {
        if (promoter.individual.promoterPhoto) {
          this.addDocs(
            formData,
            "promoterPhoto",
            promoter.individual.promoterPhoto
          );

          promoterUploadInfo.promoterPhoto.push(index);
        }

        promoter.individual.identificationDocs.forEach((idDoc, dIdx) => {
          if (idDoc.promoterAadhaar) {
            this.addDocs(formData, "promoterAadhaar", idDoc.promoterAadhaar);

            promoterUploadInfo.promoterAadhaar.push([index, dIdx]);
          }
        });

        promoter.individual.addresses.forEach((address, aindex) => {
          // old name address.addressProof
          // new name address.addressProofDocument
          if (address.addressProofDocument) {
            this.addDocs(
              formData,
              "promoterAddrProof",
              address.addressProofDocument
            );
            promoterUploadInfo.promoterAddrProof.push([index, aindex]);
          }
        });
      });

      organizationForm.identificationDocs = idDocs;
    }
    if (incubateeType === "INDIVIDUAL") {
      individualForm = this.state.individual;
      individualForm.emails = emails;
      individualForm.phones = phones;
      individualForm.addresses = [this.state.address];

      this.addDocs(formData, "scannedAadhaar", this.state.scannedAadhaar);
      this.checkAndAddIdDocs(idDocs, "AADHAAR", this.state.aadhaar);
      this.addDocs(formData, "indvPhoto", individualForm.indvPhoto);

      individualForm.identificationDocs = idDocs;
    }

    this.addDocs(formData, "addressProof", this.state.addressProof);

    const incubateeProfile = this.state.incubateeProfile;
    let anchorIncubator = incubateeProfile.anchorIncubator;
    if (anchorIncubator && anchorIncubator.value) {
      anchorIncubator = anchorIncubator.value;
    }
    let supplementaryIncubators = incubateeProfile.supplementaryIncubators;
    let supIncbtrs = [];
    supplementaryIncubators.forEach(si => {
      if (si.value) {
        supIncbtrs.push(si.value);
      }
    });

    const graduationStatus = this.state.incubateeProfile.graduationStatus;
    const residentialStatus = this.state.incubateeProfile.residentialStatus;

    const relMgr = incubateeProfile.relationshipManager || {};
    const relationshipManager = relMgr.value || "";

    const onboardForm = {
      incubateeType: incubateeType,
      individual: individualForm,
      organization: organizationForm,
      promoterUploadInfo,

      businessPlan: this.state.incubateeProfile.businessPlan,
      graduationStatus: graduationStatus ? graduationStatus : "ONGOING",
      incubateeStage: this.state.incubateeProfile.incubateeStage,
      incubationSector: this.state.incubateeProfile.incubationSector,
      subTechSector: this.state.incubateeProfile.subTechSector,
      targetSector: this.state.incubateeProfile.targetSector,
      grandsOrFunding: this.state.incubateeProfile.grandsOrFunding,
      businessModel: this.state.incubateeProfile.businessModel,
      currentEmployeeCount: this.state.incubateeProfile.currentEmployeeCount,
      femaleEmployeeCount: this.state.incubateeProfile.femaleEmployeeCount,
      about: this.state.incubateeProfile.about,
      residentialStatus: residentialStatus ? residentialStatus : "VIRTUAL",
      incubationCategory: this.state.incubateeProfile.incubationCategory,
      anchorIncubator: this.state.incubateeProfile.incubationCategory
        ? anchorIncubator
        : null,
      supplementaryIncubators: this.state.incubateeProfile.incubationCategory
        ? supIncbtrs
        : [],

      relationshipManager,
      patents: this.state.patents,

      action: action,
      status: this.state.status,
      stage: this.state.stage,
      incubatorAppFormId: incubatorAppFormId
    };

    formData.append("message", JSON.stringify(onboardForm));
    return formData;
  };

  getUpdatedMessage = data => {
    const stage = data.stage;
    const status = data.status;

    const TYPE_STAGE_STATUS_DESC = {
      "INIT|CREATED": "Onboarding form has been updated and it is in progress",
      "APPR1|PENDING":
        "Onboarding form has been updated and it is sent for Manager Approval",
      "APPR2|PENDING":
        "Onboarding form has been updated and it is sent for Manager Approval",
      "COMPLETE|REJECTED": "Onboarding form has been rejected",
      "INIT|REWORK": "Onboarding form has been sent for rework",
      "COMPLETE|APPROVED":
        "Onboarding form has been completed and incubatee has been onboarded"
    };
    const key = `${stage}|${status}`;
    return TYPE_STAGE_STATUS_DESC[key] || "Onboarding form has been updated";
  };

  handleSubmit = action => {
    if (!this.validate()) {
      return;
    }

    const rejectConfirmation = this.state.rejectConfirmation;
    if (!rejectConfirmation && action === "REJECT") {
      this.toggleRejectConfirmation();
      return;
    }

    const formData = this.getFormData(action);

    const url = INCUBATEES_ONBOARD_API + "/" + this.state._id;
    const headers = {};

    HttpUtil.put(
      url,
      headers,
      formData,
      data => {
        this.getOnboardForm(data._id);
        const alertMessage = this.getUpdatedMessage(data);
        this.setState({
          _id: data._id,
          actions: data.actions,
          status: data.status,
          stage: data.stage,
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: alertMessage,
          inputError: {}
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  addressHandler = e => {
    let address = { ...this.state.address };
    address[e.target.name] = e.target.value;
    this.setState({ address });
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      rejectConfirmation: false
    });
  };

  getStates = () => {
    const url = STATES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ states: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  downloadDocument = (file, e) => {
    e.preventDefault();

    if (file === "") {
      return;
    }
    let fileId = file._id;
    let filename = file.filename;

    // Extract request method & header & params
    const url =
      INCUBATEES_ONBOARD_API + "/" + this.state._id + "/documents/" + fileId;
    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(blob => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString());
      });
  };

  toggleRejectConfirmation = () => {
    const rejectConfirmation = this.state.rejectConfirmation;

    if (!rejectConfirmation) {
      this.setState({
        showAlert: true,
        alertType: "Confirmation",
        alertColor: "danger",
        alertMessage:
          "Rejecting this form will cancel the onborading process for this incubatee. You have to start with new application to onboard the incubatee again. Do you want to reject this application ?",
        rejectConfirmation: !this.state.rejectConfirmation
      });
    }

    if (rejectConfirmation) {
      const action = "REJECT";
      this.handleSubmit(action);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const incubateeType = this.state.incubateeType;
    const prevIncubateeType = prevState.incubateeType !== "";
    if (prevIncubateeType && prevState.incubateeType !== incubateeType) {
      this.setState({
        incubateeTypeChanged: !this.state.incubateeTypeChanged
      });
    }
  };

  render() {
    const {
      incubateeType,
      actions,
      stage,
      status,
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      loading
    } = this.state;

    const props = {
      incubateeType: this.state.incubateeType,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      aadhaar: this.state.aadhaar,
      pan: this.state.pan,
      gstn: this.state.gstn,
      // individual
      individual: this.state.individual,
      getIndividualType: this.getIndividualType,

      // organization
      organization: this.state.organization,
      name: this.state.name,
      legalStatus: this.state.legalStatus,
      estYear: this.state.estYear,
      website: this.state.website,
      reference: this.state.reference,
      subsidiary: this.state.subsidiary,
      parentCompany: this.state.parentCompany,
      bizSummary: this.state.bizSummary,
      services: this.state.services,
      domainOperation: this.state.domainOperation,
      authorizedCapital: this.state.authorizedCapital,
      annualTurnover: this.state.annualTurnover,
      annualTurnoverFY: this.state.annualTurnoverFY,
      promoters: this.state.promoters,
      patents: this.state.patents,

      promotersFieldsError: this.state.promotersFieldsError,

      // common
      incubateeProfile: this.state.incubateeProfile,
      identificationDocs: this.state.identificationDocs,
      addresses: this.state.addresses,

      states: this.state.states,
      countries: this.state.countries,

      fundAgencyTypes: this.state.fundAgencyTypes,
      fundAgencies: this.state.fundAgencies,
      fundProgrammes: this.state.fundProgrammes,
      initFunding: this.initFunding,

      //events
      handleNameInputchange: this.handleNameInputchange,
      handleInputChange: this.handleInputChange,
      addressHandler: this.addressHandler,
      addPromoter: this.addPromoter,
      removePromoter: this.removePromoter,
      inputError: this.state.inputError,
      tabsError: this.state.tabsError,
      getPromoters: this.getPromoters,
      getPatents: this.getPatents,
      getIncubateeProfile: this.getIncubateeProfile,

      getCoIncubatee: this.getCoIncubatee,
      downloadDocument: this.downloadDocument,
      handleApiFailed: this.handleApiFailed
    };

    let renderComponent = this.getRenderComponent(props);
    if (loading) {
      return (
        <div className="card-body p-0">
          <div className="row">
            <div className="col-10 offset-1">
              <div className="progress-bar-loading-text">Loading...</div>
              <div className="progress-bar">
                <div className="indeterminate" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="card-body p-0">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.toggleRejectConfirmation}
            />

            <div className="form-group row ml-2 d-flex">
              <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 text-sm-left edit-page-label-required">
                Incubatee Type
              </label>
              <div className="col-xs-12 col-sm-12 col-md-10 col-lg-4 col-xl-4">
                <select
                  className="custom-select custom-select-sm"
                  name="incubateeType"
                  onChange={this.handleChangeIncubateeType}
                  value={incubateeType}
                >
                  <option value="">Select...</option>
                  <option value="COMPANY">Organization</option>
                  <option value="INDIVIDUAL">Individual</option>
                </select>
                {this.state.incubateeTypeChanged ? (
                  <small>
                    Note:<strong> 'Incubatee Type'</strong> is Changed.
                  </small>
                ) : null}
              </div>
              <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 text-right edit-page-label">
                Status
              </label>
              <div className="col-xs-12 col-sm-12 col-md-10 col-lg-4 col-xl-4 mt-2">
                <span className="edit-page-status">
                  {getDescription("IncubateeOnboarding", stage, status)}
                </span>
              </div>
            </div>

            {renderComponent}

            <div
              className={`row ml-2 mr-2 mb-3 ${
                actions.length > 0 ? "border-top" : ""
              } `}
            >
              <div className="col-md-12 pt-3 text-md-center">
                {incubateeType !== "" && actions.length > 0
                  ? actions.map((action, idx) => {
                      return (
                        <Fragment key={idx}>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={this.handleSubmit.bind(this, action)}
                          >
                            {action}
                          </button>
                          &nbsp;&nbsp;
                        </Fragment>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default OnboardIncubatee;
