import React, { Component } from "react";
import AssetReservationRequestForm from "./AssetReservationRequestForm";
import { Link } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";
import {
  ASSET_RESERVATION_REQUEST_API,
  ORGANIZATIONS_API,
  INDIVIDUALS_API,
  STATES_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { convertDateToNumber, convertNumberToDate } from "../../lease/DateUtil";
import { Redirect } from "react-router-dom";
import getValidationSchema from "./AssetReservationValidationSchema";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AssetUsage from "./assetusagecharges/AssetUsage";
import { getDescription } from "../../common/Util";
import ErrorPage from "../../common/error.page";

const ASSET_RESERVATION_REQUEST_REJECT = 1;

class AssetReservationRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetReservation: {
        _id: "",
        userInformation: {
          requestorType: "",
          userType: "",
          requestorCategory: "",
          firstName: "",
          lastName: "",
          designation: "",
          organizationName: "",
          contactNameOrDepartment: "",
          reference: ""
        },
        contactInformation: {
          mobile: "",
          email: "",
          streetLine1: "",
          streetLine2: "",
          city: "",
          state: "TN",
          country: "IN",
          postalCode: ""
        },
        assetRequested: [],
        additionalInformation: {
          usageType: "",
          waiver: false,
          chargesWaiverReason: "",
          internalApprovalRequired: false,
          internalApprovalProof: "", // from server
          pendingInternalApprovalProof: null, // local state
          remark: "",
          remarks: []
        },
        newUser: null,
        individual: "",
        organization: "",

        stage: "",
        status: "",
        actions: []
      },

      incubatee: "",
      permanentFailure: false,
      newForm: null,
      readOnly: false,
      redirectToEditPageUrl: null,
      states: [],

      // back to previous page information
      locationValues: "",
      previousPathname: ""
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      requestSubmittedMsg: "",
      requestSubmitted: false,
      alertFor: "",
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // api success  response alert to user
  handleApiSuccess = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "success",
      alertMessage: message,
      requestSubmittedMsg: "",
      requestSubmitted: false,
      alertFor: ""
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  addFormData = (formData, fieldName, file) => {
    if (file) {
      formData.append(fieldName, file);
    }
  };

  getFormData = values => {
    const userInformation = values.userInformation;
    const contactInformation = values.contactInformation;
    const additionalInformation = values.additionalInformation;
    const contactNameOrDepartment = userInformation.contactNameOrDepartment;
    const reference = userInformation.reference;
    const mobile = {
      phone: contactInformation.mobile
    };
    const email = {
      email: contactInformation.email
    };
    const address = {
      streetLine1: contactInformation.streetLine1,
      streetLine2: contactInformation.streetLine2,
      city: contactInformation.city,
      state: contactInformation.state,
      country: contactInformation.country,
      postalCode: contactInformation.postalCode || ""
    };

    let newUser = values.newUser;
    let indRequestor = "";
    let orgRequestor = "";
    let individual = "";
    let organization = "";

    if (newUser) {
      if (userInformation.requestorType === "INDIVIDUAL") {
        indRequestor = {};
        indRequestor.firstName = userInformation.firstName;
        indRequestor.lastName = userInformation.lastName;
        indRequestor.department = contactNameOrDepartment;
        indRequestor.reference = userInformation.reference;
        indRequestor.mobile = mobile;
        indRequestor.email = email;
        indRequestor.address = address;
        indRequestor.reference = reference;
      } else {
        orgRequestor = {};
        orgRequestor.name = userInformation.organizationName;
        orgRequestor.contactName = contactNameOrDepartment;
        orgRequestor.designation = userInformation.designation;
        orgRequestor.mobile = mobile;
        orgRequestor.email = email;
        orgRequestor.address = address;
        orgRequestor.reference = reference;
      }
    } else {
      if (userInformation.requestorType === "INDIVIDUAL") {
        individual = values.individual._id || "";
      } else {
        organization = values.organization._id || "";
        orgRequestor = {};
        orgRequestor.contactName = contactNameOrDepartment;
        orgRequestor.designation = userInformation.designation;
        orgRequestor.reference = reference;
      }
    }

    const requestedAssets = values.assetRequested.map(data => {
      let from = new Date(data.from);
      let to = new Date(data.to);
      const ast = {
        asset: data.asset.value,
        rateType: data.rateType,
        rateCard: data.rateCard,
        unitType: data.unitType,
        usageUnit: data.usageUnit || null,
        usageHours: data.usageHours || null,
        from: convertDateToNumber(from),
        to: convertDateToNumber(to),
        charge: data.charge
      };
      return ast;
    });
    const remarks = values.additionalInformation.remarks || [];
    if (values.additionalInformation.remark) {
      remarks.push({ note: values.additionalInformation.remark });
    }

    const request = {
      requestorType: userInformation.requestorType,
      userType: userInformation.userType,
      requestorCategory: userInformation.requestorCategory,
      advance: values.advance || 0,
      newUser,
      indRequestor,
      orgRequestor,
      individual,
      organization,
      requestedAssets,
      usageType: additionalInformation.usageType,
      waiver: additionalInformation.waiver,
      chargesWaiverReason: additionalInformation.chargesWaiverReason,
      internalApprovalRequired: additionalInformation.internalApprovalRequired,
      remarks: remarks,
      action: values.action
    };

    const incubatee = this.state.incubatee || "";
    if (incubatee) {
      request.requestorType = "INCUBATEE";
      request.incubatee = incubatee.value;
      request.newUser = false;
    }

    if (values.incubatorEnquiry) {
      request.incubatorEnquiry = values.incubatorEnquiry;
    }

    let formData = new FormData();

    if (additionalInformation.internalApprovalRequired) {
      this.addFormData(
        formData,
        "internalApprovalProof",
        additionalInformation.pendingInternalApprovalProof
      );
    }

    formData.append("message", JSON.stringify(request));
    return formData;
  };

  create = (values, { setSubmitting }) => {
    setSubmitting(true);
    // console.log("handleSubmit:==>:", JSON.stringify(values, null, 2));

    const request = this.getFormData(values);
    const url = `${ASSET_RESERVATION_REQUEST_API}`;
    const headers = {};
    HttpUtil.post(
      url,
      headers,
      request,
      data => {
        setSubmitting(false);
        this.setState({
          redirectToEditPageUrl: `/service-mgmt/asset-reservation-requests/edit/${
            data._id
          }`
        });
        sessionStorage.clear();
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  update = (values, { setSubmitting }) => {
    setSubmitting(true);
    // console.log("handleSubmit:==>:", JSON.stringify(values, null, 2));
    const request = this.getFormData(values);
    const id = this.props.match.params.id;
    const url = `${ASSET_RESERVATION_REQUEST_API}/${id}`;
    const headers = {};
    HttpUtil.put(
      url,
      headers,
      request,
      data => {
        this.setDataFromServer(data);
        setSubmitting(false);
        this.setState({
          showAlert: true,
          alertType: "Default",
          alertColor: "success",
          alertMessage: `Asset Reservation Request form has been updated successfully.`,
          alertFor: "",
          alertWithTitle: "",
          values: undefined
        });
        sessionStorage.clear();
        window.scrollTo(0, 0);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  openAlertModel = (values, setSubmitting) => {
    this.setState({
      alertType: "Confirmation",
      showAlert: true,
      alertColor: "danger",
      alertMessage: `Do you want to Reject Asset Reservation Request ?`,
      alertFor: ASSET_RESERVATION_REQUEST_REJECT,
      alertWithTitle: "Reject ?",
      values,
      setSubmitting
    });
  };

  handleAlertModelConfirmation = () => {
    const values = this.state.values;
    const setSubmitting = this.state.setSubmitting;

    this.handleSubmit(values, { setSubmitting });
  };

  closeAlertModel = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ``,
      alertFor: "",
      alertWithTitle: ""
    });
  };

  handleSubmit = (values, { setSubmitting }) => {
    // alert
    const action = values.action;
    if (action === "REJECT" && !this.state.alertFor) {
      this.openAlertModel(values, setSubmitting);
      return;
    }

    if (this.state.newForm) {
      this.create(values, { setSubmitting });
    } else {
      this.update(values, { setSubmitting });
    }
  };

  setUserInformation = data => {
    let firstName = "";
    let lastName = "";
    let organizationName = "";
    let designation = "";
    let contactNameOrDepartment = "";
    let reference = "";

    if (data.requestorType === "ORGANIZATION") {
      const orgRequestor = data.orgRequestor || {};
      organizationName = orgRequestor.name;
      contactNameOrDepartment = orgRequestor.contactName;
      designation = orgRequestor.designation;
      reference = orgRequestor.reference;
    }

    if (data.requestorType === "INDIVIDUAL") {
      const indRequestor = data.indRequestor || {};
      firstName = indRequestor.firstName;
      lastName = indRequestor.lastName;
      contactNameOrDepartment = indRequestor.department;
      reference = indRequestor.reference;
    }

    const userInformation = {
      requestorType: data.requestorType,
      userType: data.userType,
      requestorCategory: data.requestorCategory,
      firstName,
      lastName,
      designation,
      organizationName,
      contactNameOrDepartment,
      reference
    };
    return userInformation;
  };

  setContactInformation = data => {
    let mobile = "";
    let email = "";
    let address = {
      streetLine1: "",
      streetLine2: "",
      city: "",
      state: "TN",
      country: "IN",
      postalCode: ""
    };
    if (data.requestorType === "ORGANIZATION") {
      const orgRequestor = data.orgRequestor || {};
      mobile = orgRequestor.mobile.phone;
      email = orgRequestor.email.email;
      let orgAddr = orgRequestor.address;
      address = { ...orgAddr };
    }

    if (data.requestorType === "INDIVIDUAL") {
      const indRequestor = data.indRequestor || {};
      mobile = indRequestor.mobile.phone;
      email = indRequestor.email.email;
      let indvlAddr = indRequestor.address;
      address = { ...indvlAddr };
    }

    const contactInformation = {
      mobile,
      email,
      ...address
    };
    return contactInformation;
  };

  setAssetInformation = data => {
    const requestedAssets = data.requestedAssets || [];
    const assetRequested = requestedAssets.map(data => {
      const asset = data.asset;
      const lbl = asset.assetType
        ? `${asset.assetType.name} - ${asset.assetName}`
        : `${asset.assetName}`;

      const reqAsset = {
        value: asset._id,
        label: lbl,
        assetId: asset.assetId,
        requireUnitType: asset.requireUnitType || false,
        unitTypes: asset.unitTypes,
        rateTypes: asset.rateTypes,
        availableHours: asset.availableHours
      };

      if (asset.link) {
        reqAsset.link = asset.link;
      }

      const reqAssetInformation = {
        _id: data._id,
        asset: reqAsset,
        rateCard: data.rateCard,
        charge: data.charge || "",
        requireUnitType: reqAsset.requireUnitType,
        unitType: data.unitType,
        usageUnit: data.usageUnit,
        usageHours: data.usageHours,
        rateType: data.rateType,
        from: convertNumberToDate(data.from),
        to: convertNumberToDate(data.to)
      };
      return reqAssetInformation;
    });
    return assetRequested;
  };

  setAdditionalInformation = data => {
    const additionalInformation = {
      usageType: data.usageType || "",
      waiver: data.waiver,
      chargesWaiverReason: data.chargesWaiverReason || "",
      internalApprovalRequired: data.internalApprovalRequired,
      internalApprovalProof: data.internalApprovalProof,
      pendingInternalApprovalProof: null, // set null for validation
      // remark: data.remarks || "",
      remarks: data.remarks.reverse() || []
    };
    if (data.link) {
      additionalInformation.link = data.link;
    }
    return additionalInformation;
  };

  handleExistingUserInfo = data => {
    const requestorType = data.requestorType;
    let userInformation = {};
    if (requestorType === "INDIVIDUAL") {
      const individual = data.individual;
      userInformation.firstName = individual.firstName;
      userInformation.lastName = individual.lastName;
    }
    if (requestorType === "ORGANIZATION") {
      const organization = data.organization;
      userInformation.organizationName = organization.name;
      const orgRequestor = data.orgRequestor || {};
      userInformation.contactNameOrDepartment = orgRequestor.contactName;
      userInformation.designation = orgRequestor.designation;
      userInformation.reference = orgRequestor.reference;
    }
    userInformation.requestorType = requestorType;
    userInformation.userType = data.userType;
    userInformation.requestorCategory = data.requestorCategory;
    return userInformation;
  };

  handleExistingContactInfo = data => {
    const requestorType = data.requestorType;
    let contactInformation = {};
    if (requestorType === "INDIVIDUAL") {
      const individual = data.individual;
      const address = individual.addresses[0] ? individual.addresses[0] : {};
      contactInformation = {
        email: individual.emails[0] ? individual.emails[0].email : "",
        mobile: individual.phones[0] ? individual.phones[0].phone : "",
        ...address
      };
    }
    if (requestorType === "ORGANIZATION") {
      const organization = data.organization;
      const address = organization.addresses[0]
        ? organization.addresses[0]
        : {};
      contactInformation = {
        email: organization.emails[0] ? organization.emails[0].email : "",
        mobile: organization.phones[0] ? organization.phones[0].phone : "",
        ...address
      };
    }
    return contactInformation;
  };

  setDataFromServer = data => {
    // console.log("fetched :data:", JSON.stringify(data, null, 2));
    const assetReservation = {
      _id: data._id,
      assetRequested: this.setAssetInformation(data),
      additionalInformation: this.setAdditionalInformation(data),
      stage: data.stage,
      status: data.status,
      actions: data.actions || [],
      advance: data.advance,
      reservationBalance: data.reservationBalance || 0,
      refundAmount: data.refundAmount || 0,
      refundNotes: data.refundNotes || "",

      disatisfactionReason: data.disatisfactionReason,
      serviceSatisfied: data.serviceSatisfied,
      equipmentUsed: data.equipmentUsed
    };

    if (data.newUser) {
      assetReservation.userInformation = this.setUserInformation(data);
      assetReservation.contactInformation = this.setContactInformation(data);
    } else {
      assetReservation.userInformation = this.handleExistingUserInfo(data);
      assetReservation.contactInformation = this.handleExistingContactInfo(
        data
      );
    }

    assetReservation.newUser = data.newUser;
    assetReservation.individual = data.individual || "";
    assetReservation.organization = data.organization || "";

    let readOnly =
      data.stage === "RESERVEAPPR" ||
      data.stage === "RESERVEPAY" ||
      data.stage === "RESERVEREFUND" ||
      data.stage === "COMPLETE";

    let incubatee = "";
    if (data.incubatee && data.incubatee._id) {
      incubatee = {};
      incubatee.label = data.incubatee.name;
      incubatee.value = data.incubatee._id;
    }

    let previousPathname = "";
    if (this.props.location.search !== "") {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      previousPathname = params.get("from");
    }

    this.setState({
      assetReservation,
      newForm: false,
      readOnly,
      incubatee,
      locationValues: "",
      previousPathname
    });
  };

  getAssetReservationRequest = id => {
    const url = `${ASSET_RESERVATION_REQUEST_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setDataFromServer(data);
        sessionStorage.removeItem("newAssetReservationFlow");
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  setExistingUserFromServer = (data, requestorType) => {
    const assetReservation = { ...this.state.assetReservation };
    let userInformation = { ...assetReservation.userInformation };

    if (requestorType === "INDIVIDUAL") {
      userInformation.firstName = data.firstName;
      userInformation.lastName = data.lastName;
      assetReservation.individual = data;
    }

    if (requestorType === "ORGANIZATION") {
      userInformation.organizationName = data.name;
      assetReservation.organization = data;
    }

    const address = data.addresses[0] ? data.addresses[0] : {};
    const contactInformation = {
      email: data.emails[0] ? data.emails[0].email : "",
      mobile: data.phones[0] ? data.phones[0].phone : "",
      ...address
    };

    userInformation.requestorType = requestorType;
    assetReservation.userInformation = { ...userInformation };
    assetReservation.contactInformation = { ...contactInformation };
    assetReservation.newUser = false;

    this.setState(prevState => {
      return {
        assetReservation: {
          ...prevState.assetReservation,
          ...assetReservation
        }
      };
    });
  };

  getIndividualById = id => {
    const url = `${INDIVIDUALS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => this.setExistingUserFromServer(data, "INDIVIDUAL"),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getOrganization = id => {
    const url = `${ORGANIZATIONS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => this.setExistingUserFromServer(data, "ORGANIZATION"),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  loadNewData = () => {
    const newAssetReservationFlow = sessionStorage.getItem(
      "newAssetReservationFlow"
    );
    if (newAssetReservationFlow) {
      const flowConfig = JSON.parse(newAssetReservationFlow);

      const previousPathname = flowConfig.path;
      const assetReservation = { ...this.state.assetReservation };
      if (flowConfig.data.incubatorEnquiry) {
        assetReservation.incubatorEnquiry = flowConfig.data.incubatorEnquiry;
        assetReservation.assetRequested = (
          flowConfig.data.assetRequested || []
        ).map(asset => {
          return {
            ...asset,
            charge: ""
          };
        });
      }
      let incubatee = "";

      if (flowConfig.category === "INCUBATEE") {
        incubatee = flowConfig.data.incubatee;
      } else if (
        flowConfig.category === "NOMATCH" ||
        flowConfig.category === "MATCH_CREATE_NEW"
      ) {
        let userInformation = { ...assetReservation.userInformation };
        userInformation.requestorType = flowConfig.data.requestorType;
        userInformation.firstName = flowConfig.data.firstName;
        userInformation.lastName = flowConfig.data.lastName;
        userInformation.organizationName = flowConfig.data.organizationName;
        userInformation.contactNameOrDepartment =
          flowConfig.data.contactNameOrDepartment;
        userInformation.designation = flowConfig.data.designation;
        userInformation.requestorCategory =
          flowConfig.data.requestorCategory || "";
        assetReservation.userInformation = userInformation;
        assetReservation.newUser = true;

        const contactInformation = { ...assetReservation.contactInformation };
        contactInformation.email = flowConfig.data.email;
        if (flowConfig.data.mobile) {
          contactInformation.mobile = String(flowConfig.data.mobile);
        }
        assetReservation.contactInformation = contactInformation;
      } else if (flowConfig.category === "MATCH") {
        const { selectedProfile } = flowConfig.data;
        const id = selectedProfile._id;
        if (selectedProfile.requestorType === "Individual") {
          this.getIndividualById(id);
        } else if (selectedProfile.requestorType === "Organization") {
          this.getOrganization(id);
        }
        assetReservation.userInformation.requestorCategory =
          flowConfig.data.requestorCategory || "";
        return this.setState(prevState => {
          return {
            ...prevState,
            assetReservation,
            newForm: true,
            previousPathname,
            incubatee
          };
        });
      }

      this.setState({
        newForm: true,
        assetReservation,
        previousPathname,
        incubatee
      });
    }
  };

  loadExistingRecord = id => {
    const locationState = this.props.location.state;
    if (locationState && locationState.created) {
      this.setState({
        showAlert: true,
        alertType: "Default",
        alertColor: "success",
        alertMessage: `Asset Reservation Request form has been created successfully.`
      });
      window.scrollTo(0, 0);
    }
    this.getAssetReservationRequest(id);
  };

  getStates = () => {
    const url = STATES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ states: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;
    if (id) {
      this.loadExistingRecord(id);
    } else {
      this.loadNewData();
    }
    this.getStates();
  };

  render() {
    const { newForm, incubatee, permanentFailure, alertMessage } = this.state;

    const { redirectToEditPageUrl } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (newForm === null) {
      return null;
    }

    if (redirectToEditPageUrl) {
      const location = {
        pathname: redirectToEditPageUrl,
        state: { created: true }
      };
      return <Redirect to={location} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      alertWithTitle: this.state.alertWithTitle,
      close: this.closeAlertModel,
      confirm: this.handleAlertModelConfirmation
    };

    const formikProps = {
      initialValues: this.state.assetReservation,
      validationSchema: getValidationSchema(incubatee ? "INCUBATEE" : ""),
      newForm: this.state.newForm,
      readOnly: this.state.readOnly,
      locationValues: this.state.locationValues,
      previousPathname: this.state.previousPathname,
      states: this.state.states,
      incubatee,

      // events
      handleApiFailed: this.handleApiFailed,
      handleSubmit: this.handleSubmit
    };

    const stage = this.state.assetReservation.stage;
    const status = this.state.assetReservation.status;

    const showAssetUsageInfo =
      stage === "RESERVEREFUND" ||
      stage === "RESERVEAPPR" ||
      stage === "RESERVEPAY" ||
      (stage === "COMPLETE" && status === "APPROVED") ||
      status === "CLOSED";

    return (
      <div className="container-fluid-lg container-fluid-xl">
        <div className="row mb-3">
          <div className="col-md-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card elevate">
              <FormHeader {...formikProps} />
              <Tabs>
                <TabList>
                  <Tab>Reservation Info</Tab>

                  {showAssetUsageInfo ? <Tab>Usage Info</Tab> : null}
                </TabList>
                <TabPanel>
                  <AssetReservationRequestForm
                    {...formikProps}
                    {...this.props}
                  />
                </TabPanel>

                {showAssetUsageInfo ? (
                  <TabPanel>
                    <AssetUsage
                      assetReservation={this.state.assetReservation}
                      currentPathname={this.props.location.pathname}
                      handleApiSuccess={this.handleApiSuccess}
                      getAssetReservationRequest={
                        this.getAssetReservationRequest
                      }
                    />
                  </TabPanel>
                ) : null}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetReservationRequest;

const ASSET_RESERVATION_LIST_PAGE = "/service-mgmt/open-reservations";

const FormHeader = props => {
  const { newForm, locationValues, previousPathname } = props;

  const stage = props.initialValues.stage;
  const status = props.initialValues.status;

  return (
    <div className="card-header border-0 mb-2 mt-2">
      <span className="edit-page-title">
        <Link
          to={{
            pathname: previousPathname || ASSET_RESERVATION_LIST_PAGE,
            state: { ...locationValues }
          }}
          className="badge bg-white custom-btn-sm float-left"
        >
          <i className="fas fa-arrow-left mr-2" />
        </Link>
        Asset Reservation
      </span>

      {newForm ? null : (
        <span className="float-right edit-page-status ml-2">{`Status :  ${getDescription(
          "AssetReservationRequest",
          stage,
          status
        )}`}</span>
      )}
    </div>
  );
};
