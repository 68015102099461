import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AutoLookup from "../AutoLookup";
import FloorPlan from "./floorPlan/FloorPlan";
import ImageViewer from "../../common/ImageViewer";
import Can from "../../../auth/can";

class FloorForm extends Component {
  render() {
    const {
      //Floor
      floorName,
      mainLevel,
      abbreviation,
      totalArea,
      usableArea,
      areaUnit,
      status,
      areaPrice,
      seaterPrice,

      //events
      newFloor,
      onChange,
      submit,
      inputError,

      // auto-lookup
      campus,
      building,
      getCampus,
      getBuilding,

      // for selectors
      statusList,
      areaUnitList
    } = this.props;

    const campusLookupUrl = "api/v1/campuses/search/?name=";
    let buildingLookupUrl = "";
    if (campus && campus !== "") {
      buildingLookupUrl =
        "api/v1/buildings/search/?campus=" + campus + "&buildingName=";
    }

    const imageViewerProps = {
      imageUrl: this.props.imageUrl,
      handlePreviewImageClose: this.props.handlePreviewImageClose,
      imageName: this.props.imageName
    };

    return (
      <div className="card edit-page-container">
        <div className="card-header border-bottom-0 pt-3 text-center">
          <span className="edit-page-title mb-0">
            <Link to="/admin/floors" className="float-left">
              <i className="fas fa-arrow-left" />
            </Link>
            &nbsp;
            <span className="ml-3">
              {newFloor ? "Floor - New Record" : floorName}
            </span>
          </span>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row mb-0">
                {newFloor ? (
                  <Fragment>
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                      Campus
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <AutoLookup
                        className={inputError["campus"]}
                        name="name"
                        value={campus}
                        authToken={this.props.authToken}
                        getObject={getCampus}
                        url={campusLookupUrl}
                      />
                      {inputError["campus"] ? (
                        <div className="auto-lookup-empty">
                          Please select campus name
                        </div>
                      ) : null}
                    </div>
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                      Building
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <AutoLookup
                        className={inputError["building"]}
                        name="buildingName"
                        authToken={this.props.authToken}
                        campus={campus}
                        value={building}
                        getObject={getBuilding}
                        url={buildingLookupUrl}
                        isDisabled={campus === "" ? true : false}
                      />
                      {inputError["building"] ? (
                        <div className="auto-lookup-empty">
                          Please select a building
                        </div>
                      ) : null}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                      Building
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <input
                        type="text"
                        className={
                          inputError["building"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="building *"
                        name="building"
                        value={building}
                        //onChange={onChange}
                        readOnly
                      />
                    </div>
                  </Fragment>
                )}
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Floor Name
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className={
                      inputError["floorName"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Floor Name *"
                    name="floorName"
                    value={floorName}
                    onChange={onChange}
                  />

                  <div className="invalid-feedback">
                    Please enter floor name
                  </div>
                </div>

                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Floor Status
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <select
                    className="custom-select custom-select-sm"
                    name="status"
                    value={status}
                    onChange={onChange}
                  >
                    {statusList.map((sta, idx) => (
                      <option key={idx} value={sta.statusCode}>
                        {sta.statusDesc}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Abbreviation
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className={
                      inputError["abbreviation"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Description here..."
                    name="abbreviation"
                    value={abbreviation}
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    Please enter floor abbreviation
                  </div>
                </div>

                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Main Level
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <label className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={mainLevel}
                      name="mainLevel"
                      onChange={onChange}
                    />
                    <span className="custom-control-label">
                      This floor is main level
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Area Unit
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <select
                    className={
                      inputError["areaUnit"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    name="areaUnit"
                    value={areaUnit ? areaUnit : ""}
                    onChange={onChange}
                  >
                    <option value="">Select ...</option>
                    {areaUnitList.map((unit, idx) => (
                      <option key={idx} value={unit.unitCode}>
                        {unit.unitDesc}
                      </option>
                    ))}
                  </select>

                  <div className="invalid-feedback">
                    Please select area unit
                  </div>
                </div>

                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Total Area
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="number"
                    className={
                      inputError["totalArea"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Total area"
                    name="totalArea"
                    value={totalArea}
                    onChange={onChange}
                  />
                  <div className="invalid-feedback">
                    Please enter total area
                  </div>
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Usable Area
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="number"
                    className={
                      inputError["usableArea"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Usable area"
                    name="usableArea"
                    value={usableArea}
                    onChange={onChange}
                  />

                  <div className="invalid-feedback">
                    Please enter usable area
                  </div>
                </div>

                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                  Fixed Rental per Sqft
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="Area price *"
                    name="areaPrice"
                    value={areaPrice}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                  Seater Price
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    placeholder="Seater price *"
                    name="seaterPrice"
                    value={seaterPrice}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="p-1" />
          <ImageViewer {...imageViewerProps} />

          <FloorPlan {...this.props} />

          <div className="row">
            <div className="col-sm-12 text-center">
              {newFloor ? (
                <Can I="CREATE" a="Floor">
                  <button className="btn btn-sm  btn-primary" onClick={submit}>
                    Submit
                  </button>
                </Can>
              ) : (
                <Can I="UPDATE" a="Floor">
                  <button className="btn btn-sm  btn-primary" onClick={submit}>
                    Update
                  </button>
                </Can>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FloorForm;
