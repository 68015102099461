const LogLevels = {
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  DEBUG: 3
};

const CURRENT_LOG_LEVEL = LogLevels.INFO;

const Logger = {
  error: (...args) => {
    if (CURRENT_LOG_LEVEL >= LogLevels.ERROR) {
      console.error(...args);
    }
  },

  warn: (...args) => {
    if (CURRENT_LOG_LEVEL >= LogLevels.WARN) {
      console.warn(...args);
    }
  },

  info: (...args) => {
    if (CURRENT_LOG_LEVEL >= LogLevels.INFO) {
      console.log(...args);
    }
  },

  debug: (...args) => {
    if (CURRENT_LOG_LEVEL >= LogLevels.DEBUG) {
      console.debug(...args);
    }
  }
};

export default Logger;
