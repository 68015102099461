import React from "react";

function getError(touched, error, fieldName) {
  return touched && touched[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touched, error, fieldName) {
  return getError(touched, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touched, error, datePicker } = props;
  const className = datePicker ? "auto-lookup-empty" : "invalid-feedback";

  return (
    <div className={className}>
      {touched && touched[`${fieldName}`] && error && error[`${fieldName}`]}
    </div>
  );
};

export { getError, getErrorCssClassName, ErrorComponent };
