import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";
import { USERS_API } from "../common/Constants";
import TableList from "../common/TableList";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leasingList: [],

      users: null,

      // for alert
      alertType: null,
      showAlert: null,
      alertColor: null,
      alertMessage: null,

      editUser: false,
      editUserId: null,

      responseStatusCode: null
    };
  }

  componentDidMount = () => {
    this.getAllUsers();
  };

  getAllUsers = () => {
    const url = USERS_API;

    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ users: data, responseStatusCode: 200 });
      },
      (data, status) => {
        if (status === 403) {
          this.setState({ responseStatusCode: status });
        } else {
          this.handleApiFailed(data.message);
        }
      },
      error => this.handleApiFailed(error.toString())
    );
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  getStatus = (cell, row) => {
    return row.isActive ? "Active" : "Inactive";
  };

  actionsFormater = (cell, row) => {
    if (!row) {
      return null;
    }
    //console.log("cell:", cell, "row:", row);
    const userActive = row.isActive;
    const cssClassName = userActive
      ? "badge badge-success p-1"
      : "badge badge-danger p-1";

    const label = (
      <span className={cssClassName}>{userActive ? "Active" : "Inactive"}</span>
    );

    return label;
  };
  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  linkFormater = (cell, row) => {
    if (!row) {
      return null;
    }
    const url = `/admin/users/edit/${row._id}`;
    return <Link to={url}>{row.username}</Link>;
  };

  render() {
    const {
      users,

      responseStatusCode
    } = this.state;

    if (responseStatusCode === null) {
      return null;
    }

    let customAlertComponent = null;
    if (responseStatusCode && responseStatusCode === 403) {
      customAlertComponent = (
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger text-center" role="alert">
              You are not authorized to perform this action.
            </div>
          </div>
        </div>
      );
    }
    const columnsUsers = [
      {
        dataField: "username",
        text: "Username",
        sort: true,
        formatter: this.linkFormater
      },

      {
        dataField: "firstName",
        text: "Name",
        sort: true
      },
      {
        dataField: "isActive",
        text: "Status",
        formatter: this.actionsFormater,
        csvFormatter: this.getStatus,
        filterValue: this.getStatus
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        {customAlertComponent !== null ? (
          customAlertComponent
        ) : (
          <TableList
            keyField="_id"
            columns={columnsUsers}
            data={users}
            noDataIndication="No User List found."
            sizePerPage="10"
            headerName="User List"
            showSearchbar={true}
            customComponent={
              <Link
                to="/admin/users/add"
                role="button"
                className="btn btn-sm text-white bg-pink"
              >
                New
              </Link>
            }
          />
        )}
      </Fragment>
    );
  }
}

export default UserList;
