import React from "react";
import { Link } from "react-router-dom";

function convertDate(dateString) {
  if (!dateString) {
    return;
  }
  const date = Date.parse(dateString);
  return new Intl.DateTimeFormat("en-IN").format(date);
}

function EventSearchResults(props) {
  const events = props.events;

  return (
    <div className="responsive">
      <table className="table table-sm mt-4">
        <thead>
          <tr>
            <th scope="col" className="display-table-header">
              Event Name
            </th>
            <th scope="col" className="display-table-header">
              Start Date
            </th>
            <th scope="col" className="display-table-header">
              End Date
            </th>
            <th scope="col" className="display-table-header">
              Location
            </th>
            <th scope="col" className="display-table-header">
              Organiser
            </th>
          </tr>
        </thead>
        <tbody>
          {events.length === 0 ? (
            <tr>
              <td colSpan="4">No events found for the criteria provided.</td>
            </tr>
          ) : (
            props.events.map((event, index) => (
              <tr key={event._id}>
                <td scope="row">
                  <Link to={`/events/${event._id}?from=/events/search`}>{event.name}</Link>
                </td>
                <td>{convertDate(event.startDate)}</td>
                <td>{convertDate(event.endDate)}</td>
                <td>{event.location && event.location.name}</td>
                <td>{event.organizer && event.organizer.name}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default EventSearchResults;
