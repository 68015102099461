import { convertDateToNumber, convertNumberToDate } from "../../lease/DateUtil";

export const DEFAULT_ONBOARD_FORM = {
  incubateeType: "COMPANY",
  organization: {
    // basic information
    name: "",
    website: "",
    domainOperation: "",
    legalStatus: "",
    estYear: "",
    reference: "",
    subsidiary: false,
    parentCompany: "",
    currentEmployeeCount: null,
    femaleEmployeeCount: null,

    // product and service
    products: [
      {
        name: "",
        description: ""
      }
    ],

    // Financial information
    authorizedCapital: "",
    annualTurnover: "",
    annualTurnoverFY: "",

    // Document information
    logo: "",
    companyProfile: "",
    regCertificate: "",
    articlesOfAssociation: "",

    uploadLogo: "",
    uploadCompanyProfile: "",
    uploadRegCertificate: "",
    uploadArticlesOfAssociation: "",

    // Promoters
    promoters: [
      {
        individual: {
          firstName: "",
          gender: "",
          experience: "",
          education: [
            {
              year: "",
              qualification: "",
              degree: "",
              institution: "",
              alumni: false,
              branch: ""
            }
          ],
          references: "",
          photo: "",
          uploadPhoto: "",
          addresses: [
            {
              uploadAddressProof: "",
              // addressProof: "",
              streetLine1: "",
              streetLine2: "",
              landmark: "",
              city: "",
              state: "",
              postalCode: "",
              country: "",
              status: "",
              type: ""
            }
          ],
          identificationDocs: [
            {
              status: "ACTIVE",
              type: "AADHAAR",
              number: "",
              uploadAadhaar: "",
              document: ""
            }
          ],
          emails: [{ email: "" }],
          phones: [{ phone: "" }]
        },
        designation: "",
        shareholding: "",
        isFounder: false
      }
    ]
  },

  individual: {
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    experience: "",
    references: "",

    // For Education
    qualification: "",
    degree: "",
    institution: "",
    alumni: false,
    branch: "",
    year: "",

    indvPhoto: "",
    uploadIndvPhoto: ""
  },

  contact: {
    phone: "",
    email: "",
    address: {
      uploadAddressProof: "",
      addressProof: "",
      streetLine1: "",
      streetLine2: "",
      landmark: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      status: "",
      type: ""
    }
  },

  identificationDocs: {
    panNumber: "",
    gstnNumber: "",
    aadhaarNumber: "",

    // For Both
    pan: "", // file from server

    // For Company
    gstn: "", // file from server

    // For Individual
    aadhaar: "", // file from server

    uploadPan: "",
    uploadGstn: "",
    uploadAadhaar: ""
  },

  incubateeProfile: {
    graduationStatus: "ONGOING",
    residentialStatus: "",
    incubationSector: "",
    subTechSector: "",
    otherSubSector: "",
    incubateeStage: "",
    targetSector: "",
    incubationCategory: "",
    relationshipManager: "",
    about: "",
    businessPlan: "",
    uploadBusinessPlan: ""
  },

  patents: [
    {
      titleOfInnovation: "",
      country: "",
      applicationNumber: "",
      applicationFilingDate: null,
      fieldOfInvention: "",
      classificationCode: "",
      patentStatus: ""
    }
  ],

  actions: []
};

function getFirstElement(objArr, defaultValue) {
  return objArr && objArr.length > 0 ? objArr[0] : defaultValue;
}

export const transformServerToUi = data => {
  const onboardForm = {};
  onboardForm._id = data._id;

  const incubateeType = data.incubateeType;
  onboardForm.incubateeType = incubateeType;

  let idDocs = [];
  let emails, phones, addresses;
  // For Organization
  if (incubateeType === "COMPANY") {
    const org = data.organization;

    let products = [];
    if (org.products && org.products.length > 0) {
      products = org.products.map(product => {
        return {
          _id: product._id,
          name: product.name || "",
          description: product.description || ""
        };
      });
    } else {
      products = [
        {
          name: "",
          description: ""
        }
      ];
    }
    onboardForm.organization = {
      // basic information
      _id: org._id,
      name: org.name || "",
      website: org.website || "",
      domainOperation: org.domainOperation || "",
      legalStatus: org.legalStatus || "",
      estYear: org.estYear || "",
      reference: org.reference || "",
      subsidiary: org.subsidiary || false,
      parentCompany: org.parentCompany || "",
      currentEmployeeCount: org.currentEmployeeCount || null,
      femaleEmployeeCount: org.femaleEmployeeCount || null,

      // Product and Service
      products,

      // Financial information
      authorizedCapital: org.authorizedCapital || "",
      annualTurnover: org.annualTurnover || "",
      annualTurnoverFY: org.annualTurnoverFY || "",

      // Document information
      logo: org.logo || "",
      companyProfile: org.companyProfile || "",
      regCertificate: org.regCertificate || "",
      articlesOfAssociation: org.articlesOfAssociation || "",

      uploadLogo: "",
      uploadCompanyProfile: "",
      uploadRegCertificate: "",
      uploadArticlesOfAssociation: ""
    };

    emails = data.organization.emails;
    phones = data.organization.phones;
    addresses = data.organization.addresses;
    idDocs = data.organization.identificationDocs;

    // For Promoters
    let promoters = org.promoters;
    if (!promoters || promoters.length === 0) {
      promoters = DEFAULT_ONBOARD_FORM.organization.promoters;
    }

    promoters.forEach(pmtr => {
      const individual = pmtr.individual || {};
      individual.gender = individual.gender || "";
      individual.experience = individual.experience || "";
      individual.photo = individual.photo || "";
      individual.uploadPhoto = "";

      individual.education = individual.education.map(data => {
        const edu = {
          year: data.year || "",
          qualification: data.qualification || "",
          degree: data.degree || "",
          institution: data.institution || "",
          alumni: data.alumni || false,
          branch: data.branch || ""
        };
        if (data._id) {
          edu._id = data._id;
        }
        return edu;
      });
      if (individual.emails.length === 0) {
        individual.emails = [{ email: "" }];
      }

      if (individual.phones.length === 0) {
        individual.phones = [{ phone: "" }];
      }

      const addresses = individual.addresses.map(addrObj => {
        return {
          ...addrObj,
          streetLine1: addrObj.streetLine1 || "",
          streetLine2: addrObj.streetLine2 || "",
          landmark: addrObj.landmark || "",
          city: addrObj.city || "",
          state: addrObj.state || "TN",
          postalCode: addrObj.postalCode || "",
          country: addrObj.country || "IN",
          uploadAddressProof: ""
        };
      });
      individual.addresses = addresses;

      let idDocs = [];
      if (
        individual.identificationDocs &&
        individual.identificationDocs.length > 0
      ) {
        idDocs = individual.identificationDocs.map(idDoc => {
          return {
            ...idDoc,
            uploadAadhaar: ""
          };
        });
      } else {
        idDocs = [
          {
            number: "",
            type: "AADHAAR",
            uploadAadhaar: "",
            document: ""
          }
        ];
      }
      individual.identificationDocs = idDocs;
    });

    onboardForm.organization.promoters = promoters;
  }

  // For Individual
  if (incubateeType === "INDIVIDUAL") {
    const indv = data.individual;
    const eduObj = getFirstElement(indv.education, "");

    onboardForm.individual = {
      _id: indv._id,
      firstName: indv.firstName,
      lastName: indv.lastName,
      gender: indv.gender || "",
      dob: convertNumberToDate(indv.dob) || "",
      experience: indv.experience,
      references: indv.references,

      // For Education
      year: eduObj.year || "",
      qualification: eduObj.qualification || "",
      degree: eduObj.degree || "",
      institution: eduObj.institution || "",
      alumni: eduObj.alumni || false,
      branch: eduObj.branch || "",

      indvPhoto: indv.photo || "",
      uploadIndvPhoto: ""
    };

    idDocs = data.individual.identificationDocs;
    emails = data.individual.emails;
    phones = data.individual.phones;
    addresses = data.individual.addresses;
  }

  const phoneObj = getFirstElement(phones, "");
  const emailObj = getFirstElement(emails, "");
  let addrObj = getFirstElement(addresses, "");

  const DEFAULT_ADDRESS = DEFAULT_ONBOARD_FORM.contact.address;

  if (!addrObj) {
    addrObj = DEFAULT_ADDRESS;
  } else {
    addrObj = {
      ...addrObj,
      streetLine1: addrObj.streetLine1 || "",
      streetLine2: addrObj.streetLine2 || "",
      landmark: addrObj.landmark || "",
      city: addrObj.city || "",
      state: addrObj.state || "TN",
      postalCode: addrObj.postalCode || "",
      country: addrObj.country || "IN",
      uploadAddressProof: ""
    };
  }
  // For Contact
  onboardForm.contact = {
    phone: phoneObj.phone || "",
    email: emailObj.email || "",
    address: addrObj
  };

  // Identification documents
  let panNumber, gstnNumber, aadhaarNumber;
  let pan, gstn, aadhaar;
  idDocs.forEach(idDoc => {
    if (idDoc.type === "PAN") {
      panNumber = idDoc.number || panNumber;

      pan = idDoc.document || "";
    }

    if (idDoc.type === "GSTN") {
      gstnNumber = idDoc.number || gstnNumber;
      gstn = idDoc.document || "";
    }

    if (idDoc.type === "AADHAAR") {
      aadhaarNumber = idDoc.number || aadhaarNumber;
      aadhaar = idDoc.document || "";
    }
  });

  const identificationDocs = {};
  identificationDocs.panNumber = panNumber || "";
  identificationDocs.gstnNumber = gstnNumber || "";
  identificationDocs.aadhaarNumber = aadhaarNumber || "";

  // Documents from server
  identificationDocs.pan = pan || "";

  identificationDocs.gstn = gstn || "";
  identificationDocs.uploadGstn = "";

  identificationDocs.aadhaar = aadhaar || "";
  identificationDocs.uploadAadhaar = "";

  identificationDocs.incubateeType = incubateeType; // added for yup local validation

  onboardForm.identificationDocs = identificationDocs;

  // For IncubateeProfile
  const relMagr = data.relationshipManager || {};
  const names = [];
  if (relMagr.firstName) {
    names.push(relMagr.firstName);
  }
  if (relMagr.lastName) {
    names.push(relMagr.lastName);
  }

  const relationshipManager = {
    label: names.join(" "),
    value: relMagr._id
  };

  onboardForm.incubateeProfile = {
    graduationStatus: data.graduationStatus || "ONGOING",
    residentialStatus: data.residentialStatus || "",
    incubationSector: data.incubationSector || "",
    subTechSector: data.subTechSector || "",
    otherSubSector: data.otherSubSector || "",
    incubateeStage: data.incubateeStage || "",
    targetSector: data.targetSector || "",
    incubationCategory: data.incubationCategory || "",
    relationshipManager: relationshipManager || "",
    about: data.about || "",
    businessPlan: data.businessPlan || "",
    uploadBusinessPlan: ""
  };

  // Patetns
  if (data.patents && data.patents.length > 0) {
    onboardForm.patents = data.patents.map(pt => {
      return {
        _id: pt._id,
        titleOfInnovation: pt.titleOfInnovation || "",
        country: pt.country || "",
        applicationNumber: pt.applicationNumber || "",
        applicationFilingDate: pt.applicationFilingDate || null,
        fieldOfInvention: pt.fieldOfInvention || "",
        classificationCode: pt.classificationCode || "",
        patentStatus: pt.patentStatus || ""
      };
    });
  } else {
    onboardForm.patents = DEFAULT_ONBOARD_FORM.patents;
  }

  onboardForm.actions = data.actions;
  onboardForm.status = data.status;
  onboardForm.stage = data.stage;

  return onboardForm;
};

function checkAndAddIdDocs(idDocs, docType, reference) {
  if (reference) {
    idDocs.push({ type: docType, number: reference });
  }
}

export const transformUiToApi = values => {
  const onboardForm = {};

  const contact = values.contact;
  const addresses = [];
  const address = {};
  address._id = contact.address._id;
  address.streetLine1 = contact.address.streetLine1;
  address.streetLine2 = contact.address.streetLine2;
  address.landmark = contact.address.landmark;
  address.city = contact.address.city;
  address.state = contact.address.state;
  address.postalCode = contact.address.postalCode;
  address.country = contact.address.country;
  addresses.push(address);

  const emails = [{ email: contact.email, type: "WORK", status: "ACTIVE" }];
  const phones = [{ phone: contact.phone, type: "WORK", status: "ACTIVE" }];

  const idDoc = values.identificationDocs;
  const idDocs = [];
  checkAndAddIdDocs(idDocs, "PAN", idDoc.panNumber);

  const incubateeType = values.incubateeType;
  onboardForm.incubateeType = incubateeType;

  // Organization
  if (incubateeType === "COMPANY") {
    checkAndAddIdDocs(idDocs, "GSTN", idDoc.gstnNumber);
    const organization = { ...values.organization } || {};
    organization.addresses = addresses;
    organization.emails = contact.email ? emails : [];
    organization.phones = contact.phone ? phones : [];
    organization.identificationDocs = idDocs;

    // Promoter
    const pmtr = organization.promoters || [];
    const promoters = pmtr.map(pmtr => {
      const individual = pmtr.individual || {};
      const emails = individual.emails.filter(em => em.email);
      const phones = individual.phones.filter(ph => ph.phone);
      individual.emails = emails;
      individual.phones = phones;
      individual.gender = individual.gender || null;
      if (!individual.photo) {
        delete individual.photo;
      }

      const identificationDocs = individual.identificationDocs || [];
      identificationDocs.forEach(idDoc => {
        if (!idDoc.document) {
          delete idDoc.document;
        }
      });
      individual.identificationDocs = identificationDocs;

      pmtr.individual = individual;
      return pmtr;
    });
    organization.promoters = promoters;

    // Products
    const prds = organization.products || [];
    const products = [];
    prds.forEach(product => {
      const values = Object.values(product).filter(d => d);
      if (values.length > 0) {
        products.push(product);
      }
    });
    organization.products = products;
    onboardForm.organization = organization;
  }

  // Individual
  if (incubateeType === "INDIVIDUAL") {
    checkAndAddIdDocs(idDocs, "AADHAAR", idDoc.aadhaarNumber);
    const individual = { ...values.individual } || {};
    individual.addresses = addresses;
    individual.emails = contact.email ? emails : [];
    individual.phones = contact.phone ? phones : [];
    individual.identificationDocs = idDocs;
    individual.gender = individual.gender || null;

    let dob = individual.dob;
    if (dob) {
      individual.dob = convertDateToNumber(dob);
    }
    individual.education = [
      {
        year: individual.year,
        qualification: individual.qualification,
        degree: individual.degree,
        institution: individual.institution,
        branch: individual.branch,
        alumni: individual.alumni
      }
    ];

    onboardForm.individual = individual;
  }

  // Incubatee Profile
  const relMgr = values.incubateeProfile.relationshipManager || {};
  const relationshipManager = relMgr.value || "";

  onboardForm.graduationStatus = values.incubateeProfile.graduationStatus;
  onboardForm.residentialStatus = values.incubateeProfile.residentialStatus;
  onboardForm.incubationSector = values.incubateeProfile.incubationSector;
  onboardForm.subTechSector = values.incubateeProfile.subTechSector;
  onboardForm.otherSubSector = values.incubateeProfile.otherSubSector;
  onboardForm.incubateeStage = values.incubateeProfile.incubateeStage;
  onboardForm.targetSector = values.incubateeProfile.targetSector;
  onboardForm.incubationCategory = values.incubateeProfile.incubationCategory;
  onboardForm.relationshipManager = relationshipManager;
  onboardForm.about = values.incubateeProfile.about;
  onboardForm.businessPlan = values.incubateeProfile.businessPlan;

  if (!onboardForm.businessPlan) {
    delete onboardForm.businessPlan;
  }

  // Patetns
  const ptnts = values.patents || [];
  const patents = [];
  ptnts.forEach(patent => {
    const values = Object.values(patent).filter(d => d);
    if (values.length > 0) {
      patents.push(patent);
    }
  });
  onboardForm.patents = patents;

  onboardForm.action = values.action;

  return onboardForm;
};

function addDocs(formData, name, file) {
  if (file) {
    formData.append(name, file);
  }
}

export const loadDocumentsToApi = (formData, onboardForm, values) => {
  const incubateeType = values.incubateeType;
  const idDoc = values.identificationDocs;

  const promoterUploadInfo = {
    promoterPhoto: [],
    promoterAddrProof: [],
    promoterAadhaar: []
  };

  // uploadBusinessPlan
  addDocs(formData, "businessPlan", values.incubateeProfile.uploadBusinessPlan);

  addDocs(formData, "scannedPan", idDoc.uploadPan);

  if (values.contact.address.uploadAddressProof) {
    addDocs(
      formData,
      "addressProof",
      values.contact.address.uploadAddressProof
    );
  }

  if (incubateeType === "COMPANY" && values.organization) {
    const organization = values.organization || {};
    addDocs(formData, "logo", values.organization.uploadLogo);
    addDocs(
      formData,
      "companyProfile",
      values.organization.uploadCompanyProfile
    );
    addDocs(
      formData,
      "regCertificate",
      values.organization.uploadRegCertificate
    );
    addDocs(
      formData,
      "articlesOfAssociation",
      values.organization.uploadArticlesOfAssociation
    );
    addDocs(formData, "scannedGstn", idDoc.uploadGstn);

    const promoters = organization.promoters || [];
    promoters.forEach((pmtr, pmtrIndex) => {
      const individual = pmtr.individual || {};

      if (individual.uploadPhoto) {
        addDocs(formData, "promoterPhoto", individual.uploadPhoto);
        promoterUploadInfo.promoterPhoto.push(pmtrIndex);
      }

      const addresses = individual.addresses || [];
      addresses.forEach((addr, aindex) => {
        if (addr.uploadAddressProof) {
          addDocs(formData, "promoterAddrProof", addr.uploadAddressProof);
          promoterUploadInfo.promoterAddrProof.push([pmtrIndex, aindex]);
        }
      });

      const idDocs = individual.identificationDocs || [];
      idDocs.forEach((idDoc, dIdx) => {
        if (idDoc.type === "AADHAAR" && idDoc.uploadAadhaar) {
          addDocs(formData, "promoterAadhaar", idDoc.uploadAadhaar);
          promoterUploadInfo.promoterAadhaar.push([pmtrIndex, dIdx]);
        }
      });
    });
  }

  if (incubateeType === "INDIVIDUAL" && values.individual) {
    addDocs(formData, "scannedAadhaar", idDoc.uploadAadhaar);
    addDocs(formData, "indvPhoto", values.individual.uploadIndvPhoto);
  }

  onboardForm.promoterUploadInfo = promoterUploadInfo;
};

// Tabs Error Validation start
function individualTabError(errors) {
  const individual = { ...errors.individual } || {};
  const hasError = Object.keys(individual).length > 0 || false;
  return hasError;
}

function orgTabError(errors) {
  const organization = { ...errors.organization } || {};
  const promoters = organization.promoters || [];
  if (promoters.length > 0) {
    delete organization.promoters;
  }
  const hasError = Object.keys(organization).length > 0 || false;
  return hasError;
}

function contactTabError(errors) {
  const contact = { ...errors.contact } || {};
  const hasError = Object.keys(contact).length > 0 || false;

  const identificationDocs = { ...errors.identificationDocs } || {};
  const idDocError = Object.keys(identificationDocs).length > 0 || false;
  return hasError || idDocError;
}

function promoterTabError(errors) {
  const organization = { ...errors.organization } || {};
  const promoters = organization.promoters || [];
  const hasError = promoters.length > 0 || false;
  return hasError;
}

function incubateeProfileTabError(errors) {
  const incubateeProfile = { ...errors.incubateeProfile } || {};
  const hasError = Object.keys(incubateeProfile).length > 0 || false;
  return hasError;
}

function patentTabError(errors) {
  const patents = errors.patents || [];
  const hasError = patents.length > 0 || false;
  return hasError;
}

export const getTabsError = errors => {
  const tabsError = {
    individual: individualTabError(errors),
    organization: orgTabError(errors),
    promoters: promoterTabError(errors),
    contact: contactTabError(errors),
    incubateeProfile: incubateeProfileTabError(errors),
    patents: patentTabError(errors)
  };
  return tabsError;
};
// Tabs Error Validation end
