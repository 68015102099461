import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDisplayDate,
  convertDateToNumber,
  convertNumberToDate
} from "../../../lease/DateUtil";

class Patent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patents: [],
      inputError: {}
    };
  }

  componentDidMount = () => {
    const existingPatents = this.props.patents;
    if (existingPatents.length !== 0) {
      this.setState({ patents: existingPatents });
    } else {
      this.addMorePatent();
    }
  };

  addNewPatent = () => {
    const patent = {
      country: "",
      applicationNumber: "",
      applicationFilingDate: "",
      fieldOfInvention: "",
      classificationCode: "",
      patentStatus: ""
    };
    return patent;
  };

  addMorePatent = () => {
    const newPatent = this.addNewPatent();
    const patents = this.state.patents;
    patents.push(newPatent);
    this.setState({ patents });
  };

  removePatent = (index, e) => {
    e.preventDefault();
    let patents = [...this.state.patents];
    if (patents.length === 0) return;
    patents = patents.filter((pat, idx) => idx !== index);
    this.setStatePatents(patents);
  };

  handleInputChange = (index, e) => {
    e.preventDefault();
    const targetName = e.target.name;
    const targetValue = e.target.value;
    let patents = [...this.state.patents];
    let patent = patents[index];
    patent[targetName] = targetValue;
    patents[index] = patent;
    this.setStatePatents(patents);
  };

  handleDateChange = (index, targetName, date) => {
    let patents = [...this.state.patents];
    let patent = patents[index];
    patent[targetName] = convertDateToNumber(date);
    patents[index] = patent;
    this.setStatePatents(patents);
  };

  setStatePatents = patents => {
    this.setState({ patents }, this.props.getPatents(patents));
  };

  render() {
    const { patents } = this.state;
    const { inputError } = this.props;

    const patentList = patents.map((patent, index) => {
      const showRemoveButton = patents.length > 1;

      return (
        <div key={index} className="row p-0 m-0 edit-page-subsection">
          <div className="col-md-12">
            <div className="card ml-4 mr-4 mt-4 mb-2">
              <div className="card-header edit-page-subsection-title border-0">
                {showRemoveButton ? (
                  <button
                    type="button"
                    className="close pull-right mt-1"
                    aria-label="Close"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={`Remove Patent ${index + 1}`}
                    onClick={this.removePatent.bind(this, index)}
                    style={{ color: "#ff0000" }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                ) : null}
                <h6 className="card-title  mb-0">Patent {index + 1}</h6>
              </div>
              <div className="card-body mb-3" key={index}>
                <div className="form-group row mb-2">
                  <label className="col-form-label edit-page-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left">
                    Country
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Country Name"
                      name="country"
                      value={patent.country}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                  </div>

                  <label className="col-form-label edit-page-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left">
                    Application Number
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="0"
                      name="applicationNumber"
                      value={patent.applicationNumber}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label className="col-form-label edit-page-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left">
                    Application Filing Date
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <DatePicker
                      value={getDisplayDate(
                        convertNumberToDate(patent.applicationFilingDate)
                      )}
                      selected={
                        patent.applicationFilingDate
                          ? convertNumberToDate(patent.applicationFilingDate)
                          : null
                      }
                      onChange={this.handleDateChange.bind(
                        this,
                        index,
                        "applicationFilingDate"
                      )}
                      utcOffset={0}
                      placeholderText="dd-mm-yyyy"
                      className={
                        inputError["applicationFilingDate"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />

                    {inputError["applicationFilingDate"] ? (
                      <div className="auto-lookup-empty">
                        Please select application filling date *.
                      </div>
                    ) : null}
                  </div>

                  <label className="col-form-label edit-page-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left">
                    Field Of Invention
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Field Invention"
                      name="fieldOfInvention"
                      value={patent.fieldOfInvention}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label edit-page-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left">
                    Classification (IPC)
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="IPC"
                      name="classificationCode"
                      value={patent.classificationCode}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                  </div>

                  <label className="col-form-label edit-page-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left">
                    Patent Status
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Patent Status"
                      name="patentStatus"
                      value={patent.patentStatus}
                      onChange={this.handleInputChange.bind(this, index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        {patentList}
        <div className="row p-3 pr-3 text-center">
          <div className="col-12">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={this.addMorePatent}
            >
              Add More
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Patent;
