import React, { Component } from "react";
import Download from "downloadjs";
import AlertComponent from "../common/AlertComponent";
import OrganizationForm from "./OrganizationForm";
import HttpUtil from "../common/HttpUtil";
import {
  ORGANIZATIONS_API,
  COUNTRIES_API,
  STATES_API
} from "../common/Constants";

import { DEFAULT_ORG, transferServerToUi, transferUiToApi } from "./Controller";
import { getDownloadFileName } from "../common/Util";
import ErrorPage from "../common/error.page";

class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: DEFAULT_ORG,
      previousLocation: "",
      incProfUrl: "",
      currentLocation: "",
      states: [],
      countries: [],
      pageLoading: null,
      permanentFailure: false,
      graduationStatus: ""
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      pageLoading: false,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getStates = () => {
    const url = STATES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ states: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getOrganization = id => {
    const url = `${ORGANIZATIONS_API}/${id}`;

    this.setState({ pageLoading: true });
    HttpUtil.get(
      url,
      {},
      data => {
        const organization = transferServerToUi(data);
        this.setState({ organization, pageLoading: false });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleSubmit = values => {
    const updateRequest = transferUiToApi(values);

    const url = `${ORGANIZATIONS_API}/${this.state.organization._id}`;

    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        const organization = this.getOrganization(data._id);
        this.setState({
          organization,
          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: ` ${data.name} profile has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  downloadDocument = (filePartUrl, e) => {
    console.log("dowload fun filePartUrl:", filePartUrl, " e:", e);
    const url = `${ORGANIZATIONS_API}/${filePartUrl}`;

    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return Promise.all([
            response.blob(),
            getDownloadFileName(response, "photo")
          ]);
        } else {
          throw Error(response.statusText);
        }
      })
      .then(([blob, filename]) => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString(), true);
      });
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getOrganization(id);

      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const from = params.get("from");
      const incProfUrl = params.get("incProfUrl");
      const graduationStatus = params.get("graduationStatus");
      const currentLocation = this.props.location.pathname;
      this.setState({
        previousLocation: from,
        incProfUrl,
        currentLocation,
        graduationStatus
      });
    } else {
      this.setState({ newIncubatee: true });
    }

    this.getStates();
    this.getCountries();
  }

  render() {
    const { pageLoading, permanentFailure, alertMessage } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (pageLoading === null || pageLoading) {
      return (
        <div className="text-center">
          <h4> Loading ... </h4>
        </div>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
            />
          </div>
        </div>

        <div>
          <OrganizationForm
            readOnly={this.state.graduationStatus === "GRADUATED"}
            organization={this.state.organization}
            handleSubmit={this.handleSubmit}
            downloadDocument={this.downloadDocument}
            previousLocation={this.state.previousLocation}
            incProfUrl={this.state.incProfUrl}
            currentLocation={this.state.currentLocation}
            states={this.state.states}
            countries={this.state.countries}
          />
        </div>
      </div>
    );
  }
}

export default Organization;
