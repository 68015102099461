import React, { Component, Fragment } from "react";
import AlertComponent from "../../common/AlertComponent";
import Redirect from "react-router-dom/Redirect";
import AssetLeaseForm from "./AssetLeaseForm";
import HttpUtil from "../../common/HttpUtil";
import { ASSET_LEASES_API } from "../../common/Constants";
import * as Yup from "yup";
import { convertDateToNumber, convertNumberToDate } from "../../lease/DateUtil";
import { YupSetLocale } from "../../widgets/YupUtil";

Yup.setLocale(YupSetLocale);

const ASSET_LEASE_SCHEMA = Yup.object().shape({
  leasedAssets: Yup.array().of(
    Yup.object().shape({
      startDate: Yup.date()
        .label("Start Date")
        .required(),
      endDate: Yup.date()
        .label("End Date")
        .required(),
      advance: Yup.number()
        .label("Advance Amount")
        .required(),
      rate: Yup.string()
        .label("Rate")
        .required()
    })
  )
});

const MAX_UPLOAD_DOCUMENT = 3;

class AssetLease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetLease: {
        _id: null,
        leasedAssets: []
      },

      redirectToAssetLease: false,
      redirectToAssetLeaseId: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getUpdateRequest = values => {
    const leasedAssets = values.leasedAssets.map(lsdAsset => {
      let asset = { ...lsdAsset };
      asset.asset = lsdAsset.asset._id;
      asset.advance = Number(asset.advance);
      asset.startDate = convertDateToNumber(asset.startDate);
      asset.endDate = convertDateToNumber(asset.endDate);
      return asset;
    });
    const updateRequest = { leasedAssets, action: values.action };

    const formData = new FormData();

    const documents = values.documents || [];
    const existingDocs = values.assetLeaseDocuments.length;
    const maxAllowedDocs = MAX_UPLOAD_DOCUMENT - existingDocs;
    for (let i = 0; i < maxAllowedDocs; i++) {
      this.addDocs(formData, "assetLeaseDocuments", documents[i]);
    }

    formData.append("message", JSON.stringify(updateRequest));

    return formData;
  };

  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const assetLeaseId = this.props.match.params.id;
    const url = `${ASSET_LEASES_API}/${assetLeaseId}`;

    const updateRequest = this.getUpdateRequest(values);

    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        setSubmitting(false);
        this.setState({
          redirectToAssetLease: true,
          redirectToAssetLeaseId: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAssetLease = () => {
    const assetLeaseId = this.props.match.params.id;
    const url = `${ASSET_LEASES_API}/${assetLeaseId}`;

    if (
      this.props.location.state &&
      this.props.location.state.assetLeaseCreated
    ) {
      this.setState({
        //Default alert
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: "Asset Lease Request created successfully."
      });
      window.scrollTo(0, 0);
    }

    if (
      this.props.location.state &&
      this.props.location.state.assetLeaseUpdated
    ) {
      this.setState({
        //Default alert
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: "Asset Lease Request updated successfully."
      });
      window.scrollTo(0, 0);
    }

    HttpUtil.get(
      url,
      {},
      data => {
        const leasedAssets = data.leasedAssets.map(lsdAsset => {
          const asset = { ...lsdAsset };
          asset.asset = { ...lsdAsset.asset };
          asset.startDate = convertNumberToDate(asset.startDate);
          asset.endDate = convertNumberToDate(asset.endDate);
          asset.rate = lsdAsset.rate || "";
          return asset;
        });
        const assetLease = { ...data };
        assetLease.leasedAssets = leasedAssets;

        this.setState({ assetLease });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      this.getAssetLease();
    }
  };

  render() {
    const { redirectToAssetLease, redirectToAssetLeaseId } = this.state;
    if (this.state.assetLease._id === null) {
      return null;
    }

    if (redirectToAssetLease) {
      const stateObj = {
        pathname: `/asset/lease/${redirectToAssetLeaseId}`,
        state: { assetLeaseUpdated: true }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const props = {
      initialValues: this.state.assetLease,
      handleSubmit: this.handleSubmit,
      validationSchema: ASSET_LEASE_SCHEMA,
      handleApiFailed: this.handleApiFailed
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <AssetLeaseForm {...props} />
      </Fragment>
    );
  }
}

export default AssetLease;
