import React from "react";
import PivotReport from "../PivotReport";
import { checkNaN } from "../ReportsUtil";

const dimensions = [
  { value: "hasLease", title: "Leased" },
  { value: "incubateeType", title: "Type" },
  { value: "incubateeStage", title: "Stage" },
  { value: "residentialStatus", title: "Residential Status" },
  { value: "incubationCategory", title: "Category" },
  { value: "incubationSector", title: "Sector" },
  { value: "subTechSector", title: "Sub Sector" },
  { value: "coIncubatee", title: "CoIncubatee" },
  { value: "anchor", title: "Anchor" },
  { value: "name", title: "Name" }
];

function reduce(row, aggRecord) {
  aggRecord.count = (aggRecord.count || 0) + 1;
  return aggRecord;
}

const calculations = [
  {
    title: "Count",
    value: "count",
    template: val => checkNaN(val),
    sortBy: row => checkNaN(row.count)
  }
];

function IncubateeInfoReport(props) {
  let filter = {};
  const params = new URLSearchParams(props.location.search);
  const group = params.get("group");
  let activeDimensions = [];
  if (group === "lease") {
    activeDimensions = ["Leased"];
    filter = { Leased: "Yes" };
  } else if (group === "sector") {
    activeDimensions = ["Sector"];
  } else if (group === "residential-status") {
    activeDimensions = ["Residential Status"];
  }

  return (
    <PivotReport
      title="Incubatee"
      url="/api/v1/reports/incubatee/info"
      dimensions={dimensions}
      reduce={reduce}
      calculations={calculations}
      activeDimensions={activeDimensions}
      defaultFilter={filter}
    />
  );
}

export default IncubateeInfoReport;
