import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { INCUBATION_SECTORS_OBJECT } from "../common/IncubateeProfileConstants";
import {
  OFFICE_SPACE_AVAILABILITY_API,
  DESK_SPACE_AVAILABILITY_API,
  INCUBATEE_COUNT_BY_SECTOR_API,
  PARTITION_CATEGORY_STATS_API
} from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import LoadingComponent from "../common/spinnerloader/LoadingComponent";
import ClusterUsage from "./ClusterUsage";

function calculatePercentage(portion, total) {
  if (!total) {
    return null;
  }
  let pct = ((portion / total) * 100).toFixed(2);
  let round = Math.round(pct);
  return round;
}

const officeOccupancyOptions = {
  colors: ["#20E647"],
  plotOptions: {
    radialBar: {
      startAngle: 0,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.1
        }
      },
      dataLabels: {
        name: {
          show: false
        },
        value: {
          color: "#000",
          offestY: -10,
          fontSize: "40px",
          show: true
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "vertical",
      gradientToColors: ["#00BEAB"],
      stops: [0, 100]
    }
  },
  stroke: {
    lineCap: "round"
  },
  responsive: [
    {
      breakpoint: 1199,
      options: {
        chart: { height: 210 }
      }
    }
  ]
};

const deskVacancyOptions = {
  labels: ["Occupied"],
  colors: ["#3086ff"],
  plotOptions: {
    radialBar: {
      startAngle: 0,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.1
        }
      },
      dataLabels: {
        name: {
          show: false
        },
        value: {
          color: "#000",
          offestY: -10,
          fontSize: "40px",
          show: true
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "vertical",
      gradientToColors: ["#00BEAB"],
      stops: [0, 100]
    }
  },
  stroke: {
    lineCap: "round"
  },
  responsive: [
    {
      breakpoint: 1199,
      options: {
        chart: { height: 210 }
      }
    }
  ]
};

function getIncubateeSectorChartSeries(data) {
  const series = data.map(d => d.count);
  return series;
}

function getIncubateeSectorChartOptions(data) {
  const sectors = [];
  for (let i = 0; i < data.length; i++) {
    let sector = data[i].sector;
    let desc = INCUBATION_SECTORS_OBJECT[sector];
    if (desc) {
      sectors.push(desc);
    } else {
      sectors.push(sector);
    }
  }
  const incubateesSectorChartOptions = {
    //colors: ["#3086ff", "#56ab2f", "#cb2d3e", "#6190E8", "#B24592", "#FFB75E"],
    // colors: ["#1f77b4", "#9467bd", "#7f7f7f", "#17bece", "#2f88bd"],
    // colors: ["#008ffb", "#00e396", "#775dd0", "#feb019", "#ff4560"],
    colors: [
      // color combination 400 2
      "#5c6bc0",
      "#42a5f5",
      "#26c6da",
      "#26a69a",
      "#ec407a",
      "#78909c",
      "#ab47bc",
      "#7e57c2",
      "#5c6bc0",
      "#ffca28"
      // "#ff0000",
      // "#0080ff",
      // "#3cb44b",
      // "#f032e6",
      // "#4363d8",
      // "#800000",
      // "#911eb4",
      // "#9A6324",
      // "#808000",
      // "#000075",
      // "#e6194B",
      // "#ff8000",
      // "#679317",
      // "#ef473a",
      // "#0a94bd",
      // "#ff3377",
      // "#2e4045"
    ],
    labels: sectors,
    dataLabels: {
      enabled: true,
      formatter: function(val, opts) {
        //   if (toPrint) {
        //     console.log(JSON.stringify(opts.w.config, null, 2));
        //     toPrint = false;
        //   }
        return opts.w.globals.series[opts.seriesIndex];
      }
    },
    fill: {
      type: "solid",
      // gradient: {
      //   shade: "dark",
      //   type: "vertical",
      //   gradientToColors: [
      //     "#00BEAB",
      //     "#a8e063",
      //     "#ef473a",
      //     "#A7BFE8",
      //     "#F15F79",
      //     "#ED8F03"
      //   ],
      //   stops: [0, 100]
      // }
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: [
          "#ff0000",
          "#0080ff",
          "#3cb44b",
          "#f032e6",
          "#4363d8",
          "#800000",
          "#911eb4",
          "#9A6324",
          "#808000",
          "#000075",
          "#e6194B",
          "#f58231",
          "#ff8000",
          "#679317",
          "#ef473a",
          "#0a94bd",
          "#ff3377",
          "#2e4045"
        ],
        stops: [0, 100]
      }
    },
    legend: {
      show: true,
      position: "bottom",
      verticalAlign: "middle",
      itemMargin: {
        horizontal: 8
      }
    },

    responsive: [
      {
        breakpoint: 1199,
        options: {
          // chart: { width: 200, height: 260 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 992,
        options: {
          // chart: { width: 280, height: 280 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 480,
        options: {
          //  chart: { width: 250 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 320,
        options: {
          // chart: { width: 200 },
          legend: { position: "bottom" }
        }
      }
    ]
  };

  return incubateesSectorChartOptions;
}

function getPartitionCategoryChartOptions(data) {
  const categories = [];
  const series = [];

  for (let i = 0; i < data.length; i++) {
    categories.push(data[i].category);
    series.push(data[i].totalArea);
  }

  const chartOptions = {
    // colors: ["#3086ff", "#56ab2f", "#cb2d3e", "#6190E8", "#B24592", "#FFB75E"],
    // colors: ["#1f77b4", "#9467bd", "#7f7f7f", "#17bece", "#2f88bd"],
    // colors: ["#008ffb", "#00e396", "#775dd0", "#feb019", "#ff4560"],
    colors: [
      "#7e57c2",
      "#5c6bc0",
      "#42a5f5",
      "#26c6da",
      "#26a69a",
      "#66bb6a",
      "#9ccc65",
      "#d4e157",
      "#ffca28",
      "#ffa726"
      // "#ff0000",
      // "#0080ff",
      // "#3cb44b",
      // "#f032e6",
      // "#4363d8",
      // "#800000",
      // "#911eb4",
      // "#9A6324",
      // "#808000",
      // "#000075",
      // "#e6194B",
      // "#ff8000",
      // "#679317",
      // "#ef473a",
      // "#0a94bd",
      // "#ff3377",
      // "#2e4045"
    ],
    labels: categories,
    dataLabels: {
      enabled: true,
      formatter: function(val, opts) {
        //   if (toPrint) {
        //     console.log(JSON.stringify(opts.w.config, null, 2));
        //     toPrint = false;
        //   }
        return opts.w.globals.series[opts.seriesIndex];
      }
    },
    fill: {
      type: "solid",
      // gradient: {
      //   shade: "dark",
      //   type: "vertical",
      //   gradientToColors: [
      //     "#00BEAB",
      //     "#a8e063",
      //     "#ef473a",
      //     "#A7BFE8",
      //     "#F15F79",
      //     "#ED8F03"
      //   ],
      //   stops: [0, 100]
      // }
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: [
          "#0080ff",
          "#ff0000",
          "#3cb44b",
          "#f032e6",
          "#4363d8",
          "#800000",
          "#911eb4",
          "#9A6324",
          "#808000",
          "#000075",
          "#e6194B",
          "#f58231",
          "#ff8000",
          "#679317",
          "#ef473a",
          "#0a94bd",
          "#ff3377",
          "#2e4045"
        ],
        stops: [0, 100]
      }
    },
    legend: {
      show: true,
      // position: "right",
      verticalAlign: "middle",
      itemMargin: {
        horizontal: 5
      }
    },
    responsive: [
      {
        breakpoint: 1342,
        options: {
          // chart: { width: 200, height: 260 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 992,
        options: {
          // chart: { width: 280, height: 280 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 480,
        options: {
          //  chart: { width: 250 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 320,
        options: {
          // chart: { width: 200 },
          legend: { position: "bottom" }
        }
      }
    ]
  };

  return [chartOptions, series];
}

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      officeOccupancyStats: null,
      occupancyDeskStats: null,
      incubateeSectorsStats: null,
      partitionCategoryStats: null
    };
  }

  getOfficeOccupancyStats = () => {
    let url = OFFICE_SPACE_AVAILABILITY_API;
    HttpUtil.get(
      url,
      {},
      data => {
        const officeOccupancyStats = {
          totalArea: data.totalArea,
          leasedArea: data.leasedArea,
          available: data.available,
          errorMessage: null
        };
        this.setState({ officeOccupancyStats });
      },
      (data, status) => {
        this.setState({
          officeOccupancyStats: {
            errorMessage: data.message
          }
        });
      },
      error => {
        this.setState({
          officeOccupancyStats: {
            errorMessage: "Unable to Fetch data"
          }
        });
      }
    );
  };

  getOccupancyDeskStats = () => {
    let url = DESK_SPACE_AVAILABILITY_API;
    HttpUtil.get(
      url,
      {},
      data => {
        const occupancyDeskStats = {
          total: data.total,
          leased: data.leased,
          available: data.available,
          errorMessage: null
        };

        this.setState({ occupancyDeskStats });
      },
      (data, status) => {
        this.setState({
          occupancyDeskStats: {
            errorMessage: data.message
          }
        });
      },
      error => {
        this.setState({
          occupancyDeskStats: {
            errorMessage: "Unable to Fetch data"
          }
        });
      }
    );
  };

  getIncubateeCountBySectorsStats = () => {
    let url = INCUBATEE_COUNT_BY_SECTOR_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          incubateeSectorsStats: {
            errorMessage: null,
            incubateeCountBySectors: data
          }
        });
      },
      (data, status) => {
        this.setState({
          incubateeSectorsStats: {
            errorMessage: data.message,
            incubateeCountBySectors: []
          }
        });
      },
      error => {
        this.setState({
          incubateeSectorsStats: {
            errorMessage: "Unable to Fetch data",
            incubateeCountBySectors: []
          }
        });
      }
    );
  };

  getPartitionUsageByCategory = () => {
    let url = PARTITION_CATEGORY_STATS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          partitionCategoryStats: {
            errorMessage: null,
            partitionUsage: data
          }
        });
      },
      (data, status) => {
        this.setState({
          partitionCategoryStats: {
            errorMessage: data.message,
            partitionUsage: []
          }
        });
      },
      error => {
        this.setState({
          partitionCategoryStats: {
            errorMessage: "Unable to Fetch data",
            partitionUsage: []
          }
        });
      }
    );
  };

  componentDidMount() {
    this.getOfficeOccupancyStats();
    this.getOccupancyDeskStats();
    this.getIncubateeCountBySectorsStats();
    this.getPartitionUsageByCategory();
  }

  render() {
    const {
      officeOccupancyStats,
      occupancyDeskStats,
      incubateeSectorsStats,
      partitionCategoryStats
    } = this.state;

    let leasedOfficeArea = 0;

    if (officeOccupancyStats) {
      leasedOfficeArea = calculatePercentage(
        officeOccupancyStats.leasedArea,
        officeOccupancyStats.totalArea
      );
    }

    let leasedDesk = 0;

    if (occupancyDeskStats) {
      leasedDesk = calculatePercentage(
        occupancyDeskStats.leased,
        occupancyDeskStats.total
      );
    }

    let incubateesSectorChartOptions;
    let series;
    if (incubateeSectorsStats) {
      const { incubateeCountBySectors } = incubateeSectorsStats;
      incubateesSectorChartOptions = getIncubateeSectorChartOptions(
        incubateeCountBySectors
      );
      series = getIncubateeSectorChartSeries(incubateeCountBySectors);
    }

    let partitionCategoryChartOptions;
    let partitionCategorySeries;

    if (partitionCategoryStats) {
      const { partitionUsage } = partitionCategoryStats;

      const [chartOptions, series] = getPartitionCategoryChartOptions(
        partitionUsage
      );
      partitionCategoryChartOptions = chartOptions;
      partitionCategorySeries = series;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div
              className="card elevate mt-3"
              // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
            >
              <div className="card-header border-bottom-0">
                <div
                  className="card-header-title font-size-lg text-center"
                  style={{ fontWeight: "500" }}
                >
                  Partition Categories (sqft)
                  <Link
                    className="btn btn-sm space-info-btn float-md-right shadow ml-2"
                    to="/reports/facility/total-partition-usage?group=category"
                  >
                    View Details
                  </Link>
                </div>
              </div>
              <div
                className="card-body pb-0 pt-1"
                style={{ minHeight: "250px" }}
              >
                {partitionCategoryStats === null ? (
                  <div
                    className="d-flex justify-content-center align-items-center text-primary"
                    style={{ minHeight: 200 }}
                  >
                    <LoadingComponent />
                  </div>
                ) : (
                  <Fragment>
                    {partitionCategoryStats.errorMessage !== null ? (
                      <div className="text-center text-danger">
                        {partitionCategoryStats.errorMessage}
                      </div>
                    ) : (
                      <Fragment>
                        {partitionCategoryStats.partitionUsage.length === 0 ? (
                          <div className="text-center text-muted">
                            No sector information for selected Incubatee
                          </div>
                        ) : (
                          <Fragment>
                            <ReactApexChart
                              options={partitionCategoryChartOptions}
                              series={partitionCategorySeries}
                              type="pie"
                              height="250"
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <ClusterUsage />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12 col-lg-6">
            <div
              className="card elevate mt-3"
              // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
            >
              <div className="card-header border-bottom-0">
                <div
                  className="card-header-title font-size-lg text-center"
                  style={{ fontWeight: "500" }}
                >
                  Incubatees by Sector
                  <Link
                    className="btn btn-sm space-info-btn float-md-right shadow ml-2"
                    to="/reports/incubatee/info?group=sector"
                  >
                    VIEW DETAILS
                  </Link>
                </div>
              </div>
              <div
                className="card-body pb-0 pt-1"
                style={{ minHeight: "250px" }}
              >
                {incubateeSectorsStats === null ? (
                  <div
                    className="d-flex justify-content-center align-items-center text-primary"
                    style={{ minHeight: 200 }}
                  >
                    <LoadingComponent />
                  </div>
                ) : (
                  <Fragment>
                    {incubateeSectorsStats.errorMessage !== null ? (
                      <div className="text-center text-danger">
                        {incubateeSectorsStats.errorMessage}
                      </div>
                    ) : (
                      <Fragment>
                        {incubateeSectorsStats.incubateeCountBySectors
                          .length === 0 ? (
                          <div className="text-center text-muted">
                            No partition category information.
                          </div>
                        ) : (
                          <ReactApexChart
                            options={incubateesSectorChartOptions}
                            series={series}
                            type="donut"
                            // type="pie"
                            height="250"
                            // methods={() => {
                            //   let w = document.getElementById("container");
                            //   if (w.offsetWidth > 250) w.style.width = "200px";
                            //   else w.style.width = "300px";
                            // }}
                          />
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                <div
                  className="card elevate mt-3"
                  // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
                >
                  <div className="card-header border-bottom-0">
                    <div
                      className="card-header-title font-size-lg text-center"
                      style={{ fontWeight: "500" }}
                    >
                      Occupancy (Rental)
                    </div>
                    <div className="text-center p-2">
                      <Link
                        className="btn btn-sm space-info-btn shadow"
                        to="/reports/facility/fixed-rental-occupancy"
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                  <div
                    className="card-body pb-0 pt-0"
                    style={{ minHeight: 200 }}
                  >
                    {officeOccupancyStats === null ? (
                      <div
                        className="d-flex justify-content-center align-items-center text-primary"
                        style={{ minHeight: 200 }}
                      >
                        <LoadingComponent />
                      </div>
                    ) : (
                      <div className="text-center">
                        {officeOccupancyStats.errorMessage !== null ? (
                          <div className="text-danger">
                            {officeOccupancyStats.errorMessage}
                          </div>
                        ) : (
                          <Fragment>
                            {officeOccupancyStats.totalArea === 0 ? (
                              <div className="text-muted">
                                Office partitions are not setup in the system
                              </div>
                            ) : (
                              <ReactApexChart
                                options={officeOccupancyOptions}
                                series={[leasedOfficeArea || 0]}
                                type="radialBar"
                                height="260"
                              />
                            )}
                          </Fragment>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                <div
                  className="card elevate mt-3"
                  // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
                >
                  <div className="card-header border-bottom-0">
                    <div
                      className="card-header-title font-size-lg text-center"
                      style={{ fontWeight: "500" }}
                    >
                      Occupancy (Seater)
                    </div>
                    <div className="text-center p-2">
                      <Link
                        className="btn btn-sm space-info-btn shadow"
                        to="/reports/facility/seater-occupancy"
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                  <div
                    className="card-body pb-0 pt-0"
                    style={{ minHeight: 200 }}
                  >
                    {occupancyDeskStats === null ? (
                      <div
                        className="d-flex justify-content-center align-items-center text-primary"
                        style={{ minHeight: 200 }}
                      >
                        <LoadingComponent />
                      </div>
                    ) : (
                      <div className="text-center">
                        {occupancyDeskStats.errorMessage !== null ? (
                          <div className="text-danger">
                            {occupancyDeskStats.errorMessage}
                          </div>
                        ) : (
                          <Fragment>
                            {occupancyDeskStats.total === 0 ? (
                              <div className="text-muted">
                                Desk partitions are not setup in the system
                              </div>
                            ) : (
                              <ReactApexChart
                                options={deskVacancyOptions}
                                series={[leasedDesk || 0]}
                                type="radialBar"
                                height="260"
                              />
                            )}
                          </Fragment>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Charts;
