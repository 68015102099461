import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import AutoLookup from "../../custom/AutoLookup";
import { PAYEE_TYPES } from "../../common/LookupConstants";
import { getDisplayDate } from "../../lease/DateUtil";
import HttpUtil from "../../common/HttpUtil";

function getError(touched, error, fieldName) {
  return touched && touched[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touched, error, fieldName) {
  return getError(touched, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touched, error, datePicker } = props;
  const className = datePicker ? "auto-lookup-empty" : "invalid-feedback";

  return (
    <div className={className}>
      {touched && touched[`${fieldName}`] && error && error[`${fieldName}`]}
    </div>
  );
};

function getLookupUrl(entityType) {
  const URL_MAPPER = {
    INDIVIDUAL: {
      url: "api/v1/individuals/lookup/?name=",
      entityName: "name"
    },
    COMPANY: {
      url: "api/v1/organizations/lookup/?name=",
      entityName: "name"
    }
  };

  let entityLookupUrl = null;
  let entityName = null;
  if (entityType) {
    entityName = URL_MAPPER[entityType].entityName;
    entityLookupUrl = URL_MAPPER[entityType].url;
  }

  const lookup = {
    entityLookupUrl,
    entityName
  };

  return lookup;
}

function getAdvanceAmountLookup(entityType, entity) {
  if (!entityType || !entity) {
    return null;
  }

  const MAPPER = {
    INDIVIDUAL: "Individual",
    COMPANY: "Organization"
  };

  const eType = MAPPER[entityType];

  const entityId = entity.value;
  const url = `/api/v1/finance/accounts/advance?entity=${entityId}&entityType=${eType}`;

  return url;
}

const CreateAdjustmentForm = props => {
  const { values, touched, errors, setFieldValue } = props;

  let selectedEntity = null;
  if (values.entity) {
    selectedEntity = {};
    selectedEntity.label = values.entity.label;
    selectedEntity.value = values.entity.value;
  }

  const lookup = getLookupUrl(values.entityType);

  const advanceAmtLookup = getAdvanceAmountLookup(
    values.entityType,
    values.entity
  );

  return (
    <Form>
      <div className="row">
        <div className="col-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title mb-0">
                Advance Adjustment Form
              </span>
            </div>

            <div className="card-body">
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Entity Type
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    component="select"
                    name="entityType"
                    className={`custom-select custom-select-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "entityType"
                    )}`}
                  >
                    <option value="">Select...</option>
                    {PAYEE_TYPES.map((pt, i) => (
                      <option key={i} value={pt.key}>
                        {pt.value}
                      </option>
                    ))}
                  </Field>
                  <ErrorComponent
                    fieldName={`entityType`}
                    touched={touched}
                    error={errors}
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Select Entity
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <AutoLookup
                    className={errors["entity"]}
                    name={lookup.entityName}
                    value={selectedEntity}
                    getObject={entity => {
                      setFieldValue("entity", entity);
                    }}
                    url={lookup.entityLookupUrl}
                  />

                  {errors["entity"] ? (
                    <div className="auto-lookup-empty">{errors["entity"]}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Advance Balance
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <BalanceViewer
                    url={advanceAmtLookup}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <hr />
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Amount
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    type="number"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "amount"
                    )}`}
                    name={`amount`}
                  />

                  <ErrorComponent
                    fieldName={`amount`}
                    touched={touched}
                    error={errors}
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Adjustment date
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <DatePicker
                    value={getDisplayDate(values.date) || ""}
                    selected={values.date}
                    // onChange={date => {
                    //   props.handleDateChange(date, values);
                    // }}
                    utcOffset={0}
                    disabled={true}
                    placeholderText="dd-mm-yyyy"
                    className="form-control form-control-sm"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Adjustment Reason
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "adjustmentReason"
                    )}`}
                    name={`adjustmentReason`}
                  />

                  <ErrorComponent
                    fieldName={`adjustmentReason`}
                    touched={touched}
                    error={errors}
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Notes / Remarks
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    component="textarea"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "notes"
                    )}`}
                    name={`notes`}
                  />

                  <ErrorComponent
                    fieldName={`notes`}
                    touched={touched}
                    error={errors}
                  />
                </div>
              </div>

              <div className="form-group row mt-3 mb-0">
                <div className="col-6 text-right">
                  <button className="btn btn-primary btn-sm" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const AdvanceAdjustmentForm = props => {
  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      validationSchema={props.validationSchema}
      onSubmit={(values, { setSubmitting }) =>
        props.handleSubmit(values, { setSubmitting })
      }
      render={renderProps => (
        <CreateAdjustmentForm key="001" {...props} {...renderProps} />
      )}
    />
  );
};

export default AdvanceAdjustmentForm;

class BalanceViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lookup: null
    };
  }

  loadBalanceInformation = url => {
    //const url = this.state.url;
    HttpUtil.get(
      url,
      {},
      data => {
        //console.log("fetched lookup data:", data);
        let lookup = {};
        if (data) {
          lookup.errorMessage = null;
          lookup.data = data;
          this.props.setFieldValue("advanceAmount", data.balance);
        } else {
          lookup.errorMessage = "No Advance Balance";
          lookup.data = null;
        }

        this.setState({ lookup });
      },
      (data, status) => {
        // do failed stuf
        //console.log("failed  error:", data.message);
        this.setState({
          lookup: {
            errorMessage: data.message,
            data: null
          }
        });
      },
      error => {
        // do error stuf
        // console.log("catch  error:", error.toString());
        this.setState({
          lookup: {
            errorMessage: error.toString(),
            data: null
          }
        });
      }
    );
  };

  componentDidUpdate = prevProps => {
    if (this.props.url !== prevProps.url && this.props.url) {
      this.loadBalanceInformation(this.props.url);
    }
  };

  render() {
    const { lookup } = this.state;
    let balance;

    if (lookup && lookup.errorMessage) {
      balance = lookup.errorMessage;
    }

    if (lookup && lookup.data) {
      balance = lookup.data.balance;
    }

    return (
      <div>
        <input
          type="text"
          className="form-control form-control-sm"
          value={balance || ""}
          readOnly
        />
      </div>
    );
  }
}
