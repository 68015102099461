import React, { Component } from "react";
import { Formik, Form } from "formik";
import UserInformation from "./UserInformation";
import ContactInformation from "./ContactInformation";
import AssetInformation from "./AssetInformation";
import AdditionalInformation from "./AdditionalInformation";
import { getActionCss } from "../../common/Util";
import FinancialInformation from "./FinancialInformation";
import Can from "../../../auth/can";

const FormFooter = props => {
  const { values, setFieldValue, newForm } = props;
  let actions = [];
  if (newForm) {
    actions = ["SAVE", "SUBMIT"];
  } else if (
    values.stage !== "RESERVEAPPR" &&
    values.status !== "APPROVED" &&
    values.stage !== "RESERVEREFUND"
  ) {
    actions = values.actions;
  }
  return (
    <div>
      {actions.length > 0 ? (
        <div className="row m-5">
          <div className="col-12 text-center">
            {actions.map((action, idx) => {
              return (
                <Can key={idx} do="UPDATE" on="AssetReservationRequest">
                  <button
                    className={getActionCss(action)}
                    type="submit"
                    onClick={() => {
                      setFieldValue("action", action);
                    }}
                  >
                    {action}
                  </button>
                  &nbsp;&nbsp;
                </Can>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const CustomForm = props => {
  return (
    <Form>
      <div className="row">
        <div className="col-md-12">
          <div className="card-body">
            {/* User Information */}
            <UserInformation {...props} />

            {/* Contact Information */}
            {props.incubatee ? null : <ContactInformation {...props} />}

            {/* Financial Information */}
            <FinancialInformation {...props} />

            {/* Asset Request Information */}
            <AssetInformation {...props} />

            {/* Additional Information */}
            <AdditionalInformation {...props} />

            <FormFooter {...props} />
          </div>
        </div>
      </div>
    </Form>
  );
};

class AssetReservationRequestForm extends Component {
  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.props.initialValues}
        validationSchema={this.props.validationSchema}
        onSubmit={(values, { setSubmitting }) =>
          this.props.handleSubmit(values, { setSubmitting })
        }
        render={renderProps => <CustomForm {...renderProps} {...this.props} />}
      />
    );
  }
}

export default AssetReservationRequestForm;
