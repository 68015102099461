import React, { Component, Fragment } from "react";
import { changeToINR } from "../../../common/LookupConstants";
import { FieldArray, Field, Form, Formik } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import {
  getDisplayDate,
  convertNumberToDate,
  convertDateToNumber
} from "../../../lease/DateUtil";
import { GRANT_AWARDS_API } from "../../../common/Constants";
import HttpUtil from "../../../common/HttpUtil";
import AlertComponent from "../../../common/AlertComponent";
import {
  ErrorComponent,
  getErrorCssClassName
} from "../../../common/FormikUtil";

// eslint-disable-next-line no-unused-vars
const DisbursedAmounts = props => {
  const { values, errors, touched, setFieldValue } = props;
  const disbursedAmounts = values.disbursedAmounts || [];
  const disbursedAmountsErrors = errors.disbursedAmounts || [];
  const disbursedAmountsTouched = touched.disbursedAmounts || [];
  return (
    <FieldArray
      name={`disbursedAmounts`}
      render={({ remove, push }) => {
        return (
          <Fragment>
            <div className="col-12 col-md-12">
              <strong className="border-bottom pb-1 d-block mb-2">
                Disbursed Amounts
              </strong>
              {disbursedAmounts.length > 0 ? (
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th className="display-table-header" scope="col">
                        Notes
                      </th>
                      <th
                        className="display-table-header text-right"
                        scope="col"
                      >
                        Amount
                      </th>
                      <th
                        className="display-table-header text-center"
                        scope="col"
                      >
                        Date
                      </th>
                      <th className="display-table-header" scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {disbursedAmounts.map((damt, index) => {
                      const error = disbursedAmountsErrors[index] || {};
                      const touch = disbursedAmountsTouched[index] || {};

                      return (
                        <tr key={index}>
                          <td className="align-middle">
                            <div>
                              <Field
                                component="textarea"
                                type="text"
                                className={`form-control form-control-sm ${
                                  touch["notes"] && error["notes"]
                                    ? "is-invalid"
                                    : ""
                                }`}
                                title="Enter Amount"
                                name={`disbursedAmounts.${index}.notes`}
                                value={damt.notes || ""}
                                placeholder="Enter notes"
                              />
                              <div className="invalid-feedback">
                                {touch["notes"] && error["notes"]}
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <div>
                              <Field
                                type="number"
                                className={`form-control form-control-sm text-right${
                                  touch["amount"] && error["amount"]
                                    ? "is-invalid"
                                    : ""
                                }`}
                                title="Enter Amount"
                                name={`disbursedAmounts.${index}.amount`}
                                value={damt.amount || ""}
                                placeholder="Enter amount"
                              />
                              <div className="invalid-feedback">
                                {touch["amount"] && error["amount"]}
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <DatePicker
                              value={damt.date ? getDisplayDate(damt.date) : ""}
                              selected={damt.date || null}
                              onChange={e => {
                                setFieldValue(
                                  `disbursedAmounts.${index}.date`,
                                  e
                                );
                              }}
                              utcOffset={0}
                              placeholderText="dd-mm-yyyy"
                              className={`form-control form-control-sm text-center${
                                touch["date"] && error["date"]
                                  ? "is-invalid"
                                  : ""
                              }`}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                            <div className="auto-lookup-empty">
                              {touch["date"] && error["date"]}
                            </div>
                          </td>
                          <td className="align-middle text-center">
                            <i
                              className="far fa-trash-alt text-danger"
                              title="Remove"
                              onClick={() => remove(index)}
                              style={{
                                cursor: "pointer"
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn btn-sm btn-outline-info custom-btn"
                    type="button"
                    onClick={() =>
                      push({
                        notes: "",
                        amount: "",
                        date: null
                      })
                    }
                  >
                    <i className="fas fa-plus mr-2" />
                    Add More
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    />
  );
};

const CustomForm = props => {
  const { values, setFieldValue, touched, errors } = props;
  return (
    <Form>
      <div className="form-group row mb-2 mt-2">
        <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 pr-0">
          Disbursed Date
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <DatePicker
            value={values.date ? getDisplayDate(values.date) : ""}
            selected={values.date || null}
            onChange={e => {
              setFieldValue(`date`, e);
            }}
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "date"
            )}`}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <ErrorComponent
            fieldName={`date`}
            touched={touched}
            error={errors}
            datePicker
          />
        </div>
      </div>
      <div className="form-group row mb-2 mt-2">
        <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 pr-0">
          Amount
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <Field
            type="number"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "amount"
            )}`}
            name="amount"
          />
          <ErrorComponent
            fieldName={`amount`}
            touched={touched}
            error={errors}
          />
        </div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4">
          Notes
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <Field
            component="textarea"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "notes"
            )}`}
            name="notes"
            rows="6"
          />
          <ErrorComponent
            fieldName={`notes`}
            touched={touched}
            error={errors}
          />
        </div>
      </div>
      <div className="form-group row mb-2">
        <div className="col-12 text-right">
          <button
            className="btn btn-sm btn-outline-secondary custom-btn mr-3"
            type="button"
            onClick={props.toggleEdit}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-outline-info custom-btn"
            type="submit"
          >
            Update
          </button>
        </div>
      </div>
    </Form>
  );
};

const DISBURSED_AMOUNTS_SCHEMA = Yup.object().shape({
  pendingDisbursement: Yup.number(),
  notes: Yup.string()
    .label("Disbursed Notes")
    .required(),
  amount: Yup.number()
    .label("Disbursed Amount")
    .required()
    .max(
      Yup.ref("pendingDisbursement"),
      "Total Disbursed Amount should be less than or equal to Sanctioned Amount"
    ),
  date: Yup.date()
    .label("Disbursed Date")
    .required()
});

class TransactionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditForm: false,
      showViewForm: false,

      award: null,
      disbursedAmountReport: {
        total: 0,
        disbursedAmounts: []
      },
      sanctionedAmountReport: {
        total: 0,
        sanctionedAmounts: []
      },
      initialValues: {
        pendingDisbursement: 0,
        notes: "",
        amount: "",
        date: ""
      }
    };
  }

  toggleEdit = () => {
    this.setState({
      showEditForm: !this.state.showEditForm,
      showViewForm: false
    });
  };

  toggleView = () => {
    this.setState({
      showViewForm: !this.state.showViewForm,
      showEditForm: false
    });
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      alertFor: "",
      alertWithTitle: ""
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      alertFor: "",
      alertWithTitle: ""
    });
  };

  handleAlertFor = () => {
    const values = this.state.values;
    const setSubmitting = this.state.setSubmitting;
    this.handleSubmit(values, { setSubmitting });
  };

  setDataFromServer = award => {
    const reviews = award.reviews || [];

    let sAmtTotal = 0;
    const sanctionedAmounts = reviews.map(revw => {
      const review = {};
      review._id = revw._id;
      review.title = revw.title;
      let reviewDate = revw.reviewDate;
      reviewDate = convertNumberToDate(reviewDate);
      reviewDate = getDisplayDate(reviewDate);
      review.reviewDate = reviewDate;
      review.amountSanctioned = revw.amountSanctioned;
      review.status = revw.status;
      sAmtTotal += revw.amountSanctioned;
      return review;
    });

    const sanctionedAmountReport = {
      total: sAmtTotal,
      sanctionedAmounts
    };

    const dsAmts = award.disbursedAmounts || [];
    let dsAmtTotal = 0;
    const disbursedAmounts = dsAmts.map(dsAmt => {
      let date = convertNumberToDate(dsAmt.date);
      date = getDisplayDate(date);
      const disbursedAmount = {
        ...dsAmt,
        date
      };
      dsAmtTotal += dsAmt.amount;
      return disbursedAmount;
    });

    const disbursedAmountReport = {
      total: dsAmtTotal,
      disbursedAmounts
    };

    this.setState({
      award,
      disbursedAmountReport,
      sanctionedAmountReport,
      initialValues: {
        pendingDisbursement:
          award.sanctionedAmount - disbursedAmountReport.total,
        notes: "",
        amount: "",
        date: ""
      },
      showEditForm: false,
      showViewForm: false
    });
  };

  handleSubmit = (values, { setSubmitting }) => {
    // console.log("submit:values:", JSON.stringify(values, null, 2));

    if (!this.state.alertFor) {
      return this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "primary",
        alertMessage: `Confirm update Rs.${values.amount} ?`,
        alertFor: 101, // update = 101
        alertWithTitle: "Disbursed Amount",
        values: values,
        setSubmitting: setSubmitting
      });
    }

    setSubmitting(true);

    const id = this.state.award._id;
    const url = `${GRANT_AWARDS_API}/${id}/update-disbursed-amounts`;
    const headers = { "Content-Type": "application/json" };

    const request = {};
    request.amount = values.amount;
    request.notes = values.notes;

    let date = new Date(values.date);
    date = convertDateToNumber(date);
    request.date = date;

    HttpUtil.put(
      url,
      headers,
      request,
      data => {
        setSubmitting(false);
        this.setDataFromServer(data);
        this.closeDefaultAlert();
      },

      data => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  componentDidMount() {
    const award = this.props.award;
    this.setDataFromServer(award);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.award !== this.props.award) {
      this.setDataFromServer(this.props.award);
    }
  }

  render() {
    const {
      sanctionedAmountReport,
      disbursedAmountReport,
      showEditForm,
      showViewForm
    } = this.state;

    const formikProps = {
      initialValues: this.state.initialValues,
      handleSubmit: this.handleSubmit,
      validationSchema: DISBURSED_AMOUNTS_SCHEMA,
      toggleEdit: this.toggleEdit,
      toggleView: this.toggleView
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.handleAlertFor,
      alertWithTitle: this.state.alertWithTitle
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row mb-3 mt-5">
          <div className="col-6">
            <div className="d-flex justify-content-between  edit-page-section-header-text d-block mb-2">
              Approved Amount
            </div>
            <div className="">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th className="display-table-header" scope="col">
                      #
                    </th>
                    <th className="display-table-header" scope="col">
                      Review Date
                    </th>
                    <th className="display-table-header text-right" scope="col">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sanctionedAmountReport.sanctionedAmounts.map(
                    (sAmt, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{sAmt.reviewDate}</td>
                          <td className="text-right">
                            {changeToINR(sAmt.amountSanctioned || 0)}{" "}
                          </td>
                        </tr>
                      );
                    }
                  )}

                  <tr>
                    <th scope="row" />
                    <td className="font-weight-bold text-right">Total</td>
                    <td className="font-weight-bold text-right">
                      {changeToINR(sanctionedAmountReport.total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-between edit-page-section-header-text d-block mb-2">
              {/* <span className="card-title">Disbursed Amount</span> */}
              Disbursed Amounts
              <div>
                <span
                  className="text-info mr-2"
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleView}
                >
                  View
                </span>

                {this.props.award.status === "ACTIVE" ? (
                  <span
                    className="text-info"
                    style={{ cursor: "pointer" }}
                    onClick={this.toggleEdit}
                  >
                    Add
                  </span>
                ) : null}
              </div>
            </div>

            <div className="">
              <ul className="list-group list-group-flush">
                {showEditForm ? (
                  <li className="list-group-item  border-0">
                    <Formik
                      enableReinitialize
                      initialValues={formikProps.initialValues}
                      validationSchema={formikProps.validationSchema}
                      onSubmit={(values, { setSubmitting }) =>
                        formikProps.handleSubmit(values, { setSubmitting })
                      }
                      render={renderProps => (
                        <CustomForm {...renderProps} {...formikProps} />
                      )}
                    />
                  </li>
                ) : null}

                {showViewForm ? (
                  <Fragment>
                    {disbursedAmountReport.disbursedAmounts.map(
                      (dsAmt, index) => {
                        return (
                          <li key={index} className="list-group-item border-0">
                            <div className="w-100">
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="">
                                  {changeToINR(dsAmt.amount || 0)}
                                </span>
                                <span className="">{dsAmt.date}</span>
                              </div>
                              <div className="w-75">
                                <small>{dsAmt.notes}</small>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </Fragment>
                ) : null}
              </ul>

              {!showEditForm && !showViewForm ? (
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th className="display-table-header" scope="col">
                        #
                      </th>
                      <th className="display-table-header" scope="col">
                        Disbursed Date
                      </th>
                      <th
                        className="display-table-header text-right"
                        scope="col"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {disbursedAmountReport.disbursedAmounts.map(
                      (dsAmt, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{dsAmt.date}</td>
                            <td className="text-right">
                              {changeToINR(dsAmt.amount || 0)}{" "}
                            </td>
                          </tr>
                        );
                      }
                    )}

                    <tr>
                      <th scope="row" />
                      <td className="font-weight-bold text-right">Total</td>
                      <td className="font-weight-bold text-right">
                        {changeToINR(disbursedAmountReport.total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TransactionReport;
