import React, { Fragment } from "react";
import { Field } from "formik";
import ShowHideComponent from "./ShowHideComponent";

const Emails = ({ values, touched, errors, push, remove, readOnly }) => {
  const emailErrors = errors.emails || [];
  const emailTouched = touched.emails || [];
  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <Fragment>
      {values.emails.map((email, index) => {
        const error = emailErrors[index] || {};
        const touch = emailTouched[index] || {};

        const hideRemoveButton = index === 0;
        return (
          <div key={index} className="row mb-1">
            <div className="col-6 pr-0">
              <div className="input-group">
                <Field
                  type="text"
                  className={`form-control form-control-sm w-25 ${
                    touch["email"] && error["email"] ? "is-invalid" : ""
                  }`}
                  name={`emails.${index}.email`}
                  placeholder="Email ID"
                  {...disabledField}
                />
                <div className="invalid-feedback">
                  {touch["email"] && error["email"]}
                </div>
              </div>
            </div>
            <div className="col-5 pl-2 pr-0">
              <div className="input-group">
                <Field
                  component="select"
                  name={`emails.${index}.type`}
                  className={`form-control form-control-sm w-25 ${
                    touch["type"] && error["type"] ? "is-invalid" : ""
                  }`}
                  {...disabledField}
                >
                  <option value="">Select...</option>
                  <option value="WORK">Work</option>
                  <option value="PERSONAL">Personal</option>
                  <option value="OFFICE">Office</option>
                  <option value="OTHER">Other</option>
                </Field>
                <div className="invalid-feedback">
                  {touch["type"] && error["type"]}
                </div>
              </div>
            </div>
            <div className="col-1 pl-2">
              <ShowHideComponent show={!readOnly}>
                <button
                  type="button"
                  className={`btn btn-outline-danger btn-sm ${
                    hideRemoveButton ? "d-none" : ""
                  }`}
                  title="Remove"
                  onClick={() => remove(index)}
                >
                  <i className="far fa-trash-alt " />
                </button>
              </ShowHideComponent>
            </div>
          </div>
        );
      })}
      <ShowHideComponent show={!readOnly}>
        <div className="row">
          <div className="col-md-2">
            <button
              className="btn btn-info btn-sm mb-1"
              title="Add"
              type="button"
              onClick={() =>
                push({ type: "WORK", email: "", status: "ACTIVE" })
              }
            >
              Add
            </button>
          </div>
        </div>
      </ShowHideComponent>
    </Fragment>
  );
};

export default Emails;
