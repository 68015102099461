import React, { Component, Fragment } from "react";

class IncubatorAppForm extends Component {
  getData = data => {
    return data ? data : "--";
  };
  render() {
    const { form } = this.props;

    return (
      <Fragment>
        {/* Application Information */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Application Information</h6>
              </div>
              <div className="row pl-1">
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>{`${form.source} ID`}</th>
                        <td>{form.sourceId}</td>
                      </tr>
                      <tr>
                        <th>{`${form.source} Reference`}</th>
                        <td>{form.sourceReference}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>{`${form.source} Status`}</th>
                        <td>{form.sourceStatus}</td>
                      </tr>
                      <tr>
                        <th>Application Status</th>
                        <td>{form.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Organization */}
        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Organization Information</h6>
              </div>
              <div className="row pl-2">
                <div className="col-6">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name of the Organization </strong>
                        </td>
                        <td>{this.getData(form.name)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Company Domain</strong>
                        </td>
                        <td>{this.getData(form.companyDomain)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email</strong>
                        </td>
                        <td>{this.getData(form.email)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Phone</strong>
                        </td>
                        <td>{this.getData(form.phone)}</td>
                      </tr>
                      <tr>
                        <th>
                          <strong>Fax</strong>
                        </th>
                        <td>{this.getData(form.fax)}</td>
                      </tr>
                      <tr>
                        <th>
                          <strong>Website</strong>
                        </th>
                        <td>{this.getData(form.website)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Year of Establishment</strong>
                        </td>
                        <td>{this.getData(form.estYear)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Legal Status</strong>
                        </td>
                        <td>{this.getData(form.legalStatus)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Is The Organization a Subsidiary unit</strong>
                        </td>
                        <td>
                          {form.subsidiary ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            If Yes, provide the Address Of Parent Company
                          </strong>
                        </td>
                        <td>{this.getData(form.parentCompany)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Number Of Promoters</strong>
                        </td>
                        <td>{this.getData(form.numberOfPromoters)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Registered Address</strong>
                        </td>
                        <td>{this.getData(form.registeredAddress)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Reference</strong>
                        </td>
                        <td>{this.getData(form.reference)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Promoters  */}
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="card-group">
              {form.promoters.length > 0
                ? form.promoters.map((promoter, idx) => {
                    return (
                      <div className="card" key={idx}>
                        <div className="card-header bg-card-header text-white ">
                          <h6 className="card-titile mb-0">
                            Promoter {idx + 1}
                          </h6>
                        </div>
                        <div className="row pl-1">
                          <div className="col-12">
                            <table className="table table-sm table-borderless table-striped">
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <td>{this.getData(promoter.name)}</td>
                                </tr>
                                <tr>
                                  <th>Education</th>
                                  <td>{this.getData(promoter.education)}</td>
                                </tr>
                                <tr>
                                  <th>Designation</th>
                                  <td>{this.getData(promoter.designation)}</td>
                                </tr>
                                <tr>
                                  <th>Phone</th>
                                  <td>{this.getData(promoter.phone)}</td>
                                </tr>
                                <tr>
                                  <th>Mobile</th>
                                  <td>{this.getData(promoter.mobile)}</td>
                                </tr>

                                <tr>
                                  <th>Fax</th>
                                  <td>{this.getData(promoter.fax)}</td>
                                </tr>

                                <tr>
                                  <th>Email</th>
                                  <td>{this.getData(promoter.email)}</td>
                                </tr>
                                <tr>
                                  <th>Aadhaar </th>
                                  <td>{this.getData(promoter.aadhaar)}</td>
                                </tr>
                                <tr>
                                  <th>Professional Experience </th>
                                  <td>{this.getData(promoter.experience)}</td>
                                </tr>
                                <tr>
                                  <th>Permanent Address </th>
                                  <td>
                                    {this.getData(promoter.permanentAddress)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Communication Address </th>
                                  <td>
                                    {this.getData(
                                      promoter.communicationAddress
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    Alumni of PSG / Branch & Year Of passing
                                  </th>
                                  <td>{this.getData(promoter.alumniInfo)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>

        {/* Declaration Information */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Declaration Information</h6>
              </div>
              <div className="row pl-1">
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>Declaration Date</th>
                        <td>{this.getData(form.declarationDate)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>Declaration Place</th>
                        <td>{this.getData(form.declarationPlace)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default IncubatorAppForm;
