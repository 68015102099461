import React from "react";
import { Switch, Route } from "react-router-dom";
import AssetList from "./assets/AssetList";
import Asset from "./assets/Asset";
import EnquiryFormList from "./incubator-enquiry-form/EnquiryFormList";
import IncubatorEnquiry from "./incubator-enquiry-form/IncubatorEnquiry";
import DuplicateCheck from "./assetReservationRequest/DuplicateCheck";
import AssetReservationRequest from "./assetReservationRequest/AssetReservationRequest";
import MatchedUserProfile from "./assetReservationRequest/MatchedUserProfile";
import ClosedEnquiryList from "./incubator-enquiry-form/ClosedEnquiryList";
import OpenEnquiryList from "./incubator-enquiry-form/OpenEnquiryList";
import AssetAvailabilitySearch from "./assetReservationRequest/search/AssetAvailabiltySearch";
import OpenReservation from "./assetReservationRequest/OpenReservation";
import ClosedReservation from "./assetReservationRequest/ClosedReservation";
import ActiveReservations from "./assetReservationRequest/ActiveReservations";
import ReservationTracker from "./assetReservationRequest/reservationSearch/ReservationTracker";
import UnauthorizedPage from "../common/unauthorized.page";
import Can from "../../auth/can";
import AwaitingPayments from "./assetReservationRequest/AwaitingPayments";

function componentWithAuthorization(component, subject, action) {
  return (
    <>
      <Can do={action} on={subject}>
        {component}
      </Can>
      <Can not do={action} on={subject}>
        <UnauthorizedPage />
      </Can>
    </>
  );
}

const ROUTES = [
  {
    path: "/service-mgmt/assets",
    component: AssetList,
    action: "VIEW",
    subject: "Asset"
  },
  {
    path: "/service-mgmt/assets/new",
    component: Asset,
    action: "CREATE",
    subject: "Asset"
  },
  {
    path: "/service-mgmt/assets/edit/:id",
    component: Asset,
    action: "VIEW",
    subject: "Asset",
    passProps: true
  },
  {
    path: "/service-mgmt/user-info",
    component: DuplicateCheck,
    action: "CREATE",
    subject: "AssetReservationRequest",
    passProps: true
  },
  {
    path: "/service-mgmt/matched-users",
    component: MatchedUserProfile,
    action: "CREATE",
    subject: "AssetReservationRequest",
    passProps: true
  },
  {
    path: "/service-mgmt/asset-reservation-requests/new",
    component: AssetReservationRequest,
    action: "CREATE",
    subject: "AssetReservationRequest",
    passProps: true
  },
  {
    path: "/service-mgmt/asset-reservation-requests/edit/:id",
    component: AssetReservationRequest,
    action: "VIEW",
    subject: "AssetReservationRequest",
    passProps: true
  },
  {
    path: "/service-mgmt/open-reservations",
    component: OpenReservation,
    action: "VIEW",
    subject: "AssetReservationRequest"
  },
  {
    path: "/service-mgmt/closed-reservations",
    component: ClosedReservation,
    action: "VIEW",
    subject: "AssetReservationRequest"
  },

  {
    path: "/service-mgmt/awaiting-payment",
    component: AwaitingPayments,
    action: "VIEW",
    subject: "AssetReservationRequest"
  },

  {
    path: "/service-mgmt/active-reservations",
    component: ActiveReservations,
    action: "VIEW",
    subject: "AssetReservationRequest"
  },

  {
    path: "/service-mgmt/enquiries",
    component: EnquiryFormList,
    action: "VIEW",
    subject: "IncubatorEnquiry"
  },
  {
    path: "/service-mgmt/enquiries/view/:id",
    component: IncubatorEnquiry,
    action: "VIEW",
    subject: "IncubatorEnquiry",
    passProps: true
  },
  {
    path: "/service-mgmt/open-enquiries",
    component: OpenEnquiryList,
    action: "VIEW",
    subject: "IncubatorEnquiry"
  },
  {
    path: "/service-mgmt/closed-enquiries",
    component: ClosedEnquiryList,
    action: "VIEW",
    subject: "IncubatorEnquiry"
  },
  {
    path: "/service-mgmt/asset-availabilty",
    component: AssetAvailabilitySearch,
    action: "VIEW",
    subject: "AssetReservationRequest",
    passProps: true
  },
  {
    path: "/service-mgmt/asset-reservation-daily-summary",
    component: ReservationTracker,
    action: "VIEW",
    subject: "AssetReservationRequest"
  }
];

const ServiceMgmtRoutes = () => {
  return (
    <Switch>
      {ROUTES.map((route, index) => {
        const { path, passProps, action, subject } = route;
        const key = `service-mgmt-${index}`;
        const Comp = route.component;

        let routeComponent;
        if (action && subject) {
          routeComponent = passProps
            ? props =>
                componentWithAuthorization(<Comp {...props} />, subject, action)
            : () => componentWithAuthorization(<Comp />, subject, action);
          return (
            <Route exact key={key} path={path} component={routeComponent} />
          );
        }

        routeComponent = passProps ? props => <Comp {...props} /> : Comp;
        return <Route exact key={key} path={path} component={routeComponent} />;
      })}
    </Switch>
  );
};

export default ServiceMgmtRoutes;
