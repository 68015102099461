import React, { Component, Fragment } from "react";
import { PAYMENTS_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import PaymentPreviewForm from "./PaymentPreviewForm";

class ViewPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewForm: null
    };
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getPayment(id);
    }
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  addData = (payment, labelName, value) => {
    if (value) {
      payment.push({
        label: labelName,
        value
      });
    }
  };

  nameFormatter = data => {
    let name = "";
    let names = [];
    if (data.entity.firstName) {
      names.push(data.entity.firstName);
    }
    if (data.entity.lastName) {
      names.push(data.entity.lastName);
    }
    name = names.length > 0 ? names.join(" ") : data.entity.name;
    return name;
  };

  handleData = data => {
    let alertType,
      showAlert,
      alertColor,
      alertMessage = null;
    if (this.props.location.state !== undefined) {
      alertType = "Default";
      showAlert = true;
      alertColor = "success";
      alertMessage = "Payment has been submitted successfully.";
    }

    const previewForm = { ...data };
    previewForm.entityName = this.nameFormatter(data);
    previewForm.paymentTowards = data.paymentTowards || [];

    this.setState({
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      previewForm
    });
  };

  getPayment = id => {
    const url = `${PAYMENTS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.handleData(data);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  render() {
    const {
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      previewForm
    } = this.state;

    if (previewForm === null) {
      return null;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        <PaymentPreviewForm
          data={this.state.previewForm}
          title="Payment information"
        />
      </Fragment>
    );
  }
}

export default ViewPayment;
