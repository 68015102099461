import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import HttpUtil from "../../common/HttpUtil";
import { INDIVIDUALS_API, INCUBATEES_API } from "../../common/Constants";
import AlertComponent from "../../common/AlertComponent";
import { Addresses } from "../../widgets/Addresses";
import { IdentificationDocuments } from "../../widgets/IdentificationDocuments";
import { ContactInformation } from "../../individual/EditIndividualForm";
import {
  BasicInfo,
  Promoters,
  FinancialInfo,
  ORG_ID_TYPES
} from "../../organization/EditOrganizationForm";

class IncubateeConversion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // For input validations
      validate: false,
      toBeValidated: [],
      hasError: false,
      update: false,

      // For All Component
      _id: null,
      basicInfo: null,
      phones: [],
      addresses: [],

      previousLocation: "",
      incProfUrl: "",
      currentLocation: ""
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  setDataFromServer = data => {
    const promoters = [
      {
        designation: null,
        shareholding: null,
        isFounder: false,
        individual: data
      }
    ];

    // back to navigate
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const from = params.get("from");
    const incProfUrl = params.get("incProfUrl");
    const incubatee = params.get("incubatee");
    const currentLocation = this.props.location.pathname;

    let previousLocation = `${from}${incubatee}`;

    this.setState({
      promoters,
      previousLocation,
      incubatee,
      currentLocation,
      incProfUrl
    });
  };

  getIndividualIncubatee = id => {
    const url = `${INDIVIDUALS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setDataFromServer(data);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  // if the set state is called directly,
  // then toBeValidated Array data gets overwritten
  // Passing function queues up the set state
  // and they are called in the same order they are called.
  // https://medium.freecodecamp.org/functional-setstate-is-the-future-of-react-374f30401b6b

  setStateValidateResult = (childName, childInvalid, data) => {
    this.setState(state => {
      const toBeValidated = state.toBeValidated.filter(
        item => item !== childName
      );
      const validationComplete = toBeValidated.length === 0;

      const hasError = state.hasError || childInvalid;
      const update = !hasError && validationComplete;

      let errorState = {};
      if (validationComplete && hasError) {
        errorState = {
          alertType: "Default",
          showAlert: true,
          alertColor: "danger",
          alertMessage: "Please correct the errors and submit again"
        };
        window.scrollTo(0, 0);
      }

      return {
        validate: !validationComplete,
        hasError,
        toBeValidated,
        update,
        [childName]: data,
        ...errorState
      };
    });
  };

  onValidate = (error, childName, data) => {
    if (error === null) {
      this.setStateValidateResult(childName, false, data);
    } else {
      this.setStateValidateResult(childName, true, null);
      window.scrollTo(0, 0);
    }
  };

  handleUpdate = () => {
    this.setState({
      validate: true,
      hasError: false,
      toBeValidated: [
        "basicInfo",
        "phones",
        "emails",
        "addresses",
        "idDocs",
        "promoters",
        "financials"
      ]
    });
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getFormData = () => {
    let formData = new FormData();
    const basicInfo = this.state.basicInfo;

    this.addDocs(formData, "companyProfile", basicInfo.companyProfileUpload);
    this.addDocs(formData, "regCertificate", basicInfo.regCertificateUpload);
    this.addDocs(
      formData,
      "articlesOfAssociation",
      basicInfo.articlesOfAssociationUpload
    );
    const identificationDocs = this.state.idDocs.map(id => {
      this.addDocs(formData, `id_doc_${id.type}`, id.scannedId);
      return {
        type: id.type,
        number: id.number,
        status: id.status
      };
    });

    const addresses = this.state.addresses.map(ad => {
      this.addDocs(formData, `address_doc_${ad.type}`, ad.addressProofUpload);
      return {
        _id: ad._id,
        streetLine1: ad.streetLine1,
        streetLine2: ad.streetLine2,
        landmark: ad.landmark,
        city: ad.city,
        state: ad.state,
        postalCode: ad.postalCode,
        country: ad.country,
        status: ad.status,
        type: ad.type
      };
    });

    const organizationForm = {
      name: basicInfo.name,
      legalStatus: basicInfo.legalStatus,
      estYear: basicInfo.estYear,
      website: basicInfo.website,
      subsidiary: basicInfo.subsidiary,
      parentCompany: basicInfo.parentCompany,
      reference: basicInfo.reference,
      bizSummary: basicInfo.bizSummary,
      services: basicInfo.services,
      domainOperation: basicInfo.domainOperation,
      phones: this.state.phones,
      emails: this.state.emails,
      addresses,
      identificationDocs: identificationDocs,
      promoters: this.state.promoters,
      financials: this.state.financials
    };

    formData.append("message", JSON.stringify(organizationForm));
    return formData;
  };

  callUpdateApi = () => {
    const updateRequest = this.getFormData();
    const url = `${INCUBATEES_API}/${this.state.incubatee}/convert/indv-org`;

    HttpUtil.post(
      url,
      {},
      updateRequest,
      data => {
        this.setState({ redirectProfilePage: true });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );

    this.setState({ update: false });
  };

  componentDidUpdate = prevState => {
    if (this.state.update !== prevState.update && this.state.update) {
      this.callUpdateApi();
    }
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getIndividualIncubatee(id);
    }
  }

  render() {
    if (this.state.redirectProfilePage) {
      const url = this.state.previousLocation;
      return <Redirect to={url} />;
    }

    const props = {
      onValidate: this.onValidate,
      validate: this.state.validate,

      basicInfo: this.state.basicInfo,
      phones: this.state.phones,
      emails: this.state.emails,
      addresses: this.state.addresses,
      idDocs: this.state.idDocs,
      promoters: this.state.promoters,
      financials: this.state.financials,
      previousLocation: this.state.previousLocation,
      incProfUrl: this.state.incProfUrl,
      currentLocation: this.state.currentLocation
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <BasicInfo {...props} />
        <ContactInformation {...props} />
        <Addresses {...props} />
        <IdentificationDocuments idTypes={ORG_ID_TYPES} {...props} />
        <div className="row mt-4 mb-5">
          <Promoters {...props} hideIndividualLink={true} />
          <FinancialInfo {...props} />
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-12 text-center">
            <button
              className="btn btn-sm btn-primary"
              onClick={this.handleUpdate}
            >
              Save
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default IncubateeConversion;
