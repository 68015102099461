import React, { Fragment } from "react";
import {
  changeToINR,
  PAYMENT_STATUS_LIST_OBJECT,
  PAYMENT_TYPES_OBJECT
} from "../../common/LookupConstants";
import CompactTable from "../../widgets/CompactTable";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";

function entityNameFormater(cell, row) {
  let names = [];
  let entity = row.entity;
  if (entity.firstName) {
    names.push(entity.firstName);
  }

  if (entity.lastName) {
    names.push(entity.lastName);
  }

  if (entity.name) {
    names.push(entity.name);
  }

  const name = names.join(" ");
  return name;
}

function dateFormater(yyyymmdd) {
  return getDisplayDate(convertNumberToDate(yyyymmdd));
}

const columnsPayments = [
  {
    dataField: "entity",
    text: "Customer Name",
    sort: true,
    formatter: entityNameFormater,
    filterValue: entityNameFormater,
    headerStyle: {
      width: "30%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "entityType",
    text: "Customer Type",
    sort: true,
    headerStyle: {
      width: "15%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "paymentType",
    text: "Payment Type",
    sort: true,
    classes: "text-center",
    formatter: cell => PAYMENT_TYPES_OBJECT[cell],
    headerStyle: {
      width: "30%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "paymentDate",
    text: "Payment Date",
    formatter: dateFormater,
    csvFormatter: dateFormater,
    sort: true,
    headerStyle: {
      width: "15%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "amount",
    text: "Amount",
    formatter: changeToINR,
    classes: "text-right",
    sort: true,
    headerStyle: {
      width: "10%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "text-center",
    formatter: cell => PAYMENT_STATUS_LIST_OBJECT[cell],
    headerStyle: {
      width: "10%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  }
];

const PaymentSearchResult = props => {
  let payments = [];
  if (props.searchResults && props.searchResults.payments) {
    payments = props.searchResults.payments;
  }

  return (
    <Fragment>
      <h6 className="card-title mb-2 edit-page-section-header-text">
        Payments
      </h6>
      <CompactTable
        data={payments}
        columns={columnsPayments}
        noDataIndication={"No Payments Available"}
      />
    </Fragment>
  );
};

export default PaymentSearchResult;
