import React, { Component, Fragment } from "react";
import BootsrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Redirect } from "react-router-dom";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";
import { INDIVIDUALS_API } from "../common/Constants";

class IndividualList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //for pagination
      page: 1,
      sizePerPage: 10,
      totalSize: null,
      individualList: [],

      newIndividualProfile: null,
      editIndividualProfile: null,
      viewIndividualProfile: null,
      individualId: null
    };
  }

  componentDidMount() {
    this.getAll();
  }

  getAll = () => {
    const url = INDIVIDUALS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          individualList: data,
          totalSize: data.length
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  actionsFormater = (cell, row) => {
    let viewButton = (
      <Fragment>
        <span
          role="button"
          className="badge  custom-pill badge-info"
          onClick={this.viewIndividualProfile.bind(this, row._id)}
        >
          View
        </span>
        &nbsp;&nbsp;
        <span
          role="button"
          className="badge  custom-pill badge-info"
          onClick={this.editIndividualProfile.bind(this, row._id)}
        >
          Edit
        </span>
      </Fragment>
    );
    return viewButton;
  };

  editIndividualProfile = indvId => {
    this.setState({
      editIndividualProfile: true,
      individualId: indvId
    });
  };

  viewIndividualProfile = indvId => {
    this.setState({
      viewIndividualProfile: true,
      individualId: indvId
    });
  };

  createNewIndividualProifle = () => {
    this.setState({
      newIndividualProfile: true
    });
  };
  render() {
    const {
      individualList,
      sizePerPage,
      page,
      totalSize,

      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,

      newIndividualProfile,
      editIndividualProfile,
      viewIndividualProfile,
      individualId
    } = this.state;

    if (!totalSize) {
      return null;
    }

    if (newIndividualProfile) {
      const url = "/admin/individuals/new";
      return <Redirect to={url} />;
    }

    if (editIndividualProfile) {
      const url = "/admin/individuals/edit/" + individualId;
      return <Redirect to={url} />;
    }

    if (viewIndividualProfile) {
      const url = "/admin/individuals/view/" + individualId;
      return <Redirect to={url} />;
    }

    const columnsIndividual = [
      {
        dataField: "firstName",
        text: "Name",
        sort: true
      },
      {
        dataField: "gender",
        // formatter: this.partitionFormatter,
        text: "Gender"
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.actionsFormater
      }
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="card">
              <div className="card-header bg-card-header text-white">
                <span className="card-title mb-0">
                  <i
                    className="fas fa-align-justify"
                    style={{ fontSize: "1.1em", cursor: "pointer" }}
                  />
                  &nbsp;&nbsp; Individual List
                </span>
                &nbsp;&nbsp;
                <span
                  role="button"
                  className="badge bg-white custom-btn-sm"
                  onClick={this.createNewIndividualProifle}
                >
                  New
                </span>
              </div>
              <div className="card-body onboard-table">
                <ToolkitProvider
                  keyField="_id"
                  data={individualList}
                  columns={columnsIndividual}
                  bootstrap4={true}
                  search
                >
                  {props => (
                    <Fragment>
                      <BootsrapTable
                        bootstrap4
                        condensed
                        keyField={"_id"}
                        data={individualList}
                        columns={columnsIndividual}
                        // for pagination
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize
                        })}
                        //for search
                        {...props.baseProps}
                        //for custom css
                        className="react-bootstrap-table table .react-bootstrap-table td > .selection-input-4, .react-bootstrap-table th > .selection-input-4"
                        noDataIndication="No Data found"
                      />
                    </Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default IndividualList;
