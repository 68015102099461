import React, { Fragment } from "react";
import { Field, ErrorMessage } from "formik";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const ContactInformation = props => {
  const { values, touched, errors, readOnly } = props;

  const contactInformation = values.contactInformation || {};

  const touch = touched.contactInformation || {};
  const error = errors.contactInformation || {};

  // For Existing user
  let disabledExistingUser = {};
  if (!values.newUser || readOnly) {
    disabledExistingUser = {
      readOnly: "readOnly",
      disabled: "disabled"
    };
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 mb-3 mt-3 edit-page-section-header-text">
          Contact Information
        </div>
      </div>

      <div className="form-group row mb-1">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Mobile
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            inputMode="numeric"
            name="contactInformation.mobile"
            value={contactInformation.mobile || ""}
            className={getErrorCssClassName(touch, error, "mobile")}
            {...disabledExistingUser}
          />

          <ErrorMessage
            component="div"
            name="contactInformation.mobile"
            className="invalid-feedback"
          />
        </div>

        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Email
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="email"
            name="contactInformation.email"
            value={contactInformation.email || ""}
            className={getErrorCssClassName(touch, error, "email")}
            {...disabledExistingUser}
          />
          <ErrorMessage
            component="div"
            name="contactInformation.email"
            className="invalid-feedback"
          />
        </div>
      </div>

      <div className="form-group row mb-1">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Street Line 1
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            name="contactInformation.streetLine1"
            value={contactInformation.streetLine1 || ""}
            className={getErrorCssClassName(touch, error, "streetLine1")}
            {...disabledExistingUser}
          />

          <ErrorMessage
            component="div"
            name="contactInformation.streetLine1"
            className="invalid-feedback"
          />
        </div>

        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
          Street Line 2
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            name="contactInformation.streetLine2"
            value={contactInformation.streetLine2 || ""}
            className={getErrorCssClassName(touch, error, "streetLine2")}
            {...disabledExistingUser}
          />
          <ErrorMessage
            component="div"
            name="contactInformation.streetLine2"
            className="invalid-feedback"
          />
        </div>
      </div>

      <div className="form-group row mb-1">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          City
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            name="contactInformation.city"
            value={contactInformation.city || ""}
            className={getErrorCssClassName(touch, error, "city")}
            {...disabledExistingUser}
          />

          <ErrorMessage
            component="div"
            name="contactInformation.city"
            className="invalid-feedback"
          />
        </div>

        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          State
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            component="select"
            name="contactInformation.state"
            value={contactInformation.state || ""}
            className={getErrorCssClassName(touch, error, "state")}
            {...disabledExistingUser}
          >
            <option value="">Select...</option>
            {props.states.map((state, i) => (
              <option key={i} value={state.stateCode}>
                {state.stateName}
              </option>
            ))}
          </Field>
          <ErrorMessage
            component="div"
            name="contactInformation.state"
            className="invalid-feedback"
          />
        </div>
      </div>

      <div className="form-group row mb-1">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Country
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            name="contactInformation.country"
            value={contactInformation.country || ""}
            className={getErrorCssClassName(touch, error, "country")}
            {...disabledExistingUser}
          />

          <ErrorMessage
            component="div"
            name="contactInformation.country"
            className="invalid-feedback"
          />
        </div>

        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
          Postal Code
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            name="contactInformation.postalCode"
            inputMode="numeric"
            value={contactInformation.postalCode || ""}
            className={getErrorCssClassName(touch, error, "postalCode")}
            {...disabledExistingUser}
          />
          <ErrorMessage
            component="div"
            name="contactInformation.postalCode"
            className="invalid-feedback"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ContactInformation;
