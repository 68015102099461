import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getActionCss } from "../../../common/Util";
import * as Yup from "yup";
import Can from "../../../../auth/can";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  remark: Yup.string()
});

class CancelAssetReservation extends Component {
  render() {
    const { assetReservation } = this.props;

    return (
      <Fragment>
        <Formik
          enableReinitialize
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={(values, { setSubmitting }) => {
            return this.props.handleSubmit(values, {
              setSubmitting
            });
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Fragment>
                {assetReservation.stage === "RESERVEAPPR" &&
                assetReservation.status === "APPROVED" ? (
                  <Fragment>
                    <div className="row mt-5">
                      <div className="col-12 mb-3 edit-page-section-header-text">
                        Cancellation Details
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                        Remarks
                      </label>
                      <div className="col-12 col-lg-5 col-xl-5">
                        <Field
                          component="textarea"
                          name="remark"
                          value={values.remark || ""}
                          className={getErrorCssClassName(
                            touched,
                            errors,
                            "remark"
                          )}
                          rows="3"
                        />

                        <ErrorMessage
                          component="div"
                          name="remark"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    {assetReservation.actions.length > 0 ? (
                      <div className="mt-5 text-center">
                        <Can do="UPDATE" on="AssetReservationRequest">
                          {assetReservation.actions.map((action, idx) => {
                            if (action === "CANCEL") {
                              return (
                                <Fragment key={idx}>
                                  <button
                                    className={getActionCss(action)}
                                    type="submit"
                                    onClick={() => {
                                      setFieldValue("action", action);
                                    }}
                                  >
                                    {action === "CANCEL"
                                      ? "Cancel Reservation"
                                      : action}
                                  </button>
                                </Fragment>
                              );
                            }
                            return null;
                          })}
                        </Can>
                      </div>
                    ) : null}
                  </Fragment>
                ) : null}
              </Fragment>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

export default CancelAssetReservation;
