import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../../common/HttpUtil";
import {
  convertNumberToDate,
  convertDateToMonthYear
} from "../../lease/DateUtil";
import { changeToINR } from "../../common/LookupConstants";
import AlertComponent from "../../common/AlertComponent";
import TableList from "../../common/TableList";
import { MONTHLY_REPORTS_API } from "../../common/Constants";

class ConsolidatedReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportList: null
    };
  }
  componentDidMount() {
    this.getMonthlyReports();
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getMonthlyReports = () => {
    const url = `${MONTHLY_REPORTS_API}/consolidated-reports`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ reportList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  linkFormatter = (cell, row) => {
    if (!row) {
      return "";
    }

    return (
      <Link
        to={`/finance/consolidated-report/${row._id.start}/charges`}
        role="button"
      >
        {convertDateToMonthYear(convertNumberToDate(row._id.start))}
      </Link>
    );
  };

  render() {
    const { reportList } = this.state;

    const columnsReports = [
      {
        dataField: "start",
        text: "Reporting Period ",
        formatter: this.linkFormatter,
        headerStyle: {
          width: "15%"
        }
      },
      {
        dataField: "facilityCharge",
        text: "Facility Charge",
        classes: "text-right",
        formatter: changeToINR,
        csvType: Number,
        headerClasses: "text-right",
        headerStyle: {
          width: "25%"
        }
      },
      {
        dataField: "assetCharge",
        text: "Asset Charge",
        classes: "text-right",
        formatter: changeToINR,
        csvType: Number,
        headerClasses: "text-right",
        headerStyle: {
          width: "25%"
        }
      },
      {
        dataField: "_id",
        isDummyField: true,
        csvExport: false,
        headerStyle: {
          width: "15%"
        }
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id.start"
          columns={columnsReports}
          data={reportList}
          noDataIndication="No Monthly Reports found."
          sizePerPage="25"
          headerName="Monthly Reports"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default ConsolidatedReportList;
