function processResponse(response) {
  if (response.status === 500) {
    throw Error(response.statusText);
  }
  let contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    const success = response.status === 200;
    const data = response.json();
    const promise = [success, response.status, data];
    return Promise.all(promise);
  } else {
    throw Error(response.statusText);
  }
}

function processResponseData(
  success,
  status,
  data,
  successHandler,
  failureHandler
) {
  if (success) {
    return successHandler(data);
  } else {
    return failureHandler(data, status);
  }
}

function callHttpMethod(
  url,
  options,
  successHandler,
  failureHandler,
  exceptionHandler
) {
  return fetch(url, options)
    .then(response => processResponse(response))
    .then(([success, status, data]) =>
      processResponseData(success, status, data, successHandler, failureHandler)
    )
    .catch(error => exceptionHandler(error));
}

const HttpUtil = {
  get: function(
    url,
    headers,
    successHandler,
    failureHandler,
    exceptionHandler
  ) {
    const options = {
      method: "GET",
      headers: headers
    };

    return callHttpMethod(
      url,
      options,
      successHandler,
      failureHandler,
      exceptionHandler
    );
  },
  post: function(
    url,
    headers,
    data,
    successHandler,
    failureHandler,
    exceptionHandler
  ) {
    let postData = data;
    if ("application/json" === headers["Content-Type"]) {
      postData = JSON.stringify(data);
    }

    const options = {
      method: "POST",
      headers: headers,
      body: postData
    };

    return callHttpMethod(
      url,
      options,
      successHandler,
      failureHandler,
      exceptionHandler
    );
  },

  put: function(
    url,
    headers,
    data,
    successHandler,
    failureHandler,
    exceptionHandler
  ) {
    let putData = data;
    if ("application/json" === headers["Content-Type"]) {
      putData = JSON.stringify(data);
    }

    const options = {
      method: "PUT",
      headers: headers,
      body: putData
    };

    return callHttpMethod(
      url,
      options,
      successHandler,
      failureHandler,
      exceptionHandler
    );
  },

  delete: function(
    url,
    headers,
    successHandler,
    failureHandler,
    exceptionHandler
  ) {
    const options = {
      method: "DELETE",
      headers: headers
    };

    return callHttpMethod(
      url,
      options,
      successHandler,
      failureHandler,
      exceptionHandler
    );
  }
};

export default HttpUtil;
