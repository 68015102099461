import React, { Component } from "react";

class OptionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      promoterAllField: false,
      selectedBtn: "",
      selectOptions: null
    };
  }

  handleSelectOptions = e => {
    const selectOptions = this.state.selectOptions;
    selectOptions[e.target.name] = e.target.checked;
    this.setState({ selectOptions });
  };

  handleIncubatee = e => {
    const selectOptions = this.state.selectOptions;
    const incubatee = selectOptions.incubatee;
    incubatee[e.target.name] = e.target.checked;
    this.setState({ selectOptions });
  };

  handleSelectAllPromoterField = e => {
    const selectOptions = this.state.selectOptions;
    const promoter = selectOptions.promoter;
    const checked = e.target.checked;
    promoter.name = checked;

    promoter.email = checked;
    promoter.phone = checked;
    promoter.designation = checked;
    let selectedBtn = checked ? "PROMOTERS_AND_FOUNDERS" : "";
    this.setState({
      selectOptions,
      [e.target.name]: e.target.checked,
      selectedBtn
    });
  };

  handlePromoter = e => {
    const selectOptions = this.state.selectOptions;
    const promoter = selectOptions.promoter;
    promoter[e.target.name] = e.target.checked;
    this.setState({ selectOptions });
  };

  handleSelectAll = e => {
    const checked = e.target.checked;
    const selectOptions = {
      individual: checked,
      incubatee: {
        address: checked,
        website: checked,
        email: checked,
        phone: checked,
        desc: checked
      },
      promoter: {
        name: checked,
        founder: false,
        email: checked,
        phone: checked,
        designation: checked
      }
    };

    this.setState({
      selectOptions,
      selectAll: checked,
      promoterAllField: checked,
      selectedBtn: checked ? "PROMOTERS_AND_FOUNDERS" : ""
    });
  };

  handlePromoterRadioBtn = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value;
    const selectOptions = this.state.selectOptions;
    const promoter = selectOptions.promoter;

    if (targetValue === "PROMOTERS_AND_FOUNDERS") {
      promoter.founder = false;
    } else if (targetValue === "FOUNDERS_ONLY") {
      promoter.founder = true;
    }
    this.setState({ [targetName]: targetValue, selectOptions });
  };

  validate = () => {
    const selectOptions = this.state.selectOptions;
    return selectOptions;
  };

  updateLocalStateToParent = () => {
    const selectOptions = this.validate();
    this.props.handleSelectOptions(selectOptions);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const prvSelectOptions = prevProps.selectOptions;
    const selectOptions = this.state.selectOptions;

    if (prvSelectOptions !== selectOptions) {
      this.updateLocalStateToParent();
    }
  };

  componentDidMount = () => {
    const propsSelectOptions = { ...this.props.selectOptions };
    this.setState({ selectOptions: propsSelectOptions });
  };

  render() {
    if (this.state.selectOptions === null) {
      return null;
    }

    const { selectAll, promoterAllField } = this.state;
    const { individual, incubatee, promoter } = this.state.selectOptions;
    const { address, website, email, phone, desc } = incubatee;
    const { designation } = promoter;

    return (
      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Select Fields</h5>
            </div>

            <div className="card-body">
              <div className="row ml-3">
                <div className="col-md-6 ">
                  <div className="form-group row ">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="selectAll"
                        name="selectAll"
                        checked={selectAll}
                        onChange={this.handleSelectAll}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      >
                        <strong> Select All Fields</strong>
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mt-3 mb-0">
                    <strong>Incubatee</strong>
                  </div>

                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="address"
                        name="address"
                        checked={address}
                        onChange={this.handleIncubatee}
                      />
                      <label className="custom-control-label" htmlFor="address">
                        Address
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="website"
                        name="website"
                        checked={website}
                        onChange={this.handleIncubatee}
                      />
                      <label className="custom-control-label" htmlFor="website">
                        Website
                      </label>
                    </div>
                  </div>
                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="email"
                        name="email"
                        checked={email}
                        onChange={this.handleIncubatee}
                      />
                      <label className="custom-control-label" htmlFor="email">
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="phone"
                        name="phone"
                        checked={phone}
                        onChange={this.handleIncubatee}
                      />
                      <label className="custom-control-label" htmlFor="phone">
                        Phone
                      </label>
                    </div>
                  </div>
                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="desc"
                        name="desc"
                        checked={desc}
                        onChange={this.handleIncubatee}
                      />
                      <label className="custom-control-label" htmlFor="desc">
                        Description
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="individual"
                        name="individual"
                        checked={individual}
                        onChange={this.handleSelectOptions}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="individual"
                      >
                        Individual
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mt-3 mb-0">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="promoterAllField"
                        name="promoterAllField"
                        checked={promoterAllField}
                        onChange={this.handleSelectAllPromoterField}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="promoterAllField"
                      >
                        <strong> Promoter</strong>
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="promotersOnly"
                        name="selectedBtn"
                        className="custom-control-input"
                        value="PROMOTERS_AND_FOUNDERS"
                        checked={
                          this.state.selectedBtn === "PROMOTERS_AND_FOUNDERS"
                        }
                        disabled={!promoterAllField}
                        onChange={this.handlePromoterRadioBtn}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="promotersOnly"
                      >
                        Promoters and Founders
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="founders"
                        name="selectedBtn"
                        className="custom-control-input"
                        value="FOUNDERS_ONLY"
                        checked={this.state.selectedBtn === "FOUNDERS_ONLY"}
                        disabled={!promoterAllField}
                        onChange={this.handlePromoterRadioBtn}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="founders"
                      >
                        Founders Only
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="pro.email"
                        name="email"
                        checked={promoter.email}
                        onChange={this.handlePromoter}
                        disabled={!promoterAllField}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="pro.email"
                      >
                        Email
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="pro.phone"
                        name="phone"
                        checked={promoter.phone}
                        onChange={this.handlePromoter}
                        disabled={!promoterAllField}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="pro.phone"
                      >
                        Phone
                      </label>
                    </div>
                  </div>

                  <div className="form-group row mb-0 ml-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="designation"
                        name="designation"
                        checked={designation}
                        onChange={this.handlePromoter}
                        disabled={!promoterAllField}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="designation"
                      >
                        Designation
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionsForm;
