export const getDisplayDate = date => {
  if (date === null || date === undefined || date === "") {
    return null;
  }

  let d = typeof date === "string" ? new Date(date) : date;

  const year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  //console.log(`day:${day}-month:${month}-year:${year}`)
  return `${day}-${month}-${year}`;
};

export const getMonths = (from, to) => {
  if (!isValidDate(from) || !isValidDate(to)) {
    return null;
  }
  var months =
    to.getMonth() -
    from.getMonth() +
    12 * (to.getFullYear() - from.getFullYear());

  if (to.getDate() < from.getDate()) {
    months--;
  }
  return months;
};

export const isValidDate = date => {
  return date instanceof Date;
};

function pad(no) {
  return no < 10 ? `0${no}` : `${no}`;
}

export const getDateAndTimeFormat = date => {
  if (date === null || date === undefined || date === "") {
    return;
  }

  let dt = new Date(date);

  let hour,
    pm = "AM";
  hour = dt.getHours();
  if (hour === 0) {
    pm = "AM";
    hour = 12;
  } else if (hour < 12) {
    pm = "AM";
  } else if (hour === 12) {
    pm = "PM";
  } else {
    pm = "PM";
    hour = hour - 12;
  }

  return `${pad(dt.getDate())}/${pad(dt.getMonth() + 1)}/${pad(
    dt.getFullYear()
  )} ${pad(hour)}:${pad(dt.getMinutes())} ${pm}`;
};

export const getYears = () => {
  const year = new Date().getFullYear();
  const minOffset = year - 30;
  const maxOffset = year + 2;
  const years = [];
  for (let i = minOffset; i <= maxOffset; i++) {
    years.push(i);
  }
  return years;
};

export const convertDateToNumber = date => {
  if (!isValidDate(date)) {
    return null;
  }
  let d = typeof date === "string" ? new Date(date) : date;
  return d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate();
};

export const convertNumberToDate = yyyymmddDate => {
  if (
    yyyymmddDate === null ||
    yyyymmddDate === "" ||
    yyyymmddDate === undefined
  ) {
    return null;
  }
  let stringDate = String(yyyymmddDate);
  let year = stringDate.substring(0, 4);
  let month = stringDate.substring(4, 6);
  let day = stringDate.substring(6, 8);
  //console.log("new Date:", new Date(year, month - 1, day));
  return new Date(year, month - 1, day);
};

export function getIsoDataFromNumericDate(nDate) {
  if (!nDate) {
    return null;
  }
  let stringDate = String(nDate);
  let year = stringDate.substring(0, 4);
  let month = stringDate.substring(4, 6);
  let day = stringDate.substring(6, 8);

  return `${year}-${month}-${day}`;
}

export const addDays = days => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};

export const addMonths = (date, offset) => {
  const d = new Date(date);
  d.setMonth(d.getMonth() + offset);
  d.setDate(d.getDate() - 1);
  return d;
};

export function getLastDayPreviousMonth(date) {
  const dt = date || new Date();
  dt.setDate(-1);
  return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
}

export const convertDateMonthNameYear = date => {
  const d = new Date(date);

  if (!isValidDate(d)) {
    return null;
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  let hour,
    pm = "AM";
  hour = d.getHours();
  if (hour === 0) {
    pm = "AM";
    hour = 12;
  } else if (hour < 12) {
    pm = "AM";
  } else if (hour === 12) {
    pm = "PM";
  } else {
    pm = "PM";
    hour = hour - 12;
  }

  const day = d.getDate();
  const monthIndex = d.getMonth();
  const year = d.getFullYear();

  return `${pad(day)}-${monthNames[monthIndex]}-${year} ${pad(hour)}:${pad(
    d.getMinutes()
  )} ${pm}`;
};
export const getYearFirstDate = date => {
  if (date === null || date === undefined || date === "") {
    return null;
  }

  let d = typeof date === "string" ? new Date(date) : date;

  const year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  //console.log(`day:${day}-month:${month}-year:${year}`)
  return `${year}-${month}-${day}`;
};

export const convertDateToMonthYear = date => {
  const d = new Date(date);

  if (!isValidDate(d)) {
    return null;
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const monthIndex = d.getMonth();
  const year = d.getFullYear();

  return `${monthNames[monthIndex]}, ${year}`;
};

export function formatNumericDate(nDate) {
  if (!nDate) {
    return null;
  }
  if (nDate === 99999999) {
    return null;
  }
  let stringDate = String(nDate);
  let year = stringDate.substring(0, 4);
  let month = stringDate.substring(4, 6);
  let day = stringDate.substring(6, 8);

  return `${day}-${month}-${year}`;
}

export function getDateLimit(from, to) {
  if (formatNumericDate(from) && formatNumericDate(to)) {
    return `${formatNumericDate(from)} - ${formatNumericDate(to)}`;
  }

  return null;
}
