import React from "react";
import { changeToINR } from "../../common/LookupConstants";
import PivotReport from "../PivotReport";
import { checkNaN } from "../ReportsUtil";

const dimensions = [
  { value: "hasLease", title: "Leased" },
  { value: "incubateeStage", title: "Stage" },
  { value: "residentialStatus", title: "Residential Status" },
  { value: "incubationCategory", title: "Category" },
  { value: "incubationSector", title: "Sector" },
  { value: "subTechSector", title: "Sub Sector" },
  { value: "coIncubatee", title: "CoIncubatee" },
  { value: "anchor", title: "Anchor" },
  { value: "name", title: "Incubatee" },
  { value: "financialYear", title: "Year" }
];

function reduce(row, aggRecord) {
  aggRecord.turnover = (aggRecord.turnover || 0) + parseFloat(row.turnover);

  return aggRecord;
}

const calculations = [
  {
    title: "Turnover",
    value: "turnover",
    template: val => changeToINR(val),
    sortBy: row => checkNaN(row.turnover)
  }
];

function AnnualTurnoverInfo() {
  return (
    <PivotReport
      title="Annual Turnover Information"
      url="/api/v1/dashboard/incubatee/aggregated-turnover-stats-info"
      dimensions={dimensions}
      activeDimensions={["financialYear", "Year"]}
      reduce={reduce}
      calculations={calculations}
    />
  );
}

export default AnnualTurnoverInfo;
