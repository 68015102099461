import React, { Component, Fragment } from "react";
import {
  DEFAULT_COUNTRY,
  DEFAULT_STATE
} from "../../../common/data/DefaultData";

class Contact extends Component {
  render() {
    const {
      incubateeType,
      addressHandler,
      handleInputChange,
      inputError,
      email,
      phone,
      address,
      aadhaar,
      pan,
      gstn,
      states,
      countries,
      identificationDocs,
      downloadDocument,
      addresses
    } = this.props;

    let panDocument = "",
      gstnDocument = "",
      aadhaarDocument = "";
    identificationDocs.forEach(idDoc => {
      panDocument =
        idDoc.type === "PAN" && idDoc.document ? idDoc.document : panDocument;
      gstnDocument =
        idDoc.type === "GSTN" && idDoc.document ? idDoc.document : gstnDocument;
      aadhaarDocument =
        idDoc.type === "AADHAAR" && idDoc.document
          ? idDoc.document
          : aadhaarDocument;
    });

    let addr = addresses[0];
    let addressProof = addr && address.addressProof ? address.addressProof : "";

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="card-body pt-0 pb-0">
              <div className="row">
                <div className="col-12 mb-3 edit-page-section-header-text">
                  {incubateeType === "COMPANY"
                    ? "Organization Contact"
                    : "Individual Contact"}
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label-required">
                      Phone
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <input
                        type="number"
                        className={
                          inputError["phone"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="Phone"
                        name="phone"
                        value={phone}
                        onChange={handleInputChange}
                      />
                      {inputError["phone"] ? null : (
                        <small className="form-text text-muted">
                          Please enter primary phone number
                        </small>
                      )}
                      <div className="invalid-feedback">
                        Please enter primary phone number *.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label-required">
                      Email
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <input
                        type="email"
                        className={
                          inputError["email"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="example@abcd.com"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                      />
                      {inputError["email"] ? null : (
                        <small className="form-text text-muted">
                          Please enter primary email address
                        </small>
                      )}
                      <div className="invalid-feedback">
                        {" "}
                        Please enter primary email address *.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card-body pt-0 pb-0">
              <div className="row">
                <div className="col-12 mb-3 edit-page-section-header-text">
                  {incubateeType === "COMPANY"
                    ? "Organization Address"
                    : "Individual Address"}
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label-required">
                      Street Line 1
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className={
                          inputError["streetLine1"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="Street line 1"
                        name="streetLine1"
                        value={address.streetLine1 ? address.streetLine1 : ""}
                        onChange={addressHandler}
                      />
                      <div className="invalid-feedback">
                        Please enter streetLine1 *.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                      Street Line 2
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Street line 2"
                        name="streetLine2"
                        value={address.streetLine2 ? address.streetLine2 : ""}
                        onChange={addressHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label-required">
                      City
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className={
                          inputError["city"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="City"
                        name="city"
                        value={address.city ? address.city : ""}
                        onChange={addressHandler}
                      />
                      <div className="invalid-feedback">
                        Please enter city name *.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                      Landmark
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className={
                          inputError["landmark"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="Landmark"
                        name="landmark"
                        value={address.landmark ? address.landmark : ""}
                        onChange={addressHandler}
                      />
                      <div className="invalid-feedback">
                        Please enter landmark *.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label-required">
                      State
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <select
                        className={
                          inputError["state"]
                            ? "custom-select custom-select-sm is-invalid"
                            : "custom-select custom-select-sm"
                        }
                        name="state"
                        value={
                          address.state !== null ? address.state : DEFAULT_STATE
                        }
                        onChange={addressHandler}
                      >
                        <option value="">Select...</option>
                        {states.map((state, i) => (
                          <option key={i} value={state.stateCode}>
                            {state.stateName}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        Please select state name *.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label-required">
                      Country
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      {/* <input
                        type="text"
                        className={
                          inputError["country"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="Country"
                        name="country"
                        value={address.country ? address.country : "India"}
                        onChange={addressHandler}
                      /> */}
                      <select
                        className={
                          inputError["country"]
                            ? "custom-select custom-select-sm is-invalid"
                            : "custom-select custom-select-sm"
                        }
                        name="country"
                        value={
                          address.country ? address.country : DEFAULT_COUNTRY
                        }
                        onChange={addressHandler}
                      >
                        <option value="">Select...</option>
                        {countries.map((country, i) => (
                          <option key={i} value={country.countryCode}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        Please select country name *.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label-required">
                      Postal Code
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <input
                        type="number"
                        className={
                          inputError["postalCode"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="Postal Code"
                        name="postalCode"
                        pattern="[0-9]{6}"
                        value={address.postalCode ? address.postalCode : ""}
                        onChange={addressHandler}
                      />
                      <div className="invalid-feedback">
                        Please enter postal code *.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 edit-page-label">
                      Address Proof (Driving License, Aadhaar)
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <input
                        type="file"
                        name="addressProof"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                {addressProof ? (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="form-group row">
                      <div className=" col-12 edit-page-label">
                        <button
                          className="btn btn-sm btn-info"
                          onClick={downloadDocument.bind(this, addressProof)}
                        >
                          Download Address Proof
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="card-body pt-0 pb-0">
              <div className="row">
                <div className="col-12 mb-3 edit-page-section-header-text">
                  Identification Documents
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label">
                      PAN
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                      <input
                        type="text"
                        className={
                          inputError["pan"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="PAN number"
                        name="pan"
                        value={pan}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        Please enter pan *.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 edit-page-label">
                      PAN Scanned Copy
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                      {pan !== "" && pan !== null ? (
                        <input
                          type="file"
                          name="scannedPan"
                          onChange={handleInputChange}
                        />
                      ) : (
                        <input type="file" disabled />
                      )}
                    </div>
                  </div>
                </div>
                {panDocument !== "" ? (
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="form-group row">
                      <div className="col-12 text-right">
                        <button
                          className="btn btn-sm btn-info"
                          onClick={downloadDocument.bind(this, panDocument)}
                        >
                          Download PAN copy
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {incubateeType === "COMPANY" ? (
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="form-group row">
                      <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label">
                        GSTN
                      </label>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                        <input
                          type="text"
                          className={
                            inputError["gstn"]
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          placeholder="GSTN number"
                          name="gstn"
                          value={gstn}
                          onChange={handleInputChange}
                        />
                        <div className="invalid-feedback">
                          Please enter gstn *.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="form-group row">
                      <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 edit-page-label">
                        GSTN Scanned Copy
                      </label>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                        {gstn !== "" && gstn !== null ? (
                          <input
                            type="file"
                            name="scannedGstn"
                            onChange={handleInputChange}
                          />
                        ) : (
                          <input type="file" disabled />
                        )}
                      </div>
                    </div>
                  </div>
                  {gstnDocument !== "" ? (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <div className="form-group row">
                        <div className="col-12 text-right">
                          <button
                            className="btn btn-sm btn-info"
                            onClick={downloadDocument.bind(this, gstnDocument)}
                          >
                            Download GSTN copy
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {incubateeType === "INDIVIDUAL" ? (
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="form-group row">
                      <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 edit-page-label">
                        Aadhaar
                      </label>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                        <input
                          type="text"
                          className={
                            inputError["aadhaar"]
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          placeholder="1234-1234-1234"
                          name="aadhaar"
                          value={aadhaar}
                          onChange={handleInputChange}
                        />
                        <div className="invalid-feedback">
                          Please enter aadhaar *.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className="form-group row">
                      <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 edit-page-label">
                        Aadhaar Scanned Copy
                      </label>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                        {aadhaar !== "" && aadhaar !== null ? (
                          <input
                            type="file"
                            name="scannedAadhaar"
                            onChange={handleInputChange}
                          />
                        ) : (
                          <input type="file" name="scannedAadhaar" disabled />
                        )}
                      </div>
                    </div>
                  </div>

                  {aadhaarDocument !== "" ? (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <div className="form-group row">
                        <div className="col-12 text-right">
                          <button
                            className="btn btn-sm btn-info"
                            onClick={downloadDocument.bind(
                              this,
                              aadhaarDocument
                            )}
                          >
                            Download Aadhaar copy
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Contact;
