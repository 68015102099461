import {
  generateEnumValues,
  EDU_QUALIFICATION_TYPES
} from "../../../common/LookupConstants";

function generateYears(howFar) {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let index = 0; index < howFar; index++) {
    years.push(currentYear - index);
  }
  return years;
}

const EducationSchema = {
  title: "Education",
  type: "array",
  minItems: 1,
  items: {
    title: "Education",
    type: "object",
    required: ["qualification", "degree", "year"],
    properties: {
      qualification: {
        type: "string",
        title: "Education Qualification",
        anyOf: generateEnumValues(EDU_QUALIFICATION_TYPES)
      },
      degree: {
        type: "string",
        title: "Degree"
      },
      year: {
        type: "number",
        title: "Year",
        enum: generateYears(30)
      },
      institution: {
        type: "string",
        title: "Institution"
      },
      alumni: {
        type: "boolean",
        title: "PSG Alumni"
      }
    }
  }
};

const EducationUiSchema = {};

export { EducationSchema, EducationUiSchema };
