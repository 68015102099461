import React, { Component, Fragment } from "react";
import AssetEnquiryFormik from "./AssetEnquiryFormik";
import AlertComponent from "../../common/AlertComponent";
import { RENTAL_REQUEST_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import RENTAL_REQUEST_SCHEMA from "./RentalRequestSchema";

const ENQUIRY_SCHEMA = {};

class AssetEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalRequest: RENTAL_REQUEST_SCHEMA
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getOrganization = data => {
    let organization = { ...data.incubatee.organization };

    const email = organization.emails[0] ? organization.emails[0].email : "";
    const phone = organization.phones[0] ? organization.phones[0].phone : "";
    const address = organization.addresses[0] ? organization.addresses[0] : "";
    const pan = organization.identificationDocs[0]
      ? organization.identificationDocs[0].number
      : "";

    organization.email = email;
    organization.phone = phone;
    organization.address = address;
    organization.pan = pan;

    return organization;
  };

  getIndividual = data => {
    let individual = { ...data.incubatee.individual };

    const email = individual.emails[0] ? individual.emails[0].email : "";
    const phone = individual.phones[0] ? individual.phones[0].phone : "";
    const address = individual.addresses[0] ? individual.addresses[0] : "";
    const pan = individual.identificationDocs[0]
      ? individual.identificationDocs[0].number
      : "";

    individual.email = email;
    individual.phone = phone;
    individual.address = address;
    individual.pan = pan;

    return individual;
  };

  getNonIncubatee = (data, rentalRequest) => {
    const email = data.emails[0] ? data.emails[0].email : "";
    const phone = data.phones[0] ? data.phones[0].phone : "";
    const address = data.addresses[0] ? data.addresses[0] : "";
    const pan = data.identificationDocs[0]
      ? data.identificationDocs[0].number
      : "";

    rentalRequest.email = email;
    rentalRequest.phone = phone;
    rentalRequest.address = address;
    rentalRequest.pan = pan;
  };

  setDataFromServer = data => {
    let rentalRequest = { ...data };

    const incubateeIndicator = data.incubateeIndicator;
    if (
      incubateeIndicator &&
      rentalRequest.incubatee.incubateeType === "COMPANY"
    ) {
      rentalRequest.incubatee.organization = this.getOrganization(data);
    } else if (
      incubateeIndicator &&
      rentalRequest.incubatee.incubateeType === "INDIVIDUAL"
    ) {
      rentalRequest.incubatee.individual = this.getIndividual(data);
    } else {
      this.getNonIncubatee(data, rentalRequest);
    }
    this.setState({ rentalRequest });
  };

  getRentalRequests = id => {
    let url = `${RENTAL_REQUEST_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        this.setDataFromServer(data);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  callCreateIncubateeApi = (values, setSubmitting) => {
    console.log("callCreateIncubateeApi=>values:", values);
  };

  callOnboardApi = (values, setSubmitting) => {
    console.log("callOnboardApi=>values:", values);
  };

  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    if (values.incubateeIndicator) {
      this.callOnboardApi(values, setSubmitting);
    } else {
      this.callCreateIncubateeApi(values, setSubmitting);
    }
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getRentalRequests(id);
    }
  }

  render() {
    if (this.state.rentalRequest._id === null) {
      return null;
    }
    const formikProps = {
      initialValues: this.state.rentalRequest,
      validationSchema: ENQUIRY_SCHEMA,

      // events
      handleApiFailed: this.handleApiFailed,
      handleSubmit: this.handleSubmit
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <AssetEnquiryFormik {...formikProps} />
      </Fragment>
    );
  }
}

export default AssetEnquiry;
