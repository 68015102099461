import React, { Component, Fragment } from "react";

class NidhiPrayas extends Component {
  changeToINR = number => {
    let num = number;
    if (isNaN(num)) {
      return num;
    }
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(num);
  };
  getData = data => {
    return data ? data : "--";
  };
  render() {
    const { form } = this.props;

    const GENDER = {
      M: "Male",
      F: "Female",
      O: "Others",
      T: "Transgender"
    };

    return (
      <Fragment>
        {/* Application Information */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Application Information</h6>
              </div>
              <div className="row pl-1">
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>{`${form.source} ID`}</th>
                        <td>{form.sourceId}</td>
                      </tr>
                      <tr>
                        <th>{`${form.source} Reference`}</th>
                        <td>{form.sourceReference}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>{`${form.source} Status`}</th>
                        <td>{form.sourceStatus}</td>
                      </tr>
                      <tr>
                        <th>Application Status</th>
                        <td>{form.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* NIDHI PRAYAS FORM */}
        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Basic Information</h6>
              </div>
              <div className="row pl-2">
                <div className="col-6">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name </strong>
                        </td>
                        <td>{this.getData(form.name)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Father Spouse Name </strong>
                        </td>
                        <td>{this.getData(form.fatherSpouseName)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Date of Birth </strong>
                        </td>
                        <td>{this.getData(form.dob)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email</strong>
                        </td>
                        <td>{this.getData(form.email)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Mobile</strong>
                        </td>
                        <td>{this.getData(form.mobile)}</td>
                      </tr>
                      <tr>
                        <th>Phone</th>
                        <td>{this.getData(form.phone)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Gender</strong>
                        </td>
                        <td>{this.getData(GENDER[form.gender])}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>PAN</strong>
                        </td>
                        <td>{this.getData(form.pan)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Aadhaar</strong>
                        </td>
                        <td>{this.getData(form.aadhaar)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <th>
                          Name and Address of the Institution or Organization
                        </th>
                        <td>{this.getData(form.organization)}</td>
                      </tr>
                      <tr>
                        <th>Annual Income of the Applicant</th>
                        <td>{this.getData(form.annualIncome)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Highest Education Qualification</strong>
                        </td>
                        <td>{this.getData(form.education)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Community Category</strong>
                        </td>
                        <td>{this.getData(form.communityCategory)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Postal Address</strong>
                        </td>
                        <td>{this.getData(form.postalAddress)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Permanent Adress</strong>
                        </td>
                        <td>{this.getData(form.permanentAdress)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Profession of the Applicant</strong>
                        </td>
                        <td>{this.getData(form.profession)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Team Members */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Team Members </h6>
              </div>
              <div className="row pl-1">
                <div className="col-sm-12">
                  <div className="table-responsive">
                    <table className="table mb-0 table-sm table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Mobile</th>
                          <th scope="col">Email</th>
                          <th scope="col">Aadhaar</th>
                          <th scope="col">PAN</th>
                          <th scope="col">Education</th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.teamMembers.length > 0 ? (
                          form.teamMembers.map((team, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{team.name}</td>
                                <td>{team.gender}</td>
                                <td>{team.mobile}</td>
                                <td>{team.email}</td>
                                <td>{team.aadhaar}</td>
                                <td>{team.pan}</td>
                                <td>{team.education}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>No Team Members</tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Project */}
        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Project</h6>
              </div>
              <div className="row pl-2">
                <div className="col-12">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <td style={{ width: "20%" }}>
                          <strong>Project Title </strong>
                        </td>
                        <td>{form.projectTitle}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Project Description </strong>
                        </td>
                        <td>
                          <pre>{form.projectDesc}</pre>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Project Duration </strong>
                        </td>
                        <td>{`${form.projectDuration} months`}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Science and Working Principle behind this Idea
                          </strong>
                        </td>
                        <td>
                          <pre>{form.ideaWorkingPrinciple}</pre>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>
                            Final Outcome / Deliverable of the Project
                          </strong>
                        </td>
                        <td>
                          <pre>{form.projectDeliverable}</pre>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>
                            Who would be the beneficiary of this innovation and
                            why?
                          </strong>
                        </td>
                        <td>
                          <pre>{form.innovationBeneficiary}</pre>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Status of Work Carried out</h6>
              </div>
              <div className="row pl-2">
                <div className="col-12">
                  <table className="table table-sm table-borderless p-2">
                    <tbody>
                      <tr>
                        <td style={{ width: "40%" }}>
                          <strong>Participation in Competition</strong>
                        </td>
                        <td>
                          {form.competition ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Making a Model</strong>
                        </td>
                        <td>
                          {form.modelDevelopment ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Provisional Application for Patent </strong>
                        </td>
                        <td>
                          {form.patentApplication ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Paper Presentation</strong>
                        </td>
                        <td>
                          {form.paperPresentation ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Publication</strong>
                        </td>
                        <td>
                          {form.publication ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>College Project</strong>
                        </td>
                        <td>
                          {form.collegeProject ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Any Other</strong>
                        </td>
                        <td>
                          {form.other ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Proposed Cost Time  */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Proposed Cost Time </h6>
              </div>
              <div className="row pl-1 pr-2">
                <div className="col-sm-12">
                  <div className="table-responsive">
                    <table className="table mb-0 table-sm table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Line Item</th>
                          <th className="text-right" scope="col ">
                            Own Share
                          </th>
                          <th className="text-right" scope="col ">
                            Prayas Support
                          </th>
                          <th className="text-right" scope="col ">
                            Total Cost
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.proposedCostTime.length > 0 ? (
                          form.proposedCostTime.map((pct, idx) => {
                            return (
                              <tr
                                className={
                                  form.proposedCostTime.length - 1 === idx
                                    ? "font-weight-bold"
                                    : ""
                                }
                                key={idx}
                              >
                                <td>{pct.lineItem}</td>
                                <td className="text-right">
                                  {this.changeToINR(pct.ownShare)}
                                </td>
                                <td className="text-right">
                                  {this.changeToINR(pct.prayasSupport)}
                                </td>
                                <td className="text-right">
                                  {this.changeToINR(pct.totalCost)}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>No Proposed Cost Time</tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Activity Details / Work Plan (? in lakhs) */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">
                  Activity Details / Work Plan (? in lakhs){" "}
                </h6>
              </div>
              <div className="row pl-1 pr-2">
                <div className="col-sm-12">
                  <div className="table-responsive">
                    <table className="table mb-0 table-sm table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Activity</th>
                          <th scope="col">Monitorable milestones</th>
                          <th style={{ width: "16%" }} scope="col">
                            Duration (In Months)
                          </th>
                          <th style={{ width: "13%" }} scope="col">
                            Budget (Lakhs)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.activityPlan.length > 0 ? (
                          form.activityPlan.map((act, idx) => {
                            return (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{act.activity}</td>
                                <td>{act.milestone}</td>
                                <td className="text-center">{act.duration}</td>
                                <td className="text-right"> {act.budget}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>No Activity Plan</tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Activity Plan */}
        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Activity Plan</h6>
              </div>
              <div className="row ">
                <div className="col-12">
                  <table className="table table-sm table-striped mb-0">
                    <tbody>
                      <tr>
                        <td style={{ width: "90%" }}>
                          Have you received financial support / award for
                          yourpresent work from any other sources?
                        </td>
                        <td className="text-center">
                          {form.otherFinSupport ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>If yes, Please furnish details</td>
                        <td>{form.otherFinSupportDetail}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Checklist for Essential Criteria */}
        <div className="row mt-4">
          <div className="col-12 col-xl-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">
                  Checklist for Essential Criteria
                </h6>
              </div>
              <div className="row ">
                <div className="col-12">
                  <table className="table table-sm table-striped mb-0">
                    <tbody>
                      <tr>
                        <td style={{ width: "90%" }}>
                          Applicant Confirms that he / she has not been a
                          recipient of any NIDHI-PRAYAS or similar support
                          previously for the same innovative concept as proposed
                          in this form. NIDHI-PRAYAS can be used only once
                        </td>
                        <td className="text-center">
                          {form.noPrevNidhiPrayas ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Applicant is planning to pursue NIDHI-PRAYAS full time
                          with no other concurrent commitments
                        </td>
                        <td className="text-center">
                          {form.fullTimeNidhiPrayas ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Applicant confirms that he / she is fully committed to
                          work towards the prototype development for which the
                          support is being sought and should not treat this as a
                          stop gap arrangement to support any other pursuits
                        </td>
                        <td className="text-center">
                          {form.exploreBusinessIdea ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Applicant has or is planning to register for the
                          pre-incubation or incubation program at PSG-STEP for
                          the entire duration of PRAYAS support
                        </td>
                        <td className="text-center">
                          {form.regIncubationSupport ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Applicant confirms that if selected for NIDHI-PRAYAS,
                          then they (applicant and their team members) will
                          avail support for their project from only one PRAYAS
                          Centre. If the applicant is found seeking NIDHI-PRAYAS
                          support for the same idea from two different PCs, then
                          the PC reserves right to discontinue the PRAYAS
                          support and appropriate action will be taken on the
                          applicant
                        </td>
                        <td className="text-center">
                          {form.singlePrayasSupport ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Applicant confirms that they will not avail the
                          funding support in parallel for both the schemes i.e
                          NIDHI-PRAYAS and NIDHI - EIR from the same or
                          different centers
                        </td>
                        <td className="text-center">
                          {form.noConcurrentFunding ? (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-check-square text-success font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; Yes
                            </Fragment>
                          ) : (
                            <Fragment>
                              <strong>
                                <i
                                  className="far fa-window-close text-danger font-weight-bold"
                                  style={{ fontSize: "m-large" }}
                                />
                              </strong>
                              &nbsp; No
                            </Fragment>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Declaration Information */}
        <div className="row mt-4">
          <div className="col-12">
            <div className="card flex-fill">
              <div className="card-header bg-card-header text-white ">
                <h6 className="card-titile mb-0">Declaration Information</h6>
              </div>
              <div className="row pl-1">
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>Declaration Date</th>
                        <td>{form.declarationDate}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table table-sm table-borderless ">
                    <tbody>
                      <tr>
                        <th>Declaration Place</th>
                        <td>{form.declarationPlace}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default NidhiPrayas;
