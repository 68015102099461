import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const ErrorComponent = props => {
  const { fieldName, touch, error, datePicker } = props;
  let renderComponent = "";

  if (datePicker) {
    renderComponent = (
      <div className="auto-lookup-empty">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  } else {
    renderComponent = (
      <div className="invalid-feedback">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  }
  return renderComponent;
};

const AssetInformation = props => {
  const { values } = props;
  return (
    <Fragment>
      <div className="row mt-3 mb-2">
        <div className="col-md-12 text-center">
          <strong>Asset Information</strong>
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Asset Id
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="asset.assetId"
            type="text"
            className="form-control form-control-sm "
            value={(values.asset && values.asset.assetId) || ""}
            readOnly
          />
        </div>
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Asset Name
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="asset.assetName"
            type="text"
            className="form-control form-control-sm "
            value={(values.asset && values.asset.assetName) || ""}
            readOnly
          />
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Asset Type
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="asset.assetType"
            type="text"
            className="form-control form-control-sm "
            value={(values.asset && values.asset.assetType) || ""}
            readOnly
          />
        </div>
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Asset Category
        </label>

        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="asset.assetCategory"
            type="text"
            className="form-control form-control-sm "
            value={(values.asset && values.asset.assetCategory) || ""}
            readOnly
          />
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Asset Owner
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="asset.ownerName"
            type="text"
            className="form-control form-control-sm "
            value={(values.asset && values.asset.ownerName) || ""}
            readOnly
          />
        </div>
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Rental Type
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="asset.rentalType"
            type="text"
            className="form-control form-control-sm "
            value={(values.asset && values.asset.rentalType) || ""}
            readOnly
          />
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Status
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="asset.status"
            value={(values.asset && values.asset.status) || ""}
            className="form-control form-control-sm "
            readOnly
            disabled
          />
        </div>

        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Rental Status
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="asset.rentalStatus"
            value={(values.asset && values.asset.rentalStatus) || ""}
            className="form-control form-control-sm "
            readOnly
            disabled
          />
        </div>
      </div>
    </Fragment>
  );
};

const AssetLeaseInformation = props => {
  const { values } = props;
  return (
    <Fragment>
      <div className="row mt-4 mb-3">
        <div className="col-md-12 text-center">
          <strong>Lease Information</strong>
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">Name</label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="lease.name"
            type="text"
            className="form-control form-control-sm "
            value={(values.lease && values.lease.name) || ""}
            readOnly
          />
        </div>
        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Requestor Type
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="lease.requestorType"
            type="text"
            className="form-control form-control-sm "
            value={(values.lease && values.lease.requestorType) || ""}
            readOnly
          />
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Start Date
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="lease.startDate"
            type="text"
            className="form-control form-control-sm "
            value={(values.lease && values.lease.startDate) || ""}
            readOnly
          />
        </div>
        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          End Date
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="lease.endDate"
            type="text"
            className="form-control form-control-sm "
            value={(values.lease && values.lease.endDate) || ""}
            readOnly
          />
        </div>
      </div>
    </Fragment>
  );
};

const AssetReturnInformation = props => {
  const { values, errors, touched } = props;
  return (
    <Fragment>
      <div className="row mt-4 mb-3">
        <div className="col-md-12 text-center">
          <strong>Return Information</strong>
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Received By
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="receivedBy"
            type="text"
            className={getErrorCssClassName(touched, errors, "receivedBy")}
            value={values.receivedBy || ""}
          />
          <ErrorComponent
            fieldName="receivedBy"
            touch={touched}
            error={errors}
          />
        </div>

        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Damaged
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
              name="damaged"
              checked={values.damaged || ""}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Yes
            </label>
          </div>
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Notes
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="notes"
            component="textarea"
            className={getErrorCssClassName(touched, errors, "notes")}
            value={values.notes || ""}
          />
          <ErrorComponent fieldName="notes" touch={touched} error={errors} />
        </div>

        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Damages
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            name="damages"
            component="textarea"
            className={getErrorCssClassName(touched, errors, "damages")}
            value={values.damages || ""}
          />
          <ErrorComponent fieldName="damages" touch={touched} error={errors} />
        </div>
      </div>
    </Fragment>
  );
};

const Documents = props => {
  const { setFieldValue } = props;

  return (
    <Fragment>
      <div className="row mb-2">
        <div className="col-md-12">
          <strong>Documents </strong>
        </div>
      </div>
      <div className="form-group mt-xl-2 mb-0 row">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Upload Document
        </label>
        <div className="col-12 col-lg-4 col -xl-4">
          <input
            type="file"
            name="documents"
            className="form-control-file"
            multiple
            onChange={event => {
              setFieldValue("documents", event.currentTarget.files);
            }}
          />
          <small>Max : 5 files</small>
        </div>
      </div>
    </Fragment>
  );
};

const AssetRental = props => {
  return (
    <Form>
      <AssetInformation {...props} />
      <AssetLeaseInformation {...props} />
      <AssetReturnInformation {...props} />
      <hr />
      <Documents {...props} />
      <hr />
      <div className="row mt-lg-5 mb-lg-5 mt-xl-5 mb-xl-5">
        <div className="col-md-12 text-center">
          <button className="btn btn-sm btn-info" type="submit">
            Next
          </button>
        </div>
      </div>
    </Form>
  );
};

const SearchInputRow = props => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2">
            Enter Asset Id
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              name="searchAssetId"
              type="text"
              className="form-control form-control-sm "
              value={props.searchAssetId || ""}
              onChange={props.onChange}
            />
            <small>Enter exact asset Id here</small>
          </div>
          <div className="col-12 col-lg col-xl pl-lg-0 pl-xl-0">
            <button
              onClick={props.handleSearch}
              className="btn btn-primary btn-sm"
            >
              <i className="fas fa-search" /> Search Asset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

class AssetReturnForm extends Component {
  render() {
    const { searchResult } = this.props;

    const FORM_MAPPER = {
      0: "No Record Found",
      1: (
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          validationSchema={this.props.validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            this.props.handleNext(values, { setSubmitting })
          }
          render={({
            values,
            onSubmit,
            isSubmitting,
            errors,
            touched,
            setFieldValue
          }) => {
            const formProps = {
              values,
              onSubmit,
              isSubmitting,
              errors,
              touched,
              setFieldValue,

              form: this.props.form,
              handleNext: this.props.handleNext
            };
            return <AssetRental {...formProps} />;
          }}
        />
      )
    };

    const renderForm = FORM_MAPPER[searchResult];

    return (
      <div>
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-card-header text-white">
                <span className="card-title mb-0">
                  {/* <Link
                    to={`/asset/leases`}
                    className="badge bg-white custom-btn-sm"
                  >
                    <i className="fas fa-arrow-left" />
                  </Link> */}
                  &nbsp;Asset Return Form
                </span>
              </div>
              <div className="card-body">
                {this.props.form.editForm ? (
                  <SearchInputRow {...this.props} />
                ) : null}

                {renderForm}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetReturnForm;
