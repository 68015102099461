import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import CreatableSelect from "react-select/lib/Creatable";
import AlertComponent from "../../common/AlertComponent";
import { FACILITY_CLUSTERS_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import ErrorPage from "../../common/error.page";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const FACILITY_CLUSTER_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .label("Name")
    .required(),
  type: Yup.string()
    .label("Type")
    .required()
});

class FacilityClusterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facilityCluster: {
        name: "",
        type: "", //"Computer Science Tech",
        allFacilityTypes: []
      },

      newForm: null,
      editForm: null,
      permanentFailure: false,

      redirecToEdit: false,
      redirecToEditId: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  createFacility = (values, setSubmitting) => {
    setSubmitting(true);
    const createRequest = {
      name: values.name,
      type: values.type
    };

    const url = FACILITY_CLUSTERS_API;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      createRequest,
      data => {
        setSubmitting(false);
        this.setState({
          redirecToEdit: true,
          redirecToEditId: data._id
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  updateFacility = (values, setSubmitting) => {
    const updateRequest = {
      name: values.name,
      type: values.type
    };

    const id = this.props.match.params.id;
    const url = `${FACILITY_CLUSTERS_API}/${id}`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.put(
      url,
      headers,
      updateRequest,
      data => {
        setSubmitting(false);
        this.getFacilityClusters();
        const facilityCluster = { ...this.state.facilityCluster };
        facilityCluster.name = data.name;
        facilityCluster.type = data.type;

        this.setState({
          facilityCluster,
          newForm: false,
          editForm: true,

          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Facility Cluster "${data.name}" has been updated successfully.`
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleSubmit = (values, setSubmitting) => {
    const { newForm, editForm } = this.state;

    if (newForm) {
      this.createFacility(values, setSubmitting);
    } else if (editForm) {
      this.updateFacility(values, setSubmitting);
    }
  };

  getFacilityClusters = () => {
    let url = FACILITY_CLUSTERS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        const uniqTypes = data.reduce((unique, facility) => {
          if (!unique.some(obj => obj.value === facility.type)) {
            unique.push({
              value: facility.type,
              label: facility.type
            });
          }
          return unique;
        }, []);

        const facilityCluster = { ...this.state.facilityCluster };
        const allFacilityTypes = [...facilityCluster.allFacilityTypes];
        facilityCluster.allFacilityTypes = [...allFacilityTypes, ...uniqTypes];
        this.setState({ facilityCluster });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getFacilityClusterById = id => {
    let url = `${FACILITY_CLUSTERS_API}/${id}`;

    if (this.props.location.state) {
      let createdFacility = this.props.location.state.createdFacility;
      if (createdFacility) {
        this.setState({
          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: "Facility Cluster has been created successfully."
        });
        window.scrollTo(0, 0);
      }
    }

    HttpUtil.get(
      url,
      {},
      data => {
        const facilityCluster = { ...this.state.facilityCluster };
        facilityCluster.name = data.name;
        facilityCluster.type = data.type;

        this.setState({ facilityCluster, newForm: false, editForm: true });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getFacilityClusterById(id);

      this.getFacilityClusters();
    } else {
      this.getFacilityClusters();
      this.setState({
        newForm: true,
        editForm: false
      });
    }
  };

  render() {
    const {
      facilityCluster,
      newForm,
      editForm,
      redirecToEdit,
      redirecToEditId,
      permanentFailure,
      alertMessage
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (newForm === null || editForm === null) {
      return null;
    }

    if (redirecToEdit) {
      const stateObj = {
        pathname: `/facility-clusters/edit/${redirecToEditId}`,
        state: { createdFacility: true }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card flex-fill">
              <div className="edit-page-section-header-text">
                <span className="card-title mb-0">
                  <Link to="/facility-clusters" className="">
                    <i className="fas fa-arrow-left" />
                  </Link>
                  &nbsp; Facility Cluster &nbsp;&nbsp;
                </span>
              </div>

              <div className="card-body">
                <Formik
                  enableReinitialize
                  initialValues={facilityCluster}
                  onSubmit={(values, { setSubmitting }) => {
                    this.handleSubmit(values, setSubmitting);
                  }}
                  validationSchema={FACILITY_CLUSTER_SCHEMA}
                  render={({ values, errors, touched, setFieldValue }) => {
                    const props = {
                      values,
                      errors,
                      touched,
                      setFieldValue
                    };

                    return (
                      <Form>
                        <FacilityForm {...props} />
                        <div className="row mt-3 mb-5">
                          <div className="col-12 text-center">
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FacilityClusterForm;

const FacilityForm = props => {
  const { values, touched, errors } = props;
  return (
    <Fragment>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">Name</label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            type="text"
            className={getErrorCssClassName(touched, errors, "name")}
            name="name"
          />
          <ErrorComponent fieldName="name" touch={touched} error={errors} />
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">Type</label>
        <div className="col-12 col-lg-4 col-xl-4">
          <CreatableSelect
            isClearable
            onChange={newValue => {
              if (newValue) {
                props.setFieldValue("type", newValue.value);
              } else {
                props.setFieldValue("type", "");
              }
            }}
            options={values.allFacilityTypes}
            value={{
              label: props.values.type,
              value: props.values.type
            }}
          />
          <small>Type to create or select </small>
          {touched["type"] && errors["type"] ? (
            <div style={{ fontSize: "80%" }} className="text-danger">
              {errors["type"]}
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

const ErrorComponent = props => {
  const { fieldName, touch, error, datePicker } = props;
  let renderComponent = "";

  if (datePicker) {
    renderComponent = (
      <div className="auto-lookup-empty">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  } else {
    renderComponent = (
      <div className="invalid-feedback">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  }
  return renderComponent;
};
