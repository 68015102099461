import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";
import {
  changeToINR,
  MANUAL_CHARGE_STATUS_LIST_OBJECT
} from "../../common/LookupConstants";
import HttpUtil from "../../common/HttpUtil";
import { CHARGES_API } from "../../common/Constants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import TableList from "../../common/TableList";
import Can from "../../../auth/can";

class ManualChargeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manualCharges: null
    };
  }

  componentDidMount() {
    this.getManualCharges();
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getManualCharges = () => {
    const url = `${CHARGES_API}/manual-charges`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ manualCharges: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getName = (cell, row) => {
    if (!row) {
      return null;
    }
    let name = "";
    const customer = row.customer;
    if (row.customerType === "Individual") {
      const names = [];
      if (customer.firstName) {
        names.push(customer.firstName);
      }
      if (customer.lastName) {
        names.push(customer.lastName);
      }
      name = names.join(" ");
    } else {
      name = customer.name;
    }
    return name;
  };

  nameFormatter = (cell, row) => {
    if (!row) {
      return null;
    }
    return (
      <Link to={`/admin/finance/manual-charges/view/${row._id}`} role="button">
        {this.getName(cell, row)}
      </Link>
    );
  };

  actionsFormater = (cell, row) => {
    if (!row) {
      return null;
    }
    const chargeId = row._id;

    const baseUrl =
      "/admin/finance/payment/new?from=/admin/finance/manual-charges";
    const paymentPageUrl = `${baseUrl}&charge=${chargeId}`;
    let actionBtn = (
      <Fragment>
        {row.status !== "CLOSED" ? (
          <Can do="CREATE" on="Payment">
            <Link
              to={paymentPageUrl}
              className="list-action-btn-primary ml-2"
              role="button"
            >
              Pay
            </Link>
          </Can>
        ) : null}
      </Fragment>
    );
    return actionBtn;
  };

  dateFormater = yyyymmdd => {
    return getDisplayDate(convertNumberToDate(yyyymmdd));
  };

  render() {
    const { manualCharges } = this.state;

    const columnsManCharges = [
      {
        dataField: "customer",
        text: "Name",
        sort: true,
        formatter: this.nameFormatter,
        csvFormatter: this.getName,
        filterValue: this.getName
      },
      {
        dataField: "chargeDate",
        text: "Charge Date",
        formatter: this.dateFormater,
        csvFormatter: this.dateFormater
      },
      {
        dataField: "dueDate",
        text: "Due Date",
        formatter: this.dateFormater,
        csvFormatter: this.dateFormater
      },
      {
        dataField: "amount",
        text: "Amount",
        formatter: changeToINR,
        classes: "text-right",
        csvType: Number
      },
      {
        dataField: "status",
        text: "Status",
        formatter: cell =>
          MANUAL_CHARGE_STATUS_LIST_OBJECT[cell] || "Unspecified",
        csvFormatter: cell =>
          MANUAL_CHARGE_STATUS_LIST_OBJECT[cell] || "Unspecified"
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.actionsFormater,
        csvExport: false
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsManCharges}
          data={manualCharges}
          noDataIndication="No Manual Charges found."
          sizePerPage="10"
          headerName="Manual Charges"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default ManualChargeList;

const RenderCustomButton = props => {
  return (
    <Can do="CREATE" on="FinCharge">
      <Link
        to="/admin/finance/manual-charges/new"
        role="button"
        className="btn btn-sm text-white bg-pink"
      >
        New
      </Link>
    </Can>
  );
};
