import { convertDateToNumber, convertNumberToDate } from "../../lease/DateUtil";

export const transformServerToUi = data => {
  let relationshipManager;
  if (data.relationshipManager) {
    const relMagr = data.relationshipManager || {};
    const names = [];
    if (relMagr.firstName) {
      names.push(relMagr.firstName);
    }
    if (relMagr.lastName) {
      names.push(relMagr.lastName);
    }
    relationshipManager = {
      label: names.join(" "),
      value: relMagr._id
    };
  }

  const anchorIncubator = data.anchorIncubator || "";
  const ancInbtr = anchorIncubator
    ? {
        label: anchorIncubator.name,
        value: anchorIncubator._id
      }
    : "";

  const supplIncubators = data.supplIncubators || [];
  const subppleInbtrs = supplIncubators
    ? supplIncubators.map(sptrs => {
        const supleInbtr = {
          label: sptrs.name,
          value: sptrs._id
        };
        return supleInbtr;
      })
    : [];

  const incubateeProfile = {
    businessPlan: data.businessPlan || "",
    graduationStatus: data.graduationStatus || "",
    incubateeStage: data.incubateeStage || "",
    incubationSector: data.incubationSector || "",
    subTechSector: data.subTechSector || "",
    otherSubSector: data.otherSubSector || "",
    targetSector: data.targetSector || "",
    businessModel: data.businessModel || "",
    residentialStatus: data.residentialStatus || "",
    incubationCategory: data.incubationCategory || "",
    anchorIncubator: ancInbtr,
    supplIncubators: subppleInbtrs,
    about: data.about || "",
    relationshipManager
  };
  incubateeProfile._id = data._id;
  incubateeProfile.name = data.name || "";
  incubateeProfile.individual = data.individual || "";
  incubateeProfile.organization = data.organization || "";
  incubateeProfile.incubateeType = data.incubateeType || "";

  const ptens = data.patents || [];
  let patents = [];
  for (let i = 0; i < ptens.length; i++) {
    const obj = ptens[i];
    const patent = {
      _id: obj._id,
      titleOfInnovation: obj.titleOfInnovation || "",
      country: obj.country || "",
      applicationNumber: obj.applicationNumber || "",
      applicationFilingDate: obj.applicationFilingDate
        ? convertNumberToDate(obj.applicationFilingDate)
        : null,
      fieldOfInvention: obj.fieldOfInvention || "",
      classificationCode: obj.classificationCode || "",
      patentStatus: obj.patentStatus || ""
    };
    patents.push(patent);
  }

  if (patents.length === 0) {
    patents = [
      {
        titleOfInnovation: "",
        country: "",
        applicationNumber: "",
        applicationFilingDate: null,
        fieldOfInvention: "",
        classificationCode: "",
        patentStatus: ""
      }
    ];
  }
  incubateeProfile.patents = patents;

  const products = data.products || [];

  if (products.length > 0) {
    incubateeProfile.products = products.map(product => {
      return {
        ...product,
        value: product._id
      };
    });
  } else {
    incubateeProfile.products = [
      {
        productCategory: "",
        productSubCategory: "",
        name: "",
        description: ""
      }
    ];
  }

  return incubateeProfile;
};

export const transformUiToApi = values => {
  const relationshipManager = values.relationshipManager || {};

  const ptens = values.patents || [];

  const patents = [];
  for (let i = 0; i < ptens.length; i++) {
    const obj = ptens[i];
    const hasValues = Object.values(obj).some(value => value);

    let patent = {};
    if (hasValues) {
      patent = {
        titleOfInnovation: obj.titleOfInnovation || "",
        country: obj.country || "",
        applicationNumber: obj.applicationNumber || "",
        applicationFilingDate: obj.applicationFilingDate
          ? convertDateToNumber(obj.applicationFilingDate)
          : "",
        fieldOfInvention: obj.fieldOfInvention || "",
        classificationCode: obj.classificationCode || "",
        patentStatus: obj.patentStatus || ""
      };
      if (obj._id) {
        patent._id = obj._id;
      }

      patents.push(patent);
    }
  }

  // Products start
  const prods = values.products || [];

  const products = [];
  prods.forEach(product => {
    const {
      _id,
      productCategory,
      productSubCategory,
      name,
      description
    } = product;

    const newProduct = {};
    if (_id) {
      newProduct._id = _id;
    }

    if (productCategory) {
      newProduct.productCategory = productCategory._id;
    }

    if (productSubCategory) {
      newProduct.productSubCategory = productSubCategory._id;
    }

    if (name) {
      newProduct.name = name;
    }

    if (description) {
      newProduct.description = description;
    }

    if (Object.keys(newProduct).length > 0) {
      products.push(newProduct);
    }
  });
  // Products end

  const updateRequest = {
    businessPlan: values.businessPlan,
    graduationStatus: values.graduationStatus,
    incubateeStage: values.incubateeStage,
    incubationSector: values.incubationSector,
    subTechSector: values.subTechSector,
    otherSubSector: values.otherSubSector,
    targetSector: values.targetSector,
    businessModel: values.businessModel,
    residentialStatus: values.residentialStatus,
    incubationCategory: values.incubationCategory,
    about: values.about,
    patents: patents.length > 0 ? patents : [],
    grants: values.grants,
    products,
    relationshipManager: relationshipManager.value || ""
  };

  if (values.incubationCategory === "COINCUBATION") {
    updateRequest.anchorIncubator = values.anchorIncubator
      ? values.anchorIncubator.value
      : null;
    if (values.supplIncubators && values.supplIncubators.length > 0) {
      updateRequest.supplIncubators = values.supplIncubators.map(s => s.value);
    }
  }

  return updateRequest;
};
