import React from "react";
import PivotReport from "../PivotReport";
import { checkNaN } from "../ReportsUtil";
import {
  INCUBATION_SECTORS_OBJECT,
  SUB_SECTORS_OBJECT
} from "../../common/IncubateeProfileConstants";

const dimensions = [
  { value: "facility", title: "Cluster" },
  { value: "incubatee", title: "Incubatee" },
  { value: "incubationSector", title: "Sector" },
  { value: "incubationSubSector", title: "Sub Sector" },
  { value: "displayName", title: "Partition" },
  { value: "campus", title: "Campus" },
  { value: "building", title: "Building" },
  { value: "floor", title: "Floor" }
];

function reduce(row, aggRecord) {
  const incubateeList = aggRecord.incubateeList || {};
  if (!incubateeList[row.incubatee]) {
    incubateeList[row.incubatee] = true;
  }
  aggRecord.incubateeList = incubateeList;
  aggRecord.incubateeCount = Object.keys(aggRecord.incubateeList).length;
  aggRecord.totalArea = (aggRecord.totalArea || 0) + checkNaN(row.area);
  aggRecord.totalSeatCount =
    (aggRecord.totalSeatCount || 0) + checkNaN(row.seatCount);
  return aggRecord;
}

const calculations = [
  {
    title: "Incubatee Count",
    value: "incubateeCount",
    template: val => String(checkNaN(val)),
    sortBy: row => checkNaN(row.incubateeCount)
  },
  {
    title: "Total Fixed Rental Area",
    value: "totalArea",
    template: val => String(checkNaN(val)),
    sortBy: row => checkNaN(row.totalArea)
  },
  {
    title: "Total Seat Count",
    value: "totalSeatCount",
    template: val => String(checkNaN(val)),
    sortBy: row => checkNaN(row.totalSeatCount)
  }
];

function dataTransformer(data) {
  data.forEach(record => {
    record.incubationSector =
      INCUBATION_SECTORS_OBJECT[record.incubationSector] ||
      record.incubationSector;
    record.incubationSubSector =
      SUB_SECTORS_OBJECT[record.incubationSubSector] ||
      record.incubationSubSector;
  });
  return data;
}

function IncubateeClusterInfoReport(props) {
  const activeDimensions = ["Cluster"];

  return (
    <PivotReport
      title="Incubatees By Cluster"
      url="/api/v1/dashboard/incubatee/cluster-detail"
      dimensions={dimensions}
      reduce={reduce}
      calculations={calculations}
      activeDimensions={activeDimensions}
      dataTransformer={dataTransformer}
    />
  );
}

export default IncubateeClusterInfoReport;
