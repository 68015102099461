import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchCurrentUserProfile } from "../../actions/login/LoginAction";
import ability from "../../auth/ability";
import Sidebar from "./Sidebar";
import Header from "./Header";

import { menuLinks } from "./data/sidebar";
import { headerLinks } from "./data/header";

import CampusList from "../custom/campus/CampusList";
import BuildingList from "../custom/building/BuildingList";
import AddBuilding from "../custom/building/AddBuilding";
import AddCampus from "../custom/campus/AddCampus";
import EditCampus from "../custom/campus/EditCampus";
import FloorList from "../custom/floor/FloorList";
import EditBuilding from "../custom/building/EditBuilding";
import PartitionList from "../custom/partition/PartitionList";
import OnboardList from "../onboard/OnboardList";
import LeaseList from "../lease/LeaseList";
import Lease from "../lease/Lease";
import Onboard from "../onboard/Onboard";
import Floor from "../custom/floor/Floor";
import Partition from "../custom/partition/Partition";
import IncubatorApp from "../onboard/IncubatorApp";
import IncubateeOnboard from "../onboard/IncubateeOnboard";
import IncubateeStartupProfile from "../incubatees/IncubateeStartupProfile";
import Incubatees from "../incubatees/Incubatees";
import IncubateePendingLeaseList from "../incubatees/IncubateePendingLeaseList";
import EditIncubateeProfileForm from "../incubatees/EditIncubateeProfileForm";
import ViewIncubatorForm from "../onboard/appform/ViewIncubatorForm";
import ChangePassword from "../user/ChangePassword";
import UserList from "../user/UserList";
import EditUser from "../user/EditUser";
import IndividualList from "../individual/IndividualList";
import ViewIndividual from "../individual/ViewIndividual";
import EditIndividualForm from "../individual/EditIndividualForm";
import OrganizationList from "../organization/OrganizationList";
import EditOrganizationForm from "../organization/EditOrganizationForm";
import Payment from "../finance/payment/Payment";
import PaymentList from "../finance/payment/PaymentList";
import ViewPayment from "../finance/payment/ViewPayment";
import PaymentAdjustmentList from "../finance/paymentAdjustment/PaymentAdjustmentList";
import PaymentAdjustment from "../finance/paymentAdjustment/PaymentAdjustment";
import ViewAdjustment from "../finance/paymentAdjustment/ViewAdjustment";
import ReportList from "../finance/reports/ReportList";
import ChargeList from "../finance/reports/ChargeList";
import AssetChargeList from "../finance/reports/AssetChargeList";
import ManualChargeList from "../finance/manualCharges/ManualChargeList";
import ManualCharge from "../finance/manualCharges/ManualCharge";
import ManualChargeViewForm from "../finance/manualCharges/ManualChargeViewForm";
import VoidPayment from "../finance/payment/VoidPayment";
import AccountList from "../finance/accountBalance/AccountList";
import InternalOrg from "../internalOrgs/InternalOrg";
import InternalOrgsList from "../internalOrgs/InternalOrgsList";
import StartupProfile from "../incubatees/exportStartupProfile/StartupProfile";

import LeaseRenewForm from "../lease/renew/LeaseRenewForm";
import LeaseTermination from "../lease/termination/LeaseTermination";
import LeaseTerminationList from "../lease/termination/LeaseTerminationList";
import ActiveLeases from "../lease/activeLeases/ActiveLeases";
import ClosedLeases from "../lease/closedLeases/ClosedLeases";
import LeaseRequests from "../lease/leaseRequest/LeaseRequests";
import CancelledLeaseRequests from "../lease/cancelledRequests/CancelledLeaseRequests";
import Dashboard from "../dashboard/Dashboard";
import IncubateeConversion from "../incubatees/incubateeConversion/IncubateeConversion";

import fetchUserPermissions from "../../auth/fetchUserPermissions";
import EquipmentList from "../equipment/EquipmentList";
import Equipment from "../equipment/Equipment";

import Can from "../../auth/can";
import AssetEnquiryList from "../equipment/rentalManagement/AssetEnquiryList";
import AssetEnquiry from "../equipment/rentalManagement/AssetEnquiry";
import DuplicateCheck from "../equipment/duplicateCheck/DuplicateCheck";
import RequestorOnboard from "../requestor-onboard/RequestorOnboard";
import CheckAssetAvailablity from "../assetAvailablity/CheckAssetAvailablity";
import AssetLeaseList from "../serviceManagement/assetRentLease/AssetLeaseList";
import AssetLease from "../serviceManagement/assetRentLease/AssetLease";
import RequestorOnboardList from "../requestor-onboard/RequestorOnboardList";
import AssetRelease from "../serviceManagement/assetRelease/AssetRelease";
import AssetReturn from "../serviceManagement/assetReturn/AssetReturn";
import FacilityClusters from "../custom/facilityClusters/FacilityClusters";
import FacilityClusterForm from "../custom/facilityClusters/FacilityClusterForm";

import FundingRoutes from "../funding/Route";

import AdvanceAdjustmentList from "../finance/advanceAdjustment/AdvanceAdjustmentList";
import AdvanceAdjustment from "../finance/advanceAdjustment/AdvanceAdjustment";
import Milestone from "../incubatees/milestone/Milestone";
import Graduation from "../incubatees/graduation/Graduation";
import GraduationRequestList from "../incubatees/graduation/GraduationRequestList";
import FinancialSearch from "../finance/search/FinancialSearch";
import ExpiredLeases from "../lease/closedLeases/ExpiredLeases";
import GraduatedIncubatees from "../incubatees/GraduatedIncubatees";
import ReportRoutes from "../reports/Route";
import ServiceMgmtRoutes from "../serviceManagement/Route";
import MonthlyReport from "../reports/MonthlyReport";
import UpcomingRenewals from "../lease/UpcomingRenewals";
import ConsolidatedReportList from "../finance/reports/ConsolidatedReportList";
import ChargeDetailsByMonth from "../finance/reports/ChargeDetailsByMonth";
import OnboardIncubateeForm from "../onboard/incubatee/Onboard";
import IncubateeProfile from "../incubatees/profile/IncubateeProfile";
import Individual from "../individual/Individual";
import Organization from "../organization/Organization";
import UnauthorizedPage from "./unauthorized.page";
import NewEvent from "../Events/NewEvent";
import EventDetails from "../Events/EventDetails";
import EventList from "../Events/EventList";
import EventSearch from "../Events/EventSearch";
import EventEdit from "../Events/EventEdit";
import RecentEvent from "../Events/RecentEvents";
import IncubatorUserList from "../user/incubator-user/IncubatorUserList";
import IncubatorUser from "../user/incubator-user/IncubatorUser";
import OtherUserList from "../user/other-user/OtherUserList";
import OtherUser from "../user/other-user/OtherUser";
import IncubateeDataExport from "../incubatees/incubateeDataExport/IncubateeDataExport";
import AccountClosureRequest from "../finance/accountClosureRequest/AccountClosureRequest";
import AwaitingRefund from "../finance/accountClosureRequest/list/AwaitingRefund";
import OpenAccountsClosureRequests from "../finance/accountClosureRequest/list/OpenAccountsClosureRequests";
import ClosedAccounts from "../finance/accountClosureRequest/list/ClosedAccounts";
import RejectedRequests from "../finance/accountClosureRequest/list/RejectedRequests";

function componentWithAuthorization(component, subject, action) {
  return (
    <>
      <Can do={action} on={subject}>
        {component}
      </Can>
      <Can not do={action} on={subject}>
        <UnauthorizedPage />
      </Can>
    </>
  );
}

function Home({ userName, authToken, menuLinks, pathname }) {
  return (
    <div className="wrapper">
      <Sidebar menuLinks={menuLinks} activePath={pathname} />
      <div className="main w-100">
        <Header
          headerLinks={headerLinks}
          userName={userName}
          authToken={authToken}
        />

        <div className="content">
          <div className="container-fluid p-0">
            <Switch>
              {/* Campus */}
              <Route
                exact
                path="/admin/campuses"
                component={() =>
                  componentWithAuthorization(
                    <CampusList authToken={authToken} />,
                    "Campus",
                    "VIEW"
                  )
                }
              />

              <Route
                exact
                path="/admin/campuses/add"
                component={() =>
                  componentWithAuthorization(
                    <AddCampus authToken={authToken} />,
                    "Campus",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                path="/admin/campuses/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <EditCampus {...props} />,
                    "Campus",
                    "VIEW"
                  )
                }
              />
              {/* Building */}
              <Route
                exact
                path="/admin/buildings"
                component={() =>
                  componentWithAuthorization(
                    <BuildingList authToken={authToken} />,
                    "Building",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/buildings/add"
                component={() =>
                  componentWithAuthorization(
                    <AddBuilding authToken={authToken} />,
                    "Building",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                path="/admin/buildings/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <EditBuilding {...props} />,
                    "Building",
                    "VIEW"
                  )
                }
              />

              {/* Floor */}
              <Route
                exact
                path="/admin/floors"
                component={() =>
                  componentWithAuthorization(
                    <FloorList authToken={authToken} />,
                    "Floor",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="addFloor"
                path="/admin/floors/add"
                component={() =>
                  componentWithAuthorization(<Floor />, "Floor", "CREATE")
                }
              />
              <Route
                exact
                key="editFloor"
                path="/admin/floors/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <Floor {...props} />,
                    "Floor",
                    "VIEW"
                  )
                }
              />
              {/* Partion */}
              <Route
                exact
                path="/admin/partitions"
                component={() =>
                  componentWithAuthorization(
                    <PartitionList authToken={authToken} />,
                    "Partition",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="addPartition"
                path="/admin/partitions/add"
                component={() =>
                  componentWithAuthorization(
                    <Partition />,
                    "Partition",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="editPartition"
                path="/admin/partitions/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <Partition {...props} />,
                    "Partition",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/inc-app-form"
                component={() => <IncubatorApp />}
              />
              <Route
                exact
                path="/admin/incubatoronboard"
                component={() =>
                  componentWithAuthorization(
                    <OnboardList authToken={authToken} />,
                    "IncubatorAppForm",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="add"
                path="/admin/incubatoronboard/add/:id"
                component={props => (
                  <Onboard {...props} authToken={authToken} />
                )}
              />

              <Route
                exact
                key="edit"
                path="/onboard/incubatees/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <OnboardIncubateeForm {...props} />,
                    "IncubateeOnboardingForm",
                    "VIEW"
                  )
                }
              />

              <Route
                exact
                path="/onboard/incubatees"
                component={props =>
                  componentWithAuthorization(
                    <IncubateeOnboard {...props} />,
                    "IncubateeOnboardingForm",
                    "VIEW"
                  )
                }
              />

              {/* Lease */}
              <Route
                exact
                path="/admin/lease"
                component={() =>
                  componentWithAuthorization(
                    <LeaseList authToken={authToken} />,
                    "Lease",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="addLease"
                path="/admin/lease/add"
                component={() =>
                  componentWithAuthorization(
                    <Lease authToken={authToken} />,
                    "Lease",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="editLease"
                path="/admin/lease/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <Lease {...props} authToken={authToken} />,
                    "Lease",
                    "UPDATE"
                  )
                }
              />
              <Route
                exact
                key="renewLease"
                path="/admin/lease/renew/:id"
                component={props =>
                  componentWithAuthorization(
                    <LeaseRenewForm {...props} />,
                    "Lease",
                    "RENEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/lease/terminations"
                component={() =>
                  componentWithAuthorization(
                    <LeaseTerminationList />,
                    "LeaseTerminationRequest",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="newTermination"
                path="/admin/lease/termination/new"
                component={() =>
                  componentWithAuthorization(
                    <LeaseTermination />,
                    "LeaseTerminationRequest",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="editTermination"
                path="/admin/lease/termination/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <LeaseTermination {...props} />,
                    "LeaseTerminationRequest",
                    "UPDATE"
                  )
                }
              />
              <Route
                exact
                path="/admin/active/leases"
                component={() =>
                  componentWithAuthorization(<ActiveLeases />, "Lease", "VIEW")
                }
              />
              <Route
                exact
                path="/admin/closed/leases"
                component={() =>
                  componentWithAuthorization(<ClosedLeases />, "Lease", "VIEW")
                }
              />
              <Route
                exact
                path="/admin/expired/leases"
                component={() =>
                  componentWithAuthorization(<ExpiredLeases />, "Lease", "VIEW")
                }
              />
              <Route
                exact
                path="/admin/upcoming-renewals"
                component={() =>
                  componentWithAuthorization(
                    <UpcomingRenewals />,
                    "Lease",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/request/leases"
                component={() =>
                  componentWithAuthorization(<LeaseRequests />, "Lease", "VIEW")
                }
              />
              <Route
                exact
                path="/admin/cancelled/leases/requests"
                component={() =>
                  componentWithAuthorization(
                    <CancelledLeaseRequests />,
                    "Lease",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/incubatees"
                component={() =>
                  componentWithAuthorization(
                    <Incubatees />,
                    "Incubatee",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/incubatees/pending-lease"
                component={() =>
                  componentWithAuthorization(
                    <IncubateePendingLeaseList />,
                    "Incubatee",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/graduated/incubatees"
                component={() =>
                  componentWithAuthorization(
                    <GraduatedIncubatees />,
                    "Incubatee",
                    "VIEW"
                  )
                }
              />
              {/* New incubatee  with formik form */}
              <Route
                exact
                path="/incubatee/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <IncubateeProfile {...props} />,
                    "Incubatee",
                    "UPDATE"
                  )
                }
              />
              {/* Old incubatee  */}
              <Route
                exact
                path="/admin/incubatee/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <EditIncubateeProfileForm {...props} />,
                    "Incubatee",
                    "UPDATE"
                  )
                }
              />
              <Route
                exact
                path="/admin/incubatee/profile/:id"
                component={props =>
                  componentWithAuthorization(
                    <IncubateeStartupProfile {...props} />,
                    "Incubatee",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/incubatee/convert/:id"
                component={props =>
                  componentWithAuthorization(
                    <IncubateeConversion {...props} />,
                    "Organization",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                path="/admin/view/incubator/form/:id"
                component={props =>
                  componentWithAuthorization(
                    <ViewIncubatorForm {...props} />,
                    "IncubatorAppForm",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/change-password"
                component={() => <ChangePassword />}
              />
              <Route
                exact
                path="/admin/users"
                component={props => <UserList {...props} />}
              />
              <Route
                exact
                key="addUser"
                path="/admin/users/add"
                component={() => <EditUser />}
              />
              <Route
                exact
                key="editUser"
                path="/admin/users/edit/:id"
                component={props => <EditUser {...props} />}
              />

              <Route
                exact
                path="/incubator-users"
                component={() => <IncubatorUserList />}
              />

              <Route
                exact
                key="new-incubator-user"
                path="/incubator-users/new"
                component={() => <IncubatorUser />}
              />

              <Route
                exact
                key="edit-incubator-user"
                path="/incubator-users/edit/:id"
                component={props => <IncubatorUser {...props} />}
              />

              <Route
                exact
                path="/external-users"
                component={() => <OtherUserList />}
              />

              <Route
                exact
                key="new-external-user"
                path="/external-users/new"
                component={() => <OtherUser />}
              />

              <Route
                exact
                key="edit-external-user"
                path="/external-users/edit/:id"
                component={props => <OtherUser {...props} />}
              />

              <Route
                exact
                path="/admin/individuals"
                component={() => <IndividualList />}
              />

              <Route
                exact
                key="viewIndividualProfile"
                path="/admin/individuals/view/:id"
                component={props => <ViewIndividual {...props} />}
              />
              <Route
                exact
                key="newIndividualProfile"
                path="/admin/individuals/new"
                component={() => <EditIndividualForm />}
              />

              {/* New component */}
              <Route
                exact
                key="editIndividualProfile"
                path="/individuals/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <Individual {...props} />,
                    "Individual",
                    "VIEW"
                  )
                }
              />

              <Route
                exact
                path="/admin/organizations"
                component={() => <OrganizationList />}
              />

              {/* New component with formik*/}
              <Route
                exact
                path="/organizations/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <Organization {...props} />,
                    "Organization",
                    "VIEW"
                  )
                }
              />
              {/* Old component */}
              <Route
                exact
                path="/admin/organizations/edit/:id"
                component={props => <EditOrganizationForm {...props} />}
              />
              <Route
                exact
                key="newPayment"
                path="/admin/finance/payment/new"
                component={props =>
                  componentWithAuthorization(
                    <Payment {...props} />,
                    "Payment",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="voidPayment"
                path="/admin/finance/payment/void/:id"
                component={props =>
                  componentWithAuthorization(
                    <VoidPayment {...props} />,
                    "Payment",
                    "UPDATE"
                  )
                }
              />
              <Route
                exact
                key="paymentList"
                path="/admin/finance/payments"
                component={() =>
                  componentWithAuthorization(<PaymentList />, "Payment", "VIEW")
                }
              />
              <Route
                exact
                key="viewPayment"
                path="/admin/finance/payments/view/:id"
                component={props =>
                  componentWithAuthorization(
                    <ViewPayment {...props} />,
                    "Payment",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/finance/adjustments/payments"
                component={() =>
                  componentWithAuthorization(
                    <PaymentAdjustmentList />,
                    "Adjustment",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="addAdjustment"
                path="/admin/finance/adjustments/add"
                component={() =>
                  componentWithAuthorization(
                    <PaymentAdjustment />,
                    "Adjustment",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="viewAdjustment"
                path="/admin/finance/adjustments/view/:id"
                component={props =>
                  componentWithAuthorization(
                    <ViewAdjustment {...props} />,
                    "Adjustment",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/finance/reports"
                component={() =>
                  componentWithAuthorization(
                    <ReportList />,
                    "FinChargeReport",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/finance/reports/:id/charges"
                component={props =>
                  componentWithAuthorization(
                    <ChargeList {...props} />,
                    "FinChargeReport",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/finance/asset-reports/:id/charges"
                component={props => <AssetChargeList {...props} />}
              />
              <Route
                exact
                path="/admin/finance/monthly-reports/:id"
                component={props =>
                  componentWithAuthorization(
                    <MonthlyReport {...props} />,
                    "FinChargeReport",
                    "VIEW"
                  )
                }
              />

              <Route
                exact
                path="/admin/finance/manual-charges"
                component={() =>
                  componentWithAuthorization(
                    <ManualChargeList />,
                    "FinCharge",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/finance/manual-charges/new"
                component={() =>
                  componentWithAuthorization(
                    <ManualCharge />,
                    "FinCharge",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                path="/admin/finance/manual-charges/view/:id"
                component={props =>
                  componentWithAuthorization(
                    <ManualChargeViewForm {...props} />,
                    "FinCharge",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/finance/accounts"
                component={() =>
                  componentWithAuthorization(<AccountList />, "Account", "VIEW")
                }
              />
              <Route
                exact
                path="/admin/internal/orgs"
                component={() =>
                  componentWithAuthorization(
                    <InternalOrgsList />,
                    "IncubatorOrg",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="newIncubator"
                path="/admin/internal/orgs/new"
                component={() =>
                  componentWithAuthorization(
                    <InternalOrg />,
                    "IncubatorOrg",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="editIncubator"
                path="/admin/internal/orgs/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <InternalOrg {...props} />,
                    "IncubatorOrg",
                    "UPDATE"
                  )
                }
              />
              <Route
                exact
                path="/admin/incubatee/startup-profile"
                component={() =>
                  componentWithAuthorization(
                    <StartupProfile />,
                    "Incubatee",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/dashboard"
                component={props =>
                  componentWithAuthorization(
                    <Dashboard {...props} />,
                    "Lease",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                path="/assets"
                component={() =>
                  componentWithAuthorization(
                    <EquipmentList />,
                    "Equipment",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="newEquipment"
                path="/assets/new"
                component={() =>
                  componentWithAuthorization(
                    <Equipment />,
                    "Equipment",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="editEquipment"
                path="/assets/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <Equipment {...props} />,
                    "Equipment",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/asset/enquiries"
                component={props => <AssetEnquiryList {...props} />}
              />
              <Route
                exact
                path="/check/asset/availablity/:id"
                component={props => <CheckAssetAvailablity {...props} />}
              />
              <Route
                exact
                path="/asset/enquiry-form/:id"
                component={props => <AssetEnquiry {...props} />}
              />
              <Route
                exact
                path="/asset/requestor-onboard-form/:id"
                component={props => <RequestorOnboard {...props} />}
              />
              <Route
                exact
                path="/asset/requestor-onboards"
                component={() => <RequestorOnboardList />}
              />
              <Route
                exact
                path="/asset/duplicate-check/:id"
                component={props => <DuplicateCheck {...props} />}
              />
              <Route
                exact
                path="/asset/leases"
                component={() => <AssetLeaseList />}
              />
              <Route
                exact
                path="/asset/lease/:id"
                component={props => <AssetLease {...props} />}
              />
              <Route
                exact
                key="assetRelease"
                path="/asset/release"
                component={() => <AssetRelease />}
              />
              <Route
                exact
                key="assetReleaseWithId"
                path="/asset/release/:id"
                component={props => <AssetRelease {...props} />}
              />
              <Route
                exact
                path="/facility-clusters"
                component={() =>
                  componentWithAuthorization(
                    <FacilityClusters />,
                    "FacilityCluster",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                key="assetReturn"
                path="/asset/return"
                component={() => <AssetReturn />}
              />
              <Route
                exact
                key="newFacility"
                path="/facility-clusters/new"
                component={() =>
                  componentWithAuthorization(
                    <FacilityClusterForm />,
                    "FacilityCluster",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="assetReturnWithId"
                path="/asset/return/:id"
                component={props => <AssetReturn {...props} />}
              />
              <Route
                exact
                key="editFacility"
                path="/facility-clusters/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <FacilityClusterForm {...props} />,
                    "FacilityCluster",
                    "UPDATE"
                  )
                }
              />
              {/* {events} */}
              <Route
                exact
                path="/events"
                component={() =>
                  componentWithAuthorization(<EventList />, "Event", "VIEW")
                }
              />
              <Route
                exact
                path="/events/recent"
                component={() =>
                  componentWithAuthorization(<RecentEvent />, "Event", "VIEW")
                }
              />
              <Route
                exact
                path="/events/search"
                component={props =>
                  componentWithAuthorization(
                    <EventSearch {...props} />,
                    "Event",
                    "VIEW"
                  )
                }
              />

              <Route
                exact
                path="/events/new"
                component={() =>
                  componentWithAuthorization(<NewEvent />, "Event", "CREATE")
                }
              />
              <Route
                exact
                path="/events/:id"
                component={props =>
                  componentWithAuthorization(
                    <EventDetails {...props} />,
                    "Event",
                    "VIEW"
                  )
                }
              />

              <Route
                exact
                path="/events/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <EventEdit {...props} />,
                    "Event",
                    "UPDATE"
                  )
                }
              />

              <Route path="/service-mgmt" component={ServiceMgmtRoutes} />
              <Route path="/funding" component={FundingRoutes} />
              <Route path="/reports" component={ReportRoutes} />

              <Route
                exact
                path="/finance/adjustment/advances"
                component={() => <AdvanceAdjustmentList />}
              />
              <Route
                exact
                key="addAdjustmentAdvance"
                path="/finance/adjustment/advances/add"
                component={() => <AdvanceAdjustment />}
              />
              <Route
                exact
                key="viewAdjustmentAdvance"
                path="/finance/adjustment/advances/view/:id"
                component={props => <AdvanceAdjustment {...props} />}
              />
              <Route
                key="newMilestone"
                exact
                path="/add-milestone/:incubateeId"
                component={props =>
                  componentWithAuthorization(
                    <Milestone {...props} />,
                    "Milestone",
                    "CREATE"
                  )
                }
              />
              <Route
                exact
                key="graduationForm"
                path="/incubatee/graduation/form/:id"
                component={props =>
                  componentWithAuthorization(
                    <Graduation {...props} />,
                    "GraduationRequest",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/admin/incubatees/graduations"
                component={() =>
                  componentWithAuthorization(
                    <GraduationRequestList />,
                    "GraduationRequest",
                    "VIEW"
                  )
                }
              />
              <Route
                key="finSearch"
                exact
                path="/finance/search"
                component={() =>
                  componentWithAuthorization(
                    <FinancialSearch />,
                    "Payment",
                    "VIEW"
                  )
                }
              />

              <Route
                key="new"
                exact
                path="/finance/consolidated-report"
                component={() =>
                  componentWithAuthorization(
                    <ConsolidatedReportList />,
                    "FinChargeReport",
                    "VIEW"
                  )
                }
              />

              <Route
                key="new"
                exact
                path="/finance/consolidated-report/:startDate/charges"
                component={props =>
                  componentWithAuthorization(
                    <ChargeDetailsByMonth {...props} />,
                    "FinChargeReport",
                    "VIEW"
                  )
                }
              />
              <Route
                key="new"
                exact
                path="/admin/incubatee/data-export"
                component={props =>
                  componentWithAuthorization(
                    <IncubateeDataExport {...props} />,
                    "Incubatee",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/finance/account/closure-requests/new"
                component={props =>
                  componentWithAuthorization(
                    <AccountClosureRequest {...props} />,
                    "AccountClosureRequest",
                    "VIEW"
                  )
                }
              />
              <Route
                key="edit"
                exact
                path="/finance/account/closure-requests/edit/:id"
                component={props =>
                  componentWithAuthorization(
                    <AccountClosureRequest {...props} />,
                    "AccountClosureRequest",
                    "UPDATE"
                  )
                }
              />
              <Route
                exact
                path="/finance/account/awaiting-refund"
                component={props =>
                  componentWithAuthorization(
                    <AwaitingRefund {...props} />,
                    "AccountClosureRequest",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/finance/account/open-closure-requests"
                component={props =>
                  componentWithAuthorization(
                    <OpenAccountsClosureRequests {...props} />,
                    "AccountClosureRequest",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/finance/account/closed-requests"
                component={props =>
                  componentWithAuthorization(
                    <ClosedAccounts {...props} />,
                    "AccountClosureRequest",
                    "VIEW"
                  )
                }
              />
              <Route
                exact
                path="/finance/account/rejected-requests"
                component={props =>
                  componentWithAuthorization(
                    <RejectedRequests {...props} />,
                    "AccountClosureRequest",
                    "VIEW"
                  )
                }
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

function RedirectToPortal() {
  if (ability && ability.rules.length > 0) {
    const url = process.env.REACT_APP_INCUBATOR_PORTAL_URL;
    if (url) {
      window.location.href = url;
    }
    return <div>You are not authorized to view this page.</div>;
  }

  return <div className="text-center">Loading...</div>;
}

class HomeWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { userLoggedIn, userLogout } = this.props;

    if (userLogout) {
      return <Redirect to="/login" />;
    }

    if (userLoggedIn === null) {
      this.fetchCurrentUserProfile();
      fetchUserPermissions();
    }
  }

  fetchCurrentUserProfile = () => {
    this.props.fetchCurrentUserProfile();
  };

  render() {
    const { userLoggedIn, userLogout, userProfile } = this.props;

    if (userLoggedIn === null) {
      return null;
    }
    if (userLogout) {
      return <Redirect to="/login" />;
    }

    const userName = userProfile ? userProfile.firstName : "";

    let authToken = "";

    // TODO Remove it and replace it with real permission
    const action = "CREATE";
    const subject = "Lease";
    return (
      <>
        <Can do={action} on={subject}>
          <Home
            userName={userName}
            authToken={authToken}
            menuLinks={menuLinks}
            pathname={this.props.location.pathname}
          />
        </Can>
        <Can not do={action} on={subject}>
          <RedirectToPortal />
        </Can>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchCurrentUserProfile }, dispatch);

//Redux
const mapStateToProps = state => ({
  userLoggedIn: state.LoginReducer.userLoggedIn,
  userLogout: state.LoginReducer.userLoggedOut,
  userProfile: state.LoginReducer.data
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeWrapper);
