import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import { Field } from "formik";
import { getErrorCssClassName, ErrorComponent } from "../../common/FormikUtil";
import { PAYMENT_TRANSFER_TYPES } from "../../common/LookupConstants";
import { CARD_TYPES } from "../../common/LookupConstants";
import { CARD_ISSUERS } from "../../common/LookupConstants";
import { REFUND_PAYMENT_MODES } from "../../common/LookupConstants";
import { getDisplayDate } from "../../lease/DateUtil";

function PaymentDetails(props) {
  const {
    errors,
    touched,
    setFieldValue,
    values,
    chargeDetails,
    disabledField
  } = props;

  function getAmount() {
    let refund = chargeDetails.refund;
    if (
      (values.stage === "COMPLETE" || values.status === "REJECTED") &&
      chargeDetails.advance > chargeDetails.pendingBalance
    ) {
      refund = chargeDetails.advance - chargeDetails.pendingBalance;
    } else if (chargeDetails.advance === 0) {
      refund = chargeDetails.advance;
    }
    return refund;
  }
  const paymentMode = values.paymentMode;

  React.useEffect(() => setFieldValue("amount", chargeDetails.refund), []);

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-3 edit-page-section-header-text">
          Refund Payment Details
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
          Amount
        </label>
        <div className="col-12 col-lg-4 col-xl-4 pr-4">
          <Field
            name="amount"
            type="number"
            value={getAmount()}
            disabled
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "amount"
            )}`}
          />
          <ErrorComponent fieldName="amount" touched={touched} error={errors} />
        </div>
      </div>

      {/* Payment Mode */}
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
          Payment Mode
        </label>
        <div className="col-12 col-lg-10 col-xl-10">
          {REFUND_PAYMENT_MODES.map((pm, i) => {
            return (
              <div
                key={i}
                className="custom-control custom-radio custom-control-inline"
              >
                <input
                  type="radio"
                  name={pm.key}
                  id={pm.key}
                  className="custom-control-input"
                  checked={values.paymentMode === pm.key ? true : false}
                  onChange={e => {
                    setFieldValue("paymentMode", e.target.name);
                    setFieldValue("bankName", "");
                    setFieldValue("paymentNotes", "");
                    setFieldValue("paymentDate", "");
                    setFieldValue("reference", "");
                    setFieldValue("collectedBy", "");
                  }}
                  disabled={disabledField}
                />
                <label className="custom-control-label" htmlFor={pm.key}>
                  {pm.value}
                </label>
              </div>
            );
          })}
        </div>
      </div>

      {paymentMode === ("REFUND_CHEQUE" || "REFUND_ONLINE_PAYMENT") && (
        // Bank Name
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Bank Name
          </label>
          <div className="col-12 col-lg-4 col-xl-4 pr-4">
            <Field
              name="bankName"
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touched,
                errors,
                "bankName"
              )}`}
              disabled={disabledField}
            />
            <ErrorComponent
              fieldName={`bankName`}
              touched={touched}
              error={errors}
            />
          </div>
        </div>
      )}

      {paymentMode === "REFUND_CHEQUE" && (
        <Fragment>
          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Cheque Number
            </label>
            <div className="col-12 col-lg-4 col-xl-4 pr-4">
              <Field
                name="chequeNumber"
                type="text"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "chequeNumber"
                )}`}
                disabled={disabledField}
              />
              <ErrorComponent
                fieldName="chequeNumber"
                touched={touched}
                error={errors}
              />
            </div>
          </div>

          {/* Cheque Date */}
          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Cheque Date
            </label>
            <div className="col-12 col-lg-4 col-xl-4 pr-4">
              <DatePicker
                value={getDisplayDate(values.chequeDate) || ""}
                selected={values.chequeDate || null}
                onChange={e => {
                  setFieldValue(`chequeDate`, e);
                }}
                utcOffset={0}
                placeholderText="dd-mm-yyyy"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "chequeDate"
                )}`}
                disabled={disabledField}
                dropdownMode="select"
              />
              <ErrorComponent
                fieldName="chequeDate"
                touched={touched}
                error={errors}
              />
            </div>
          </div>
        </Fragment>
      )}

      {paymentMode === "REFUND_ONLINE_PAYMENT" && (
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Transfer Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4 pr-4">
            <Field
              component="select"
              name="paymentTransferType"
              className={`custom-select custom-select-sm ${getErrorCssClassName(
                touched,
                errors,
                "paymentTransferType"
              )}`}
              disabled={disabledField}
            >
              <option value="">Select...</option>
              {PAYMENT_TRANSFER_TYPES.map((pt, i) => (
                <option key={i} value={pt.key}>
                  {pt.value}
                </option>
              ))}
            </Field>
            <ErrorComponent
              fieldName="paymentTransferType"
              touched={touched}
              error={errors}
            />
          </div>
        </div>
      )}

      {paymentMode === "REFUND_CARD_PAYMENT" && (
        <Fragment>
          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Card Type
            </label>
            <div className="col-12 col-lg-4 col-xl-4 pr-4">
              <Field
                component="select"
                name="cardType"
                className={`custom-select custom-select-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "cardType"
                )}`}
                disabled={disabledField}
              >
                <option value="">Select...</option>
                {CARD_TYPES.map((pt, i) => (
                  <option key={i} value={pt.key}>
                    {pt.value}
                  </option>
                ))}
              </Field>
              <ErrorComponent
                fieldName="cardType"
                touched={touched}
                error={errors}
              />
            </div>
          </div>
          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Card Issue
            </label>
            <div className="col-12 col-lg-4 col-xl-4 pr-4">
              <Field
                component="select"
                name="cardIssuer"
                className={`custom-select custom-select-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "cardIssuer"
                )}`}
                disabled={disabledField}
              >
                <option value="">Select...</option>
                {CARD_ISSUERS.map((pt, i) => (
                  <option key={i} value={pt.key}>
                    {pt.value}
                  </option>
                ))}
              </Field>
              <ErrorComponent
                fieldName={`cardIssuer`}
                touched={touched}
                error={errors}
                datePicker
              />
            </div>
          </div>
        </Fragment>
      )}

      {/* Payment Date */}
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
          Payment Date
        </label>
        <div className="col-12 col-lg-4 col-xl-4 pr-4">
          <DatePicker
            value={getDisplayDate(values.paymentDate) || ""}
            selected={values.paymentDate || null}
            onChange={e => {
              setFieldValue(`paymentDate`, e);
            }}
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "paymentDate"
            )}`}
            dropdownMode="select"
            disabled={disabledField}
          />
          <ErrorComponent
            fieldName={`paymentDate`}
            touched={touched}
            error={errors}
            datePicker
          />
        </div>
      </div>

      {/* Collected By */}
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
          Collected By
        </label>
        <div className="col-12 col-lg-4 col-xl-4 pr-4">
          <Field
            name="collectedBy"
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "collectedBy"
            )}`}
            disabled={disabledField}
          />
          <ErrorComponent
            fieldName={`collectedBy`}
            touched={touched}
            error={errors}
          />
        </div>
      </div>

      {/* Reference */}
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
          Reference
        </label>
        <div className="col-12 col-lg-4 col-xl-4 pr-4">
          <Field
            name="reference"
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "reference"
            )}`}
            disabled={disabledField}
          />
          <ErrorComponent
            fieldName={`reference`}
            touched={touched}
            error={errors}
          />
        </div>
      </div>

      {/* Notes */}
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
          Notes
        </label>

        <div className="col-12 col-lg-4 col-xl-4 pr-4">
          <Field
            component="textarea"
            name="paymentNotes"
            type="text"
            className={`form-control form-control-sm ${getErrorCssClassName(
              touched,
              errors,
              "paymentNotes"
            )}`}
            disabled={disabledField}
          />
          <ErrorComponent
            fieldName="paymentNotes"
            touched={touched}
            error={errors}
          />
        </div>
      </div>
    </>
  );
}

export default PaymentDetails;
