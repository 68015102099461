export const schema1 = {
  definitions: {},
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "http://example.com/root.json",
  type: "object",
  title: "Root",
  required: [
    "name",
    "type",
    "netWorth",
    "address",
    "tags",
    "personalDetails",
    "promoters",
    "schemes"
  ],
  properties: {
    name: {
      $id: "#/properties/name",
      type: "string",
      title: "Name",
      default: "",
      examples: ["Test Company"],
      pattern: "^(.*)$"
    },
    type: {
      $id: "#/properties/type",
      type: "string",
      title: "Type",
      default: "",
      examples: ["Company"],
      pattern: "^(.*)$"
    },
    netWorth: {
      $id: "#/properties/netWorth",
      type: "number",
      title: "Networth",
      default: 0.0,
      examples: [105.5]
    },
    address: {
      $id: "#/properties/address",
      type: "string",
      title: "Address",
      default: "",
      examples: ["cbe"],
      pattern: "^(.*)$"
    },
    tags: {
      $id: "#/properties/tags",
      type: "array",
      title: "Tags",
      items: {
        $id: "#/properties/tags/items",
        type: "string",
        title: "Items",
        default: "",
        examples: ["nano", "bio"],
        pattern: "^(.*)$"
      }
    },
    personalDetails: {
      $id: "#/properties/personalDetails",
      type: "object",
      title: "Personaldetails",
      required: ["aadhaar", "phones", "email"],
      properties: {
        aadhaar: {
          $id: "#/properties/personalDetails/properties/aadhaar",
          type: "string",
          title: "Aadhaar",
          default: "",
          examples: ["21312131213"],
          pattern: "^(.*)$"
        },
        phones: {
          $id: "#/properties/personalDetails/properties/phones",
          type: "array",
          title: "Phones",
          items: {
            $id: "#/properties/personalDetails/properties/phones/items",
            type: "object",
            title: "Items",
            required: ["type", "number"],
            properties: {
              type: {
                $id:
                  "#/properties/personalDetails/properties/phones/items/properties/type",
                type: "string",
                title: "Type",
                default: "",
                examples: ["Mobile"],
                pattern: "^(.*)$"
              },
              number: {
                $id:
                  "#/properties/personalDetails/properties/phones/items/properties/number",
                type: "string",
                title: "Number",
                default: "",
                examples: ["0212123-121267"],
                pattern: "^(.*)$"
              }
            }
          }
        },
        email: {
          $id: "#/properties/personalDetails/properties/email",
          type: "string",
          title: "Email",
          default: "",
          examples: ["abcd@gmail.com"],
          pattern: "^(.*)$"
        }
      }
    },
    promoters: {
      $id: "#/properties/promoters",
      type: "array",
      title: "Promoters",
      items: {
        $id: "#/properties/promoters/items",
        type: "object",
        title: "Items",
        required: ["active", "name", "designation"],
        properties: {
          active: {
            $id: "#/properties/promoters/items/properties/active",
            type: "boolean",
            title: "Active",
            default: false,
            examples: [false]
          },
          name: {
            $id: "#/properties/promoters/items/properties/name",
            type: "string",
            title: "Name",
            default: "",
            examples: ["Sathish"],
            pattern: "^(.*)$"
          },
          designation: {
            $id: "#/properties/promoters/items/properties/designation",
            type: "string",
            title: "Designation",
            default: "",
            examples: ["MD"],
            pattern: "^(.*)$"
          }
        }
      }
    },
    schemes: {
      $id: "#/properties/schemes",
      type: "array",
      title: "Schemes",
      items: {
        $id: "#/properties/schemes/items",
        type: "object",
        title: "Items",
        required: ["name", "year", "prayasForm"],
        properties: {
          name: {
            $id: "#/properties/schemes/items/properties/name",
            type: "string",
            title: "Name",
            default: "",
            examples: ["PRAYAS"],
            pattern: "^(.*)$"
          },
          year: {
            $id: "#/properties/schemes/items/properties/year",
            type: "integer",
            title: "Year",
            default: 0,
            examples: [2018]
          },
          prayasForm: {
            $id: "#/properties/schemes/items/properties/prayasForm",
            type: "object",
            title: "Prayasform",
            required: ["amount"],
            properties: {
              amount: {
                $id:
                  "#/properties/schemes/items/properties/prayasForm/properties/amount",
                type: "integer",
                title: "Amount",
                default: 0,
                examples: [10000]
              }
            }
          }
        }
      }
    }
  }
};

export const schema2 = {
  definitions: {},
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "http://example.com/root.json",
  type: "object",
  title: "Root",
  required: ["name", "type", "doi", "address"],
  properties: {
    name: {
      $id: "#/properties/name",
      type: "string",
      title: "Name",
      default: "",
      examples: ["Test Corporation"],
      pattern: "^(.*)$"
    },
    type: {
      $id: "#/properties/type",
      type: "string",
      title: "Type",
      default: "",
      examples: ["Company"],
      pattern: "^(.*)$"
    },
    doi: {
      $id: "#/properties/doi",
      type: "string",
      title: "Doi",
      default: "",
      examples: ["2018-02-28"],
      pattern: "^(.*)$"
    },
    address: {
      $id: "#/properties/address",
      type: "string",
      title: "Address",
      default: "",
      examples: ["cbe"],
      pattern: "^(.*)$"
    }
  }
};
