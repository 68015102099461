import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { ADJUSTMENTS_API } from "../../common/Constants";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import {
  changeToINR,
  ADJUSTMENT_PAYMENT_STATUS_LIST_OBJECT
} from "../../common/LookupConstants";
import TableList from "../../common/TableList";
import Can from "../../../auth/can";

class PaymentAdjustmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adjustPmtList: null
    };
  }
  componentDidMount() {
    this.getAll();
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    const url = ADJUSTMENTS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ adjustPmtList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  nameFormatter = (adjustmentTo, payment) => {
    if (!payment) {
      return null;
    }
    let name = "";
    if (payment.entityType === "INDIVIDUAL") {
      const names = [];
      if (adjustmentTo.firstName) {
        names.push(adjustmentTo.firstName);
      }
      if (adjustmentTo.lastName) {
        names.push(adjustmentTo.lastName);
      }
      name = names.join(" ");
    } else {
      name = adjustmentTo.name;
    }
    return name;
  };

  dateFormatter = (cell, row) => {
    return getDisplayDate(convertNumberToDate(cell));
  };

  actionsFormater = (cell, row) => {
    if (!row) {
      return null;
    }
    let viewButton = (
      <Can do="VIEW" on="Adjustment">
        <Link to={`/admin/finance/adjustments/view/${row._id}`} role="button">
          <i
            className="fas fa-eye fa-lg action-icon"
            datatoggle="tooltip"
            title="View"
          />
        </Link>
      </Can>
    );
    return viewButton;
  };

  render() {
    const { adjustPmtList } = this.state;

    const columnsAdjustmentPayments = [
      {
        dataField: "adjustmentTo",
        text: "Name",
        sort: true,
        formatter: this.nameFormatter,
        csvFormatter: this.nameFormatter,
        filterValue: this.nameFormatter
      },
      {
        dataField: "date",
        text: "Date",
        formatter: this.dateFormatter,
        csvFormatter: this.dateFormatter
      },
      {
        dataField: "adjustmentAmount",
        text: "Amount",
        formatter: changeToINR,
        headerClasses: "text-right",
        classes: "text-right",
        csvType: Number
      },
      {
        dataField: "status",
        text: "Status",
        headerClasses: "text-center",
        classes: "text-center",
        formatter: cell =>
          ADJUSTMENT_PAYMENT_STATUS_LIST_OBJECT[cell] || "Unspecified",
        csvFormatter: cell =>
          ADJUSTMENT_PAYMENT_STATUS_LIST_OBJECT[cell] || "Unspecified",
        filterValue: cell =>
          ADJUSTMENT_PAYMENT_STATUS_LIST_OBJECT[cell] || "Unspecified"
      },
      {
        dataField: "actions",
        text: "Actions",
        headerClasses: "text-center",
        classes: "text-center",
        isDummyField: true,
        formatter: this.actionsFormater,
        csvExport: false
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsAdjustmentPayments}
          data={adjustPmtList}
          noDataIndication="No Adjustment List found."
          sizePerPage="10"
          headerName="Adjustment List"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default PaymentAdjustmentList;

const RenderCustomButton = props => {
  return (
    <Can do="CREATE" on="Adjustment">
      <Link
        to="/admin/finance/adjustments/add"
        role="button"
        className="btn btn-sm text-white bg-pink"
      >
        New
      </Link>
    </Can>
  );
};
