import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableList from "../../common/TableList";
import AlertComponent from "../../common/AlertComponent";
import { INCUBATEE_TYPES_OBJECT } from "../../common/LookupConstants";
import {
  RESIDENTIAL_STATUS_OBJECT,
  GRADUATION_STATUS_OBJECT
} from "../../common/IncubateeProfileConstants";
import HttpUtil from "../../common/HttpUtil";
import { INCUBATEE_GRADUATION_INPROGRESS_API } from "../../common/Constants";
import { getDescription, getConcatenatedName} from "../../common/Util";

function getIncubateeName(cell, row) {
  if (!row) return null;
  const incubatee = row.incubatee || {};
  const url = `/incubatee/graduation/form/${row._id}`;
  return <Link to={url}>{incubatee.name}</Link>;
}

function getIncubateeNameFilter(cell, row) {
  if (!row) return null;
  const incubatee = row.incubatee;
  return incubatee.name || "Unspecified";
}

function getIncubateeType(cell, row) {
  if (!row) return null;
  const incubatee = row.incubatee || {};
  const incubateeType = incubatee.incubateeType;
  return INCUBATEE_TYPES_OBJECT[incubateeType] || "Unspecified";
}

function getrelationshipManager(cell, row) {
  if (!row) return null;

  let relationshipManager = row.incubatee.relationshipManager || {};
  return getConcatenatedName(
    relationshipManager.firstName,
    relationshipManager.lastName
  );
};

function getGraduationStatus(cell, row) {
  if (!row) return null;
  const incubatee = row.incubatee || {};
  const graduationStatus = incubatee.graduationStatus;
  return GRADUATION_STATUS_OBJECT[graduationStatus];
}

function getResidentialStatus(cell, row) {
  if (!row) return null;
  const incubatee = row.incubatee || {};
  const residentialStatus = incubatee.residentialStatus;
  return RESIDENTIAL_STATUS_OBJECT[residentialStatus];
}

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  if (!row) return null;
  return getDescription("IncubateeGraduation", row[formatExtraData], cell);
}

const GRADUATION_REQUEST_COLUMNS = [
  {
    dataField: "incubatee",
    text: "Incubatee",
    formatter: getIncubateeName,
    filterValue: getIncubateeNameFilter,
    csvFormatter: getIncubateeNameFilter
  },
  {
    dataField: "incubatee.relationshipManager",
    text: "Relationship Manager",
    sort: true,
    formatter: getrelationshipManager,
    filterValue: getrelationshipManager,
    csvFormatter: getrelationshipManager
  },
  {
    dataField: "incubatee.incubateeType",
    text: "Incubatee Type",
    sort: true,
    formatter: getIncubateeType,
    filterValue: getIncubateeType
  },

 
  {
    dataField: "incubatee.graduationStatus",
    text: "Graduation Status",
    sort: true,
    formatter: getGraduationStatus,
    filterValue: getGraduationStatus
  },

  {
    dataField: "incubatee.residentialStatus",
    text: "Residentital Status",
    sort: true,
    formatter: getResidentialStatus,
    filterValue: getResidentialStatus
  },

  {
    dataField: "status",
    text: "Request Status",
    formatter: statusFormatter,
    formatExtraData: "stage",
    filterValue: statusFormatter
  }
];
class GraduationRequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graduationRequests: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getGraduationRequests = () => {
    let url = `${INCUBATEE_GRADUATION_INPROGRESS_API}`;

    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ graduationRequests: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    this.getGraduationRequests();
  };

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={GRADUATION_REQUEST_COLUMNS}
          data={this.state.graduationRequests}
          noDataIndication="No Graduation found."
          sizePerPage="10"
          headerName="Graduation Requests"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default GraduationRequestList;
