import {
  USER_LOGIN_API,
  USER_LOGOUT_API,
  USERS_CURRENT_API
} from "../../components/common/Constants";

import HttpUtil from "../../components/common/HttpUtil";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";

export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED";

// actions should be below to pass apiUtil.js
export const loginActions = {
  success: LOGIN_SUCCESS,
  failed: AUTHENTICATE_USER,
  error: AUTHENTICATE_USER
};

export const logoutActions = {
  success: USER_LOGOUT_SUCCESS,
  failed: USER_LOGOUT_FAILED,
  error: USER_LOGOUT_FAILED
};

// Login User
export const loginUser = loginRequest => (dispatch, getState) => {
  let url = USER_LOGIN_API;

  const headers = { "Content-Type": "application/json" };
  return HttpUtil.post(
    url,
    headers,
    loginRequest,
    data => {
      return dispatch({ type: loginActions.success, data });
    },
    (data, status) => {
      return dispatch({
        type: loginActions.failed,
        data: data.message
      });
    },
    error => {
      return dispatch({
        type: loginActions.error,
        data: error.toString()
      });
    }
  );
};

// Logout User
export const logoutUser = () => (dispatch, getState) => {
  let url = USER_LOGOUT_API;

  const headers = { "Content-Type": "application/json" };

  return HttpUtil.post(
    url,
    headers,
    {},
    data => {
      return dispatch({ type: logoutActions.success, data });
    },
    (data, status) => {
      return dispatch({ type: logoutActions.failed, data: data.message });
    },
    error => {
      return dispatch({ type: logoutActions.error, data: error.toString() });
    }
  );
};

export const CURRENT_USER_PROFILE = "CURRENT_USER_PROFILE";

export const USER_PROFILE = {
  success: CURRENT_USER_PROFILE,
  failed: AUTHENTICATE_USER,
  error: AUTHENTICATE_USER
};
export const fetchCurrentUserProfile = () => {
  let url = USERS_CURRENT_API;

  const headers = { "Content-Type": "application/json" };

  return (dispatch, getState) => {
    HttpUtil.get(
      url,
      headers,
      data => {
        return dispatch({
          type: USER_PROFILE.success,
          data
        });
      },
      (data, status) => {
        return dispatch({
          type: USER_PROFILE.failed,
          data
        });
      },
      error => {
        return dispatch({
          type: USER_PROFILE.error,
          data: error.toString()
        });
      }
    );
  };
};
