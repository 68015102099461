import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { FLOOR_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import DefaultAlert from "../DefaultAlert";
import TableList from "../../common/TableList";
import { STATUS_LIST_OBJECT } from "../../common/LookupConstants";
import Can from "../../../auth/can";

export default class FloorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      floorList: null,

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    };
  }

  //React life cycle
  componentDidMount = () => {
    this.getFloorList();
  };

  // For api success return
  handleApiSuccess = data => {
    this.setState({ floorList: data });
  };

  // For api failed return
  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  // Get floors from server
  getFloorList = () => {
    const url = FLOOR_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    // Fetch api
    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  // Close bootstrap default alert box
  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  linkFormater = (cell, row) => {
    if (!row) {
      return null;
    }
    const url = `/admin/floors/edit/${row._id}`;
    return <Link to={url}>{row.floorName}</Link>;
  };

  getFloorName = (cell, row) => {
    if (!row) {
      return null;
    }
    return cell;
  };

  render() {
    const {
      floorList,

      //Default alert
      showAlert,
      alertMode,
      alertMessage
    } = this.state;

    const columnsFloor = [
      {
        dataField: "building.campus.name",
        text: "Campus  Name",
        sort: true
      },
      {
        dataField: "building.buildingName",
        text: "Building Name",
        sort: true
      },
      {
        dataField: "floorName",
        text: "Floor",
        sort: true,
        formatter: this.linkFormater,
        csvFormatter: this.getFloorName,
        filterValue: this.getFloorName
      },
      {
        dataField: "abbreviation",
        text: "Abbreviation",
        sort: true
      },
      {
        dataField: "status",
        text: "Status",
        formatter: cell => STATUS_LIST_OBJECT[cell],
        csvFormatter: cell => STATUS_LIST_OBJECT[cell],
        filterValue: cell => STATUS_LIST_OBJECT[cell]
      }
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/* show alert message  */}
            <DefaultAlert
              show={showAlert}
              alertMode={alertMode}
              message={alertMessage}
              closeAlert={this.closeDefaultAlert}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsFloor}
          data={floorList}
          noDataIndication="No Floor found."
          sizePerPage="10"
          headerName="Floor"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

const RenderCustomButton = props => {
  return (
    <Can I="CREATE" a="Floor">
      <Link
        to="/admin/floors/add"
        role="button"
        className="btn btn-sm text-white bg-pink"
      >
        New
      </Link>
    </Can>
  );
};
