import React, { Fragment } from "react";
import { Field, FieldArray } from "formik";
import ShowHideComponent from "./ShowHideComponent";
import { downloadDocument } from "../common/Util";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  return getError(touch, error, fieldName) ? "is-invalid" : "";
}

const IdDocs = props => {
  const { values, touched, errors, fieldName, setFieldValue, readOnly } = props;
  const idDocErrors = errors["fieldName"] || [];
  const idDocTouched = touched["fieldName"] || [];
  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name={fieldName}
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            <table className="table table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">ID Type</th>
                  <th scope="col">ID Document Number</th>
                  <th scope="col">Upload ID Document Copy</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {values[fieldName].map((idDoc, index) => {
                  const error = idDocErrors[index] || {};
                  const touch = idDocTouched[index] || {};
                  return (
                    <tr key={`row-${index}`}>
                      <td>
                        <Field
                          component="select"
                          className={`form-control form-control-sm ${getErrorCssClassName(
                            touch,
                            error,
                            "type"
                          )}`}
                          name={`${fieldName}.${index}.type`}
                          {...disabledField}
                        >
                          <option value="">Select...</option>
                          <option value="PAN">PAN</option>
                          <option value="AADHAAR">AADHAAR</option>>
                        </Field>
                        <div className="invalid-feedback">
                          {getError(touch, error, "type")}
                        </div>
                      </td>
                      <td>
                        <Field
                          type="text"
                          className={`form-control form-control-sm ${getErrorCssClassName(
                            touch,
                            error,
                            "number"
                          )}`}
                          name={`${fieldName}.${index}.number`}
                          {...disabledField}
                        />
                        <div className="invalid-feedback">
                          {getError(touch, error, "number")}
                        </div>
                      </td>
                      <td>
                        <input
                          type="file"
                          id="idProofUpload"
                          name="idProofUpload"
                          onChange={event => {
                            setFieldValue(
                              `${fieldName}.${index}.idProofUpload`,
                              event.currentTarget.files[0]
                            );
                          }}
                          {...disabledField}
                        />
                      </td>
                      <td>
                        <ShowHideComponent
                          show={idDoc.document && idDoc.document.link}
                        >
                          <button
                            name={`${fieldName}.${index}.download`}
                            className="btn btn-outline-info btn-sm mr-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download ID Proof"
                            type="button"
                            onClick={() =>
                              downloadDocument(
                                idDoc.document.link,
                                msg => window.alert(msg),
                                idDoc.document.filename
                              )
                            }
                          >
                            <i className="fas fa-download" />
                          </button>
                        </ShowHideComponent>
                        <ShowHideComponent show={!readOnly}>
                          <button
                            type="button"
                            className={`btn btn-outline-danger btn-sm`}
                            title="Remove"
                            onClick={() => remove(index)}
                          >
                            <i className="far fa-trash-alt " />
                          </button>
                        </ShowHideComponent>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ShowHideComponent show={!readOnly}>
              <div className="form-group row mt-3 mb-0">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-sm btn-info"
                    onClick={() =>
                      push({
                        number: "",
                        type: "",
                        status: "ACTIVE"
                      })
                    }
                  >
                    Add More
                  </button>
                </div>
              </div>
            </ShowHideComponent>
          </Fragment>
        );
      }}
    />
  );
};

export default IdDocs;
