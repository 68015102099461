import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import { Redirect, Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { getDisplayDate, convertDateToNumber } from "../../lease/DateUtil";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { MILESTONE_API } from "../../common/Constants";
import * as Yup from "yup";
import { MILESTONE_CATEGORY_TYPES } from "../../common/LookupConstants";
import ErrorPage from "../../common/error.page";

function getError(touched, error, fieldName) {
  return touched && touched[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touched, error, fieldName) {
  return getError(touched, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touched, error, datePicker } = props;
  const className = datePicker ? "auto-lookup-empty" : "invalid-feedback";

  return (
    <div className={className}>
      {touched && touched[`${fieldName}`] && error && error[`${fieldName}`]}
    </div>
  );
};

const MilestoneForm = props => {
  const { values, touched, errors, setFieldValue } = props;
  return (
    <Form>
      <div className="shadow card flex-fill box-border box-light-blue">
        <div className="card-header border-0 text-center pb-0">
          <span className="edit-page-title mb-0 float-left">
            <Link
              to={props.previousLocation}
              className="badge bg-white custom-btn-sm"
              data-toggle="tooltip"
              title="Back to Payment List Page"
            >
              <i className="fas fa-arrow-left" />
            </Link>
          </span>
          <h6 className="mb-0 d-inline-block">
            <strong>Create Milestone</strong>
          </h6>
        </div>

        <div className="card-body pt-3">
          <div className="form-group row mb-2">
            <label className="col-form-label col-form-label-sm col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Title
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                type="text"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "title"
                )}`}
                name="title"
              />
              <ErrorComponent
                fieldName={`title`}
                touched={touched}
                error={errors}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-form-label col-form-label-sm col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Date
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <DatePicker
                value={getDisplayDate(values.date) || ""}
                selected={values.date || null}
                onChange={date => {
                  setFieldValue("date", date);
                }}
                utcOffset={0}
                placeholderText="dd-mm-yyyy"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "date"
                )}`}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <ErrorComponent
                fieldName={`date`}
                touched={touched}
                error={errors}
                datePicker={true}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-form-label col-form-label-sm col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Category
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                component="select"
                type="text"
                className={`custom-select custom-select-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "category"
                )}`}
                name="category"
              >
                <option value="">Select...</option>
                {MILESTONE_CATEGORY_TYPES.map((opt, i) => (
                  <option key={i} value={opt.key}>
                    {opt.value}
                  </option>
                ))}
              </Field>
              <ErrorComponent
                fieldName={`category`}
                touched={touched}
                error={errors}
              />
            </div>
          </div>

          {values.category === "OTHER" ? (
            <div className="form-group row mb-2">
              <label className="col-form-label col-form-label-sm col-12 col-lg-2 col-xl-2 edit-page-label-required">
                Other Category
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${getErrorCssClassName(
                    touched,
                    errors,
                    "otherCategory"
                  )}`}
                  name="otherCategory"
                />
                <ErrorComponent
                  fieldName={`otherCategory`}
                  touched={touched}
                  error={errors}
                />
              </div>
            </div>
          ) : null}

          <div className="form-group row mb-2">
            <label className="col-form-label col-form-label-sm col-12 col-lg-2 col-xl-2 edit-page-label">
              Link
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                type="text"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "link"
                )}`}
                name="link"
              />
              <ErrorComponent
                fieldName={`link`}
                touched={touched}
                error={errors}
              />
            </div>
          </div>

          <div className="form-group row mb-2">
            <label className="col-form-label col-form-label-sm col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Description
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                component="textarea"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "description"
                )}`}
                name="description"
                rows="4"
              />
              <ErrorComponent
                fieldName={`description`}
                touched={touched}
                error={errors}
              />
            </div>
          </div>
          <div className="row mb-2 mt-3">
            <div className="col-md-12 text-center">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  setFieldValue("category", "");
                  setFieldValue("link", "");
                  setFieldValue("otherCategory", "");
                  setFieldValue("date", "");
                  setFieldValue("description", "");
                  setFieldValue("title", "");
                }}
              >
                Clear
              </button>
              &nbsp;
              <button className="btn btn-sm btn-primary" type="submit">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const MILESTONE_SCHEMA = Yup.object().shape({
  title: Yup.string()
    .label("Title")
    .required(),
  date: Yup.date()
    .label("Date")
    .required(),
  category: Yup.string()
    .label("Category")
    .required(),
  otherCategory: Yup.string().when("category", {
    is: "OTHER",
    then: Yup.string()
      .label("Other Category")
      .required()
  }),
  description: Yup.string()
    .label("Description")
    .required()
});

class Milestone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      milestone: {
        category: "",
        link: "",
        otherCategory: "",
        date: "",
        description: "",
        title: ""
      },
      previousLocation: null,
      redirectTo: false,
      permanentFailure: false
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getCreateRequest = values => {
    const incubateeId = this.props.match.params.incubateeId;
    let milestoneDate;
    if (values.date) {
      milestoneDate = new Date(values.date);
      milestoneDate = convertDateToNumber(milestoneDate);
    }
    return {
      entity: incubateeId,
      entityType: "Incubatee",
      category: values.category,
      link: values.link,
      otherCategory: values.otherCategory,
      date: milestoneDate,
      description: values.description,
      title: values.title
    };
  };

  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const milestoneRequest = this.getCreateRequest(values);

    const url = MILESTONE_API;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      milestoneRequest,
      data => {
        setSubmitting(false);
        this.setState({ redirectTo: true });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount() {
    if (this.props.location.search !== "") {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const previousLocation = params.get("from");
      this.setState({ previousLocation });
    }
  }

  render() {
    const { permanentFailure, alertMessage } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (this.state.previousLocation === null) {
      return null;
    }

    if (this.state.redirectTo) {
      const stateObj = {
        pathname: this.state.previousLocation,
        state: { milestoneCreated: true }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const props = {
      initialValues: this.state.milestone,
      validationSchema: MILESTONE_SCHEMA,
      previousLocation: this.state.previousLocation,
      handleSubmit: this.handleSubmit
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <Formik
          initialValues={props.initialValues}
          validationSchema={props.validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            props.handleSubmit(values, { setSubmitting })
          }
          render={renderProps => (
            <MilestoneForm
              {...renderProps}
              previousLocation={props.previousLocation}
            />
          )}
        />
      </Fragment>
    );
  }
}

export default Milestone;
