import React, { Component } from "react";
import HttpUtil from "../common/HttpUtil";
import { EVENTS_API } from "../common/Constants";
import AlertComponent from "../common/AlertComponent";
import { EVENT_TYPES_OBJECT } from "../common/LookupConstants";
import { downloadDocument } from "../common/Util";
import { Link } from "react-router-dom";

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {}
    };
  }
  getEvents = id => {
    let url = `${EVENTS_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => this.setState({ event: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getEvents(id);
    }

    if (this.props.location.search !== "") {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const previousLocation = params.get("from");
      this.setState({ previousLocation });
    }
  }

  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  okConfirmUpdate = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      userConfirmation: true
    });
  };
  getDate(date) {
    let dateObj = Date.parse(date);
    return new Intl.DateTimeFormat("en-IN").format(dateObj);
  }

  checkEmptyAndAdd(list, string) {
    if (string) {
      string = string.trim();
      if (string.length > 0) {
        list.push(string);
      }
    }
  }

  addHttpToWebsite(website) {
    if (website && website.indexOf("http") !== 0) {
      return `http://${website}`;
    }
    return website;
  }

  render() {
    const event = this.state.event;
    // console.log("event===>", event);
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    let flyerLink;
    let eventDetailClassName = "col-md-11 mx-auto";
    let eventLinkClassName = "col-md-12";

    if (event.flyer) {
      flyerLink = `/api/v1/events/${event._id}/flyer`;
      eventDetailClassName = "col-md-8";
    }

    if (event.brochure) {
      eventLinkClassName = "col-md-6 mt-4 mt-md-0";
    }

    const location = [];
    if (event.location) {
      this.checkEmptyAndAdd(location, event.location.name);
      this.checkEmptyAndAdd(location, event.location.address);
    }

    const organizer = [];

    if (event.organizer) {
      this.checkEmptyAndAdd(organizer, event.organizer.name);
      this.checkEmptyAndAdd(organizer, event.organizer.address);
    }

    return (
      <div className="row">
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <div className="col-md-12 col-lg-12 m-auto">
          <div className="card edit-page-container mb-3">
            <div className="card-header border-bottom-0 text-center mb-3 p-0">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#f8f9fa",
                  height: "75px"
                }}
              >
                <h4 style={{ color: "rgb(100, 122, 203)" }}>{event.name}</h4>
                {!event.publish && (
                  <span className="badge badge-primary ml-2 mb-2">Draft</span>
                )}
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="row pl-3 pr-3">
                <div className={eventDetailClassName}>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="edit-page-title mb-0">Event Type</label>
                      <div className="pre-normal">
                        {EVENT_TYPES_OBJECT[event.type]}
                      </div>
                    </div>

                    <div className="col-md-6 mt-4 mt-md-0">
                      <label className="edit-page-title mb-0">Event Date</label>
                      <div className="pre-normal">
                        <span>
                          {event.startDate && this.getDate(event.startDate)}
                        </span>
                        {event.endDate && <span className="ml-2">-</span>}
                        <span className="ml-2">
                          {event.endDate && this.getDate(event.endDate)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    {event.brochure && event._id ? (
                      <div className="col-md-6">
                        <label className="edit-page-title mb-0">
                          Event Brochure
                        </label>
                        <div>
                          <button
                            className="pre-normal btn btn-link btn-sm p-0"
                            onClick={() =>
                              downloadDocument(
                                `/api/v1/events/${event._id}/brochure`,
                                msg => window.alert(msg),
                                "brochure"
                              )
                            }
                          >
                            Download Brochure
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {event.website ? (
                      <div className={eventLinkClassName}>
                        <label className="edit-page-title mb-0">
                          Event Website
                        </label>
                        <div className="pre-normal">
                          <a
                            href={this.addHttpToWebsite(event.website)}
                            target="_blank"
                          >
                            {event.website}
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {event.location && event.location.name ? (
                    <div className="row mt-4">
                      <div className="pre-normal col-md-6">
                        <label className="edit-page-title mb-0">
                          Event Location
                        </label>
                        <div>{location.join(", ")}</div>
                      </div>
                    </div>
                  ) : null}

                  {event.organizer && event.organizer.name ? (
                    <div className="row mt-4">
                      <div className="pre-normal col-md-6">
                        <label className="edit-page-title mb-0">
                          Event Organizer
                        </label>
                        <div>{organizer.join(", ")}</div>
                      </div>
                    </div>
                  ) : null}
                  {event.description ? (
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <label className="edit-page-title mb-0">
                          Event Details
                        </label>
                        <pre className="pre-normal text-justify">
                          {event.description}
                        </pre>
                      </div>
                    </div>
                  ) : null}
                </div>
                {flyerLink ? (
                  <div className="col-md-4">
                    <a href={flyerLink} target="_blank">
                      <img
                        className="img-fluid rounded border"
                        src={flyerLink}
                      />
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="card-footer text-center border-0">
              <Link
                className="btn btn-sm btn-primary mt-2 text-center"
                to={`/events/edit/${event._id}`}
                role="button"
              >
                Edit
              </Link>

              <Link
                className="btn btn-sm btn-outline-secondary mt-2 ml-4"
                to={this.state.previousLocation || `/events/`}
                role="button"
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventDetails;
