import React, { Component, Fragment } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { getActionCss } from "../../../common/Util";
import Can from "../../../../auth/can";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

class RefundAssetReservation extends Component {
  render() {
    const { assetReservation } = this.props;

    return (
      <Fragment>
        <Formik
          enableReinitialize
          initialValues={{
            refundAmount:
              assetReservation.refundAmount ||
              assetReservation.reservationBalance ||
              0,
            refundNotes: assetReservation.refundNotes || ""
          }}
          onSubmit={(values, { setSubmitting }) => {
            return this.props.handleSubmit(values, {
              setSubmitting
            });
          }}
          render={({ errors, touched, values, setFieldValue }) => (
            <Form>
              <Fragment>
                <div className="row mt-5">
                  <div className="col-12 mb-3 edit-page-section-header-text">
                    Refund Details
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Refund Amount
                  </label>

                  <div className="col-12 col-lg-3 col-xl-3">
                    <Field
                      type="text"
                      name="refundAmount"
                      value={values.refundAmount || 0}
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "refundAmount"
                      )}
                      disabled={true}
                    />
                    <ErrorMessage
                      component="div"
                      name="refundAmount"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Refund Notes
                  </label>
                  <div className="col-12 col-lg-5 col-xl-5">
                    <Field
                      component="textarea"
                      name="refundNotes"
                      value={values.refundNotes || ""}
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "refundNotes"
                      )}
                      rows="3"
                      disabled={
                        assetReservation.stage === "COMPLETE" &&
                        assetReservation.status === "CLOSED"
                      }
                    />

                    <ErrorMessage
                      component="div"
                      name="refundNotes"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="row m-5">
                  <div className="col-12 text-center">
                    {assetReservation.actions.length > 0 ? (
                      <Fragment>
                        {assetReservation.actions.map((action, idx) => {
                          return (
                            <Fragment key={idx}>
                              <Can do="UPDATE" on="AssetReservationRequest">
                                <button
                                  className={getActionCss(action)}
                                  type="submit"
                                  onClick={() => {
                                    setFieldValue("action", action);
                                  }}
                                >
                                  {action === "REFUND"
                                    ? "Refund Balance Advance"
                                    : action}
                                </button>
                              </Can>
                            </Fragment>
                          );
                        })}
                      </Fragment>
                    ) : null}
                  </div>
                </div>
              </Fragment>
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

export default RefundAssetReservation;
