import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { getDisplayDate } from "../../lease/DateUtil";
import { ASSETS_LIST_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { changeToINR } from "../../common/LookupConstants";
import { Field, FieldArray, ErrorMessage } from "formik";

class AssetInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],

      asset: "",
      unitType: "",
      rateType: "",
      rateCard: "",
      from: "",
      to: "",
      charge: "",

      errors: {},
      totalCharge: []
    };
  }

  handleSelectAsset = asset => {
    this.setState({ asset });
  };

  handleSelectDate = (filedName, date) => {
    this.setState({ [filedName]: date });
  };

  handleOnchange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddAsset = () => {
    const { asset } = this.state;

    let errors = {};
    if (!asset) {
      errors.asset = true;
    }
    const invalid = Object.keys(errors).length > 0;

    if (invalid) {
      return this.setState({ errors });
    }

    const { values, setFieldValue } = this.props;

    const newAsset = {
      asset,
      from: null,
      to: null,
      unitType: "",
      usageUnit: "",
      usageHours: "",
      charge: "",
      requireUnitType: asset.requireUnitType
    };

    const assetRequested = values.assetRequested || [];
    assetRequested.push(newAsset);

    setFieldValue("assetRequested", assetRequested);
    this.setState({
      asset: "",
      unitType: "",
      rateType: "",
      rateCard: "",
      from: "",
      to: "",
      charge: "",
      errors
    });
  };

  handleRemove = index => {
    const { values, setFieldValue } = this.props;
    let assetRequested = values.assetRequested || [];
    assetRequested = assetRequested.filter((asset, idx) => idx !== index);
    setFieldValue("assetRequested", assetRequested);
  };

  getAll = () => {
    const { readOnly, values, setFieldValue } = this.props;
    if (readOnly) {
      return;
    }
    let url = ASSETS_LIST_API;
    HttpUtil.get(
      url,
      {},
      data => {
        /**
         * update following property an "asset" object
         * "requireUnitType" and "unitTypes"
         */
        const assetRequested = values.assetRequested || [];
        assetRequested.forEach(assetReq => {
          const asset = assetReq.asset;
          const reqAssets = data.filter(d => d.value === asset.value);
          if (reqAssets.length) {
            assetReq.asset = reqAssets[0];
          }
        });
        setFieldValue("assetRequested", assetRequested);
        this.setState({ assets: data });
      },

      (data, status) => console.log(`error:${data}`),
      error => console.log(`error:${error}`)
    );
  };
  addtotalCharge = assetRequested => {
    let sum = 0;
    assetRequested.forEach(asset => {
      sum = sum + Number(asset.charge || 0);
    });
    return sum;
  };

  componentDidMount = () => {
    this.getAll();
  };

  getTwoMonthsBefore = () => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 60);
    return minDate;
  };

  getAssetList = (assetRequested, fullAssets) => {
    let filterData = [];

    if (assetRequested.length > 0) {
      const existingSelectedAssetIds = new Set();

      assetRequested.forEach(astReq => {
        existingSelectedAssetIds.add(astReq.asset.value);
      });

      filterData = fullAssets.filter(asset => {
        return !existingSelectedAssetIds.has(asset.value);
      });
    } else {
      filterData = fullAssets;
    }

    return filterData;
  };

  render() {
    const { assets, errors } = this.state;
    const { values, readOnly, setFieldValue } = this.props;
    const propsError = this.props.errors || {};
    const propsTouched = this.props.touched || {};

    const styles = {
      control: base => ({
        ...base,
        height: "calc(1.8125rem + 2px)",
        minHeight: "calc(1.8125rem + 2px)"
      }),
      indicatorsContainer: provided => ({
        ...provided,
        height: "30px"
      })
    };

    const assetRequested = values.assetRequested || [];
    const refreshedAssetList = this.getAssetList(assetRequested, assets);
    // const rateTypes = ASSET_RATE_TYPES.filter(
    //   rtype =>
    //     this.state.asset &&
    //     this.state.asset.rateTypes &&
    //     this.state.asset.rateTypes.includes(rtype.value)
    // );

    const disabledField = readOnly
      ? {
          readOnly: "readOnly",
          disabled: "disabled"
        }
      : {};

    return (
      <Fragment>
        <div className="row mt-5">
          <div className="col-12 mb-3 edit-page-section-header-text">
            Asset Information
          </div>
        </div>

        <Fragment>
          {readOnly ? null : (
            <Fragment>
              <div className="row">
                <div className="col-sm-2 mb-2">
                  <label className="edit-page-label-required mb-0">Asset</label>
                </div>
                <div className="col-sm-8 mb-2">
                  <Select
                    isClearable
                    onChange={(selectedValue, idx) => {
                      this.handleSelectAsset(selectedValue);
                    }}
                    value={this.state.asset || ""}
                    options={refreshedAssetList}
                    styles={styles}
                    classNamePrefix="react-select"
                    className={`${errors.asset ? "is-invalid" : ""}`}
                  />

                  {errors.asset ? (
                    <div
                      className="text-danger m-1"
                      style={{ fontSize: "0.850em" }}
                    >
                      Asset Name is required.
                    </div>
                  ) : null}
                </div>

                <div className="col-sm-2 mb-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-success"
                    onClick={() => this.handleAddAsset()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {assetRequested && assetRequested.length === 0 && (
                <div
                  className="text-danger m-1"
                  style={{ fontSize: "0.850em" }}
                >
                  {propsError.assetRequested}
                </div>
              )}
            </Fragment>
          )}

          <FieldArray
            name="assetRequested"
            render={({ remove }) => {
              return (
                <Fragment>
                  {assetRequested.length > 0 ? (
                    <div className="mb-2 mt-4 edit-page-label">
                      <strong> Asset Requested </strong>
                    </div>
                  ) : null}

                  {assetRequested.map((assetReq, index) => {
                    const err =
                      (propsError.assetRequested &&
                        propsError.assetRequested[index]) ||
                      {};

                    const tcd =
                      (propsTouched.assetRequested &&
                        propsTouched.assetRequested[index]) ||
                      {};

                    let minMaxValue = {};
                    if (assetReq.asset.availableHours) {
                      minMaxValue = {
                        min: "0",
                        max: `${assetReq.asset.availableHours}`
                      };
                    }
                    return (
                      <Fragment key={index}>
                        <div className="row no-gutters mt-4">
                          <div className="col-11">
                            <div className="">
                              <h6
                                className="mb-0 font-weight-bold text-uppercase"
                                style={{ fontSize: "1em" }}
                              >
                                {assetReq.asset.label || "--"}
                              </h6>
                              <small className="text-muted">{`ASSET ID : ${assetReq
                                .asset.assetId || "--"}`}</small>
                            </div>
                            <div className="form-row mt-2">
                              <div className="form-group col-sm-6 col-md-5">
                                <label
                                  className="edit-page-label-required mb-0"
                                  style={{ paddingRight: "45px" }}
                                >
                                  From
                                </label>
                                <div>
                                  <DatePicker
                                    value={getDisplayDate(
                                      assetReq.from || null
                                    )}
                                    selected={
                                      assetReq.from
                                        ? new Date(assetReq.from)
                                        : null
                                    }
                                    onChange={date => {
                                      if (date) {
                                        setFieldValue(
                                          `assetRequested[${index}].from`,
                                          date
                                        );
                                      }
                                    }}
                                    utcOffset={0}
                                    placeholderText="dd-mm-yyyy"
                                    className={`form-control form-control-sm ${
                                      tcd.from && err.from ? "is-invalid" : ""
                                    }`}
                                    minDate={this.getTwoMonthsBefore()}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={readOnly}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`assetRequested[${index}].from`}
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                              <div className="form-group col-sm-6 col-md-5">
                                <label className="edit-page-label-required mb-0">
                                  To
                                </label>
                                <div>
                                  <DatePicker
                                    value={getDisplayDate(assetReq.to || null)}
                                    selected={
                                      assetReq.to ? new Date(assetReq.to) : null
                                    }
                                    onChange={date => {
                                      if (date) {
                                        setFieldValue(
                                          `assetRequested[${index}].to`,
                                          date
                                        );
                                      }
                                    }}
                                    utcOffset={0}
                                    minDate={
                                      new Date(assetReq.from) ||
                                      this.getTwoMonthsBefore()
                                    }
                                    placeholderText="dd-mm-yyyy"
                                    className={`form-control form-control-sm ${
                                      tcd.to && err.to ? "is-invalid" : ""
                                    }`}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    disabled={
                                      assetReq.from === "" || readOnly
                                        ? true
                                        : false
                                    }
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`assetRequested[${index}].to`}
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-row ">
                              <div className="form-group col-sm-6 col-md-5">
                                <label
                                  className={`edit-page-label${
                                    assetReq.asset.requireUnitType
                                      ? "-required"
                                      : ""
                                  } mb-0`}
                                >
                                  Usage Unit Type
                                </label>
                                <select
                                  className={`form-control form-control-sm custom-select custom-select-sm ${
                                    tcd.unitType && err.unitType
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    setFieldValue(
                                      `assetRequested[${index}].unitType`,
                                      e.target.value
                                    );
                                  }}
                                  name="unitType"
                                  value={assetReq.unitType}
                                  disabled={readOnly ? true : false}
                                >
                                  <option value="" />
                                  {(
                                    (assetReq.asset &&
                                      assetReq.asset.unitTypes) ||
                                    []
                                  ).map(unitType => (
                                    <option
                                      value={unitType._id}
                                      key={unitType._id}
                                    >
                                      {unitType.name}
                                    </option>
                                  ))}
                                </select>
                                <ErrorMessage
                                  component="div"
                                  name={`assetRequested[${index}].unitType`}
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group col-sm-6 col-md-5">
                                <label
                                  className={`edit-page-label${
                                    assetReq.asset.requireUnitType
                                      ? "-required"
                                      : ""
                                  } mb-0`}
                                >
                                  Usage Unit
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control form-control-sm ${
                                    tcd.usageUnit && err.usageUnit
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  inputMode="numeric"
                                  name="usageUnit"
                                  value={assetReq.usageUnit || ""}
                                  onChange={e => {
                                    setFieldValue(
                                      `assetRequested[${index}].usageUnit`,
                                      e.target.value
                                    );
                                  }}
                                  {...disabledField}
                                />
                                <ErrorMessage
                                  component="div"
                                  name={`assetRequested[${index}].usageUnit`}
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="form-group col-sm-6 col-md-5">
                                <label className="edit-page-label-required mb-0">
                                  {`Requested Usage Hours ${
                                    assetReq.asset.availableHours
                                      ? `(Max ${assetReq.asset.availableHours})`
                                      : ""
                                  }`}
                                </label>
                                <Field
                                  type="number"
                                  className={`form-control form-control-sm ${
                                    tcd.usageHours && err.usageHours
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  inputMode="numeric"
                                  name="usageHours"
                                  value={assetReq.usageHours || ""}
                                  onChange={e => {
                                    setFieldValue(
                                      `assetRequested[${index}].usageHours`,
                                      e.target.value
                                    );
                                  }}
                                  {...minMaxValue}
                                  {...disabledField}
                                />
                                <ErrorMessage
                                  component="div"
                                  name={`assetRequested[${index}].usageHours`}
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group col-sm-6 col-md-5">
                                <label className="edit-page-label-required mb-0">
                                  Estimated Charges
                                </label>
                                <div>
                                  <Field
                                    type="text"
                                    className={`form-control form-control-sm ${
                                      tcd.charge && err.charge
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    inputMode="numeric"
                                    name="charge"
                                    value={assetReq.charge || ""}
                                    onChange={e => {
                                      setFieldValue(
                                        `assetRequested[${index}].charge`,
                                        e.target.value
                                      );
                                    }}
                                    {...disabledField}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name={`assetRequested[${index}].charge`}
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-1 text-center">
                            {readOnly ? null : (
                              <span
                                className="text-danger cursor-pointer"
                                style={{ fontSize: "0.850em" }}
                                onClick={() => remove(index)}
                              >
                                <i className="fas fa-trash-alt  mr-2" />
                                <span className="d-none d-md-inline">
                                  Remove
                                </span>
                              </span>
                            )}
                          </div>
                        </div>
                        <hr />
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            }}
          />

          <div className="row no-gutters mt-4">
            <div className="col-12 col-lg-2" />
            <div className="col-12 col-lg-7 text-right">
              <label className="edit-page-label">
                Total Estimated Charges :
              </label>
              <span className="ml-2" style={{ fontSize: "1.5rem" }}>
                {changeToINR(this.addtotalCharge(values.assetRequested))}
              </span>
            </div>
            <div className="col-12 col-lg-3" />
          </div>
        </Fragment>
      </Fragment>
    );
  }
}

export default AssetInformation;
