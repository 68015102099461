import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  CAMPUS_API,
  STATUS_LIST_API,
  AREA_UNIT_LIST_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import DefaultAlert from "../DefaultAlert";
import Campus from "./Campus";
class AddCampus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      managedBy: "",
      notes: "",
      city: "",
      district: "",
      state: "",
      country: "",
      postalCode: "",
      landmark: "",
      status: "ACTIVE",
      areaUnit: null,
      areaPrice: 0,
      seaterPrice: 0,

      //redirect to edit campus with id
      editCampus: false,
      editCampusId: "",

      //for input error alert
      inputError: false,

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: "",

      // for selector
      statusList: [],
      areaUnitList: []
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getCampus = () => {
    const {
      name,
      managedBy,
      notes,
      city,
      district,
      state,
      country,
      postalCode,
      landmark,
      status,
      areaUnit,
      areaPrice,
      seaterPrice
    } = this.state;

    const campus = {
      name,
      managedBy,
      notes,
      city,
      district,
      state,
      country,
      postalCode,
      landmark,
      status,
      areaUnit,
      areaPrice,
      seaterPrice
    };

    return campus;
  };

  validation = campus => {
    let inputError = {};
    let valid = true;
    if (campus.name === "") {
      valid = false;
      inputError["name"] = true;
    }

    // if (campus.managedBy === "") {
    //   valid = false;
    //   inputError["managedBy"] = true;
    // }

    if (!valid) {
      return this.setState({
        inputError: { ...inputError }
      });
    }
    return valid;
  };

  handleApiSuccess = (data, flag) => {
    if (flag === "ADD") {
      const editCampusId = data._id;
      this.setState({
        inputError: false,
        editCampus: true,
        editCampusId: editCampusId
      });
    }

    if (flag === "STATUS_LIST") {
      this.setState({ statusList: data });
    }

    if (flag === "AREA_UNIT_LIST") {
      this.setState({ areaUnitList: data });
    }
  };

  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  addCampus = () => {
    // Extract campus object from component state
    const campus = this.getCampus();
    // validation and show error alert
    if (!this.validation(campus)) {
      return;
    }

    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = CAMPUS_API;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    // Fire request
    HttpUtil.post(
      url,
      headers,
      campus,
      data => this.handleApiSuccess(data, "ADD"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  componentDidMount = () => {
    this.getStatusList();
    this.getAreaUnitList();
  };

  getStatusList = () => {
    const url = STATUS_LIST_API + "CAMPUS";
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data, "STATUS_LIST"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAreaUnitList = () => {
    const url = AREA_UNIT_LIST_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data, "AREA_UNIT_LIST"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  render() {
    const {
      inputError,

      editCampus,
      editCampusId,

      //Default alert
      showAlert,
      alertMode,
      alertMessage,

      //campus
      name,
      managedBy,
      notes,
      city,
      district,
      state,
      country,
      postalCode,
      landmark,
      status,
      areaUnit,
      areaPrice,
      seaterPrice,

      // for selectors
      statusList,
      areaUnitList
    } = this.state;

    if (editCampus) {
      const locationState = {
        pathname: `/admin/campuses/edit/${editCampusId}`,
        state: { created: true }
      };
      return <Redirect to={locationState} />;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-12">
          {/* show alert message  */}
          <DefaultAlert
            show={showAlert}
            alertMode={alertMode}
            message={alertMessage}
            closeAlert={this.closeDefaultAlert}
          />

          <Campus
            //campus
            name={name}
            managedBy={managedBy}
            notes={notes}
            city={city}
            district={district}
            state={state}
            country={country}
            postalCode={postalCode}
            landmark={landmark}
            status={status}
            areaUnit={areaUnit}
            areaPrice={areaPrice}
            seaterPrice={seaterPrice}
            // events
            newCampus={true}
            onChange={this.onChange}
            submit={this.addCampus}
            //inputError
            inputError={inputError}
            // for selectors
            statusList={statusList}
            areaUnitList={areaUnitList}
          />
        </div>
      </div>
    );
  }
}
export default AddCampus;
