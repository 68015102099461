import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { PAYMENTS_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import {
  PAYEE_TYPES_OBJECT,
  changeToINR,
  renderInputData
} from "../../common/LookupConstants";
import ErrorPage from "../../common/error.page";

class VoidPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voidReason: null,
      payment: [],
      inputError: {},
      voidPaymentForm: true,
      confirmationForm: false,
      permanentFailure: false,
      paymentVoided: false
    };
  }

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    this.setState({ [targetName]: targetValue });
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getPayment(id);
    }
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  addData = (payment, labelName, value) => {
    if (value) {
      payment.push({
        label: labelName,
        value
      });
    }
  };

  handleData = data => {
    const payment = [];
    const entityName = data.entity.name || data.entity.firstName || null;
    const paymentDate = getDisplayDate(convertNumberToDate(data.paymentDate));
    this.addData(payment, "Entity Type", PAYEE_TYPES_OBJECT[data.entityType]);
    this.addData(payment, "Entity Name", entityName);
    this.addData(payment, "Payment Date", paymentDate);
    this.addData(payment, "Amount Paid", changeToINR(data.amount));

    this.setState({ payment, _id: data._id });
  };

  getPayment = id => {
    const url = `${PAYMENTS_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        this.handleData(data);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleNextForm = () => {
    const valid = this.state.voidReason !== null;
    if (!valid) {
      return;
    } else {
      this.setState({
        voidPaymentForm: false,
        confirmationForm: true
      });
    }
  };

  handleEditForm = () => {
    this.setState({
      voidPaymentForm: true,
      confirmationForm: false,
      inputError: {}
    });
  };

  handleSubmit = () => {
    const paymentId = this.state._id;
    const url = `${PAYMENTS_API}/${paymentId}/void`;
    const headers = { "Content-Type": "application/json" };

    const voidReason = { reason: this.state.voidReason };

    HttpUtil.post(
      url,
      headers,
      voidReason,
      data => {
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: "Payment has been voided successfully.",
          paymentVoided: true
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };
  render() {
    const {
      alertType,
      showAlert,
      alertColor,
      alertMessage,

      payment,
      voidPaymentForm,
      confirmationForm,
      permanentFailure
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (payment.length === 0) {
      // return null;
    }

    const props = {
      voidReason: this.state.voidReason,
      payment: this.state.payment,
      inputError: this.state.inputError,
      paymentVoided: this.state.paymentVoided,
      handleInputChange: this.handleInputChange,
      handleNextForm: this.handleNextForm,
      handleEditForm: this.handleEditForm,
      handleSubmit: this.handleSubmit
    };

    let renderForm;
    if (voidPaymentForm) {
      renderForm = <VoidPaymentForm {...props} />;
    }
    if (confirmationForm) {
      renderForm = <ConfirmationForm {...props} />;
    }
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        {renderForm}
      </Fragment>
    );
  }
}

export default VoidPayment;

export const VoidPaymentForm = props => {
  const {
    voidReason,
    payment,
    inputError,
    handleInputChange,
    handleNextForm
  } = props;
  const rows = payment.map((pmt, index) => {
    return (
      <tr key={index}>
        <th className="edit-page-label" style={{ width: "35%" }}>
          {pmt.label}
        </th>
        <td>{pmt.value}</td>
      </tr>
    );
  });
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card edit-page-container">
          <div className="card-header border-bottom-0 pt-3">
            <span className="edit-page-title mb-0">
              <Link
                to={"/admin/finance/payments"}
                className="badge bg-white custom-btn-sm"
              >
                <i className="fas fa-arrow-left" />
              </Link>
              &nbsp; Payment Void Information
            </span>
          </div>
          <div className="card-body">
            <table className="table table-sm my-2 table-borderless">
              <tbody>{rows}</tbody>
            </table>
            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                Payment Void Reason
              </label>
              <div className="col-12 col-lg-8 col-xl-8 pl-xl-0">
                <input
                  type="text"
                  name="voidReason"
                  value={renderInputData(voidReason)}
                  onChange={handleInputChange}
                  className={
                    inputError["voidReason"]
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                />
                <div className="invalid-feedback">
                  Please enter void rReason *
                </div>
              </div>
            </div>
            <div className="form-group row mt-3 mb-0">
              <div className="col-8 text-right">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleNextForm}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ConfirmationForm = props => {
  const {
    voidReason,
    payment,
    paymentVoided,
    handleEditForm,
    handleSubmit
  } = props;
  const rows = payment.map((pmt, index) => {
    return (
      <tr key={index}>
        <th style={{ width: "35%" }}>{pmt.label}</th>
        <td>{pmt.value}</td>
      </tr>
    );
  });
  return (
    <div className="row">
      <div className="col-md-10 col-12">
        <div className="card edit-page-container">
          <div className="card-header border-bottom-0 pt-3">
            <h5 className="h6 card-title mb-0">
              <Link
                to={"/admin/finance/payments"}
                className="badge bg-white custom-btn-sm"
              >
                <i className="fas fa-arrow-left" />
              </Link>
              &nbsp; Void Payment Confirmation
            </h5>
          </div>
          <div className="card-body">
            <h5 className="h6 card-title mb-0 text-center font-weight-bold text-danger pb-2">
              Payments voided can't be rolled back. Do you want really want to
              void the below payment ?
            </h5>
            <table className="table table-sm my-2 table-borderless">
              <tbody>
                {rows}

                <tr>
                  <th style={{ width: "35%" }}>Payment Void Reason</th>
                  <td>{voidReason}</td>
                </tr>
              </tbody>
            </table>
            <div className="form-group row mt-3 mb-0">
              {paymentVoided ? null : (
                <div className="col-8 text-right">
                  <div className="col-12 text-right">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleEditForm}
                    >
                      Edit
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
