import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { MONTHLY_REPORTS_API } from "../../common/Constants";
import { convertNumberToDate, getDisplayDate } from "../../lease/DateUtil";
import { changeToINR } from "../../common/LookupConstants";
import TableList from "../../common/TableList";

const REPORT_TYPE_DESC = {
  MONTHLY_ASSET_CHARGE: "Monthly Asset Charge Report",
  MONTHLY_FACILITY_RENTAL: "Monthly Facility Rental  Report"
};

function getReportTypeDesc(data) {
  return REPORT_TYPE_DESC[data] || data;
}

class ReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportList: null
    };
  }
  componentDidMount() {
    this.getMonthlyReports();
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getMonthlyReports = () => {
    const url = MONTHLY_REPORTS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ reportList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  dateFormater = yyyymmdd => {
    return getDisplayDate(convertNumberToDate(yyyymmdd));
  };
  actionsFormater = (cell, row) => {
    if (row.reportType === "MONTHLY_ASSET_CHARGE") {
      return (
        <Link
          to={`/admin/finance/asset-reports/${row._id}/charges`}
          role="button"
          className="list-action-btn-info bg-success mr-2"
        >
          Detail Report
        </Link>
      );
    }
    return (
      <Fragment>
        <Link
          to={`/admin/finance/reports/${row._id}/charges`}
          role="button"
          className="list-action-btn-info bg-success mr-2"
        >
          Detail Report
        </Link>
        <Link
          to={`/admin/finance/monthly-reports/${row._id}`}
          role="button"
          className="list-action-btn-info bg-warning"
        >
          Grouped Report
        </Link>
      </Fragment>
    );
  };

  render() {
    const { reportList } = this.state;

    const columnsReports = [
      {
        dataField: "reportType",
        text: "Report Type",
        formatter: getReportTypeDesc,
        csvFormatter: getReportTypeDesc,
        sort: true,
        headerStyle: {
          width: "15%"
        }
      },
      {
        dataField: "description",
        text: "Description"
      },
      {
        dataField: "reportingPeriod.start",
        text: "Start Date ",
        formatter: this.dateFormater,
        csvFormatter: this.dateFormater
      },
      {
        dataField: "reportingPeriod.end",
        text: "End Date",
        formatter: this.dateFormater,
        csvFormatter: this.dateFormater
      },
      {
        dataField: "amount",
        text: "Total Amount",
        classes: "text-right",
        formatter: changeToINR,
        csvType: Number
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.actionsFormater,
        csvExport: false,
        headerStyle: {
          width: "15%"
        }
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsReports}
          data={reportList}
          noDataIndication="No Monthly Reports found."
          sizePerPage="10"
          headerName="Monthly Reports"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default ReportList;
