import React, { Component, Fragment } from "react";
import { ASSET_AVAILABILITY_API } from "../../../common/Constants";
import {
  convertDateToNumber,
  convertNumberToDate,
  getDisplayDate
} from "../../../lease/DateUtil";
import HttpUtil from "../../../common/HttpUtil";
import { Link } from "react-router-dom";

function appendQueryString(obj, field, value) {
  if (value) {
    obj.append(field, value);
  }
}

function transformData(results) {
  const dateMappedData = {};
  results.forEach(result => {
    const { assetId, assetName, availableHours, date, reservations } = result;
    const asset = {
      assetId,
      assetName,
      availableHours,
      reservations
    };
    if (dateMappedData[date]) {
      dateMappedData[date].push(asset);
    } else {
      dateMappedData[date] = [asset];
    }
  });
  return Object.keys(dateMappedData)
    .sort((a, b) => a - b)
    .map(date => {
      return {
        date,
        formattedDate: getDisplayDate(convertNumberToDate(date)),
        assets: dateMappedData[date]
      };
    });
}

function prepareQueryString(values) {
  const searchParams = new URLSearchParams();
  appendQueryString(searchParams, "assetType", values.assetType);
  appendQueryString(searchParams, "from", convertDateToNumber(values.fromDate));
  if (values.asset) {
    appendQueryString(searchParams, "asset", values.asset);
  }
  if (values.toDate) {
    appendQueryString(searchParams, "to", convertDateToNumber(values.toDate));
  }
  return searchParams.toString();
}

function renderAssetInfo(asset, date) {
  const isPastDate = date <= convertDateToNumber(new Date());
  const totalHours = asset.reservations.reduce((total, value) => {
    total += value.usageHours || 0;
    return total;
  }, 0);
  const availableHours = asset.availableHours
    ? `${asset.availableHours - totalHours} Hours`
    : "--";
  return (
    <Fragment>
      <td>{asset.assetName}</td>
      <td>{availableHours}</td>
      <td>
        {asset.reservations.length > 0 ? (
          <ul className="list-unstyled">
            {asset.reservations.map((reservation, index) => {
              const isComplete = reservation.reservationStatus === "Complete";
              const color = isComplete ? "success" : "warning";

              const listItemClass =
                index !== asset.reservations.length - 1
                  ? "pb-3 border-bottom mb-2"
                  : "";
              return (
                <li className={listItemClass}>
                  <h6 className="mb-1 font-weight-normal">
                    <Link
                      to={`/service-mgmt/asset-reservation-requests/edit/${
                        reservation._id
                      }?from=/service-mgmt/asset-reservations`}
                    >
                      {reservation.requestor}
                    </Link>
                  </h6>
                  <div className="d-flex w-100 justify-content-start">
                    <div className="pr-2">
                      <span
                        className={`badge badge-${color} badge-pill text-uppercase`}
                      >
                        {isComplete ? "RESERVED" : "INPROGRESS"}
                      </span>
                    </div>
                    <div>
                      <span className="badge badge-primary badge-pill">
                        {reservation.usageHours
                          ? `${reservation.usageHours} Hours`
                          : null}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : isPastDate ? (
          <span className="badge badge-pill badge-secondary">Unreserved</span>
        ) : (
          <span className="badge badge-pill badge-success">Available</span>
        )}
      </td>
    </Fragment>
  );
}

function renderResultRow(result) {
  if (result.assets.length <= 0) {
    return;
  }
  return result.assets.map((asset, index) => {
    const rowSpan = index === 0 ? result.assets.length : 1;

    return (
      <tr key={`${result.date}-${asset.assetId}`}>
        {index ? null : <td rowSpan={rowSpan}>{result.formattedDate}</td>}
        {renderAssetInfo(asset, result.date)}
      </tr>
    );
  });
}

class SearchResults extends Component {
  constructor(args) {
    super(args);
    this.state = {
      data: null,
      loading: false
    };
  }

  handleApiFailed = message => {
    this.setState({
      loading: false,
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  loadData = () => {
    this.setState({ loading: true });
    HttpUtil.get(
      `${ASSET_AVAILABILITY_API}?${prepareQueryString(this.props)}`,
      {},
      results => {
        this.setState({ data: transformData(results), loading: false });
      },
      data => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidUpdate = prevProps => {
    if (
      this.props.assetType !== prevProps.assetType ||
      this.props.asset !== prevProps.asset ||
      this.props.fromDate !== prevProps.fromDate ||
      this.props.toDate !== prevProps.toDate
    ) {
      this.loadData();
    }
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="row">
          <div className="col-10 offset-1">
            <div className="progress-bar-loading-text">Loading...</div>
            <div className="progress-bar">
              <div className="indeterminate" />
            </div>
          </div>
        </div>
      );
    }

    const data = this.state.data || [];

    if (data.length === 0) {
      return <div>No data found.</div>;
    }

    return (
      <table className="table table-sm border-bottom">
        <thead>
          <tr>
            <th
              style={{ width: "10%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            >
              Date
            </th>
            <th
              style={{ width: "35%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            >
              Asset Name
            </th>
            <th
              style={{ width: "10%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            >
              Availability
            </th>
            <th
              style={{ width: "45%", backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            >
              Reservation Status
            </th>
          </tr>
        </thead>
        <tbody>{data.map(result => renderResultRow(result))}</tbody>
      </table>
    );
  }
}

export default SearchResults;
