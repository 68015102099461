import React, { Component } from "react";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import Home from "./components/common/Home";
import Login from "./components/common/Login";
import Logout from "./components/common/Logout";
import PublicHome from "./components/public/PublicHome";

import configureReduxStore from "./ConfigureReduxStore";

// Create Redux store
const store = configureReduxStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/logout" component={Logout} />

            <Route path="/public" component={PublicHome} />
            <Route
              exact
              path="/"
              component={() => <Redirect to="/dashboard" />}
            />
            <Route path="/" component={Home} />
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
