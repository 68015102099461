import React from "react";
import { GRANT_AWARD_CLOSURE_STATUS_REASONS_OBJECT } from "../../common/LookupConstants";
import { convertNumberToDate, getDisplayDate } from "../../lease/DateUtil";
import { downloadDocument } from "../../common/Util";
import { getFileIconByContentType } from "../../common/IconUtil";
import { COUNTRIES_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";

function getFieldName(stringValue) {
  if (!stringValue) {
    return null;
  }
  let text = stringValue.toLowerCase();
  text = text.split(" ");
  let name = "";
  for (let i = 1; i < text.length; i++) {
    let txt = text[i];
    txt = txt.charAt(0).toUpperCase() + txt.substring(1);
    text[i] = txt;
  }
  name = text.join("");
  return name;
}

function getCountries(setApiStatus) {
  HttpUtil.get(
    COUNTRIES_API,
    {},
    countries => {
      const data = {};
      countries.forEach(c => (data[c.countryCode] = c.name));
      setApiStatus({ success: true, data });
    },
    (data, status) => setApiStatus({ error: true, message: data.message }),
    error => setApiStatus({ error: true, message: error.toString() })
  );
}

function GrantAwardClosureDetail({ award }) {
  const [apiStatus, setApiStatus] = React.useState({ loading: true });
  const { closureTemplate } = award.grantRef.grantProgressTemplate;

  React.useEffect(() => {
    getCountries(setApiStatus);
  }, []);

  if (apiStatus.loading) {
    return <div>Loading...</div>;
  }

  if (apiStatus.error) {
    return <div className="text-danger">{apiStatus.message}</div>;
  }

  return (
    <div className="shadow card flex-fill box-border box-light-blue mb-4">
      <div className="card-header border-0 text-center pb-0">
        <h6 className="mb-0 text-light-blue d-inline">
          <strong>Closure Information</strong>
        </h6>
      </div>

      <div className="card-body">
        <div className="row">
          <div className="col-md-6 col-12">
            <table className="table table-sm table-borderless ">
              <tbody>
                <tr>
                  <th>Completed Successfully</th>
                  <td>{award.completed ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th>Status Reason</th>
                  <td>
                    {GRANT_AWARD_CLOSURE_STATUS_REASONS_OBJECT[
                      award.statusReason
                    ] || "--"}
                  </td>
                </tr>
                <tr>
                  <th>Closure Date</th>
                  <td>
                    {getDisplayDate(
                      convertNumberToDate(award.grantClosureDate)
                    )}
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">Notes</th>
                </tr>
                <tr>
                  <td colSpan="2">
                    <ul>
                      {award.closureNotes.map(note => (
                        <li className="mt-1" key={note._id}>
                          {note.remark}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-md-6 col-12">
            <table className="table table-sm table-borderless">
              <tbody>
                <tr>
                  <th>Trademark Registered</th>
                  <td>{award.trademarkRegistered ? "Yes" : "No"}</td>
                </tr>
                {award.trademarkRegistered ? (
                  <tr>
                    <th>Trademark Name</th>
                    <td>{award.trademarkName}</td>
                  </tr>
                ) : null}
                <tr>
                  <th>Company Formed</th>
                  <td>
                    {award.companyFormed
                      ? `Yes (${
                          award.afterGrantAward ? "After Grant" : "Before Grant"
                        })`
                      : "No"}
                  </td>
                </tr>
                {award.companyFormed ? (
                  <>
                    <tr>
                      <th>Name of the Company</th>
                      <td>{award.companyName}</td>
                    </tr>
                    <tr>
                      <th>Date of Incorporation</th>
                      <td>
                        {getDisplayDate(
                          convertNumberToDate(award.dateOfIncorporation)
                        )}
                      </td>
                    </tr>
                  </>
                ) : null}
                {closureTemplate.fieldTemplates.map(field => {
                  const fieldValue =
                    award.closureAddlInfo[getFieldName(field.name)];
                  return (
                    <tr>
                      <th>{field.name}</th>
                      <td>
                        {(field.type === "date"
                          ? getDisplayDate(fieldValue)
                          : fieldValue) || "--"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12  col-lg-6 col-xl-6">
            <div className="border-bottom pb-2 mb-2">
              <strong>Documents</strong>
            </div>
            <ul className="list-group list-group-flush">
              {award.closureDocuments.map((doc, index) => {
                return (
                  <li
                    key={doc._id}
                    className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                  >
                    <span>
                      <i
                        className={`${getFileIconByContentType(doc.type)} mr-2`}
                      />
                      {doc.type} - {doc.name}
                    </span>
                    <div>
                      <i
                        className="fas fa-download fa-md mr-3"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          downloadDocument(
                            doc.link,
                            msg => window.alert(msg),
                            doc.filename
                          )
                        }
                      />
                    </div>
                  </li>
                );
              })}
              {award.closureDocuments.length === 0 ? (
                <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                  No documents available
                </li>
              ) : null}
            </ul>
          </div>

          {award.closureChecklist.length > 0 ? (
            <div className="col-xs-12 col-sm-12 col-md-12  col-lg-6 col-xl-6">
              <div className="border-bottom pb-2 mb-2">
                <strong>Checklist</strong>
              </div>

              {award.closureChecklist.map(chklst => {
                return (
                  <div key={chklst._id} className="form-group row mb-2 mt-3">
                    <div className="col-12">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={chklst._id}
                          checked={chklst.selected}
                          readOnly
                          disabled
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={chklst._id}
                        >
                          {chklst.label}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <div className="border-bottom pb-2 mb-2">
              <strong>Patents</strong>
            </div>
            {award.patents.length > 0 ? (
              <div className="resposive">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th className="display-table-header" scope="col">
                        Field Of Invention
                      </th>
                      <th className="display-table-header" scope="col">
                        Application Number
                      </th>
                      <th className="display-table-header" scope="col">
                        Filing Date
                      </th>
                      <th className="display-table-header" scope="col">
                        Classification (IPC)
                      </th>
                      <th className="display-table-header" scope="col">
                        Country
                      </th>
                      <th className="display-table-header" scope="col">
                        Patent Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {award.patents.map(patent => (
                      <tr key={patent._id}>
                        <td>{patent.fieldOfInvention || "--"}</td>
                        <td>{patent.applicationNumber || "--"}</td>
                        <td>
                          {getDisplayDate(
                            convertNumberToDate(patent.applicationFilingDate)
                          ) || "--"}
                        </td>
                        <td>{patent.classificationCode || "--"}</td>
                        <td>
                          {apiStatus.data[patent.country] ||
                            patent.country ||
                            "--"}
                        </td>
                        <td>{patent.patentStatus || "--"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center"> No Patents Available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrantAwardClosureDetail;
