import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import HttpUtil from "../../common/HttpUtil";
import DefaultAlert from "../DefaultAlert";
import { PARTITION_API } from "../../common/Constants";
import TableList from "../../common/TableList";
import { PARTITION_STATUS_LIST_OBJECT } from "../../common/LookupConstants";
import Can from "../../../auth/can";

function partitionTypeText(cell, row) {
  if (!row) {
    return;
  }

  let type = "--";
  const partitionType = row.partitionType;
  if (partitionType === "SEATER") {
    type = "Seater";
  } else if (partitionType === "RENTAL") {
    type = "Fixed Rental";
  }
  return type;
}

function partitionType(cell, row) {
  if (!row) {
    return;
  }

  const partitionType = row.partitionType;
  let availablePartition = "";

  let messurement = "";
  let value = "";
  if (partitionType === "SEATER") {
    messurement = row.seatCount > 1 ? "seats" : "seat";
    value = row.seatCount;
  } else {
    messurement = "sqft";
    value = row.area;
  }

  availablePartition = (
    <div className="d-flex align-items-center">
      <span className="w-50 text-right">{value}</span>
      <small className="ml-2">{messurement}</small>
    </div>
  );
  return availablePartition;
}

function partitionTypeValue(cell, row) {
  if (!row) {
    return;
  }

  const partitionType = row.partitionType;
  let availablePartition = "";

  let messurement = "";
  let value = "";
  if (partitionType === "SEATER") {
    messurement = row.seatCount > 1 ? "seats" : "seat";
    value = row.seatCount;
  } else if (partitionType === "RENTAL") {
    messurement = "sqft";
    value = row.area;
  }

  availablePartition = `${value} ${messurement}`;

  return availablePartition;
}

class PartitionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partitionList: null,

      //redirect to edit partition with id
      editPartition: false,
      editPartitionId: "",

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    };
  }

  //React life cycle
  componentDidMount = () => {
    this.getPartitionList();
  };

  // For api success return
  handleApiSuccess = data => {
    this.setState({ partitionList: data });
  };

  // For api failed return
  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  // Get floors from server
  getPartitionList = () => {
    const url = PARTITION_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    // Fetch api
    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  linkFormater = (cell, row) => {
    if (!row) {
      return null;
    }
    const url = `/admin/partitions/edit/${row._id}`;

    return (
      <Link className="ml-5" to={url}>
        {row.partitionName}
      </Link>
    );
  };

  getName = (cell, row) => {
    if (!row) {
      return null;
    }
    return row.partitionName;
  };

  render() {
    const {
      partitionList,

      //redirect to edit floor with id
      editPartition,
      editPartitionId,

      //Default alert
      showAlert,
      alertMode,
      alertMessage
    } = this.state;

    if (editPartition) {
      let url = "/admin/partitions/edit/" + editPartitionId;
      return <Redirect to={url} />;
    }

    const columnsPartitions = [
      {
        dataField: "floor.building.buildingName",
        text: "Building",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15%" };
        }
      },
      {
        dataField: "floor.floorName",
        text: "Floor",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "15%" };
        }
      },

      {
        dataField: "partitionName",
        text: "Partition",
        sort: true,
        classes: "align-middle text-left",
        formatter: this.linkFormater,
        csvFormatter: this.getName,
        filterValue: this.getName,
        headerStyle: (colum, colIndex) => {
          return { width: "18%", textAlign: "center" };
        }
      },
      {
        dataField: "displayName",
        text: "Display Name",
        classes: "align-middle text-left",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "16.66%" };
        }
      },
      {
        dataField: "partitionType",
        text: "Type",
        sort: true,
        classes: "align-middle text-center",
        formatter: partitionTypeText,
        csvFormatter: partitionTypeText,
        filterValue: partitionTypeText,
        headerStyle: (colum, colIndex) => {
          return { width: "10%", textAlign: "center" };
        }
      },
      {
        dataField: "partition",
        text: "Sqft / Seats",
        sort: true,
        classes: "align-middle text-center",
        formatter: partitionType,
        csvFormatter: partitionTypeValue,
        filterValue: partitionTypeValue,
        headerStyle: (colum, colIndex) => {
          return { width: "10%" };
        }
      },

      {
        dataField: "status",
        text: "Status",
        classes: "align-middle text-center",
        formatter: cell => PARTITION_STATUS_LIST_OBJECT[cell],
        csvFormatter: cell => PARTITION_STATUS_LIST_OBJECT[cell],
        filterValue: cell => PARTITION_STATUS_LIST_OBJECT[cell],
        headerStyle: (colum, colIndex) => {
          return { width: "5%", textAlign: "center" };
        }
      }
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            {/* show alert message  */}
            <DefaultAlert
              show={showAlert}
              alertMode={alertMode}
              message={alertMessage}
              closeAlert={this.closeDefaultAlert}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsPartitions}
          data={partitionList}
          noDataIndication="No Partition found."
          sizePerPage="10"
          headerName="Partition"
          showSearchbar={true}
          customComponent={
            <Can I="CREATE" a="Partition">
              <Link
                to="/admin/partitions/add"
                role="button"
                className="btn btn-sm text-white bg-pink"
              >
                New
              </Link>
            </Can>
          }
        />
      </Fragment>
    );
  }
}

export default PartitionList;
