import React, { Component, Fragment } from "react";
import { convertDateMonthNameYear } from "../../lease/DateUtil";

class PartitionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: []
    };
  }

  getValue = (list, keyColumn, valueColumn, key) => {
    if (!list || !key) return "";

    for (let index = 0; index < list.length; index++) {
      let record = list[index];
      if (record[keyColumn] === key) {
        return record[valueColumn];
      }
    }
    return "";
  };

  comparePreviousHistory = (currentObj, prevObj) => {
    let diff = {};
    const keys = [
      "partitionType",
      "partitionOwnerName",
      "status",
      "area",
      "areaPrice",
      "seatCount",
      "seaterPrice",
      "remarks",
      "rentTaxable",
      "rentTaxRate",
      "maxSeatingCapacity",
      "partitionFacilityName",
      "partitionCategoryName"
    ];

    keys.forEach(key => {
      const cValue = currentObj[key];
      const pValue = prevObj[key];
      if (cValue !== pValue) {
        diff[key] = pValue ? pValue : "Empty";
      }
    });

    const cam = currentObj.amenities.reduce((set, amenity) => {
      set[`${amenity.amenityType}:${amenity.amenityReference}`] = true;
      return set;
    }, {});
    const pam = prevObj.amenities.reduce((set, amenity) => {
      set[`${amenity.amenityType}:${amenity.amenityReference}`] = true;
      return set;
    }, {});

    const amenityDiff = {};
    Object.keys(cam).forEach(key => {
      amenityDiff[key] = pam[key] ? "NOC" : "ADD";
    });

    Object.keys(pam).forEach(key => {
      if (!cam[key]) {
        amenityDiff[key] = "DEL";
      }
    });

    diff.amenities = amenityDiff;

    return diff;
  };

  loadChanges = () => {
    const propsHistory = this.props.history;

    if (propsHistory && propsHistory.length > 0) {
      const sortedArray = propsHistory.sort((a, b) => {
        return b.effectiveDate - a.effectiveDate;
      });

      for (let index = 0; index < sortedArray.length - 1; index++) {
        sortedArray[index].changes = this.comparePreviousHistory(
          sortedArray[index],
          sortedArray[index + 1]
        );
      }
      sortedArray[sortedArray.length - 1].changes = { amenities: {} };

      this.setState({ history: sortedArray });
    }
  };

  componentDidMount() {
    this.loadChanges();
  }

  componentDidUpdate = prevState => {
    if (prevState.history !== this.props.history) {
      this.loadChanges();
    }
  };

  keyValue = (obj, key) => obj[key];

  partTypesFn = (obj, key) => {
    return this.getValue(
      this.props.partitionTypes,
      "partitionTypeCode",
      "partitionTypeDesc",
      obj[key]
    );
  };
  areaFn = obj => {
    const areaUnit = this.getValue(
      this.props.areaUnits,
      "unitCode",
      "unitDesc",
      obj.areaUnit
    );

    return `${obj.area} ${areaUnit}`;
  };

  partStatusFn = (obj, key) => {
    return this.getValue(
      this.props.statusList,
      "statusCode",
      "statusDesc",
      obj[key]
    );
  };

  rentTaxableFn = (obj, key) => {
    return obj[key] ? "Yes" : "No";
  };

  render() {
    const changedValueClassNames = "badge badge-pill badge-warning";
    const keys = [
      ["partitionType", "Partition Type", this.partTypesFn],
      ["status", "Status", this.partStatusFn], //
      [
        "partitionOwnerName",
        "Partition Owner",
        (obj, key) => {
          return obj[key] ? obj[key] : "";
        }
      ],
      [
        "partitionFacilityName",
        "Facility Name",
        (obj, key) => {
          return obj[key] ? obj[key] : "";
        }
      ],
      [
        "partitionCategoryName",
        "Partition Category",
        (obj, key) => {
          return obj[key] ? obj[key] : "";
        }
      ],
      ["area", "Area", this.areaFn],
      ["areaPrice", "Area Price", this.keyValue],
      ["seatCount", "Seat Count", this.keyValue],
      ["maxSeatingCapacity", "Max Seating Capacity", this.keyValue],
      ["seaterPrice", "Seater Price", this.keyValue],
      ["remarks", "Remarks", this.keyValue],
      ["rentTaxable", "Rent Taxable", this.rentTaxableFn],
      ["rentTaxRate", "Tax Rate", this.keyValue]
    ];
    const { history } = this.state;
    if (history.length === 0) {
      return null;
    }

    const allHistory = history.map((hsty, index) => {
      let style = {};
      if (index % 2 !== 0) {
        style = {
          backgroundColor: "#c4d1df"
        };
      }
      return (
        <Fragment key={index}>
          <div style={style} className="pt-3 pb-3">
            <div className="form-row mb-3 mt-3 pl-3">
              <div className="form-row pl-3 mb-3">
                <div className="col-12">
                  <strong>Changes Effective From : </strong>
                  {convertDateMonthNameYear(hsty.updatedAt)}
                </div>
              </div>
              <div className="form-row">
                {keys.map(([key, label, valueFn]) => {
                  return (
                    <div
                      className="form-group mb-0 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"
                      key={key}
                    >
                      <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                        <strong>{label}</strong>
                      </label>
                      <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                        <span
                          className={
                            hsty.changes[key] ? changedValueClassNames : ""
                          }
                        >
                          {valueFn(hsty, key)}
                        </span>
                      </label>
                    </div>
                  );
                })}

                {hsty.amenities.length > 0 ? (
                  <div className="form-group mb-0 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                    <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12 align-top">
                      <strong>Amenities </strong>
                    </label>
                    <label className="col-xs-6 col-sm-6 col-md-12 col-lg-12 col-xl-12">
                      {Object.entries(hsty.changes.amenities).map(
                        ([amt, action]) => {
                          let amnetityClass = "";
                          let display = amt;
                          if (action === "ADD") {
                            amnetityClass =
                              "badge badge-pill badge-success p-1 pl-2 pr-2 mr-2";
                          } else if (action === "DEL") {
                            amnetityClass =
                              "badge badge-pill badge-danger p-1 pl-2 pr-2 mr-2";
                            display = <del>{amt}</del>;
                          } else {
                            amnetityClass =
                              "badge badge-pill badge-secondary p-1 pl-2 pr-2 mr-2";
                          }
                          return (
                            <span key={amt} className={amnetityClass}>
                              {display}
                            </span>
                          );
                        }
                      )}
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* {index !== history.length - 1 ? <hr /> : null} */}
        </Fragment>
      );
    });

    return (
      <div className="row mt-4 mb-5">
        <div className="col-md-12">
          <div className="card elevate">
            <div className="card-header edit-page-title border-0 mt-3 text-center">
              Partition History
            </div>
            <div className="card-body h-100 p-0">{allHistory}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartitionHistory;
