import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import HttpUtil from "../../common/HttpUtil";
import { GRANT_PROGRAM_API } from "../../common/Constants";

class GrantInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grant: null,
      breadCrumbList: []
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      confirm: false
    });
  };

  getGrant = id => {
    const url = `${GRANT_PROGRAM_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        const grant = {};
        grant._id = data._id || "";
        grant.name = data.name || "";
        grant.fullName = data.fullName || "";

        grant.type = data.type;
        grant.description = data.description;
        grant.logo = data.logo || "";
        grant.website = data.website;

        grant.state = data.state || "";
        grant.country = data.country || "IN";

        grant.agency = data.agency;

        const breadCrumbList = [
          {
            linkTo: "/funding/agencies",
            displayName: "Funding Agencies"
          },
          {
            linkTo: `/funding/setup/agencies/info/${grant.agency._id}`,
            displayName: grant.agency.name
          },
          {
            linkTo: null,
            displayName: grant.name
          }
        ];
        this.setState({ grant: data, breadCrumbList });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getGrant(id);
    }
  };

  render() {
    const { grant } = this.state;
    if (grant === null) {
      return null;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <Breadcrumb breadCrumbList={this.state.breadCrumbList} />
          </div>
        </div>

        <div
          className="elevate"
          style={{ padding: "1em", backgroundColor: "#fff" }}
        >
          <div className="card border-0">
            <div className="card-body text-center">
              <div className="d-flex justify-content-center align-items-center mb-3">
                <h2 className="mb-0" style={{ color: "rgb(100, 122, 203)" }}>
                  {grant.name}
                </h2>
              </div>
              <div className="text-justify py-2">{grant.description}</div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GrantInformation;
