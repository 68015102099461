import React from "react";

export default function LoadingComponent() {
  return (
    <div className="row vh-80 text-center">
      <div className="col-12">
        <div
          className="spinner-border align-middle"
          style={{ width: "2rem", height: "2rem" }}
          role="status"
        />
      </div>
    </div>
  );
}
