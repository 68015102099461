import React, { Component, Fragment } from "react";

import {
  CAMPUS_API,
  STATUS_LIST_API,
  AREA_UNIT_LIST_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import ErrorPage from "../../common/error.page";
import DefaultAlert from "../DefaultAlert";
import Campus from "./Campus";
import CampusBasedBuildingList from "./CampusBasedBuildingList";
class EditCampus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      name: "",
      managedBy: "",
      notes: "",
      city: "",
      district: "",
      state: "",
      country: "",
      postalCode: "",
      landmark: "",
      status: "",
      areaUnit: null,
      areaPrice: 0,
      seaterPrice: 0,

      buildings: [],

      //for input error alert
      inputError: false,

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: "",
      permanentFailure: false,

      // for selector
      statusList: [],
      areaUnitList: []
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = () => {
    this.getStatusList();
    this.getAreaUnitList();

    let currentUrl = window.location.href;
    currentUrl = currentUrl.split("edit/");

    let id = currentUrl[1];
    this.getCampusById(id);
  };

  handleApiSuccess = (data, flag) => {
    if (flag === "GET_BY_ID_API") {
      this.setState({
        _id: data._id,
        name: data.name,
        managedBy: data.managedBy,
        notes: data.notes,
        city: data.city,
        district: data.district,
        state: data.state,
        country: data.country,
        postalCode: data.postalCode,
        landmark: data.landmark,
        status: data.status,
        areaUnit: data.areaUnit,
        areaPrice: data.areaPrice,
        seaterPrice: data.seaterPrice,
        buildings: data.buildings
      });
    } else if (flag === "UPDATE_API") {
      this.setState({
        // false
        inputError: false,
        showAlert: true,
        alertMode: "success",
        alertMessage: `Campus "${data.name}" has been updated successfully.`,

        _id: data._id,
        name: data.name,
        managedBy: data.managedBy,
        notes: data.notes,
        city: data.city,
        district: data.district,
        state: data.state,
        country: data.country,
        postalCode: data.postalCode,
        landmark: data.landmark,
        status: data.status,
        areaUnit: data.areaUnit,
        areaPrice: data.areaPrice,
        seaterPrice: data.seaterPrice,
        buildings: data.buildings
      });
    }

    if (flag === "STATUS_LIST") {
      this.setState({ statusList: data });
    }

    if (flag === "AREA_UNIT_LIST") {
      this.setState({ areaUnitList: data });
    }
  };

  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message,
      permanentFailure
    });
  };

  getCampusById = id => {
    let url = CAMPUS_API + "/" + id;
    const authToken = this.props.authToken;

    let showAlert,
      alertMode,
      alertMessage = null;

    if (this.props.location.state && this.props.location.state.created) {
      showAlert = true;
      alertMode = "success";
      alertMessage = "New Campus has been added successfully.";
    }

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => {
        this.handleApiSuccess(data, "GET_BY_ID_API");
        this.setState({
          showAlert,
          alertMode,
          alertMessage
        });
      },
      (data, status) => {
        this.handleApiFailed(data.message, status === 403 || status === 404);
      },
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getCampus = () => {
    const {
      name,
      managedBy,
      notes,
      city,
      district,
      state,
      country,
      postalCode,
      landmark,
      status,
      areaUnit,
      areaPrice,
      seaterPrice
    } = this.state;

    const campus = {
      name,
      managedBy,
      notes,
      city,
      district,
      state,
      country,
      postalCode,
      landmark,
      status,
      areaUnit,
      areaPrice,
      seaterPrice
    };

    return campus;
  };

  validation = campus => {
    let inputError = {};
    let valid = true;
    if (campus.name === "") {
      valid = false;
      inputError["name"] = true;
    }

    // if (campus.managedBy === "") {
    //   valid = false;
    //   inputError["managedBy"] = true;
    // }

    if (!valid) {
      return this.setState({
        inputError: { ...inputError }
      });
    }
    return valid;
  };

  updateCampusById = () => {
    // Extract campus object from component state
    const campus = this.getCampus();

    // validation and show error alert
    if (!this.validation(campus)) {
      return;
    }

    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = CAMPUS_API + "/" + this.state._id;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    // Fire request
    HttpUtil.put(
      url,
      headers,
      campus,
      data => this.handleApiSuccess(data, "UPDATE_API"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  getStatusList = () => {
    const url = STATUS_LIST_API + "CAMPUS";
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data, "STATUS_LIST"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAreaUnitList = () => {
    const url = AREA_UNIT_LIST_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data, "AREA_UNIT_LIST"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  render() {
    const {
      inputError,

      //Default alert
      showAlert,
      alertMode,
      alertMessage,
      permanentFailure,

      //campus
      _id,
      name,
      managedBy,
      notes,
      city,
      district,
      state,
      country,
      postalCode,
      landmark,
      status,
      areaUnit,
      areaPrice,
      seaterPrice,
      buildings,

      // for selectors
      statusList,
      areaUnitList
    } = this.state;

    if (!_id && !showAlert) {
      return (
        <div className="row">
          <div className="col-10 offset-1">
            <div className="progress-bar-loading-text">Loading...</div>
            <div className="progress-bar">
              <div className="indeterminate" />
            </div>
          </div>
        </div>
      );
    }

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 col-xl-12">
            {/* show alert message  */}
            <DefaultAlert
              show={showAlert}
              alertMode={alertMode}
              message={alertMessage}
              closeAlert={this.closeDefaultAlert}
            />

            <Campus
              //campus
              name={name}
              managedBy={managedBy}
              notes={notes}
              city={city}
              district={district}
              state={state}
              country={country}
              postalCode={postalCode}
              landmark={landmark}
              status={status}
              areaUnit={areaUnit}
              areaPrice={areaPrice}
              seaterPrice={seaterPrice}
              // events
              newCampus={false}
              onChange={this.onChange}
              submit={this.updateCampusById}
              //inputError
              inputError={inputError}
              // for selectors
              statusList={statusList}
              areaUnitList={areaUnitList}
            />
          </div>
        </div>
        <CampusBasedBuildingList buildings={buildings} />
      </Fragment>
    );
  }
}

export default EditCampus;
