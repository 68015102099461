import React from "react";
import PivotReport from "../PivotReport";
import {
  checkNaN,
  getPercentString,
  getPercent,
  formatNumber
} from "../ReportsUtil";

const dimensions = [
  { value: "campusName", title: "Campus" },
  { value: "buildingName", title: "Building" },
  { value: "floorName", title: "Floor" }
];

function reduce(row, aggRecord) {
  //   aggRecord.count = (aggRecord.count || 0) + 1;
  aggRecord.floorTotalArea =
    (aggRecord.floorTotalArea || 0) + parseFloat(row.floorTotalArea);
  aggRecord.floorUsableArea =
    (aggRecord.floorUsableArea || 0) + parseFloat(row.floorUsableArea);
  aggRecord.usedArea = (aggRecord.usedArea || 0) + parseFloat(row.usedArea);
  aggRecord.provisionedArea =
    (aggRecord.provisionedArea || 0) + parseFloat(row.provisionedArea);

  const usableArea = aggRecord.floorUsableArea;
  const usedArea = aggRecord.usedArea;
  const provisionedArea = aggRecord.provisionedArea;

  const availableUsableArea = usableArea - usedArea - provisionedArea;
  aggRecord.availableUsableArea = availableUsableArea;
  aggRecord.allocatedArea = usedArea + provisionedArea;
  return aggRecord;
}

const calculations = [
  {
    title: "Total Area",
    value: "floorTotalArea",
    template: (val, row) => formatNumber(row.floorTotalArea)
    // template: (val, row) =>
    //   getPercentString(row.floorTotalArea, row.floorUsableArea)
    //   sortBy: row => getPercent(row.floorTotalArea, row.floorUsableArea)
  },

  {
    title: "Usable Area",
    value: "floorUsableArea",
    template: (val, row) => formatNumber(row.floorUsableArea),
    sortBy: row => checkNaN(row.floorUsableArea)
  },

  {
    title: "Allocated Space",
    value: "allocatedArea",
    template: (val, row) => formatNumber(row.allocatedArea),
    sortBy: row => checkNaN(row.allocatedArea)
  },

  {
    title: "Used Area",
    value: "usedArea",
    template: (val, row) => formatNumber(row.usedArea),
    sortBy: row => checkNaN(row.usedArea)
  },

  {
    title: "Allocated Space Usage",
    value: "usedArea",
    template: (val, row) => getPercentString(row.usedArea, row.allocatedArea),
    sortBy: row => getPercent(row.usedArea, row.allocatedArea)
  }
];

function ConsolidatedFacilityUsage(props) {
  return (
    <PivotReport
      title="Facility Usage"
      url="/api/v1/reports/facility/consolidated-facility-usage"
      dimensions={dimensions}
      reduce={reduce}
      calculations={calculations}
    />
  );
}

export default ConsolidatedFacilityUsage;
