import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import ShowHideComponent from "./ShowHideComponent";
import {
  sizePerPageOptionRenderer,
  paginationTotalRenderer
} from "../common/TableList";

function CompactTable(props) {
  const hasData = props.data ? true : false;

  const options = {
    custom: true,
    alwaysShowAllBtns: true,
    sizePerPageOptionRenderer,
    paginationTotalRenderer,
    withFirstAndLast: false,
    hidePageListOnlyOnePage: true,
    sizePerPage: 10,
    totalSize: props.data ? props.data.length : 0
  };

  return (
    <ShowHideComponent show={hasData}>
      <PaginationProvider pagination={paginationFactory(options)}>
        {({ paginationProps, paginationTableProps }) => (
          <Fragment>
            <BootstrapTable
              bootstrap4
              keyField={"_id"}
              data={props.data}
              columns={props.columns}
              condensed
              bordered={false}
              noDataIndication={props.noDataIndication}
              {...paginationTableProps}
            />
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="table-list-total">
                  <PaginationTotalStandalone {...paginationProps} />
                </div>
              </div>

              <div className="col-lg-8 col-md-8 col-sm-12">
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          </Fragment>
        )}
      </PaginationProvider>
    </ShowHideComponent>
  );
}

export default CompactTable;
