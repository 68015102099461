import React, { Component, Fragment } from "react";
import { USER_CHANGE_PASSWORD_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
      inputError: {}
    };
  }
  onChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    this.setState({ [targetName]: targetValue });
  };

  handleConfirmPassword = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    const newPassword = this.state.newPassword;

    let inputError = {};
    inputError.newPassword = newPassword === "" || newPassword === null;
    inputError.confirmPassword = newPassword !== targetValue;
    this.setState({ [targetName]: targetValue, inputError });
  };

  validate = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;

    const crrPassword = currentPassword ? currentPassword : "";
    const nPassword = newPassword ? newPassword : "";
    const conPassword = confirmPassword ? confirmPassword : "";

    let inputError = {};
    inputError.currentPassword = crrPassword === "";
    inputError.newPassword = nPassword.length < 8;
    inputError.confirmPassword = nPassword !== conPassword;

    const invalid = Object.values(inputError).some(item => item);
    if (invalid) {
      this.setState({ inputError });
      return false;
    } else {
      this.setState({ inputError: {} });
      return true;
    }
  };

  handleSubmit = () => {
    if (!this.validate()) {
      return;
    }

    const { currentPassword, newPassword, confirmPassword } = this.state;

    const chengePassword = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmPassword
    };

    const url = USER_CHANGE_PASSWORD_API;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      chengePassword,
      data => {
        this.setState({
          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: "New password is updated successfully.",
          currentPassword: null,
          newPassword: null,
          confirmPassword: null
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  render() {
    const {
      currentPassword,
      newPassword,
      confirmPassword,

      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,

      inputError
    } = this.state;

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Change Password</h5>
                <div className="form-group ">
                  <label htmlFor="inputPasswordCurrent">Current password</label>
                  <input
                    type="password"
                    className={
                      inputError["currentPassword"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    name="currentPassword"
                    value={currentPassword ? currentPassword : ""}
                    onChange={this.onChange}
                  />
                  <div className="invalid-feedback">
                    Please enter current password *
                  </div>
                </div>
                <div className="form-group ">
                  <label htmlFor="inputPasswordNew">New password</label>
                  <input
                    type="password"
                    className={
                      inputError["newPassword"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    name="newPassword"
                    value={newPassword ? newPassword : ""}
                    onChange={this.onChange}
                  />
                  <div className="invalid-feedback">
                    New password must be minimum 8 characters *
                  </div>
                </div>

                <div className="form-group ">
                  <label htmlFor="inputPasswordNew2">Confirm password</label>
                  <input
                    type="password"
                    className={
                      inputError["confirmPassword"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    name="confirmPassword"
                    value={confirmPassword ? confirmPassword : ""}
                    onChange={this.handleConfirmPassword}
                  />
                  <div className="invalid-feedback">
                    Confirm password doesn't match *
                  </div>
                </div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ChangePassword;
