import React, { Component, Fragment } from "react";
import HttpUtil from "../../common/HttpUtil";
import { USERS_API } from "../../common/Constants";
import { Link } from "react-router-dom";
import TableList from "../../common/TableList";
import AlertComponent from "../../common/AlertComponent";
import ability from "../../../auth/ability";

function actionsFormater(cell, row) {
  if (!row) {
    return null;
  }

  const userActive = row.isActive;
  const cssClassName = userActive
    ? "badge badge-success p-1"
    : "badge badge-danger p-1";

  const label = (
    <span className={cssClassName}>{userActive ? "Active" : "Inactive"}</span>
  );

  return label;
}

function getStatus(cell, row) {
  return row.isActive ? "Active" : "Inactive";
}

function linkFormater(cell, row) {
  if (!row) {
    return null;
  }
  const url = `/external-users/edit/${row._id}`;
  return <Link to={url}>{row.username}</Link>;
}

function getFullName(cell, row) {
  if (!row) {
    return null;
  }

  const names = [];
  if (row.firstName) {
    names.push(row.firstName);
  }
  if (row.lastName) {
    names.push(row.lastName);
  }

  return names.join(" ");
}

const INCUBATOR_USER_LIST_COLUMNS = [
  {
    dataField: "username",
    text: "Username",
    sort: true,
    formatter: linkFormater,
    filterValue: cell => cell
  },
  {
    dataField: "firstName",
    text: "Name",
    sort: true,
    formatter: getFullName,
    filterValue: getFullName,
    csvFormatter: getFullName
  },
  {
    dataField: "isActive",
    text: "Status",
    formatter: actionsFormater,
    csvFormatter: getStatus,
    filterValue: getStatus
  }
];

export class OtherUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null
    };
  }
  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getIncubatorUsers = () => {
    let url = `${USERS_API}/?userType=${"Other"}`;

    HttpUtil.get(
      url,
      {},
      data => this.setState({ users: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getIncubatorUsers();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={INCUBATOR_USER_LIST_COLUMNS}
          data={this.state.users}
          noDataIndication="No User found."
          sizePerPage="25"
          headerName="Incubatee Users"
          showSearchbar={true}
          customComponent={<Actions />}
        />
      </Fragment>
    );
  }
}

export default OtherUserList;

const Actions = () => {
  const hasPermission = ability.rulesFor("CREATE", "User").some(
    rule =>
      !rule.conditions || // All conditions
      !rule.conditions.userType || // No User Type mentioned
      rule.conditions.userType === "Other" // User Type is Incubator
  );
  return (
    hasPermission && (
      <Link
        to="/external-users/new"
        role="button"
        className="btn btn-sm text-white bg-pink"
      >
        New
      </Link>
    )
  );
};
