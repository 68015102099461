import { TOGGLE_SIDEBAR } from "./ToggleSidebarAction";

const initialState = {
  hide: false
};

const ToggleSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        hide: !action.hide
      };

    default:
      return state;
  }
};

export default ToggleSidebarReducer;
