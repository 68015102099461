import React, { Component, Fragment } from "react";
import AlertComponent from "../../common/AlertComponent";
import DatePicker from "react-datepicker";
import { PUBLIC_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { Formik, Form, Field } from "formik";
import {
  ENQUIRY_TYPES,
  REQUESTOR_CATEGORY_TYPES
} from "../../common/LookupConstants";
import Select from "react-select";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const CustomForm = props => {
  const { values, readOnly, touched, errors } = props;

  const incubator = values.incubator || {};

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <Form>
      <div className="card border-0 pl-1 pr-1">
        <div className="d-flex justify-content-center align-items-center mt-4">
          {incubator.logoPath ? (
            <div
              className="mr-2 d-flex align-self-center"
              style={{ width: 50, height: 50 }}
            >
              <img className="w-100" src={incubator.logoPath} alt="User" />
            </div>
          ) : (
            <i
              className="fas fa-industry pr-2 fa-3x"
              style={{ color: "#647acb" }}
            />
          )}

          <h1 className="mb-0" style={{ color: "#647acb" }}>
            {incubator.name}
          </h1>
        </div>
        <div className="edit-page-section-header-text mt-2 text-center">
          <strong>Enquiry Form</strong>
        </div>

        <div className="card-body">
          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
              Enquiry Regarding
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <Field
                component="select"
                name="enquiryType"
                className="form-control form-control-sm"
                value={values.enquiryType || ""}
                {...disabledField}
              >
                <option value="">Select...</option>
                {ENQUIRY_TYPES.map((opt, i) => (
                  <option key={i} value={opt.key}>
                    {opt.value}
                  </option>
                ))}
              </Field>
              <div className="invalid-feedback">
                {/* {getError(touch, error, "type")} */}
              </div>
            </div>
          </div>
          {values.enquiryType === "EQUIPMENT_RENTAL" ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
                Name of Equipment
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Select
                  key="001"
                  isMulti
                  name="equipments"
                  isDisabled={readOnly}
                  value={values.equipments || null}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                <div className="invalid-feedback">
                  {/* {getError(touch, error, "type")} */}
                </div>
              </div>
            </div>
          ) : null}

          {values.enquiryType === "LAB_USAGE" ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
                Lab Test {/*labTest*/}
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Field
                  type="text"
                  className="form-control form-control-sm"
                  name="labTest"
                  value={values.labTest || ""}
                  {...disabledField}
                />
                <div className="invalid-feedback">
                  {/* {getError(touch, error, "type")} */}
                </div>
              </div>
            </div>
          ) : null}

          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
              Priority
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInlineLOW"
                  name="priority"
                  className="custom-control-input"
                  checked={values.priority === "LOW" ? true : false}
                  {...disabledField}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInlineLOW"
                >
                  Low
                </label>
              </div>
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInlineMEDIUM"
                  name="priority"
                  className="custom-control-input"
                  checked={values.priority === "MEDIUM" ? true : false}
                  {...disabledField}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInlineMEDIUM"
                >
                  Medium
                </label>
              </div>
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInlineHIGH"
                  name="priority"
                  className="custom-control-input"
                  checked={values.priority === "HIGH" ? true : false}
                  {...disabledField}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInlineHIGH"
                >
                  High
                </label>
              </div>
            </div>
          </div>

          <div className="form-group row mb-4">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
              Date
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <label className="mb-1">From</label>
                  <DatePicker
                    value={getDisplayDate(values.from)}
                    selected={values.from}
                    utcOffset={0}
                    placeholderText="dd-mm-yyyy"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabled={readOnly}
                  />

                  {errors.from ? (
                    <div
                      className="text-danger m-1"
                      style={{ fontSize: "0.850em" }}
                    >
                      From required field
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-lg-6">
                  <label className="mb-1">To</label>
                  <DatePicker
                    value={getDisplayDate(values.to)}
                    selected={values.to}
                    utcOffset={0}
                    minDate={values.from}
                    placeholderText="dd-mm-yyyy"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabled={readOnly}
                  />
                  {errors.to ? (
                    <div
                      className="text-danger m-1"
                      style={{ fontSize: "0.850em" }}
                    >
                      To required field
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
              Are you currently or previously incubated at PSG-STEP?
              {/* incubatee */}
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInline1"
                  name="incubatee"
                  className="custom-control-input"
                  checked={values.incubatee ? true : false}
                  {...disabledField}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline1"
                >
                  Yes
                </label>
              </div>
              <div
                className="custom-control custom-radio d-inline-block"
                style={{ width: "33.33%" }}
              >
                <Field
                  type="radio"
                  id="customRadioInline2"
                  name="incubatee"
                  className="custom-control-input"
                  checked={!values.incubatee ? true : false}
                  {...disabledField}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline2"
                >
                  No
                </label>
              </div>
            </div>
          </div>

          {values.incubatee ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                Name of Incubatee
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Select
                  key="002"
                  name="nameOfIncubatee"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={readOnly}
                  isClearable
                  value={values.nameOfIncubatee || null}
                />
                <div className="invalid-feedback">
                  {/* {getError(touch, error, "type")} */}
                </div>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Are you already associated with any other incubator or
                  accelerator?
                  {/* otherIncubator */}
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInline3"
                      name="otherIncubator"
                      className="custom-control-input"
                      checked={values.otherIncubator ? true : false}
                      {...disabledField}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline3"
                    >
                      Yes
                    </label>
                  </div>
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInline4"
                      name="otherIncubator"
                      className="custom-control-input"
                      checked={!values.otherIncubator ? true : false}
                      {...disabledField}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline4"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              {values.otherIncubator ? (
                <Fragment>
                  <div className="form-group row">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                      Incubator Name
                    </label>
                    <div className="col-12 col-lg-6 col-xl-6">
                      <Field
                        type="text"
                        className={getErrorCssClassName(
                          touched,
                          errors,
                          "incubatorName"
                        )}
                        name="incubatorName"
                        value={values.incubatorName || ""}
                        {...disabledField}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                      Incubator Location
                    </label>
                    <div className="col-12 col-lg-6 col-xl-6">
                      <Field
                        type="text"
                        className={getErrorCssClassName(
                          touched,
                          errors,
                          "incubatorLocation"
                        )}
                        name="incubatorLocation"
                        value={values.incubatorLocation || ""}
                        {...disabledField}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : null}

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  What best describes you? {/* requestorCategory  */}
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    component="select"
                    name="requestorCategory"
                    className="form-control form-control-sm"
                    value={values.requestorCategory || ""}
                    {...disabledField}
                  >
                    <option value="">Select...</option>
                    {REQUESTOR_CATEGORY_TYPES.map((opt, i) => (
                      <option key={i} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </Field>
                  <div className="invalid-feedback">
                    {/* {getError(touch, error, "type")} */}
                  </div>
                </div>
              </div>

              {values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Institution Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className="form-control form-control-sm"
                      name="institutionName"
                      value={values.institutionName || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Requestor Type
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInline5"
                      name="incubateeType"
                      className="custom-control-input"
                      checked={
                        values.incubateeType === "INDIVIDUAL" ? true : false
                      }
                      {...disabledField}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline5"
                    >
                      Individual
                    </label>
                  </div>
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInline6"
                      name="incubateeType"
                      className="custom-control-input"
                      checked={
                        values.incubateeType === "COMPANY" ? true : false
                      }
                      {...disabledField}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline6"
                    >
                      Organization
                    </label>
                  </div>
                </div>
              </div>

              {values.incubateeType === "INDIVIDUAL" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(touched, errors, "name")}
                      name="name"
                      value={values.name || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Organization Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "organizationName"
                      )}
                      name="organizationName"
                      value={values.organizationName || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Contact Person Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "contactPersonName"
                      )}
                      name="contactPersonName"
                      value={values.contactPersonName || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              {values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Department
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "department"
                      )}
                      name="department"
                      value={values.department || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ||
              values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Designation
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "contactPersonDesignation"
                      )}
                      name="organizationName"
                      value={values.contactPersonDesignation || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Email
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="email"
                    className={getErrorCssClassName(touched, errors, "email")}
                    name="email"
                    value={values.email || ""}
                    {...disabledField}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Mobile
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="number"
                    className={getErrorCssClassName(touched, errors, "mobile")}
                    name="mobile"
                    value={values.mobile || ""}
                    {...disabledField}
                  />
                </div>
              </div>
            </Fragment>
          )}

          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
              Requestor Notes {/*userNotes*/}
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <Field
                component="textarea"
                className="form-control form-control-sm"
                name="userNotes"
                rows="5"
                value={values.userNotes}
                {...disabledField}
              />
              <div className="invalid-feedback">
                {/* {getError(touch, error, "type")} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const IncubatorEnquiryForm = props => {
  return (
    <div className="card edit-page-container">
      <Formik
        enableReinitialize
        initialValues={props.initialValues}
        onSubmit={(values, { setSubmitting }) =>
          props.handleSubmit(values, { setSubmitting })
        }
        render={renderProps => <CustomForm {...renderProps} {...props} />}
      />
    </div>
  );
};

class IncubatorEnquiryReadOnlyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiry: {
        _id: null,
        incubator: null,
        enquiryType: "",
        incubatee: "",
        priority: "",
        from: "",
        to: "",
        institutionName: "",
        nameOfIncubatee: "",
        otherIncubator: "",
        incubatorName: "",
        incubatorLocation: "",
        requestorCategory: "",
        equipments: null,
        labTest: "",
        userNotes: "",
        incubatorNotes: [],
        incubatorNote: "",
        responseMessage: "",
        stage: "",
        status: "",
        action: "",
        actions: []
      },
      readOnly: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      readOnly: true
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getEnquiryById = id => {
    const url = `${PUBLIC_API}/enquiry/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        const enquiry = { ...data };
        const from = convertNumberToDate(data.from);
        const to = convertNumberToDate(data.to);
        enquiry.from = from;
        enquiry.to = to;
        this.setState({ enquiry, readOnly: true });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  loadLocationState = () => {
    let location = this.props.location;

    if (location.state && location.state.message) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: location.state.message
      });
      window.scrollTo(0, 0);
    }
  };

  componentDidMount = () => {
    this.loadLocationState();

    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getEnquiryById(id);
    }
  };

  render() {
    const { readOnly } = this.state;

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const formikProps = {
      initialValues: this.state.enquiry,
      validationSchema: {},
      assetList: this.state.assetList,
      incubatees: this.state.incubatees,
      readOnly: this.state.readOnly
    };
    return (
      <div
        className="wrapper"
        style={{ backgroundColor: "#dadada", minHeight: "100vh" }}
      >
        <div className="main w-100">
          <div className="content">
            <div className="container-fluid-lg container-fluid-xl">
              <div className="row mb-3">
                <div className="col-md-2" />
                <div className="col-md-8">
                  <AlertComponent {...alertProps} />
                </div>
                <div className="col-md-2" />
              </div>

              {readOnly === null ? (
                <div className="row mb-3">
                  <div className="col-md-2" />
                  <div className="col-md-8">
                    <h3>Loading ...</h3>
                  </div>
                  <div className="col-md-2" />
                </div>
              ) : (
                <div className="row mb-5">
                  <div className="col-md-2" />
                  <div className="col-md-8">
                    <IncubatorEnquiryForm {...formikProps} />
                  </div>
                  <div className="col-md-2" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IncubatorEnquiryReadOnlyForm;
