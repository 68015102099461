import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableList from "../../common/TableList";
import AlertComponent from "../../common/AlertComponent";
import { GRANT_PROGRAM_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";

function linkFormater(cell, row) {
  const url = `/funding/grants/edit/${row._id}`;
  return <Link to={url}>{row.name}</Link>;
}

function agencyFormatter(cell, row) {
  return row.agency.name;
}

const columnsGrants = [
  {
    dataField: "name",
    text: "Grant Name",
    sort: true,
    formatter: linkFormater
  },
  {
    dataField: "agency",
    text: "Agency",
    sort: true,
    formatter: agencyFormatter,
    csvFormatter: agencyFormatter
  },
  {
    dataField: "fullName",
    text: "Grant Full Name",
    sort: true
  }
];

class GrantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grantList: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    HttpUtil.get(
      GRANT_PROGRAM_API,
      {},
      data => {
        this.setState({ grantList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb border-bottom-0">
                <li className="breadcrumb-item">
                  <Link to="/funding/agencies">Funding Agencies</Link>
                </li>
                <li className="breadcrumb-item active">Grants</li>
              </ol>
            </nav>
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsGrants}
          data={this.state.grantList}
          noDataIndication="No Grants found."
          sizePerPage="10"
          headerName="Grants"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default GrantList;

const RenderCustomButton = props => {
  return (
    <Link
      to="/funding/grants/new"
      role="button"
      className="btn btn-sm text-white bg-pink"
    >
      New
    </Link>
  );
};
