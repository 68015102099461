import React, { Component } from "react";

/** Bootstrap alert css */
const alertList = {
  success: "alert alert-success text-center",
  danger: "alert alert-danger text-center",
  info: "alert alert-info text-center"
};

const alertTitle = {
  danger: "Please correct the following error(s),"
};

function getMessage(alertColor, message, close) {
  if (Array.isArray(message) && message.length > 1) {
    const title = alertTitle[alertColor];

    return (
      <div className="p-2 text-left">
        {title}

        {close && (
          <button
            type="button"
            onClick={close}
            className="close pull-right "
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}

        <ul>
          {message.map(msg => (
            <li>{msg}</li>
          ))}
        </ul>
      </div>
    );
  }
  return <div className="text-center p-2">{message}</div>;
}

class AlertComponent extends Component {
  /**  Bootstrap default alert */
  getDefultAlert = (alertColor, message, close) => {
    const cssClassName = alertList[alertColor];
    return (
      <div className={cssClassName} role="alert">
        {getMessage(alertColor, message, close)}
      </div>
    );
  };

  /**  Confirmation dialog alert */
  getConfirmationAlert = (
    message,
    close,
    confirm,
    alertWithTitle,
    alertColor
  ) => {
    let alertMessage = "";

    /** if "message" is array then below code execute */
    if (message instanceof Array) {
      alertMessage = (
        <ul>
          {this.props.message
            ? this.props.message.map(function(item, i) {
                return <li key={i}>{item}</li>;
              })
            : ""}
        </ul>
      );
    }

    /** if "message" is not an array then assign as 'string' */
    alertMessage = message;

    // The gray background
    const backdropStyle = {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      padding: 50,
      zIndex: 1000
    };

    // The modal "window"
    const modalStyle = {
      backgroundColor: "#fff",
      borderRadius: 5,
      margin: "0 auto",
      width: 400
    };
    const textClassName = alertColor ? `text-${alertColor}` : "text-primary";

    const modalTitle = alertWithTitle || "Confirmation";
    return (
      <div className="backdrop" style={backdropStyle}>
        <div className="modal-dialog" style={modalStyle}>
          <div className="modal-content">
            <div className="alert-modal-header">
              <h5 className="h6 modal-title p-0">{modalTitle}</h5>
              <button
                type="button"
                onClick={close}
                className="close pull-right "
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="alert-modal-body">{alertMessage}</div>
            <div className="alert-modal-footer">
              <button
                className={`btn btn-defalt btn-sm ${textClassName} font-weight-bold`}
                onClick={confirm}
                type="button"
              >
                OK
              </button>
              <button
                className="btn btn-outline-default btn-sm"
                onClick={close}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const {
      show,
      type,
      alertColor,
      message,
      close,
      confirm,
      alertWithTitle
    } = this.props;
    if (!show) {
      return null;
    }

    let alert = "";

    /** For default alert */
    if (type === "Default") {
      alert = this.getDefultAlert(alertColor, message, close);
    }

    /** For Confirmation alert */
    if (type === "Confirmation") {
      alert = this.getConfirmationAlert(
        message,
        close,
        confirm,
        alertWithTitle,
        alertColor
      );
    }

    /** We can add more alert type below */

    /** return only alert */
    return alert;
  }
}

export default AlertComponent;
