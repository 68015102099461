import React, { Component, Fragment } from "react";

import HttpUtil from "../../common/HttpUtil";
import {
  INCUBATEES_ONBOARD_API,
  LEASE_ACTIONS_API
} from "../../common/Constants";
class MasterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // form field onboard information
      _id: "",
      organizationName: "",
      domainOperation: "",
      phone: "",
      email: "",
      address: "",
      reference: "",
      incubatorId: "",

      status: "INIT",
      actionStatus: "",
      actionStage: "",
      action: "",
      actionList: [],

      newOnboardForm: true,
      editOnboardForm: false,

      // for form field error
      inputError: {}
    };
  }

  onChange = e => {
    let targetValue = e.target.value;
    if (e.target.type === "number" && isNaN(targetValue)) {
      return;
    }
    this.setState({ [e.target.name]: targetValue });
  };

  componentDidMount = () => {
    const incubator = this.props.incubator;
    if (incubator) {
      this.setState({
        incubatorId: incubator.incubatorId,
        organizationName: incubator.organizationName,
        domainOperation: incubator.domainOperation,
        phone: incubator.phone,
        email: incubator.email,
        status: incubator.status,
        address: incubator.address,
        reference: incubator.reference,
        newOnboardForm: incubator.status === "Not Started" ? true : false,
        onboardId: incubator.incubatorOnboardId
      });
    }
    this.getOnboardActions();
  };

  getOnboardActions = () => {
    let url = LEASE_ACTIONS_API; // we need to change actions api to onboard actions.
    const authToken = this.props.authToken;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken
    };

    return HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({
          actionList: data
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  fetchInOnboardById = id => {
    const url = INCUBATEES_ONBOARD_API + "/" + id;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({
          onboardId: data._id,
          incubatorId: data.incubatorId,
          organizationName: data.name,
          domainOperation: data.domainOperation,
          phone: data.phone,
          address: data.address,
          email: data.email,
          reference: data.reference,
          status: data.status,
          inputError: {},
          fetchOnboard: false
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  validation = incubatorOnboard => {
    let inputError = this.state.inputError;
    let formError = {};
    let errorMessage = "";
    let valid = true;
    if (incubatorOnboard.organizationName === "") {
      errorMessage = "Please enter name";
      formError.organizationName = {
        error: true,
        errorMessage: errorMessage
      };
    }

    if (incubatorOnboard.status === "") {
      errorMessage = "Please select status";
      formError.status = {
        error: true,
        errorMessage: errorMessage
      };
    }

    if (incubatorOnboard.email === "") {
      errorMessage = "Please select email";
      formError.email = {
        error: true,
        errorMessage: errorMessage
      };
    }

    inputError = formError;

    if (Object.keys(inputError).length !== 0) {
      valid = false;
      this.setState({
        inputError: { ...inputError }
      });
    }
    return valid;
  };

  getOnboardState = action => {
    let incubatorId = this.state.incubatorId;
    const incubatorOnboard = {
      name: this.state.organizationName,
      domainOperation: this.state.domainOperation,
      phone: this.state.phone,
      email: this.state.email,
      address: this.state.address,
      reference: this.state.reference,
      status: this.state.status,
      action: action,
      incubatorId: incubatorId
    };

    let newOnboardForm = this.state.newOnboardForm;
    let status = this.state.status;

    if (!newOnboardForm && status !== "Not Started") {
      incubatorOnboard._id = this.state.onboardId;
    }
    return incubatorOnboard;
  };

  handleSubmit = action => {
    let newOnboardForm = this.state.newOnboardForm;
    let status = this.state.status;

    if (newOnboardForm && status === "Not Started") {
      this.handleCreateOnboard(action);
    } else {
      this.handleUpdate(action);
    }
  };

  handleCreateOnboard = action => {
    let incubatorOnboard = this.getOnboardState(action);
    if (!this.validation(incubatorOnboard)) {
      return;
    }

    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = INCUBATEES_ONBOARD_API;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.post(
      url,
      headers,
      incubatorOnboard,
      data => {
        this.setState({
          onboardId: data._id,
          newOnboardForm: false,
          fetchOnboard: true,
          status: data.status,
          inputError: {}
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };
  handleUpdate = action => {
    let incubatorOnboard = this.getOnboardState(action);
    if (!this.validation(incubatorOnboard)) {
      return;
    }

    let id = this.state.onboardId;
    let url = INCUBATEES_ONBOARD_API + "/" + id;

    const authToken = this.props.authToken;

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.put(
      url,
      headers,
      incubatorOnboard,
      data => {
        this.setState({
          onboardId: data._id,
          newIncubatorOnboard: false,
          fetchOnboard: true,
          inputError: {}
        });
      },

      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  render() {
    const {
      organizationName,
      domainOperation,
      phone,
      email,
      reference,
      inputError,
      fetchOnboard,
      onboardId,
      actionList,
      status
    } = this.state;

    if (fetchOnboard) {
      this.fetchInOnboardById(onboardId);
    }

    return (
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Organization Information
              </legend>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                      Name
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className={
                          inputError["organizationName"] !== undefined
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="Name of organization"
                        name="organizationName"
                        value={organizationName}
                        onChange={this.onChange}
                      />
                      {inputError["organizationName"] ? (
                        <div className="invalid-feedback">
                          {inputError.organizationName.errorMessage}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                      Email
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                      <input
                        type="email"
                        className={
                          inputError["email"] !== undefined
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="Email"
                        name="email"
                        value={email}
                        onChange={this.onChange}
                      />
                      {inputError["email"] ? (
                        <div className="invalid-feedback">
                          {inputError.email.errorMessage}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                      Area / Domain
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Area / Domain operation"
                        name="domainOperation"
                        value={domainOperation}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                      Phone
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Phone"
                        name="phone"
                        value={phone}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                  <div className="form-group row">
                    <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left">
                      References
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="References any"
                        name="reference"
                        value={reference}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group row">
            <div className="col-sm-12 text-left">
              <h5>
                <span className="sidebar-badge badge badge-md badge-light">{`Status : ${status}`}</span>
              </h5>
            </div>
            <div className="col-sm-12 text-center">
              {actionList.length > 0
                ? actionList.map((action, idx) => {
                    return (
                      <Fragment key={idx}>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={this.handleSubmit.bind(this, action)}
                        >
                          {action}
                        </button>
                        &nbsp;&nbsp;
                      </Fragment>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MasterForm;
