import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { renderInputData } from "../common/LookupConstants";

import { INTERNAL_ORGS_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";
import ErrorPage from "../common/error.page";

class InternalOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internalOrg: {
        name: null
      },
      editInternalOrg: false,
      editInternalOrgId: null,
      newInternalOrg: null,

      inputError: {},
      permanentFailure: false,

      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    };
  }

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    const internalOrg = this.state.internalOrg;
    internalOrg[targetName] = targetValue;
    this.setState({ internalOrg });
  };

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  validate = () => {
    let inputError = {};
    let internalOrg = this.state.internalOrg;
    inputError.name = internalOrg.name === null || internalOrg.name === "";
    const invalid = Object.values(inputError).some(item => item);

    if (invalid) {
      this.setState({ inputError });
      return false;
    } else {
      this.setState({ inputError: {} });
      return internalOrg;
    }
  };

  handleCreate = () => {
    const internalOrg = this.validate();
    if (!internalOrg) {
      return;
    }

    const internalOrgReq = {
      name: internalOrg.name
    };

    const url = INTERNAL_ORGS_API;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      internalOrgReq,
      data => {
        this.setState({
          editInternalOrg: true,
          editInternalOrgId: data._id
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleUpdate = () => {
    const internalOrg = this.validate();
    if (!internalOrg) {
      return;
    }
    const internalOrgReq = {
      name: internalOrg.name
    };
    const id = this.state._id;

    const url = `${INTERNAL_ORGS_API}/${id}`;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.put(
      url,
      headers,
      internalOrgReq,
      data => {
        this.setState({
          _id: data._id,
          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: "Internal Organization is updated successfully."
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getInternalOrg = id => {
    const url = `${INTERNAL_ORGS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        const internalOrg = this.state.internalOrg;
        internalOrg.name = data.name;
        this.setState({ internalOrg, _id: data._id, newInternalOrg: false });
      },
      (data, status) => {
        this.setState({ newInternalOrg: false });
        return this.handleApiFailed(
          data.message,
          status === 403 || status === 404
        );
      },
      error => {
        this.setState({ newInternalOrg: false });
        this.handleApiFailed(error.toString(), true);
      }
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getInternalOrg(id);
    } else {
      this.setState({ newInternalOrg: true });
    }
  }

  render() {
    const {
      newInternalOrg,
      inputError,
      editInternalOrg,
      editInternalOrgId,
      permanentFailure,
      alertMessage
    } = this.state;
    const { name } = this.state.internalOrg;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (newInternalOrg === null) {
      return null;
    }

    if (editInternalOrg) {
      const url = `/admin/internal/orgs/edit/${editInternalOrgId}`;
      return <Redirect to={url} />;
    }

    const alertCompProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertCompProps} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="edit-page-section-header-text">
                <h5 className="h6 card-title mb-0">Internal Organization</h5>
              </div>
              <div className="card-body">
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Name
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <input
                      type="text"
                      name="name"
                      value={renderInputData(name)}
                      onChange={this.handleInputChange}
                      className={
                        inputError["name"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                    />
                    <div className="invalid-feedback">Please enter name *</div>
                  </div>
                </div>
                <div className="form-group row mt-3 mb-0">
                  <div className="col-12 text-center">
                    {newInternalOrg ? (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={this.handleCreate}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={this.handleUpdate}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default InternalOrg;
