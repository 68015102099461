import { PhoneInfoSchema } from "./PhoneSchema";
import { EmailInfoSchema } from "./EmailSchema";
import { AddressInfoSchema } from "./AddressSchema";
import { IdDocSchema } from "./IdDocSchema";

const ContactSchema = {
  type: "object",
  properties: {
    phones: PhoneInfoSchema,
    emails: EmailInfoSchema,
    addresses: AddressInfoSchema,
    identificationDocs: IdDocSchema
  }
};

const ContactUiSchema = {};

export { ContactSchema, ContactUiSchema };
