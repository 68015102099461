import React from "react";

function getPageNumbersToDisplay(pageIndex, totalPages, paginationPageCount) {
  const halfPoint = Math.floor(paginationPageCount / 2);
  let pageStartIndex = pageIndex - halfPoint;
  let startOffset = 0;
  if (pageStartIndex < 0) {
    startOffset = 0 - pageStartIndex;
    pageStartIndex = 0;
  }
  let pageEndIndex = pageIndex + halfPoint;
  let endOffset = 0;
  if (pageEndIndex >= totalPages) {
    endOffset = totalPages - pageEndIndex;
    pageEndIndex = totalPages - 1;
  }
  pageStartIndex = Math.max(0, pageStartIndex + endOffset);
  pageEndIndex = Math.min(totalPages - 1, pageEndIndex + startOffset);
  const pages = [];
  for (let indx = pageStartIndex; indx <= pageEndIndex; indx++) {
    pages.push(indx);
  }
  return pages;
}

function Pagination({
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  totalRecords,
  pageSize,
  pageIndex,
  gotoPage,
  pageCount,
  setPageSize,
  currentPageRecordCount,
  paginationPageCount
}) {
  if (totalRecords === 0) {
    return null;
  }

  const startRecord = pageIndex * pageSize + 1;
  const endRecord = startRecord + currentPageRecordCount - 1;

  paginationPageCount = paginationPageCount || 7;

  return (
    <div className="bg-white flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="row pl-3 pr-3">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="table-list-total">
            <span className="react-bootstrap-table-pagination-total">
              Showing <strong>{startRecord}</strong> -{" "}
              <strong>{endRecord}</strong> / <strong>{totalRecords}</strong>{" "}
              Results
            </span>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 text-center">
          <div className="table-list-size-per-page react-bs-table-sizePerPage-dropdown dropdown d-inline-block">
            <select
              className="form-control d-inline-block"
              id="pageDropDown"
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
              value={pageSize}
            >
              {[5, 10, 20, 30, 40, 50, 100].map(pgSize => (
                <option key={pgSize} value={pgSize}>
                  {pgSize}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-lg-4 col-md-12 col-sm-12">
          <ul className="pagination react-bootstrap-table-page-btns-ul">
            <li
              className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
              title="previous page"
              onClick={() => previousPage()}
            >
              <span className="page-link">&lt;</span>
            </li>

            {getPageNumbersToDisplay(
              pageIndex,
              pageCount,
              paginationPageCount
            ).map((pageNumber, pIndex) => {
              return (
                <li
                  key={pIndex}
                  className={`page-item  ${
                    pageNumber === pageIndex ? "active" : ""
                  }`}
                  onClick={() => gotoPage(pageNumber)}
                >
                  <span className="page-link">{pageNumber + 1}</span>
                </li>
              );
            })}

            <li
              className={`page-item ${!canNextPage ? "disabled" : ""}`}
              title="previous page"
              onClick={() => gotoPage(pageCount - 1)}
            >
              <span className="page-link">&gt;</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
