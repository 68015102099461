import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import OtherUserForm from "./OtherUserForm";
import HttpUtil from "../../common/HttpUtil";
import {
  USER_ROLES_API,
  USERS_API,
  INCUBATEES_API
} from "../../common/Constants";
import AlertComponent from "../../common/AlertComponent";
import * as Yup from "yup";
import LoadingComponent from "../../common/spinnerloader/LoadingComponent";

const USER_SCHEMA = Yup.object().shape({
  newUser: Yup.mixed()
    .optional()
    .nullable(),
  firstName: Yup.string()
    .label("First Name")
    .required(),
  lastName: Yup.string()
    .label("Last Name")
    .optional(),

  password: Yup.string().when("newUser", {
    is: true,
    then: Yup.string()
      .label("Password")
      .required(),
    otherwise: Yup.string().optional()
  }),
  confirmPassword: Yup.string().when("newUser", {
    is: true,
    then: Yup.string().when("password", {
      is: value => {
        return value;
      },
      then: Yup.string()
        .label("Confirm Password")
        .test(
          "passwords-match",
          "Confirm password doesn't match with Password",
          function(value) {
            return this.parent.password === value;
          }
        )
        .required(),
      otherwise: Yup.string().optional()
    })
  }),
  email: Yup.string()
    .email("Invalid email")
    .label("Email")
    .required(),
  mobileNumber: Yup.string()
    .label("Mobile Number")
    .required(),
  role: Yup.string()
    .label("Role")
    .required(),
  entity: Yup.string()
    .label("Entity")
    .required(),
  isActive: Yup.boolean()
    .label("Status")
    .optional()
});

const INITIAL_VALUES = {
  userType: "Other",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  mobileNumber: "",
  role: "",
  entity: "",
  isActive: true,
  newUser: null
};

export class OtherUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: INITIAL_VALUES,
      userRoles: [],
      incubatees: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getRequest = values => {
    const request = {
      username: values.email,
      userType: "Other", //enum: ["Incubator", "Other"]
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      mobileNumber: values.mobileNumber,
      roles: [
        {
          role: values.role,
          entity: values.entity.value,
          entityType: "Incubatee"
        }
      ],
      isActive: values.isActive
    };
    return request;
  };

  createUser = (values, setSubmitting) => {
    setSubmitting(true);
    const newUser = this.getRequest(values);
    const url = USERS_API;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      newUser,
      data => {
        setSubmitting(false);
        this.setState({
          editUser: true,
          editUserId: data._id
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  updateUser = (values, setSubmitting) => {
    setSubmitting(true);

    const user = this.getRequest(values);

    const url = `${USERS_API}/${values._id}`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.put(
      url,
      headers,
      user,
      data => {
        setSubmitting(false);
        this.getUserById(data._id);
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `User profile form has been updated now.`,
          inputError: {}
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  handleSubmit = (values, { setSubmitting }) => {
    // console.log("values:", JSON.stringify(values, null, 2));
    if (values.newUser) {
      this.createUser(values, setSubmitting);
    } else {
      this.updateUser(values, setSubmitting);
    }
  };

  getAllUserRolles = () => {
    const url = `${USER_ROLES_API}/?roleType=Other`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ userRoles: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getUserById = id => {
    const url = `${USERS_API}/${id}`;

    let alertType,
      showAlert,
      alertColor,
      alertMessage = null;

    if (this.props.location.state && this.props.location.state.created) {
      alertType = "Default";
      showAlert = true;
      alertColor = "success";
      alertMessage = "New User has been added successfully.";

      this.setState({
        alertType,
        showAlert,
        alertColor,
        alertMessage
      });
    }

    HttpUtil.get(
      url,
      {},
      data => {
        const userRole = data.roles[0];
        let incubatee = userRole.entity || "";

        let entity = "";
        if (incubatee) {
          entity = {
            label: incubatee.name,
            value: incubatee._id
          };
        }

        const user = {
          _id: data._id,
          username: data.username,
          userType: data.userType,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobileNumber: data.mobileNumber,
          role: userRole.role,
          entity: entity || "",
          isActive: data.isActive,
          newUser: false
        };
        this.setState({ user });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllIncubatess = () => {
    const url = `${INCUBATEES_API}/ongoing`;
    HttpUtil.get(
      url,
      {},
      data => {
        const incubatees = data.map(d => {
          const incubatee = {
            label: d.name,
            value: d._id,
            incubateeType: d.incubateeType,
            userType: d.userType
          };
          return incubatee;
        });
        this.setState({ incubatees });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getUserById(id);
    } else {
      const user = this.state.user;
      user.newUser = true;
      this.setState({ user });
    }
    this.getAllUserRolles();
    this.getAllIncubatess();
  };

  render() {
    const { incubatees } = this.state;
    if (incubatees === null) {
      return <LoadingComponent />;
    }

    if (this.state.editUser) {
      const locationState = {
        pathname: `/external-users/edit/${this.state.editUserId}`,
        state: { created: true }
      };
      return <Redirect to={locationState} />;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        <OtherUserForm
          initialValues={this.state.user}
          validationSchema={USER_SCHEMA}
          handleSubmit={this.handleSubmit}
          userRoles={this.state.userRoles}
          incubatees={this.state.incubatees}
        />
      </Fragment>
    );
  }
}

export default OtherUser;
