import React, { Component, Fragment } from "react";
import { downloadDocument } from "../../../common/Util";
import { getFileIconByContentType } from "../../../common/IconUtil";

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

class FloorPlan extends Component {
  render() {
    const {
      handleChangeFloorPlanImage,
      handlePreviewImage,
      handleRemoveImage,
      removeFile,
      floorPlans,
      pendingImageUploads
    } = this.props;

    let renderNoImageAvailable = null;
    if (floorPlans.length === 0 && pendingImageUploads.length === 0) {
      renderNoImageAvailable = (
        <div className="col-md-12 text-center mb-0">
          <i className="fas fa-image fa-10x text-info" />
          <div className="text-muted">No Image Available</div>
        </div>
      );
    }
    return (
      <Fragment>
        <div className="text-center">
          <h6 className="edit-page-title">
            <strong>Floor Plan</strong>
          </h6>
        </div>
        {renderNoImageAvailable}

        <div className="row p-2 justify-content-center">
          {floorPlans.map((fileInfo, idx) => {
            const contentType = fileInfo.contentType || "";
            const isImage = contentType.indexOf("image/") !== -1;
            return (
              <Fragment key={idx}>
                <div className="col-12 col-md-4 mt-3">
                  <div className="card">
                    {isImage ? (
                      <img
                        src={fileInfo.url}
                        className="card-img-top img-fluid"
                        alt={fileInfo.name}
                        style={{
                          maxHeight: 200,
                          minHeight: 200,
                          objectFit: "cover"
                        }}
                        onClick={handlePreviewImage.bind(
                          this,
                          fileInfo.url,
                          fileInfo.name
                        )}
                      />
                    ) : (
                      <i
                        className={`${getFileIconByContentType(
                          contentType,
                          "fa-10x"
                        )}`}
                        style={{
                          minHeight: "200px",
                          textAlign: "center",
                          padding: "30px"
                        }}
                      />
                    )}
                    <div className="card-body p-2">
                      <span className="float-right">
                        <span
                          className="mr-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            downloadDocument(
                              fileInfo.url,
                              msg => window.alert(msg),
                              fileInfo.name
                            )
                          }
                        >
                          <i className="fas fa-download"></i>
                        </span>
                        <span
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={handleRemoveImage.bind(this, fileInfo._id)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </span>
                      </span>

                      <small>
                        <h6 className="card-title mb-0">{fileInfo.name}</h6>
                      </small>
                      <small className="mb-0">
                        <i className="far fa-hdd mr-1"></i> {fileInfo.size}
                      </small>
                      <small className="mb-0 ml-4">
                        <i className="far fa-clock mr-1"></i>
                        {fileInfo.updatedAt}
                      </small>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}

          {pendingImageUploads.map((file, idx) => {
            return (
              <Fragment key={idx}>
                <div className="col-12 col-md-4 mt-3">
                  <div className="card">
                    <img
                      src={URL.createObjectURL(file)}
                      className="card-img-top img-fluid"
                      alt={file.name}
                      style={{
                        maxHeight: 200,
                        minHeight: 200,
                        objectFit: "cover"
                      }}
                      onClick={handlePreviewImage.bind(
                        this,
                        file.url,
                        file.name
                      )}
                    />

                    <div className="card-body p-2">
                      <span className="float-right">
                        <span className="mr-2 text-success">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={removeFile.bind(this, idx)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </span>
                      </span>

                      <small>
                        <h6 className="card-title mb-0">
                          {file.name}
                          <span className="badge badge-success ml-1"> New</span>
                        </h6>
                      </small>

                      <small className="mb-0">
                        <i className="far fa-hdd"></i> {bytesToSize(file.size)}
                      </small>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>

        <div className="row mb-2 mt-5">
          <div className="col-md-2" />
          <div className="col-md-8 text-center">
            <div className="d-flex justify-content-center mt-2">
              <div className="input-group mb-3">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFile01"
                    multiple
                    aria-describedby="inputGroupFileAddon01"
                    onChange={handleChangeFloorPlanImage}
                    accept="image/*"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile01"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
      </Fragment>
    );
  }
}

export default FloorPlan;
