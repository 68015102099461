import React, { Component } from "react";

class DuplicateCheck extends Component {
  render() {
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <fieldset className="scheduler-border ">
              <legend className="scheduler-border">
                Record Found{" "}
                <span className="sidebar-badge badge badge-primary">
                  3<div />
                </span>
              </legend>

              <table className="table table-bordered table-striped table-sm">
                <caption style={{ captionSide: "top" }}>
                  <h5>Company List</h5> Following mathces records found with
                  that{" "}
                  <strong>
                    <mark>Biospot</mark>{" "}
                  </strong>{" "}
                  Incubatee.
                </caption>

                <thead>
                  <tr>
                    <th>Select One</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Action </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "10%", textAlign: "center" }}>
                    <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="defaultChecked2"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="defaultChecked2"
                        />
                      </div>
                    </td>
                    <td>101A2B</td>
                    <td>Biospot Solution</td>
                    <td>Active</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                        />
                      </label>
                    </td>
                    <td>101A2B</td>
                    <td>Biospot Tech</td>
                    <td>Active</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                        />
                      </label>
                    </td>
                    <td>101A2B</td>
                    <td>Biospot Medical Center</td>
                    <td>Active</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table table-bordered table-striped table-sm">
                <caption style={{ captionSide: "top" }}>
                  <h5>Individual List</h5> Following mathces records found with
                  that{" "}
                  <strong>
                    <mark>Biospot</mark>{" "}
                  </strong>{" "}
                  Incubatee.
                </caption>

                <thead>
                  <tr>
                    <th>Select One</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Action </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="defaultChecked2"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="defaultChecked2"
                        />
                      </div>
                    </td>
                    <td>101A2B</td>
                    <td>Biospot Solution</td>
                    <td>Active</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                        />
                      </label>
                    </td>
                    <td>101A2B</td>
                    <td>Biospot Tech</td>
                    <td>Active</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "10%", textAlign: "center" }}>
                      <label className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                        />
                      </label>
                    </td>
                    <td>101A2B</td>
                    <td>Biospot Medical Center</td>
                    <td>Active</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <fieldset className="scheduler-border ">
              <legend className="scheduler-border">No Record Found</legend>
              <span>
                There is no existing data for{" "}
                <strong>
                  <mark>Biospot</mark>{" "}
                </strong>{" "}
                Incubatee.
              </span>

              <div className="row">
                <div className="col-12">
                  <div className="card-body">
                    <legend className="scheduler-border">
                      Select Incubatee Type
                    </legend>
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="option2"
                        name="radios-example"
                      />
                      <span className="form-check-label">Company Type</span>
                    </label>
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="option2"
                        name="radios-example"
                      />
                      <span className="form-check-label">Individual Type</span>
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default DuplicateCheck;
