import React from "react";
import { Formik } from "formik";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { convertDateToNumber } from "../../lease/DateUtil";
import IncubateeDataExportForm from "./IncubateeDataExportForm";
import exportFilteredIncubatees from "./IncubateeDataExportConverter";
import validationSchema from "./validationSchema";

export function getInitialValues() {
  return {
    // Incubation
    incubateeType: "",
    graduationStatus: "",
    incubatedDateFrom: "",
    incubatedDateTo: "",
    graduatedDateFrom: "",
    graduatedDateTo: "",
    incubateeStage: [],
    incubationSector: [],
    subTechSector: [],
    incubationCategory: [],
    residentialStatus: "",
    category: [],
    patentFiled: "",
    gender: "",
    fromAge: "",
    toAge: "",
    psgAlumini: "",
    yearOfPassingFrom: "",
    yearOfPassingTo: "",
    references: "",
    companyStartDateFrom: "",
    companyStartDateTo: "",
    legalStatus: "",
    totalEmployeeCountFrom: "",
    totalEmployeeCountTo: "",
    femaleEmployeeCountFrom: "",
    femaleEmployeeCountTo: "",
    lastAnnualTurnoverFrom: "",
    lastAnnualTurnoverTo: "",
    proGender: "",
    proFromAge: "",
    proToAge: "",
    proPsgAlumini: "",
    proYearOfPassingFrom: "",
    proYearOfPassingTo: "",
    foundersOnly: "",
    financialYear: "",
    metricsYear: "",
    selectOptions: {
      selectAll: false,
      selectIncubatee: false,
      profile: false,
      profileFields: {
        incubateeType: false,
        incubationDate: false,
        graduationDate: false,
        graduationStatus: false,
        incubateeSector: false,
        subTechSector: false,
        incubatedStage: false,
        residentialStatus: false,
        incubationCategory: false,
        relationshipManager: false
      },
      patents: false,
      patentsFields: {
        titleOfInnovation: false,
        applicationNumber: false,
        applicationFilingDate: false,
        patentStatus: false,
        fieldOfInvention: false,
        classification: false,
        country: false
      },
      productsServices: false,
      productsServicesFields: {
        name: false,
        description: false,
        category: false,
        subCategory: false
      },
      basicInfoFields: {
        dob: false,
        gender: false,
        experience: false,
        references: false,
        website: false,
        domainOperation: false,
        legalStatus: false,
        estYear: false,
        parentCompany: false
      },
      educationFields: {
        qualification: false,
        degree: false,
        institution: false,
        alumni: false,
        year: false,
        branch: false
      },
      financialsFields: { annualTurnover: false },
      metricsFields: {
        totalEmployeeCount: false,
        femaleEmployeeCount: false,
        updatedAt: false
      },
      contactsFields: { mobile: false, email: false, address: false },
      idDocsFields: { aadhar: false, pan: false },
      basicInfo: false,
      metrics: false,
      education: false,
      contacts: false,
      idDocs: false,
      financials: false,
      promoters: false,
      promotersFields: {
        idDocs: false,
        basicInfo: false,
        contacts: false,
        education: false,
        basicInfoFields: {
          founders: false,
          designation: false,
          shareholding: false,
          dob: false,
          gender: false,
          experience: false,
          references: false
        },
        contactsFields: { mobile: false, email: false, address: false },
        idDocsFields: { aadhar: false, pan: false },
        educationFields: {
          qualification: false,
          degree: false,
          institution: false,
          alumni: false,
          year: false,
          branch: false
        }
      }
    }
  };
}

function getProductTypes(setProductTypes, setAlert) {
  const url = `/api/v1/incubatees/meta?type=ProductsCategory`;
  HttpUtil.get(
    url,
    {},
    data => setProductTypes(data),
    (data, status) => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: data.message
      });
      window.scrollTo(0, 0);
    },
    error => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: error.toString()
      });
      window.scrollTo(0, 0);
    }
  );
}

function getUpdatedArray(arr) {
  return arr.map(obj => obj.value);
}
// Main Data Fetching Function
function fetchAndExportIncubatees(values, setAlert) {
  const {
    incubateeStage,
    incubationSector,
    subTechSector,
    incubationCategory,
    productCategory,
    incubatedDateFrom,
    incubatedDateTo,
    graduatedDateFrom,
    graduatedDateTo,
    ...restValues
  } = values;

  // Converting array of objects to array of strings
  restValues.incubateeStage = getUpdatedArray(incubateeStage);
  restValues.incubationSector = getUpdatedArray(incubationSector);
  restValues.subTechSector = getUpdatedArray(subTechSector);
  restValues.incubationCategory = getUpdatedArray(incubationCategory);
  // restValues.productCategory = getUpdatedArray(productCategory);
  // Converting Date to Number
  restValues.incubatedDateFrom = convertDateToNumber(incubatedDateFrom);
  restValues.incubatedDateTo = convertDateToNumber(incubatedDateTo);
  restValues.graduatedDateFrom = convertDateToNumber(graduatedDateFrom);
  restValues.graduatedDateTo = convertDateToNumber(graduatedDateTo);

  const url = "/api/v1/incubatees/data-export";
  const headers = { "Content-Type": "application/json" };
  HttpUtil.post(
    url,
    headers,
    restValues,
    data => exportFilteredIncubatees(data, values.selectOptions),
    data => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: data.message
      });
      window.scrollTo(0, 0);
    },
    error => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: error.toString()
      });
      window.scrollTo(0, 0);
    }
  );
}

function IncubateeDataExport() {
  const [alert, setAlert] = React.useState({
    alertType: "",
    showAlert: false,
    alertColor: "",
    alertMessage: ""
  });
  const [productTypes, setProductTypes] = React.useState([]);

  React.useEffect(() => {
    getProductTypes(setProductTypes, setAlert);
  }, []);

  const productTypesOptions = React.useMemo(
    () =>
      productTypes.map(type => {
        return {
          label: type.label,
          value: type.key
        };
      }),

    [productTypes]
  );

  return (
    <>
      <AlertComponent
        show={alert.showAlert}
        type={alert.alertType}
        alertColor={alert.alertColor}
        message={alert.alertMessage}
        close={() =>
          setAlert({
            alertType: "",
            showAlert: false,
            alertColor: "",
            alertMessage: ""
          })
        }
      />
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={values => fetchAndExportIncubatees(values, setAlert)}
      >
        {formikProps => (
          <IncubateeDataExportForm
            {...formikProps}
            productTypesOptions={productTypesOptions}
          />
        )}
      </Formik>
    </>
  );
}

export default IncubateeDataExport;
