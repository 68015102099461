import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import {
  GRANT_PROGRAM_API,
  GRANT_FUNDING_CYCLE_API,
  GRANT_PROGRESS_TEMPLATES_API
} from "../../common/Constants";
import {
  getDisplayDate,
  convertDateToNumber,
  convertNumberToDate
} from "../../lease/DateUtil";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  return getError(touch, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touch, error, datePicker } = props;
  let renderComponent = "";

  if (datePicker) {
    renderComponent = (
      <div className="auto-lookup-empty">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  } else {
    renderComponent = (
      <div className="invalid-feedback">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  }
  return renderComponent;
};

const GrantFundingCycleForm = props => {
  const { values, touched, errors, setFieldValue } = props;
  const disabledField = values.readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};
  return (
    <Form>
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="shadow card flex-fill box-border box-light-blue">
            <div className="card-header border-0 text-center  pb-0">
              <span className="float-left">
                <Link to="/funding/funding-cycles" className="text-light-blue">
                  <i className="fas fa-arrow-left" />
                </Link>
              </span>
              <h6 className="mb-0 d-inline-block text-light-blue">
                <strong>{values.formTitle}</strong>
              </h6>
            </div>

            <div className="card-body pt-2">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <strong className="border-bottom pb-1 mb-2 d-block text-muted">
                    Grant Information
                  </strong>
                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      Grant ID
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "grantId"
                        )}`}
                        name="grantId"
                        {...disabledField}
                      />
                      <ErrorComponent
                        fieldName="grantId"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      Grant Program
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <Field
                        component="select"
                        type="text"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "grantProgram"
                        )}`}
                        name="grantProgram"
                        value={values.grantProgram || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {values.grantList.map((grant, i) => (
                          <option key={i} value={grant._id}>
                            {grant.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorComponent
                        fieldName="grantProgram"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      Grant Progress Template
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <Field
                        component="select"
                        type="text"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "grantProgressTemplate"
                        )}`}
                        name="grantProgressTemplate"
                        value={values.grantProgressTemplate || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {values.grantProgressTemplateList.map((gpt, i) => (
                          <option key={i} value={gpt._id}>
                            {gpt.name || "Untitled Template"}
                          </option>
                        ))}
                      </Field>
                      <ErrorComponent
                        fieldName="grantProgressTemplate"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      Start Date
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <DatePicker
                        value={
                          values.startDate
                            ? getDisplayDate(values.startDate)
                            : ""
                        }
                        selected={values.startDate}
                        onChange={e => {
                          setFieldValue(`startDate`, e);
                        }}
                        utcOffset={0}
                        placeholderText="dd-mm-yyyy"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "startDate"
                        )}`}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <ErrorComponent
                        fieldName="startDate"
                        touch={touched}
                        error={errors}
                        datePicker={true}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      End Date
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <DatePicker
                        value={
                          values.endDate ? getDisplayDate(values.endDate) : ""
                        }
                        selected={values.endDate}
                        onChange={e => {
                          setFieldValue(`endDate`, e);
                        }}
                        utcOffset={0}
                        placeholderText="dd-mm-yyyy"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "endDate"
                        )}`}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <ErrorComponent
                        fieldName="endDate"
                        touch={touched}
                        error={errors}
                        datePicker={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <strong className="border-bottom pb-1 mb-2 d-block text-muted">
                    Additional Information
                  </strong>
                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      Status
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <Field
                        component="select"
                        type="text"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "status"
                        )}`}
                        name="status"
                        value={values.status || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        <option value="OPENED">Opened</option>
                        <option value="CLOSED">Closed</option>
                      </Field>
                      <ErrorComponent
                        fieldName="status"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      Allocated Amount
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <Field
                        type="number"
                        className={`form-control form-control-sm text-right pr-0 ${getErrorCssClassName(
                          touched,
                          errors,
                          "allocatedAmount"
                        )}`}
                        name="allocatedAmount"
                        {...disabledField}
                      />
                      <ErrorComponent
                        fieldName="allocatedAmount"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      Amount Disbursed
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <Field
                        type="number"
                        className={`form-control form-control-sm text-right pr-0 ${getErrorCssClassName(
                          touched,
                          errors,
                          "amountDisburesd"
                        )}`}
                        name="amountDisburesd"
                        disabled
                        {...disabledField}
                      />
                      <ErrorComponent
                        fieldName="amountDisburesd"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                      Grantee Count
                    </label>
                    <div className="col-12 col-lg-7 col-xl-7">
                      <Field
                        type="number"
                        className={`form-control form-control-sm text-right pr-0 ${getErrorCssClassName(
                          touched,
                          errors,
                          "granteeCount"
                        )}`}
                        name="granteeCount"
                        {...disabledField}
                        disabled
                      />
                      <ErrorComponent
                        fieldName="granteeCount"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2 mt-2">
                <div className="col-md-12 text-center">
                  <button className="btn btn-sm btn-primary" type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const FUND_CYCLE_SCHEMA = Yup.object().shape({
  grantId: Yup.string()
    .label("Grant ID")
    .required(),
  grantProgram: Yup.string()
    .label("Funding Programme")
    .required(),
  grantProgressTemplate: Yup.string()
    .label("Grant Progress Template")
    .required(),
  startDate: Yup.date()
    .label("Start Date")
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
  endDate: Yup.date()
    .label("End Date")
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
  allocatedAmount: Yup.number()
    .label("Allocated Amount")
    .required(),
  status: Yup.string()
    .label("Status")
    .required()
});

class GrantFundingCycleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fundingCycle: {
        _id: "",
        grantId: "",
        grantProgram: "",
        grantProgressTemplate: "",
        startDate: null,
        endDate: null,
        allocatedAmount: "",
        amountDisburesd: "",
        status: "",

        granteeCount: "",

        // extras
        readOnly: false,
        formTitle: "",
        grantList: [],
        grantProgressTemplateList: []
      },

      editFundCycle: false,
      editFundCycleId: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getFundingCycle = id => {
    if (this.props.location.state && this.props.location.state.created) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: `Grant Funding Cycle has been created successfully.`
      });
      window.scrollTo(0, 0);
    }

    const url = `${GRANT_FUNDING_CYCLE_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        const fundingCycle = { ...this.state.fundingCycle };

        let startDate = null;
        let endDate = null;

        if (data.startDate) {
          startDate = convertNumberToDate(data.startDate);
        }

        if (data.endDate) {
          endDate = convertNumberToDate(data.endDate);
        }

        fundingCycle._id = data._id || "";
        fundingCycle.grantId = data.grantId || "";

        fundingCycle.grantProgram =
          data.grantProgram._id || data.grantProgram || "";

        if (data.grantProgressTemplate) {
          fundingCycle.grantProgressTemplate =
            data.grantProgressTemplate._id || data.grantProgressTemplate || "";
        }

        fundingCycle.startDate = startDate || null;
        fundingCycle.endDate = endDate || null;
        fundingCycle.allocatedAmount = data.allocatedAmount || "";
        fundingCycle.amountDisburesd = data.amountDisburesd || "";
        fundingCycle.status = data.status || "";

        fundingCycle.granteeCount = data.granteeCount || "";

        fundingCycle.formTitle = `${data.grantProgram.name} `;

        this.setState({ fundingCycle });

        //this.setState((state)=>({fundingCycle }))
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllGrants = () => {
    HttpUtil.get(
      GRANT_PROGRAM_API,
      {},
      data => {
        this.setState(prevState => ({
          fundingCycle: {
            ...prevState.fundingCycle,
            grantList: data
          }
        }));
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllGrantProgressTemplate = () => {
    HttpUtil.get(
      GRANT_PROGRESS_TEMPLATES_API,
      {},
      data => {
        this.setState(prevState => ({
          fundingCycle: {
            ...prevState.fundingCycle,
            grantProgressTemplateList: data
          }
        }));
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getRequest = values => {
    let startDate = new Date(values.startDate);
    startDate = convertDateToNumber(startDate);
    let endDate = new Date(values.endDate);
    endDate = convertDateToNumber(endDate);
    return {
      grantId: values.grantId,
      grantProgram: values.grantProgram,
      grantProgressTemplate: values.grantProgressTemplate,
      startDate,
      endDate,
      allocatedAmount: values.allocatedAmount,
      amountDisburesd: values.amountDisburesd,
      status: values.status,
      granteeCount: values.granteeCount
    };
  };

  createFundingCycle = (values, setSubmitting) => {
    setSubmitting(true);
    const createRequest = this.getRequest(values);

    const url = `${GRANT_FUNDING_CYCLE_API}`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      createRequest,
      data => {
        setSubmitting(false);
        this.setState({
          editFundCycle: true,
          editFundCycleId: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  updateFundingCycle = (values, setSubmitting) => {
    setSubmitting(true);

    const updateRequest = this.getRequest(values);

    const id = this.props.match.params.id;
    const url = `${GRANT_FUNDING_CYCLE_API}/${id}`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.put(
      url,
      headers,
      updateRequest,
      data => {
        setSubmitting(false);
        this.getFundingCycle(data._id);
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Grant Funding Cycle "${
            data.grantId
          }" has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSubmit = (values, { setSubmitting }) => {
    const fundingCycle = this.state.fundingCycle;
    if (fundingCycle._id) {
      this.updateFundingCycle(values, setSubmitting);
    } else {
      this.createFundingCycle(values, setSubmitting);
    }
  };

  componentDidMount() {
    const { match } = this.props;
    if (match && match.params && match.params.id) {
      const id = match.params.id;
      this.getFundingCycle(id);
    } else {
      const fundingCycle = { ...this.state.fundingCycle };
      fundingCycle.formTitle = "New Grant Cycle";
      this.setState({ fundingCycle });
    }
    this.getAllGrants();
    this.getAllGrantProgressTemplate();
  }

  render() {
    const { editFundCycle, editFundCycleId } = this.state;
    if (editFundCycle) {
      const stateObj = {
        pathname: `/funding/funding-cycles/edit/${editFundCycleId}`,
        state: { created: true }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const props = {
      initialValues: this.state.fundingCycle,
      validationSchema: FUND_CYCLE_SCHEMA,
      handleSubmit: this.handleSubmit
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={props.initialValues}
          validationSchema={props.validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            props.handleSubmit(values, { setSubmitting })
          }
          render={({
            values,
            isSubmitting,
            errors,
            touched,
            setFieldValue
          }) => {
            const formProps = {
              values,
              isSubmitting,
              errors,
              touched,
              setFieldValue
            };
            return <GrantFundingCycleForm {...formProps} />;
          }}
        />
      </Fragment>
    );
  }
}

export default GrantFundingCycleDetail;
