import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../../common/HttpUtil";
import { ASSET_RESERVATION_REQUEST_API } from "../../common/Constants";
import TableList from "../../common/TableList";
import AlertComponent from "../../common/AlertComponent";
import { getDescription } from "../../common/Util";
import {
  formatNumericDate,
  getDateAndTimeFormat,
  getDateLimit
} from "../../lease/DateUtil";
import { changeToINR } from "../../common/LookupConstants";
import { exportMappedFields } from "../../../lib/table";

function getIndividualName(fname, lname) {
  let names = [];
  if (fname) {
    names.push(fname);
  }
  if (lname) {
    names.push(lname);
  }
  return names.join(" ");
}

function getRequestorName(cell, row) {
  if (!row) {
    return;
  }

  let requestorName = "--";

  const requestorType = row.requestorType;

  if (requestorType === "INDIVIDUAL") {
    if (row.newUser) {
      const indRequestor = row.indRequestor || {};
      requestorName = getIndividualName(
        indRequestor.firstName || "--",
        indRequestor.lastName
      );
    } else {
      const individual = row.individual || {};
      requestorName = getIndividualName(
        individual.firstName || "--",
        individual.lastName
      );
    }
  }

  if (requestorType === "ORGANIZATION") {
    if (row.newUser) {
      const orgRequestor = row.orgRequestor || {};
      requestorName = orgRequestor.name;
    } else {
      const organization = row.organization || {};
      requestorName = organization.name;
    }
  }

  if (requestorType === "INCUBATEE") {
    const incubatee = row.incubatee || {};
    requestorName = incubatee.name || "";
  }

  return requestorName;
}

function linkFormater(cell, row) {
  const requestorName = getRequestorName(cell, row);
  const currentPathname = "/service-mgmt/closed-reservations";
  const url = `/service-mgmt/asset-reservation-requests/edit/${
    row._id
  }?from=${currentPathname}`;
  return <Link to={url}>{requestorName}</Link>;
}

function capitalize(s) {
  if (typeof s !== "string") {
    return "";
  }
  let word = s.toLocaleLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function requestorType(cell, row) {
  if (!row) {
    return;
  }
  return capitalize(row.requestorType) || "--";
}

function ourIncubatee(cell, row) {
  let ourInc = "";
  if (row.newUser) {
    ourInc = "No";
  } else {
    ourInc = "Yes";
  }
  return ourInc;
}

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  return getDescription("AssetReservationRequest", row[formatExtraData], cell);
}

function requestedAt(cell, row) {
  let createdAt = row.createdAt;
  createdAt = getDateAndTimeFormat(createdAt);
  return createdAt;
}

const ENQUIRY_LIST_COLUMNS = [
  {
    dataField: "name",
    text: "Requestor",
    formatter: linkFormater,
    csvFormatter: getRequestorName,
    filterValue: getRequestorName
  },
  {
    dataField: "incubateeType",
    text: "Requestor Type",
    formatter: requestorType,
    csvFormatter: requestorType,
    filterValue: requestorType
  },
  {
    dataField: "createdAt",
    text: "Requested At",
    formatter: requestedAt,
    csvFormatter: requestedAt,
    filterValue: requestedAt
  },
  {
    dataField: "advance",
    text: "Advance",
    classes: "text-right",
    headerClasses: "text-right",
    formatter: changeToINR,
    csvType: Number
  },
  {
    dataField: "usageCharges",
    text: "Usage Charges",
    classes: "text-right",
    headerClasses: "text-right",
    formatter: changeToINR,
    csvType: Number
  },
  {
    dataField: "status",
    text: "Status",
    formatter: statusFormatter,
    formatExtraData: "stage",
    csvFormatter: statusFormatter,
    filterValue: statusFormatter
  }
];

const EXPORT_ALL_COLUMNS = [
  {
    Header: "Company Name",
    accessor: ".",
    exportValue: v => getRequestorName(null, v)
  },
  {
    Header: "Requestor Type",
    accessor: ".",
    exportValue: v => requestorType(null, v)
  },
  {
    Header: "Requested At",
    accessor: ".",
    exportValue: v => requestedAt(null, v)
  },
  {
    Header: "Advance",
    accessor: "advance",
    exportValue: changeToINR
  },
  {
    Header: "Status",
    accessor: "status",
    exportValue: (v, o) => statusFormatter(v, o, null, "stage")
  },
  {
    Header: "Estimated Charges",
    accessor: "requestedAssets.charge",
    exportValue: changeToINR
  },
  {
    Header: "Usage Charge",
    accessor: "assetUsageTotals.assetCharge",
    exportValue: changeToINR
  },
  {
    Header: "Usage Period",
    accessor: "assetUsageTotals.from",
    exportValue: (v, obj) => getDateLimit(v, obj.assetUsageTotals.to) || ""
  },
  {
    Header: "Used Hours",
    accessor: "assetUsageTotals.usageHours"
  },
  {
    Header: "Additional Charges",
    accessor: "assetUsageTotals.additionalCharges",
    exportValue: changeToINR
  },
  {
    Header: "Total amount",
    accessor: "usageTotal",
    exportValue: changeToINR
  }
  // {
  //   multiExport: true,
  //   headerName: "Asset",
  //   accessor: "requestedAssets",
  //   fields: [
  //     {
  //       fieldName: "Name",
  //       accessor: "assetName"
  //     },
  //   ]
  // }
];

class ClosedReservation extends Component {
  constructor(props) {
    super(props);
    this.state = { enquiryList: null };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    let url = `${ASSET_RESERVATION_REQUEST_API}/closed`;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ enquiryList: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  exportFullReport(fileName, cols) {
    let url = `${ASSET_RESERVATION_REQUEST_API}/closed-report`;

    HttpUtil.get(
      url,
      {},
      data => {
        console.log(data);
        exportMappedFields(fileName, cols, data);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  }

  componentDidMount() {
    this.getAll();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={ENQUIRY_LIST_COLUMNS}
          data={this.state.enquiryList}
          noDataIndication="No Closed Reservations found."
          sizePerPage="25"
          headerName="Closed Reservations"
          showSearchbar={true}
          exportAll={() =>
            this.exportFullReport(
              "Closed Reservations - All",
              EXPORT_ALL_COLUMNS
            )
          }
        />
      </Fragment>
    );
  }
}

export default ClosedReservation;
