import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import Logger from "../../Logger";
import Can from "../../auth/can";
import HttpUtil from "../common/HttpUtil";
import {
  DASHBOARD_LIST_COUNTS,
  CURRENT_INCUBATOR_API
} from "../common/Constants";

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: {},
      timerId: null,
      listCounts: {},
      currentIncubator: {}
    };
  }

  handleShowHide = (idx, e) => {
    //Logger.info("Sidebar.js==>e:", e, " idx:", idx);
    e.preventDefault();
    //const menuItemIndex = `menuItem${idx}`;
    this.setState({
      show: {
        [idx]: !this.state.show[idx]
      }
    });
  };

  populateCounts = counts => {
    const listCounts = {
      "pending-incubatee-app-forms": counts.PENDING_APPFORMS_REQUESTS,
      "pending-incubatee-forms": counts.PENDING_INCUBATEE_ONBOARD_REQUESTS,
      "pending-lease-requests":
        counts.PENDING_LEASE_REQUESTS + counts.PENDING_LEASE_TERMINATE_REQUESTS,
      "expired-leases-count": counts.EXPIRED_LEASES_COUNT,
      "pending-graduation-requests": counts.GRADUATION_REQUESTS,
      "pending-incubatee-leasing": counts.INCUBATEE_PENDING_ON_LEASE_COUNT,
      "upcoming-renewals-count": counts.UPCOMING_RENEWAL_LEASES_COUNT,
      "pending-asset-enquiries": counts.PENDING_ASSET_ENQUIRIES,
      "pending-data-update-requests": counts.PENDING_DATA_UPDATE_REQUESTS
    };

    this.setState({ listCounts });
  };

  fetchCounts = () => {
    HttpUtil.get(
      DASHBOARD_LIST_COUNTS,
      {},
      this.populateCounts,
      data => console.log(`Sidebar Error - ${data.message}`),
      error => console.log(`Sidebar Error - ${error.toString()}`)
    );
  };

  fetchCurrentIncubator = () => {
    HttpUtil.get(
      CURRENT_INCUBATOR_API,
      {},
      data => {
        const currentIncubator = {
          name: data.name || "",
          logoPath: data.logoPath || null
        };
        this.setState({ currentIncubator });
      },
      data => console.log(`Sidebar Error - ${data.message}`),
      error => console.log(`Sidebar Error - ${error.toString()}`)
    );
  };

  componentDidMount() {
    this.fetchCounts();
    this.fetchCurrentIncubator();
    const timerId = setInterval(this.fetchCounts, 300000);
    this.setState({ timerId });
  }

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  render() {
    const { show, listCounts } = this.state;
    const cssClassName = this.props.hide ? "active" : "";
    const { menuLinks } = this.props;
    //Logger.info("Sidebar.js==>menuLinks:", menuLinks);

    const active = this.props.activePath;
    const portalUrl = process.env.REACT_APP_INCUBATOR_PORTAL_URL || "";

    const links =
      menuLinks !== undefined
        ? menuLinks.map((link, idx) => {
            const hasChildren = link.children !== undefined ? true : false;
            return (
              <li
                key={`parentLinkKey${idx}`}
                className={
                  active === link.link ? "sidebar-item active" : "sidebar-item"
                }
              >
                {hasChildren ? (
                  <Fragment>
                    <Link
                      to={""}
                      className="sidebar-link"
                      replace={true}
                      onClick={this.handleShowHide.bind(this, idx)}
                    >
                      <i className={"sidebar-left-icon " + link.icon} />
                      <span className="align-middle">{link.title}</span>
                      <i
                        className={
                          show[idx] ? "fas fa-angle-up " : "fas fa-angle-down "
                        }
                      />
                    </Link>

                    <ul
                      key={idx}
                      id={idx}
                      className={
                        show[idx]
                          ? "sidebar-dropdown list-unstyled collapse show"
                          : "sidebar-dropdown list-unstyled collapse"
                      }
                    >
                      {link.children.map((childLink, idx) => {
                        const activeLink = active === childLink.link;
                        const childLinkItem = (
                          <li
                            key={`childLinkKey${idx}`}
                            className={
                              activeLink
                                ? "sidebar-item active"
                                : "sidebar-item"
                            }
                          >
                            <Link
                              replace={true}
                              className="sidebar-link"
                              style={{ paddingLeft: 50 }}
                              to={childLink.link}
                            >
                              {childLink.title}
                              {listCounts[childLink.key] ? (
                                <span
                                  className={
                                    activeLink
                                      ? "sidebar-item-count-active"
                                      : "sidebar-item-count"
                                  }
                                >
                                  {listCounts[childLink.key]}
                                </span>
                              ) : null}
                            </Link>
                          </li>
                        );
                        if (childLink.permission) {
                          return (
                            <Can
                              key={`canChildLinkKey${idx}`}
                              do={childLink.permission.action}
                              on={childLink.permission.subject}
                            >
                              {childLinkItem}
                            </Can>
                          );
                        } else {
                          return childLinkItem;
                        }
                      })}
                    </ul>
                  </Fragment>
                ) : link.permission ? (
                  <Can
                    key={`canParentLinkKey${idx}`}
                    do={link.permission.action}
                    on={link.permission.subject}
                  >
                    {link.portalUrl ? (
                      <a
                        key={idx}
                        href={`${portalUrl}${link.link}`}
                        className="sidebar-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className={"sidebar-left-icon " + link.icon} />
                        <span className="align-middle">{link.title}</span>
                        {listCounts[link.key] ? (
                          <span
                            className={"sidebar-item-count"}
                            style={{ marginTop: "5px" }}
                          >
                            {listCounts[link.key]}
                          </span>
                        ) : null}
                      </a>
                    ) : (
                      <Link
                        key={idx}
                        to={link.link}
                        className="sidebar-link"
                        replace={true}
                      >
                        <i className={"sidebar-left-icon " + link.icon} />
                        <span className="align-middle">{link.title}</span>
                        {listCounts[link.key] ? (
                          <span
                            className={"sidebar-item-count"}
                            style={{ marginTop: "5px" }}
                          >
                            {listCounts[link.key]}
                          </span>
                        ) : null}
                      </Link>
                    )}
                  </Can>
                ) : link.portalUrl ? (
                  <a
                    key={idx}
                    href={`${portalUrl}${link.link}`}
                    className="sidebar-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={"sidebar-left-icon " + link.icon} />
                    <span className="align-middle">{link.title}</span>
                    {listCounts[link.key] ? (
                      <span
                        className={"sidebar-item-count"}
                        style={{ marginTop: "5px" }}
                      >
                        {listCounts[link.key]}
                      </span>
                    ) : null}
                  </a>
                ) : (
                  <Link
                    key={idx}
                    to={link.link}
                    className="sidebar-link"
                    replace={true}
                  >
                    <i className={"sidebar-left-icon " + link.icon} />
                    <span className="align-middle">{link.title}</span>
                    {listCounts[link.key] ? (
                      <span
                        className={"sidebar-item-count"}
                        style={{ marginTop: "5px" }}
                      >
                        {listCounts[link.key]}
                      </span>
                    ) : null}
                  </Link>
                )}
              </li>
            );
          })
        : null;

    const currentIncubator = this.state.currentIncubator;

    let title = "Incubator App";
    if (currentIncubator.name) {
      title = `${currentIncubator.name} - Incubator App`;
    }

    let logoPath = null;
    if (currentIncubator.logoPath) {
      logoPath = currentIncubator.logoPath;
    }
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
        </Helmet>
        <nav id="sidebar" className={cssClassName}>
          <div className="sidebar-content">
            <Link className="sidebar-brand" to="/" replace={true}>
              {logoPath ? (
                <img
                  src={logoPath}
                  className="img-fluid bg-white"
                  width="60"
                  alt={currentIncubator.name || "Incubator Logo"}
                />
              ) : null}
            </Link>
          </div>
          <ul className="sidebar-nav">{links}</ul>
        </nav>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  hide: state.ToggleSidebarReducer.hide
});
export default connect(
  mapStateToProps,
  null
)(Sidebar);
