import React, { Component } from "react";

import AutoLookup from "../custom/AutoLookup";
import {
  PARTITION_TYPE_LIST_API,
  VACANT_PARTITIONS_SEARCH_API,
  AREA_UNIT_LIST_API
} from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import { getDisplayDate, convertNumberToDate } from "./DateUtil";

const changeToINR = number => {
  let num = number;
  if (isNaN(num)) {
    return num;
  }
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(num);
};

export const PARTITION_TYPE_MAPPER = {
  RENTAL: "Fixed Rental",
  SEATER: "Seater area"
};

class PopUpPartition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // User Search
      campus: "",
      building: "",
      seatCount: 0,
      area: 0,
      areaUnitList: [],
      partitionType: "",
      partitionTypes: [],
      vacantPartitionList: [],
      selectedPartitions: [],
      inputError: false,

      selectedCount: 0
    };
  }

  getCampus = campus => {
    if (campus !== null) {
      this.setState({
        campus: campus.lookup,
        building: null
      });
    } else {
      this.setState({
        campus: null,
        building: null
      });
    }
  };

  getBuilding = building => {
    if (building !== null) {
      this.setState({
        building: building.lookup
      });
    } else {
      this.setState({
        building: null
      });
    }
  };

  // input onchange event
  onChange = e => {
    if (e.target.type === "number" && isNaN(e.target.value)) {
      return;
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = () => {
    this.getPartitionTypeList();
    this.getVacantList();
    this.getAreaUnitList();
  };

  getSearchSchema = () => {
    const { partitionType, area, seatCount, building, campus } = this.state;
    let searchSchema = {
      partitionType: partitionType ? partitionType : "",
      area: area ? area : 0,
      seatCount: seatCount ? seatCount : 0,
      buildingId: building ? building._id : "",
      campusId: campus ? campus._id : ""
    };
    return searchSchema;
  };

  getVacantList = () => {
    let url = VACANT_PARTITIONS_SEARCH_API;
    const authToken = this.props.authToken;
    const searchSchema = this.getSearchSchema();

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken
    };

    HttpUtil.post(
      url,
      headers,
      searchSchema,
      data => {
        const leaseSelectedPartitions = this.props.leaseSelectedPartitions;

        let filteredData = [];
        if (leaseSelectedPartitions.length > 0) {
          const existingPartitions = new Set();
          leaseSelectedPartitions.forEach(lsp =>
            existingPartitions.add(lsp.partition)
          );

          filteredData = data.filter(
            partition => !existingPartitions.has(partition.partitionId)
          );
        } else {
          filteredData = data;
        }

        const partitionsIndx = {};
        const uniquePartionsList = [];
        // //Location	Name	Type	Price	Availablity	Status
        filteredData.forEach(vacancy => {
          const partitionId = vacancy.partitionId;
          let index = partitionsIndx[partitionId];
          if (!index) {
            uniquePartionsList.push({
              partitionId,
              areaUnit: vacancy.areaUnit,
              campusName: vacancy.campusName,
              buildingName: vacancy.buildingName,
              floorName: vacancy.floorName,
              displayName: vacancy.displayName,
              partitionType: vacancy.partitionType,
              seaterPrice: vacancy.seaterPrice,
              seatCount: vacancy.seatCount,
              areaPrice: vacancy.areaPrice,
              area: vacancy.area,
              availability: []
            });
            index = uniquePartionsList.length;
            partitionsIndx[partitionId] = index;
          }
          const isVacant = vacancy.leaseEndDate === undefined;
          uniquePartionsList[index - 1].availability.push({
            seatCount: vacancy.seatCount,
            area: vacancy.area,
            leaseEndDate: vacancy.leaseEndDate,
            isVacant
          });
        });

        this.setState({
          vacantPartitionList: uniquePartionsList,
          selectedCount: 0
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSearch = () => {
    const { partitionType, building, campus } = this.state;
    let searchInputEmpty = false;

    searchInputEmpty = partitionType === "" ? true : false;
    searchInputEmpty = campus === "" ? true : false;
    searchInputEmpty = "";

    if (campus === "" && building === "" && partitionType === "") {
      searchInputEmpty = true;
    }

    if (searchInputEmpty) {
      return;
    }
    this.getVacantList();
  };

  getPartitionTypeList = () => {
    const url = PARTITION_TYPE_LIST_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.setState({ partitionTypes: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAreaUnitList = () => {
    const url = AREA_UNIT_LIST_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({ areaUnitList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  handleSelectAllPartitions = e => {
    const vacantPartitionList = this.state.vacantPartitionList;
    const checkboxSelected = e.target.checked;
    vacantPartitionList.forEach(
      partition => (partition.checked = checkboxSelected)
    );
    let selectedPartitions = [];
    if (checkboxSelected) {
      selectedPartitions = vacantPartitionList;
    } else {
      selectedPartitions = [];
    }

    const selectedCount = checkboxSelected ? vacantPartitionList.length : 0;
    this.setState({ selectedPartitions, selectedCount });
  };

  handleSelectPartitionById = (index, e) => {
    const checkboxSelected = e.target.checked;
    const vacantPartitionList = this.state.vacantPartitionList;
    let selectedPartitions = this.state.selectedPartitions;
    vacantPartitionList[index].checked = checkboxSelected;
    let partition = vacantPartitionList[index];

    if (checkboxSelected) {
      selectedPartitions.push(partition);
    } else {
      selectedPartitions = selectedPartitions.filter(
        part => part.partitionId !== partition.partitionId
      );
    }

    let selectedCount = this.state.selectedCount;
    selectedCount += checkboxSelected ? 1 : -1;
    this.setState({ vacantPartitionList, selectedPartitions, selectedCount });
  };

  addSelectedPartitions = () => {
    const selectedPartitions = this.state.selectedPartitions;
    if (selectedPartitions.length === 0) {
      return;
    }
    const partitions = selectedPartitions.filter(
      partition => partition.checked === true
    );

    const partitionType = this.state.partitionType;
    const seatCount = this.state.seatCount;

    let searchedSeatCount = 0;
    if (partitionType === "SEATER" && seatCount > 0) {
      searchedSeatCount = seatCount;
    }

    this.props.selectedPartitions(partitions, searchedSeatCount);
  };

  render() {
    const {
      campus,
      building,
      seatCount,
      area,
      areaUnit,
      areaUnitList,
      partitionType,
      partitionTypes,
      inputError,
      vacantPartitionList,
      selectedCount
    } = this.state;
    const { authToken } = this.props;

    // Render nothing if the "show" prop is false
    if (!this.props.show) {
      return null;
    }

    // The modal "window"
    const modalStyle = {
      borderRadius: 5,
      margin: "0 auto",
      width: "65%",
      height: "60%",
      minHeight: "400px"
    };

    const campusLookupUrl = "api/v1/campuses/search/?name=";
    let buildingLookupUrl = "";
    if (campus !== null && campus !== "") {
      buildingLookupUrl =
        "api/v1/buildings/search/?campus=" + campus._id + "&buildingName=";
    }

    let partitionList = [];
    if (vacantPartitionList.length > 0) {
      partitionList = vacantPartitionList.map((partition, index) => {
        const checked =
          partition.checked !== undefined ? partition.checked : false;
        const availabilityList = partition.availability.map((avl, i) => {
          let seatCount = `${avl.seatCount}  Seats`;
          let area = `${avl.area} Sqft`;
          const availableSpace =
            partition.partitionType === "SEATER" ? seatCount : area;
          let expiry = "Vacant";
          if (avl.isVacant) {
            expiry = (
              <span key={i} className="badge badge-info badge-pill">
                Vacant
              </span>
            );
          } else {
            expiry = (
              <span key={i} className="badge badge-warning badge-pill">
                {getDisplayDate(convertNumberToDate(avl.leaseEndDate))}
              </span>
            );
          }
          return (
            <li
              key={i}
              className="list-group-item d-flex justify-content-between align-items-center p-2"
            >
              {availableSpace}
              {expiry}
            </li>
          );
        });
        return (
          <tr key={index}>
            <td style={{ width: "10%", textAlign: "center" }}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`${index}#${partition.partitionId}`}
                  name={partition.partitionName}
                  checked={checked}
                  onChange={this.handleSelectPartitionById.bind(this, index)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${index}#${partition.partitionId}`}
                />
              </div>
            </td>
            <td>
              {partition.campusName}
              <br />
              <small className="text-muted">
                <strong> {partition.buildingName}</strong>{" "}
              </small>
            </td>
            <td>
              {partition.displayName}
              <br />
              <small className="text-muted">
                <strong>Floor: {partition.floorName}</strong>{" "}
              </small>
            </td>
            <td>{PARTITION_TYPE_MAPPER[partition.partitionType]}</td>

            {/* <td className="text-right">{partition.area}</td> */}
            <td className="text-right">
              {partition.partitionType === "SEATER"
                ? changeToINR(partition.seaterPrice)
                : changeToINR(partition.areaPrice)}
            </td>
            <td className="text-right">
              <ul className="list-group">{availabilityList}</ul>
            </td>
          </tr>
        );
      });
    }

    let defaultAreaUnit = areaUnit;

    if (areaUnitList.length > 0) {
      defaultAreaUnit = areaUnitList[0].unitCode;
    }

    return (
      <div>
        <div
          className={this.props.show ? "modal fade show" : "modal fade"}
          id="sizedModalMd"
          tabIndex="-1"
          role="dialog"
          style={
            this.props.show
              ? {
                  display: "block",
                  paddingRight: 10,
                  backgroundColor: "rgba(0, 0, 0, 0.5)"
                }
              : { display: "none" }
          }
        >
          <div
            className="modal-dialog modal-lg"
            role="document"
            style={modalStyle}
          >
            <div className="modal-content" style={{ maxHeight: "80vh" }}>
              <div className="modal-header">
                <h5 className="modal-title">Partition Search</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.close}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div
                className="modal-body m-3"
                style={{ maxHeight: "80vh", overflowY: "auto" }}
              >
                <div className="card-body" style={{ padding: 0 }}>
                  <div className="row mt-2">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                      <fieldset className="scheduler-border ">
                        <legend className="scheduler-border">
                          Search Facility
                        </legend>
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <div className="form-group row">
                              <label className="col-form-label col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-4 text-sm-left">
                                Campus
                              </label>
                              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-8">
                                <AutoLookup
                                  className={inputError["campus"]}
                                  name="name"
                                  value={campus}
                                  authToken={authToken}
                                  getObject={this.getCampus}
                                  url={campusLookupUrl}
                                />

                                {inputError["campus"] ? (
                                  <div className="auto-lookup-emptyk">
                                    Please select campus name *.
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <div className="form-group row">
                              <label className="col-form-label col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-4 text-sm-left">
                                Building
                              </label>
                              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-8">
                                <AutoLookup
                                  className={inputError["building"]}
                                  name="buildingName"
                                  authToken={authToken}
                                  value={building}
                                  getObject={this.getBuilding}
                                  url={buildingLookupUrl}
                                />

                                {inputError["building"] ? (
                                  <div className="auto-lookup-empty">
                                    Please select building name *.
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            <div className="form-group row">
                              <label className="col-form-label col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-4 text-sm-left">
                                Partition type
                              </label>
                              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-8">
                                <select
                                  className={
                                    inputError["partitionType"]
                                      ? "custom-select custom-select-sm is-invalid"
                                      : "custom-select custom-select-sm"
                                  }
                                  name="partitionType"
                                  value={partitionType}
                                  onChange={this.onChange}
                                >
                                  <option value="">Select</option>
                                  {partitionTypes.map((type, idx) => (
                                    <option
                                      key={idx}
                                      value={type.partitionTypeCode}
                                    >
                                      {type.partitionTypeDesc}
                                    </option>
                                  ))}
                                </select>
                                {inputError["partitionType"] ? (
                                  <div className="invalid-feedback">
                                    Please select partition type *.
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                            {partitionType !== "" &&
                            partitionType === "SEATER" ? (
                              <div className="form-group row">
                                <label className="col-form-label col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-4 text-sm-left">
                                  Seat count
                                </label>
                                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-8">
                                  <input
                                    type="number"
                                    className={
                                      inputError["seatCount"]
                                        ? "form-control form-control-sm is-invalid"
                                        : "form-control form-control-sm"
                                    }
                                    placeholder="0"
                                    name="seatCount"
                                    value={seatCount}
                                    onChange={this.onChange}
                                  />
                                  {inputError["seatCount"] ? (
                                    <div className="invalid-feedback">
                                      Please enter seat count *.
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}

                            {partitionType !== "" &&
                            partitionType === "RENTAL" ? (
                              <div className="form-group row">
                                <label className="col-form-label col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-4 text-sm-left">
                                  Area
                                </label>
                                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-8">
                                  <div className="input-group mb-3">
                                    <input
                                      type="number"
                                      className={
                                        inputError["area"]
                                          ? "form-control form-control-sm is-invalid"
                                          : "form-control form-control-sm"
                                      }
                                      placeholder="0"
                                      name="area"
                                      value={area}
                                      onChange={this.onChange}
                                    />

                                    <select
                                      className={
                                        inputError["areaUnit"]
                                          ? "custom-select custom-select-sm is-invalid"
                                          : "custom-select custom-select-sm"
                                      }
                                      name="areaUnit"
                                      value={defaultAreaUnit}
                                      onChange={this.onChange}
                                    >
                                      <option value="">Select</option>
                                      {areaUnitList.map((unit, idx) => (
                                        <option key={idx} value={unit.unitCode}>
                                          {unit.unitDesc}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {inputError["area"] ? (
                                    <div className="invalid-feedback">
                                      Please enter area *.
                                    </div>
                                  ) : (
                                    <div className="invalid-feedback">
                                      Please select unit *.
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-12 text-right">
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={this.handleSearch}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="table-responsive-sm table-responsive-md">
                    <table className="table table-sm table-striped table-bordered">
                      <caption style={{ captionSide: "top" }}>
                        <h5>
                          Partitions{" "}
                          <span className="badge badge-primary badge-pill">
                            {partitionList.length}
                          </span>
                        </h5>
                      </caption>
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            scope="col"
                            style={{ width: "10%" }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="defaultChecked2"
                                checked={
                                  selectedCount ===
                                    vacantPartitionList.length &&
                                  vacantPartitionList.length !== 0
                                }
                                onChange={this.handleSelectAllPartitions.bind(
                                  this
                                )}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="defaultChecked2"
                              />
                            </div>
                          </th>
                          <th style={{ width: "14.28%" }} scope="col">
                            Location
                          </th>
                          <th style={{ width: "14.28%" }} scope="col">
                            Name
                          </th>
                          <th style={{ width: "14.28%" }} scope="col">
                            Type
                          </th>
                          <th
                            className="text-center"
                            style={{ width: "10%" }}
                            scope="col"
                          >
                            Price
                          </th>
                          <th style={{ width: "14.28%" }} scope="col">
                            Availablity
                          </th>
                        </tr>
                      </thead>
                      <tbody>{partitionList}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="form-group row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-outline-default"
                      data-dismiss="modal"
                      onClick={this.props.close}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.addSelectedPartitions}
                    >
                      Add Selected
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PopUpPartition;
