import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoLookup from "../../custom/AutoLookup";
import Select from "react-select";
import { getDisplayDate } from "../../lease/DateUtil";
import {
  PAYMENT_TRANSFER_TYPES,
  CARD_TYPES,
  CARD_ISSUERS,
  PAYEE_TYPES,
  PAYMENT_MODES,
  renderInputData,
  PAYMENT_TYPES,
  PAYEE_TYPES_OBJECT
} from "../../common/LookupConstants";

class PaymentEditForm extends Component {
  renderChequeInput = props => {
    const { bankName, chequeNumber, chequeDate } = props.payment;
    const { inputError, handleInputChange, handleDateChange } = props;
    return (
      <Fragment>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Bank Name
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="text"
              name="bankName"
              value={renderInputData(bankName)}
              onChange={handleInputChange}
              className={
                inputError["bankName"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm"
              }
            />
            <div className="invalid-feedback">Please enter bank name *</div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Cheque Number
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="text"
              name="chequeNumber"
              value={renderInputData(chequeNumber)}
              onChange={handleInputChange}
              className={
                inputError["chequeNumber"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm"
              }
            />
            <div className="invalid-feedback">Please enter cheque number *</div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Cheque date
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <DatePicker
              value={chequeDate ? getDisplayDate(chequeDate) : ""}
              selected={chequeDate ? chequeDate : null}
              onChange={handleDateChange.bind(this, "chequeDate")}
              utcOffset={0}
              placeholderText="dd-mm-yyyy"
              className={
                inputError["chequeDate"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm"
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />

            {inputError["chequeDate"] ? (
              <div className="auto-lookup-empty">
                Please select cheque date *.
              </div>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  };

  renderOnlinePaymentInput = props => {
    const { bankName, paymentTransferType } = props.payment;
    const { inputError, handleInputChange } = props;

    return (
      <Fragment>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Bank Name
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="text"
              name="bankName"
              value={renderInputData(bankName)}
              onChange={handleInputChange}
              className={
                inputError["bankName"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm"
              }
            />
            <div className="invalid-feedback">Please enter bank name *</div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Transfer Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <select
              name="paymentTransferType"
              className={
                inputError["paymentTransferType"]
                  ? "custom-select custom-select-sm is-invalid"
                  : "custom-select custom-select-sm"
              }
              value={renderInputData(paymentTransferType)}
              onChange={handleInputChange}
            >
              <option value="">Select...</option>
              {PAYMENT_TRANSFER_TYPES.map((pt, i) => (
                <option key={i} value={pt.key}>
                  {pt.value}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              Please select payment transfer type *
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  renderCardPaymentInput = props => {
    const { cardType, cardIssuer } = props.payment;
    const { inputError, handleInputChange } = props;
    return (
      <Fragment>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Card Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <select
              name="cardType"
              value={renderInputData(cardType)}
              onChange={handleInputChange}
              className={
                inputError["cardType"]
                  ? "custom-select custom-select-sm is-invalid"
                  : "custom-select custom-select-sm"
              }
            >
              <option value="">Select...</option>
              {CARD_TYPES.map((ct, i) => (
                <option key={i} value={ct.key}>
                  {ct.value}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Please select card type *</div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Card Issuer
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <select
              name="cardIssuer"
              value={renderInputData(cardIssuer)}
              onChange={handleInputChange}
              className={
                inputError["cardIssuer"]
                  ? "custom-select custom-select-sm is-invalid"
                  : "custom-select custom-select-sm"
              }
            >
              <option value="">Select...</option>
              {CARD_ISSUERS.map((ci, i) => (
                <option key={i} value={ci.key}>
                  {ci.value}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Please select card name *</div>
          </div>
        </div>
      </Fragment>
    );
  };

  renderReadOnlyEntityInfo = props => {
    const { payeeType } = props.payment;
    const { displayName } = props;

    return (
      <Fragment>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
            Entity Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="text"
              className="form-control form-control-sm "
              value={renderInputData(PAYEE_TYPES_OBJECT[payeeType])}
              readOnly
            />
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
            Entity
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="text"
              className="form-control form-control-sm"
              value={renderInputData(displayName)}
              readOnly
            />
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
            Payment Towards
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            {props.payment.paymentTowards.map((pt, i) => {
              return (
                <input
                  key={`${i}`}
                  type="text"
                  className="form-control form-control-sm mb-1"
                  value={pt.label}
                  readOnly
                />
              );
            })}
          </div>
        </div>
      </Fragment>
    );
  };

  renderEntityInfo = props => {
    const { payeeType, paymentTowards, payee, paymentType } = props.payment;
    const {
      payeeName,
      payeeLookupUrl,
      paymentTowardsList,
      handlePayeeType,
      getPayee,
      handlePaymentTowards,
      inputError,
      handleInputChange
    } = props;

    let styles = "";
    if (inputError.paymentTowards) {
      styles = {
        control: base => ({
          ...base,
          borderColor: "#f44455"
        })
      };
    } else {
      styles = {
        control: base => ({
          ...base
        }),
        indicatorsContainer: provided => ({
          ...provided
        })
      };
    }

    return (
      <Fragment>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
            Payment Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <select
              name="paymentType"
              value={renderInputData(paymentType)}
              onChange={handleInputChange}
              className={
                inputError["paymentType"]
                  ? "custom-select custom-select-sm is-invalid"
                  : "custom-select custom-select-sm"
              }
            >
              <option value="">Select...</option>
              {PAYMENT_TYPES.map((pt, i) => (
                <option key={i} value={pt.key}>
                  {pt.value}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Please select payment type *</div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Entity Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <select
              name="payeeType"
              value={renderInputData(payeeType)}
              onChange={handlePayeeType}
              className={
                inputError["payeeType"]
                  ? "custom-select custom-select-sm is-invalid"
                  : "custom-select custom-select-sm"
              }
            >
              <option value="">Select...</option>
              {PAYEE_TYPES.map((pt, i) => (
                <option key={i} value={pt.key}>
                  {pt.value}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Please select payee type *</div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
            Select Entity
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <AutoLookup
              className={inputError["payee"]}
              name={payeeName}
              value={payee ? payee : null}
              getObject={getPayee}
              url={payeeLookupUrl}
            />
            {inputError["payee"] ? (
              <div className="auto-lookup-empty">
                Please select payee name *.
              </div>
            ) : null}
          </div>
        </div>

        <div className="form-group row mb-0">
          <label
            className={`col-form-label col-12 col-lg-2 col-xl-2 edit-page-label`}
          >
            Payment Towards
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <Select
              defaultValue={paymentTowards}
              isMulti
              isDisabled={paymentType === "ADVANCE"}
              name="paymentTowards"
              options={paymentTowardsList}
              className="basic-multi-select"
              styles={styles}
              classNamePrefix="select"
              onChange={handlePaymentTowards}
            />
            {inputError["paymentTowards"] ? (
              <div className="auto-lookup-empty">
                Please select payment towards *
              </div>
            ) : null}
          </div>
        </div>

        {/* <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2">
            Towards Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <select
              name="paymentTowardsType"
              value={renderInputData(paymentTowardsType)}
               onChange={handleInputChange}
              className={
                inputError["paymentTowardsType"]
                  ? "custom-select custom-select-sm is-invalid"
                  : "custom-select custom-select-sm"
              }
            >
              <option value="">Select...</option>
              {TOWARDS_TYPES.map((pt, i) => (
                <option key={i} value={pt.key}>
                  {pt.value}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              Please select payment towards type *
            </div>
          </div>
        </div> */}
      </Fragment>
    );
  };
  render() {
    const {
      paymentMode,
      paymentDate,
      amount,
      reference,
      notes,
      collectedBy
    } = this.props.payment;

    const {
      inputError,
      previousLocation,
      // events
      handleDateChange,
      handleInputChange,
      handleAmountChange,
      handlePaymentModeChange,

      handleNextForm,
      newPayment
    } = this.props;

    const PAYMENT_MODE_MAPPER = {
      CHEQUE: this.renderChequeInput(this.props),
      ONLINE_PAYMENT: this.renderOnlinePaymentInput(this.props),
      CARD_PAYMENT: this.renderCardPaymentInput(this.props)
    };

    const renderPaymentMode = PAYMENT_MODE_MAPPER[paymentMode];

    let renderBasicInfo = null;
    if (newPayment) {
      renderBasicInfo = this.renderEntityInfo(this.props);
    } else {
      renderBasicInfo = this.renderReadOnlyEntityInfo(this.props);
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title mb-0">
                <Link
                  to={previousLocation}
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
                &nbsp;Payment Information
              </span>
            </div>
            <div className="card-body">
              {renderBasicInfo}
              <hr />

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Amount
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="number"
                    name="amount"
                    value={renderInputData(amount)}
                    onChange={handleAmountChange}
                    className={
                      inputError["amount"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                  />
                  <div className="invalid-feedback">Please enter amount *</div>
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Payment Mode
                </label>
                <div className="col-12 col-lg-10 col-xl-10">
                  {PAYMENT_MODES.map((pm, i) => {
                    return (
                      <div
                        key={i}
                        className="custom-control custom-radio custom-control-inline"
                      >
                        <input
                          type="radio"
                          name={pm.key}
                          id={pm.key}
                          className="custom-control-input"
                          checked={paymentMode === pm.key ? true : false}
                          onChange={handlePaymentModeChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={pm.key}
                        >
                          {pm.value}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>

              {renderPaymentMode}

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Payment date
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <DatePicker
                    value={paymentDate ? getDisplayDate(paymentDate) : ""}
                    selected={paymentDate ? paymentDate : null}
                    onChange={handleDateChange.bind(this, "paymentDate")}
                    utcOffset={0}
                    placeholderText="dd-mm-yyyy"
                    className={
                      inputError["paymentDate"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />

                  {inputError["paymentDate"] ? (
                    <div className="auto-lookup-empty">
                      Please select payment date *.
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Collected By
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    name="collectedBy"
                    className={
                      inputError["collectedBy"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    value={renderInputData(collectedBy)}
                    onChange={handleInputChange}
                  />
                  <div className="invalid-feedback">
                    Please enter collected by *
                  </div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Reference
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    name="reference"
                    value={renderInputData(reference)}
                    onChange={handleInputChange}
                    className={
                      inputError["reference"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                  />
                  <div className="invalid-feedback">
                    Please enter any reference *
                  </div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Notes
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <textarea
                    name="notes"
                    className={
                      inputError["notes"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    value={renderInputData(notes)}
                    onChange={handleInputChange}
                  />
                  <div className="invalid-feedback">Please enter notes *</div>
                </div>
              </div>
              <div className="form-group row mt-3 mb-0">
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleNextForm}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentEditForm;
