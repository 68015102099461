import React, { Component } from "react";
import EventForm from "./EventForm";
import HttpUtil from "../common/HttpUtil";
import { EVENTS_API } from "../common/Constants";
import AlertComponent from "../common/AlertComponent";
import { Redirect } from "react-router";

const initialValues = {
  type: "",
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  website: "",
  websiteValidate: "",
  publish: "",
  location: {
    name: "",
    address: ""
  },
  organizer: {
    name: "",
    address: ""
  },
  flyer: null,
  brochure: null
};

class NewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {}
    };
  }

  getRequest = values => {
    const request = {};
    this.addField(request, "type", values.type);
    this.addField(request, "name", values.name);
    this.addField(request, "description", values.description);
    this.addField(request, "startDate", values.startDate);
    this.addField(request, "endDate", values.endDate);
    this.addField(request, "website", values.website);
    request.publish = values.publish;

    let lctn = values.location || {};

    let location = {};
    if (lctn && lctn.name) {
      location.name = lctn.name;
    }

    if (lctn.address) {
      location.address = lctn.address;
    }

    if (Object.keys(location).length > 0) {
      request.location = location;
    }

    let orgnsr = values.organizer || {};

    let organizer = {};
    if (orgnsr && orgnsr.name) {
      organizer.name = orgnsr.name;
    }

    if (orgnsr && orgnsr.address) {
      organizer.address = orgnsr.address;
    }
    if (Object.keys(organizer).length > 0) {
      request.organizer = organizer;
    }
    const formdata = new FormData();
    formdata.append("message", JSON.stringify(request));
    if (values.flyer) {
      formdata.append("flyer", values.flyer);
    }
    if (values.brochure) {
      formdata.append("brochure", values.brochure);
    }
    return formdata;
  };

  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const requestData = this.getRequest(values);
    const headers = {};

    HttpUtil.post(
      EVENTS_API,
      headers,
      requestData,
      data => this.setState({ event: data, redirectToDetailsPage: true }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  addField = (request, name, value) => {
    if (value) {
      request[name] = value;
    }
  };

  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };
  okConfirmUpdate = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      userConfirmation: true
    });
  };
  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    if (this.state.redirectToDetailsPage) {
      return <Redirect to={`/events/${this.state.event._id}`} />;
    }
    return (
      <div>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <EventForm onSubmit={this.onSubmit} initialValues={initialValues} />
      </div>
    );
  }
}

export default NewEvent;
