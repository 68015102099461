import { createStore, applyMiddleware, compose } from "redux";
import throttle from "lodash/throttle";
import rootReducer from "./rootReducer/RootReducer";
import thunk from "redux-thunk";

const middleware = [thunk];

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    //localStorage.clear();
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (error) {
    // handle error here...
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureReduxStore = () => {
  const persistedState = loadState();
  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(...middleware))
  );
  //console.log("ConfigureReduxStore.js==>store.getStet():", store.getState());

  store.subscribe(
    throttle(() => {
      saveState({
        appState: store.getState().appState
      });
    }, 1000)
  );

  return store;
};

export default configureReduxStore;
