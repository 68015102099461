import React from "react";
const DEFAULT_MESSAGE = `An error occurred while processing your request. 
Please refresh the page or contact support team if the issue persists.`;

function ErrorPage(props) {
  return (
    <div className="row">
      <div className="col-md-7 offset-md-3">
        <div className="card">
          <div className="card-header text-center">
            <h5 className="card-title">Error</h5>
          </div>
          <div className="card-body">
            <p
              className="card-text alert alert-danger text-center"
              role="alert"
            >
              {props.message || DEFAULT_MESSAGE}.
            </p>
          </div>
          <div className="card-footer bg-white text-center">
            <a href="/" className="btn btn-primary">
              Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
