import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Building from "./Building";
import DefaultAlert from "../DefaultAlert";
import {
  BUILDING_API,
  STATUS_LIST_API,
  AREA_UNIT_LIST_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
class AddBuilding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //building
      buildingName: "",
      totalFloors: "",
      status: "ACTIVE",
      areaUnit: null,
      areaPrice: 0,
      seaterPrice: 0,

      //redirect to edit building with id
      editBuilding: false,
      editBuildingId: "",

      //for input error alert
      inputError: false,

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: "",

      // auto lookup
      campus: "",

      // for selector
      statusList: [],
      areaUnitList: []
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validation = () => {
    let inputError = {};
    let valid = true;
    const { buildingName, campus, totalFloors } = this.state;
    if (buildingName === "") {
      valid = false;
      inputError["buildingName"] = true;
    }

    if (campus === "") {
      valid = false;
      inputError["campus"] = true;
    }

    if (totalFloors === "") {
      valid = false;
      inputError["totalFloors"] = true;
    }

    if (!valid) {
      return this.setState({
        inputError: { ...inputError }
      });
    }
    return valid;
  };

  handleApiSuccess = (data, flag) => {
    if (flag === "ADD") {
      const editBuildingId = data._id;
      this.setState({
        editBuilding: true,
        editBuildingId: editBuildingId
      });
    }

    if (flag === "STATUS_LIST") {
      this.setState({ statusList: data });
    }

    if (flag === "AREA_UNIT_LIST") {
      this.setState({ areaUnitList: data });
    }
  };

  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  addBuilding = event => {
    event.preventDefault();
    const {
      buildingName,
      campus,
      totalFloors,
      status,
      areaUnit,
      areaPrice,
      seaterPrice
    } = this.state;
    // validation and show error alert
    if (!this.validation()) {
      return;
    }

    const building = {
      buildingName: buildingName,
      campus: campus,
      totalFloors: totalFloors,
      status: status,
      areaUnit: areaUnit,
      areaPrice: areaPrice,
      seaterPrice: seaterPrice
    };

    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = BUILDING_API;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    // Fire request
    HttpUtil.post(
      url,
      headers,
      building,
      data => this.handleApiSuccess(data, "ADD"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  getCampus = campus => {
    if (campus !== null) {
      return this.setState({
        campus: campus.value
      });
    } else {
      this.setState({ campus: null });
    }
  };

  componentDidMount = () => {
    this.getStatusList();
    this.getAreaUnitList();
  };

  getStatusList = () => {
    const url = STATUS_LIST_API + "BUILDING";
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data, "STATUS_LIST"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAreaUnitList = () => {
    const url = AREA_UNIT_LIST_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data, "AREA_UNIT_LIST"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };
  render() {
    const {
      //building
      buildingName,
      totalFloors,
      status,
      areaUnit,
      areaPrice,
      seaterPrice,

      // redirect to edit page
      editBuilding,
      editBuildingId,

      //Default alert
      showAlert,
      alertMode,
      alertMessage,

      inputError,

      // auto-lookup
      campus,

      // for selectors
      statusList,
      areaUnitList
    } = this.state;

    if (editBuilding) {
      const locationState = {
        pathname: `/admin/buildings/edit/${editBuildingId}`,
        state: { created: true }
      };
      return <Redirect to={locationState} />;
    }

    return (
      <div className="row">
        <div className="col-12 col-xl-12">
          {/* show alert message  */}
          <DefaultAlert
            show={showAlert}
            alertMode={alertMode}
            message={alertMessage}
            closeAlert={this.closeDefaultAlert}
          />

          <Building
            //building
            buildingName={buildingName}
            totalFloors={totalFloors}
            status={status}
            areaUnit={areaUnit}
            areaPrice={areaPrice}
            seaterPrice={seaterPrice}
            //events
            newBuilding={true}
            onChange={this.onChange}
            submit={this.addBuilding}
            backToBuildingList={this.backToBuildingList}
            inputError={inputError}
            //authToken to AutoLookup
            authToken={this.props.authToken}
            campus={campus}
            getCampus={this.getCampus}
            // for selectors
            statusList={statusList}
            areaUnitList={areaUnitList}
          />
        </div>
      </div>
    );
  }
}

export default AddBuilding;
