import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import DatePicker from "react-datepicker";
import Emails from "../widgets/EmailsFormik";
import Phones from "../widgets/PhonesFormik";
import Addresses from "../widgets/AddressFormik";
import IdDocs from "../widgets/IdDocsFormik";
import { getDisplayDate } from "../lease/DateUtil";
import { LEGAL_STATUS_TYPES } from "../common/LookupConstants";
import { getDescription } from "../common/Util";

export const TextInput = props => {
  const {
    label,
    required,
    readOnly,
    fieldName,
    touch,
    error,
    helperText
  } = props;

  const labelCssClass = `col-form-label col-12 col-lg-2 col-xl-2 ${
    required ? "required" : ""
  }`;

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <Fragment>
      <label className={labelCssClass}>{label}</label>
      <div className="col-12 col-lg-4 col-xl-4">
        <Field
          type="text"
          className={`form-control form-control-sm ${
            touch && error ? "is-invalid" : ""
          }`}
          name={fieldName}
          {...disabledField}
        />
        <small>{helperText || null}</small>
        <div className="invalid-feedback">{error}</div>
      </div>
    </Fragment>
  );
};

const Individual = props => {
  const { values, setFieldValue, errors, touched, readOnly } = props;
  const individual = values.individual || {};
  let dob = individual && individual.dob ? individual.dob : "";

  const errorsIndv = errors.individual || {};
  const touchedIndv = touched.individual || {};

  return (
    <Fragment>
      <div className="form-group row mb-0">
        <TextInput
          label="First Name"
          fieldName="individual.firstName"
          error={errorsIndv["firstName"]}
          touch={touchedIndv["firstName"]}
          {...props}
        />
        <TextInput
          label="Last Name"
          fieldName="individual.lastName"
          error={errorsIndv["lastName"]}
          touch={touchedIndv["lastName"]}
          {...props}
        />
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Gender
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            component="select"
            name="individual.gender"
            type="text"
            className={`custom-select custom-select-sm ${
              errorsIndv["gender"] ? "is-invalid" : ""
            }`}
            readOnly={readOnly}
            disabled={readOnly}
          >
            <option value="">Select...</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="T">Transgender</option>
          </Field>

          <div className="invalid-feedback">
            {errorsIndv["gender"] ? errorsIndv["gender"] : ""}
          </div>
        </div>
        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Date of Birth
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <DatePicker
            value={`${dob ? getDisplayDate(dob) : ""}`}
            selected={dob ? dob : null}
            onChange={e => {
              setFieldValue("individual.dob", e);
            }}
            utcOffset={0}
            maxDate={new Date()}
            placeholderText="dd-mm-yyyy"
            className={`form-control form-control-sm ${
              errorsIndv["dob"] ? "is-invalid" : ""
            }`}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            readOnly={readOnly}
            disabled={readOnly}
          />

          {errorsIndv["dob"] ? (
            <div className="auto-lookup-empty">{errorsIndv["dob"]}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Fragment>
  );
};

const Organization = props => {
  const { errors, touched, readOnly } = props;

  const errorsOrg = errors.organization || {};
  const touchedOrg = touched.organization || {};

  return (
    <Fragment>
      <div className="form-group row mb-0">
        <TextInput
          label="Name"
          fieldName="organization.name"
          error={errorsOrg["name"]}
          touch={touchedOrg["name"]}
          {...props}
        />

        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Legal Status
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            component="select"
            name="organization.legalStatus"
            type="text"
            className={`custom-select custom-select-sm ${
              touchedOrg["legalStatus"] && errorsOrg["legalStatus"]
                ? "is-invalid"
                : ""
            }`}
            readOnly={readOnly}
            disabled={readOnly}
          >
            <option value="">Select...</option>
            {LEGAL_STATUS_TYPES.map((ls, idx) => (
              <option key={idx} value={ls.key}>
                {ls.value}
              </option>
            ))}
          </Field>

          <div className="invalid-feedback">
            {touchedOrg["legalStatus"] && errorsOrg["legalStatus"]
              ? errorsOrg["legalStatus"]
              : ""}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const RadioOptions = props => {
  const {
    values,
    setFieldValue,
    touched,
    errors,
    fieldName,
    label,
    options,
    readOnly
  } = props;
  const invalid = touched[fieldName] && errors[fieldName] ? "is-invalid" : "";

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <div className="form-group row mb-0">
      <label className="col-form-label col-12 col-lg-2 col-xl-2 required">
        {label}
      </label>
      <div className="col-12 col-lg-4 col-xl-4">
        <div className="form-group">
          <div className={`form-control form-control-sm ${invalid}`}>
            {options.map((option, index) => {
              return (
                <div
                  key={`${fieldName}-${option.value}`}
                  className="form-check form-check-inline"
                >
                  <Field
                    type="radio"
                    name={fieldName}
                    id={option.value}
                    value={option.value}
                    checked={values[fieldName] === option.value}
                    className={`form-check-input ${invalid}`}
                    onChange={e => {
                      setFieldValue(fieldName, option.value);
                    }}
                    {...disabledField}
                  />

                  <label className="form-check-label" htmlFor={option.value}>
                    {option.label}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="invalid-feedback">{errors[fieldName]}</div>
        </div>
      </div>
    </div>
  );
};

const BasicInfo = props => {
  const { values } = props;
  const requestorType = values.requestorType;

  const MAPPER = {
    INDIVIDUAL: <Individual {...props} />,
    COMPANY: <Organization {...props} />
  };

  const renderComponent = MAPPER[requestorType];
  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-header bg-card-header text-white">
            <h5 className="h6 card-title mb-0">
              <Link
                to="/asset/requestor-onboards"
                className="badge bg-white custom-btn-sm"
              >
                <i className="fas fa-arrow-left" />
              </Link>
              &nbsp;Basic Information
            </h5>
          </div>

          <div className="card-body">
            <RadioOptions
              {...props}
              label="Requestor Type"
              fieldName="requestorType"
              options={[
                { label: "Individual", value: "INDIVIDUAL" },
                { label: "Organization", value: "COMPANY" }
              ]}
            />
            {renderComponent}
          </div>
        </div>
      </div>
    </div>
  );
};

class ContactForm extends Component {
  render() {
    const { values, errors, touched, readOnly } = this.props;
    return (
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <h5 className="h6 card-title mb-0">Contact Information</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong>Phones</strong>

                  <FieldArray
                    name="phones"
                    render={({ insert, remove, push }) =>
                      Phones({
                        values,
                        insert,
                        remove,
                        push,
                        errors,
                        touched,
                        readOnly
                      })
                    }
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <strong>Email</strong>
                  <FieldArray
                    name="emails"
                    render={({ insert, remove, push }) =>
                      Emails({
                        values,
                        insert,
                        remove,
                        push,
                        errors,
                        touched,
                        readOnly
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const IdDocsForm = props => {
  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-header bg-card-header text-white">
            <h5 className="h6 card-title mb-0">Identification Documents </h5>
          </div>
        </div>
        <div className="card-body border">
          <IdDocs {...props} />
        </div>
      </div>
    </div>
  );
};

const AddressForm = props => {
  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-header bg-card-header text-white">
            <h5 className="h6 card-title mb-0">Addresses </h5>
          </div>
        </div>
        <div className="card-body border">
          <Addresses {...props} />
        </div>
      </div>
    </div>
  );
};

class RequestorOnboardForm extends Component {
  render() {
    const { onboardForm, validationSchema, handleSubmit } = this.props;

    return (
      <Formik
        initialValues={onboardForm}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        validationSchema={validationSchema}
        render={({ values, errors, touched, setFieldValue }) => {
          const props = {
            values,
            errors,
            touched,
            setFieldValue,
            readOnly: values.actions.length === 0
          };

          return (
            <Form>
              <BasicInfo {...props} />
              <ContactForm {...props} />
              <IdDocsForm {...props} fieldName="identificationDocs" />
              <AddressForm {...props} />
              <div className="row mt-3 mb-5">
                <div className="col-sm-12 text-left">
                  <h5>
                    <span className="sidebar-badge badge badge-md badge-light">{`Status : ${getDescription(
                      "RequestorOnboard",
                      values.stage,
                      values.status
                    )}`}</span>
                  </h5>
                </div>
                <div className="col-12 text-center">
                  {values.actions.length > 0
                    ? values.actions.map((action, idx) => {
                        return (
                          <Fragment key={idx}>
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                setFieldValue("action", action);
                              }}
                            >
                              {action}
                            </button>
                            &nbsp;&nbsp;
                          </Fragment>
                        );
                      })
                    : null}
                  {/* <button type="submit" className="btn btn-sm btn-primary">
                    Submit
                  </button> */}
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

export default RequestorOnboardForm;
