import React, { Component, Fragment } from "react";
import LoadingComponent from "../common/spinnerloader/LoadingComponent";

class UserForm extends Component {
  render() {
    const {
      firstName,
      lastName,
      username,
      password,
      confirmPassword,
      email,
      mobileNumber,
      isActive,
      userRole,

      inputError,
      userRoles,
      handleInputChange,
      handleSubmit,
      handleConfirmPassword,
      newUser
    } = this.props;

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            {newUser === null ? (
              <LoadingComponent />
            ) : (
              <div className="card">
                <div className="edit-page-section-header-text">
                  <h5 className="h6 card-title mb-0">
                    {newUser ? "New User" : `Update ${firstName} profile`}
                  </h5>
                </div>
                <div className="card-body">
                  <div className="form-group row mb-1">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      First Name
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className={
                          inputError["firstName"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="First Name"
                        name="firstName"
                        value={firstName ? firstName : ""}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        Please enter first name *
                      </div>
                    </div>
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Last Name
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Last Name"
                        name="lastName"
                        value={lastName ? lastName : ""}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row mb-1">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      User Name
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      {newUser ? (
                        <input
                          type="text"
                          className={
                            inputError["firstName"]
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          placeholder="User Name"
                          name="username"
                          value={username ? username : ""}
                          onChange={handleInputChange}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="User Name"
                          name="username"
                          value={username ? username : ""}
                          disabled
                        />
                      )}
                      <div className="invalid-feedback">
                        Please enter user name *
                      </div>
                    </div>
                  </div>

                  {newUser ? (
                    <Fragment>
                      <div className="form-group row mb-1">
                        <label className="col-form-label col-12 col-lg-2 col-xl-2">
                          Password
                        </label>
                        <div className="col-12 col-lg-4 col-xl-4">
                          <input
                            type="password"
                            className={
                              inputError["password"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="Password"
                            name="password"
                            value={password ? password : ""}
                            onChange={handleInputChange}
                          />
                          <div className="invalid-feedback">
                            New password must be minimum 8 characters *
                          </div>
                        </div>
                      </div>
                      <div className="form-group row mb-1">
                        <label className="col-form-label col-12 col-lg-2 col-xl-2">
                          Confirm password
                        </label>
                        <div className="col-12 col-lg-4 col-xl-4">
                          <input
                            type="password"
                            className={
                              inputError["confirmPassword"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={confirmPassword ? confirmPassword : ""}
                            onChange={handleConfirmPassword}
                          />
                          <div className="invalid-feedback">
                            Confirm password doesn't match *
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}

                  <div className="form-group row mb-1">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Email
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <input
                        type="email"
                        className={
                          inputError["email"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="example@example.com"
                        name="email"
                        value={email ? email : ""}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        Please enter primary email *
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-1">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Mobile Number
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <input
                        type="text"
                        className={
                          inputError["mobileNumber"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        placeholder="10 digits mobile number"
                        name="mobileNumber"
                        value={mobileNumber ? mobileNumber : ""}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        Please enter primary mobile number *
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-1">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Status
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control custom-control-input"
                          id="defaultChecked01233"
                          name="isActive"
                          checked={isActive}
                          onChange={handleInputChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="defaultChecked01233"
                        >
                          Active
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-1">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2">
                      Roles
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <select
                        name="userRole"
                        value={userRole ? userRole : ""}
                        onChange={handleInputChange}
                        className={
                          inputError["userRole"]
                            ? "custom-select custom-select-sm  is-invalid"
                            : "custom-select custom-select-sm "
                        }
                      >
                        <option value="">Select...</option>
                        {userRoles.map((role, i) => (
                          <option key={i} value={role._id}>
                            {role.name}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        Please select user role *
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-12">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleSubmit}
                      >
                        {newUser ? "Add" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserForm;
