import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Can from "../../auth/can";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  ASSET_CATEGORIES,
  RENTAL_TYPES,
  STATUS_LIST,
  RENTAL_RATES_TYPES
} from "../common/LookupConstants";

export const TextInput = props => {
  const { label, required, fieldName, touch, error, helperText } = props;
  const labelCssClass = `col-form-label col-12 col-lg-2 col-xl-2 ${
    required ? "edit-page-label-required" : "edit-page-label"
  }`;

  return (
    <Fragment>
      <label className={labelCssClass}>{label}</label>
      <div className="col-12 col-lg-4 col-xl-4">
        <Field
          type="text"
          className={`form-control form-control-sm ${
            touch && error ? "is-invalid" : ""
          }`}
          name={fieldName}
        />
        <small>{helperText || null}</small>
        <div className="invalid-feedback">{error}</div>
      </div>
    </Fragment>
  );
};

export const TextAreaInput = props => {
  const { label, required, fieldName, touch, error, helperText } = props;
  const labelCssClass = `col-form-label col-12 col-lg-2 col-xl-2 ${
    required ? "edit-page-label-required" : "edit-page-label"
  }`;

  return (
    <Fragment>
      <label className={labelCssClass}>{label}</label>
      <div className="col-12 col-lg-4 col-xl-4">
        <Field
          component="textarea"
          className={`form-control form-control-sm ${
            touch && error ? "is-invalid" : ""
          }`}
          name={fieldName}
        />
        <small>{helperText || null}</small>
        <div className="invalid-feedback">{error}</div>
      </div>
    </Fragment>
  );
};

export const SelectInput = props => {
  const { label, required, fieldName, error, optionsData } = props;
  const labelCssClass = `col-form-label col-12 col-lg-2 col-xl-2 ${
    required ? "edit-page-label-required" : "edit-page-label"
  }`;

  return (
    <Fragment>
      <label className={labelCssClass}>{label}</label>
      <div className="col-12 col-lg-4 col-xl-4">
        <Field
          component="select"
          className={`custom-select custom-select-sm ${
            error ? "is-invalid" : ""
          }`}
          name={fieldName}
        >
          <option value="">Select...</option>
          {optionsData.map((op, idx) => (
            <option key={idx} value={op.key}>
              {op.value}
            </option>
          ))}
        </Field>
        <div className="invalid-feedback">{error}</div>
      </div>
    </Fragment>
  );
};

/********************** ProductImages Start ****************************************/
class ImagePreviewContainer extends React.Component {
  constructor(args) {
    super(args);
    this.state = {
      pendingImageUploads: []
    };
  }

  readFileInfo = () => {
    const files = this.props.pendingUploads;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.onloadend = e => {
        this.setState(state => {
          const fileInfo = {
            url: reader.result,
            name: file.name
          };
          return {
            pendingImageUploads: [...state.pendingImageUploads, fileInfo]
          };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.pendingUploads !== this.props.pendingUploads) {
      this.readFileInfo();
    }
  }

  render() {
    const { completedUploads, handleRemoveImage } = this.props;
    const { pendingImageUploads } = this.state;
    const previewUploadedFiles =
      completedUploads.length < 1 && pendingImageUploads.length < 1 ? null : (
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-row flex-wrap align-items-center">
              {completedUploads.map((fileInfo, idx) => {
                return (
                  <Fragment key={idx}>
                    <div className="card-body d-inline text-center">
                      <img
                        src={fileInfo.url}
                        className="img-fluid"
                        style={{ width: 152, height: 128 }}
                        alt={fileInfo.url}
                      />
                      <div className="mt-1">
                        <button
                          className="btn btn-sm btn-outline-danger"
                          aria-label="Close"
                          onClick={handleRemoveImage.bind(this, fileInfo._id)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </Fragment>
                );
              })}

              {pendingImageUploads.map((fileInfo, idx) => {
                return (
                  <Fragment key={idx}>
                    <div className="card-body d-inline text-center">
                      <img
                        src={fileInfo.url}
                        className="img-fluid"
                        style={{ width: 152, height: 128 }}
                        alt={fileInfo.name}
                      />
                      <div className="mt-1">
                        {fileInfo.name}
                        <span className="ml-2 badge badge-success">New</span>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      );

    return <Fragment>{previewUploadedFiles}</Fragment>;
  }
}

const ProductImages = props => {
  const {
    values,
    uploadedProductImagesInfo,
    handleRemoveImage,
    setFieldValue
  } = props;
  return (
    <Fragment>
      <div className="row mt-4 mb-5">
        <div className="col-md-12 text-center">
          <h6>
            <strong>Product Images</strong>
          </h6>

          <div>
            <ImagePreviewContainer
              completedUploads={uploadedProductImagesInfo}
              handleRemoveImage={handleRemoveImage}
              pendingUploads={values.uploadedImages}
            />
          </div>
        </div>
      </div>
      <div className="row mt-1 mb-5">
        <div className="col-md-3" />
        <div className="col-md-6 text-center">
          <div className="d-flex justify-content-center mt-2">
            <div className="input-group mb-0">
              <div className="custom-file">
                <input
                  id="uploadedImages"
                  name="uploadedImages"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={event => {
                    setFieldValue("uploadedImages", event.currentTarget.files);
                  }}
                />
                <label className="custom-file-label" htmlFor="uploadedImages" />
              </div>
            </div>
          </div>
          <small>
            For best results, use an image at least 150px by 130px in .jpg or
            .png format, {"<"} 1 MB
          </small>
        </div>
        <div className="col-md-3" />
      </div>
    </Fragment>
  );
};
/********************** ProductImages End ****************************************/

/********************** RentalPrice Start ****************************************/
const RentalPrice = props => {
  const { values, touched, errors } = props;
  return (
    <FieldArray
      name="rates"
      render={({ insert, remove, push }) => {
        const ratesErrors = errors.rates || [];
        const ratesTouched = touched.rates || [];
        return (
          <Fragment>
            <div className="table-responsive-sm table-responsive-md">
              <div className="row mt-4 mb-3">
                <div className="col-md-12 text-center">
                  <strong>Rental Price</strong>
                  <div className="text-danger">
                    {errors &&
                      errors.rates &&
                      typeof errors.rates === "string" &&
                      errors.rates}
                  </div>
                </div>
              </div>

              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th className="w-25 text-center" scope="col">
                      Rate
                    </th>
                    <th className="w-25 text-center" scope="col">
                      Unit
                    </th>
                    <th className="w-25 text-center" scope="col">
                      Unit Type
                    </th>
                    <th className="w-25 text-center" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {values.rates.map((rtlRate, index) => {
                    const error = ratesErrors[index] || {};
                    const touch = ratesTouched[index] || {};
                    const hideRemoveButton = values.rates.length === 1;
                    const showAddButton = values.rates.length - 1 !== index;
                    return (
                      <tr key={`row-${index}`}>
                        <td>
                          <Field
                            type="number"
                            className={`form-control form-control-sm text-right ${
                              touch["rate"] && error["rate"] ? "is-invalid" : ""
                            }`}
                            name={`rates.${index}.rate`}
                            value={rtlRate.rate || ""}
                          />
                          <div className="invalid-feedback">
                            {touch["rate"] && error["rate"]}
                          </div>
                        </td>

                        <td>
                          <Field
                            type="number"
                            className={`form-control form-control-sm text-right ${
                              touch["unit"] && error["unit"] ? "is-invalid" : ""
                            }`}
                            name={`rates.${index}.unit`}
                            value={rtlRate.unit || ""}
                          />
                          <div className="invalid-feedback">
                            {touch["unit"] && error["unit"]}
                          </div>
                        </td>

                        <td>
                          <Field
                            component="select"
                            name={`rates.${index}.unitType`}
                            value={rtlRate.unitType || ""}
                            className={`form-control form-control-sm ${
                              touch["unitType"] && error["unitType"]
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            <option value="">Select...</option>
                            {RENTAL_RATES_TYPES.map((rtl, idx) => (
                              <option key={idx} value={rtl.key}>
                                {rtl.value}
                              </option>
                            ))}
                          </Field>
                          <div className="invalid-feedback">
                            {touch["unitType"] && error["unitType"]}
                          </div>
                        </td>

                        <td>
                          &nbsp;&nbsp;&nbsp;
                          <button
                            type="button"
                            className={`btn btn-outline-danger btn-sm ${
                              hideRemoveButton ? "d-none" : ""
                            }`}
                            title="Remove"
                            onClick={() => remove(index)}
                          >
                            <i className="far fa-trash-alt " />
                          </button>
                          &nbsp;
                          <button
                            className={`btn btn-info btn-sm ${
                              showAddButton ? "d-none" : ""
                            }`}
                            title="Add"
                            type="button"
                            onClick={() =>
                              push({
                                rate: 40,
                                unit: 2,
                                unitType: "HOURLY"
                              })
                            }
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Fragment>
        );
      }}
    />
  );
};
/********************** RentalPrice End ****************************************/

/********************** Manufacture Start ****************************************/
const Manufacture = props => {
  const errrorsManufacture = props.errors.manufacture || "";
  const touchedManufacture = props.touched.manufacture || "";
  return (
    <Fragment>
      <div className="row mt-4 mb-3">
        <div className="col-md-6">
          <div className="row mt-4 mb-3 no-gutters">
            <div className="col-12 edit-page-section-header-text">
              <strong className="ml-2">Manufacture Information</strong>
            </div>
          </div>
          <div className="card-body">
            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                Manufacturer Name
              </label>
              <div className="col-12 col-lg-8 col-xl-8">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["manufacturerName"] &&
                    errrorsManufacture["manufacturerName"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.manufacturerName"
                />
                <div className="invalid-feedback">
                  {errrorsManufacture["manufacturerName"]}
                </div>
              </div>
            </div>

            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                Manufacture Year
              </label>
              <div className="col-12 col-lg-8 col-xl-8">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["manufactureYear"] &&
                    errrorsManufacture["manufactureYear"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.manufactureYear"
                />
                <div className="invalid-feedback">
                  {errrorsManufacture["manufactureYear"]}
                </div>
              </div>
            </div>

            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                Model Number
              </label>
              <div className="col-12 col-lg-8 col-xl-8">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["modelNumber"] &&
                    errrorsManufacture["modelNumber"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.modelNumber"
                />
                <div className="invalid-feedback">
                  {errrorsManufacture["modelNumber"]}
                </div>
              </div>
            </div>

            <div className="form-group row mb-0">
              <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
                Serial Number
              </label>
              <div className="col-12 col-lg-8 col-xl-8">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    touchedManufacture["serialNumber"] &&
                    errrorsManufacture["serialNumber"]
                      ? "is-invalid"
                      : ""
                  }`}
                  name="manufacture.serialNumber"
                />
                <div className="invalid-feedback">
                  {errrorsManufacture["serialNumber"]}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <RentalPrice {...props} />
        </div>
      </div>
    </Fragment>
  );
};
/********************** Manufacture End ****************************************/

/********************** Location Start ****************************************/
const Location = props => {
  const errorsLocation = props.errors.location || "";
  const touchedLocation = props.touched.location || "";
  return (
    <Fragment>
      <div className="edit-page-section-header-text">
        <strong>Location Information (as applicable)</strong>
      </div>
      <div className="card-body">
        <div className="form-group row mb-0">
          <TextInput
            label="Campus Name"
            fieldName="location.campusName"
            {...props}
            error={errorsLocation["campusName"] || ""}
            touch={touchedLocation["campusName"] || ""}
            helperText={`Eg: PSG Tech, PSG iTech, PSG IAS, etc.`}
          />
          <TextInput
            label="Building Name"
            fieldName="location.buildingName"
            {...props}
            error={errorsLocation["buildingName"] || ""}
            touch={touchedLocation["buildingName"] || ""}
            helperText={`Eg: K Block, G Block, etc.`}
          />
        </div>

        <div className="form-group row mb-0">
          <TextInput
            label="Floor Name / Number"
            fieldName="location.floor"
            {...props}
            error={errorsLocation["floor"] || ""}
            touch={touchedLocation["floor"] || ""}
            helperText={`Eg:Ground, 1st, 2nd, 3rd, etc.`}
          />
          <TextInput
            label="Room"
            fieldName="location.room"
            {...props}
            error={errorsLocation["room"] || ""}
            touch={touchedLocation["room"] || ""}
            helperText={`Enter Room No or Name.`}
          />
        </div>

        <div className="form-group row mb-0">
          <TextInput
            label="Facility Name"
            fieldName="location.facilityName"
            {...props}
            error={errorsLocation["facilityName"] || ""}
            touch={touchedLocation["facilityName"] || ""}
            helperText={`Eg: PRAYAS Lab, Bionest, Nano Lab, etc.`}
          />
          <TextInput
            label="Department"
            fieldName="location.department"
            {...props}
            error={errorsLocation["department"] || ""}
            touch={touchedLocation["department"] || ""}
            helperText={`Enter College Department.`}
          />
        </div>
      </div>
    </Fragment>
  );
};
/********************** Location End ****************************************/

/********************** AssetOwnerContact Start ****************************************/
const AssetOwnerContact = props => {
  const errorsContact = props.errors.assetOwnerContact || "";
  const touchedContact = props.touched.assetOwnerContact || "";
  return (
    <Fragment>
      <div className="edit-page-section-header-text">
        <strong>Asset Owner Contact Information</strong>
      </div>
      <div className="card-body">
        <div className="form-group row mb-0">
          <TextInput
            label="Name"
            fieldName="assetOwnerContact.name"
            {...props}
            error={errorsContact["name"] || ""}
            touch={touchedContact["name"] || ""}
            required
          />
          <TextInput
            label="Phone"
            fieldName="assetOwnerContact.phone"
            {...props}
            error={errorsContact["phone"] || ""}
            touch={touchedContact["phone"] || ""}
          />
        </div>
        <div className="form-group row mb-0">
          <TextInput
            label="Email"
            fieldName="assetOwnerContact.email"
            {...props}
            error={errorsContact["email"] || ""}
            touch={touchedContact["email"] || ""}
          />
        </div>
      </div>
    </Fragment>
  );
};
/********************** AssetOwnerContact End ****************************************/

/********************** IncubatorContact Start ****************************************/
const IncubatorContact = props => {
  const errorsContact = props.errors.incubatorContact || "";
  const touchedContact = props.touched.incubatorContact || "";
  return (
    <Fragment>
      <div className="edit-page-section-header-text">
        <strong>Incubator Contact Information</strong>
      </div>
      <div className="card-body">
        <div className="form-group row mb-0">
          <TextInput
            label="Name"
            fieldName="incubatorContact.name"
            {...props}
            error={errorsContact["name"] || ""}
            touch={touchedContact["name"] || ""}
            required
          />
          <TextInput
            label="Phone"
            fieldName="incubatorContact.phone"
            {...props}
            error={errorsContact["phone"] || ""}
            touch={touchedContact["phone"] || ""}
          />
        </div>
        <div className="form-group row mb-0">
          <TextInput
            label="Email"
            fieldName="incubatorContact.email"
            {...props}
            error={errorsContact["email"] || ""}
            touch={touchedContact["email"] || ""}
          />
        </div>
      </div>
    </Fragment>
  );
};
/********************** IncubatorContact End ****************************************/

/********************** BasicInfo Start ****************************************/
const BasicInfo = props => {
  return (
    <Fragment>
      <div className="edit-page-section-header-text">
        <strong>Basic Information</strong>
      </div>
      <div className="card-body">
        <div className="form-group row mb-0">
          <TextInput
            label="Asset Id"
            fieldName="assetId"
            {...props}
            error={props.errors["assetId"] || ""}
            touch={props.touched["assetId"] || ""}
            required
          />

          <TextInput
            label="Asset Name"
            fieldName="assetName"
            {...props}
            error={props.errors["assetName"] || ""}
            touch={props.touched["assetName"] || ""}
            required
          />
        </div>

        <div className="form-group row mb-0">
          <TextInput
            label="Asset Type"
            fieldName="assetType"
            {...props}
            error={props.errors["assetType"] || ""}
            touch={props.touched["assetType"] || ""}
            required
          />
          <SelectInput
            label="Asset Category"
            fieldName="assetCategory"
            {...props}
            error={props.errors["assetCategory"] || ""}
            touch={props.touched["assetCategory"] || ""}
            optionsData={ASSET_CATEGORIES}
            required
          />
        </div>

        <div className="form-group row mb-0">
          <TextInput
            label="Asset Owner"
            fieldName="ownerName"
            {...props}
            error={props.errors["ownerName"] || ""}
            touch={props.touched["ownerName"] || ""}
            required
          />
          <SelectInput
            label="Rental Type"
            fieldName="rentalType"
            {...props}
            error={props.errors["rentalType"] || ""}
            touch={props.touched["rentalType"] || ""}
            optionsData={RENTAL_TYPES}
            required
          />
        </div>
        <div className="form-group row mb-0">
          <SelectInput
            label="Status"
            fieldName="status"
            {...props}
            error={props.errors["status"] || ""}
            touch={props.touched["status"] || ""}
            optionsData={STATUS_LIST}
            required
          />
        </div>
      </div>
    </Fragment>
  );
};
/********************** BasicInfo End ****************************************/

/*********************** EquipmentForm Start *******************************/
const EquipmentForm = props => {
  const { onSubmit, isSubmitting, newEquipment } = props;

  const permisson = newEquipment ? "CREATE" : "UPDATE";
  const submitLabel = newEquipment ? "Save" : "Update";

  return (
    <Form>
      <div className="pl-1 pr-1">
        <BasicInfo {...props} />
        <hr />
        <AssetOwnerContact {...props} />
        <hr />
        <IncubatorContact {...props} />
        <hr />
        <Location {...props} />
        <hr />
        <Manufacture {...props} />

        <hr />
        <ProductImages {...props} />
        <hr />
        <div className="card-body">
          <div className="form-group row mb-0">
            <TextAreaInput
              label="Notes"
              fieldName="notes"
              {...props}
              error={props.errors["notes"] || ""}
              touch={props.touched["notes"] || ""}
            />
          </div>
        </div>
        <hr />

        <div className="row mb-5 mt-5">
          <div className="col-md-12 text-center">
            <Can do={permisson} on="Equipment">
              <button
                className="btn btn-sm btn-primary"
                type="submit"
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                {submitLabel}
              </button>
            </Can>
          </div>
        </div>
      </div>
    </Form>
  );
};
/*********************** EquipmentForm End *******************************/

/*********************** EquipmentFormik Start *******************************/
class EquipmentFormik extends Component {
  render() {
    const { internalOrgs, newEquipment } = this.props;
    return (
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3 mb-3">
              <span className="edit-page-title">
                <Link to="/assets" className="badge bg-white custom-btn-sm">
                  <i className="fas fa-arrow-left mr-2" />
                </Link>
                {newEquipment ? "Add Asset" : "Update Asset"}
              </span>
            </div>

            <Formik
              initialValues={this.props.initialValues}
              validationSchema={this.props.validationSchema}
              onSubmit={(values, { setSubmitting }) =>
                this.props.handleSubmit(values, { setSubmitting })
              }
              render={({
                values,
                onSubmit,
                isSubmitting,
                errors,
                touched,
                setFieldValue
              }) => {
                const formProps = {
                  values,
                  uploadedProductImagesInfo: this.props
                    .uploadedProductImagesInfo,
                  handleRemoveImage: this.props.handleRemoveImage,
                  newEquipment: this.props.newEquipment,
                  onSubmit,
                  isSubmitting,
                  internalOrgs,
                  errors,
                  touched,
                  setFieldValue
                };
                return <EquipmentForm {...formProps} />;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EquipmentFormik;
/*********************** EquipmentFormik End *******************************/
