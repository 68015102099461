const PhoneInfoSchema = {
  title: "Phones",
  type: "array",
  minItems: 1,
  items: {
    title: "Phone",
    type: "object",
    required: ["type", "phone"],
    properties: {
      type: {
        type: "string",
        title: "Type",
        anyOf: [
          {
            type: "string",
            title: "Mobile",
            enum: ["MOBILE"]
          },
          {
            type: "string",
            title: "Work",
            enum: ["WORK"]
          },
          {
            type: "string",
            title: "Office",
            enum: ["OFFICE"]
          },
          {
            type: "string",
            title: "Other",
            enum: ["OTHER"]
          }
        ]
      },
      phone: {
        type: "string",
        title: "Phone"
      }
    }
  }
};

const PhoneInfoUiSchema = {};

export { PhoneInfoSchema, PhoneInfoUiSchema };
