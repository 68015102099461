import React, { Component, Fragment } from "react";
import { PAYEE_TYPES_OBJECT } from "../../common/LookupConstants";
import { convertDateToNumber } from "../../lease/DateUtil";
import ManualChargePreviewForm from "./ManualChargePreviewForm";

class ManualChargeConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewForm: null
    };
  }

  addData = (manualCharge, labelName, value) => {
    if (value) {
      manualCharge.push({
        label: labelName,
        value
      });
    }
  };

  getCheckIcon = data => {
    let unitPriced;
    if (data) {
      unitPriced = (
        <Fragment>
          <strong>
            <i
              className="far fa-check-square text-success font-weight-bold"
              style={{ fontSize: "m-large" }}
            />
          </strong>
          &nbsp; Yes
        </Fragment>
      );
    } else {
      unitPriced = (
        <Fragment>
          <strong>
            <i
              className="far fa-window-close text-danger font-weight-bold"
              style={{ fontSize: "m-large" }}
            />
          </strong>
          &nbsp; No
        </Fragment>
      );
    }
    return unitPriced;
  };

  componentDidMount = () => {
    const previewForm = { ...this.props.manualCharge };
    previewForm.chargeDate = convertDateToNumber(
      this.props.manualCharge.chargeDate
    );
    previewForm.dueDate = convertDateToNumber(this.props.manualCharge.dueDate);

    let customer = {
      name: this.props.displayName
    };
    previewForm.customer = customer;
    previewForm.customerType =
      PAYEE_TYPES_OBJECT[this.props.manualCharge.customerType];
    this.setState({ previewForm });
  };

  render() {
    const { handleEditForm, handleSubmit } = this.props;
    const { previewForm } = this.state;
    if (previewForm === null) {
      return null;
    }

    return (
      <Fragment>
        <ManualChargePreviewForm
          data={this.state.previewForm}
          title="Charge  Confirmation"
        />
        <div className="form-group row mt-3 mb-0">
          <div className="col-12 text-center">
            <button className="btn btn-primary btn-sm" onClick={handleEditForm}>
              Edit
            </button>
            &nbsp;
            <button className="btn btn-primary btn-sm" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ManualChargeConfirm;
