import React, { Component, Fragment } from "react";

import HttpUtil from "../../common/HttpUtil";
import { PAYMENTS_API, CHARGES_API } from "../../common/Constants";
import AlertComponent from "../../common/AlertComponent";
import PaymentEditForm from "./PaymentEditForm";
import PaymentConfirmForm from "./PaymentConfirmForm";
import { convertDateToNumber } from "../../lease/DateUtil";
import Redirect from "react-router-dom/Redirect";
import { checkEmptyValue } from "../../common/LookupConstants";
import ErrorPage from "../../common/error.page";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: {
        payeeType: null,
        paymentMode: "CHEQUE",
        paymentType: "REGULAR",
        payee: null,
        paymentTowards: [],
        paymentTowardsType: "FIN_CHARGE",
        paymentDate: new Date(),
        notes: null,
        amount: null,
        reference: null,
        collectedBy: null,
        // cheque payment
        bankName: null,
        chequeNumber: null,
        chequeDate: null,
        // online payment
        paymentTransferType: null,
        // card payment
        cardType: null,
        cardIssuer: null
      },

      // payee display name ==> "ViewPayment.js"
      displayName: null,
      chargeDescription: null,
      payeeName: null,
      payeeLookupUrl: null,

      paymentTowardsList: [],
      inputError: {},

      // forms
      paymentConfirmationForm: false,
      paymentEditForm: true,

      viewPayment: false,
      viewPaymentId: null,
      permanentFailure: false,

      previousLocation: "/admin/finance/payments",

      newPayment: null
    };
  }

  getPayee = payee => {
    if (payee !== null) {
      const MAPPER = {
        INDIVIDUAL: "Individual",
        COMPANY: "Organization"
      };
      const payment = { ...this.state.payment };
      const customerType = MAPPER[payment.payeeType];
      const customer = payee.value;
      const baseUrl = "/api/v1/finance/charges/pending?";
      const pmtTowsLkupUrl = `${baseUrl}customer=${customer}&customerType=${customerType}`;

      payment.payee = payee;
      this.setState({
        payment,
        displayName: payee.label
      });

      // To fetch charges based on Entity
      let paymentTowardsList = [];
      HttpUtil.get(
        pmtTowsLkupUrl,
        {},
        data => {
          if (data.length > 0) {
            const options = data.map(pt => {
              return {
                label: pt.description,
                value: pt._id
              };
            });
            paymentTowardsList = options;
          }
          // else {
          //   paymentTowardsList.push({
          //     _id: "",
          //     description: "No Charges are Available"
          //   });
          // }
          this.setState({ paymentTowardsList });
        },
        (data, status) =>
          this.handleApiFailed(data.message, status === 403 || status === 404),
        error => this.handleApiFailed(error.toString(), true)
      );
    } else {
      const payment = { ...this.state.payment };
      payment.payee = null;
      this.setState({ payment });
    }
  };

  handleDateChange = (targetName, date) => {
    const payment = this.state.payment;
    payment[targetName] = date;
    this.setState({ payment });
  };

  handlePaymentTowards = paymentTowards => {
    const payment = { ...this.state.payment };
    payment.paymentTowards = paymentTowards;
    this.setState({ payment });
  };

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    const payment = { ...this.state.payment };
    payment[targetName] = targetValue;
    this.setState({ payment });
  };

  handleAmountChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (Number(targetValue) < 1) {
      return;
    }
    const payment = { ...this.state.payment };
    payment[targetName] = targetValue;
    this.setState({ payment });
  };

  handlePaymentModeChange = e => {
    const payment = { ...this.state.payment };
    payment.paymentMode = e.target.name;
    payment.bankName = null;
    payment.chequeNumber = null;
    payment.chequeDate = null;
    payment.paymentTransferType = null;
    payment.cardType = null;
    payment.cardIssuer = null;
    this.setState({ payment, inputError: {} });
  };

  handlePayeeType = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    const URL_MAPPER = {
      INDIVIDUAL: {
        url: "api/v1/individuals/lookup/finance/?name=",
        payeeName: "name"
      },
      COMPANY: {
        url: "api/v1/organizations/lookup/finance?name=",
        payeeName: "name"
      }
    };

    let payeeLookupUrl = null;
    let payeeName = null;

    if (targetValue !== null) {
      payeeName = URL_MAPPER[targetValue].payeeName;
      payeeLookupUrl = URL_MAPPER[targetValue].url;
    }

    const payment = { ...this.state.payment };
    payment[targetName] = targetValue;
    this.setState({ payment, payeeLookupUrl, payeeName });
  };

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  validateByPaymentMode = (inputError, paymentRequest) => {
    if (paymentRequest.paymentMode === "CHEQUE") {
      checkEmptyValue(inputError, "bankName", paymentRequest.bankName);
      checkEmptyValue(inputError, "chequeNumber", paymentRequest.chequeNumber);
      checkEmptyValue(inputError, "chequeDate", paymentRequest.chequeDate);
    } else if (paymentRequest.paymentMode === "ONLINE_PAYMENT") {
      checkEmptyValue(inputError, "bankName", paymentRequest.bankName);
      checkEmptyValue(
        inputError,
        "paymentTransferType",
        paymentRequest.paymentTransferType
      );
    } else if (paymentRequest.paymentMode === "CARD_PAYMENT") {
      checkEmptyValue(inputError, "cardType", paymentRequest.cardType);
      checkEmptyValue(inputError, "cardIssuer", paymentRequest.cardIssuer);
    }
  };

  validate = () => {
    const payment = { ...this.state.payment };
    const newPayment = this.state.newPayment;
    let payee = null;
    if (newPayment && payment.payee) {
      payee = payment.payee.value;
    } else {
      payee = payment.payee;
    }
    const paymentRequest = {
      entityType: payment.payeeType,
      paymentType: payment.paymentType,
      paymentMode: payment.paymentMode,
      bankName: payment.bankName,
      entity: payee,
      paymentDate: convertDateToNumber(payment.paymentDate),
      notes: payment.notes,
      amount: payment.amount,
      reference: payment.reference,
      collectedBy: payment.collectedBy,
      // cheque payment
      chequeNumber: payment.chequeNumber,
      chequeDate: convertDateToNumber(payment.chequeDate),
      // online payment
      paymentTransferType: payment.paymentTransferType,
      // card payment
      cardType: payment.cardType,
      cardIssuer: payment.cardIssuer,

      paymentTowards:
        (payment.paymentTowards &&
          payment.paymentTowards.map(pt => pt.value)) ||
        [],
      paymentTowardsType: payment.paymentTowardsType
    };

    let inputError = {};
    checkEmptyValue(inputError, "payeeType", paymentRequest.entityType);
    checkEmptyValue(inputError, "payee", paymentRequest.entity);
    checkEmptyValue(inputError, "paymentType", paymentRequest.paymentType);
    checkEmptyValue(inputError, "amount", paymentRequest.amount);
    this.validateByPaymentMode(inputError, paymentRequest);

    const invalid = Object.values(inputError).some(item => item);

    if (invalid) {
      this.setState({ inputError });
      return false;
    } else {
      this.setState({ inputError: {} });
      return paymentRequest;
    }
  };

  handleSubmit = () => {
    const paymentRequest = this.validate();
    if (!paymentRequest) {
      return;
    }
    const url = PAYMENTS_API;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      paymentRequest,
      data => {
        this.setState({
          viewPayment: true,
          viewPaymentId: data._id
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleNextForm = () => {
    const valid = this.validate();
    if (!valid) {
      return;
    } else {
      this.setState({
        paymentConfirmationForm: true,
        paymentEditForm: false
      });
    }
  };

  handleEditForm = () => {
    this.setState({
      paymentConfirmationForm: false,
      paymentEditForm: true,
      inputError: {}
    });
  };

  componentDidMount = () => {
    if (this.props.location.search !== "") {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const reportId = params.get("report");
      const chargeIds = params.get("charge");
      const from = params.get("from");
      let previousLocation = "";
      if (from) {
        previousLocation = from;
      } else {
        previousLocation = `/admin/finance/reports/${reportId}/charges`;
      }
      chargeIds
        .split(",")
        .forEach(chargeId => this.getCharge(previousLocation, chargeId));
    } else {
      this.setState({ newPayment: true });
    }
  };

  getName = data => {
    let name;
    const names = [];
    if (data.customer.firstName) {
      names.push(data.customer.firstName);
    }

    if (data.customer.lastName) {
      names.push(data.customer.lastName);
    }

    name = names.length > 0 ? names.join(" ") : data.customer.name;
    return name;
  };

  setCharge = data => {
    let charge = {};
    const customerType = data.customerType;
    const URL_MAPPER = {
      Individual: {
        url: "api/v1/individuals",
        payeeName: "firstName",
        type: "INDIVIDUAL"
      },
      Organization: {
        url: "api/v1/organizations",
        payeeName: "name",
        type: "COMPANY"
      }
    };
    let payeeLookupUrl = null;
    let payeeName = null;

    payeeName = URL_MAPPER[customerType].payeeName;
    payeeLookupUrl = URL_MAPPER[customerType].url;

    charge = {
      payeeType: URL_MAPPER[customerType].type,
      payee: data.customer._id,
      displayName: this.getName(data),
      paymentDate: new Date(),
      amount: data.amount,
      paidAmount: data.paidAmount,
      payeeName: payeeName,
      payeeLookupUrl: payeeLookupUrl,
      paymentTowards: data._id,
      chargeDescription: data.description
    };

    return charge;
  };

  getCharge = (previousLocation, chargeId) => {
    const url = `${CHARGES_API}/${chargeId}`;

    HttpUtil.get(
      url,
      {},
      data => {
        const chargeInfo = this.setCharge(data);
        const payment = this.state.payment;
        payment.payeeType = chargeInfo.payeeType;
        payment.payee = chargeInfo.payee;
        payment.paymentDate = chargeInfo.paymentDate;
        payment.amount =
          (payment.amount || 0) +
          (chargeInfo.amount - (chargeInfo.paidAmount || 0));
        payment.paymentTowards = [
          ...payment.paymentTowards,
          { label: data.description, value: data._id }
        ];

        this.setState({
          payment: { ...payment },
          // chargeDescription: chargeInfo.chargeDescription,
          displayName: chargeInfo.displayName,
          payeeName: payment.payeeName,
          payeeLookupUrl: payment.payeeLookupUrl,
          // back to monthly report charges page
          previousLocation: previousLocation,
          newPayment: false
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  render() {
    const {
      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      paymentConfirmationForm,
      paymentEditForm,

      viewPayment,
      viewPaymentId,
      newPayment,
      permanentFailure
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (newPayment === null) {
      return null;
    }

    if (viewPayment) {
      // const url = `/admin/finance/payments/view/${viewPaymentId}`;
      const stateObj = {
        pathname: `/admin/finance/payments/view/${viewPaymentId}`,
        state: { paymentSubmitted: true }
      };
      return <Redirect to={stateObj} />;
    }

    const props = {
      newPayment: newPayment,
      payment: this.state.payment,
      inputError: this.state.inputError,
      displayName: this.state.displayName,
      // chargeDescription: this.state.chargeDescription,
      payeeName: this.state.payeeName,
      payeeLookupUrl: this.state.payeeLookupUrl,
      paymentTowardsList: this.state.paymentTowardsList,
      previousLocation: this.state.previousLocation,

      // events
      handleDateChange: this.handleDateChange,
      handlePaymentTowards: this.handlePaymentTowards,
      handleInputChange: this.handleInputChange,
      handleAmountChange: this.handleAmountChange,
      handlePayeeType: this.handlePayeeType,
      handlePaymentModeChange: this.handlePaymentModeChange,
      getPayee: this.getPayee,
      handleNextForm: this.handleNextForm,
      handleEditForm: this.handleEditForm,
      handleSubmit: this.handleSubmit
    };

    let renderForm = null;
    if (paymentEditForm) {
      renderForm = <PaymentEditForm {...props} />;
    } else if (paymentConfirmationForm) {
      renderForm = <PaymentConfirmForm {...props} />;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        {renderForm}
      </Fragment>
    );
  }
}

export default Payment;
