import React, { Component, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import ShowHideComponent from "../widgets/ShowHideComponent";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

const paginationTotalRenderer = (from, to, size) => {
  const hasData = size ? true : false;
  return (
    <ShowHideComponent show={hasData}>
      <span className="react-bootstrap-table-pagination-total">
        Showing <strong>{from}</strong> - <strong>{to}</strong> /{" "}
        <strong>{size}</strong> Results
      </span>
    </ShowHideComponent>
  );
};

const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
  <li key={text} role="presentation" className="dropdown-item">
    <button
      className="btn btn-sm btn-outline"
      tabIndex="-1"
      role="menuitem"
      data-page={page}
      onMouseDown={e => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      {text}
    </button>
  </li>
);

const TableListPageHeader = props => {
  const { title, actions, onSearch, onExport, exportAll } = props;
  return (
    <div className="table-list-page-header mb-3 mt-1 align-items-center">
      <div className="table-list-page-header-title pl-4 pr-5">{title}</div>
      <div className="table-list-page-header-actions pr-2">
        <div className="float-right">
          <button
            className="btn btn-outline-info btn-sm mr-2"
            onClick={() => onExport()}
          >
            Export
          </button>
          {actions}
        </div>
        {exportAll && (
          <div className="float-right">
            <button
              className="btn btn-outline-info btn-sm mr-2"
              onClick={() => exportAll()}
            >
              Export All
            </button>
          </div>
        )}
      </div>
      <div className="table-list-page-header-search">
        <div className="input-group">
          <input
            className="form-control form-control-sm rounded-pill border-0"
            type="text"
            placeholder=" Search..."
            name="searchText"
            onChange={e => onSearch(e.target.value)}
            style={{ backgroundColor: "#647acb2b" }}
          />
          <span
            className="input-group-append "
            style={{ backgroundColor: "#647acb2b" }}
          >
            <div className="input-group-text border-0 ml-n5 bg-transparent">
              <i className="fa fa-search" style={{ color: "#9d9c9c" }} />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

class TableList extends Component {
  render() {
    const hasData = this.props.data ? true : false;

    const paginationOptions = {
      custom: true,
      totalSize: hasData ? this.props.data.length : 0,
      alwaysShowAllBtns: true,
      paginationTotalRenderer,
      sizePerPageOptionRenderer,
      sizePerPage: this.props.sizePerPage,
      withFirstAndLast: false,
      hidePageListOnlyOnePage: true
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <ToolkitProvider
          keyField={this.props.keyField}
          columns={this.props.columns}
          data={this.props.data}
          search
          exportCSV={{
            fileName: `${this.props.headerName}.csv`
          }}
        >
          {toolkitprops => (
            <Fragment>
              <TableListPageHeader
                title={this.props.headerName}
                actions={this.props.customComponent}
                {...toolkitprops.searchProps}
                {...toolkitprops.csvProps}
                exportAll={this.props.exportAll}
              />
              <div className="table-responsive-xs table-responsive-sm">
                <BootstrapTable
                  bootstrap4
                  bordered={false}
                  headerClasses="table-list-header text-white"
                  classes="table-list"
                  noDataIndication={this.props.noDataIndication}
                  {...toolkitprops.baseProps}
                  {...paginationTableProps}
                />
              </div>
            </Fragment>
          )}
        </ToolkitProvider>
        <div className="row pl-3 pr-3">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="table-list-total">
              <PaginationTotalStandalone {...paginationProps} />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="table-list-size-per-page">
              <SizePerPageDropdownStandalone {...paginationProps} />
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      </div>
    );

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div className="card table-list-box-shadow">
            <div className="card-body pl-0 pr-0">
              <ShowHideComponent show={!hasData}>
                <div className="row">
                  <div className="col-10 offset-1">
                    <div className="progress-bar-loading-text">Loading...</div>
                    <div className="progress-bar">
                      <div className="indeterminate" />
                    </div>
                  </div>
                </div>
              </ShowHideComponent>
              <ShowHideComponent show={hasData}>
                <PaginationProvider
                  pagination={paginationFactory(paginationOptions)}
                >
                  {contentTable}
                </PaginationProvider>
              </ShowHideComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TableList;

export { sizePerPageOptionRenderer, paginationTotalRenderer };
