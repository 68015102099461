function generateObject(array, keyField, valueField) {
  return array.reduce((obj, item) => {
    const key = item[keyField];
    const value = item[valueField];
    obj[key] = value;
    return obj;
  }, {});
}

// Graduation Status
export const GRADUATION_STATUS = [
  {
    key: "GRADUATED",
    value: "Graduated"
  },
  {
    key: "ONGOING",
    value: "Ongoing"
  },
  {
    key: "PENDING_LEASE",
    value: "Awaiting Lease Completion"
  }
];

export const GRADUATION_STATUS_OBJECT = generateObject(
  GRADUATION_STATUS,
  "key",
  "value"
);

// Residential Status
export const RESIDENTIAL_STATUS = [
  {
    key: "PHYSICAL",
    value: "Physical"
  },
  {
    key: "VIRTUAL",
    value: "Virtual"
  }
];

export const RESIDENTIAL_STATUS_OBJECT = generateObject(
  RESIDENTIAL_STATUS,
  "key",
  "value"
);

// Incubation Sector
export const INCUBATION_SECTORS = [
  {
    key: "BIO_TECH",
    value: "BioTech"
  },
  {
    key: "ELECTRONIC",
    value: "Electronic"
  },
  {
    key: "ICT",
    value: "ICT"
  },
  {
    key: "MANUFACTURING",
    value: "Manufacturing"
  },
  {
    key: "NANO_TECH",
    value: "NanoTech"
  }
];

export const INCUBATION_SECTORS_OBJECT = generateObject(
  INCUBATION_SECTORS,
  "key",
  "value"
);

// Sub Sector
export const SUB_SECTORS = [
  {
    key: "AGRI_TECH",
    value: "AgriTech"
  },
  {
    key: "AUTOMATION",
    value: "Automation"
  },
  {
    key: "CLEAN_TECH",
    value: "CleanTech"
  },
  {
    key: "DEEP_TECH",
    value: "DeepTech"
  },
  {
    key: "ENG_PROD_DESIGN",
    value: "Engineering / Product Design"
  },
  {
    key: "ED_TECH",
    value: "EdTech"
  },
  {
    key: "FOOD_TECH",
    value: "FoodTech"
  },
  {
    key: "FIN_TECH",
    value: "FinTech"
  },
  {
    key: "HEALTHCARE",
    value: "Healthcare"
  },
  {
    key: "INDUSTRIAL_BIO_TECH",
    value: "Industrial Biotech"
  },
  {
    key: "IOT_ENERGY",
    value: "IoT-Energy"
  },
  {
    key: "IOT_INDUSTRY",
    value: "IoT-Industry"
  },

  {
    key: "IOT_ROBOTICS",
    value: "IIoT-Robotics"
  },
  {
    key: "IOT_HOME",
    value: "IoT-Home"
  },
  {
    key: "IOT_WEARABLE",
    value: "IoT-Wearable"
  },
  {
    key: "LEGAL_TECH",
    value: "LegalTech"
  },
  {
    key: "MED_TECH",
    value: "MedTech"
  },
  {
    key: "RETAIL_TECH",
    value: "RetailTech"
  },
  {
    key: "SASS_WEB_MOBILE_APP",
    value: "Sass(Web/MobileApp)"
  },
  {
    key: "TEXTILE",
    value: "Textile"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export const SUB_SECTORS_OBJECT = generateObject(SUB_SECTORS, "key", "value");

// Incubatee Stage
export const INCUBATEE_STAGES = [
  {
    key: "GROWTH",
    value: "Growth"
  },
  {
    key: "IDEA",
    value: "Idea"
  },
  {
    key: "MVP_Pre_Seed",
    value: "MVP (Pre-Seed)"
  },
  {
    key: "PRODUCT",
    value: "Product"
  },
  {
    key: "POC",
    value: "Proof of Concept"
  },
  {
    key: "POC_PROTOTYPE",
    value: "Proof of Concept/Prototype"
  },
  {
    key: "GRANT",
    value: "Grant"
  },
  {
    key: "PROTOTYPE",
    value: "Prototype"
  }
];

export const INCUBATEE_STAGES_OBJECT = generateObject(
  INCUBATEE_STAGES,
  "key",
  "value"
);

export const INCUBATION_CATEGORY_TYPES = [
  {
    key: "PREINCUBATION",
    value: "Pre Incubation"
  },
  {
    key: "INCUBATION",
    value: "Incubation"
  },
  {
    key: "COINCUBATION",
    value: "Co-Incubation"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export const INCUBATION_CATEGORY_TYPES_OBJECT = generateObject(
  INCUBATION_CATEGORY_TYPES,
  "key",
  "value"
);
