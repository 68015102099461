import React, { Fragment } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import DatePicker from "react-datepicker";
import {
  GRADUATION_REASONS,
  PHONE_TYPES_LIST_OBJECT,
  EMAIL_TYPES_LIST_OBJECT,
  ADDRESS_TYPES_LIST_OBJECT
} from "../../common/LookupConstants";
import Phones from "../../widgets/PhonesFormik";
import Emails from "../../widgets/EmailsFormik";
import Addresses from "../../widgets/AddressFormik";
import { getDisplayDate } from "../../lease/DateUtil";
import { downloadDocument, getDescription } from "../../common/Util";
import Can from "../../../auth/can";

function getError(touched, error, fieldName) {
  return touched && touched[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touched, error, fieldName) {
  return getError(touched, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touched, error, datePicker } = props;
  const className = datePicker ? "auto-lookup-empty" : "invalid-feedback";

  return (
    <div className={className}>
      {touched && touched[`${fieldName}`] && error && error[`${fieldName}`]}
    </div>
  );
};

const IncubateeTitle = props => {
  const { values } = props;
  const incubateeName =
    (props.incubatee && props.incubatee.name) || "Unspecified";

  const STATUS_MAPPER = {
    REJECTED: "badge-soft-danger",
    ACTIVE: "badge-soft-success"
  };

  let statusCssClass = STATUS_MAPPER[values.status] || "";

  return (
    <div className="row mb-3">
      <div className="col-md-4" />
      <div className="col-md-4 text-right">
        <h6 className="mb-0 d-inline-block">
          <strong style={{ color: "rgb(100, 122, 203)" }}>
            {`${incubateeName} - Graduation`}
          </strong>
        </h6>
      </div>
      <div className="col-md-4">
        <span
          className={`float-right edit-page-status ml-2 ${statusCssClass} `}
        >
          {getDescription("IncubateeGraduation", values.stage, values.status)}
        </span>
      </div>
    </div>
  );
};

const CurrentIncubateeInformation = props => {
  return (
    <div className="row mb-5">
      <div className="col-md-12">
        <div className="shadow card flex-fill box-border box-light-blue">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-12 text-center">
                <h6>
                  <strong>Current Incubatee Information</strong>
                </h6>
              </div>
            </div>

            {/* Promoter Information */}
            <PromoterInformation {...props} />

            {/* ExistingContactInformation */}
            <ExistingContactInformation {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateGraduationForm = props => {
  const {
    values,
    errors,
    touched,
    readOnly,
    setFieldValue,
    availableDocuments
  } = props;

  const hasDocuments =
    (availableDocuments || []).length > 0 ||
    (values.documents || []).length > 0;

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <div className="row mb-5">
      <div className="col-md-12">
        <div className="shadow card flex-fill box-border box-light-blue">
          <div className="card-body">
            <div className="row mb-2">
              <div className="col-12 text-center">
                <h6>
                  <strong>Graduation Form</strong>
                </h6>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <p className="font-weight-lighter">
                  Please provide updated contact information for future
                  communications. Below form needs to be filled only if the
                  updated contact information is not same as the current contact
                  information.
                </p>
              </div>
            </div>

            {/* Graduation Information */}
            <div className="row mb-4">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-3 ">
                <div className="col-md-12 edit-page-section-header-text">
                  Graduation Information
                </div>

                <div className="form-group row mt-3 mb-2">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 edit-page-label-required">
                    Reason
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <Field
                      component="select"
                      className={`form-control form-control-sm ${getErrorCssClassName(
                        touched,
                        errors,
                        "reason"
                      )}`}
                      name="reason"
                      value={values.reason || ""}
                      {...disabledField}
                    >
                      <option value="">Select...</option>
                      {GRADUATION_REASONS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </Field>
                    <ErrorComponent
                      fieldName={`reason`}
                      touched={touched}
                      error={errors}
                    />
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 edit-page-label-required">
                    Graduation Date
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <DatePicker
                      value={getDisplayDate(values.graduationDate) || ""}
                      selected={values.graduationDate || null}
                      onChange={e => {
                        setFieldValue(`graduationDate`, e);
                      }}
                      utcOffset={0}
                      placeholderText="dd-mm-yyyy"
                      className={`form-control form-control-sm ${getErrorCssClassName(
                        touched,
                        errors,
                        "graduationDate"
                      )}`}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      disabled={readOnly}
                    />
                    <ErrorComponent
                      fieldName={`graduationDate`}
                      touched={touched}
                      error={errors}
                      datePicker
                    />
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 edit-page-label-required">
                    Notes
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <Field
                      component="textarea"
                      className={`form-control form-control-sm ${getErrorCssClassName(
                        touched,
                        errors,
                        "notes"
                      )}`}
                      name="notes"
                      value={values.notes || ""}
                      rows="4"
                      {...disabledField}
                    />
                    <ErrorComponent
                      fieldName={`notes`}
                      touched={touched}
                      error={errors}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 col-xl-6 p-3">
                <div className="col-md-12 edit-page-section-header-text">
                  Documents
                </div>

                <ul
                  className="list-group list-group-flush mt-2 mb-3"
                  style={{ minHeight: "135px" }}
                >
                  {availableDocuments.map((doc, index) => {
                    return (
                      <li
                        key={index}
                        className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                      >
                        <span>
                          <i className={`far fa-file fa-lg mr-2`} />
                          {doc.filename}
                        </span>
                        <div>
                          <i
                            className="fas fa-download fa-md mr-3"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              downloadDocument(
                                doc.link,
                                msg => window.alert(msg),
                                doc.filename
                              )
                            }
                          />
                        </div>
                      </li>
                    );
                  })}
                  {Array.from(values.documents).map((file, index) => {
                    return (
                      <li
                        key={index}
                        className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                      >
                        <span>
                          <i className={`far fa-file fa-lg mr-2`} />
                          {file.name}
                        </span>
                        <div>Upload Pending</div>
                      </li>
                    );
                  })}
                  {!hasDocuments ? (
                    <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                      No documents available
                    </li>
                  ) : null}
                </ul>
                {readOnly ? null : (
                  <div className="text-center">
                    <div className="upload-btn-wrapper">
                      <button
                        className="btn btn-sm btn-outline-info"
                        type="button"
                        {...disabledField}
                      >
                        <i className="fas fa-upload mr-2" />
                        Upload
                        <input
                          type="file"
                          name="documents"
                          multiple
                          onChange={event => {
                            setFieldValue(
                              "documents",
                              event.currentTarget.files
                            );
                          }}
                          {...disabledField}
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Contact Information */}
            <div className="row mt-3">
              <div className="col-12 col-lg-6 col-xl-6">
                <div className="col-md-12 edit-page-section-header-text mb-2">
                  New Phone
                </div>
                <FieldArray
                  name="phones"
                  render={({ insert, remove, push }) =>
                    Phones({
                      values,
                      insert,
                      remove,
                      push,
                      errors,
                      touched,
                      readOnly
                    })
                  }
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <div className="col-md-12 edit-page-section-header-text mb-2">
                  New Email
                </div>
                <FieldArray
                  name="emails"
                  render={({ insert, remove, push }) =>
                    Emails({
                      values,
                      insert,
                      remove,
                      push,
                      errors,
                      touched,
                      readOnly
                    })
                  }
                />
              </div>
            </div>

            {/* Address Information */}
            <div className="row mt-5 no-gutters">
              <div className="col-md-12 edit-page-section-header-text">
                Address
              </div>
              <div className="col-md-12 mt-2">
                <Addresses {...props} />
              </div>
            </div>
          </div>

          {values.actions.length > 0 ? (
            <div className="card-footer text-center pb-3">
              {values.actions.map((action, idx) => {
                return (
                  <Can do="UPDATE" on="GraduationRequest">
                    <Fragment key={idx}>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          setFieldValue("action", action);
                        }}
                      >
                        {action}
                      </button>
                      &nbsp;&nbsp;
                    </Fragment>
                  </Can>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const GraduationForm = props => {
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={props.initialValues}
        validationSchema={props.validationSchema}
        onSubmit={(values, { setSubmitting }) =>
          props.handleSubmit(values, { setSubmitting })
        }
        render={renderProps => (
          <Form>
            <IncubateeTitle {...props} {...renderProps} />
            <CurrentIncubateeInformation {...props} {...renderProps} />
            <CreateGraduationForm {...props} {...renderProps} />
          </Form>
        )}
      />
    </div>
  );
};

export default GraduationForm;

function replaceAll(txt, find, replace) {
  return txt.split(find).join(replace);
}

function checkEmptyAndAdd(list, data) {
  if (data) {
    let newData = replaceAll(data, "\r", "");
    newData = replaceAll(newData, "\n", "");
    list.push(newData);
  }
}

function splitIndianMobileFormate(mobileNumber) {
  let numberToString = mobileNumber.toString();
  if (numberToString.charAt(0) === "0") {
    return mobileNumber;
  }

  if (numberToString.length !== 10) {
    return mobileNumber;
  }

  let output = [];
  for (let i = 0; i < numberToString.length; i++) {
    if (i === 5) {
      output.push(" ");
    }
    output.push(numberToString.charAt(i));
  }
  return output.join("");
}

function addressBuilder(address) {
  const addressList = [];
  const addrLine123 = [];
  checkEmptyAndAdd(addrLine123, address.streetLine1);
  checkEmptyAndAdd(addrLine123, address.streetLine2);
  checkEmptyAndAdd(addrLine123, address.landmark);
  checkEmptyAndAdd(addressList, addrLine123.join(", "));

  const cityStatePostalCode = [];
  checkEmptyAndAdd(cityStatePostalCode, address.city);
  checkEmptyAndAdd(cityStatePostalCode, address.state);
  checkEmptyAndAdd(cityStatePostalCode, String(address.postalCode));

  checkEmptyAndAdd(addressList, cityStatePostalCode.join(", "));
  return addressList;
}

const ExistingContactInformation = props => {
  const incubatee = props.incubatee || {};
  let phones = [];
  let emails = [];
  let addresses = [];

  let incubateeType = incubatee.incubateeType;
  if (incubateeType && incubateeType === "INDIVIDUAL") {
    const individual = incubatee.individual;
    phones = individual.phones;
    emails = individual.emails;
    addresses = individual.addresses;
  }

  if (incubateeType && incubateeType === "COMPANY") {
    const organization = incubatee.organization;
    phones = organization.phones;
    emails = organization.emails;
    addresses = organization.addresses;
  }
  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="col-md-12 edit-page-section-header-text">
            Existing Contact Information
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-lg-4 col-xl-4 mt-2">
          <div className="card border-bottom-0">
            {phones.length > 0 ? (
              <Fragment>
                {phones.map((ph, index) => {
                  return (
                    <div key={index} className="row no-gutters border-bottom">
                      <div className="col-2 text-center align-self-center">
                        <i className="fas fa-phone fa-flip-horizontal text-secondary fa-lg" />
                      </div>
                      <div className="col-10 d-flex justify-content-between align-items-center border-left p-3">
                        <div className="text-wrap w-75">
                          {splitIndianMobileFormate(ph.phone)}
                        </div>
                        <span className="badge badge-primary badge-pill">
                          {`${PHONE_TYPES_LIST_OBJECT[ph.type] ||
                            "Unspecified"}`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <div className="row no-gutters border-bottom">
                <div className="col-2 text-center align-self-center">
                  <i className="fas fa-phone fa-flip-horizontal text-secondary fa-lg" />
                </div>
                <div className="col-10 d-flex justify-content-between align-items-center border-left p-3">
                  No Information
                </div>
              </div>
            )}

            {emails.length > 0 ? (
              <Fragment>
                {emails.map((em, index) => {
                  return (
                    <div key={index} className="row no-gutters border-bottom">
                      <div className="col-2 text-center align-self-center">
                        <i className="fas fa-envelope text-secondary fa-lg" />
                      </div>
                      <div className="col-10 d-flex justify-content-between align-items-center border-left p-3">
                        <div className="text-wrap w-75">{em.email}</div>
                        <span className="badge badge-primary badge-pill">
                          {`${EMAIL_TYPES_LIST_OBJECT[em.type] ||
                            "Unspecified"}`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <div className="row no-gutters border-bottom">
                <div className="col-2 text-center align-self-center">
                  <i className="fas fa-envelope text-secondary fa-lg" />
                </div>
                <div className="col-10 d-flex justify-content-between align-items-center border-left p-3">
                  No Information
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-12 col-lg-8 col-xl-8">
          <div className="row">
            {addresses.length > 0 ? (
              <Fragment>
                {addresses.map((addr, index) => {
                  const addressList = addressBuilder(addr);
                  return (
                    <div key={index} className="col-md-6 mt-2">
                      <div className="media border p-2">
                        <i className="fas fa-map-marker-alt text-secondary align-self-start mr-3 fa-lg mt-2" />
                        <div className="media-body">
                          <span className="text-muted">
                            {`${ADDRESS_TYPES_LIST_OBJECT[addr.type] ||
                              "Unspecified"}`}
                          </span>
                          <br />
                          {addressList.map((addr, idx) => (
                            <div className="row pb-1" key={idx}>
                              <div className="col-12">{addr}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <div className="col-md-6 mt-2">
                <div className="media border p-2">
                  <i className="fas fa-map-marker-alt text-secondary align-self-start mr-3 fa-lg mt-2" />
                  <div className="media-body">No Information</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const PromoterInformation = props => {
  const incubatee = props.incubatee || {};
  const incubateeType = incubatee.incubateeType;
  if (incubateeType && incubateeType === "INDIVIDUAL") {
    return null;
  }
  const promoters = props.promoters || [];

  return (
    <Fragment>
      <div className="row mt-1 mb-3">
        <div className="col-md-12">
          <div className="col-md-12 edit-page-section-header-text">
            Promoter Information
          </div>
        </div>
        <div className="col-md-12">
          <div className="table-responsive-sm table-responsive-md table-responsive-lg mt-2">
            <table className="table table-sm table-bordered mb-0">
              <thead>
                <tr>
                  <th
                    className="border-bottom-0"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                    scope="col"
                  >
                    Name
                  </th>
                  <th
                    className="border-bottom-0"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                    scope="col"
                  >
                    Designation
                  </th>
                  <th
                    className="border-bottom-0"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                    scope="col"
                  >
                    Email
                  </th>
                  <th
                    className="border-bottom-0"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                    scope="col"
                  >
                    Phone
                  </th>
                  <th
                    className="border-bottom-0"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                    scope="col"
                  >
                    Share Holding Percentage (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                {promoters.length > 0 ? (
                  <Fragment>
                    {promoters.map((promoter, index) => {
                      const individual = promoter.individual || {};
                      const names = [];
                      names.push(individual.firstName);
                      names.push(individual.lastName);

                      const email = individual.emails[0] || {};
                      const phone = individual.phones[0] || {};

                      return (
                        <tr key={index}>
                          <td>{names.join(" ")}</td>
                          <td>{promoter.designation || "--"}</td>
                          <td>{email.email || "--"}</td>
                          <td>{phone.phone || "--"}</td>
                          <td>{promoter.shareholding || "--"}</td>
                        </tr>
                      );
                    })}
                  </Fragment>
                ) : (
                  <tr>
                    <td>No Information</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
