import React, { Fragment } from "react";
import CompactTable from "../../widgets/CompactTable";
import {
  changeToINR,
  MANUAL_CHARGE_STATUS_LIST_OBJECT
} from "../../common/LookupConstants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";

function customerNameFormater(cell, row) {
  let names = [];
  let customer = row.customer;
  if (customer.firstName) {
    names.push(customer.firstName);
  }

  if (customer.lastName) {
    names.push(customer.lastName);
  }

  if (customer.name) {
    names.push(customer.name);
  }

  const name = names.join(" ");
  return name;
}
function dateFormater(yyyymmdd) {
  return getDisplayDate(convertNumberToDate(yyyymmdd));
}

const columnsCharges = [
  {
    dataField: "customer",
    text: "Customer Name",
    sort: true,
    formatter: customerNameFormater,
    filterValue: customerNameFormater,
    headerStyle: {
      width: "30%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "customerType",
    text: "Customer Type",
    sort: true,
    headerStyle: {
      width: "15%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "description",
    text: "Description",
    sort: true,
    headerStyle: {
      width: "30%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "chargeDate",
    text: "Charge Date",
    formatter: dateFormater,
    csvFormatter: dateFormater,
    sort: true,
    headerStyle: {
      width: "15%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "amount",
    text: "Amount",
    formatter: changeToINR,
    classes: "text-right",
    sort: true,
    headerStyle: {
      width: "10%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "text-center",
    formatter: cell => MANUAL_CHARGE_STATUS_LIST_OBJECT[cell],
    headerStyle: {
      width: "10%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  }
];

const ChargesSearchResult = props => {
  let charges = [];

  if (props.searchResults && props.searchResults.charges) {
    charges = props.searchResults.charges;
  }

  return (
    <Fragment>
      <h6 className="card-title mb-2 edit-page-section-header-text">Charges</h6>

      <CompactTable
        data={charges}
        columns={columnsCharges}
        noDataIndication={"No Financial Charges Available"}
      />
    </Fragment>
  );
};

export default ChargesSearchResult;
