import React, { Component } from "react";

class ImageViewer extends Component {
  render() {
    const { handlePreviewImageClose, imageUrl, imageName } = this.props;

    if (!imageUrl) {
      return null;
    }

    return (
      <div>
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.3)" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0 d-flex align-items-center">
                <h5 className="modal-title mx-auto">{imageName}</h5>

                <span
                  style={{ outline: "none", cursor: "pointer" }}
                  onClick={handlePreviewImageClose}
                >
                  <i className="fas fa-times fa-lg"></i>
                </span>
              </div>
              <div className="modal-body text-center">
                <img src={imageUrl} className="img-fluid" alt={imageName} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageViewer;
