import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { Formik, Form, Field, FieldArray } from "formik";
import AlertComponent from "../../common/AlertComponent";
import {
  GRANT_AWARDS_API,
  GRANT_FUNDING_CYCLE_API,
  INDIVIDUALS_API,
  ORGANIZATIONS_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import {
  getDisplayDate,
  convertDateToNumber,
  convertNumberToDate
} from "../../lease/DateUtil";
import { INCUBATION_SECTORS } from "../../common/IncubateeProfileConstants";
import {
  AWARD_STATUS,
  INCUBATEE_TYPES,
  INCUBATEE_TYPES_OBJECT
} from "../../common/LookupConstants";
import { downloadDocument } from "../../common/Util";
import { getFileIconByContentType } from "../../common/IconUtil";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  return getError(touch, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touch, error, datePicker } = props;
  let renderComponent = "";

  if (datePicker) {
    renderComponent = (
      <div className="auto-lookup-empty">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  } else {
    renderComponent = (
      <div className="invalid-feedback">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  }
  return renderComponent;
};

const DisbursedAmounts = props => {
  const { values, errors, touched, setFieldValue, readOnly } = props;
  const disbursedAmounts = values.disbursedAmounts || [];
  const disbursedAmountsErrors = errors.disbursedAmounts || [];
  const disbursedAmountsTouched = touched.disbursedAmounts || [];

  const disabledField = readOnly
    ? { readOnly: "readOnly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name={`disbursedAmounts`}
      render={({ remove, push }) => {
        return (
          <Fragment>
            <div className="col-12 col-md-12">
              <strong className="border-bottom pb-1 d-block mb-2">
                Disbursed Amounts
              </strong>
              {disbursedAmounts.length > 0 ? (
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th
                        className="display-table-header text-center"
                        scope="col"
                      >
                        Date
                      </th>
                      <th
                        className="display-table-header text-right"
                        scope="col"
                      >
                        Amount
                      </th>
                      <th className="display-table-header" scope="col">
                        Notes
                      </th>
                      <th className="display-table-header" scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {disbursedAmounts.map((damt, index) => {
                      const error = disbursedAmountsErrors[index] || {};
                      const touch = disbursedAmountsTouched[index] || {};

                      return (
                        <tr key={index}>
                          <td className="align-middle">
                            <DatePicker
                              value={damt.date ? getDisplayDate(damt.date) : ""}
                              selected={damt.date || null}
                              onChange={e => {
                                setFieldValue(
                                  `disbursedAmounts.${index}.date`,
                                  e
                                );
                              }}
                              utcOffset={0}
                              placeholderText="dd-mm-yyyy"
                              className={`form-control form-control-sm text-center${
                                touch["date"] && error["date"]
                                  ? "is-invalid"
                                  : ""
                              }`}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              disabled={readOnly}
                            />
                            <div className="auto-lookup-empty">
                              {touch["date"] && error["date"]}
                            </div>
                          </td>
                          <td className="align-middle">
                            <div>
                              <Field
                                type="number"
                                className={`form-control form-control-sm text-right${
                                  touch["amount"] && error["amount"]
                                    ? "is-invalid"
                                    : ""
                                }`}
                                title="Enter Amount"
                                name={`disbursedAmounts.${index}.amount`}
                                value={damt.amount || ""}
                                placeholder="Enter amount"
                                {...disabledField}
                              />
                              <div className="invalid-feedback">
                                {touch["amount"] && error["amount"]}
                              </div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <div>
                              <Field
                                component="textarea"
                                type="text"
                                className={`form-control form-control-sm ${
                                  touch["notes"] && error["notes"]
                                    ? "is-invalid"
                                    : ""
                                }`}
                                title="Enter Amount"
                                name={`disbursedAmounts.${index}.notes`}
                                value={damt.notes || ""}
                                placeholder="Enter notes"
                                {...disabledField}
                              />
                              <div className="invalid-feedback">
                                {touch["notes"] && error["notes"]}
                              </div>
                            </div>
                          </td>
                          {readOnly ? null : (
                            <td className="align-middle text-center">
                              <i
                                className="far fa-trash-alt text-danger"
                                title="Remove"
                                onClick={() => remove(index)}
                                style={{
                                  cursor: "pointer"
                                }}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : null}

              {readOnly ? null : (
                <div className="row">
                  <div className="col-12">
                    <button
                      className="btn btn-sm btn-outline-info custom-btn"
                      type="button"
                      onClick={() =>
                        push({
                          notes: "",
                          amount: "",
                          date: null
                        })
                      }
                    >
                      <i className="fas fa-plus mr-2" />
                      Add More
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        );
      }}
    />
  );
};

const AwardForm = props => {
  const {
    values,
    isSubmitting,
    setFieldValue,
    touched,
    errors,
    availableDocuments,
    granteeList,
    fundingCycleList,
    previousLocation
  } = props;
  const hasDocuments =
    (availableDocuments || []).length > 0 ||
    (values.documents || []).length > 0;

  const readOnly = values._id ? true : false;

  const disabledField = readOnly
    ? { readOnly: "readOnly", disabled: "disabled" }
    : {};

  return (
    <Form>
      <div className="row mb-5">
        <div className="col-md-12">
          <div className="shadow card flex-fill box-border box-light-blue">
            <div className="card-header border-0 text-center pb-0">
              <span className="float-left">
                <Link to={previousLocation} className="text-light-blue">
                  <i className="fas fa-arrow-left" />
                </Link>
              </span>
              <h6 className="mb-0 d-inline-block text-light-blue">
                <strong>{values.formTitle}</strong>
              </h6>
            </div>

            <div className="card-body pt-2">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="card border-0 h-100">
                    <div className="card-header border-0 pb-0">
                      <strong className="border-bottom pb-1 d-block text-muted">
                        Award Information
                      </strong>
                    </div>
                    <div className="card-body pt-2">
                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Project Title
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "projectTitle"
                            )}`}
                            name="projectTitle"
                            {...disabledField}
                          />
                          <ErrorComponent
                            fieldName="projectTitle"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Grant Award Id
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "grantAwardId"
                            )}`}
                            name="grantAwardId"
                            {...disabledField}
                          />
                          <ErrorComponent
                            fieldName="grantAwardId"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Grant Name
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            component="select"
                            type="text"
                            className={`custom-select custom-select-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "grantRef"
                            )}`}
                            name="grantRef"
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {fundingCycleList.map((fund, i) => (
                              <option key={i} value={fund._id}>
                                {`${fund.grantProgram.name} - ${fund.grantId}`}
                              </option>
                            ))}
                          </Field>
                          <ErrorComponent
                            fieldName="grantRef"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Grantee Type
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            component="select"
                            type="text"
                            className={`custom-select custom-select-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "granteeType"
                            )}`}
                            name="granteeType"
                            onChange={e => {
                              setFieldValue("granteeType", e.target.value);
                              props.getGrantees(e.target.value, true);
                            }}
                            {...disabledField}
                          >
                            <option value="">Select...</option>

                            {INCUBATEE_TYPES.map((opt, i) => (
                              <option key={`${i}`} value={opt.key}>
                                {opt.value}
                              </option>
                            ))}
                          </Field>
                          <ErrorComponent
                            fieldName="granteeType"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Grantee
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            component="select"
                            type="text"
                            className={`custom-select custom-select-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "grantee"
                            )}`}
                            name="grantee"
                            value={values.grantee || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {granteeList.map((grantee, i) => {
                              let names = [];
                              if (values.granteeType === "INDIVIDUAL") {
                                names.push(grantee.firstName);
                                names.push(grantee.lastName);
                              } else if (values.granteeType === "COMPANY") {
                                names.push(grantee.name);
                              }
                              let name = names.join(" ");
                              return (
                                <option key={i} value={grantee._id}>
                                  {name}
                                </option>
                              );
                            })}
                          </Field>
                          <ErrorComponent
                            fieldName="grantee"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Vertical
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            component="select"
                            type="text"
                            className={`custom-select custom-select-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "vertical"
                            )}`}
                            name="vertical"
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {INCUBATION_SECTORS.map((opt, i) => (
                              <option key={`${i}`} value={opt.key}>
                                {opt.value}
                              </option>
                            ))}
                          </Field>
                          <ErrorComponent
                            fieldName="vertical"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="card border-0 h-100">
                    <div className="card-header border-0 pb-0">
                      <strong className="border-bottom pb-1 d-block text-muted">
                        Planning
                      </strong>
                    </div>
                    <div className="card-body pt-2">
                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Grant Start Date
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <DatePicker
                            value={
                              values.startDate
                                ? getDisplayDate(values.startDate)
                                : ""
                            }
                            selected={values.startDate}
                            onChange={e => {
                              setFieldValue(`startDate`, e);
                            }}
                            utcOffset={0}
                            placeholderText="dd-mm-yyyy"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "startDate"
                            )}`}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            disabled={readOnly}
                          />
                          <ErrorComponent
                            fieldName="startDate"
                            touch={touched}
                            error={errors}
                            datePicker={true}
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Target End Date
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <DatePicker
                            value={
                              values.endDate
                                ? getDisplayDate(values.endDate)
                                : ""
                            }
                            selected={values.endDate}
                            onChange={e => {
                              setFieldValue(`endDate`, e);
                            }}
                            utcOffset={0}
                            placeholderText="dd-mm-yyyy"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "endDate"
                            )}`}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            disabled={readOnly}
                          />
                          <ErrorComponent
                            fieldName="endDate"
                            touch={touched}
                            error={errors}
                            datePicker={true}
                          />
                        </div>
                      </div>

                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Sanctioned Amount
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            type="number"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "sanctionedAmount"
                            )}`}
                            name="sanctionedAmount"
                            {...disabledField}
                          />
                          <ErrorComponent
                            fieldName="sanctionedAmount"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div>

                      {/* <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Disbursement Amt
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            type="number"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "amountDisburesd"
                            )}`}
                            name="amountDisburesd"
                          />
                          <ErrorComponent
                            fieldName="amountDisburesd"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div> */}

                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm col-12 col-lg-5 col-xl-5">
                          Status
                        </label>
                        <div className="col-12 col-lg-7 col-xl-7">
                          <Field
                            component="select"
                            type="text"
                            className={`custom-select custom-select-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "status"
                            )}`}
                            name="status"
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {AWARD_STATUS.map((opt, i) => (
                              <option key={`${i}`} value={opt.key}>
                                {opt.value}
                              </option>
                            ))}
                          </Field>
                          <ErrorComponent
                            fieldName="status"
                            touch={touched}
                            error={errors}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="card border-0 h-100">
                    <div className="card-header border-0 pb-0">
                      <strong className="border-bottom pb-1 d-block">
                        Notes
                      </strong>
                    </div>
                    <div className="card-body pt-2">
                      <Field
                        component="textarea"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "notes"
                        )}`}
                        name="notes"
                        rows="6"
                        {...disabledField}
                      />
                      <ErrorComponent
                        fieldName="notes"
                        touch={touched}
                        error={errors}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="card border-0 h-100">
                    <div className="card-header border-0 pb-0">
                      <strong className="border-bottom pb-1 d-block">
                        Documents
                      </strong>
                    </div>
                    <div className="card-body pt-2">
                      <ul
                        className="list-group list-group-flush mb-3"
                        style={{ minHeight: "88px" }}
                      >
                        {availableDocuments.map((doc, index) => {
                          return (
                            <li
                              key={index}
                              className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                            >
                              <span>
                                <i
                                  className={`${getFileIconByContentType(
                                    doc.contentType
                                  )} mr-2`}
                                />
                                {doc.filename}
                              </span>
                              <div>
                                <i
                                  className="fas fa-download fa-md mr-3"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    downloadDocument(
                                      doc.link,
                                      msg => window.alert(msg),
                                      doc.filename
                                    )
                                  }
                                />
                              </div>
                            </li>
                          );
                        })}
                        {Array.from(values.documents).map((file, index) => {
                          return (
                            <li
                              key={index}
                              className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                            >
                              <span>
                                <i
                                  className={`${getFileIconByContentType(
                                    file.type
                                  )} mr-2`}
                                />
                                {file.name}
                              </span>
                              <div>Upload Pending</div>
                            </li>
                          );
                        })}
                        {!hasDocuments ? (
                          <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                            No documents available
                          </li>
                        ) : null}
                      </ul>
                      <div className="text-center">
                        {readOnly ? null : (
                          <div className="upload-btn-wrapper">
                            <button className="btn btn-sm btn-outline-info custom-btn">
                              <i className="fas fa-file-upload mr-2" />
                              Upload
                              <input
                                type="file"
                                name="documents"
                                multiple
                                onChange={event => {
                                  setFieldValue(
                                    "documents",
                                    event.currentTarget.files
                                  );
                                }}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <DisbursedAmounts {...props} readOnly={readOnly} />
              </div>

              {readOnly ? null : (
                <div className="row mb-2 mt-2">
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn-sm btn-primary"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const AWARD_SCHEMA = Yup.object().shape({
  projectTitle: Yup.string()
    .label("Projec Title")
    .required(),
  grantAwardId: Yup.string()
    .label("Grant Award Id")
    .optional()
    .nullable(),
  grantRef: Yup.string()
    .label("Grant Name")
    .required(),
  granteeType: Yup.string()
    .label("Grant Type")
    .required(),
  grantee: Yup.string()
    .label("Grantee")
    .required(),
  vertical: Yup.string()
    .label("Vertical")
    .required(),
  startDate: Yup.date()
    .label("Start Date")
    .typeError(({ label }) => `${label} is a required field`)
    .required(),
  endDate: Yup.date()
    .label("End Date")
    .typeError(({ label }) => `${label} is a required field`)
    .required(),

  sanctionedAmount: Yup.number()
    .label("Sanctioned Amount")
    .required(),

  disbursedAmounts: Yup.array()
    .of(
      Yup.object().shape({
        notes: Yup.string()
          .label("Notes")
          .required(),
        amount: Yup.number()
          .label("Amount")
          .typeError("Amount is required field.")
          .min(0, "Min value is 0.")
          .nullable()
          .required(),
        date: Yup.date()
          .label("Date")
          .typeError("Date is required field.")
          .nullable()
          .required()
      })
    )
    .label("Disbursed Amounts")
    .optional(),

  status: Yup.string()
    .label("Status")
    .required()
});

class GrantAwardDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      award: {
        _id: "",
        applicationFormId: "",
        grantAwardId: "",
        projectTitle: "",
        grantRef: "",
        granteeType: "",
        grantee: "",
        vertical: "",
        sanctionedAmount: "",
        amountDisburesd: "",
        disbursedAmounts: [],
        startDate: null,
        endDate: null,
        revisedEndDate: "",
        status: "",
        notes: [],
        reviews: [],
        documents: [],

        // extras
        readOnly: false,
        formTitle: ""
      },
      editAward: false,
      editAwardId: null,
      availableDocuments: [],
      fundingCycleList: [],
      granteeList: [],

      previousLocation: "/funding/awards"
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getRequest = values => {
    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "documents", docs[i]);
    }

    let startDate = new Date(values.startDate);
    startDate = convertDateToNumber(startDate);

    let endDate = new Date(values.endDate);
    endDate = convertDateToNumber(endDate);

    let disbursedAmounts = [];
    if (values.disbursedAmounts.length > 0) {
      disbursedAmounts = values.disbursedAmounts.map(dAmt => {
        let date = new Date(dAmt.date);
        date = convertDateToNumber(date);
        return {
          notes: dAmt.notes,
          amount: dAmt.amount,
          date
        };
      });
    }

    const request = {
      applicationFormId: values.applicationFormId || null,
      grantAwardId: values.grantAwardId || undefined,
      projectTitle: values.projectTitle,
      grantRef: values.grantRef,
      granteeType: INCUBATEE_TYPES_OBJECT[values.granteeType],
      grantee: values.grantee,
      vertical: values.vertical,
      sanctionedAmount: values.sanctionedAmount,
      disbursedAmounts: disbursedAmounts,
      startDate: startDate,
      endDate: endDate,
      revisedEndDate: endDate,
      status: values.status,
      notes: [{ remark: values.notes }],
      reviews: values.reviews
    };

    formData.append("message", JSON.stringify(request));
    return formData;
  };

  getAward = id => {
    if (this.props.location.state && this.props.location.state.created) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: `Grant Award has been created successfully.`
      });
      window.scrollTo(0, 0);
    }

    const url = `${GRANT_AWARDS_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        const award = { ...this.state.award };

        const TYPES = {
          Individual: "INDIVIDUAL",
          Organization: "COMPANY"
        };

        let disbursedAmounts = [];

        if (data.disbursedAmounts.length > 0) {
          disbursedAmounts = data.disbursedAmounts.map(dAmt => {
            const date = convertNumberToDate(dAmt.date);
            return {
              notes: dAmt.notes,
              amount: dAmt.amount,
              date: date
            };
          });
        }

        award._id = data._id || "";
        award.applicationFormId = data.applicationFormId || null;
        award.grantAwardId = data.grantAwardId || null;
        award.projectTitle = data.projectTitle || "";
        award.grantRef = data.grantRef._id || data.grantRef || "";
        award.granteeType = TYPES[data.granteeType] || "";
        award.grantee = data.grantee._id || data.grantee || "";
        award.vertical = data.vertical || "";
        award.sanctionedAmount = data.sanctionedAmount || "";
        award.amountDisburesd = data.amountDisburesd || "";
        award.disbursedAmounts = disbursedAmounts || [];
        award.startDate = convertNumberToDate(data.startDate) || null;
        award.endDate = convertNumberToDate(data.endDate) || null;
        award.revisedEndDate = convertNumberToDate(data.revisedEndDate) || null;
        award.status = data.status || "";
        award.notes = data.notes && data.notes[0] ? data.notes[0].remark : "";
        award.reviews = data.reviews || "";
        award.formTitle = `${data.projectTitle}`;
        const availableDocuments = data.documents || [];
        this.setState({ award, availableDocuments });
        this.getGrantees(TYPES[data.granteeType]);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  createAward = (values, setSubmitting) => {
    setSubmitting(true);
    const createRequest = this.getRequest(values);

    const url = `${GRANT_AWARDS_API}`;

    HttpUtil.post(
      url,
      {},
      createRequest,
      data => {
        setSubmitting(false);
        this.setState({
          editAward: true,
          editAwardId: data._id
        });
      },
      (data, status) => {
        this.handleApiFailed(data.message);
        setSubmitting(false);
      },
      error => {
        this.handleApiFailed(error.toString());
        setSubmitting(false);
      }
    );
  };

  updateAward = (values, setSubmitting) => {
    setSubmitting(true);

    const updateRequest = this.getRequest(values);

    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}`;

    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        setSubmitting(false);
        this.getAward(data._id);
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Grant Award for project "${
            data.projectTitle
          }" has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSubmit = (values, { setSubmitting }) => {
    const award = this.state.award;
    if (award._id) {
      this.updateAward(values, setSubmitting);
    } else {
      this.createAward(values, setSubmitting);
    }
  };

  getAllFundCycles = newAward => {
    HttpUtil.get(
      GRANT_FUNDING_CYCLE_API,
      {},
      data => {
        const fundingCycleList = newAward
          ? data.filter(cycle => cycle.status === "OPENED")
          : data;
        this.setState({ fundingCycleList });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getGrantees = (granteeType, ongoingOnly) => {
    let url = "";
    if (granteeType === "INDIVIDUAL") {
      url = INDIVIDUALS_API;
    } else if (granteeType === "COMPANY") {
      url = ORGANIZATIONS_API;
    }

    if (ongoingOnly) {
      url = `${url}/lookup/active?incubateeStatus=ONGOING`;
    }

    HttpUtil.get(
      url,
      {},
      data => this.setState({ granteeList: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getIncubatee = (previousLocation, url, type, appFormId) => {
    if (url === null) {
      return;
    }

    HttpUtil.get(
      url,
      {},
      data => {
        const award = { ...this.state.award };

        const TYPES = {
          Individual: "INDIVIDUAL",
          Organization: "COMPANY"
        };

        award.incubatee = data._id || "";
        award.granteeType = TYPES[type] || "";
        award.grantee = data._id || "";
        award.applicationFormId = appFormId;
        this.setState({ award, previousLocation });
        this.getGrantees(TYPES[type]);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    let newAward = true;
    const { match } = this.props;
    if (match && match.params && match.params.id) {
      newAward = false;
      const id = match.params.id;
      this.getAward(id);
    } else {
      if (this.props.location.search !== "") {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const incubateeId = params.get("incubatee");
        const type = params.get("type");
        const appFormId = params.get("appFormId");
        const from = params.get("from");
        let previousLocation = "";
        if (from) {
          previousLocation = from;
        }

        let url = null;
        if (type === "Individual") {
          url = `${INDIVIDUALS_API}/${incubateeId}`;
        }

        if (type === "Organization") {
          url = `${ORGANIZATIONS_API}/${incubateeId}`;
        }

        this.getIncubatee(previousLocation, url, type, appFormId);
      }

      const award = { ...this.state.award };
      award.formTitle = "New Award Form";
      this.setState({ award });
    }

    this.getAllFundCycles(newAward);
  }

  render() {
    const { editAward, editAwardId } = this.state;
    if (editAward) {
      const stateObj = {
        pathname: `/funding/awards/edit/${editAwardId}`,
        state: { created: true }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const props = {
      initialValues: this.state.award,
      validationSchema: AWARD_SCHEMA,
      handleSubmit: this.handleSubmit,
      getGrantees: this.getGrantees
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={props.initialValues}
          validationSchema={props.validationSchema}
          onSubmit={(values, { setSubmitting }) =>
            props.handleSubmit(values, { setSubmitting })
          }
          render={({
            values,
            isSubmitting,
            errors,
            touched,
            setFieldValue
          }) => {
            const formProps = {
              values,
              isSubmitting,
              errors,
              touched,
              setFieldValue,
              getGrantees: props.getGrantees,
              granteeList: this.state.granteeList,
              fundingCycleList: this.state.fundingCycleList,

              availableDocuments: this.state.availableDocuments,
              previousLocation: this.state.previousLocation
            };
            return <AwardForm {...formProps} />;
          }}
        />
      </Fragment>
    );
  }
}

export default GrantAwardDetail;
