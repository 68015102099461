import React, { useMemo } from "react";
import { Fragment } from "react";
import {
  useTable,
  usePagination,
  useGroupBy,
  useExpanded,
  useGlobalFilter,
  useSortBy
} from "react-table";

import Pagination from "./Pagination";

function PageHeader({
  headerName,
  exportToExcel,
  showSearchbar,
  setGlobalFilter
}) {
  return (
    <Fragment>
      <div className="table-list-page-header mb-3 mt-1 align-items-center">
        <div className="table-list-page-header-title pl-4 pr-5">
          {headerName}
        </div>
        <div className="table-list-page-header-actions pr-2">
          <div className="float-right">
            <button
              className="btn btn-outline-info btn-sm mr-2"
              onClick={exportToExcel}
            >
              Export
            </button>
          </div>
        </div>

        {showSearchbar ? (
          <div className="table-list-page-header-search">
            <div className="input-group">
              <input
                className="form-control form-control-sm rounded-pill border-0"
                type="text"
                placeholder=" Search..."
                name="searchText"
                style={{
                  backgroundColor: "rgba(100, 122, 203, 0.17)"
                }}
                onChange={e => {
                  setGlobalFilter(e.target.value || undefined);
                }}
              />
              <span
                className="input-group-append "
                style={{
                  backgroundColor: "rgba(100, 122, 203, 0.17)"
                }}
              >
                <div className="input-group-text border-0 ml-n5 bg-transparent">
                  <i
                    className="fa fa-search"
                    style={{ color: "rgb(157, 156, 156)" }}
                  />
                </div>
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}

function Table({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  totalColumns,
  loading,
  data,
  page,
  noDataMessage,
  prepareRow
}) {
  return (
    <table {...getTableProps()} className="table table-list">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="table-list-header text-white"
          >
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={column.headerClassName}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {loading ? (
          <tr>
            <td colSpan={totalColumns} className="px-6 py-4">
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="progress-bar-loading-text">Loading...</div>
                  <div className="progress-bar">
                    <div className="indeterminate" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ) : data.length === 0 ? (
          <tr>
            <td colSpan={totalColumns} className="text-center px-6 py-4">
              {noDataMessage}
            </td>
          </tr>
        ) : page.length === 0 ? (
          <tr>
            <td colSpan="5" className="px-6 py-4">
              No matched records found
            </td>
          </tr>
        ) : null}

        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={i}>
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps({
                      className: cell.column.cellClassName
                    })}
                  >
                    {cell.isGrouped ? (
                      <>
                        <span
                          {...row.getToggleRowExpandedProps()}
                          className="mr-1"
                        >
                          {row.isExpanded ? (
                            <i className="fas fa-caret-down fa-lg" />
                          ) : (
                            <i className="fas fa-caret-right fa-lg" />
                          )}
                        </span>{" "}
                        {cell.render("Cell")} ({row.subRows.length})
                      </>
                    ) : cell.isAggregated ? (
                      cell.render("Aggregated")
                    ) : cell.isPlaceholder ? null : (
                      cell.render("Cell")
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function GroupedTableList({
  columns,
  data,
  groupByHeader,
  loading,
  exportToExcel,
  noDataMessage,
  sizePerPage,
  headerName,
  showSearchbar,
  sortByHeader,
  hiddenColumns = [],
  footerComponent
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: sizePerPage,
        hiddenColumns,
        groupBy: useMemo(() => groupByHeader, []),
        sortBy: useMemo(() => sortByHeader, [])
      }
    },
    useGlobalFilter,

    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="card table-list-box-shadow">
          <div className="card-body pl-0 pr-0">
            <PageHeader
              headerName={headerName}
              exportToExcel={exportToExcel}
              showSearchbar={showSearchbar}
              setGlobalFilter={setGlobalFilter}
            />

            <div
              className="table-responsive-xs table-responsive-sm react-bootstrap-table"
              {...getTableProps()}
            >
              <Table
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                getTableBodyProps={getTableBodyProps}
                totalColumns={columns.length}
                loading={loading}
                data={data}
                page={page}
                noDataMessage={noDataMessage}
                prepareRow={prepareRow}
              />

              {footerComponent ? footerComponent : null}

              {/* pagination */}
              <Pagination
                totalRecords={rows.length}
                pageCount={pageCount}
                pageSize={pageSize}
                pageIndex={pageIndex}
                currentPageRecordCount={page.length}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                setPageSize={setPageSize}
                previousPage={previousPage}
                nextPage={nextPage}
                gotoPage={gotoPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupedTableList;
