const DEFAULT_FONT_SIZE = "fa-lg";

const CONTENT_TYPE_FILE_ICONS = {
  unknown: "far fa-file",

  // Images
  "image/gif": "far fa-file-image text-primary",
  "image/jpeg": "far fa-file-image text-primary",
  "image/png": "far fa-file-image text-primary",
  "image/svg+xml": "far fa-file-image text-primary",
  "image/webp": "far fa-file-image text-primary",
  "image/x-icon": "far fa-file-image text-primary",

  // PDF
  "application/pdf": "far fa-file-pdf text-danger",

  // MS Powerpoint
  "application/vnd.ms-powerpoint": "far fa-file-powerpoint text-primary",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "far fa-file-powerpoint text-primary",
  "application/vnd.openxmlformats-officedocument.presentationml.template":
    "far fa-file-powerpoint text-primary",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    "far fa-file-powerpoint text-primary",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12":
    "far fa-file-powerpoint text-primary",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12":
    "far fa-file-powerpoint text-primary",
  "application/vnd.ms-powerpoint.template.macroEnabled.12":
    "far fa-file-powerpoint text-primary",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12":
    "far fa-file-powerpoint text-primary",

  // MS Word
  "application/msword": "far fa-file-word text-primary",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "far fa-file-word text-primary",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
    "far fa-file-word text-primary",
  "application/vnd.ms-word.document.macroEnabled.12":
    "far fa-file-word text-primary",
  "application/vnd.ms-word.template.macroEnabled.12":
    "far fa-file-word text-primary",

  // MS Excel
  "application/vnd.ms-excel": "far fa-file-excel text-primary",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    "far fa-file-excel text-primary",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
    "far fa-file-excel text-primary",
  "application/vnd.ms-excel.sheet.macroEnabled.12":
    "far fa-file-excel text-primary",
  "application/vnd.ms-excel.template.macroEnabled.12":
    "far fa-file-excel text-primary",
  "application/vnd.ms-excel.addin.macroEnabled.12":
    "far fa-file-excel text-primary",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12":
    "far fa-file-excel text-primary"
};

function getFileIconByContentType(contentType, fontSize) {
  return `${CONTENT_TYPE_FILE_ICONS[contentType] ||
    CONTENT_TYPE_FILE_ICONS.unknown} ${fontSize || DEFAULT_FONT_SIZE}`;
}

export { getFileIconByContentType };
