function generateFinancialYears(howFar) {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let index = 0; index < howFar; index++) {
    const fy = `${currentYear - index}-${currentYear - index - 1}`;
    years.push(fy);
  }
  return years;
}

const FinancialInfoSchema = {
  title: "Financial Info",
  description: "Financial  Information about the compnay",
  type: "object",
  properties: {
    authorizedCapital: {
      type: "number",
      title: "Authorized Capital"
    },
    annualTurnover: {
      type: "number",
      title: "Annual Turnover"
    },
    financialYear: {
      type: "string",
      title: "Annual Turnover FY",
      enum: generateFinancialYears(5)
    }
  }
};

const FinancialInfoUiSchema = {};

export { FinancialInfoSchema, FinancialInfoUiSchema };
