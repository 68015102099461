import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableList from "../../common/TableList";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { FUND_AGENCY_API, STATES_API } from "../../common/Constants";
import { FUNDING_AGENCY_TYPES_OBJECT } from "../../common/LookupConstants";

function actionsFormater(id) {
  if (!id) return null;
  return (
    <>
      <Link
        to={`/funding/setup/agencies/info/${id}`}
        role="button"
        datatoggle="tooltip"
        title="View"
      >
        <i className="fas fa-eye fa-lg action-icon" />
      </Link>
      <Link
        to={`/funding/agencies/edit/${id}`}
        role="button"
        datatoggle="tooltip"
        title="Edit"
      >
        <i className="ml-2 fas fa-edit fa-lg action-icon" />
      </Link>
    </>
  );
}

function getColumnsAgency(states) {
  if (!states) {
    return;
  }
  return [
    {
      dataField: "name",
      text: "Agency Name",
      sort: true,
      headerStyle: { width: "20%" }
    },

    {
      dataField: "agencyType",
      text: "Agency Type",
      sort: true,
      formatter: val => FUNDING_AGENCY_TYPES_OBJECT[val] || val || "--",
      csvFormatter: val => FUNDING_AGENCY_TYPES_OBJECT[val] || val || "--",
      filterValue: val => FUNDING_AGENCY_TYPES_OBJECT[val] || val || "--",
      headerStyle: { width: "15%" }
    },
    {
      dataField: "state",
      text: "State (for agencies)",
      sort: true,
      formatter: val => states[val] || val || "--",
      csvFormatter: val => states[val] || val || "--",
      filterValue: val => states[val] || val || "--",
      headerStyle: { width: "20%" }
    },
    // {
    //   dataField: "country",
    //   text: "Country",
    //   csvFormatter: country => country || "",
    //   sort: true,
    //   headerStyle: { width: "10%" }
    // },

    {
      dataField: "fullName",
      text: "Agency Full Name",
      csvFormatter: fullName => fullName || "",
      sort: true,
      headerStyle: { width: "30%" }
    },
    {
      dataField: "_id",
      text: "Actions",
      formatter: actionsFormater,
      sort: false,
      headerStyle: { width: "30%" }
    }
  ];
}

class FundingAgencyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      states: null,
      agencyList: null
    };
  }

  getAll = () => {
    const url = FUND_AGENCY_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ agencyList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getStates = () => {
    HttpUtil.get(
      STATES_API,
      {},
      data => {
        const states = {};
        data.forEach(st => (states[st.stateCode] = st.stateName));
        this.setState({ states });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getStates();
    this.getAll();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={getColumnsAgency(this.state.states)}
          data={
            this.state.agencyList && this.state.states
              ? this.state.agencyList
              : null
          }
          noDataIndication="No Agency found."
          sizePerPage="10"
          headerName="Funding Agencies"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default FundingAgencyList;

const RenderCustomButton = props => {
  return (
    <Link
      to="/funding/agencies/new"
      role="button"
      className="btn btn-sm text-white bg-pink"
    >
      New
    </Link>
  );
};
