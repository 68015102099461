import React, { Component } from "react";
import AlertComponent from "../common/AlertComponent";
import AvailablityForm from "./AvailablityForm";
import { RENTAL_REQUEST_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import Redirect from "react-router-dom/Redirect";

class CheckAssetAvailablity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetInformation: null,
      basicInfo: {},
      assetsToLease: [],
      selectedAssets: {},

      assetLeaseForm: false,
      assetLeaseFormId: null
    };
    /**
     * selectedAssets: { "Laptop": [ undefined, false, true ] }
     */
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  handleSubmit = (values, setSubmitting) => {
    console.log("handleSubmit:values:", values);
  };

  setDataFromServer = data => {
    const incubateeIndicator = data.incubateeIndicator;
    const basicInfo = this.state.basicInfo;
    if (incubateeIndicator) {
      const incubatee = data.incubatee;
      const incubateeType = incubatee.incubateeType;
      basicInfo.requestorType = incubateeType;
      basicInfo.name = incubatee.name;
      basicInfo.status = data.status;
    } else {
      basicInfo.requestorType = data.requestorType;
      basicInfo.name = data.name;
      basicInfo.status = data.status;
    }
    this.setState({ assetInformation: data, basicInfo });
  };

  getRentalRequests = id => {
    let url = `${RENTAL_REQUEST_API}/${id}/asset-availability`;

    HttpUtil.get(
      url,
      {},
      data => {
        this.setDataFromServer(data);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSelectAll = (index, e) => {
    const selected = e.target.checked;
    this.setState(prevState => {
      const assetRequest = prevState.assetInformation.equipments[index];
      const checkedAssetTypes = [];
      assetRequest.availabileAssets.forEach((value, assetIndex) => {
        checkedAssetTypes[assetIndex] = selected;
      });

      const assetType = assetRequest.equipment;
      const selectedAssets = prevState.selectedAssets;
      selectedAssets[assetType] = checkedAssetTypes;
      return { selectedAssets };
    });
  };

  handleSelect = (index, assetIndex, assetType, asset, e) => {
    const selected = e.target.checked;
    this.setState(prevState => {
      const selectedAssets = prevState.selectedAssets;
      selectedAssets[assetType] = selectedAssets[assetType] || [];
      selectedAssets[assetType][assetIndex] = selected;
      return { selectedAssets };
    });
  };

  addSelectedRecords = () => {
    this.setState(prevState => {
      let { selectedAssets, assetInformation, assetsToLease } = prevState;

      assetInformation.equipments.forEach(assetRequest => {
        const assetType = assetRequest.equipment;
        if (!selectedAssets[assetType]) {
          return;
        }

        const notSelectedAssets = [];
        assetRequest.availabileAssets.forEach((asset, index) => {
          if (selectedAssets[assetType][index]) {
            assetsToLease.push({ assetType, asset });
          } else {
            notSelectedAssets.push(asset);
          }
        });
        assetRequest.availabileAssets = notSelectedAssets;
      });
      selectedAssets = {};
      return { selectedAssets, assetInformation, assetsToLease };
    });
  };

  removeSelectedRecords = assetIndex => {
    this.setState(prevState => {
      let { assetInformation, assetsToLease } = prevState;

      const leaseAsset = assetsToLease[assetIndex];
      let equipments = assetInformation.equipments;

      for (let i = 0; i < equipments.length; i++) {
        let assetRequest = equipments[i];
        if (assetRequest.equipment === leaseAsset.assetType) {
          assetRequest.availabileAssets.push(leaseAsset.asset);
          break;
        }
      }

      assetsToLease.splice(assetIndex, 1);

      return { assetInformation, assetsToLease };
    });
  };

  getLeaseRequest = () => {
    const assetsToLease = [...this.state.assetsToLease];
    const leasedAssets = assetsToLease.map(atl => {
      return {
        asset: atl.asset._id,
        startDate: "",
        endDate: "",
        advance: ""
      };
    });

    const assetRequest = {
      leasedAssets
    };

    return assetRequest;
  };

  createAssetLease = () => {
    if (this.props.match === undefined) {
      return;
    }

    const id = this.props.match.params.id;
    const url = `${RENTAL_REQUEST_API}/${id}/asset-lease`;
    const headers = { "Content-Type": "application/json" };

    const assetLeaseRequest = this.getLeaseRequest();

    HttpUtil.post(
      url,
      headers,
      assetLeaseRequest,
      data => {
        console.log("Asset Lease Created:data:", data);
        this.setState({
          assetLeaseForm: true,
          assetLeaseFormId: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getRentalRequests(id);
    }
  }

  render() {
    if (this.state.assetInformation === null) {
      return null;
    }

    if (this.state.assetLeaseForm) {
      const stateObj = {
        pathname: `/asset/lease/${this.state.assetLeaseFormId}`,
        state: { assetLeaseCreated: true }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const props = {
      values: this.state.assetInformation,
      basicInfo: this.state.basicInfo,
      assetsToLease: this.state.assetsToLease,
      selectedAssets: this.state.selectedAssets,

      // events
      handleApiFailed: this.handleApiFailed,
      handleSelectAll: this.handleSelectAll,
      handleSelect: this.handleSelect,
      handleSubmit: this.handleSubmit,
      addSelectedRecords: this.addSelectedRecords,
      removeSelectedRecords: this.removeSelectedRecords
    };
    return (
      <div>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <AvailablityForm {...props} />

        {this.state.assetInformation.status === "PENDING_RENTAL" ? (
          <div className="row mb-5 mt-5">
            <div className="col-md-12 text-center">
              {this.state.assetsToLease.length > 0 ? (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.createAssetLease}
                >
                  Create Asset Lease
                </button>
              ) : (
                <button className="btn btn-sm btn-primary" disabled>
                  Create Asset Lease
                </button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CheckAssetAvailablity;
