import React from "react";

function getErrorDescription(error, schema) {
  let propName;
  const property = error.property;
  try {
    const propHierarchy = property.split(".").slice(1);
    let propSchema = schema;
    for (let index = 0; index < propHierarchy.length; index++) {
      const propName = propHierarchy[index];

      if (propSchema.properties && propSchema.properties[propName]) {
        propSchema = propSchema.properties[propName];
      } else {
        propSchema = {};
        break;
      }
    }

    propName = propSchema.title || property;
    if (property === propName) {
      let newSchema = schema;
      let pathParts = error.schemaPath.split("/");
      pathParts = pathParts[0] === "#" ? pathParts.slice(1) : pathParts;
      if (
        error.name === "required" &&
        pathParts[pathParts.length - 1] === "required"
      ) {
        for (let index = 0; index < pathParts.length - 1; index++) {
          newSchema = newSchema[pathParts[index]];
        }
        propName = newSchema.properties[error.params.missingProperty].title;
      }
    }
  } catch (e) {
    console.log(e);
  }

  return propName && propName !== property
    ? `${propName} ${error.message}`
    : error.stack;
}

function ErrorListTemplate(props) {
  const { errors } = props;
  return (
    <div className="alert alert-danger" role="alert">
      <h6>Please correct the following errors,</h6>
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{getErrorDescription(error, props.schema)}</li>
        ))}
      </ul>
    </div>
  );
}

export default ErrorListTemplate;
