import React, { Component, Fragment } from "react";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";
import ReactPivot from "../common/react-pivot/react-pivot";

class PivotReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true
    };
  }

  handleApiFailed = message => {
    this.setState({
      loading: false,
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  loadData = () => {
    // If transformer is provied, then use it or return the same data using an arrow function.
    const dataTransformer = this.props.dataTransformer || (e => e);
    HttpUtil.get(
      this.props.url,
      {},
      data => {
        this.setState({ data: dataTransformer(data), loading: false });
      },
      data => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate = prevProps => {
    if (this.props.url !== prevProps.url && this.props.url) {
      this.loadData();
    }
  };

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <h5 className="text-center">
              <strong style={{ color: "rgb(100, 122, 203)" }}>
                {this.props.title}
              </strong>
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.state.loading ? (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="progress-bar-loading-text">Loading...</div>
                  <div className="progress-bar">
                    <div className="indeterminate" />
                  </div>
                </div>
              </div>
            ) : this.state.data ? (
              <ReactPivot
                rows={this.state.data}
                dimensions={this.props.dimensions}
                reduce={this.props.reduce}
                calculations={this.props.calculations}
                activeDimensions={this.props.activeDimensions || []}
                csvDownloadFileName={`${this.props.title}.csv`}
                soloText="Filter"
                nPaginateRows="50"
                tableClassName="table table-sm"
                solo={this.props.defaultFilter || {}}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PivotReport;
