import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { MONTHLY_REPORTS_API } from "../../common/Constants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import {
  changeToINR,
  MANUAL_CHARGE_STATUS_LIST_OBJECT
} from "../../common/LookupConstants";
import TableList from "../../common/TableList";

function descriptionFormatter(cell, row) {
  return (
    <Link
      to={`/service-mgmt/asset-reservation-requests/edit/${row.reservation}`}
    >
      {cell}
    </Link>
  );
}

class AssetChargeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chargeList: null,
      chargeDesc: null
    };
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const reportId = this.props.match.params.id;
      this.getAllChargeList(reportId);
    }
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAllChargeList = reportId => {
    const url = `${MONTHLY_REPORTS_API}/asset-reports/${reportId}/charges`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          chargeList: data.charges,
          chargeDesc: data.description
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  dateFormater = yyyymmdd => {
    return getDisplayDate(convertNumberToDate(yyyymmdd));
  };

  actionsFormater = (cell, row) => {
    if (!row) return null;
    const reportId = this.props.match.params.id;
    const chargeId = row._id;
    const reportType = "chargereport";
    const baseUrl = "/admin/finance/payment/new?from=";
    const paymentPageUrl = `${baseUrl}${reportType}&report=${reportId}&charge=${chargeId}`;

    let btnLink = (
      <Fragment>
        {row.status !== "CLOSED" ? (
          <Link
            to={paymentPageUrl}
            role="button"
            className="list-action-btn-info"
          >
            Pay
          </Link>
        ) : null}
      </Fragment>
    );
    return btnLink;
  };

  render() {
    const {
      chargeList,
      chargeDesc,

      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage
    } = this.state;

    const columnsCharges = [
      {
        dataField: "customer",
        text: "Customer Name"
      },

      {
        dataField: "description",
        text: "Description",
        formatter: descriptionFormatter
      },

      {
        dataField: "amount",
        text: "Amount",
        classes: "text-right",
        formatter: changeToINR
      },
      {
        dataField: "status",
        text: "Status",
        formatter: cell =>
          MANUAL_CHARGE_STATUS_LIST_OBJECT[cell] || "Unspecified",
        csvFormatter: cell =>
          MANUAL_CHARGE_STATUS_LIST_OBJECT[cell] || "Unspecified",
        filterValue: cell =>
          MANUAL_CHARGE_STATUS_LIST_OBJECT[cell] || "Unspecified"
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.actionsFormater,
        csvExport: false
      }
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsCharges}
          data={chargeList}
          noDataIndication="No Charges found."
          sizePerPage="10"
          headerName={chargeDesc}
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default AssetChargeList;
