import React, { Component } from "react";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import {
  INCUBATEES_API,
  RELATIONSHIP_MANAGERS_API,
  STATES_API,
  COUNTRIES_API,
  SUPPLEMENTARY_INCUBATORS_API
} from "../../common/Constants";
import IncubateeProfileForm from "./IncubateeProfileForm";
import { transformServerToUi, transformUiToApi } from "./Controller";
import * as Yup from "yup";

const INCUBATEE_SCHEMA = Yup.object().shape({
  businessPlan: Yup.string()
    .label("Business Plan")
    .optional(),
  businessModel: Yup.string()
    .label("Business Model")
    .optional(),
  incubateeStage: Yup.string()
    .label("Incubatee Stage")
    .optional(),
  targetSector: Yup.string()
    .label("Target Sector")
    .optional(),
  incubationSector: Yup.string()
    .label("Incubation Sector")
    .optional(),
  subTechSector: Yup.string()
    .label("Sub Tech Sector")
    .optional(),
  graduationStatus: Yup.string()
    .label("Graduation Status")
    .optional(),
  residentialStatus: Yup.string()
    .label("Residental Status")
    .optional(),
  incubationCategory: Yup.string()
    .label("Incubation Category")
    .optional(),
  anchorIncubator: Yup.object().shape({
    value: Yup.string()
      .label("Anchor Incubator")
      .optional()
  }),
  supplIncubators: Yup.array()
    .of(
      Yup.string()
        .label("Supplementory Incubators")
        .optional()
    )
    .optional(),
  relationshipManager: Yup.object().shape({
    value: Yup.string()
      .label("Relationship Manager")
      .optional()
  }),

  about: Yup.string()
    .label("About")
    .optional(),

  patents: Yup.array()
    .of(
      Yup.object().shape({
        titleOfInnovation: Yup.string()
          .label("Title of the innovation")
          .required(),
        country: Yup.string()
          .label("Country")
          .optional(),
        applicationNumber: Yup.string()
          .label("Application Number")
          .optional(),
        applicationFilingDate: Yup.date()
          .typeError("Application Filing Date required")
          .label("Application Filing Date")
          .nullable()
          .optional(),
        fieldOfInvention: Yup.string()
          .label("Field Of Invention")
          .optional(),
        classificationCode: Yup.string()
          .label("Classification Code")
          .optional(),
        patentStatus: Yup.string()
          .label("Patent Status")
          .optional()
      })
    )

    .label("Patents")
    .optional(),

  products: Yup.array()
    .of(
      Yup.object().shape({
        productCategory: Yup.string()
          .label("Product Category")
          .optional(),
        productSubCategory: Yup.string()
          .label("Sub Category")
          .optional(),
        name: Yup.string()
          .label("Product Name")
          .required(),
        description: Yup.string()
          .label("Description")
          .required()
      })
    )
    .label("Products")
    .optional()
});

const DEFAULT_INCUBATEE = {
  businessPlan: "",
  businessModel: "",
  incubateeStage: "",
  targetSector: "",
  incubationSector: "",
  subTechSector: "",
  graduationStatus: "",
  residentialStatus: "",
  incubationCategory: "",
  relationshipManager: { value: "" },
  about: "",

  patents: [
    {
      titleOfInnovation: "",
      country: "",
      applicationNumber: "",
      applicationFilingDate: "",
      fieldOfInvention: "",
      classificationCode: "",
      patentStatus: ""
    }
  ]
};

class IncubateeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incubateeProfile: {},
      previousLocation: "",
      incubators: [],
      supplIncubators: [],
      anchorIncubators: [],
      relationshipManagers: [],
      ancLoding: false,
      supLoding: false,
      states: [],
      countries: [],
      productMetadata: []
    };
  }
  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getIncubateeById = id => {
    const url = `${INCUBATEES_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        // console.log("fetched data:", data);
        const incubateeProfile = transformServerToUi(data);
        this.setState({ incubateeProfile });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSubmit = values => {
    // console.log("handle submit values:", JSON.stringify(values, null, 2));
    //  call api here
    const updateRequest = transformUiToApi(values);

    const formData = new FormData();
    formData.append("businessPlan", values.uploadBusinessPlan);
    formData.append("message", JSON.stringify(updateRequest));

    const url = `${INCUBATEES_API}/${this.state.incubateeProfile._id}`;

    HttpUtil.put(
      url,
      {},
      formData,
      data => {
        const incubateeProfile = transformServerToUi(data);
        this.setState({
          incubateeProfile, //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: ` ${data.name} profile has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getRelationshipManager = () => {
    const url = RELATIONSHIP_MANAGERS_API;

    HttpUtil.get(
      url,
      {},
      data => {
        let relationshipManagers = [];
        if (data.length > 0) {
          relationshipManagers = data.map(relationshipmanager => {
            let relationmanager = {};
            relationmanager.label = relationshipmanager.name;
            relationmanager.value = relationshipmanager._id;
            return relationmanager;
          });
        }
        this.setState({
          relationshipManagers: relationshipManagers
        });
      },
      (data, status) => {
        this.props.handleApiFailed(
          data.message ||
            "An error occurred while getting data for relationship managers. Please try again or contact support if the issue persists."
        );
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  getStates = () => {
    const url = STATES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ states: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllIncubators = () => {
    const url = SUPPLEMENTARY_INCUBATORS_API;

    HttpUtil.get(
      url,
      {},
      data => {
        let incubators = [];
        if (data.length > 0) {
          incubators = data.map(incubator => {
            let inbtr = {};
            inbtr.label = incubator.name;
            inbtr.value = incubator._id;
            return inbtr;
          });
        }
        this.setState({
          incubators: incubators,
          supplIncubators: incubators,
          anchorIncubators: incubators
        });
      },
      (data, status) => {
        this.props.handleApiFailed(data.message);
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  createAnchorIncubator = (newIncubatorName, setFieldValue) => {
    this.setState({
      ancLoding: true
    });

    const url = SUPPLEMENTARY_INCUBATORS_API;
    const headers = {
      "Content-Type": "application/json"
    };

    const incubator = { name: newIncubatorName };
    HttpUtil.post(
      url,
      headers,
      incubator,
      data => {
        let incubators = [];
        let newIncubator = {};
        if (data.length > 0) {
          incubators = data.map(incubator => {
            let inbtr = {};
            inbtr.label = incubator.name;
            inbtr.value = incubator._id;

            if (incubator.name === newIncubatorName) {
              newIncubator = inbtr;
            }
            return inbtr;
          });
        }

        const subppleInbtr = this.removeIncubator(
          newIncubator.value,
          incubators
        );

        // const incubateeProfile = { ...this.state.incubateeProfile };
        // incubateeProfile.anchorIncubator = newIncubator;
        setFieldValue("anchorIncubator", newIncubator);

        this.setState({
          ancLoding: false,
          incubators: incubators,
          supplIncubators: subppleInbtr,
          anchorIncubators: incubators
          // incubateeProfile
        });
      },
      (data, status) => {
        this.props.handleApiFailed(data.message);
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  createSupplementoryIncubator = (newIncubatorName, values, setFieldValue) => {
    this.setState({
      supLoding: true
    });

    const url = SUPPLEMENTARY_INCUBATORS_API;
    const headers = {
      "Content-Type": "application/json"
    };

    const incubator = { name: newIncubatorName };
    HttpUtil.post(
      url,
      headers,
      incubator,
      data => {
        let incubators = [];
        let newIncubator = {};
        if (data.length > 0) {
          incubators = data.map(incubator => {
            let inbtr = {};
            inbtr.label = incubator.name;
            inbtr.value = incubator._id;

            if (incubator.name === newIncubatorName) {
              newIncubator = inbtr;
            }
            return inbtr;
          });
        }
        const ancInbtr = this.removeIncubator(newIncubator.value, incubators);

        const supplIncubators = values.supplIncubators || [];
        supplIncubators.push(newIncubator);
        setFieldValue("supplIncubators", supplIncubators);

        this.setState({
          supLoding: false,
          incubators: incubators,
          supplIncubators: incubators,
          anchorIncubators: ancInbtr
        });
      },
      (data, status) => {
        this.props.handleApiFailed(data.message);
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  removeIncubator = (removeId, incubators) => {
    if (incubators.length === 0) {
      return;
    }
    return incubators.filter(ibtr => ibtr.value !== removeId);
  };

  selectAnchorIncubator = (incubator, setFieldValue) => {
    if (incubator === null) {
      setFieldValue("anchorIncubator", null);
      return;
    }
    const acnhorIncubatorId = incubator.value;
    const incubators = this.state.incubators;
    const supplIncubators = this.removeIncubator(acnhorIncubatorId, incubators);

    setFieldValue("anchorIncubator", incubator);
    this.setState({ supplIncubators });
  };

  selectSupplementaryIncubator = (incubator, setFieldValue) => {
    if (incubator.length === 0) {
      setFieldValue("supplIncubators", []);
      return;
    }

    const selectedInbtrs = incubator;
    const incubators = this.state.incubators;
    let anchorIncubators = [];
    if (selectedInbtrs.length > 0) {
      const exstingSuppleInbtrs = new Set();
      selectedInbtrs.forEach(si => exstingSuppleInbtrs.add(si.value));
      anchorIncubators = incubators.filter(
        inctr => !exstingSuppleInbtrs.has(inctr.value)
      );
    }

    setFieldValue("supplIncubators", selectedInbtrs);
    this.setState({ anchorIncubators });
  };

  transferProductMetaData = data => {
    const list = data || [];
    list.forEach(meta => {
      meta.value = meta._id;
      const children = meta.children || [];
      children.forEach(chMetaData => {
        chMetaData.value = chMetaData._id;
      });
    });
    return list;
  };

  getMetaDataByType = () => {
    const url = `/api/v1/incubatees/meta?type=ProductsCategory`;
    HttpUtil.get(
      url,
      {},
      data => {
        const productMetadata = this.transferProductMetaData(data);
        this.setState({ productMetadata });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  createMetaDataForProducts = (meta, setFieldValue, name) => {
    const url = `/api/v1/incubatees/meta`;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      meta,
      data => {
        // const metaData = { ...data };
        // metaData.value = data._id;
        // setFieldValue(name, metaData);
        const productMetadata = this.transferProductMetaData(data);
        productMetadata.forEach(proMeta => {
          if (meta.type === "productCategory" && proMeta.label === meta.label) {
            setFieldValue(name, proMeta);
          }

          if (meta.type === "ProductsSubCategory") {
            const children = proMeta.children || [];
            children.forEach(chMeta => {
              if (chMeta.label === meta.label) {
                setFieldValue(name, chMeta);
              }
            });
          }
        });

        this.setState({ productMetadata });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getIncubateeById(id);

      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const from = params.get("from");
      const graduationStatus = params.get("graduationStatus");
      this.setState({ previousLocation: from, graduationStatus });
    }

    this.getRelationshipManager();
    this.getStates();
    this.getCountries();
    this.getAllIncubators();
    this.getMetaDataByType();
  }
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
            />
          </div>
        </div>

        <div>
          <IncubateeProfileForm
            anchorIncubators={this.state.anchorIncubators}
            supplIncubators={this.state.supplIncubators}
            ancLoding={this.state.ancLoding}
            supLoding={this.state.supLoding}
            selectAnchorIncubator={this.selectAnchorIncubator}
            createAnchorIncubator={this.createAnchorIncubator}
            selectSupplementaryIncubator={this.selectSupplementaryIncubator}
            createSupplementoryIncubator={this.createSupplementoryIncubator}
            //
            relationshipManagers={this.state.relationshipManagers}
            incubateeProfile={this.state.incubateeProfile || DEFAULT_INCUBATEE}
            validationSchema={INCUBATEE_SCHEMA}
            handleSubmit={this.handleSubmit}
            previousLocation={this.state.previousLocation}
            states={this.state.states}
            countries={this.state.countries}
            productMetadata={this.state.productMetadata}
            createMetaDataForProducts={this.createMetaDataForProducts}
          />
        </div>
      </div>
    );
  }
}

export default IncubateeProfile;
