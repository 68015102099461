import React from "react";

function ProductServices(props) {
  const incubatee = props.incubatee;

  const products = incubatee.products;

  if (products.length === 0) {
    return null;
  }

  return (
    <div>
      <div
        className="card my-4"
        style={{
          borderTop: "2px solid #647acb"
        }}
      >
        <div className="card-header border-0">
          <h6 className="card-title mb-0 text-left">Product Services</h6>
        </div>

        <div className="card-body py-0">
          {products.map((product, index) => {
            const productCategory = product.productCategory || {};
            const productSubCategory = product.productSubCategory || {};

            return (
              <div key={`ps${index}`} className="my-2">
                <div className="d-flex border-bottom">
                  <h6 className="mb-0 font-weight-bold">{product.name}</h6>
                </div>
                <div className="my-2 text-sm">
                  {productCategory.label} -{productSubCategory.label}
                </div>
                <p className="mt-2">{product.description || "--"}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProductServices;
