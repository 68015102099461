import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { MONTHLY_REPORTS_API } from "../../common/Constants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import {
  changeToINR,
  MANUAL_CHARGE_STATUS_LIST_OBJECT
} from "../../common/LookupConstants";
import TableList from "../../common/TableList";
import Can from "../../../auth/can";

class ChargeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chargeList: [],
      chargeDesc: null
    };
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const reportId = this.props.match.params.id;
      this.getAllChargeList(reportId);
    }
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAllChargeList = reportId => {
    const url = `${MONTHLY_REPORTS_API}/${reportId}/charges`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          chargeList: data.charges,
          chargeDesc: data.description
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  dateFormater = yyyymmdd => {
    return getDisplayDate(convertNumberToDate(yyyymmdd));
  };

  actionsFormater = (cell, row) => {
    if (!row) return null;
    const reportId = this.props.match.params.id;
    const chargeId = row._id;
    const reportType = "chargereport";
    const baseUrl = "/admin/finance/payment/new?from=";
    const paymentPageUrl = `${baseUrl}${reportType}&report=${reportId}&charge=${chargeId}`;

    let btnLink = (
      <Fragment>
        <Can do="CREATE" on="Payment">
          {row.status !== "CLOSED" ? (
            <Link
              to={paymentPageUrl}
              role="button"
              className="list-action-btn-info"
            >
              Pay
            </Link>
          ) : null}
        </Can>
      </Fragment>
    );
    return btnLink;
  };

  nameFormatter = (cell, row) => {
    if (!row) return;
    let name = "";
    const names = [];
    if (cell.firstName) {
      names.push(cell.firstName);
    }
    if (cell.lastName) {
      names.push(cell.lastName);
    }
    name = names.length > 0 ? names.join(" ") : cell.name;
    return name;
  };

  render() {
    const {
      chargeList,
      chargeDesc,

      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage
    } = this.state;

    const columnsCharges = [
      {
        dataField: "organization",
        text: "Organization"
        //sort: true
      },
      {
        dataField: "campus",
        text: "Campus"
        //sort: true
      },
      {
        dataField: "building",
        text: "Building"
        //sort: true
      },
      {
        dataField: "floor",
        text: "Floor"
        //sort: true
      },
      {
        dataField: "partition",
        text: "Partition"
        //sort: true
      },
      {
        dataField: "cluster",
        text: "Cluster"
        //sort: true
      },
      {
        dataField: "customer",
        text: "Incubatee Name",
        formatter: this.nameFormatter,
        csvFormatter: this.nameFormatter
      },

      {
        dataField: "amount",
        text: "Amount",
        classes: "text-right",
        formatter: changeToINR
      },
      {
        dataField: "status",
        text: "Status",
        formatter: cell =>
          MANUAL_CHARGE_STATUS_LIST_OBJECT[cell] || "Unspecified",
        csvFormatter: cell =>
          MANUAL_CHARGE_STATUS_LIST_OBJECT[cell] || "Unspecified",
        filterValue: cell =>
          MANUAL_CHARGE_STATUS_LIST_OBJECT[cell] || "Unspecified"
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.actionsFormater,
        csvExport: false
      }
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsCharges}
          data={chargeList}
          noDataIndication="No Charges found."
          sizePerPage="10"
          headerName={chargeDesc}
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default ChargeList;
