import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { LEASE_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import { getDescription } from "../common/Util";
import { convertNumberToDate, getDisplayDate } from "./DateUtil";
import AlertComponent from "../common/AlertComponent";
import TableList from "../common/TableList";

class UpcomingRenewals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leases: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getUpcomingRenewals = () => {
    let url = `${LEASE_API}/upcoming-renewals`;
    HttpUtil.get(
      url,
      {},
      leases => this.setState({ leases }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  partitionFormatter = (partitions, row) => {
    if (!row) return null;
    const partitionNames = partitions.map(lp => lp.partition.displayName);
    return partitionNames.join(",");
  };

  buildingFormatter = (cell, record) => {
    if (!record) return null;
    let buildings = {};
    record.leasePartitions.forEach(lp => {
      buildings[lp.partition.floor.building.buildingName] = true;
    });
    const entries = Object.keys(buildings);
    return entries.length === 1 ? entries[0] : "--";
  };

  statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (!row) return null;
    return getDescription("LEASE", row[formatExtraData], cell);
  };

  dateFormatter = (cell, row) => {
    if (!row) return null;
    let displayDate = getDisplayDate(convertNumberToDate(cell));
    return displayDate;
  };

  linkFormater = (cell, row) => {
    if (!row) return null;
    const url = `/admin/lease/edit/${row._id}?from=/admin/active/leases`;
    return <Link to={url}>{row.incubatee.name}</Link>;
  };

  getIncubateeName = (cell, row) => {
    if (!row) return null;
    return row.incubatee.name;
  };

  componentDidMount() {
    this.getUpcomingRenewals();
  }

  render() {
    const { leases } = this.state;

    const columnsLease = [
      {
        dataField: "incubatee.name",
        text: "Incubatee",
        sort: true,
        formatter: this.linkFormater,
        filterValue: this.getIncubateeName
      },
      {
        dataField: "incubatee.relManager",
        text: "Relationship Manager",
        sort: true,
        formatter: val => val || "--",
        filterValue: val => val || "--",
        csvFormatter: val => val || "--"
      },
      //   {
      //     dataField: "",
      //     formatter: this.buildingFormatter,
      //     csvFormatter: this.buildingFormatter,
      //     text: "Building"
      //   },
      //   {
      //     dataField: "leasePartitions",
      //     formatter: this.partitionFormatter,
      //     csvFormatter: this.partitionFormatter,
      //     text: "Partition"
      //   },
      {
        dataField: "leaseStartDate",
        text: "Start date",
        csvFormatter: this.dateFormatter,
        formatter: this.dateFormatter,
        filterValue: this.dateFormatter
      },
      {
        dataField: "leaseEndDate",
        text: "End date",
        formatter: this.dateFormatter,
        csvFormatter: this.dateFormatter,
        filterValue: this.dateFormatter
      },

      {
        dataField: "status",
        text: "Status",
        formatter: this.statusFormatter,
        csvFormatter: this.statusFormatter,
        formatExtraData: "stage",
        filterValue: this.statusFormatter
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsLease}
          data={leases}
          noDataIndication="No Leases due for next 30 days."
          sizePerPage="25"
          headerName="Upcoming Renewals"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default UpcomingRenewals;
