import React, { Fragment } from "react";

function ArrayFieldTemplate(props) {
  return (
    <fieldset
      className={`field field-array field-array-of-${props.schema.items.type}`}
      id={`${props.idSchema.$id}`}
    >
      <legend id={`${props.idSchema.$id}__title`}>{props.title}</legend>

      <div className="array-item-list">
        {props.items &&
          props.items.map((element, index) => (
            <div
              key={element.key}
              className={`card ${element.className} m-2 p-2`}
            >
              <div className="card-body pl-3 pt-1 pb-0 pr-3">
                <div className="row">
                  <div className="col-10">{element.children}</div>
                  <div className="col-2 array-item-toolbox">
                    <button
                      onClick={element.onDropIndexClick(element.index)}
                      className="btn array-item-remove float-right"
                    >
                      <i className="fas fa-times fa-md text-danger" />
                    </button>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          ))}

        {props.canAdd && (
          <div className="row">
            <p className="col-3 array-item-add ml-2">
              <button
                onClick={props.onAddClick}
                type="button"
                className="btn btn-outline-success btn-add btn-sm"
              >
                + New {props.items.title}
              </button>
            </p>
          </div>
        )}
      </div>
    </fieldset>
  );
}

export default ArrayFieldTemplate;
