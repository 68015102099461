import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";

class MatchedUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchedRecords: [],

      selectedProfile: null,
      redirect: false,
      newUser: false,

      // back to previous page information
      matchedUserProfilePrevLocInfo: ""
    };
  }

  handleSelectedProfile = selectedProfile => {
    const emails = selectedProfile.emails || [];
    let email = "";
    emails.forEach(em => {
      if (em.matched) {
        email = em.email;
      }
    });

    const phones = selectedProfile.phones || [];
    let phone = "";
    phones.forEach(ph => {
      if (ph.matched) {
        phone = ph.phone;
      }
    });

    const profile = {
      _id: selectedProfile._id,
      name: selectedProfile.name,
      requestorType: selectedProfile.requestorType,
      email,
      phone
    };

    this.setState({ selectedProfile: profile });
  };

  handleClear = () => {
    this.setState({ selectedProfile: null });
  };

  handleNext = () => {
    const selectedProfile = this.state.selectedProfile;
    if (selectedProfile) {
      const newAssetReservationFlow = sessionStorage.getItem(
        "newAssetReservationFlow"
      );
      if (newAssetReservationFlow) {
        const flowConfig = JSON.parse(newAssetReservationFlow);
        flowConfig.category = "MATCH";
        flowConfig.data.selectedProfile = selectedProfile;
        flowConfig.path = this.props.location.pathname;
        sessionStorage.setItem(
          "newAssetReservationFlow",
          JSON.stringify(flowConfig)
        );
        this.setState({ redirect: true, newUser: false });
      }
    }
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      confirm: false
    });
  };

  okConfirmUpdate = () => {
    // do stuff
    this.setState(
      {
        alertType: "",
        showAlert: false,
        alertColor: "",
        alertMessage: "",
        confirm: true
      },
      this.handleCreate()
    );
  };

  handleCreate = e => {
    if (e) {
      e.preventDefault();
    }

    if (!this.state.confirm) {
      return this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "info",
        alertMessage:
          "Matched user profile is found. Do you still want to create new user profile ?",
        confirm: true
      });
    }

    const newAssetReservationFlow = sessionStorage.getItem(
      "newAssetReservationFlow"
    );
    if (newAssetReservationFlow) {
      const flowConfig = JSON.parse(newAssetReservationFlow);
      flowConfig.category = "MATCH_CREATE_NEW";
      delete flowConfig.data.selectedProfile;
      flowConfig.path = this.props.location.pathname;
      sessionStorage.setItem(
        "newAssetReservationFlow",
        JSON.stringify(flowConfig)
      );
      this.setState({
        redirect: true,
        newUser: true,
        selectedProfile: null,
        confirm: false
      });
    }
  };

  componentDidMount = () => {
    const newAssetReservationFlow = sessionStorage.getItem(
      "newAssetReservationFlow"
    );
    if (newAssetReservationFlow) {
      const flowConfig = JSON.parse(newAssetReservationFlow);

      const PATH_CONFIG = {
        NEW: "/service-mgmt/user-info",
        ENQUIRY: `/service-mgmt/enquiries/view/${flowConfig.data.incubatorEnquiry}`
      };

      const previousPathname = PATH_CONFIG[flowConfig.type];

      return this.setState({
        matchedRecords: flowConfig.data.matchedRecords,
        matchedUserProfilePrevLocInfo: {
          pathname: previousPathname
        }
      });
    }
  };

  render() {
    const {
      matchedRecords,
      selectedProfile,
      redirect,
      matchedUserProfilePrevLocInfo
    } = this.state;

    if (redirect) {
      return <Redirect to="/service-mgmt/asset-reservation-requests/new" />;
    }

    let list = matchedRecords.map((profile, index) => {
      const greenColor = {
        border: "0.5px solid #4bbf73",
        borderRadius: 4
      };
      const blueColor = {
        border: "0.5px solid #93a3dffc",
        borderRadius: 4
      };

      let selectedStyle = {};
      let isSelected =
        (selectedProfile && selectedProfile._id === profile._id) || false;
      if (isSelected) {
        selectedStyle = greenColor;
      } else {
        selectedStyle = blueColor;
      }

      let imgSrc = "";
      if (profile.requestorType === "Individual") {
        imgSrc = (
          <img
            src={"images/grey-person-image-icon.png"}
            className="rounded-circle mr-2"
            alt={profile.name}
            width="46"
            height="46"
          />
        );
      } else {
        imgSrc = (
          <i className="fas fa-industry fa-2x" style={{ color: "#647acb" }} />
        );
      }

      return (
        <div className="col-md-4 p-2" key={index}>
          <div
            className="card p-1 m-3 cursor-pointer"
            style={selectedStyle}
            onClick={this.handleSelectedProfile.bind(this, profile)}
          >
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col-auto pr-0">
                  {/* <img
                    src={"images/grey-person-image-icon.png"}
                    className="rounded-circle mr-2"
                    alt={profile.name}
                    width="46"
                    height="46"
                  /> */}
                  {imgSrc}
                </div>
                <div className="col ml-md-n2">
                  {profile.name}
                  <small className="d-block text-muted">
                    {profile.requestorType}
                  </small>
                </div>
                <div className="col-auto">
                  {isSelected ? (
                    <span className="d-inline-block float-right mt-1">
                      <i className="fas fa-check-circle fa-2x text-success"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="row align-items-center p-3">
                <div className="col-1 align-self-start">
                  <i className="fas fa-mobile-alt mr-2"></i>
                </div>
                <div className="col pl-2">
                  <h6 className="text-sm mb-0">Phone</h6>
                  <div className="mt-1">
                    {profile.phones.map((ph, phIndex) => {
                      let cssClassName = ph.matched
                        ? "text-sm text-muted matched-highlight mb-1"
                        : "text-sm text-muted mb-1";
                      return (
                        <div key={phIndex} className={cssClassName}>
                          {ph.phone}
                          {ph.matched ? (
                            <i className="fas fa-check-circle ml-1"></i>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row align-items-center p-3">
                <div className="col-1 align-self-start">
                  <i className="far fa-envelope"></i>
                </div>
                <div className="col pl-2">
                  <h6 className="text-sm mb-0">Email</h6>
                  <div className="mt-1">
                    {profile.emails.map((em, emIndex) => {
                      let cssClassName = em.matched
                        ? "text-sm text-muted matched-highlight mb-1"
                        : "text-sm text-muted mb-1";
                      return (
                        <div key={emIndex} className={cssClassName}>
                          {em.email}
                          {em.matched ? (
                            <i className="fas fa-check-circle ml-1"></i>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="container-fluid-lg container-fluid-xl">
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card elevate">
              <div className="card-header border-0">
                <span className="edit-page-title">
                  <Link
                    to={{
                      pathname:
                        matchedUserProfilePrevLocInfo.pathname ||
                        "/service-mgmt/user-info"
                    }}
                    className="badge bg-white custom-btn-sm float-left"
                  >
                    <i className="fas fa-arrow-left mr-2" />
                  </Link>
                  Asset Usage Request
                </span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 mb-3 edit-page-section-header-text">
                    Select User Profile
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 p-3 m-3">
                    Matched user profile is found. Select user profile or create
                    new user profile
                  </div>
                </div>

                <div className="row no-gutters">{list}</div>

                <div className="row">
                  <div className="col-12 text-center">
                    {selectedProfile !== null ? (
                      <button
                        className="btn btn-sm btn-primary mr-3"
                        type="button"
                        onClick={this.handleNext}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-primary mr-3"
                        type="button"
                        disabled
                      >
                        Next
                      </button>
                    )}

                    <button
                      className="btn btn-sm btn-outline-info mr-3"
                      type="button"
                      onClick={this.handleCreate}
                    >
                      Create New
                    </button>
                    <button
                      className="btn btn-sm btn-outline-secondary mr-3"
                      type="button"
                      onClick={this.handleClear}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MatchedUserProfile;
