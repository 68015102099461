import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ToggleSideBarAction from "../../actions/toggle-sidebar/ToggleSidebarAction";
import ToggleNavbarAction from "../../actions/toggle-navbar/ToggleNavbarAction";
import { logoutUser } from "../../actions/login/LoginAction";
import HttpUtil from "./HttpUtil";
import { DASHBOARD_LIST_COUNTS } from "./Constants";
//import Logger from "../../Logger";

export class Header extends Component {
  constructor(args) {
    super(args);
    this.state = {
      showUserDropDown: false,
      showNotificationDropDown: false,
      timer: null,
      genericStats: []
    };
  }

  handleClick = () => {
    this.props.ToggleSideBarAction();
  };

  handleNavebarClick = () => {
    this.props.ToggleNavbarAction();
  };

  handleLogout = e => {
    this.props.logoutUser();
  };

  handleUserToggle = () => {
    this.setState({
      showUserDropDown: !this.state.showUserDropDown
    });

    if (this.state.showNotificationDropDown) {
      this.handleNotificationToggle();
    }
  };

  handleNotificationToggle = () => {
    this.setState({
      showNotificationDropDown: !this.state.showNotificationDropDown
    });
    if (this.state.showUserDropDown) {
      this.handleUserToggle();
    }
  };

  setGenericStats = data => {
    const genericStats = [];

    if (data.PENDING_APPFORMS_REQUESTS > 0) {
      genericStats.push({
        label: "Pending Shortlisted Applications",
        value: data.PENDING_APPFORMS_REQUESTS,
        path: "/admin/incubatoronboard"
      });
    }

    if (data.PENDING_INCUBATEE_ONBOARD_REQUESTS > 0) {
      genericStats.push({
        label: "Pending Incubatee Onboarding Requests",
        value: data.PENDING_INCUBATEE_ONBOARD_REQUESTS,
        path: "/onboard/incubatees"
      });
    }

    const pendingLeaseRequestCount =
      data.PENDING_LEASE_REQUESTS + data.PENDING_LEASE_TERMINATE_REQUESTS;
    if (pendingLeaseRequestCount > 0) {
      genericStats.push({
        label: "Pending Lease Requests",
        value: pendingLeaseRequestCount,
        path: "/admin/request/leases"
      });
    }

    if (data.EXPIRED_LEASES_COUNT > 0) {
      genericStats.push({
        label: "Expired Leases",
        value: data.EXPIRED_LEASES_COUNT,
        path: "/admin/expired/leases"
      });
    }

    if (data.GRADUATION_REQUESTS > 0) {
      genericStats.push({
        label: "Pending Graduation Requests",
        value: data.GRADUATION_REQUESTS,
        path: "/admin/incubatees/graduations"
      });
    }

    if (data.INCUBATEE_PENDING_ON_LEASE_COUNT > 0) {
      genericStats.push({
        label: "Incubatees Awaiting Leasing",
        value: data.INCUBATEE_PENDING_ON_LEASE_COUNT,
        path: "/admin/incubatees/pending-lease"
      });
    }

    if (data.UPCOMING_RENEWAL_LEASES_COUNT > 0) {
      genericStats.push({
        label: "Upcoming Renewal Leases",
        value: data.UPCOMING_RENEWAL_LEASES_COUNT,
        path: "/admin/upcoming-renewals"
      });
    }

    if (data.PENDING_ASSET_ENQUIRIES > 0) {
      genericStats.push({
        label: "Open Asset Enquiries",
        value: data.PENDING_ASSET_ENQUIRIES,
        path: "/service-mgmt/open-enquiries"
      });
    }

    if (data.PENDING_DATA_UPDATE_REQUESTS > 0) {
      genericStats.push({
        label: "Open Data Update Requests",
        value: data.PENDING_DATA_UPDATE_REQUESTS,
        portalUrl: process.env.REACT_APP_INCUBATOR_PORTAL_URL || "",
        path: "/portal/update-request"
      });
    }

    this.setState({ genericStats });
  };

  genericStatsApi = () => {
    HttpUtil.get(
      DASHBOARD_LIST_COUNTS,
      {},
      this.setGenericStats,
      data => console.log(`Header Count Fetch Error - ${data.message}`),
      error => console.log(`Header Count Fetch Error - ${error.toString()}`)
    );
  };

  componentDidMount() {
    this.genericStatsApi(); // first time

    const minute = 60000 * 5;
    let timer = setInterval(this.genericStatsApi.bind(this), minute);
    this.setState({ timer });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  render() {
    const { navBarShow } = this.props;
    const { genericStats } = this.state;

    const showUserDropDown = this.state.showUserDropDown ? "show" : "";

    const showNotificationDropDown = this.state.showNotificationDropDown
      ? "show"
      : "";
    //Logger.info("Header.js==>this.props:", this.props);
    return (
      <div className="navbar navbar-expand-lg navbar-light bg-white">
        {/* Sidebar collapse Toggle button */}
        <span
          id="sidebarCollapse"
          className="sidebar-toggle d-flex mr-2"
          onClick={this.handleClick}
        >
          <i className="fas fa-align-justify" />
        </span>
        {/* Searchbar 
        <form className="form-inline d-none d-sm-inline-block">
          <input
            className="form-control mr-sm-2"
            type="text"
            placeholder="Search project"
            aria-label="Search"
          />
        </form>*/}
        &nbsp;&nbsp;
        {/* ToggleButton for responsive view (mobile or small devices) */}
        <span className="navbar-toggler" onClick={this.handleNavebarClick}>
          <i className="fas fa-align-justify" />
        </span>
        {/* <a
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="tempvalue"
        >
          <i className="fas fa-align-justify" />
        </a> */}
        {/* Header Menu Links */}
        <div
          className={
            navBarShow
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse "
          }
          id="navbarNav"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              {/* Searchbar for responsive view (mobile or small devices) */}
              {/*
              <form className="form-inline d-block d-sm-none d-md-none d-lg-none">
                <input
                  className="form-control mr-sm-2"
                  type="text"
                  placeholder="Search project"
                  aria-label="Search"
                />
              </form>
               */}
            </li>

            <li className={`nav-item dropdown ${showNotificationDropDown}`}>
              <span className="d-none d-sm-inline-block position-relative">
                <button
                  aria-haspopup="true"
                  className="btn btn-link nav-link  text-dark"
                  aria-expanded={showNotificationDropDown}
                  onClick={this.handleNotificationToggle}
                >
                  <i className="far fa-bell fa-lg mr-2" />
                  {genericStats.length > 0 ? (
                    <span className="indicator">{genericStats.length}</span>
                  ) : null}
                </button>
              </span>

              <div
                className={`dropdown-menu dropdown-menu-lg dropdown-menu-right py-0 ${showNotificationDropDown}`}
                aria-labelledby={`${showNotificationDropDown}`}
              >
                <div className="dropdown-menu-header small">
                  {`${genericStats.length} New Notifications`}
                </div>
                <div className="list-group mb-2">
                  {genericStats.map((stat, index) => {
                    return !stat.portalUrl ? (
                      <Link
                        to={stat.path}
                        key={index}
                        replace={true}
                        className="list-group-item border-0"
                        onClick={this.handleNotificationToggle}
                      >
                        <div className="row no-gutters align-items-center">
                          <div className="col-2">
                            <span className="fa-stack">
                              <span className="fas fa-circle fa-stack-2x" />
                              <strong className="fa-stack-1x text-white">
                                {stat.value}
                              </strong>
                            </span>
                          </div>
                          <div className="col-10">
                            <div className="text-muted small">{stat.label}</div>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <a
                        href={`${stat.portalUrl}${stat.path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="list-group-item border-0"
                      >
                        <div className="row no-gutters align-items-center">
                          <div className="col-2">
                            <span className="fa-stack">
                              <span className="fas fa-circle fa-stack-2x" />
                              <strong className="fa-stack-1x text-white">
                                {stat.value}
                              </strong>
                            </span>
                          </div>
                          <div className="col-10">
                            <div className="text-muted small">{stat.label}</div>
                          </div>
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </li>

            <li className={`nav-item dropdown ${showUserDropDown}`}>
              <span className="d-none d-sm-inline-block">
                <button
                  aria-haspopup="true"
                  className="btn btn-link nav-link text-dark"
                  aria-expanded={showUserDropDown}
                  onClick={this.handleUserToggle}
                >
                  <i
                    className="fas fa-user-circle fa-lg mr-2"
                    alt={this.props.userName}
                  />
                  <span className="text-dark">{this.props.userName}</span>
                </button>
              </span>

              <div
                className={`dropdown-menu dropdown-menu-right ${showUserDropDown}`}
                aria-labelledby="dropdown01"
              >
                <Link className="dropdown-item small" to="/change-password">
                  Change Password
                </Link>

                <Link
                  className="dropdown-item small"
                  to="/logout"
                  onClick={this.handleLogout}
                >
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

// Redux
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ToggleSideBarAction, ToggleNavbarAction, logoutUser },
    dispatch
  );

export const getStateReducerValue = (state, reducerName, key) => {
  return state && state[reducerName] ? state[reducerName][key] : undefined;
};

export const mapStateToProps = state => ({
  navBarShow: getStateReducerValue(state, "ToggleNavbarReducer", "navBarShow")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
