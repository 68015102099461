import React, { Fragment } from "react";
import { getDisplayDate } from "../../../lease/DateUtil";
import { INCUBATION_SECTORS_OBJECT } from "../../../common/IncubateeProfileConstants";
import { changeToINR } from "../../../common/LookupConstants";

export const GrantOverview = props => {
  const { award } = props;

  // let incubateeName = "--";
  // if (award.incubatee && award.incubatee.incubateeType === "COMPANY") {
  //   incubateeName = award.incubatee.organization.name;
  // }

  // if (award.incubatee && award.incubatee.incubateeType === "INDIVIDUAL") {
  //   let fName = award.incubatee.individual.firstName || "";
  //   let lName = award.incubatee.individual.lastName || "";
  //   incubateeName = `${fName} ${lName}`;
  // }

  let granteeName = "--";
  if (award.grantee) {
    granteeName = `${award.grantee.name || ""} ${award.grantee.firstName ||
      ""} ${award.grantee.lastName || ""}`;
  }

  return (
    <Fragment>
      <div className="pl-3 pr-3">
        <div className="row">
          <div className="col-12 edit-page-section-header-text mb-2">
            Grant Information
          </div>
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
              <i className="fas fa-award fa-stack-1x" />
            </span>
            <span>
              <small className="mb-0 text-muted">Grantee Type</small>
              <div className="mb-0">{award.granteeType}</div>
            </span>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
              <i className="fas fa-trophy fa-stack-1x" />
            </span>
            <span>
              <small className="mb-0 text-muted">Grantee Name</small>
              <div className="mb-0">{granteeName || "--"}</div>
            </span>
          </div>

          {/* <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
              <i className="fas fa-rocket fa-stack-1x" />
            </span>
            <span>
              <small className="mb-0 text-muted">Incubatee Name</small>
              <div className="mb-0">{incubateeName}</div>
            </span>
          </div> */}

          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
              <i className="fas fa-rupee-sign fa-stack-1x" />
            </span>
            <span>
              <small className="mb-0 text-muted">Amount Sanctioned</small>
              <div className="mb-0">{changeToINR(award.sanctionedAmount)}</div>
            </span>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
              <i className="fas fa-rupee-sign fa-stack-1x" />
            </span>
            <span>
              <small className="mb-0 text-muted">Amount Disbursed</small>
              <div className="mb-0">{changeToINR(award.amountDisburesd)}</div>
            </span>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
              <i className="fas fa-calendar-alt fa-stack-1x" />
            </span>
            <span>
              <small className="mb-0 text-muted">Fund Start Date</small>
              <div className="mb-0">
                {getDisplayDate(award.startDate) || "--"}
              </div>
            </span>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
              <i className="fas fa-calendar-alt fa-stack-1x" />
            </span>
            <span>
              <small className="mb-0 text-muted">Fund End Date</small>
              <div className="mb-0">
                {getDisplayDate(award.endDate) || "--"}
              </div>
            </span>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mt-3 d-flex">
            <span className="fa-stack fa-lg">
              <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
              <i className="fas fa-ruler-vertical fa-stack-1x" />
            </span>
            <span>
              <small className="mb-0 text-muted">Vertical</small>
              <div className="mb-0">
                {INCUBATION_SECTORS_OBJECT[award.vertical] || "--"}
              </div>
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
