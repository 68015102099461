import React from "react";
import { Link } from "react-router-dom";
import AlertComponent from "../../../common/AlertComponent";
import TableList from "../../../common/TableList";
import HttpUtil from "../../../common/HttpUtil";
import { convertNumberToDate, getDisplayDate } from "../../../lease/DateUtil";
import { getDescription } from "../../../common/Util";
import Can from "../../../../auth/can";

function linkFormatter(cell, row) {
  const entity = row.entity || {};

  let name = "";
  if (row.entityType === "Individual") {
    const names = [];
    if (entity.firstName) {
      names.push(entity.firstName);
    }
    if (entity.lastName) {
      names.push(entity.lastName);
    }
    name = names.join(" ");
  } else {
    name = entity.name;
  }
  const url = `/finance/account/closure-requests/edit/${
    row._id
  }?from=/finance/account/awaiting-refund`;
  return <Link to={url}>{name}</Link>;
}

function nameFormatter(cell, row) {
  let entity = row.entity;
  let name = "";
  if (row.entityType === "Individual") {
    const names = [];
    if (entity.firstName) {
      names.push(entity.firstName);
    }
    if (entity.lastName) {
      names.push(entity.lastName);
    }
    name = names.join(" ");
  } else {
    name = entity.name;
  }
  return name;
}

function dateFormater(yyyymmdd) {
  return getDisplayDate(convertNumberToDate(yyyymmdd));
}

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  return getDescription("AccountClosure", row[formatExtraData], cell);
}

const columnsAccountClosure = [
  {
    dataField: "entity",
    text: "Name",
    sort: true,
    formatter: linkFormatter,
    csvFormatter: nameFormatter,
    filterValue: nameFormatter
  },
  {
    dataField: "entityType",
    text: "Customer Type",
    sort: true
  },
  {
    dataField: "closureDate",
    text: "Closure Date",
    formatter: dateFormater,
    csvFormatter: dateFormater,
    filterValue: dateFormater
  },
  {
    dataField: "status",
    text: "Status",
    formatter: statusFormatter,
    formatExtraData: "stage",
    csvFormatter: statusFormatter,
    filterValue: statusFormatter
  }
];

function getAccountClosureRequests(setClosureRequests, setAlert) {
  let url = "/api/v1/finance/account-closures/awaiting-refund-requests";

  HttpUtil.get(
    url,
    {},
    data => setClosureRequests(data),
    (data, status) => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: data.message
      });
      window.scrollTo(0, 0);
    },
    error => {
      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: error.toString()
      });
      window.scrollTo(0, 0);
    }
  );
}

function AwaitingRefund() {
  const [alert, setAlert] = React.useState({
    alertType: "",
    showAlert: false,
    alertColor: "",
    alertMessage: ""
  });
  const [closureRequests, setClosureRequests] = React.useState(null);
  React.useEffect(
    () => getAccountClosureRequests(setClosureRequests, setAlert),
    []
  );
  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* show alert message  */}
          <AlertComponent
            show={alert.showAlert}
            type={alert.alertType}
            alertColor={alert.alertColor}
            message={alert.alertMessage}
            close={() =>
              setAlert({
                alertType: "",
                showAlert: false,
                alertColor: "",
                alertMessage: ""
              })
            }
          />
        </div>
      </div>

      <TableList
        keyField="_id"
        columns={columnsAccountClosure}
        data={closureRequests}
        noDataIndication="No Awaiting Refund found."
        sizePerPage="10"
        headerName="Awaiting Refund"
        showSearchbar={true}
        customComponent={
          <Can do="CREATE" on="AccountClosureRequest">
            <Link
              to="/finance/account/closure-requests/new?from=/finance/account/awaiting-refund"
              role="button"
              className="btn btn-sm text-white bg-pink"
            >
              New
            </Link>
          </Can>
        }
      />
    </>
  );
}

export default AwaitingRefund;
