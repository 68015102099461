import React, { Fragment, Component } from "react";
import { Field, FieldArray } from "formik";
import { STATES_API, COUNTRIES_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import { downloadDocument } from "../common/Util";
import ShowHideComponent from "./ShowHideComponent";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  return getError(touch, error, fieldName) ? "is-invalid" : "";
}

const AddressFieldCell = props => {
  return (
    <div className="form-group row mb-0">
      <label
        className={`col-form-label col-12 col-lg-4 col-xl-4 ${props.required}`}
      >
        {props.label}
      </label>
      <div className="col-12 col-lg-8 col-xl-8">{props.children}</div>
    </div>
  );
};

const Address = props => {
  const {
    values,
    index,
    touch,
    error,
    disabledField,
    countries,
    states
  } = props;
  return (
    <div className="mt-0">
      <strong className="text-muted">Address {index + 1}</strong>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-6">
          <AddressFieldCell
            label="Street Line 1"
            required="edit-page-label-required"
          >
            <Field
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touch,
                error,
                "streetLine1"
              )}`}
              name={`addresses.${index}.streetLine1`}
              placeholder="Street Line 1"
              {...disabledField}
            />
            <div className="invalid-feedback">
              {getError(touch, error, "streetLine1")}
            </div>
          </AddressFieldCell>

          <AddressFieldCell label="City" required="edit-page-label-required">
            <Field
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touch,
                error,
                "city"
              )}`}
              name={`addresses.${index}.city`}
              placeholder="City"
              {...disabledField}
            />
            <div className="invalid-feedback">
              {getError(touch, error, "city")}
            </div>
          </AddressFieldCell>

          <AddressFieldCell label="State" required="edit-page-label-required">
            <Field
              component="select"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touch,
                error,
                "state"
              )}`}
              name={`addresses.${index}.state`}
              {...disabledField}
            >
              <option value="">Select...</option>
              {states.map((state, i) => (
                <option key={i} value={state.stateCode}>
                  {state.stateName}
                </option>
              ))}
              >
            </Field>
            <div className="invalid-feedback">
              {getError(touch, error, "state")}
            </div>
          </AddressFieldCell>

          <AddressFieldCell
            label="Postal Code"
            required="edit-page-label-required"
          >
            <Field
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touch,
                error,
                "postalCode"
              )}`}
              name={`addresses.${index}.postalCode`}
              placeholder="Postal Code"
              {...disabledField}
            />
            <div className="invalid-feedback">
              {getError(touch, error, "postalCode")}
            </div>
          </AddressFieldCell>
        </div>
        <div className="col-12 col-lg-6 col-xl-6">
          <AddressFieldCell label="Street Line 2" required="">
            <Field
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touch,
                error,
                "streetLine2"
              )}`}
              name={`addresses.${index}.streetLine2`}
              placeholder="Street Line 2"
              {...disabledField}
            />
            <div className="invalid-feedback">
              {getError(touch, error, "streetLine2")}
            </div>
          </AddressFieldCell>

          <AddressFieldCell label="Landmark" required="">
            <Field
              type="text"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touch,
                error,
                "landmark"
              )}`}
              name={`addresses.${index}.landmark`}
              placeholder="Landmark"
              {...disabledField}
            />
            <div className="invalid-feedback">
              {getError(touch, error, "landmark")}
            </div>
          </AddressFieldCell>

          <AddressFieldCell label="Country" required="edit-page-label-required">
            <Field
              component="select"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touch,
                error,
                "country"
              )}`}
              name={`addresses.${index}.country`}
              {...disabledField}
            >
              <option value="">Select...</option>
              {countries.map((country, i) => (
                <option key={i} value={country.countryCode}>
                  {country.name}
                </option>
              ))}
              >
            </Field>
            <div className="invalid-feedback">
              {getError(touch, error, "country")}
            </div>
          </AddressFieldCell>
          <AddressFieldCell
            label="Address Type"
            required="edit-page-label-required"
          >
            <Field
              component="select"
              className={`form-control form-control-sm ${getErrorCssClassName(
                touch,
                error,
                "type"
              )}`}
              name={`addresses.${index}.type`}
              {...disabledField}
            >
              <option value="">Select...</option>
              <option value="PERMANENT">Permanent Address</option>
              <option value="COMMUNICATION">Communication Address</option>
              <option value="OFFICE">Office Address</option>
            </Field>
            <div className="invalid-feedback">
              {getError(touch, error, "type")}
            </div>
          </AddressFieldCell>
        </div>
      </div>
    </div>
  );
};

class Addresses extends Component {
  constructor(args) {
    super(args);
    this.state = {
      states: [],
      countries: []
    };
  }
  handleApiFailed = (field, message) => {
    window.alert(`"${field}" data fetch failed with ${message}`);
  };

  getStates = () => {
    const url = STATES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ states: data }),
      (data, status) => this.handleApiFailed("States", data.message),
      error => this.handleApiFailed("States", error.toString())
    );
  };

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) => this.handleApiFailed("Countries", data.message),
      error => this.handleApiFailed("Countries", error.toString())
    );
  };

  componentDidMount() {
    this.getCountries();
    this.getStates();
  }

  render() {
    const { values, touched, errors, setFieldValue, readOnly } = this.props;
    const addressErrors = errors.addresses || [];
    const addressTouched = touched.addresses || [];
    const { countries, states } = this.state;
    return (
      <FieldArray
        name="addresses"
        render={({ insert, remove, push }) => (
          <Fragment>
            {values.addresses.map((address, index) => {
              const error = addressErrors[index] || {};
              const touch = addressTouched[index] || {};

              const showAddButton = values.addresses.length - 1 === index;

              const showRemoveButton = values.addresses.length > 1;

              const pendingAddressProofUpload = address.addressProofUpload
                ? address.addressProofUpload.name
                : null;

              const disabledField = readOnly
                ? { readOnly: "readonly", disabled: "disabled" }
                : {};

              const props = {
                values,
                index,
                touch,
                error,
                readOnly,
                countries,
                disabledField,
                states
              };
              return (
                <Fragment key={index}>
                  <Address {...props} />
                  <div className="form-group mt-xl-2 mb-0 row">
                    <label className="col-form-label col-2 col-lg-2 col-xl-2">
                      Address Proof
                    </label>
                    <ShowHideComponent show={!readOnly}>
                      <Fragment>
                        <div className="col-4 col-lg-4 col-xl-4">
                          <div className="custom-file">
                            <input
                              type="file"
                              id="addressProofUpload"
                              name="addressProofUpload"
                              onChange={event => {
                                setFieldValue(
                                  `addresses.${index}.addressProofUpload`,
                                  event.currentTarget.files[0]
                                );
                              }}
                              {...disabledField}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="addressProofUpload"
                            />
                          </div>
                        </div>
                        <div className="col-3 col-lg-3 col-xl-3 mt-2 mt-lg-0 mt-xl-0">
                          {pendingAddressProofUpload ? (
                            <Fragment>
                              <span className="mr-1">
                                {pendingAddressProofUpload}
                              </span>
                              <span className="badge badge-pill badge-warning">
                                Pending
                              </span>
                            </Fragment>
                          ) : (
                            <span>No File Chosen for upload</span>
                          )}
                        </div>
                      </Fragment>
                    </ShowHideComponent>
                    <div className="col-2 col-lg-2 col-xl-2 mt-2 mt-lg-0 mt-xl-0">
                      <ShowHideComponent
                        show={address.addressProof && address.addressProof.link}
                      >
                        <button
                          className={`btn btn-sm btn-info`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Download Address Proof"
                          type="button"
                          onClick={() =>
                            downloadDocument(
                              address.addressProof.link,
                              msg => window.alert(msg),
                              address.addressProof.filename
                            )
                          }
                        >
                          <i className="fas fa-file-download" />
                          &nbsp; Download
                        </button>
                      </ShowHideComponent>
                    </div>
                  </div>

                  <ShowHideComponent show={!readOnly}>
                    <div className="form-group row mt-3 mb-0">
                      <div className="col-12">
                        <ShowHideComponent show={showAddButton}>
                          <button
                            type="button"
                            className="btn btn-sm btn-info"
                            onClick={() =>
                              push({
                                streetLine1: "",
                                streetLine2: "",
                                landmark: "",
                                city: "",
                                state: "",
                                postalCode: "",
                                country: "IN",
                                status: "ACTIVE",
                                type: ""
                              })
                            }
                          >
                            Add More
                          </button>
                        </ShowHideComponent>
                        &nbsp;
                        <ShowHideComponent show={showRemoveButton}>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => remove(index)}
                          >
                            Remove Address {index + 1}
                          </button>
                        </ShowHideComponent>
                      </div>
                    </div>
                  </ShowHideComponent>

                  <ShowHideComponent
                    show={values.addresses.length - 1 !== index}
                  >
                    <hr />
                  </ShowHideComponent>
                </Fragment>
              );
            })}
          </Fragment>
        )}
      />
    );
  }
}

export default Addresses;
