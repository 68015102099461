import React, { Component, Fragment } from "react";
import CreatableSelect from "react-select/lib/Creatable";
import {
  SUPPLEMENTARY_INCUBATORS_API,
  RELATIONSHIP_MANAGERS_API
} from "../../../common/Constants";
import HttpUtil from "../../../common/HttpUtil";
import {
  GRADUATION_STATUS,
  RESIDENTIAL_STATUS,
  INCUBATION_SECTORS,
  SUB_SECTORS,
  INCUBATEE_STAGES,
  INCUBATION_CATEGORY_TYPES
} from "../../../common/IncubateeProfileConstants";

import Select from "react-select";

class IncubateeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supLoding: false,
      ancLoding: false,

      incubators: [],
      anchorIncubator: null,
      anchorIncubators: [],
      supplementaryIncubators: [],
      selectedAnchorIncubator: null,
      selectedSuplIncubators: [],

      incubateeProfile: {
        businessPlan: "",
        businessModel: "",
        incubateeStage: "",
        grandsOrFunding: "",
        incubationSector: "",
        subTechSector: "",
        targetSector: "",
        currentEmployeeCount: "",
        femaleEmployeeCount: "",
        about: "",
        graduationStatus: "ONGOING",
        residentialStatus: "",
        incubationCategory: "",
        relationshipManager: null,
        anchorIncubator: null,
        supplementaryIncubators: []
      }
    };
  }

  onChange = e => {
    console.log("onChange:e===>e.target.value:", e.target.value);
    let incubateeProfile = { ...this.state.incubateeProfile };
    incubateeProfile[e.target.name] = e.target.value;
    this.setState({ incubateeProfile }, this.handleSetState(incubateeProfile));
  };
  getRelationshipManager = () => {
    const url = RELATIONSHIP_MANAGERS_API;

    HttpUtil.get(
      url,
      {},
      data => {
        let relationshipManagers = [];
        if (data.length > 0) {
          relationshipManagers = data.map(relationshipmanager => {
            let relationmanager = {};
            relationmanager.label = relationshipmanager.name;
            relationmanager.value = relationshipmanager._id;
            return relationmanager;
          });
        }
        this.setState({
          relationshipManagers: relationshipManagers
        });
      },
      (data, status) => {
        this.props.handleApiFailed(data.message);
      },
      error => this.props.handleApiFailed(error.toString())
    );
  };

  handleRelationshipManager = selectedManager => {
    const incubateeProfile = this.state.incubateeProfile;
    incubateeProfile.relationshipManager = selectedManager;
    this.setState({ incubateeProfile }, this.handleSetState(incubateeProfile));
  };

  handleInputChange = e => {
    let incubateeProfile = { ...this.state.incubateeProfile };
    if (e.target.checked) {
      incubateeProfile[e.target.name] = e.target.checked;
    } else {
      incubateeProfile.anchorIncubator = null;
      incubateeProfile.supplementaryIncubators = [];
      incubateeProfile[e.target.name] = e.target.checked;
    }
    this.setState({ incubateeProfile }, this.handleSetState(incubateeProfile));
  };

  componentDidMount() {
    const propIncubateeProfile = this.props.incubateeProfile;
    let incubateeProfile = { ...this.state.incubateeProfile };

    if (propIncubateeProfile) {
      for (const key in propIncubateeProfile) {
        incubateeProfile[key] = propIncubateeProfile[key];
      }
      incubateeProfile.graduationStatus = propIncubateeProfile.graduationStatus
        ? propIncubateeProfile.graduationStatus
        : "ONGOING";

      this.setState({ incubateeProfile });
    }
    this.getAllIncubators();
    this.getRelationshipManager();
  }

  getAllIncubators = () => {
    const url = SUPPLEMENTARY_INCUBATORS_API;

    HttpUtil.get(
      url,
      {},
      data => {
        let incubators = [];
        if (data.length > 0) {
          incubators = data.map(incubator => {
            let inbtr = {};
            inbtr.label = incubator.name;
            inbtr.value = incubator._id;
            return inbtr;
          });
        }

        let anchorIncubator = this.state.incubateeProfile.anchorIncubator;
        //console.log("data:anchorIncubator:", anchorIncubator);
        let supplementaryIncubators = [];
        if (anchorIncubator) {
          supplementaryIncubators = incubators.filter(
            inbtr => inbtr.value !== anchorIncubator.value
          );
        } else {
          supplementaryIncubators = incubators;
        }

        let suppleInbtrs = this.state.incubateeProfile.supplementaryIncubators;
        //console.log("data:suppleInbtrs:", suppleInbtrs);
        let anchorIncubators = [];
        if (suppleInbtrs.length > 0) {
          [...suppleInbtrs].forEach(spi => {
            anchorIncubators = incubators.filter(inbtr => {
              return inbtr.value !== spi.value;
            });
          });
        } else {
          anchorIncubators = incubators;
        }

        this.setState({
          incubators: incubators,
          supplementaryIncubators: supplementaryIncubators,
          anchorIncubators: anchorIncubators
        });
      },
      (data, status) => {
        this.handleApiFailed(data.message);
      },
      error => this.handleApiFailed(error.toString())
    );
  };
  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  handleCreateIncubator = (flag, newIncubatorName) => {
    this.setState({
      ancLoding: flag === "ANCHOR",
      supLoding: flag === "SUPPLEMENTARY"
    });

    const url = SUPPLEMENTARY_INCUBATORS_API;
    const headers = {
      "Content-Type": "application/json"
    };

    const incubator = { name: newIncubatorName };
    HttpUtil.post(
      url,
      headers,
      incubator,
      data => {
        let selectedAnchorIncubator = this.state.selectedAnchorIncubator;
        let selectedSuplIncubators = [...this.state.selectedSuplIncubators];
        let incubators = [];
        if (data.length > 0) {
          incubators = data.map(incubator => {
            let inbtr = {};
            inbtr.label = incubator.name;
            inbtr.value = incubator._id;

            if (flag === "ANCHOR") {
              selectedAnchorIncubator = inbtr;
            }

            if (
              flag === "SUPPLEMENTARY" &&
              newIncubatorName === incubator.name
            ) {
              selectedSuplIncubators.push(inbtr);
            }

            return inbtr;
          });
        }

        let incubateeProfile = { ...this.state.incubateeProfile };
        incubateeProfile.supplementaryIncubators = selectedSuplIncubators;
        incubateeProfile.anchorIncubator = selectedAnchorIncubator;
        this.setState(
          {
            ancLoding: false,
            supLoding: false,
            incubateeProfile,
            incubators: incubators
          },
          this.handleSetState(incubateeProfile)
        );
      },
      (data, status) => {
        this.handleApiFailed(data.message);
      },
      error => this.handleApiFailed(error.toString())
    );
  };

  selectSupplementaryIncubator = (incubator, actionMeta) => {
    let incubateeProfile = { ...this.state.incubateeProfile };
    if (incubator === null) {
      incubateeProfile.supplementaryIncubators = null;
      this.setState({ incubateeProfile });
      return;
    }

    const supleIncubatorId = incubator.value;
    const incubators = this.state.incubators;

    const anchorIncubators = incubators.filter(
      inbtr => inbtr.value !== supleIncubatorId
    );

    incubateeProfile.supplementaryIncubators = incubator;
    this.setState(
      {
        anchorIncubators,
        incubateeProfile
      },
      this.handleSetState(incubateeProfile)
    );
  };

  selectAnchorIncubator = (incubator, actionMeta) => {
    let incubateeProfile = { ...this.state.incubateeProfile };

    if (incubator === null) {
      incubateeProfile.anchorIncubator = null;
      this.setState({ incubateeProfile });
      return;
    }
    const acnhorIncubatorId = incubator.value;
    const incubators = this.state.incubators;

    const supplementaryIncubators = incubators.filter(
      inbtr => inbtr.value !== acnhorIncubatorId
    );

    let selectedSuplIncubators = this.state.selectedSuplIncubators;
    if (selectedSuplIncubators.length > 0) {
      selectedSuplIncubators = selectedSuplIncubators.filter(
        ssibtr => ssibtr.value !== acnhorIncubatorId
      );
    }

    incubateeProfile.supplementaryIncubators = selectedSuplIncubators;
    incubateeProfile.anchorIncubator = incubator;
    this.setState(
      {
        supplementaryIncubators,
        incubateeProfile
      },
      this.handleSetState(incubateeProfile)
    );
  };

  handleSetState = incubateeProfile => {
    this.props.getIncubateeProfile(incubateeProfile);
  };

  render() {
    const {
      businessPlan,
      businessModel,
      incubateeStage,
      incubationSector,
      subTechSector,
      targetSector,
      currentEmployeeCount,
      femaleEmployeeCount,
      about,
      graduationStatus,
      residentialStatus,
      incubationCategory,
      relationshipManager
    } = this.state.incubateeProfile;

    const {
      supLoding,
      ancLoding,
      anchorIncubators,
      supplementaryIncubators
    } = this.state;

    const { inputError } = this.props;

    const selectedAnchorIncubator = this.state.incubateeProfile.anchorIncubator;
    const selectedSuplIncubators = this.state.incubateeProfile
      .supplementaryIncubators;

    return (
      <Fragment>
        <div className="row p-0 m-0 edit-page-subsection">
          <div className="col-md-12">
            <div className="card ml-4 mr-4 mt-4 mb-4">
              <div className="card-header edit-page-subsection-title border-0">
                <h6 className="card-title  mb-0">Incubatee Profile</h6>
              </div>
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col-12 mb-3 edit-page-subsection-header-text ">
                    Profile
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
                    Graduation Status
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <select
                      name="graduationStatus"
                      onChange={this.onChange}
                      value={graduationStatus}
                      disabled
                      className={
                        inputError["graduationStatus"]
                          ? "custom-select custom-select-sm is-invalid"
                          : "custom-select custom-select-sm "
                      }
                    >
                      <option value="">Select...</option>
                      {GRADUATION_STATUS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      Please select graduation status *.
                    </div>
                  </div>

                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Residential Status
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <select
                      name="residentialStatus"
                      onChange={this.onChange}
                      value={residentialStatus}
                      className={
                        inputError["residentialStatus"]
                          ? "custom-select custom-select-sm is-invalid"
                          : "custom-select custom-select-sm "
                      }
                    >
                      <option value="">Select...</option>
                      {RESIDENTIAL_STATUS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Incubation Sector
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <select
                      name="incubationSector"
                      onChange={this.onChange}
                      value={incubationSector}
                      className="custom-select custom-select-sm"
                    >
                      <option value="">Select...</option>
                      {INCUBATION_SECTORS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Sub Sector
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <select
                      name="subTechSector"
                      value={subTechSector}
                      onChange={this.onChange}
                      className="custom-select custom-select-sm"
                    >
                      <option value="">Select...</option>
                      {SUB_SECTORS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Incubatee Stage
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <select
                      name="incubateeStage"
                      onChange={this.onChange}
                      value={incubateeStage}
                      className="custom-select custom-select-sm"
                    >
                      <option value="">Select...</option>
                      {INCUBATEE_STAGES.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Target Sector
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Target Sector"
                      name="targetSector"
                      value={targetSector}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Incubation Category
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <select
                      name="incubationCategory"
                      onChange={this.onChange}
                      value={incubationCategory}
                      className="custom-select custom-select-sm"
                    >
                      <option value="">Select...</option>
                      {INCUBATION_CATEGORY_TYPES.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Relationship Manager
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <Select
                      isClearable
                      options={this.state.relationshipManagers}
                      name="relationshipManager"
                      onChange={this.handleRelationshipManager}
                      value={relationshipManager}
                    />
                  </div>
                </div>
                {incubationCategory === "COINCUBATION" ? (
                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                      Anchor Incubator
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                      <CreatableSelect
                        isClearable
                        isDisabled={ancLoding}
                        isLoading={ancLoding}
                        onChange={this.selectAnchorIncubator}
                        onCreateOption={this.handleCreateIncubator.bind(
                          this,
                          "ANCHOR"
                        )}
                        options={anchorIncubators}
                        value={selectedAnchorIncubator}
                      />
                    </div>

                    <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                      Supplementary Incubator
                    </label>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                      <CreatableSelect
                        isMulti
                        isDisabled={supLoding}
                        isLoading={supLoding}
                        onChange={this.selectSupplementaryIncubator}
                        onCreateOption={this.handleCreateIncubator.bind(
                          this,
                          "SUPPLEMENTARY"
                        )}
                        options={supplementaryIncubators}
                        value={selectedSuplIncubators}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Current Employee Count
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="0"
                      name="currentEmployeeCount"
                      value={currentEmployeeCount}
                      onChange={this.onChange}
                    />
                  </div>

                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Female Employee Count
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="0"
                      name="femaleEmployeeCount"
                      value={femaleEmployeeCount}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 mb-3 edit-page-subsection-header-text ">
                    About
                  </div>
                </div>
                <div className="form-group row ">
                  <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label">
                    About
                  </label>
                  <div className="col-12 col-lg-9 col-xl-9">
                    <textarea
                      type="text"
                      className="form-control form-control-sm"
                      name="about"
                      placeholder="Write brief description about the incubatee"
                      rows="6"
                      value={about}
                      onChange={this.onChange}
                    />
                    <div className="invalid-feedback">
                      Please enter about business *.
                    </div>
                  </div>
                </div>

                <div className="form-group row ">
                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Business Model
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                    <textarea
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Business Model"
                      rows="6"
                      name="businessModel"
                      value={businessModel}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="form-group row ">
                  <label className="col-form-label col-form-label-sm col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                    Business Plan
                  </label>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                    <textarea
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Business Plan"
                      rows="6"
                      name="businessPlan"
                      value={businessPlan}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default IncubateeProfile;
