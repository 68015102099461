import React, { Component, Fragment } from "react";
import AlertComponent from "../../common/AlertComponent";
import GrantProgressTemplateForm from "./GrantProgressTemplateForm";
import HttpUtil from "../../common/HttpUtil";
import { GRANT_PROGRESS_TEMPLATES_API } from "../../common/Constants";
import Redirect from "react-router-dom/Redirect";
import * as Yup from "yup";

// Yup.addMethod(Yup.array, "unique", function(message, mapper = a => a) {
//   return this.test("unique", message, function(list) {
//     console.log("list:", list);
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });

function getFieldName(stringValue) {
  if (!stringValue) {
    return null;
  }

  let text = stringValue.toLowerCase();
  text = text.split(" ");
  let name = "";
  for (let i = 1; i < text.length; i++) {
    let txt = text[i];
    txt = txt.charAt(0).toUpperCase() + txt.substring(1);
    text[i] = txt;
  }
  name = text.join("");
  return name;
}

Yup.addMethod(Yup.array, "unique", function(message, path) {
  // console.log("message:", message, "path:", path);
  return this.test("unique", message, function(list) {
    // const mapper = x => lodash.get(x, path);
    // console.log(
    //   "createError:",
    //   this.createError({ path: `[${idx}].${path}`, message })
    // );
    const fieldInfo = {};
    let duplicate = {};
    list.forEach((lst, index) => {
      const name = getFieldName(lst.name);
      // console.log("!fieldInfo[name]:", !fieldInfo[name]);
      if (!fieldInfo[name]) {
        fieldInfo[name] = index;
      } else {
        // duplicate[name] = fieldInfo[name];
        duplicate = {
          path: `${path}.[${fieldInfo[name]}].${name}`,
          message
        };
      }
    });
    const errors = this.createError(duplicate);
    console.log("erros:", errors);
    return errors;
    // return this.createError({ path: `[${idx}].${path}`, message });
  });
});

// Yup.addMethod(Yup.array, "unique", function(message) {
//   return this.test("unique", message, function(list) {
// const fieldInfo = {};
// const duplicate = {};
// list.forEach((lst, index) => {
//   const name = getFieldName(lst.name);
//   console.log("!fieldInfo[name]:", !fieldInfo[name]);
//   if (!fieldInfo[name]) {
//     fieldInfo[name] = index;
//   } else {
//     duplicate[name] = fieldInfo[name];
//   }
// });

//     console.log("fieldInfo:", fieldInfo);
//     console.log("duplicate:", duplicate);
//     console.log("list:", list);
//     return true;
//   });
// });

const DocumentTemplateKeys = Yup.array()
  .of(
    Yup.object().shape({
      documentType: Yup.string()
        .label("Document Type")
        .required(),
      required: Yup.boolean()
        .label("Required")
        .required(),
      allowMultiple: Yup.boolean()
        .label("Allow Multiple Required")
        .required()
    })
  )
  .label("Reviewer Documents")
  .optional();

const LabelTemplateKeys = Yup.array()
  .of(
    Yup.object().shape({
      label: Yup.string()
        .label("Label Name")
        .required(),
      required: Yup.boolean()
        .label("Required")
        .required()
    })
  )
  .optional();

const FieldTemplatesKeys = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string()
        .label("Field Name")
        .required(),
      type: Yup.string()
        .label("Field Type")
        .required(),
      required: Yup.boolean()
        .label("Required")
        .required()
    })
  )
  .unique("Duplicate Field Name", "fieldTemplates")
  .optional();

const GRANT_PROGRESS_TEMPLATE_SCHEMA = Yup.object().shape({
  reviewTemplate: Yup.object()
    .shape({
      documents: DocumentTemplateKeys,
      checklist: LabelTemplateKeys
    })
    .required(),
  closureTemplate: Yup.object()
    .shape({
      documents: DocumentTemplateKeys,
      checklist: LabelTemplateKeys,
      fieldTemplates: FieldTemplatesKeys
    })
    .required(),
  terminationTemplate: Yup.object()
    .shape({
      documents: DocumentTemplateKeys,
      checklist: LabelTemplateKeys
    })
    .required(),
  name: Yup.string()
    .label("Template Name")
    .required()
});

class GrantProgressTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        incubator: null,
        reviewTemplate: {
          documents: [
            {
              documentType: "",
              allowMultiple: false,
              required: false
            }
          ],
          checklist: [
            {
              label: "",
              required: false
            }
          ]
        },
        closureTemplate: {
          documents: [
            {
              documentType: "",
              allowMultiple: false,
              required: false
            }
          ],
          checklist: [
            {
              label: "",
              required: false
            }
          ],
          fieldTemplates: [
            {
              name: "",
              type: "",
              required: false
            }
          ]
        },
        terminationTemplate: {
          documents: [
            {
              documentType: "",
              allowMultiple: false,
              required: false
            }
          ],
          checklist: [
            {
              label: "",
              required: false
            }
          ]
        },

        status: "",
        name: "",
        action: ""
      },
      newTemplate: null,
      editPage: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      alertFor: "",
      alertWithTitle: ""
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      alertFor: "",
      alertWithTitle: ""
    });
  };

  createGrantProgressTemplate = (values, { setSubmitting }) => {
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      GRANT_PROGRESS_TEMPLATES_API,
      headers,
      values,
      data => {
        setSubmitting(false);
        this.setState({
          editPage: data._id,
          message: "Grant Progress Template is created successfully."
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  updateGrantProgressTemplate = (values, { setSubmitting }) => {
    const id = this.state.initialValues._id;
    const headers = { "Content-Type": "application/json" };
    setSubmitting(false);

    HttpUtil.put(
      `${GRANT_PROGRESS_TEMPLATES_API}/${id}`,
      headers,
      values,
      data => {
        setSubmitting(false);
        this.setState({
          editPage: data._id,
          message: "Grant Progress Template is updated successfully."
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  handleAlertFor = () => {
    // const alertFor = this.state.alertFor
    const values = this.state.values;
    const setSubmitting = this.state.setSubmitting;
    this.handleSubmit(values, { setSubmitting });
  };

  handleSubmit = (values, { setSubmitting }) => {
    // console.log("submit:values:", JSON.stringify(values, null, 2));
    if (values.action === "FINALIZE") {
      if (!this.state.alertFor) {
        return this.setState({
          alertType: "Confirmation",
          showAlert: true,
          alertColor: "primary",
          alertMessage: `Once the template has been finalized, it can't be changed. Do you want to proceed ?`,
          alertFor: 101, // FINALIZE = 101
          alertWithTitle: "Finalize Template",
          values: values,
          setSubmitting: setSubmitting
        });
      }
    }

    values.status = values.action;
    delete values.action;

    if (this.state.newTemplate) {
      this.createGrantProgressTemplate(values, { setSubmitting });
    } else {
      this.updateGrantProgressTemplate(values, { setSubmitting });
    }
  };

  getTemplate = id => {
    const url = `${GRANT_PROGRESS_TEMPLATES_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        const initialValues = { ...data };
        this.setState({ newTemplate: false, initialValues });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  loadUrlLocationState = () => {
    const location = this.props.location;

    if (!location) {
      return;
    }

    if (location.state !== undefined && location.state.submitted) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: location.state.message
      });
      window.scrollTo(0, 0);
    }
  };
  componentDidMount = () => {
    this.loadUrlLocationState();
    if (this.props.match.params.id !== undefined) {
      let id = this.props.match.params.id;
      this.getTemplate(id);
    } else {
      this.setState({ newTemplate: true });
    }
  };
  render() {
    const { newTemplate, editPage } = this.state;
    if (newTemplate === null) {
      return null;
    }

    if (editPage) {
      const locationState = {
        pathname: `/funding/grant-progress-template/edit/${editPage}`,
        state: {
          submitted: true,
          message: this.state.message
        }
      };
      return <Redirect to={locationState} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      // confirm: this.okConfirmUpdate,
      confirm: this.handleAlertFor,
      alertWithTitle: this.state.alertWithTitle
    };

    const props = {
      initialValues: this.state.initialValues,
      handleSubmit: this.handleSubmit,
      validationSchema: GRANT_PROGRESS_TEMPLATE_SCHEMA
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <GrantProgressTemplateForm {...props} />
      </Fragment>
    );
  }
}

export default GrantProgressTemplate;
