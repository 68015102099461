import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import {
  ASSETS_API,
  INTERNAL_ORGS_API,
  RENTAL_RATE_UNIT_TYPES_API,
  RENTAL_RATE_CARD_TYPES_API,
  ASSET_TYPES_API
} from "../../common/Constants";
import AssetForm from "./AssetForm";
import { convertDateMonthNameYear } from "../../lease/DateUtil";
import { ASSET_RATE_TYPES } from "../../common/LookupConstants";
import ErrorPage from "../../common/error.page";

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; // for Mobile Numbers

const ASSET_SCHEMA = Yup.object().shape({
  assetId: Yup.string().required("Asset Id is required"),
  assetName: Yup.string().required("Asset Name is required"),
  assetType: Yup.object()
    .shape({
      value: Yup.string()
        .label("Asset Type")
        .required(),
      label: Yup.string().optional()
    })
    .required(),
  assetCategory: Yup.string().required("Asset Category is required"),
  assetUsage: Yup.string().required("Asset Usage is required"),
  ownerName: Yup.string().required("Asset Owner is required"),
  description: Yup.string().optional(),
  assetDetailUpload: Yup.mixed().optional("Asset Detail Upload is required"), // upload file
  specification: Yup.mixed().required("Specification is required"), // upload file
  operationManual: Yup.mixed().required("Operation Manual is required"), // upload file
  serialNumber: Yup.string().required("Serial Number is required"),

  unitTypes: Yup.array()
    .label("Unit Type")
    .when("requireUnitType", {
      is: true,
      then: Yup.array()
        .min(1)
        .label("Unit Type")
        .required()
    }),
  // notes: Yup.string().required("Notes is required"),
  status: Yup.string().required("Status is required"),
  assetOwnerContact: Yup.object().shape({
    name: Yup.string()
      .label("Asset Owner Name")
      .required(),
    // email: Yup.string().required("Email is required"),
    phone: Yup.string()
      .label("Phone")
      .notRequired()
      .test("Phone", "Please enter valid mobile number", value => {
        if (value) {
          const schema = Yup.string().matches(
            PHONE_REG_EXP,
            "Please enter valid mobile number"
          );

          return schema.isValidSync(value);
        }
        return true;
      })
  }),
  incubatorContact: Yup.object().shape({
    name: Yup.string()
      .label("Incubator Name")
      .required(),
    // email: Yup.string().required("Email is required"),
    phone: Yup.string()
      .label("Phone")
      .notRequired()
      .test("Phone", "Please enter valid mobile number", value => {
        if (value) {
          const schema = Yup.string().matches(
            PHONE_REG_EXP,
            "Please enter valid mobile number"
          );

          return schema.isValidSync(value);
        }
        return true;
      })
  }),
  manufacture: Yup.object()
    .shape({
      modelNumber: Yup.string().label("Model Number"),
      manufacturerName: Yup.string().label("Manufacturer Name"),
      manufacturedYear: Yup.string().label("Manufacture Year"),
      vendorName: Yup.string()
        .label("Vendor Name")
        .optional(),
      vendorEmail: Yup.string()
        .email()
        .label("Vendor Email")
        .optional(),
      vendorPhone: Yup.string()
        .label("Vendor Phone")
        .optional()
      // vendorPrice: Yup.number()
      //   .label("Charge")
      //   .optional()
      //   .typeError("Asset Price")
      //   .nullable()
    })
    .label("Manufacture")
    .optional(),
  location: Yup.object()
    .shape({
      campus: Yup.string()
        .label("Institution Name")
        .required(),
      building: Yup.string()
        .label("Building Name")
        .optional(),
      facility: Yup.string()
        .label("Lab/Center Name")
        .optional(),
      floor: Yup.string()
        .label("Floor number")
        .optional(),
      room: Yup.string()
        .label("Room number")
        .optional(),
      department: Yup.string()
        .label("Department Name")
        .required()
    })
    .label("Location")
    .required()
  //
  // rates: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       rate: Yup.mixed().test(
  //         "rate-empty",
  //         "Rate should be a positive number",
  //         value => value && Number(value) > 0
  //       ),
  //       unit: Yup.mixed().test(
  //         "unit-empty",
  //         "Unit should be a positive number",
  //         value => value && Number(value) > 0
  //       ),
  //       unitType: Yup.string()
  //         .label("Unit Type")
  //         .required()
  //     })
  //   )
  //   .test(
  //     "alteast-one-rate",
  //     "Atleast one rate information should be entered",
  //     values => {
  //       return values.some(
  //         value => !Number.isNaN(value.rate) && value.rate > 0
  //       );
  //     }
  //   )
});

class Asset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asset: {
        _id: "",
        assetId: "",
        assetName: "",
        assetType: "",
        assetCategory: "",
        serialNumber: "",
        serialNumberDisable: false, // custom validation
        requireUnitType: false,
        availableHours: 8,
        unitTypes: [],
        rateTypes: [],
        description: "",
        assetDetailUpload: "",
        specification: "",
        operationManual: "",
        status: "ACTIVE",
        assetUsage: "ON_PREMISE",
        ownerName: "",
        notes: "",

        rates: [],
        productImages: [],

        assetOwnerContact: {
          name: "",
          phone: "",
          email: ""
        },
        incubatorContact: {
          name: "",
          phone: "",
          email: ""
        },

        location: {
          campus: "",
          building: "",
          facility: "",
          floor: "",
          room: "",
          department: ""
        },
        manufacture: {
          modelNumber: "",
          manufacturerName: "",
          manufacturedYear: "",
          vendorName: "",
          vendorEmail: "",
          vendorPhone: "",
          vendorPrice: 0,
          assetInvoice: ""
        },

        action: ""
      },

      internalOrgs: [],
      uploadedProductImagesInfo: [],
      rentalRateUnitTypes: [],
      assetTypes: [],
      rentalRateCardTypes: [],

      permanentFailure: false,
      newAsset: null,
      saveAsset: false,
      assetId: null,
      inputError: {}
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  handleUpdate = (formData, setSubmitting) => {
    const id = this.state.asset._id;
    const url = `${ASSETS_API}/${id}`;
    HttpUtil.put(
      url,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.setState({
          assetId: id,
          asset: data,
          saveAsset: true
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message, status === 403 || status === 404);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString(), true);
      }
    );
  };

  handleRemoveImage = documentId => {
    const assetId = this.state.asset._id;
    const url = `${ASSETS_API}/${assetId}/remove-image/${documentId}`;
    HttpUtil.delete(
      url,
      {},
      data => {
        let uploadedProductImagesInfo = [];
        const productImages = data.productImages || [];
        productImages.forEach(img => {
          const preview = {
            url: `${ASSETS_API}/${data._id}/product-images/${img._id}`,
            _id: img._id,
            name: img.filename,
            updatedAt: convertDateMonthNameYear(img.uploadDate),
            size: bytesToSize(img.length)
          };
          uploadedProductImagesInfo.push(preview);
        });

        const asset = { ...this.state.asset };
        asset.productImages = productImages;

        this.setState({ saveAsset: true, assetId: data._id });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getAllInternalOrgs = () => {
    const url = INTERNAL_ORGS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ internalOrgs: data });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getAllRentalRateCardTypes = () => {
    const url = RENTAL_RATE_CARD_TYPES_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ rentalRateCardTypes: data });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getAllRentalUnitTypes = () => {
    const url = RENTAL_RATE_UNIT_TYPES_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ rentalRateUnitTypes: data });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  createUnitType = (selectedUnitType, values) => {
    const url = RENTAL_RATE_UNIT_TYPES_API;
    const request = {
      unitType: selectedUnitType.label
    };
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      request,
      data => {
        const asset = { ...values };
        const selectedUnitType = {
          label: data.unitType.name,
          value: data.unitType._id
        };
        asset.unitTypes.push(selectedUnitType);

        this.setState({ asset, rentalRateUnitTypes: data.unitTypes });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getAllAssetTypes = () => {
    const url = ASSET_TYPES_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ assetTypes: data });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  createAssetType = (selectedAssetType, values) => {
    const url = ASSET_TYPES_API;
    const request = {
      assetType: selectedAssetType.label
    };
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      request,
      data => {
        const asset = { ...values };
        const selectedAssetType = {
          label: data.assetType.name,
          value: data.assetType._id
        };
        asset.assetType = selectedAssetType;

        const list = data.assetTypes.map(at => {
          return {
            label: at.name,
            value: at._id
          };
        });

        this.setState({ asset, assetTypes: list });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  setDataFromServer = data => {
    let uploadedProductImagesInfo = [];
    const productImages = data.productImages || [];
    productImages.forEach(img => {
      const preview = {
        url: `${ASSETS_API}/${data._id}/product-images/${img._id}`,
        _id: img._id,
        name: img.filename,
        updatedAt: convertDateMonthNameYear(img.uploadDate),
        size: bytesToSize(img.length)
      };
      uploadedProductImagesInfo.push(preview);
    });

    const assOwnCt = data.assetOwnerContact || {};
    const assetOwnerContact = {
      name: assOwnCt.name || "",
      phone: assOwnCt.phone || "",
      email: assOwnCt.email || ""
    };

    const incCt = data.incubatorContact || {};
    const incubatorContact = {
      name: incCt.name || "",
      phone: incCt.phone || "",
      email: incCt.email || ""
    };

    const loc = data.location || {};
    const location = {
      campus: loc.campus || "",
      building: loc.building || "",
      facility: loc.facility || "",
      floor: loc.floor || "",
      room: loc.room || "",
      department: loc.department || ""
    };

    const manfatre = data.manufacture || {};
    const manufacture = {
      modelNumber: manfatre.modelNumber || "",
      manufacturerName: manfatre.manufacturerName || "",
      manufacturedYear: manfatre.manufacturedYear || "",
      vendorName: manfatre.vendorName || "",
      vendorEmail: manfatre.vendorEmail || "",
      vendorPhone: manfatre.vendorPhone || "",
      vendorPrice: manfatre.vendorPrice || "",
      assetInvoice: manfatre.assetInvoice || ""
    };

    const rentalRates = data.rentalRates.map(rtl => {
      let unitType = "";
      if (rtl.unitType && rtl.unitType._id) {
        unitType = {
          label: rtl.unitType.unitType,
          value: rtl.unitType._id
        };
      }

      let rateCard = "";
      if (rtl.rateCard && rtl.rateCard._id) {
        rateCard = {
          label: rtl.rateCard.rateCard,
          value: rtl.rateCard._id
        };
      }

      return {
        _id: rtl._id,
        rateCard,
        rate: rtl.rate,
        quantity: rtl.quantity,
        unitType
      };
    });

    let typeOfAsset = {};
    if (data.assetType) {
      const assetType = data.assetType;
      typeOfAsset.value = assetType._id;
      typeOfAsset.label = assetType.name;
    }
    let unitTypes = [];
    if (data.unitTypes && data.unitTypes.length > 0) {
      unitTypes = data.unitTypes.map(unitType => {
        return {
          label: unitType.name,
          value: unitType._id
        };
      });
    }
    let rateTypes = [];
    if (data.rateTypes && data.rateTypes.length > 0) {
      rateTypes = ASSET_RATE_TYPES.filter(rtype =>
        data.rateTypes.includes(rtype.value)
      );
    }

    const uploadedOtherDocuments = {};

    if (data.assetDetailUpload && data.assetDetailUpload._id) {
      uploadedOtherDocuments.assetDetailUpload = data.assetDetailUpload;
    }

    if (data.operationManual && data.operationManual._id) {
      uploadedOtherDocuments.operationManual = data.operationManual;
    }

    if (data.specification && data.specification._id) {
      uploadedOtherDocuments.specification = data.specification;
    }

    if (data.manufacture.assetInvoice && data.manufacture.assetInvoice._id) {
      uploadedOtherDocuments.assetInvoice = data.manufacture.assetInvoice;
    }

    const asset = {
      _id: data._id,
      productImages: productImages,
      assetId: data.assetId,
      assetName: data.assetName,
      assetType: typeOfAsset,
      assetCategory: data.assetCategory,
      assetUsage: data.assetUsage,
      serialNumber: data.serialNumber,
      serialNumberDisable: data.serialNumber ? true : false, // custom validation
      status: data.status,
      requireUnitType: data.requireUnitType,
      availableHours: data.availableHours,
      unitTypes,
      rateTypes,
      description: data.description,
      assetDetailUpload: data.assetDetailUpload,
      specification: data.specification,
      operationManual: data.operationManual,
      ownerName: data.ownerName,
      notes: data.notes,

      assetOwnerContact,
      incubatorContact,
      location,
      manufacture,
      rates: rentalRates,
      uploadedOtherDocuments
    };

    this.setState({
      asset,
      uploadedProductImagesInfo,
      newAsset: false
    });
  };

  getAssetById = id => {
    const message =
      this.props.location.state &&
      this.props.location.state.save &&
      this.props.location.state.message;
    if (message) {
      this.setState({
        showAlert: true,
        alertType: "Default",
        alertColor: "success",
        alertMessage: message
      });
      window.scrollTo(0, 0);
    }

    const url = `${ASSETS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setDataFromServer(data);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => {
        // this.handleApiFailed(error.toString(), true)
      }
    );
  };

  componentDidMount() {
    if (this.props.match && this.props.match.params.id !== undefined) {
      const id = this.props.match.params.id;
      this.getAssetById(id);
    } else {
      this.setState({ newAsset: true });
    }

    this.getAllInternalOrgs();
    this.getAllRentalUnitTypes();
    this.getAllRentalRateCardTypes();
    this.getAllAssetTypes();
  }

  /** Formik props Start ********************************/
  checkAddValue = (currentObj, newObject, fieldName) => {
    newObject[fieldName] = currentObj[fieldName] || "";
  };

  transformToApiModel = asset => {
    const newAsset = {};
    this.checkAddValue(asset, newAsset, "assetId");
    this.checkAddValue(asset, newAsset, "assetName");
    if (asset.assetType && asset.assetType.value) {
      newAsset.assetType = asset.assetType.value;
    }
    this.checkAddValue(asset, newAsset, "assetCategory");
    this.checkAddValue(asset, newAsset, "assetUsage");
    this.checkAddValue(asset, newAsset, "assetUsage");
    this.checkAddValue(asset, newAsset, "description");
    this.checkAddValue(asset, newAsset, "serialNumber");
    this.checkAddValue(asset, newAsset, "status");
    newAsset.requireUnitType = asset.requireUnitType || false;
    newAsset.availableHours = asset.availableHours || "";
    newAsset.unitTypes = [];
    if (asset.unitTypes && asset.unitTypes.length > 0) {
      newAsset.unitTypes = asset.unitTypes.map(utype => utype.value);
    }
    newAsset.rateTypes = [];
    if (asset.rateTypes && asset.rateTypes.length > 0) {
      newAsset.rateTypes = asset.rateTypes.map(rtype => rtype.value);
    }
    this.checkAddValue(asset, newAsset, "ownerName");
    this.checkAddValue(asset, newAsset, "notes");

    const assetOwnerContact = asset.assetOwnerContact;
    newAsset.assetOwnerContact = {
      name: assetOwnerContact.name || null,
      phone: assetOwnerContact.phone || null,
      email: assetOwnerContact.email || null
    };

    const incubatorContact = asset.incubatorContact;
    newAsset.incubatorContact = {
      name: incubatorContact.name || null,
      phone: incubatorContact.phone || null,
      email: incubatorContact.email || null
    };

    const location = asset.location;
    newAsset.location = {
      campus: location.campus || null,
      building: location.building || null,
      facility: location.facility || null,
      floor: location.floor || null,
      room: location.room || null,
      department: location.department || null
    };

    const manufacture = asset.manufacture;
    newAsset.manufacture = {
      modelNumber: `${manufacture.modelNumber}` || null,
      manufacturerName: manufacture.manufacturerName || null,
      manufacturedYear: `${manufacture.manufacturedYear}` || null,
      vendorName: `${manufacture.vendorName}` || null,
      vendorEmail: `${manufacture.vendorEmail}` || null,
      vendorPhone: `${manufacture.vendorPhone}` || null,
      vendorPrice: `${manufacture.vendorPrice}` || null
    };

    const rentalRates = asset.rates.map(rtl => {
      let unitType = "";
      if (rtl.unitType.value) {
        unitType = rtl.unitType.value;
      } else {
        unitType = rtl.unitType;
      }

      let rateCard = "";
      if (rtl.rateCard.value) {
        rateCard = rtl.rateCard.value;
      }
      return {
        rateCard,
        rate: rtl.rate,
        quantity: rtl.quantity,
        unitType
      };
    });

    newAsset.rentalRates = rentalRates;

    this.checkAddValue(asset, newAsset, "action");

    return newAsset;
  };

  handleSave = (formData, setSubmitting) => {
    HttpUtil.post(
      ASSETS_API,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.setState({
          saveAsset: true,
          asset: data,
          assetId: data._id
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message, status === 403 || status === 404);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString(), true);
      }
    );
  };

  getFormData = values => {
    const formData = new FormData();
    const uploadedImages = values.uploadedImages || [];

    for (let i = 0; i < uploadedImages.length; i++) {
      this.addDocs(formData, "productImages", uploadedImages[i]);
    }
    this.addDocs(formData, "assetDetailUpload", values.assetDetailUpload);
    this.addDocs(formData, "specification", values.specification);
    this.addDocs(formData, "operationManual", values.operationManual);
    this.addDocs(formData, "assetInvoice", values.manufacture.assetInvoice);

    formData.append(
      "message",
      JSON.stringify(this.transformToApiModel(values))
    );

    return formData;
  };

  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    const asset = { ...values };
    asset.action = "SUBMIT";

    const formData = this.getFormData(asset);

    if (this.state.newAsset) {
      this.handleSave(formData, setSubmitting);
    } else {
      this.handleUpdate(formData, setSubmitting);
    }
  };
  /**
   * When onClick 'Save as Draft' server side validation error is occured.
   * We need minimum required fields are should be enter then submit form as 'save as draft'
   * Pending task
   */

  handleSaveAsDraft = values => {
    const setSubmitting = () => true;

    const asset = { ...values };
    asset.action = "SAVE_AS_DRAFT";
    const formData = this.getFormData(asset);

    if (this.state.newAsset) {
      this.handleSave(formData, setSubmitting);
    } else {
      this.handleUpdate(formData, setSubmitting);
    }
  };
  /** Formik props End ********************************/

  render() {
    const { newAsset, permanentFailure, alertMessage } = this.state;
    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }
    if (newAsset === null) {
      return null;
    }
    if (this.state.saveAsset) {
      const asset = this.state.asset;
      const assetName = asset.assetType ? `"${asset.assetType.name}"` : "";

      const message = this.state.newAsset
        ? `New Asset has been added successfully.`
        : `Asset ${assetName} has been updated successfully.`;
      const redirectState = {
        pathname: `/service-mgmt/assets/edit/${this.state.assetId}`,
        state: { save: true, message }
      };
      return <Redirect to={redirectState} />;
    }

    const assetFormProps = {
      newAsset: this.state.newAsset,
      internalOrgs: this.state.internalOrgs,
      uploadedProductImagesInfo: this.state.uploadedProductImagesInfo,
      rentalRateUnitTypes: this.state.rentalRateUnitTypes,
      assetTypes: this.state.assetTypes,
      rentalRateCardTypes: this.state.rentalRateCardTypes,
      inputError: this.state.inputError,
      createUnitType: this.createUnitType,
      createAssetType: this.createAssetType,

      // formik props
      initialValues: this.state.asset,
      validationSchema: ASSET_SCHEMA,
      handleSubmit: this.handleSubmit,
      handleSaveAsDraft: this.handleSaveAsDraft,

      // events
      handleRemoveImage: this.handleRemoveImage
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <AssetForm {...assetFormProps} />
      </Fragment>
    );
  }
}

export default Asset;
