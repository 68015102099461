import React, { Component, Fragment } from "react";
import { GENDERS_API } from "../../../common/Constants";
import HttpUtil from "../../../common/HttpUtil";
import { getYears } from "../../../lease/DateUtil";
import {
  DEFAULT_COUNTRY,
  DEFAULT_STATE
} from "../../../common/data/DefaultData";
import { EDU_QUALIFICATION_TYPES } from "../../../common/LookupConstants";
import { titleCaseForOneCaseString } from "../../../common/Util";

const YEARS = getYears();

class Promoter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoters: [],
      inputError: {},
      genders: []
    };
  }

  getGenders = () => {
    const url = GENDERS_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ genders: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertType: "Default",
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  addNewPromoter = () => {
    const address = {
      streetLine1: "",
      streetLine2: "",
      landmark: "",
      city: "",
      state: DEFAULT_STATE,
      postalCode: "",
      country: DEFAULT_COUNTRY,
      addressProof: null,
      status: "",
      type: ""
    };

    const promoter = {
      individual: {
        firstName: "",
        gender: null,
        experience: "",
        education: [
          {
            year: "",
            qualification: "",
            degree: "",
            institution: "",
            alumni: false
          }
        ],
        references: "",
        indvPhoto: null,
        addresses: [address],
        identificationDocs: [],
        promoterAadhaar: null,
        emails: [],
        phones: []
      },
      designation: "",
      shareholding: "",
      isFounder: false
    };
    return promoter;
  };

  addMorePromoter = () => {
    const newPromoter = this.addNewPromoter();
    const promoters = this.state.promoters;
    promoters.push(newPromoter);
    this.setState({ promoters });
  };

  removePromoter = (index, e) => {
    e.preventDefault();
    let promoters = [...this.state.promoters];
    if (promoters.length === 0) {
      return;
    }
    promoters = promoters.filter((pro, idx) => idx !== index);
    this.setStatePromoters(promoters);
  };

  addressHandler = (index, e) => {
    let promoters = this.state.promoters;
    let address = promoters[index].individual.addresses[0];
    let targetValue = e.target.value;
    if (e.target.type === "file") {
      targetValue = e.target.files[0];
    }
    address = address ? { ...address } : {};
    address[e.target.name] = targetValue;
    promoters[index].individual.addresses[0] = address;
    this.setStatePromoters(promoters);
  };

  handleIndvIdDocs = (index, e) => {
    let promoters = this.state.promoters;
    let idDocs = promoters[index].individual.identificationDocs[0];
    idDocs = idDocs ? { ...idDocs } : {};
    let targetValue = e.target.value;
    if (e.target.type === "file") {
      targetValue = e.target.files[0];
    }
    idDocs[e.target.name] = targetValue;
    promoters[index].individual.identificationDocs[0] = idDocs;
    this.setStatePromoters(promoters);
  };

  handleIndividualNameChange = (index, e) => {
    e.preventDefault();
    const targetName = e.target.name;
    let targetValue = e.target.value;

    let promoters = [...this.state.promoters];
    let promoter = promoters[index];
    const prevValueFieldName = `${targetName}PrevValue`;

    const prevValue = promoter.individual[prevValueFieldName];

    if (prevValue === targetValue) {
      promoter.individual[targetName] = targetValue;
    } else {
      promoter.individual[targetName] = titleCaseForOneCaseString(targetValue);
    }

    promoters[index] = promoter;

    this.setStatePromoters(promoters);
  };

  handleIndividualInfoChange = (index, e) => {
    e.preventDefault();
    const targetName = e.target.name;
    let targetValue = e.target.value;

    if (targetName === "gender" && targetValue === "") {
      targetValue = null;
    } else if (e.target.type === "file") {
      targetValue = e.target.files[0];
    }

    let promoters = [...this.state.promoters];
    let promoter = promoters[index];

    promoter.individual[targetName] = targetValue;

    promoters[index] = promoter;

    this.setStatePromoters(promoters);
  };

  arrayHandler = (index, e) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    let promoters = [...this.state.promoters];
    let promoter = promoters[index];
    const individual = promoter.individual;
    if (targetName === "email") {
      let email = individual.emails[0];
      email = {
        [targetName]: targetValue,
        type: "PERSONAL",
        status: "ACTIVE"
      };
      individual.emails[0] = email;
    }

    if (targetName === "phone") {
      let phone = individual.phones[0];
      phone = {
        [targetName]: targetValue,
        type: "PERSONAL",
        status: "ACTIVE"
      };
      individual.phones[0] = phone;
    }

    if (targetName === "aadhar") {
      let idDocs = individual.identificationDocs[0];
      idDocs = {
        number: targetValue,
        type: "AADHAAR",
        status: ""
      };

      individual.identificationDocs[0] = idDocs;
    }

    promoter.individual = individual;
    promoters[index] = promoter;

    this.setStatePromoters(promoters);
  };

  handleFounderChange = (index, e) => {
    const promoters = [...this.state.promoters];
    const promoter = promoters[index];
    if (e.target.type === "checkbox") {
      promoter[e.target.name] = e.target.checked;
    }
    this.setStatePromoters(promoters);
  };

  handleEducationInfoChange = (index, e) => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
    }

    const promoters = [...this.state.promoters];
    const promoter = promoters[index];
    const individual = promoter.individual;
    let education = this.getFirstElement(individual.education, {});
    education = education ? { ...education } : {};

    education[targetName] = targetValue;
    promoter.individual.education[0] = education;

    promoters[index] = promoter;

    this.setStatePromoters(promoters);
  };

  setStatePromoters = promoters => {
    this.setState({ promoters }, this.props.getPromoters(promoters));
  };

  handleInputChange = (index, e) => {
    e.preventDefault();
    const targetName = e.target.name;
    let targetValue = e.target.value;

    let promoters = [...this.state.promoters];
    let promoter = promoters[index];

    promoter[targetName] = targetValue;

    promoters[index] = promoter;

    this.setStatePromoters(promoters);
  };

  componentDidMount = () => {
    let existingPromoters = this.props.promoters;
    if (existingPromoters.length !== 0) {
      let promoters = existingPromoters.map(ptmr => {
        let promoter = {};
        let individual = {};

        individual = {
          firstName: ptmr.individual.firstName
            ? ptmr.individual.firstName
            : null,
          gender: ptmr.individual.gender ? ptmr.individual.gender : null,
          experience: ptmr.individual.experience
            ? ptmr.individual.experience
            : null,
          education: ptmr.individual.education ? ptmr.individual.education : [],
          references: ptmr.individual.references
            ? ptmr.individual.references
            : null,
          photo: ptmr.individual.photo ? ptmr.individual.photo : null,
          addresses: ptmr.individual.addresses ? ptmr.individual.addresses : [],
          identificationDocs: ptmr.individual.identificationDocs
            ? ptmr.individual.identificationDocs
            : [],
          emails: ptmr.individual.emails ? ptmr.individual.emails : [],
          phones: ptmr.individual.phones ? ptmr.individual.phones : []
        };

        promoter.individual = individual;
        promoter._id = ptmr._id;
        promoter.designation = ptmr.designation;
        promoter.shareholding = ptmr.shareholding;
        promoter.isFounder = ptmr.isFounder;
        return promoter;
      });
      this.setState({ promoters: promoters });
    } else {
      this.addMorePromoter();
    }
    this.getGenders();
  };

  getFirstElement(arr, defaultValue) {
    return arr && arr.length > 0 ? arr[0] : defaultValue;
  }

  render() {
    const { promoters, genders } = this.state;

    const {
      states,
      downloadDocument,
      countries,
      promotersFieldsError
    } = this.props;

    if (genders.length === 0) {
      // return null; // temp hided
    }

    const promoterList = promoters.map((promoter, index) => {
      const showRemoveHandler = promoters.length > 1;
      const individual = promoter.individual;
      let emailObj = individual.emails[0] ? individual.emails[0] : "";
      let phoneObj = individual.phones[0] ? individual.phones[0] : "";
      let address = individual.addresses[0] ? individual.addresses[0] : {};

      let email = emailObj.email ? emailObj.email : "";
      let phone = phoneObj.phone ? phoneObj.phone : "";
      //let address = addressObj ? addressObj : {};

      let aadhaar = "";
      let aadhaarDocument = "";
      individual.identificationDocs.forEach(idDoc => {
        aadhaar = idDoc.type === "AADHAAR" ? idDoc.number : aadhaar;
        aadhaarDocument =
          idDoc.type === "AADHAAR" && idDoc.document
            ? idDoc.document
            : aadhaarDocument;
      });

      const education = this.getFirstElement(individual.education, {});

      const { qualification, degree, year, institution, alumni } = education;

      let inputError =
        promotersFieldsError[index] && promotersFieldsError[index]
          ? promotersFieldsError[index].individual
          : {};

      let indvInputError =
        promotersFieldsError[index] && promotersFieldsError[index]
          ? promotersFieldsError[index].individual
          : {};

      let addressError =
        indvInputError.addresses && indvInputError.addresses[0]
          ? indvInputError.addresses[0]
          : {};

      let phoneError =
        indvInputError.phones && indvInputError.phones[0]
          ? indvInputError.phones[0]
          : {};

      let emailError =
        indvInputError.emails && indvInputError.emails[0]
          ? indvInputError.emails[0]
          : {};

      let educationError =
        indvInputError.education && indvInputError.education[0]
          ? indvInputError.education[0]
          : {};

      return (
        <Fragment key={index}>
          <div className="row p-0 m-0 edit-page-subsection">
            <div className="col-md-12">
              <div className="card m-4">
                <div className="card-header edit-page-subsection-title border-0">
                  {showRemoveHandler ? (
                    <button
                      type="button"
                      className="close pull-right"
                      aria-label="Close"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={`Remove Promoter ${index + 1}`}
                      onClick={this.removePromoter.bind(this, index)}
                      style={{ color: "#ff0000" }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  ) : null}
                  <h6 className="card-title  mb-0">
                    Promoter {`${index + 1}`}
                  </h6>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-12 mb-3 edit-page-subsection-header-text">
                      Basic Info
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Name
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["firstName"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="name"
                            name="firstName"
                            value={
                              individual.firstName ? individual.firstName : ""
                            }
                            onChange={this.handleIndividualNameChange.bind(
                              this,
                              index
                            )}
                          />
                          <div className="invalid-feedback">
                            Please enter name *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Gender
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <select
                            className="custom-select custom-select-sm"
                            name="gender"
                            value={
                              individual.gender !== null
                                ? individual.gender
                                : ""
                            }
                            onChange={this.handleIndividualInfoChange.bind(
                              this,
                              index
                            )}
                          >
                            <option value="">Select...</option>
                            {genders.map((g, i) => (
                              <option key={i} value={g.value}>
                                {g.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Photo
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="file"
                            name="promoterPhoto"
                            onChange={this.handleIndividualInfoChange.bind(
                              this,
                              index
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {promoter.individual.photo ? (
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <div className=" col-12 text-sm-left edit-page-label">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={downloadDocument.bind(
                                this,
                                promoter.individual.photo
                              )}
                            >
                              Download Photo
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3 edit-page-subsection-header-text">
                      Education and Experience
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Edu Qualification
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <select
                            className={
                              educationError["qualification"]
                                ? "custom-select custom-select-sm is-invalid"
                                : "custom-select custom-select-sm"
                            }
                            name="qualification"
                            value={qualification !== null ? qualification : ""}
                            onChange={this.handleEducationInfoChange.bind(
                              this,
                              index
                            )}
                          >
                            <option value="">Select...</option>
                            {EDU_QUALIFICATION_TYPES.map((opt, i) => (
                              <option key={i} value={opt.key}>
                                {opt.value}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            Education qualification required *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Degree
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              educationError["degree"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="education"
                            name="degree"
                            value={degree ? degree : ""}
                            onChange={this.handleEducationInfoChange.bind(
                              this,
                              index
                            )}
                          />
                          <div className="invalid-feedback">
                            Please select promoter education *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Year of Passing
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <select
                            className={
                              educationError["year"]
                                ? "custom-select custom-select-sm is-invalid"
                                : "custom-select custom-select-sm"
                            }
                            name="year"
                            value={year !== null ? year : ""}
                            onChange={this.handleEducationInfoChange.bind(
                              this,
                              index
                            )}
                          >
                            <option value="">Select...</option>
                            {YEARS.map(yyyy => (
                              <option key={yyyy} value={yyyy}>
                                {yyyy}
                              </option>
                            ))}
                          </select>

                          <div className="invalid-feedback">
                            Please select year of passing *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Institution
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            value={institution ? institution : ""}
                            className={
                              educationError["institution"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder=""
                            name="institution"
                            onChange={this.handleEducationInfoChange.bind(
                              this,
                              index
                            )}
                          />
                          <div className="invalid-feedback">
                            Please enter institution name *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          PSG Alumni
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`${index}#alumni`}
                              name="alumni"
                              checked={alumni ? alumni : ""}
                              onChange={this.handleEducationInfoChange.bind(
                                this,
                                index
                              )}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`${index}#alumni`}
                            />
                            Yes
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Experience
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["experience"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="experience"
                            name="experience"
                            value={
                              individual.experience ? individual.experience : ""
                            }
                            onChange={this.handleIndividualInfoChange.bind(
                              this,
                              index
                            )}
                          />

                          <div className="invalid-feedback">
                            Please select promoter experience *.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3 edit-page-subsection-header-text">
                      Shareholding Info
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Founder
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`${index}#isFounder`}
                              name="isFounder"
                              checked={
                                promoter.isFounder ? promoter.isFounder : ""
                              }
                              onChange={this.handleFounderChange.bind(
                                this,
                                index
                              )}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`${index}#isFounder`}
                            />
                            Yes
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Sharehold Percentage
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["shareholding"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder=""
                            name="shareholding"
                            value={
                              promoter.shareholding ? promoter.shareholding : ""
                            }
                            onChange={this.handleInputChange.bind(this, index)}
                          />
                          <div className="invalid-feedback">
                            Please enter shareholding percentage *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Designation
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["designation"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="designation"
                            name="designation"
                            value={
                              promoter.designation ? promoter.designation : ""
                            }
                            onChange={this.handleInputChange.bind(this, index)}
                          />

                          <div className="invalid-feedback">
                            Please select promoter designation *.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" />
                  <div className="row">
                    <div className="col-12 mb-3 edit-page-subsection-header-text">
                      Contact Information
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Mobile
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="number"
                            className={
                              phoneError["phone"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="Mobile"
                            name="phone"
                            value={phone}
                            onChange={this.arrayHandler.bind(this, index)}
                          />
                          {phoneError["phone"] ? null : (
                            <small className="form-text text-muted">
                              Please enter primary mobile number
                            </small>
                          )}

                          <div className="invalid-feedback">
                            Please enter primary mobile number *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Email
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="email"
                            className={
                              emailError["email"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="Email"
                            name="email"
                            value={email}
                            onChange={this.arrayHandler.bind(this, index)}
                          />
                          {emailError["email"] ? null : (
                            <small className="form-text text-muted">
                              Please enter primary email address
                            </small>
                          )}

                          <div className="invalid-feedback">
                            Please enter primary email address *.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3 edit-page-subsection-header-text">
                      Address
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Street Line 1
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              addressError["streetLine1"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="Street line 1"
                            name="streetLine1"
                            value={address.streetLine1}
                            onChange={this.addressHandler.bind(this, index)}
                          />
                          <div className="invalid-feedback">
                            Please enter street line1 *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Street Line 2
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Street line 2"
                            name="streetLine2"
                            value={
                              address.streetLine2 ? address.streetLine2 : ""
                            }
                            onChange={this.addressHandler.bind(this, index)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          City
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              addressError["city"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="City"
                            name="city"
                            value={address.city ? address.city : ""}
                            onChange={this.addressHandler.bind(this, index)}
                          />
                          <div className="invalid-feedback">
                            Please enter city *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Landmark
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              inputError["landmark"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="Landmark"
                            name="landmark"
                            value={address.landmark}
                            onChange={this.addressHandler.bind(this, index)}
                          />
                          <div className="invalid-feedback">
                            Please enter landmark *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          State
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <select
                            className={
                              addressError["state"]
                                ? "custom-select custom-select-sm is-invalid"
                                : "custom-select custom-select-sm"
                            }
                            name="state"
                            value={address.state}
                            onChange={this.addressHandler.bind(this, index)}
                          >
                            <option value="">Select...</option>
                            {states.map((state, i) => (
                              <option key={i} value={state.stateCode}>
                                {state.stateName}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            Please select state name *.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Country
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          {/* <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Country"
                            name="country"
                            value={address.country ? address.country : "India"}
                            onChange={this.addressHandler.bind(this, index)}
                          /> */}
                          <select
                            className={
                              addressError["country"]
                                ? "custom-select custom-select-sm is-invalid"
                                : "custom-select custom-select-sm"
                            }
                            name="country"
                            value={address.country}
                            onChange={this.addressHandler.bind(this, index)}
                          >
                            <option value="">Select...</option>
                            {countries.map((country, i) => (
                              <option key={i} value={country.countryCode}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            Please select country name *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label-required">
                          Postal Code
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className={
                              addressError["postalCode"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="Postal Code"
                            name="postalCode"
                            pattern="[0-9]{6}"
                            value={address.postalCode}
                            onChange={this.addressHandler.bind(this, index)}
                          />
                          <div className="invalid-feedback">
                            Please enter postal code *.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 text-sm-left edit-page-label">
                          Address Proof
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <input
                            type="file"
                            name="addressProofDocument"
                            onChange={this.addressHandler.bind(this, index)}
                          />
                        </div>
                      </div>
                    </div>

                    {address.addressProof ? (
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="form-group row">
                          <div className=" col-12 text-sm-left edit-page-label">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={downloadDocument.bind(
                                this,
                                address.addressProof
                              )}
                            >
                              Download Address Proof
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="col-12 mb-3 edit-page-subsection-header-text">
                      Identification Documents
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-sm-left edit-page-label">
                          Aadhaar
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                          <input
                            type="text"
                            className={
                              inputError["aadhar"]
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            placeholder="1234-1234-1234"
                            name="aadhar"
                            value={aadhaar}
                            onChange={this.arrayHandler.bind(this, index)}
                          />

                          <div className="invalid-feedback">
                            Please enter aadhar *.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <div className="form-group row">
                        <label className="col-form-label col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 text-sm-left edit-page-label">
                          Aadhaar Scanned Copy
                        </label>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                          {aadhaar !== "" && aadhaar !== null ? (
                            <input
                              type="file"
                              name="promoterAadhaar"
                              onChange={this.handleIndvIdDocs.bind(this, index)}
                            />
                          ) : (
                            <input type="file" disabled />
                          )}
                        </div>
                      </div>
                    </div>
                    {aadhaarDocument !== "" ? (
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div className="form-group row">
                          <div className="col-12 text-right">
                            <button
                              className="btn btn-sm btn-info"
                              onClick={downloadDocument.bind(
                                this,
                                aadhaarDocument
                              )}
                            >
                              Download Aadhaar copy
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    });

    return (
      <Fragment>
        {promoterList}
        <div className="row mb-3 text-center">
          <div className="col-12">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={this.addMorePromoter}
            >
              Add More
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Promoter;
