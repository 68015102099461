import React, { Component } from "react";

const renderInputData = data => {
  return data ? data : "";
};

export class IdentificationDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: [],
      inputError: []
    };
  }

  loadStateFromProps = () => {
    const { newRecord, idDocs, idTypes } = this.props;
    const idTypeIndex = {};

    idTypes.forEach((idType, index) => {
      idTypeIndex[idType.code] = index + 1;
    });

    // ids property in state should have same number of records as idtypes sent from props.
    const ids = this.state.ids;
    for (let index = ids.length; index < idTypes.length; index++) {
      ids[index] = {};
    }

    if (!newRecord && idDocs && idDocs.length > 0) {
      idDocs.forEach(idDoc => {
        const index = idTypeIndex[idDoc.type];
        if (index) {
          ids[index - 1].type = idDoc.type;
          ids[index - 1].number = idDoc.number;
          ids[index - 1].document = idDoc.document;
          ids[index - 1].scannedId = null;
        }
      });
    }

    this.setState({ ids });
  };

  handleInputChange = (index, e) => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (e.target.type === "file") {
      targetValue = e.target.files[0];
    }
    let ids = [...this.state.ids];
    ids[index][targetName] = targetValue;

    this.setState({ ids });
  };

  validate = () => {
    const { idTypes } = this.props;
    const { ids } = this.state;

    const inputError = [];
    const idDocs = [];
    let hasError = false;

    idTypes.forEach((idType, index) => {
      inputError[index] = {};

      const value = ids[index].number;

      if (value) {
        idDocs.push({
          type: idType.code,
          number: value,
          status: "ACTIVE",
          scannedId: ids[index].scannedId
        });
      } else if (idType.required) {
        // If id type is required, then show an error.
        // Otherwise ignore the form value.
        inputError[index].number = true;
        hasError = true;
      }
    });

    if (hasError) {
      this.props.onValidate(inputError, "idDocs", null);
    } else {
      this.props.onValidate(null, "idDocs", idDocs);
    }

    this.setState({ inputError });
  };

  download = index => {
    const id = this.state.ids[index];
    const docId =
      id.document && id.document._id ? id.document._id : id.document;
    this.props.downloadDocument(`id-doc/${id.type}/${docId}`);
  };

  componentDidMount = () => {
    this.loadStateFromProps();
  };

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }
    if (this.props.idDocs !== prevState.idDocs) {
      this.loadStateFromProps();
    }
  };

  render() {
    const inputError = this.state.inputError;
    const ids = this.props.idTypes.map((idType, index) => {
      const id = this.state.ids[index] || {};
      // const hasDocument = id.document ? true : false;

      const fileName =
        id.document && id.document.filename
          ? id.document.filename
          : "Not Available";

      const scannedId = id.scannedId || null;

      return (
        <div className="form-group row mb-0" key={index}>
          <label
            className={`col-form-label col-12 col-lg-1 col-xl-1 ${
              idType.required ? "required" : ""
            }`}
          >
            {idType.name}
          </label>
          <div className="col-12 col-lg-3 col-xl-3">
            <input
              type="text"
              className={`form-control form-control-sm ${
                inputError[index] && inputError[index]["number"]
                  ? "is-invalid"
                  : ""
              }`}
              placeholder={`${idType.name} number`}
              name="number"
              value={renderInputData(id.number)}
              onChange={this.handleInputChange.bind(this, index)}
            />
            <div className="invalid-feedback">
              Please enter {idType.name} *.
            </div>
          </div>
          <label className="col-form-label col-12 col-lg-2 col-xl-2">
            {idType.name} Copy
          </label>
          <label className="col-form-label col-12 col-lg-4 col-xl-3">
            {fileName}
            &nbsp;&nbsp;
            <span className="text-info">
              {scannedId ? `New File : "${scannedId.name}"` : null}
            </span>
          </label>
          <div className="col-12 col-lg-4 col-xl-3">
            <div className="upload-btn-wrapper mt-2">
              <button className="btn btn-sm btn-outline-info custom-btn">
                <i className="fas fa-file-upload" />
                &nbsp;Upload
                <input
                  type="file"
                  name="scannedId"
                  onChange={this.handleInputChange.bind(this, index)}
                />
              </button>
            </div>
            &nbsp;
            {id.document && id.document._id ? (
              <div className="download-btn-wrapper mt-2">
                <button
                  className="btn btn-sm btn-outline-info custom-btn"
                  data-toggle="tooltip"
                  title={`"Download Scanned ${idType.name} copy"`}
                  onClick={this.download.bind(this, index)}
                >
                  <i className="fas fa-file-download" />
                  &nbsp; Download
                </button>
              </div>
            ) : null}
          </div>
          {/* <label className="col-form-label col-12 col-lg-2 col-xl-2">
            {idType.name} Copy
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="file"
              name="scannedId"
              onChange={this.handleInputChange.bind(this, index)}
            />
          </div>
          <div className="col-12 col-lg-2 col-xl-2 mt-2 mt-lg-0 mt-xl-0">
            <button
              className={`btn btn-sm btn-${id.document ? "info" : "secondary"}`}
              data-toggle="tooltip"
              data-placement="top"
              title={`"Download Scanned ${idType.name} copy"`}
              disabled={!hasDocument}
              onClick={this.download.bind(this, index)}
            >
              <i className="fas fa-file-download" />
              &nbsp; Download
            </button>
          </div> */}
        </div>
      );
    });

    return (
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <h5 className="h6 card-title mb-0">Identification Documents </h5>
            </div>
          </div>
          <div className="card-body border">{ids}</div>
        </div>
      </div>
    );
  }
}
