import React from "react";
import { Field, ErrorMessage } from "formik";
import Select from "react-select";

import {
  RESIDENTIAL_STATUS,
  INCUBATION_CATEGORY_TYPES,
  SUB_SECTORS,
  INCUBATEE_STAGES,
  INCUBATION_SECTORS,
  GRADUATION_STATUS
} from "../../../common/IncubateeProfileConstants";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}
const IncubateeProfile = props => {
  const { values, errors, touched, setFieldValue } = props;
  const incProErrors = errors.incubateeProfile || {};
  const incProTouched = touched.incubateeProfile || {};
  const incubateeProfile = values.incubateeProfile || {};
  return (
    <div>
      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Incubatee Information</span>
        </div>
      </div>
      <div className="card-body">
        <div className="row mb-2">
          {/* Graduation Status */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Graduation Status
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(incProTouched, incProErrors, "graduationStatus")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="incubateeProfile.graduationStatus"
                  values={incubateeProfile.graduationStatus || ""}
                  disabled
                >
                  <option value="">Select...</option>
                  {GRADUATION_STATUS.map((opt, i) => (
                    <option key={`${i}`} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="incubateeProfile.graduationStatus"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Incubation Sector */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Incubation Sector
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(incProTouched, incProErrors, "incubationSector")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="incubateeProfile.incubationSector"
                  values={incubateeProfile.incubationSector || ""}
                >
                  <option value="">Select...</option>
                  {INCUBATION_SECTORS.map((opt, i) => (
                    <option key={`${i}`} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="incubateeProfile.incubationSector"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          {/* Sub Sector */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Sub Sector
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(incProTouched, incProErrors, "subTechSector")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="incubateeProfile.subTechSector"
                  values={incubateeProfile.subTechSector || ""}
                >
                  <option value="">Select...</option>
                  {SUB_SECTORS.map((opt, i) => (
                    <option key={`${i}`} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="incubateeProfile.subTechSector"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Other Sub sector */}
          {incubateeProfile.subTechSector === "OTHER" ? (
            <div className="col-lg-6 mb-2">
              <div className="form-group row mb-0">
                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                  Other Sub sector
                </label>
                <div className="col-lg-6">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${
                      getError(incProTouched, incProErrors, "otherSubSector")
                        ? "is-invalid"
                        : ""
                    }`}
                    name="incubateeProfile.otherSubSector"
                    values={incubateeProfile.otherSubSector || ""}
                  />

                  <ErrorMessage
                    component="div"
                    name="incubateeProfile.otherSubSector"
                    className="invalid-feedback mb-2"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row mb-2">
          {/* Residential Status */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Residential Status
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(incProTouched, incProErrors, "residentialStatus")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="incubateeProfile.residentialStatus"
                  values={incubateeProfile.residentialStatus || ""}
                >
                  <option value="">Select...</option>
                  {RESIDENTIAL_STATUS.map((opt, i) => (
                    <option key={`${i}`} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="incubateeProfile.residentialStatus"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Incubatee Stage */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Incubatee Stage
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(incProTouched, incProErrors, "incubateeStage")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="incubateeProfile.incubateeStage"
                  values={incubateeProfile.incubateeStage || ""}
                >
                  <option value="">Select...</option>
                  {INCUBATEE_STAGES.map((opt, i) => (
                    <option key={`${i}`} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="incubateeProfile.incubateeStage"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Target Sector */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Target Sector
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(incProTouched, incProErrors, "targetSector")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="incubateeProfile.targetSector"
                  values={incubateeProfile.targetSector || ""}
                />

                <ErrorMessage
                  component="div"
                  name="incubateeProfile.targetSector"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Incubation Category */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Incubation Category
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(incProTouched, incProErrors, "incubationCategory")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="incubateeProfile.incubationCategory"
                  values={incubateeProfile.incubationCategory || ""}
                >
                  <option value="">Select...</option>
                  {INCUBATION_CATEGORY_TYPES.map((opt, i) => (
                    <option key={`${i}`} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="incubateeProfile.incubationCategory"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Relationship Manager */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Relationship Manager
              </label>
              <div className="col-lg-6">
                <div
                  className={`${
                    getError(incProTouched, incProErrors, "relationshipManager")
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <Select
                    isClearable
                    options={props.relationshipManagers}
                    name="relationshipManager"
                    onChange={selectedManager => {
                      if (selectedManager) {
                        setFieldValue(
                          "incubateeProfile.relationshipManager",
                          selectedManager
                        );
                      } else {
                        setFieldValue(
                          "incubateeProfile.relationshipManager",
                          ""
                        );
                      }
                    }}
                    value={incubateeProfile.relationshipManager}
                  />
                </div>
                <ErrorMessage
                  component="div"
                  name="incubateeProfile.relationshipManager"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> About</span>
        </div>
      </div>

      <div className="card-body">
        {/* About */}
        <div className="row mb-2">
          <div className="col-lg-12 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label">
                About
              </label>
              <div className="col-lg-9">
                <Field
                  component="textarea"
                  className={`form-control form-control-sm ${
                    getError(incProTouched, incProErrors, "about")
                      ? "is-invalid"
                      : ""
                  }`}
                  rows="6"
                  name="incubateeProfile.about"
                  values={incubateeProfile.about || ""}
                />

                <ErrorMessage
                  component="div"
                  name="incubateeProfile.about"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Business Plan  */}
        {/* <div className="row mb-2">
          <div className="col-lg-12 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label">
                Business Plan
              </label>
              <div className="col-lg-9">
                <Field
                  component="textarea"
                  className={`form-control form-control-sm ${
                    getError(incProTouched, incProErrors, "businessPlan")
                      ? "is-invalid"
                      : ""
                  }`}
                  rows="6"
                  name="incubateeProfile.businessPlan"
                  values={incubateeProfile.businessPlan || ""}
                />

                <ErrorMessage
                  component="div"
                  name="incubateeProfile.businessPlan"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* Business Plan */}
        <div className="row mb-2">
          <div className="col-lg-12 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mt-1">
                Business Plan
              </label>
              <div className="col-lg-6">
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="incubateeProfile.uploadBusinessPlan"
                      aria-describedby="incubateeProfile.uploadBusinessPlan"
                      name="incubateeProfile.uploadBusinessPlan"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue(
                            "incubateeProfile.uploadBusinessPlan",
                            file
                          );
                        }
                        e.target.value = "";
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="incubateeProfile.uploadBusinessPlan"
                    >
                      {incubateeProfile.uploadBusinessPlan
                        ? incubateeProfile.uploadBusinessPlan.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        setFieldValue("incubateeProfile.uploadBusinessPlan", "")
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <small className="text-muted">Attachement only</small>
              </div>
              <div className="col-lg-2">
                {incubateeProfile.businessPlan &&
                incubateeProfile.businessPlan._id ? (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-1"
                    onClick={props.downloadDocument.bind(
                      this,
                      incubateeProfile.businessPlan
                    )}
                  >
                    <i className="fas fa-download mr-1" /> Business Plan
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncubateeProfile;
