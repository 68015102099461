import React from "react";
import { EDU_QUALIFICATION_TYPES_OBJECT } from "../common/LookupConstants";

function EducationList(props) {
  const incubatee = props.incubatee;
  const individual = incubatee.individual;

  const education = individual.education || [];

  return (
    <div>
      <div
        class="card flex-fill"
        style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
      >
        <div class="card-header border-0">
          <h6 class="card-titile mb-0 text-center">Education</h6>
        </div>
        <div class="card-body pt-0">
          <div class="responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th class="display-table-header" scope="col">
                    Edu Qualification
                  </th>
                  <th class="display-table-header" scope="col">
                    Institution
                  </th>
                  <th class="display-table-header" scope="col">
                    Degree
                  </th>
                  <th class="display-table-header" scope="col">
                    PSG Alumni
                  </th>
                  <th class="display-table-header" scope="col">
                    Year of Passing
                  </th>
                  <th class="display-table-header" scope="col">
                    Branch
                  </th>
                </tr>
              </thead>
              <tbody>
                {education.map((edu, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {EDU_QUALIFICATION_TYPES_OBJECT[edu.qualification]}
                      </td>
                      <td>{edu.institution}</td>
                      <td>{edu.degree}</td>
                      <td>{edu.alumni ? "Yes" : "No"}</td>
                      <td>{edu.year}</td>
                      <td>{edu.branch}</td>
                    </tr>
                  );
                })}

                {education.length === 0 ? (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No Financial Information
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationList;
