import React, { Fragment } from "react";
import { Field } from "formik";
import { downloadDocument } from "../../common/Util";

function sort(a, b) {
  const atype = (a.type || "").toLocaleLowerCase();
  const btype = (b.type || "").toLocaleLowerCase();
  if (atype === btype) {
    const aname = (a.name || "").toLocaleLowerCase();
    const bname = (b.name || "").toLocaleLowerCase();
    if (aname < bname) {
      return -1;
    } else if (aname === bname) {
      return 0;
    } else {
      return 1;
    }
  } else if (atype < btype) {
    return -1;
  } else {
    return 1;
  }
}

export const DocumentTemplate = props => {
  const { values, documentTypes, setFieldValue, errors, handleDelete } = props;
  // console.log("errors:", errors);

  const availableDocumentsError =
    (errors && errors.availableDocumentsError) || [];
  const documentsError = (errors && errors.documentsError) || [];

  const hasError =
    availableDocumentsError.length > 0 || documentsError.length > 0;

  const fieldEmpty = values.documentType === "" || values.documentName === "";

  const disabledField =
    hasError || fieldEmpty
      ? { readOnly: "readonly", disabled: "disabled" }
      : {};

  let availableDocuments = values.availableDocuments
    ? [...values.availableDocuments]
    : [];
  availableDocuments.sort(sort);

  return (
    <Fragment>
      <strong className="edit-page-section-header-text d-block">
        Documents
      </strong>

      <ul className="list-group list-group-flush">
        {availableDocuments.map((doc, index) => {
          return (
            <li key={index} className="list-group-item  pl-0 pr-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-fill" style={{ width: "33%" }}>
                  <span className=""> {doc.type}</span>
                </div>

                <div
                  className="align-self-center ml-2 flex-fill"
                  style={{ width: "42%" }}
                >
                  <span className="">{doc.name}</span>
                  <br />
                  <small className="">{doc.document.filename}</small>
                </div>

                <div className="flex-fill text-right" style={{ width: "24%" }}>
                  <i
                    className="fas fa-download fa-md ml-3 text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      downloadDocument(
                        doc.link,
                        msg => window.alert(msg),
                        doc.name
                      )
                    }
                  />
                  {handleDelete ? (
                    <i
                      className="far fa-trash-alt fa-md text-danger  ml-3 mr-3"
                      style={{ cursor: "pointer" }}
                      onClick={handleDelete.bind(this, doc)}
                    />
                  ) : null}
                </div>
              </div>
              {errors.availableDocumentsError &&
              errors.availableDocumentsError[index] ? (
                <div className="text-danger pl-2 mt-1 fa-sm">
                  {errors.availableDocumentsError[index].name}
                </div>
              ) : null}
            </li>
          );
        })}

        {Array.from(values.documents).map((file, index) => {
          return (
            <li key={index} className="list-group-item  pl-0 pr-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-fill" style={{ width: "33.33%" }}>
                  <span className=""> {file.type}</span>
                </div>

                <div
                  className="align-self-center ml-2 flex-fill"
                  style={{ width: "33.33%" }}
                >
                  <span className="">{file.name}</span>
                  <br />
                  <small className="">{file.document.name}</small>
                </div>

                <div
                  className="flex-fill text-right"
                  style={{ width: "33.33%" }}
                >
                  <span className="">Upload Pending</span>

                  <i
                    className="far fa-trash-alt fa-md text-danger  ml-3 mr-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const documents = values.documents.filter(
                        (d, docIdx) => docIdx !== index
                      );
                      setFieldValue("documents", documents);
                    }}
                  />
                </div>
              </div>
              {errors.documentsError && errors.documentsError[index] ? (
                <div className="text-danger pl-2 mt-1 fa-sm">
                  {errors.documentsError[index].name}
                </div>
              ) : null}
              {/* <div className="text-danger pl-2 mt-1 fa-sm">Error Msg here</div> */}
            </li>
          );
        })}

        <li className="list-group-item  pl-0 pr-0">
          <div className="d-flex justify-content-between align-items-center">
            <div className="flex-fill mx-sm-3" style={{ width: "33.33%" }}>
              {documentTypes && documentTypes.length > 0 ? (
                <Field
                  component="select"
                  name="documentType"
                  className="form-control form-control-sm"
                  placeholder="Document Type"
                >
                  <option value="">Select</option>
                  {documentTypes.map((ftyp, idx) => (
                    <option key={idx} value={ftyp}>
                      {ftyp}
                    </option>
                  ))}
                </Field>
              ) : (
                <Field
                  type="text"
                  name="documentType"
                  className="form-control form-control-sm"
                  placeholder="Document Type"
                />
              )}
            </div>

            <div
              className="align-self-center mx-sm-3 flex-fill"
              style={{ width: "33.33%" }}
            >
              <Field
                type="text"
                className="form-control form-control-sm"
                placeholder="Document Name"
                name="documentName"
              />
            </div>

            <div className="flex-fill text-center" style={{ width: "33.33%" }}>
              <div className="upload-btn-wrapper align-middle">
                <button
                  className="btn btn-sm btn-outline-info custom-btn d-flex align-items-center"
                  type="button"
                  {...disabledField}
                >
                  <i className="fas fa-upload mr-2"></i>Choose
                  <input
                    type="file"
                    className="validation-file"
                    name="documents"
                    {...disabledField}
                    onChange={event => {
                      const documents = [...values.documents] || [];

                      if (event.currentTarget.files.length > 0) {
                        Array.from(event.currentTarget.files).forEach(file => {
                          const doc = {
                            name: values.documentName,
                            type: values.documentType,
                            document: file
                          };
                          documents.push(doc);
                        });
                      }
                      setFieldValue("documents", documents);
                      setFieldValue("documentName", "");
                      setFieldValue("documentType", "");
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
          {hasError ? (
            <div className="text-danger pl-2 mx-sm-3 fa-sm">
              Files are already Exists
            </div>
          ) : null}
        </li>
      </ul>
    </Fragment>
  );
};
