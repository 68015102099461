import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import HttpUtil from "../common/HttpUtil";
import { INCUBATEE_STATS_BY_CLUSTER_API } from "../common/Constants";

import LoadingComponent from "../common/spinnerloader/LoadingComponent";

function getIncubateeClusterChartSeries(data) {
  const series = data.map(d => d.count);
  return series;
}

function getIncubateeClusterChartOptions(data) {
  const clusters = [];
  for (let i = 0; i < data.length; i++) {
    clusters.push(data[i].facilityCluster);
  }
  const incubateesClusterChartOptions = {
    //colors: ["#3086ff", "#56ab2f", "#cb2d3e", "#6190E8", "#B24592", "#FFB75E"],
    // colors: ["#1f77b4", "#9467bd", "#7f7f7f", "#17bece", "#2f88bd"],
    // colors: ["#008ffb", "#00e396", "#775dd0", "#feb019", "#ff4560"],
    colors: [
      // color combination 400 2
      "#5c6bc0",
      "#42a5f5",
      "#26c6da",
      "#26a69a",
      "#ec407a",
      "#78909c",
      "#ab47bc",
      "#7e57c2",
      "#5c6bc0",
      "#ffca28"
      // "#ff0000",
      // "#0080ff",
      // "#3cb44b",
      // "#f032e6",
      // "#4363d8",
      // "#800000",
      // "#911eb4",
      // "#9A6324",
      // "#808000",
      // "#000075",
      // "#e6194B",
      // "#ff8000",
      // "#679317",
      // "#ef473a",
      // "#0a94bd",
      // "#ff3377",
      // "#2e4045"
    ],
    labels: clusters,
    dataLabels: {
      enabled: true,
      formatter: function(val, opts) {
        //   if (toPrint) {
        //     console.log(JSON.stringify(opts.w.config, null, 2));
        //     toPrint = false;
        //   }
        return opts.w.globals.series[opts.seriesIndex];
      }
    },
    fill: {
      type: "solid",
      // gradient: {
      //   shade: "dark",
      //   type: "vertical",
      //   gradientToColors: [
      //     "#00BEAB",
      //     "#a8e063",
      //     "#ef473a",
      //     "#A7BFE8",
      //     "#F15F79",
      //     "#ED8F03"
      //   ],
      //   stops: [0, 100]
      // }
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: [
          "#ff0000",
          "#0080ff",
          "#3cb44b",
          "#f032e6",
          "#4363d8",
          "#800000",
          "#911eb4",
          "#9A6324",
          "#808000",
          "#000075",
          "#e6194B",
          "#f58231",
          "#ff8000",
          "#679317",
          "#ef473a",
          "#0a94bd",
          "#ff3377",
          "#2e4045"
        ],
        stops: [0, 100]
      }
    },
    legend: {
      show: true,
      position: "bottom",
      verticalAlign: "middle",
      itemMargin: {
        horizontal: 2
      }
    },

    responsive: [
      {
        breakpoint: 1199,
        options: {
          // chart: { width: 200, height: 260 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 992,
        options: {
          // chart: { width: 280, height: 280 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 480,
        options: {
          //  chart: { width: 250 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 320,
        options: {
          // chart: { width: 200 },
          legend: { position: "bottom" }
        }
      }
    ]
  };

  return incubateesClusterChartOptions;
}

class IncubateeClusterStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incubateeClusterStats: null
    };
  }

  getIncubateeStatsByCluster = () => {
    let url = INCUBATEE_STATS_BY_CLUSTER_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          incubateeClusterStats: {
            errorMessage: null,
            incubateeCountByClusters: data
          }
        });
      },
      (data, status) => {
        this.setState({
          incubateeClusterStats: {
            errorMessage: data.message,
            incubateeCountByClusters: []
          }
        });
      },
      error => {
        this.setState({
          incubateeClusterStats: {
            errorMessage: "Unable to Fetch data",
            incubateeCountByClusters: []
          }
        });
      }
    );
  };

  componentDidMount = () => {
    this.getIncubateeStatsByCluster();
  };

  render() {
    const { incubateeClusterStats } = this.state;

    let incubateesClusterChartOptions;
    let series;
    if (incubateeClusterStats) {
      const { incubateeCountByClusters } = incubateeClusterStats;
      incubateesClusterChartOptions = getIncubateeClusterChartOptions(
        incubateeCountByClusters
      );
      series = getIncubateeClusterChartSeries(incubateeCountByClusters);
    }

    return (
      <div className="col-12 col-lg-6">
        <div
          className="card elevate mt-3"
          // style={{ boxShadow: "25px 25px 25px 25px rgba(0,0,0,.1)" }}
        >
          <div className="card-header border-bottom-0">
            <div
              className="card-header-title font-size-lg text-center"
              style={{ fontWeight: "500" }}
            >
              Incubatees by Cluster
              <Link
                className="btn btn-sm space-info-btn float-md-right shadow ml-2"
                to="/reports/incubatee/cluster-info"
              >
                VIEW DETAILS
              </Link>
            </div>
          </div>
          <div className="card-body pb-0 pt-1" style={{ minHeight: "250px" }}>
            {incubateeClusterStats === null ? (
              <div
                className="d-flex justify-content-center align-items-center text-primary"
                style={{ minHeight: 200 }}
              >
                <LoadingComponent />
              </div>
            ) : (
              <Fragment>
                {incubateeClusterStats.errorMessage !== null ? (
                  <div className="text-center text-danger">
                    {incubateeClusterStats.errorMessage}
                  </div>
                ) : (
                  <Fragment>
                    {incubateeClusterStats.incubateeCountByClusters.length ===
                    0 ? (
                      <div className="text-center text-muted">
                        No partition category information.
                      </div>
                    ) : (
                      <ReactApexChart
                        options={incubateesClusterChartOptions}
                        series={series}
                        type="donut"
                        // type="pie"
                        height="250"
                        // methods={() => {
                        //   let w = document.getElementById("container");
                        //   if (w.offsetWidth > 250) w.style.width = "200px";
                        //   else w.style.width = "300px";
                        // }}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default IncubateeClusterStats;
