import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { exportCustomResult } from "../../../lib/table";
import AlertComponent from "../../common/AlertComponent";
import { ASSET_RESERVATION_REQUEST_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { changeToINR } from "../../common/LookupConstants";
import GroupedTableList from "../../common/GroupedTableList";
import { getDisplayDate } from "../../lease/DateUtil";

function fetchAwaitingPayments(setAwaitingPayments, setAlert) {
  let url = `${ASSET_RESERVATION_REQUEST_API}/awaiting`;
  HttpUtil.get(
    url,
    {},
    data => {
      setAwaitingPayments({ status: "success", data });
    },
    (data, status) => {
      setAwaitingPayments({
        status: "failed",
        data: [],
        message: data.message
      });

      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: data.message
      });
    },
    error => {
      setAwaitingPayments({
        status: "error",
        data: [],
        message: error.toString()
      });

      setAlert({
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: error.toString()
      });
    }
  );
}

const DISPLAY_COLUMNS = [
  {
    Header: "Requestor",
    accessor: "requestor",
    cellClassName: "d-flex align-items-center",
    aggregate: "count",
    canGroupBy: true
  },
  {
    Header: "Requestor Type",
    accessor: "requestorType",
    canGroupBy: true,
    aggregate: values => values[0],
    Aggregated: ({ value }) => value,
    Cell: () => ""
  },
  {
    Header: "Requested At",
    accessor: "createdAt",
    headerClassName: "text-center",
    cellClassName: "text-center",
    canGroupBy: false,
    Cell: ({ value }) => getDisplayDate(value)
  },

  {
    Header: "Charge Amount",
    accessor: "chargeAmount",
    headerClassName: "text-right",
    cellClassName: "text-right",
    canGroupBy: false,
    aggregate: "sum",
    Aggregated: ({ value }) => changeToINR(value),
    Cell: ({ value }) => changeToINR(value)
  },

  {
    Header: "Advance / Paid Amount",
    accessor: "paidAmount",
    headerClassName: "text-right",
    cellClassName: "text-right",
    canGroupBy: false,
    aggregate: "sum",
    Aggregated: ({ value }) => changeToINR(value),
    Cell: ({ value }) => changeToINR(value)
  },

  {
    Header: "Pending / Refund",
    accessor: "pendingOrRefundAmount",
    headerClassName: "text-right pr-5",
    cellClassName: "text-right pr-5",
    aggregate: "sum",
    Aggregated: ({ value }) => changeToINR(value),
    canGroupBy: false,
    Cell: ({ row, value }) => {
      const original = row.original || {};

      let renderComp = "";

      const currentPathname = "/service-mgmt/awaiting-payment";
      const url = `/service-mgmt/asset-reservation-requests/edit/${
        original._id
      }?from=${currentPathname}`;

      if (original.requestor) {
        renderComp = (
          <Link to={url} title={value < 0 ? "Refund Amount" : "Pending Amount"}>
            {changeToINR(value)}
          </Link>
        );
      }
      return renderComp;
    }
  }
];

function AwaitingPayments() {
  const [alert, setAlert] = useState({
    alertType: "",
    showAlert: false,
    alertColor: "",
    alertMessage: ""
  });

  const [awaitingPayments, setAwaitingPayments] = useState({
    status: "loading",
    data: []
  });

  useEffect(() => fetchAwaitingPayments(setAwaitingPayments, setAlert), []);

  const totalOutStandingAmount = React.useMemo(
    () => {
      const result = awaitingPayments.data.reduce(
        (acc, currItem) => {
          if (currItem.pendingOrRefundAmount < 0) {
            acc.refund -= currItem.pendingOrRefundAmount;
          } else {
            acc.pendingAmount += currItem.pendingOrRefundAmount;
          }

          return acc;
        },
        { pendingAmount: 0, refund: -0 }
      );
      return result;
    },
    [awaitingPayments.data]
  );

  const columns = React.useMemo(() => DISPLAY_COLUMNS, []);

  const alertProps = {
    show: alert.showAlert,
    type: alert.alertType,
    alertColor: alert.alertColor,
    message: alert.alertMessage
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-12">
          <AlertComponent {...alertProps} />
        </div>
      </div>

      <GroupedTableList
        columns={columns}
        data={awaitingPayments.data || []}
        footerComponent={<TotalAmount total={totalOutStandingAmount} />}
        loading={awaitingPayments.status === "loading" ? true : false}
        groupByHeader={["requestor"]}
        sortByHeader={[{ id: "requestor" }, { id: "createdAt" }]}
        exportToExcel={() => {
          const sortResult = awaitingPayments.data.sort((a, b) => {
            if (a.requestor < b.requestor) return -1;
            return a.requestor > b.requestor ? 1 : 0;
          });
          const exportData = sortResult.map(d => {
            return {
              ...d,
              createdAt: getDisplayDate(d.createdAt)
            };
          });
          exportCustomResult("Awaiting Payment", columns, exportData);
        }}
        noDataMessage="No Reservations with awaiting payment or refund not found."
        sizePerPage="10"
        headerName="Awaiting Payment / Refund"
        showSearchbar={true}
      />
    </div>
  );
}

export default AwaitingPayments;

const TotalAmount = props => {
  const { total } = props;
  return (
    <div className="px-5 my-4">
      <div className="d-flex justify-content-end align-items-center">
        <h5 className="text-right mb-0">Total Pending :</h5>
        <h5 className="text-right mb-0" style={{ width: 130 }}>
          {changeToINR(total.pendingAmount)}
        </h5>
      </div>

      <div className="d-flex justify-content-end align-items-center mt-3">
        <h5 className="text-right mb-0">Total Refund : </h5>
        <h5 className="text-right mb-0" style={{ width: 130 }}>
          {changeToINR(total.refund)}
        </h5>
      </div>
    </div>
  );
};
