import React, { Component } from "react";

import AsyncSelect from "react-select/lib/Async";
import HttpUtil from "../common/HttpUtil";

class AutoLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: []
    };
  }

  // api success response
  handleApiSuccess = (data, value, callback) => {
    let name = this.props.name;
    let lookupData = data.map(lookup => {
      let dataOption = {};
      dataOption = {
        value: lookup._id,
        label: lookup[name],
        lookup
      };
      return dataOption;
    });

    this.setState({
      dataList: lookupData
    });

    return callback(this.filterData(value));
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  filterData = value => {
    if (!value) return this.state.dataList;
    return this.state.dataList.filter(i =>
      i.label.toLowerCase().includes(value.toLowerCase())
    );
  };

  loadOptions = (value, callback) => {
    if (
      this.props.url === "" ||
      this.props.url === null ||
      this.props.url === undefined
    ) {
      return [];
    }

    let url = `${this.props.url}${value ? value : ""}`;

    const headers = {
      "Content-Type": "application/json"
    };

    HttpUtil.get(
      url,
      headers,
      data => {
        this.handleApiSuccess(data, value, callback);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidUpdate = prevProps => {
    if (this.props.url !== prevProps.url && this.props.url) {
      this.loadOptions(null, () => {});
    }
  };

  render() {
    let styles = "";
    if (this.props.className) {
      styles = {
        control: base => ({
          ...base,
          borderColor: "#f44455",
          height: "calc(1.8125rem + 2px)",
          minHeight: "calc(1.8125rem + 2px)"
        })
      };
    } else {
      styles = {
        control: base => ({
          ...base,
          height: "calc(1.8125rem + 2px)",
          minHeight: "calc(1.8125rem + 2px)"
        }),
        indicatorsContainer: provided => ({
          ...provided,
          height: "30px"
        })
      };
    }

    return this.props.value ? (
      <AsyncSelect
        key={JSON.stringify(this.props.url)}
        isClearable
        defaultOptions={true}
        loadOptions={this.loadOptions}
        onChange={this.props.getObject}
        styles={styles}
        cacheOptions={false}
      />
    ) : (
      <AsyncSelect
        key={JSON.stringify(this.props.url)}
        value={null}
        isClearable
        defaultOptions={true}
        isDisabled={this.props.isDisabled}
        loadOptions={this.loadOptions}
        onChange={this.props.getObject}
        styles={styles}
        cacheOptions={false}
      />
    );
  }
}

export default AutoLookup;
