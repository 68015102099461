import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
// import EquipmentRentalRequest from "../public/EquipmentRentalRequest";
import IncubateePublicDataCollectionForm from "../public/incubatee-data-collection/IncubateePublicDataCollectionForm";
import IncubatorEnquiry from "./incubator-enquiry-form/IncubatorEnquiry";
import IncubatorEnquiryReadOnlyForm from "./incubator-enquiry-form/IncubatorEnquiryReadOnlyForm";

class PublicHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        {/* Old asset request form is hided.
         <Route
          exact
          key="equipmentRentalRequest"
          path="/public/equipment-requests/new/:id"
          component={props => <EquipmentRentalRequest {...props} />}
        /> */}
        <Route
          exact
          key="incubateeDataForm"
          path="/public/incubatee-data-form"
          component={IncubateePublicDataCollectionForm}
        />

        <Route
          exact
          key="incubatorEnquiryForm"
          path="/public/enquiry/new/:id"
          component={IncubatorEnquiry}
        />

        <Route
          exact
          key="incubatorEnquiryForm"
          path="/public/enquiry/:id"
          component={IncubatorEnquiryReadOnlyForm}
        />
      </Switch>
    );
  }
}

export default PublicHome;
