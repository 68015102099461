import React, { Component } from "react";
import { Link } from "react-router-dom";
import Can from "../../../auth/can";

class Campus extends Component {
  render() {
    const {
      //campus
      name,
      notes,
      city,
      district,
      state,
      country,
      postalCode,
      landmark,
      status,
      areaUnit,
      areaPrice,
      seaterPrice,

      // events
      newCampus,
      onChange,
      submit,

      inputError,

      // for selectors
      statusList,
      areaUnitList
    } = this.props;

    return (
      <div className="card edit-page-container">
        <div className="card-header text-center mb-0 border-0">
          <span className="edit-page-title text-center">
            <Link to="/admin/campuses" className="float-left">
              <i className="fas fa-arrow-left" />
            </Link>
            <span className="ml-1">{newCampus ? "New Campus" : name}</span>
          </span>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Campus Name
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className={
                      inputError["name"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Campus Name *"
                    name="name"
                    value={name}
                    onChange={onChange}
                  />
                  {inputError["name"] ? (
                    <div className="invalid-feedback">
                      Please enter campus name *.
                    </div>
                  ) : null}
                </div>
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Status
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <select
                    name="status"
                    className="custom-select custom-select-sm"
                    value={status}
                    onChange={onChange}
                  >
                    {statusList.map((sta, idx) => (
                      <option key={idx} value={sta.statusCode}>
                        {sta.statusDesc}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                  Area Unit
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <select
                    className={
                      inputError["areaUnit"]
                        ? "custom-select custom-select-sm  is-invalid"
                        : "custom-select custom-select-sm "
                    }
                    name="areaUnit"
                    value={areaUnit ? areaUnit : ""}
                    onChange={onChange}
                  >
                    <option value="">Select ...</option>
                    {areaUnitList.map((unit, idx) => (
                      <option key={idx} value={unit.unitCode}>
                        {unit.unitDesc}
                      </option>
                    ))}
                  </select>
                  {inputError["areaUnit"] ? (
                    <div className="invalid-feedback">
                      Please select unit *.
                    </div>
                  ) : null}
                </div>
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                  Fixed Rental per Sqft
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="number"
                    className={
                      inputError["areaPrice"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Area price *"
                    name="areaPrice"
                    value={areaPrice}
                    onChange={onChange}
                  />
                  {inputError["areaUnit"] ? (
                    <div className="invalid-feedback">
                      Please enter price *.
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                  Seater Price
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="number"
                    className={
                      inputError["seaterPrice"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Seater price *"
                    name="seaterPrice"
                    value={seaterPrice}
                    onChange={onChange}
                  />
                  {inputError["areaUnit"] ? (
                    <div className="invalid-feedback">
                      Please enter price *.
                    </div>
                  ) : null}
                </div>
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                  Notes
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <textarea
                    className="form-control form-control-sm"
                    rows="3"
                    name="notes"
                    onChange={onChange}
                    value={notes}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label ">
                  Landmark
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Landmark "
                    name="landmark"
                    value={landmark}
                    onChange={onChange}
                  />
                </div>
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label ">
                  City
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="City"
                    name="city"
                    value={city}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label ">
                  PIN Code
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Pin Code"
                    name="postalCode"
                    value={postalCode}
                    onChange={onChange}
                  />
                </div>
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label ">
                  District
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="District name"
                    name="district"
                    value={district}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label ">
                  State
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="State name"
                    name="state"
                    value={state}
                    onChange={onChange}
                  />
                </div>
                <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label ">
                  Country
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Country Name"
                    name="country"
                    value={country}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row mt-5 mb-0">
            <div className="col-sm-12 text-center">
              {newCampus ? (
                <Can I="CREATE" a="Campus">
                  <button className="btn btn-sm  btn-primary" onClick={submit}>
                    Submit
                  </button>
                </Can>
              ) : (
                <Can I="UPDATE" a="Campus">
                  <button className="btn btn-sm  btn-primary" onClick={submit}>
                    Update
                  </button>
                </Can>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Campus;
