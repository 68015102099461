import * as Yup from "yup";

const POSTAL_CODE_REG_EXP = /^[1-9][0-9]{5}$/;

const ADDRESS_SCHEMA = Yup.object().shape({
  streetLine1: Yup.string()
    .label("Street Line 1")
    .required(),
  streetLine2: Yup.string()
    .label("Street Line 2")
    .optional(),
  landmark: Yup.string()
    .label("Landmark")
    .optional(),
  city: Yup.string()
    .label("City")
    .required(),
  state: Yup.string()
    .label("State")
    .required(),
  country: Yup.string()
    .label("Country")
    .required(),
  postalCode: Yup.string()
    .label("Postal Code")
    .test("Postal Code", "Please enter valid Postal Code", value => {
      if (value) {
        const schema = Yup.string().matches(
          POSTAL_CODE_REG_EXP,
          "Please enter valid Postal Code"
        );
        return schema.isValidSync(value);
      }
      return true;
    })
    .required(),
  type: Yup.string()
    .label("Address Type")
    .required()
});

export const INDIVIDUAL_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .label("First Name")
    .required(),
  lastName: Yup.string()
    .label("Last Name")
    .optional(),
  gender: Yup.string()
    .label("Gender")
    .nullable()
    .optional(),
  dob: Yup.string()
    .label("Date of Birth")
    .optional(),

  experience: Yup.string()
    .label("Experience")
    .required(),
  references: Yup.string()
    .label("References")
    .optional(),

  education: Yup.array()
    .of(
      Yup.object().shape({
        qualification: Yup.string()
          .label("Qualification")
          .required(),
        degree: Yup.string()
          .label("Degree")
          .required(),
        institution: Yup.string()
          .label("Institution")
          .optional(),
        alumni: Yup.boolean()
          .label("Alumni")
          .optional(),

        year: Yup.string()
          .when("alumni", {
            is: true,
            then: Yup.string()
              .label("Year")
              .required(),
            otherwise: Yup.string()
              .label("Year")
              .optional()
          })
          .label("Year")
          .optional(),

        branch: Yup.string()
          .when("alumni", {
            is: true,
            then: Yup.string()
              .label("Branch")
              .required(),
            otherwise: Yup.string()
              .label("Branch")
              .optional()
          })
          .label("Branch")
          .optional()
      })
    )
    .min(1)
    .label("Education")
    .required(),

  addresses: Yup.array()
    .of(ADDRESS_SCHEMA)
    .min(1)
    .label("Addresses")
    .required(),

  phones: Yup.array()
    .of(
      Yup.object().shape({
        phone: Yup.string()
          .label("Phone")
          .required(),
        type: Yup.string()
          .label("Type")
          .required()
      })
    )
    .min(1)
    .label("Phones")
    .required(),

  emails: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string()
          .label("Email")
          .required(),
        type: Yup.string()
          .label("Type")
          .required()
      })
    )
    .min(1)
    .label("Emails")
    .required(),

  identificationDocs: Yup.object().shape({
    panNumber: Yup.string()
      .label("PAN Number")
      .required(),

    aadhaarNumber: Yup.string()
      .label("Aadhaar Number")
      .required()
  })
});
