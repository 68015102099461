import React from "react";
import { Link } from "react-router-dom";
import { PAYEE_TYPES_OBJECT } from "../../common/LookupConstants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";

function getName(data) {
  if (data.adjustmentTo) {
    let entityType = data.entityType;
    let names = [];
    if (entityType === "INDIVIDUAL") {
      names.push(data.adjustmentTo.firstName);
      names.push(data.adjustmentTo.lastName);
    } else if (entityType === "COMPANY") {
      names.push(data.adjustmentTo.name);
    }
    return names.join(" ");
  } else {
    return data.entity.label;
  }
}

function getDate(data) {
  const date =
    typeof data.date === "number" ? convertNumberToDate(data.date) : data.date;
  return getDisplayDate(date);
}

function AdvAdjustmentPreviewForm(props) {
  const { data } = props;

  return (
    <div className="row mb-2">
      <div className="col-md-12">
        <div className="card edit-page-container">
          <div className="card-header border-bottom-0 pt-3">
            <span className="edit-page-title mb-0">
              <Link
                to={"/finance/adjustment/advances"}
                className="badge bg-white custom-btn-sm"
                data-toggle="tooltip"
                title="Back to Adjustment List Page"
              >
                <i className="fas fa-arrow-left" />
              </Link>
              &nbsp;{props.title}
            </span>
          </div>

          <div className="card-body pl-1 pr-1">
            <div className="form-group row mb-0 pl-4 pr-2">
              <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                <strong>Entity Type</strong>
              </label>
              <label className="col-form-label col-xs-12 col-md-4 ">
                {PAYEE_TYPES_OBJECT[data.entityType]}
              </label>
            </div>

            <div className="form-group row mb-0 pl-4 pr-2">
              <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                <strong>Entity</strong>
              </label>
              <label className="col-form-label col-xs-12 col-md-4 ">
                {getName(data)}
              </label>
            </div>

            <div className="form-group row mb-0 pl-4 pr-2">
              <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                <strong>Amount</strong>
              </label>
              <label className="col-form-label col-xs-12 col-md-4 ">
                {data.amount}
              </label>
            </div>

            <div className="form-group row mb-0 pl-4 pr-2">
              <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                <strong>Adjustment date</strong>
              </label>
              <label className="col-form-label col-xs-12 col-md-4 ">
                {getDate(data)}
              </label>
            </div>

            <div className="form-group row mb-0 pl-4 pr-2">
              <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                <strong>Adjustment Reason</strong>
              </label>
              <label className="col-form-label col-xs-12 col-md-4 ">
                {data.adjustmentReason}
              </label>
            </div>

            <div className="form-group row mb-0 pl-4 pr-2">
              <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                <strong>Notes / Remarks</strong>
              </label>
              <label className="col-form-label col-xs-12 col-md-4 ">
                {data.notes}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvAdjustmentPreviewForm;
