import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";

import { GRANT_AWARDS_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import {
  convertDateToNumber,
  convertNumberToDate,
  getDisplayDate
} from "../../lease/DateUtil";
import AlertComponent from "../../common/AlertComponent";
import ShowHideComponent from "../../widgets/ShowHideComponent";
import { AWARD_STATUS_OBJECT } from "../../common/LookupConstants";
import { INCUBATION_SECTORS_OBJECT } from "../../common/IncubateeProfileConstants";
import { downloadDocument } from "../../common/Util";
import { getFileIconByContentType } from "../../common/IconUtil";
import GrantAwardReviewForm from "./GrantAwardReviewForm";
import GrantAwardReview from "./GrantAwardReview";
import GrantAwardClosureForm from "./GrantAwardClosureForm";
import GrantAwardTerminationForm from "./GrantAwardTerminationForm";
import GrantAwardClosureDetail from "./GrantAwardClosureDetail";
import GrantAwardTerminationDetail from "./GrantAwardTerminationDetail";
import Link from "react-router-dom/Link";
import Breadcrumb from "../../common/Breadcrumb";
import TransactionReport from "../grant-progress/grant-widgets/TransactionReport";
import { changeToINR } from "../../common/LookupConstants";
import { Redirect } from "react-router";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  return getError(touch, error, fieldName) ? "is-invalid" : "";
}

function getName(entity) {
  const names = [];
  if (entity.name) {
    names.push(entity.name);
  } else {
    if (entity.firstName) {
      names.push(entity.firstName);
    }
    if (entity.lastName) {
      names.push(entity.lastName);
    }
  }
  return names.join(" ");
}
// const AdditionalInformation = props =>{
//   return <Fragment>

//   </Fragment>
// }

const AdditionalInformation = props => {
  const disabledField = props.awardClosed ? { disabled: true } : {};
  return (
    <Formik
      enableReinitialize
      initialValues={props.award}
      onSubmit={props.handleSubmit}
      render={({
        values,
        onSubmit,
        isSubmitting,
        errors,
        touched,
        setFieldValue
      }) => {
        const hasDocuments =
          (props.availableDocuments || []).length > 0 ||
          (values.documents || []).length > 0;
        return (
          <Form>
            <div className="row mb-3">
              <div className="col-12">
                <div className="edit-page-section-header-text">
                  Additional Information
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
                <div className="pl-3 pr-3">
                  {/* <strong className="edit-page-section-header-text d-block">
                    Grant Information
                  </strong> */}
                  <div className="form-group row mb-2 mt-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 pr-0">
                      Revised End Date
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <DatePicker
                        value={
                          values.revisedEndDate
                            ? getDisplayDate(values.revisedEndDate)
                            : ""
                        }
                        selected={values.revisedEndDate}
                        onChange={e => {
                          setFieldValue(`revisedEndDate`, e);
                        }}
                        utcOffset={0}
                        placeholderText="dd-mm-yyyy"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "revisedEndDate"
                        )}`}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        {...disabledField}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4">
                      Notes
                    </label>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <Field
                        component="textarea"
                        className="form-control form-control-sm"
                        name="notes"
                        rows="6"
                        {...disabledField}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
                <div className="pl-3 pr-3">
                  {/* <strong className="edit-page-section-header-text d-block">
                    Documents
                  </strong> */}
                  <ul
                    className="list-group list-group-flush mb-3"
                    style={{ minHeight: "128px" }}
                  >
                    {props.availableDocuments.map((doc, index) => {
                      return (
                        <li
                          key={index}
                          className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                        >
                          <span>
                            <i
                              className={`${getFileIconByContentType(
                                doc.contentType
                              )} mr-2`}
                            />
                            {doc.filename}
                          </span>
                          <div>
                            <i
                              className="fas fa-download fa-md mr-3"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                downloadDocument(
                                  doc.link,
                                  msg => window.alert(msg),
                                  doc.filename
                                )
                              }
                            />
                          </div>
                        </li>
                      );
                    })}
                    {Array.from(values.documents).map((file, index) => {
                      return (
                        <li
                          key={index}
                          className="list-group-item border-0 d-flex justify-content-between align-items-center pl-0 pr-0 ml-0 mr-0 pb-1 pt-1"
                        >
                          <span>
                            <i
                              className={`${getFileIconByContentType(
                                file.type
                              )} mr-2`}
                            />
                            {file.name}
                          </span>
                          <div>Upload Pending</div>
                        </li>
                      );
                    })}
                    {!hasDocuments ? (
                      <li className="list-group-item border-0 pl-0 pr-0 ml-0 mr-0 pb-1 pt-1">
                        No documents available
                      </li>
                    ) : null}
                  </ul>
                  {!props.awardClosed ? (
                    <div className="text-center">
                      <div className="upload-btn-wrapper">
                        <button
                          className="btn btn-sm btn-outline-info"
                          type="button"
                        >
                          <i className="fas fa-upload mr-2" />
                          Upload
                          <input
                            type="file"
                            name="documents"
                            multiple
                            onChange={event => {
                              setFieldValue(
                                "documents",
                                event.currentTarget.files
                              );
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {!props.awardClosed ? (
              <div className="row">
                <div className="col-12 text-center">
                  <button type="submit" className="btn btn-sm btn-primary">
                    Save
                  </button>
                </div>
              </div>
            ) : null}
          </Form>
        );
      }}
    />
  );
};

const GrantAwardForm = props => {
  return (
    <div className="shadow card flex-fill box-border box-light-blue mb-5">
      <div className="card-header border-0 text-center pb-0">
        <h6 className="mb-0 text-light-blue d-inline-block">
          <strong>
            Grant Progress - {props.award.granteeName} (
            {props.award.projectTitle})
          </strong>
        </h6>
        <span className="badge badge-soft-info badge-pill ml-2">
          {AWARD_STATUS_OBJECT[props.award.status] || "--"}
        </span>
      </div>
      <div className="card-body">
        <GrantOverview award={props.award} />
        <TransactionReport award={props.award} />
        <AdditionalInformation {...props} />
      </div>
    </div>
  );
};

class GrantAwardProgress extends Component {
  constructor(args) {
    super(args);
    this.state = {
      award: {
        _id: "",
        applicationId: "",
        grantAwardId: "",
        projectTitle: "",
        grantRef: "",
        granteeType: "",
        grantee: "",
        vertical: "",
        sanctionedAmount: "",
        amountDisburesd: "",
        startDate: null,
        endDate: null,
        revisedEndDate: null,
        status: "",
        currentNotes: [],
        notes: [],
        reviews: [],
        documents: []
      },
      fetched: null,
      availableDocuments: [],
      newReview: false,
      terminate: false,
      closure: false,
      breadCrumbList: [],
      redirectUrl: "",
      actionBtnShow: false
    };
  }

  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  toggleActionBtn = () => {
    this.setState({ actionBtnShow: !this.state.actionBtnShow });
  };

  getAward = id => {
    const url = `${GRANT_AWARDS_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => {
        const award = {};

        const breadCrumbList = [];

        const awardList = {
          displayName: "Awards",
          linkTo: `/funding/awards`
        };
        breadCrumbList.push(awardList);

        const granteeName = getName(data.grantee);
        const project = {
          displayName: granteeName,
          linkTo: null
        };
        breadCrumbList.push(project);

        award._id = data._id || "";
        award.applicationId = data.applicationId || "";
        award.grantAwardId = data.grantAwardId || "";
        award.projectTitle = data.projectTitle || "";
        award.grantRef = data.grantRef || "";
        award.granteeType = data.granteeType || "";
        award.grantee = data.grantee || "";
        award.granteeName = granteeName || "";
        award.vertical = data.vertical || "";
        award.sanctionedAmount = data.sanctionedAmount || "";
        award.amountDisburesd = data.amountDisburesd || "";
        award.startDate = convertNumberToDate(data.startDate) || null;
        award.endDate = convertNumberToDate(data.endDate) || null;
        award.revisedEndDate = convertNumberToDate(data.revisedEndDate) || null;
        award.status = data.status || "";
        // Used as input field. So no need to load data.
        // Documents from the existing record is available in availableDocuments
        award.documents = [];
        award.currentNotes = (data.notes || []).filter(n => !!n.remark);

        award.reviews = data.reviews || [];
        award.disbursedAmounts = data.disbursedAmounts || [];
        const availableDocuments = data.documents || [];

        award.grantRef = data.grantRef || "";

        if (data.grantRef.grantProgressTemplate) {
          const grantProgressTemplate = data.grantRef.grantProgressTemplate;
          let reviewTemplate = grantProgressTemplate.reviewTemplate;
          reviewTemplate.checklist = reviewTemplate.checklist.map(chklst => {
            return {
              ...chklst,
              grantProgressTemplate: grantProgressTemplate._id,
              selected: false
            };
          });
        }

        // Closure Information
        award.completed = data.completed;
        award.statusReason = data.statusReason;
        award.grantClosureDate = data.grantClosureDate;
        award.closureNotes = data.notes || [];
        award.trademarkRegistered = data.trademarkRegistered;
        award.trademarkName = data.trademarkName;
        award.companyFormed = data.companyFormed;
        award.afterGrantAward = data.afterGrantAward;
        award.companyName = data.companyName;
        award.dateOfIncorporation = data.dateOfIncorporation;
        award.closureAddlInfo = data.closureAddlInfo || {};
        award.closureDocuments = data.closureDocuments || [];
        award.closureChecklist = data.closureChecklist || [];
        award.patents = data.patents || [];
        award.otherStatusReason = data.otherStatusReason || "";

        this.setState({
          award,
          availableDocuments,
          breadCrumbList,
          fetched: true
        });
      },
      data => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.getAward(id);
    } else {
      this.setState({ fetched: false });
    }

    window.scrollTo(0, 0);
  }

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getRequest = values => {
    const { award } = this.state;
    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "documents", docs[i]);
    }

    const request = {
      ...award
    };

    request.grantRef = award.grantRef._id;
    request.grantee = award.grantee._id;
    request.startDate = convertDateToNumber(award.startDate);
    request.endDate = convertDateToNumber(award.endDate);

    request.notes = [{ remark: values.notes }];
    request.revisedEndDate = convertDateToNumber(values.revisedEndDate);

    formData.append("message", JSON.stringify(request));
    return formData;
  };

  updateAward = (values, { setSubmitting }) => {
    setSubmitting(true);

    const updateRequest = this.getRequest(values);

    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}`;

    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        setSubmitting(false);
        this.getAward(data._id);
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Grant Award for project "${
            data.projectTitle
          }" has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },

      data => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  onAddReview = e => {
    e.preventDefault();
    this.setState({ newReview: true, actionBtnShow: false });
  };

  onNewReviewCancel = () => this.setState({ newReview: false });

  getReview = (values, formData) => {
    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "documents", docs[i].document);
    }

    return {
      title: values.title,
      reviewDate: convertDateToNumber(values.reviewDate),
      amountSanctioned: values.amountSanctioned,
      status: values.status,
      reviewers: values.reviewers,
      reviewerComments: values.reviewerComments,
      incubatorComments: values.incubatorComments,
      checklist: values.checklist,
      documents: docs.map(d => {
        return { name: d.name, type: d.type };
      }),
      grantProgressTemplate: values.grantProgressTemplate
    };
  };

  onNewReviewSave = (values, { setSubmitting }) => {
    setSubmitting(true);

    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}/reviews`;

    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "documents", docs[i].document);
    }
    // console.log("onNewReviewSave:values:", values);
    const review = this.getReview(values, formData);

    formData.append("message", JSON.stringify(review));

    HttpUtil.post(
      url,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.getAward(id);
        this.setState({
          newReview: false,
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Review "${values.title}" has been added successfully.`
        });
        window.scrollTo(0, 0);
      },

      data => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  onUpdateReview = (reviewId, values, updateStatus) => {
    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}/reviews/${reviewId}`;

    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "documents", docs[i].document);
    }
    // console.log("onUpdateReview:values:", values);
    const review = this.getReview(values, formData);
    review._id = reviewId;

    formData.append("message", JSON.stringify(review));

    HttpUtil.put(
      url,
      {},
      formData,
      data => {
        updateStatus(true);
        this.getAward(id);
        this.setState({
          newReview: false,
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `Review "${
            values.title
          }" has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },

      data => {
        updateStatus(false);
        this.handleApiFailed(data.message);
      },
      error => {
        updateStatus(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  onStartClosure = e => {
    e.preventDefault();
    const notClosedReviews = this.state.award.reviews.filter(
      review => review.status !== "CLOSED"
    );
    if (notClosedReviews.length) {
      this.handleApiFailed(
        `There are ${
          notClosedReviews.length
        } review open for this award. Please close the reviews and start the Closure Process.`
      );
    } else {
      this.setState({
        redirectUrl: `/funding/awards/grant-closure/${this.state.award._id}`
      });
    }

    this.toggleActionBtn();
  };

  onCloseAward = () => {
    this.setState({ closure: true });
  };

  onCancelClosure = () => {
    this.setState({ closure: false });
  };

  getPatents = patents => {
    let allPatents = patents || [];
    let newPatents = [];
    for (let i = 0; i < allPatents.length; i++) {
      let pt = allPatents[i];
      let hasData = Object.values(pt).some(itm => itm);

      let patent = {};
      patent.country = pt.country;
      patent.applicationNumber = pt.applicationNumber;
      let date = new Date(pt.applicationFilingDate);
      date = convertDateToNumber(date);
      patent.applicationFilingDate = date;
      patent.fieldOfInvention = pt.fieldOfInvention;
      patent.classificationCode = pt.classificationCode;
      patent.patentStatus = pt.patentStatus;

      if (hasData) {
        newPatents.push(patent);
      }
    }
    return newPatents;
  };

  onClosure = (values, setSubmitting) => {
    setSubmitting(true);

    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}/close`;

    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "closureDocuments", docs[i].document);
    }

    const patents = this.getPatents(values.patents);

    const closureRequest = {
      completed: values.completed,
      closureDate: convertDateToNumber(values.closureDate),
      statusReason: values.statusReason,
      otherStatusReason: values.otherStatusReason || undefined,
      notes: values.notes,
      closureChecklist: values.checklist,
      closureDocuments: docs.map(d => {
        return { name: d.name, type: d.type };
      }),

      trademarkRegistered: values.trademarkRegistered,
      trademarkName: values.trademarkName,

      companyFormed: values.companyFormed,
      afterGrantAward: values.afterGrantAward,
      companyName: values.companyName,
      dateOfIncorporation: convertDateToNumber(values.dateOfIncorporation),
      patents
    };

    formData.append("message", JSON.stringify(closureRequest));

    HttpUtil.post(
      url,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.getAward(id);
        this.setState({
          closure: false,
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `"${
            this.state.award.projectTitle
          }" has been closed successfully.`
        });
        window.scrollTo(0, 0);
      },

      data => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  terminateAward = () => {
    this.setState({ terminate: true });
  };

  onStartTermination = e => {
    e.preventDefault();
    const notClosedReviews = this.state.award.reviews.filter(
      review => review.status !== "CLOSED"
    );
    if (notClosedReviews.length) {
      this.handleApiFailed(
        `There are ${
          notClosedReviews.length
        } review open for this award. Please close the reviews and start the Termination Process.`
      );
    } else {
      this.setState({
        redirectUrl: `/funding/awards/grant-termination/${this.state.award._id}`
      });
    }

    this.toggleActionBtn();
  };

  onCancelTerminate = () => {
    this.setState({ terminate: false });
  };

  onTerminate = (values, setSubmitting) => {
    // console.log(values);
    setSubmitting(true);

    const id = this.props.match.params.id;
    const url = `${GRANT_AWARDS_API}/${id}/terminate`;

    const formData = new FormData();

    const docs = values.documents || [];
    for (let i = 0; i < docs.length; i++) {
      this.addDocs(formData, "closureDocuments", docs[i].document);
    }

    const patents = this.getPatents(values.patents);

    const terminateRequest = {
      closureDate: convertDateToNumber(values.closureDate),
      statusReason: values.statusReason,
      otherStatusReason: values.otherStatusReason || undefined,
      notes: values.notes,
      closureChecklist: values.checklist,
      closureDocuments: docs.map(d => {
        return { name: d.name, type: d.type };
      }),
      trademarkRegistered: values.trademarkRegistered,
      trademarkName: values.trademarkName,

      companyFormed: values.companyFormed,
      afterGrantAward: values.afterGrantAward,
      companyName: values.companyName,
      dateOfIncorporation: convertDateToNumber(values.dateOfIncorporation),
      patents
    };

    formData.append("message", JSON.stringify(terminateRequest));

    HttpUtil.post(
      url,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.getAward(id);
        this.setState({
          terminate: false,
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `"${
            this.state.award.projectTitle
          }" has been terminated successfully.`
        });
        window.scrollTo(0, 0);
      },

      data => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(
          "Server error occurred. Please contact support if the issue persists."
        );
      }
    );
  };

  isReviewClosed(review) {
    return review.status === "CLOSED";
  }

  render() {
    const { fetched, award, actionBtnShow, redirectUrl } = this.state;
    if (fetched === null) {
      return null;
    }

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const awardClosed =
      this.state.award.status === "CLOSED" ||
      this.state.award.status === "TERMINATED";

    let reviewTemplate = {};
    let closureTemplate = {};
    let terminationTemplate = {};
    if (this.state.award.grantRef) {
      let grantProgressTemplate = this.state.award.grantRef
        .grantProgressTemplate;
      reviewTemplate = grantProgressTemplate.reviewTemplate;
      reviewTemplate.grantProgressTemplate = grantProgressTemplate._id;

      closureTemplate = grantProgressTemplate.closureTemplate;
      terminationTemplate = grantProgressTemplate.terminationTemplate;
    }

    const reviews = this.state.award.reviews || [];

    const reviewNotClosed = reviews.some(review => review.status !== "CLOSED");

    const show = actionBtnShow ? "show" : "";

    return (
      <Fragment>
        <div className="row mb-0">
          <div className="col-6 ">
            <Breadcrumb breadCrumbList={this.state.breadCrumbList} />
          </div>
          <ShowHideComponent show={!awardClosed}>
            <div className="col-6 text-right">
              <div className={`btn-group ${show} mt-2`}>
                <button
                  type="button"
                  className="btn btn-sm bg-n-primary dropdown-toggle text-white"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={actionBtnShow}
                  onClick={this.toggleActionBtn}
                >
                  Action
                </button>
                <div
                  className={`dropdown-menu ${show} m-0 p-0`}
                  x-placement="bottom-start"
                  style={{
                    border: "none",
                    position: "absolute",
                    willChange: "transform",
                    top: 0,
                    left: "-160px",
                    transform: "translate3d(0px, 31px, 0px)",
                    width: 230
                  }}
                >
                  <div className="list-group dropdown-item-icon-text">
                    {
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href="#"
                        role="button"
                        className="list-group-item list-group-item-action d-flex align-items-center"
                        onClick={this.onAddReview.bind(this)}
                      >
                        <i
                          className="fas fa-tasks fa-sm pr-3"
                          aria-hidden="true"
                        />
                        <span className="fa-sm">New Review</span>
                      </a>
                    }

                    <button
                      className="list-group-item list-group-item-action d-flex align-items-center text-success"
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={this.onStartClosure.bind(this)}
                    >
                      <i className="fas fa-check-circle fa-sm pr-3" />
                      <span className="fa-sm"> Closure</span>
                    </button>
                    <button
                      className="list-group-item list-group-item-action d-flex align-items-center text-danger"
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={this.onStartTermination.bind(this)}
                    >
                      <i className="fas fa-times-circle fa-sm pr-3" />
                      <span className="fa-sm"> Terminate</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ShowHideComponent>
        </div>

        <div className="row mb-0">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <GrantAwardForm
          award={this.state.award}
          awardClosed={awardClosed}
          reviewNotClosed={reviewNotClosed}
          availableDocuments={this.state.availableDocuments}
          handleSubmit={this.updateAward}
          addReview={this.onAddReview}
          terminateAward={this.terminateAward}
          closeAward={this.onCloseAward}
        />

        {this.state.award.status === "CLOSED" ? (
          <GrantAwardClosureDetail award={this.state.award} />
        ) : null}

        {this.state.award.status === "TERMINATED" ? (
          <GrantAwardTerminationDetail award={this.state.award} />
        ) : null}

        {/* <TransactionReport award={this.state.award} /> */}

        {reviews.map((review, index) => (
          <GrantAwardReview
            awardClosed={awardClosed}
            key={index}
            review={review}
            template={review.grantProgressTemplate.reviewTemplate}
            updateReview={this.onUpdateReview}
          />
        ))}

        <ShowHideComponent show={this.state.newReview}>
          <GrantAwardReviewForm
            template={reviewTemplate}
            cancelReview={this.onNewReviewCancel}
            saveReview={this.onNewReviewSave}
          />
        </ShowHideComponent>
        <ShowHideComponent show={this.state.terminate}>
          <GrantAwardTerminationForm
            awardClosed={awardClosed}
            template={terminationTemplate}
            cancelTerminate={this.onCancelTerminate}
            terminateGrantAward={this.onTerminate}
          />
        </ShowHideComponent>
        <ShowHideComponent show={this.state.closure}>
          <GrantAwardClosureForm
            awardClosed={awardClosed}
            template={closureTemplate}
            cancelClosure={this.onCancelClosure}
            closeGrantAward={this.onClosure}
          />
        </ShowHideComponent>
      </Fragment>
    );
  }
}

const GrantOverview = props => {
  const { award } = props;

  return (
    <Fragment>
      <div className="row mb-3">
        <div className="col-12">
          <div className="edit-page-section-header-text">
            <span className="">Grant Information</span>
            <small className="float-right">
              <strong> {award.grantAwardId || ""} </strong>
            </small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-award fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Grantee Type</small>
            <div className="mb-0">{award.granteeType}</div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-trophy fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Grantee Name</small>
            <div className="mb-0">{award.granteeName || "--"}</div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-ruler-vertical fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Vertical</small>
            <div className="mb-0">
              {INCUBATION_SECTORS_OBJECT[award.vertical] || "--"}
            </div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-rupee-sign fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Amount Sanctioned</small>
            <div className="mb-0">
              {changeToINR(award.sanctionedAmount) || "--"}
            </div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-calendar-alt fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Fund Start Date</small>
            <div className="mb-0">
              {getDisplayDate(award.startDate) || "--"}
            </div>
          </span>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-calendar-alt fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Fund End Date</small>
            <div className="mb-0">{getDisplayDate(award.endDate) || "--"}</div>
          </span>
        </div>

        {/* <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3 d-flex">
          <span className="fa-stack fa-lg">
            <i className="fas fa-circle fa-stack-2x fa-bg-light-grey" />
            <i className="fas fa-rupee-sign fa-stack-1x" />
          </span>
          <span>
            <small className="mb-0 text-muted">Disbursed Amount</small>
            <div className="mb-0">{award.amountDisburesd || "--"}</div>
          </span>
        </div> */}
      </div>

      {props.award.status === "ACTIVE" ? (
        <div className="row mt-5">
          <div class="col-12">
            <div className="edit-page-section-header-text">Notes</div>

            <ul>
              {props.award.currentNotes.map(a => (
                <li className="mt-1" key={a._id}>
                  {a.remark}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default GrantAwardProgress;
