import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import PaymentAdjustmentForm from "./PaymentAdjustmentForm";
import { checkEmptyValue } from "../../common/LookupConstants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { ADJUSTMENTS_API } from "../../common/Constants";
import { convertDateToNumber } from "../../lease/DateUtil";
import AdjustmentConfirmForm from "./AdjustmentConfirmForm";
import ErrorPage from "../../common/error.page";

class PaymentAdjustment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payeeType: null,
      adjustmentTo: null,
      adjustmentAmount: null,
      date: new Date(),
      remarks: null,
      adjustmentReason: null,
      status: null,
      notes: [],
      payeeName: null,
      payeeLookupUrl: null,

      displayName: null,

      // input error
      inputError: {},

      // forms
      adjustmentEditForm: true,
      adjustmentConfirmationForm: false,

      // redirect to
      viewAdjustment: false,
      viewAdjustmentId: null,
      permanentFailure: false
    };
  }

  handleDateChange = (targetName, date) => {
    this.setState({ [targetName]: date });
  };

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    this.setState({ [targetName]: targetValue });
  };

  handleAmountChange = e => {
    const targetName = e.target.name;

    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (Number(targetValue) < 1) {
      return;
    }
    this.setState({ [targetName]: targetValue });
  };

  getPayee = payee => {
    if (payee !== null) {
      this.setState({
        adjustmentTo: payee.value,
        displayName: payee.label
      });
    } else {
      this.setState({
        adjustmentTo: null,
        displayName: null
      });
    }
  };

  handlePayeeType = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    const URL_MAPPER = {
      INDIVIDUAL: {
        url: "api/v1/individuals/lookup/finance/?name=",
        payeeName: "name"
      },
      COMPANY: {
        url: "api/v1/organizations/lookup/finance/?name=",
        payeeName: "name"
      }
    };

    let payeeLookupUrl = null;
    let payeeName = null;
    if (targetValue !== null) {
      payeeName = URL_MAPPER[targetValue].payeeName;
      payeeLookupUrl = URL_MAPPER[targetValue].url;
    }
    this.setState({ [targetName]: targetValue, payeeLookupUrl, payeeName });
  };

  validate = () => {
    const remarks = this.state.remarks;
    const notes = [...this.state.notes, `${remarks}`];
    const adjustmentReq = {
      entityType: this.state.payeeType,
      adjustmentTo: this.state.adjustmentTo,
      adjustmentAmount: this.state.adjustmentAmount,
      date: convertDateToNumber(this.state.date),
      adjustmentReason: this.state.adjustmentReason,
      notes: notes,
      status: this.state.status
    };

    let inputError = {};
    checkEmptyValue(inputError, "payeeType", adjustmentReq.entityType);
    checkEmptyValue(inputError, "adjustmentTo", adjustmentReq.adjustmentTo);
    checkEmptyValue(
      inputError,
      "adjustmentAmount",
      adjustmentReq.adjustmentAmount
    );
    checkEmptyValue(
      inputError,
      "adjustmentReason",
      adjustmentReq.adjustmentReason
    );
    checkEmptyValue(inputError, "remarks", remarks);

    const invalid = Object.values(inputError).some(item => item);
    if (invalid) {
      this.setState({ inputError });
      return false;
    } else {
      this.setState({ inputError: {} });
      return adjustmentReq;
    }
  };

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  handleCreateAdjustment = () => {
    const adjustmentRequest = this.validate();
    if (!adjustmentRequest) {
      return;
    }

    const url = ADJUSTMENTS_API;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      adjustmentRequest,
      data => {
        // this.setState({ editAdjustment: true, editAdjustmentId: data._id });
        this.setState({ viewAdjustment: true, viewAdjustmentId: data._id });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleNextForm = () => {
    const valid = this.validate();
    if (!valid) {
      return;
    } else {
      this.setState({
        adjustmentConfirmationForm: true,
        adjustmentEditForm: false
      });
    }
  };

  handleEditForm = () => {
    this.setState({
      adjustmentConfirmationForm: false,
      adjustmentEditForm: true,
      inputError: {}
    });
  };

  render() {
    const {
      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,

      adjustmentConfirmationForm,
      adjustmentEditForm,

      viewAdjustment,
      viewAdjustmentId,
      permanentFailure
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (viewAdjustment) {
      const stateObj = {
        pathname: `/admin/finance/adjustments/view/${viewAdjustmentId}`,
        state: { adjustmentSubmitted: true }
      };
      return <Redirect to={stateObj} />;
    }

    // For AlertComponent
    const alertProps = {
      show: showAlert,
      type: alertType,
      alertColor: alertColor,
      message: alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    // For PaymentAdjustmentForm
    const props = {
      payeeType: this.state.payeeType,
      adjustmentTo: this.state.adjustmentTo,
      adjustmentAmount: this.state.adjustmentAmount,
      date: this.state.date,
      remarks: this.state.remarks,
      adjustmentReason: this.state.adjustmentReason,
      status: this.state.status,

      displayName: this.state.displayName,

      payeeName: this.state.payeeName,
      payeeLookupUrl: this.state.payeeLookupUrl,

      // error
      inputError: this.state.inputError,

      // events
      handleInputChange: this.handleInputChange,
      handleDateChange: this.handleDateChange,
      handleAmountChange: this.handleAmountChange,
      handlePayeeType: this.handlePayeeType,
      getPayee: this.getPayee,
      handleNextForm: this.handleNextForm,
      handleEditForm: this.handleEditForm,
      handleSubmit: this.handleCreateAdjustment
    };

    let renderForm = null;
    if (adjustmentEditForm) {
      renderForm = <PaymentAdjustmentForm {...props} />;
    } else if (adjustmentConfirmationForm) {
      renderForm = <AdjustmentConfirmForm {...props} />;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>
        {renderForm}
      </Fragment>
    );
  }
}

export default PaymentAdjustment;
