import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { PAYEE_TYPES_OBJECT } from "../common/LookupConstants";

const SelectedAsset = props => {
  const { assetsToLease } = props;

  return (
    <div className="card-body">
      <div className="row mb-2">
        <div className="col-md-12 text-center">
          <strong>Selected Assets Information</strong>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12 text-center">
          {assetsToLease.length > 0 ? (
            <div className="table-responsive-sm">
              <table className="table table-sm table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Asset Name</th>
                    <th scope="col">Asset Id</th>
                    <th scope="col">Asset Type</th>
                    <th scope="col">Asset Category</th>
                    <th scope="col">Rental Type</th>
                    <th scope="col">Owner Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {assetsToLease.map((avaAsset, assetIndex) => {
                    const asset = avaAsset.asset;
                    return (
                      <Fragment key={assetIndex}>
                        <tr key={assetIndex}>
                          <td>{asset.assetName}</td>
                          <td>{asset.assetId}</td>
                          <td>{asset.assetType}</td>
                          <td>{asset.assetCategory}</td>
                          <td>{asset.rentalType}</td>
                          <td>{asset.ownerName}</td>
                          <td>{asset.status}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-outline-danger btn-sm"
                              title="Remove"
                              onClick={props.removeSelectedRecords.bind(
                                this,
                                assetIndex
                              )}
                            >
                              <i className="far fa-trash-alt " />
                            </button>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-danger">No Record is selected</div>
          )}
        </div>
      </div>
    </div>
  );
};

function isAllSelected(availabileAssets, selectedAssets) {
  if (!selectedAssets) {
    return "";
  }
  for (let index = 0; index < availabileAssets.length; index++) {
    if (!selectedAssets[index]) {
      return "";
    }
  }
  return "checked";
}

const RequestedAssetForm = props => {
  const { basicInfo, values } = props;

  const trows = values.equipments.map((eq, index) => {
    return (
      <Fragment key={index}>
        <div className="row mt-3">
          <div className="col-md-12">
            <strong>{`${eq.equipment}`}</strong> [ <strong>Requirement</strong>:{" "}
            {eq.quantity} ]
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {eq.availabileAssets.length > 0 ? (
              <table className="table table-sm table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`rowid${index}`}
                          name="selectAllIncubatees"
                          checked={isAllSelected(
                            eq.availabileAssets,
                            props.selectedAssets[eq.equipment]
                          )}
                          onChange={props.handleSelectAll.bind(this, index)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`rowid${index}`}
                        />
                      </div>
                    </th>
                    <th scope="col">Asset Name</th>
                    <th scope="col">Asset Id</th>
                    <th scope="col">Asset Type</th>
                    <th scope="col">Asset Category</th>
                    <th scope="col">Rental Type</th>
                    <th scope="col">Owner Name</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {eq.availabileAssets.map((avaAsset, assetIndex) => {
                    return (
                      <Fragment key={assetIndex}>
                        <tr key={assetIndex}>
                          <th scope="row" className="text-center">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="selected"
                                id={avaAsset._id}
                                checked={
                                  (props.selectedAssets[eq.equipment] &&
                                    props.selectedAssets[eq.equipment][
                                      assetIndex
                                    ]) ||
                                  ""
                                }
                                onChange={props.handleSelect.bind(
                                  this,
                                  index,
                                  assetIndex,
                                  avaAsset.assetType,
                                  avaAsset
                                )}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={avaAsset._id}
                              />
                            </div>
                          </th>
                          <td>{avaAsset.assetName}</td>
                          <td>{avaAsset.assetId}</td>
                          <td>{avaAsset.assetType}</td>
                          <td>{avaAsset.assetCategory}</td>
                          <td>{avaAsset.rentalType}</td>
                          <td>{avaAsset.ownerName}</td>
                          <td>{avaAsset.status}</td>
                        </tr>
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              "No Assets Available for Rent"
            )}
          </div>
        </div>
      </Fragment>
    );
  });

  return (
    <Fragment>
      <div className="card-body">
        <div className="row mb-2">
          <div className="col-md-12 text-center">
            <strong>Basic Information</strong>
          </div>
        </div>

        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
            Name
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="text"
              className="form-control form-control-sm "
              defaultValue={basicInfo.name}
              readOnly
            />
          </div>
          <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
            Status
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="text"
              className="form-control form-control-sm "
              defaultValue={basicInfo.status}
              readOnly
            />
          </div>
          <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
            Requestor Type
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="text"
              className="form-control form-control-sm "
              defaultValue={PAYEE_TYPES_OBJECT[basicInfo.requestorType]}
              readOnly
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-md-12 text-center">
            <strong>Requested Asset Availability Information</strong>
          </div>
        </div>
        {trows}
        {values.status === "PENDING_RENTAL" ? (
          <div className="row mt-3">
            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-info btn-sm"
                onClick={props.addSelectedRecords}
              >
                Add Selected Items
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

class AvailablityForm extends Component {
  render() {
    return (
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="card flex-fill">
            <div className="card-header bg-card-header text-white">
              <span className="card-title mb-0">
                <Link
                  to="/asset/enquiries"
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
                &nbsp; Requested Asset Form &nbsp;&nbsp;
              </span>
            </div>
            <div className="card-body p-0">
              <RequestedAssetForm {...this.props} />
              {this.props.values.status === "PENDING_RENTAL" ? (
                <Fragment>
                  <hr />
                  <SelectedAsset {...this.props} />
                </Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AvailablityForm;
