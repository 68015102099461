function generateObject(array, keyField, valueField) {
  return array.reduce((obj, item) => {
    const key = item[keyField];
    const value = item[valueField];
    obj[key] = value;
    return obj;
  }, {});
}

// Location: finance/ Payment Modules
export const PAYMENT_MODES = [
  {
    key: "CASH",
    value: "Cash"
  },
  {
    key: "CHEQUE",
    value: "Cheque"
  },
  {
    key: "ONLINE_PAYMENT",
    value: "Online"
  },
  {
    key: "CARD_PAYMENT",
    value: "Credit / Debit Card"
  }
];
export const PAYMENT_MODES_OBJECT = generateObject(
  PAYMENT_MODES,
  "key",
  "value"
);

export const REFUND_PAYMENT_MODES = [
  {
    key: "REFUND_CASH",
    value: "Cash"
  },
  {
    key: "REFUND_CHEQUE",
    value: "Cheque"
  },
  {
    key: "REFUND_ONLINE_PAYMENT",
    value: "Online"
  },
  {
    key: "REFUND_CARD_PAYMENT",
    value: "Credit / Debit Card"
  }
];
export const REFUND_PAYMENT_MODES_OBJECT = generateObject(
  REFUND_PAYMENT_MODES,
  "key",
  "value"
);

export const MANUAL_CHARGE_STATUS_LIST = [
  {
    key: "CREATED",
    value: "Created"
  },
  {
    key: "PENDING",
    value: "Pending"
  },
  {
    key: "PROCESSED",
    value: "Processed"
  },
  {
    key: "CLOSED",
    value: "Closed"
  }
];

export const MANUAL_CHARGE_STATUS_LIST_OBJECT = generateObject(
  MANUAL_CHARGE_STATUS_LIST,
  "key",
  "value"
);

export const PAYMENT_STATUS_LIST = [
  {
    key: "CREATED",
    value: "Created"
  },
  {
    key: "PENDING",
    value: "Pending"
  },
  {
    key: "PROCESSED",
    value: "Processed"
  },
  {
    key: "CLOSED",
    value: "Closed"
  },
  {
    key: "VOIDED",
    value: "Voided"
  }
];
export const PAYMENT_STATUS_LIST_OBJECT = generateObject(
  PAYMENT_STATUS_LIST,
  "key",
  "value"
);

export const REFUND_STATUS_LIST = [
  {
    key: "CREATED",
    value: "Created"
  },
  {
    key: "PENDING",
    value: "Pending"
  },
  {
    key: "PROCESSED",
    value: "Processed"
  },
  {
    key: "CLOSED",
    value: "Closed"
  }
];
export const REFUND_STATUS_LIST_OBJECT = generateObject(
  REFUND_STATUS_LIST,
  "key",
  "value"
);

export const ADJUSTMENT_PAYMENT_STATUS_LIST = [
  {
    key: "CREATED",
    value: "Created"
  },
  {
    key: "PENDING",
    value: "Pending"
  },
  {
    key: "PROCESSED",
    value: "Processed"
  },
  {
    key: "CLOSED",
    value: "Closed"
  }
];
export const ADJUSTMENT_PAYMENT_STATUS_LIST_OBJECT = generateObject(
  ADJUSTMENT_PAYMENT_STATUS_LIST,
  "key",
  "value"
);

export const ADVANCE_ADJUSTMENT_PAYMENT_STATUS_LIST = [
  {
    key: "CREATED",
    value: "Created"
  },
  {
    key: "PENDING",
    value: "Pending"
  },
  {
    key: "PROCESSED",
    value: "Processed"
  },
  {
    key: "CLOSED",
    value: "Closed"
  }
];
export const ADVANCE_ADJUSTMENT_PAYMENT_STATUS_LIST_OBJECT = generateObject(
  ADVANCE_ADJUSTMENT_PAYMENT_STATUS_LIST,
  "key",
  "value"
);

export const CARD_TYPES = [
  {
    key: "CREDIT_CARD",
    value: "Credit Card"
  },
  {
    key: "DEBIT_CARD",
    value: "Debit Card"
  }
];

export const CARD_TYPES_OBJECT = generateObject(CARD_TYPES, "key", "value");

export const CARD_ISSUERS = [
  {
    key: "VISA_CARD",
    value: "Visa Card"
  },
  {
    key: "MASTER_CARD",
    value: "Master Card"
  },
  {
    key: "RUPAY_CARD",
    value: "RuPay Card"
  },
  {
    key: "OTHERS",
    value: "Others"
  }
];

export const CARD_ISSUERS_OBJECT = generateObject(CARD_ISSUERS, "key", "value");

export const ASSET_RATE_TYPES = [
  {
    value: "HOURLY",
    label: "Hourly"
  },
  {
    value: "DAILY",
    label: "Daily"
  },

  {
    value: "WEEKLY",
    label: "Weekly"
  },
  {
    value: "MONTHLY",
    label: "Monthly"
  },
  {
    value: "SLOT",
    label: "Slot"
  }
];

export const ASSET_RATE_TYPES_OBJECT = generateObject(
  ASSET_RATE_TYPES,
  "value",
  "label"
);

export const RENTAL_RATES_TYPES = [
  {
    key: "HOURLY",
    value: "Hourly"
  },
  {
    key: "DAILY",
    value: "Daily"
  },

  {
    key: "WEEKLY",
    value: "Weekly"
  },
  {
    key: "MONTHLY",
    value: "Monthly"
  },
  {
    key: "SLOT",
    value: "Slot"
  }
];

export const RENTAL_RATES_TYPES_OBJECT = generateObject(
  RENTAL_RATES_TYPES,
  "key",
  "value"
);

export const ENTITY_TYPES = [
  {
    key: "Individual",
    value: "Individual"
  },
  {
    key: "Organization",
    value: "Organization"
  }
];

export const ENTITY_TYPES_OBJECT = generateObject(ENTITY_TYPES, "key", "value");

export const PAYEE_TYPES = [
  {
    key: "INDIVIDUAL",
    value: "Individual"
  },
  {
    key: "COMPANY",
    value: "Organization"
  }
];

export const PAYEE_TYPES_OBJECT = generateObject(PAYEE_TYPES, "key", "value");

export const PAYMENT_TYPES = [
  // {
  //   key: "REGULAR_PAYMENT",
  //   value: "Regular Payment"
  // },
  {
    key: "REGULAR",
    value: "Regular Payment"
  },
  // {
  //   key: "ADVANCE_PAYMENT",
  //   value: "Advance Payment"
  // },
  {
    key: "ADVANCE",
    value: "Advance Payment"
  }
];
export const PAYMENT_TYPES_OBJECT = generateObject(
  PAYMENT_TYPES,
  "key",
  "value"
);

export const ACCOUNT_TYPES = [
  {
    key: "REGULAR",
    value: "Regular"
  },
  {
    key: "ADVANCE",
    value: "Advance"
  }
];

export const ACCOUNT_TYPES_OBJECT = generateObject(
  ACCOUNT_TYPES,
  "key",
  "value"
);

export const ENQUIRY_TYPES = [
  {
    key: "INCUBATION",
    value: "Incubation"
  },
  {
    key: "EQUIPMENT_RENTAL",
    value: "Equipment Usage"
  },
  {
    key: "LAB_USAGE",
    value: "Lab usage"
  },
  {
    key: "CONSULTING",
    value: "Consulting"
  },
  {
    key: "PRODUCT_DEVELOPMENT",
    value: "Product Development"
  }
];

export const ENQUIRY_TYPES_OBJECT = generateObject(
  ENQUIRY_TYPES,
  "key",
  "value"
);

export const PRIORITY_TYPES = [
  {
    key: "LOW",
    value: "Low"
  },
  {
    key: "MEDIUM",
    value: "Medium"
  },

  {
    key: "HIGH",
    value: "High"
  }
];

export const PRIORITY_TYPES_OBJECT = generateObject(
  PRIORITY_TYPES,
  "key",
  "value"
);

export const REQUESTOR_CATEGORY_TYPES = [
  {
    key: "INNOVATOR",
    value: "Innovator"
  },
  {
    key: "STARTUP",
    value: "Startup"
  },
  {
    key: "INDUSTRY",
    value: "Industry"
  },
  {
    key: "ACADEMIC",
    value: "Academic"
  },
  {
    key: "OTHER",
    value: "Others"
  }
];

export const REQUESTOR_CATEGORY_TYPES_OBJECT = generateObject(
  REQUESTOR_CATEGORY_TYPES,
  "key",
  "value"
);

// Termination Reasons
export const TERMINATION_REASONS = [
  {
    key: "EXPANSION",
    value: "Expansion"
  },
  {
    key: "LOCATION_CHANGE",
    value: "Moving to a different place"
  },
  {
    key: "GRADUATION",
    value: "Completion of Incubation Period"
  },
  {
    key: "SUSPEND",
    value: "Suspending the operations"
  },
  {
    key: "OTHER",
    value: "Any other reason"
  }
];
export const TERMINATION_REASONS_OBJECT = generateObject(
  TERMINATION_REASONS,
  "key",
  "value"
);

// Towards
export const TOWARDS_TYPES = [
  {
    key: "FIN_CHARGE",
    value: "Financial Charge"
  },
  {
    key: "INVOICE_CHARGE",
    value: "Invoice Charge"
  }
];
export const TOWARDS_TYPES_OBJECT = generateObject(
  TOWARDS_TYPES,
  "key",
  "value"
);

// Graduation Status
export const GRADUATION_STATUS = [
  {
    key: "GRADUATED",
    value: "Graduated"
  },
  {
    key: "ONGOING",
    value: "Ongoing"
  },
  {
    key: "PENDING_LEASE",
    value: "Awaiting Lease Completion"
  }
];

export const GRADUATION_STATUS_OBJECT = generateObject(
  GRADUATION_STATUS,
  "key",
  "value"
);

export const GRADUATION_REASONS = [
  {
    key: "EXPANSION",
    value: "Expansion"
  },
  {
    key: "MOVING_TO_DIFFERENT_PLACE",
    value: "Moving to a different place"
  },
  {
    key: "COMPLETION_OF_INCUBATION_PERIOD",
    value: "Completion of Incubation Period"
  },
  {
    key: "SUSPENDING_THE_OPERATIONS",
    value: "Suspending the operations"
  },
  {
    key: "OTHER",
    value: "Any other reason"
  }
];

export const GRADUATION_REASONS_OBJECT = generateObject(
  GRADUATION_REASONS,
  "key",
  "value"
);

// paymentTransferType
export const PAYMENT_TRANSFER_TYPES = [
  {
    key: "IMPS",
    value: "IMPS"
  },
  {
    key: "NEFT",
    value: "NEFT"
  },
  {
    key: "RTGS",
    value: "RTGS"
  },
  {
    key: "NET_BANKING",
    value: "Net Banking"
  },
  {
    key: "OTHERS",
    value: "Others"
  }
];

export const PAYMENT_TRANSFER_TYPES_OBJECT = generateObject(
  PAYMENT_TRANSFER_TYPES,
  "key",
  "value"
);

export const LEGAL_STATUS_TYPES = [
  {
    key: "PROPRIETARY",
    value: "Proprietorship"
  },
  {
    key: "PARTNERSHIP",
    value: "Partnership"
  },
  {
    key: "PVTLTD",
    value: "Private Limited"
  },
  {
    key: "LLP",
    value: "LLP"
  },
  {
    key: "OPC",
    value: "OPC"
  }
];

export const LEGAL_STATUS_TYPES_OBJECT = generateObject(
  LEGAL_STATUS_TYPES,
  "key",
  "value"
);

export const EDU_QUALIFICATION_TYPES = [
  {
    key: "UG",
    value: "Under Graduate/Bachelors (B.E, B.Tech, etc...)"
  },
  {
    key: "PG",
    value: "Post Graduate/Masters (M.Tech, MBA, etc...)"
  },
  {
    key: "DOCTORATE",
    value: "Doctorate (Ph.D)"
  },
  {
    key: "DIPLOMA",
    value: "Diploma"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export const EDU_QUALIFICATION_TYPES_OBJECT = generateObject(
  EDU_QUALIFICATION_TYPES,
  "key",
  "value"
);

export const INCUBATEE_TYPES_2 = [
  {
    key: "Individual",
    value: "Individual"
  },
  {
    key: "Organization",
    value: "Organization"
  }
];

export const INCUBATEE_TYPES_2_OBJECT = generateObject(
  INCUBATEE_TYPES_2,
  "key",
  "value"
);

export const INCUBATEE_TYPES = [
  {
    key: "INDIVIDUAL",
    value: "Individual"
  },
  {
    key: "COMPANY",
    value: "Organization"
  }
];

export const INCUBATEE_TYPES_OBJECT = generateObject(
  INCUBATEE_TYPES,
  "key",
  "value"
);

export const LEASE_REQUEST_TYPES = [
  {
    key: "NEW",
    value: "New"
  },
  {
    key: "RENEW",
    value: "Renew"
  },
  {
    key: "TERMINATE",
    value: "Terminate"
  }
];

export const LEASE_REQUEST_TYPES_OBJECT = generateObject(
  LEASE_REQUEST_TYPES,
  "key",
  "value"
);

export const DASHBOARD_PENDING_ITEM_TYPES = [
  {
    key: "Lease",
    value: "Lease"
  },
  {
    key: "LeaseTermination",
    value: "Lease"
  },
  {
    key: "IncubateeOnboard",
    value: "Incubatee Onboarding"
  }
];

export const DASHBOARD_PENDING_ITEM_TYPES_OBJECT = generateObject(
  DASHBOARD_PENDING_ITEM_TYPES,
  "key",
  "value"
);

export const STATUS_LIST = [
  {
    key: "ACTIVE",
    value: "Active"
  },
  {
    key: "INACTIVE",
    value: "Inactive"
  }
];

export const STATUS_LIST_OBJECT = generateObject(STATUS_LIST, "key", "value");

export const PARTITION_STATUS_LIST = [
  {
    key: "ACTIVE",
    value: "Active"
  },
  {
    key: "PROVISIONED",
    value: "Provisioned"
  },
  {
    key: "RETIRED",
    value: "Retired"
  }
];

export const PARTITION_STATUS_LIST_OBJECT = generateObject(
  PARTITION_STATUS_LIST,
  "key",
  "value"
);

export const EMAIL_TYPES_LIST = [
  {
    key: "WORK",
    value: "Work"
  },
  {
    key: "PERSONAL",
    value: "Personal"
  },
  {
    key: "OFFICE",
    value: "Office"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export const EMAIL_TYPES_LIST_OBJECT = generateObject(
  EMAIL_TYPES_LIST,
  "key",
  "value"
);

export const PHONE_TYPES_LIST = [
  {
    key: "WORK",
    value: "Work"
  },
  {
    key: "PERSONAL",
    value: "Personal"
  },
  {
    key: "OFFICE",
    value: "Office"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export const PHONE_TYPES_LIST_OBJECT = generateObject(
  PHONE_TYPES_LIST,
  "key",
  "value"
);

export const ADDRESS_TYPES_LIST = [
  {
    key: "PERMANENT",
    value: "Permanent"
  },
  {
    key: "COMMUNICATION",
    value: "Communication"
  },
  {
    key: "OFFICE",
    value: "Office"
  }
];

export const ADDRESS_TYPES_LIST_OBJECT = generateObject(
  ADDRESS_TYPES_LIST,
  "key",
  "value"
);

export const MILESTONE_CATEGORY_TYPES = [
  {
    key: "AWARD",
    value: "Awards/Recognition"
  },
  {
    key: "FUND_RAISE",
    value: "Fund Raised"
  },
  {
    key: "IP",
    value: "Intellectual Property"
  },
  {
    key: "Colloborations",
    value: "Partnership/Collaborations"
  },
  {
    key: "PRODUCT_LAUNCH",
    value: "Product Launch"
  },
  {
    key: "MERGER_ACQ",
    value: "Merger and Acquisitions"
  },
  {
    key: "CLIENT_ORDER",
    value: "Notable Client or Orders"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export const MILESTONE_CATEGORY_TYPES_OBJECT = generateObject(
  MILESTONE_CATEGORY_TYPES,
  "key",
  "value"
);

export const LEASE_TYPES = [
  {
    key: "REGULAR",
    value: "Regular"
  },
  {
    key: "COMPLEMENTARY",
    value: "Complementary"
  },
  {
    key: "ONE_OF_PAYMENT",
    value: "One off Payment"
  }
];

export const LEASE_TYPES_OBJECT = generateObject(LEASE_TYPES, "key", "value");

export const RENTAL_TYPES = [
  {
    key: "ON_PREMISE",
    value: "On-Premise"
  },
  {
    key: "EXTERNAL_USAGE",
    value: "External Usage"
  },
  {
    key: "ON_PREMISE/EXTERNAL_USAGE",
    value: "On-Premise/External Usage"
  }
];

export const RENTAL_TYPES_OBJECT = generateObject(RENTAL_TYPES, "key", "value");

export const ASSET_CATEGORIES = [
  {
    key: "EQUIPMENT",
    value: "Equipment"
  },
  {
    key: "FACILITY/LAB_USE",
    value: "Facility / Lab use"
  },
  {
    key: "FACILITY_CONSULTING",
    value: "Facility Consulting"
  }
];

export const ASSET_CATEGORIES_OBJECT = generateObject(
  ASSET_CATEGORIES,
  "key",
  "value"
);

export const AWARD_STATUS = [
  {
    key: "ACTIVE",
    value: "Active"
  },
  {
    key: "CLOSED",
    value: "Closed"
  },
  {
    key: "EXTENDED",
    value: "Extended"
  },
  {
    key: "TERMINATED",
    value: "Terminated"
  }
];

export const AWARD_STATUS_OBJECT = generateObject(AWARD_STATUS, "key", "value");

export const GRANT_AWARD_REVIEW_STATUS = [
  {
    key: "INPROGRESS",
    value: "In Progress"
  },
  {
    key: "CLOSED",
    value: "Closed"
  }
];

export const GRANT_AWARD_REVIEW_STATUS_OBJECT = generateObject(
  GRANT_AWARD_REVIEW_STATUS,
  "key",
  "value"
);

export const CLOSURE_STATUS_LIST = [
  {
    key: "CLOSED",
    value: "Closed"
  },
  {
    key: "NOT_CLOSED",
    value: "Not Closed"
  }
];

export const CLOSURE_STATUS_LIST_OBJECT = generateObject(
  CLOSURE_STATUS_LIST,
  "key",
  "value"
);

export const CLOSURE_STATUS_REASONS = [
  {
    key: "COMPLETED",
    value: "Succssfully Completed"
  },
  {
    key: "NOT_COMPLETED",
    value: "Not Completed"
  }
];

export const CLOSURE_STATUS_REASONS_OBJECT = generateObject(
  CLOSURE_STATUS_REASONS,
  "key",
  "value"
);

export const GRANT_AWARD_CLOSURE_STATUS_REASONS = [
  {
    key: "COMPLETED",
    value: "Met Grant Objectives"
  },
  {
    key: "PARTIAL",
    value: "Grant Objectives Not Met"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export const GRANT_AWARD_CLOSURE_STATUS_REASONS_OBJECT = generateObject(
  GRANT_AWARD_CLOSURE_STATUS_REASONS,
  "key",
  "value"
);

export const FIELD_TEMPLATE_TYPES = [
  {
    key: "text",
    value: "Single line text"
  },
  {
    key: "textarea",
    value: "Multi line text"
  },
  {
    key: "number",
    value: "Number"
  },
  {
    key: "date",
    value: "Date"
  }
];

export const FIELD_TEMPLATE_TYPES_OBJECT = generateObject(
  FIELD_TEMPLATE_TYPES,
  "key",
  "value"
);

export const GRANT_AWARD_TERMINATION_STATUS_REASONS = [
  {
    key: "DISCONTINUED",
    value: "Discontinued"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export const GRANT_AWARD_TERMINATION_STATUS_REASONS_OBJECT = generateObject(
  GRANT_AWARD_TERMINATION_STATUS_REASONS,
  "key",
  "value"
);

export const DASHBOARD_PENDING_ITEM_SUB_CATEGORY_MAPPER = {
  "Lease|NEW": { cssClassName: "success", label: "New" },
  "Lease|RENEW": { cssClassName: "info", label: "Renew" },
  "LeaseTermination|TERMINATE": { cssClassName: "danger", label: "Terminate" }
};

export const checkEmptyValue = (inputError, fieldName, value) => {
  if (value === undefined || value === "" || value === null) {
    inputError[fieldName] = true;
    return true;
  }
  return false;
};

export const renderInputData = data => {
  return data ? data : "";
};

export const setInputData = data => {
  return data ? data : "";
};

export const changeToINR = number => {
  let num = number;
  if (isNaN(num)) {
    return num;
  }
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(num);
};

export const roundOf = number => {
  let num = number;
  if (isNaN(num)) {
    return num;
  }
  return Math.round(number);
};

export const numberFormat = number => {
  let num = number;
  if (isNaN(num)) {
    return num;
  }
  num = roundOf(num);
  return num.toLocaleString("en");
};

export const AppFormStatus = status => {
  const STATUS_MAPPER = {
    CREATED: "Completed",
    NOT_STARTED: "Pending",
    REJECTED: "Cancelled",
    CLOSED: "Closed",
    INPROGRESS: "In Progress"
  };
  const desc = STATUS_MAPPER[status] || "In Progress";
  return desc;
};
export const EVENT_TYPES = [
  {
    value: "CONFERENCE",
    label: "Conference"
  },
  {
    value: "EXPO",
    label: "Expo"
  },
  {
    value: "MEETING",
    label: "Meeting"
  },
  {
    value: "WORKSHOP",
    label: "Workshop"
  },
  {
    value: "OTHER",
    label: "Others"
  }
];

export const EVENT_TYPES_OBJECT = generateObject(EVENT_TYPES, "value", "label");

export const FUNDING_AGENCY_TYPES = [
  {
    value: "CENTRAL",
    label: "Central"
  },
  {
    value: "STATE",
    label: "State"
  }
];

export const FUNDING_AGENCY_TYPES_OBJECT = generateObject(
  FUNDING_AGENCY_TYPES,
  "value",
  "label"
);

export const generateEnumValues = (data, type) => {
  const newType = type || "string";
  return data.map(object => {
    return {
      type: newType,
      title: object.value,
      enum: [object.key]
    };
  });
};
