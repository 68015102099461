import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoLookup from "../../../custom/AutoLookup";
import { getDisplayDate, addMonths } from "../../../lease/DateUtil";
import HttpUtil from "../../../common/HttpUtil";
import AvailabilitySearchResults from "./AssetAvailabilitySearchResults";
import { ASSET_TYPES_API, ASSET_LOOKUP_API } from "../../../common/Constants";
import AlertComponent from "../../../common/AlertComponent";

function validate(values) {
  let filterApplied = values.assetType;

  filterApplied =
    filterApplied && values.fromDate !== "" && values.toDate !== "";

  if (!filterApplied) {
    return false;
  }

  return filterApplied;
}

function getDateWitMonthOffset(offset, date) {
  const newDate = date || new Date();
  return addMonths(newDate, offset);
}

const SearchForm = props => {
  const { values, setFieldValue, assetTypes } = props;

  let filterApplied = validate(values);

  return (
    <Fragment>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
          Asset Type
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            component="select"
            name="assetType"
            value={values.assetType || ""}
            className="custom-select custom-select-sm"
            onChange={e => {
              if (e.target.value) {
                setFieldValue("assetType", e.target.value);
              } else {
                setFieldValue("assetType", "");
              }
              setFieldValue("asset", "");
            }}
          >
            <option value="">Select...</option>
            {assetTypes.map((at, i) => (
              <option key={i} value={at.value}>
                {at.label}
              </option>
            ))}
          </Field>
          <div className="invalid-feedback">Please select customer type *</div>
        </div>

        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
          Select Asset
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <AutoLookup
            name={"label"}
            value={values.asset ? values.asset : null}
            getObject={value => {
              if (value) {
                const asset = {
                  label: value.label,
                  value: value.lookup.value,
                  lookup: value.lookup
                };
                setFieldValue("asset", asset);
              } else {
                setFieldValue("asset", "");
              }
            }}
            url={
              values.assetType
                ? `${ASSET_LOOKUP_API}/?assetType=${values.assetType}`
                : ASSET_LOOKUP_API
            }
          />
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
          From Date
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <DatePicker
            value={values.fromDate ? getDisplayDate(values.fromDate) : ""}
            selected={values.fromDate ? values.fromDate : null}
            onChange={date => {
              setFieldValue("fromDate", date);
            }}
            utcOffset={0}
            minDate={getDateWitMonthOffset(-12)}
            maxDate={values.toDate || getDateWitMonthOffset(6)}
            placeholderText="dd-mm-yyyy"
            className="form-control form-control-sm"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>

        <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
          To Date
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <DatePicker
            value={values.toDate ? getDisplayDate(values.toDate) : ""}
            selected={values.toDate ? values.toDate : null}
            onChange={date => {
              setFieldValue("toDate", date);
            }}
            utcOffset={0}
            minDate={values.fromDate || getDateWitMonthOffset(-12)}
            maxDate={getDateWitMonthOffset(6)}
            placeholderText="dd-mm-yyyy"
            className="form-control form-control-sm"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-12 text-center">
          {filterApplied ? (
            <button className="btn btn-sm btn-primary" type="submit">
              Search
            </button>
          ) : (
            <button className="btn btn-sm btn-primary" type="button" disabled>
              Search
            </button>
          )}

          <button
            className="btn btn-sm btn-outline-secondary ml-2"
            onClick={() => {
              setFieldValue("assetType", "");
              setFieldValue("asset", "");
              setFieldValue("fromDate", "");
              setFieldValue("toDate", "");
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </Fragment>
  );
};

const SEARCH_SCHEMA = {};
class AssetAvailabilitySearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        assetType: "",
        asset: "",
        fromDate: "",
        toDate: ""
      },
      assetTypes: [],
      showResults: false
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  handleSubmit = (values, { setSubmitting }) => {
    const assetSearch = {
      assetType: values.assetType,
      asset: values.asset,
      fromDate: values.fromDate,
      toDate: values.toDate
    };

    sessionStorage.setItem("assetSearch", JSON.stringify(assetSearch));

    setSubmitting(true);
    this.setState({ initialValues: values, showResults: true });
  };

  addValue = (obj, field, value) => {
    if (value) {
      obj[field] = value;
    }
  };

  loadData = () => {
    let newSearch = false;
    if (this.props.location.search) {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      newSearch = params.get("new");
    }

    let assetSearch = sessionStorage.getItem("assetSearch");

    // New Search or no params stored in session storage, then return null record
    if (newSearch || !assetSearch) {
      sessionStorage.removeItem("assetSearch");
      return null;
    }
    const initialValues = { ...this.state.initialValues };
    assetSearch = JSON.parse(assetSearch);

    const assetType = assetSearch.assetType;
    const asset = assetSearch.asset;
    const fromDate = new Date(assetSearch.fromDate);
    const toDate = new Date(assetSearch.toDate);

    this.addValue(initialValues, "assetType", assetType);
    this.addValue(initialValues, "asset", asset);
    this.addValue(initialValues, "fromDate", fromDate);
    this.addValue(initialValues, "toDate", toDate);
    return initialValues;
  };

  getAllAssetTypes = () => {
    HttpUtil.get(
      ASSET_TYPES_API,
      {},
      data => {
        const initialValues = this.loadData();
        const newState = initialValues
          ? { initialValues, showResults: true, assetTypes: data }
          : { assetTypes: data };

        this.setState(newState);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getAllAssetTypes();
  }

  render() {
    const props = {
      initialValues: this.state.initialValues,
      validationSchema: SEARCH_SCHEMA,
      handleSubmit: this.handleSubmit
    };

    const formProps = {
      assetTypes: this.state.assetTypes
    };

    const resultProps = {
      showResults: this.state.showResults
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12 text-center">
            <h5 className="font-weight-bold" style={{ color: "#647acb" }}>
              Asset Availability Search
            </h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="card-header border-bottom-0 pt-3 text-center">
                <span className="edit-page-title" style={{ color: "#647acb" }}>
                  Search
                </span>
              </div>

              <div className="card-body">
                <div className="row no-gutters">
                  <div className="col-md-12">
                    <Formik
                      enableReinitialize
                      initialValues={props.initialValues}
                      validationSchema={props.validationSchema}
                      onSubmit={(values, { setSubmitting }) =>
                        props.handleSubmit(values, { setSubmitting })
                      }
                      render={renderProps => (
                        <Form>
                          <SearchForm {...renderProps} {...formProps} />
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SearchResults {...resultProps} values={this.state.initialValues} />
      </Fragment>
    );
  }
}

export default AssetAvailabilitySearch;

export const SearchResults = props => {
  const { values, showResults } = props;
  const { assetType, fromDate } = values;

  if (assetType === "" || fromDate === "" || !showResults) {
    return null;
  }

  return (
    <Fragment>
      <div className="row mt-4 mb-5">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-header border-bottom-0 pt-3 text-center">
              <span
                className="edit-page-title"
                style={{ color: "rgb(100, 122, 203)" }}
              >
                Search Results
              </span>
            </div>
            <div className="card-body">
              <AvailabilitySearchResults
                assetType={values.assetType}
                asset={
                  values.asset &&
                  values.asset.lookup &&
                  values.asset.lookup.value
                }
                fromDate={values.fromDate}
                toDate={values.toDate}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
