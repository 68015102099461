import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import { FUND_AGENCY_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";

class AgencyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agency: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      confirm: false
    });
  };

  getFundingAgency = id => {
    const url = `${FUND_AGENCY_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        const agency = {};
        agency._id = data._id || "";
        agency.name = data.name || "";
        agency.fullName = data.fullName || "";

        agency.type = data.type;
        agency.description = data.description;
        agency.logo = data.logo;
        agency.website = data.website;

        agency.state = data.state || "";
        agency.country = data.country || "IN";

        agency.grants = data.grants || [];

        const breadCrumbList = [
          {
            linkTo: "/funding/agencies",
            displayName: "Funding Agencies"
          },
          {
            linkTo: null,
            displayName: agency.name
          }
        ];

        this.setState({ agency, breadCrumbList });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getFundingAgency(id);
    }
  };
  render() {
    const { agency } = this.state;

    if (agency === null) {
      return null;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <Breadcrumb breadCrumbList={this.state.breadCrumbList} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div
          className="elevate mb-5"
          style={{ padding: "1em", backgroundColor: "#fff" }}
        >
          <div className="card border-0">
            <div className="card-body text-center pb-0">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="mb-0" style={{ color: "rgb(100, 122, 203)" }}>
                  {agency.name}
                </h2>
                <Link
                  to="/funding/grants/new"
                  role="button"
                  className="btn btn-sm text-white bg-pink"
                >
                  New Grant
                </Link>
              </div>
              {agency.description ? (
                <div className="text-justify py-2">{agency.description}</div>
              ) : null}
            </div>
          </div>

          {agency.grants.length > 0 ? (
            <div
              className="card m-3"
              style={{ borderTop: "2px solid rgb(100, 122, 203)" }}
            >
              <table className="table mb-1">
                <thead className="">
                  <tr>
                    <th
                      scope="col"
                      className="border-bottom-0 display-table-header"
                      style={{ width: "20%" }}
                    >
                      Grant Name
                    </th>
                    <th
                      scope="col"
                      className="border-bottom-0 display-table-header"
                      style={{ width: "70%" }}
                    >
                      Grant Full Name
                    </th>
                    <th
                      scope="col"
                      className="border-bottom-0 display-table-header"
                      style={{ width: "10%" }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {agency.grants.map((grant, index) => {
                    return (
                      <tr key={index}>
                        <td>{grant.name}</td>
                        <td>{grant.fullName}</td>

                        <td>
                          <Link
                            to={`/funding/setup/agencies/info/${
                              agency._id
                            }/grant/${grant._id}`}
                          >
                            <i className="fas fa-eye fa-lg action-icon" />
                          </Link>
                          <Link
                            to={`/funding/grants/edit/${grant._id}`}
                            className="ml-2"
                          >
                            <i className="ml-2 fas fa-edit fa-lg action-icon" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default AgencyInformation;
