import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import HttpUtil from "../common/HttpUtil";
import { EVENTS_API } from "../common/Constants";
import { EVENT_TYPES } from "../common/LookupConstants";
import { getYearFirstDate } from "../../components/lease/DateUtil";
import EventSearchResults from "./EventSearchResults";
import AlertComponent from "../common/AlertComponent";

const initialValues = {
  type: "",
  fromDate: "",
  toDate: ""
};

const validationSchema = Yup.object().shape({
  type: Yup.string().label("Event Type"),
  fromDate: Yup.date()
    .label("Start Date")
    .when("toDate", (toDate, schema) =>
      !toDate
        ? schema.required("From Date is requried when End Date is not entered.")
        : schema
    ),
  toDate: Yup.date().label("End Date")
});

function appendQueryString(obj, field, value) {
  if (value) {
    obj.append(field, value);
  }
}

class EventSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null
    };
  }

  prepareQueryString(values) {
    const searchParams = new URLSearchParams();
    appendQueryString(searchParams, "type", values.type);
    appendQueryString(searchParams, "from", values.fromDate);
    appendQueryString(searchParams, "to", values.toDate);

    return searchParams.toString();
  }
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    const queryString = this.prepareQueryString(values);
    HttpUtil.get(
      `${EVENTS_API}/search?${queryString}`,
      {},
      data => this.setState({ events: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };
  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  okConfirmUpdate = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      userConfirmation: true
    });
  };

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <div className="mb-5">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
        >
          {({ errors, touched, values }) => {
            return (
              <Form>
                <div className="row">
                  <div className="row mb-3">
                    <div className="col-md-12">
                      {/* show alert message  */}
                      <AlertComponent {...alertProps} />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 m-auto">
                    <div className="card edit-page-container">
                      <div className="card-body pr-5 pl-5">
                        <h4
                          className="card-title text-center"
                          style={{ color: "rgb(100, 122, 203)" }}
                        >
                          Event Search
                        </h4>
                        <div className="row">
                          <div className="col-md-12 pt-0">
                            <div className="row">
                              <div className="col-12 edit-page-section-header-text">
                                Search params
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.error ? (
                          <div
                            className="alert alert-danger text-center"
                            role="alert"
                          >
                            {this.state.message}
                          </div>
                        ) : null}
                        <div className="member-search-params mt-4">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <div className="row">
                                <div className="form-group col-md-4">
                                  <label
                                    className="edit-page-title mb-0"
                                    htmlFor="type"
                                  >
                                    Event Type
                                  </label>
                                </div>
                                <div className="form-group col-md-8">
                                  <Field
                                    component="select"
                                    className={`custom-select custom-select-sm ${
                                      errors.type && touched.type
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name="type"
                                  >
                                    <option value="">Select</option>
                                    {EVENT_TYPES.map(type => (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    component="div"
                                    name="type"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <div className="row">
                                <div className="form-group col-md-4">
                                  <label
                                    className="edit-page-title mb-0"
                                    htmlFor="fromDate"
                                  >
                                    Start Date
                                  </label>
                                </div>
                                <div className="col-8 ">
                                  <Field
                                    className={`form-control form-control-sm ${
                                      errors.fromDate && touched.fromDate
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name="fromDate"
                                    type="date"
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="fromDate"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="row">
                              <div className="form-group col-md-4">
                                <label
                                  className="edit-page-title mb-0"
                                  htmlFor="toDate"
                                >
                                  End Date
                                </label>
                              </div>
                              <div className="form-group col-md-8">
                                <Field
                                  className={`form-control form-control-sm ${
                                    errors.toDate && touched.toDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  min={
                                    values.fromDate ||
                                    getYearFirstDate(new Date())
                                  }
                                  name="toDate"
                                  type="date"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="toDate"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="m-auto text-center">
                          <button
                            className="btn btn-sm btn btn-primary mt-4"
                            type="submit"
                          >
                            Search
                          </button>
                          <button
                            className="btn btn-sm btn-outline-secondary mt-4 ml-4"
                            type="reset"
                            onClick={() => {
                              this.setState({ events: null });
                            }}
                          >
                            Clear
                          </button>
                        </div>

                        {this.state.events !== null ? (
                          <div className="search-results">
                            <div className="row">
                              <div className="col-12 mt-5 card-title edit-page-section-header-text">
                                Search Results
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 m-0">
                                <EventSearchResults
                                  events={this.state.events}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default EventSearch;
