import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";

import HttpUtil from "../common/HttpUtil";
import { INCUBATEES_ONBOARD_API } from "../common/Constants";
import { getDescription } from "../common/Util";
import { INCUBATEE_TYPES_OBJECT } from "../common/LookupConstants";
import TableList from "../common/TableList";
import AlertComponent from "../common/AlertComponent";
import Can from "../../auth/can";

class IncubateeOnboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incbOnboardings: null,

      // redirect to edit page
      editOnboardForm: false,
      editOnboardFormById: ""
    };
  }

  handleOnboard = (row, e) => {
    if (!row) return null;
    let id = row._id;
    return this.setState({
      editOnboardForm: true,
      editOnboardFormById: id
    });
  };

  statusFormatter = (cell, row) => {
    if (!row) return null;
    const status = getDescription("IncubateeOnboarding", row.stage, row.status);
    return status;
  };

  actionsFormater = (cell, row) => {
    if (!row) return null;
    return (
      <Fragment key={row._id}>
        <Can do="VIEW" on="IncubateeOnboardingForm">
          <Link
            to={`/onboard/incubatees/edit/${row._id}?from=/onboard/incubatees`}
            role="button"
            onClick={this.handleOnboard.bind(this, row)}
          >
            {row.stage === "COMPLETE" ? (
              <i
                className="fas fa-eye fa-lg action-icon"
                datatoggle="tooltip"
                title="View"
              />
            ) : (
              <i
                className="fas fa-edit fa-lg action-icon"
                datatoggle="tooltip"
                title="Edit"
              />
            )}
          </Link>
        </Can>
        &nbsp;
        {!row.name ? (
          <Can do="DELETE" on="IncubateeOnboardingForm">
            <span
              role="button"
              onClick={this.handleDeleteOnboard.bind(this, row._id)}
              className="cursor-pointer ml-4"
            >
              <i
                className="fas fa-trash-alt fa-lg action-icon-danger"
                datatoggle="tooltip"
                title="Remove"
              />
            </span>
          </Can>
        ) : null}
      </Fragment>
    );
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  handleDeleteOnboard = id => {
    const url = `${INCUBATEES_ONBOARD_API}/${id}`;
    HttpUtil.delete(
      url,
      {},
      data => this.handleApiSuccess(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    this.getOnboardList();
  };

  createNewOnboard = () => {
    let formData = new FormData();
    const onboardForm = {
      incubateeType: "COMPANY",
      individual: {},
      organization: {},
      emails: [],
      phones: [],
      identificationDocs: [],
      addresses: [],
      businessPlan: "",
      graduationStatus: "",
      incubateeStage: "",
      incubationSector: "",
      subTechSector: "",
      targetSector: "",
      grandsOrFunding: "",
      businessModel: "",
      currentEmployeeCount: "",
      femaleEmployeeCount: "",
      residentialStatus: "",
      incubationCategory: "",
      anchorIncubator: null,
      supplementaryIncubators: [],
      patents: [],
      stage: "",
      status: "",
      action: "SAVE"
    };

    formData.append("message", JSON.stringify(onboardForm));

    const url = INCUBATEES_ONBOARD_API;
    const headers = {};

    HttpUtil.post(
      url,
      headers,
      formData,
      data => {
        this.setState({
          editOnboardForm: true,
          editOnboardFormById: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleApiSuccess = data => {
    const onboardingList = [];
    data.forEach(onboard => {
      let name = "";
      if (onboard.incubateeType === "COMPANY") {
        name = onboard.organization.name;
      } else if (onboard.individual) {
        name = onboard.individual.firstName;
      }

      const relationshipManager = onboard.relationshipManager || "--";
      const names = [];
      if (relationshipManager.firstName) {
        names.push(relationshipManager.firstName);
      }
      if (relationshipManager.firstName) {
        names.push(relationshipManager.lastName);
      }
      let newOnboard = {
        _id: onboard._id,
        incubateeType: onboard.incubateeType,
        name,
        relationshipManager: names.join(" ") || "--",
        stage: onboard.stage,
        status: onboard.status
      };
      onboardingList.push(newOnboard);
    });
    return this.setState({ incbOnboardings: onboardingList });
  };

  getOnboardList = () => {
    let url = `${INCUBATEES_ONBOARD_API}/in-progress`;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  render() {
    const {
      incbOnboardings,

      editOnboardForm,
      editOnboardFormById
    } = this.state;

    if (editOnboardForm) {
      let url = "/onboard/incubatees/edit/" + editOnboardFormById;
      return <Redirect to={url} />;
    }

    const columnsOnboard = [
      {
        dataField: "name",
        text: "Applicant Name",
        formatter: cell => cell || "--",
        sort: true
      },
      {
        dataField: "incubateeType",
        text: "Incubatee Type",
        sort: true,
        formatter: cell => INCUBATEE_TYPES_OBJECT[cell],
        csvFormatter: cell => INCUBATEE_TYPES_OBJECT[cell]
      },

      {
        dataField: "relationshipManager",
        text: "Relationship Manager",
        sort: true,
        headerStyle: {
          width: "20%"
        }
      },

      {
        dataField: "status",
        text: "Status",
        formatter: this.statusFormatter,
        csvFormatter: this.statusFormatter,
        sort: true
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        csvExport: false,
        formatter: this.actionsFormater
      }
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsOnboard}
          data={incbOnboardings}
          noDataIndication="No Incubatee Onboard Forms found."
          sizePerPage="10"
          headerName="Incubatee Onboard Forms"
          showSearchbar={true}
          customComponent={
            <RenderCustomButton createNewOnboard={this.createNewOnboard} />
          }
        />
      </Fragment>
    );
  }
}

export default IncubateeOnboard;

const RenderCustomButton = props => {
  return (
    <Can do="CREATE" on="IncubateeOnboardingForm">
      <button
        className="btn btn-sm text-white bg-pink"
        onClick={props.createNewOnboard}
      >
        New
      </button>
    </Can>
  );
};
