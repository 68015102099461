import React, { Component, Fragment } from "react";
import HttpUtil from "../../common/HttpUtil";
import { ADJUSTMENTS_API } from "../../common/Constants";
import AlertComponent from "../../common/AlertComponent";
import AdjustmentPreviewForm from "./AdjustmentPreviewForm";

class ViewAdjustment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewForm: null
    };
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getAdjustment(id);
    }
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  addData = (adjustment, labelName, value) => {
    if (value) {
      adjustment.push({
        label: labelName,
        value
      });
    }
  };

  nameFormatter = data => {
    let name = "";
    let names = [];
    if (data.adjustmentTo.firstName) {
      names.push(data.adjustmentTo.firstName);
    }
    if (data.adjustmentTo.lastName) {
      names.push(data.adjustmentTo.lastName);
    }
    name = names.length > 0 ? names.join(" ") : data.adjustmentTo.name;
    return name;
  };

  handleData = data => {
    let alertType,
      showAlert,
      alertColor,
      alertMessage = null;
    if (this.props.location.state !== undefined) {
      alertType = "Default";
      showAlert = true;
      alertColor = "success";
      alertMessage = "Adjustment Payment has been submitted successfully.";
    }

    const remarks = data.notes.length > 0 ? data.notes[0] : "";

    const previewForm = { ...data };
    previewForm.adjustmentTo = this.nameFormatter(data);
    previewForm.remarks = remarks;

    this.setState({
      previewForm,
      alertType,
      showAlert,
      alertColor,
      alertMessage
    });
  };

  getAdjustment = id => {
    const url = `${ADJUSTMENTS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => this.handleData(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };
  render() {
    const {
      alertType,
      showAlert,
      alertColor,
      alertMessage,

      previewForm
    } = this.state;

    if (previewForm === null) {
      return null;
    }

    // For AlertComponent
    const alertProps = {
      show: showAlert,
      type: alertType,
      alertColor: alertColor,
      message: alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    // const rows = adjustment.map((pmt, index) => {
    //   return (
    //     <tr key={index}>
    //       <th>{pmt.label}</th>
    //       <td>{pmt.value}</td>
    //     </tr>
    //   );
    // });

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <AdjustmentPreviewForm
          data={this.state.previewForm}
          title="Adjustment Information"
        />
        {/* <div className="row">
          <div className="col-6">
            <div className="card">
              <div className="card-header bg-card-header text-white">
                <h5 className="h6 card-title mb-0">
                  Adjustment Payment information
                </h5>
              </div>
              <div className="card-body">
                <table className="table table-sm my-2 table-borderless">
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </Fragment>
    );
  }
}

export default ViewAdjustment;
