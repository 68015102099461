import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AlertComponent from "../../common/AlertComponent";
import * as Yup from "yup";
import { SEARCH_INDVL_ORG_API, INCUBATEES_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import Select from "react-select";

const REQEUEST_FORM = Yup.object().shape({
  requestorType: Yup.string()
    .label("Requester Type")
    .required(),

  incubatee: Yup.string().when("requestorType", {
    is: "INCUBATEE",
    then: Yup.string()
      .label("Incubatee")
      .required()
  }),

  firstName: Yup.string().when("requestorType", {
    is: "INDIVIDUAL",
    then: Yup.string()
      .label("First Name")
      .required()
  }),

  organizationName: Yup.string().when("requestorType", {
    is: "ORGANIZATION",
    then: Yup.string()
      .label("Organization Name")
      .required()
  }),

  email: Yup.string().when("requestorType", {
    is: "INCUBATEE",
    then: Yup.string().notRequired(),
    otherwise: Yup.string()
      .label("Email")
      .required()
  }),
  mobile: Yup.string().when("requestorType", {
    is: "INCUBATEE",
    then: Yup.string().notRequired(),
    otherwise: Yup.string()
      .label("Mobile")
      .required()
  })
});

class DuplicateCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      redirectPage: "",
      initialValues: {
        requestorType: "INCUBATEE",
        firstName: "",
        lastName: "",
        organizationName: "",
        email: "",
        mobile: "",
        incubatee: ""
      },
      incubatees: []
    };
  }

  setMatchedPhones = (matchedPhones, phones) => {
    let allPhones = [];
    const mPhones = {};
    matchedPhones.forEach(ph => {
      mPhones[ph.phone] = true;
    });

    phones.forEach(ph => {
      const matched = mPhones[ph.phone] || false;
      const phone = { ...ph };
      phone.matched = matched;
      allPhones.push(phone);
    });
    return allPhones;
  };

  setMatchedEmails = (matchedEmails, emails) => {
    let allEmails = [];
    const mEmails = {};
    matchedEmails.forEach(em => {
      mEmails[em.email] = true;
    });

    emails.forEach(em => {
      const matched = mEmails[em.email] || false;
      const email = { ...em };
      email.matched = matched;
      allEmails.push(email);
    });

    return allEmails;
  };

  getIndividual = data => {
    const individuals = data.individuals || [];
    const indvls = individuals.map(indvl => {
      let names = [];

      if (indvl.firstName) {
        names.push(indvl.firstName);
      }
      if (indvl.lastName) {
        names.push(indvl.lastName);
      }
      const matchedEmails = indvl.matchedEmails || [];
      const emails = this.setMatchedEmails(matchedEmails, indvl.emails);

      const matchedphones = indvl.matchedphones || [];
      const phones = this.setMatchedPhones(matchedphones, indvl.phones);

      const individual = {
        _id: indvl._id,
        name: names.join(" "),
        emails,
        phones,
        requestorType: "Individual"
      };
      return individual;
    });

    return indvls;
  };

  getOrganization = data => {
    const organizations = data.organizations || [];

    const orgs = organizations.map(org => {
      const matchedEmails = org.matchedEmails || [];
      const emails = this.setMatchedEmails(matchedEmails, org.emails);

      const matchedphones = org.matchedphones || [];
      const phones = this.setMatchedPhones(matchedphones, org.phones);

      const organization = {
        _id: org._id,
        name: org.name,
        emails,
        phones,
        requestorType: "Organization"
      };
      return organization;
    });
    return orgs;
  };

  handleIncubateeSelected = values => {
    const initialValues = this.state.initialValues;
    initialValues.incubatee = values.incubatee;
    initialValues.requestorType = values.requestorType;

    const newAssetReservationFlow = {
      type: "NEW",
      category: "INCUBATEE",
      path: this.props.location.pathname,
      data: {
        incubatee: initialValues.incubatee
      }
    };

    sessionStorage.setItem(
      "newAssetReservationFlow",
      JSON.stringify(newAssetReservationFlow)
    );

    this.setState({
      redirect: true,
      redirectPage: "/service-mgmt/asset-reservation-requests/new",
      initialValues
    });
  };

  handleSubmit = (values, { setSubmitting }) => {
    let incubatee = values.incubatee || null;

    if (values.requestorType === "INCUBATEE" && incubatee) {
      this.handleIncubateeSelected(values);
    } else {
      const request = {
        email: values.email,
        phone: `${values.mobile}`
      };

      const url = `${SEARCH_INDVL_ORG_API}`;
      const headers = { "Content-Type": "application/json" };

      HttpUtil.post(
        url,
        headers,
        request,
        data => {
          const indvls = this.getIndividual(data);
          const orgs = this.getOrganization(data);

          const matchedRecords = [...indvls, ...orgs];

          if (matchedRecords.length > 0) {
            const newAssetReservationFlow = {
              type: "NEW",
              category: "MATCH",
              path: this.props.location.pathname,
              data: { ...values, matchedRecords }
            };

            sessionStorage.setItem(
              "newAssetReservationFlow",
              JSON.stringify(newAssetReservationFlow)
            );

            this.setState({
              initialValues: { ...values },
              redirect: true,
              redirectPage: "/service-mgmt/matched-users"
            });
          } else {
            const newAssetReservationFlow = {
              type: "NEW",
              category: "NOMATCH",
              path: this.props.location.pathname,
              data: values
            };

            sessionStorage.setItem(
              "newAssetReservationFlow",
              JSON.stringify(newAssetReservationFlow)
            );

            this.setState({
              initialValues: { ...values },
              redirect: true,
              redirectPage: "/service-mgmt/asset-reservation-requests/new"
            });
          }

          setSubmitting(false);
        },
        (data, status) => this.handleApiFailed(data.message),
        error => this.handleApiFailed(error.toString())
      );
    }
  };

  getIncubatees = () => {
    const url = `${INCUBATEES_API}/assetReservation`;
    HttpUtil.get(
      url,
      {},
      data => {
        const incubatees = data.map(incubatee => {
          return {
            label: incubatee.name,
            value: incubatee._id
          };
        });
        this.setState({ incubatees });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  loadLocationState = () => {
    const newAssetReservationFlow = sessionStorage.getItem(
      "newAssetReservationFlow"
    );
    if (newAssetReservationFlow) {
      const flowConfig = JSON.parse(newAssetReservationFlow);
      if (flowConfig.type !== "NEW") {
        return;
      }

      if (flowConfig.category === "INCUBATEE") {
        return this.setState({
          initialValues: {
            requestorType: "INCUBATEE",
            incubatee: flowConfig.data.incubatee
          }
        });
      } else if (
        flowConfig.category === "NOMATCH" ||
        flowConfig.category === "MATCH"
      ) {
        const initialValues = {
          firstName: flowConfig.data.firstName,
          lastName: flowConfig.data.lastName,
          organizationName: flowConfig.data.organizationName,
          mobile: flowConfig.data.mobile,
          email: flowConfig.data.email,
          requestorType: flowConfig.data.requestorType
        };
        return this.setState({
          initialValues
        });
      }
    }
  };

  componentDidMount = () => {
    this.getIncubatees();
    this.loadLocationState();
  };

  render() {
    const { redirect, redirectPage } = this.state;

    if (redirect) {
      return <Redirect to={redirectPage} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const formikProps = {
      initialValues: this.state.initialValues,
      validationSchema: REQEUEST_FORM,
      incubatees: this.state.incubatees,

      // events
      handleApiFailed: this.handleApiFailed,
      handleSubmit: this.handleSubmit
    };
    return (
      <div className="container-fluid-lg container-fluid-xl">
        <div className="row mb-3">
          <div className="col-md-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card elevate">
              <Formik
                enableReinitialize
                initialValues={formikProps.initialValues}
                validationSchema={formikProps.validationSchema}
                onSubmit={(values, { setSubmitting }) =>
                  formikProps.handleSubmit(values, { setSubmitting })
                }
                render={renderProps => (
                  <CustomForm {...renderProps} {...formikProps} />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DuplicateCheck;

const FormHeader = props => {
  return (
    <div className="card-header border-0">
      <span className="edit-page-title">
        <Link
          to="/service-mgmt/open-reservations"
          className="badge bg-white custom-btn-sm float-left"
        >
          <i className="fas fa-arrow-left mr-2" />
        </Link>
        Asset Usage Request
      </span>
    </div>
  );
};

const CustomForm = props => {
  const { values, touched, errors, setFieldValue, incubatees } = props;

  return (
    <Form>
      <FormHeader {...props} />
      <div className="card-body">
        <div className="row">
          <div className="col-12 mb-3 edit-page-section-header-text">
            ASSET REQUEST
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
            Requester Type
          </label>
          <div className="col-12 col-lg-6 col-xl-6">
            <div
              className={`custom-radios ${
                touched.requestorType && errors.requestorType
                  ? "is-invalid"
                  : ""
              }`}
            >
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  type="radio"
                  id="INCUBATEE"
                  name="requestorType"
                  value="INCUBATEE"
                  checked={values.requestorType === "INCUBATEE"}
                  className={`custom-control-input ${
                    touched.requestorType && errors.requestorType
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={e => {
                    setFieldValue("requestorType", e.target.value);
                    setFieldValue("organizationName", "");
                    setFieldValue("firstName", "");
                    setFieldValue("lastName", "");
                  }}
                />
                <label className="custom-control-label" htmlFor="INCUBATEE">
                  Incubatee
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline ml-3">
                <Field
                  type="radio"
                  id="INDIVIDUAL"
                  name="requestorType"
                  value="INDIVIDUAL"
                  checked={values.requestorType === "INDIVIDUAL"}
                  className={`custom-control-input ${
                    touched.requestorType && errors.requestorType
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={e => {
                    setFieldValue("requestorType", e.target.value);
                    setFieldValue("organizationName", "");
                    setFieldValue("incubatee", "");
                  }}
                />
                <label className="custom-control-label" htmlFor="INDIVIDUAL">
                  Individual
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline ml-3">
                <Field
                  type="radio"
                  id="ORGANIZATION"
                  name="requestorType"
                  value="ORGANIZATION"
                  checked={values.requestorType === "ORGANIZATION"}
                  className={`custom-control-input ${
                    touched.requestorType && errors.requestorType
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={e => {
                    setFieldValue("requestorType", e.target.value);
                    setFieldValue("firstName", "");
                    setFieldValue("lastName", "");
                    setFieldValue("incubatee", "");
                  }}
                />
                <label className="custom-control-label" htmlFor="ORGANIZATION">
                  Organization
                </label>
              </div>
            </div>
            <ErrorMessage
              component="div"
              name="requestorType"
              className="invalid-feedback"
            />
          </div>
        </div>

        {values.requestorType === "INCUBATEE" ? (
          <div className="form-group row">
            <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
              Incubatee
            </label>

            <div className="col-12 col-lg-5 col-xl-5">
              <Select
                isClearable
                isSearchable
                name="incubatee"
                options={incubatees}
                value={values.incubatee}
                onChange={selectedIncubatee => {
                  const value = selectedIncubatee || "";
                  setFieldValue("incubatee", value);
                }}
                classNamePrefix="react-select"
                className={`${
                  touched["incubatee"] && errors["incubatee"]
                    ? "is-invalid"
                    : ""
                }`}
              />

              <ErrorMessage
                component="div"
                name="incubatee"
                className="auto-lookup-empty"
              />
            </div>
          </div>
        ) : null}

        {values.requestorType === "INDIVIDUAL" ? (
          <Fragment>
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
                First Name
              </label>

              <div className="col-12 col-lg-3 col-xl-3">
                <Field
                  type="text"
                  name="firstName"
                  value={values.firstName || ""}
                  className={`form-control form-control-sm ${
                    touched.firstName && errors.firstName ? "is-invalid" : ""
                  }`}
                />

                <ErrorMessage
                  component="div"
                  name="firstName"
                  className="invalid-feedback"
                />
              </div>

              <label className="col-form-label col-form-label-sm  col-12 col-lg-2 col-xl-2 pr-xl-0 text-sm-left edit-page-label">
                Last Name
              </label>

              <div className="col-12 col-lg-3 col-xl-3 pl-xl-0">
                <Field
                  type="text"
                  name="lastName"
                  value={values.lastName || ""}
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <Contacts {...props} />
          </Fragment>
        ) : null}
        {values.requestorType === "ORGANIZATION" ? (
          <Fragment>
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
                Organization Name
              </label>

              <div className="col-12 col-lg-3 col-xl-3">
                <Field
                  type="text"
                  name="organizationName"
                  value={values.organizationName || ""}
                  className={`form-control form-control-sm ${
                    touched.organizationName && errors.organizationName
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="organizationName"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <Contacts {...props} />
          </Fragment>
        ) : null}

        <div className="row m-5">
          <div className="col-12">
            <button className="btn btn-sm btn-primary" type="submit">
              Next
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
};

const Contacts = props => {
  const { values, errors, touched } = props;
  return (
    <Fragment>
      <div className="form-group row">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Email
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="email"
            name="email"
            value={values.email || ""}
            className={`form-control form-control-sm ${
              touched.email && errors.email ? "is-invalid" : ""
            }`}
          />
          <ErrorMessage
            component="div"
            name="email"
            className="invalid-feedback"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Mobile
        </label>

        <div className="col-12 col-lg-3 col-xl-3">
          <Field
            type="text"
            inputMode="numeric"
            name="mobile"
            value={values.mobile || ""}
            className={`form-control form-control-sm ${
              touched.mobile && errors.mobile ? "is-invalid" : ""
            }`}
          />

          <ErrorMessage
            component="div"
            name="mobile"
            className="invalid-feedback"
          />
        </div>
      </div>
    </Fragment>
  );
};
