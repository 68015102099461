import React, { Component, Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import BootsrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import DefaultAlert from "../custom/DefaultAlert";
import HttpUtil from "../common/HttpUtil";
import { getDescription } from "../common/Util";
import { getDisplayDate, convertNumberToDate } from "./DateUtil";
import { LEASE_API } from "../common/Constants";

const { SearchBar } = Search;

class LeaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //for pagination
      page: 1,
      leasingList: [],
      sizePerPage: 10,

      //redirect to edit lease page
      editLease: false,
      editLeaseId: "",

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    };
  }

  // React life cycle
  componentDidMount = () => {
    //this.getBuildingList();
  };

  handleView = () => {
    //Logger.info("Location.js:view button clicked:");
  };

  handleEdit = () => {
    //Logger.info("Location.js:edit button clicked:");
    this.setState({ editLease: true });
  };

  handleDelete = row => {
    //Logger.info("Location.js:delete button clicked:row:", row);
  };

  actionsFormater = (cell, row) => {
    return (
      <Fragment key={row._id}>
        <button
          className="btn btn-secondary btn-sm"
          //onClick={this.handleEdit}
        >
          {" "}
          Edit
        </button>
        &nbsp;&nbsp;
        <button
          className="btn btn-outline-danger btn-sm"
          //onClick={this.handleReject}
        >
          {" "}
          Reject
        </button>
      </Fragment>
    );
  };

  componentDidMount = () => {
    this.getLeasingList();
  };

  // For api success return
  handleApiSuccess = data => {
    this.setState({ leasingList: data });
  };

  // For api failed return
  handleApiFailed = message => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message
    });
  };

  getLeasingList = () => {
    let url = LEASE_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    // Fetch api
    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  // Close bootstrap default alert box
  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  partitionFormatter = partitions => {
    let partitiDisplayName = "";
    partitions.forEach(lp => {
      partitiDisplayName = lp.partition.displayName;
    });
    return partitiDisplayName;
  };

  statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    return getDescription("LEASE", row[formatExtraData], cell);
  };

  dateFormatter = (cell, row) => {
    let displayDate = getDisplayDate(convertNumberToDate(cell));
    return displayDate;
  };

  linkFormater = (cell, row) => {
    const url = `/admin/lease/edit/${row._id}?from=/admin/lease`;
    return <Link to={url}>{row.incubatee.name}</Link>;
  };

  getIncubateeName = (cell, row) => {
    return row.incubatee.name;
  };

  render() {
    const {
      sizePerPage,
      page,
      leasingList,
      totalSize,

      editLease,
      editLeaseId,

      //Default alert
      showAlert,
      alertMode,
      alertMessage
    } = this.state;

    const dataList = leasingList;

    if (editLease) {
      let url = "/admin/lease/edit/" + editLeaseId;
      return <Redirect to={url} />;
    }

    const columnsLease = [
      {
        dataField: "incubatee.name",
        text: "Incubatee",
        sort: true,
        formatter: this.linkFormater,
        filterValue: this.getIncubateeName
      },
      {
        dataField: "leasePartitions",
        formatter: this.partitionFormatter,
        text: "Partition"
      },
      {
        dataField: "leaseStartDate",
        text: "Start date",
        formatter: this.dateFormatter,
        filterValue: this.dateFormatter
      },
      {
        dataField: "leaseEndDate",
        text: "End date",
        formatter: this.dateFormatter,
        filterValue: this.dateFormatter
      },

      {
        dataField: "status",
        text: "Status",
        formatter: this.statusFormatter,
        formatExtraData: "stage",
        filterValue: this.statusFormatter
      }
    ];
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          {/* show alert message  */}
          <DefaultAlert
            show={showAlert}
            alertMode={alertMode}
            message={alertMessage}
            closeAlert={this.closeDefaultAlert}
          />
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <span className="card-title mb-0">
                <i
                  className="fas fa-align-justify"
                  style={{ fontSize: "1.1em", cursor: "pointer" }}
                />
                &nbsp;&nbsp; Leasing List &nbsp;&nbsp;
              </span>
              <Link
                to="/admin/lease/add"
                role="button"
                className="badge bg-white custom-btn-sm"
              >
                New
              </Link>
              {/*
              <div className="col-4" style={{ display: "inline-block" }}>
                <div className="input-group input-group-sm">
                  <span className="input-group-prepend">
                    <button className="btn btn-primary btn-sm" type="button">
                      Go to
                    </button>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Campus"
                  />
                </div>
              </div>
             */}
            </div>
            <div className="card-body">
              <ToolkitProvider
                keyField="_id"
                data={dataList}
                columns={columnsLease}
                bootstrap4={true}
                search
              >
                {props => (
                  <Fragment>
                    <div className="mb-2">
                      <SearchBar {...props.searchProps} />
                    </div>
                    <BootsrapTable
                      bootstrap4
                      keyField={"_id"}
                      data={dataList}
                      columns={columnsLease}
                      condensed
                      striped
                      headerClasses="bg-card-header text-white"
                      // selectRow={selectRow}
                      // for pagination
                      pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize
                      })}
                      //for search
                      {...props.baseProps}
                      //for custom css
                      className="react-bootstrap-table table .react-bootstrap-table td > .selection-input-4, .react-bootstrap-table th > .selection-input-4"
                      // enable remote
                      // remote
                      // below funtion work on when enable remote
                      // onTableChange={this.onTableChange}
                      noDataIndication="No Data found"
                    />
                  </Fragment>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeaseList;
