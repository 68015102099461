import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import AlertComponent from "../common/AlertComponent";
import HttpUtil from "../common/HttpUtil";
import { INTERNAL_ORGS_API } from "../common/Constants";
import TableList from "../common/TableList";
import Can from "../../auth/can";

class InternalOrgsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internalList: null
    };
  }
  componentDidMount() {
    this.getAll();
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    const url = INTERNAL_ORGS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ internalList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  actionsFormater = (cell, row) => {
    if (!row) {
      return null;
    }
    let editBtnLink = (
      <Can do="UPDATE" on="IncubatorOrg">
        <Link to={`/admin/internal/orgs/edit/${row._id}`} role="button">
          <i
            className="fas fa-edit fa-lg action-icon"
            datatoggle="tooltip"
            title="Edit"
          />
        </Link>
      </Can>
    );

    return editBtnLink;
  };

  render() {
    const { internalList } = this.state;

    const columnsIncubators = [
      {
        dataField: "name",
        text: "Internal Organization",
        sort: true
      },

      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: this.actionsFormater,
        csvExport: false
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsIncubators}
          data={internalList}
          noDataIndication="No Internal Organizations found."
          sizePerPage="10"
          headerName="Internal Organizations"
          showSearchbar={true}
          customComponent={
            <Can do="CREATE" on="IncubatorOrg">
              <Link
                to="/admin/internal/orgs/new"
                role="button"
                className="btn btn-sm text-white bg-pink"
              >
                New
              </Link>
            </Can>
          }
        />
      </Fragment>
    );
  }
}

export default InternalOrgsList;
