import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import AlertComponent from "../common/AlertComponent";
import UserForm from "./UserForm";
import { USER_ROLES_API, USERS_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: null,
      inputError: {},

      // user object
      firstName: null,
      lastName: null,
      username: null,
      password: null,
      confirmPassword: null,
      email: null,
      mobileNumber: null,
      isActive: true,
      userRole: null,

      // user roles
      userRoles: [],

      loading: true
    };
  }
  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getUserById(id);
    } else {
      this.setState({ newUser: true, loading: false });
    }
    this.getAllUserRolles();
  };

  getAllUserRolles = () => {
    const url = USER_ROLES_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ userRoles: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getUserById = userId => {
    const url = USERS_API + "/" + userId;

    let alertType,
      showAlert,
      alertColor,
      alertMessage = null;

    if (this.props.location.state && this.props.location.state.created) {
      alertType = "Default";
      showAlert = true;
      alertColor = "success";
      alertMessage = "New User has been added successfully.";

      this.setState({
        alertType,
        showAlert,
        alertColor,
        alertMessage
      });
    }

    HttpUtil.get(
      url,
      {},
      data => {
        const userRole = data.roles[0];
        this.setState({
          _id: data._id,
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mobileNumber: data.mobileNumber,
          isActive: data.isActive,
          userRole: userRole.role,
          newUser: false,
          loading: false
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
    }
    this.setState({ [targetName]: targetValue });
  };

  handleConfirmPassword = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    const password = this.state.password;

    let inputError = {};
    inputError.password = password === "" || password === null;
    inputError.confirmPassword = password !== targetValue;
    this.setState({ [targetName]: targetValue, inputError });
  };

  handleCreateUser = () => {
    const createNewUser = this.validate();
    if (!createNewUser) {
      return;
    }

    const url = USERS_API;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.post(
      url,
      headers,
      createNewUser,
      data => {
        // redirect to edit

        this.setState({
          editUser: true,
          editUserId: data._id,

          username: null,
          password: null,
          firstName: null,
          lastName: null,
          email: null,
          userRole: null,
          mobileNumber: null,
          isActive: false
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getTrueOrFalse = data => {
    return data === null || data === "" || data === undefined;
  };

  validate = () => {
    const {
      firstName,
      lastName,
      username,
      password,
      confirmPassword,
      email,
      mobileNumber,
      isActive,
      userRole,
      newUser
    } = this.state;
    let inputError = {};

    inputError.firstName = this.getTrueOrFalse(firstName);
    inputError.email = this.getTrueOrFalse(email);
    inputError.mobileNumber = this.getTrueOrFalse(mobileNumber);
    inputError.userRole = this.getTrueOrFalse(userRole);

    inputError.username = this.getTrueOrFalse(username);
    if (newUser) {
      const nPassword = password ? password : "";
      const conPassword = confirmPassword ? confirmPassword : "";
      inputError.confirmPassword =
        nPassword !== conPassword || conPassword === "";
      inputError.password =
        this.getTrueOrFalse(password) || password.length < 8;
    }

    const invalid = Object.values(inputError).some(item => item);

    if (invalid) {
      this.setState({ inputError });
      return false;
    } else {
      const userObj = {};
      if (newUser) {
        userObj.password = password;
      } else {
        userObj._id = this.state._id;
      }

      userObj.firstName = firstName;
      if (lastName) {
        userObj.lastName = lastName;
      }
      userObj.username = username;
      userObj.email = email;
      userObj.mobileNumber = mobileNumber;
      userObj.isActive = isActive;
      userObj.roles = [{ role: userRole }];

      this.setState({ inputError: {} });
      return userObj;
    }
  };

  handleUpdateUser = () => {
    const updateUser = this.validate();
    if (!updateUser) {
      return;
    }
    const url = USERS_API + "/" + updateUser._id;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.put(
      url,
      headers,
      updateUser,
      data => {
        // redirect to edit
        this.getUserById(data._id);
        this.setState({
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: `User profile form has been updated now.`,
          inputError: {}
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  render() {
    const {
      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,

      editUser,
      editUserId
    } = this.state;

    if (this.state.newUser === null) {
      //return null;
    }

    if (editUser) {
      const locationState = {
        pathname: `/admin/users/edit/${editUserId}`,
        state: { created: true }
      };
      return <Redirect to={locationState} />;
    }

    const props = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      username: this.state.username,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      email: this.state.email,
      mobileNumber: this.state.mobileNumber,
      isActive: this.state.isActive,
      userRole: this.state.userRole,
      inputError: this.state.inputError,
      newUser: this.state.newUser,

      userRoles: this.state.userRoles,

      // events
      handleInputChange: this.handleInputChange,
      handleConfirmPassword: this.handleConfirmPassword,
      handleSubmit: this.state.newUser
        ? this.handleCreateUser
        : this.handleUpdateUser
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        <UserForm {...props} />
      </Fragment>
    );
  }
}

export default EditUser;
