import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import {
  changeToINR,
  PAYMENT_MODES_OBJECT,
  PAYMENT_TYPES_OBJECT,
  CARD_TYPES_OBJECT,
  CARD_ISSUERS_OBJECT
} from "../../common/LookupConstants";

class PaymentPreviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInfo: [],
      paymentInfo: [],
      additionalInfo: []
    };
  }
  componentDidMount() {
    const data = this.props.data;
    this.renderData(data);
  }

  addData = (payment, labelName, value) => {
    if (value) {
      payment.push({
        label: labelName,
        value
      });
    }
  };

  getBasicInfo = data => {
    let basicInfo = [];
    // this.addData(basicInfo, "Here Facility Information", "Working-in-progress");

    this.addData(basicInfo, "Entity Name", data.entityName);
    this.addData(basicInfo, "Entity Type", data.entityType);

    let ptTowards;

    if (data.paymentTowards && data.paymentTowards.length === 1) {
      ptTowards = data.paymentTowards.map(pt => pt.description);
    } else if (data.paymentTowards && data.paymentTowards.length > 1) {
      ptTowards = (
        <ul className="pl-3">
          {data.paymentTowards.map((pt, i) => (
            <li key={i} aria-disabled="true">
              {pt.description}
            </li>
          ))}
        </ul>
      );
    }

    this.addData(basicInfo, "Payment Towards", ptTowards);

    if (basicInfo.length === 0) {
      basicInfo.push({
        label: null,
        value: "No basic Information available."
      });
    }
    return basicInfo;
  };

  getPaymentInfo = data => {
    let paymentInfo = [];

    const paymentDate = getDisplayDate(convertNumberToDate(data.paymentDate));
    const chequeDate = getDisplayDate(convertNumberToDate(data.chequeDate));
    this.addData(paymentInfo, "Cheque Number", data.chequeNumber);
    this.addData(paymentInfo, "Amount Paid", changeToINR(data.amount));
    this.addData(paymentInfo, "Cheque Date", chequeDate);
    this.addData(paymentInfo, "Bank Name", data.bankName);
    this.addData(
      paymentInfo,
      "Payment Transfer Type",
      data.paymentTransferType
    );
    this.addData(
      paymentInfo,
      "Payment Mode",
      PAYMENT_MODES_OBJECT[data.paymentMode]
    );
    this.addData(
      paymentInfo,
      "Payment Type",
      PAYMENT_TYPES_OBJECT[data.paymentType]
    );

    this.addData(paymentInfo, "Payment Date", paymentDate);
    this.addData(paymentInfo, "Card Type", CARD_TYPES_OBJECT[data.cardType]);
    this.addData(
      paymentInfo,
      "Card Issuer",
      CARD_ISSUERS_OBJECT[data.cardIssuer]
    );
    if (paymentInfo.length === 0) {
      paymentInfo.push({
        label: null,
        value: "No payment Information available."
      });
    }
    return paymentInfo;
  };

  getAdditionalInfo = data => {
    let additionalInfo = [];
    this.addData(additionalInfo, "Collected By", data.collectedBy);
    this.addData(additionalInfo, "Reference", data.reference);
    this.addData(additionalInfo, "Notes", data.notes);
    this.addData(additionalInfo, "Status", data.status);

    if (additionalInfo.length === 0) {
      additionalInfo.push({
        label: null,
        value: "No additional Information available."
      });
    }
    return additionalInfo;
  };

  renderData = data => {
    const basicInfo = this.getBasicInfo(data);
    const paymentInfo = this.getPaymentInfo(data);
    const additionalInfo = this.getAdditionalInfo(data);

    this.setState({
      basicInfo,
      paymentInfo,
      additionalInfo
    });
  };

  render() {
    const { basicInfo, paymentInfo, additionalInfo } = this.state;
    return (
      <div className="row mb-2">
        <div className="col-md-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title mb-0">
                <Link
                  to={"/admin/finance/payments"}
                  className="badge bg-white custom-btn-sm"
                  data-toggle="tooltip"
                  title="Back to Payment List Page"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
              </span>
              <span style={{ color: "#647acb" }}>
                <strong>{this.props.title}</strong>
              </span>
            </div>
            <div className="card-body">
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Basic Information</strong>
                </div>
              </div>
              {basicInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label ">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-9 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}

              <hr />
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Payment Information</strong>
                </div>
              </div>
              {paymentInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-4 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}

              <hr />
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Additional Information</strong>
                </div>
              </div>
              {additionalInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-4 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentPreviewForm;
