// actions
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

const ToggleSidebarAction = () => (dispatch, getState) => {
  const hide = getState().ToggleSidebarReducer.hide;
  const toggle = {
    type: TOGGLE_SIDEBAR,
    hide
  };
  return dispatch(toggle);
};

export default ToggleSidebarAction;
