import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { CLUSTER_USAGE_STATS_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import { roundOf } from "../common/LookupConstants";
import LoadingComponent from "../common/spinnerloader/LoadingComponent";

function getChartOptions(labels) {
  const chartOptions = {
    colors: [
      // color combination 400 1
      "#26a69a",
      "#ef5350",
      "#ec407a",
      "#ab47bc",
      "#7e57c2",
      "#5c6bc0",
      "#42a5f5",
      "#8d6e63",
      "#78909c",
      "#9ccc65"

      // red color combination
      // "#e57373",
      // "#ff8a80",
      // "#f44336",
      // "#ff5252",
      // "#ff1744",
      // "#d50000",

      // default
      // "#3B3A57",
      // "#808000",
      // "#0a94bd",
      // "#e6194B",
      // "#800000",
      // "#911eb4",
      // "#ff8000",
      // "#679317",
      // "#ef473a",
      // "#ff0000",
      // "#0080ff",
      // "#000075",
      // "#3cb44b",
      // "#f032e6",
      // "#4363d8",
      // "#0a94bd",
      // "#ff3377",
      // "#2e4045"
    ],
    labels: labels,
    dataLabels: {
      enabled: true,
      formatter: function(val, opts) {
        return opts.w.globals.series[opts.seriesIndex];
      }
    },
    legend: {
      show: true,
      position: "right",
      itemMargin: {
        horizontal: 5
      }
    },
    responsive: [
      {
        breakpoint: 1342,
        options: {
          // chart: { width: 200, height: 260 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 992,
        options: {
          // chart: { width: 280, height: 280 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 480,
        options: {
          //  chart: { width: 250 },
          legend: { position: "bottom" }
        }
      },
      {
        breakpoint: 320,
        options: {
          // chart: { width: 200 },
          legend: { position: "bottom" }
        }
      }
    ]
  };

  return chartOptions;
}

function getClusterChartOptions(data) {
  const labels = [];
  const series = [];

  for (let i = 0; i < data.length; i++) {
    labels.push(data[i].cluster);
    series.push(roundOf(data[i].totalArea));
  }
  const chartOptions = getChartOptions(labels);
  return [chartOptions, series];
}

class ClusterUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cluster: null
    };
  }

  getClusterStats = () => {
    let url = CLUSTER_USAGE_STATS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          cluster: {
            errorMessage: null,
            usageStats: data
          }
        });
      },
      (data, status) => {
        this.setState({
          cluster: {
            errorMessage: data.message,
            usageStats: []
          }
        });
      },
      error => {
        this.setState({
          cluster: {
            errorMessage: "Unable to Fetch data",
            usageStats: []
          }
        });
      }
    );
  };

  componentDidMount = () => {
    this.getClusterStats();
  };

  render() {
    const cluster = this.state.cluster;

    let clusterStats = cluster && cluster.usageStats ? cluster.usageStats : [];

    let clusterChartOptions;
    let clusterSeries;

    if (clusterStats) {
      const [chartOptions, series] = getClusterChartOptions(clusterStats);
      clusterChartOptions = chartOptions;
      clusterSeries = series;
    }
    return (
      <div className="card elevate mt-3">
        <div className="card-header border-bottom-0">
          <div
            className="card-header-title font-size-lg text-center"
            style={{ fontWeight: "500" }}
          >
            Space Usage By Cluster (sqft)
            <Link
              className="btn btn-sm space-info-btn float-md-right shadow ml-2"
              to="/reports/facility/total-partition-usage?group=cluster"
            >
              View Details
            </Link>
          </div>
        </div>

        <div
          className="card-body card-body pb-0 pt-1"
          style={{ minHeight: "250px" }}
        >
          {cluster === null ? (
            <div
              className="d-flex justify-content-center align-items-center text-primary"
              style={{ minHeight: 200 }}
            >
              <LoadingComponent />
            </div>
          ) : (
            <Fragment>
              {cluster.errorMessage !== null ? (
                <div className="text-center text-danger">
                  {cluster.errorMessage}
                </div>
              ) : (
                <Fragment>
                  {cluster.usageStats.length === 0 ? (
                    <div className="text-center text-muted">
                      No cluster information.
                    </div>
                  ) : (
                    <ReactApexChart
                      options={clusterChartOptions}
                      series={clusterSeries}
                      type="pie"
                      height="250"
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default ClusterUsage;
