const EmailInfoSchema = {
  title: "Emails",
  type: "array",
  minItems: 1,
  items: {
    title: "Email",
    type: "object",
    required: ["type", "email"],
    properties: {
      type: {
        type: "string",
        title: "Type",
        anyOf: [
          {
            type: "string",
            title: "Work",
            enum: ["WORK"]
          },
          {
            type: "string",
            title: "Personal",
            enum: ["PERSONAL"]
          },
          {
            type: "string",
            title: "Office",
            enum: ["OFFICE"]
          },
          {
            type: "string",
            title: "Other",
            enum: ["OTHER"]
          }
        ]
      },
      email: {
        type: "string",
        title: "Email"
      }
    }
  }
};

const EmailInfoUiSchema = {};

export { EmailInfoSchema, EmailInfoUiSchema };
