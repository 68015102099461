import React, { Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Organization from "./Organization";
import Individual from "./Individual";
import Contact from "./Contact";
import IncubateeProfile from "./IncubateeProfile";
import Promoters from "./Promoters";
import Patents from "./Patents";
import { getDescription } from "../../../common/Util";
import Can from "../../../../auth/can";
import { getTabsError, DEFAULT_ONBOARD_FORM } from "../OnboardController";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

const CustomForm = props => {
  const { values, errors, touched, setFieldValue } = props;

  const incubateeType = values.incubateeType;

  const tabsError = getTabsError(errors);

  const saveForLater = values.actions.indexOf("SAVE") !== -1;
  const actions = values.actions.filter(action => action !== "SAVE");

  return (
    <Form>
      <div className="card-header border-bottom-0 pt-3">
        <span className="edit-page-title">
          <a
            className="badge bg-white custom-btn-sm"
            href="#/onboard/incubatees"
          >
            <i className="fas fa-arrow-left" />
          </a>
          &nbsp;Onboard
        </span>
      </div>
      <div className="card border-0">
        <div className="card-body pt-0">
          <div className="row no-gutters">
            {/* Incubatee Type */}
            <div className="col-lg-6">
              <div className="form-group row mb-0">
                <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                  Incubatee Type
                </label>
                <div className="col-lg-6">
                  <Field
                    component="select"
                    className={`custom-select custom-select-sm ${
                      getError(touched, errors, "incubateeType")
                        ? "is-invalid"
                        : ""
                    }`}
                    name="incubateeType"
                    values={values.incubateeType || ""}
                    onChange={e => {
                      const incubateeType = e.target.value;

                      if (incubateeType === "COMPANY") {
                        const org = DEFAULT_ONBOARD_FORM.organization;
                        setFieldValue("organization", org);
                      }

                      if (incubateeType === "INDIVIDUAL") {
                        const indv = DEFAULT_ONBOARD_FORM.individual;
                        setFieldValue("individual", indv);
                      }

                      setFieldValue("incubateeType", incubateeType);
                      setFieldValue(
                        "identificationDocs.incubateeType",
                        incubateeType
                      );
                    }}
                  >
                    <option value="">Select...</option>
                    <option value="COMPANY">Organization</option>
                    <option value="INDIVIDUAL">Individual</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="incubateeType"
                    className="invalid-feedback mb-2"
                  />
                </div>
              </div>
            </div>

            {/* Status here */}
            <div className="col-lg-6 mt-2">
              <div className="form-group row mb-0">
                <label className="col-lg-4 mb-0 text-lg-right edit-page-label">
                  Status
                </label>
                <div className="col-lg-6 mb-0">
                  <span className="edit-page-status">
                    {getDescription(
                      "IncubateeOnboarding",
                      values.stage,
                      values.status
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Tabs>
            <TabList>
              {incubateeType === "COMPANY" ? (
                <Tab>
                  {tabsError.organization ? (
                    <i className="far fa-times-circle align-middle react-tabs-error mr-2" />
                  ) : null}
                  Organization
                </Tab>
              ) : (
                <Tab>
                  {tabsError.individual ? (
                    <i className="far fa-times-circle align-middle react-tabs-error mr-2" />
                  ) : null}
                  Individual
                </Tab>
              )}

              <Tab>
                {tabsError.contact ? (
                  <i className="far fa-times-circle align-middle react-tabs-error mr-2" />
                ) : null}
                Contact
              </Tab>

              {incubateeType === "COMPANY" ? (
                <Tab>
                  {tabsError.promoters ? (
                    <i className="far fa-times-circle align-middle react-tabs-error mr-2" />
                  ) : null}
                  Promoter
                </Tab>
              ) : null}

              <Tab>
                {tabsError.incubateeProfile ? (
                  <i className="far fa-times-circle align-middle react-tabs-error mr-2" />
                ) : null}
                Incubatee Profile
              </Tab>

              <Tab>
                {tabsError.patents ? (
                  <i className="far fa-times-circle align-middle react-tabs-error mr-2" />
                ) : null}
                Patents
              </Tab>
            </TabList>

            <TabPanel>
              <div className="card border-0">
                {incubateeType === "COMPANY" ? (
                  <Organization {...props} />
                ) : (
                  <Individual {...props} />
                )}
              </div>
            </TabPanel>

            <TabPanel>
              <div className="card border-0">
                <Contact {...props} />
              </div>
            </TabPanel>

            {incubateeType === "COMPANY" ? (
              <TabPanel>
                <div className="card border-0">
                  <Promoters {...props} />
                </div>
              </TabPanel>
            ) : null}

            <TabPanel>
              <div className="card border-0">
                <IncubateeProfile {...props} />
              </div>
            </TabPanel>

            <TabPanel>
              <div className="card border-0">
                <Patents {...props} />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>

      <div
        className={`row ml-2 mr-2 mb-4 ${
          values.actions.length > 0 ? "border-top" : ""
        } `}
      >
        <div className="col-md-12 pt-3 text-md-center">
          <Can do="UPDATE" on="IncubateeOnboardingForm">
            {saveForLater ? (
              <button
                className="btn btn-sm btn-primary"
                type="button"
                data-toggle="tooltip"
                title="Save For Later"
                onClick={() => {
                  values.action = "SAVE";
                  props.handleSubmit(values);
                }}
              >
                SAVE
              </button>
            ) : null}
            {incubateeType !== "" && actions.length > 0
              ? actions.map((action, idx) => {
                  return (
                    <Fragment key={idx}>
                      <button
                        className="btn btn-sm btn-primary ml-3"
                        type="submit"
                        onClick={() => {
                          props.setFieldValue("action", action);
                        }}
                      >
                        {action}
                      </button>
                    </Fragment>
                  );
                })
              : null}
          </Can>
        </div>
      </div>
    </Form>
  );
};

const OnboardForm = props => {
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={props.onboardForm}
        validationSchema={props.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // console.log("Submit : ", JSON.stringify(values, null, 2));
          props.handleSubmit(values);
        }}
        render={renderProps => <CustomForm {...renderProps} {...props} />}
      />
    </div>
  );
};

export default OnboardForm;
