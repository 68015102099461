import React, { Component, Fragment } from "react";
const checkEmptyValue = (inputError, fieldName, value) => {
  if (value === undefined || value === "" || value === null) {
    inputError[fieldName] = true;
    return true;
  }
  return false;
};

const renderInputData = data => {
  return data ? data : "";
};
export class Emails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      inputError: []
    };
  }

  addMoreEmails = () => {
    const emails = [...this.state.emails];
    emails.push({
      email: null,
      type: "WORK",
      status: null
    });
    this.setState({ emails });
  };

  setStateEmails = emails => {
    this.setState({ emails });
  };

  removeEmail = index => {
    let emails = [...this.state.emails];
    if (emails.length === 0) return;
    emails = emails.filter((em, idx) => idx !== index);
    this.setStateEmails(emails);
  };

  handleInputChange = (index, e) => {
    e.preventDefault();
    const targetName = e.target.name;
    const targetValue = e.target.value !== "" ? e.target.value : null;
    let emails = [...this.state.emails];
    let email = emails[index];
    email[targetName] = targetValue;
    emails[index] = email;
    this.setStateEmails(emails);
  };

  validate = () => {
    let inputError = [];
    const emails = this.state.emails;
    let hasError = false;

    emails.forEach((em, index) => {
      inputError[index] = {};
      checkEmptyValue(inputError[index], "email", em.email);
      checkEmptyValue(inputError[index], "type", em.type);
      hasError = hasError || Object.keys(inputError[index]).length > 0;
    });

    if (hasError) {
      this.props.onValidate(inputError, "emails", null);
    } else {
      this.props.onValidate(null, "emails", emails);
      inputError = [];
    }
    this.setState({ inputError });
  };

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }
  };

  loadStateFromProps = () => {
    const { newRecord, emails } = this.props;
    // props 'emails' not null or not empty then add to local state.
    if (!newRecord && emails && emails.length > 0) {
      this.setState({ emails: [...emails] });
      return;
    }
    // props 'emails' is null or is empty then create new 'emails' array in local state.
    if (this.state.emails.length === 0) {
      this.addMoreEmails();
    }
  };

  componentDidMount() {
    this.loadStateFromProps();
  }

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    const emails = this.props.emails;
    if (emails !== prevState.emails) {
      this.loadStateFromProps();
    }
  };

  render() {
    const { emails, inputError } = this.state;

    const emailList = emails.map((em, index) => {
      const hideRemoveBtn = emails.length === 1;
      const hideAddBtn = emails.length - 1 !== index;
      return (
        <div key={index} className="row">
          <div className="col-12">
            <div className="input-group mb-3">
              <input
                type="text"
                className={
                  inputError[index] && inputError[index]["email"]
                    ? "form-control form-control-sm w-25 is-invalid"
                    : "form-control form-control-sm w-25"
                }
                placeholder="example@example.com"
                name="email"
                value={renderInputData(em.email)}
                onChange={this.handleInputChange.bind(this, index)}
              />
              &nbsp;
              <select
                className={
                  inputError[index] && inputError[index]["type"]
                    ? "custom-select custom-select-sm w-25 is-invalid"
                    : "custom-select custom-select-sm w-25"
                }
                name="type"
                value={renderInputData(em.type)}
                onChange={this.handleInputChange.bind(this, index)}
              >
                <option value="">Select...</option>
                <option value="WORK">Work</option>
                <option value="PERSONAL">Personal</option>
                <option value="OFFICE">Office</option>
                <option value="OTHER">Other</option>
              </select>
              &nbsp;
              <button
                className={
                  hideAddBtn
                    ? "btn btn-info btn-sm d-none"
                    : "btn btn-info btn-sm"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Add"
                type="button"
                onClick={this.addMoreEmails}
              >
                <i className="fas fa-plus " />
              </button>
              &nbsp;
              <button
                className={
                  hideRemoveBtn
                    ? "btn btn-outline-danger btn-sm d-none"
                    : "btn btn-outline-danger btn-sm"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Remove"
                type="button"
                onClick={this.removeEmail.bind(this, index)}
              >
                <i className="far fa-trash-alt " />
              </button>
              <div className="invalid-feedback">
                Please enter email and type *
              </div>
            </div>
          </div>
        </div>
      );
    });
    return <Fragment>{emailList}</Fragment>;
  }
}
