function getFieldName(stringName) {
  if (!stringName) {
    return null;
  }
  let text = stringName.toLowerCase();
  text = text.split(" ");
  let camelCaseName = "";
  for (let i = 1; i < text.length; i++) {
    let txt = text[i];
    txt = txt.charAt(0).toUpperCase() + txt.substring(1);
    text[i] = txt;
  }
  camelCaseName = text.join("");
  return camelCaseName;
}

function validateDuplicate(arrayValues, label, field) {
  let duplicates = [];
  let fldTmpIndex = {};

  arrayValues.forEach((fld, index) => {
    const camelCaseName = getFieldName(fld[field]);
    if (!camelCaseName) {
      duplicates[index] = {
        ...fld,
        [field]: `${label} is empty.`
      };
    } else if (!fldTmpIndex[camelCaseName]) {
      fldTmpIndex[camelCaseName] = index + 1;
    } else {
      let dupIndex = fldTmpIndex[camelCaseName];
      duplicates[dupIndex - 1] = {
        ...fld,
        [field]: `${fld[field]} is already exist.`
      };
      duplicates[index] = {
        ...fld,
        [field]: `${fld[field]} is already exist.`
      };
    }
  });

  return duplicates;
}

function validateChecklist(template) {
  const checklist = template.checklist || [];
  const duplicates = validateDuplicate(checklist, "Label", "label");
  return duplicates;
}

function validateDocuments(template) {
  const documents = template.documents || [];
  const duplicates = validateDuplicate(
    documents,
    "Document Type",
    "documentType"
  );

  return duplicates;
}

function validateClosureTemplate(errors, closureTemplate) {
  const closureTemplateError = {};

  const fieldTemplates = closureTemplate.fieldTemplates;
  const fieldTemplatesErrors = validateDuplicate(
    fieldTemplates,
    "Field Name",
    "name"
  );

  if (fieldTemplatesErrors.length > 0) {
    closureTemplateError.fieldTemplates = fieldTemplatesErrors;
  }

  const documentErrors = validateDocuments(closureTemplate);
  if (documentErrors.length > 0) {
    closureTemplateError.documents = documentErrors;
  }

  const checklistErrors = validateChecklist(closureTemplate);
  if (checklistErrors.length > 0) {
    closureTemplateError.checklist = checklistErrors;
  }

  const hasError = Object.keys(closureTemplateError).length > 0;
  if (hasError) {
    errors.closureTemplate = closureTemplateError;
  }
}

function validateTerminationTemplate(errors, terminationTemplate) {
  const terminationTemplateError = {};

  const documentErrors = validateDocuments(terminationTemplate);
  if (documentErrors.length > 0) {
    terminationTemplateError.documents = documentErrors;
  }

  const checklistErrors = validateChecklist(terminationTemplate);
  if (checklistErrors.length > 0) {
    terminationTemplateError.checklist = checklistErrors;
  }

  const hasError = Object.keys(terminationTemplateError).length > 0;
  if (hasError) {
    errors.terminationTemplate = terminationTemplateError;
  }
}

function validateReviewTemplate(errors, reviewTemplate) {
  const reviewTemplateError = {};

  const documentErrors = validateDocuments(reviewTemplate);
  if (documentErrors.length > 0) {
    reviewTemplateError.documents = documentErrors;
  }

  const checklistErrors = validateChecklist(reviewTemplate);
  if (checklistErrors.length > 0) {
    reviewTemplateError.checklist = checklistErrors;
  }

  const hasError = Object.keys(reviewTemplateError).length > 0;
  if (hasError) {
    errors.reviewTemplate = reviewTemplateError;
  }
}

export {
  validateClosureTemplate,
  validateTerminationTemplate,
  validateReviewTemplate
};
