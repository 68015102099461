import React, { Component } from "react";
import { Link } from "react-router-dom";
import { changeToINR } from "../../common/LookupConstants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";

class AdjustmentPreviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInfo: [],
      paymentInfo: [],
      additionalInfo: []
    };
  }

  componentDidMount() {
    const data = this.props.data;
    this.renderData(data);
  }

  addData = (payment, labelName, value) => {
    if (value) {
      payment.push({
        label: labelName,
        value
      });
    }
  };

  getBasicInfo = data => {
    let basicInfo = [];
    this.addData(basicInfo, "Adjustment To", data.adjustmentTo);
    this.addData(basicInfo, "Entity Type", data.adjustmentToType);

    if (basicInfo.length === 0) {
      basicInfo.push({
        label: null,
        value: "No basic Information available."
      });
    }
    return basicInfo;
  };

  getPaymentInfo = data => {
    let paymentInfo = [];
    this.addData(
      paymentInfo,
      "Adjustment Amount",
      changeToINR(data.adjustmentAmount)
    );
    this.addData(
      paymentInfo,
      "Adjustment Date",
      getDisplayDate(convertNumberToDate(data.date))
    );
    if (paymentInfo.length === 0) {
      paymentInfo.push({
        label: null,
        value: "No payment Information available."
      });
    }
    return paymentInfo;
  };

  getAdditionalInfo = data => {
    let additionalInfo = [];
    this.addData(additionalInfo, "Adjustment Reason", data.adjustmentReason);
    this.addData(additionalInfo, "Notes / Remarks", data.remarks);
    this.addData(additionalInfo, "Status", data.status);

    if (additionalInfo.length === 0) {
      additionalInfo.push({
        label: null,
        value: "No additional Information available."
      });
    }
    return additionalInfo;
  };

  renderData = data => {
    const basicInfo = this.getBasicInfo(data);
    const paymentInfo = this.getPaymentInfo(data);
    const additionalInfo = this.getAdditionalInfo(data);

    this.setState({
      basicInfo,
      paymentInfo,
      additionalInfo
    });
  };
  render() {
    const { basicInfo, paymentInfo, additionalInfo } = this.state;
    return (
      <div className="row mb-2">
        <div className="col-md-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title mb-0">
                <Link
                  to={"/admin/finance/adjustments/payments"}
                  className="badge bg-white custom-btn-sm"
                  data-toggle="tooltip"
                  title="Back to Adjustment List Page"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
                &nbsp;{this.props.title}
              </span>
            </div>
            <div className="card-body">
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Basic Information</strong>
                </div>
              </div>
              {basicInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-4 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}

              <hr />
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Payment Information</strong>
                </div>
              </div>
              {paymentInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-4 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}

              <hr />
              <div className="row  mb-3">
                <div className="col-12">
                  <strong className="text-muted">Additional Information</strong>
                </div>
              </div>
              {additionalInfo.map((data, i) => {
                return (
                  <div key={i} className="form-group row mb-0">
                    <label className="col-form-label col-xs-12 col-md-3 edit-page-label">
                      <strong>{data.label}</strong>
                    </label>
                    <label className="col-form-label col-xs-12 col-md-4 ">
                      {data.value}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdjustmentPreviewForm;
