/* eslint-disable no-loop-func */
import * as XLSX from "xlsx";

function sortTypeCaseIgnore(rowA, rowB, columnId) {
  const a = (rowA.values[columnId] || "").toLowerCase();
  const b = (rowB.values[columnId] || "").toLowerCase();

  let result = 0;

  if (a < b) {
    result = -1;
  } else if (a > b) {
    result = 1;
  }

  return result;
}

function exportToExcel(fileName, columns, rows) {
  const data = [];
  data.push(columns.map(column => column.Header));
  rows.forEach(row => {
    const rowData = [];
    columns.forEach(column => {
      const value = row.values[column.accessor];
      if (column.exportValue) {
        rowData.push(column.exportValue({ row, value }));
      } else {
        rowData.push(value);
      }
    });
    data.push(rowData);
  });
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(data);

  XLSX.utils.book_append_sheet(workbook, sheet, fileName);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

function exportExcelMultipeSheets(fileName, sheets) {
  const workbook = XLSX.utils.book_new();

  sheets.forEach(st => {
    const sheet = XLSX.utils.aoa_to_sheet(st.data);
    XLSX.utils.book_append_sheet(workbook, sheet, st.sheetName);
  });

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

function exportMultipleTables(fileName, combinedData) {
  const finalData = [];

  combinedData.forEach(indvData => {
    const { heading, data } = indvData;
    const { rows, columns } = data;
    finalData.push([heading]);
    finalData.push(columns.map(column => column.label));

    rows.forEach(row => {
      const rowData = [];
      columns.forEach(column => {
        const value = row[column.key];
        if (column.exportValue) {
          rowData.push(column.exportValue({ row, value }));
        } else {
          rowData.push(value);
        }
      });
      finalData.push(rowData);
    });
    finalData.push([]);
  });

  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(finalData);

  XLSX.utils.book_append_sheet(workbook, sheet, fileName);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

function exportCustomResult(fileName, columns, rows) {
  if (rows.length === 0) {
    return;
  }

  const data = [];
  // Adding Header
  data.push(columns.map(column => column.Header));
  rows.forEach(row => {
    const rowData = [];
    columns.forEach(column => {
      const value = row[column.accessor];
      rowData.push(value);
    });
    data.push(rowData);
  });
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(data);

  XLSX.utils.book_append_sheet(workbook, sheet, fileName);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

function getValue(obj, accessor) {
  if (!obj) {
    return;
  }
  if (accessor === ".") {
    return obj;
  }
  let value = obj;
  const splitKeys = accessor.split(".");
  for (const key of splitKeys) {
    value = value[key];
    if (!value) {
      break;
    }
  }
  return value;
}

function exportMappedFields(fileName, columns, data) {
  const header = [];
  const rows = [];

  // Adding headers
  columns.forEach(map => {
    if (map.multiExport) {
      // Getting data count to append headers
      let maxCount = 0;
      data.forEach(dat => {
        maxCount = Math.max(maxCount, dat[map.accessor].length);
      });
      for (let i = 0; i < maxCount; i++) {
        map.fields.forEach(({ fieldName }) => {
          header.push(`${map.headerName} - ${i + 1} - ${fieldName}`);
        });
      }
      return;
    }
    header.push(map.Header);
  });

  const incubateeRowIndex = {};

  for (const oneData of data) {
    const row = [];
    for (const fieldMap of columns) {
      // Performing multi export
      if (fieldMap.multiExport) {
        oneData[fieldMap.accessor].forEach(obj => {
          fieldMap.fields.forEach(({ accessor: inAccessor, exportValue }) => {
            const colValue = getValue(obj, inAccessor);
            row.push(exportValue ? exportValue(colValue, obj) : colValue);
          });
        });
        continue;
      }

      const colValue = getValue(oneData, fieldMap.accessor);
      row.push(
        fieldMap.exportValue
          ? fieldMap.exportValue(colValue, oneData)
          : colValue
      );
    }
    rows.push(row);
    incubateeRowIndex[oneData._id] = rows.length;
  }
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet([header, ...rows]);

  XLSX.utils.book_append_sheet(workbook, sheet, "Data Export");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

export {
  sortTypeCaseIgnore,
  exportToExcel,
  exportMultipleTables,
  exportCustomResult,
  exportExcelMultipeSheets,
  exportMappedFields
};
