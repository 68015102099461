import React, { Component, Fragment } from "react";

const checkEmptyValue = (inputError, fieldName, value) => {
  if (value === undefined || value === "" || value === null) {
    inputError[fieldName] = true;
    return true;
  }
  return false;
};

const renderInputData = data => {
  return data ? data : "";
};

export class Phones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phones: [],
      inputError: []
    };
  }

  addMorePhones = () => {
    const phones = this.state.phones;
    phones.push({
      phone: null,
      type: "MOBILE"
    });
    this.setState({ phones });
  };

  setStatePhones = phones => {
    this.setState({ phones });
  };

  removePhone = index => {
    let phones = [...this.state.phones];
    if (phones.length === 0) return;
    phones = phones.filter((ph, idx) => idx !== index);
    this.setStatePhones(phones);
  };

  handleInputChange = (index, e) => {
    e.preventDefault();
    const targetName = e.target.name;
    const targetValue = e.target.value !== "" ? e.target.value : null;
    let phones = [...this.state.phones];
    let phone = phones[index];
    phone[targetName] = targetValue;
    phones[index] = phone;
    this.setStatePhones(phones);
  };

  validate = () => {
    let inputError = [];
    const phones = this.state.phones;
    let hasError = false;
    phones.forEach((ph, index) => {
      inputError[index] = {};
      checkEmptyValue(inputError[index], "phone", ph.phone);
      checkEmptyValue(inputError[index], "type", ph.type);
      hasError = hasError || Object.keys(inputError[index]).length > 0;
    });

    if (hasError) {
      this.props.onValidate(inputError, "phones", null);
    } else {
      this.props.onValidate(null, "phones", phones);
      inputError = [];
    }
    this.setState({ inputError });
  };

  loadStateFromProps = () => {
    const { newRecord, phones } = this.props;

    // props 'phones' not null or not empty then add to local state.
    if (!newRecord && phones && phones.length > 0) {
      this.setState({ phones: [...phones] });
      return;
    }

    // props 'phones' is null or is empty then create new 'phones' array in local state.
    if (this.state.phones.length === 0) {
      this.addMorePhones();
    }
  };

  componentDidMount() {
    this.loadStateFromProps();
  }

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    const phones = this.props.phones;
    if (phones !== prevState.phones) {
      this.loadStateFromProps();
    }
  };

  render() {
    const { phones, inputError } = this.state;

    const phoneList = phones.map((ph, index) => {
      const hideRemoveBtn = phones.length === 1;
      const hideAddBtn = phones.length - 1 !== index;
      return (
        <div key={index} className="row">
          <div className="col-12">
            <div className="input-group mb-3">
              <input
                type="text"
                className={
                  inputError[index] && inputError[index]["phone"]
                    ? "form-control form-control-sm w-25 is-invalid"
                    : "form-control form-control-sm w-25"
                }
                placeholder="10 digits"
                name="phone"
                value={renderInputData(ph.phone)}
                onChange={this.handleInputChange.bind(this, index)}
              />
              &nbsp;
              <select
                className={
                  inputError[index] && inputError[index]["type"]
                    ? "custom-select custom-select-sm w-25 is-invalid"
                    : "custom-select custom-select-sm w-25"
                }
                name="type"
                value={renderInputData(ph.type)}
                onChange={this.handleInputChange.bind(this, index)}
              >
                <option value="">Select...</option>
                <option value="MOBILE">Mobile</option>
                <option value="WORK">Work</option>
                <option value="OFFICE">Office</option>
                <option value="OTHER">Other</option>
              </select>
              &nbsp;
              <button
                className={
                  hideAddBtn
                    ? "btn btn-info btn-sm d-none"
                    : "btn btn-info btn-sm"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Add"
                type="button"
                onClick={this.addMorePhones}
              >
                <i className="fas fa-plus " />
              </button>
              &nbsp;
              <button
                className={
                  hideRemoveBtn
                    ? "btn btn-outline-danger btn-sm d-none"
                    : "btn btn-outline-danger btn-sm"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Remove"
                type="button"
                onClick={this.removePhone.bind(this, index)}
              >
                <i className="far fa-trash-alt " />
              </button>
              <div className="invalid-feedback">
                Please enter phone and type *
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <strong>Mobile</strong>
        {phoneList}
      </Fragment>
    );
  }
}
