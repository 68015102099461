import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Download from "downloadjs";
import AlertComponent from "../common/AlertComponent";
import { ORGANIZATIONS_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import { getDownloadFileName } from "../common/Util";
import { getYears } from "../lease/DateUtil";
import { ContactInformation } from "../individual/EditIndividualForm";
import { Addresses } from "../widgets/Addresses";
import { IdentificationDocuments } from "../widgets/IdentificationDocuments";
import { titleCaseForOneCaseString } from "../common/Util";

export const ORG_ID_TYPES = [
  {
    code: "PAN",
    name: "PAN",
    required: true
  },
  {
    code: "GSTIN",
    name: "GSTIN",
    required: false
  }
];

const changeToINR = number => {
  let num = number;
  if (isNaN(num)) {
    return num;
  }
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(num);
};

const getFinancialYears = () => {
  let year = new Date().getFullYear();
  const fy = [];
  for (let indx = 0; indx < 5; indx++) {
    fy.push(
      `${year - indx - 1}-${((year - indx) % 100).toString().padStart(2, "0")}`
    );
  }
  return fy;
};

const checkEmptyValue = (inputError, fieldName, value) => {
  if (value === undefined || value === "" || value === null) {
    inputError[fieldName] = true;
    return true;
  }
  return false;
};

const renderInputData = data => {
  return data ? data : "";
};

class EditOrganizationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // For input validations
      validate: false,
      toBeValidated: [],
      hasError: false,
      update: false,

      // For All Component
      _id: null,
      basicInfo: null,
      phones: [],
      emails: [],
      addresses: [],
      idDocs: [],
      promoters: [],
      financials: [],
      newRecord: null,
      previousLocation: "",
      incProfUrl: "",
      currentLocation: ""
    };
  }

  downloadDocument = filePartUrl => {
    const url = `${ORGANIZATIONS_API}/${this.state._id}/${filePartUrl}`;

    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return Promise.all([
            response.blob(),
            getDownloadFileName(response, "photo")
          ]);
        } else {
          throw Error(response.statusText);
        }
      })
      .then(([blob, filename]) => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString());
      });
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  setDataFromServer = data => {
    const basicInfo = {
      name: data.name,
      legalStatus: data.legalStatus,
      estYear: data.estYear,
      website: data.website,
      subsidiary: data.subsidiary,
      parentCompany: data.parentCompany,
      reference: data.reference,
      bizSummary: data.bizSummary,
      services: data.services,
      domainOperation: data.domainOperation,
      logo: data.logo,
      companyProfile: data.companyProfile,
      regCertificate: data.regCertificate,
      articlesOfAssociation: data.articlesOfAssociation
    };

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const from = params.get("from");
    const incProfUrl = params.get("incProfUrl");
    const currentLocation = this.props.location.pathname;

    this.setState({
      _id: data._id,
      basicInfo,
      phones: data.phones ? data.phones : [],
      emails: data.emails ? data.emails : [],
      addresses: data.addresses ? data.addresses : [],
      idDocs: data.identificationDocs ? data.identificationDocs : [],
      promoters: data.promoters ? data.promoters : [],
      financials: data.financials ? data.financials : [],
      newRecord: false,
      previousLocation: from,
      incProfUrl: incProfUrl,
      currentLocation: currentLocation
    });
  };

  getOrganization = id => {
    const url = `${ORGANIZATIONS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => this.setDataFromServer(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getFormData = () => {
    let formData = new FormData();
    const basicInfo = this.state.basicInfo;

    this.addDocs(formData, "logo", basicInfo.logoUpload);
    this.addDocs(formData, "companyProfile", basicInfo.companyProfileUpload);
    this.addDocs(formData, "regCertificate", basicInfo.regCertificateUpload);
    this.addDocs(
      formData,
      "articlesOfAssociation",
      basicInfo.articlesOfAssociationUpload
    );
    const identificationDocs = this.state.idDocs.map(id => {
      this.addDocs(formData, `id_doc_${id.type}`, id.scannedId);
      return {
        type: id.type,
        number: id.number,
        status: id.status
      };
    });

    const addresses = this.state.addresses.map(ad => {
      this.addDocs(formData, `address_doc_${ad.type}`, ad.addressProofUpload);
      return {
        _id: ad._id,
        streetLine1: ad.streetLine1,
        streetLine2: ad.streetLine2,
        landmark: ad.landmark,
        city: ad.city,
        state: ad.state,
        postalCode: ad.postalCode,
        country: ad.country,
        status: ad.status,
        type: ad.type
      };
    });

    const organizationForm = {
      name: basicInfo.name,
      legalStatus: basicInfo.legalStatus,
      estYear: basicInfo.estYear,
      website: basicInfo.website,
      subsidiary: basicInfo.subsidiary,
      parentCompany: basicInfo.parentCompany,
      reference: basicInfo.reference,
      bizSummary: basicInfo.bizSummary,
      services: basicInfo.services,
      domainOperation: basicInfo.domainOperation,
      phones: this.state.phones,
      emails: this.state.emails,
      addresses,
      identificationDocs: identificationDocs,
      promoters: this.state.promoters,
      financials: this.state.financials
    };

    formData.append("message", JSON.stringify(organizationForm));
    return formData;
  };

  callUpdateApi = () => {
    const updateRequest = this.getFormData();

    const url = `${ORGANIZATIONS_API}/${this.state._id}`;

    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        this.setDataFromServer(data);
        this.setState({
          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: ` ${data.name} profile has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );

    this.setState({ update: false });
  };

  // if the set state is called directly,
  // then toBeValidated Array data gets overwritten
  // Passing function queues up the set state
  // and they are called in the same order they are called.
  // https://medium.freecodecamp.org/functional-setstate-is-the-future-of-react-374f30401b6b

  setStateValidateResult = (childName, childInvalid, data) => {
    this.setState(state => {
      const toBeValidated = state.toBeValidated.filter(
        item => item !== childName
      );
      const validationComplete = toBeValidated.length === 0;

      const hasError = state.hasError || childInvalid;
      const update = !hasError && validationComplete;

      let errorState = {};
      if (validationComplete && hasError) {
        errorState = {
          alertType: "Default",
          showAlert: true,
          alertColor: "danger",
          alertMessage: "Please correct the errors and submit again"
        };
        window.scrollTo(0, 0);
      }

      return {
        validate: !validationComplete,
        hasError,
        toBeValidated,
        update,
        [childName]: data,
        ...errorState
      };
    });
  };

  onValidate = (error, childName, data) => {
    if (error === null) {
      this.setStateValidateResult(childName, false, data);
    } else {
      this.setStateValidateResult(childName, true, null);
      window.scrollTo(0, 0);
    }
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getOrganization(id);
    } else {
      this.setState({ newRecord: true });
    }
  };

  componentDidUpdate = prevState => {
    if (this.state.update !== prevState.update && this.state.update) {
      this.callUpdateApi();
    }
  };

  handleUpdate = () => {
    this.setState({
      validate: true,
      hasError: false,
      toBeValidated: [
        "basicInfo",
        "phones",
        "emails",
        "addresses",
        "idDocs",
        "promoters",
        "financials"
      ]
    });
  };

  render() {
    const {
      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      newRecord
    } = this.state;

    if (newRecord === null) {
      return null;
    }

    const props = {
      // for validation
      onValidate: this.onValidate,
      validate: this.state.validate,

      // state object
      newRecord: newRecord,
      basicInfo: this.state.basicInfo,
      phones: this.state.phones,
      emails: this.state.emails,
      addresses: this.state.addresses,
      idDocs: this.state.idDocs,
      promoters: this.state.promoters,
      financials: this.state.financials,
      previousLocation: this.state.previousLocation,
      incProfUrl: this.state.incProfUrl,
      currentLocation: this.state.currentLocation,

      // download document
      downloadDocument: this.downloadDocument
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        {/* All Components */}
        <BasicInfo {...props} />
        <ContactInformation {...props} />

        <Addresses {...props} />
        <IdentificationDocuments idTypes={ORG_ID_TYPES} {...props} />
        <div className="row mt-4 mb-5">
          <Promoters {...props} />
          <FinancialInfo {...props} />
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-12">
            <button
              className="btn btn-sm btn-primary"
              onClick={this.handleUpdate}
            >
              Update Changes
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditOrganizationForm;

/*+++++++++++++++++++++ BasicInfo Start ++++++++++++++++++++++++++ */
export class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputError: {},
      basicInfo: {
        name: null,
        legalStatus: null,
        estYear: null,
        website: null,
        subsidiary: false,
        parentCompany: null,
        reference: null,
        bizSummary: null,
        services: null,
        domainOperation: null,
        logo: null,
        companyProfile: null,
        regCertificate: null,
        articlesOfAssociation: null
      }
    };
  }

  validate = () => {
    let inputError = {};
    let hasError = false;
    const basicInfo = this.state.basicInfo;

    checkEmptyValue(inputError, "name", basicInfo.name);
    checkEmptyValue(inputError, "domainOperation", basicInfo.domainOperation);
    checkEmptyValue(inputError, "legalStatus", basicInfo.legalStatus);
    checkEmptyValue(inputError, "estYear", basicInfo.estYear);

    hasError = hasError || Object.keys(inputError).length > 0;

    if (hasError) {
      this.props.onValidate(inputError, "basicInfo", null);
    } else {
      this.props.onValidate(null, "basicInfo", basicInfo);
      inputError = {};
    }
    this.setState({ inputError });
  };

  handleNameInputchange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    const basicInfo = { ...this.state.basicInfo };

    const prevValueFieldName = `${targetName}PrevValue`;
    const prevValue = this.state[prevValueFieldName];

    if (prevValue === targetValue) {
      basicInfo[targetName] = targetValue;
    } else {
      basicInfo[targetName] = titleCaseForOneCaseString(targetValue);
    }

    this.setState({ basicInfo, [prevValueFieldName]: targetValue });
  };

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    if (e.target.type === "file") {
      targetValue = e.target.files[0];
    } else if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
    }
    const basicInfo = { ...this.state.basicInfo };
    basicInfo[targetName] = targetValue;

    this.setState({ basicInfo });
  };

  loadStateFromProps = () => {
    const { newRecord, basicInfo } = this.props;
    if (!newRecord && basicInfo !== null) {
      const newBasicInfo = { ...basicInfo };
      this.setState({ basicInfo: newBasicInfo });
    }
  };

  componentDidMount = () => {
    this.loadStateFromProps();
  };

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    if (this.props.basicInfo !== prevState.basicInfo) {
      this.loadStateFromProps();
    }
  };

  render() {
    const {
      name,
      legalStatus,
      estYear,
      website,
      subsidiary,
      parentCompany,
      reference,
      bizSummary,
      services,
      domainOperation,
      logo,
      companyProfile,
      regCertificate,
      articlesOfAssociation
    } = this.state.basicInfo;

    const { inputError } = this.state;
    const YEARS = getYears();

    const childProps = {
      logo: logo,
      companyProfile: companyProfile,
      regCertificate: regCertificate,
      articlesOfAssociation: articlesOfAssociation,
      basicInfo: this.state.basicInfo,

      // events
      handleInputChange: this.handleInputChange,

      // props events
      downloadDocument: this.props.downloadDocument
    };

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <h5 className="h6 card-title mb-0">
                <Link
                  to={this.props.incProfUrl}
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left" />
                </Link>
                &nbsp;Basic Information
              </h5>
            </div>

            <div className="card-body">
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 required">
                  Name
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className={
                      inputError["name"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Organization Name"
                    name="name"
                    value={renderInputData(name)}
                    onChange={this.handleNameInputchange}
                  />
                  <div className="invalid-feedback">
                    Please enter organization name *
                  </div>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Website
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className={
                      inputError["website"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="www.example.com"
                    name="website"
                    value={renderInputData(website)}
                    onChange={this.handleInputChange}
                  />
                  <div className="invalid-feedback">Please enter website *</div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 required">
                  Area / Domain
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className={
                      inputError["domainOperation"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    placeholder="Domain Operation"
                    name="domainOperation"
                    value={renderInputData(domainOperation)}
                    onChange={this.handleInputChange}
                  />
                  <div className="invalid-feedback">Please enter domain *</div>
                </div>

                <label className="col-form-label col-12 col-lg-2 col-xl-2 required">
                  Legal Status
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    className={
                      inputError["legalStatus"]
                        ? "custom-select custom-select-sm is-invalid"
                        : "custom-select custom-select-sm"
                    }
                    name="legalStatus"
                    value={renderInputData(legalStatus)}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select...</option>
                    <option value="PROPRIETARY">Proprietorship</option>
                    <option value="PARTNERSHIP">Partnership</option>
                    <option value="PVTLTD">Private Limited</option>
                    <option value="LLP">LLP</option>
                    <option value="OPC">OPC</option>
                  </select>
                  <div className="invalid-feedback">
                    Please select legal status *.
                  </div>
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 required">
                  Year of Establishment
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    className={
                      inputError["estYear"]
                        ? "custom-select custom-select-sm is-invalid"
                        : "custom-select custom-select-sm"
                    }
                    name="estYear"
                    value={renderInputData(estYear)}
                    onChange={this.handleInputChange}
                  >
                    <option value="">Select...</option>
                    {YEARS.map(yyyy => (
                      <option key={yyyy} value={yyyy}>
                        {yyyy}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    Please select year of Establishment *.
                  </div>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  References
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Any"
                    name="reference"
                    value={renderInputData(reference)}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Subsidiary
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control custom-control-input"
                      id="editOrgSubsitary123"
                      name="subsidiary"
                      checked={subsidiary ? subsidiary : ""}
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="editOrgSubsitary123"
                    />
                    Yes
                  </div>
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Parent Company
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Parent Company Name"
                    name="parentCompany"
                    value={renderInputData(parentCompany)}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <hr />
              <div className="form-group row ">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Business / Summary
                </label>
                <div className="col-12 col-lg-7 col-xl-7">
                  <textarea
                    type="text"
                    className="form-control form-control-sm"
                    name="bizSummary"
                    value={renderInputData(bizSummary)}
                    onChange={this.handleInputChange}
                  />
                  <div className="invalid-feedback">
                    Please enter business background and summary *.
                  </div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2">
                  Products / Service
                </label>
                <div className="col-12 col-lg-7 col-xl-7">
                  <textarea
                    type="text"
                    className="form-control form-control-sm"
                    name="services"
                    value={renderInputData(services)}
                    onChange={this.handleInputChange}
                  />
                  <div className="invalid-feedback">
                    Please enter products or services offered *.
                  </div>
                </div>
              </div>
              <OrganizationDocuments {...childProps} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*+++++++++++++++++++++ BasicInfo End ++++++++++++++++++++++++++ */

/*+++++++++++++++++++++ OrganizationDocuments Start ++++++++++++++++++++++++++ */
export class OrganizationDocuments extends Component {
  downloadOrgDocument = (docType, document) => {
    this.props.downloadDocument(`${docType}/${document._id}`);
  };

  render() {
    const {
      logo,
      companyProfile,
      regCertificate,
      articlesOfAssociation,
      handleInputChange
    } = this.props;

    // const hasProfile = companyProfile && companyProfile._id;
    // const hasRegCert = regCertificate && regCertificate._id;
    // const hasAoa = articlesOfAssociation && articlesOfAssociation._id;

    const companyLogo = logo && logo._id ? logo : null;

    const cProfile =
      companyProfile && companyProfile._id ? companyProfile : null;
    const rCertificate =
      regCertificate && regCertificate._id ? regCertificate : null;

    const aoaFile =
      articlesOfAssociation && articlesOfAssociation._id
        ? articlesOfAssociation
        : null;

    const basicInfo = this.props.basicInfo;
    const logoUpload = basicInfo.logoUpload || null;
    const companyProfileUpload = basicInfo.companyProfileUpload || null;

    const regCertificateUpload = basicInfo.regCertificateUpload || null;

    const articlesOfAssociationUpload =
      basicInfo.articlesOfAssociationUpload || null;

    return (
      <Fragment>
        <hr />
        <div className="row  mb-3">
          <div className="col-12">
            <strong>Organization Documents</strong>
          </div>
        </div>
        {/* <div className="form-group mb-0 row">
          <label className="col-form-label col-12 col-lg-3 col-xl-3">
            Incubatee Profile
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="file"
              name="companyProfileUpload"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-12 col-lg-2 col-xl-2">
            <button
              className={`btn btn-sm btn-${hasProfile ? "info" : "secondary"}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Download Company Profile copy"
              disabled={!hasProfile}
              onClick={this.downloadOrgDocument.bind(
                this,
                "profile",
                companyProfile
              )}
            >
              <i className="fas fa-file-download" />
              &nbsp; Download
            </button>
          </div>
        </div>
        <div className="form-group mb-0 row">
          <label className="col-form-label col-12 col-lg-3 col-xl-3">
            Registration Certificate
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="file"
              name="regCertificateUpload"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-12 col-lg-2 col-xl-2">
            <button
              className={`btn btn-sm btn-${hasRegCert ? "info" : "secondary"}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Download Registration Certificate copy"
              disabled={!hasRegCert}
              onClick={this.downloadOrgDocument.bind(
                this,
                "reg-cert",
                regCertificate
              )}
            >
              <i className="fas fa-file-download" />
              &nbsp; Download
            </button>
          </div>
        </div>
        <div className="form-group  mb-0 row">
          <label className="col-form-label col-12 col-lg-3 col-xl-3">
            Articles Of Association
          </label>
          <div className="col-12 col-lg-4 col-xl-4">
            <input
              type="file"
              name="articlesOfAssociationUpload"
              onChange={handleInputChange}
            />
          </div>
          <div className="col-12 col-lg-2 col-xl-2">
            <button
              className={`btn btn-sm btn-${hasAoa ? "info" : "secondary"}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Download Articles Of Association copy"
              disabled={!hasAoa}
              onClick={this.downloadOrgDocument.bind(
                this,
                "aoa",
                articlesOfAssociation
              )}
            >
              <i className="fas fa-file-download" />
              &nbsp; Download
            </button>
          </div>
        </div> */}

        <div className="row  mb-3">
          <div className="col-12">
            <div className="table-responsive-sm table-responsive-md">
              <table className="table table-sm table-striped table-contensed">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Document Type</th>
                    <th scope="col">Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Company Logo</td>
                    <td>
                      {companyLogo ? companyLogo.filename : "Not Available"}
                      &nbsp;&nbsp;
                      <span className="text-info">
                        {logoUpload ? `New File : "${logoUpload.name}"` : null}
                      </span>
                    </td>

                    <td>
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-sm btn-outline-info custom-btn">
                          <i className="fas fa-file-upload" />
                          &nbsp;Upload
                          <input
                            type="file"
                            name="logoUpload"
                            onChange={handleInputChange}
                          />
                        </button>
                      </div>
                      &nbsp;
                      {companyLogo ? (
                        <div className="download-btn-wrapper">
                          <button
                            className="btn btn-sm btn-outline-info custom-btn"
                            data-toggle="tooltip"
                            title="Download Incubatee Profile copy"
                            onClick={this.downloadOrgDocument.bind(
                              this,
                              "logo",
                              companyLogo
                            )}
                          >
                            <i className="fas fa-file-download" />
                            &nbsp; Download
                          </button>
                        </div>
                      ) : null}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">2</th>
                    <td>Incubatee Profile</td>
                    <td>
                      {cProfile ? cProfile.filename : "Not Available"}
                      &nbsp;&nbsp;
                      <span className="text-info">
                        {companyProfileUpload
                          ? `New File : "${companyProfileUpload.name}"`
                          : null}
                      </span>
                    </td>

                    <td>
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-sm btn-outline-info custom-btn">
                          <i className="fas fa-file-upload" />
                          &nbsp;Upload
                          <input
                            type="file"
                            name="companyProfileUpload"
                            onChange={handleInputChange}
                          />
                        </button>
                      </div>
                      &nbsp;
                      {cProfile ? (
                        <div className="download-btn-wrapper">
                          <button
                            className="btn btn-sm btn-outline-info custom-btn"
                            data-toggle="tooltip"
                            title="Download Incubatee Profile copy"
                            onClick={this.downloadOrgDocument.bind(
                              this,
                              "profile",
                              companyProfile
                            )}
                          >
                            <i className="fas fa-file-download" />
                            &nbsp; Download
                          </button>
                        </div>
                      ) : null}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">3</th>
                    <td>Registration Certificate</td>
                    <td>
                      {rCertificate ? rCertificate.filename : "Not Available"}
                      &nbsp;&nbsp;
                      <span className="text-info">
                        {regCertificateUpload
                          ? `New File : "${regCertificateUpload.name}"`
                          : null}
                      </span>
                    </td>
                    <td>
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-sm btn-outline-info custom-btn">
                          <i className="fas fa-file-upload" />
                          &nbsp;Upload
                          <input
                            type="file"
                            name="regCertificateUpload"
                            onChange={handleInputChange}
                          />
                        </button>
                      </div>
                      &nbsp;
                      {rCertificate ? (
                        <div className="download-btn-wrapper">
                          <button
                            className="btn btn-sm btn-outline-info custom-btn"
                            data-toggle="tooltip"
                            title="Download Registration Certificate copy"
                            onClick={this.downloadOrgDocument.bind(
                              this,
                              "reg-cert",
                              regCertificate
                            )}
                          >
                            <i className="fas fa-file-download" />
                            &nbsp; Download
                          </button>
                        </div>
                      ) : null}
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">4</th>
                    <td>Articles Of Association</td>
                    <td>
                      {aoaFile ? aoaFile.filename : "Not Available"}
                      &nbsp;&nbsp;
                      <span className="text-info">
                        {articlesOfAssociationUpload
                          ? `New File : "${articlesOfAssociationUpload.name}"`
                          : null}
                      </span>
                    </td>

                    <td>
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-sm btn-outline-info custom-btn">
                          <i className="fas fa-file-upload" />
                          &nbsp;Upload
                          <input
                            type="file"
                            name="articlesOfAssociationUpload"
                            onChange={handleInputChange}
                          />
                        </button>
                      </div>
                      &nbsp;
                      {aoaFile ? (
                        <div className="download-btn-wrapper">
                          <button
                            className="btn btn-sm btn-outline-info custom-btn"
                            data-toggle="tooltip"
                            title="Download Articles Of Association copy"
                            onClick={this.downloadOrgDocument.bind(
                              this,
                              "aoa",
                              articlesOfAssociation
                            )}
                          >
                            <i className="fas fa-file-download" />
                            &nbsp; Download
                          </button>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
/*+++++++++++++++++++++ OrganizationDocuments End ++++++++++++++++++++++++++ */

/*+++++++++++++++++++++ Promoters Start ++++++++++++++++++++++++++ */
export class Promoters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoters: [],
      inputError: []
    };
  }

  loadStateFromProps = () => {
    const { newRecord, promoters } = this.props;
    // props 'promoters' not null or not empty then add to local state.
    if (!newRecord && promoters && promoters.length > 0) {
      this.setState({ promoters: [...promoters] });
      return;
    }

    // props 'promoters' is null or is empty then create new 'promoters' array in local state.
    if (this.state.promoters.length === 0) {
      //this.addMoreAddresses();
    }
  };

  handleInputChange = (index, e) => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
    }

    let promoters = [...this.state.promoters];
    let promoter = promoters[index];
    promoter[targetName] = targetValue;
    promoters[index] = promoter;
    this.setState({ promoters });
  };

  validate = () => {
    const promoters = [...this.state.promoters];
    let hasError = false;
    let inputError = [];
    let totalShare = 0;

    promoters.forEach((pmtr, index) => {
      inputError[index] = {};
      const sharehold = pmtr.shareholding ? Number(pmtr.shareholding) : 0;

      if (sharehold < 0) {
        inputError[index].shareHolding =
          "Shareholding shouldn't be in negative.";
        hasError = true;
      }
      totalShare = totalShare + sharehold;

      if (checkEmptyValue(inputError[index], "designation", pmtr.designation)) {
        inputError[index].designation = "Designation shouldn't be empty.";
        hasError = true;
      }
    });

    // Some of the shareholding are in negative.
    if (hasError) {
      this.props.onValidate(inputError, "promoters", null);
      this.setState({ inputError });
      return;
    }

    hasError = totalShare > 100;

    if (hasError) {
      inputError = promoters.map(() => {
        return { shareHolding: "Total Shareholding shouldn't more than 100%." };
      });
      this.props.onValidate(inputError, "promoters", null);
    } else {
      inputError = {};
      this.props.onValidate(null, "promoters", this.state.promoters);
    }
    this.setState({ inputError });
  };

  componentDidMount() {
    this.loadStateFromProps();
  }

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    const promoters = this.props.promoters;
    if (promoters !== prevState.promoters) {
      this.loadStateFromProps();
    }
  };

  render() {
    const { promoters, inputError } = this.state;
    const hideIndividualLink = this.props.hideIndividualLink || false;
    const promoterList = promoters.map((pmtr, index) => {
      const error = inputError[index] || {};
      const shareHoldingMsg = error.shareHolding || "";
      const designationMsg = error.designation || "";

      const individual = pmtr.individual;
      let fname = `${individual.firstName ? individual.firstName : ""}`;
      let lname = `${individual.lastName ? individual.lastName : ""}`;
      const promoterName = `${fname} ${lname}`;
      return (
        <tr key={index}>
          <td>
            {promoterName}
            <br />
            {hideIndividualLink ? null : (
              <Link
                to={`/admin/individuals/edit/${pmtr.individual._id}?from=${
                  this.props.currentLocation
                }&incProfUrl=${this.props.incProfUrl}`}
              >
                Edit
              </Link>
            )}
          </td>
          <td className="text-center">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="isFounder"
                name="isFounder"
                checked={pmtr.isFounder ? pmtr.isFounder : ""}
                onChange={this.handleInputChange.bind(this, index)}
              />
              <label className="custom-control-label" htmlFor="isFounder" />
            </div>
          </td>
          <td className="align-middle">
            <input
              type="text"
              className={
                designationMsg
                  ? "form-control form-control-sm text-right is-invalid"
                  : "form-control form-control-sm text-right"
              }
              placeholder=""
              name="designation"
              value={renderInputData(pmtr.designation)}
              onChange={this.handleInputChange.bind(this, index)}
            />
            <div className="invalid-feedback">{designationMsg}</div>
          </td>
          <td className="align-middle">
            <input
              type="number"
              className={
                shareHoldingMsg
                  ? "form-control form-control-sm text-right is-invalid"
                  : "form-control form-control-sm text-right"
              }
              placeholder="0%"
              min="0"
              maxLength="2"
              name="shareholding"
              value={renderInputData(pmtr.shareholding)}
              onChange={this.handleInputChange.bind(this, index)}
            />
            <div className="invalid-feedback">{shareHoldingMsg}</div>
          </td>
        </tr>
      );
    });
    return (
      <div className="col-12 col-lg-6 col-xl-6">
        <div className="card flex-fill">
          <div className="card-header bg-card-header text-white ">
            <h6 className="card-titile mb-0">Promoters</h6>
          </div>
          <div className="row ">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-sm table-striped mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: "25%" }} scope="col">
                        Name
                      </th>
                      <th
                        className="text-center"
                        style={{ width: "25%" }}
                        scope="col"
                      >
                        Founder
                      </th>
                      <th style={{ width: "25%" }} scope="col">
                        Designation
                      </th>
                      <th style={{ width: "25%" }} scope="col">
                        Sharehold (%)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {promoterList.length > 0 ? (
                      promoterList
                    ) : (
                      <tr>
                        <td>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*+++++++++++++++++++++ Promoters End ++++++++++++++++++++++++++ */

/*+++++++++++++++++++++ Financial Info Start ++++++++++++++++++++++++++ */
export class FinancialInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fYearMapper: getFinancialYears(),
      financials: [],
      financialYear: null,
      authorizedCapital: null,
      annualTurnover: null,
      inputError: {}
    };
  }

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    this.setState({ [targetName]: targetValue });
  };

  validate = () => {
    const { financialYear, authorizedCapital, annualTurnover } = this.state;
    const financials = [...this.state.financials];

    let inputError = {};
    const finYearEmpty = checkEmptyValue(
      inputError,
      "financialYear",
      financialYear
    );
    const authCapitalEmpty = checkEmptyValue(
      inputError,
      "authorizedCapital",
      authorizedCapital
    );
    const annualTurnoverEmpty = checkEmptyValue(
      inputError,
      "annualTurnover",
      annualTurnover
    );

    // No data is entered in the fields. Just send the current values
    if (finYearEmpty && authCapitalEmpty && annualTurnoverEmpty) {
      this.props.onValidate(null, "financials", financials);
      return;
    }

    const hasError = finYearEmpty || authCapitalEmpty || annualTurnoverEmpty;

    if (hasError) {
      this.props.onValidate(inputError, "financials", null);
    } else {
      const finance = {
        date: new Date(),
        authorizedCapital: authorizedCapital,
        annualTurnover: annualTurnover,
        financialYear: financialYear
      };
      financials.push(finance);
      this.props.onValidate(null, "financials", financials);
      inputError = [];
    }

    this.setState({ inputError });
  };

  loadStateFromProps = () => {
    const { newRecord, financials } = this.props;

    // props 'financials' not null or not empty then add to local state.
    if (!newRecord && financials && financials.length > 0) {
      let fYearMapper = [...this.state.fYearMapper];
      const newFinancial = [...financials];

      // Remove existing finacial year.
      newFinancial.forEach(fncls => {
        fYearMapper = fYearMapper.filter(fy => fy !== fncls.financialYear);
      });

      this.setState({
        financials: newFinancial,
        fYearMapper,
        financialYear: null,
        authorizedCapital: null,
        annualTurnover: null
      });
      return;
    }
  };

  componentDidMount() {
    this.loadStateFromProps();
  }

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    const financials = this.props.financials;
    if (financials !== prevState.financials) {
      this.loadStateFromProps();
    }
  };
  render() {
    const {
      fYearMapper,
      financialYear,
      authorizedCapital,
      annualTurnover,
      inputError,
      financials
    } = this.state;

    let financialList = [];
    if (financials.length > 0) {
      financialList = financials.map((fin, index) => {
        return (
          <tr key={index}>
            <td className="text-center w-25">{fin.financialYear}</td>
            <td className="text-right">
              {renderInputData(changeToINR(fin.authorizedCapital))}
            </td>
            <td className="text-right">
              {renderInputData(changeToINR(fin.annualTurnover))}
            </td>
          </tr>
        );
      });
    }

    return (
      <div className="col-12 col-lg-6 col-xl-6">
        <div className="card flex-fill">
          <div className="card-header bg-card-header text-white ">
            <h6 className="card-titile mb-0">Financial Information</h6>
          </div>

          <div className="row ">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-sm table-striped mb-0">
                  <thead>
                    <tr>
                      <th className="text-center w-25" scope="col">
                        Financial Year
                      </th>
                      <th className="text-right" scope="col">
                        Authorized Capital
                      </th>
                      <th className="text-right" scope="col">
                        Annual Turnover
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {financialList}

                    <tr>
                      <td>
                        <select
                          className={
                            inputError["financialYear"]
                              ? "custom-select custom-select-sm is-invalid"
                              : "custom-select custom-select-sm "
                          }
                          name="financialYear"
                          value={renderInputData(financialYear)}
                          onChange={this.handleInputChange}
                        >
                          <option value="">Select...</option>
                          {fYearMapper.map(yyyy => (
                            <option key={yyyy} value={yyyy}>
                              {yyyy}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          Please select financial year *.
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          className={
                            inputError["authorizedCapital"]
                              ? "custom-select custom-select-sm is-invalid"
                              : "custom-select custom-select-sm "
                          }
                          placeholder="0"
                          name="authorizedCapital"
                          value={renderInputData(authorizedCapital)}
                          onChange={this.handleInputChange}
                        />
                        <div className="invalid-feedback">
                          Please enter authorized capital *.
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          className={
                            inputError["annualTurnover"]
                              ? "custom-select custom-select-sm is-invalid"
                              : "custom-select custom-select-sm "
                          }
                          placeholder="0"
                          name="annualTurnover"
                          value={renderInputData(annualTurnover)}
                          onChange={this.handleInputChange}
                        />
                        <div className="invalid-feedback">
                          Please enter annual turnover *.
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*+++++++++++++++++++++ Financial Info End ++++++++++++++++++++++++++ */
