import HttpUtil from "../components/common/HttpUtil";
import { USER_PERMISSIONS_URL } from "../components/common/Constants";

function fetchUserPermissions() {
  // Fetch api
  HttpUtil.get(
    USER_PERMISSIONS_URL,
    {},
    data => {
      window.ability.update(data);
    },
    () => {},
    () => {}
  );
}

export default fetchUserPermissions;
