import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  incubatedDateTo: Yup.date().when(
    "incubatedDateFrom",
    (incubatedDateFrom, schema) => {
      return schema.test({
        test: incubatedDateTo =>
          !incubatedDateFrom ||
          incubatedDateTo > incubatedDateFrom ||
          !incubatedDateTo,
        message: "To Date should be after From Date"
      });
    }
  ),

  graduatedDateTo: Yup.date().when(
    "graduatedDateFrom",
    (graduatedDateFrom, schema) => {
      return schema.test({
        test: graduatedDateTo =>
          !graduatedDateFrom ||
          graduatedDateTo > graduatedDateFrom ||
          !graduatedDateTo,
        message: "To Date should be after From Date"
      });
    }
  ),
  toAge: Yup.number().when("fromAge", (fromAge, schema) => {
    return schema.test({
      test: toAge => !fromAge || toAge > fromAge || !toAge,
      message: "To Age should be more then From Age"
    });
  }),
  proToAge: Yup.number().when("proFromAge", (proFromAge, schema) => {
    return schema.test({
      test: proToAge => !proFromAge || proToAge > proFromAge || !proToAge,
      message: "To Age should be more then From Age"
    });
  }),
  yearOfPassingTo: Yup.string().when(
    "yearOfPassingFrom",
    (yearOfPassingFrom, schema) => {
      return schema.test({
        test: yearOfPassingTo =>
          !yearOfPassingFrom ||
          yearOfPassingTo > yearOfPassingFrom ||
          !yearOfPassingTo,
        message: "To Year should be after From Year"
      });
    }
  ),
  proYearOfPassingTo: Yup.string().when(
    "proYearOfPassingFrom",
    (proYearOfPassingFrom, schema) => {
      return schema.test({
        test: proYearOfPassingTo =>
          !proYearOfPassingFrom ||
          proYearOfPassingTo > proYearOfPassingFrom ||
          !proYearOfPassingTo,
        message: "To Year should be after From Year"
      });
    }
  ),
  companyStartDateTo: Yup.string().when(
    "companyStartDateFrom",
    (companyStartDateFrom, schema) => {
      return schema.test({
        test: companyStartDateTo =>
          !companyStartDateFrom ||
          companyStartDateTo > companyStartDateFrom ||
          !companyStartDateTo,
        message: "To Year should be after From Year"
      });
    }
  ),
  totalEmployeeCountTo: Yup.number().when(
    "totalEmployeeCountFrom",
    (totalEmployeeCountFrom, schema) => {
      return schema.test({
        test: totalEmployeeCountTo =>
          !totalEmployeeCountFrom ||
          totalEmployeeCountTo > totalEmployeeCountFrom ||
          !totalEmployeeCountTo,
        message: "To Count should be more then From Count"
      });
    }
  ),
  femaleEmployeeCountTo: Yup.number().when(
    "femaleEmployeeCountFrom",
    (femaleEmployeeCountFrom, schema) => {
      return schema.test({
        test: femaleEmployeeCountTo =>
          !femaleEmployeeCountFrom ||
          femaleEmployeeCountTo > femaleEmployeeCountFrom ||
          !femaleEmployeeCountTo,
        message: "To Count should be more then From Count"
      });
    }
  ),
  lastAnnualTurnoverTo: Yup.number().when(
    "lastAnnualTurnoverFrom",
    (lastAnnualTurnoverFrom, schema) => {
      return schema.test({
        test: lastAnnualTurnoverTo =>
          !lastAnnualTurnoverFrom ||
          lastAnnualTurnoverTo > lastAnnualTurnoverFrom ||
          !lastAnnualTurnoverTo,
        message: "To Count should be more then From Count"
      });
    }
  )
});

export default validationSchema;
