import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import FloorForm from "./FloorForm";
import DefaultAlert from "../DefaultAlert";
import {
  FLOOR_API,
  STATUS_LIST_API,
  AREA_UNIT_LIST_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import Breadcrumb from "../../common/Breadcrumb";
import FloorBasedPartiitonList from "./FloorBasedPartiitonList";
import { convertDateMonthNameYear } from "../../lease/DateUtil";
import ErrorPage from "../../common/error.page";

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

class Floor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // auto lookup
      campus: "",

      //Floor
      building: "", // auto lookup
      floorName: "",
      mainLevel: false,
      abbreviation: "",
      usableArea: 0,
      totalArea: 0,
      areaUnit: "",
      areaPrice: 0,
      seaterPrice: 0,
      status: "ACTIVE",

      breadCrumbList: [],

      partitions: [],

      //redirect to edit floor with id
      newFloor: null,
      editFloor: false,
      editFloorId: "",

      //for input error alert
      inputError: {},

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: "",
      permanentFailure: false,

      // for selector
      statusList: [],
      areaUnitList: [],

      // For Floor Plan
      floorPlan: null,
      floorPlanPreviewUrl: null,
      fileName: null,

      pendingImageUploads: [],
      floorPlans: []
    };
  }

  onChange = e => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleChangeFloorPlanImage = e => {
    const files = e.target.files || [];
    const imageFiles = this.state.pendingImageUploads || [];
    // Find out duplicate file
    let existingFiles = {};
    for (let i = 0; i < imageFiles.length; i++) {
      let file = imageFiles[i];

      if (!existingFiles[file.name]) {
        existingFiles[file.name] = true;
      }
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      const fileType = file.type;

      // verify duplicate file
      let isDuplicateFile = existingFiles[file.name] || false;
      if (fileType.includes("image") && !isDuplicateFile) {
        imageFiles.push(file);
      }
    }

    this.setState({ pendingImageUploads: imageFiles });

    // clear the input after uploading the file. Otherwise selecting the same file again would not trigger the input change as nothing has changed.
    e.target.value = null;
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getFloorById(id);
    } else {
      this.setState({ newFloor: true });
    }
    this.fetchFloorStatus();
    this.fetchFloorAreaUnitList();
  };

  fetchFloorStatus = () => {
    const url = STATUS_LIST_API + "FLOOR";
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({ statusList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  fetchFloorAreaUnitList = () => {
    //
    const url = AREA_UNIT_LIST_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => {
        this.setState({ areaUnitList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  validate = floor => {
    let inputError = {};

    inputError.campus = floor.campus === "";
    inputError.building = floor.building === null || floor.building === "";
    inputError.floorName = floor.floorName === "";
    inputError.abbreviation = floor.abbreviation === "";
    inputError.usableArea = floor.usableArea <= 0;
    inputError.totalArea = floor.totalArea <= 0;
    inputError.areaUnit = floor.areaUnit === "";

    const invalid = Object.values(inputError).some(item => item);

    if (invalid) {
      this.setState({
        inputError
      });
      return false;
    } else {
      return true;
    }
  };

  getFloor = () => {
    const {
      //Floor
      floorName,
      building,
      mainLevel,
      abbreviation,
      usableArea,
      totalArea,
      areaUnit,
      status,
      areaPrice,
      seaterPrice
    } = this.state;

    const floor = {
      //Floor
      floorName,
      building,
      mainLevel,
      abbreviation,
      usableArea,
      totalArea,
      areaUnit,
      status,
      areaPrice,
      seaterPrice
    };

    return floor;
  };

  setDataFromServer = data => {
    const breadCrumbList = [];

    let campusName = data.building.campus.name;
    let campusId = data.building.campus._id;
    let bctCampus = {
      displayName: campusName,
      linkTo: `/admin/campuses/edit/${campusId}`
    };
    breadCrumbList.push(bctCampus);

    let buildingName = data.building.buildingName;
    let buildingId = data.building._id;
    let bctBuilding = {
      displayName: buildingName,
      linkTo: `/admin/buildings/edit/${buildingId}`
    };
    breadCrumbList.push(bctBuilding);

    let bctFloor = {
      displayName: data.floorName,
      linkTo: null
    };
    breadCrumbList.push(bctFloor);

    let floorPlans = data.floorPlans || [];
    floorPlans = floorPlans.map((floorPlan, i) => {
      const baseUrl = `${FLOOR_API}/${data._id}`;
      const preview = {
        url: `${baseUrl}/floor-plan/${floorPlan._id}`,
        _id: floorPlan._id,
        name: floorPlan.filename,
        contentType: floorPlan.contentType,
        updatedAt: convertDateMonthNameYear(floorPlan.uploadDate),
        size: bytesToSize(floorPlan.size)
      };
      return preview;
    });

    this.setState({
      _id: data._id,
      floorName: data.floorName,
      building: data.building.buildingName,
      buildingId: data.building._id,
      mainLevel: data.mainLevel,
      abbreviation: data.abbreviation,
      usableArea: data.usableArea,
      totalArea: data.totalArea,
      areaUnit: data.areaUnit,
      status: data.status,
      areaPrice: data.areaPrice,
      seaterPrice: data.seaterPrice,
      breadCrumbList: breadCrumbList,
      partitions: data.partitions ? data.partitions : [],

      inputError: {},
      newFloor: false,

      floorPlans,
      pendingImageUploads: []
    });
  };

  getFloorById = id => {
    let url = FLOOR_API + "/" + id;
    const authToken = this.props.authToken;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken
    };

    let showAlert,
      alertMode,
      alertMessage = null;

    if (this.props.location.state && this.props.location.state.created) {
      showAlert = true;
      alertMode = "success";
      alertMessage = "New Floor has been added successfully.";
      this.setState({ showAlert, alertMode, alertMessage });
    }

    HttpUtil.get(
      url,
      headers,
      data => {
        this.setDataFromServer(data);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  getFormData = () => {
    let formData = new FormData();
    const pendingImageUploads = this.state.pendingImageUploads || [];
    console.log("pendingImageUploads:", pendingImageUploads);
    for (let i = 0; i < pendingImageUploads.length; i++) {
      const file = pendingImageUploads[i];
      this.addDocs(formData, "floorPlans", file);
    }

    const floor = this.getFloor();

    const newFloor = this.state.newFloor;
    const building = newFloor ? this.state.building : this.state.buildingId;
    floor.building = building;

    formData.append("message", JSON.stringify(floor));
    return formData;
  };

  createFloor = () => {
    // Extract floor object from component state
    const floor = this.getFloor();

    // validate and show error alert
    if (!this.validate(floor)) {
      return;
    }

    const createRequest = this.getFormData();

    const url = FLOOR_API;

    // Fire request
    HttpUtil.post(
      url,
      {},
      createRequest,
      data => {
        const editFloorId = data._id;
        this.setState({
          inputError: {},
          editFloor: true,
          editFloorId: editFloorId
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  updateFloor = () => {
    // Extract floor object from component state
    const floor = this.getFloor();
    floor.building = this.state.buildingId;

    // validate and show error alert
    if (!this.validate(floor)) {
      return;
    }
    const updateRequest = this.getFormData();

    const url = `${FLOOR_API}/${this.state._id}`;
    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        this.setDataFromServer(data);
        this.setState({
          showAlert: true,
          alertMode: "success",
          alertMessage: `Floor ${data.floorName} has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  getCampus = campus => {
    if (campus !== null) {
      return this.setState({
        campus: campus.value,
        building: null
      });
    } else {
      this.setState({
        campus: null,
        building: null
      });
    }
  };

  getBuilding = building => {
    if (building !== null) {
      return this.setState({
        building: building.value
      });
    } else {
      this.setState({ building: null });
    }
  };

  backToFloorList = () => {
    this.setState({
      floorList: true
    });
  };

  handleRemoveImage = documentId => {
    const url = `${FLOOR_API}/${this.state._id}/floor-plan/${documentId}`;
    HttpUtil.delete(
      url,
      {},
      data => {
        this.setDataFromServer(data);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  removeFile = index => {
    let pendingImageUploads = [...this.state.pendingImageUploads];
    if (pendingImageUploads.length === 0) {
      return;
    }

    pendingImageUploads = pendingImageUploads.filter(
      (file, idx) => idx !== index
    );

    return this.setState({ pendingImageUploads });
  };

  handlePreviewImage = (imageUrl, imageName) => {
    this.setState({ imageUrl, imageName });
  };

  handlePreviewImageClose = () => {
    this.setState({ imageUrl: null, imageName: null });
  };

  render() {
    const {
      floorList,

      //Floor
      floorName,
      mainLevel,
      abbreviation,

      usableArea,
      totalArea,
      areaUnit,
      status,
      areaPrice,
      seaterPrice,
      //redirect to edit floor with id
      newFloor,
      editFloor,
      editFloorId,

      //for input error alert
      inputError,

      //Default alert
      showAlert,
      alertMode,
      alertMessage,
      permanentFailure,

      // auto-lookup
      campus,
      building,

      // for selectors
      statusList,
      areaUnitList
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (newFloor === null) {
      return null;
    }

    if (floorList) {
      return <Redirect to="/admin/floors" />;
    }

    if (editFloor) {
      const locationState = {
        pathname: `/admin/floors/edit/${editFloorId}`,
        state: { created: true }
      };
      return <Redirect to={locationState} />;
    }

    const floorPlanProps = {
      floorPlans: this.state.floorPlans,
      pendingImageUploads: this.state.pendingImageUploads,

      // events
      handleChangeFloorPlanImage: this.handleChangeFloorPlanImage,
      handleRemoveImage: this.handleRemoveImage,
      removeFile: this.removeFile
    };

    const imageViewerProps = {
      imageUrl: this.state.imageUrl,
      handlePreviewImage: this.handlePreviewImage,
      handlePreviewImageClose: this.handlePreviewImageClose,
      imageName: this.state.imageName
    };

    return (
      <Fragment>
        {newFloor ? null : (
          <Breadcrumb breadCrumbList={this.state.breadCrumbList} />
        )}

        {/* <FloorPlan {...floorPlanProps} /> */}
        <div className="row">
          {/* show alert message  */}

          <div className="col-12 col-xl-12">
            <DefaultAlert
              show={showAlert}
              alertMode={alertMode}
              message={alertMessage}
              closeAlert={this.closeDefaultAlert}
            />
            <FloorForm
              floorName={floorName}
              mainLevel={mainLevel}
              abbreviation={abbreviation}
              usableArea={usableArea}
              totalArea={totalArea}
              areaUnit={areaUnit}
              status={status}
              areaPrice={areaPrice}
              seaterPrice={seaterPrice}
              //events
              newFloor={newFloor}
              onChange={this.onChange}
              submit={newFloor ? this.createFloor : this.updateFloor}
              backToFloorList={this.backToFloorList}
              inputError={inputError}
              //authToken to AutoLookup
              authToken={this.props.authToken}
              campus={campus}
              building={building}
              getCampus={this.getCampus}
              getBuilding={this.getBuilding}
              // for selectors
              statusList={statusList}
              areaUnitList={areaUnitList}
              {...floorPlanProps}
              {...imageViewerProps}
            />
          </div>
        </div>
        <FloorBasedPartiitonList partitions={this.state.partitions} />
      </Fragment>
    );
  }
}

export default Floor;
