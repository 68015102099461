import React from "react";
import { Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";

import { EDU_QUALIFICATION_TYPES } from "../../../common/LookupConstants";

import { getYears, getDisplayDate } from "../../../lease/DateUtil";

const YEARS = getYears();

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

const GENDERS = [
  {
    label: "Male",
    value: "M"
  },
  {
    label: "Female",
    value: "F"
  },
  {
    label: "Transgender",
    value: "T"
  }
];

const Individual = props => {
  const { values, errors, touched, setFieldValue, downloadDocument } = props;

  const individualErrors = errors.individual || {};
  const individualTouched = touched.individual || {};
  const individual = values.individual || {};
  return (
    <div>
      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3"> Basic Information</span>
        </div>
      </div>
      <div className="card-body">
        {/* First Name */}
        <div className="row mb-2">
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                First Name
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(individualTouched, individualErrors, "firstName")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.firstName"
                  values={individual.firstName || ""}
                />
                <ErrorMessage
                  component="div"
                  name="individual.firstName"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Last Name */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Last Name
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(individualTouched, individualErrors, "lastName")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.lastName"
                  values={individual.lastName || ""}
                />
                <ErrorMessage
                  component="div"
                  name="individual.lastName"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Date of Birth */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Date of Birth
              </label>
              <div className="col-lg-6">
                <DatePicker
                  value={getDisplayDate(individual.dob) || null}
                  selected={individual.dob || null}
                  onChange={date => {
                    setFieldValue("individual.dob", date);
                  }}
                  utcOffset={0}
                  maxDate={new Date()}
                  placeholderText="dd-mm-yyyy"
                  className={`form-control form-control-sm ${
                    getError(individualTouched, individualErrors, "dob")
                      ? "is-invalid"
                      : ""
                  }`}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <ErrorMessage
                  component="div"
                  name="individual.dob"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Gender */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Gender
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(individualTouched, individualErrors, "gender")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.gender"
                  values={individual.gender || ""}
                >
                  <option value="">Select...</option>
                  {GENDERS.map((g, i) => (
                    <option key={i} value={g.value}>
                      {g.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="individual.gender"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Experience */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Experience
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(individualTouched, individualErrors, "experience")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.experience"
                  values={individual.experience || ""}
                />
                <ErrorMessage
                  component="div"
                  name="individual.experience"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* References */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                References
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(individualTouched, individualErrors, "references")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.references"
                  values={individual.references || ""}
                />
                <ErrorMessage
                  component="div"
                  name="individual.references"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
          {/* Photo */}
          <div className="col-lg-12 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label required mt-1">
                Photo
              </label>
              <div className="col-lg-6">
                <div
                  className={`input-group ${
                    getError(
                      individualTouched,
                      individualErrors,
                      "uploadIndvPhoto"
                    )
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <div className="custom-file">
                    <input
                      type="file"
                      className={`custom-file-input ${
                        getError(
                          individualTouched,
                          individualErrors,
                          "uploadIndvPhoto"
                        )
                          ? "is-invalid"
                          : ""
                      }`}
                      id="individual.uploadIndvPhoto"
                      aria-describedby="individual.uploadIndvPhoto"
                      name="individual.uploadIndvPhoto"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (file) {
                          setFieldValue("individual.uploadIndvPhoto", file);
                        }
                        e.target.value = "";
                      }}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="individual.uploadIndvPhoto"
                    >
                      {individual.uploadIndvPhoto
                        ? individual.uploadIndvPhoto.name
                        : "Choose file"}
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-outline-info"
                      type="button"
                      onClick={e =>
                        setFieldValue("individual.uploadIndvPhoto", "")
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <ErrorMessage
                  component="div"
                  name={`individual.uploadIndvPhoto`}
                  className="invalid-feedback d-block mb-2"
                />
              </div>
              <div className="col-lg-2">
                {individual.indvPhoto ? (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-1"
                    onClick={downloadDocument.bind(this, individual.indvPhoto)}
                  >
                    <i className="fas fa-download mr-1" /> Photo
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-12 mb-3 edit-page-section-header-text">
          <span className="ml-3">Education</span>
        </div>
      </div>
      <div className="card-body">
        <div className="row mb-2">
          {/* Edu Qualification */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Edu Qualification
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(
                      individualTouched,
                      individualErrors,
                      "qualification"
                    )
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.qualification"
                  values={individual.qualification || ""}
                >
                  <option value="">Select...</option>
                  {EDU_QUALIFICATION_TYPES.map((opt, i) => (
                    <option key={i} value={opt.key}>
                      {opt.value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="individual.qualification"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Degree */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                Degree
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(individualTouched, individualErrors, "degree")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.degree"
                  values={individual.degree || ""}
                />
                <ErrorMessage
                  component="div"
                  name="individual.degree"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Institution */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                Institution
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(individualTouched, individualErrors, "institution")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.institution"
                  values={individual.institution || ""}
                />
                <ErrorMessage
                  component="div"
                  name="individual.institution"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          {/* PSG Alumni */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                PSG Alumni
              </label>
              <div className="col-lg-6">
                <div className="custom-control custom-checkbox">
                  <Field
                    type="checkbox"
                    className="custom-control custom-control-input"
                    id="alumni"
                    name="alumni"
                    checked={individual.alumni || false}
                    onChange={e => {
                      if (!e.target.checked) {
                        setFieldValue("individual.parentCompany", "");
                      }
                      setFieldValue("individual.alumni", e.target.checked);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="alumni" />
                  Yes
                </div>
                <ErrorMessage
                  component="div"
                  name="individual.alumni"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          {/* Year of Passing */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label
                className={`col-lg-4 col-form-label col-form-label-sm edit-page-label ${
                  individual.alumni ? "required" : ""
                }`}
              >
                Year of Passing
              </label>
              <div className="col-lg-6">
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${
                    getError(individualTouched, individualErrors, "year")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.year"
                  values={individual.year || ""}
                >
                  <option value="">Select...</option>
                  {YEARS.map(yyyy => (
                    <option key={yyyy} value={yyyy}>
                      {yyyy}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="div"
                  name="individual.year"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>

          {/* Branch */}
          <div className="col-lg-6 mb-2">
            <div className="form-group row mb-0">
              <label
                className={`col-lg-4 col-form-label col-form-label-sm edit-page-label ${
                  individual.alumni ? "required" : ""
                }`}
              >
                Branch
              </label>
              <div className="col-lg-6">
                <Field
                  type="text"
                  className={`form-control form-control-sm ${
                    getError(individualTouched, individualErrors, "branch")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="individual.branch"
                  values={individual.branch || ""}
                />
                <ErrorMessage
                  component="div"
                  name="individual.branch"
                  className="invalid-feedback mb-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Individual;
