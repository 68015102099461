import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import { getDisplayDate, getYears } from "../lease/DateUtil";
import { INDIVIDUAL_SCHEMA } from "./ValidationSchema";
import { EDU_QUALIFICATION_TYPES } from "../common/LookupConstants";
import Can from "../../auth/can";

const YEARS = getYears();

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

const GENDERS = [
  {
    label: "Male",
    value: "M"
  },
  {
    label: "Female",
    value: "F"
  },
  {
    label: "Transgender",
    value: "T"
  }
];

const Education = props => {
  const { values, errors, touched, setFieldValue, readOnly } = props;

  const education = values.education || [];

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name={`education`}
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {education.map((edu, eduIndex) => {
              const educationError =
                (errors.education && errors.education[eduIndex]) || {};
              const educationTouched =
                (touched.education && touched.education[eduIndex]) || {};

              const hideRemoveBtn = education.length === 1;
              const hideAddBtn = education.length - 1 !== eduIndex;
              return (
                <div key={eduIndex}>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <strong> Education {eduIndex + 1} </strong>
                    </div>
                  </div>
                  <div className="row mb-0">
                    {/* Edu Qualification */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Edu Qualification
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            // className="form-control form-control-sm"
                            className={`custom-select custom-select-sm ${
                              getError(
                                educationTouched,
                                educationError,
                                "qualification"
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`education[${eduIndex}].qualification`}
                            values={edu.qualification || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {EDU_QUALIFICATION_TYPES.map((opt, i) => (
                              <option key={i} value={opt.key}>
                                {opt.value}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`education[${eduIndex}].qualification`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Degree */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Degree
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(
                                educationTouched,
                                educationError,
                                "degree"
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`education[${eduIndex}].degree`}
                            values={edu.degree || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`education[${eduIndex}].degree`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Institution */}
                    <div className="col-lg-6 mb-0">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          Institution
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(
                                educationTouched,
                                educationError,
                                "institution"
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`education[${eduIndex}].institution`}
                            values={edu.institution || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`education[${eduIndex}].institution`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-0">
                    {/* PSG Alumni */}
                    <div className="col-lg-6">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          PSG Alumni
                        </label>
                        <div className="col-lg-6">
                          <div className="custom-control custom-checkbox">
                            <Field
                              type="checkbox"
                              className="custom-control custom-control-input"
                              id={`education[${eduIndex}].alumni`}
                              checked={edu.alumni || false}
                              onChange={e => {
                                setFieldValue(
                                  `education[${eduIndex}].alumni`,
                                  e.target.checked
                                );
                              }}
                              {...disabledField}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`education[${eduIndex}].alumni`}
                            />
                            Yes
                          </div>
                          <ErrorMessage
                            component="div"
                            name={`education[${eduIndex}].alumni`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    {/* Year of Passing */}
                    <div className="col-lg-6">
                      <div className="form-group row mb-0">
                        <label
                          className={`col-lg-4 col-form-label col-form-label-sm edit-page-label ${
                            edu.alumni ? "required" : ""
                          }`}
                        >
                          Year of Passing
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            className={`custom-select custom-select-sm ${
                              getError(educationTouched, educationError, "year")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`education[${eduIndex}].year`}
                            values={edu.year || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {YEARS.map(yyyy => (
                              <option key={yyyy} value={yyyy}>
                                {yyyy}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`education[${eduIndex}].year`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Branch */}
                    <div className="col-lg-6">
                      <div className="form-group row mb-0">
                        <label
                          className={`col-lg-4 col-form-label col-form-label-sm edit-page-label ${
                            edu.alumni ? "required" : ""
                          }`}
                        >
                          Branch
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(
                                educationTouched,
                                educationError,
                                "branch"
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`education[${eduIndex}].branch`}
                            values={edu.branch || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`education[${eduIndex}].branch`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mt-4 mb-4">
                    <div className="col-12">
                      <button
                        className={`btn btn-outline-info btn-sm ${
                          hideAddBtn ? "d-none" : ""
                        }`}
                        type="button"
                        onClick={() =>
                          push({
                            qualification: "",
                            degree: "",
                            year: "",
                            institution: "",
                            alumni: false
                          })
                        }
                        {...disabledField}
                      >
                        Add More
                      </button>
                      &nbsp;
                      <button
                        className={`btn btn-outline-danger btn-sm ${
                          hideRemoveBtn ? "d-none" : ""
                        }`}
                        type="button"
                        onClick={() => remove(eduIndex)}
                        {...disabledField}
                      >
                        Remove Education {eduIndex + 1}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const Phones = props => {
  const { values, errors, touched, readOnly } = props;

  const phones = values.phones || [];

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name="phones"
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {phones.map((ph, phIndex) => {
              const phError = (errors.phones && errors.phones[phIndex]) || {};
              const phTouched =
                (touched.phones && touched.phones[phIndex]) || {};

              const hideRemoveBtn = phones.length === 1;
              const hideAddBtn = phones.length - 1 !== phIndex;
              return (
                <Fragment key={phIndex}>
                  <strong className="edit-page-label">Mobile</strong>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`input-group  ${
                          getError(phTouched, phError, "phone")
                            ? "is-invalid mb-0"
                            : "mb-3"
                        }`}
                      >
                        <Field
                          type="text"
                          className={`form-control form-control-sm w-25  ${
                            getError(phTouched, phError, "phone")
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="10 digits"
                          name={`phones[${phIndex}].phone`}
                          value={ph.phone || ""}
                          {...disabledField}
                        />
                        &nbsp;
                        <Field
                          component="select"
                          className={`custom-select custom-select-sm  ${
                            getError(phTouched, phError, "type")
                              ? "is-invalid"
                              : ""
                          }`}
                          name={`phones[${phIndex}].type`}
                          value={ph.type || ""}
                          {...disabledField}
                        >
                          <option value="">Select...</option>
                          <option value="MOBILE">Mobile</option>
                          <option value="WORK">Work</option>
                          <option value="OFFICE">Office</option>
                          <option value="OTHER">Other</option>
                        </Field>
                        &nbsp;
                        <button
                          className={`btn btn-info btn-sm  ${
                            hideAddBtn ? "d-none" : ""
                          }`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add"
                          type="button"
                          onClick={() => push({ phone: "", type: "WORK" })}
                          {...disabledField}
                        >
                          <i className="fas fa-plus " />
                        </button>
                        &nbsp;
                        <button
                          className={`btn btn-outline-danger btn-sm  ${
                            hideRemoveBtn ? "d-none" : ""
                          }`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          type="button"
                          onClick={() => remove(phIndex)}
                          {...disabledField}
                        >
                          <i className="far fa-trash-alt " />
                        </button>
                      </div>
                      <div
                        className={` ${
                          getError(phTouched, phError, "phone")
                            ? "invalid-feedback d-block mb-3"
                            : "d-none"
                        }`}
                      >
                        Please enter phone and type *
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const Emails = props => {
  const { values, errors, touched, readOnly } = props;

  const emails = values.emails || [];

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name="emails"
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {emails.map((em, emIndex) => {
              const emError = (errors.emails && errors.emails[emIndex]) || {};
              const emTouched =
                (touched.emails && touched.emails[emIndex]) || {};

              const hideRemoveBtn = emails.length === 1;
              const hideAddBtn = emails.length - 1 !== emIndex;
              return (
                <Fragment key={emIndex}>
                  <strong className="edit-page-label">Email</strong>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`input-group  ${
                          getError(emTouched, emError, "email")
                            ? "is-invalid mb-0"
                            : "mb-3"
                        }`}
                      >
                        <Field
                          type="email"
                          className={`form-control form-control-sm w-25  ${
                            getError(emTouched, emError, "email")
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="10 digits"
                          name={`emails[${emIndex}].email`}
                          value={em.email || ""}
                          {...disabledField}
                        />
                        &nbsp;
                        <Field
                          component="select"
                          className={`custom-select custom-select-sm  ${
                            getError(emTouched, emError, "type")
                              ? "is-invalid"
                              : ""
                          }`}
                          name={`emails[${emIndex}].type`}
                          value={em.type || ""}
                          {...disabledField}
                        >
                          <option value="">Select...</option>
                          <option value="MOBILE">Mobile</option>
                          <option value="WORK">Work</option>
                          <option value="OFFICE">Office</option>
                          <option value="OTHER">Other</option>
                        </Field>
                        &nbsp;
                        <button
                          className={`btn btn-info btn-sm  ${
                            hideAddBtn ? "d-none" : ""
                          }`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add"
                          type="button"
                          onClick={() => push({ email: "", type: "WORK" })}
                          {...disabledField}
                        >
                          <i className="fas fa-plus " />
                        </button>
                        &nbsp;
                        <button
                          className={`btn btn-outline-danger btn-sm  ${
                            hideRemoveBtn ? "d-none" : ""
                          }`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                          type="button"
                          onClick={() => remove(emIndex)}
                          {...disabledField}
                        >
                          <i className="far fa-trash-alt " />
                        </button>
                      </div>
                      <div
                        className={` ${
                          getError(emTouched, emError, "email")
                            ? "invalid-feedback d-block mb-3"
                            : "d-none"
                        }`}
                      >
                        Please enter email and type *
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const Addresses = props => {
  const { values, errors, touched, setFieldValue, readOnly } = props;

  const addresses = values.addresses || [];

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <FieldArray
      name={`addresses`}
      render={({ insert, remove, push }) => {
        return (
          <Fragment>
            {addresses.map((address, addrIndex) => {
              const addrTouched =
                (touched.addresses && touched.addresses[addrIndex]) || {};
              const addrError =
                (errors.addresses && errors.addresses[addrIndex]) || {};

              const hideRemoveBtn = addresses.length === 1;
              const hideAddBtn = addresses.length - 1 !== addrIndex;
              return (
                <Fragment key={addrIndex}>
                  <div
                    className={`row no-gutters ${
                      addrIndex === 0 ? "" : "mt-5"
                    }`}
                  >
                    <div className="col-12 mb-3">
                      <strong> Address Information {addrIndex + 1} </strong>
                    </div>
                  </div>

                  <div className="row mb-2">
                    {/* Street Line 1 */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Street Line 1
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "streetLine1")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].streetLine1`}
                            values={address.streetLine1 || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].streetLine1`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Street Line 2 */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          Street Line 2
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "streetLine2")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].streetLine2`}
                            values={address.streetLine2 || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].streetLine2`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* City */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          City
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "city")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].city`}
                            values={address.city || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].city`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Landmark */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                          Landmark
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "landmark")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].landmark`}
                            values={address.landmark || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].landmark`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* State */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          State
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "state")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].state`}
                            values={address.state || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {props.states.map((state, i) => (
                              <option key={i} value={state.stateCode}>
                                {state.stateName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].state`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Country */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Country
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "country")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].country`}
                            values={address.country || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            {props.countries.map((country, i) => (
                              <option key={i} value={country.countryCode}>
                                {country.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].country`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Postal Code */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Postal Code
                        </label>
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "postalCode")
                                ? "is-invalid"
                                : ""
                            }`}
                            inputMode="numeric"
                            name={`addresses[${addrIndex}].postalCode`}
                            values={address.postalCode || ""}
                            {...disabledField}
                          />
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].postalCode`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Address Type */}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group row mb-0">
                        <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                          Address Type
                        </label>
                        <div className="col-lg-6">
                          <Field
                            component="select"
                            className={`form-control form-control-sm ${
                              getError(addrTouched, addrError, "type")
                                ? "is-invalid"
                                : ""
                            }`}
                            name={`addresses[${addrIndex}].type`}
                            values={address.type || ""}
                            {...disabledField}
                          >
                            <option value="">Select...</option>
                            <option value="PERMANENT">Permanent Address</option>
                            <option value="COMMUNICATION">
                              Communication Address
                            </option>
                            <option value="OFFICE">Office Address</option>
                          </Field>
                          <ErrorMessage
                            component="div"
                            name={`addresses[${addrIndex}].type`}
                            className="invalid-feedback mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Address Proof */}
                  <div className="row mb-2">
                    <div className="col-lg-12 mb-0">
                      <div className="form-group row mb-0">
                        <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label">
                          Address Proof (Driving License, Aadhaar)
                        </label>
                        <div className="col-lg-6">
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id={`addresses[${addrIndex}].uploadAddressProof`}
                                name={`addresses[${addrIndex}].uploadAddressProof`}
                                onChange={e => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    setFieldValue(
                                      `addresses[${addrIndex}].uploadAddressProof`,
                                      file
                                    );
                                  }
                                }}
                                {...disabledField}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor={`addresses[${addrIndex}].uploadAddressProof`}
                              >
                                {address.uploadAddressProof
                                  ? address.uploadAddressProof.name
                                  : "Choose file"}
                              </label>
                            </div>
                            <div className="input-group-append">
                              <button
                                className="btn btn-sm btn-outline-info"
                                type="button"
                                onClick={e =>
                                  setFieldValue(
                                    `addresses[${addrIndex}].uploadAddressProof`,
                                    ""
                                  )
                                }
                                {...disabledField}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          {address.addressProof ? (
                            <button
                              type="button"
                              className="btn btn-info btn-sm mt-1"
                              onClick={() => {
                                if (address.addressProof) {
                                  const id = address.addressProof._id;
                                  props.downloadDocument(
                                    `address/${address._id}/${id}`
                                  );
                                }
                              }}
                            >
                              <i className="fas fa-download mr-1" /> Address
                              Proof
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2 mb-0">
                    <div className="col-12">
                      <button
                        className={`btn btn-outline-info btn-sm ${
                          hideAddBtn ? "d-none" : ""
                        }`}
                        type="button"
                        onClick={() =>
                          push({
                            uploadAddressProof: "",
                            addressProof: "",
                            streetLine1: "",
                            streetLine2: "",
                            landmark: "",
                            city: "",
                            state: "TN",
                            postalCode: "",
                            country: "IN",
                            status: "",
                            type: ""
                          })
                        }
                        {...disabledField}
                      >
                        Add More
                      </button>
                      &nbsp;
                      <button
                        className={`btn btn-outline-danger btn-sm ${
                          hideRemoveBtn ? "d-none" : ""
                        }`}
                        type="button"
                        onClick={() => remove(addrIndex)}
                        {...disabledField}
                      >
                        Remove Address {addrIndex + 1}
                      </button>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    />
  );
};

const CustomForm = props => {
  const { values, errors, touched, setFieldValue, readOnly } = props;

  const identificationDocs = values.identificationDocs || {};

  const identificationDocsTouched = touched.identificationDocs || {};
  const identificationDocsErrors = errors.identificationDocs || {};

  const names = [];
  if (values.firstName) {
    names.push(values.firstName);
  }

  if (values.lastName) {
    names.push(values.lastName);
  }

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <Form>
      <div className="card edit-page-container mb-5">
        <div className="card-header border-bottom-0 pt-3">
          <span className="edit-page-title">
            <Link
              to={`${props.previousLocation}?from=/admin/individuals/edit/${
                props.individual._id
              }&incProfUrl=${props.incProfUrl}`}
              className="mr-2"
            >
              <i className="fas fa-arrow-left" />
            </Link>
            {names.join(" ") || "Individual"}
          </span>
          <span className="float-right edit-page-status ml-2">{`Individual`}</span>
        </div>
        <div className="card-body p-0 mt-2">
          {/* Basic Information */}
          <div className="card border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Basic Information </h5>
            </div>

            <div className="card-body pb-0">
              <div className="row mb-2">
                {/* First Name */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                      First Name
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "firstName")
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="First Name"
                        name="firstName"
                        value={values.firstName || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="firstName"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Last Name */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Last Name
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "lastName")
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Last Name"
                        name="lastName"
                        value={values.lastName || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="lastName"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Gender */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Gender
                    </label>
                    <div className="col-lg-6">
                      <Field
                        component="select"
                        className={`custom-select custom-select-sm ${
                          getError(touched, errors, "gender")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="gender"
                        values={values.gender || ""}
                        {...disabledField}
                      >
                        <option value="">Select...</option>
                        {GENDERS.map((g, i) => (
                          <option key={i} value={g.value}>
                            {g.label}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        component="div"
                        name="gender"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Date of Birth */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      Date of Birth
                    </label>
                    <div className="col-lg-6">
                      <DatePicker
                        value={getDisplayDate(values.dob) || ""}
                        selected={values.dob || null}
                        onChange={date => {
                          if (date) {
                            setFieldValue("dob", date);
                          }
                        }}
                        utcOffset={0}
                        maxDate={new Date()}
                        placeholderText="dd-mm-yyyy"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "dob") ? "is-invalid" : ""
                        }`}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        disabled={readOnly}
                      />
                      <ErrorMessage
                        component="div"
                        name="dob"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* Experience */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label required">
                      Experience
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "experience")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="experience"
                        values={values.experience || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="experience"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>

                {/* References */}
                <div className="col-lg-6 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-4 col-form-label col-form-label-sm edit-page-label">
                      References
                    </label>
                    <div className="col-lg-6">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(touched, errors, "references")
                            ? "is-invalid"
                            : ""
                        }`}
                        name="references"
                        values={values.references || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="references"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Photo */}
              <div className="row mb-2">
                <div className="col-lg-12 mb-2">
                  <div className="form-group row mb-0">
                    <label className="col-lg-2 col-form-label col-form-label-sm edit-page-label mt-1">
                      Photo
                    </label>
                    <div className="col-lg-6">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="uploadIndvPhoto"
                            aria-describedby="uploadIndvPhoto"
                            name="uploadIndvPhoto"
                            onChange={e => {
                              const file = e.target.files[0];
                              if (file) {
                                setFieldValue("uploadIndvPhoto", file);
                              }
                            }}
                            {...disabledField}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="uploadIndvPhoto"
                          >
                            {values.uploadIndvPhoto
                              ? values.uploadIndvPhoto.name
                              : "Choose file"}
                          </label>
                        </div>
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-outline-info"
                            type="button"
                            onClick={e => setFieldValue("uploadIndvPhoto", "")}
                            {...disabledField}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      {values.photo && values.photo._id ? (
                        <button
                          type="button"
                          className="btn btn-info btn-sm mt-1"
                          onClick={props.downloadDocument.bind(
                            this,
                            `photo/${values.photo._id}`
                          )}
                        >
                          <i className="fas fa-download mr-1" /> Photo
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Education */}
          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Education </h5>
            </div>
            <div className="card-body">
              <Education {...props} />
            </div>
          </div>

          {/* Primary Contact Information */}
          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">
                Primary Contact Information
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                {/* Mobile */}
                <div className="col-12 col-lg-6 col-xl-6">
                  <Phones {...props} />
                </div>

                {/* Email */}
                <div className="col-12 col-lg-6 col-xl-6">
                  <Emails {...props} />
                </div>
              </div>
            </div>
          </div>

          {/* Address Information */}
          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Addresses</h5>
            </div>
            <div className="card-body">
              <Addresses {...props} />
            </div>
          </div>

          <div className="card mt-5 border-0">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Identification Documents</h5>
            </div>
            <div className="card-body">
              {/* Pan */}
              <div className="row mb-2">
                <div className="col-lg-4">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-lg-4 col-xl-4 edit-page-label required">
                      Pan
                    </label>
                    <div className="col-lg-8 col-xl-8">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(
                            identificationDocsTouched,
                            identificationDocsErrors,
                            "panNumber"
                          )
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Pan Number"
                        name="identificationDocs.panNumber"
                        value={identificationDocs.panNumber || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="identificationDocs.panNumber"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-lg-4 edit-page-label">
                      Pan Scanned Copy
                    </label>
                    <div className="col-lg-7">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="identificationDocs.uploadPan"
                            name="identificationDocs.uploadPan"
                            onChange={e => {
                              const file = e.target.files[0];
                              if (file) {
                                setFieldValue(
                                  "identificationDocs.uploadPan",
                                  file
                                );
                              }
                            }}
                            {...disabledField}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="identificationDocs.uploadPan"
                          >
                            {identificationDocs.uploadPan
                              ? identificationDocs.uploadPan.name
                              : "Choose file"}
                          </label>
                        </div>
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-outline-info"
                            type="button"
                            onClick={e =>
                              setFieldValue("identificationDocs.uploadPan", "")
                            }
                            {...disabledField}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="row mb-0">
                    <div className="col-12">
                      {identificationDocs.pan ? (
                        <button
                          className="btn btn-sm btn-info"
                          type="button"
                          onClick={() => {
                            const docId = identificationDocs.pan._id;
                            props.downloadDocument(`id-doc/PAN/${docId}`);
                          }}
                        >
                          <i className="fas fa-download mr-1" /> Pan copy
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* Aadhaar */}
              <div className="row mb-2">
                <div className="col-lg-4">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-lg-4 col-xl-4 edit-page-label">
                      Aadhaar
                    </label>
                    <div className="col-lg-8 col-xl-8">
                      <Field
                        type="text"
                        className={`form-control form-control-sm ${
                          getError(
                            identificationDocsTouched,
                            identificationDocsErrors,
                            "aadhaarNumber"
                          )
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Aadhaar Number"
                        name="identificationDocs.aadhaarNumber"
                        value={identificationDocs.aadhaarNumber || ""}
                        {...disabledField}
                      />
                      <ErrorMessage
                        component="div"
                        name="identificationDocs.aadhaarNumber"
                        className="invalid-feedback mb-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-lg-4 edit-page-label">
                      Aadhaar Scanned Copy
                    </label>
                    <div className="col-lg-7">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="identificationDocs.uploadAadhaar"
                            aria-describedby="identificationDocs.uploadAadhaar"
                            name="identificationDocs.uploadAadhaar"
                            onChange={e => {
                              const file = e.target.files[0];
                              if (file) {
                                setFieldValue(
                                  "identificationDocs.uploadAadhaar",
                                  file
                                );
                              }
                            }}
                            {...disabledField}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="identificationDocs.uploadAadhaar"
                          >
                            {identificationDocs.uploadAadhaar
                              ? identificationDocs.uploadAadhaar.name
                              : "Choose file"}
                          </label>
                        </div>
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-outline-info"
                            type="button"
                            onClick={e =>
                              setFieldValue(
                                "identificationDocs.uploadAadhaar",
                                ""
                              )
                            }
                            {...disabledField}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="row mb-0">
                    <div className="col-12">
                      {identificationDocs.aadhaar ? (
                        <button
                          className="btn btn-sm btn-info"
                          type="button"
                          onClick={() => {
                            const docId = identificationDocs.aadhaar._id;
                            props.downloadDocument(`id-doc/AADHAAR/${docId}`);
                          }}
                        >
                          <i className="fas fa-download mr-1" /> Aadhaar copy
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-muted mt-5 p-4">
          {/* Update button */}
          <div className="row no-gutters">
            <div className="col-12 text-center">
              {readOnly ? null : (
                <Can do="UPDATE" on="Individual">
                  <button
                    className="btn btn-sm btn-primary ml-md-5"
                    type="submit"
                  >
                    Update
                  </button>
                </Can>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const IndividualForm = props => {
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={props.individual}
        validationSchema={INDIVIDUAL_SCHEMA}
        onSubmit={(values, { setSubmitting }) => {
          props.handleSubmit(values);
        }}
        render={renderProps => <CustomForm {...renderProps} {...props} />}
      />
    </div>
  );
};

export default IndividualForm;
