import React, { Component, Fragment } from "react";
import { convertDateToNumber } from "../../lease/DateUtil";

import PaymentPreviewForm from "./PaymentPreviewForm";
import { PAYEE_TYPES_OBJECT } from "../../common/LookupConstants";

class PaymentConfirmForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewForm: null
    };
  }

  addData = (payment, labelName, value) => {
    if (value) {
      payment.push({
        label: labelName,
        value
      });
    }
  };

  componentDidMount() {
    const previewForm = { ...this.props.payment };
    previewForm.entityName =
      this.props.displayName || this.props.displayName || null;
    const entityType = PAYEE_TYPES_OBJECT[this.props.payment.payeeType];
    previewForm.entityType = entityType;
    previewForm.paymentDate = convertDateToNumber(
      this.props.payment.paymentDate
    );
    previewForm.chequeDate = convertDateToNumber(this.props.payment.chequeDate);

    if (this.props.payment.paymentTowards) {
      previewForm.paymentTowards = this.props.payment.paymentTowards.map(pt => {
        return { description: pt.label };
      });
    }

    this.setState({ previewForm });
  }
  render() {
    const { handleEditForm, handleSubmit } = this.props;
    if (this.state.previewForm === null) {
      return null;
    }

    return (
      <Fragment>
        <PaymentPreviewForm
          data={this.state.previewForm}
          title="Payment Confirmation"
        />
        <div className="form-group row mt-3 mb-0">
          <div className="col-12 text-center">
            <button className="btn btn-primary btn-sm" onClick={handleEditForm}>
              Edit
            </button>
            &nbsp;
            <button className="btn btn-primary btn-sm" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PaymentConfirmForm;
