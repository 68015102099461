import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";

import { INCUBATEES_API, COUNTRIES_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";
import {
  getDisplayDate,
  convertDateToNumber,
  convertNumberToDate
} from "../lease/DateUtil";
import { IncubateeProfileForm } from "./IncubateeProfileForm";
import ErrorPage from "../common/error.page";

const checkEmptyValue = (inputError, fieldName, value) => {
  if (value === undefined || value === "" || value === null) {
    inputError[fieldName] = true;
    return true;
  }
  return false;
};

const renderInputData = data => {
  return data ? data : "";
};

class EditIncubateeProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // For input validations
      validate: false,
      toBeValidated: [],
      hasError: false,
      update: false,
      updatedProfile: {},

      // For All Component
      _id: null,
      incubateeName: null,
      incubateeProfile: null,
      patents: [],
      grants: [],
      metrics: [],
      newIncubatee: null,
      previousLocation: "",
      permanentFailure: false
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  // if the set state is called directly,
  // then toBeValidated Array data gets overwritten
  // Passing function queues up the set state
  // and they are called in the same order they are called.
  // https://medium.freecodecamp.org/functional-setstate-is-the-future-of-react-374f30401b6b

  setStateValidateResult = (childName, childInvalid, data) => {
    this.setState(state => {
      const toBeValidated = state.toBeValidated.filter(
        item => item !== childName
      );
      const validationComplete = toBeValidated.length === 0;

      const hasError = state.hasError || childInvalid;
      const update = !hasError && validationComplete;

      const updatedProfile = state.updatedProfile;
      updatedProfile[childName] = data;

      let errorState = {};
      if (validationComplete && hasError) {
        errorState = {
          alertType: "Default",
          showAlert: true,
          alertColor: "danger",
          alertMessage: "Please correct the errors and submit again"
        };
        window.scrollTo(0, 0);
      }

      return {
        validate: !validationComplete,
        hasError,
        toBeValidated,
        update,
        updatedProfile,
        ...errorState
      };
    });
  };

  onValidate = (error, childName, data) => {
    if (error === null) {
      this.setStateValidateResult(childName, false, data);
    } else {
      this.setStateValidateResult(childName, true, null);
      window.scrollTo(0, 0);
    }
  };

  setDataFromServer = data => {
    const relMagr = data.relationshipManager || {};
    const names = [];
    if (relMagr.firstName) {
      names.push(relMagr.firstName);
    }
    if (relMagr.lastName) {
      names.push(relMagr.lastName);
    }

    const relationshipManager = {
      label: names.join(" "),
      value: relMagr._id
    };
    const incubateeProfile = {
      businessPlan: data.businessPlan,
      graduationStatus: data.graduationStatus,
      incubateeStage: data.incubateeStage,
      incubationSector: data.incubationSector,
      subTechSector: data.subTechSector,
      targetSector: data.targetSector,
      businessModel: data.businessModel,
      residentialStatus: data.residentialStatus,
      incubationCategory: data.incubationCategory,
      anchorIncubator: data.anchorIncubator,
      supplIncubators: data.supplIncubators,
      about: data.about,
      relationshipManager
    };

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const from = params.get("from");

    this.setState({
      _id: data._id,
      incubateeName: data.name,
      incubateeType: data.incubateeType,
      organization: data.organization,
      incubateeProfile: incubateeProfile,

      patents: data.patents ? data.patents : [],
      grants: data.grants ? data.grants : [],
      metrics: data.metrics ? data.metrics : [],
      newIncubatee: false,
      previousLocation: from
    });
  };

  getAllStateData = () => {
    const { updatedProfile } = this.state;
    const incubateeProfile = updatedProfile.incubateeProfile;
    const relationshipManager = incubateeProfile.relationshipManager || {};

    const updateRequest = {
      businessPlan: incubateeProfile.businessPlan,
      graduationStatus: incubateeProfile.graduationStatus,
      incubateeStage: incubateeProfile.incubateeStage,
      incubationSector: incubateeProfile.incubationSector,
      subTechSector: incubateeProfile.subTechSector,
      targetSector: incubateeProfile.targetSector,
      businessModel: incubateeProfile.businessModel,
      residentialStatus: incubateeProfile.residentialStatus,
      incubationCategory: incubateeProfile.incubationCategory,
      anchorIncubator: incubateeProfile.anchorIncubator,
      supplIncubators: incubateeProfile.supplIncubators,
      about: incubateeProfile.about,
      patents: updatedProfile.patents,
      grants: updatedProfile.grants,
      metrics: updatedProfile.metrics,
      relationshipManager: relationshipManager.value || ""
    };

    return updateRequest;
  };

  callUpdateApi = () => {
    const updateRequest = this.getAllStateData();

    const headers = { "Content-Type": "application/json" };

    const url = `${INCUBATEES_API}/${this.state._id}`;

    HttpUtil.put(
      url,
      headers,
      updateRequest,
      data => {
        this.setDataFromServer(data);

        this.setState({
          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: ` ${data.name} profile has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );

    this.setState({ update: false, updatedProfile: {} });
  };

  handleUpdate = () => {
    this.setState({
      validate: true,
      hasError: false,
      toBeValidated: ["incubateeProfile", "patents", "metrics"],
      updatedProfile: {}
    });
  };

  getIncubateeById = id => {
    const url = INCUBATEES_API + "/" + id;

    HttpUtil.get(
      url,
      {},
      data => this.setDataFromServer(data),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getIncubateeById(id);
    } else {
      this.setState({ newIncubatee: true });
    }
  }

  componentDidUpdate = prevState => {
    if (this.state.update !== prevState.update && this.state.update) {
      this.callUpdateApi();
    }
  };
  render() {
    const {
      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      newIncubatee,
      incubateeName,
      permanentFailure
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (newIncubatee === null) {
      return null;
    }

    const props = {
      onValidate: this.onValidate,
      validate: this.state.validate,

      // state objects
      incubateeProfile: this.state.incubateeProfile,
      patents: this.state.patents,
      grants: this.state.grants,
      metrics: this.state.metrics,
      previousLocation: this.state.previousLocation,

      // common events
      handleApiFailed: this.handleApiFailed
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <h5>{incubateeName}</h5>
          </div>
        </div>

        {/* Incubatee Profile */}
        <IncubateeProfileForm {...props} />

        {/* Patents */}
        <Patents {...props} />

        {/* Metrics */}
        <Metrics {...props} />

        <div className="row mt-5 mb-5">
          <div className="col-12">
            <button
              className="btn btn-sm btn-primary"
              onClick={this.handleUpdate}
            >
              Update Changes
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditIncubateeProfileForm;
/*++++++++++++++++++++ EditIncubateeProfileForm End ++++++++++++++++++++++++++ */

/*++++++++++++++++++++ Patents Start ++++++++++++++++++++++++++ */
export class Patents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patents: [],
      inputError: [],
      countries: []
    };
  }

  addNewPatent = () => {
    const patent = {
      country: null,
      applicationNumber: null,
      applicationFilingDate: null,
      fieldOfInvention: null,
      classificationCode: null,
      patentStatus: null
    };
    return patent;
  };
  addMorePatent = () => {
    const newPatent = this.addNewPatent();
    const patents = this.state.patents;
    if (patents.length > 0) {
      let patent = patents[patents.length - 1];
      const haveValues = Object.values(patent).some(item => item);
      if (!haveValues) {
        return;
      }
    }
    patents.push(newPatent);
    this.setState({ patents });
  };

  handleClear = () => {
    const patents = this.state.patents;
    const lstIndex = patents.length - 1;
    let patent = patents[lstIndex];
    patent.country = null;
    patent.applicationNumber = null;
    patent.applicationFilingDate = null;
    patent.fieldOfInvention = null;
    patent.classificationCode = null;
    patent.patentStatus = null;
    patents[lstIndex] = patent;
    this.setState({ patents });
  };

  removePatent = (index, e) => {
    e.preventDefault();
    let patents = [...this.state.patents];
    if (patents.length === 0) return;
    patents = patents.filter((pat, idx) => idx !== index);
    this.setStatePatents(patents);
  };

  handleInputChange = (index, e) => {
    e.preventDefault();
    const targetName = e.target.name;
    const targetValue = e.target.value;
    let patents = [...this.state.patents];
    let patent = patents[index];
    patent[targetName] = targetValue;
    patents[index] = patent;
    this.setStatePatents(patents);
  };

  renderInputData = data => {
    return data ? data : "";
  };

  handleDateChange = (index, targetName, date) => {
    let patents = [...this.state.patents];
    let patent = patents[index];
    patent[targetName] = convertDateToNumber(date);
    patents[index] = patent;
    this.setStatePatents(patents);
  };

  setStatePatents = patents => {
    this.setState({ patents });
  };

  removeEmptyRow = patents => {
    let newArray = [...patents];
    if (newArray.length > 0) {
      const lstIndex = newArray.length - 1;
      let patent = newArray[lstIndex];
      const haveValues = Object.values(patent).some(item => item);
      if (!haveValues) {
        newArray.splice(lstIndex, 1);
      }
    }
    return newArray;
  };

  validate = () => {
    let inputError = [];
    const patents = [...this.state.patents];
    let hasError = false;

    patents.forEach((pt, index) => {
      inputError[index] = {};
      if (pt.country || pt.applicationNumber || pt.applicationFilingDate) {
        checkEmptyValue(inputError[index], "country", pt.country);
        checkEmptyValue(
          inputError[index],
          "applicationNumber",
          pt.applicationNumber
        );
        checkEmptyValue(
          inputError[index],
          "applicationFilingDate",
          pt.applicationFilingDate
        );
      }

      hasError = hasError || Object.keys(inputError[index]).length > 0;
    });

    if (hasError) {
      this.props.onValidate(inputError, "patents", null);
    } else {
      const updatedPatents = this.removeEmptyRow(patents);
      this.props.onValidate(null, "patents", updatedPatents);
      inputError = [];
    }

    this.setState({ inputError });
  };

  loadStateFromProps = () => {
    const { patents } = this.props;
    if (patents && patents.length > 0) {
      this.setState({ patents: [...patents] });
      return;
    }

    if (this.state.patents.length === 0) {
      this.addMorePatent();
    }
  };

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount() {
    this.getCountries();
    this.loadStateFromProps();
  }

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    if (this.props.patents !== prevState.patents) {
      this.loadStateFromProps();
    }
  };

  render() {
    const { patents, inputError, countries } = this.state;

    const patentList = patents.map((patent, index) => {
      const hideRemoveBtn = patents.length === 1;
      return (
        <tr key={index}>
          <td>
            <select
              className={
                inputError[index] && inputError[index]["country"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm "
              }
              name="country"
              value={this.renderInputData(patent.country)}
              onChange={this.handleInputChange.bind(this, index)}
            >
              <option value="">Select...</option>
              {countries.map((country, i) => (
                <option key={i} value={country.countryCode}>
                  {country.name}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">Please select country *</div>
          </td>
          <td>
            <input
              type="text"
              className={
                inputError[index] && inputError[index]["applicationNumber"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm "
              }
              name="applicationNumber"
              value={this.renderInputData(patent.applicationNumber)}
              onChange={this.handleInputChange.bind(this, index)}
            />
            <div className="invalid-feedback">
              Please enter application number *
            </div>
          </td>
          <td>
            <DatePicker
              value={getDisplayDate(
                convertNumberToDate(patent.applicationFilingDate)
              )}
              selected={
                patent.applicationFilingDate
                  ? convertNumberToDate(patent.applicationFilingDate)
                  : null
              }
              onChange={this.handleDateChange.bind(
                this,
                index,
                "applicationFilingDate"
              )}
              utcOffset={0}
              placeholderText="dd-mm-yyyy"
              className={
                inputError[index] && inputError[index]["applicationFilingDate"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm "
              }
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {inputError[index] && inputError[index]["applicationFilingDate"] ? (
              <div className="auto-lookup-empty">
                Please select application filing date *.
              </div>
            ) : null}
          </td>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Field Invention"
              name="fieldOfInvention"
              value={this.renderInputData(patent.fieldOfInvention)}
              onChange={this.handleInputChange.bind(this, index)}
            />
          </td>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="IPC"
              name="classificationCode"
              value={this.renderInputData(patent.classificationCode)}
              onChange={this.handleInputChange.bind(this, index)}
            />
          </td>
          <td>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Patent Status"
              name="patentStatus"
              value={this.renderInputData(patent.patentStatus)}
              onChange={this.handleInputChange.bind(this, index)}
            />
          </td>
          <td className="align-middle">
            {hideRemoveBtn ? null : (
              <i
                className="far fa-trash-alt text-danger"
                data-toggle="tooltip"
                data-placement="top"
                title="Remove"
                style={{ cursor: "pointer" }}
                onClick={this.removePatent.bind(this, index)}
              />
            )}
          </td>
        </tr>
      );
    });

    return (
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title   mb-0">Patents</h5>
            </div>

            <div className="card-body">
              <div className="table-responsive-xs table-responsive-sm table-responsive-md">
                <table className="table table-sm table-striped table-borderless mb-0">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        style={{ width: "16.66%" }}
                        scope="col"
                      >
                        Country
                      </th>
                      <th
                        className="text-center"
                        style={{ width: "16.66%" }}
                        scope="col"
                      >
                        Application Number
                      </th>
                      <th
                        className="text-center"
                        style={{ width: "16.66%" }}
                        scope="col"
                      >
                        Filing Date
                      </th>
                      <th
                        className="text-center"
                        style={{ width: "16.66%" }}
                        scope="col"
                      >
                        Field Of Invention
                      </th>
                      <th
                        className="text-center"
                        style={{ width: "16.66%" }}
                        scope="col"
                      >
                        Classification (IPC)
                      </th>
                      <th
                        className="text-center"
                        style={{ width: "16.66%" }}
                        scope="col"
                      >
                        Status
                      </th>
                      <th
                        className="text-center"
                        style={{ width: "16.66%" }}
                        scope="col"
                      />
                    </tr>
                  </thead>
                  <tbody>{patentList}</tbody>
                </table>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <button
                    className="btn btn-sm btn-info"
                    onClick={this.addMorePatent}
                  >
                    Add More
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-sm btn-outline-info"
                    onClick={this.handleClear}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*++++++++++++++++++++ Patents End ++++++++++++++++++++++++++ */

/*++++++++++++++++++++ Metrics Start ++++++++++++++++++++++++++ */
export class Metrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metrics: [],
      employeeCount: null,
      femaleEmployeeCount: 0,
      inputError: {}
    };
  }

  handleInputChange = e => {
    const targetName = e.target.name;
    if (isNaN(e.target.value)) {
      return;
    }

    let targetValue =
      e.target.value !== "" || e.target.value !== 0 ? e.target.value : null;
    this.setState({ [targetName]: Number(targetValue) });
  };

  validate = () => {
    const { employeeCount, femaleEmployeeCount } = this.state;
    const metrics = [...this.state.metrics];

    let hasError = false;
    let inputError = {};
    const employeeCountEmpty = checkEmptyValue(
      inputError,
      "employeeCount",
      employeeCount
    );
    const femaleEmployeeCountEmpty = checkEmptyValue(
      inputError,
      "femaleEmployeeCount",
      femaleEmployeeCount
    );

    // No data is entered in the fields. Just send the current values
    if (employeeCountEmpty && femaleEmployeeCountEmpty) {
      this.props.onValidate(null, "metrics", metrics);
      return;
    }

    if (femaleEmployeeCount < 0) {
      inputError.femaleEmployeeCount =
        "Female employee count shouldn't be in negative.";
      hasError = true;
    }

    if (employeeCount < 0) {
      inputError.employeeCount = "Employee count shouldn't be in negative.";
      hasError = true;
    }

    if (employeeCount < femaleEmployeeCount) {
      inputError.femaleEmployeeCount =
        "Female employee count shouldn't more than total employee count.";
      hasError = true;
    }

    if (hasError) {
      this.props.onValidate(inputError, "metrics", null);
      this.setState({ inputError });
      return;
    } else {
      const newMetrics = [];
      // To "avoid employeeCount : null , femaleEmployeeCount : null" records from db.
      for (let i = 0; i < metrics.length; i++) {
        if (metrics[i].employeeCount && metrics[i].femaleEmployeeCount) {
          const metric = metrics[i];
          newMetrics.push(metric);
        }
      }

      const finance = {
        date: new Date(),
        employeeCount: employeeCount,
        femaleEmployeeCount: femaleEmployeeCount
      };
      if (employeeCount > 0 && femaleEmployeeCount > 0) {
        newMetrics.push(finance);
      }

      this.props.onValidate(null, "metrics", newMetrics);
      inputError = {};
    }

    this.setState({ inputError });
  };

  loadStateFromProps = () => {
    const { newRecord, metrics } = this.props;

    // props 'metrics' not null or not empty then add to local state.
    if (!newRecord && metrics && metrics.length > 0) {
      this.setState({
        metrics: [...metrics],
        employeeCount: null,
        femaleEmployeeCount: null
      });
      return;
    }
  };

  componentDidMount() {
    this.loadStateFromProps();
  }

  componentDidUpdate = prevState => {
    if (this.props.validate !== prevState.validate && this.props.validate) {
      this.validate();
    }

    const metrics = this.props.metrics;
    if (metrics !== prevState.metrics) {
      this.loadStateFromProps();
    }
  };

  render() {
    const {
      metrics,
      employeeCount,
      femaleEmployeeCount,
      inputError
    } = this.state;

    const metricList = metrics.map((mtr, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{mtr.employeeCount}</td>
          <td className="text-center">{mtr.femaleEmployeeCount}</td>
          <td className="text-center w-25">{getDisplayDate(mtr.date)}</td>
        </tr>
      );
    });

    const employeeCountMsg = inputError.employeeCount || "";
    const femaleEmployeeCountMsg = inputError.femaleEmployeeCount || "";
    return (
      <div className="row mt-4">
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="card flex-fill">
            <div className="edit-page-section-header-text">
              <h6 className="card-titile mb-0">Metrics Information</h6>
            </div>

            <div className="row ">
              <div className="col-sm-12">
                <div className="table-responsive">
                  <table className="table table-sm table-striped mb-0">
                    <thead>
                      <tr>
                        <th className="text-center" scope="col">
                          Total Employee
                        </th>
                        <th className="text-center" scope="col">
                          Female Employee
                        </th>
                        <th className="text-center w-25" scope="col">
                          Updated At
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {metricList}
                      <tr>
                        <td>
                          <input
                            type="number"
                            className={
                              employeeCountMsg
                                ? "form-control form-control-sm text-right is-invalid"
                                : "form-control form-control-sm text-right"
                            }
                            placeholder="0"
                            name="employeeCount"
                            value={renderInputData(employeeCount)}
                            onChange={this.handleInputChange}
                          />
                          <div className="invalid-feedback">
                            {employeeCountMsg}
                          </div>
                        </td>
                        <td>
                          <input
                            type="number"
                            className={
                              femaleEmployeeCountMsg
                                ? "form-control form-control-sm text-right is-invalid"
                                : "form-control form-control-sm text-right"
                            }
                            placeholder="0"
                            name="femaleEmployeeCount"
                            value={renderInputData(femaleEmployeeCount)}
                            onChange={this.handleInputChange}
                          />
                          <div className="invalid-feedback">
                            {femaleEmployeeCountMsg}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*++++++++++++++++++++ Metrics End ++++++++++++++++++++++++++ */
