import React, { Component, Fragment } from "react";
import { renderInputData } from "../../common/LookupConstants";

const getFinancialYears = () => {
  let year = new Date().getFullYear();
  const fy = [];
  for (let indx = 0; indx < 2; indx++) {
    fy.push(
      `${year - indx - 1}-${((year - indx) % 100).toString().padStart(2, "0")}`
    );
  }
  return fy;
};

const fYearMapper = getFinancialYears();

class LeaseRenewForm extends Component {
  renderEmployeeForm = props => {
    const { inputError, handleCountChange } = props;
    const { totalEmployee, femaleEmployee } = props.leaseRenewForm;
    return (
      <Fragment>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-5 col-xl-5 edit-page-label">
            Total Employee Count (<strong>excluding promoters </strong>)
          </label>
          <div className="col-12 col-lg-2 col-xl-2">
            <input
              type="number"
              name="totalEmployee"
              value={renderInputData(totalEmployee)}
              onChange={handleCountChange}
              className={
                inputError["totalEmployee"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm"
              }
            />
            <div className="invalid-feedback">
              Total employee count is required
            </div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-5 col-xl-5 edit-page-label">
            Female Employee Count (<strong>excluding promoters </strong>)
          </label>
          <div className="col-12 col-lg-2 col-xl-2">
            <input
              type="number"
              name="femaleEmployee"
              value={renderInputData(femaleEmployee)}
              onChange={handleCountChange}
              className={
                inputError["femaleEmployeeCountMsg"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm"
              }
            />
            <div className="invalid-feedback">
              {inputError.femaleEmployeeCountMsg
                ? inputError.femaleEmployeeCountMsg
                : null}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  renderFinanceForm = props => {
    const { inputError, handleCountChange, handleInputChange } = props;
    const { annualTurnover, annualTurnoverFy } = props.leaseRenewForm;
    return (
      <Fragment>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-5 col-xl-5 edit-page-label">
            Annual Turnover
          </label>
          <div className="col-12 col-lg-2 col-xl-2">
            <input
              type="number"
              name="annualTurnover"
              value={renderInputData(annualTurnover)}
              onChange={handleCountChange}
              className={
                inputError["annualTurnover"]
                  ? "form-control form-control-sm is-invalid"
                  : "form-control form-control-sm"
              }
            />
            <div className="invalid-feedback">Annual turnover is required</div>
          </div>
        </div>
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-5 col-xl-5 edit-page-label">
            Financial Year
          </label>
          <div className="col-12 col-lg-2 col-xl-2">
            <select
              className={
                inputError["annualTurnoverFy"]
                  ? "custom-select custom-select-sm is-invalid"
                  : "custom-select custom-select-sm "
              }
              name="annualTurnoverFy"
              value={renderInputData(annualTurnoverFy)}
              onChange={handleInputChange}
            >
              <option value="">Select...</option>
              {fYearMapper.map(yyyy => (
                <option key={yyyy} value={yyyy}>
                  {yyyy}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              Please select financial year *.
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const {
      renewalMetrics,
      empInfo,
      finInfo,
      inputError,
      handleRenewalInputChange,
      handleRenewalRadioBtn,
      handleRenewalCountChange
    } = this.props;

    const props = {
      leaseRenewForm: renewalMetrics,
      inputError: inputError,

      // events
      handleInputChange: handleRenewalInputChange,
      handleCountChange: handleRenewalCountChange,
      handleRadioBtn: handleRenewalRadioBtn
    };

    const EMP_FORM = {
      true: this.renderEmployeeForm(props),
      false: null
    };

    const FIN_FORM = {
      true: this.renderFinanceForm(props),
      false: null
    };

    const render_emp_form = EMP_FORM[empInfo];
    const render_fin_form = FIN_FORM[finInfo];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12 mb-3 edit-page-section-header-text">
            Renewal Additional Information
          </div>
        </div>

        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-5 col-xl-5 edit-page-label">
            Do you have any employee's ?
          </label>
          <div className="col-12 col-lg-3 col-xl-3">
            <div
              className={`custom-radios ${
                inputError["empInfo"] ? "is-invalid" : ""
              }`}
            >
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline1"
                  name="empInfo"
                  className={`custom-control-input ${
                    inputError["empInfo"] ? "is-invalid" : ""
                  }`}
                  checked={empInfo === true}
                  onChange={handleRenewalRadioBtn.bind(this, true)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline1"
                >
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline2"
                  name="empInfo"
                  checked={empInfo === false}
                  className={`custom-control-input ${
                    inputError["empInfo"] ? "is-invalid" : ""
                  }`}
                  onChange={handleRenewalRadioBtn.bind(this, false)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline2"
                >
                  No
                </label>
              </div>
            </div>
            {inputError["empInfo"] ? (
              <div className="invalid-feedback">
                Please select required field
              </div>
            ) : null}
          </div>
        </div>
        {render_emp_form}
        <hr />
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-5 col-xl-5 edit-page-label">
            Do you have any revenue during last two financial years ?
          </label>
          <div className="col-12 col-lg-3 col-xl-3">
            <div
              className={`custom-radios ${
                inputError["finInfo"] ? "is-invalid" : ""
              }`}
            >
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline3"
                  className={`custom-control-input ${
                    inputError["finInfo"] ? "is-invalid" : ""
                  }`}
                  name="finInfo"
                  checked={finInfo === true}
                  onChange={handleRenewalRadioBtn.bind(this, true)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline3"
                >
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline4"
                  className={`custom-control-input ${
                    inputError["finInfo"] ? "is-invalid" : ""
                  }`}
                  name="finInfo"
                  checked={finInfo === false}
                  onChange={handleRenewalRadioBtn.bind(this, false)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline4"
                >
                  No
                </label>
              </div>
            </div>
            {inputError["finInfo"] ? (
              <div className="invalid-feedback">
                Please select required field
              </div>
            ) : null}
          </div>
        </div>
        <div className="mb-5">{render_fin_form}</div>
      </Fragment>
    );
  }
}

export default LeaseRenewForm;
