import React, { Component, Fragment } from "react";
import ErrorPage from "../../common/error.page";

import {
  BUILDING_API,
  STATUS_LIST_API,
  AREA_UNIT_LIST_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import DefaultAlert from "../DefaultAlert";
import Building from "./Building";
import Breadcrumb from "../../common/Breadcrumb";
import BuildingBasedFloorList from "./BuildingBasedFloorList";
class EditBuilding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //building
      buildingName: "",
      totalFloors: "",
      status: "ACTIVE",
      areaUnit: 0,
      areaPrice: 0,
      seaterPrice: 0,

      breadCrumbList: [],

      floors: [],
      usableArea: 0,
      totalArea: 0,

      //for input error alert
      inputError: false,

      //Default alert
      showAlert: false,
      alertMode: "",
      alertMessage: "",
      permanentFailure: false,

      // auto lookup
      campus: "",

      // for selector
      statusList: [],
      areaUnitList: []
    };
  }

  componentDidMount = () => {
    this.getStatusList();
    this.getAreaUnitList();

    let currentUrl = window.location.href;
    currentUrl = currentUrl.split("edit/");

    let id = currentUrl[1];
    this.getBuildingById(id);
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleApiSuccess = (data, flag) => {
    let usableArea = 0;
    let totalArea = 0;

    let floors = data.floors || [];

    floors.forEach(floor => {
      usableArea += floor.usableArea;
      totalArea += floor.totalArea;
    });

    if (flag === "GET_BY_ID_API") {
      this.setState({
        //building
        _id: data._id,
        buildingName: data.buildingName,
        campus: data.campus.name,
        campusId: data.campus._id,
        totalFloors: data.totalFloors,
        status: data.status,
        areaUnit: data.areaUnit,
        areaPrice: data.areaPrice,
        seaterPrice: data.seaterPrice,
        floors: data.floors ? data.floors : [],
        usableArea,
        totalArea
      });
    } else if (flag === "UPDATE_API") {
      this.setState({
        //building
        _id: data._id,
        buildingName: data.buildingName,
        campus: data.campus.name,
        campusId: data.campus._id,
        totalFloors: data.totalFloors,
        status: data.status,
        areaUnit: data.areaUnit,
        areaPrice: data.areaPrice,
        seaterPrice: data.seaterPrice,
        floors: data.floors ? data.floors : [],
        usableArea,
        totalArea,
        // false
        inputError: false,
        showAlert: true,
        alertMode: "success",
        alertMessage: `Building "${data.buildingName}" has been updated successfully.`
      });
    }

    if (flag === "STATUS_LIST") {
      this.setState({ statusList: data });
    }

    if (flag === "AREA_UNIT_LIST") {
      this.setState({ areaUnitList: data });
    }
  };

  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      showAlert: true,
      alertMode: "danger",
      alertMessage: message,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  getBuildingById = id => {
    const url = BUILDING_API + "/" + id;
    const authToken = this.props.authToken;

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: authToken
    };

    let showAlert,
      alertMode,
      alertMessage = null;

    if (this.props.location.state && this.props.location.state.created) {
      showAlert = true;
      alertMode = "success";
      alertMessage = "New Building has been added successfully.";
    }

    HttpUtil.get(
      url,
      headers,
      data => {
        const breadCrumbList = [];

        let campusName = data.campus.name;
        let campusId = data.campus._id;
        let bctCampus = {
          displayName: campusName,
          linkTo: `/admin/campuses/edit/${campusId}`
        };
        breadCrumbList.push(bctCampus);

        let bctBuilding = {
          displayName: data.buildingName,
          linkTo: null
        };
        breadCrumbList.push(bctBuilding);
        this.handleApiSuccess(data, "GET_BY_ID_API");
        this.setState({ breadCrumbList, showAlert, alertMode, alertMessage });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  validation = () => {
    let inputError = {};
    let valid = true;
    const { buildingName, campus, totalFloors } = this.state;
    if (buildingName === "") {
      valid = false;
      inputError["buildingName"] = true;
    }

    if (campus === "") {
      valid = false;
      inputError["campusName"] = true;
    }

    if (totalFloors === "") {
      valid = false;
      inputError["totalFloors"] = true;
    }

    if (!valid) {
      return this.setState({
        inputError: { ...inputError }
      });
    }
    return valid;
  };

  updateBuilding = event => {
    event.preventDefault();
    const {
      buildingName,
      campusId,
      totalFloors,
      status,
      areaUnit,
      areaPrice,
      seaterPrice
    } = this.state;
    // validation and show error alert
    if (!this.validation()) {
      return;
    }

    const building = {
      buildingName: buildingName,
      campus: campusId,
      totalFloors: totalFloors,
      status: status,
      areaUnit: areaUnit,
      areaPrice: areaPrice,
      seaterPrice: seaterPrice
    };

    // Extract request method & header & params
    const authToken = this.props.authToken;
    const url = BUILDING_API + "/" + this.state._id;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    // Fire request
    HttpUtil.put(
      url,
      headers,
      building,
      data => {
        this.handleApiSuccess(data, "UPDATE_API");
        window.scrollTo(0, 0);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  closeDefaultAlert = () => {
    this.setState({
      showAlert: false,
      alertMode: "",
      alertMessage: ""
    });
  };

  getStatusList = () => {
    const url = STATUS_LIST_API + "BUILDING";
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data, "STATUS_LIST"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAreaUnitList = () => {
    const url = AREA_UNIT_LIST_API;
    const authToken = this.props.authToken;
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken
    };

    HttpUtil.get(
      url,
      headers,
      data => this.handleApiSuccess(data, "AREA_UNIT_LIST"),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  render() {
    const {
      //building
      buildingName,
      campus,
      totalFloors,
      status,
      areaUnit,
      areaPrice,
      seaterPrice,

      //Default alert
      showAlert,
      alertMode,
      alertMessage,
      permanentFailure,
      inputError,

      // for selectors
      statusList,
      areaUnitList
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    return (
      <Fragment>
        <Breadcrumb breadCrumbList={this.state.breadCrumbList} />
        <div className="row">
          <div className="col-12 col-xl-12">
            {/* show alert message  */}
            <DefaultAlert
              show={showAlert}
              alertMode={alertMode}
              message={alertMessage}
              closeAlert={this.closeDefaultAlert}
            />

            <Building
              //building
              buildingName={buildingName}
              campus={campus}
              totalFloors={totalFloors}
              status={status}
              areaUnit={areaUnit}
              areaPrice={areaPrice}
              seaterPrice={seaterPrice}
              //events
              newBuilding={false}
              onChange={this.onChange}
              submit={this.updateBuilding}
              backToBuildingList={this.backToBuildingList}
              inputError={inputError}
              // for selectors
              statusList={statusList}
              areaUnitList={areaUnitList}
              usableArea={this.state.usableArea}
              totalArea={this.state.totalArea}
            />
          </div>
        </div>

        <BuildingBasedFloorList floors={this.state.floors} />
      </Fragment>
    );
  }
}

export default EditBuilding;
