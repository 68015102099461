import React, { Component, Fragment } from "react";
import { Field, FieldArray } from "formik";
import DatePicker from "react-datepicker";
import { getErrorCssClassName, ErrorComponent } from "../../common/FormikUtil";
import { getDisplayDate } from "../../lease/DateUtil";
import HttpUtil from "../../common/HttpUtil";
import { COUNTRIES_API } from "../../common/Constants";

class Patents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: []
    };
  }

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    this.getCountries();
  };

  render() {
    const { values, touched, errors, setFieldValue } = this.props;
    const { countries } = this.state;

    const patents = values.patents || [];
    return (
      <Fragment>
        <div className="edit-page-section-header-text mb-2">Patents</div>
        <div className="p-2">
          <FieldArray
            name="patents"
            render={({ remove, push }) => (
              <Fragment>
                {patents.map((patent, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="card mb-3">
                        <div className="card-header border-0">
                          <span
                            className="card-title font-weight-bold"
                            style={{ color: "#647acb" }}
                          >
                            Patent {patents.length === 1 ? "" : index + 1}
                          </span>

                          <span
                            className="float-right text-danger"
                            style={{ cursor: "pointer", outline: "none" }}
                            onClick={() => remove(index)}
                          >
                            <i className="fas fa-times"></i>
                          </span>
                        </div>
                        <div className="card-body pt-0">
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label className="edit-page-label">
                                Country (Jurisdiction)
                              </label>
                              <Field
                                component="select"
                                type="text"
                                className={`form-control form-control-sm ${getErrorCssClassName(
                                  touched,
                                  errors,
                                  "country"
                                )}`}
                                name={`patents.${index}.country`}
                                value={patent.country || "IN"}
                              >
                                <option value="">Select</option>
                                {countries.map((cntry, idx) => (
                                  <option key={idx} value={cntry.countryCode}>
                                    {cntry.name}
                                  </option>
                                ))}
                              </Field>

                              <ErrorComponent
                                fieldName="country"
                                touched={touched}
                                error={errors}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="edit-page-label">
                                Application Number
                              </label>
                              <Field
                                type="text"
                                className={`form-control form-control-sm ${getErrorCssClassName(
                                  touched,
                                  errors,
                                  "applicationNumber"
                                )}`}
                                name={`patents.${index}.applicationNumber`}
                                value={patent.applicationNumber || ""}
                              />

                              <ErrorComponent
                                fieldName="applicationNumber"
                                touched={touched}
                                error={errors}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="edit-page-label">
                                Application Filing Date
                              </label>
                              <DatePicker
                                value={
                                  getDisplayDate(
                                    patent.applicationFilingDate
                                  ) || ""
                                }
                                selected={patent.applicationFilingDate || null}
                                onChange={e => {
                                  setFieldValue(
                                    `patents.${index}.applicationFilingDate`,
                                    e
                                  );
                                }}
                                utcOffset={0}
                                placeholderText="dd-mm-yyyy"
                                className={`form-control form-control-sm ${getErrorCssClassName(
                                  touched,
                                  errors,
                                  "applicationFilingDate"
                                )}`}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />

                              <ErrorComponent
                                fieldName="applicationFilingDate"
                                touched={touched}
                                error={errors}
                                datePicker={true}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label className="edit-page-label">
                                Field Of Invention
                              </label>
                              <Field
                                type="text"
                                className={`form-control form-control-sm ${getErrorCssClassName(
                                  touched,
                                  errors,
                                  "fieldOfInvention"
                                )}`}
                                name={`patents.${index}.fieldOfInvention`}
                                value={patent.fieldOfInvention || ""}
                              />

                              <ErrorComponent
                                fieldName="fieldOfInvention"
                                touched={touched}
                                error={errors}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="edit-page-label">
                                Classification (IPC)
                              </label>

                              <Field
                                type="text"
                                className={`form-control form-control-sm ${getErrorCssClassName(
                                  touched,
                                  errors,
                                  "classificationCode"
                                )}`}
                                name={`patents.${index}.classificationCode`}
                                value={patent.classificationCode || ""}
                              />

                              <ErrorComponent
                                fieldName="classificationCode"
                                touched={touched}
                                error={errors}
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label className="edit-page-label">
                                Patent Status
                              </label>
                              <Field
                                type="text"
                                className={`form-control form-control-sm ${getErrorCssClassName(
                                  touched,
                                  errors,
                                  "patentStatus"
                                )}`}
                                name={`patents.${index}.patentStatus`}
                                value={patent.patentStatus || ""}
                              />

                              <ErrorComponent
                                fieldName="patentStatus"
                                touched={touched}
                                error={errors}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
                <div className="form-group row mt-2 mb-0">
                  <div className="col-12">
                    <button
                      className="btn btn-outline-success btn-sm"
                      onClick={() =>
                        push({
                          country: "",
                          applicationNumber: "",
                          applicationFilingDate: "",
                          fieldOfInvention: "",
                          classificationCode: "",
                          patentStatus: ""
                        })
                      }
                      style={{ outline: "none" }}
                      type="button"
                    >
                      + Add More
                    </button>
                  </div>
                </div>
              </Fragment>
            )}
          />
        </div>
      </Fragment>
    );
  }
}

export default Patents;
