// For Siderbar.js
export const menuLinks = [
  { title: "Dashboard", link: "/dashboard", icon: "fas fa-tachometer-alt" },
  {
    title: "Data Update Requests",
    link: "/portal/update-request/open",
    portalUrl: true,
    key: "pending-data-update-requests",
    icon: "fas fa-clipboard-list"
  },
  {
    title: "Incubatee Profile",
    link: "/admin/incubatees",
    icon: "fas fa-rocket",
    permission: { action: "VIEW", subject: "Incubatee" }
  },
  {
    title: "Incubatee Lifecycle",
    link: "/admin/onboard-mgmt",
    icon: "fas fa-sitemap",
    children: [
      {
        title: "Shortlist",
        link: "/admin/incubatoronboard",
        icon: "far fa-file",
        key: "pending-incubatee-app-forms",
        permission: { action: "VIEW", subject: "IncubatorAppForm" }
      },
      {
        title: "Onboarding",
        link: "/onboard/incubatees",
        icon: "far fa-file",
        key: "pending-incubatee-forms",
        permission: { action: "VIEW", subject: "IncubateeOnboardingForm" }
      },
      {
        title: "Awaiting Leasing",
        link: "/admin/incubatees/pending-lease",
        icon: "far fa-file",
        key: "pending-incubatee-leasing",
        permission: { action: "VIEW", subject: "Incubatee" }
      },
      {
        title: "Graduation Requests",
        link: "/admin/incubatees/graduations",
        icon: "far fa-file",
        key: "pending-graduation-requests",
        permission: { action: "VIEW", subject: "GraduationRequest" }
      },
      {
        title: "Graduated Incubatees",
        link: "/graduated/incubatees",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Incubatee" }
      }
    ]
  },
  {
    title: "Lease",
    link: "/lease",
    icon: "fas fa-tasks",
    children: [
      {
        title: "Open Requests",
        link: "/admin/request/leases",
        icon: "far fa-file",
        key: "pending-lease-requests",
        permission: { action: "VIEW", subject: "Lease" }
      },
      {
        title: "Rejected Requests",
        link: "/admin/cancelled/leases/requests",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Lease" }
      },
      {
        title: "Active Leases",
        link: "/admin/active/leases",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Lease" }
      },
      {
        title: "Past / Closed Leases",
        link: "/admin/closed/leases",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Lease" }
      },
      {
        title: "Upcoming Renewals",
        link: "/admin/upcoming-renewals",
        icon: "far fa-file",
        key: "upcoming-renewals-count",
        permission: { action: "VIEW", subject: "Lease" }
      },
      {
        title: "Expired Leases",
        link: "/admin/expired/leases",
        icon: "far fa-file",
        key: "expired-leases-count",
        permission: { action: "VIEW", subject: "Lease" }
      }
    ]
  },
  {
    title: "Funding",
    link: "/",
    icon: "fas fa-award",
    children: [
      {
        title: "Shortlist",
        link: "/funding/shortList",
        icon: "fas fa-spinner"
      },
      { title: "Awards", link: "/funding/awards", icon: "far fa-file" },
      {
        title: "Funding Cycle",
        link: "/funding/funding-cycles",
        icon: "far fa-file"
      },
      {
        title: "Template",
        link: "/funding/grant-progress-template",
        icon: "fas fa-spinner"
      },
      {
        title: "Setup",
        link: "/funding/agencies",
        icon: "fas fa-spinner"
      }
    ]
  },
  {
    title: "Asset",
    link: "/",
    icon: "fas fa-wrench",
    children: [
      {
        title: "Assets",
        link: "/service-mgmt/assets",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Asset" }
      },
      {
        title: "Open Enquiries",
        link: "/service-mgmt/open-enquiries",
        key: "pending-asset-enquiries",
        permission: { action: "VIEW", subject: "IncubatorEnquiry" }
      },
      {
        title: "Past / Closed Enquiries",
        link: "/service-mgmt/closed-enquiries",
        permission: { action: "VIEW", subject: "IncubatorEnquiry" }
      },
      {
        title: "Asset Availability",
        link: "/service-mgmt/asset-availabilty?new=true",
        permission: { action: "VIEW", subject: "AssetReservationRequest" }
      }
    ]
  },
  {
    title: "Asset Reservation",
    link: "/",
    icon: "fas fa-book",
    children: [
      {
        title: "Daily Summary",
        link: "/service-mgmt/asset-reservation-daily-summary",
        permission: { action: "VIEW", subject: "AssetReservationRequest" }
      },
      {
        title: "Open Reservations",
        link: "/service-mgmt/open-reservations",
        permission: { action: "VIEW", subject: "AssetReservationRequest" }
      },
      {
        title: "Active Reservations",
        link: "/service-mgmt/active-reservations",
        permission: { action: "VIEW", subject: "AssetReservationRequest" }
      },
      {
        title: "Awaiting Payment/Refund",
        link: "/service-mgmt/awaiting-payment",
        permission: { action: "VIEW", subject: "AssetReservationRequest" }
      },
      {
        title: "Past / Closed Reservations",
        link: "/service-mgmt/closed-reservations",
        permission: { action: "VIEW", subject: "AssetReservationRequest" }
      }
    ]
  },
  {
    title: "Account Closures",
    link: "/finance",
    icon: "fas fa-money-bill-wave",
    children: [
      {
        title: "Open Requests",
        link: "/finance/account/open-closure-requests",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "AccountClosureRequest" }
      },
      {
        title: "Rejected Requests",
        link: "/finance/account/rejected-requests",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "AccountClosureRequest" }
      },
      {
        title: "Awaiting Refund",
        link: "/finance/account/awaiting-refund",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "AccountClosureRequest" }
      },
      {
        title: "Closed Requests",
        link: "/finance/account/closed-requests",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "AccountClosureRequest" }
      }
    ]
  },
  {
    title: "Finance",
    link: "/finance",
    icon: "fas fa-money-bill-wave",
    children: [
      {
        title: "Balance",
        link: "/admin/finance/accounts",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Account" }
      },
      {
        title: "Monthly Reports",
        link: "/admin/finance/reports",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "FinChargeReport" }
      },
      {
        title: "Consoldated Reports",
        link: "/finance/consolidated-report",
        icon: "far fa-registered",
        permission: { action: "VIEW", subject: "FinChargeReport" }
      },
      {
        title: "Manual Charges",
        link: "/admin/finance/manual-charges",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "FinCharge" }
      },
      {
        title: "Payments",
        link: "/admin/finance/payments",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Payment" }
      },
      {
        title: "Adjustments",
        link: "/admin/finance/adjustments/payments",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Adjustment" }
      }, //   link: "/asset/requestor-onboards", //   title: "Applicant Onboard", // { // link: "/admin/incubatees/onboard", // {
      //   icon: "far fa-file",
      //   permission: {
      //     action: "VIEW",
      //     subject: "Equipment"
      //   }
      // },
      // {
      //   title: "Lease",
      //   link: "/asset/leases",
      //   icon: "far fa-file",
      //   permission: {
      //     action: "VIEW",
      //     subject: "Equipment"
      //   }
      // },
      // {
      //   title: "Release",
      //   link: "/asset/release",
      //   icon: "far fa-file",
      //   permission: {
      //     action: "VIEW",
      //     subject: "Equipment"
      //   }
      // },
      // {
      //   title: "Return",
      //   link: "/asset/return",
      //   icon: "far fa-file",
      //   permission: {
      //     action: "VIEW",
      //     subject: "Equipment"
      //   }
      // }
      //   title: "Advance Adjustments",
      //   link: "/finance/adjustment/advances",
      //   icon: "far fa-file"
      // },

      {
        title: "Search",
        link: "/finance/search",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Payment" }
      }
    ]
  }, //   icon: "far fa-building", //   link: "/admin/facility", //   title: "Performance Metrics", // {
  //   children: [
  //     {
  //       title: "Dashboard",
  //       link: "/pm/dashboard",
  //       icon: "far fa-file"
  //     },

  //     {
  //       title: "KPI Acheivement",
  //       link: "/pm/kpi",
  //       icon: "far fa-file"
  //     },

  //     {
  //       title: "Monthly Reports",
  //       link: "/pm/monthly-report",
  //       icon: "far fa-file"
  //     }
  //   ]
  // },
  {
    title: "Facility",
    link: "/admin/facility",
    icon: "far fa-building",
    children: [
      {
        title: "Campus",
        link: "/admin/campuses",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Campus" }
      },
      {
        title: "Building",
        link: "/admin/buildings",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Building" }
      },
      {
        title: "Floor",
        link: "/admin/floors",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Floor" }
      },
      {
        title: "Partition",
        link: "/admin/partitions",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Partition" }
      },
      {
        title: "Clusters",
        link: "/facility-clusters",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "FacilityCluster" }
      }
    ]
  },
  {
    title: "Events",
    icon: "far fa-calendar",
    children: [
      {
        title: "Upcoming Events",
        link: "/events",
        icon: "",
        permission: { action: "VIEW", subject: "Event" }
      },
      {
        title: "Recent Events",
        link: "/events/recent",
        icon: "",
        permission: { action: "VIEW", subject: "Event" }
      },
      {
        title: "Event Search",
        link: "/events/search",
        icon: "",
        permission: { action: "VIEW", subject: "Event" }
      }
    ]
  },
  {
    title: "Reports",
    link: "/admin/reports",
    icon: "far fa-registered",
    children: [
      // {
      //   title: "Startup Report",
      //   link: "/admin/incubatee/startup-profile",
      //   icon: "far fa-file",
      //   permission: { action: "VIEW", subject: "Incubatee" }
      // },
      {
        title: "Data Export",
        link: "/admin/incubatee/data-export",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "Incubatee" }
      }
    ]
  },
  {
    title: "Admin",
    link: "/admin",
    icon: "fas fa-user",
    children: [
      {
        title: "Incubator Users",
        link: "/incubator-users",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "User" }
      },
      {
        title: "Incubatee Users",
        link: "/external-users",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "User" }
      },
      {
        title: "Internal Organizations",
        link: "/admin/internal/orgs",
        icon: "far fa-file",
        permission: { action: "VIEW", subject: "IncubatorOrg" }
      }
    ]
  }
];
