import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { getDescription } from "../common/Util";
import {
  PartitionInfo,
  PaymentInfo,
  AdditionalInfo,
  Agreement,
  LeaseHistory
} from "./LeaseInputForm";
import LeaseRenewForm from "./renew/LeaseRenewForm";
import { ReadOnlyTerminationForm } from "./termination/LeaseTerminationForm";
import Can from "../../auth/can";

class LeaseForm extends Component {
  render() {
    // props
    const {
      //events
      submit,
      _id,
      incubatee,
      previousLease,
      terminationRequestExist,
      terminationId,
      leaseTermination,
      previousLocation,
      actions,
      actionStage,
      actionStatus,
      closeLeaseTerminationAlert,
      renewalMetrics,
      empInfo,
      finInfo,
      inputError,
      handleRenewalInputChange,
      handleRenewalRadioBtn,
      handleRenewalCountChange,
      handleTerminateLease,
      handleLeaseRenew,
      downloadDocument
    } = this.props;

    let showBtns = false;
    showBtns =
      showBtns || (actionStage === "COMPLETE" && actionStatus === "ACTIVE");
    showBtns = showBtns || actionStatus === "EXPIRED";

    const STATUS_MAPPER = [
      "TERMINATED",
      "APPROVED",
      "CLOSED",
      "ACTIVE",
      "EXPIRED"
    ];

    let title = "";
    const stage = actionStage;
    const status = actionStatus;

    if (stage === "COMPLETE" && STATUS_MAPPER.indexOf(status) !== -1) {
      title = "Lease";
    } else {
      // const previousLease = this.state.previousLease;
      if (previousLease) {
        title = "Lease Request - Renewal";
      } else {
        title = "Lease Request";
      }
    }

    const show = this.props.actionBtnShow ? "show" : "";
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-12 text-right">
            {showBtns ? (
              <div className={`btn-group ${show}`}>
                <button
                  type="button"
                  className="btn btn-sm bg-n-primary dropdown-toggle text-white mr-3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={this.props.actionBtnShow}
                  onClick={this.props.toggleActionBtn}
                >
                  Action
                </button>
                <div
                  className={`dropdown-menu ${show} m-0 p-0`}
                  aria-labelledby="dropdownMenuOffset"
                  style={{
                    border: "none",
                    position: "absolute",
                    willChange: "transform",
                    top: 0,
                    left: "-160px",
                    transform: "translate3d(0px, 31px, 0px)",
                    width: 230
                  }}
                >
                  <div className="list-group dropdown-item-icon-text">
                    <Can I="RENEW" a="Lease">
                      <Fragment>
                        {
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            href="#"
                            role="button"
                            className="list-group-item list-group-item-action d-flex align-items-center"
                            onClick={handleLeaseRenew}
                          >
                            <i
                              className="fas fa-plus fa-sm pr-3"
                              aria-hidden="true"
                            />
                            <span className="fa-sm">Renew Lease</span>
                          </a>
                        }
                      </Fragment>
                    </Can>

                    <Can I="TERMINATE" a="Lease">
                      <Fragment>
                        {
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            href="#"
                            role="button"
                            className="list-group-item list-group-item-action d-flex align-items-center"
                            onClick={handleTerminateLease}
                          >
                            <i
                              className="fas fa-handshake fa-sm pr-2"
                              aria-hidden="true"
                            />
                            <span className="fa-sm">Terminate Lease</span>
                          </a>
                        }
                      </Fragment>
                    </Can>
                    {/* <a
                    className="list-group-item list-group-item-action d-flex align-items-center"
                    href="#"
                  >
                    <i className="fas fa-plus fa-sm pr-3" />
                    <span className="fa-sm"> Action 2</span>
                  </a> */}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="card edit-page-container">
          <div className="card-header border-bottom-0 pt-3">
            <span className="edit-page-title">
              {previousLocation ? (
                <Link
                  to={previousLocation}
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left mr-3" />
                </Link>
              ) : (
                <Link
                  to="/admin/active/leases"
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left mr-3" />
                </Link>
              )}
              {title}
            </span>

            <span className="float-right edit-page-status ml-2">{`Status : ${getDescription(
              "LEASE",
              actionStage,
              actionStatus
            )}`}</span>
          </div>
          <div className="card-body">
            <Agreement {...this.props} />
            <PartitionInfo {...this.props} authToken={this.props.authToken} />
            <LeaseHistory {...this.props} />
            <PaymentInfo {...this.props} />
            {previousLease !== null ? (
              <LeaseRenewForm
                leaseId={_id}
                incubatee={incubatee}
                renewalMetrics={renewalMetrics}
                empInfo={empInfo}
                finInfo={finInfo}
                inputError={inputError}
                handleRenewalInputChange={handleRenewalInputChange}
                handleRenewalRadioBtn={handleRenewalRadioBtn}
                handleRenewalCountChange={handleRenewalCountChange}
              />
            ) : null}

            <AdditionalInfo {...this.props} />
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12" />
            </div>

            {leaseTermination !== null ? (
              <ReadOnlyTerminationForm
                downloadDocument={downloadDocument}
                leaseTermination={leaseTermination}
              />
            ) : null}

            <ViewExistingTerminateLease
              show={terminationRequestExist}
              terminationId={terminationId}
              close={closeLeaseTerminationAlert}
            />

            <hr />
            <div className="form-group row">
              <div className="col-sm-12 text-center">
                {actions.length > 0
                  ? actions.map((action, idx) => {
                      return (
                        <Fragment key={idx}>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={submit.bind(this, action)}
                          >
                            {action}
                          </button>
                          &nbsp;&nbsp;
                        </Fragment>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LeaseForm;

export const ViewExistingTerminateLease = props => {
  const { show, close, terminationId } = props;
  if (!show) {
    return null;
  }
  // The gray background
  const backdropStyle = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.3)",
    padding: 50,
    zIndex: 1000
  };

  // The modal "window"
  const modalStyle = {
    backgroundColor: "#fff",
    borderRadius: 5,
    margin: "0 auto",
    width: 400
  };

  const message = `There is an termination request for this lease. Please select "View Termination Request" to go to the termination request or "Cancel" to stay on this page.`;

  return (
    <div>
      <div className="backdrop" style={backdropStyle}>
        <div className="modal-dialog" style={modalStyle}>
          <div className="modal-content">
            <div className="alert-modal-header">
              <h5 className="h6 modal-title p-0">Termination Request Exists</h5>
              <button
                type="button"
                onClick={close}
                className="close pull-right"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="alert-modal-body">{message}</div>
            <div className="alert-modal-footer">
              <Link
                to={`/admin/lease/termination/edit/${terminationId}`}
                role="button"
                className="btn btn-sm btn-default text-primary font-weight-bold"
              >
                View Termination Request
              </Link>
              &nbsp;
              <button
                className="btn btn-outline-default btn-sm"
                onClick={close}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
