import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import AutoLookup from "../AutoLookup";
import Can from "../../../auth/can";

function getError(touched, error, fieldName) {
  return touched && touched[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touched, error, fieldName) {
  return getError(touched, error, fieldName) ? "is-invalid" : "";
}

const ErrorComponent = props => {
  const { fieldName, touched, error, datePicker } = props;
  const className = datePicker ? "auto-lookup-empty" : "invalid-feedback";

  return (
    <div className={className}>
      {touched && touched[`${fieldName}`] && error && error[`${fieldName}`]}
    </div>
  );
};

function getCampusLookupUrl() {
  return "api/v1/campuses/search/?name=";
}

function getBuildingLookupUrl(campus) {
  let buildingLookupUrl = "";
  if (campus) {
    const campusId = campus.value;
    buildingLookupUrl =
      "api/v1/buildings/search/?campus=" + campusId + "&buildingName=";
  }
  return buildingLookupUrl;
}

function getFloorLookupUrl(building) {
  let floorLookupUrl = "";
  if (building) {
    const buildingId = building.value;
    floorLookupUrl = "api/v1/floors/search/?building=" + buildingId + "&name=";
  }
  return floorLookupUrl;
}

const CreatePartitionForm = props => {
  const { values, errors, touched, setFieldValue } = props;

  let styles = {};
  if (errors.category && errors.category["value"]) {
    styles = {
      control: base => ({
        ...base,
        borderColor: "#f44455",
        height: "calc(1.8125rem + 2px)",
        minHeight: "calc(1.8125rem + 2px)"
      })
    };
  }
  return (
    <Form>
      <div className="card elevate">
        <div className="card-header edit-page-title border-0 mt-3">
          <span className="mb-0 mr-1">
            <Link to="/admin/partitions" className="">
              <i className="fas fa-arrow-left" />
            </Link>
            &nbsp; Partition
          </span>

          <span>
            {props.newPartition ? "New Record" : values.partitionName}
          </span>
        </div>

        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-12 edit-page-section-header-text">
              <strong> Facility Information </strong>
            </div>
          </div>

          {props.newPartition ? (
            <Fragment>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Campus
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <AutoLookup
                    className={errors.campus && errors.campus["value"]}
                    name="name"
                    value={values.campus}
                    getObject={entity => {
                      if (entity) {
                        setFieldValue("campus", entity);
                      } else {
                        setFieldValue("campus", null);
                        setFieldValue("building", null);
                        setFieldValue("floor", null);
                      }
                    }}
                    url={getCampusLookupUrl()}
                  />
                  {errors.campus && errors.campus["value"] ? (
                    <div className="auto-lookup-empty">
                      {errors.campus["value"]}
                    </div>
                  ) : null}
                </div>

                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Building
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <AutoLookup
                    className={errors.building && errors.building["value"]}
                    name="buildingName"
                    value={values.building}
                    getObject={entity => {
                      if (entity) {
                        setFieldValue("building", entity);
                      } else {
                        setFieldValue("building", null);
                        setFieldValue("floor", null);
                      }
                    }}
                    url={getBuildingLookupUrl(values.campus)}
                  />

                  {errors.building && errors.building["value"] ? (
                    <div className="auto-lookup-empty">
                      {errors.building["value"]}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Floor
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <AutoLookup
                    className={errors.floor && errors.floor["value"]}
                    name="floorName"
                    value={values.floor}
                    getObject={entity => {
                      if (entity) {
                        setFieldValue("floor", entity);
                      } else {
                        setFieldValue("floor", null);
                      }
                    }}
                    url={getFloorLookupUrl(values.building)}
                  />
                  {errors.floor && errors.floor["value"] ? (
                    <div className="auto-lookup-empty">
                      {errors.floor["value"]}
                    </div>
                  ) : null}
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Floor
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    type="text"
                    className={`form-control form-control-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "floor"
                    )}`}
                    name="floor"
                    value={values.floor.label || ""}
                    readOnly
                  />
                </div>
              </div>
            </Fragment>
          )}

          <div className="row mb-3 mt-3">
            <div className="col-md-12 edit-page-section-header-text">
              <strong> Partition Information </strong>
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Partition Name
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                type="text"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "partitionName"
                )}`}
                name={`partitionName`}
                value={values.partitionName || ""}
              />

              <ErrorComponent
                fieldName={`partitionName`}
                touched={touched}
                error={errors}
              />
            </div>

            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
              Display Name
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                type="text"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "displayName"
                )}`}
                name={`displayName`}
                value={values.displayName || ""}
              />

              <ErrorComponent
                fieldName={`displayName`}
                touched={touched}
                error={errors}
              />
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Area
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <div className="input-group mb-0">
                <Field
                  type="number"
                  className={`form-control form-control-sm ${getErrorCssClassName(
                    touched,
                    errors,
                    "area"
                  )}`}
                  name={`area`}
                  value={values.area || ""}
                />
                <Field
                  component="select"
                  className={`custom-select custom-select-sm ${getErrorCssClassName(
                    touched,
                    errors,
                    "areaUnit"
                  )}`}
                  name="areaUnit"
                  value={values.areaUnit || ""}
                >
                  <option value="">Select...</option>
                  {props.areaUnits.map((unit, idx) => (
                    <option key={idx} value={unit.unitCode}>
                      {unit.unitDesc}
                    </option>
                  ))}
                </Field>

                {errors["area"] ? (
                  <ErrorComponent
                    fieldName={`area`}
                    touched={touched}
                    error={errors}
                  />
                ) : null}

                {errors["areaUnit"] ? (
                  <ErrorComponent
                    fieldName={`areaUnit`}
                    touched={touched}
                    error={errors}
                  />
                ) : null}
              </div>
            </div>

            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Status
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                component="select"
                className={`custom-select custom-select-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "status"
                )}`}
                name="status"
                value={values.status || ""}
              >
                <option value="">Select...</option>
                {props.statusList.map((sta, idx) => (
                  <option key={idx} value={sta.statusCode}>
                    {sta.statusDesc}
                  </option>
                ))}
              </Field>

              <ErrorComponent
                fieldName={`status`}
                touched={touched}
                error={errors}
              />
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
              Partition Owner
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                component="select"
                className={`custom-select custom-select-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "partitionOwner"
                )}`}
                name="partitionOwner"
                value={values.partitionOwner || ""}
              >
                <option value="">Select...</option>
                {props.incubators.map((ibtr, idx) => (
                  <option key={idx} value={ibtr._id}>
                    {ibtr.name}
                  </option>
                ))}
              </Field>

              <ErrorComponent
                fieldName={`partitionOwner`}
                touched={touched}
                error={errors}
              />
            </div>

            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
              Facility Cluster
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Select
                value={values.facility}
                onChange={entity => setFieldValue("facility", entity)}
                options={props.allFacilities}
                isClearable
              />

              {/*
              {inputError["facility"] ? (
                <div style={{ fontSize: "80%" }} className="text-danger">
                  Please select Facility *
                </div>
              ) : null} */}
            </div>
          </div>

          <div className="form-group row mb-2 mt-1">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 pr-0 edit-page-label-required">
              Partition Category
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Select
                value={values.category}
                onChange={entity => {
                  if (entity) {
                    setFieldValue("category", entity);
                    setFieldValue("rentablePartitionSelected", entity.rentable);
                  } else {
                    setFieldValue("category", "");
                    setFieldValue("rentablePartitionSelected", false);
                  }
                }}
                options={props.partitionCategories}
                styles={styles}
                isClearable
              />

              {errors.category && errors.category["value"] ? (
                <div className="auto-lookup-empty">
                  {errors.category["value"]}
                </div>
              ) : null}

              {/* <ErrorComponent
                fieldName={`category`}
                touched={touched}
                error={errors}
              /> */}
            </div>

            {values.category &&
            values.category.subCategories &&
            values.category.subCategories.length > 0 ? (
              <Fragment>
                <label className="col-form-label col-12 col-lg-2 col-xl-2 pr-0 edit-page-label">
                  Sub Category
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Select
                    value={values.subCategory || null}
                    onChange={entity => {
                      if (entity) {
                        setFieldValue("subCategory", entity);
                      } else {
                        setFieldValue("subCategory", "");
                      }
                    }}
                    options={values.category.subCategories}
                    styles={styles}
                    isClearable
                  />

                  {errors.category && errors.category["value"] ? (
                    <div className="auto-lookup-empty">
                      {errors.category["value"]}
                    </div>
                  ) : null}
                </div>
              </Fragment>
            ) : null}
          </div>

          {values.rentablePartitionSelected ? (
            <Fragment>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Partition Type
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <Field
                    component="select"
                    className={`custom-select custom-select-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "partitionType"
                    )}`}
                    name="partitionType"
                    value={values.partitionType || ""}
                  >
                    <option value="">Select...</option>
                    {props.partitionTypes.map((type, idx) => (
                      <option key={idx} value={type.partitionTypeCode}>
                        {type.partitionTypeDesc}
                      </option>
                    ))}
                  </Field>

                  <ErrorComponent
                    fieldName={`partitionType`}
                    touched={touched}
                    error={errors}
                  />
                </div>

                {values.partitionType === "RENTAL" ? (
                  <Fragment>
                    <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                      Price (Rs/SQFT)
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="number"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "areaPrice"
                        )}`}
                        name={`areaPrice`}
                        value={values.areaPrice || ""}
                      />
                      <ErrorComponent
                        fieldName={`areaPrice`}
                        touched={touched}
                        error={errors}
                      />
                    </div>
                  </Fragment>
                ) : null}

                {values.partitionType === "SEATER" ? (
                  <Fragment>
                    <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                      Seater Price
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="number"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "seaterPrice"
                        )}`}
                        name={`seaterPrice`}
                        value={values.seaterPrice || ""}
                      />
                      <ErrorComponent
                        fieldName={`seaterPrice`}
                        touched={touched}
                        error={errors}
                      />
                    </div>
                  </Fragment>
                ) : null}
              </div>

              {values.partitionType === "SEATER" ? (
                <Fragment>
                  <div className="form-group row mb-0">
                    <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                      Seat Count
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="number"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "seatCount"
                        )}`}
                        name={`seatCount`}
                        value={values.seatCount || ""}
                      />
                      <ErrorComponent
                        fieldName={`seatCount`}
                        touched={touched}
                        error={errors}
                      />
                    </div>

                    <label className="col-form-label col-12 col-lg-2 col-xl-2 pr-0 edit-page-label">
                      Seating Capacity
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="number"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "maxSeatingCapacity"
                        )}`}
                        name={`maxSeatingCapacity`}
                        value={values.maxSeatingCapacity || ""}
                      />
                      <ErrorComponent
                        fieldName={`maxSeatingCapacity`}
                        touched={touched}
                        error={errors}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : null}

              <div className="form-group row mb-0 mt-1">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Rent Taxable
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <div className="custom-control custom-checkbox">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="rentTaxable1"
                      name="rentTaxable"
                      checked={values.rentTaxable}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="rentTaxable1"
                    >
                      Yes
                    </label>
                  </div>
                </div>

                {values.rentTaxable ? (
                  <Fragment>
                    <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                      Tax Rate
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <Field
                        type="number"
                        className={`form-control form-control-sm ${getErrorCssClassName(
                          touched,
                          errors,
                          "rentTaxRate"
                        )}`}
                        name={`rentTaxRate`}
                        value={values.rentTaxRate || ""}
                      />

                      <ErrorComponent
                        fieldName={`rentTaxRate`}
                        touched={touched}
                        error={errors}
                      />
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </Fragment>
          ) : null}

          {/* <hr />
          <div className="row mb-3">
            <div className="col-md-12 edit-page-section-header-text">
              <strong> Additional Information </strong>
            </div>
          </div> */}

          <div className="form-group row mb-0 mt-1">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
              Amenities
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                type="text"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "amenityType"
                )}`}
                placeholder="Type (ex: Electricity, Internet)"
                name={`amenityType`}
                value={values.amenityType || ""}
              />
              <div className="input-group" style={{ marginTop: 10 }}>
                <Field
                  type="text"
                  className={`form-control form-control-sm ${getErrorCssClassName(
                    touched,
                    errors,
                    "amenityReference"
                  )}`}
                  placeholder="Reference Number here..."
                  name={`amenityReference`}
                  value={values.amenityReference || ""}
                />
                &nbsp;&nbsp;
                <span className="input-group-sm input-group-prepend">
                  <button
                    className="btn btn-sm btn-secondary"
                    type="button"
                    style={{ zIndex: 0 }}
                    onClick={() => {
                      const amenities = [...values.amenities];
                      if (values.amenityType && values.amenityReference) {
                        const amenity = {
                          amenityType: values.amenityType,
                          amenityReference: values.amenityReference
                        };
                        amenities.push(amenity);
                        setFieldValue("amenityType", "");
                        setFieldValue("amenityReference", "");
                        setFieldValue("amenities", amenities);
                      }
                    }}
                  >
                    Add
                  </button>
                </span>
              </div>
              {values.amenities.length > 0 ? (
                <div
                  style={{
                    maxHeight: 100,
                    overflowY: "scroll",
                    marginTop: 10
                  }}
                >
                  <table className="table table-bordered table-striped table-sm">
                    <thead>
                      <tr>
                        <th
                          className="border-bottom-0 display-table-header text-center"
                          style={{
                            width: "35px",
                            overflow: "hidden"
                          }}
                        >
                          Type
                        </th>
                        <th
                          className="border-bottom-0 display-table-header text-center"
                          style={{
                            width: "35px",
                            overflow: "hidden"
                          }}
                        >
                          Ref Number
                        </th>
                        <th
                          className="border-bottom-0 display-table-header text-center"
                          style={{
                            width: "35px",
                            overflow: "hidden"
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.amenities.map((amenity, index) => {
                        return (
                          <tr key={index}>
                            <td>{amenity.amenityType}</td>
                            <td>{amenity.amenityReference}</td>
                            <td
                              className="table-action"
                              style={{ width: "30px" }}
                            >
                              <i
                                className="fas fa-times"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  let amenities = [...values.amenities];
                                  amenities = amenities.filter(
                                    (amenity, idx) => idx !== index
                                  );
                                  setFieldValue("amenities", amenities);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>

            <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
              Remarks
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                component="textarea"
                className={`form-control form-control-sm ${getErrorCssClassName(
                  touched,
                  errors,
                  "remarks"
                )}`}
                name={`remarks`}
                value={values.remarks || ""}
                rows="3"
              />
            </div>
          </div>

          <div className="form-group row mt-4 mb-3">
            <div className="col-12 text-center">
              {props.newPartition ? (
                <Can I="CREATE" a="Partition">
                  <button className="btn btn-sm  btn-primary" type="submit">
                    Submit
                  </button>
                </Can>
              ) : (
                <Can I="UPDATE" a="Partition">
                  <button className="btn btn-sm  btn-primary" type="submit">
                    Update
                  </button>
                </Can>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const PartitionForm = props => {
  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      validationSchema={props.validationSchema}
      onSubmit={(values, { setSubmitting }) =>
        props.handleSubmit(values, { setSubmitting })
      }
      render={renderProps => (
        <CreatePartitionForm key="001" {...props} {...renderProps} />
      )}
    />
  );
};

export default PartitionForm;
