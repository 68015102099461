import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import ManualChargeForm from "./ManualChargeForm";
import ManualChargeConfirm from "./ManualChargeConfirm";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { CHARGES_API } from "../../common/Constants";
import { addDays, convertDateToNumber } from "../../lease/DateUtil";
import { checkEmptyValue } from "../../common/LookupConstants";
import ErrorPage from "../../common/error.page";

class ManualCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationForm: false,
      editForm: true,

      // manualCharge
      manualCharge: {
        organization: null,
        customer: null,
        customerType: null,
        item: null,
        itemType: null,
        chargeDate: new Date(),
        dueDate: addDays(30),
        description: null,
        unitPriced: false,
        price: null,
        quantity: null,
        amount: null,
        systemGenerated: false
      },
      permanentFailure: false,

      inputError: {},

      viewManualCharge: false,
      viewManualChargeId: null
    };
  }

  getCustomer = cutomer => {
    if (cutomer !== null) {
      const manualCharge = this.state.manualCharge;
      manualCharge.customer = cutomer;
      this.setState({
        manualCharge,
        displayName: cutomer.label
      });
    } else {
      const manualCharge = this.state.manualCharge;
      manualCharge.customer = null;
      this.setState({ manualCharge });
    }
  };
  handleDateChange = (targetName, date) => {
    const manualCharge = this.state.manualCharge;
    manualCharge[targetName] = date;
    this.setState({ manualCharge });
  };

  handleUniPrice = e => {
    const manualCharge = this.state.manualCharge;
    const targetName = e.target.name;
    const targetValue = e.target.value;
    let amount = 0;
    let unitPriced = manualCharge.unitPriced;
    if (unitPriced && targetName === "price") {
      let qty = manualCharge.quantity ? manualCharge.quantity : 0;
      amount = Number(targetValue) * Number(qty);
    }

    if (unitPriced && targetName === "quantity") {
      let prc = manualCharge.price ? manualCharge.price : 0;
      amount = Number(targetValue) * Number(prc);
    }
    manualCharge[targetName] = targetValue;
    manualCharge.amount = amount;
    this.setState({ manualCharge });
  };

  handleInputChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    const manualCharge = this.state.manualCharge;
    if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
      if (targetName === "unitPriced" && !targetValue) {
        manualCharge.price = null;
        manualCharge.quantity = null;
        manualCharge.amount = null;
      }
    }
    manualCharge[targetName] = targetValue;
    this.setState({ manualCharge });
  };

  handleAmountChange = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;
    if (Number(targetValue) < 1) {
      return;
    }
    const manualCharge = this.state.manualCharge;
    manualCharge[targetName] = targetValue;
    this.setState({ manualCharge });
  };

  handleCustomerType = e => {
    const targetName = e.target.name;
    let targetValue = e.target.value !== "" ? e.target.value : null;

    const URL_MAPPER = {
      INDIVIDUAL: {
        url: "api/v1/individuals/lookup/finance/?name=",
        customerName: "name"
      },
      COMPANY: {
        url: "api/v1/organizations/lookup/finance/?name=",
        customerName: "name"
      }
    };

    let customerLookupUrl = null;
    let customerName = null;

    if (targetValue !== null) {
      customerName = URL_MAPPER[targetValue].customerName;
      customerLookupUrl = URL_MAPPER[targetValue].url;
    }

    const manualCharge = this.state.manualCharge;
    manualCharge[targetName] = targetValue;

    this.setState({
      manualCharge,
      customerLookupUrl,
      customerName
    });
  };

  handleNextForm = () => {
    const valid = this.validate();
    if (!valid) {
      return;
    } else {
      this.setState({
        confirmationForm: true,
        editForm: false
      });
    }
  };

  handleEditForm = () => {
    this.setState({
      confirmationForm: false,
      editForm: true,
      inputError: {}
    });
  };

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  validate = () => {
    const manualCharge = this.state.manualCharge;

    let customer = null;
    if (manualCharge.customer) {
      customer = manualCharge.customer.value;
    }
    const manualChargeReq = {
      customer: customer,
      customerType: manualCharge.customerType,
      item: manualCharge.item,
      itemType: manualCharge.itemType,
      chargeDate: convertDateToNumber(manualCharge.chargeDate),
      dueDate: convertDateToNumber(manualCharge.dueDate),
      description: manualCharge.description,
      unitPriced: manualCharge.unitPriced,
      price: manualCharge.price,
      quantity: manualCharge.quantity,
      amount: manualCharge.amount,
      systemGenerated: manualCharge.systemGenerated
    };

    let inputError = {};
    checkEmptyValue(inputError, "customer", manualChargeReq.customer);
    checkEmptyValue(inputError, "customerType", manualChargeReq.customerType);
    checkEmptyValue(inputError, "description", manualChargeReq.description);
    checkEmptyValue(inputError, "amount", manualChargeReq.amount);

    if (manualChargeReq.unitPriced) {
      checkEmptyValue(inputError, "price", manualChargeReq.price);
      checkEmptyValue(inputError, "quantity", manualChargeReq.quantity);
    }

    const invalid = Object.values(inputError).some(item => item);

    if (invalid) {
      this.setState({ inputError });
      return false;
    } else {
      this.setState({ inputError: {} });
      return manualChargeReq;
    }
  };

  handleSubmit = () => {
    const manualChargeReq = this.validate();
    if (!manualChargeReq) {
      return;
    }

    const url = CHARGES_API;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      manualChargeReq,
      data => {
        this.setState({
          viewManualCharge: true,
          viewManualChargeId: data._id
        });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  render() {
    const {
      //Default alert
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      confirmationForm,
      editForm,

      viewManualCharge,
      viewManualChargeId,
      permanentFailure
    } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    if (viewManualCharge) {
      const stateObj = {
        pathname: `/admin/finance/manual-charges/view/${viewManualChargeId}`,
        state: { paymentSubmitted: true }
      };
      return <Redirect to={stateObj} />;
    }

    const props = {
      manualCharge: this.state.manualCharge,
      inputError: this.state.inputError,
      displayName: this.state.displayName,
      customerName: this.state.customerName,
      customerLookupUrl: this.state.customerLookupUrl,

      // events
      handleDateChange: this.handleDateChange,
      handleInputChange: this.handleInputChange,
      handleAmountChange: this.handleAmountChange,
      handleCustomerType: this.handleCustomerType,
      getCustomer: this.getCustomer,
      handleUniPrice: this.handleUniPrice,

      handleNextForm: this.handleNextForm,
      handleEditForm: this.handleEditForm,
      handleSubmit: this.handleSubmit
    };

    let renderForm = null;
    if (editForm) {
      renderForm = <ManualChargeForm {...props} />;
    } else if (confirmationForm) {
      renderForm = <ManualChargeConfirm {...props} />;
    }
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        {renderForm}
      </Fragment>
    );
  }
}

export default ManualCharge;
