import React, { Component, Fragment } from "react";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { changeToINR } from "../../common/LookupConstants";
import { ACCOUNTS_API } from "../../common/Constants";
import TableList from "../../common/TableList";

function getRelationshipManager(cell, row) {
  if (!row) return null;
  return row.relationshipManager || "--";
}

class AccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: null
    };
  }
  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    const url = ACCOUNTS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ accounts: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const { accounts } = this.state;

    const columnsAccounts = [
      {
        dataField: "entity",
        text: "Entity",
        sort: true,
        headerStyle: {
          width: "35%"
        }
      },
      {
        dataField: "relationshipManager",
        text: "Relationship Manager",
        sort: true,
        formatter: getRelationshipManager,
        csvFormatter: getRelationshipManager,
        filterValue: getRelationshipManager,
        headerStyle: {
          width: "20%"
        }
      },
      {
        dataField: "entityType",
        text: "Entity Type",
        sort: true,
        headerStyle: {
          width: "15%"
        }
      },

      {
        dataField: "advance",
        text: "Advance",
        classes: "text-right",
        headerClasses: "text-right",
        formatter: changeToINR,
        csvType: Number,
        headerStyle: {
          width: "15%"
        }
      },
      {
        dataField: "balance",
        text: "Balance",
        classes: "text-right",
        headerClasses: "text-right",
        formatter: changeToINR,
        csvType: Number,
        headerStyle: {
          width: "15%"
        }
      }
    ];

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsAccounts}
          data={accounts}
          noDataIndication="No Account Balance found."
          sizePerPage="10"
          headerName="Account Balance"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default AccountList;
