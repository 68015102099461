import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AutoLookup from "../AutoLookup";
import Can from "../../../auth/can";

class Building extends Component {
  render() {
    // props
    const {
      //building
      buildingName,
      totalFloors,
      status,
      areaUnit,
      areaPrice,
      seaterPrice,

      //events
      newBuilding,
      onChange,
      submit,

      inputError,

      // auto-lookup
      campus,
      getCampus,

      // for selectors
      statusList,
      areaUnitList
    } = this.props;

    const campusLookupUrl = "api/v1/campuses/search/?name=";
    return (
      <Fragment>
        <div className="card edit-page-container">
          <div className="card-header border-bottom-0 pt-3 text-center">
            <span className="edit-page-title mb-0">
              <Link to="/admin/buildings" className="float-left">
                <i className="fas fa-arrow-left" />
              </Link>
              Building
              <span className="ml-2">
                {newBuilding ? "New Record" : buildingName}
              </span>
            </span>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row mb-0">
                  {newBuilding ? (
                    <Fragment>
                      <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                        Campus
                      </label>
                      <div className="col-12 col-lg-8 col-xl-8">
                        <AutoLookup
                          className={inputError["campus"]}
                          name="name"
                          value={campus}
                          authToken={this.props.authToken}
                          getObject={getCampus}
                          url={campusLookupUrl}
                        />
                        {inputError["campus"] ? (
                          <div className="auto-lookup-empty">
                            Please select campus name *.
                          </div>
                        ) : null}
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                        Campus
                      </label>
                      <div className="col-12 col-lg-8 col-xl-8">
                        <input
                          type="text"
                          className={
                            inputError["campus"]
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          placeholder="campus *"
                          name="campus"
                          value={campus}
                          //onChange={onChange}
                          readOnly
                        />
                      </div>
                    </Fragment>
                  )}
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                    Building Name
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="text"
                      className={
                        inputError["buildingName"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      placeholder="Building Name *"
                      name="buildingName"
                      value={buildingName}
                      onChange={onChange}
                    />
                    {inputError["buildingName"] ? (
                      <div className="invalid-feedback">
                        Please enter building name *.
                      </div>
                    ) : null}
                  </div>
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                    Total Floors
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="text"
                      className={
                        inputError["totalFloors"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      placeholder="Total Floors *"
                      name="totalFloors"
                      value={totalFloors}
                      onChange={onChange}
                    />
                    {inputError["totalFloors"] ? (
                      <div className="invalid-feedback">
                        Please enter total floors *.
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label-required">
                    Building Status
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <select
                      className="custom-select custom-select-sm"
                      name="status"
                      value={status}
                      onChange={onChange}
                    >
                      {statusList.map((sta, idx) => (
                        <option key={idx} value={sta.statusCode}>
                          {sta.statusDesc}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                    Area Unit
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <select
                      className={
                        inputError["areaUnit"]
                          ? "custom-select custom-select-sm is-invalid"
                          : "custom-select custom-select-sm"
                      }
                      name="areaUnit"
                      value={areaUnit ? areaUnit : ""}
                      onChange={onChange}
                    >
                      <option value="">Select ...</option>
                      {areaUnitList.map((unit, idx) => (
                        <option key={idx} value={unit.unitCode}>
                          {unit.unitDesc}
                        </option>
                      ))}
                    </select>
                    {inputError["areaUnit"] ? (
                      <div className="invalid-feedback">
                        Please select unit *.
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                    Rental Price per Sqft
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="number"
                      className={
                        inputError["areaPrice"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      placeholder="Area price *"
                      name="areaPrice"
                      value={areaPrice}
                      onChange={onChange}
                    />
                    {inputError["areaUnit"] ? (
                      <div className="invalid-feedback">
                        Please enter price *.
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                    Seater Price
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <input
                      type="number"
                      className={
                        inputError["seaterPrice"]
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      placeholder="Seater price *"
                      name="seaterPrice"
                      value={seaterPrice}
                      onChange={onChange}
                    />
                    {inputError["areaUnit"] ? (
                      <div className="invalid-feedback">
                        Please enter price *.
                      </div>
                    ) : null}
                  </div>
                </div>

                {newBuilding ? null : (
                  <Fragment>
                    <div className="form-group row mb-0">
                      <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                        Total Area (sqft)
                      </label>
                      <div className="col-12 col-lg-8 col-xl-8">
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          name="totalArea"
                          value={this.props.totalArea}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-0">
                      <label className="col-form-label col-12 col-lg-4 col-xl-4 edit-page-label">
                        Usable Area (sqft)
                      </label>
                      <div className="col-12 col-lg-8 col-xl-8">
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          name="usableArea"
                          value={this.props.usableArea}
                          disabled
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
            <div className="form-group mt-3 row">
              <div className="col-sm-12 text-center">
                {newBuilding ? (
                  <Can I="CREATE" a="Building">
                    <button className="btn btn-sm btn-primary" onClick={submit}>
                      Submit
                    </button>
                  </Can>
                ) : (
                  <Can I="UPDATE" a="Building">
                    <button className="btn btn-sm btn-primary" onClick={submit}>
                      Update
                    </button>
                  </Can>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Building;
