import React, { Component } from "react";
import EventForm from "./EventForm";
import { EVENTS_API } from "../common/Constants";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";
import { Redirect } from "react-router";
class EventEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {}
    };
  }

  getEvents = id => {
    let url = `${EVENTS_API}/${id}`;

    HttpUtil.get(
      url,
      {},
      data => this.setState({ event: this.getUpdatedEvent(data) }),

      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getEvents(id);
    }
  }

  getRequest = values => {
    const request = {};
    this.addField(request, "type", values.type);
    this.addField(request, "name", values.name);
    this.addField(request, "description", values.description);
    this.addField(request, "startDate", values.startDate);
    this.addField(request, "endDate", values.endDate);
    this.addField(request, "website", values.website);
    request.publish = values.publish;

    let lctn = values.location || {};

    let location = {};
    if (lctn && lctn.name) {
      location.name = lctn.name;
    }

    if (lctn.address) {
      location.address = lctn.address;
    }

    if (Object.keys(location).length > 0) {
      request.location = location;
    }

    let orgnsr = values.organizer || {};

    let organizer = {};
    if (orgnsr && orgnsr.name) {
      organizer.name = orgnsr.name;
    }

    if (orgnsr && orgnsr.address) {
      organizer.address = orgnsr.address;
    }
    if (Object.keys(organizer).length > 0) {
      request.organizer = organizer;
    }
    const formdata = new FormData();
    formdata.append("message", JSON.stringify(request));
    if (values.flyer) {
      formdata.append("flyer", values.flyer);
    }
    if (values.brochure) {
      formdata.append("brochure", values.brochure);
    }
    return formdata;
  };

  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const id = this.props.match.params.id;
    const url = `${EVENTS_API}/${id}`;
    const requestData = this.getRequest(values);
    const headers = {};

    HttpUtil.put(
      url,
      headers,
      requestData,
      data => this.setState({ event: data, redirectToDetailsPage: true }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getUpdatedEvent = data => {
    const event = {};

    event._id = data._id || "";
    event.type = data.type || "";
    event.name = data.name || "";
    event.description = data.description || "";
    event.startDate = data.startDate || "";
    event.endDate = data.endDate || "";
    event.website = data.website || "";
    // event.websiteValidate = addHttpToWebsite(data.website);
    event.publish = data.publish;
    event.location = {
      name: (data.location && data.location.name) || "",
      address: (data.location && data.location.address) || ""
    };
    event.organizer = {
      name: (data.organizer && data.organizer.name) || "",
      address: (data.organizer && data.organizer.address) || ""
    };
    event.flyer = data.flyer || "";
    event.brochure = data.brochure || "";

    return event;
  };

  addField = (request, name, value) => {
    if (value) {
      request[name] = value;
    }
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  okConfirmUpdate = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      userConfirmation: true
    });
  };
  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    if (this.state.redirectToDetailsPage) {
      return <Redirect to={`/events/${this.state.event._id}`} />;
    }
    return (
      <div>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <EventForm initialValues={this.state.event} onSubmit={this.onSubmit} />
      </div>
    );
  }
}

export default EventEdit;
