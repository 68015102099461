import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import TableList from "../../common/TableList";
import { GRANT_FUNDING_CYCLE_API } from "../../common/Constants";
import { changeToINR } from "../../common/LookupConstants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import { convertNumberToDate, getDisplayDate } from "../../lease/DateUtil";

function linkFormater(grantId, record) {
  const url = `/funding/funding-cycles/edit/${record._id}`;
  return <Link to={url}>{grantId}</Link>;
}

function displayDateFormate(date) {
  return getDisplayDate(convertNumberToDate(date));
}

const columnsGrantFundingCycle = [
  {
    dataField: "grantId",
    text: "Grant ID",
    sort: true,
    formatter: linkFormater,
    headerStyle: { width: "14.28%" }
  },
  {
    dataField: "grantProgram",
    text: "Grant",
    sort: true,
    formatter: grantProgram => grantProgram.name,
    csvFormatter: grantProgram => grantProgram.name,
    headerStyle: { width: "14.28%" }
  },
  {
    dataField: "startDate",
    text: "Start Date",
    sort: true,
    formatter: displayDateFormate,
    csvFormatter: displayDateFormate,
    headerStyle: { width: "14.28%" }
  },
  {
    dataField: "endDate",
    text: "End Date",
    sort: true,
    formatter: displayDateFormate,
    csvFormatter: displayDateFormate,
    headerStyle: { width: "14.28%" }
  },
  {
    dataField: "allocatedAmount",
    text: "Allocated Amount",
    sort: true,
    csvType: Number,
    formatter: changeToINR,
    headerStyle: { width: "14.28%" }
  },
  {
    dataField: "amountDisburesd",
    text: "Amount Disbursed",
    sort: true,
    csvType: Number,
    formatter: changeToINR,
    headerStyle: { width: "14.28%" }
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    headerStyle: { width: "14.28%" }
  },
  {
    dataField: "numberOfCompaniesFunded",
    text: "Grant Awards",
    csvType: Number,
    sort: true,
    headerStyle: { width: "14.28%" }
  }
];

class GrantFundingCycleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fundingCycleList: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    HttpUtil.get(
      GRANT_FUNDING_CYCLE_API,
      {},
      data => {
        this.setState({ fundingCycleList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumb ">
              <ol className="breadcrumb border-bottom-0">
                <li className="breadcrumb-item">
                  <Link to="/funding/agencies">Funding Agencies</Link>
                </li>
                <li className="breadcrumb-item active">Grants</li>
              </ol>
            </nav>
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsGrantFundingCycle}
          data={this.state.fundingCycleList}
          noDataIndication="No Grants Funding Cycle available."
          sizePerPage="10"
          headerName="Grant Funding Cycle"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default GrantFundingCycleList;

const RenderCustomButton = props => {
  return (
    <Link
      to="/funding/funding-cycles/new"
      role="button"
      className="btn btn-sm text-white bg-pink"
    >
      New
    </Link>
  );
};
