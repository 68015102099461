import React from "react";
import PivotReport from "../PivotReport";
import { checkNaN } from "../ReportsUtil";

const dimensions = [
  { value: "incubateeType", title: "Type" },
  { value: "incubateeStage", title: "Stage" },
  { value: "residentialStatus", title: "Residential Status" },
  { value: "incubationCategory", title: "Category" },
  { value: "incubationSector", title: "Sector" },
  { value: "subTechSector", title: "Sub Sector" },
  { value: "coIncubatee", title: "CoIncubatee" },
  { value: "anchor", title: "Anchor" },
  { value: "name", title: "Incubatee" }
];

function reduce(row, aggRecord) {
  aggRecord.employeeCount =
    (aggRecord.employeeCount || 0) + parseFloat(row.employeeCount);

  aggRecord.femaleEmployeeCount =
    (aggRecord.femaleEmployeeCount || 0) + parseFloat(row.femaleEmployeeCount);

  return aggRecord;
}

const calculations = [
  {
    title: "Employee Count",
    value: "employeeCount",
    template: val => checkNaN(val),
    sortBy: row => checkNaN(row.count)
  },
  {
    title: "Female Employee Count",
    value: "femaleEmployeeCount",
    template: val => checkNaN(val),
    sortBy: row => checkNaN(row.count)
  }
];

function JobGeneratedInfo() {
  return (
    <PivotReport
      title="Jobs Generated Information"
      url="/api/v1/dashboard/incubatee/jobs-generated-info"
      dimensions={dimensions}
      reduce={reduce}
      calculations={calculations}
    />
  );
}

export default JobGeneratedInfo;
