import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { INCUBATEE_TYPES_OBJECT } from "../../common/LookupConstants";

const addAddress = (array, value) => {
  if (value) {
    array.push(value);
  }
};

const getIncubateeObj = props => {
  const { values } = props;

  const incubatee = values.incubatee;

  const incubateeType = incubatee.incubateeType;
  let onboaredIncubatee = {};
  if (incubateeType === "COMPANY") {
    const organization = incubatee.organization;
    onboaredIncubatee = {
      basicInfo: {
        name: organization.name,
        status: values.status,
        type: "Incubatee",
        requestorType: incubateeType
      },
      emails: organization.emails,
      phones: organization.phones,
      identificationDocs: organization.identificationDocs,
      addresses: organization.addresses
    };
  } else if (incubateeType === "INDIVIDUAL") {
    const individual = incubatee.individual;
    let names = [];
    let fname = individual.firstName;
    let lname = individual.lastName;
    names.push(fname);
    names.push(lname);

    onboaredIncubatee = {
      basicInfo: {
        name: names.join(" "),
        status: values.status,
        type: "Incubatee",
        requestorType: incubateeType
      },
      emails: individual.emails,
      phones: individual.phones,
      identificationDocs: individual.identificationDocs,
      addresses: individual.addresses
    };
  } else if (!values.mappedIndividual && !values.mappedOrganization) {
    onboaredIncubatee = {
      basicInfo: {
        name: values.name,
        status: values.status,
        type: "Incubatee",
        requestorType: values.requestorType
      },
      emails: values.emails,
      phones: values.phones,
      identificationDocs: values.identificationDocs,
      addresses: values.addresses
    };
  }

  return onboaredIncubatee;
};

const getNonIncubateeObj = props => {
  const { values } = props;

  const mappedType = values.mappedType;

  let onboaredIncubatee = {};
  if (mappedType === "COMPANY") {
    const mappedOrganization = values.mappedOrganization;

    onboaredIncubatee = {
      basicInfo: {
        name: mappedOrganization.name,
        status: values.status,
        type: "Non-Incubatee",
        requestorType: mappedType
      },
      emails: mappedOrganization.emails,
      phones: mappedOrganization.phones,
      identificationDocs: mappedOrganization.identificationDocs,
      addresses: mappedOrganization.addresses
    };
  } else if (mappedType === "INDIVIDUAL") {
    const mappedIndividual = values.mappedIndividual;
    let names = [];
    let fname = values.mappedIndividual.firstName;
    let lname = values.mappedIndividual.lastName;
    names.push(fname);
    names.push(lname);

    onboaredIncubatee = {
      basicInfo: {
        name: names.join(" "),
        status: values.status,
        type: "Non-Incubatee",
        requestorType: mappedType
      },
      emails: mappedIndividual.emails,
      phones: mappedIndividual.phones,
      identificationDocs: mappedIndividual.identificationDocs,
      addresses: mappedIndividual.addresses
    };
  } else if (!values.mappedIndividual && !values.mappedOrganization) {
    onboaredIncubatee = {
      basicInfo: {
        name: values.name,
        status: values.status,
        type: "Non-Incubatee",
        requestorType: values.requestorType
      },
      emails: values.emails,
      phones: values.phones,
      identificationDocs: values.identificationDocs,
      addresses: values.addresses
    };
  }
  return onboaredIncubatee;
};

const getOnboaredIncubateeObj = props => {
  const { values } = props;

  let onboaredIncubatee = {};
  const incubateeIndicator = values.incubateeIndicator;
  if (!incubateeIndicator) {
    onboaredIncubatee = getNonIncubateeObj(props);
  } else if (incubateeIndicator) {
    onboaredIncubatee = getIncubateeObj(props);
  }
  return onboaredIncubatee;
};

export const TextInput = props => {
  const {
    label,
    required,
    readOnly,
    fieldName,
    touch,
    error,
    helperText
  } = props;

  const labelCssClass = `col-form-label col-12 col-lg-2 col-xl-2 ${
    required ? "required" : ""
  }`;

  return (
    <Fragment>
      <label className={labelCssClass}>{label}</label>
      <div className="col-12 col-lg-4 col-xl-4">
        <Field
          type="text"
          className={`form-control form-control-sm ${
            touch && error ? "is-invalid" : ""
          }`}
          name={fieldName}
          readOnly={readOnly ? true : false}
        />
        <small>{helperText || null}</small>
        <div className="invalid-feedback">{error}</div>
      </div>
    </Fragment>
  );
};

/************************ Rental Information Start ***********************************/
const Equipments = props => {
  const { values } = props;

  const rows = values.equipments.map((equt, idx) => {
    return (
      <tr key={idx}>
        <th scope="row">{idx + 1}</th>
        <td>{equt.equipment}</td>
        <td>{equt.quantity}</td>
      </tr>
    );
  });
  return (
    <Fragment>
      <div className="row mt-3">
        <div className="col-md-12">
          <strong>Requested Assets </strong>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          {rows.length > 0 ? (
            <div className="table-responsive-sm">
              <table className="table table-sm table-borderless">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Equipment Name</th>
                    <th scope="col">Quantity</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

const ContactInfo = props => {
  const { incubateeIndicator } = props;

  if (incubateeIndicator) {
    return null;
  }
  return (
    <Fragment>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
          Phone
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <label className="col-12 col-lg-8 col-xl-8">{props.phone}</label>
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
          Email
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <label className="col-12 col-lg-8 col-xl-8">{props.email}</label>
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-4 col-xl-4 ">PAN</label>
        <div className="col-12 col-lg-8 col-xl-8">
          <label className="col-12 col-lg-8 col-xl-8">{props.pan}</label>
        </div>
      </div>
    </Fragment>
  );
};

const BasicInfo = props => {
  const incubateeIndicator = props.incubateeIndicator;
  return (
    <Fragment>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-4 col-xl-4 ">Name</label>
        <div className="col-12 col-lg-8 col-xl-8">
          <label className="col-12 col-lg-8 col-xl-8">{props.name}</label>
        </div>
      </div>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
          Requestor Type
        </label>
        <div className="col-12 col-lg-8 col-xl-8">
          <label className="col-12 col-lg-8 col-xl-8">
            {INCUBATEE_TYPES_OBJECT[props.requestorType]}
          </label>
        </div>
      </div>
      {incubateeIndicator ? null : (
        <div className="form-group row mb-0">
          <label className="col-form-label col-12 col-lg-4 col-xl-4 ">
            Address
          </label>
          <div className="col-12 col-lg-8 col-xl-8">
            <label className="col-12 col-lg-8 col-xl-8">
              {props.addressString}
            </label>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const RentalInformation = props => {
  const { values } = props;

  const requestorType = values.requestorType;

  const email = values.emails[0] ? values.emails[0].email : "--";
  const phone = values.phones[0] ? values.phones[0].phone : "--";

  const pan = values.identificationDocs[0]
    ? values.identificationDocs[0].number
    : "--";

  const address = values.addresses[0] ? values.addresses[0] : "";

  let addrs = [];
  addAddress(addrs, address.streetLine1);
  addAddress(addrs, address.streetLine2);
  addAddress(addrs, address.landmark);
  addAddress(addrs, address.city);
  addAddress(addrs, address.state);
  addAddress(addrs, address.postalCode);
  addAddress(addrs, address.country);
  let addressString = addrs.join(",");

  let basicInfoProps = {};
  if (values.incubateeIndicator) {
    basicInfoProps = {
      name: values.incubatee.name,
      requestorType: values.incubatee.incubateeType,
      incubateeIndicator: values.incubateeIndicator
    };
  } else {
    basicInfoProps = {
      name: values.name,
      requestorType: requestorType,
      addressString,
      incubateeIndicator: values.incubateeIndicator
    };
  }

  const contactProps = {
    email,
    phone,
    pan,
    incubateeIndicator: values.incubateeIndicator
  };
  return (
    <div className="row mb-3">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header bg-card-header text-white">
            <span className="card-title mb-0">
              <Link
                to="/asset/enquiries"
                className="badge bg-white custom-btn-sm float-left"
              >
                <i className="fas fa-arrow-left" />
              </Link>
              <div className="text-center">Rental Request Information</div>
            </span>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <BasicInfo {...basicInfoProps} />
              </div>
              <div className="col-md-6">
                <ContactInfo {...contactProps} />
              </div>
            </div>
            <hr />
            <Equipments {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

/************************ Rental Information End ***********************************/

/************************ OnboardedIncubateeInformation End ***********************************/
const OnboardedIncubateeInformation = props => {
  const onboaredIncubatee = getOnboaredIncubateeObj(props);

  const basicInfo = onboaredIncubatee.basicInfo;
  const phones = onboaredIncubatee.phones;
  const emails = onboaredIncubatee.emails;
  const addresses = onboaredIncubatee.addresses;
  const idDocs = onboaredIncubatee.identificationDocs;

  const incubateeIndicator = props.values.incubateeIndicator;

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header bg-card-header text-white">
              <span className="card-title mb-0">
                <div className="text-center">Onboarded Entity Information</div>
              </span>
            </div>
            <div className="card-body mb-5">
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">Basic Information</h6>
                    </div>
                    <div className="card-body">
                      <div className="form-group row mb-0">
                        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
                          Name
                        </label>
                        <label className="col-form-label col-12 col-lg-10 col-xl-10">
                          {basicInfo.name} &nbsp;&nbsp;
                          <span
                            className={`badge ${
                              incubateeIndicator
                                ? "badge-success"
                                : "badge-warning"
                            } badge-pill`}
                          >
                            {basicInfo.type}
                          </span>
                        </label>
                      </div>

                      <div className="form-group row mb-0">
                        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
                          Status
                        </label>
                        <label className="col-form-label col-12 col-lg-10 col-xl-10">
                          {basicInfo.status}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {addresses.length > 0 ? (
                  <Fragment>
                    {addresses.map((addr, idx) => {
                      return (
                        <div key={idx} className="col-md-6 col-lg-6 col-xl-6">
                          <div className="card">
                            <div className="card-header">
                              <h6 className="mb-0">Address - {addr.type}</h6>
                            </div>
                            <div className="card-body">
                              <p className="card-text">
                                {addr.streetLine1}
                                <br />
                                {`${addr.city}-${addr.postalCode}`}
                                <br />
                                {addr.state}
                                <br />
                                {addr.country}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                ) : null}
              </div>

              <div className="row mb-3 mt-5">
                <div className="col-md-6 col-lg-4 col-xl-4">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <ul className="list-group list-group-flush">
                      {phones.length > 0 ? (
                        <Fragment>
                          {phones.map((ph, idx) => {
                            return (
                              <li
                                key={idx}
                                className="list-group-item d-flex justify-content-between align-items-center p-2"
                              >
                                {ph.phone}
                                <span className="badge badge-primary badge-pill">
                                  {ph.type}
                                </span>
                              </li>
                            );
                          })}
                        </Fragment>
                      ) : (
                        "No data available"
                      )}
                    </ul>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4 col-xl-4">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">Email</h6>
                    </div>
                    <ul className="list-group list-group-flush">
                      {emails.length > 0 ? (
                        <Fragment>
                          {emails.map((em, idx) => {
                            return (
                              <li
                                key={idx}
                                className="list-group-item d-flex justify-content-between align-items-center p-2"
                              >
                                {em.email}
                                <span className="badge badge-primary badge-pill">
                                  {em.type}
                                </span>
                              </li>
                            );
                          })}
                        </Fragment>
                      ) : (
                        "No data available"
                      )}
                    </ul>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4 col-xl-4">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">Identification Documents</h6>
                    </div>
                    <ul className="list-group list-group-flush">
                      {idDocs.length > 0 ? (
                        <Fragment>
                          {idDocs.map((doc, idx) => {
                            return (
                              <li
                                key={idx}
                                className="list-group-item d-flex justify-content-between align-items-center p-2"
                              >
                                {doc.number}
                                <span className="badge badge-primary badge-pill">
                                  {doc.type}
                                </span>
                              </li>
                            );
                          })}
                        </Fragment>
                      ) : (
                        "No data available"
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
/************************ OnboardedIncubateeInformation End ***********************************/

const AssetEnuiryForm = props => {
  const { values } = props;

  let renderBtn = "";
  if (values.incubateeIndicator) {
    renderBtn = (
      <button
        className="btn btn-sm btn-primary"
        type="submit"
        disabled={props.isSubmitting}
      >
        Onboard
      </button>
    );
  } else {
    renderBtn = (
      <button
        className="btn btn-sm btn-primary"
        type="submit"
        disabled={props.isSubmitting}
      >
        Create
      </button>
    );
  }
  return (
    <Form>
      <RentalInformation {...props} />
      <OnboardedIncubateeInformation {...props} />

      <div className="form-group row mb-5 mt-5">
        <div className="col-md-12 text-center">{renderBtn}</div>
      </div>
    </Form>
  );
};

class AssetEnquiryFormik extends Component {
  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        validationSchema={this.props.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          this.props.handleSubmit(values, { setSubmitting });
        }}
        render={({ values, isSubmitting, errors, touched, setFieldValue }) => {
          const formProps = {
            values,
            isSubmitting,
            errors,
            touched,
            setFieldValue,

            handleApiFailed: this.props.handleApiFailed
          };
          return <AssetEnuiryForm {...formProps} />;
        }}
      />
    );
  }
}

export default AssetEnquiryFormik;
