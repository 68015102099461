// Login.js
export const USER_LOGIN_API = "/api/v1/auth/login";

export const USERS_API = "/api/v1/users";
export const USER_ROLES_API = "/api/v1/roles";

export const USERS_CURRENT_API = "api/v1/users/current";

export const USER_CHANGE_PASSWORD_API = "/api/v1/users/change-password";

export const USER_LOGOUT_API = "/api/v1/auth/logout";

export const INCUBATORS_API = "api/v1/incubators";
export const SUPPLEMENTARY_INCUBATORS_API = "api/v1/supplementary-incubators";
export const INCUBATEES_API = "api/v1/incubatees";
export const INDIVIDUALS_API = "/api/v1/individuals";
export const ORGANIZATIONS_API = "/api/v1/organizations";
export const INTERNAL_ORGS_API = "api/v1/incubators/orgs";

export const FUND_AGENCY_TYPE_API = "api/v1/incubatee/fund-agency-types";
export const FUND_AGENCY_API = "api/v1/incubatee/fund-agencies";
export const FUND_PROGRAMME_API = "api/v1/incubatee/fund-programmes";
export const GRANT_PROGRAM_API = "api/v1/invest-mgmt/grants";
export const GRANT_FUNDING_CYCLE_API =
  "api/v1/invest-mgmt/grant-funding-cycles";
export const GRANT_AWARDS_API = "api/v1/invest-mgmt/grant-awards";

//Location.js
export const LOCATION_LIST_API = "/users/locationlist";

//CampusList.js
export const CAMPUS_API = "api/v1/campuses";

//BuildingList.js
export const BUILDING_API = "api/v1/buildings";

//FloorList.js
export const FLOOR_API = "api/v1/floors";

//PartitionList.js
export const PARTITION_API = "api/v1/partitions";

export const STATUS_LIST_API = "/api/v1/statuses/?statusType=";
export const AREA_UNIT_LIST_API = "/api/v1/area-units";
export const PARTITION_TYPE_LIST_API = "/api/v1/partition-types";
export const PARTITION_AVAILABILITY_API = "/api/v1/partitionavailability";

export const LEASE_API = "/api/v1/leases";
export const LEASE_TERMINATIONS_API = "/api/v1/lease/terminations";

export const LEASE_ACTIONS_API = "api/v1/leases/actions";
export const VACANT_PARTITIONS_SEARCH_API = "api/v1/partitions/search-vacancy";

export const INCUBATOR_APPLICATION_API = "/api/v1/incubator/applications";
export const INCUBATEES_ONBOARD_API = "/api/v1/incubatees/onboard";

export const GENDERS_API = "/api/v1/genders";
export const STATES_API = "/api/v1/states";
export const COUNTRIES_API = "/api/v1/countries";

export const PAYMENTS_API = "/api/v1/finance/payments";
export const ADJUSTMENTS_API = "/api/v1/finance/adjustments";
export const MONTHLY_REPORTS_API = "/api/v1/finance/reports";
export const CHARGES_API = "/api/v1/finance/charges";
export const ACCOUNTS_API = "/api/v1/finance/accounts";

export const DASHBOARD_PENDING_ITEMS_API = "/api/v1/dashboard/pending-items";
export const FACILITY_COUNT_BY_CAMPUS_API =
  "/api/v1/dashboard/facility/count-by-campus";

export const LEASE_STATISTICS_API =
  "/api/v1/dashboard/facility/lease-incubatee-count";

export const LEASE_SPACE_STATISTICS_API =
  "/api/v1/dashboard/facility/lease-space-seat-count";

export const LEASE_STATS_BY_YEAR =
  "/api/v1/dashboard/facility/lease-stats-last-year";

export const INCUBATEE_COUNT_BY_SECTOR_API =
  "/api/v1/dashboard/incubatee/incubatee-count-by-sector";
export const INCUBATEE_COUNT_BY_GRANTS_API =
  "/api/v1/dashboard/incubatee/incubatee-count-by-grants";
export const PARTITION_CATEGORY_STATS_API =
  "/api/v1/dashboard/incubatee/partition-usage-by-category";

export const TOTAL_PARTITION_USAGE_API =
  "/api/v1/dashboard/incubatee/total-space-usage";

export const USER_PERMISSIONS_URL = "api/v1/users/current/permissions";

export const EQUIPMENTS_API = "/api/v1/equipments";
export const ASSETS_API = "/api/v1/assets";
export const ASSETS_LIST_API = "/api/v1/assets/all/list";
export const RENTAL_REQUEST_API = "/api/v1/rental-requests";
export const REQUESTOR_ONBOARD_API = "/api/v1/requestor-onboard";
export const ASSET_LEASES_API = "/api/v1/asset-lease";
export const ASSET_RELEASE_API = "/api/v1/asset-release";
export const ASSET_RETURN_API = "/api/v1/asset-return";

export const PUBLIC_API = "/api/v1/public";

export const FACILITY_CLUSTERS_API = "/api/v1/facility-clusters";

export const GRANT_APPLICATION_API =
  "/api/v1/incubator/applications/grant-application";

export const INCUBATEE_APP_FORMS_API =
  "/api/v1/incubator/applications/incubatee-application";

export const INCUBATEE_APP_FORMS_INPROGRESS_API =
  "/api/v1/incubator/applications/in-progress";

export const OFFICE_SPACE_AVAILABILITY_API =
  "/api/v1/dashboard/incubatee/office-space-availability";

export const DESK_SPACE_AVAILABILITY_API =
  "/api/v1/dashboard/incubatee/desk-space-availability";

export const INCUBATEES_ONGOING_COUNT =
  "/api/v1/dashboard/incubatee/ongoing-count";

export const PHYSICAL_INCUBATEES_ONGOING_COUNT =
  "/api/v1/dashboard/incubatee/physical-ongoing-count";

export const ANNUAL_TURNOVER_STATS_API =
  "/api/v1/dashboard/incubatee/aggregated-turnover-stats";

export const JOBS_GENERATED_STATS_API =
  "/api/v1/dashboard/incubatee/jobs-generated";
export const PARTITION_SPACE_USAGE_API =
  "/api/v1/dashboard/partition/space-usage";
export const CLUSTER_USAGE_STATS_API =
  "/api/v1/dashboard/partition/cluster-usage";

export const ADJUSTMENTS_FOR_ADVANCE_API =
  "/api/v1/finance/advance-adjustments";

export const MILESTONE_API = "/api/v1/milestones";

export const DASHBOARD_LIST_COUNTS = "/api/v1/dashboard/list-counts";

export const INCUBATEE_GRADUATION_API = "/api/v1/incubatee/graduation-requests";
export const INCUBATEE_GRADUATION_INPROGRESS_API =
  "/api/v1/incubatee/graduation-requests/open-requests";

export const FINANCIAL_SEARCH_API = "/api/v1/finance/search";

export const ACCOUNT_CLOSURE_REQUESTES_API = "/api/v1/finance/account-closures";

export const CURRENT_INCUBATOR_API = "/api/v1/incubators/current";

export const GRANT_PROGRESS_TEMPLATES_API =
  "/api/v1/invest-mgmt/grant-progress-templates";

export const RENTAL_RATE_UNIT_TYPES_API =
  "/api/v1/service-mgmt/rental-rate-unit-types";

export const RENTAL_RATE_CARD_TYPES_API =
  "/api/v1/service-mgmt/rental-rate-card-types";

export const INCUBATOR_ENQUIRIES_API =
  "/api/v1/service-mgmt/incubator-enquiries";

export const PARTITION_CATEGORIES_API = "/api/v1/partition-categories";

export const ASSET_RESERVATION_REQUEST_API =
  "/api/v1/service-mgmt/asset-reservation-requests";

export const SEARCH_INDVL_ORG_API = "/api/v1/search/individual-organization";

export const INCUBATEE_STATS_BY_CLUSTER_API =
  "/api/v1/dashboard/incubatee/cluster";

export const RELATIONSHIP_MANAGERS_API =
  "/api/v1/incubators/relationship-managers";

export const ASSET_TYPES_API = "/api/v1/asset-types";

export const ASSET_LOOKUP_API = "api/v1/assets/lookup";

export const ASSET_AVAILABILITY_API =
  "api/v1/service-mgmt/asset-reservation-requests/asset-availabilty";

export const ASSET_USAGES_API = "/api/v1/asset-usages";

export const ASSET_RESERVATIONS_API =
  "/api/v1/service-mgmt/asset-reservation-requests/asset-reservations";

  export const EVENTS_API = "api/v1/events";