import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoLookup from "../../custom/AutoLookup";
import { renderInputData, PAYEE_TYPES } from "../../common/LookupConstants";
import { getDisplayDate, addDays } from "../../lease/DateUtil";

class ManualChargeForm extends Component {
  render() {
    const {
      customerName,
      getCustomer,
      customerLookupUrl,
      inputError,

      // events
      handleCustomerType,
      handleDateChange,
      handleAmountChange,
      handleNextForm,
      handleInputChange,
      handleUniPrice
    } = this.props;

    const {
      customerType,
      customer,
      //   item,
      //   itemType,
      chargeDate,
      dueDate,
      description,
      unitPriced,
      price,
      quantity,
      amount
    } = this.props.manualCharge;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title mb-0">Manual Charge Form</span>
            </div>
            <div className="card-body">
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Customer Type
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    name="customerType"
                    value={renderInputData(customerType)}
                    onChange={handleCustomerType}
                    className={
                      inputError["customerType"]
                        ? "custom-select custom-select-sm is-invalid"
                        : "custom-select custom-select-sm"
                    }
                  >
                    <option value="">Select...</option>
                    {PAYEE_TYPES.map((pt, i) => (
                      <option key={i} value={pt.key}>
                        {pt.value}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    Please select customer type *
                  </div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Select Entity
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <AutoLookup
                    className={inputError["customer"]}
                    name={customerName}
                    value={customer}
                    getObject={getCustomer}
                    url={customerLookupUrl}
                  />
                  {inputError["customer"] ? (
                    <div className="auto-lookup-empty">
                      Please select customer name *.
                    </div>
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Charge Description
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    name="description"
                    value={renderInputData(description)}
                    onChange={handleInputChange}
                    className={
                      inputError["description"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                  />
                  <div className="invalid-feedback">
                    Please enter any description *
                  </div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Unit Priced
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control custom-control-input"
                      id="unitPriced123"
                      name="unitPriced"
                      checked={unitPriced}
                      onChange={handleInputChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="unitPriced123"
                    />
                    Yes
                  </div>
                </div>
              </div>
              {unitPriced ? (
                <Fragment>
                  <div className="form-group row mb-0">
                    <label
                      className={`col-form-label col-12 col-lg-2 col-xl-2 ${
                        unitPriced
                          ? "edit-page-label-required"
                          : "edit-page-label"
                      }`}
                    >
                      Price
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <input
                        type="number"
                        name="price"
                        value={renderInputData(price)}
                        onChange={handleUniPrice}
                        className={
                          inputError["price"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                      />
                      <div className="invalid-feedback">
                        Please enter price *
                      </div>
                    </div>
                  </div>
                  <div className="form-group row mb-0">
                    <label
                      className={`col-form-label col-12 col-lg-2 col-xl-2 ${
                        unitPriced
                          ? "edit-page-label-required"
                          : "edit-page-label"
                      }`}
                    >
                      Quantity
                    </label>
                    <div className="col-12 col-lg-4 col-xl-4">
                      <input
                        type="number"
                        name="quantity"
                        value={renderInputData(quantity)}
                        onChange={handleUniPrice}
                        className={
                          inputError["quantity"]
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                      />
                      <div className="invalid-feedback">
                        Please enter quantity *
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : null}

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Amount
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="number"
                    name="amount"
                    value={renderInputData(amount)}
                    onChange={handleAmountChange}
                    className={
                      inputError["amount"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                  />
                  <div className="invalid-feedback">Please enter amount *</div>
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Charge date
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <DatePicker
                    value={chargeDate ? getDisplayDate(chargeDate) : ""}
                    selected={chargeDate ? chargeDate : null}
                    onChange={handleDateChange.bind(this, "chargeDate")}
                    utcOffset={0}
                    minDate={new Date()}
                    placeholderText="dd-mm-yyyy"
                    className={
                      inputError["chargeDate"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />

                  {inputError["chargeDate"] ? (
                    <div className="auto-lookup-empty">
                      Please select payment date *.
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label">
                  Due date
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <DatePicker
                    value={dueDate ? getDisplayDate(dueDate) : ""}
                    selected={dueDate ? dueDate : null}
                    onChange={handleDateChange.bind(this, "dueDate")}
                    utcOffset={0}
                    minDate={new Date()}
                    maxDate={addDays(60)}
                    placeholderText="dd-mm-yyyy"
                    className={
                      inputError["chargeDate"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />

                  {inputError["chargeDate"] ? (
                    <div className="auto-lookup-empty">
                      Please select payment date *.
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row mt-3 mb-0">
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleNextForm}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManualChargeForm;
