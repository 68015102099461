import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoLookup from "../../custom/AutoLookup";
import { PAYEE_TYPES, renderInputData } from "../../common/LookupConstants";
import { getDisplayDate } from "../../lease/DateUtil";

class PaymentAdjustmentForm extends Component {
  render() {
    const {
      payeeType,
      adjustmentTo,
      adjustmentAmount,
      date,
      adjustmentReason,
      remarks,

      payeeName,
      payeeLookupUrl,
      handleInputChange,
      handleAmountChange,
      handlePayeeType,
      getPayee,
      handleNextForm,
      inputError
    } = this.props;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card edit-page-container">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title mb-0">Payment Adjustment</span>
            </div>
            <div className="card-body">
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Entity Type
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <select
                    name="payeeType"
                    value={renderInputData(payeeType)}
                    onChange={handlePayeeType}
                    className={
                      inputError["payeeType"]
                        ? "custom-select custom-select-sm is-invalid"
                        : "custom-select custom-select-sm"
                    }
                  >
                    <option value="">Select...</option>
                    {PAYEE_TYPES.map((pt, i) => (
                      <option key={i} value={pt.key}>
                        {pt.value}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">
                    Please select payee type *
                  </div>
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Select Entity
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <AutoLookup
                    className={inputError["adjustmentTo"]}
                    name={payeeName}
                    value={adjustmentTo}
                    getObject={getPayee}
                    url={payeeLookupUrl}
                  />
                  {inputError["adjustmentTo"] ? (
                    <div className="auto-lookup-empty">
                      Please select payee name *.
                    </div>
                  ) : null}
                </div>
              </div>
              <hr />

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Amount
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="number"
                    name="adjustmentAmount"
                    min="0"
                    value={renderInputData(adjustmentAmount)}
                    onChange={handleAmountChange}
                    className={
                      inputError["adjustmentAmount"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                  />
                  <div className="invalid-feedback">
                    Please enter adjustment amount *
                  </div>
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Adjustment date
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <DatePicker
                    value={date ? getDisplayDate(date) : ""}
                    selected={date ? date : null}
                    // onChange={handleDateChange.bind(this, "date")}
                    utcOffset={0}
                    disabled={true}
                    placeholderText="dd-mm-yyyy"
                    className={
                      inputError["date"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />

                  {inputError["date"] ? (
                    <div className="auto-lookup-empty">
                      Please select payment date *.
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Adjustment Reason
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <input
                    type="text"
                    name="adjustmentReason"
                    value={renderInputData(adjustmentReason)}
                    onChange={handleInputChange}
                    className={
                      inputError["adjustmentReason"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                  />
                  <div className="invalid-feedback">
                    Please enter adjustment reason *
                  </div>
                </div>
              </div>

              <div className="form-group row mb-0">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Notes / Remarks
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <textarea
                    name="remarks"
                    className={
                      inputError["remarks"]
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    value={renderInputData(remarks)}
                    onChange={handleInputChange}
                  />
                  <div className="invalid-feedback">Please enter remarks *</div>
                </div>
              </div>

              <div className="form-group row mt-3 mb-0">
                <div className="col-6 text-right">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleNextForm}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentAdjustmentForm;
