import React, { Component } from "react";

class DefaultAlert extends Component {
  render() {
    const { show, alertMode, message,closeAlert } = this.props;

    if(!show){
        return null
    }


    const alertList = {
      success: "alert alert-success",
      danger: "alert alert-danger"
    };

    const cssClassName = alertList[alertMode]

    return (
      <div>
        <div className={cssClassName} role="alert">
          {" "}
          {message}
          <i
            className="fas fa-times"
            style={{ float: "right", cursor: "pointer" }}
            onClick={closeAlert.bind(this, alertMode)}
          />
        </div>
      </div>
    );
  }
}

export default DefaultAlert;
