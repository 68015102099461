import React, { Component } from "react";
import Download from "downloadjs";
import OnboardForm from "./form/OnboardForm";
import {
  STATES_API,
  COUNTRIES_API,
  RELATIONSHIP_MANAGERS_API,
  GENDERS_API,
  INCUBATEES_ONBOARD_API
} from "../../common/Constants";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";

import {
  DEFAULT_ONBOARD_FORM,
  transformServerToUi,
  transformUiToApi,
  loadDocumentsToApi
} from "./OnboardController";
import { ONBOARD_FORM_SCHEMA } from "./ValidationSchema";
import ErrorPage from "../../common/error.page";

function getFormData(values) {
  let formData = new FormData();
  const onboardForm = transformUiToApi(values);
  loadDocumentsToApi(formData, onboardForm, values);
  formData.append("message", JSON.stringify(onboardForm));
  return formData;
}

class Onboard extends Component {
  constructor(args) {
    super(args);
    this.state = {
      onboardForm: DEFAULT_ONBOARD_FORM,
      states: [],
      countries: [],
      genders: [],
      relationshipManagers: [],
      permanentFailure: false
    };
  }

  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      showAlert: true,
      alertType: "Default",
      alertColor: "danger",
      alertMessage: message,
      rejectConfirmation: false,
      formValues: "",
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      //Default alert
      showAlert: false,
      alertType: "",
      alertColor: "",
      alertMessage: ""
    });
  };

  downloadDocument = (file, e) => {
    e.preventDefault();

    if (file === "") {
      return;
    }

    let fileId = file._id;
    let filename = file.filename;

    const url = `${INCUBATEES_ONBOARD_API}/${this.state.onboardForm._id}/documents/${fileId}`;

    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(blob => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString(), true);
      });
  };

  toggleRejectConfirmation = values => {
    const rejectConfirmation = this.state.rejectConfirmation;

    if (!rejectConfirmation) {
      this.setState({
        showAlert: true,
        alertType: "Confirmation",
        alertColor: "danger",
        alertMessage:
          "Rejecting this form will cancel the onborading process for this incubatee. You have to start with new application to onboard the incubatee again. Do you want to reject this application ?",
        rejectConfirmation: !this.state.rejectConfirmation,
        formValues: values
      });
    }

    if (rejectConfirmation) {
      const formValues = this.state.formValues;
      this.handleSubmit(formValues);
    }
  };

  getStates = () => {
    const url = STATES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ states: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getGenders = () => {
    const url = GENDERS_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ genders: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getRelationshipManager = () => {
    const url = RELATIONSHIP_MANAGERS_API;

    HttpUtil.get(
      url,
      {},
      data => {
        let relationshipManagers = [];
        if (data.length > 0) {
          relationshipManagers = data.map(relationshipmanager => {
            let relationmanager = {};
            relationmanager.label = relationshipmanager.name;
            relationmanager.value = relationshipmanager._id;
            return relationmanager;
          });
        }
        this.setState({
          relationshipManagers: relationshipManagers
        });
      },
      (data, status) => {
        this.props.handleApiFailed(
          data.message,
          status === 403 || status === 404
        );
      },
      error => this.props.handleApiFailed(error.toString(), true)
    );
  };

  getUpdatedMessage = data => {
    const stage = data.stage;
    const status = data.status;

    const TYPE_STAGE_STATUS_DESC = {
      "INIT|CREATED": "Onboarding form has been updated and it is in progress",
      "APPR1|PENDING":
        "Onboarding form has been updated and it is sent for Manager Approval",
      "APPR2|PENDING":
        "Onboarding form has been updated and it is sent for Manager Approval",
      "COMPLETE|REJECTED": "Onboarding form has been rejected",
      "INIT|REWORK": "Onboarding form has been sent for rework",
      "COMPLETE|APPROVED":
        "Onboarding form has been completed and incubatee has been onboarded"
    };
    const key = `${stage}|${status}`;
    return TYPE_STAGE_STATUS_DESC[key] || "Onboarding form has been updated";
  };

  handleSubmit = values => {
    const rejectConfirmation = this.state.rejectConfirmation;
    if (!rejectConfirmation && values.action === "REJECT") {
      this.toggleRejectConfirmation(values);
      return;
    }

    const formData = getFormData(values);

    const url = `${INCUBATEES_ONBOARD_API}/${this.state.onboardForm._id}`;
    const headers = {};

    HttpUtil.put(
      url,
      headers,
      formData,
      data => {
        this.getOnboardForm(data._id);
        const alertMessage = this.getUpdatedMessage(data);
        this.setState({
          _id: data._id,
          actions: data.actions,
          status: data.status,
          stage: data.stage,
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: alertMessage,
          rejectConfirmation: false,
          formValues: ""
        });
        window.scrollTo(0, 0);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getOnboardForm = id => {
    const url = `${INCUBATEES_ONBOARD_API}/${id}`;
    const headers = {};

    HttpUtil.get(
      url,
      headers,
      data => {
        const onboardForm = transformServerToUi(data);
        this.setState({ onboardForm });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount = () => {
    if (this.props.match && this.props.match.params.id) {
      const onboardFormId = this.props.match.params.id;
      this.getOnboardForm(onboardFormId);
    }

    this.getStates();
    this.getCountries();
    this.getGenders();
    this.getRelationshipManager();
  };

  render() {
    if (this.state.permanentFailure) {
      return <ErrorPage message={this.state.alertMessage} />;
    }

    return (
      <div>
        {/* show alert message  */}
        <AlertComponent
          show={this.state.showAlert}
          type={this.state.alertType}
          alertColor={this.state.alertColor}
          message={this.state.alertMessage}
          close={this.closeDefaultAlert}
          confirm={this.toggleRejectConfirmation}
        />

        <div className="card edit-page-container">
          <OnboardForm
            onboardForm={this.state.onboardForm}
            validationSchema={ONBOARD_FORM_SCHEMA}
            handleSubmit={this.handleSubmit}
            downloadDocument={this.downloadDocument}
            states={this.state.states}
            countries={this.state.countries}
            genders={this.state.genders}
            relationshipManagers={this.state.relationshipManagers}
          />
        </div>
      </div>
    );
  }
}

export default Onboard;
