import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoLookup from "../../custom/AutoLookup";
import { INCUBATEE_TYPES_2 } from "../../common/LookupConstants";
import { getDisplayDate, convertDateToNumber } from "../../lease/DateUtil";
import HttpUtil from "../../common/HttpUtil";
import { FINANCIAL_SEARCH_API } from "../../common/Constants";
import ChargesSearchResult from "./ChargesSearchResult";
import AlertComponent from "../../common/AlertComponent";
import PaymentSearchResult from "./PaymentSearchResult";
import AdjustmentSearchResult from "./AdjustmentSearchResult";
import AdvanceAdjustmentSearchResult from "./AdvanceAdjustmentSearchResult";
import RefundSearchResult from "./RefundSearchResults";

function validate(values) {
  let filterApplied = values.entityType && values.entity;

  filterApplied = filterApplied || values.fromDate !== "";

  if (!filterApplied) {
    return false;
  }

  let resultTypeSelected = false;
  resultTypeSelected = resultTypeSelected || values.charges;
  resultTypeSelected = resultTypeSelected || values.payments;
  resultTypeSelected = resultTypeSelected || values.adjustments;
  resultTypeSelected = resultTypeSelected || values.advanceAdjustments;
  resultTypeSelected = resultTypeSelected || values.refunds;
  return filterApplied && resultTypeSelected;
}

const SearchForm = props => {
  const { values, setFieldValue } = props;

  let filterApplied = validate(values);

  return (
    <Fragment>
      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Entity Type
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Field
            component="select"
            name="entityType"
            value={values.entityType || ""}
            className="custom-select custom-select-sm"
            onChange={e => {
              if (e.target.value) {
                const URL_MAPPER = {
                  Individual: {
                    url: "api/v1/individuals/lookup/?name="
                  },
                  Organization: {
                    url: "api/v1/organizations/lookup/?name="
                  }
                };

                let entityLookupUrl = null;

                if (e.target.value !== null) {
                  entityLookupUrl = URL_MAPPER[e.target.value].url;
                }
                setFieldValue("entityType", e.target.value);
                setFieldValue("entity", "");
                setFieldValue("entityLookupUrl", entityLookupUrl);
              } else {
                setFieldValue("entityType", "");
                setFieldValue("entity", "");
                setFieldValue("entityLookupUrl", null);
              }
            }}
          >
            <option value="">Select...</option>
            {INCUBATEE_TYPES_2.map((pt, i) => (
              <option key={i} value={pt.key}>
                {pt.value}
              </option>
            ))}
          </Field>
          <div className="invalid-feedback">Please select customer type *</div>
        </div>

        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          Select Entity
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <AutoLookup
            name={"name"}
            value={values.entity ? values.entity : null}
            getObject={value => {
              setFieldValue("entity", value);
            }}
            url={values.entityLookupUrl}
          />
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          From Date
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <DatePicker
            value={values.fromDate ? getDisplayDate(values.fromDate) : ""}
            selected={values.fromDate ? values.fromDate : null}
            onChange={date => {
              setFieldValue("fromDate", date);
            }}
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className="form-control form-control-sm"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>

        <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
          To Date
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <DatePicker
            value={values.toDate ? getDisplayDate(values.toDate) : ""}
            selected={values.toDate ? values.toDate : null}
            onChange={date => {
              setFieldValue("toDate", date);
            }}
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className="form-control form-control-sm"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
      </div>

      <div className="form-group row mb-0">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">Show</label>
        <div className="col-12 col-lg-10 col-xl-10 d-md-flex align-items-center justifiy-content-between">
          <div className="custom-control custom-checkbox">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
              name="charges"
              checked={values.charges}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Charges
            </label>
          </div>
          <div className="custom-control custom-checkbox ml-md-5">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="customCheck2"
              name="payments"
              checked={values.payments}
            />
            <label className="custom-control-label" htmlFor="customCheck2">
              Payments
            </label>
          </div>
          <div className="custom-control custom-checkbox ml-md-5">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="customCheck3"
              name="adjustments"
              checked={values.adjustments}
            />
            <label className="custom-control-label" htmlFor="customCheck3">
              Adjustments
            </label>
          </div>
          <div className="custom-control custom-checkbox ml-md-5">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="customCheck4"
              name="advanceAdjustments"
              checked={values.advanceAdjustments}
            />
            <label className="custom-control-label" htmlFor="customCheck4">
              Advance Adjustments
            </label>
          </div>

          <div className="custom-control custom-checkbox ml-md-5">
            <Field
              type="checkbox"
              className="custom-control-input"
              id="customCheck5"
              name="refunds"
              checked={values.refunds}
            />
            <label className="custom-control-label" htmlFor="customCheck5">
              Refunds
            </label>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-12 text-center">
          {filterApplied ? (
            <button className="btn btn-sm btn-primary" type="submit">
              Search
            </button>
          ) : (
            <button className="btn btn-sm btn-primary" type="button" disabled>
              Search
            </button>
          )}

          <button
            className="btn btn-sm btn-outline-secondary ml-2"
            onClick={() => {
              setFieldValue("entityType", "");
              setFieldValue("entity", "");
              setFieldValue("fromDate", "");
              setFieldValue("toDate", "");
              setFieldValue("charges", false);
              setFieldValue("adjustments", false);
              setFieldValue("advanceAdjustments", false);
              setFieldValue("payments", false);
              setFieldValue("refunds", false);
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </Fragment>
  );
};

const SEARCH_SCHEMA = {};
class FinancialSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        entityType: "",
        entity: "",
        fromDate: "",
        toDate: "",
        charges: false,
        adjustments: false,
        advanceAdjustments: false,
        payments: false,
        refunds: false
      },

      searchResults: null,

      showCharges: false,
      showAdjustments: false,
      showAdvanceAdjustment: false,
      showPayments: false,
      showRefunds: false
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getRequest = values => {
    const searchRequest = {};

    if (values.entityType) {
      searchRequest.entityType = values.entityType;
    }

    if (values.entity && values.entity.value) {
      searchRequest.entity = values.entity.value;
    }

    if (values.fromDate) {
      let date = new Date(values.fromDate);
      date = convertDateToNumber(date);
      searchRequest.fromDate = date;
    }

    if (values.toDate) {
      let date = new Date(values.toDate);
      date = convertDateToNumber(date);
      searchRequest.toDate = date;
    }

    if (values.charges) {
      searchRequest.charges = values.charges;
    }

    if (values.adjustments) {
      searchRequest.adjustments = values.adjustments;
    }

    if (values.advanceAdjustments) {
      searchRequest.advanceAdjustments = values.advanceAdjustments;
    }

    if (values.payments) {
      searchRequest.payments = values.payments;
    }

    if (values.refunds) {
      searchRequest.refunds = values.refunds;
    }

    return searchRequest;
  };

  handleSubmit = (values, { setSubmitting }) => {
    // console.log("submit: values:", JSON.stringify(values, null, 2));
    setSubmitting(true);
    const searchRequest = this.getRequest(values);

    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      FINANCIAL_SEARCH_API,
      headers,
      searchRequest,
      data => {
        setSubmitting(false);
        this.setState({
          searchResults: data,
          showCharges: values.charges,
          showAdjustments: values.adjustments,
          showAdvanceAdjustment: values.advanceAdjustments,
          showPayments: values.payments,
          showRefunds: values.refunds
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  render() {
    const props = {
      initialValues: this.state.initialValues,
      validationSchema: SEARCH_SCHEMA,
      handleSubmit: this.handleSubmit
    };

    const resultProps = {
      searchResults: this.state.searchResults,
      showCharges: this.state.showCharges,
      showAdjustments: this.state.showAdjustments,
      showAdvanceAdjustment: this.state.showAdvanceAdjustment,
      showPayments: this.state.showPayments,
      showRefunds: this.state.showRefunds
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12 text-center">
            <h5 className="font-weight-bold" style={{ color: "#647acb" }}>
              Finance Search
            </h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card shadow">
              <div className="card-header border-bottom-0 pt-3 text-center">
                <span className="edit-page-title" style={{ color: "#647acb" }}>
                  Search
                </span>
              </div>

              <div className="card-body">
                <div className="row no-gutters">
                  <div className="col-md-12">
                    <Formik
                      enableReinitialize
                      initialValues={props.initialValues}
                      validationSchema={props.validationSchema}
                      onSubmit={(values, { setSubmitting }) =>
                        props.handleSubmit(values, { setSubmitting })
                      }
                      render={renderProps => (
                        <Form>
                          <SearchForm {...renderProps} />
                        </Form>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SearchResults {...resultProps} />
      </Fragment>
    );
  }
}

export default FinancialSearch;

export const SearchResults = props => {
  const {
    searchResults,
    showCharges,
    showAdjustments,
    showAdvanceAdjustment,
    showPayments,
    showRefunds
  } = props;

  if (searchResults === null) {
    return null;
  }

  return (
    <Fragment>
      <div className="row mt-4 mb-5">
        <div className="col-md-12">
          <div className="card shadow">
            <div className="card-header border-bottom-0 pt-3 text-center">
              <span className="edit-page-title" style={{ color: "#647acb" }}>
                Search Results
              </span>
            </div>

            <div className="card-body">
              {showCharges ? (
                <ChargesSearchResult searchResults={searchResults} />
              ) : null}
              {showPayments ? (
                <PaymentSearchResult searchResults={searchResults} />
              ) : null}
              {showAdjustments ? (
                <AdjustmentSearchResult searchResults={searchResults} />
              ) : null}
              {showAdvanceAdjustment ? (
                <AdvanceAdjustmentSearchResult searchResults={searchResults} />
              ) : null}

              {showRefunds ? (
                <RefundSearchResult searchResults={searchResults} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
