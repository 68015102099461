import React, { Component } from "react";

const MERGE_OPTIONS = {
  EXISTING: 0,
  NEW: 1,
  BOTH: 2
};

class DiffViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // do stuff
      compareResults: [],
      userValue: "",
      objectChildren: [],
      objectArrayChildren: [],
      simpleObjectArrayChildren: [],
      changedData: props.data[0]
    };
  }

  onChange = (index, e) => {
    e.preventDefault();
    const compareResults = this.state.compareResults;
    let fieldData = compareResults[index];

    if (fieldData.type === "number" && isNaN(e.target.value)) {
      return;
    }

    if (fieldData.type === "array") {
      fieldData[e.target.name].push(e.target.value);
    } else {
      fieldData[e.target.name] = e.target.value;
    }

    const changedData = this.state.changedData;
    changedData[fieldData.name] = fieldData.mergedValue;

    return this.setState(
      { compareResults: compareResults, changedData },
      this.onDataChange(changedData)
    );
  };

  handleUserValue = e => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRadioButtons = (option, index) => {
    const compareResults = this.state.compareResults;
    let fieldData = compareResults[index];

    switch (option) {
      case MERGE_OPTIONS.EXISTING:
        fieldData.mergeOption = option;
        fieldData.mergedValue = fieldData.existingValue;
        break;
      case MERGE_OPTIONS.NEW:
        fieldData.mergeOption = option;
        fieldData.mergedValue = fieldData.newValue;
        break;
      case MERGE_OPTIONS.BOTH:
        fieldData.mergeOption = option;
        let existingArrayValue = [...fieldData.existingValue];
        let newArrayValue = [...fieldData.newValue];
        let tags = [...existingArrayValue, ...newArrayValue];
        let uniqueData = [...new Set(tags)];
        fieldData.mergedValue = uniqueData;
        break;
      default:
        break;
    }

    const changedData = this.state.changedData;
    changedData[fieldData.name] = fieldData.mergedValue;

    return this.setState(
      { compareResults, changedData },
      this.onDataChange(changedData)
    );
  };

  handleAddData = (index, e) => {
    e.preventDefault();
    const { userValue } = this.state;
    if (userValue === "") {
      return;
    }
    const compareResults = this.state.compareResults;
    let fieldData = compareResults[index];
    let mergedValue = fieldData.mergedValue;
    mergedValue.push(userValue);
    fieldData.mergedValue = mergedValue;

    const changedData = this.state.changedData;
    changedData[fieldData.name] = fieldData.mergedValue;

    return this.setState(
      {
        compareResults: compareResults,
        changedData,
        userValue: ""
      },
      this.onDataChange(changedData)
    );
  };

  handleEdit = (row, idx, e) => {
    e.preventDefault();
    const compareResults = this.state.compareResults;
    let fieldData = compareResults[row];
    if (fieldData.mergeOption !== MERGE_OPTIONS.BOTH) {
      return;
    }
    let mergedValue = fieldData.mergedValue;
    mergedValue[idx] = e.target.value;
    fieldData.mergedValue = mergedValue;

    const changedData = this.state.changedData;
    changedData[fieldData.name] = fieldData.mergedValue;

    return this.setState(
      { compareResults: compareResults, changedData },
      this.onDataChange(changedData)
    );
  };

  handleRemove = (row, idx, e) => {
    e.preventDefault();

    const compareResults = this.state.compareResults;
    let fieldData = compareResults[row];

    let mergedValue = fieldData.mergedValue.filter(
      (data, index) => index !== idx
    );
    fieldData.mergedValue = mergedValue;

    const changedData = this.state.changedData;
    changedData[fieldData.name] = fieldData.mergedValue;

    return this.setState(
      { compareResults: compareResults, changedData },
      this.onDataChange(changedData)
    );
  };

  handleFormData = () => {
    const { changedData } = this.state;
    this.props.formData(changedData);
  };

  onDataChange = changedData => {
    const { name, dataChange } = this.props;
    if (dataChange) {
      dataChange(name, changedData);
    }
  };

  componentDidMount = () => {
    const {
      schema,
      data: [data1, data2]
    } = this.props;

    const compareResults = [];
    const children = [];
    const objectArrayChildren = [];
    const simpleObjectArrayChildren = [];
    const changedData = this.state.changedData;
    for (const propKey in schema.properties) {
      const prop = schema.properties[propKey];
      const data1Prop = data1[propKey];
      const data2Prop = data2[propKey];

      let hasComplexObject = false;
      if (prop.type === "array" && prop.items.type === "object") {
        const newSchema = schema.properties[propKey].items;
        for (const newKey in newSchema.properties) {
          hasComplexObject = newSchema.properties[newKey].type === "object";
          // if (hasComplexObject) {
          //   console.log("hasComplex:propKey:",propKey)
          //   break;
          // }
        }

        if (hasComplexObject) {
          const combined = [];
          data1Prop.forEach(value =>
            combined.push({ status: "EXISTING", data: value })
          );
          data2Prop.forEach(value =>
            combined.push({ status: "NEW", data: value })
          );
          objectArrayChildren.push({
            name: propKey,
            type: prop.type,
            title: prop.title,
            existingValue: data1Prop,
            newValue: data2Prop,
            mergedValue: combined,
            schema: newSchema
          });
        } else {
          const combined = [];
          data1Prop.forEach(value =>
            combined.push({ status: "EXISTING", data: value })
          );
          data2Prop.forEach(value =>
            combined.push({ status: "NEW", data: value })
          );

          simpleObjectArrayChildren.push({
            name: propKey,
            type: prop.type,
            title: prop.title,
            existingValue: data1Prop,
            newValue: data2Prop,
            mergedValue: combined,
            schema: newSchema
          });
          changedData[propKey] = combined;
        }
        continue;
      }

      if (prop.type === "object") {
        children.push({
          name: propKey,
          type: prop.type,
          title: prop.title,
          existingValue: data1Prop,
          newValue: data2Prop,
          schema: schema.properties[propKey]
        });
        continue;
      }

      let mergedValue;
      let mergeOption = "";
      if (
        prop.type === "string" ||
        prop.type === "integer" ||
        prop.type === "number"
      ) {
        if (data1Prop) {
          mergedValue = data1Prop;
          mergeOption = MERGE_OPTIONS.EXISTING;
        } else {
          mergedValue = data2Prop;
          mergeOption = MERGE_OPTIONS.NEW;
        }
      }

      if (prop.type === "array") {
        mergeOption = MERGE_OPTIONS.BOTH;
        let existingArrayValue = data1Prop;
        let newArrayValue = data2Prop;
        let tags = [...existingArrayValue, ...newArrayValue];
        let uniqueData = [...new Set(tags)];
        mergedValue = uniqueData;
      }

      compareResults.push({
        name: propKey,
        type: prop.type,
        title: prop.title,
        existingValue: data1Prop ? data1Prop : "",
        newValue: data2Prop ? data2Prop : "",
        mergeOption: mergeOption,
        mergedValue: mergedValue
      });
      changedData[propKey] = mergedValue;
    }
    this.setState({
      compareResults,
      children,
      objectArrayChildren,
      simpleObjectArrayChildren,
      changedData
    });
  };

  addSimpleObjectArrayChildren = dataIndex => {
    const { simpleObjectArrayChildren } = this.state;
    const simpleObjectArray = simpleObjectArrayChildren[dataIndex];
    const mergedValue = simpleObjectArray.mergedValue;
    const properties = simpleObjectArray.schema.properties;
    const lastFieldValue = mergedValue[mergedValue.length - 1];

    const inputEmpty = [];
    for (const key in properties) {
      if (lastFieldValue.data[key] === "") {
        inputEmpty.push(key);
      }
    }
    if (inputEmpty.length > 0 || inputEmpty.length !== 0) {
      return;
    }

    let addData = {};
    let dataValue = {};
    for (const key in properties) {
      dataValue[key] = "";
      addData = {
        status: "",
        data: { ...dataValue }
      };
    }

    mergedValue.push(addData);
    this.setState({ simpleObjectArrayChildren });
  };

  editSimpleObjectArrayChildren = (dataIndex, childId, e) => {
    let targetName = "",
      targetValue = "";
    if (e.target.type === "checkbox") {
      targetName = e.target.name;
      if (e.target.checked) {
        targetValue = e.target.checked;
      } else {
        targetValue = e.target.checked;
      }
    } else {
      targetName = e.target.name;
      targetValue = e.target.value;
    }

    const { simpleObjectArrayChildren } = this.state;
    const simpleObjectArray = simpleObjectArrayChildren[dataIndex];
    const mergedValue = simpleObjectArray.mergedValue;
    const fieldValue = mergedValue[childId];
    const data = fieldValue.data;
    data[targetName] = targetValue;

    const changedData = this.state.changedData;
    this.setState(
      { simpleObjectArrayChildren, changedData },
      this.onDataChange(changedData)
    );
  };

  removeSimpleObjectArrayChildren = (dataIndex, childId) => {
    const { simpleObjectArrayChildren } = this.state;
    const simpleObjectArray = simpleObjectArrayChildren[dataIndex];
    const mergedValue = simpleObjectArray.mergedValue;
    mergedValue.splice(childId, 1);
    const changedData = this.state.changedData;
    changedData[simpleObjectArray.name] = mergedValue;
    this.setState(
      { simpleObjectArrayChildren, changedData },
      this.onDataChange(changedData)
    );
  };

  render() {
    const { compareResults } = this.state;

    const list = compareResults.map((element, index) => {
      let mergedValue = element.mergedValue;
      const useExisting = element.mergeOption === MERGE_OPTIONS.EXISTING;
      const useNew = element.mergeOption === MERGE_OPTIONS.NEW;
      const useMergeBoth = element.mergeOption === MERGE_OPTIONS.BOTH;
      let listData = [];

      if (element.type === "array" && useExisting) {
        listData = [...element.existingValue];
      }
      if (element.type === "array" && useNew) {
        listData = [...element.newValue];
      }
      if (element.type === "array" && useMergeBoth) {
        listData = [...element.mergedValue];
      }

      return (
        <tr key={element.name}>
          <td>{element.title}</td>
          <td>
            {element.type === "array" ? (
              <ul>
                {element.existingValue.map((data, i) => (
                  <li key={i}>{data}</li>
                ))}
              </ul>
            ) : (
              element.existingValue
            )}
          </td>
          <td>
            {element.type === "array" ? (
              <ul>
                {element.newValue.map((data, i) => (
                  <li key={i}>{data}</li>
                ))}
              </ul>
            ) : (
              element.newValue
            )}
          </td>
          <td>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                className="custom-control-input"
                id={`${element.name}Existing`}
                name={`${element.name}Existing`}
                group={`merge${element.name}`}
                checked={useExisting}
                onChange={this.handleRadioButtons.bind(
                  this,
                  MERGE_OPTIONS.EXISTING,
                  index
                )}
              />
              <label
                className="custom-control-label"
                htmlFor={`${element.name}Existing`}
              >
                Use Existing data
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input
                type="radio"
                className="custom-control-input"
                id={`${element.name}KeyNew`}
                name={`${element.name}KeyNew`}
                group={`merge${element.name}`}
                checked={useNew}
                onChange={this.handleRadioButtons.bind(
                  this,
                  MERGE_OPTIONS.NEW,
                  index
                )}
              />
              <label
                className="custom-control-label"
                htmlFor={`${element.name}KeyNew`}
              >
                Use new data
              </label>
            </div>
            {element.type === "array" ? (
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  id={`${element.name}MergeBoth`}
                  name={`${element.name}MergeBoth`}
                  group={`merge${element.name}`}
                  checked={useMergeBoth}
                  onChange={this.handleRadioButtons.bind(
                    this,
                    MERGE_OPTIONS.BOTH,
                    index
                  )}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`${element.name}MergeBoth`}
                >
                  Merge Both
                </label>
              </div>
            ) : null}
          </td>
          <td>
            {element.type === "array" ? (
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingRight: 5
                }}
              >
                {listData.map((value, idx) => {
                  return (
                    <div
                      key={idx}
                      className="input-group-prepend"
                      style={{ marginBottom: 5 }}
                    >
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        value={value}
                        name={element.name}
                        onChange={this.handleEdit.bind(this, index, idx)}
                      />
                      &nbsp;
                      <div
                        className="input-group-text"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Remove"
                        onClick={this.handleRemove.bind(this, index, idx)}
                      >
                        <i className="far fa-trash-alt" />
                      </div>
                    </div>
                  );
                })}
                <div
                  className="input-group-prepend"
                  style={{ marginBottom: 5 }}
                >
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    value={this.state.userValue}
                    name="userValue"
                    onChange={this.handleUserValue}
                  />
                  &nbsp;
                  <div
                    className="input-group-text"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add"
                    onClick={this.handleAddData.bind(this, index)}
                  >
                    <i className="fas fa-plus" />
                  </div>
                </div>
              </div>
            ) : (
              <input
                style={{ marginBottom: 5 }}
                className="form-control form-control-sm"
                type="text"
                value={mergedValue}
                name="mergedValue"
                onChange={this.onChange.bind(this, index)}
              />
            )}
          </td>
        </tr>
      );
    });

    let propertyDiff = null;
    if (compareResults.length > 0) {
      propertyDiff = (
        <table className="table table-bordered table-striped table-fixed">
          <caption style={{ captionSide: "top" }}>
            <h5>{this.props.name}</h5> Please update correct information for
            <strong>
              <mark>Biospot</mark>{" "}
            </strong>{" "}
            Incubatee.
          </caption>
          <thead>
            <tr>
              <th>Property</th>
              <th>Existing Data</th>
              <th>New Data</th>
              <th>Merge Option</th>
              <th>Merged Data</th>
            </tr>
          </thead>
          <tbody>{list}</tbody>
        </table>
      );
    }
    const objectChildren = this.state.objectChildren.map(child => (
      <DiffViewer
        key={child.name}
        name={child.title}
        schema={child.schema}
        data={[child.existingValue, child.newValue]}
        level={this.props.level + 1}
      />
    ));

    const objectArrayChildrenUi = this.state.simpleObjectArrayChildren.map(
      (child, index) => {
        const tableHeader = [];
        for (const key in child.schema.properties) {
          const title = child.schema.properties[key].title;
          const type = child.schema.properties[key].type;
          tableHeader.push({ title: title, key: key, type: type });
        }
        const mergedValue = child.mergedValue;

        if (mergedValue.length === 0) {
          return null;
        }

        

        return (
          <div
            key={index}
            className="table-responsive"
            style={{ marginTop: 10 }}
          >
            <table className="table table-sm table-bordered table-striped table-fixed">
              <caption style={{ captionSide: "top" }}>
                <h5>{child.title}</h5> Please update correct information for
                <strong>
                  <mark>Biospot</mark>{" "}
                </strong>{" "}
                Incubatee.
              </caption>
              <thead>
                <tr>
                  <th className="th-center th-vertical-middle">S.no</th>
                  {tableHeader.map((header, i) => (
                    <th key={i}> {header.title} </th>
                  ))}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {mergedValue.map((obj, idx) => {
                  const isNew = obj.status === "NEW";
                  return (
                    <tr key={idx} className={isNew ? "diff-new" : "diff-existing"}>
                      <td className="td-center td-vertical-middle">
                        {idx + 1}
                      </td>
                      {tableHeader.map((property, i) => (
                        <td key={i} className="td-center td-vertical-middle">
                          {/* {obj[property]} defaultChecked= {obj.data[property.key]} */}

                          {property.type === "boolean" ? (
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name={property.key}
                                id={`checkbox${idx}`}
                                checked={obj.data[property.key]}
                                onChange={this.editSimpleObjectArrayChildren.bind(
                                  this,
                                  index,
                                  idx
                                )}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`checkbox${idx}`}
                              />
                              
                            </div>
                          ) : (
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              name={property.key}
                              value={obj.data[property.key]}
                              onChange={this.editSimpleObjectArrayChildren.bind(
                                this,
                                index,
                                idx
                              )}
                            />
                          )}
                        </td>
                      ))}
                      <td>
                        <span
                          className="input-group-text input-group-sm d-inline"
                          data-toggle="tooltip"
                          title="Remove"
                          onClick={this.removeSimpleObjectArrayChildren.bind(
                            this,
                            index,
                            idx
                          )}
                        >
                          <i className={isNew ? "far fa-trash-alt" :"far fa-trash-alt existing-bg-dark" } />
                        </span>
                        &nbsp;
                        {mergedValue.length - 1 === idx ? (
                          <span
                            className="input-group-text d-inline"
                            data-toggle="tooltip"
                            title="Add"
                            onClick={this.addSimpleObjectArrayChildren.bind(
                              this,
                              index
                            )}
                          >
                            <i className="fas fa-plus" />
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      }
    );

    /* ====> Hold complex object temporary
    const complexObjectUi = this.state.objectArrayChildren.map(
      (object, index) => {
        const complexObjectList = object.mergedValue.map((compObject, idx) => {
          let listOfData = [];
          for (const key in compObject.data) {
            let value = compObject.data[key];
            let isObject = value.constructor === Object;
            let child = [];
            if (isObject) {
              for (const childKey in value) {
                let listValue = value[childKey];
                child.push(
                  <ul key={childKey} className="list-group">
                    <li className="list-group-item ">{`${childKey} : ${listValue}`}</li>
                  </ul>
                );
              }
              listOfData.push(child);
            } else {
              listOfData.push(
                <ul key={key} className="list-group">
                  <li className="list-group-item ">{`${key} : ${value}`}</li>
                </ul>
              );
            }
          }

          const cssColor = compObject.status === "NEW" ? "badge badge-pill badge-success" : "badge badge-pill badge-info"
          
          return (
            <div key={idx} className="col-md-6">
              <div className="card" style={{ marginBottom: 10 }}>
                <div className="card-header">
                 {compObject.data.name}&nbsp;
                 { 
                    <span className={cssColor}>{compObject.status}</span>
                 }
                  <button
                    type="button"
                    //onClick={close}
                    data-toggle="tooltip"
                    title="Close Information"
                    className="close pull-right"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="card-body">{listOfData}</div>
              </div>
            </div>
          );
        });

        return (
          <fieldset key={index} className="scheduler-border ">
            <legend className="scheduler-border">{object.title}</legend>

            <caption style={{ captionSide: "top-outside" }}>
              <h5>{this.props.name}</h5> Please update correct information for
              <strong>
                <mark>Biospot</mark>{" "}
              </strong>{" "}
              Incubatee.
            </caption>

            <div className="row">{complexObjectList}</div>
          </fieldset>
        );
      }
    ); */

    return (
      <div>
        {propertyDiff}
        {objectArrayChildrenUi}
        {objectChildren}
        {/* {complexObjectUi} ====> Hold complex object temporary */}
      </div>
    );
  }

  dummy = () => {};
}

export default DiffViewer;
