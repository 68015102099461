import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Download from "downloadjs";
import DatePicker from "react-datepicker";
import { Formik, Form, Field } from "formik";
import { getDisplayDate } from "../../lease/DateUtil";
import { RENTAL_RATES_TYPES_OBJECT } from "../../common/LookupConstants";
import { getDescription } from "../../common/Util";
import { ASSET_LEASES_API } from "../../common/Constants";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

function getBasicInformation(values) {
  const requestor = values.requestor;

  let name = "";
  if (values.requestorType === "Individual") {
    name = requestor.firstName;
  } else {
    name = requestor.name;
  }

  const basicInfo = {
    requestorType: values.requestorType,
    name
  };

  return basicInfo;
}

const ErrorComponent = props => {
  const { fieldName, touch, error, datePicker } = props;
  let renderComponent = "";

  if (datePicker) {
    renderComponent = (
      <div className="auto-lookup-empty">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  } else {
    renderComponent = (
      <div className="invalid-feedback">
        {touch[`${fieldName}`] && error[`${fieldName}`]}
      </div>
    );
  }
  return renderComponent;
};

const RequestedAssets = props => {
  const { values, setFieldValue, errors, touched, readOnly } = props;

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  return (
    <Fragment>
      <div className="row mb-2">
        <div className="col-md-12">
          <strong>Requested Assets </strong>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive-sm table-responsive-md">
            <table className="table table-sm table-striped my-0">
              <thead>
                <tr>
                  <th style={{ width: "14.28%" }}>Asset Id</th>
                  <th style={{ width: "14.28%" }}>Asset Type</th>
                  <th style={{ width: "14.28%" }}>Asset Name</th>
                  <th style={{ width: "14.28%" }}>Start Date</th>
                  <th style={{ width: "14.28%" }}>End Date</th>
                  <th style={{ width: "14.28%" }}>Advance</th>
                  <th style={{ width: "14.28%" }}>Rates</th>
                </tr>
              </thead>
              <tbody>
                {values.leasedAssets.length > 0 ? (
                  <Fragment>
                    {values.leasedAssets.map((lsAst, index) => {
                      const startDate = lsAst.startDate || null;
                      const endDate = lsAst.endDate || "";

                      const rentalRates = lsAst.asset.rentalRates.map(
                        rental => {
                          const rate = `${rental.rate}/`;
                          const unit = rental.unit > 1 ? `${rental.unit}/` : "";
                          const unitType = `${
                            RENTAL_RATES_TYPES_OBJECT[rental.unitType]
                          }`;

                          const rateOption = {
                            label: `${rate}${unit}${unitType}`,
                            value: `${rate}${unit}${unitType}`
                          };
                          return rateOption;
                        }
                      );

                      const error =
                        errors.leasedAssets && errors.leasedAssets[index]
                          ? errors.leasedAssets[index]
                          : {};
                      const touch =
                        touched.leasedAssets && touched.leasedAssets[index]
                          ? touched.leasedAssets[index]
                          : {};

                      const errorProps = { error, touch };

                      let deleteBtn = null;
                      let trCssClass = "";
                      if (
                        (!readOnly && lsAst.asset.rentalStatus) ||
                        lsAst.asset.status !== "ACTIVE"
                      ) {
                        deleteBtn = (
                          <td>
                            <button
                              type="button"
                              className="btn btn-outline-danger btn-sm"
                              title="Remove"
                              onClick={e => {
                                const leasedAssets = values.leasedAssets.filter(
                                  lsdAsset =>
                                    lsdAsset.asset._id !== lsAst.asset._id
                                );

                                setFieldValue(`leasedAssets`, leasedAssets);
                              }}
                            >
                              <i className="far fa-trash-alt " />
                            </button>
                          </td>
                        );

                        trCssClass = "table-danger";
                      }
                      return (
                        <tr key={`${index}`} className={trCssClass}>
                          <td>{lsAst.asset.assetId}</td>
                          <td>{lsAst.asset.assetType}</td>
                          <td>{lsAst.asset.assetName}</td>
                          <td>
                            <DatePicker
                              value={
                                lsAst.startDate !== null
                                  ? getDisplayDate(lsAst.startDate)
                                  : ""
                              }
                              selected={startDate}
                              onChange={e => {
                                setFieldValue(
                                  `leasedAssets[${index}].startDate`,
                                  e
                                );
                              }}
                              utcOffset={0}
                              placeholderText="dd-mm-yyyy"
                              className={getErrorCssClassName(
                                touch,
                                error,
                                "startDate"
                              )}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              disabled={readOnly}
                            />
                            <ErrorComponent
                              fieldName="startDate"
                              datePicker={true}
                              {...errorProps}
                            />
                          </td>
                          <td>
                            <DatePicker
                              value={getDisplayDate(endDate) || ""}
                              selected={endDate || null}
                              onChange={e => {
                                setFieldValue(
                                  `leasedAssets[${index}].endDate`,
                                  e
                                );
                              }}
                              utcOffset={0}
                              placeholderText="dd-mm-yyyy"
                              className={getErrorCssClassName(
                                touch,
                                error,
                                "endDate"
                              )}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              disabled={readOnly}
                            />
                            <ErrorComponent
                              fieldName="endDate"
                              datePicker={true}
                              {...errorProps}
                            />
                          </td>
                          <td>
                            <Field
                              type="text"
                              className={getErrorCssClassName(
                                touch,
                                error,
                                "advance"
                              )}
                              name={`leasedAssets[${index}].advance`}
                              value={lsAst.advance || ""}
                              {...disabledField}
                            />
                            <ErrorComponent
                              fieldName="advance"
                              {...errorProps}
                            />
                          </td>
                          <td>
                            <Field
                              component="select"
                              name={`leasedAssets[${index}].rate`}
                              value={lsAst.rate || ""}
                              type="text"
                              className={`custom-select custom-select-sm ${
                                touch["rate"] && error["rate"]
                                  ? "is-invalid"
                                  : ""
                              }`}
                              {...disabledField}
                            >
                              <option value="">Select...</option>
                              {rentalRates.map((rt, i) => (
                                <option key={i} value={rt.value}>
                                  {rt.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorComponent fieldName="rate" {...errorProps} />
                          </td>
                          {deleteBtn || null}
                        </tr>
                      );
                    })}
                  </Fragment>
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center text-danger">
                      <span>No record found</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const BasicInformation = props => {
  const { values } = props;
  const basicInfo = getBasicInformation(props.values);
  return (
    <Fragment>
      <div className="row mb-2">
        <div className="col-md-12">
          <strong>Basic Information </strong>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="form-group row mb-0">
            <label className="col-form-label col-12 col-lg-2 col-xl-2 ">
              Name
            </label>
            <label className="col-form-label col-12 col-lg-4 col-xl-4">
              {basicInfo.name}
            </label>
            <label className="col-form-label col-12 col-lg-2 col-xl-2 pl-xl-0 pr-lg-0 pl-lg-0 pr-xl-0">
              Requestor Type
            </label>
            <label className="col-form-label col-12 col-lg-4 col-xl-4">
              {basicInfo.requestorType}
            </label>
          </div>
        </div>
        <div className="col-md-4 text-left">
          <div className="row">
            <h5>
              <span className="sidebar-badge badge badge-md badge-light">{`Status : ${getDescription(
                "AssetLease",
                values.stage,
                values.status
              )}`}</span>
            </h5>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Documents = props => {
  const { setFieldValue, values, readOnly } = props;
  const downloadDocument = (doc, e) => {
    e.preventDefault();

    if (doc === "") {
      return;
    }

    let filename = doc.filename;
    const url = `${ASSET_LEASES_API}/${values._id}/asset-lease-documents/${
      doc._id
    }`;

    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(blob => {
        Download(blob, filename);
      })
      .catch(error => {
        props.handleApiFailed(error.toString());
      });
  };

  return (
    <Fragment>
      <div className="row mb-2">
        <div className="col-md-12">
          <strong>Documents </strong>
        </div>
      </div>
      <div className="form-group mt-xl-2 mb-0 row">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Upload Document
        </label>
        <div className="col-12 col-lg-4 col -xl-4">
          <input
            type="file"
            name="documents"
            className="form-control-file"
            multiple
            disabled={values.assetLeaseDocuments.length >= 3 || readOnly}
            onChange={event => {
              setFieldValue("documents", event.currentTarget.files);
            }}
          />
          <small>Max : 3 files</small>
        </div>
      </div>
      {values.assetLeaseDocuments.length > 0 ? (
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="table-responsive-sm table-responsive-md">
              <table className="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">File Name</th>
                    <th scope="col">Upload At</th>
                    <th scope="col" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {values.assetLeaseDocuments.map((doc, index) => {
                    return (
                      <tr key={`${index}`}>
                        <th scope="row">{index + 1}</th>
                        <td>{doc.filename}</td>
                        <td>{getDisplayDate(doc.uploadDate)}</td>
                        <td className="text-center">
                          <i
                            className="fas fa-download text-info"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download"
                            onClick={downloadDocument.bind(this, doc)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

const MyLeaseForm = props => {
  const { values, setFieldValue } = props;
  const disabledField =
    values.leasedAssets.length === 0
      ? { readOnly: "readonly", disabled: "disabled" }
      : {};

  return (
    <Form>
      <BasicInformation {...props} />
      <hr />
      <RequestedAssets {...props} />
      <hr />
      <Documents {...props} />
      <hr />
      <div className="row">
        <div className="col-md-12 text-center">
          {values.actions.length > 0
            ? values.actions.map((action, idx) => {
                return (
                  <Fragment key={idx}>
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        setFieldValue("action", action);
                      }}
                      {...disabledField}
                    >
                      {action}
                    </button>
                    &nbsp;&nbsp;
                  </Fragment>
                );
              })
            : null}
        </div>
      </div>
    </Form>
  );
};

class AssetLeaseForm extends Component {
  render() {
    return (
      <div>
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-card-header text-white">
                <span className="card-title mb-0">
                  <Link
                    to={`/asset/leases`}
                    className="badge bg-white custom-btn-sm"
                  >
                    <i className="fas fa-arrow-left" />
                  </Link>
                  &nbsp;Asset Lease Form
                </span>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={this.props.initialValues}
                  validationSchema={this.props.validationSchema}
                  onSubmit={(values, { setSubmitting }) =>
                    this.props.handleSubmit(values, { setSubmitting })
                  }
                  render={({
                    values,
                    onSubmit,
                    isSubmitting,
                    errors,
                    touched,
                    setFieldValue
                  }) => {
                    const formProps = {
                      values,
                      onSubmit,
                      isSubmitting,
                      errors,
                      touched,
                      setFieldValue,
                      readOnly: values.actions.length === 0,
                      handleApiFailed: this.props.handleApiFailed
                    };
                    return <MyLeaseForm {...formProps} />;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetLeaseForm;
