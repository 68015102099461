import React, { Component, Fragment } from "react";
import { getDescription } from "../../common/Util";
import { getDateAndTimeFormat } from "../../lease/DateUtil";
import {
  INCUBATEE_TYPES_OBJECT,
  PRIORITY_TYPES_OBJECT
} from "../../common/LookupConstants";
import { INCUBATOR_ENQUIRIES_API } from "../../common/Constants";
import TableList from "../../common/TableList";
import AlertComponent from "../../common/AlertComponent";
import HttpUtil from "../../common/HttpUtil";
import { Link } from "react-router-dom";

function linkFormater(cell, row) {
  let requestorName = "";
  if (row.incubatee) {
    requestorName = row.nameOfIncubatee.name;
  } else if (row.otherIncubator) {
    requestorName = row.name;
  } else if (row.incubateeType === "COMPANY") {
    requestorName = row.organizationName;
  } else if (row.incubateeType === "INDIVIDUAL") {
    requestorName = row.name;
  }

  const url = `/service-mgmt/enquiries/view/${row._id}?from=/service-mgmt/closed-enquiries`;
  return <Link to={url}>{requestorName}</Link>;
}

function statusFormatter(cell, row, rowIndex, formatExtraData) {
  return getDescription("IncubatorEnquiry", row[formatExtraData], cell);
}

function ourIncubatee(cell, row) {
  let ourInc = "";
  if (row.incubatee) {
    ourInc = "Yes";
  } else {
    ourInc = "No";
  }
  return ourInc;
}

function requestorType(cell, row) {
  let reqType = "";
  if (row.incubatee) {
    reqType = row.nameOfIncubatee.incubateeType;
    reqType = INCUBATEE_TYPES_OBJECT[reqType] || "Unspecified";
  } else if (row.otherIncubator) {
    reqType = "Other Incubator";
  } else {
    reqType = row.incubateeType;
    reqType = INCUBATEE_TYPES_OBJECT[reqType] || "Unspecified";
  }

  return reqType;
}

function getRequestorName(cell, row) {
  let requestorName = "";
  if (row.incubatee) {
    requestorName = row.nameOfIncubatee.name;
  } else if (row.otherIncubator) {
    requestorName = row.name;
  } else if (row.incubateeType === "COMPANY") {
    requestorName = row.organizationName;
  } else if (row.incubateeType === "INDIVIDUAL") {
    requestorName = row.name;
  }

  return requestorName;
}

function requestedAt(cell, row) {
  let createdAt = row.createdAt;
  createdAt = getDateAndTimeFormat(createdAt);
  return createdAt;
}

const ENQUIRY_LIST_COLUMNS = [
  {
    dataField: "name",
    text: "Requestor",
    formatter: linkFormater,
    csvFormatter: getRequestorName,
    filterValue: getRequestorName,
    headerStyle: {
      width: "26.66%"
    }
  },
  {
    dataField: "priority",
    text: "Priority",
    formatter: cell => PRIORITY_TYPES_OBJECT[cell] || "--",
    csvFormatter: cell => PRIORITY_TYPES_OBJECT[cell] || "--",
    filterValue: cell => PRIORITY_TYPES_OBJECT[cell] || "--",
    headerStyle: {
      width: "11.66%"
    }
  },
  {
    dataField: "incubateeType",
    text: "Requestor Type",
    formatter: requestorType,
    csvFormatter: requestorType,
    filterValue: requestorType,
    headerStyle: {
      width: "16.66%"
    }
  },
  {
    dataField: "incubatee",
    text: "Incubatee",
    formatter: ourIncubatee,
    csvFormatter: ourIncubatee,
    filterValue: ourIncubatee,
    headerStyle: {
      width: "11.66%"
    }
  },
  {
    dataField: "createdAt",
    text: "Requested At",
    formatter: requestedAt,
    csvFormatter: requestedAt,
    filterValue: requestedAt,
    headerStyle: {
      width: "16.66%"
    }
  },
  {
    dataField: "status",
    text: "Status",
    formatter: statusFormatter,
    formatExtraData: "stage",
    csvFormatter: statusFormatter,
    filterValue: statusFormatter,
    headerStyle: {
      width: "16.66%"
    }
  }
];

class ClosedEnquiryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiryList: null
    };
  }
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    let url = `${INCUBATOR_ENQUIRIES_API}/closed`;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ enquiryList: data }),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    this.getAll();
  }

  render() {
    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={ENQUIRY_LIST_COLUMNS}
          data={this.state.enquiryList}
          noDataIndication="No Rejected/Closed Enquiries found."
          sizePerPage="25"
          headerName="Closed Enquiries"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}
export default ClosedEnquiryList;
