import React, { Component, Fragment } from "react";
import AlertComponent from "../../common/AlertComponent";
import TableList from "../../common/TableList";
import { GRANT_PROGRESS_TEMPLATES_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import { Link } from "react-router-dom";
import { getDisplayDate } from "../../lease/DateUtil";

const columns = [
  {
    dataField: "name",
    text: "Template Name",
    sort: true,
    formatter: (cell, row) => {
      return (
        <Link
          to={`/funding/grant-progress-template/edit/${row._id}`}
          role="button"
          datatoggle="tooltip"
          title="View"
        >
          {row.name || "Untitled Template"}
        </Link>
      );
    }
  },

  {
    dataField: "incubator.name",
    text: "Incubator Name",
    sort: true,
    formatter: (cell, row) => {
      return row.incubator.name;
    }
  },

  {
    dataField: "updatedBy",
    text: "Updated By",
    sort: true,
    formatter: (cell, row) => {
      const names = [];
      if (row.updatedBy.firstName) {
        names.push(row.updatedBy.firstName);
      }
      if (row.updatedBy.lastName) {
        names.push(row.updatedBy.lastName);
      }
      return names.join("");
    }
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    sort: true,
    formatter: (cell, row) => {
      return getDisplayDate(row.updatedAt);
    }
  }
];

class GrantProgressTemplateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gpTemplateList: []
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getAll = () => {
    // Fetch api
    HttpUtil.get(
      GRANT_PROGRESS_TEMPLATES_API,
      {},
      data => {
        this.setState({ gpTemplateList: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };
  componentDidMount = () => {
    this.getAll();
  };
  render() {
    const { gpTemplateList } = this.state;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columns}
          data={gpTemplateList}
          noDataIndication="No Template found."
          sizePerPage="10"
          headerName="Grant Progress Template"
          showSearchbar={true}
          customComponent={<RenderCustomButton />}
        />
      </Fragment>
    );
  }
}

export default GrantProgressTemplateList;

const RenderCustomButton = props => {
  return (
    <Link
      to="/funding/grant-progress-template/new"
      role="button"
      className="btn btn-sm text-white bg-pink"
    >
      New
    </Link>
  );
};
