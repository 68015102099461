import React, { Component } from "react";
import AlertComponent from "../../../common/AlertComponent";
import AssetUsageCharges from "./AssetUsageCharges";
import {
  convertDateToNumber,
  convertNumberToDate,
  getDisplayDate
} from "../../../lease/DateUtil";
import {
  ASSET_USAGES_API,
  ASSET_RESERVATION_REQUEST_API
} from "../../../common/Constants";
import HttpUtil from "../../../common/HttpUtil";
import { changeToINR } from "../../../common/LookupConstants";
import ClosedAssetReservation from "./CloseAssetReservation";
import RefundAssetReservation from "./RefundAssetReservation";
import CancelAssetReservation from "./CancelAssetReservation";
import Can from "../../../../auth/can";

class AssetUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUsageModel: false,
      usages: null,
      assetUsageCharge: {
        asset: "",
        usageUnit: "",
        usageHours: "",
        unitType: "",
        unitTypes: [],
        rateTypes: [],
        assetCharge: "",
        fromDate: "",
        toDate: "",
        description: "",
        additionalCharge: ""
      },
      paymentPageUrl: ""
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      showUsageModel: false
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      confirm: false
    });
  };

  okConfirmUpdate = () => {
    const values = this.state.values;
    const setSubmitting = this.state.setSubmitting;
    // do stuff
    this.setState(
      {
        alertType: "",
        showAlert: false,
        alertColor: "",
        alertMessage: "",
        confirm: true
      },
      this.handleClosedReservationSubmit(values, { setSubmitting })
    );
  };

  toggleUsageModel = () => {
    this.setState({ showUsageModel: !this.state.showUsageModel });
  };

  createAssetUsageCharge = (values, setSubmitting, resetForm) => {
    setSubmitting(false);
    const assetReservationId = this.props.assetReservation._id;

    const from = convertDateToNumber(values.fromDate);
    const to = convertDateToNumber(values.toDate);
    const request = {
      reservation: assetReservationId,
      asset: values.asset,
      unitType: values.unitType,
      usageUnit: values.usageUnit,
      usageHours: values.usageHours,
      rateType: values.rateType,
      from,
      to,
      description: values.description,
      additionalCharge: values.additionalCharge,
      assetCharge: values.assetCharge
    };

    const url = `${ASSET_USAGES_API}`;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      request,
      data => {
        setSubmitting(true);
        resetForm(this.state.assetUsageCharge);
        this.props.getAssetReservationRequest(this.props.assetReservation._id);
        this.setState({
          showUsageModel: false,
          usages: data
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleSubmit = (values, { setSubmitting, resetForm }) => {
    // console.log("submit usage charge:values:", JSON.stringify(values, null, 2));
    this.createAssetUsageCharge(values, setSubmitting, resetForm);
  };

  handleClosedReservationSubmit = (values, { setSubmitting }) => {
    const id = this.props.assetReservation._id;
    if (!id) {
      return;
    }

    if (!this.state.confirm) {
      return this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "info",
        alertMessage: "Do you want to Close the Reservation ?",
        confirm: true,
        values: values,
        setSubmitting: setSubmitting
      });
    }

    const url = `${ASSET_RESERVATION_REQUEST_API}/${id}/close`;
    const headers = { "Content-Type": "application/json" };
    const request = {
      action: values.action
    };
    HttpUtil.put(
      url,
      headers,
      request,
      data => {
        setSubmitting(true);
        const message = "Asset Reservation closed successfully.";
        this.props.getAssetReservationRequest(data._id);
        this.props.handleApiSuccess(message);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleRefundReservationSubmit = (values, { setSubmitting }) => {
    const id = this.props.assetReservation._id;
    if (!id) {
      return;
    }

    const url = `${ASSET_RESERVATION_REQUEST_API}/${id}/refund`;
    const headers = { "Content-Type": "application/json" };
    const request = {
      action: values.action,
      refundAmount: values.refundAmount,
      refundNotes: values.refundNotes
    };
    HttpUtil.put(
      url,
      headers,
      request,
      data => {
        setSubmitting(true);
        const message =
          "Asset Reservation balance amount has been refunded successfully.";
        this.props.getAssetReservationRequest(data._id);
        this.props.handleApiSuccess(message);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleCancelReservationSubmit = (values, { setSubmitting }) => {
    const id = this.props.assetReservation._id;
    if (!id) {
      return;
    }

    const url = `${ASSET_RESERVATION_REQUEST_API}/${id}/cancel`;
    const headers = { "Content-Type": "application/json" };

    const remarks =
      this.props.assetReservation.additionalInformation.remarks || [];
    if (values.remark) {
      remarks.push({ note: values.remark });
    }

    const request = {
      action: values.action,
      remarks
    };
    HttpUtil.put(
      url,
      headers,
      request,
      data => {
        setSubmitting(true);
        const message = "Asset Reservation has been cancelled.";
        this.props.getAssetReservationRequest(data._id);
        this.props.handleApiSuccess(message);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAssetUsageByReservationId = () => {
    const assetReservationId = this.props.assetReservation._id;
    if (!assetReservationId) {
      return;
    }

    const url = `${ASSET_USAGES_API}?reservation=${assetReservationId}`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ showUsageModel: false, usages: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getFinChargeByReservationRequest = () => {
    const assetReservationId = this.props.assetReservation._id;
    if (!assetReservationId) {
      return;
    }

    const url = `${ASSET_RESERVATION_REQUEST_API}/${assetReservationId}/item`;
    HttpUtil.get(
      url,
      {},
      data => {
        if (data && data.length > 0) {
          const currentPathname = this.props.currentPathname;
          const baseUrl = `/admin/finance/payment/new?from=${currentPathname}`;
          const charges = data.map(charge => charge._id).join(",");
          const paymentPageUrl = `${baseUrl}&charge=${charges}`;
          this.setState({ paymentPageUrl });
        }
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    this.getAssetUsageByReservationId();
    this.getFinChargeByReservationRequest();
  };
  render() {
    const { showUsageModel, usages, paymentPageUrl } = this.state;

    const assetReservation = this.props.assetReservation;
    const assetRequested = assetReservation.assetRequested || [];

    const props = {
      assetUsageCharge: this.state.assetUsageCharge,
      toggleUsageModel: this.toggleUsageModel,
      handleSubmit: this.handleSubmit,
      assetRequested
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    let renderComp = null;

    if (usages === null) {
      renderComp = (
        <div>
          <h5 className="text-center">Loding ...</h5>
        </div>
      );
    } else if (usages && usages.length === 0) {
      renderComp = (
        <div>
          <h5 className="text-center" style={{ color: "#767676" }}>
            No Asset Usages has been added.
          </h5>
        </div>
      );
    }

    let totalAssetChrg = 0;
    let totalAddlChrg = 0;

    const hideAssetUsageBtn =
      assetReservation.stage !== "RESERVEAPPR" ||
      assetReservation.status !== "APPROVED";

    return (
      <div className={`card-body ${showUsageModel ? "open-model" : ""}`}>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>
        <div
          className={`modal fade ${showUsageModel ? "show" : ""}`}
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={
            showUsageModel
              ? {
                  display: "block",
                  paddingRight: 10,
                  "overflow-y": "auto",
                  backgroundColor: "rgba(0, 0, 0, 0.5)"
                }
              : { display: "none" }
          }
        >
          <div className="modal-dialog modal-lg" role="document">
            <AssetUsageCharges {...props} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3 edit-page-section-header-text">
            <div className="d-flex justify-content-between align-items-center">
              <span className=""> Asset Usage </span>

              {hideAssetUsageBtn ? null : (
                <Can do="UPDATE" on="AssetReservationRequest">
                  <button
                    className="btn btn-sm text-white bg-pink"
                    type="button"
                    onClick={this.toggleUsageModel}
                  >
                    Add Usage
                  </button>
                </Can>
              )}
            </div>
          </div>
        </div>

        {usages && usages.length > 0 ? (
          <div className="row">
            <div className="col-12">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      style={{ width: "40%" }}
                      scope="col"
                      className="border-bottom-0 display-table-header"
                    >
                      Asset
                    </th>
                    <th
                      style={{ width: "20%" }}
                      scope="col"
                      className="border-bottom-0 display-table-header"
                    >
                      Usage Period
                    </th>
                    <th
                      style={{ width: "8%" }}
                      scope="col"
                      className="border-bottom-0 display-table-header"
                    >
                      Used Hours
                    </th>
                    <th
                      style={{ width: "8%" }}
                      scope="col"
                      className="border-bottom-0 display-table-header"
                    >
                      Unit
                    </th>
                    <th
                      style={{ width: "8%" }}
                      scope="col"
                      className="border-bottom-0 display-table-header text-right"
                    >
                      Charge
                    </th>
                    <th
                      style={{ width: "8%" }}
                      scope="col"
                      className="border-bottom-0 display-table-header text-right"
                    >
                      Addl Charge
                    </th>
                    <th
                      style={{ width: "8%" }}
                      scope="col"
                      className="border-bottom-0 display-table-header text-right"
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usages.map((usage, index) => {
                    const asset = usage.asset || {};
                    const from = convertNumberToDate(usage.from);
                    const to = convertNumberToDate(usage.to);

                    totalAssetChrg += usage.assetCharge;
                    totalAddlChrg += usage.additionalCharge;
                    return (
                      <tr key={index}>
                        <td className="align-top">
                          <span className="text-uppercase font-weight-bold">
                            {asset.assetName}
                          </span>
                          <br />
                          {usage.description}
                        </td>
                        <td className="align-top">
                          {getDisplayDate(from)}
                          <br />
                          <div className="pl-4">to</div>
                          {getDisplayDate(to)}
                        </td>
                        <td className="align-top">
                          {usage.usageHours || "--"}
                        </td>
                        <td className="align-top">
                          {usage.unitType
                            ? `${usage.usageUnit} ${usage.unitType.name}`
                            : "--"}
                        </td>
                        <td className="text-right align-top">
                          {changeToINR(usage.assetCharge)}
                        </td>
                        <td className="text-right align-top">
                          {changeToINR(usage.additionalCharge)}
                        </td>
                        <td className="text-right align-top">
                          {changeToINR(
                            usage.assetCharge + usage.additionalCharge
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr style={{ fontWeight: "bold" }}>
                    <td colSpan="3" />
                    <td className="align-middle">Total</td>
                    <td className="text-right align-middle">
                      {changeToINR(totalAssetChrg)}
                    </td>
                    <td className="align-middle">
                      <div className="text-right justify-content-between">
                        {changeToINR(totalAddlChrg)}
                      </div>
                    </td>
                    <td className="text-right align-middle">
                      <div className="">
                        <span className="mr-3" />
                        {changeToINR(totalAssetChrg + totalAddlChrg)}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          renderComp
        )}

        {usages && usages.length > 0 ? (
          <ClosedAssetReservation
            handleSubmit={this.handleClosedReservationSubmit}
            paymentPageUrl={paymentPageUrl}
            {...this.props}
          />
        ) : null}

        {usages && usages.length === 0 ? (
          <CancelAssetReservation
            handleSubmit={this.handleCancelReservationSubmit}
            {...this.props}
          />
        ) : null}

        {(assetReservation.stage === "RESERVEREFUND" &&
          assetReservation.status === "PENDING") ||
        (assetReservation.stage === "COMPLETE" &&
          assetReservation.status === "CLOSED" &&
          assetReservation.refundAmount !== 0) ? (
          <RefundAssetReservation
            handleSubmit={this.handleRefundReservationSubmit}
            paymentPageUrl={paymentPageUrl}
            {...this.props}
          />
        ) : null}
      </div>
    );
  }
}

export default AssetUsage;
