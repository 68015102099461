import React from "react";
import PivotReport from "../PivotReport";
import {
  checkNaN,
  getPercent,
  getPercentString,
  formatNumber
} from "../ReportsUtil";

const dimensions = [
  { value: "owner", title: "Owner" },
  { value: "cluster", title: "Cluster" },
  { value: "campus", title: "Campus" },
  { value: "building", title: "Building" },
  { value: "partitionDisplayName", title: "Partition" },
  { value: "status", title: "Status" }
];

function reduce(row, aggRecord) {
  aggRecord.count = (aggRecord.count || 0) + 1;
  aggRecord.totalOccupiedArea =
    (aggRecord.totalOccupiedArea || 0) + parseFloat(row.occupiedArea);
  aggRecord.totalArea = (aggRecord.totalArea || 0) + parseFloat(row.area);
  return aggRecord;
}

const calculations = [
  {
    title: "Partition Count",
    value: "count",
    template: val => checkNaN(val),
    sortBy: row => checkNaN(row.count)
  },
  {
    title: "Occupancy",
    value: "count",
    template: (val, row) =>
      getPercentString(row.totalOccupiedArea, row.totalArea),
    sortBy: row => getPercent(row.totalOccupiedArea, row.totalArea)
  },
  {
    title: "Occupied Area",
    value: "totalOccupiedArea",
    template: (val, row) => formatNumber(row.totalOccupiedArea),
    sortBy: row => checkNaN(row.totalOccupiedArea)
  },
  {
    title: "Total Area",
    value: "totalArea",
    template: (val, row) => formatNumber(row.totalArea),
    sortBy: row => checkNaN(row.totalArea)
  }
];

function FixedRentalAvailabilityReport() {
  return (
    <PivotReport
      title="Occupancy - Fixed Rental"
      url="/api/v1/reports/facility/fixed-rental-occupancy"
      dimensions={dimensions}
      reduce={reduce}
      calculations={calculations}
    />
  );
}

export default FixedRentalAvailabilityReport;
