import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import {
  ENQUIRY_TYPES,
  REQUESTOR_CATEGORY_TYPES
} from "../../common/LookupConstants";
import { getDateAndTimeFormat, getDisplayDate } from "../../lease/DateUtil";
import { getDescription } from "../../common/Util";
import Can from "../../../auth/can";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const CustomForm = props => {
  const { values, setFieldValue, readOnly, errors, touched } = props;

  const disabledField = readOnly
    ? { readOnly: "readonly", disabled: "disabled" }
    : {};

  const additionalInfoDisabled =
    values.actions.length === 0
      ? { readOnly: "readonly", disabled: "disabled" }
      : {};

  const isApproved = values.stage === "RESERVE" && values.status === "PENDING";
  const hasAssetRequest = !!values.assetReservationRequest;

  const incubatorNotes = values.incubatorNotes || [];

  return (
    <Form>
      <div className="card border-0 pl-1 pr-1">
        <div className="card-header border-0">
          <span className="edit-page-title">
            <Link
              to={props.previousPath}
              className="badge bg-white custom-btn-sm float-left"
            >
              <i className="fas fa-arrow-left mr-2" />
            </Link>
            Enquiry Form
          </span>
          <span className="float-right edit-page-status ml-2">{`Status : ${getDescription(
            "IncubatorEnquiry",
            values.stage,
            values.status
          )}`}</span>
        </div>

        <div className="edit-page-section-header-text mt-2">
          <strong>Enquiry Information</strong>
        </div>

        <div className="card-body">
          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
              Enquiry Regarding
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <Field
                component="select"
                name="enquiryType"
                className="form-control form-control-sm"
                value={values.enquiryType || ""}
                {...disabledField}
              >
                <option value="">Select...</option>
                {ENQUIRY_TYPES.map((opt, i) => (
                  <option key={i} value={opt.key}>
                    {opt.value}
                  </option>
                ))}
              </Field>
              <div className="invalid-feedback">
                {/* {getError(touch, error, "type")} */}
              </div>
            </div>
          </div>

          {values.enquiryType === "EQUIPMENT_RENTAL" ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
                Name of Equipment
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Select
                  key="001"
                  isMulti
                  name="equipments"
                  isDisabled={readOnly}
                  value={values.equipments || null}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={data => {
                    const equipments = data.map(data => data.value);
                    setFieldValue("equipments", equipments);
                  }}
                />
                <div className="invalid-feedback">
                  {/* {getError(touch, error, "type")} */}
                </div>
              </div>
            </div>
          ) : null}

          {values.enquiryType === "LAB_USAGE" ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
                Lab Test {/*labTest*/}
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Field
                  type="text"
                  className="form-control form-control-sm"
                  name="labTest"
                  value={values.labTest || ""}
                  {...disabledField}
                />
                <div className="invalid-feedback">
                  {/* {getError(touch, error, "type")} */}
                </div>
              </div>
            </div>
          ) : null}

          <div className="form-group row mb-4">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
              Date
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <label className="mb-1">From</label>
                  <DatePicker
                    value={getDisplayDate(values.from)}
                    selected={values.from}
                    utcOffset={0}
                    placeholderText="dd-mm-yyyy"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabled={readOnly}
                  />

                  {errors.from ? (
                    <div
                      className="text-danger m-1"
                      style={{ fontSize: "0.850em" }}
                    >
                      From required field
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-lg-6">
                  <label className="mb-1">To</label>
                  <DatePicker
                    value={getDisplayDate(values.to)}
                    selected={values.to}
                    utcOffset={0}
                    minDate={values.from}
                    placeholderText="dd-mm-yyyy"
                    className="form-control"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabled={readOnly}
                  />
                  {errors.to ? (
                    <div
                      className="text-danger m-1"
                      style={{ fontSize: "0.850em" }}
                    >
                      To required field
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
              Are you currently or previously incubated at PSG-STEP?
              {/* incubatee */}
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  type="radio"
                  id="customRadioInline1"
                  name="incubatee"
                  className="custom-control-input"
                  checked={values.incubatee ? true : false}
                  onChange={() => {
                    setFieldValue("incubatee", true);
                  }}
                  {...disabledField}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline1"
                >
                  Yes
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  type="radio"
                  id="customRadioInline2"
                  name="incubatee"
                  className="custom-control-input"
                  checked={!values.incubatee ? true : false}
                  onChange={() => {
                    setFieldValue("incubatee", false);
                  }}
                  {...disabledField}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline2"
                >
                  No
                </label>
              </div>
            </div>
          </div>

          {values.incubatee ? (
            <div className="form-group row">
              <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                Name of Incubatee
              </label>
              <div className="col-12 col-lg-6 col-xl-6">
                <Select
                  key="002"
                  name="nameOfIncubatee"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={readOnly}
                  isClearable
                  value={values.nameOfIncubatee || null}
                />
                <div className="invalid-feedback">
                  {/* {getError(touch, error, "type")} */}
                </div>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Are you already associated with any other incubator or
                  accelerator?
                  {/* otherIncubator */}
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      id="customRadioInline3"
                      name="otherIncubator"
                      className="custom-control-input"
                      checked={values.otherIncubator ? true : false}
                      onChange={() => {
                        setFieldValue("otherIncubator", true);
                      }}
                      {...disabledField}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline3"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      id="customRadioInline4"
                      name="otherIncubator"
                      className="custom-control-input"
                      checked={!values.otherIncubator ? true : false}
                      onChange={() => {
                        setFieldValue("otherIncubator", false);
                      }}
                      {...disabledField}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline4"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              {values.otherIncubator ? (
                <Fragment>
                  <div className="form-group row">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                      Incubator Name
                    </label>
                    <div className="col-12 col-lg-6 col-xl-6">
                      <Field
                        type="text"
                        className={getErrorCssClassName(
                          touched,
                          errors,
                          "incubatorName"
                        )}
                        name="incubatorName"
                        value={values.incubatorName || ""}
                        {...disabledField}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                      Incubator Location
                    </label>
                    <div className="col-12 col-lg-6 col-xl-6">
                      <Field
                        type="text"
                        className={getErrorCssClassName(
                          touched,
                          errors,
                          "incubatorLocation"
                        )}
                        name="incubatorLocation"
                        value={values.incubatorLocation || ""}
                        {...disabledField}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : null}

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  What best describes you? {/* requestorCategory  */}
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    component="select"
                    name="requestorCategory"
                    className="form-control form-control-sm"
                    value={values.requestorCategory || ""}
                    {...disabledField}
                  >
                    <option value="">Select...</option>
                    {REQUESTOR_CATEGORY_TYPES.map((opt, i) => (
                      <option key={i} value={opt.key}>
                        {opt.value}
                      </option>
                    ))}
                  </Field>
                  <div className="invalid-feedback">
                    {/* {getError(touch, error, "type")} */}
                  </div>
                </div>
              </div>

              {values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Institution Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className="form-control form-control-sm"
                      name="institutionName"
                      value={values.institutionName || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Requestor Type
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      id="customRadioInline5"
                      name="incubateeType"
                      className="custom-control-input"
                      checked={
                        values.incubateeType === "INDIVIDUAL" ? true : false
                      }
                      onChange={() => {
                        setFieldValue("incubateeType", "INDIVIDUAL");
                        setFieldValue("organizationName", "");
                      }}
                      {...disabledField}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline5"
                    >
                      Individual
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Field
                      type="radio"
                      id="customRadioInline6"
                      name="incubateeType"
                      className="custom-control-input"
                      checked={
                        values.incubateeType === "COMPANY" ? true : false
                      }
                      onChange={() => {
                        setFieldValue("incubateeType", "COMPANY");
                        setFieldValue("name", "");
                      }}
                      {...disabledField}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline6"
                    >
                      Organization
                    </label>
                  </div>
                </div>
              </div>

              {values.incubateeType === "INDIVIDUAL" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(touched, errors, "name")}
                      name="name"
                      value={values.name || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Organization Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "organizationName"
                      )}
                      name="organizationName"
                      value={values.organizationName || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Contact Person Name
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "contactPersonName"
                      )}
                      name="contactPersonName"
                      value={values.contactPersonName || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              {values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Department
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "department"
                      )}
                      name="department"
                      value={values.department || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              {values.incubateeType === "COMPANY" ||
              values.requestorCategory === "ACADEMIC" ? (
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                    Designation
                  </label>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <Field
                      type="text"
                      className={getErrorCssClassName(
                        touched,
                        errors,
                        "contactPersonDesignation"
                      )}
                      name="organizationName"
                      value={values.contactPersonDesignation || ""}
                      {...disabledField}
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Email
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="email"
                    className={getErrorCssClassName(touched, errors, "email")}
                    name="email"
                    value={values.email || ""}
                    {...disabledField}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label-required">
                  Mobile
                </label>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="number"
                    className={getErrorCssClassName(touched, errors, "mobile")}
                    name="mobile"
                    value={values.mobile || ""}
                    {...disabledField}
                  />
                </div>
              </div>
            </Fragment>
          )}

          <div className="form-group row">
            <label className="col-form-label col-form-label-sm col-12 col-lg-6 col-xl-6 edit-page-label">
              Requestor Notes {/*userNotes*/}
            </label>
            <div className="col-12 col-lg-6 col-xl-6">
              <Field
                component="textarea"
                className="form-control form-control-sm"
                name="userNotes"
                rows="5"
                value={values.userNotes}
                {...disabledField}
              />
              <div className="invalid-feedback">
                {/* {getError(touch, error, "type")} */}
              </div>
            </div>
          </div>
        </div>

        <div className="edit-page-section-header-text mt-4">
          <strong>Additional Information </strong>
        </div>

        <div className="card-body">
          <div className="row mb-5">
            <div className="col-12 col-lg-6 col-xl-6">
              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 edit-page-label">
                  Priority
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInlineLOW"
                      name="priority"
                      className="custom-control-input"
                      checked={values.priority === "LOW" ? true : false}
                      onChange={() => {
                        setFieldValue("priority", "LOW");
                      }}
                      {...additionalInfoDisabled}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInlineLOW"
                    >
                      Low
                    </label>
                  </div>
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInlineMEDIUM"
                      name="priority"
                      className="custom-control-input"
                      checked={values.priority === "MEDIUM" ? true : false}
                      onChange={() => {
                        setFieldValue("priority", "MEDIUM");
                      }}
                      {...additionalInfoDisabled}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInlineMEDIUM"
                    >
                      Medium
                    </label>
                  </div>
                  <div
                    className="custom-control custom-radio d-inline-block"
                    style={{ width: "33.33%" }}
                  >
                    <Field
                      type="radio"
                      id="customRadioInlineHIGH"
                      name="priority"
                      className="custom-control-input"
                      checked={values.priority === "HIGH" ? true : false}
                      onChange={() => {
                        setFieldValue("priority", "HIGH");
                      }}
                      {...additionalInfoDisabled}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInlineHIGH"
                    >
                      High
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {values.actions.length === 0 ? null : (
              <div className="col-lg-6">
                <div className="form-group row">
                  <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 edit-page-label-required">
                    Incubator Notes {/*incubatorNotes*/}
                  </label>
                  <div className="col-12 col-lg-8 col-xl-8">
                    <Field
                      component="textarea"
                      className={getErrorCssClassName(
                        errors,
                        errors,
                        "incubatorNote"
                      )}
                      name="incubatorNote"
                      value={values.incubatorNote || ""}
                      rows="5"
                      {...additionalInfoDisabled}
                    />
                    <div className="invalid-feedback">
                      {errors["incubatorNote"] ? errors["incubatorNote"] : ""}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {incubatorNotes.length > 0 ? (
              <div className="col-lg-6 mb-4">
                <div className="card-title edit-page-label border-bottom pb-1">
                  Incubator Notes History
                </div>
                <ul className="list-group">
                  {incubatorNotes.map((note, ntIdx) => {
                    let noteHistory = "";

                    if (note.user) {
                      noteHistory = (
                        <li
                          key={ntIdx}
                          className="list-group-item border-0 p-0 mb-3"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <strong className="text-muted">
                              {note.user.name}
                            </strong>
                            <small>{getDateAndTimeFormat(note.date)}</small>
                          </div>
                          <p className="text-muted mb-1">{note.note}</p>
                        </li>
                      );
                    }

                    return noteHistory;
                  })}
                </ul>
              </div>
            ) : null}
          </div>

          <div className="row mt-3">
            <div className="col-lg-6">
              <div className="form-group row">
                <label className="col-form-label col-form-label-sm col-12 col-lg-4 col-xl-4 edit-page-label-required">
                  Response Message {/*responseMessage*/}
                </label>
                <div className="col-12 col-lg-8 col-xl-8">
                  <Field
                    component="textarea"
                    className={getErrorCssClassName(
                      errors,
                      errors,
                      "responseMessage"
                    )}
                    name="responseMessage"
                    rows="5"
                    {...additionalInfoDisabled}
                  />
                  <div className="invalid-feedback">
                    {errors["responseMessage"] ? errors["responseMessage"] : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6"> </div>
          </div>
        </div>
      </div>
      {values.actions.length > 0 ? (
        <div className="row m-5">
          <div className="col-12 text-center">
            {values.actions.map((action, idx) => {
              return (
                <Can key={action} do="UPDATE" on="IncubatorEnquiry">
                  <button
                    className="btn btn-sm btn-primary mr-2"
                    type="submit"
                    onClick={() => {
                      setFieldValue("action", action);
                    }}
                  >
                    {action}
                  </button>
                </Can>
              );
            })}
            {hasAssetRequest ? (
              <Link
                className="btn btn-sm btn-primary mr-2"
                to={`/service-mgmt/asset-reservation-requests/edit/${
                  values.assetReservationRequest._id
                }`}
              >
                View Reservation
              </Link>
            ) : isApproved ? (
              <button
                className="btn btn-sm btn-primary mr-2"
                type="button"
                onClick={props.handleStartReservation}
              >
                Start Reservation
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
    </Form>
  );
};

class IncubatorEnquiryForm extends Component {
  render() {
    return (
      <div className="card edit-page-container">
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          validationSchema={this.props.validationSchema}
          // validate={values => validateFn(values)}
          // validate={values => validateFn(values, props.template)}
          onSubmit={(values, { setSubmitting }) =>
            this.props.handleSubmit(values, { setSubmitting })
          }
          render={renderProps => (
            <CustomForm {...renderProps} {...this.props} />
          )}
        />
      </div>
    );
  }
}

export default IncubatorEnquiryForm;
