import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";
import * as Yup from "yup";
import AdvanceAdjustmentConfirmForm from "./AdvAdjustmentConfirmForm";
import HttpUtil from "../../common/HttpUtil";
import { ADJUSTMENTS_FOR_ADVANCE_API } from "../../common/Constants";
import { convertDateToNumber } from "../../lease/DateUtil";
import AdvAdjustmentPreviewForm from "./AdvAdjustmentPreviewForm";
import AdvanceAdjustmentForm from "./AdvanceAdjustmentForm";

const FORM_SCHEMA = Yup.object().shape({
  entityType: Yup.string()
    .label("Entity Type")
    .required(),
  adjustmentReason: Yup.string()
    .label("Reason")
    .required(),
  amount: Yup.number()
    .positive()
    .integer()
    .label("Amount")
    .required(),
  notes: Yup.string()
    .label("Notes")
    .required(),
  entity: Yup.string()
    .label("Entity")
    .required()
});

class AdvanceAdjustment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAdjustment: null,
      initialValues: {
        entityType: "",
        adjustmentReason: "",
        date: new Date(),
        entity: "",
        amount: 0,
        advanceAmount: 0,
        notes: ""
      },
      entityLookupUrl: "",
      entityName: "",

      // forms
      adjustmentEditForm: true,
      adjustmentConfirmationForm: false
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: "",
      confirm: false
    });
  };

  okConfirmUpdate = () => {
    // do stuff
    this.setState(
      {
        alertType: "",
        showAlert: false,
        alertColor: "",
        alertMessage: "",
        confirm: true
      },
      this.handleSubmit()
    );
  };

  handleSubmit = () => {
    const initialValues = { ...this.state.initialValues };
    let message = "";
    if (initialValues.advanceAmount < initialValues.amount) {
      message = `The adjustment amount is greater than the advance balance. Do you want to continue ?`;
    }

    if (initialValues.advanceAmount === 0) {
      message = `There is no advance balance present for this entity. Do you want to continue ?`;
    }

    if (!this.state.confirm && message) {
      return this.setState({
        alertType: "Confirmation",
        showAlert: true,
        alertColor: "info",
        alertMessage: message,
        confirm: true
      });
    }

    let date = new Date(initialValues.date);
    date = convertDateToNumber(date);
    const request = {
      entityType: initialValues.entityType,
      adjustmentTo: initialValues.entity.value,
      amount: Number(initialValues.amount),
      date: date,
      notes: [initialValues.notes],
      adjustmentReason: initialValues.adjustmentReason
    };

    const url = ADJUSTMENTS_FOR_ADVANCE_API;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      request,
      data => {
        this.setState({
          redirectToViewPage: true,
          redirectToViewPageId: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  handleNextForm = values => {
    this.setState({
      adjustmentConfirmationForm: true,
      adjustmentEditForm: false,
      initialValues: { ...values }
    });
  };

  handleEditForm = () => {
    this.setState({
      adjustmentConfirmationForm: false,
      adjustmentEditForm: true
    });
  };

  getAdjustment = id => {
    if (this.props.location.state !== undefined) {
      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: "Advance Adjustment has been submitted sucessfully."
      });
    }
    const url = `${ADJUSTMENTS_FOR_ADVANCE_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ viewAdjustment: true, fetchedData: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getAdjustment(id);
    } else {
      this.setState({ viewAdjustment: false });
    }
  }

  render() {
    const {
      adjustmentConfirmationForm,
      adjustmentEditForm,
      viewAdjustment,
      redirectToViewPage,
      redirectToViewPageId
    } = this.state;

    if (viewAdjustment === null) {
      return null;
    }

    if (redirectToViewPage) {
      const stateObj = {
        pathname: `/finance/adjustment/advances/view/${redirectToViewPageId}`,
        state: { adjustmentSubmitted: true }
      };
      return <Redirect to={stateObj} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    let renderForm = null;
    if (viewAdjustment) {
      renderForm = (
        <AdvAdjustmentPreviewForm
          data={this.state.fetchedData}
          title="Adjustment  Information"
        />
      );
    } else if (adjustmentEditForm) {
      const adjustmentFormProps = {
        initialValues: this.state.initialValues,
        entityLookupUrl: this.state.entityLookupUrl,
        entityName: this.state.entityName,
        validationSchema: FORM_SCHEMA,

        handleSubmit: this.handleNextForm
      };

      renderForm = <AdvanceAdjustmentForm {...adjustmentFormProps} />;
    } else if (adjustmentConfirmationForm) {
      const props = {
        initialValues: this.state.initialValues,
        handleSubmit: this.handleSubmit,
        handleEditForm: this.handleEditForm
      };
      renderForm = <AdvanceAdjustmentConfirmForm {...props} />;
    }

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-12">{renderForm}</div>
        </div>
      </Fragment>
    );
  }
}

export default AdvanceAdjustment;
