import React, { useState } from "react";
import CreateClosureRequest from "../accountClosureRequest/CreateClosureRequest";
import { Field } from "formik";
import { ENTITY_TYPES } from "../../common/LookupConstants";
import AutoLookup from "../../custom/AutoLookup";
import { Link } from "react-router-dom";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";
import { getDescription } from "../../common/Util";
import { LEASE_TYPES_OBJECT } from "../../common/LookupConstants";
import { getErrorCssClassName, ErrorComponent } from "../../common/FormikUtil";
import { changeToINR } from "../../common/LookupConstants";
import PopUpAlert from "./PopUpAlert";

function InitiateClosureRequest(props) {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleReset,
    handleValidate,
    handleClosureCreate,
    data,
    setData,
    prevLocation
  } = props;

  const { leases, assetReservations, status } = data;

  function getLookupUrl(entityType) {
    const URL_MAPPER = {
      Individual: {
        url:
          "/api/v1/individuals/lookup/active?&incubatee=true&incubateeStatus=ONGOING&accountStatus=ACTIVE&name=",
        entityName: "name"
      },
      Organization: {
        url:
          "/api/v1/organizations/lookup/active?&incubatee=true&incubateeStatus=ONGOING&accountStatus=ACTIVE&name=",
        entityName: "name"
      }
    };

    let entityLookupUrl = null;
    let entityName = null;
    if (entityType) {
      entityName = URL_MAPPER[entityType].entityName;
      entityLookupUrl = URL_MAPPER[entityType].url;
    }

    const lookup = {
      entityLookupUrl,
      entityName
    };

    return lookup;
  }

  const lookup = values.entityType ? getLookupUrl(values.entityType) : "";

  const selectedEntity = values.entity
    ? {
        label: values.entity.label,
        value: values.entity.value
      }
    : null;

  const enabled = values.entity && values.entityType;

  const [popUp, setPopUp] = useState(false);

  return (
    <form>
      <div className="row">
        <div className="col-12">
          <div className="card edit-page-container box-border box-light-blue">
            <div className="card-header border-bottom-0 pt-3">
              <span className="edit-page-title">
                <Link
                  to={prevLocation}
                  className="badge bg-white custom-btn-sm"
                >
                  <i className="fas fa-arrow-left mr-3" />
                </Link>
                Account Closure Request
              </span>
            </div>
            <div className="card-body">
              <div className="row no-gutter">
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Entity Type
                </label>
                <div className="col-12 col-lg-4 col-xl-4 pr-4">
                  <Field
                    component="select"
                    name="entityType"
                    className={`custom-select custom-select-sm ${getErrorCssClassName(
                      touched,
                      errors,
                      "entityType"
                    )}`}
                    onChange={e => {
                      setFieldValue("entityType", e.target.value);
                      setFieldValue("entity", "");
                      setData({ leases: [], assetReservations: [] });
                    }}
                  >
                    <option value="">Select...</option>
                    {ENTITY_TYPES.map((pt, i) => (
                      <option key={i} value={pt.key}>
                        {pt.value}
                      </option>
                    ))}
                  </Field>
                  <ErrorComponent
                    fieldName={`entityType`}
                    touched={touched}
                    error={errors}
                  />
                </div>
                <label className="col-form-label col-12 col-lg-2 col-xl-2 edit-page-label-required">
                  Entity
                </label>
                <div className="col-12 col-lg-4 col-xl-4">
                  <AutoLookup
                    className={errors["entity"]}
                    name={lookup.entityName}
                    value={selectedEntity}
                    getObject={entity => {
                      setFieldValue("entity", entity);
                      setData({ leases: [], assetReservations: [] });
                    }}
                    url={lookup.entityLookupUrl}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-sm btn-info mx-2"
                    type="button"
                    onClick={() => handleValidate(values)}
                    disabled={!enabled}
                  >
                    Submit
                  </button>

                  <button
                    className="btn btn-sm btn-outline-info mx-2"
                    type="reset"
                    onClick={() => {
                      handleReset();
                      setData({ leases: [], assetReservations: [] });
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>

              {status === "fetched" && values.entityType && values.entity && (
                <div className="mt-4">
                  <div className="row mt-3 no-gutters">
                    <div className="col-12">
                      <strong className="border-bottom pb-1 d-block">
                        Open/Current Leases
                      </strong>
                    </div>
                  </div>
                  {leases.length > 0 ? (
                    <div className="responsive">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th className="display-table-header" scope="col">
                              Lease Type
                            </th>
                            <th className="display-table-header" scope="col">
                              Partition
                            </th>

                            <th className="display-table-header" scope="col">
                              Lease Start Date
                            </th>
                            <th className="display-table-header" scope="col">
                              Lease End Date
                            </th>
                            <th className="display-table-header" scope="col">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {leases.map((lease, index) => {
                            let partitionNames = [];
                            lease.leasePartitions.forEach((ptn, idx) => {
                              partitionNames.push(ptn.partition.displayName);
                            });

                            const names = partitionNames.join(", ");
                            return (
                              <tr key={index}>
                                <td>
                                  {LEASE_TYPES_OBJECT[lease.leaseType] ||
                                    "Unspecified"}
                                </td>
                                <td>{names}</td>
                                <td>
                                  {getDisplayDate(
                                    convertNumberToDate(lease.leaseStartDate)
                                  )}
                                </td>
                                <td>
                                  {getDisplayDate(
                                    convertNumberToDate(lease.leaseEndDate)
                                  )}
                                </td>
                                <td>
                                  {getDescription(
                                    "LEASE",
                                    lease.stage,
                                    lease.status
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    "No Active Lease"
                  )}

                  <div className="row mt-3 no-gutters">
                    <div className="col-12">
                      <strong className="border-bottom pb-1 d-block">
                        Pending Asset Reservation
                      </strong>
                    </div>
                  </div>
                  {assetReservations.length > 0 ? (
                    <div className="responsive">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th className="display-table-header" scope="col">
                              Requested at
                            </th>
                            <th className="display-table-header" scope="col">
                              Charge Amount
                            </th>

                            <th className="display-table-header" scope="col">
                              Advance / Paid Amount
                            </th>
                            <th className="display-table-header" scope="col">
                              Pending / Refund
                            </th>
                            <th className="display-table-header" scope="col">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {assetReservations.map((asset, index) => {
                            return (
                              <tr key={index}>
                                <td>{getDisplayDate(asset.createdAt)}</td>
                                <td>{changeToINR(asset.chargeAmount)}</td>
                                <td>{changeToINR(asset.advance)}</td>
                                <td>
                                  {changeToINR(asset.pendingOrRefundAmount)}
                                </td>
                                <td>
                                  {getDescription(
                                    "AssetReservationRequest",
                                    asset.stage,
                                    asset.status
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    "No Asset Reservations"
                  )}
                </div>
              )}
            </div>
            {!leases.length &&
              !assetReservations.length &&
              status === "fetched" && (
                <div className="row my-4">
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => setPopUp(true)}
                      type="button"
                    >
                      Create Closure Request
                    </button>
                    {popUp && (
                      <PopUpAlert
                        setPopUp={setPopUp}
                        handleClosureCreate={handleClosureCreate}
                        values={values}
                      />
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </form>
  );
}

function AccountClosureRequestForm(props) {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleValidate,
    handleClosureCreate,
    handleReset,
    setData,
    data,
    closureRequestId,
    closureRequest,
    prevLocation
  } = props;

  const [screen, setScreen] = useState(
    closureRequestId ? "Create" : "Initiate"
  );

  return (
    <>
      {screen === "Initiate" && (
        <InitiateClosureRequest
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          setScreen={setScreen}
          screen={screen}
          handleValidate={handleValidate}
          handleClosureCreate={handleClosureCreate}
          handleReset={handleReset}
          setData={setData}
          data={data}
          prevLocation={prevLocation}
        />
      )}
      {screen === "Create" && (
        <CreateClosureRequest
          values={values}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          screen={screen}
          closureRequest={closureRequest}
          prevLocation={prevLocation}
        />
      )}
    </>
  );
}

export default AccountClosureRequestForm;
