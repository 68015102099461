const PatentSchema = {
  title: "Patents",
  type: "array",
  items: {
    title: "Patent",
    type: "object",
    required: ["country", "applicationNumber", "applicationFilingDate"],
    properties: {
      country: {
        type: "string",
        title: "Country"
      },
      applicationNumber: {
        type: "string",
        title: "Application Number"
      },
      applicationFilingDate: {
        type: "string",
        title: "Application Filing Date",
        format: "date"
      },
      fieldOfInvention: {
        type: "string",
        title: "Field Of Invention"
      },
      classificationCode: {
        type: "string",
        title: "Classification (IPC)"
      },
      patentStatus: {
        type: "string",
        title: "Patent Status"
      }
    }
  }
};

const PatentUiSchema = {};

export { PatentSchema, PatentUiSchema };
