import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import RequestorOnboardForm from "./RequestorOnboardForm";
import * as Yup from "yup";
import AlertComponent from "../common/AlertComponent";
import { convertDateToNumber, convertNumberToDate } from "../lease/DateUtil";
import HttpUtil from "../common/HttpUtil";
import { REQUESTOR_ONBOARD_API } from "../common/Constants";

const REQUESTOR_ONBOARD_FORM_SCHEMA = Yup.object().shape({
  requestorType: Yup.string()
    .label("Requestor Type")
    .required(),
  individual: Yup.object().when("requestorType", {
    is: "INDIVIDUAL",
    then: Yup.object().shape({
      firstName: Yup.string()
        .label("First name")
        .required(),
      lastName: Yup.string()
        .label("Last name")
        .nullable(),
      gender: Yup.string()
        .label("Gender")
        .required(),
      dob: Yup.date()
        .label("Individual Date of Birth")
        .required()
    })
  }),
  organization: Yup.object().when("requestorType", {
    is: "COMPANY",
    then: Yup.object().shape({
      name: Yup.string()
        .label("Name")
        .required(),
      legalStatus: Yup.string()
        .label("Legal Status")
        .required()
    })
  }),
  addresses: Yup.array()
    .of(
      Yup.object().shape({
        streetLine1: Yup.string()
          .label("Street Line 1")
          .required(),
        streetLine2: Yup.string().label("Street Line 2"),
        landmark: Yup.string().label("Landmark"),
        city: Yup.string()
          .label("City")
          .required(),
        state: Yup.string()
          .label("State")
          .required(),
        postalCode: Yup.string()
          .label("Postal Code")
          .required(),
        country: Yup.string()
          .label("Country")
          .required(),
        type: Yup.string()
          .label("Type")
          .required(),
        status: Yup.string()
          .label("Status")
          .required()
      })
    )
    .label("Addresses")
    .min(1),
  emails: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .label("Email Type")
          .required(),
        email: Yup.string()
          .label("Email")
          .required(),
        status: Yup.string().label("Status")
      })
    )
    .label("Phones")
    .min(1),
  phones: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .label("Phone Type")
          .required(),
        phone: Yup.string()
          .label("Phone")
          .required(),
        status: Yup.string().label("Status")
      })
    )
    .label("Phones")
    .min(1)
});

function addDocs(formData, name, file) {
  if (file) {
    formData.append(name, file);
  }
}

class RequestorOnboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboardForm: null,
      redirectToOnboardForm: false,
      redirectToOnboardFormId: null
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getFormData = form => {
    const formData = new FormData();
    let requestorOnboardForm = { ...form };
    requestorOnboardForm.individual = { ...form.individual }; // to avoid dob error.

    const requestorType = form.requestorType;
    if (requestorType === "INDIVIDUAL") {
      requestorOnboardForm.organization = {};
      let dob = new Date(form.individual.dob);
      requestorOnboardForm.individual.dob = convertDateToNumber(dob);
    }

    if (requestorType === "COMPANY") {
      requestorOnboardForm.individual = {};
    }

    formData.append("message", JSON.stringify(requestorOnboardForm));

    requestorOnboardForm.addresses.forEach(address => {
      addDocs(
        formData,
        `address_doc_${address.type}`,
        address.addressProofUpload
      );
      address.addressProofUpload = undefined;
    });

    requestorOnboardForm.identificationDocs.forEach(doc => {
      addDocs(formData, `id_doc_${doc.type}`, doc.idProofUpload);
      doc.idProofUpload = undefined;
    });
    return formData;
  };

  handleSubmit = (form, setSubmitting) => {
    if (!this.props.match) {
      this.setState({
        //Default alert
        alertType: "Default",
        showAlert: true,
        alertColor: "danger",
        alertMessage: "Invalid ID provided"
      });

      return;
    }

    // setSubmitting(true);

    const formData = this.getFormData(form);
    const url = `${REQUESTOR_ONBOARD_API}/${this.props.match.params.id}`;

    HttpUtil.put(
      url,
      {},
      formData,
      data => {
        setSubmitting(false);

        // this.setState({
        //   //Default alert
        //   alertType: "Default",
        //   showAlert: true,
        //   alertColor: "success",
        //   alertMessage: "Onboard form is submitted successfully"
        // });

        this.setState({
          redirectToOnboardForm: true,
          redirectToOnboardFormId: data._id
        });
        this.fetchOnboardFormData();
        window.scrollTo(0, 0);
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  fetchOnboardFormData = () => {
    const id = this.props.match.params.id;
    if (this.props.location.state !== undefined) {
      let message = "";
      let created = this.props.location.state.onboardCreated || false;
      let updated = this.props.location.state.onboardUpdated || false;

      if (created) {
        message = "Onboard Form has been created successfully.";
      }

      if (updated) {
        message = "Onboard Form has been updated successfully.";
      }

      this.setState({
        alertType: "Default",
        showAlert: true,
        alertColor: "success",
        alertMessage: message
      });
      window.scrollTo(0, 0);
    }

    HttpUtil.get(
      `${REQUESTOR_ONBOARD_API}/${id}`,
      {},
      onboardForm => {
        if (onboardForm.requestorType === "INDIVIDUAL") {
          let indvDob = onboardForm.individual.dob || null;
          onboardForm.individual.dob = convertNumberToDate(indvDob);
        }
        this.setState({ onboardForm });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    if (this.props.match !== undefined) {
      this.fetchOnboardFormData();
    } else {
      // console.log("No ID Found.");
    }
  };

  render() {
    const { redirectToOnboardForm, redirectToOnboardFormId } = this.state;

    if (!this.state.onboardForm) {
      return null;
    }

    if (redirectToOnboardForm) {
      const stateObj = {
        pathname: `/asset/requestor-onboard-form/${redirectToOnboardFormId}`,
        state: { onboardUpdated: true }
      };
      return <Redirect to={stateObj} />;
    }

    const props = {
      onboardForm: this.state.onboardForm,
      handleSubmit: this.handleSubmit,
      validationSchema: REQUESTOR_ONBOARD_FORM_SCHEMA
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <RequestorOnboardForm {...props} />
      </Fragment>
    );
  }
}

export default RequestorOnboard;
