import React, { Component, Fragment } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDisplayDate,
  convertDateToNumber,
  convertNumberToDate
} from "../../../lease/DateUtil";

import * as Yup from "yup";
import { changeToINR } from "../../../common/LookupConstants";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function addtotalCharge(values) {
  let sum = 0;
  sum = sum + Number(values.assetCharge || 0);
  sum = sum + Number(values.additionalCharge || 0);

  return sum;
}

function getDateLimit(assetRequested) {
  let minDate = convertDateToNumber(assetRequested[0].from);
  let maxDate = convertDateToNumber(assetRequested[0].to);
  assetRequested.forEach(asset => {
    let fromDate = convertDateToNumber(asset.from);
    let toDate = convertDateToNumber(asset.to);
    if (fromDate < minDate) {
      minDate = fromDate;
    }
    if (toDate > maxDate) {
      maxDate = toDate;
    }
  });
  minDate = convertNumberToDate(minDate);
  maxDate = convertNumberToDate(maxDate);
  return { minDate, maxDate };
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const ChargeForm = props => {
  const { values, setFieldValue, touched, errors, resetForm } = props;

  const dateLimit = getDateLimit(props.assetRequested);
  return (
    <Fragment>
      <div className="modal-content">
        <div className="modal-header border-0">
          <span className="edit-page-title" style={{ color: "#647acb" }}>
            Add Usage Charges
          </span>

          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              props.toggleUsageModel();
              resetForm(props.initialValues);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group row mb-4">
            <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label-required">
              Asset
            </label>
            <div className="col-12 col-lg-8">
              <Field
                component="select"
                name="asset"
                value={values.asset || ""}
                className={`custom-select custom-select-sm ${
                  touched["asset"] && errors["asset"] ? "is-invalid" : ""
                }`}
                onChange={e => {
                  setFieldValue("asset", e.target.value);
                  let requestedAsset = props.assetRequested.filter(
                    at => at.asset.value === e.target.value
                  );
                  if (requestedAsset.length > 0) {
                    setFieldValue(
                      "unitTypes",
                      requestedAsset[0].asset.unitTypes
                    );
                    setFieldValue(
                      "rateTypes",
                      requestedAsset[0].asset.rateTypes
                    );
                    setFieldValue("unitType", requestedAsset[0].unitType || "");
                    setFieldValue("usageUnit", null);
                  } else {
                    setFieldValue("unitTypes", []);
                    setFieldValue("rateTypes", []);
                    setFieldValue("unitType", "");
                    setFieldValue("usageUnit", null);
                  }
                }}
              >
                <option value="">Select...</option>
                {props.assetRequested.map((at, i) => (
                  <option key={i} value={at.asset.value}>
                    {at.asset.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                component="div"
                name="asset"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="form-group row mb-4">
            <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label-required">
              Usage Period
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <DatePicker
                value={values.fromDate ? getDisplayDate(values.fromDate) : ""}
                selected={values.fromDate ? values.fromDate : null}
                onChange={date => {
                  setFieldValue("fromDate", date);
                }}
                utcOffset={0}
                minDate={dateLimit.minDate}
                maxDate={dateLimit.maxDate}
                placeholderText="From : dd-mm-yyyy"
                className={getErrorCssClassName(touched, errors, "fromDate")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <ErrorMessage
                component="div"
                name="fromDate"
                className="invalid-feedback"
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <DatePicker
                value={values.toDate ? getDisplayDate(values.toDate) : ""}
                selected={values.toDate ? values.toDate : null}
                onChange={date => {
                  setFieldValue("toDate", date);
                }}
                utcOffset={0}
                minDate={values.fromDate ? values.fromDate : dateLimit.minDate}
                maxDate={dateLimit.maxDate}
                placeholderText="To : dd-mm-yyyy"
                className={getErrorCssClassName(touched, errors, "toDate")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <ErrorMessage
                component="div"
                name="toDate"
                className="invalid-feedback"
              />
            </div>
          </div>
          {values.unitType && (
            <div className="form-group row mb-4">
              <label className="col-form-label col-12 col-lg-3 col-xl-3 edit-page-label-required">
                Used Units
              </label>
              <div className="col-12 col-lg-4 col-xl-4">
                <div class="input-group">
                  <Field
                    className={`form-control form-control-sm ${
                      touched["usageUnit"] && errors["usageUnit"]
                        ? "is-invalid"
                        : ""
                    }`}
                    name="usageUnit"
                    value={values.units}
                  />
                  <div class="input-group-append">
                    <Field
                      component="select"
                      className="custom-select custom-select-sm"
                      name="unitType"
                      value={values.unitType}
                      readOnly
                      disabled
                    >
                      {(values.unitTypes || []).map(unitType => (
                        <option value={unitType._id}>{unitType.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="unitType"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <ErrorMessage
                  component="div"
                  name="usageUnit"
                  className="text-danger"
                />
              </div>
            </div>
          )}

          <div className="form-group row mb-4">
            <label className="col-4 col-lg-3 m-0 edit-page-label-required">
              Used Hours
            </label>
            <div className="col-8 col-lg-4 m-0">
              <Field
                type="text"
                className={getErrorCssClassName(touched, errors, "usageHours")}
                inputMode="numeric"
                name="usageHours"
                value={values.usageHours || ""}
              />
              <ErrorMessage
                component="div"
                name="usageHours"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="form-group row mb-4">
            <label className="col-4 col-lg-3 m-0 edit-page-label-required">
              Usage Charge
            </label>
            <div className="col-8 col-lg-4 m-0">
              <Field
                type="text"
                className={getErrorCssClassName(touched, errors, "assetCharge")}
                inputMode="numeric"
                name="assetCharge"
                value={values.assetCharge || ""}
              />
              <ErrorMessage
                component="div"
                name="assetCharge"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="form-group row mb-4">
            <label className="col-4 col-lg-3 m-0 edit-page-label">
              Additional Charges
            </label>
            <div className="col-8 col-lg-4 m-0">
              <Field
                type="text"
                className={getErrorCssClassName(
                  touched,
                  errors,
                  "additionalCharge"
                )}
                inputMode="numeric"
                name="additionalCharge"
                value={values.additionalCharge || ""}
              />
              <ErrorMessage
                component="div"
                name="additionalCharge"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="form-group row mb-4">
            <label className="col-4 col-lg-3 m-0 edit-page-label">
              Charge Description
            </label>
            <div className="col-8 col-lg-4 m-0">
              <Field
                component="textarea"
                rows="5"
                className={getErrorCssClassName(touched, errors, "description")}
                name="description"
                value={values.description || ""}
              />
              <ErrorMessage
                component="div"
                name="description"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="row no-gutters mt-5">
            <div className="col-12 col-lg-2" />
            <div className="col-12 col-lg-7 text-right">
              <label className="edit-page-label">Total Charges :</label>
              <span className="ml-2" style={{ fontSize: "1rem" }}>
                {changeToINR(addtotalCharge(values))}
              </span>
            </div>
            <div className="col-12 col-lg-3" />
          </div>
          <div className="row mt-4 mb-4">
            <div className="col-md-12 text-right">
              <button className="btn btn-sm btn-primary" type="submit">
                Add
              </button>
              <button
                className="btn btn-sm btn-outline-secondary mr-5 ml-2"
                type="button"
                onClick={() => {
                  props.toggleUsageModel();
                  resetForm(props.initialValues);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const CHARGE_SCHEMA = Yup.object().shape({
  asset: Yup.string()
    .label("Asset")
    .required(),
  unitType: Yup.string().label("Unit Type"),
  usageUnit: Yup.mixed()
    .label("Usage Unit")
    .when("unitType", {
      is: unitType => !unitType,
      then: Yup.mixed()
        .nullable()
        .notRequired(),
      otherwise: Yup.number()
        .label("Usage Unit")
        .required()
    }),
  usageHours: Yup.number()
    .label("Used Hours")
    .positive()
    .required(),
  assetCharge: Yup.number()
    .label("Charge")
    .positive()
    .required(),
  fromDate: Yup.mixed()
    .label("From Date")
    .required(),
  toDate: Yup.mixed()
    .label("To Date")
    .required(),
  description: Yup.string()
    .label("Asset")
    .optional(),
  additionalCharge: Yup.number()
    .label("Charge")
    .positive()
    .optional()
});

class AssetUsageCharges extends Component {
  render() {
    const props = {
      validationSchema: CHARGE_SCHEMA,
      handleSubmit: this.props.handleSubmit,
      assetRequested: this.props.assetRequested,
      toggleUsageModel: this.props.toggleUsageModel
    };

    return (
      <Fragment>
        <Formik
          enableReinitialize
          initialValues={this.props.assetUsageCharge}
          validationSchema={props.validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            return props.handleSubmit(values, { setSubmitting, resetForm });
          }}
          render={renderProps => (
            <Form>
              <ChargeForm {...renderProps} {...props} />
            </Form>
          )}
        />
      </Fragment>
    );
  }
}

export default AssetUsageCharges;
