import React, { useCallback, useMemo, useState } from "react";
import { ErrorMessage, Field } from "formik";

import Select from "react-select";
import {
  INCUBATEE_STAGES,
  INCUBATION_SECTORS,
  SUB_SECTORS,
  INCUBATION_CATEGORY_TYPES
} from "../../common/IncubateeProfileConstants";
import { LEGAL_STATUS_TYPES } from "../../common/LookupConstants";
import { getYears, getDisplayDate } from "../../lease/DateUtil";
import DatePicker from "react-datepicker";
import ExportSelectOptions from "./ExportSelectOptions";

function changeToSelectOptions(constant) {
  return constant.map(option => {
    return {
      label: option.value,
      value: option.key
    };
  });
}
const YEARS = getYears();

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function IncubationFilters(props) {
  const {
    values,
    touched,
    errors,
    setAndChooseValue,
    setFieldTouched,
    productTypesOptions
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-12 mb-3 edit-page-section-header-text">
          Incubation Filters
        </div>
      </div>
      {/* IncubateeType */}
      <div className="form-group row">
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Incubatee Type
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              id="incTypeAll"
              type="radio"
              name="incubateeType"
              checked={values.incubateeType === ""}
              onChange={() => setAndChooseValue("incubateeType", "")}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="incTypeAll"
            >
              All
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              id="incTypeInd"
              type="radio"
              name="incubateeType"
              checked={values.incubateeType === "INDIVIDUAL"}
              onChange={() => setAndChooseValue("incubateeType", "INDIVIDUAL")}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="incTypeInd"
            >
              Individual
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              type="radio"
              id="incTypeOrg"
              name="incubateeType"
              checked={values.incubateeType === "COMPANY"}
              onChange={() => setAndChooseValue("incubateeType", "COMPANY")}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="incTypeOrg"
            >
              Organization
            </label>
          </div>
        </div>

        {/* Graduation Status */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Graduation Status
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              id="graduationStatusAll"
              type="radio"
              name="graduationStatus"
              checked={values.graduationStatus === ""}
              onChange={() => setAndChooseValue("graduationStatus", "")}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="graduationStatusAll"
            >
              All
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              id="graduationStatusOngoing"
              type="radio"
              name="graduationStatus"
              checked={values.graduationStatus === "ONGOING"}
              onChange={() => setAndChooseValue("graduationStatus", "ONGOING")}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="graduationStatusOngoing"
            >
              Ongoing
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              type="radio"
              id="graduationStatusGraduated"
              name="graduationStatus"
              checked={values.graduationStatus === "GRADUATED"}
              onChange={() =>
                setAndChooseValue("graduationStatus", "GRADUATED")
              }
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="graduationStatusGraduated"
            >
              Graduated
            </label>
          </div>
        </div>
      </div>

      <div className="form-group row">
        {/* Incubated Date */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Incubation Start Date
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <label htmlFor="" className="col-form-label-date">
            From
          </label>
          <DatePicker
            value={getDisplayDate(values.incubatedDateFrom)}
            selected={values.incubatedDateFrom}
            onChange={date => {
              setAndChooseValue("incubatedDateFrom", date);
              setFieldTouched("incubatedDateFrom", true);
            }}
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className="form-control form-control-sm"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
        <div className="col-12 col-lg-4 col-xl-4">
          <label htmlFor="" className="col-form-label-date">
            To
          </label>
          <DatePicker
            value={getDisplayDate(values.incubatedDateTo)}
            selected={values.incubatedDateTo}
            onChange={date => {
              setAndChooseValue("incubatedDateTo", date);
              setFieldTouched("incubatedDateTo", true);
            }}
            utcOffset={0}
            placeholderText="dd-mm-yyyy"
            className={`form-control form-control-sm ${
              getError(touched, errors, "incubatedDateTo") ? "is-invalid" : ""
            }`}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          {errors.incubatedDateTo && touched.incubatedDateTo && (
            <div className="invalid-feedback mb-2">
              {errors.incubatedDateTo}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        {/* Graduated Date */}
        {!(values.graduationStatus === "ONGOING") && (
          <React.Fragment>
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Graduated Date
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="" className="col-form-label-date">
                From
              </label>

              <DatePicker
                value={getDisplayDate(values.graduatedDateFrom)}
                selected={values.graduatedDateFrom}
                onChange={date => {
                  setAndChooseValue("graduatedDateFrom", date);
                  setFieldTouched("graduatedDateFrom", true);
                }}
                utcOffset={0}
                placeholderText="dd-mm-yyyy"
                className="form-control form-control-sm"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-4">
              <label htmlFor="" className="col-form-label-date">
                To
              </label>
              <DatePicker
                value={getDisplayDate(values.graduatedDateTo)}
                selected={values.graduatedDateTo}
                onChange={date => {
                  setAndChooseValue("graduatedDateTo", date);
                  setFieldTouched("graduatedDateTo", true);
                }}
                utcOffset={0}
                placeholderText="dd-mm-yyyy"
                className={`form-control form-control-sm ${
                  getError(touched, errors, "graduatedDateTo")
                    ? "is-invalid"
                    : ""
                }`}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors.graduatedDateTo && touched.graduatedDateTo && (
                <div className="invalid-feedback mb-2">
                  {errors.graduatedDateTo}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="form-group row">
        {/* Incubatee Stage */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Incubatee Stage
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Select
            isMulti
            name="incubateeStage"
            value={values.incubateeStage || null}
            options={
              changeToSelectOptions(INCUBATEE_STAGES) // styles={styles}
            }
            onChange={e => setAndChooseValue("incubateeStage", e)}
          />
        </div>
        {/* Incubation Sector */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Incubation Sector
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Select
            isMulti
            name="incubationSector"
            value={values.incubationSector || null}
            options={
              changeToSelectOptions(INCUBATION_SECTORS) // styles={styles}
            }
            onChange={e => setAndChooseValue("incubationSector", e)}
          />
        </div>
      </div>

      <div className="form-group row">
        {/* Sub Tech Sector */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Sub Tech Sector
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Select
            isMulti
            name="subTechSector"
            value={values.subTechSector || null}
            options={
              changeToSelectOptions(SUB_SECTORS) // styles={styles}
            }
            onChange={e => setAndChooseValue("subTechSector", e)}
          />
        </div>

        {/* Incubation Category */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Incubation Category
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Select
            isMulti
            name="incubationCategory"
            value={values.incubationCategory || null}
            options={
              changeToSelectOptions(INCUBATION_CATEGORY_TYPES) // styles={styles}
            }
            onChange={e => setAndChooseValue("incubationCategory", e)}
          />
        </div>
      </div>

      <div className="form-group row">
        {/* Residential Status */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Residential Status
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              id="residentialStatusAll"
              type="radio"
              name="residentialStatus"
              checked={values.residentialStatus === ""}
              onChange={() => setAndChooseValue("residentialStatus", "")}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="residentialStatusAll"
            >
              All
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              id="residentialStatusPhysical"
              type="radio"
              name="residentialStatus"
              checked={values.residentialStatus === "PHYSICAL"}
              onChange={() =>
                setAndChooseValue("residentialStatus", "PHYSICAL")
              }
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="residentialStatusPhysical"
            >
              Physical
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              type="radio"
              id="residentialStatusVirtual"
              name="residentialStatus"
              checked={values.residentialStatus === "VIRTUAL"}
              onChange={() => setAndChooseValue("residentialStatus", "VIRTUAL")}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="residentialStatusVirtual"
            >
              Virtual
            </label>
          </div>
        </div>

        {/* Patents Filed */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Patents Filed
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              id="patentFiledYes"
              type="radio"
              name="patentFiled"
              checked={values.patentFiled === true}
              onChange={() => setAndChooseValue("patentFiled", true)}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="patentFiledYes"
            >
              Yes
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline mt-1">
            <Field
              id="patentFiledNo"
              type="radio"
              name="patentFiled"
              checked={values.patentFiled === false}
              onChange={() => setAndChooseValue("patentFiled", false)}
              className={"custom-control-input"}
            />
            <label
              className="edit-page-label custom-control-label"
              htmlFor="patentFiledNo"
            >
              No
            </label>
          </div>
        </div>
      </div>

      <div className="form-group row">
        {/* Product Category */}
        <label className="col-form-label col-12 col-lg-2 col-xl-2">
          Product Category
        </label>
        <div className="col-12 col-lg-4 col-xl-4">
          <Select
            isMulti
            name="productCategory"
            value={values.productCategory || null}
            options={
              productTypesOptions // styles={styles}
            }
            onChange={e => setAndChooseValue("productCategory", e)}
          />
        </div>
      </div>
    </>
  );
}

function IndividualFilters(props) {
  const { values, setAndChooseValue, errors, touched, individualOnly } = props;

  return (
    <>
      {individualOnly ? (
        <div>
          <div className="row">
            <div className="col-12 mb-3 edit-page-section-header-text">
              Individual Filters
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Gender
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  id="genderMale"
                  type="radio"
                  name="gender"
                  checked={values.gender === "M"}
                  onChange={() => setAndChooseValue("gender", "M")}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="genderMale"
                >
                  Male
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  id="genderFemale"
                  type="radio"
                  name="gender"
                  checked={values.gender === "F"}
                  onChange={() => setAndChooseValue("gender", "F")}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="genderFemale"
                >
                  Female
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  type="radio"
                  id="genderTransgender"
                  name="gender"
                  checked={values.gender === "T"}
                  onChange={() => setAndChooseValue("gender", "T")}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="genderTransgender"
                >
                  Transgender
                </label>
              </div>
            </div>

            {/* Age */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Age
            </label>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                From Age
              </label>
              <Field
                type="number"
                className="form-control form-control-sm"
                name="fromAge"
                value={values.fromAge}
                onChange={e => setAndChooseValue("fromAge", e.target.value)}
                min="0"
              />
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                To Age
              </label>
              <Field
                type="number"
                className={`form-control form-control-sm ${
                  getError(touched, errors, "toAge") ? "is-invalid" : ""
                }`}
                name="toAge"
                value={values.toAge}
                onChange={e => setAndChooseValue("toAge", e.target.value)}
                min="0"
              />
              <ErrorMessage
                component="div"
                name="toAge"
                className="invalid-feedback mb-2"
              />
            </div>
          </div>

          <div className="form-group row">
            {/* PSG Alumini */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              PSG Alumni
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  id="psgAluminiAll"
                  type="radio"
                  name="psgAlumini"
                  checked={values.psgAlumini === ""}
                  onChange={() => setAndChooseValue("psgAlumini", "")}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="psgAluminiAll"
                >
                  Both
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  id="psgAluminiYes"
                  type="radio"
                  name="psgAlumini"
                  checked={values.psgAlumini === true}
                  onChange={() => setAndChooseValue("psgAlumini", true)}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="psgAluminiYes"
                >
                  Alumni
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  type="radio"
                  id="psgAluminiNo"
                  name="psgAlumini"
                  checked={values.psgAlumini === false}
                  onChange={() => setAndChooseValue("psgAlumini", false)}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="psgAluminiNo"
                >
                  Non Alumni
                </label>
              </div>
            </div>

            {/* Year of Passing */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Year of Passing
            </label>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                From
              </label>
              <Field
                component="select"
                className={`custom-select custom-select-sm`}
                name="yearOfPassingFrom"
                value={values.yearOfPassingFrom}
                onChange={e =>
                  setAndChooseValue("yearOfPassingFrom", e.target.value)
                }
              >
                <option value="">Select..</option>
                {YEARS.map(year => (
                  <option
                    data-testid="select-option-yearOfPassingFrom"
                    key={year}
                    value={year}
                  >
                    {year}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                To
              </label>
              <Field
                component="select"
                name="yearOfPassingTo"
                className={`custom-select custom-select-sm ${
                  getError(touched, errors, "yearOfPassingTo")
                    ? "is-invalid"
                    : ""
                }`}
                value={values.yearOfPassingTo}
                onChange={e =>
                  setAndChooseValue("yearOfPassingTo", e.target.value)
                }
              >
                <option value="">Select..</option>
                {YEARS.map(year => (
                  <option
                    data-testid="select-option-yearOfPassingTo"
                    key={year}
                    value={year}
                  >
                    {year}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                component="div"
                name="yearOfPassingTo"
                className="invalid-feedback mb-2"
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

function OrganizationFilters(props) {
  const {
    values,
    errors,
    touched,
    organizationOnly,
    setAndChooseValue
  } = props;

  const getFinancialYears = useCallback(limit => {
    let year = new Date().getFullYear();
    const fy = [];
    for (let indx = 0; indx < limit; indx++) {
      fy.push(
        `${year - indx - 1}-${((year - indx) % 100)
          .toString()
          .padStart(2, "0")}`
      );
    }
    return fy;
  }, []);

  const fnYear = useMemo(() => getFinancialYears(5), []);
  const fnYearForMetrics = useMemo(() => getFinancialYears(3), []);

  return (
    <>
      {organizationOnly ? (
        <div>
          <div className="row">
            <div className="col-12 mb-3 edit-page-section-header-text">
              Organization Filters
            </div>
          </div>
          <div className="form-group row">
            {/* Establishment year */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Establishment year
            </label>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                From
              </label>
              <Field
                component="select"
                className="custom-select custom-select-sm"
                name="companyStartDateFrom"
                value={values.companyStartDateFrom}
                onChange={e =>
                  setAndChooseValue("companyStartDateFrom", e.target.value)
                }
              >
                <option value="">Select..</option>
                {YEARS.map(year => (
                  <option
                    data-testid="select-option-companyStartDateFrom"
                    key={year}
                    value={year}
                  >
                    {year}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                To
              </label>
              <Field
                component="select"
                className={`custom-select custom-select-sm ${
                  getError(touched, errors, "companyStartDateTo")
                    ? "is-invalid"
                    : ""
                }`}
                name="companyStartDateTo"
                value={values.companyStartDateTo}
                onChange={e =>
                  setAndChooseValue("companyStartDateTo", e.target.value)
                }
              >
                <option data-testid="select-option-companyStartDateTo" value="">
                  Select..
                </option>
                {YEARS.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                component="div"
                name="companyStartDateTo"
                className="invalid-feedback mb-2"
              />
            </div>
            {/* Legal Status */}

            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Legal Status
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <Field
                component="select"
                className="custom-select custom-select-sm"
                name="legalStatus"
                value={values.legalStatus}
                onChange={e => setAndChooseValue("legalStatus", e.target.value)}
              >
                <option value="">Select..</option>
                {LEGAL_STATUS_TYPES.map(type => (
                  <option
                    data-testid="select-option-legalStatus"
                    key={type.key}
                    value={type.key}
                  >
                    {type.value}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="form-group row">
            {/* Last Annual Turnover */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Annual Turnover
            </label>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                Financial year
              </label>
              <Field
                component="select"
                className="custom-select custom-select-sm"
                name="financialYear"
                value={values.financialYear}
                onChange={e =>
                  setAndChooseValue("financialYear", e.target.value)
                }
              >
                <option value="">Select..</option>
                {fnYear.map(yyyy => {
                  return (
                    <option key={yyyy} value={yyyy}>
                      {yyyy}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                component="div"
                name="lastAnnualTurnoverTo"
                className="invalid-feedback mb-2"
              />
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                From Amount
              </label>
              <Field
                type="number"
                className="form-control form-control-sm"
                name="lastAnnualTurnoverFrom"
                value={values.lastAnnualTurnoverFrom}
                onChange={e =>
                  setAndChooseValue("lastAnnualTurnoverFrom", e.target.value)
                }
                min="0"
              />
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                To Amount
              </label>
              <Field
                type="number"
                className={`form-control form-control-sm ${
                  getError(touched, errors, "lastAnnualTurnoverTo")
                    ? "is-invalid"
                    : ""
                }`}
                name="lastAnnualTurnoverTo"
                value={values.lastAnnualTurnoverTo}
                onChange={e =>
                  setAndChooseValue("lastAnnualTurnoverTo", e.target.value)
                }
                min="0"
              />
              <ErrorMessage
                component="div"
                name="lastAnnualTurnoverTo"
                className="invalid-feedback mb-2"
              />
            </div>
          </div>

          <div className="text-sm font-bold font-weight-bold border-bottom pb-2 mb-2">
            Employee Count
          </div>

          <div className="ml-2 form-group row">
            {/* Metrics Financial year */}
            <div className="col-12 col-lg-3 col-xl-3">
              <label className="col-form-label">Financial year</label>
              <Field
                component="select"
                className="custom-select custom-select-sm"
                name="metricsYear"
                value={values.metricsYear}
                onChange={e => setAndChooseValue("metricsYear", e.target.value)}
              >
                <option value="">Select..</option>
                {fnYearForMetrics.map(yyyy => {
                  return (
                    <option key={yyyy} value={yyyy}>
                      {yyyy}
                    </option>
                  );
                })}
              </Field>
            </div>

            {/* Total Employee Count */}
            <div className="col-12 col-lg-3 col-xl-3">
              <label className="col-form-label">Total Employee Count</label>
              <div className="form-group row">
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="number"
                    className="form-control form-control-sm"
                    name="totalEmployeeCountFrom"
                    placeholder="From"
                    value={values.totalEmployeeCountFrom}
                    onChange={e =>
                      setAndChooseValue(
                        "totalEmployeeCountFrom",
                        e.target.value
                      )
                    }
                    min="0"
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="number"
                    className={`form-control form-control-sm ${
                      getError(touched, errors, "totalEmployeeCountTo")
                        ? "is-invalid"
                        : ""
                    }`}
                    name="totalEmployeeCountTo"
                    placeholder="To"
                    value={values.totalEmployeeCountTo}
                    onChange={e =>
                      setAndChooseValue("totalEmployeeCountTo", e.target.value)
                    }
                    min="0"
                  />
                  <ErrorMessage
                    component="div"
                    name="totalEmployeeCountTo"
                    className="invalid-feedback mb-2"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-xl-3">
              <label className="col-form-label">Female Employee Count</label>
              <div className="form-group row">
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="number"
                    className="form-control form-control-sm"
                    name="femaleEmployeeCountFrom"
                    placeholder="From"
                    value={values.femaleEmployeeCountFrom}
                    onChange={e =>
                      setAndChooseValue(
                        "femaleEmployeeCountFrom",
                        e.target.value
                      )
                    }
                    min="0"
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <Field
                    type="number"
                    className={`form-control form-control-sm ${
                      getError(touched, errors, "femaleEmployeeCountTo")
                        ? "is-invalid"
                        : ""
                    }`}
                    name="femaleEmployeeCountTo"
                    placeholder="To"
                    value={values.femaleEmployeeCountTo}
                    onChange={e =>
                      setAndChooseValue("femaleEmployeeCountTo", e.target.value)
                    }
                    min="0"
                  />
                  <ErrorMessage
                    component="div"
                    name="femaleEmployeeCountTo"
                    className="invalid-feedback mb-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

function PromoterFilters(props) {
  const {
    values,
    setAndChooseValue,
    errors,
    touched,
    organizationOnly
  } = props;
  return (
    <>
      {organizationOnly ? (
        <div>
          <div className="row">
            <div className="col-12 mb-3 edit-page-section-header-text">
              Promoter Filters
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Gender
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  id="proGenderMale"
                  type="radio"
                  name="proGender"
                  checked={values.proGender === "M"}
                  onChange={() => setAndChooseValue("proGender", "M")}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="proGenderMale"
                >
                  Male
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  id="proGenderFemale"
                  type="radio"
                  name="proGender"
                  checked={values.proGender === "F"}
                  onChange={() => setAndChooseValue("proGender", "F")}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="proGenderFemale"
                >
                  Female
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  type="radio"
                  id="proGenderTransgender"
                  name="proGender"
                  checked={values.proGender === "T"}
                  onChange={() => setAndChooseValue("proGender", "T")}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="proGenderTransgender"
                >
                  Transgender
                </label>
              </div>
            </div>

            {/* Age */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Age
            </label>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                From Age
              </label>
              <Field
                type="number"
                className="form-control form-control-sm"
                name="proFromAge"
                value={values.proFromAge}
                onChange={e => setAndChooseValue("proFromAge", e.target.value)}
                min="0"
              />
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                To Age
              </label>
              <Field
                type="number"
                className={`form-control form-control-sm ${
                  getError(touched, errors, "proToAge") ? "is-invalid" : ""
                }`}
                name="proToAge"
                onChange={e => setAndChooseValue("proToAge", e.target.value)}
                value={values.proToAge}
                min="0"
              />
              <ErrorMessage
                component="div"
                name="proToAge"
                className="invalid-feedback mb-2"
              />
            </div>
          </div>

          <div className="form-group row">
            {/* PSG Alumini */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              PSG Alumni
            </label>
            <div className="col-12 col-lg-4 col-xl-4">
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  id="proPsgAluminiAll"
                  type="radio"
                  name="proPsgAlumini"
                  checked={values.proPsgAlumini === ""}
                  onChange={() => setAndChooseValue("proPsgAlumini", "")}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="proPsgAluminiAll"
                >
                  Both
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  id="proPsgAluminiYes"
                  type="radio"
                  name="proPsgAlumini"
                  checked={values.proPsgAlumini === true}
                  onChange={() => setAndChooseValue("proPsgAlumini", true)}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="proPsgAluminiYes"
                >
                  Alumni
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline mt-1">
                <Field
                  type="radio"
                  id="proPsgAluminiNo"
                  name="proPsgAlumini"
                  checked={values.proPsgAlumini === false}
                  onChange={() => setAndChooseValue("proPsgAlumini", false)}
                  className={"custom-control-input"}
                />
                <label
                  className="edit-page-label custom-control-label"
                  htmlFor="proPsgAluminiNo"
                >
                  Non Alumni
                </label>
              </div>
            </div>

            {/* Year of Passing */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Year of Passing
            </label>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                From
              </label>
              <Field
                component="select"
                className={`custom-select custom-select-sm`}
                name="proYearOfPassingFrom"
                value={values.proYearOfPassingFrom}
                onChange={e =>
                  setAndChooseValue("proYearOfPassingFrom", e.target.value)
                }
              >
                <option value="">Select..</option>
                {YEARS.map(year => (
                  <option
                    data-testid="select-option-proYearOfPassingFrom"
                    key={year}
                    value={year}
                  >
                    {year}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-12 col-lg-2 col-xl-2">
              <label htmlFor="" className="col-form-label-date">
                To
              </label>
              <Field
                component="select"
                name="proYearOfPassingTo"
                className={`custom-select custom-select-sm ${
                  getError(touched, errors, "proYearOfPassingTo")
                    ? "is-invalid"
                    : ""
                }`}
                value={values.proYearOfPassingTo}
                onChange={e =>
                  setAndChooseValue("proYearOfPassingTo", e.target.value)
                }
              >
                <option value="">Select..</option>
                {YEARS.map(year => (
                  <option
                    data-testid="select-option-proYearOfPassingTo"
                    key={year}
                    value={year}
                  >
                    {year}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                component="div"
                name="proYearOfPassingTo"
                className="invalid-feedback mb-2"
              />
            </div>
          </div>
          <div className="form-group row">
            {/* Founders Only */}
            <label className="col-form-label col-12 col-lg-2 col-xl-2">
              Founders Only
            </label>
            <div className="col-12 col-lg-4 col-xl-4 mt-2">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  id="foundersOnly"
                  name="foundersOnly"
                  checked={values.foundersOnly}
                  value={values.foundersOnly}
                  onChange={e =>
                    setAndChooseValue("foundersOnly", e.target.checked)
                  }
                />
                <label className="custom-control-label" htmlFor="foundersOnly">
                  Yes
                </label>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

function isSelected(options) {
  let bool = false;

  Object.values(options).forEach(val => {
    if (typeof val === "boolean") {
      bool = bool || val;
    }

    if (typeof val === "object") {
      Object.values(val).forEach(va => {
        if (typeof va === "object") {
          Object.values(va).forEach(v => {
            bool = bool || v;
          });
        } else {
          bool = bool || va;
        }
      });
    }
  });
  return bool;
}

const filterAndSelectMaping = {
  graduationStatus: "profileFields.graduationStatus",
  incubatedDateFrom: "profileFields.incubationDate",
  incubatedDateTo: "profileFields.incubationDate",
  graduatedDateFrom: "profileFields.graduationDate",
  graduatedDateTo: "profileFields.graduationDate",
  incubateeStage: "profileFields.incubateeStage",
  incubationSector: "profileFields.incubateeSector",
  subTechSector: "profileFields.subTechSector",
  incubationCategory: "profileFields.incubationCategory",
  residentialStatus: "profileFields.residentialStatus",
  productCategory: [
    "productsServicesFields.name",
    "productsServicesFields.category"
  ],
  patentFiled: "patents",
  gender: "basicInfoFields.gender",
  psgAlumini: "educationFields.alumni",
  yearOfPassingFrom: "educationFields.year",
  yearOfPassingTo: "educationFields.year",
  fromAge: "basicInfoFields.dob",
  toAge: "basicInfoFields.dob",
  companyStartDateFrom: "basicInfoFields.estYear",
  companyStartDateTo: "basicInfoFields.estYear",
  legalStatus: "basicInfoFields.legalStatus",
  totalEmployeeCountFrom: "metricsFields.totalEmployeeCount",
  totalEmployeeCountTo: "metricsFields.totalEmployeeCount",
  femaleEmployeeCountFrom: "metricsFields.femaleEmployeeCount",
  femaleEmployeeCountTo: "metricsFields.femaleEmployeeCount",
  metricsYear: "metricsFields.updatedAt",
  lastAnnualTurnoverFrom: "financialsFields.annualTurnover",
  lastAnnualTurnoverTo: "financialsFields.annualTurnover",
  financialYear: "financialsFields.annualTurnover",
  proGender: "promotersFields.basicInfoFields.gender",
  proFromAge: "promotersFields.basicInfoFields.dob",
  proToAge: "promotersFields.basicInfoFields.dob",
  proYearOfPassingFrom: "promotersFields.educationFields.year",
  proYearOfPassingTo: "promotersFields.educationFields.year",
  proPsgAlumini: "promotersFields.educationFields.alumni",
  foundersOnly: "promotersFields.basicInfoFields.founders"
};

function IncubateeDataExportForm(props) {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleReset
  } = props;

  function handlePatentSelectAll(check) {
    const patentFields = {
      titleOfInnovation: check,
      applicationNumber: check,
      applicationFilingDate: check,
      patentStatus: check,
      fieldOfInvention: check,
      classification: check,
      country: check
    };
    setFieldValue("selectOptions.patentsFields", patentFields);
    setFieldValue("selectOptions.patents", check);
  }

  // Takes the field name and checks the related option using maping object.
  function chooseFilterSpecificOption(field) {
    if (!filterAndSelectMaping[field]) {
      return;
    }
    const fieldType = typeof filterAndSelectMaping[field];

    if (filterAndSelectMaping[field] === "patents") {
      return handlePatentSelectAll(!values[field]);
    }

    if (fieldType === "string" || fieldType === "boolean") {
      return setFieldValue(
        `selectOptions.${filterAndSelectMaping[field]}`,
        true
      );
    }
    filterAndSelectMaping[field].forEach(field =>
      setFieldValue(`selectOptions.${field}`, true)
    );
  }

  // Intermediate for chooseing option and seting value state
  function setAndChooseValue(field, value) {
    setFieldValue(field, value);
    chooseFilterSpecificOption(field);
  }

  const [errorMsg, setErrorMsg] = useState(false);

  let INDIVIDUAL =
    values.incubateeType === "" || values.incubateeType === "INDIVIDUAL";
  let ORGANIZATION =
    values.incubateeType === "" || values.incubateeType === "COMPANY";

  return (
    <div className=" mb-3">
      <div className="col-md-12 p-0">
        <div className="card edit-page-container">
          <div className="card-header border-bottom-0 pt-3">
            <span className="edit-page-title">Incubatee Data Export</span>
          </div>
          <div className="card-body">
            <IncubationFilters
              values={values}
              touched={touched}
              errors={errors}
              setAndChooseValue={setAndChooseValue}
              setFieldTouched={setFieldTouched}
              productTypesOptions={props.productTypesOptions}
            />

            <IndividualFilters
              values={values}
              touched={touched}
              errors={errors}
              setAndChooseValue={setAndChooseValue}
              individualOnly={INDIVIDUAL}
            />
            <OrganizationFilters
              values={values}
              touched={touched}
              errors={errors}
              setAndChooseValue={setAndChooseValue}
              organizationOnly={ORGANIZATION}
            />
            <PromoterFilters
              values={values}
              touched={touched}
              errors={errors}
              setAndChooseValue={setAndChooseValue}
              organizationOnly={ORGANIZATION}
            />
            <ExportSelectOptions
              values={values}
              touched={touched}
              errors={errors}
              setFieldValue={setFieldValue}
              individualOnly={INDIVIDUAL}
              organizationOnly={ORGANIZATION}
              isSelected={isSelected(values.selectOptions)}
              errorMsg={errorMsg}
            />

            <div className="row mt-2">
              <div className="col-md-12 text-center">
                <button
                  className="btn btn-sm btn-info"
                  type="button"
                  onClick={
                    isSelected(values.selectOptions)
                      ? handleSubmit
                      : () => setErrorMsg(true)
                  }
                >
                  Download
                </button>
                &nbsp;
                <button
                  className="btn btn-sm btn-outline-info"
                  type="reset"
                  onClick={handleReset}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncubateeDataExportForm;
