import React, { Component } from "react";
import { connect } from "react-redux";
import Redirect from "react-router-dom/Redirect";

class Logout extends Component {
  render() {
    if (this.props.userLogout || !this.props.userLoggedIn) {
      return <Redirect to="/login" />;
    }
    return <div />;
  }
}

const mapStateToProps = state => ({
  userLoggedIn: state.LoginReducer.userLoggedIn,
  userLogout: state.LoginReducer.userLoggedOut
});

export default connect(
  mapStateToProps,
  null
)(Logout);
