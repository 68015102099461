import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Download from "downloadjs";
import { INCUBATEES_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import {
  INCUBATEE_TYPES_OBJECT,
  setInputData,
  GRADUATION_STATUS_OBJECT
} from "../../common/LookupConstants";
import OptionsForm from "./OptionsForm";
import {
  INCUBATION_SECTORS,
  INCUBATEE_STAGES,
  SUB_SECTORS,
  GRADUATION_STATUS,
  RESIDENTIAL_STATUS,
  RESIDENTIAL_STATUS_OBJECT
} from "../../common/IncubateeProfileConstants";
import Can from "../../../auth/can";

class StartupProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      selectedIncubatees: [],

      selectOptions: {
        individual: true,
        incubatee: {
          address: true,
          website: true,
          email: true,
          phone: true,
          desc: true
        },
        promoter: {
          name: false,
          founder: false,
          email: false,
          phone: false,
          designation: false
        }
      },

      nextPage: false,
      prevPage: true
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  handleSearchResults = searchResults => {
    this.setState({ searchResults });
  };

  handleSelectedIncubatees = selectedIncubatees => {
    this.setState({ selectedIncubatees });
  };

  handleSelectOptions = selectOptions => {
    this.setState({ selectOptions });
  };

  handleNextPage = () => {
    this.setState({ nextPage: true, prevPage: false });
  };

  handlePrevPage = () => {
    this.setState({
      nextPage: false,
      prevPage: true,
      selectOptions: {
        individual: true,
        incubatee: {
          address: true,
          website: true,
          email: true,
          phone: true,
          desc: true
        },
        promoter: {
          name: false,
          founder: false,
          email: false,
          phone: false,
          designation: false
        }
      }
    });
  };

  handleGeneratePdf = () => {
    const selectedIncubatees = this.state.selectedIncubatees;
    const incubateeIds = selectedIncubatees.map(inc => inc._id);

    const pdfRequest = {
      options: this.state.selectOptions,
      selectedIncubatees: incubateeIds
    };

    const url = `${INCUBATEES_API}/pdf`;

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(pdfRequest)
    })
      .then(response => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error(response.statusText);
        }
      })
      .then(blob => {
        Download(blob, "Startup Profile.pdf");
      })
      .catch(error => {
        this.handleApiFailed(error.toString());
      });
  };

  render() {
    const searchProps = {
      // events
      handleApiFailed: this.handleApiFailed,
      handleSearchResults: this.handleSearchResults
    };

    const incubateesProps = {
      searchResults: this.state.searchResults,
      // events
      handleApiFailed: this.handleApiFailed,
      handleSelectedIncubatees: this.handleSelectedIncubatees
    };

    const selectedIncubateesProps = {
      selectedIncubatees: this.state.selectedIncubatees,
      // events
      handleApiFailed: this.handleApiFailed,
      handleSelectedIncubatees: this.handleSelectedIncubatees
    };

    const selectOptionsProps = {
      selectOptions: this.state.selectOptions,
      // events
      handleSelectOptions: this.handleSelectOptions
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert
    };

    const { nextPage, prevPage } = this.state;

    let renderComponent = "";
    if (nextPage) {
      renderComponent = <OptionsForm {...selectOptionsProps} />; // here hiding <ProfileSearch /> and <Incubatees /> components
    } else if (prevPage) {
      renderComponent = (
        <Fragment>
          <ProfileSearch {...searchProps} />
          <Incubatees {...incubateesProps} />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>
        {renderComponent}
        <SelectedIncubatees {...selectedIncubateesProps} />
        <div className="row mt-2 mb-5">
          <div className="col-md-12 text-center">
            {nextPage ? (
              <Fragment>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.handlePrevPage}
                >
                  Back
                </button>
                &nbsp;
                {this.state.selectedIncubatees.length > 0 ? (
                  <Can do="VIEW" on="Incubatee">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.handleGeneratePdf}
                    >
                      Generate PDF
                    </button>
                  </Can>
                ) : (
                  <Can do="VIEW" on="Incubatee">
                    <button
                      className="btn btn-sm btn-primary"
                      data-toggle="tooltip"
                      title="No Incubatee's are selected."
                      disabled
                    >
                      Generate PDF
                    </button>
                  </Can>
                )}
              </Fragment>
            ) : null}

            {prevPage ? (
              <Fragment>
                {this.state.selectedIncubatees.length > 0 ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={this.handleNextPage}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-primary"
                    data-toggle="tooltip"
                    title="No Incubatee's are selected."
                    disabled
                  >
                    Next
                  </button>
                )}
              </Fragment>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default StartupProfile;
/*++++++++++++++++++++ StartupProfile End ++++++++++++++++++++++ */

/*++++++++++++++++++++ ProfileSearch Search ++++++++++++++++++++++ */
export class ProfileSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      stage: null,
      sector: null,
      subTechSector: null,
      graduationStatus: "ONGOING",
      residentialStatus: null
    };
  }

  handleInputChange = e => {
    const targetName = e.target.name;
    const targetValue = e.target.value ? e.target.value : null;
    this.setState({ [targetName]: targetValue });
  };

  addData = (object, filedName, value) => {
    if (value) {
      object[filedName] = value;
    }
  };

  validate = () => {
    const ps = this.state;
    const searchRequest = {};
    this.addData(searchRequest, "name", ps.name);
    this.addData(searchRequest, "stage", ps.stage);
    this.addData(searchRequest, "sector", ps.sector);
    this.addData(searchRequest, "subTechSector", ps.subTechSector);
    this.addData(searchRequest, "graduationStatus", ps.graduationStatus);
    this.addData(searchRequest, "residentialStatus", ps.residentialStatus);
    return searchRequest;
  };

  handleSearch = () => {
    const searchRequest = this.validate();

    const url = `${INCUBATEES_API}/profile-search`;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      searchRequest,
      data => this.props.handleSearchResults(data),
      (data, status) => this.props.handleApiFailed(data.message),
      error => this.props.handleApiFailed(error.toString())
    );
  };

  handleClear = () => {
    this.setState({
      name: null,
      stage: null,
      sector: null,
      subTechSector: null,
      graduationStatus: null,
      residentialStatus: null
    });
  };

  render() {
    const {
      name,
      stage,
      sector,
      subTechSector,
      graduationStatus,
      residentialStatus
    } = this.state;
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="edit-page-section-header-text">
                <h5 className="h6 card-title mb-0">Search Incubatee</h5>
              </div>

              <div className="card-body">
                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Incubatee Name
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Incubatee Name"
                      name="name"
                      value={setInputData(name)}
                      onChange={this.handleInputChange}
                    />
                    <div className="invalid-feedback">
                      Please enter incubatee name *.
                    </div>
                  </div>
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Incubation Sector
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <select
                      name="sector"
                      className="custom-select custom-select-sm"
                      value={setInputData(sector)}
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select...</option>
                      {INCUBATION_SECTORS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Incubatee Stage
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <select
                      name="stage"
                      value={setInputData(stage)}
                      className="custom-select custom-select-sm"
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select...</option>
                      {INCUBATEE_STAGES.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Sub Sector
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <select
                      name="subTechSector"
                      value={setInputData(subTechSector)}
                      className="custom-select custom-select-sm"
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select...</option>
                      {SUB_SECTORS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Graduation Status
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <select
                      name="graduationStatus"
                      value={setInputData(graduationStatus)}
                      className="custom-select custom-select-sm"
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select...</option>
                      {GRADUATION_STATUS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label className="col-form-label col-12 col-lg-2 col-xl-2">
                    Residential Status
                  </label>
                  <div className="col-12 col-lg-4 col-xl-4">
                    <select
                      name="residentialStatus"
                      value={setInputData(residentialStatus)}
                      className="custom-select custom-select-sm"
                      onChange={this.handleInputChange}
                    >
                      <option value="">Select...</option>
                      {RESIDENTIAL_STATUS.map((opt, i) => (
                        <option key={`${i}`} value={opt.key}>
                          {opt.value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn-sm btn-info"
                      onClick={this.handleSearch}
                    >
                      Search
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-sm btn-outline-info"
                      onClick={this.handleClear}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
/*++++++++++++++++++++ ProfileSearch End ++++++++++++++++++++++ */

/*++++++++++++++++++++ Incubatees Start ++++++++++++++++++++++ */
export class Incubatees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incubatees: [],
      selectAllIncubatees: false
    };
  }

  handleSelectAll = e => {
    const targetName = e.target.name;
    const checked = e.target.checked;
    const incubatees = this.state.incubatees;
    incubatees.forEach(inc => (inc.selected = checked));
    this.setState({ [targetName]: checked, incubatees });
  };

  handleClearAll = () => {
    this.setState({ incubatees: [], selectAllIncubatees: false });
  };

  handleCheckbox = (index, e) => {
    const targetName = e.target.name;
    let targetValue = e.target.checked;
    const incubatees = this.state.incubatees;
    const incubatee = incubatees[index];
    incubatee[targetName] = targetValue;
    const selectAllIncubatees = incubatees.every(inc => inc.selected);
    this.setState({ incubatees, selectAllIncubatees });
  };

  handleAddSelected = () => {
    const { incubatees } = this.state;
    if (incubatees.length === 0) {
      return;
    }

    const selectedIncubatees = [];
    incubatees.forEach((inc, index) => {
      if (inc.selected) {
        delete inc.selected;
        selectedIncubatees.push(inc);
      }
    });
    this.setState({ selectAllIncubatees: false });
    this.props.handleSelectedIncubatees(selectedIncubatees);
  };

  getAllIncubatees = () => {
    const url = `${INCUBATEES_API}/profile-search`;
    const headers = { "Content-Type": "application/json" };
    HttpUtil.post(
      url,
      headers,
      {},
      data => this.setState({ incubatees: data }),
      (data, status) => this.props.handleApiFailed(data.message),
      error => this.props.handleApiFailed(error.toString())
    );
  };

  componentDidMount = () => {
    this.getAllIncubatees();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.searchResults !== this.props.searchResults) {
      let incubatees = this.props.searchResults;
      this.setState({ incubatees });
    }
  };

  render() {
    const { incubatees, selectAllIncubatees } = this.state;

    const trows = incubatees.map((inc, index) => {
      return (
        <tr key={index}>
          <th scope="row" className="text-center">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="selected"
                id={inc._id}
                checked={inc.selected ? inc.selected : ""}
                onChange={this.handleCheckbox.bind(this, index)}
              />
              <label className="custom-control-label" htmlFor={inc._id} />
            </div>
          </th>
          <td>
            <Link to={`/admin/incubatee/profile/${inc._id}`}>{inc.name}</Link>
          </td>
          <td>{INCUBATEE_TYPES_OBJECT[inc.incubateeType]}</td>
          <td>{GRADUATION_STATUS_OBJECT[inc.graduationStatus]}</td>
          <td>{RESIDENTIAL_STATUS_OBJECT[inc.residentialStatus]}</td>
        </tr>
      );
    });
    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="card">
              <div className="edit-page-section-header-text">
                <h5 className="h6 card-title mb-0">Search Results</h5>
              </div>
              <div className="card-body">
                {trows.length !== 0 ? (
                  <Fragment>
                    <div className="table-responsive react-bootstrap-table">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="text-center"
                              style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                            >
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={"##007"}
                                  checked={selectAllIncubatees}
                                  name="selectAllIncubatees"
                                  onChange={this.handleSelectAll}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={"##007"}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                            >
                              Incubatee Name
                            </th>
                            <th
                              scope="col"
                              style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                            >
                              Incubatee Type
                            </th>
                            <th
                              scope="col"
                              style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                            >
                              Graduation Status
                            </th>
                            <th
                              scope="col"
                              style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                            >
                              Residential Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>{trows}</tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <button
                          className="btn btn-sm btn-info"
                          onClick={this.handleAddSelected}
                        >
                          Add Selected
                        </button>
                        &nbsp;
                        <button
                          className="btn btn-sm btn-outline-info"
                          onClick={this.handleClearAll}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  "No Data Found"
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
/*++++++++++++++++++++ Incubatees End ++++++++++++++++++++++ */

/*++++++++++++++++++++ SelectedIncubatees Start ++++++++++++++++++++++ */
export class SelectedIncubatees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIncubatees: [],
      selectAllIncubatees: false
    };
  }

  handleSelectAll = e => {
    const targetName = e.target.name;
    const checked = e.target.checked;
    const selectedIncubatees = this.state.selectedIncubatees;
    selectedIncubatees.forEach(inc => (inc.selected = checked));
    this.setState({ [targetName]: checked, selectedIncubatees });
  };

  handleClearAll = () => {
    const selectedIncubatees = [];
    this.props.handleSelectedIncubatees(selectedIncubatees);
    this.setState({ selectedIncubatees, selectAllIncubatees: false });
  };

  handleCheckbox = (index, e) => {
    const targetName = e.target.name;
    let targetValue = e.target.checked;
    const selectedIncubatees = this.state.selectedIncubatees;
    const incubatee = selectedIncubatees[index];
    incubatee[targetName] = targetValue;
    const selectAllIncubatees = selectedIncubatees.every(inc => inc.selected);
    this.setState({ selectedIncubatees, selectAllIncubatees });
  };

  handleRemoveSelected = () => {
    let selectedIncubatees = this.state.selectedIncubatees;
    selectedIncubatees = selectedIncubatees.filter(sInc => !sInc.selected);
    this.props.handleSelectedIncubatees(selectedIncubatees);
    this.setState({ selectedIncubatees, selectAllIncubatees: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.selectedIncubatees !== this.props.selectedIncubatees) {
      let selectedIncubatees = [...this.state.selectedIncubatees];
      const propsIncuts = this.props.selectedIncubatees;

      let filteredData = [];
      const existingIncubatees = new Set();
      selectedIncubatees.forEach(sInc => existingIncubatees.add(sInc._id));

      filteredData = propsIncuts.filter(
        sInc => !existingIncubatees.has(sInc._id)
      );

      selectedIncubatees = selectedIncubatees.concat(filteredData);

      this.setState({ selectedIncubatees });
    }
  };

  render() {
    const { selectedIncubatees, selectAllIncubatees } = this.state;
    const trows = selectedIncubatees.map((inc, index) => {
      return (
        <tr key={index}>
          <th scope="row" className="text-center">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                name="selected"
                id={`###${inc._id}`}
                checked={inc.selected ? inc.selected : ""}
                onChange={this.handleCheckbox.bind(this, index)}
              />
              <label
                className="custom-control-label"
                htmlFor={`###${inc._id}`}
              />
            </div>
          </th>
          <td>
            <Link to={`/admin/incubatee/profile/${inc._id}`}>{inc.name}</Link>
          </td>
          <td>{INCUBATEE_TYPES_OBJECT[inc.incubateeType]}</td>
          <td>{GRADUATION_STATUS_OBJECT[inc.graduationStatus]}</td>
          <td>{RESIDENTIAL_STATUS_OBJECT[inc.residentialStatus]}</td>
        </tr>
      );
    });
    return (
      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="edit-page-section-header-text">
              <h5 className="h6 card-title mb-0">Selected Incubatees</h5>
            </div>

            <div className="card-body">
              {trows.length > 0 ? (
                <Fragment>
                  <div className="table-responsive react-bootstrap-table">
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="text-center"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                          >
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={"##008"}
                                checked={selectAllIncubatees}
                                name="selectAllIncubatees"
                                onChange={this.handleSelectAll}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={"##008"}
                              />
                            </div>
                          </th>
                          <th
                            scope="col"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                          >
                            Incubatee Name
                          </th>
                          <th
                            scope="col"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                          >
                            Incubatee Type
                          </th>
                          <th
                            scope="col"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                          >
                            Graduation Status
                          </th>
                          <th
                            scope="col"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                          >
                            Residential Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>{trows}</tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button
                        className="btn btn-sm btn-info"
                        onClick={this.handleRemoveSelected}
                      >
                        Remove Selected
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-sm btn-outline-info"
                        onClick={this.handleClearAll}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </Fragment>
              ) : (
                "No Incubatee's are selected."
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*++++++++++++++++++++ SelectedIncubatees End ++++++++++++++++++++++ */
