const AddressInfoSchema = {
  title: "Addresses",
  type: "array",
  minItems: 1,
  items: {
    title: "Address",
    type: "object",
    required: ["type", "streetLine1", "city", "state", "postalCode"],
    properties: {
      type: {
        type: "string",
        title: "Type",
        anyOf: [
          {
            type: "string",
            title: "Permanent Address",
            enum: ["PERMANENT"]
          },
          {
            type: "string",
            title: "Communication Address",
            enum: ["COMMUNICATION"]
          },
          {
            type: "string",
            title: "Office Address",
            enum: ["OFFICE"]
          }
        ]
      },
      streetLine1: {
        type: "string",
        title: "Street Line 1"
      },
      streetLine2: {
        type: "string",
        title: "Street Line 2"
      },
      landmark: {
        type: "string",
        title: "Landmark"
      },
      city: {
        type: "string",
        title: "City"
      },
      state: {
        type: "string",
        title: "State"
      },
      postalCode: {
        type: "number",
        title: "Postal Code"
      },
      addressProof: {
        type: "string",
        format: "data-url",
        title: "Upload Address Proof"
      }
    }
  }
};

const AddressInfoUiSchema = {};

export { AddressInfoSchema, AddressInfoUiSchema };
