import React, { Component } from "react";
import { Link } from "react-router-dom";
import BootsrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

class CampusBasedBuildingList extends Component {
  actionsFormater = (cell, row) => {
    const url = `/admin/buildings/edit/${row._id}`;
    let viewButton = (
      <Link role="button" className="" to={url}>
        {row.buildingName}
      </Link>
    );
    return viewButton;
  };

  render() {
    const columnsBuilding = [
      {
        dataField: "buildingName",
        text: "Building Name",
        headerClasses: "display-table-header",
        sort: true,
        formatter: this.actionsFormater
      },
      {
        dataField: "totalFloors",
        text: "Total Floors",
        sort: true,
        headerClasses: "display-table-header"
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        headerClasses: "display-table-header"
      }
    ];
    return (
      <div className="row mb-5 mt-5">
        <div className="col-12">
          <div className="card edit-page-container">
            <h4 className="card-header edit-page-title border-bottom-0 mt-3 text-center">
              Building Information
            </h4>
            <div className="card-body">
              <BootsrapTable
                bootstrap4
                keyField={"_id"}
                data={this.props.buildings}
                columns={columnsBuilding}
                condensed
                bordered={false}
                //for custom css
                noDataIndication="No Building Available for this Campus."
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CampusBasedBuildingList;
