import React, { Fragment } from "react";
import AdvAdjustmentPreviewForm from "./AdvAdjustmentPreviewForm";

function AdvAdjustmentConfirmForm(props) {
  const { initialValues, handleEditForm, handleSubmit } = props;
  return (
    <Fragment>
      <AdvAdjustmentPreviewForm
        data={initialValues}
        title="Advance Adjustment  Confirmation"
      />
      <div className="form-group row mt-3 mb-0">
        <div className="col-12 text-center">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleEditForm}
            type="button"
          >
            Edit
          </button>
          &nbsp;
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default AdvAdjustmentConfirmForm;
