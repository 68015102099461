import React, { Fragment } from "react";
import { Field, ErrorMessage } from "formik";
import { downloadDocument } from "../../common/Util";
import { getDateAndTimeFormat } from "../../lease/DateUtil";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const AdditionalInformation = props => {
  const { values, touched, errors, setFieldValue, readOnly } = props;
  const additionalInformation = values.additionalInformation || {};

  const touch = touched.additionalInformation || {};
  const error = errors.additionalInformation || {};

  const disabledField = readOnly
    ? { readOnly: "readOnly", disabled: "disabled" }
    : {};

  const pdInProof = additionalInformation.pendingInternalApprovalProof || "";
  const inProof = additionalInformation.internalApprovalProof || "";

  const fileName = pdInProof.name || inProof.filename || "Choose File";

  const remarks = additionalInformation.remarks || [];

  return (
    <Fragment>
      <div className="row mt-5">
        <div className="col-12 mb-3 edit-page-section-header-text">
          Additional Information
        </div>
      </div>

      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Usage Type
        </label>

        <div className="col-12 col-lg-5 col-xl-5">
          <div
            className={`custom-radios ${
              touch.usageType && error.usageType ? "is-invalid" : ""
            }`}
          >
            <div
              className="custom-control custom-radio d-inline-block"
              style={{ width: "33.33%" }}
            >
              <Field
                type="radio"
                id="RENTAL"
                name="additionalInformation.usageType"
                value="RENTAL"
                checked={additionalInformation.usageType === "RENTAL"}
                className={`custom-control-input ${
                  touch.usageType && error.usageType ? "is-invalid" : ""
                }`}
                onChange={e => {
                  setFieldValue(
                    "additionalInformation.usageType",
                    e.target.value
                  );
                }}
                {...disabledField}
              />
              <label className="custom-control-label" htmlFor="RENTAL">
                Rental
              </label>
            </div>
            <div
              className="custom-control custom-radio d-inline-block"
              style={{ width: "33.33%" }}
            >
              <Field
                type="radio"
                id="LAB_TEST"
                name="additionalInformation.usageType"
                value="LAB_TEST"
                checked={additionalInformation.usageType === "LAB_TEST"}
                className={`custom-control-input ${
                  touch.usageType && error.usageType ? "is-invalid" : ""
                }`}
                onChange={e => {
                  setFieldValue(
                    "additionalInformation.usageType",
                    e.target.value
                  );
                }}
                {...disabledField}
              />
              <label className="custom-control-label" htmlFor="LAB_TEST">
                Lab Test
              </label>
            </div>

            <div
              className="custom-control custom-radio d-inline-block"
              style={{ width: "33.33%" }}
            >
              <Field
                type="radio"
                id="CONSULTANCY"
                name="additionalInformation.usageType"
                value="CONSULTANCY"
                checked={additionalInformation.usageType === "CONSULTANCY"}
                className={`custom-control-input ${
                  touch.usageType && error.usageType ? "is-invalid" : ""
                }`}
                onChange={e => {
                  setFieldValue(
                    "additionalInformation.usageType",
                    e.target.value
                  );
                }}
                {...disabledField}
              />
              <label className="custom-control-label" htmlFor="CONSULTANCY">
                Consultancy
              </label>
            </div>
          </div>
          <ErrorMessage
            component="div"
            name="additionalInformation.usageType"
            className="invalid-feedback"
          />
        </div>
      </div>

      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Waiver
        </label>

        <div className="col-12 col-lg-5 col-xl-5">
          <div
            className={`custom-radios ${
              touch.waiver && error.waiver ? "is-invalid" : ""
            }`}
          >
            <div
              className="custom-control custom-radio d-inline-block"
              style={{ width: "33.33%" }}
            >
              <Field
                type="radio"
                id="WAIVER_NO"
                name="additionalInformation.waiver"
                value="NO"
                checked={additionalInformation.waiver === false}
                className={`custom-control-input ${
                  touch.waiver && error.waiver ? "is-invalid" : ""
                }`}
                onChange={e => {
                  setFieldValue("additionalInformation.waiver", false);
                }}
                {...disabledField}
              />
              <label className="custom-control-label" htmlFor="WAIVER_NO">
                No
              </label>
            </div>
            <div
              className="custom-control custom-radio d-inline-block"
              style={{ width: "33.33%" }}
            >
              <Field
                type="radio"
                id="WAIVER_YES"
                name="additionalInformation.waiver"
                value="YES"
                checked={additionalInformation.waiver === true}
                className={`custom-control-input ${
                  touch.waiver && error.waiver ? "is-invalid" : ""
                }`}
                onChange={e => {
                  setFieldValue("additionalInformation.waiver", true);
                }}
                {...disabledField}
              />
              <label className="custom-control-label" htmlFor="WAIVER_YES">
                Yes
              </label>
            </div>
          </div>
          <ErrorMessage
            component="div"
            name="additionalInformation.waiver"
            className="invalid-feedback"
          />
        </div>
      </div>

      {additionalInformation.waiver ? (
        <div className="form-group row mb-2">
          <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
            Charges Waiver Reason
          </label>

          <div className="col-12 col-lg-5 col-xl-5">
            <Field
              type="text"
              name="additionalInformation.chargesWaiverReason"
              value={additionalInformation.chargesWaiverReason || ""}
              className={getErrorCssClassName(
                touch,
                error,
                "chargesWaiverReason"
              )}
              {...disabledField}
            />
            <ErrorMessage
              component="div"
              name="additionalInformation.chargesWaiverReason"
              className="invalid-feedback"
            />
          </div>
        </div>
      ) : null}

      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
          Internal Approval Required
        </label>

        <div className="col-12 col-lg-5 col-xl-5">
          <div
            className={`custom-radios ${
              touch.internalApprovalRequired && error.internalApprovalRequired
                ? "is-invalid"
                : ""
            }`}
          >
            <div
              className="custom-control custom-radio d-inline-block"
              style={{ width: "33.33%" }}
            >
              <Field
                type="radio"
                id="INTERNAL_APPROVAL_NO"
                name="additionalInformation.internalApprovalRequired"
                value="NO"
                checked={
                  additionalInformation.internalApprovalRequired === false
                }
                className={`custom-control-input ${
                  touch.internalApprovalRequired &&
                  error.internalApprovalRequired
                    ? "is-invalid"
                    : ""
                }`}
                onChange={e => {
                  setFieldValue(
                    "additionalInformation.internalApprovalRequired",
                    false
                  );
                }}
                {...disabledField}
              />
              <label
                className="custom-control-label"
                htmlFor="INTERNAL_APPROVAL_NO"
              >
                No
              </label>
            </div>
            <div
              className="custom-control custom-radio d-inline-block"
              style={{ width: "33.33%" }}
            >
              <Field
                type="radio"
                id="INTERNAL_APPROVAL_YES"
                name="additionalInformation.internalApprovalRequired"
                value="YES"
                checked={
                  additionalInformation.internalApprovalRequired === true
                }
                className={`custom-control-input ${
                  touch.internalApprovalRequired &&
                  error.internalApprovalRequired
                    ? "is-invalid"
                    : ""
                }`}
                onChange={e => {
                  setFieldValue(
                    "additionalInformation.internalApprovalRequired",
                    true
                  );
                }}
                {...disabledField}
              />
              <label
                className="custom-control-label"
                htmlFor="INTERNAL_APPROVAL_YES"
              >
                Yes
              </label>
            </div>
          </div>
          <ErrorMessage
            component="div"
            name="additionalInformation.internalApprovalRequired"
            className="invalid-feedback"
          />
        </div>
      </div>

      {additionalInformation.internalApprovalRequired ? (
        <div className="form-group row mb-2">
          <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
            Approval Proof <small>2 MB(MAX)</small>
          </label>

          <div className="col-12 col-lg-5 col-xl-5">
            <div
              className={`d-flex align-items-center justify-content-between custom-file ${
                touch.pendingInternalApprovalProof &&
                error.pendingInternalApprovalProof
                  ? "is-invalid"
                  : ""
              }`}
            >
              <label
                className="col-form-label col-form-label-sm"
                style={{ width: "70%" }}
              >
                {fileName}
              </label>
              <div className="d-flex align-items-center cursor-pointer">
                {additionalInformation.link ? (
                  <div
                    className="mr-2 text-primary"
                    data-toggle="tooltip"
                    title="Download"
                  >
                    <i
                      className="fas fa-download"
                      onClick={() =>
                        downloadDocument(
                          additionalInformation.link,
                          msg => window.alert(msg),
                          additionalInformation.internalApprovalProof.filename
                        )
                      }
                    ></i>
                  </div>
                ) : null}

                <div className="upload-btn-wrapper cursor-pointer">
                  <button
                    className={`btn btn-sm btn-outline-info custom-btn ${
                      touch.pendingInternalApprovalProof &&
                      error.pendingInternalApprovalProof
                        ? "btn-outline-danger"
                        : ""
                    }`}
                    {...disabledField}
                  >
                    <i className="fas fa-file-upload" />
                    &nbsp;Upload
                    <Field
                      type="file"
                      name="pendingInternalApprovalProof"
                      onChange={e => {
                        let file = e.target.files[0] || "";
                        if (file) {
                          setFieldValue(
                            "additionalInformation.pendingInternalApprovalProof",
                            file
                          );
                        }
                      }}
                      {...disabledField}
                    />
                  </button>
                </div>
              </div>
            </div>

            <ErrorMessage
              component="div"
              name="additionalInformation.pendingInternalApprovalProof"
              className="invalid-feedback"
            />
          </div>
        </div>
      ) : null}

      <div className="form-group row mb-2">
        <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
          Remarks
        </label>
        <div className="col-12 col-lg-5 col-xl-5">
          <Field
            component="textarea"
            name="additionalInformation.remark"
            value={additionalInformation.remark || ""}
            className={getErrorCssClassName(touch, error, "remark")}
            rows="3"
            {...disabledField}
          />

          <ErrorMessage
            component="div"
            name="additionalInformation.remark"
            className="invalid-feedback"
          />
        </div>
      </div>

      {remarks.length > 0 ? (
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className="card-title edit-page-label border-bottom pb-1">
              Remarks
            </div>
            <ul className="list-group">
              {remarks.map((remark, ntIdx) => {
                let remarkHistory = "";

                if (remark.user) {
                  remarkHistory = (
                    <li
                      key={ntIdx}
                      className="list-group-item border-0 p-0 mb-3"
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <strong className="text-muted">
                          {remark.user.name}
                        </strong>
                        <small>{getDateAndTimeFormat(remark.date)}</small>
                      </div>
                      <p className="text-muted mb-1">{remark.note}</p>
                    </li>
                  );
                }

                return remarkHistory;
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default AdditionalInformation;
