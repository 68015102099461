import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AlertComponent from "../../common/AlertComponent";
import IncubatorEnquiryForm from "./IncubatorEnquiryForm";
import {
  INCUBATOR_ENQUIRIES_API,
  SEARCH_INDVL_ORG_API
} from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import * as Yup from "yup";
import { convertNumberToDate } from "../../lease/DateUtil";
import ErrorPage from "../../common/error.page";

const DEF_BACK_PATH = "/service-mgmt/open-enquiries";

const ENQUIRY_SCHEMA = Yup.object().shape({
  incubatorNote: Yup.string()
    .label("Incubator Note")
    .required(),
  responseMessage: Yup.string()
    .label("Response Message")
    .required()
});

export const INCUBATEE_USER = "INCUBATEE_USER";

function requestedEquipmentsToReservationFormat(enquiry) {
  const from = convertNumberToDate(enquiry.from);
  const to = convertNumberToDate(enquiry.to);

  return enquiry.equipments.map(e => {
    return {
      asset: {
        value: e.value,
        label: e.label,
        assetId: e.assetId
      },
      from,
      to
    };
  });
}

class IncubatorEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiry: {
        _id: null,
        incubator: null,
        enquiryType: "",
        incubatee: "",
        priority: "",
        from: "",
        to: "",
        institutionName: "",
        nameOfIncubatee: "",
        otherIncubator: "",
        incubatorName: "",
        incubatorLocation: "",
        requestorCategory: "",
        equipments: null,
        labTest: "",
        organizationName: "",
        contactPersonName: "",
        contactPersonDesignation: "",
        userNotes: "",
        incubatorNotes: [],
        incubatorNote: "",
        responseMessage: "",
        stage: "",
        status: "",
        action: "",
        actions: []
      },
      readOnly: true,
      previousPath: DEF_BACK_PATH,
      permanentFailure: false,
      showAlert: false,
      alertType: "",
      alertColor: "",
      alertMessage: "",

      redirect: ""
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      requestSubmittedMsg: "",
      requestSubmitted: false,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getReservationModel = () => {
    const assetReservation = {
      requestorType: "",
      userType: "",
      requestorCategory: "",
      newUser: "", // b
      indRequestor: "",
      orgRequestor: "",
      individual: "",
      organization: "",
      incubatee: "",
      requestedAssets: [],
      usageType: "",
      waiver: "",
      chargesWaiverReason: "",
      internalApprovalRequired: "",
      internalApprovalProof: "",
      comments: "",
      stage: "",
      status: ""
    };
    return assetReservation;
  };

  handleStartReservation = () => {
    this.setState({ redirect: true });
  };

  handleIncubateeEnquiryRequest = enquiryRequest => {
    const incubatee = enquiryRequest.nameOfIncubatee;

    const newAssetReservationFlow = {
      type: "ENQUIRY",
      category: "INCUBATEE",
      path: this.props.location.pathname,
      data: {
        incubatee: incubatee,
        assetRequested: requestedEquipmentsToReservationFormat(enquiryRequest),
        incubatorEnquiry: enquiryRequest._id
      }
    };

    sessionStorage.setItem(
      "newAssetReservationFlow",
      JSON.stringify(newAssetReservationFlow)
    );

    this.setState({
      redirectPage: "/service-mgmt/asset-reservation-requests/new"
    });
  };

  setMatchedPhones = (matchedPhones, phones) => {
    let allPhones = [];
    const mPhones = {};
    matchedPhones.forEach(ph => {
      mPhones[ph.phone] = true;
    });

    phones.forEach(ph => {
      const matched = mPhones[ph.phone] || false;
      const phone = { ...ph };
      phone.matched = matched;
      allPhones.push(phone);
    });
    return allPhones;
  };

  setMatchedEmails = (matchedEmails, emails) => {
    let allEmails = [];
    const mEmails = {};
    matchedEmails.forEach(em => {
      mEmails[em.email] = true;
    });

    emails.forEach(em => {
      const matched = mEmails[em.email] || false;
      const email = { ...em };
      email.matched = matched;
      allEmails.push(email);
    });

    return allEmails;
  };

  getIndividual = data => {
    const individuals = data.individuals || [];
    const indvls = individuals.map(indvl => {
      let names = [];

      if (indvl.firstName) {
        names.push(indvl.firstName);
      }
      if (indvl.lastName) {
        names.push(indvl.lastName);
      }
      const matchedEmails = indvl.matchedEmails || [];
      const emails = this.setMatchedEmails(matchedEmails, indvl.emails);

      const matchedphones = indvl.matchedphones || [];
      const phones = this.setMatchedPhones(matchedphones, indvl.phones);

      const individual = {
        _id: indvl._id,
        name: names.join(" "),
        emails,
        phones,
        requestorType: "Individual"
      };
      return individual;
    });

    return indvls;
  };

  getOrganization = data => {
    const organizations = data.organizations || [];

    const orgs = organizations.map(org => {
      const matchedEmails = org.matchedEmails || [];
      const emails = this.setMatchedEmails(matchedEmails, org.emails);

      const matchedphones = org.matchedphones || [];
      const phones = this.setMatchedPhones(matchedphones, org.phones);

      const organization = {
        _id: org._id,
        name: org.name,
        emails,
        phones,
        requestorType: "Organization"
      };
      return organization;
    });
    return orgs;
  };

  searchUser = data => {
    const url = `${SEARCH_INDVL_ORG_API}`;
    const headers = { "Content-Type": "application/json" };

    const request = {
      email: data.email,
      mobile: data.mobile
    };

    HttpUtil.post(
      url,
      headers,
      request,
      result => {
        const indvls = this.getIndividual(result);
        const orgs = this.getOrganization(result);

        const matchedRecords = [...indvls, ...orgs];

        if (matchedRecords.length > 0) {
          const newAssetReservationFlow = {
            type: "ENQUIRY",
            category: "MATCH",
            path: this.props.location.pathname,
            data: {
              matchedRecords,
              requestorCategory: data.requestorCategory,
              assetRequested: requestedEquipmentsToReservationFormat(data),
              incubatorEnquiry: data._id
            }
          };

          sessionStorage.setItem(
            "newAssetReservationFlow",
            JSON.stringify(newAssetReservationFlow)
          );

          this.setState({
            redirectPage: "/service-mgmt/matched-users"
          });
        } else {
          let requestorType = data.incubateeType;
          if (
            data.incubateeType === "COMPANY" ||
            data.incubateeType === "ORGANIZATION"
          ) {
            requestorType = "ORGANIZATION";
          }
          const newUser = {
            requestorType,
            firstName: data.name,
            lastName: data.lastName,
            organizationName: data.organizationName,
            contactNameOrDepartment: data.contactPersonName,
            designation: data.contactPersonDesignation,
            email: data.email,
            mobile: data.mobile
          };

          const newAssetReservationFlow = {
            type: "ENQUIRY",
            category: "NOMATCH",
            path: this.props.location.pathname,
            data: {
              ...newUser,
              requestorCategory: data.requestorCategory,
              assetRequested: requestedEquipmentsToReservationFormat(data),
              incubatorEnquiry: data._id
            }
          };

          sessionStorage.setItem(
            "newAssetReservationFlow",
            JSON.stringify(newAssetReservationFlow)
          );

          this.setState({
            redirectPage: "/service-mgmt/asset-reservation-requests/new"
          });
        }
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  handleReservation = enquiryRequest => {
    const enquiryConfirmed =
      enquiryRequest.stage === "RESERVE" && enquiryRequest.status === "PENDING";

    if (!enquiryConfirmed) {
      return;
    }

    if (enquiryRequest.incubatee) {
      this.handleIncubateeEnquiryRequest(enquiryRequest);
    } else {
      this.searchUser(enquiryRequest);
    }
  };

  getEnquiryById = id => {
    const url = `${INCUBATOR_ENQUIRIES_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        const enquiry = { ...data };
        const from = convertNumberToDate(data.from);
        const to = convertNumberToDate(data.to);
        enquiry.from = from;
        enquiry.to = to;

        this.setState({ enquiry, readOnly: true });

        this.handleReservation(data);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getRequest = values => {
    const incubatorNotes = values.incubatorNotes || [];
    if (values.incubatorNote) {
      incubatorNotes.push({ note: values.incubatorNote });
    }

    const request = {
      priority: values.priority,
      userNotes: values.userNotes,
      incubatorNotes,
      responseMessage: values.responseMessage,
      action: values.action
    };

    return request;
  };

  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const request = this.getRequest(values);
    const enquiryId = values._id;
    const url = `${INCUBATOR_ENQUIRIES_API}/${enquiryId}`;
    const headers = { "Content-Type": "application/json" };

    HttpUtil.put(
      url,
      headers,
      request,
      data => {
        const enquiry = { ...data };
        const from = convertNumberToDate(data.from);
        const to = convertNumberToDate(data.to);
        enquiry.from = from;
        enquiry.to = to;
        enquiry.incubatorNote = "";
        this.setState({
          enquiry,
          showAlert: true,
          alertType: "Default",
          alertColor: "success",
          alertMessage: `Enquiry form has been submitted successfully.`
        });
        setSubmitting(false);
        window.scrollTo(0, 0);
        this.handleReservation(data);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount = () => {
    let previousPath;
    if (this.props.location.search !== "") {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      previousPath = params.get("from");
    }

    this.setState({
      previousPath: previousPath || DEF_BACK_PATH
    });

    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getEnquiryById(id);
    }
  };

  render() {
    const { permanentFailure, alertMessage } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }
    if (this.state.enquiry._id === null) {
      return null;
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirectPage} />;
    }

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    const formikProps = {
      initialValues: this.state.enquiry,
      validationSchema: ENQUIRY_SCHEMA,
      readOnly: this.state.readOnly,
      previousPath: this.state.previousPath,

      // events
      handleApiFailed: this.handleApiFailed,
      handleSubmit: this.handleSubmit,
      handleStartReservation: this.handleStartReservation
    };
    return (
      <div className="container-fluid-lg container-fluid-xl">
        <div className="row mb-3">
          <div className="col-md-12">
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <IncubatorEnquiryForm {...formikProps} />
          </div>
        </div>
      </div>
    );
  }
}

export default IncubatorEnquiry;
