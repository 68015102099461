import React, { Component } from "react";
import Download from "downloadjs";
import HttpUtil from "../common/HttpUtil";
import {
  INDIVIDUALS_API,
  COUNTRIES_API,
  STATES_API
} from "../common/Constants";
import IndividualForm from "./IndividualForm";
import AlertComponent from "../common/AlertComponent";
import { getDownloadFileName } from "../common/Util";
import { transferServerToUi, transferUiToApi } from "./individualController";
import ErrorPage from "../common/error.page";

const DEFAULT_INDIVIDUAL = {
  firstName: "",
  lastName: "",
  gender: "",
  dob: "",
  photo: "",
  uploadIndvPhoto: "",
  experience: "",
  education: [
    {
      qualification: "",
      degree: "",
      year: "",
      institution: "",
      alumni: false,
      branch: ""
    }
  ],
  references: "",
  addresses: [
    {
      uploadAddressProof: "",
      addressProof: "",
      streetLine1: "",
      streetLine2: "",
      landmark: "",
      city: "",
      state: "TN",
      postalCode: "",
      country: "IN",
      status: "",
      type: ""
    }
  ],

  identificationDocs: {
    panNumber: "",
    aadhaarNumber: "",

    // For Both
    pan: "", // file from server

    // For Individual
    aadhaar: "", // file from server

    uploadPan: "",
    uploadAadhaar: ""
  },

  emails: [{ email: "", type: "WORK" }],
  phones: [{ phone: "", type: "WORK" }]
};

class Individual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individual: { ...DEFAULT_INDIVIDUAL },
      states: [],
      countries: [],
      previousLocation: "",
      incProfUrl: "",
      permanentFailure: false,
      graduationStatus: ""
    };
  }

  // api failed  response alert to user
  handleApiFailed = (message, permanentFailure) => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message,
      permanentFailure
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  handleSubmit = values => {
    // console.log("handle submit values:", JSON.stringify(values, null, 2));

    const updateRequest = transferUiToApi(values);

    const url = `${INDIVIDUALS_API}/${this.state.individual._id}`;

    HttpUtil.put(
      url,
      {},
      updateRequest,
      data => {
        const individual = transferServerToUi(data);

        this.setState({
          individual,

          //Default alert
          alertType: "Default",
          showAlert: true,
          alertColor: "success",
          alertMessage: ` ${
            data.firstName
          } profile has been updated successfully.`
        });
        window.scrollTo(0, 0);
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  downloadDocument = filePartUrl => {
    const individual = this.state.individual;
    const url = `${INDIVIDUALS_API}/${individual._id}/${filePartUrl}`;

    fetch(url, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        if (response.status === 200) {
          return Promise.all([
            response.blob(),
            getDownloadFileName(response, "photo")
          ]);
        } else {
          throw Error(response.statusText);
        }
      })
      .then(([blob, filename]) => {
        Download(blob, filename);
      })
      .catch(error => {
        this.handleApiFailed(error.toString(), true);
      });
  };

  getIndividualById = id => {
    const url = `${INDIVIDUALS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        const individual = transferServerToUi(data);
        this.setState({ individual });
      },
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getStates = () => {
    const url = STATES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ states: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  getCountries = () => {
    const url = COUNTRIES_API;
    HttpUtil.get(
      url,
      {},
      data => this.setState({ countries: data }),
      (data, status) =>
        this.handleApiFailed(data.message, status === 403 || status === 404),
      error => this.handleApiFailed(error.toString(), true)
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getIndividualById(id);

      const search = this.props.location.search;
      const params = new URLSearchParams(search);
      const from = params.get("from");
      const incProfUrl = params.get("incProfUrl");
      const graduationStatus = params.get("graduationStatus");
      this.setState({ previousLocation: from, incProfUrl, graduationStatus });
    } else {
      this.setState({ newIncubatee: true });
    }

    this.getStates();
    this.getCountries();
  }

  render() {
    const { permanentFailure, alertMessage } = this.state;

    if (permanentFailure) {
      return <ErrorPage message={alertMessage} />;
    }

    return (
      <div>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
            />
          </div>
        </div>

        <div>
          <IndividualForm
            readOnly={this.state.graduationStatus === "GRADUATED"}
            individual={this.state.individual}
            handleSubmit={this.handleSubmit}
            downloadDocument={this.downloadDocument}
            previousLocation={this.state.previousLocation}
            incProfUrl={this.state.incProfUrl}
            states={this.state.states}
            countries={this.state.countries}
          />
        </div>
      </div>
    );
  }
}

export default Individual;
