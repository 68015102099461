import React, { Fragment } from "react";
import { Field, ErrorMessage } from "formik";
import { REQUESTOR_CATEGORY_TYPES } from "../../common/LookupConstants";

function getError(touch, error, fieldName) {
  return touch && touch[fieldName] && error && error[fieldName];
}

function getErrorCssClassName(touch, error, fieldName) {
  let formControlSm = "form-control form-control-sm";
  let formControlInValid = "form-control form-control-sm is-invalid";
  return getError(touch, error, fieldName) ? formControlInValid : formControlSm;
}

const UserInformation = props => {
  const { values, touched, errors, setFieldValue, readOnly } = props;
  const userInformation = values.userInformation || {};

  const touch = touched.userInformation || {};
  const error = errors.userInformation || {};

  const disabledField = readOnly
    ? { readOnly: "readOnly", disabled: "disabled" }
    : {};

  // For Existing user
  let disabledExistingUser = {};
  if (!values.newUser || readOnly) {
    disabledExistingUser = {
      readOnly: "readOnly",
      disabled: "disabled"
    };
  }

  const incubatee = props.incubatee || "";

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 mb-3 edit-page-section-header-text">
          User Information
        </div>
      </div>

      {incubatee ? (
        <div className="form-group row mb-1">
          <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
            Requestor
          </label>
          <div className="col-12 col-lg-5 col-xl-5">{incubatee.label}</div>
        </div>
      ) : (
        <Fragment>
          <div className="form-group row mb-1">
            <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
              Requestor Type
            </label>

            <div className="col-12 col-lg-5 col-xl-5">
              <div
                className={`custom-radios ${
                  touch.requestorType && error.requestorType ? "is-invalid" : ""
                }`}
              >
                <div
                  className="custom-control custom-radio d-inline-block"
                  style={{ width: "33.33%" }}
                >
                  <Field
                    type="radio"
                    id="INDIVIDUAL"
                    name="userInformation.requestorType"
                    value="INDIVIDUAL"
                    checked={userInformation.requestorType === "INDIVIDUAL"}
                    className={`custom-control-input ${
                      touch.requestorType && error.requestorType
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={e => {
                      setFieldValue(
                        "userInformation.requestorType",
                        e.target.value
                      );
                      setFieldValue("userInformation.organizationName", "");
                    }}
                    {...disabledExistingUser}
                  />
                  <label className="custom-control-label" htmlFor="INDIVIDUAL">
                    Individual
                  </label>
                </div>
                <div
                  className="custom-control custom-radio d-inline-block"
                  style={{ width: "33.33%" }}
                >
                  <Field
                    type="radio"
                    id="ORGANIZATION"
                    name="userInformation.requestorType"
                    value="ORGANIZATION"
                    checked={userInformation.requestorType === "ORGANIZATION"}
                    className={`custom-control-input ${
                      touch.requestorType && error.requestorType
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={e => {
                      setFieldValue(
                        "userInformation.requestorType",
                        e.target.value
                      );
                      setFieldValue("userInformation.firstName", "");
                      setFieldValue("userInformation.lastName", "");
                    }}
                    {...disabledExistingUser}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="ORGANIZATION"
                  >
                    Organization
                  </label>
                </div>
              </div>
              <ErrorMessage
                component="div"
                name="userInformation.requestorType"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="form-group row mb-1">
            <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
              User Type
            </label>

            <div className="col-12 col-lg-5 col-xl-5">
              <div
                className={`custom-radios ${
                  touch.userType && error.userType ? "is-invalid" : ""
                }`}
              >
                <div
                  className="custom-control custom-radio d-inline-block"
                  style={{ width: "33.33%" }}
                >
                  <Field
                    type="radio"
                    id="INTERNAL"
                    name="userInformation.userType"
                    value="INTERNAL"
                    checked={userInformation.userType === "INTERNAL"}
                    className={`custom-control-input ${
                      touch.userType && error.userType ? "is-invalid" : ""
                    }`}
                    onChange={e => {
                      setFieldValue("userInformation.userType", e.target.value);
                    }}
                    {...disabledField}
                  />
                  <label className="custom-control-label" htmlFor="INTERNAL">
                    Internal
                  </label>
                </div>
                <div
                  className="custom-control custom-radio d-inline-block"
                  style={{ width: "33.33%" }}
                >
                  <Field
                    type="radio"
                    id="EXTERNAL"
                    name="userInformation.userType"
                    value="EXTERNAL"
                    checked={userInformation.userType === "EXTERNAL"}
                    className={`custom-control-input ${
                      touch.userType && error.userType ? "is-invalid" : ""
                    }`}
                    onChange={e => {
                      setFieldValue("userInformation.userType", e.target.value);
                    }}
                    {...disabledField}
                  />
                  <label className="custom-control-label" htmlFor="EXTERNAL">
                    External
                  </label>
                </div>
              </div>
              <ErrorMessage
                component="div"
                name="userInformation.userType"
                className="invalid-feedback"
              />
            </div>
          </div>

          <div className="form-group row mb-1">
            <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
              Requester Category
            </label>

            <div className="col-12 col-lg-7 col-xl-6">
              <div
                className={`custom-radios ${
                  touch.requestorCategory && error.requestorCategory
                    ? "is-invalid"
                    : ""
                }`}
              >
                {REQUESTOR_CATEGORY_TYPES.map((category, idx) => {
                  return (
                    <div
                      className="custom-control custom-radio d-inline-block"
                      style={{ width: "25%" }}
                      key={`${idx}`}
                    >
                      <Field
                        type="radio"
                        id={category.key}
                        name="userInformation.requestorCategory"
                        value={category.key}
                        checked={
                          userInformation.requestorCategory === category.key
                        }
                        className={`custom-control-input ${
                          touch.requestorCategory && error.requestorCategory
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={e => {
                          setFieldValue(
                            "userInformation.requestorCategory",
                            e.target.value
                          );
                        }}
                        {...disabledField}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={category.key}
                      >
                        {category.value}
                      </label>
                    </div>
                  );
                })}
              </div>
              <ErrorMessage
                component="div"
                name="userInformation.requestorCategory"
                className="invalid-feedback"
              />
            </div>
          </div>

          {userInformation.requestorType === "INDIVIDUAL" ? (
            <Fragment>
              <div className="form-group row mb-1">
                <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
                  First Name
                </label>

                <div className="col-12 col-lg-3 col-xl-3">
                  <Field
                    type="text"
                    name="userInformation.firstName"
                    value={userInformation.firstName || ""}
                    className={getErrorCssClassName(touch, error, "firstName")}
                    {...disabledExistingUser}
                  />

                  <ErrorMessage
                    component="div"
                    name="userInformation.firstName"
                    className="invalid-feedback"
                  />
                </div>

                <label className="col-form-label col-form-label-sm  col-12 col-lg-2 col-xl-2 text-sm-left edit-page-label">
                  Last Name
                </label>

                <div className="col-12 col-lg-3 col-xl-3">
                  <Field
                    type="text"
                    name="userInformation.lastName"
                    value={userInformation.lastName || ""}
                    className={getErrorCssClassName(touch, error, "lastName")}
                    {...disabledExistingUser}
                  />
                  <ErrorMessage
                    component="div"
                    name="userInformation.lastName"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </Fragment>
          ) : null}

          {userInformation.requestorType === "ORGANIZATION" ? (
            <Fragment>
              <div className="form-group row mb-1">
                <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label-required">
                  Organization Name
                </label>

                <div className="col-12 col-lg-3 col-xl-3">
                  <Field
                    type="text"
                    name="userInformation.organizationName"
                    value={userInformation.organizationName || ""}
                    className={getErrorCssClassName(
                      touch,
                      error,
                      "organizationName"
                    )}
                    {...disabledExistingUser}
                  />
                  <ErrorMessage
                    component="div"
                    name="userInformation.organizationName"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-group row mb-1">
                <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                  Contact Name / Department
                </label>

                <div className="col-12 col-lg-3 col-xl-3">
                  <Field
                    type="text"
                    name="userInformation.contactNameOrDepartment"
                    value={userInformation.contactNameOrDepartment || ""}
                    className={getErrorCssClassName(
                      touch,
                      error,
                      "contactNameOrDepartment"
                    )}
                    {...disabledField}
                  />
                  <ErrorMessage
                    component="div"
                    name="userInformation.contactNameOrDepartment"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-group row mb-1">
                <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                  Designation
                </label>

                <div className="col-12 col-lg-3 col-xl-3">
                  <Field
                    type="text"
                    name="userInformation.designation"
                    value={userInformation.designation || ""}
                    className={getErrorCssClassName(
                      touch,
                      error,
                      "designation"
                    )}
                    {...disabledField}
                  />
                  <ErrorMessage
                    component="div"
                    name="userInformation.designation"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-group row mb-1">
                <label className="col-form-label col-form-label-sm  col-12 col-lg-3 col-xl-3 text-sm-left edit-page-label">
                  Reference
                </label>

                <div className="col-12 col-lg-3 col-xl-3">
                  <Field
                    type="text"
                    name="userInformation.reference"
                    value={userInformation.reference || ""}
                    className={getErrorCssClassName(touch, error, "reference")}
                    {...disabledField}
                  />
                  <ErrorMessage
                    component="div"
                    name="userInformation.reference"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </Fragment>
          ) : null}
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserInformation;
