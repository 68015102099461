import React, { Fragment } from "react";
import { Field } from "formik";

export const ChecklistTemplate = props => {
  const { values, uniqueKey, errors } = props;
  const checklist = values.checklist || [];

  const checklistErrors = errors && errors.checklist ? errors.checklist : [];
  return (
    <Fragment>
      <strong className="edit-page-section-header-text d-block">
        Checklist
      </strong>
      {checklist.map((chklst, index) => {
        return (
          <div
            key={`${uniqueKey}-${index}`}
            className="form-group row mb-2 mt-3"
          >
            <div className="col-12">
              <div className="custom-control custom-checkbox">
                <Field
                  type="checkbox"
                  className="custom-control-input"
                  name={`checklist[${index}].selected`}
                  checked={checklist[index].selected || ""}
                  id={`${uniqueKey}-${index}`}
                />
                <label
                  className="custom-control-label edit-page-label"
                  htmlFor={`${uniqueKey}-${index}`}
                >
                  {chklst.label}
                </label>

                {checklistErrors && checklistErrors[index] ? (
                  <div className="text-danger fa-sm">
                    {checklistErrors[index].selected}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
      {/* <div className="form-group row mb-2 mt-3">
        <div className="col-12">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck4"
            />
            <label className="custom-control-label" htmlFor="customCheck4">
              Consider Indirect Cost (F&A) if applicable. A final IDC expense
              will be calculated and posted by Accounting Services once all
              expenses are finalized.
            </label>
          </div>
        </div>
      </div>
      <div className="form-group row mb-2 mt-3">
        <div className="col-12">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck5"
            />
            <label className="custom-control-label" htmlFor="customCheck5">
              Consider Indirect Cost (F&A) if applicable. A final IDC expense
              will be calculated and posted by Accounting Services once all
              expenses are finalized.
            </label>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};
