import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import AlertComponent from "../common/AlertComponent";
import HttpUtil from "../common/HttpUtil";
import { EQUIPMENTS_API, INTERNAL_ORGS_API } from "../common/Constants";
import EquipmentFormik from "./EquipmentFormik";

const EQUIPMENT_SCHEMA = Yup.object().shape({
  assetId: Yup.string().required("Asset Id is required"),
  assetName: Yup.string().required("Asset Name is required"),
  assetType: Yup.string().required("Asset Type is required"),
  assetCategory: Yup.string().required("Asset Category is required"),
  rentalType: Yup.string().required("Rental Type is required"),
  ownerName: Yup.string().required("Asset Owner is required"),
  // notes: Yup.string().required("Notes is required"),
  status: Yup.string().required("Status is required"),
  assetOwnerContact: Yup.object().shape({
    name: Yup.string()
      .label("Asset Owner Name")
      .required()
    // email: Yup.string().required("Email is required"),
    // phone: Yup.string().required("Phone is required")
  }),
  incubatorContact: Yup.object().shape({
    name: Yup.string()
      .label("Incubator Name")
      .required()
    // email: Yup.string().required("Email is required"),
    // phone: Yup.string().required("Phone is required")
  }),
  // location: Yup.object().shape({
  //   campusName: Yup.string().required("Campus Name is required"),
  //   buildingName: Yup.string().required("Building Name is required"),
  //   facilityName: Yup.string().required("Facility Name is required"),
  //   floor: Yup.string().required("Floor Name / Number is required"),
  //   room: Yup.string().required("Room Name / Number is required"),
  //   department: Yup.string().required("Department Name is required")
  // }),
  // manufacture: Yup.object().shape({
  //   modelNumber: Yup.string().required("Model Number is required"),
  //   serialNumber: Yup.string().required("Serial Number is required"),
  //   manufacturerName: Yup.string().required("Manufacturer Name is required"),
  //   manufactureYear: Yup.string().required("Manufacture Year is required")
  // }),
  rates: Yup.array()
    .of(
      Yup.object().shape({
        rate: Yup.mixed().test(
          "rate-empty",
          "Rate should be a positive number",
          value => value && Number(value) > 0
        ),
        unit: Yup.mixed().test(
          "unit-empty",
          "Unit should be a positive number",
          value => value && Number(value) > 0
        ),
        unitType: Yup.string()
          .label("Unit Type")
          .required()
      })
    )
    .test(
      "alteast-one-rate",
      "Atleast one rate information should be entered",
      values => {
        return values.some(
          value => !Number.isNaN(value.rate) && value.rate > 0
        );
      }
    )
});

function getRateDurationList() {
  return [
    {
      key: "HOURLY",
      label: "Hourly",
      rate: ""
    },
    {
      key: "DAILY",
      label: "Daily",
      rate: ""
    },
    {
      key: "WEEKLY",
      label: "Weekly",
      rate: ""
    },
    {
      key: "MONTHLY",
      label: "Monthly",
      rate: ""
    },
    {
      key: "LONG_TERM",
      label: "Long Term",
      rate: ""
    }
  ];
}

const RATE_DURATION_LIST_KEY_INDEX = getRateDurationList().reduce(
  (ag, value, index) => {
    ag[value.key] = index + 1;
    return ag;
  },
  {}
);

class Equipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipment: {
        _id: "",
        assetId: "",
        assetName: "",
        assetType: "",
        assetCategory: "",
        status: "ACTIVE",
        rentalType: "ON_PREMISE",
        ownerName: "",
        notes: "",

        rates: [
          {
            rate: 0,
            unit: 0,
            unitType: ""
          }
        ],
        productImages: [],

        assetOwnerContact: {
          name: "",
          phone: "",
          email: ""
        },
        incubatorContact: {
          name: "",
          phone: "",
          email: ""
        },

        location: {
          campusName: "",
          buildingName: "",
          facilityName: "",
          floor: "",
          room: "",
          department: ""
        },
        manufacture: {
          modelNumber: "",
          serialNumber: "",
          manufacturerName: "",
          manufactureYear: ""
        }
      },

      internalOrgs: [],
      uploadedProductImagesInfo: [],

      newEquipment: null,
      saveEquipment: false,
      equipmentId: null,
      inputError: {}
    };
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
    window.scrollTo(0, 0);
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  addDocs = (formData, name, file) => {
    if (file) {
      formData.append(name, file);
    }
  };

  handleUpdate = (formData, setSubmitting) => {
    const id = this.state.equipment._id;
    const url = `${EQUIPMENTS_API}/${id}`;
    HttpUtil.put(
      url,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.setState({
          equipmentId: id,
          equipment: data,
          saveEquipment: true
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  handleRemoveImage = documentId => {
    const equipmentId = this.state.equipment._id;
    const url = `${EQUIPMENTS_API}/${equipmentId}/remove-image/${documentId}`;
    HttpUtil.delete(
      url,
      {},
      data => {
        let uploadedProductImagesInfo = [];
        const productImages = data.productImages || [];
        productImages.forEach(img => {
          const preview = {
            url: `${EQUIPMENTS_API}/${data._id}/product-images/${img._id}`,
            _id: img._id
          };
          uploadedProductImagesInfo.push(preview);
        });

        const equipment = { ...this.state.equipment };
        equipment.productImages = productImages;

        this.setState({
          save: true,
          equipmentId: data._id
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  getAllInternalOrgs = () => {
    const url = INTERNAL_ORGS_API;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({ internalOrgs: data });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  setDataFromServer = data => {
    let uploadedProductImagesInfo = [];
    const productImages = data.productImages || [];
    productImages.forEach(img => {
      const preview = {
        url: `${EQUIPMENTS_API}/${data._id}/product-images/${img._id}`,
        _id: img._id
      };
      uploadedProductImagesInfo.push(preview);
    });

    const rates = getRateDurationList();
    data.rentalRates.forEach(price => {
      const index = RATE_DURATION_LIST_KEY_INDEX[price.duration];
      if (index) {
        rates[index - 1].rate = price.rate;
      }
    });

    const assOwnCt = data.assetOwnerContact || {};
    const assetOwnerContact = {
      name: assOwnCt.name || "",
      phone: assOwnCt.phone || "",
      email: assOwnCt.email || ""
    };

    const incCt = data.incubatorContact || {};
    const incubatorContact = {
      name: incCt.name || "",
      phone: incCt.phone || "",
      email: incCt.email || ""
    };

    const loc = data.location || {};
    const location = {
      campusName: loc.campusName || "",
      buildingName: loc.buildingName || "",
      facilityName: loc.facilityName || "",
      floor: loc.floor || "",
      room: loc.room || "",
      department: loc.department || ""
    };

    const manfatre = data.manufacture || {};
    const manufacture = {
      modelNumber: manfatre.modelNumber || "",
      serialNumber: manfatre.serialNumber || "",
      manufacturerName: manfatre.manufacturerName || "",
      manufactureYear: manfatre.manufactureYear || ""
    };

    const equipment = {
      _id: data._id,
      productImages: productImages,
      assetId: data.assetId,
      assetName: data.assetName,
      assetType: data.assetType,
      assetCategory: data.assetCategory,
      rentalType: data.rentalType,
      status: data.status,
      ownerName: data.ownerName,
      notes: data.notes,

      assetOwnerContact,
      incubatorContact,
      location,
      manufacture,
      rates: data.rentalRates
    };

    this.setState({
      equipment,
      uploadedProductImagesInfo,
      newEquipment: false
    });
  };

  getEquipmentById = id => {
    const message =
      this.props.location.state &&
      this.props.location.state.save &&
      this.props.location.state.message;
    if (message) {
      this.setState({
        showAlert: true,
        alertType: "Default",
        alertColor: "success",
        alertMessage: message
      });
      window.scrollTo(0, 0);
    }

    const url = `${EQUIPMENTS_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setDataFromServer(data);
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getEquipmentById(id);
    } else {
      this.setState({ newEquipment: true });
    }

    this.getAllInternalOrgs();
  }

  /** Formik props Start ********************************/
  checkAddValue = (currentObj, newObject, fieldName) => {
    newObject[fieldName] = currentObj[fieldName] || undefined;
  };

  transformToApiModel = equipment => {
    const newEquipment = {};
    this.checkAddValue(equipment, newEquipment, "assetId");
    this.checkAddValue(equipment, newEquipment, "assetName");
    this.checkAddValue(equipment, newEquipment, "assetType");
    this.checkAddValue(equipment, newEquipment, "assetCategory");
    this.checkAddValue(equipment, newEquipment, "rentalType");
    this.checkAddValue(equipment, newEquipment, "status");
    this.checkAddValue(equipment, newEquipment, "ownerName");
    this.checkAddValue(equipment, newEquipment, "notes");

    const assetOwnerContact = equipment.assetOwnerContact;
    newEquipment.assetOwnerContact = {
      name: assetOwnerContact.name || null,
      phone: assetOwnerContact.phone || null,
      email: assetOwnerContact.email || null
    };

    const incubatorContact = equipment.incubatorContact;
    newEquipment.incubatorContact = {
      name: incubatorContact.name || null,
      phone: incubatorContact.phone || null,
      email: incubatorContact.email || null
    };

    const location = equipment.location;
    newEquipment.location = {
      campusName: location.campusName || null,
      buildingName: location.buildingName || null,
      facilityName: location.facilityName || null,
      floor: location.floor || null,
      room: location.room || null,
      department: location.department || null
    };

    const manufacture = equipment.manufacture;
    newEquipment.manufacture = {
      modelNumber: `${manufacture.modelNumber}` || null,
      serialNumber: `${manufacture.serialNumber}` || null,
      manufacturerName: manufacture.manufacturerName || null,
      manufactureYear: `${manufacture.manufactureYear}` || null
    };

    newEquipment.rentalRates = equipment.rates;
    // .filter(item => item.rate > 0)
    // .map(item => {
    //   return { duration: item.key, rate: item.rate };
    // });
    return newEquipment;
  };

  handleSave = (formData, setSubmitting) => {
    HttpUtil.post(
      EQUIPMENTS_API,
      {},
      formData,
      data => {
        setSubmitting(false);
        this.setState({
          saveEquipment: true,
          equipment: data,
          equipmentId: data._id
        });
      },
      (data, status) => {
        setSubmitting(false);
        this.handleApiFailed(data.message);
      },
      error => {
        setSubmitting(false);
        this.handleApiFailed(error.toString());
      }
    );
  };

  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const formData = new FormData();
    const uploadedImages = values.uploadedImages || [];

    for (let i = 0; i < uploadedImages.length; i++) {
      this.addDocs(formData, "productImages", uploadedImages[i]);
    }

    formData.append(
      "message",
      JSON.stringify(this.transformToApiModel(values))
    );

    const productImages = values.productImages || [];
    for (let index = 0; index < productImages.length; index++) {
      this.addDocs(formData, "productImages", productImages[index]);
    }

    if (this.state.newEquipment) {
      this.handleSave(formData, setSubmitting);
    } else {
      this.handleUpdate(formData, setSubmitting);
    }
  };
  /** Formik props End ********************************/

  render() {
    const { newEquipment } = this.state;
    if (newEquipment === null) {
      return null;
    }
    if (this.state.saveEquipment) {
      const equipment = this.state.equipment;
      const message = this.state.newEquipment
        ? `New Asset has been added successfully.`
        : `Asset "${equipment.assetType}" has been updated successfully.`;
      const redirectState = {
        pathname: `/equipment/edit/${this.state.equipmentId}`,
        state: { save: true, message }
      };
      return <Redirect to={redirectState} />;
    }

    const equipmentFormProps = {
      newEquipment: this.state.newEquipment,
      internalOrgs: this.state.internalOrgs,
      uploadedProductImagesInfo: this.state.uploadedProductImagesInfo,
      inputError: this.state.inputError,

      // formik props
      initialValues: this.state.equipment,
      validationSchema: EQUIPMENT_SCHEMA,
      handleSubmit: this.handleSubmit,

      // events
      handleRemoveImage: this.handleRemoveImage
    };

    const alertProps = {
      show: this.state.showAlert,
      type: this.state.alertType,
      alertColor: this.state.alertColor,
      message: this.state.alertMessage,
      close: this.closeDefaultAlert,
      confirm: this.okConfirmUpdate
    };

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-12">
            {/* show alert message  */}
            <AlertComponent {...alertProps} />
          </div>
        </div>

        <EquipmentFormik {...equipmentFormProps} />
      </Fragment>
    );
  }
}

export default Equipment;
