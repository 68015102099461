import { combineReducers } from "redux";
import ToggleSidebarReducer from "../actions/toggle-sidebar/ToggleSidebarReducer";
import ToggleNavbarReducer from "../actions/toggle-navbar/ToggleNavbarReducer";
import LoginReducer from "../actions/login/LoginReducer";

const rootReducer = combineReducers({
  ToggleSidebarReducer,
  ToggleNavbarReducer,
  LoginReducer
});

export default rootReducer;
