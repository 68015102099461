import * as Yup from "yup";

export const YupSetLocale = {
  mixed: {
    notType: ({ path, type, value, originalValue }) => {
      if (type === "number" && Number.isNaN(value)) {
        return `${path} should be of type \`${type}\``;
      }

      if (type === "date" && Number.isNaN(value.getTime())) {
        return `${path} should be of type \`${type}\``;
      }

      let isCast = originalValue != null && originalValue !== value;
      let msg =
        `${path} must be a \`${type}\` type, ` +
        `but the final value was: \`${Yup.printValue(value, true)}\`` +
        (isCast
          ? ` (cast from the value \`${Yup.printValue(originalValue, true)}\`).`
          : ".");

      if (value === null) {
        msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
      }
      return msg;
    }
  }
};
