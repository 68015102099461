import React, { Component, Fragment } from "react";
import { CHARGES_API } from "../../common/Constants";
import HttpUtil from "../../common/HttpUtil";
import AlertComponent from "../../common/AlertComponent";
import ManualChargePreviewForm from "./ManualChargePreviewForm";

class ManualChargeViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewForm: null
    };
  }

  componentDidMount() {
    if (this.props.match !== undefined) {
      const id = this.props.match.params.id;
      this.getCharge(id);
    }
  }

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  getCheckIcon = data => {
    let unitPriced;
    if (data) {
      unitPriced = (
        <Fragment>
          <strong>
            <i
              className="far fa-check-square text-success font-weight-bold"
              style={{ fontSize: "m-large" }}
            />
          </strong>
          &nbsp; Yes
        </Fragment>
      );
    } else {
      unitPriced = (
        <Fragment>
          <strong>
            <i
              className="far fa-window-close text-danger font-weight-bold"
              style={{ fontSize: "m-large" }}
            />
          </strong>
          &nbsp; No
        </Fragment>
      );
    }
    return unitPriced;
  };

  getName = customer => {
    let name = "";
    const names = [];
    if (customer.firstName) {
      names.push(customer.firstName);
    }
    if (customer.lastName) {
      names.push(customer.lastName);
    }
    name = names.join(" ");
    name = names.length > 0 ? names.join(" ") : customer.name;
    return name;
  };

  addData = (manualCharges, labelName, value) => {
    if (value) {
      manualCharges.push({
        label: labelName,
        value
      });
    }
  };

  handleData = data => {
    let alertType,
      showAlert,
      alertColor,
      alertMessage = null;
    if (this.props.location.state !== undefined) {
      alertType = "Default";
      showAlert = true;
      alertColor = "success";
      alertMessage = "Manual Charge has been created successfully.";
    }

    const previewForm = { ...data };

    this.setState({
      previewForm,
      alertType,
      showAlert,
      alertColor,
      alertMessage
    });
  };

  getCharge = id => {
    const url = `${CHARGES_API}/${id}`;
    HttpUtil.get(
      url,
      {},
      data => this.handleData(data),
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  render() {
    const {
      alertType,
      showAlert,
      alertColor,
      alertMessage,
      previewForm
    } = this.state;

    if (previewForm === null) {
      return null;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={showAlert}
              type={alertType}
              alertColor={alertColor}
              message={alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>
        <ManualChargePreviewForm
          data={this.state.previewForm}
          title="Manual Charge information"
        />
      </Fragment>
    );
  }
}

export default ManualChargeViewForm;
