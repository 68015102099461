import React from "react";
import { Formik, Form, Field } from "formik";
import { getErrorCssClassName, ErrorComponent } from "../../common/FormikUtil";
import DatePicker from "react-datepicker";
import { getDisplayDate } from "../../lease/DateUtil";
import { GRANT_AWARD_TERMINATION_STATUS_REASONS } from "../../common/LookupConstants";
import ShowHideComponent from "../../widgets/ShowHideComponent";
import { DocumentTemplate } from "./DocumentTemplate";
import { ChecklistTemplate } from "./ChecklistTemplate";
import { validate } from "./GrantAwardReviewForm";
import Patents from "./Patents";

function getDocumentTypes(template) {
  const documents = (template && template.documents) || [];
  const docTypes = documents.map(d => d.documentType);
  return docTypes;
}

function getCheckListLabel(template) {
  return (template && template.checklist) || [];
}

/*
const GRANT_TERMINATION_SCHEMA = Yup.object().shape({
  statusReason: Yup.string()
    .label("Status Reason")
    .required(),
  closureDate: Yup.date()
    .label("Termination Date")
    .required(),
  otherStatusReason: Yup.string().when("statusReason", {
    is: "OTHER",
    then: Yup.string()
      .required()
      .label("Other Status Reason"),
    else: Yup.string()
  }),
  notes: Yup.string()
    .label("Closure Notes")
    .required(),
  trademarkRegistered: Yup.boolean()
    .label("Trademark Registered")
    .optional(),
  trademarkName: Yup.string().when("trademarkRegistered", {
    is: true,
    then: Yup.string()
      .label("Trademark Name")
      .required()
  }),
  companyFormed: Yup.boolean()
    .label("Company Formed")
    .optional(),
  afterGrantAward: Yup.boolean()
    .label("After Grant Award")
    .optional(),
  companyName: Yup.string()
    .label("Company Name")
    .optional(),
  dateOfIncorporation: Yup.date()
    .label("Date Of Incorporation")
    .optional()
});
*/
function validateFn(values, template) {
  const errors = validate(values, template);

  if (!values.statusReason) {
    errors.statusReason = "Reason is required field";
  }

  if (!values.closureDate) {
    errors.closureDate = "Termination Date is required field";
  }

  if (values.statusReason === "OTHER") {
    errors.otherStatusReason = "Status Reason is required field";
  }

  if (!values.notes) {
    errors.notes = "Notes is required field";
  }

  if (values.trademarkRegistered) {
    if (!values.trademarkName) {
      errors.trademarkName = "Trademark Name is required field";
    }
  }

  return errors;
}

const INITIAL_VALUES = {
  statusReason: "",
  completed: false,
  otherStatusReason: "",
  closureDate: null,
  notes: "",
  documents: [],
  availableDocuments: [],
  documentName: "",
  documentType: "",
  checklist: [],

  trademarkRegistered: false,
  trademarkName: "",

  companyFormed: false,
  afterGrantAward: false,
  companyName: "",
  dateOfIncorporation: "",
  patents: [
    {
      country: "",
      applicationNumber: "",
      applicationFilingDate: "",
      fieldOfInvention: "",
      classificationCode: "",
      patentStatus: ""
    }
  ]
};

function GrantAwardTerminationForm(props) {
  const docTypes = getDocumentTypes(props.template);
  INITIAL_VALUES.checklist = getCheckListLabel(props.template);
  return (
    <Formik
      enableReinitialize
      // validationSchema={GRANT_TERMINATION_SCHEMA}
      validate={values => validateFn(values, props.template)}
      initialValues={INITIAL_VALUES}
      onSubmit={(values, { setSubmitting }) => {
        props.terminateGrantAward(values, setSubmitting);
      }}
      render={({
        values,
        onSubmit,
        isSubmitting,
        errors,
        touched,
        setFieldValue
      }) => {
        return (
          <Form>
            <div className="shadow card flex-fill box-border box-danger">
              <div className="card-header border-0 text-center pb-0">
                <h6 className="mb-0 text-light-blue">
                  <strong>Grant Termination</strong>
                </h6>
              </div>

              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-0">
                    <div className="pl-3 pr-3">
                      <strong className="border-bottom pb-1 d-block">
                        Termination Information
                      </strong>
                      <div className="form-group row mb-2 mt-2">
                        <label className="col-form-label edit-page-label-required col-form-label-sm col-12 col-lg-4 col-xl-4 pr-0">
                          Status
                        </label>
                        <div className="col-12 col-lg-8 col-xl-8">
                          Terminated
                        </div>
                      </div>
                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
                          Status Reason
                        </label>
                        <div className="col-12 col-lg-8 col-xl-8">
                          <Field
                            component="select"
                            type="text"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "statusReason"
                            )}`}
                            name="statusReason"
                            value={values.statusReason || ""}
                          >
                            <option value="">Select...</option>
                            {GRANT_AWARD_TERMINATION_STATUS_REASONS.map(
                              (clsr, i) => (
                                <option key={i} value={clsr.key}>
                                  {clsr.value}
                                </option>
                              )
                            )}
                          </Field>

                          <ErrorComponent
                            fieldName="statusReason"
                            touched={touched}
                            error={errors}
                          />
                        </div>
                      </div>

                      <ShowHideComponent show={values.statusReason === "OTHER"}>
                        <div className="form-group row mb-2">
                          <label className="col-form-label edit-page-label-required col-form-label-sm col-12 col-lg-4 col-xl-4">
                            Other Reason
                          </label>
                          <div className="col-12 col-lg-8 col-xl-8">
                            <Field
                              type="text"
                              className={`form-control form-control-sm ${getErrorCssClassName(
                                touched,
                                errors,
                                "otherStatusReason"
                              )}`}
                              name="otherStatusReason"
                              value={values.otherStatusReason || ""}
                            />

                            <ErrorComponent
                              fieldName="otherStatusReason"
                              touched={touched}
                              error={errors}
                            />
                          </div>
                        </div>
                      </ShowHideComponent>
                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
                          Termination Date
                        </label>
                        <div className="col-12 col-lg-8 col-xl-8">
                          <DatePicker
                            value={
                              values.closureDate !== null
                                ? getDisplayDate(values.closureDate)
                                : ""
                            }
                            selected={values.closureDate}
                            onChange={e => {
                              setFieldValue(`closureDate`, e);
                            }}
                            utcOffset={0}
                            placeholderText="dd-mm-yyyy"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "closureDate"
                            )}`}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />

                          <ErrorComponent
                            fieldName="closureDate"
                            touched={touched}
                            error={errors}
                            datePicker={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row mb-2">
                        <label className="col-form-label col-form-label-sm edit-page-label-required col-12 col-lg-4 col-xl-4">
                          Notes
                        </label>
                        <div className="col-12 col-lg-8 col-xl-8">
                          <Field
                            component="textarea"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "notes"
                            )}`}
                            name="notes"
                            rows="6"
                          />

                          <ErrorComponent
                            fieldName="notes"
                            touched={touched}
                            error={errors}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                    <DocumentTemplate
                      values={values}
                      errors={errors}
                      documentTypes={docTypes}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                    <ChecklistTemplate
                      values={values}
                      errors={errors}
                      uniqueKey={values._id || "New"}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-8">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label col-form-label-sm">
                        Trademark Registered ?
                      </label>
                      <div className="col-sm-8">
                        <div className="custom-control custom-checkbox">
                          <Field
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                            name="trademarkRegistered"
                            checked={values.trademarkRegistered || ""}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>

                    <ShowHideComponent show={values.trademarkRegistered}>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm">
                          Trademark Name
                        </label>
                        <div className="col-sm-8">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "trademarkName"
                            )}`}
                            name="trademarkName"
                            value={values.trademarkName || ""}
                          />

                          <ErrorComponent
                            fieldName="trademarkName"
                            touched={touched}
                            error={errors}
                          />
                        </div>
                      </div>
                    </ShowHideComponent>

                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label col-form-label-sm">
                        Company is Formed ?
                      </label>
                      <div className="col-sm-8">
                        <div className="custom-control custom-checkbox">
                          <Field
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck2"
                            name="companyFormed"
                            checked={values.companyFormed || ""}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            Yes
                          </label>
                        </div>
                        <ErrorComponent
                          fieldName="companyFormed"
                          touched={touched}
                          error={errors}
                        />
                      </div>
                    </div>

                    <ShowHideComponent show={values.companyFormed}>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm">
                          After Grant Award ?
                        </label>
                        <div className="col-sm-8">
                          <div className="custom-control custom-checkbox">
                            <Field
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck3"
                              name="afterGrantAward"
                              checked={values.afterGrantAward || ""}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck3"
                            >
                              Yes
                            </label>
                          </div>
                          <ErrorComponent
                            fieldName="afterGrantAward"
                            touched={touched}
                            error={errors}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm">
                          Name of Company
                        </label>
                        <div className="col-sm-8">
                          <Field
                            type="text"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "companyName"
                            )}`}
                            name="companyName"
                            value={values.companyName || ""}
                          />

                          <ErrorComponent
                            fieldName="companyName"
                            touched={touched}
                            error={errors}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label col-form-label-sm">
                          Date of Incorporation
                        </label>
                        <div className="col-sm-8">
                          <DatePicker
                            value={
                              values.dateOfIncorporation !== null
                                ? getDisplayDate(values.dateOfIncorporation)
                                : ""
                            }
                            selected={values.dateOfIncorporation || null}
                            onChange={e => {
                              setFieldValue(`dateOfIncorporation`, e);
                            }}
                            utcOffset={0}
                            placeholderText="dd-mm-yyyy"
                            className={`form-control form-control-sm ${getErrorCssClassName(
                              touched,
                              errors,
                              "dateOfIncorporation"
                            )}`}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />

                          <ErrorComponent
                            fieldName="dateOfIncorporation"
                            touched={touched}
                            error={errors}
                            datePicker={true}
                          />
                        </div>
                      </div>
                    </ShowHideComponent>
                  </div>
                </div>

                <Patents
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />

                <div className="row mt-5 mb-3">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-sm btn-outline-secondary mr-2"
                      type="button"
                      onClick={() => props.cancelTerminate()}
                    >
                      Cancel
                    </button>
                    <button className="btn btn-sm btn-danger" type="submit">
                      Terminate Grant
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default GrantAwardTerminationForm;
