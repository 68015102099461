import React, { Fragment } from "react";
import CompactTable from "../../widgets/CompactTable";
import {
  changeToINR,
  ADJUSTMENT_PAYMENT_STATUS_LIST_OBJECT
} from "../../common/LookupConstants";
import { getDisplayDate, convertNumberToDate } from "../../lease/DateUtil";

function adjustmentToNameFormater(cell, row) {
  let names = [];
  let adjustmentTo = row.adjustmentTo;
  if (adjustmentTo.firstName) {
    names.push(adjustmentTo.firstName);
  }

  if (adjustmentTo.lastName) {
    names.push(adjustmentTo.lastName);
  }

  if (adjustmentTo.name) {
    names.push(adjustmentTo.name);
  }

  const name = names.join(" ");
  return name;
}

function dateFormater(yyyymmdd) {
  return getDisplayDate(convertNumberToDate(yyyymmdd));
}

const columnsAdjustments = [
  {
    dataField: "adjustmentTo",
    text: "Customer Name",
    sort: true,
    formatter: adjustmentToNameFormater,
    filterValue: adjustmentToNameFormater,
    headerStyle: {
      width: "30%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "adjustmentToType",
    text: "Customer Type",
    sort: true,
    headerStyle: {
      width: "15%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "adjustmentReason",
    text: "Reason",
    sort: true,
    classes: "text-center",
    headerStyle: {
      width: "30%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },
  {
    dataField: "date",
    text: "Date",
    formatter: dateFormater,
    csvFormatter: dateFormater,
    sort: true,
    headerStyle: {
      width: "15%",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "adjustmentAmount",
    text: "Amount",
    formatter: changeToINR,
    classes: "text-right",
    sort: true,
    headerStyle: {
      width: "10%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  },

  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "text-center",
    formatter: cell => ADJUSTMENT_PAYMENT_STATUS_LIST_OBJECT[cell],
    headerStyle: {
      width: "10%",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.05)"
    }
  }
];

const AdjustmentSearchResult = props => {
  let adjustments = [];
  if (props.searchResults && props.searchResults.adjustments) {
    adjustments = props.searchResults.adjustments;
  }

  return (
    <Fragment>
      <h6 className="card-title mb-2 edit-page-section-header-text">
        Payment Adjustments
      </h6>

      <CompactTable
        data={adjustments}
        columns={columnsAdjustments}
        noDataIndication={"No Payment Adjustments Available"}
      />
    </Fragment>
  );
};

export default AdjustmentSearchResult;
