import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import HttpUtil from "../common/HttpUtil";
import AlertComponent from "../common/AlertComponent";
import { INCUBATEES_API } from "../common/Constants";
import { INCUBATEE_TYPES_OBJECT } from "../common/LookupConstants";
import TableList from "../common/TableList";
import { GRADUATION_STATUS_OBJECT } from "../common/IncubateeProfileConstants";
import { getDisplayDate, convertNumberToDate } from "../lease/DateUtil";

function getIncubateeName(cell, row) {
  if (!row) return null;
  const url = `/admin/incubatee/profile/${row._id}`;
  return <Link to={url}>{row.name}</Link>;
}

function getIncubateeNameFilter(cell, row) {
  if (!row) return null;
  return row.name || "Unspecified";
}

class GraduatedIncubatees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graduatedIncubatees: null
    };
  }

  componentDidMount() {
    this.getAll();
  }

  actionsFormater = (cell, row) => {
    if (!row) return null;
    let viewButton = (
      <Link
        to={`/admin/incubatee/profile/${row._id}`}
        role="button"
        datatoggle="tooltip"
        title="View"
      >
        <i className="fas fa-eye fa-lg action-icon" />
      </Link>
    );
    return viewButton;
  };

  getAll = () => {
    const url = `${INCUBATEES_API}/graduated`;
    HttpUtil.get(
      url,
      {},
      data => {
        this.setState({
          graduatedIncubatees: data
        });
      },
      (data, status) => this.handleApiFailed(data.message),
      error => this.handleApiFailed(error.toString())
    );
  };

  // api failed  response alert to user
  handleApiFailed = message => {
    this.setState({
      //Default alert
      alertType: "Default",
      showAlert: true,
      alertColor: "danger",
      alertMessage: message
    });
  };

  // close alert message
  closeDefaultAlert = () => {
    this.setState({
      alertType: "",
      showAlert: false,
      alertColor: "",
      alertMessage: ""
    });
  };

  dateFormater = yyyymmdd => {
    return getDisplayDate(convertNumberToDate(yyyymmdd));
  };

  render() {
    const { graduatedIncubatees } = this.state;

    const columnsGraduIncuabtees = [
      {
        dataField: "name",
        text: "Name",
        sort: true,
        formatter: getIncubateeName,
        filterValue: getIncubateeNameFilter
      },
      {
        dataField: "incubateeType",
        text: "Type",
        sort: true,
        formatter: cell => INCUBATEE_TYPES_OBJECT[cell],
        csvFormatter: cell => INCUBATEE_TYPES_OBJECT[cell]
      },
      {
        dataField: "graduationDate",
        text: "Graduation Date",
        sort: true,
        formatter: this.dateFormater,
        csvFormatter: this.dateFormater
      },
      {
        dataField: "graduationStatus",
        text: "Graduation Status",
        sort: true,
        formatter: cell => GRADUATION_STATUS_OBJECT[cell] || "Unspecified"
      },

      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        csvExport: false,
        formatter: this.actionsFormater
      }
    ];

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            {/* show alert message  */}
            <AlertComponent
              show={this.state.showAlert}
              type={this.state.alertType}
              alertColor={this.state.alertColor}
              message={this.state.alertMessage}
              close={this.closeDefaultAlert}
              confirm={this.okConfirmUpdate}
            />
          </div>
        </div>

        <TableList
          keyField="_id"
          columns={columnsGraduIncuabtees}
          data={graduatedIncubatees}
          noDataIndication="No Graduated Incubatees found."
          sizePerPage="10"
          headerName="Graduated Incubatees"
          showSearchbar={true}
        />
      </Fragment>
    );
  }
}

export default GraduatedIncubatees;
