import {
  IndividualSchema,
  IndividualUiSchema
} from "./schemas/IndividualSchema";
import { ContactSchema } from "./schemas/ContactSchema";
import {
  IncubateeProfileSchema,
  IncubateeProfileUiSchema
} from "./schemas/IncubateeProfileSchema";
import { PatentSchema } from "./schemas/PatentSchema";

const INDIVIDUAL_STEPS = [
  {
    schema: IndividualSchema,
    uiSchema: IndividualUiSchema,
    key: "individual",
    label: "Individual Info"
  },
  {
    schema: ContactSchema,
    key: "contacts",
    label: "Contacts & ID"
  },
  {
    schema: IncubateeProfileSchema,
    uiSchema: IncubateeProfileUiSchema,
    key: "incubateeProfile",
    label: "Profile"
  },
  {
    schema: PatentSchema,
    key: "patents",
    label: "Patents"
  }
];

export { INDIVIDUAL_STEPS };
