import React from "react";
export default function PopUpAlert(props) {
  const { setPopUp, handleClosureCreate, values } = props;

  const backdropStyle = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.3)",
    padding: 50,
    zIndex: 1000
  };

  // The modal "window"
  const modalStyle = {
    backgroundColor: "#fff",
    borderRadius: 5,
    margin: "0 auto",
    width: 400
  };

  return (
    <div className="backdrop" style={backdropStyle}>
      <div className="modal-dialog" style={modalStyle}>
        <div className="modal-content">
          <div className="alert-modal-header">
            <h5 className="h6 modal-title p-0">Account Closure</h5>
          </div>
          <div className="alert-modal-body d-flex">
            Do you want start the Account Closure process ?
          </div>
          <div className="alert-modal-footer">
            <button
              className={`btn btn-defalt btn-sm text-primary font-weight-bold`}
              onClick={() => handleClosureCreate(values)}
              type="button"
            >
              OK
            </button>
            <button
              className="btn btn-outline-default btn-sm"
              onClick={() => setPopUp(false)}
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
