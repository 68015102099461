import {
  PAYEE_TYPES_OBJECT,
  GRADUATION_STATUS_OBJECT,
  LEGAL_STATUS_TYPES_OBJECT
} from "../../common/LookupConstants";
import {
  RESIDENTIAL_STATUS_OBJECT,
  SUB_SECTORS_OBJECT,
  INCUBATION_CATEGORY_TYPES_OBJECT,
  INCUBATEE_STAGES_OBJECT,
  INCUBATION_SECTORS_OBJECT
} from "../../common/IncubateeProfileConstants";
import { formatNumericDate, getDisplayDate } from "../../lease/DateUtil";
import { getName } from "../../common/Util";
const XLSX = require("xlsx");

const GENDER_MAPPING = {
  M: "Male",
  F: "Female",
  T: "Transgender"
};

const EXPORT_SINGLE_MAPPING = [
  {
    header: "Name",
    accessor: "name",
    field: "individual.name"
  },
  {
    header: "Inubatee Type",
    accessor: "incubateeType",
    field: "incubatee.profile.incubateeType",
    exportValue: v => PAYEE_TYPES_OBJECT[v]
  },
  {
    header: "Incubation Date",
    accessor: "incubationDate",
    field: "incubatee.profile.incubationDate",
    exportValue: formatNumericDate
  },
  {
    header: "Graduation Status",
    accessor: "graduationStatus",
    field: "incubatee.profile.graduationStatus",
    exportValue: v => GRADUATION_STATUS_OBJECT[v]
  },
  {
    header: "Graduation Date",
    accessor: "graduationDate",
    field: "incubatee.profile.graduationDate",
    exportValue: formatNumericDate
  },
  {
    header: "Incubation Sector",
    accessor: "incubationSector",
    field: "incubatee.profile.incubateeSector",
    exportValue: v => INCUBATION_SECTORS_OBJECT[v]
  },
  {
    header: "Sub Tech Sector",
    accessor: "subTechSector",
    field: "incubatee.profile.subTechSector",
    exportValue: v => SUB_SECTORS_OBJECT[v]
  },
  {
    header: "Incubatee Stage",
    accessor: "incubateeStage",
    field: "incubatee.profile.incubateeStage",
    exportValue: v => INCUBATEE_STAGES_OBJECT[v]
  },
  {
    header: "Residential Status",
    accessor: "residentialStatus",
    field: "incubatee.profile.residentialStatus",
    exportValue: v => RESIDENTIAL_STATUS_OBJECT[v]
  },
  {
    header: "Incubation Category",
    accessor: "incubationCategory",
    field: "incubatee.profile.incubationCategory",
    exportValue: v => INCUBATION_CATEGORY_TYPES_OBJECT[v]
  },
  {
    header: "Relationship Manager",
    accessor: "relationshipManager",
    field: "incubatee.profile.relationshipManager"
  },
  {
    header: "Gender",
    accessor: "individual.gender",
    field: "individual.gender",
    exportValue: val => (val ? GENDER_MAPPING[val] : val)
  },
  {
    header: "Date of Birth",
    accessor: "individual.dob",
    field: "individual.dob",
    exportValue: formatNumericDate
  },
  {
    header: "Experience",
    accessor: "individual.experience",
    field: "individual.experience"
  },
  {
    header: "Area/Domain",
    accessor: "organization.domainOperation",
    field: "organization.domainOperation"
  },
  {
    header: "Website",
    accessor: "organization.website",
    field: "organization.website"
  },
  {
    header: "Legal Status",
    accessor: "organization.legalStatus",
    field: "organization.legalStatus",
    exportValue: val => LEGAL_STATUS_TYPES_OBJECT[val]
  },
  {
    header: "Year of Establishment",
    accessor: "organization.estYear",
    field: "organization.estYear"
  },
  {
    header: "Subsidiary",
    accessor: "organization.subsidiary",
    field: "organization.subsidiary",
    exportValue: val => (val ? "Yes" : "No")
  },
  {
    header: "Parent Company",
    accessor: "organization.parentCompany",
    field: "organization.parentCompany"
  },
  {
    header: "Mobile",
    accessor: ".",
    field: "individual.contacts.mobile",
    exportValue: obj => {
      if (obj.incubateeType === "INDIVIDUAL") {
        const indi = obj.individual || {};
        const phones = indi.phones || [];
        return phones.length ? phones[0].phone : "--";
      } else {
        const org = obj.organization || {};
        const phones = org.phones || [];
        return phones.length ? phones[0].phone : "--";
      }
    }
  },
  {
    header: "Email",
    accessor: ".",
    field: "individual.contacts.email",
    exportValue: obj => {
      if (obj.incubateeType === "INDIVIDUAL") {
        const indi = obj.individual || {};
        const emails = indi.emails || [];
        return emails.length ? emails[0].email : "--";
      } else {
        const indi = obj.organization || {};
        const emails = indi.emails || [];
        return emails.length ? emails[0].email : "--";
      }
    }
  },
  {
    header: "Address",
    accessor: ".",
    field: "individual.contacts.address",
    exportValue: obj => {
      if (obj.incubateeType === "INDIVIDUAL") {
        const address = obj.individual.addresses[0] || {};
        return `${address.streetLine1}, ${address.streetLine2}, ${
          address.city
        }, ${address.state}, ${address.postalCode}`;
      } else {
        const address = obj.organization.addresses[0] || {};

        return `${address.streetLine1}, ${address.streetLine2}, ${
          address.city
        }, ${address.state}, ${address.postalCode}`;
      }
    }
  },
  {
    header: "PAN",
    accessor: ".",
    field: "organization.idDocs.pan",
    exportValue: obj => {
      if (obj.incubateeType === "INDIVIDUAL") {
        const docs = obj.individual.identificationDocs;
        let panNumber;
        docs.forEach(doc => {
          if (doc.type === "PAN") {
            panNumber = doc.number;
          }
        });
        return panNumber;
      } else {
        const docs = obj.organization.identificationDocs;
        let panNumber;
        docs.forEach(doc => {
          if (doc.type === "PAN") {
            panNumber = doc.number;
          }
        });
        return panNumber;
      }
    }
  },
  {
    header: "AADHAAR",
    accessor: ".",
    field: "organization.idDocs.aadhar",
    exportValue: obj => {
      if (obj.incubateeType === "INDIVIDUAL") {
        const docs = obj.individual.identificationDocs;
        let aadharNumber;
        docs.forEach(doc => {
          if (doc.type === "AADHAAR") {
            aadharNumber = doc.number;
          }
        });
        return aadharNumber;
      } else {
        const docs = obj.organization.identificationDocs;
        let aadharNumber;
        docs.forEach(doc => {
          if (doc.type === "AADHAAR") {
            aadharNumber = doc.number;
          }
        });
        return aadharNumber;
      }
    }
  },
  {
    header: "GSTN",
    accessor: ".",
    field: "organization.idDocs.gstn",
    exportValue: obj => {
      if (obj.incubateeType === "INDIVIDUAL") {
        const docs = obj.individual.identificationDocs;
        let gstnNumber;
        docs.forEach(doc => {
          if (doc.type === "GSTN") {
            gstnNumber = doc.number;
          }
        });
        return gstnNumber;
      } else {
        const docs = obj.organization.identificationDocs;
        let gstnNumber;
        docs.forEach(doc => {
          if (doc.type === "GSTN") {
            gstnNumber = doc.number;
          }
        });
        return gstnNumber;
      }
    }
  },
  {
    header: "Total employee count",
    accessor: ".",
    field: "organization.metrics.totalEmployeeCount",
    exportValue: obj => {
      if (obj.incubateeType === "COMPANY") {
        const data = obj.organization || {};
        const metrics = data.metrics || [];
        if (metrics.length) {
          return metrics[metrics.length - 1].employeeCount;
        }
      }
    }
  },
  {
    header: "Female employee count",
    accessor: ".",
    field: "organization.metrics.femaleEmployeeCount",
    exportValue: obj => {
      if (obj.incubateeType === "COMPANY") {
        const data = obj.organization || {};
        const metrics = data.metrics || [];
        if (metrics.length) {
          return metrics[metrics.length - 1].femaleEmployeeCount;
        }
      }
    }
  },
  {
    header: "Metrics Updated Date",
    accessor: ".",
    field: "organization.metrics.updatedAt",
    exportValue: obj => {
      if (obj.incubateeType === "COMPANY") {
        const data = obj.organization || {};
        const metrics = data.metrics || [];
        if (metrics.length) {
          const date = getDisplayDate(metrics[metrics.length - 1].date);
          if (date === "01-01-1970") {
            return ""
          }
          return date
        }
      }
    }
  },
  {
    header: "References",
    accessor: ".",
    field: "individual.references",
    exportValue: obj => {
      if (obj.incubateeType === "INDIVIDUAL") {
        return obj.individual.references;
      } else {
        return obj.organization.references;
      }
    }
  }
];

const EXPORT_MULTIPLE_MAPPING = [
  {
    type: "Education",
    accessor: "individual.education",
    columns: [
      {
        header: "Qualification",
        accessor: "qualification",
        field: "individual.education.qualification"
      },
      {
        header: "Degree",
        accessor: "degree",
        field: "individual.education.degree"
      },
      {
        header: "Institution",
        accessor: "institution",
        field: "individual.education.institution"
      },
      {
        header: "Alumni",
        accessor: "alumni",
        field: "individual.education.alumni",
        exportValue: v => (v ? "Yes" : "No")
      },
      {
        header: "Year",
        accessor: "year",
        field: "individual.education.year"
      },
      {
        header: "Branch",
        accessor: "branch",
        field: "individual.education.branch"
      }
    ]
  },
  {
    type: "Product",
    accessor: "products",
    columns: [
      {
        header: "Name",
        accessor: "name",
        field: "incubatee.productsServices.name"
      },
      {
        header: "Description",
        accessor: "description",
        field: "incubatee.productsServices.description"
      },
      {
        header: "Category",
        accessor: "productCategory",
        field: "incubatee.productsServices.category"
      },
      {
        header: "Sub Category",
        accessor: "productSubCategory",
        field: "incubatee.productsServices.subCategory"
      }
    ]
  },
  {
    type: "Patent",
    accessor: "patents",
    columns: [
      {
        header: "Title Of Innovation",
        accessor: "titleOfInnovation",
        field: "incubatee.patents.titleOfInnovation"
      },
      {
        header: "Application Number",
        accessor: "applicationNumber",
        field: "incubatee.patents.applicationNumber"
      },
      {
        header: "Application Filing Date",
        accessor: "applicationFilingDate",
        field: "incubatee.patents.applicationFilingDate",
        exportData: v => getDisplayDate(v)
      },
      {
        header: "Patent Status",
        accessor: "patentStatus",
        field: "incubatee.patents.patentStatus"
      },
      {
        header: "Field Of Invention",
        accessor: "fieldOfInvention",
        field: "incubatee.patents.fieldOfInvention"
      },
      {
        header: "Classification",
        accessor: "classification",
        field: "incubatee.patents.classification"
      },
      {
        header: "Country",
        accessor: "country",
        field: "incubatee.patents.country"
      }
    ]
  }
];

const EXPORT_KEYED_MAPPING = [
  {
    type: "Financials",
    accessor: "organization.financials",
    keyField: "financialYear",
    sort: true,
    columns: [
      {
        header: "Turnover",
        accessor: "annualTurnover",
        field: "organization.financials.annualTurnover"
      }
    ]
  }
];

const EXPORT_PROMOTER_SINGLE_MAPPING = [
  {
    header: "Promoter Name",
    accessor: ".",
    field: "promoter.basicInfoFields.name",
    exportValue: obj => {
      const indi = obj.individual || {};
      return getName(indi);
    }
  },
  {
    header: "Designation",
    accessor: "designation",
    field: "promoter.basicInfoFields.designation"
  },
  {
    header: "Shareholding",
    accessor: "shareholding",
    field: "promoter.basicInfoFields.shareholding"
  },
  {
    header: "Gender",
    accessor: "individual.gender",
    field: "promoter.basicInfoFields.gender",
    exportValue: val => (val ? GENDER_MAPPING[val] : val)
  },
  {
    header: "Date of Birth",
    accessor: "individual.dob",
    field: "promoter.basicInfoFields.dob",
    exportValue: formatNumericDate
  },
  {
    header: "Experience",
    accessor: "individual.experience",
    field: "promoter.basicInfoFields.experience"
  },
  {
    header: "References",
    accessor: "individual.references",
    field: "promoter.basicInfoFields.references"
  },
  {
    header: "Founders",
    accessor: "isFounder",
    field: "promoter.basicInfoFields.founders",
    exportValue: val => (val ? "Yes" : "No")
  },
  {
    header: "Mobile",
    accessor: ".",
    field: "promoter.contactsFields.mobile",
    exportValue: obj => {
      const indi = obj.individual || {};
      const phones = indi.phones || [];
      return phones.length ? phones[0].phone : "--";
    }
  },
  {
    header: "Email",
    accessor: ".",
    field: "promoter.contactsFields.email",
    exportValue: obj => {
      const indi = obj.individual || {};
      const emails = indi.emails || [];
      return emails.length ? emails[0].email : "--";
    }
  },
  {
    header: "Address",
    accessor: ".",
    field: "promoter.contactsFields.address",
    exportValue: obj => {
      const address = obj.individual.addresses[0] || {};
      return `${address.streetLine1}, ${address.streetLine2}, ${
        address.city
      }, ${address.state}, ${address.postalCode}`;
    }
  },
  {
    header: "PAN",
    accessor: ".",
    field: "promoter.idDocsFields.pan",
    exportValue: obj => {
      const docs = obj.individual.identificationDocs;
      let panNumber;
      docs.forEach(doc => {
        if (doc.type === "PAN") {
          panNumber = doc.number;
        }
      });
      return panNumber;
    }
  },
  {
    header: "AADHAAR",
    accessor: ".",
    field: "promoter.idDocsFields.aadhar",
    exportValue: obj => {
      const docs = obj.individual.identificationDocs;
      let aadharNumber;
      docs.forEach(doc => {
        if (doc.type === "AADHAAR") {
          aadharNumber = doc.number;
        }
      });
      return aadharNumber;
    }
  }
];

const EXPORT_PROMOTER_MULTY_MAPPING = [
  {
    type: "Education",
    accessor: "individual.education",
    columns: [
      {
        header: "Qualification",
        accessor: "qualification",
        field: "promoter.educationFields.qualification"
      },
      {
        header: "Degree",
        accessor: "degree",
        field: "promoter.educationFields.degree"
      },
      {
        header: "Institution",
        accessor: "institution",
        field: "promoter.educationFields.institution"
      },
      {
        header: "Alumni",
        accessor: "alumni",
        field: "promoter.educationFields.alumni",
        exportValue: v => (v ? "Yes" : "No")
      },
      {
        header: "Year",
        accessor: "year",
        field: "promoter.educationFields.year"
      },
      {
        header: "Branch",
        accessor: "branch",
        field: "promoter.educationFields.branch"
      }
    ]
  }
];

function getValue(obj, accessor) {
  if (!obj) {
    return;
  }
  if (accessor === ".") {
    return obj;
  }
  let value = obj;
  const splitKeys = accessor.split(".");
  for (const key of splitKeys) {
    value = value[key];
    if (!value) {
      break;
    }
  }
  return value;
}

/**
 * Exports array objects in a single sheet.
 * E.g. Education with fields like Year of Passing, Degree, etc
 *
 * @param {Set<String>} selectedFields Set of selected fields for export
 * @param {Array<Object>} incubateeData List of incubatee records that needs to be exported
 * @param {Array<String>} header Header row for the data export
 * @param {Array<Array<String>} rows Array for records with each record represent exported incubatee data fields
 * @param {Object} incubateeRowIndex Mapping of incubatee _id and the row position in the above rows param.
 */

function exportMultiFields(
  selectedFields,
  incubateeData,
  header,
  rows,
  incubateeRowIndex
) {
  for (const multiMap of EXPORT_MULTIPLE_MAPPING) {
    const columns = multiMap.columns.filter(col =>
      selectedFields.has(col.field)
    );
    // No fields selected from this array of objects. Skip
    if (columns.length === 0) {
      console.log(`Skipping: ${multiMap.type}`);
      continue;
    }
    console.log(`Processing: ${multiMap.type}`);
    // Record count to keep track of max record count.
    // Even if the data is not there for this group,
    // it is presumed that there will be empty record displayed in output
    let recordCount = 1;
    for (const incubatee of incubateeData) {
      const records = getValue(incubatee, multiMap.accessor) || [];
      // Data is not available for the incubatee
      if (!records.length) {
        continue;
      }
      // Update the max recordCount
      recordCount = Math.max(recordCount, records.length);
      // Set the column index at position of the last updated data
      let colIndex = header.length;
      // Get the incubatee data row
      const row = rows[incubateeRowIndex[incubatee._id] - 1];
      for (const record of records) {
        // Add the selected fields
        for (const fieldMap of columns) {
          const colValue = getValue(record, fieldMap.accessor);

          row[colIndex++] = fieldMap.exportValue
            ? fieldMap.exportValue(colValue, incubatee)
            : colValue;
        }
      }
    }
    // Add the column headers for fields along with the record id.
    const columnNames = columns.map(col => col.header);
    for (let index = 1; index <= recordCount; index++) {
      header.push(
        ...columnNames.map(col => `${multiMap.type} ${index} - ${col}`)
      );
    }
  }
}

function exportPromoterMultiFields(
  selectedFields,
  promoterData,
  header,
  rows,
  incubateeRowIndex,
  promoterIdx
) {
  for (const multiMap of EXPORT_PROMOTER_MULTY_MAPPING) {
    const columns = multiMap.columns.filter(({ field }) =>
      selectedFields.has(field)
    );

    if (columns.length === 0) {
      console.log(`Skipping: ${multiMap.type}`);
      continue;
    }
    let recordCount = 1;
    for (const promoterObj of promoterData) {
      const records = getValue(promoterObj.promoter, multiMap.accessor);
      if (!records.length) {
        continue;
      }

      // To keep track of rocord count if finally used add headers
      recordCount = Math.max(recordCount, records.length);
      // let colIndex = header.length;

      const row = rows[incubateeRowIndex[promoterObj.incubateeId] - 1];
      for (const record of records) {
        for (const fieldMap of columns) {
          const colValue = getValue(record, fieldMap.accessor);
          row.push(
            fieldMap.exportValue
              ? fieldMap.exportValue(colValue, promoterObj.promoter)
              : colValue
          );
        }
      }
    }
    // Add the column headers for fields along with the record id.
    const columnNames = columns.map(col => col.header);
    for (let index = 1; index <= recordCount; index++) {
      header.push(
        ...columnNames.map(
          col => `Promoter-${promoterIdx}- ${multiMap.type} ${index} - ${col}`
        )
      );
    }
  }
}

function exportPromoterFields(
  selectedFields,
  incubateeData,
  header,
  rows,
  incubateeRowIndex
) {
  const selectedSingleValueFields = EXPORT_PROMOTER_SINGLE_MAPPING.filter(map =>
    selectedFields.has(map.field)
  );

  // Looping to get each promoter loop ends while there is no promoter data
  for (let promoterIdx = 0; true; promoterIdx++) {
    let hasPromoter = false;
    const promoters = [];

    for (const incubatee of incubateeData) {
      const organization = incubatee.organization || {};

      if (
        organization.promoters &&
        organization.promoters.length > promoterIdx
      ) {
        hasPromoter = true;

        let colIndex = header.length;

        const promoter = organization.promoters[promoterIdx];
        promoters.push({
          promoter,
          incubateeId: incubatee._id
        });

        // Taking the incubatee row with Row index
        const row = rows[incubateeRowIndex[incubatee._id] - 1];

        for (const fieldMap of selectedSingleValueFields) {
          const colValue = getValue(promoter, fieldMap.accessor);
          row[colIndex++] = fieldMap.exportValue
            ? fieldMap.exportValue(colValue, promoter)
            : colValue;
        }
      }
    }
    if (hasPromoter) {
      // Adding header columns only while promoter existing loops
      selectedSingleValueFields.forEach(map =>
        header.push(`Promoter-${promoterIdx + 1} ${map.header}`)
      );
      exportPromoterMultiFields(
        selectedFields,
        promoters,
        header,
        rows,
        incubateeRowIndex,
        promoterIdx + 1
      );
    } else {
      break;
    }
  }
}

/**
 * Exports array objects with grouping based on key field rather than record id.
 * E.g. Turnover with year as key
 *
 * @param {Set<String>} selectedFields Set of selected fields for export
 * @param {Array<Object>} incubateeData List of incubatee records that needs to be exported
 * @param {Array<String>} header Header row for the data export
 * @param {Array<Array<String>} rows Array for records with each record represent exported incubatee data fields
 * @param {Object} incubateeRowIndex Mapping of incubatee _id and the row position in the above rows param.
 */
function exportKeyedFields(
  selectedFields,
  incubateeData,
  header,
  rows,
  incubateeRowIndex
) {
  for (const keyMap of EXPORT_KEYED_MAPPING) {
    const columns = keyMap.columns.filter(col => selectedFields.has(col.field));
    // No fields selected from this array of objects. Skip
    if (columns.length === 0) {
      console.log(`Skipping: ${keyMap.type}`);
      continue;
    }
    console.log(`Processing: ${keyMap.type}`);

    // Create Object with key as key field value.
    // The value will be object that will have key as incubatee _id
    // and value as array of exported data.
    // KeyedData will look like this for exporting turnover by year
    // {
    //     "2019-20": {
    //       "123": [
    //         2500000
    //       ]
    //     },
    //     "2018-19": {
    //       "456": [
    //         150000
    //       ]
    //     }
    // }
    const keyedData = {};
    for (let incubatee of incubateeData) {
      const values = getValue(incubatee, keyMap.accessor) || [];
      if (!values.length) {
        continue;
      }
      for (const value of values) {
        const objKey = value[keyMap.keyField];

        if (!objKey) {
          continue;
        }

        if (!keyedData[objKey]) {
          keyedData[objKey] = {};
        }
        keyedData[objKey][incubatee._id] = columns.map(col =>
          getValue(value, col.accessor)
        );
      }
    }

    // Get the keys and sort if required.
    const keys = Object.keys(keyedData);
    if (keyMap.sort) {
      keys.sort();
    }
    // Loop through the keys and fetch the values
    // and add the field values to corresponding incubatee row
    // Finally add the headers for the key in the loop itself
    for (const key of keys) {
      const value = keyedData[key];
      for (const incubateeKey of Object.keys(value)) {
        const row = rows[incubateeRowIndex[incubateeKey] - 1];
        let colIndex = header.length;
        for (const incColValue of value[incubateeKey]) {
          row[colIndex++] = incColValue;
        }
      }

      columns.forEach(col => {
        header.push(`${key} ${col.header}`);
      });
    }
  }
}

function exportData(incubateeData, selectedOptions) {
  const selectedFields = new Set(selectedOptions);
  const header = [];
  const rows = [];

  // Get selected fields that has only one value per incubatee
  // E.g. Gender, Website, etc
  const selectedSingleValueFields = EXPORT_SINGLE_MAPPING.filter(map =>
    selectedFields.has(map.field)
  );

  // Add them to header
  selectedSingleValueFields.forEach(map => header.push(map.header));

  // Loop throught the incubatee records and add the selected field values.
  // Each row represents data for incubatee. The row position is also stored
  // in incubateeRowIndex object using _id as key, which will be used later.
  const incubateeRowIndex = {};
  for (const incubatee of incubateeData) {
    const row = [];
    for (const fieldMap of selectedSingleValueFields) {
      const colValue = getValue(incubatee, fieldMap.accessor);
      row.push(
        fieldMap.exportValue
          ? fieldMap.exportValue(colValue, incubatee)
          : colValue
      );
    }
    rows.push(row);
    incubateeRowIndex[incubatee._id] = rows.length;
  }

  exportMultiFields(
    selectedFields,
    incubateeData,
    header,
    rows,
    incubateeRowIndex
  );

  exportKeyedFields(
    selectedFields,
    incubateeData,
    header,
    rows,
    incubateeRowIndex
  );

  exportPromoterFields(
    selectedFields,
    incubateeData,
    header,
    rows,
    incubateeRowIndex
  );

  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet([header, ...rows]);

  XLSX.utils.book_append_sheet(workbook, sheet, "Data Export");
  XLSX.writeFile(workbook, `Incubatee Data Export.xlsx`);
}
const SELECTED_EXPORT_OPTIONS_MAPPING = {
  basicInfoFields: {
    prefixes: ["individual.", "organization."]
  },
  profileFields: {
    prefixes: ["incubatee.profile."]
  },
  patentsFields: {
    prefixes: ["incubatee.patents."]
  },
  productsServicesFields: {
    prefixes: ["incubatee.productsServices."]
  },
  educationFields: {
    prefixes: ["individual.education."]
  },
  financialsFields: {
    prefixes: ["organization.financials."]
  },
  contactsFields: {
    prefixes: ["individual.contacts.", "organization.contacts."]
  },
  metricsFields: {
    prefixes: ["organization.metrics."]
  },
  idDocsFields: {
    prefixes: ["individual.idDocs.", "organization.idDocs."]
  },
  promotersFields: {
    prefixes: ["promoter."]
  }
};

function getExportOptions(selectedFieldList) {
  const exportOptions = [];

  Object.entries(selectedFieldList).forEach(([rKey, rValue]) => {
    if (typeof rValue === "boolean") {
      return;
    }
    const { prefixes } = SELECTED_EXPORT_OPTIONS_MAPPING[rKey] || {};
    if (!prefixes) {
      return;
    }

    Object.entries(rValue).forEach(([key, value]) => {
      if (typeof value === "object") {
        // 3rd level looping for promoter options
        Object.entries(value).forEach(([sKey, sValue]) => {
          if (sValue) {
            // Defaulting promoter name for all promoters
            if (exportOptions.indexOf("promoter.basicInfoFields.name") === -1) {
              exportOptions.push("promoter.basicInfoFields.name");
            }
            prefixes.forEach(prefix => {
              exportOptions.push(`${prefix}${key}.${sKey}`);
            });
          }
        });
      } else if (value) {
        prefixes.forEach(prefix => {
          exportOptions.push(`${prefix}${key}`);
        });
      }
    });
  });
  return exportOptions;
}

function convertOptionsAndExport(data, selectedFieldList) {
  const exportOptions = getExportOptions(selectedFieldList);
  exportOptions.unshift("individual.name");

  exportData(data, exportOptions);
}

export default convertOptionsAndExport;
