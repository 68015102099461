import React from "react";
import { checkNaN, formatNumber } from "../../reports/ReportsUtil";
import PivotReport from "../../reports/PivotReport";
// import { convertNumberToDate, getDisplayDate } from "../lease/DateUtil";

const dimensions = [
  { value: "customer", title: "Customer" },
  { value: "customerType", title: "Customer Type" },
  { value: "chargeType", title: "Charge Type" },
  { value: "description", title: "Charge Description" },
  { value: "paymentStatus", title: "Payment Status" }
];

function reduce(row, aggRecord) {
  aggRecord.totalAmount = (aggRecord.totalAmount || 0) + checkNaN(row.amount);

  return aggRecord;
}
const calculations = [
  {
    title: "Total Amount",
    value: "totalAmount",
    template: val => formatNumber(val),
    sortBy: row => checkNaN(row.totalAmount)
  }
];

function ChargeDetailsByMonth(props) {
  const startDate = props.match.params.startDate;
  return (
    <PivotReport
      title="Grouped Monthly Charge Report"
      url={`/api/v1/finance/reports/consolidated-reports/${startDate}/charges`}
      dimensions={dimensions}
      reduce={reduce}
      calculations={calculations}
    />
  );
}

export default ChargeDetailsByMonth;
