import React from "react";
import PivotReport from "../PivotReport";
import {
  checkNaN,
  getPercent,
  getPercentString,
  formatNumber
} from "../ReportsUtil";

const dimensions = [
  { value: "owner", title: "Owner" },
  { value: "cluster", title: "Cluster" },
  { value: "campus", title: "Campus" },
  { value: "building", title: "Building" },
  { value: "floor", title: "Floor" },
  { value: "partitionDisplayName", title: "Partition" },
  { value: "partitionType", title: "Partition Type" },
  { value: "status", title: "Status" },
  { value: "category", title: "Category" },
  { value: "subCategory", title: "Sub Category" }
];

function reduce(row, aggRecord) {
  aggRecord.count = (aggRecord.count || 0) + (row.status ? 1 : 0);
  aggRecord.totalUsedArea =
    (aggRecord.totalUsedArea || 0) + parseFloat(row.usedArea);
  aggRecord.totalUsableArea =
    (aggRecord.totalUsableArea || 0) + parseFloat(row.area);
  return aggRecord;
}

const calculations = [
  {
    title: "Partition Count",
    value: "count",
    template: val => checkNaN(val),
    sortBy: row => checkNaN(row.count)
  },
  {
    title: "Usage",
    value: "count",
    template: (val, row) =>
      getPercentString(row.totalUsedArea, row.totalUsableArea),
    sortBy: row => getPercent(row.totalUsedArea, row.totalUsableArea)
  },

  {
    title: "Used Area",
    value: "totalUsedArea",
    template: (val, row) => formatNumber(row.totalUsedArea),
    sortBy: row => checkNaN(row.totalUsedArea)
  },
  {
    title: "Total Usable Area",
    value: "totalUsableArea",
    template: (val, row) => formatNumber(row.totalUsableArea),
    sortBy: row => checkNaN(row.totalUsableArea)
  }
];

function PartitionUsageReport(props) {
  let title = "Total Space Usage";
  const params = new URLSearchParams(props.location.search);
  const group = params.get("group");
  let activeDimensions = [];
  if (group === "category") {
    activeDimensions = ["Category", "category"];
    title = "Space Usage - Partition Category";
  } else if (group === "cluster") {
    activeDimensions = ["Cluster", "cluster"];
    title = "Space Usage - Cluster";
  }

  return (
    <PivotReport
      title={title}
      url="/api/v1/reports/facility/total-facility-usage"
      dimensions={dimensions}
      activeDimensions={activeDimensions}
      reduce={reduce}
      calculations={calculations}
    />
  );
}

export default PartitionUsageReport;
